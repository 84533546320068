import MultiUseDialog from '../Components/MultiUseDialog';

import API, {
  GetOrganizationPathForApi,
} from './api';
import debounce from 'es6-promise-debounce';

export const OrganizationRecommendedForRemoval = (organization, withCreatingUserLastActivity) => {
  if (!organization) {
    return false;
  }
  
  let createdOnPlus1h = new Date(organization.CreatedOn);
  createdOnPlus1h = new Date(createdOnPlus1h.setSeconds(createdOnPlus1h.getSeconds() + (60 * 60)));

  const creatingUserLastActivity = new Date(organization.CreatingUserLastActivity);
  let createdOnPlus1m = new Date(organization.CreatedOn);
  createdOnPlus1m = new Date(createdOnPlus1m.setSeconds(createdOnPlus1m.getSeconds() + 60));
 
  const minNumberItemsToCheck = 100;
  return (
    (new Date() > createdOnPlus1h)
    && ((withCreatingUserLastActivity) ? creatingUserLastActivity < createdOnPlus1m : true)
    && organization.UniqueNonReadOnlyOrganizationMembers < minNumberItemsToCheck
    && organization.HasUpToMinTasks < minNumberItemsToCheck
    && organization.HasUpToMinDocuments < minNumberItemsToCheck
    && organization.HasUpToMinFormTemplates < minNumberItemsToCheck
    && organization.HasUpToMinProcesses < minNumberItemsToCheck
  );
}

export const HandleUpdateOrganization = debounce((organization, onApiError, onOrgUpdated) => {
  return API.put(GetOrganizationPathForApi(organization.ID), organization)
    .then(resp => {
      if (onOrgUpdated) {
        onOrgUpdated();
      }
    })
    .catch(onApiError);
}, 250);

const handleRenameOrganization = (context, state, onSetState, onApiError, onOrgNameChanged, organization, name) => {
  if (!name || !organization)
    return;
  
  organization.Name = name;

  onSetState({ ShowDialogProgressIndicatorImmediately: true });
  HandleUpdateOrganization(
    organization,
    onApiError,
    () => {
      onSetState({ ShowDialogProgressIndicatorImmediately: false });
      // context.Reset();
      context.UpdateUserOrganizationName(organization.ID, name);
      HandleSetShowRenameOrganizationDialog(onSetState, false);
      if (onOrgNameChanged) {
        onOrgNameChanged(organization.ID, name);
      }
    },
  );
}

export const HandleSetShowRenameOrganizationDialog = (onSetState, ShowRenameOrganizationDialog, extraState) => {
  onSetState({
    ...extraState,
    ShowRenameOrganizationDialog,
  });
}

export const GetRenameOrganizationDialog = (context, state, onSetState, onApiError, onOrgNameChanged,
  organization, defaultValue) => {
  
  return (
    <MultiUseDialog Details={{
      Open:state.ShowRenameOrganizationDialog || false,
      ShowProgressIndicatorImmediately:state.ShowDialogProgressIndicatorImmediately,
      Title:"Rename organization",
      RequireTextInput1:true,
      TextInput1Label:"Name",
      TextInput1DefaultValue:defaultValue,
      CancelCallback:() => HandleSetShowRenameOrganizationDialog(onSetState, false),
      CloseCallback:() => HandleSetShowRenameOrganizationDialog(onSetState, false),
      ConfirmLabel:"RENAME",
      ConfirmCallback:name => handleRenameOrganization(context, state, onSetState, onApiError, onOrgNameChanged,
        organization, name),
    }} />
  );
}