import API, {
  GetAssetItemAssetItemsPathForApi,
} from './api';

export const AddAssetItemAssetItems = (organizationId, projectId, assetId, assetItemId, AssetItems) => {
  return API.post(GetAssetItemAssetItemsPathForApi(organizationId, projectId, assetId, assetItemId), { AssetItems });
}

export const RemoveAssetItemAssetItems = (organizationId, projectId, assetId, assetItemId, AssetItems) => {
  return API.delete(GetAssetItemAssetItemsPathForApi(organizationId, projectId, assetId, assetItemId), { data: { AssetItems } });
}