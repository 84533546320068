import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import Divider from '@material-ui/core/Divider';
// import Tooltip from '@material-ui/core/Tooltip';

import DashboardCard from '../DashboardCard';
import {
  GetProjectMembersPath,
  GetProjectTasksPath,
  GetUserProfilePath,
  GetOrganizationProfilePath,
  GetOrganizationSubscriptionPath,
  GetProjectProcessesPath,
  AppleAppStoreUrl,
  AndroidAppStoreUrl,
  DiscordInviteUrl,
} from '../../Util/PathHelper';
import ProjectCreationDialog from '../../Admin/Components/ProjectCreationDialog';
import {
  HandleSetShowRenameOrganizationDialog,
  GetRenameOrganizationDialog,
} from '../../Util/Organizations';
import {
  HandleSetShowProjectSelectionDialog,
  GetProjectSelectionDialog,
} from '../../Util/Projects';

import Icon from '@material-ui/icons/DoubleArrow';
import IconColor from '@material-ui/core/colors/deepPurple';

import {
  IsMobile,
  IsiOS,
 } from '../../Util/MobileDetector';
import { GlobalContext } from '../../Context/Global.context';

const styles = theme => ({
  list: {
    padding: 0,
    height: "100%",
    overflow: "auto",
    overflowX: "hidden",
    whiteSpace: "nowrap",
  },
});

class GettingStartedActionsWidget extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
   
    this.state = {
      ProjectActionID: null,
      ShowCreateProjectDialog: false,
      ShowProjectSelectionDialog: false,
    }
  }

  handleRouteTo = (url, useNewTab) => {
    if (url) {
      if (useNewTab) {
        window.open(url, "n1_resourceContent");
      } else {
        this.props.history.push(url);
      }
    }
  }

  handleRouteToMobileApp = () => {
    if (IsiOS()) {
      this.handleRouteTo(AppleAppStoreUrl, true);
    } else {
      this.handleRouteTo(AndroidAppStoreUrl, true);
    }
  }

  handleSetShowCreateProjectDialog = (ShowCreateProjectDialog) => {
    this.setState({
      ShowCreateProjectDialog,
    });
  }

  handleProjectCreated = project => {
    this.handleSetShowCreateProjectDialog(false);
    this.context.Reset();
    if (IsMobile()) {
      this.props.history.push(GetProjectTasksPath(project.ID));
    } else {
      this.props.history.push(GetProjectMembersPath(project.ID));
    }
  }

  handleStartProjectAction = ProjectActionID => {
    this.setState({ProjectActionID})
    HandleSetShowProjectSelectionDialog(state => this.setState(state), true);
  }

  handleCompleteProjectAction = projectMember => {
    HandleSetShowProjectSelectionDialog(state => this.setState(state), false, projectMember);
    switch (this.state.ProjectActionID) {
      case "designWorkflow":
        this.handleRouteTo(GetProjectProcessesPath(projectMember.ProjectID, true));
        break;
      default:
        break;
    }
  }

  getProjectMembershipPackageFilterByProjectAction = () => {
    switch (this.state.ProjectActionID) {
      case "designWorkflow":
        return pmp => pmp.IsAdmin;
      default:
        return null;
    }
  }

  render() {
    const {
      ShowCreateProjectDialog,
      ShowProjectSelectionDialog,
    } = this.state;
    const { 
      classes,
      theme,
      organizationId,
      DashboardWidget,
      ...passThroughProps
    } = this.props;
    const {
      CompletedGET,
      OrganizationMembershipPackages,
    } = this.context;

    let isOrgAdmin = false;
    let organization;
    if (CompletedGET.OrganizationMembershipPackages) {
      const orgPkgFinder = OrganizationMembershipPackages.filter(omp => omp.Organization.ID === organizationId);
      if (orgPkgFinder.length) {
        organization = orgPkgFinder[0].Organization;
        isOrgAdmin = orgPkgFinder[0].IsAdmin;
      }
    }

    const organizationNamingListItem = (isOrgAdmin)
      ? (
        <ListItem button onClick={() => HandleSetShowRenameOrganizationDialog(state => this.setState(state), true)}>
          <ListItemText primary="Name your organization" />
        </ListItem>
      ) : null;

    const organizationProfileListItem = (isOrgAdmin)
      ? (
        <ListItem button onClick={() => this.handleRouteTo(GetOrganizationProfilePath(organizationId))}>
          <ListItemText primary="Set your industry" />
        </ListItem>
      ) : null;

    const organizationSubscriptionListItem = (isOrgAdmin)
      ? (
        <ListItem button onClick={() => this.handleRouteTo(GetOrganizationSubscriptionPath(organizationId))}>
          <ListItemText primary="Manage billing subscription" />
        </ListItem>
      ) : null;

    const resourcesList = (
      <List dense className={classes.list}>
        <ListItem button onClick={() => this.handleRouteToMobileApp()}>
          <ListItemText primary="Get the mobile app" />
        </ListItem>

        {organizationNamingListItem}

        <ListItem button onClick={() => this.handleRouteTo(GetUserProfilePath(), false)}>
          <ListItemText primary="Complete your profile" />
        </ListItem>

        {organizationProfileListItem}

        <ListItem button onClick={() => this.handleSetShowCreateProjectDialog(true)}>
          <ListItemText primary="Create a project" />
        </ListItem>

        {organizationSubscriptionListItem}

        <ListItem button onClick={() => this.handleRouteTo(DiscordInviteUrl, true)}>
          <ListItemText primary="Join our Discord server" />
        </ListItem>

        <ListItem button onClick={() => this.handleStartProjectAction("designWorkflow")}>
          <ListItemText primary="Design a workflow process" />
        </ListItem>
      </List>
    );

    const projectCreationDialog = (
      <ProjectCreationDialog
        organizationId={organizationId}
        open={ShowCreateProjectDialog}
        onCreated={this.handleProjectCreated}
        onClose={() => this.handleSetShowCreateProjectDialog(false)}
        onApiError={passThroughProps.onApiError}
      />
    );

    const projectSelectionDialog = (ShowProjectSelectionDialog)
      ? GetProjectSelectionDialog(
        this.context,
        this.getProjectMembershipPackageFilterByProjectAction(),
        this.state,
        state => this.setState(state),
        this.handleCompleteProjectAction,
        true,
        () => this.handleSetShowCreateProjectDialog(true),
      ) : null;

    const renameOrganizationDialog = GetRenameOrganizationDialog(this.context, this.state,
      state => this.setState(state),
      passThroughProps.onApiError,
      null,
      organization,
      organization && organization.Name);

    return (
      <DashboardCard
        title="Getting-Started Actions"
        titleIcon={<Icon style={{color: IconColor[500]}} />}
        noFadeout
        xs={12}
        sm={6}
        md={3}
        lg={2}
        xl={2}
        {...passThroughProps}
        DashboardWidget={DashboardWidget}
      >
        {resourcesList}
        {projectCreationDialog}
        {projectSelectionDialog}
        {renameOrganizationDialog}
      </DashboardCard>  
    );
  }
}

GettingStartedActionsWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string,
};

export default withStyles(styles, {withTheme: true})(GettingStartedActionsWidget);