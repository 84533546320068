import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import { GlobalContext } from '../../Context/Global.context';
import GetMoreButton from '../../Util/GetMoreButton';
import ProgressIndicator from '../../Components/ProgressIndicator';

import API from '../../Util/api';
import debounce from 'es6-promise-debounce';
import { IsMobile } from '../../Util/MobileDetector';
import {
  GetUserValue,
} from '../../Util/Properties';
import {
  GetOrganizationMemberPathForApi,
  GetOrganizationMemberProjectsPathForApi,
} from '../../Util/api';
import {
  GetProjectPath,
  GetOrganizationLink,
} from '../../Util/PathHelper';

const styles = theme => ({
  dialogPaper: {
    height:(!IsMobile()) ? "80%" : undefined,
  },
  dialogTitle: {
  },
  dialogContent: {
  },
  dialogActions: {
  },
  contentContainer: {
    marginTop:theme.spacing(2),
  },
});

class OrganizationMemberDialog extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      OrganizationMember: props.OrganizationMember,
      OrganizationMemberProjects: [],
      OrganizationMemberProjectsCursor: "",
      ShowGetMoreButton: false,
      ShowProgressIndicator: false,
    }
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleUpdateOrganizationMemberBoolean(id, boolean) {
    let organizationMember = {...this.state.OrganizationMember};
    organizationMember[id] = boolean;
    this.updateOrganizationMember(organizationMember);
  }

  updateOrganizationMember = OrganizationMember => {
    this.setState({OrganizationMember});
    this.handleUpdateServerOrganizationMember(OrganizationMember);
    if (OrganizationMember && this.props.onOrganizationMemberRevised) {
      this.props.onOrganizationMemberRevised({...OrganizationMember});
    }
  }

  handleUpdateServerOrganizationMember = debounce(organizationMember => {
    if (!this.props.OrganizationMember) {
      return;
    }
    if (!organizationMember) {
      organizationMember = {...this.state.OrganizationMember};
    }

    API.put(GetOrganizationMemberPathForApi(this.state.OrganizationMember.OrganizationID, 
      this.state.OrganizationMember.ID), organizationMember)
      .then(resp => {
        this.setState({
          // OrganizationMember: resp.data,
        });
      })
      .catch(this.handleApiError);
  }, 250);

  handleLoadOrganizationMemberProjects = reset => {
    const params = {
      cursor: (reset) ? "" : this.state.OrganizationMemberProjectsCursor,
    };
    API.get(GetOrganizationMemberProjectsPathForApi(this.state.OrganizationMember.OrganizationID,
      this.state.OrganizationMember.ID), { params })
      .then(resp => {
        let stateToUpdate = {
          OrganizationMemberProjectsCursor: resp.data.Cursor,
          ShowGetMoreButton: resp.data.OrganizationMemberProjects.length >= resp.data.PageSize,
        };
        if (reset) {
          stateToUpdate.OrganizationMemberProjects = resp.data.OrganizationMemberProjects;
        } else {
          stateToUpdate.OrganizationMemberProjects = [...this.state.OrganizationMemberProjects]
            .concat(resp.data.OrganizationMemberProjects);
        }
        this.setState(stateToUpdate);
      })
      .catch(this.handleApiError);
  }

  handleUpdateOrganizationMemberProjectIsMapped = (projectId, isMapped) => {
    let OrganizationMemberProjects = [...this.state.OrganizationMemberProjects];
    const projectFinder = OrganizationMemberProjects.filter(p => p.ProjectID === projectId);
    if (!projectFinder.length) {
      return;
    }
    let organizationMemberProject = projectFinder[0];
    organizationMemberProject.IsMapped = isMapped;
    this.setState({OrganizationMemberProjects});
    this.handleUpdateServerOrganizationMemberProject(organizationMemberProject);
  }

  handleUpdateServerOrganizationMemberProject = organizationMemberProject => {
    let OrganizationMemberProjects = [...this.state.OrganizationMemberProjects];
    const params = {
      projectPath: GetOrganizationLink(this.state.OrganizationMember.OrganizationID,
        GetProjectPath(organizationMemberProject.ProjectID)),
    }
    API.put(GetOrganizationMemberProjectsPathForApi(this.state.OrganizationMember.OrganizationID,
      this.state.OrganizationMember.ID), [organizationMemberProject], { params })
      .then(resp => {
      })
      .catch(err => {
        this.handleApiError(err);
        this.setState({ OrganizationMemberProjects });
      });
  }

  isCurrentUserSameAsOrganizationMember() {
    return this.state.OrganizationMember.UserEmail === this.context.UserPreferences.UserEmail;
  }

  canUpdateIsDisabled() {
    if (
      // Current user is the same as the member
      this.isCurrentUserSameAsOrganizationMember()
      // Member is an admin
      || this.state.OrganizationMember.IsAdmin
    ) { return false; }
    return true;
  }

  canUpdateIsAdmin() {
    if (
      // Current user is the same as the member
      this.isCurrentUserSameAsOrganizationMember()
      // Member is disabled
      || this.state.OrganizationMember.Disabled
      // Member is read-only
      || this.state.OrganizationMember.IsReadOnly
    ) { return false; }
    return true;
  }

  canUpdateIsReadOnly() {
    if (
      // Current user is the same as the member
      this.isCurrentUserSameAsOrganizationMember()
      // Member is disabled
      || this.state.OrganizationMember.Disabled
      // Member is an admin
      || this.state.OrganizationMember.IsAdmin
    ) { return false; }
    return true;
  }

  handleApiError = err => {
    this.setState({ShowProgressIndicator: false});
    this.props.onApiError(err);
  }

  componentDidMount() {
    this.handleLoadOrganizationMemberProjects();
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      let stateToUpdate = {
        open: this.props.open !== false,
      }
      if (this.props.open) {
        if (this.props.OrganizationMember) {
          stateToUpdate.OrganizationMember = this.props.OrganizationMember;
        } else {
          stateToUpdate.OrganizationMember = null;
        }
      } else {
        stateToUpdate.OrganizationMember = null;
      }
      this.setState(stateToUpdate);
    }
  }

  render() {
    const {
      open,
      OrganizationMember,
      OrganizationMemberProjects,
      ShowGetMoreButton,
      ShowProgressIndicator,
    } = this.state;
    const {
      classes,
      theme,
      showProgressIndicatorImmediately,
    } = this.props;
    const {
      CompletedGET,
    } = this.context;

    if (!CompletedGET.UserPreferences) {
      return null;
    }

    const dialogActions = (
      <DialogActions className={classes.dialogActions}>
        <Button onClick={() => this.handleClose()}>
          CLOSE
        </Button>
      </DialogActions>
    );

    const progressIndicator = (ShowProgressIndicator || showProgressIndicatorImmediately)
      ? (
        <ProgressIndicator showImmediately={showProgressIndicatorImmediately} />
      )
      : null;

    let memberProjectGridItems = [];
    if (OrganizationMemberProjects && OrganizationMemberProjects.length) {
      OrganizationMemberProjects.forEach(omp => {
        const gridItem = (
          <Grid item key={`omp_${omp.ProjectID}`}>
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  checked={omp.IsMapped}
                  onClick={e => e.stopPropagation()}
                  onChange={e => this.handleUpdateOrganizationMemberProjectIsMapped(omp.ProjectID, e.target.checked)} 
                />
              }
              label={omp.ProjectName} /> 
          </Grid>
        );
        memberProjectGridItems.push(gridItem);
      });
    }

    if (ShowGetMoreButton) {
      memberProjectGridItems.push(GetMoreButton(true, () => this.handleLoadOrganizationMemberProjects(), "", theme));
    }

    const memberProjects = (memberProjectGridItems.length && !OrganizationMember.IsAdmin)
      ? (
        <div>
          <Typography variant="subtitle2" style={{marginTop:theme.spacing(3)}}>
            Projects
          </Typography>

          <Grid container direction="column" spacing={2}>
            {memberProjectGridItems}
          </Grid>
        </div>
      )
      : null;

    const content = (
      <React.Fragment>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  checked={OrganizationMember.Disabled || false}
                  disabled={!this.canUpdateIsDisabled()}
                  onClick={e => e.stopPropagation()}
                  onChange={e => this.handleUpdateOrganizationMemberBoolean("Disabled", e.target.checked)}
                />
              }
              label="Disabled" />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  checked={OrganizationMember.IsAdmin || false}
                  disabled={!this.canUpdateIsAdmin()}
                  onClick={e => e.stopPropagation()}
                  onChange={e => this.handleUpdateOrganizationMemberBoolean("IsAdmin", e.target.checked)} 
                />
              }
              label="Administrator" />
          </Grid>
        </Grid>

        {memberProjects}

      </React.Fragment>
    );

    return (
      <Dialog
        fullScreen={IsMobile()}
        fullWidth={!IsMobile()}
        maxWidth="sm"
        open={open}
        classes={{
          paper:classes.dialogPaper,
        }}
        onClose={() => this.handleClose()}
        // aria-labelledby="dialog-title"
        // aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title" className={classes.dialogTitle}>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {GetUserValue(OrganizationMember.UserEmail, OrganizationMember.UserName, "profile")}

          {progressIndicator}
          
          <div className={classes.contentContainer}>
            {content}
          </div>

        </DialogContent>
        {dialogActions}
      </Dialog>
    );
  }
}

OrganizationMemberDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  OrganizationMember: PropTypes.object,
  onClose: PropTypes.func,
  onApiError: PropTypes.func.isRequired,
  onOrganizationMemberRevised: PropTypes.func,
};

export default withStyles(styles, {withTheme: true})(OrganizationMemberDialog);