import { GetFieldForFilterAndSort } from '../Util/Field';

const SimpleSearchResultsCollectionFields = [
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[Name].keyword",
		"Name",
		"FieldType_Text",
		"Meta_text_kw256lc[Name]",
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[ProjectName].keyword",
		"Project",
		"FieldType_Text",
		"Meta_text_kw256lc[ProjectName]",
		null,
    "projectList",
    true,
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[AssignmentUserEmail].keyword",
		"Assigned to/Shared with",
		"FieldType_Text",
		null,
    [
      {
          value: "equals",
          label: "equals",
      },
      {
        value: "notEquals",
        label: "does not equal",
      },
    ],
    "addressBookItemsList",
    true,
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw50lc[Tag].keyword",
		"Tags",
		"FieldType_Text",
		"Meta_text_kw50lc[Tag]",
		null,
  	"tags",
  	true,
  	false,
  	null,
  	null,
  	false,
  	null,
  	true,
	),
];

export default SimpleSearchResultsCollectionFields;