import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import API, {
  GetAssetsPathForApi,
} from '../Util/api';
import ProgressIndicator from '../Components/ProgressIndicator';

import { IsMobile } from '../Util/MobileDetector';

import NewAsset from '../Model/Asset';

const styles = theme => ({
});

class AssetCreationDialog extends Component {
  constructor(props) {
    super(props);
    
    this.initialState = {
      ShowProgressIndicatorImmediately: false,
      Name: "",
      PluralName: "",
    };

    this.state = {
      ...this.initialState,
      open: props.open,
    }
  }

  setInitialState = () => {
    this.setState(this.initialState);
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
    this.setInitialState();
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleCreate = () => {
    if (!this.state.Name || !this.state.PluralName) {
      return;
    }

    this.setState({ShowProgressIndicatorImmediately: true});
    
    const newAsset = {
      ...NewAsset,
      Name: this.state.Name,
      PluralName: this.state.PluralName,
    };
    if (this.props.onBeginCreate) {
      this.props.onBeginCreate(newAsset);
    }

    API.post(GetAssetsPathForApi(this.props.organizationId, this.props.projectId), [newAsset])
      .then(resp => {
        if (this.props.onCreated) {
          this.props.onCreated(resp.data[0]);
        }
        this.setInitialState();
      })
      .catch(this.handleApiError);
  }

  handleApiError = (err, title_optional) => {
    this.setState({
      ShowProgressIndicatorImmediately: false,
    });
    this.props.onApiError(err, title_optional);
    if (err) {
      setTimeout(() => this.props.onApiError(null), 1);
    }
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      this.setState({open: this.props.open !== false});
    }
  }

  render() {
    const { 
      open,
      Name,
      PluralName,
      ShowProgressIndicatorImmediately,
  	} = this.state;
    const {
      // classes,
      theme,
      projectName,
    } = this.props;

    let progressIndicator = null;
    if (ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    let dialogActions = (
      <DialogActions>
        <Button onClick={() => this.handleClose()}>
          CLOSE
        </Button>
        <Button onClick={() => this.handleCreate()}>
          CREATE
        </Button>
      </DialogActions>
    );

    let content = (
      <Grid container spacing={2} style={{marginTop:theme.spacing(1)}}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            autoFocus
            variant="outlined"
            label="Name"
            InputLabelProps={{ shrink: true }}
            placeholder="Employee"
            onChange={e => this.setState({Name: e.target.value})}
            value={Name}
            onKeyDown={e => { if (e.keyCode === 13) { this.handleCreate(); } }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            label="Plural name"
            InputLabelProps={{ shrink: true }}
            placeholder="Employees"
            onChange={e => this.setState({PluralName: e.target.value})}
            value={PluralName}
            onKeyDown={e => { if (e.keyCode === 13) { this.handleCreate(); } }}
          />
        </Grid>
      </Grid>
    );

    const dialogTitle = `Create an asset${(projectName) ? " in " + projectName : ""}`;

    return (
       <Dialog
        fullWidth={!IsMobile()}
        fullScreen={IsMobile()}
        maxWidth="sm"
        // TransitionComponent={Transition}
        // PaperProps={{
        //   style:{
        //     minHeight:"50%",
        //   }
        // }}
        open={open}
        onClose={() => this.handleClose()}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description">
        <DialogTitle id="dialog-title">
          {dialogTitle}
        </DialogTitle>
        <DialogContent style={{paddingTop:0}}>
          {progressIndicator}

          {content}
        </DialogContent>

        {dialogActions}
      </Dialog>
    );
  }
}

AssetCreationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  projectName: PropTypes.string,
  onBeginCreate: PropTypes.func,
  onCreated: PropTypes.func,
  onApiError: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default withStyles(styles, {withTheme: true})(AssetCreationDialog);