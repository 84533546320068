import React, { Component } from 'react';

import ItemCollectionBase from '../Components/ItemCollectionBase';

import Collection from '../Model/Collection';
import { SearchResults_Collection } from '../Model/SearchResults';
import { GlobalContext } from '../Context/Global.context';
import { IsMobile } from '../Util/MobileDetector';

class SearchResults extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.state = {
      CollectionUpdateID: null,
    }

    this.InitialFullTextQuery = null;
    this.setInitialFullTextQuery();

    this.Collection = new Collection(this.props, state => this.setState(state), this.handleApiError);
  }

  setInitialFullTextQuery = () => {
    if (this.props.match.params.searchQuery_json_base64) {
      const searchQuery_json_base64 = this.props.match.params.searchQuery_json_base64;
      this.InitialFullTextQuery = JSON.parse(decodeURIComponent(escape(atob(searchQuery_json_base64))));
      this.props.history.replace("/search");
    }
  }

  setCollectionWhenReady = () => {
    if (!this.context.CompletedGET.UserPreferences 
      || !this.context.CompletedGET.ProjectMembershipPackages) {
      setTimeout(() => this.setCollectionWhenReady(), 250);
      return;
    }
    this.Collection = new SearchResults_Collection(this.props, this.context, state => this.setState(state),
      this.handleApiError);
    // This ensures ItemCollectionBase always sees the new collection
    this.setState({CollectionUpdateID: new Date()});
  }

  componentDidMount() {
    this.setCollectionWhenReady();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setInitialFullTextQuery();
    }
  }

  handleApiError = err => {
    this.setState({ ApiError:err });
    if (err) {
      setTimeout(() => this.handleApiError(null), 1);
    }
  }

  render() {
    const {
      ApiError,
    } = this.state;
    const {
      UserPreferences,
    } = this.context;

    return (
      <ItemCollectionBase
        {...this.props}

        organizationId={UserPreferences.ActiveOrganizationID}
        pageTitle="Search"
        showOrgAsTitleOnDesktop
        contentUri={this.Collection.ContentUri}
        // contentUriParams={contentUriParams}
        fullTextFilter={(this.InitialFullTextQuery) ? this.InitialFullTextQuery.FullText : undefined}
        collectionName={this.Collection.CollectionName}
        itemsName={this.Collection.ItemsName}
        itemName={this.Collection.ItemName}
        defaultViewType={this.Collection.DefaultViewType}
        
        onGetCollectionFieldsPromise={this.Collection.HandleGetCollectionFieldsPromise}
        onGetHeadCells={this.Collection.HandleGetHeadCells}
        onGetCardGridItems={this.Collection.HandleGetCardGridItems}
        onGetTableRows={this.Collection.HandleGetTableRows}

        // allowSelect
        hideSensitiveFields={this.Collection.HideSensitiveFields}
        hideFilterSortDrawer={this.Collection.HideFilterSortDrawer}
        loadItemsImmediately
        showFilterAndSortDrawerOnLoad={!IsMobile()}
        apiError={ApiError}
      />
    );
  }
}

export default SearchResults;
