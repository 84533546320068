import React from 'react';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';

import Collection from '../Model/Collection';

import ItemTableRow from '../Components/ItemTableRow';
import UserApiKeyCard from '../Components/UserApiKeyCard';
import UserApiKeyCollectionFields from '../Model/UserApiKeyCollectionFields';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  GetDateValue,
} from '../Util/Properties';

import API, {
  GetUserApiKeysPathForApi,
  GetUserApiKeyPathForApi,
} from '../Util/api';
import {
  GetUserApiKeysPath,
  GetUserApiKeyPath,
} from '../Util/PathHelper';

import MultiUseDialog from '../Components/MultiUseDialog';

export class UserApiKeys_Collection extends Collection {
  constructor(props, onSetState, onApiError) {
    super(props, onSetState, onApiError);

    this.PageTitle = "API Keys";
    this.ContentUri = GetUserApiKeysPathForApi();
    this.CollectionName = "UserApiKeys";
    this.ItemsName = "UserApiKeys";
    this.ItemName = "Key";
    this.DefaultViewType = "List";
    this.AllowSelect = true;
    this.CanSelectItem = item => true;
    this.UserApiKeysForDialog = [];

    this.keyRef = React.createRef();
  }

  HandleGetCollectionFieldsPromise = () => Promise.resolve(UserApiKeyCollectionFields);

  HandleGetHeadCells = (items, sensitiveFields) => [
    { id: 'Name', numeric: false, label: 'Name' },
    { id: 'CreatedOn', numeric: false, label: 'Created On' },
  ];

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length) 
      ? items
        .map(i => {
          return (
            <Grid item key={i.ID} sm={12} md={6} lg={4} xl={3} className={classes.cardGridItem}>
              <UserApiKeyCard
                UserApiKey={i}
                onCardAction={() => this.HandleRouteToUserApiKey(this.props, i.ID)}
                onSelect={(this.CanSelectItem(i)) ? () => onSelect(i.ID) : undefined}
                selected={selectedIDs.indexOf(i.ID) > -1}
              />
            </Grid>
          );
        }) 
      : [];
  }

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length)
      ? items
        .map(i => {
          const labelId = `label_${i.ID}`;
          return (
            <ItemTableRow key={`k_${i.ID}`}
              onItemClick={() => this.HandleRouteToUserApiKey(this.props, i.ID)}
            >
              <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
                <Checkbox
                  style={{
                    visibility: (!this.CanSelectItem(i)) ? "hidden" : undefined,
                  }}
                  color="secondary"
                  onClick={event => { event.stopPropagation(); onSelect(i.ID); }}
                  checked={selectedIDs.indexOf(i.ID) > -1}
                  inputProps={{ 'aria-labelledby': labelId, style:{zIndex:0} }}
                />
              </TableCell>
              <TableCell className={classes.tableCell} component="th" id={`label_${i.ID}`} scope="row" padding="none">
                {i.Name}
              </TableCell>
              <TableCell className={classes.tableCell}>{GetDateValue(i.CreatedOn)}</TableCell>
            </ItemTableRow>
          );
        }) 
      : [];
  }

  HandleRouteToUserApiKey = (props, userApiKeyId) => {
    props.history.push(GetUserApiKeyPath(userApiKeyId), { ...props.location.state });
  }

  HandleItemsChanged = items => {
    this.UserApiKeysForDialog = items || [];
    this.handleDisplayUpdateApiKeyDialogIfApplicable(null, items);
  }

  HandleGetDialogContent = state => {
    if (state.AddUserApiKeyDialogIsOpen) {
      return (
        <MultiUseDialog
          Details={{
            Open: state.AddUserApiKeyDialogIsOpen,
            Title: "Add API Key",
            RequireTextInput1: true,
            ShowProgressIndicatorImmediately: state.ShowDialogProgressIndicatorImmediately || false,
            TextInput1Label: "Name/Description",
            ConfirmLabel: "ADD",
            ConfirmCallback: name => this.handleAddUserApiKey(state, name),
            CancelCallback: this.handleAddUserApiKeyDialogClosed,
            CloseCallback: this.handleAddUserApiKeyDialogClosed,
          }}
        />
      );
    } else if (state.UpdateUserApiKeyDialogIsOpen) {
      return (
        <MultiUseDialog
          Details={{
            Open: state.UpdateUserApiKeyDialogIsOpen,
            Title: "Update API Key",
            RequireTextInput1: true,
            ShowProgressIndicatorImmediately: state.ShowDialogProgressIndicatorImmediately || false,
            TextInput1Label: "Name/Description",
            TextInput1DefaultValue:state.UserApiKeyForUserApiKeyDialog && state.UserApiKeyForUserApiKeyDialog.Name,
            ConfirmLabel: "UPDATE",
            ConfirmCallback: name => this.handleUpdateUserApiKey(state, name),
            CancelCallback: this.handleUpdateUserApiKeyDialogClosed,
            CloseCallback: this.handleUpdateUserApiKeyDialogClosed,
          }}
        />
      );
    }
  }

  handleAddUserApiKeyDialogClosed = () => {
    this.setState({
      AddUserApiKeyDialogIsOpen: false,
    })
  }

  handleShowPopover = text => {
    this.setState({ KeyCopiedPopoverText: text });
    setTimeout(() => this.setState({ KeyCopiedPopoverText: null }), 1500); 
  }

  HandleGetMiscContent = state => {
    return (
      <Popover
        open={Boolean(state.KeyCopiedPopoverText)}
        anchorEl={(this.keyRef && !this.keyRef.hasOwnProperty('current')) ? this.keyRef : undefined}
        onClose={() => this.handleShowPopover(null)}
        anchorOrigin={{
          vertical:'center',
          horizontal:'center',
        }}
        transformOrigin={{
          vertical:'center',
          horizontal:'center',
        }}
        // classes={{
        //   paper: classes.popoverPaper,
        // }}
      >
        {state.KeyCopiedPopoverText}
      </Popover>
    );
  }

  handleAddUserApiKey = (state, Name) => {
    if (!Name)
      return;
    
    this.setState({ShowDialogProgressIndicatorImmediately: true});
    const userApiKey = {
      Name,
    };
    API.post(GetUserApiKeysPathForApi(), [userApiKey])
      .then(resp => {
        this.setState({
          Alert: {
            Title: "API Key Created",
            DialogWidth: "md",
            BodyContent: (
              <React.Fragment>
                <Tooltip title="Copy to clipboard">
                  <CopyToClipboard
                    key="ctc_putKeyInClipboard"
                    text={resp.data[0].Key}
                    onCopy={() => this.handleShowPopover("Copied to clipboard")}
                  >
                    <Typography style={{cursor:"grab",textAlign:"center",fontWeight:"bold"}} ref={instance => this.keyRef}>{resp.data[0].Key}</Typography>
                  </CopyToClipboard>
                </Tooltip>
                <Typography style={{marginTop:this.props.theme.spacing(3)}}>
                  This is the ONLY time you will see this key.
                </Typography>
                <Typography>
                  Keep it in a safe place, and do not commit it to any version control system.
                </Typography>
              </React.Fragment>
            ),
          },
          ForcePrependItems: resp.data,
          ShowDialogProgressIndicatorImmediately: false,
        });
        this.handleAddUserApiKeyDialogClosed();
      })
      .catch(this.handleApiError);
  }

  handleUpdateUserApiKey = (state, Name) => {
    if (!Name || !state.UserApiKeyForUserApiKeyDialog) {
      return;
    }
    
    this.setState({ShowDialogProgressIndicatorImmediately: true});
    let UserApiKey = {...state.UserApiKeyForUserApiKeyDialog};
    UserApiKey.Name = Name;
    API.put(GetUserApiKeyPathForApi(UserApiKey.ID), UserApiKey)
      .then(resp => {
        state.UserApiKeyForUserApiKeyDialog.Name = resp.data.Name;
        this.handleUpdateUserApiKeyDialogClosed();
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ShowDialogProgressIndicatorImmediately: false});
      });
  }

  HandleCreateNew = () => {
    this.setState({
      AddUserApiKeyDialogIsOpen: true,
    });
  }

  handleDisplayUpdateApiKeyDialogIfApplicable = (userApiKeyId, userApiKeys) => {
    if (!userApiKeyId) {
      userApiKeyId = this.props.match.params.collectionItemID;
    }
    if (userApiKeyId) {
      const displayUserApiKeyDialog = (userApiKey, extraState) => {
        this.setState({
          UserApiKeyForUserApiKeyDialog: userApiKey,
          UpdateUserApiKeyDialogIsOpen: true,
          ...extraState,
        });
      }
      if (!userApiKeys) {
        userApiKeys = [...this.UserApiKeysForDialog];
      }
      let keyFinder = userApiKeys.filter(uak => uak.ID === userApiKeyId);
      if (!keyFinder.length) {
        API.get(GetUserApiKeyPathForApi(userApiKeyId))
          .then(resp => {
            displayUserApiKeyDialog(resp.data, {
              ForcePrependItems: [resp.data],
            });
          })
          .catch(err => {
            this.handleApiError(err);
            this.handleUpdateUserApiKeyDialogClosed();
          });
      } else {
        displayUserApiKeyDialog(keyFinder[0]);
      }
    }
  }

  handleUpdateUserApiKeyDialogClosed = () => {
    const stateToUpdate = {
      UpdateUserApiKeyDialogIsOpen: false,
      ShowDialogProgressIndicatorImmediately: false,
    };
    this.setState(stateToUpdate);

    const newPath = GetUserApiKeysPath();
    if (this.props.location.pathname !== newPath) {
      this.props.history.push(newPath, { ...this.props.location.state, ...stateToUpdate });
    }
  }

  // Returns whether further path changes can occur.
  updateProps(props) {
    Collection.prototype.updateProps.bind(this)(props);

    if (this.props.match.params.collectionItemID && !this.prevProps.match.params.collectionItemID) {
      this.handleDisplayUpdateApiKeyDialogIfApplicable(null, null);
      return false;
    } else if (!this.props.match.params.collectionItemID && this.prevProps.match.params.collectionItemID) {
      this.handleUpdateUserApiKeyDialogClosed();
      return false;
    }

    return true;
  }
}