import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Card from '../Components/Card';
import IconButton from '@material-ui/core/IconButton';

import ActionType from '../Model/ActionType';
import { GetItemTypeNameFromApproval } from '../Util/Approvals';
import {
  GetUserValue,
  GetDateValue,
} from '../Util/Properties';
import {
  GetTagsPreviewFromTagsObject,
} from '../Util/Tags';

class ApprovalCard extends Component {
  render() {
    const {
      Approval,
      onAction,
      onSelect,
      noSelect,
      selected,
      onCardAction,
      style,
      height,
      isSearchResults,
    } = this.props;

    let hoverActionItems = [
      <IconButton
        key={ActionType.Approval_Approve.Id}
        aria-label={ActionType.Approval_Approve.Label}
        title={ActionType.Approval_Approve.Label}
        onClick={() => onAction(ActionType.Approval_Approve, Approval.ID)}
        >
        {ActionType.Approval_Approve.Icon}
      </IconButton>,
      <IconButton
        key={ActionType.Approval_Decline.Id}
        aria-label={ActionType.Approval_Decline.Label}
        title={ActionType.Approval_Decline.Label}
        onClick={() => onAction(ActionType.Approval_Decline, Approval.ID)}
        >
        {ActionType.Approval_Decline.Icon}
      </IconButton>
    ];

    const metadata = [
      ["Project", Approval.ProjectName],
      ["Item Type", GetItemTypeNameFromApproval(Approval)],
      ["Process", Approval.ProcessName],
      ["Stage", Approval.ProcessElementName], 
      ["Assigned to", GetUserValue(Approval.AssignmentUserEmail, Approval.AssignmentUserName, "sm")],
      ["Approval Created On", GetDateValue(Approval.CreatedOn)],
      ["Tags", GetTagsPreviewFromTagsObject(Approval)],
      ["Item Created On", GetDateValue(Approval.ItemCreatedOn)],
      ["Item Created By", GetUserValue(Approval.ItemCreatedByUserEmail, Approval.ItemCreatedByUserName, "sm")]
    ];

    return (
      <Card
        style={style}
        height={height || 256}
        hoverActionItems={hoverActionItems}
        onCardAction={onCardAction}
        noSelect={noSelect}
        onSelect={onSelect}
        selected={selected}
        name={Approval.ItemName || Approval.Name}
        metadata={metadata}
        isSigned={Approval.DocumentIsSigned}
        pendingSignatures={Approval.DocumentSignatureSessionIsActive}
        fileName={Approval.DocumentName}
        thumbnailUrl={Approval.ThumbnailUrl}
        isSearchResults={isSearchResults}
        contentItem={Approval}
      />
    );
  }
}

ApprovalCard.propTypes = {
  Approval: PropTypes.object.isRequired,
  onCardAction: PropTypes.func.isRequired,
};

export default ApprovalCard;