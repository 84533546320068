import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
// import IconButton from '@material-ui/core/IconButton';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';

// import OrganizationIcon from '@material-ui/icons/Domain';
import AddIcon from '@material-ui/icons/Add';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import { WorkspaceIcon } from '../Util/Icons';
import MembersIcon from '@material-ui/icons/Group';
import {
  ProjectsIcon,
} from '../Util/Icons';
import SubscriptionIcon from '@material-ui/icons/Payment';

import UiCore from '../Components/UiCore';
import { GlobalContext } from '../Context/Global.context';
// import ProgressIndicator from '../Components/ProgressIndicator';
import { IsMobile } from '../Util/MobileDetector';

import ProjectCreationDialog from '../Admin/Components/ProjectCreationDialog';
import WelcomeDialog from '../Guides/WelcomeDialog';

import red from '@material-ui/core/colors/red';

import {
  GetOrganizationManagementPath,
  GetOrganizationMembersPath,
  GetOrganizationSubscriptionPath,
  GetProjectPath,
  GetProjectMembersPath,
  GetProjectTasksPath,
  GetWorkspacePath,
} from '../Util/PathHelper';

const _projectCardHeight = 96;
const styles = theme => ({
  scrollContainer: {
    overflowY:"auto",
  },
  contentContainer: {
    // background:theme.palette.background.paper,
    padding: theme.spacing(3),
    // marginTop:theme.spacing(3),
    height:"100%",
  },
  section: {
    display:"flex",
    alignItems:"center",
  },
  sectionTitle: {
    flexGrow:1,
  },
  orgCardContent: {
    display:"flex",
    flexWrap:"noWrap",
    alignItems:"center",
  },
  orgIcon: {
    fontSize:(IsMobile()) ? 24 : 36,
  },
  projectsIcon: {
    marginTop:(IsMobile()) ? 2 : 4,
    marginRight:(IsMobile()) ? 6 : 0,
    fontSize:(IsMobile()) ? 20 : 28,
  },
  projectCardContent:{
    height: (!IsMobile()) ? _projectCardHeight : undefined,
  },
  newProjectCard:{
    boxShadow:"none",
    backgroundColor:"transparent",
    borderWidth:2,
    borderStyle:"dashed",
    borderColor:theme.palette.divider,
  },
  newProjectCardContent:{
    height:(!IsMobile()) ? _projectCardHeight - 4 : undefined,
  },
  projectCardContentContainer: {
    display:"flex",
    height:(!IsMobile()) ? _projectCardHeight - 22 : undefined,
    overflowY:"hidden",
  },
  projectName: {
    marginLeft:(IsMobile()) ? theme.spacing(1) : theme.spacing(2),
  },
  hasUnreadNotificationIndicator: {
    backgroundColor:red[500],
    borderRadius:4,
    width:8,
    height:8,
    position:"absolute",
    top:22,
    left:(IsMobile()) ? 33 : 44,
    zIndex:1,
  },
});

class Home extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      ShowCreateProjectDialog: false,
      ShowOrgSelectDialog: false,
      OrgMenuAnchorEl: null,
      ShowProgressIndicatorImmediately: true,
    }
  }

  handleApiError = err => {
    this.setState({
      ApiError: err,
      ShowProgressIndicatorImmediately: false,
    });
  }

  handleSetOrgMenuVisibility = visible => event => {
    this.setState({ OrgMenuAnchorEl: (visible) ? event.currentTarget : null });
  }

  handleSetShowOrgSelectDialogVisibility = visible => {
    this.setState({
      OrgMenuAnchorEl: null,
      ShowOrgSelectDialog: visible,
    });
  }

  handleSetCreateProjectDialogVisibility = (visible) => {
    this.setState({
      ShowCreateProjectDialog: visible,
    });
  }

  handleProjectCreated = project => {
    this.handleSetCreateProjectDialogVisibility(false);
    this.context.Reset();
    if (IsMobile()) {
      this.props.history.push(GetProjectTasksPath(project.ID));
    } else {
      this.props.history.push(GetProjectMembersPath(project.ID));
    }
  }

  render() {
    const {
      ShowCreateProjectDialog,
      ShowOrgSelectDialog,
      // OrgMenuAnchorEl,
      ApiError,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      classes,
      theme,
      location,
      history,
    } = this.props;
    const {
      ApiError: userPreferencesApiError,
      CompletedGET,
      UserPreferences,
      UserOrganizations,
      OrganizationMembershipPackages,
      ProjectMembershipPackages,
      UserHasUnreadNotification,
    } = this.context;
    const {
      ActiveOrganizationName,
      ActiveOrganizationID,
      ActiveOrganizationMember,
    } = UserPreferences;

    let isOrgAdmin = false;
    let subscriptionExpired = false;
    if (CompletedGET.UserPreferences) {
      const orgPkgFinder = OrganizationMembershipPackages.filter(omp => omp.Organization.ID === ActiveOrganizationID);
      if (orgPkgFinder.length) {
        isOrgAdmin = orgPkgFinder[0].IsAdmin;
        subscriptionExpired = orgPkgFinder[0].IsExpired;
      }
    }

    // const manageOrgMenuItem = (isOrgAdmin)
    //   ? (
    //     <MenuItem onClick={() => history.push(GetOrganizationManagementOrganizationPath(ActiveOrganizationID))}>Manage this organization</MenuItem>
    //   ) : null;

    // const orgMenu = (
    //   <React.Fragment>
    //   <IconButton
    //     style={{marginRight:-20}}
    //     aria-label="Organization menu"
    //     aria-controls="organization-menu"
    //     aria-haspopup="true"
    //     onClick={this.handleSetOrgMenuVisibility(true)}
    //   >
    //     <MoreVertIcon />
    //   </IconButton>
    //   <Menu
    //     id="organization-menu"
    //     anchorEl={OrgMenuAnchorEl}
    //     keepMounted
    //     open={Boolean(OrgMenuAnchorEl)}
    //     onClose={this.handleSetOrgMenuVisibility(false)}
    //   >
    //     <MenuItem onClick={() => this.handleSetShowOrgSelectDialogVisibility(true)}>Switch to a different organization</MenuItem>
    //     {manageOrgMenuItem}
    //   </Menu>
    //   </React.Fragment>
    //   );

    const getOrgCardGridItem = (icon, name, uri, xsMobileOverride) => (
      <Grid item key={`orgGridItem_${name}`} xs={(IsMobile()) ? xsMobileOverride || 6 : undefined}>
        <Card>
          <CardActionArea onClick={() => history.push(uri)}>
            <CardContent>
              <div className={classes.orgCardContent}>
                <div style={{marginRight:theme.spacing(2)}}>
                  {icon}
                </div>
                <div>
                  <Typography variant={(IsMobile()) ? "body1" : "h6"} style={{whiteSpace:"nowrap"}}>
                    {name}
                  </Typography>
                </div>
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    );

    const hasUnreadNotificationIndicator = (UserHasUnreadNotification)
      ? (
        <div className={classes.hasUnreadNotificationIndicator} />
      ) : (<div />);
    let currentOrgGridItems = [];
    if (UserOrganizations.length) {
      currentOrgGridItems.push(
        getOrgCardGridItem(
          <div>
            {hasUnreadNotificationIndicator}
            <WorkspaceIcon className={classes.orgIcon} />
          </div>,
          "My Work", GetWorkspacePath()
        ),
      );
      if (isOrgAdmin) {
        currentOrgGridItems.push(
          getOrgCardGridItem(<MembersIcon className={classes.orgIcon} />, "Members", GetOrganizationMembersPath(ActiveOrganizationID)),
          // getOrgCardGridItem(<ProjectsIcon className={classes.orgIcon} />, "Projects", GetOrganizationProjectsPath(ActiveOrganizationID)),
          getOrgCardGridItem(<SubscriptionIcon className={classes.orgIcon} />, "Subscription", GetOrganizationSubscriptionPath(ActiveOrganizationID), 12),
        );
      }
    }

    const currentOrganizationGridItem = (currentOrgGridItems.length)
      ? (
        <Grid item key="currentOrg">
          <div className={classes.section}>
            <Typography variant="h5" className={classes.sectionTitle}>
              {(ActiveOrganizationName) ? ActiveOrganizationName : ""}
            </Typography>
            {/*<div>
              {orgMenu}
            </div>*/}
          </div>
          <Divider />
          <Grid container spacing={2} style={{marginTop:theme.spacing(1)}}>
            {currentOrgGridItems}
          </Grid>
        </Grid>
      ) : (CompletedGET.UserOrganizations)
        ? (
          <Grid item key="manageOrgs">
            <Button onClick={() => history.push(GetOrganizationManagementPath())}>
              MANAGE ORGANIZATIONS
            </Button>
          </Grid>
        )
        : null;

    const getProjectCard = p => {
      return (
        <Card>
          <CardActionArea onClick={() => history.push(GetProjectPath(p.ID))}>
            <CardContent className={classes.projectCardContent}>
              <div className={classes.projectCardContentContainer}>
                <ProjectsIcon className={classes.projectsIcon} />
                <Typography variant={(IsMobile()) ? "body1" : "h6"}
                  className={classes.projectName}
                >
                  {p.Name}
                </Typography>
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      )
    };

    const getProjectCardGridItem = (card, key) => (
      <Grid item key={key} xs={(IsMobile()) ? 12 : 4}>
        {card}
      </Grid>
    );

    const projectGridItems = [];
    if (ProjectMembershipPackages) {
      ProjectMembershipPackages.forEach(pm => {
        if (pm.ProjectMember.Disabled) {
          return;
        }
        projectGridItems.push(getProjectCardGridItem(getProjectCard(pm.Project), pm.Project.ID));
      });
    }
    
    // New Project
    const getNewProjectCard = () => {
      return (
        <Card className={classes.newProjectCard}>
          <CardActionArea onClick={() => this.handleSetCreateProjectDialogVisibility(true)}>
            <CardContent className={classes.newProjectCardContent}>
              <div style={{
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                height:"100%",
              }}>
                <AddIcon style={{
                  marginLeft:-1*theme.spacing(2),
                  marginRight:theme.spacing(1),
                  fontSize:24,
                }} />
                <Typography variant={(IsMobile()) ? "body1" : "h6"}>
                  Create a project
                </Typography>
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      );
    };

    projectGridItems.push(
      getProjectCardGridItem(getNewProjectCard(),
        "newProject"));
    const projectsGridItem = (UserPreferences && ActiveOrganizationMember && !subscriptionExpired)
      ? (
        <Grid item>
          <div className={classes.section}>
            <Typography variant="h5" className={classes.sectionTitle}>
              Projects
            </Typography>
          </div>
          <Divider style={{marginTop:theme.spacing(1)}} />
          <Grid container spacing={2} style={{marginTop:theme.spacing(1)}}>
            {projectGridItems}
          </Grid>
        </Grid>
      ) : null;

    const projectCreationDialog = (ActiveOrganizationID)
      ? (
        <ProjectCreationDialog
          organizationId={ActiveOrganizationID}
          open={ShowCreateProjectDialog || false}
          onCreated={this.handleProjectCreated}
          onClose={() => this.handleSetCreateProjectDialogVisibility(false)}
          onApiError={this.handleApiError}
        />
      ) : null;

    const welcomeDialog = (
      <WelcomeDialog
        location={location}
        promoteProjectCreation={ActiveOrganizationMember}
        onBeginCreateProject={() => this.handleSetCreateProjectDialogVisibility(true)}
      />
    );

    const content = (
      <div className={classes.scrollContainer}>
        <Container className={classes.contentContainer} maxWidth="md">
          {welcomeDialog}
          {projectCreationDialog}
          <Grid container direction="column" spacing={(IsMobile() ? 4 : 6)} style={{flexWrap:"noWrap"}}>
            {currentOrganizationGridItem}
            {projectsGridItem}
          </Grid>
        </Container>
      </div>
    );

    return (
      <UiCore
        title="Home"
        content={content}
        showOrgSelectDialog={ShowOrgSelectDialog}
        showOrgAsTitleOnDesktop
        orgAsTitlePreposition=" for "
        onOrgSelectDialogClosed={() => this.handleSetShowOrgSelectDialogVisibility(false)}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
        apiError={ApiError || userPreferencesApiError}
      />
    );
  }
}

export default withStyles(styles, {withTheme: true})(Home);
