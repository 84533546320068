import React from 'react';

import DocBreakIcon from '@material-ui/icons/InsertDriveFile';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import DeleteIcon from '@material-ui/icons/Delete';
import ZoomInIcon from '@material-ui/icons/ZoomIn';

const ImageActionType = {
	DocBreak: {
		Id: "DocBreak",
		Label: "New-document marker",
		Icon: (<DocBreakIcon />),
	},
	RotateRight: {
		Id: "RotateRight",
		Label: "Rotate right",
		Icon: (<RotateRightIcon />),
	},
	RotateLeft: {
		Id: "RotateLeft",
		Label: "Rotate left",
		Icon: (<RotateLeftIcon />),
	},
	Delete: {
		Id: "Delete",
		Label: "Delete",
		Icon: (<DeleteIcon />),
	},
	ZoomIn: {
		Id: "ZoomIn",
		Label: "Zoom in",
		Icon: (<ZoomInIcon />),
	},
};

export default ImageActionType;