import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import DashboardCard from '../DashboardCard';

import Icon from '@material-ui/icons/SpaceBar';

const styles = theme => ({
});

class SpacerWidget extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
    }
  }

  render() {
    // const {
    // } = this.state;
    const { 
      classes,
      theme,
      ...passThroughProps
    } = this.props;

    return (
      <DashboardCard
        {...passThroughProps}
        title="Spacer"
        titleIcon={<Icon />}
        gridItemStyle={{
          visibility:"hidden",
        }}
        xs={1}
        sm={1}
        md={1}
        lg={1}
        xl={1}
        noPointerEvents
      >
      </DashboardCard>
    );
  }
}

SpacerWidget.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(SpacerWidget);