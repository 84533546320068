import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IsMobile } from '../Util/MobileDetector';
import debounce from 'es6-promise-debounce';

import AsyncSelectControl from '../Components/AsyncSelectControl';
import {
  GetAddressBookItemsPromise,
} from '../Util/AddressBookItems';
import { ValidateEmail } from '../Util/Regex';
import {
  GetTagsControl,
} from '../Util/Tags';
import {
  GetUserValue,
} from '../Util/Properties';

import ProgressIndicator from './ProgressIndicator';

const styles = theme => ({
  dialogPaper: {
    // height:(!IsMobile()) ? "80%" : undefined,
    minWidth:(!IsMobile()) ? 400 : undefined,
  },
  dialogTitle: {
  },
  dialogContent: {
    // overflow:"hidden",
  },
  dialogActions: {
  },
});

class FormShareDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      RecipientListValues: [],
      TagListValues: [],
      ShowProgressIndicatorImmediately: false,
    }
  }

  handleGetAddressBookItemsPromise = debounce(filter => {
    return GetAddressBookItemsPromise(this.props.organizationId, this.props.projectId, 
      true, true, false, false, filter)
      .then(items => {
        const addressBookItems = items.map(abi => { 
          if (abi.ProjectMemberID) {
            return ({
              value: abi.EmailLower,
              label: GetUserValue(abi.EmailLower,
                (abi.Name)
                  ? `${abi.Name} (member ${abi.EmailLower})`
                  : `${abi.EmailLower} (member)`,
                "", false, undefined, {}, {}, true,
                ),
            });
          } else {
            return ({
              value: abi.EmailLower,
              label: GetUserValue(abi.EmailLower,
                (abi.Name)
                  ? `${abi.Name} (${abi.EmailLower})`
                  : abi.EmailLower,
                "", false, undefined, {}, {}, true,
                ),
            });
          }
        });

        let RecipientListValues = [...this.state.RecipientListValues];
        RecipientListValues.forEach(lv => {
          // Attempt to update list values where label and value are both the email.
          if (lv.value === lv.label) {
            const addressBookItemFinder = addressBookItems.filter(abi => abi.value === lv.value);
            if (addressBookItemFinder.length) {
              lv.label = addressBookItemFinder[0].label;
            }
          }
        });
        this.setState({RecipientListValues});
        return addressBookItems;
      })
      .catch(this.handleApiError);
  }, 250);

  handleAddRecipientListValues = emailOrListValues => {
    let RecipientListValues = [];
    if (Array.isArray(emailOrListValues)) {
      RecipientListValues = emailOrListValues;
    } else if (typeof emailOrListValues === "string") {
      RecipientListValues = [...this.state.RecipientListValues]
        .concat({ value: emailOrListValues, label: emailOrListValues });
    }
    if (this.validateEmailsToAdd(RecipientListValues)) {
      this.setState({ RecipientListValues });
    }
  }

  validateEmailsToAdd = emailsToAdd => {
    for (let i = 0; i < emailsToAdd.length; i++) {
      let email = emailsToAdd[i].value;
      if (!ValidateEmail(email)) {
        this.handleApiError(`${email} is not a valid e-mail address.`);
        return false;
      }
    }
    return true;
  }

  handleShare = () => {
    const recipientEmails = [...this.state.RecipientListValues].map(rlv => rlv.value);
    const tags = [...this.state.TagListValues]
      .filter(tlv => !tlv.AssetItemTag || !tlv.AssetItemTag.AssetItemID)
      .map(tlv => tlv.value);
    const assetItemTags = [...this.state.TagListValues]
      .filter(tlv => tlv.AssetItemTag && tlv.AssetItemTag.AssetItemID)
      .map(tlv => tlv.AssetItemTag);
    this.props.onAction(recipientEmails, tags, assetItemTags);
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleApiError = err => {
    this.setState({ShowProgressIndicatorImmediately: false});
    this.props.onApiError(err);
  }

  render() {
    const {
      open,
      RecipientListValues,
      TagListValues,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      classes,
      // theme,
      formTemplates,
    } = this.props;

    let dialogActions = (
      <DialogActions className={classes.dialogActions}>
        <Button onClick={() => this.handleClose()}>
          CLOSE
        </Button>
        <Button 
          onClick={() => this.handleShare()}
          disabled={!RecipientListValues.length}>
          SHARE
        </Button>
      </DialogActions>
    );

    let progressIndicator = null;
    if (ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    const tagsControl = (formTemplates && formTemplates.length)
      ? GetTagsControl(formTemplates[0].OrganizationID, formTemplates[0].ProjectID, null, 
          false, false, null, true,
          TagListValues, TagListValues => this.setState({TagListValues}),
          () => {}, false, -1, "Tags for form submission", false
        ) : null;

    return (
      <Dialog
        fullScreen={IsMobile()}
        fullWidth={!IsMobile()}
        maxWidth="sm"
        open={open}
        classes={{
          paper:classes.dialogPaper,
        }}
        onClose={() => this.handleClose()}
        // aria-labelledby="dialog-title"
        // aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title" className={classes.dialogTitle}>
          {`Share form${(formTemplates.length === 1) ? " " + formTemplates[0].Name : "s"}`}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {progressIndicator}
          <Grid container spacing={3} direction="column" style={{marginBottom:0}}>
            <Grid item>
              <AsyncSelectControl label="Share with people" 
                // floatingOptions
                isMulti
                onCreateOption={this.handleAddRecipientListValues}
                allowCreateWhileLoading
                autoReloadOnValueChange
                onGetOptionsFilterPromise={this.handleGetAddressBookItemsPromise}
                listValues={RecipientListValues}
                onValueChange={this.handleAddRecipientListValues}
              />
            </Grid>
            <Grid item>
              {tagsControl}
            </Grid>
          </Grid>
        </DialogContent>
        {dialogActions}
      </Dialog>
    );
  }
}

FormShareDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  formTemplates: PropTypes.array.isRequired,
  onAction: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onApiError: PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(FormShareDialog);