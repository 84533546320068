import API, {
  GetFormTemplateActionsPathForApi,
  ProcessApiCallsGroupedByProjectForItems,
} from '../Util/api';

export function ShareFormTemplates(formTemplates, RecipientEmails, Tags, AssetItemTags) {
  return ProcessApiCallsGroupedByProjectForItems(formTemplates, 
    itemsByProject => API.post(
      `${GetFormTemplateActionsPathForApi(itemsByProject.OrganizationID, itemsByProject.ProjectID)}/share`,
      {
        IDs: itemsByProject.Items.map(i => i.ID),
        RecipientEmails,
        Tags,
        AssetItemTags,
      }
    )
  );
}