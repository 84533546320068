import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
// import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import API, {
  GetFieldsPathForApi,
} from '../Util/api';
import ProgressIndicator from '../Components/ProgressIndicator';

import { IsMobile } from '../Util/MobileDetector';

import {
  GetNewTextField,
} from '../Model/Field';

const styles = theme => ({
  card: {
    backgroundColor: theme.palette.type === "dark" ? "#222" : "#eee",
  },
  cardContent: {
    minHeight: 80,
    minWidth: 180,
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    position:"relative",
  },
  selectedCard: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
  },
  infoIcon: {
    position:"absolute",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    opacity:0.5,
  },
});

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="left" ref={ref} {...props} />;
// });

class FieldCreationDialog extends Component {
  constructor(props) {
    super(props);
    
    this.initialState = {
      ShowProgressIndicatorImmediately: false,
      Name: "",
    };

    this.state = {
      ...this.initialState,
      open: props.open,
    }
  }

  setInitialState = () => {
    this.setState(this.initialState);
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
    this.setInitialState();
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleCreate = () => {
    if (!this.state.Name) {
      return;
    }

    this.setState({ShowProgressIndicatorImmediately: true});
    
    const newField = GetNewTextField(this.state.Name);
    if (this.props.onBeginCreate) {
      this.props.onBeginCreate(newField);
    }

    API.post(GetFieldsPathForApi(this.props.organizationId, this.props.projectId), [newField])
      .then(resp => {
        if (this.props.onCreated) {
          this.props.onCreated(resp.data[0]);
        }
        this.setInitialState();
      })
      .catch(this.handleApiError);
  }

  handleApiError = (err, title_optional) => {
    this.setState({
      ShowProgressIndicatorImmediately: false,
    });
    this.props.onApiError(err, title_optional);
    if (err) {
      setTimeout(() => this.props.onApiError(null), 1);
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      this.setState({open: this.props.open !== false});
    }
  }

  render() {
    const { 
      open,
      Name,
      ShowProgressIndicatorImmediately,
  	} = this.state;
    const {
      // classes,
      theme,
      projectName,
    } = this.props;

    let progressIndicator = null;
    if (ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    let dialogActions = (
      <DialogActions>
        <Button onClick={() => this.handleClose()}>
          CLOSE
        </Button>
        <Button onClick={() => this.handleCreate()}>
          CREATE
        </Button>
      </DialogActions>
    );

    let content = (
      <div style={{marginTop:theme.spacing(1)}}>
        <TextField
          fullWidth
          autoFocus
          variant="outlined"
          label="Name"
          onChange={e => this.setState({Name: e.target.value})}
          value={Name}
          onKeyDown={e => { if (e.keyCode === 13) { this.handleCreate(); } }}
        />
      </div>
    );

    const dialogTitle = `Create a field${(projectName) ? " in " + projectName : ""}`;

    return (
       <Dialog
        fullWidth={!IsMobile()}
        fullScreen={IsMobile()}
        maxWidth="sm"
        // TransitionComponent={Transition}
        // PaperProps={{
        //   style:{
        //     minHeight:"50%",
        //   }
        // }}
        open={open}
        onClose={() => this.handleClose()}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description">
        <DialogTitle id="dialog-title">
          {dialogTitle}
        </DialogTitle>
        <DialogContent style={{paddingTop:0}}>
          {progressIndicator}

          {content}
        </DialogContent>

        {dialogActions}
      </Dialog>
    );
  }
}

FieldCreationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  projectName: PropTypes.string,
  onBeginCreate: PropTypes.func,
  onCreated: PropTypes.func,
  onApiError: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default withStyles(styles, {withTheme: true})(FieldCreationDialog);