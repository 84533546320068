import API, {
  GetTaskDocumentsPathForApi,
} from './api';

export const AddTaskDocuments = (organizationId, projectId, taskId, documents) => {
  return API.post(GetTaskDocumentsPathForApi(organizationId, projectId, taskId), { DocumentIDs: documents.map(d => d.ID) });
}

export const RemoveTaskDocuments = (organizationId, projectId, taskId, IDs) => {
  return API.delete(GetTaskDocumentsPathForApi(organizationId, projectId, taskId), { data: { IDs } });
}