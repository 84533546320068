import Collection from '../Model/Collection';

import { 
  GetSearchResultHeadCells,
  GetSearchResultCardGridItems,
  GetSearchResultTableRows,
} from '../Util/SearchResults';

import SimpleSearchResultsCollectionFields from '../Model/SimpleSearchResultsCollectionFields';

import {
  GetOrganizationSearchResultsPathForApi,
  GetUserOrganizationSearchResultsPathForApi,
} from '../Util/api';

export class SimpleSearchResults_Collection extends Collection {
  constructor(props, context, onSetState, onApiError) {
    super(props, onSetState, onApiError);

    this.PageTitle = "Search results";
    this.ContentUri = (context.UserPreferences.ActiveOrganizationMember)
      ? GetOrganizationSearchResultsPathForApi(context.UserPreferences.ActiveOrganizationID)
      : GetUserOrganizationSearchResultsPathForApi(context.UserPreferences.ActiveOrganizationID);
    this.CollectionName = "SearchResults";
    this.ItemsName = "SearchResults";
    this.ItemName = "Search Result";
    this.DefaultViewType = "Card";
    this.AllowSelect = false;
    // this.HideSensitiveFields = true;

    this.context = context;
  }

  HandleGetCollectionFieldsPromise = () => Promise.resolve(SimpleSearchResultsCollectionFields);

  HandleGetHeadCells = (items, sensitiveFields) => {
    return GetSearchResultHeadCells(items, sensitiveFields, true, false, null, true);
  }

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, selectedIDs, 
    onAction, postActionData, sortType, sortDescending, useSingleColumnLayout, cardStyle) => {
    return GetSearchResultCardGridItems(this.props, this.context, true, false)
      (items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction,
        postActionData, sortType, sortDescending, cardStyle);
  }

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return GetSearchResultTableRows(this.props, this.context, true, true, false, null, true)
      (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs, sortType, sortDescending);
  }
}