import API, {
  GetUserOrganizationNotificationActionsPathForApi,
} from '../Util/api';

export const MarkUserNotificationsRead = (context, userNotifications, organizationId) => {
  return API.post(`${GetUserOrganizationNotificationActionsPathForApi(organizationId)}/markRead`,
    { IDs: userNotifications.map(i => i.ID) }
  )
    .then(resp => {
      context.GetUserHasUnreadNotification(organizationId);
      return resp;
    });
}

export const MarkUserNotificationsUnread = (context, userNotifications, organizationId) => {
  return API.post(`${GetUserOrganizationNotificationActionsPathForApi(organizationId)}/markUnread`,
    { IDs: userNotifications.map(i => i.ID) }
  )
    .then(resp => {
      context.GetUserHasUnreadNotification(organizationId);
      return resp;
    });
}

export const ClearUserNotifications = (context, userNotifications, organizationId) => {
  return API.post(`${GetUserOrganizationNotificationActionsPathForApi(organizationId)}/clear`,
    { IDs: userNotifications.map(i => i.ID) }
  )
    .then(resp => {
      context.GetUserHasUnreadNotification(organizationId);
      return resp;
    });
}