import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import SubscribedIcon from '@material-ui/icons/Bookmark';

import Card from './Card';

import { 
  GetFileSizePageCountSpan,
  GetDocumentSubscription,
} from '../Util/Document';
// import { 
//   GetCardMetadataFromPreviewMetadata,
// } from '../Util/Fields';
import {
  GetUserValue,
  GetDateValue,
} from '../Util/Properties';
import ActionType from '../Model/ActionType';

import orange from '@material-ui/core/colors/orange';

class NeedSignatureCard extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      DocumentSubscription: null,
    }
  }

  loadDocumentSubscription = () => {
    GetDocumentSubscription(this.props.organizationId, this.props.projectId,
      this.props.DocumentSignatureSessionRecipient.DocumentID)
      .then(DocumentSubscription => this.setState({DocumentSubscription}));
  }

  handleSubscribeAction = (actionType, id) => {
    if (!this.props.onAction) {
      return;
    }
    this.props.onAction(actionType, id, true)
      .then(documentSubscriptions => {
        if (documentSubscriptions && documentSubscriptions.length) {
          this.setState({DocumentSubscription: documentSubscriptions[0]});
        } else {
          this.loadDocumentSubscription();
        }
      });
  }

  handlePostActionData = data => {
    // Updated subscription
    if (data.DocumentSubscriptions) {
      let subscriptionFinder = data.DocumentSubscriptions.filter(ds => ds.DocumentID === this.props.DocumentSignatureSessionRecipient.DocumentID);
      if (subscriptionFinder.length) {
        this.setState({DocumentSubscription: subscriptionFinder[0]});
      }
    }
  }

  componentDidMount() {
    if (this.props.allowSubscriptions) {
      this.loadDocumentSubscription();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.postActionData
      && this.props.postActionData !== prevProps.postActionData) {
      this.handlePostActionData(this.props.postActionData);
    }
  }

  render() {
    const {
      DocumentSubscription,
    } = this.state;
    const { 
      DocumentSignatureSessionRecipient,
      // sensitiveFields,
      noSelect,
      selected,
      onSelect,
      onAction,
      onCardAction,
      disableCardActions,
      style,
      isSearchResults,
      showDocumentFields,
    } = this.props;

    let metadata = [
      ["", GetFileSizePageCountSpan(DocumentSignatureSessionRecipient.DocumentFileSize
        || DocumentSignatureSessionRecipient.FileSize, 
        DocumentSignatureSessionRecipient.DocumentPageCount
        || DocumentSignatureSessionRecipient.PageCount)],
      ["Project", DocumentSignatureSessionRecipient.ProjectName],
      ["Requested On", GetDateValue(DocumentSignatureSessionRecipient.RequestedOn
        || DocumentSignatureSessionRecipient.CreatedOn)],
      ["Assigned to", GetUserValue(DocumentSignatureSessionRecipient.AssignmentUserEmail,
        DocumentSignatureSessionRecipient.AssignmentUserName, "sm")],
    ];

    if (showDocumentFields) {
      // metadata.push(...GetCardMetadataFromPreviewMetadata(DocumentSignatureSessionRecipient.PreviewMetadata, sensitiveFields));
    }

    let subscriptionIconButton = (DocumentSubscription)
      ? (
        (DocumentSubscription.Subscribed)
          ? (
            <IconButton
              key={ActionType.Subscription_Delete.Id}
              aria-label={ActionType.Subscription_Delete.Label}
              title={ActionType.Subscription_Delete.Label}
              onClick={() => this.handleSubscribeAction(ActionType.Subscription_Delete, DocumentSignatureSessionRecipient.ID)}
              >
              <SubscribedIcon style={{color:orange[500]}} />
            </IconButton> 
          )
          : (
            <IconButton
              key={ActionType.Subscription_Create.Id}
              aria-label={ActionType.Subscription_Create.Label}
              title={ActionType.Subscription_Create.Label}
              onClick={() => this.handleSubscribeAction(ActionType.Subscription_Create, DocumentSignatureSessionRecipient.ID)}
              >
              {ActionType.Subscription_Create.Icon}
            </IconButton> 
          )
      ) : null;
    let downloadIconButton = (
      <IconButton
        key={ActionType.Download.Id}
        aria-label={ActionType.Download.Label}
        title={ActionType.Download.Label}
        onClick={() => onAction(ActionType.Download, DocumentSignatureSessionRecipient.ID)}
        >
        {ActionType.Download.Icon}
      </IconButton>
    );

    return (
      <Card
        style={style}
        onCardAction={onCardAction}
        noSelect={noSelect}
        onSelect={onSelect}
        selected={selected}
        height={256}
        pendingSignatures
        name={DocumentSignatureSessionRecipient.DocumentName || DocumentSignatureSessionRecipient.Name}
        fileName={DocumentSignatureSessionRecipient.DocumentName || DocumentSignatureSessionRecipient.Name}
        thumbnailUrl={DocumentSignatureSessionRecipient.DocumentThumbnailUrl
          || DocumentSignatureSessionRecipient.ThumbnailUrl}
        metadata={metadata}
        hoverActionItems={!disableCardActions
          ? [
            subscriptionIconButton,
            downloadIconButton,
          ]
          : undefined
        }
        isSearchResults={isSearchResults}
        contentItem={DocumentSignatureSessionRecipient}
      />
    );
  }
}

NeedSignatureCard.propTypes = {
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  DocumentSignatureSessionRecipient: PropTypes.object.isRequired,
  onCardAction: PropTypes.func.isRequired,
  allowSubscriptions: PropTypes.bool,
  disableCardActions: PropTypes.bool,
}

export default NeedSignatureCard;