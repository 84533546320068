import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Card from '../../Components/Card';

import {
  GetDateValue,
  GetUserValue,
} from '../../Util/Properties';

class UserCard extends Component {
  render() {
    const {
      User,
    } = this.props; 
    
    let metadata = [];
    
    if (User.Name && User.Email) {
      metadata.push(["Email", User.Email]);
    }

    metadata.push(
      ["Created On", GetDateValue(User.CreatedOn)],
      ["Last Sign In", GetDateValue(User.LastSignIn)],
      ["Last Activity", GetDateValue(User.LastActivity)],
      ["Sign-in Provider", User.Provider],
    );

    return (
      <Card
        name={GetUserValue(User.Email, User.Name, "lg")}
        metadata={metadata}
      />
    );
  }
}

UserCard.propTypes = {
  User: PropTypes.object.isRequired,
  // onCardAction: PropTypes.func.isRequired,
};

export default UserCard;