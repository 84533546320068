import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Card from '../../Components/Card';

import {
  GetBoolValue,
  GetDateValue,
  GetUserValue,
} from '../../Util/Properties';

import red from '@material-ui/core/colors/red';

class OrganizationMemberCard extends Component {
  render() {
    const {
      OrganizationMember,
      style,
      height,
      onCardAction,
      onSelect,
      selected,
      isSearchResults,
    } = this.props; 
   
    let metadata = [];

    if (OrganizationMember.UserEmail && OrganizationMember.UserName) {
      metadata.push(["Email", OrganizationMember.UserEmail]);
    }

    metadata.push(["Added", GetDateValue(OrganizationMember.CreatedOn)]);

    if (OrganizationMember.IsAdmin) {
      metadata.push(["", "Organization Admin"]);
    }

    if (OrganizationMember.Disabled) {
      metadata.push(["Disabled", GetBoolValue(true), { color: red[500], }]);
    }
   
    return (
      <Card
        style={style}
        height={height}
        onCardAction={onCardAction}
        onSelect={onSelect}
        selected={selected}
        minHeight={132}
        name={GetUserValue(OrganizationMember.UserEmail, OrganizationMember.UserName, "lg")}
        metadata={metadata}
        isSearchResults={isSearchResults}
        contentItem={OrganizationMember}
      />
    );
  }
}

OrganizationMemberCard.propTypes = {
  OrganizationMember: PropTypes.object.isRequired,
  onCardAction: PropTypes.func.isRequired,
};

export default OrganizationMemberCard;