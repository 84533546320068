import React from 'react';
import { Helmet } from 'react-helmet';

const TitleComponent = ({ title, hideNucleusOne }) => {
  return (
    <Helmet>
      <title>{`${(title) ? title : ""}${(!hideNucleusOne) ? (title) ? " | Nucleus One" : "Nucleus One" : ""}`}</title>
    </Helmet>
  );
};

export default TitleComponent;