import Tooltip from '@material-ui/core/Tooltip';

import AsyncSelectControl from '../Components/AsyncSelectControl';

import API, {
  GetTagsPathForApi,
  GetUserOrganizationProjectTaskTagsPathForApi,
  GetUserOrganizationProjectApprovalTaskTagsPathForApi,
} from '../Util/api';

import {
  GetProjectAssetItemPath,
} from '../Util/PathHelper';

import debounce from 'es6-promise-debounce';

export const GetTagsControl = (organizationId, projectId, taskId, isTaskAssignment,
  isApprovalTaskAssignment, approvalId, includeAssetItems,
  stateTagListValues, onSetTagListValuesState, onTagListValuesChanged,
  disabled, tabIndex, labelOverride, floatingOptions, key_optional) => {
	
  const onCanClickValue = value => {
    return (value && value.AssetItemTag && value.AssetItemTag.AssetID && value.AssetItemTag.AssetItemID);
  }

  const onValueClick = (e, value) => {
    if (onCanClickValue(value)) {
      window.open(GetProjectAssetItemPath(projectId, value.AssetItemTag.AssetID, value.AssetItemTag.AssetItemID));
    }
  }

  return (
    <AsyncSelectControl label={labelOverride || "Tags"} 
      floatingOptions={floatingOptions}
      isMulti
      key={key_optional}
      tabIndex={tabIndex}
      notClearable
      onCanClickValue={onCanClickValue}
      onValueClick={onValueClick}
      disabled={disabled}
      onCreateOption={tagOrListValues => handleAddTagListValues(tagOrListValues, stateTagListValues,
        onSetTagListValuesState, onTagListValuesChanged)}
      allowCreateWhileLoading
      autoReloadOnValueChange
      onGetOptionsFilterPromise={debounce(filter => {
        if (disabled) {
          return Promise.resolve([]);
        }
        return GetTagOptionsPromise(organizationId, projectId, taskId, isTaskAssignment,
          isApprovalTaskAssignment, approvalId, includeAssetItems, filter);
      }, 250)}
      listValues={stateTagListValues}
      onValueChange={tagOrListValues => handleAddTagListValues(tagOrListValues, stateTagListValues, 
        onSetTagListValuesState, onTagListValuesChanged)}
    />
  )
}

const handleAddTagListValues = (tagOrListValues, stateTagListValues, onSetTagListValuesState,
  onTagListValuesChanged) => {

  let tagListValues = [];
  if (Array.isArray(tagOrListValues)) {
    tagListValues = tagOrListValues;
  } else {
    tagListValues = [...stateTagListValues];
    if (typeof tagOrListValues === "string") {
      const tag = tagOrListValues.trim();
      if (tag && !tagListValues.filter(tlv => tlv.value === tag).length) {
        tagListValues.push({ value: tag, label: tag, });
      }
    }
  }

  // Limit text tags to 50 characters
  const TagMaxLength = 50;
  let TagListValues = [];
  tagListValues.forEach(tlv => {
    if (tlv.AssetItemTag && tlv.AssetItemTag.AssetItemID) {
      TagListValues.push(tlv);
    } else {
      if (tlv.value && tlv.value.trim().length > TagMaxLength) {
        tlv.label = tlv.label.trim().substr(0, TagMaxLength);
        tlv.value = tlv.value.trim().substr(0, TagMaxLength)
        TagListValues.push(tlv);
      } else {
        TagListValues.push(tlv);
      }
    }
  });

  onSetTagListValuesState(TagListValues);
  onTagListValuesChanged(TagListValues);
}

export const GetTagOptionsPromise = (organizationId, projectId, taskId, isTaskAssignment,
  isApprovalTaskAssignment, approvalId, includeAssetItems, filter) => {

  const params = {
    filter,
    includeAssetItems,
  };
  if (!projectId || (isTaskAssignment && !taskId)) {
    return Promise.resolve([]);
  }
  const uri = (isApprovalTaskAssignment)
    ? GetUserOrganizationProjectApprovalTaskTagsPathForApi(organizationId, projectId, approvalId, taskId)
    : (isTaskAssignment)
      ? GetUserOrganizationProjectTaskTagsPathForApi(organizationId, projectId, taskId)
      : GetTagsPathForApi(organizationId, projectId);
  return API.get(uri, { params })
    .then(resp => {
      if (!resp || !resp.data || !resp.data.Tags) {
        return [];
      }
      return getTagOptionsFromServerTags(resp.data.Tags);
    });
};

export const getTagOptionsFromServerTags = serverTags => {
  let options = [];
  serverTags.forEach(serverTag => {
    const AssetItemTag = serverTag.AssetItemTag;
    if (AssetItemTag && AssetItemTag.AssetItemID) {
      if (AssetItemTag.AssetItemName) {
        options.push({
          label: getLabelForAssetItemTag(AssetItemTag),
          value: AssetItemTag.AssetItemID,
          AssetItemTag,
        });
      }
    } else {
      if (serverTag.Text) {
        options.push({
          label: serverTag.Text,
          value: serverTag.Text,
        });
      }
    }
  });
  return options;
}

export const getLabelForAssetItemTag = assetItemTag => {
  return `${
      (assetItemTag.AssetItemName) ? assetItemTag.AssetItemName : "Unnamed asset item"
    } ${
      (assetItemTag.AssetName) ? " (" + assetItemTag.AssetName + ")" : ""
    }`;
}

export const GetTagListValuesFromTagsObject = (tagsObject, tagsPropertyPrefix) => {
  if (!tagsObject) {
    return [];
  }
  let options = [];
  const assetItemTagsPropertyName = ((tagsPropertyPrefix) ? tagsPropertyPrefix : "") + "AssetItemTags";
  if (tagsObject[assetItemTagsPropertyName]) {
    tagsObject[assetItemTagsPropertyName].forEach(AssetItemTag => {
      if (AssetItemTag.AssetItemID) {
        options.push({
          label: getLabelForAssetItemTag(AssetItemTag),
          value: AssetItemTag.AssetItemID,
          AssetItemTag,
        });
      }
    });
  }
  const tagsPropertyName = ((tagsPropertyPrefix) ? tagsPropertyPrefix : "") + "Tags";
  if (tagsObject[tagsPropertyName]) {
    tagsObject[tagsPropertyName].forEach(tag => {
      options.push({
        label: tag,
        value: tag,
      });
    });
  }
  return options;
}

export const GetTagsPreviewFromTagsObject = (tagsObject, reverseSort) => {
  if (!tagsObject) {
    return [];
  }
  let tagsPreview = [];
  if (tagsObject.AssetItemTags) {
    tagsObject.AssetItemTags.forEach(assetItemTag => {
      if (assetItemTag.AssetItemID) {
        tagsPreview.push(getLabelForAssetItemTag(assetItemTag));
      }
    });
  }
  if (tagsObject.Tags) {
    tagsObject.Tags.forEach(tag => {
      tagsPreview.push(tag);
    });
  }
  tagsPreview.sort((a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  });
  if (reverseSort) {
    tagsPreview.reverse();
  }
  return tagsPreview.join(", ");
}

export const GetTagsAndAssetItemTagsFromTagListValues = tagListValues => {
  let Tags = [];
  let AssetItemTags = [];
  tagListValues.forEach(tlv => {
    if (tlv.AssetItemTag && tlv.AssetItemTag.AssetItemID) {
      AssetItemTags.push(tlv.AssetItemTag);
    } else {
      const tag = tlv.value.trim();
      if (tag) {
        Tags.push(tag);
      }
    }
  });
  return { Tags, AssetItemTags };
}

export const PostTagsFromListValues = (organizationId, projectId, tags, onApiError) => {
  if (!tags || !tags.length) {
    return Promise.resolve();
  }
  const textTags = tags
    .filter(t => t.value.length && !t.AssetItemTag)
    .map(t => ({Text: t.value}));
  if (!textTags.length) {
    return Promise.resolve();
  }
  return API.post(GetTagsPathForApi(organizationId, projectId), textTags)
    .catch(onApiError);
}

export const GetTagsForList = (tagsObject, sortType, sortDescending) => {
  if (!tagsObject) {
    return "";
  }
  const tagsPreview = GetTagsPreviewFromTagsObject(tagsObject, 
    sortType === "Meta_text_kw50lc[Tag].keyword" && sortDescending);
  if (tagsPreview.length > 10) {
    return (
      <Tooltip title={tagsPreview}><span>{`${tagsPreview.substr(0, 10)}...`}</span></Tooltip>
    );
  }
  return tagsPreview;
}