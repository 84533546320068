import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import GetMoreButton from '../Util/GetMoreButton';
import ProgressIndicator from '../Components/ProgressIndicator';

import API from '../Util/api';
import { IsMobile } from '../Util/MobileDetector';
import { UniqueIdDebouncer } from '../Util/Debounce';

import {
  GetFieldsPathForApi,
} from '../Util/api';
import {
  UpdateFieldNoDebounce,
} from '../Util/Field';

const styles = theme => ({
  dialogPaper: {
    height:(!IsMobile()) ? "80%" : undefined,
  },
  contentContainer: {
    marginTop:theme.spacing(2),
  },
});

class TagDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      Fields: [],
      FieldsCursor: "",
      ShowGetMoreFields: false,
      ShowProgressIndicatorImmediately: false,
    }

    this.FieldDebouncer = new UniqueIdDebouncer(250);
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleLoadFields = reset => {
    this.setState({ShowProgressIndicatorImmediately:true});
    const params = {
      cursor: (reset) ? "" : this.state.FieldsCursor,
    };
    API.get(GetFieldsPathForApi(this.props.organizationId, this.props.projectId), { params })
      .then(resp => {
        let stateToUpdate = {
          FieldsCursor: resp.data.Cursor,
          ShowGetMoreFields: resp.data.Fields.length >= resp.data.PageSize,
        };
        if (reset) {
          stateToUpdate.Fields = resp.data.Fields;
        } else {
          stateToUpdate.Fields = [...this.state.Fields]
            .concat(resp.data.Fields);
        }
        this.setState(stateToUpdate);
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ShowProgressIndicatorImmediately:false});
      });
  }

  getFieldIncludesDocumentTag = field => {
    return field.DocumentTags && field.DocumentTags
      .map(t => t.toLowerCase())
      .includes(this.props.Tag.Text.toLowerCase());
  }

  addTagToField = field => {
    if (!field.DocumentTags) {
      field.DocumentTags = [];
    }
    if (!this.getFieldIncludesDocumentTag(field)) {
      field.DocumentTags.push(this.props.Tag.Text);
    }
  }

  removeTagFromField = field => {
    field.DocumentTags = field.DocumentTags
      .filter(t => t.toLowerCase() !== this.props.Tag.Text.toLowerCase());
  }

  handleUpdateTagDocumentFieldIsMapped = (fieldId, isMapped) => {
    let Fields = [...this.state.Fields];
    const field = Fields.find(f => f.ID === fieldId);
    if (!field) {
      return;
    }
    if (isMapped) {
      this.addTagToField(field);
    } else {
      this.removeTagFromField(field);
    }
    this.setState({Fields});
    this.FieldDebouncer.debounceByUniqueId(
      fieldId,
      () => UpdateFieldNoDebounce(this.props.organizationId, this.props.projectId, field),
    );
  }

  handleApiError = err => {
    this.setState({ShowProgressIndicatorImmediately: false});
    this.props.onApiError(err);
  }

  componentDidMount() {
    this.handleLoadFields();
  }

  render() {
    const {
      open,
      Fields,
      ShowGetMoreFields,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      classes,
      theme,
      Tag,
      showProgressIndicatorImmediately,
    } = this.props;
   
    const dialogActions = (
      <DialogActions>
        <Button onClick={() => this.handleClose()}>
          CLOSE
        </Button>
      </DialogActions>
    );

    const progressIndicator = (ShowProgressIndicatorImmediately || showProgressIndicatorImmediately)
      ? (
        <ProgressIndicator showImmediately />
      )
      : null;

    let tagDocumentFieldGridItems = [];
    if (Fields && Fields.length) {
      Fields.forEach(f => {
        const gridItem = (
          <Grid item key={`f${f.ID}`}>
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  checked={this.getFieldIncludesDocumentTag(f)}
                  onClick={e => e.stopPropagation()}
                  onChange={e => this.handleUpdateTagDocumentFieldIsMapped(f.ID, e.target.checked)} 
                />
              }
              label={`${f.Name}${(f.Label) ? " (" + f.Label + ")" : ""}`} /> 
          </Grid>
        );
        tagDocumentFieldGridItems.push(gridItem);
      });
    }

    if (ShowGetMoreFields) {
      tagDocumentFieldGridItems.push(GetMoreButton(true, () => this.handleLoadFields(), "", theme));
    }

    const tagDocumentFields = (
      <div>
        <Typography variant="subtitle2" style={{marginTop:theme.spacing(3)}}>
          Document fields
        </Typography>

        <Grid container direction="column" spacing={2}>
          {tagDocumentFieldGridItems}
        </Grid>
      </div>
    );

    return (
      <Dialog
        fullScreen={IsMobile()}
        fullWidth={!IsMobile()}
        maxWidth="sm"
        open={open}
        classes={{
          paper:classes.dialogPaper,
        }}
        onClose={() => this.handleClose()}
        // aria-labelledby="dialog-title"
        // aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">
          {Tag.Text}
        </DialogTitle>
        <DialogContent>
          {progressIndicator}
          
          <div className={classes.contentContainer}>
            {tagDocumentFields}
          </div>

        </DialogContent>
        {dialogActions}
      </Dialog>
    );
  }
}

TagDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  Tag: PropTypes.object,
  showProgressIndicatorImmediately: PropTypes.bool,
  onClose: PropTypes.func,
  onApiError: PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(TagDialog);