import {
  GetProjectMemberPath,
} from '../Util/PathHelper';

export const HandleRouteToProjectMember = (props, projectId, projectMemberId, openInNewTab) => {
  const uri = GetProjectMemberPath(projectId, projectMemberId);
  if (openInNewTab) {
    window.open(uri, "n1_projectMemberContent")
  } else {
    props.history.push(uri, { ...props.location.state });  
  }
}