import ProcessElementDialogHelper from '../../Util/ProcessElementDialogs';

export default function GetContent(location, history, organizationId, projectId, processElement, processElementConnections_Input,
  onGetLocalState, onSetLocalState, onGetLocalProperty, onSetLocalProperty,
  onApiError, onAlert, onGetListValuesAndLabelsPropertyName, onChangeHandlers,
  onSelectionListValueChange, onSelectionListCreateOption, onSetCloseDialogFunc) {
    
    const dh = new ProcessElementDialogHelper(location, history, organizationId, projectId, processElement, processElementConnections_Input,
      onGetLocalState, onSetLocalState, onGetLocalProperty, onSetLocalProperty, onApiError, onGetListValuesAndLabelsPropertyName, onChangeHandlers,
      onSelectionListValueChange, onSelectionListCreateOption, onSetCloseDialogFunc);

    return dh.GetTriggerContentForTags();
  }