import React, {Component} from 'react';

import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import HidePaneIcon from '@material-ui/icons/NavigateBefore';
import ShowPaneIcon from '@material-ui/icons/NavigateNext';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    height:"100%",
    position:"relative",
  },
  pane: {
    backgroundColor: theme.palette.background.pane,
    height:"100%",
    overflowY:"auto",
    position:"relative",
    transition: "margin 0.2s",
    minWidth:300,
    maxWidth:400,
    [theme.breakpoints.up('md')]: {
      maxWidth: 500,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 600,
    },
    zIndex:1201,
  },
  paneInner: {
    padding:theme.spacing(3),
    height:"100%", // This was added for the projects screen. It might need to be relocated there, sent here via paneInnerStyle property.
  },
  pane_visible: {
    marginLeft:0,
  },
  pane_hidden: {
    // This is now handled via a ref and offsetWidth
    // marginLeft:-300,
  },
  settingsDiv: {
    position:"absolute",
    right:theme.spacing(1),
    top:theme.spacing(1),
  },
  hidePaneButton: {
    position:"absolute",
    bottom:7,
    right:theme.spacing(1),
    zIndex: 1203,
  },
  showPaneButton: {
    opacity:0,
    height:42,
    width:24,
    position:"absolute",
    bottom:theme.spacing(1),
    left:0,
    display:"flex",
    alignItems:"center",
    justifyContent:"flex-end",
    backgroundColor:"#ddd",
    boxShadow: "1px 1px 2px 0px #808080",
    borderTopRightRadius:50,
    borderBottomRightRadius:50,
    "&:hover": {
      width:48,
    },
    transition: "width 0.2s, opacity 0.2s",
    cursor:"pointer",
    "&:hover $showPaneIcon": {
      marginRight:8,
    },
    zIndex:1202,
  },
  showPaneIcon: {
    color:"#666",
    marginRight:4,
    transition: "margin 0.2s",
  },
});

class CollapsibleLeftPane extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      PaneIsVisible: true,
    }

    this.PaneRef = React.createRef();
  }

  handleSetPaneVisibility = PaneIsVisible => {
    this.setState({ PaneIsVisible });
    if (this.props.onVisibilityChange) {
      this.props.onVisibilityChange(PaneIsVisible);
    }
  }

  componentDidMount() {
    if (this.props.onVisibilityChange) {
      this.props.onVisibilityChange(this.state.PaneIsVisible);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.forceShow !== this.props.forceShow && this.props.forceShow) {
      this.handleSetPaneVisibility(true);
    }

    if (prevProps.forceHide !== this.props.forceHide && this.props.forceHide) {
      this.handleSetPaneVisibility(false);
    } 
  }

  render() {
    const {
      PaneIsVisible,
    } = this.state;
    const {
      classes,
      children,
      onSettingsClick,
      settingsAriaLabel,
      paneInnerStyle,
      paneStyle,
    } = this.props;

    let settingsButton = null;
    if (onSettingsClick) {
      settingsButton = (
        <div className={classes.settingsDiv}>
          <IconButton
            aria-label={settingsAriaLabel}
            onClick={(onSettingsClick) ? onSettingsClick() : undefined}
            >
            <SettingsIcon fontSize="small" />
          </IconButton>
        </div>
      );
    }

    let showPaneButton = (
      <div
        className={classes.showPaneButton}
        style={{
          opacity: (PaneIsVisible) ? 0 : 1,
        }}
        onClick={() => this.handleSetPaneVisibility(true)}>
        <ShowPaneIcon
          className={classes.showPaneIcon}
          fontSize="small"
        />
      </div>
    );
    let hidePaneButton = (PaneIsVisible)
      ? (
        <IconButton
          className={classes.hidePaneButton}
          onClick={() => this.handleSetPaneVisibility(false)}
        >
          <HidePaneIcon fontSize="small" />
        </IconButton>
      ) : null;

    let paneVisibilityClass = (PaneIsVisible) ? classes.pane_visible : classes.pane_hidden;

    return (
      <div className={classes.container}
        style={paneStyle}>
        <div className={classNames(classes.pane, paneVisibilityClass)}
          ref={instance => this.PaneRef = instance}
          style={{
            marginLeft: (!PaneIsVisible) ? -1 * this.PaneRef.offsetWidth || 3000 : undefined,
          }}
        >
          <div className={classes.paneInner}
            style={paneInnerStyle}>
            {children}
            {settingsButton}
          </div>
        </div>
        {hidePaneButton}
        {showPaneButton}
      </div>
    );
  }
}

export default withStyles(styles, {withTheme: true})(CollapsibleLeftPane);
