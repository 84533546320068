import Collection from '../Model/Collection';

import Document from '../Screens/Document';

export class PublicDocumentPassThrough_Collection extends Collection {
  constructor(props, onSetState, onApiError, organizationId, projectId, documentId) {
    if (typeof organizationId !== "string") {
      console.log("Warning: organizationId required and not found");
    }
    if (typeof projectId !== "string") {
      console.log("Warning: projectId required and not found");
    }
    if (typeof documentId !== "string") {
      console.log("Warning: documentId required and not found");
    }
    super(props, onSetState, onApiError, false, false, organizationId, projectId);

    const {
      theme,
      classes,
      ...restProps
    } = this.props;

    this.PassThroughComponent = (
      <Document {...restProps} usePublicApi />
    );
  }
}