import Collection from '../Model/Collection';

import { 
  GetSearchResultHeadCells,
  GetSearchResultCardGridItems,
  GetSearchResultTableRows,
} from '../Util/SearchResults';
import {
  AddFilterSortFieldsToCollectionFields,
} from '../Util/Fields';

import AdvancedSearchResultsCollectionFields from '../Model/AdvancedSearchResultsCollectionFields';

import {
  GetProjectSearchResultsPathForApi,
  // GetUserOrganizationSearchResultsPathForApi,
} from '../Util/api';

export class AdvancedSearchResults_Collection extends Collection {
  constructor(props, context, onSetState, onApiError, organizationId, projectId, filterSortFields) {
    super(props, onSetState, onApiError, false, false, organizationId, projectId);

    this.PageTitle = "Advanced search results";
    this.ContentUri = /*(context.UserPreferences.ActiveOrganizationMember)
      ? */GetProjectSearchResultsPathForApi(organizationId, projectId)
      /*: GetUserOrganizationSearchResultsPathForApi(context.UserPreferences.ActiveOrganizationID)*/;
    this.CollectionName = "SearchResults";
    this.ItemsName = "SearchResults";
    this.ItemName = "Search Result";
    this.DefaultViewType = "Card";
    this.AllowSelect = false;
    // this.HideSensitiveFields = true;

    this.context = context;
    this.filterSortFields = filterSortFields.map(fsf => ({
      Field: {...fsf, ID: fsf.FieldID },
      FilterID: fsf.FilterID,
      SortID: fsf.SortID,
    }));
  }

  HandleGetCollectionFieldsPromise = () => {
    let collectionFields = [...AdvancedSearchResultsCollectionFields];
    AddFilterSortFieldsToCollectionFields(collectionFields, this.filterSortFields);
    return Promise.resolve(collectionFields);
  }

  HandleGetHeadCells = (items, sensitiveFields) => {
    return GetSearchResultHeadCells(items, sensitiveFields, false, true, this.filterSortFields, true);
  }

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, selectedIDs, 
    onAction, postActionData, sortType, sortDescending, useSingleColumnLayout, cardStyle) => {
    return GetSearchResultCardGridItems(this.props, this.context, true, false)
      (items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction,
        postActionData, sortType, sortDescending, cardStyle);
  }

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return GetSearchResultTableRows(this.props, this.context, true, false, true, this.filterSortFields, true)
      (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs, sortType, sortDescending);
  }
}