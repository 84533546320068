import React, { Component } from 'react';

import ItemCollectionBase from '../../Components/ItemCollectionBase';

import { SupportUsers_Collection } from '../../Model/SupportUsers';

class SupportUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    this.Collection = new SupportUsers_Collection(props, () => {}, () => {}, props.isWorkspace, props.isProjects);
  }

  render() {
    return (
      <ItemCollectionBase
        {...this.props}

        pageTitle="Support: Users"
        contentUri={this.Collection.ContentUri}
        collectionName={this.Collection.CollectionName}
        itemsName={this.Collection.ItemsName}
        itemName={this.Collection.ItemName}
        defaultViewType={this.Collection.DefaultViewType}
        
        onGetCollectionFieldsPromise={this.Collection.HandleGetCollectionFieldsPromise}
        onGetHeadCells={this.Collection.HandleGetHeadCells}
        onGetCardGridItems={this.Collection.HandleGetCardGridItems}
        onGetTableRows={this.Collection.HandleGetTableRows}

        loadItemsImmediately
        hideFilterSortDrawer
      />
    );
  }
}

export default SupportUsers;