import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

import EnhancedTableHead from '../../Components/EnhancedTableHead';
import ErrorEventCard from '../Components/ErrorEventCard';
import ProgressIndicator from '../../Components/ProgressIndicator';
import GetMoreButton from '../../Util/GetMoreButton';

import {
  GetDateValue,
} from '../../Util/Properties';

const styles = theme => ({
  cardGridItem: {
    width: "100%",
  },
  // For Table
  tableWrapper: {
    minWidth:"100%",
    width:'max-content',
    overflowX: "visible",
  },
  table: {
    backgroundColor: theme.palette.background.paper,
  },
  tableCell_FirstCell: {
    paddingLeft:15,
    paddingRight:20,
    [theme.breakpoints.down('xs')]: {
      paddingLeft:7,
    },
  },
});

class ErrorEventListBase extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
    }
  }

  handleRowClick = item => e => {
    if (e.ctrlKey) {

    } else if (this.props.onItemClick) {
      this.props.onItemClick(item.ID);
    }
  }

  render() {
    const { 
      ShowProgressIndicator,
      ShowProgressIndicatorImmediately,
  	} = this.state;
    const {
      classes,
      theme,
      errorEvents,
      showGetMoreButton,
      onGetMoreItems,
      onItemClick,
      sortType,
      collectionFields,
      sortDescending,
      onSortChangeFromTableHeader,
      viewType,
    } = this.props;

		let cardGridItems = [];
    if (errorEvents && errorEvents.length > 0) {
      cardGridItems = errorEvents
      .map(ee => {
        return (
          <Grid item key={ee.ID} sm={12} md={6} lg={4} xl={3} className={classes.cardGridItem}>
            <ErrorEventCard
              ErrorEvent={ee}
              onApiError={(err) => this.setState({ApiError: err})}
              onShowProgressIndicator={show => this.setState({ShowProgressIndicator: show})}
              onShowProgressIndicatorImmediately={show => this.setState({ShowProgressIndicatorImmediately: show})}
              onCardAction={(onItemClick !== undefined) ? () => onItemClick(ee.ID) : undefined}
            />
          </Grid>
        );
      });
    }

    let progressIndicator = null;
    if (ShowProgressIndicator || ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    let headCells = [
      { id: 'CreatedOn', numeric: false, label: 'Created On' },
      { id: 'ID', numeric: false, label: 'ID' },
      { id: 'ServiceNameLower', numeric: false, label: 'Service' },
      { id: 'Level', numeric: false, label: 'Level' },
      { id: 'RequestURI', numeric: false, label: 'Request URI' },
      { id: 'RequestMethod', numeric: false, label: 'Request Method' },
      { id: 'OrganizationName', numeric: false, label: 'Organization' },
      { id: 'OrganizationID', numeric: false, label: 'Organization ID' },
      { id: 'ProjectName', numeric: false, label: 'Project' },
      { id: 'ProjectID', numeric: false, label: 'Project ID' },
      { id: 'UserEmailLower', numeric: false, label: 'User Email' },
      { id: 'UserID', numeric: false, label: 'User ID' },
      { id: 'UniqueID', numeric: false, label: 'Unique ID' },
      { id: 'Header', numeric: false, label: 'Header' },
      { id: 'Message', numeric: false, label: 'Message' },
      { id: 'ClientMessage', numeric: false, label: 'ClientMessage' },
      { id: 'OtherValues', numeric: false, label: 'OtherValues' },
      { id: 'HttpStatus', numeric: false, label: 'HTTP Status' },
    ];

    let tableHead = (
      <EnhancedTableHead
        headCells={headCells}
        sortDescending={sortDescending}
        sortType={sortType}
        collectionFields={collectionFields}
        onSortChange={id => onSortChangeFromTableHeader(id)}
      />
    );

    let table = (errorEvents) ? (
      <div className={classes.tableWrapper}>
        <Table
          stickyHeader
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'medium'}
        >
          {tableHead}
          <TableBody>
            {errorEvents.map(ee => {
              const labelId = `label_${ee.ID}`;
              return (
                <TableRow
                  hover={onItemClick !== undefined}
                  onClick={this.handleRowClick(ee)}
                  tabIndex={-1}
                  key={ee.ID}
                  style={{cursor:(onItemClick) ? "pointer" : null}}
                >
                  <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
                    <Checkbox
                      style={{
                        visibility: "hidden",
                      }}
                      color="secondary"
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell} component="th" id={labelId} scope="row" padding="none">
                    {GetDateValue(ee.CreatedOn)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>{ee.ID}</TableCell>
                  <TableCell className={classes.tableCell}>{ee.ServiceNameLower}</TableCell>
                  <TableCell className={classes.tableCell}>{ee.Level}</TableCell>
                  <TableCell className={classes.tableCell}>{ee.RequestURI}</TableCell>
                  <TableCell className={classes.tableCell}>{ee.RequestMethod}</TableCell>
                  <TableCell className={classes.tableCell}>{ee.OrganizationName}</TableCell>
                  <TableCell className={classes.tableCell}>{ee.OrganizationID}</TableCell>
                  <TableCell className={classes.tableCell}>{ee.ProjectName}</TableCell>
                  <TableCell className={classes.tableCell}>{ee.ProjectID}</TableCell>
                  <TableCell className={classes.tableCell}>{ee.UserEmailLower}</TableCell>
                  <TableCell className={classes.tableCell}>{ee.UserID}</TableCell>
                  <TableCell className={classes.tableCell}>{ee.UniqueID}</TableCell>
                  <TableCell className={classes.tableCell}>{ee.Header}</TableCell>
                  <TableCell className={classes.tableCell}>{ee.Message}</TableCell>
                  <TableCell className={classes.tableCell}>{ee.ClientMessage}</TableCell>
                  <TableCell className={classes.tableCell}>{ee.OtherValues}</TableCell>
                  <TableCell className={classes.tableCell}>{ee.HttpStatus}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    ) : null;

    let contentByView = null;
    let contentStyle = {};
    switch (viewType) {
      case "List":
        contentStyle = {};
        contentByView = table;
        break;
      case "Card":
      default:
        contentStyle = {
          padding:theme.spacing(2),
        };
        contentByView = (
          <Grid container spacing={2}>
            {cardGridItems}
          </Grid>
        );
        break;
    }

    return (
      <div style={contentStyle}>

        {progressIndicator}

        {contentByView}

        {GetMoreButton(showGetMoreButton, () => onGetMoreItems(), viewType, theme)}
      </div>    
    );
  }
}

export default withStyles(styles, {withTheme: true})(ErrorEventListBase);