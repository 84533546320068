export function IsMobile() {
	return (
		typeof window.orientation !== "undefined") 
		|| 
		(navigator.userAgent.indexOf('IEMobile') !== -1
	);
}

export function IsAndroid() {
	const userAgent = navigator.userAgent || navigator.vendor || window.opera;

	return (/android/i.test(userAgent));
}

export function IsiOS() {
	const userAgent = navigator.userAgent || navigator.vendor || window.opera;

	return (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream);
}