const DashboardWidget = {
    ID: "",
    OrganizationID: "",
    OrganizationMemberID: "",
    Type: "Inbox",
    Name: "",
    Rank: 0,
    JsonData: "",
}

export default DashboardWidget;