const TaskPriorityType = [
	{
		Type: 0,
		Label: "None",
	},
	{
		Type: 7,
		Label: "Highest",
	},
	{
		Type: 6,
		Label: "Higher",
	},
	{
		Type: 5,
		Label: "High",
	},
	{
		Type: 4,
		Label: "Medium",
	},
	{
		Type: 3,
		Label: "Low",
	},
	{
		Type: 2,
		Label: "Lower",
	},
	{
		Type: 1,
		Label: "Lowest",
	},
];

export default TaskPriorityType;