import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
// import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import AsyncSelectControl from '../../Components/AsyncSelectControl';

import API, {
  GetProjectMembersPathForApi,
} from '../../Util/api';
import {
  GetHomePath,
  GetProjectPath,
  GetOrganizationLink,
} from '../../Util/PathHelper';
import {
  GetOrganizationMemberListValuesPromise,
} from '../../Util/OrganizationMembers';
import { ValidateEmail } from '../../Util/Regex';
import ProgressIndicator from '../../Components/ProgressIndicator';

import { IsMobile } from '../../Util/MobileDetector';
import { GlobalContext } from '../../Context/Global.context';

const styles = theme => ({
});

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="left" ref={ref} {...props} />;
// });

class ProjectMemberCreationDialog extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.initialState = {
      ShowProgressIndicatorImmediately: false,
      EmailOptionsToAdd: [],
    };

    this.state = {
      ...this.initialState,
      open: props.open,
    }
  }

  setInitialState = () => {
    this.setState(this.initialState);
  }

  handleClose = () => {
    this.setState({ 
      open: false,
    });
    this.setInitialState();
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleCreate = () => {
    if (!this.state.EmailOptionsToAdd || !this.state.EmailOptionsToAdd.length) {
      return;
    }

    this.setState({ShowProgressIndicatorImmediately: true});
    
    const newProjectMembers = this.state.EmailOptionsToAdd.map(li => {
      return {
        UserEmail: li.value,
      };
    });
    if (this.props.onBeginCreate) {
      this.props.onBeginCreate(newProjectMembers);
    }
    const params = {
      homePath: GetOrganizationLink(this.props.organizationId, GetHomePath()),
      projectPath: GetOrganizationLink(this.props.organizationId, GetProjectPath(this.props.projectId)),
    }
    API.post(GetProjectMembersPathForApi(this.props.organizationId, this.props.projectId),
      newProjectMembers, { params })
      .then(resp => {
        if (this.props.onCreated) {
          this.props.onCreated(resp.data);
        }
        this.setInitialState();
      })
      .catch(this.handleApiError);
  }

  handleAddEmailOptions = emailOrListValues => {
    let EmailOptionsToAdd = [];
    if (Array.isArray(emailOrListValues)) {
      EmailOptionsToAdd = emailOrListValues;
    } else if (typeof emailOrListValues === "string") {
      EmailOptionsToAdd = [...this.state.EmailOptionsToAdd]
        .concat({ value: emailOrListValues, label: emailOrListValues });
    }
    if (this.validateEmailOptionsToAdd(EmailOptionsToAdd)) {
      this.setState({ EmailOptionsToAdd });
    }
  }

  validateEmailOptionsToAdd = EmailOptionsToAdd => {
    for (let i = 0; i < EmailOptionsToAdd.length; i++) {
      let email = EmailOptionsToAdd[i].value;
      if (!ValidateEmail(email)) {
        this.handleApiError(`${email} is not a valid e-mail address.`);
        return false;
      }
    }
    return true;
  }

  handleGetOrganizationMemberListPromiseIfApplicable = filter => {
    if (!this.context.ActiveOrganizationPermissions || !this.context.ActiveOrganizationPermissions.IsAdmin) {
      return Promise.resolve([]);
    }

    return GetOrganizationMemberListValuesPromise(
      this.props.organizationId,
      filter,
      true,
      this.handleApiError,
    );
  }

  handleApiError = (err, title_optional) => {
    this.setState({
      ShowProgressIndicatorImmediately: false,
    });
    this.props.onApiError(err, title_optional);
    if (err) {
      setTimeout(() => this.props.onApiError(null), 1);
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      this.setState({open: this.props.open !== false});
    }
  }

  render() {
    const { 
      open,
      EmailOptionsToAdd,
      ShowProgressIndicatorImmediately,
  	} = this.state;
    const {
      // classes,
      theme,
      // organizationId,
      projectName,
    } = this.props;

    let progressIndicator = null;
    if (ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    let dialogActions = (
      <DialogActions>
        <Button onClick={() => this.handleClose()}>
          CLOSE
        </Button>
        <Button onClick={() => this.handleCreate()}>
          ADD
        </Button>
      </DialogActions>
    );

    let content = (
      <div style={{marginTop:theme.spacing(1)}}>
        <AsyncSelectControl label="E-mail(s)" 
          // floatingOptions
          isMulti
          autoFocus
          onCreateOption={this.handleAddEmailOptions}
          allowCreateWhileLoading
          autoReloadOnValueChange
          onGetOptionsFilterPromise={this.handleGetOrganizationMemberListPromiseIfApplicable}
          listValues={EmailOptionsToAdd}
          onValueChange={this.handleAddEmailOptions}
          notClearable
        />
      </div>
    );

    const dialogTitle = `Add project members${(projectName) ? " to " + projectName : ""}`;

    return (
       <Dialog
        fullWidth={!IsMobile()}
        fullScreen={IsMobile()}
        maxWidth="sm"
        // TransitionComponent={Transition}
        // PaperProps={{
        //   style:{
        //     minHeight:"50%",
        //   }
        // }}
        open={open}
        onClose={() => this.handleClose()}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description">
        <DialogTitle id="dialog-title">
          {dialogTitle}
        </DialogTitle>
        <DialogContent style={{paddingTop:0}}>
          {progressIndicator}
          {content}
        </DialogContent>

        {dialogActions}
      </Dialog>
    );
  }
}

ProjectMemberCreationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  projectName: PropTypes.string,
  onBeginCreate: PropTypes.func,
  onCreated: PropTypes.func,
  onApiError: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default withStyles(styles, {withTheme: true})(ProjectMemberCreationDialog);