import React, {Component} from 'react';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import AddIcon from '@material-ui/icons/Add';

import DraggableTaskSubTask from './DraggableTaskSubTask';
import TaskSubTaskDragLayer from './TaskSubTaskDragLayer';

import {
  GetSubTaskComponentClasses,
} from '../Util/Task';

import debounce from 'es6-promise-debounce';
import { IsMobile } from '../Util/MobileDetector';

const styles = theme => ({
  ...GetSubTaskComponentClasses(theme),
  checklistContainer: {
    flexGrow:1,
    overflowY:"auto",
    paddingTop:1,
    marginTop:1+theme.spacing(1),
    display:"flex",
    flexDirection:"column",
  },
});

class TaskChecklist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      NewChecklistItemValue: "",
    }

    this.CheckListContainerRef = React.createRef();
    this.ChecklistItemRefsByIndex = [];
  }

  handleChecklistItemAdded = debounce(initialValue => {
    // console.log("handleChecklistItemAdded", initialValue);
    this.setState({NewChecklistItemValue: ""});
    this.props.onSubTaskAdded(initialValue);
    const refFinder = this.ChecklistItemRefsByIndex.filter(r => r.index === this.props.Task.SubTasks.length - 1);
    if (refFinder.length) {
      const end = refFinder[0].ref.value.length;
      refFinder[0].ref.setSelectionRange(end, end);
      refFinder[0].ref.focus();
    }
  }, 250);

  handleChecklistItemAddedAfter = (index, initialValue) => {
    // console.log("handleChecklistItemAddedAfter", index, initialValue);
    this.props.onSubTaskAddedAfter(index, initialValue);
    // setTimeout allows the ref to be created before we need it
    setTimeout(() => {
      const refFinder = this.ChecklistItemRefsByIndex.filter(r => r.index === 1 + index);
      if (refFinder.length) {
        const end = refFinder[0].ref.value.length;
        refFinder[0].ref.setSelectionRange(end, end);
        refFinder[0].ref.focus();
      }
    }, 1);
  }

  handleNewChecklistItemChanged = e => {
    this.setState({NewChecklistItemValue: e.target.value});
    this.handleChecklistItemAdded(e.target.value);
  }

  handleSetSubTaskTextFieldRef = (index, ref) => {
    const existingRefFinder = this.ChecklistItemRefsByIndex.filter(r => r.index === index);
    if (existingRefFinder.length) {
      existingRefFinder[0].ref = ref;
    } else {
      this.ChecklistItemRefsByIndex.push({
        index,
        ref,
      })
    }
  }

  render() {
    const {
      NewChecklistItemValue,
    } = this.state;
    const {
      classes,
      theme,
      Task,
      onSubTaskChanged,
      onStartMoveSubTask,
      onMoveSubTask,
      onAbortMoveSubTask,
      onEndMoveSubTask,
      disabled,
    } = this.props;
    
    let newChecklistItem;
    if (!disabled) {
      newChecklistItem = (IsMobile())
        ? (
          <div style={{
            flexGrow:1,
            marginLeft:5+theme.spacing(2),
          }}>
            <Tooltip title="Add item">
              <IconButton aria-label="Add item"
                onClick={() => this.handleChecklistItemAdded()}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </div>
        )
        : (
          <div style={{
            color:(theme.palette.type === "dark") ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.54)",
            display:"flex",
            alignItems:"center",
            marginLeft:1+theme.spacing(4),
          }}>
            <AddIcon />
            <TextField
              style={{
                marginLeft:1+theme.spacing(1),
                flexGrow:1,
              }}
              variant="outlined"
              placeholder="Checklist item"
              size="small"
              value={NewChecklistItemValue}
              onChange={this.handleNewChecklistItemChanged}
            />
          </div>
        );
    }

    const checklistItems = (Task && Task.SubTasks)
      ? Task.SubTasks.map((targetSubTask, i) => {
        return (
          <DraggableTaskSubTask
            key={`cli_${i}`}
            Task={Task}
            SubTaskIndex={i}
            disabled={disabled}
            onSetSubTaskTextFieldRef={this.handleSetSubTaskTextFieldRef}
            onSubTaskChanged={onSubTaskChanged}
            onSubTaskAddedAfter={this.handleChecklistItemAddedAfter}
            onStartMoveSubTask={onStartMoveSubTask}
            onMoveSubTask={
              (sourceSubTask, sourceClientOffsetY) => {
                const targetRefFinder = this.ChecklistItemRefsByIndex.filter(r => r.index === i);
                const targetClientOffset = (targetRefFinder.length && targetRefFinder[0].ref)
                  ? targetRefFinder[0].ref.getBoundingClientRect()
                  : -1;
                return onMoveSubTask(sourceSubTask, sourceClientOffsetY, targetSubTask, targetClientOffset.y);
              }
            }
            onAbortMoveSubTask={onAbortMoveSubTask}
            onEndMoveSubTask={onEndMoveSubTask}
          />
        );
      })
      : null;

    const checklistLabel = (!IsMobile())
      ? (
        <div>
          Checklist
        </div>
      ) : null;

    const checklistCompleteInfo = (Task && Task.SubTasks && Task.SubTasks.length)
      ? (
        <div style={{
          flexGrow:1,
          textAlign:(!IsMobile()) ? "right" : undefined,
        }}>
          {`${Task.SubTasks.filter(s => s.IsComplete).length} of ${Task.SubTasks.length} complete`}
        </div>
      ) : null;

    return (
      <React.Fragment>
        <Typography variant="caption" style={{
          display:"flex",
          marginLeft:4+theme.spacing(4),
          color:(theme.palette.type === "dark") ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.54)",
        }}>
          {checklistLabel}
          {checklistCompleteInfo}
        </Typography>
        <div className={classes.checklistContainer}
          ref={instance => this.CheckListContainerRef = instance}
        >
          {checklistItems}
          {newChecklistItem}
          <TaskSubTaskDragLayer classes={classes} theme={theme} width={this.CheckListContainerRef.clientWidth} />
        </div>
      </React.Fragment>
    );
  }
}

TaskChecklist.propTypes = {
  classes: PropTypes.object.isRequired,
  Task: PropTypes.object.isRequired,
  onSubTaskAdded: PropTypes.func.isRequired,
  onSubTaskChanged: PropTypes.func.isRequired,
  onSubTaskAddedAfter: PropTypes.func.isRequired,
  onStartMoveSubTask: PropTypes.func.isRequired,
  onMoveSubTask: PropTypes.func.isRequired,
  onAbortMoveSubTask: PropTypes.func.isRequired,
  onEndMoveSubTask: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default withStyles(styles, {withTheme: true})(TaskChecklist);
