import React from 'react';
import { DragLayer } from 'react-dnd';
import PropTypes from 'prop-types';

import TaskCardForKanban from './TaskCardForKanban';

function collect(monitor, props) {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    clientOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }
}

function KanbanTaskDragLayer(
  {
    // classes,
    // theme,
    width,
    item,
    // itemType,
    clientOffset,
    // initialClientOffset,
    isDragging,
    hideAssignment,
  }) {
  if (!item
    || !item.Task
    || !clientOffset
    || !isDragging) {
    return null;
  }

  const sourceTaskIsInSelectedGroup = 
    Boolean(
      item.selectedTaskIds 
      && item.selectedTaskIds.length > 1
      && item.selectedTaskIds.filter(id => id === item.Task.ID).length > 0
    );

  return (
    <div style={{
      position:"fixed",
      pointerEvents:"none",
      left:clientOffset.x-8,
      top:clientOffset.y-18,
      width,
      zIndex:2,
    }}>
      <TaskCardForKanban
        isDragLayer
        isMultiTaskDrag={sourceTaskIsInSelectedGroup}
        Task={item.Task}
        hideAssignment={hideAssignment}
        onCardAction={() => {}}
        onStartMoveTask={() => {}}
        onAbortMoveTask={() => {}}
        onEndMoveTask={() => {}}
        onMoveTaskOverTask={() => {}}
      />
    </div>
  );
}

KanbanTaskDragLayer.propTypes = {
  // classes: PropTypes.object.isRequired,
  // theme: PropTypes.object.isRequired,
  width: PropTypes.number,
  hideAssignment: PropTypes.bool,
}

export default DragLayer(collect)(KanbanTaskDragLayer);