import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
// import Button from '@material-ui/core/Button';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
// import Divider from '@material-ui/core/Divider';

import { withStyles } from '@material-ui/core/styles';

import DashboardCard from '../DashboardCard';
import { Player } from 'video-react';
import "video-react/dist/video-react.css";
import {
  GetPublicVideoPath,
} from '../../Util/api';

import Icon from '@material-ui/icons/DirectionsRun';
import IconColor from '@material-ui/core/colors/green';

const styles = theme => ({
  paper: {
    width:"100%",
    overflow:"hidden",
    borderRadius: 8,
    position:"relative",
    // "&:hover $topicsButton": {
    //   display: "block",
    // }
  },
  // topicsButton: {
  //   position:"absolute",
  //   right:theme.spacing(2),
  //   bottom: 48,
  //   display: "none",
  // },
});

class JumpInWidget extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      TopicsMenuAnchorEl: null,
    }
  }

  handleSetTopicsMenuVisibility = visible => e => {
    this.setState({ TopicsMenuAnchorEl: (visible) ? e.currentTarget : null });
  }

  render() {
    // const {
    //   TopicsMenuAnchorEl,
    // } = this.state;
    const { 
      classes,
      theme,
      ...passThroughProps
    } = this.props;

    const paper = (
      <Paper
        className={classes.paper}
      >
        <Player
          playsInline
          poster={GetPublicVideoPath("jump-in.jpg")}
          src={GetPublicVideoPath("jump-in.mp4")}
        />
        {/*<Button
          size="small"
          variant="contained"
          className={classes.topicsButton}
          onClick={this.handleSetTopicsMenuVisibility(true)}
        >
          TOPICS
        </Button>
        <Menu
          id="topics-menu"
          anchorEl={TopicsMenuAnchorEl}
          keepMounted
          open={Boolean(TopicsMenuAnchorEl)}
          onClose={this.handleSetTopicsMenuVisibility(false)}
        >
          <MenuItem onClick={() => {}}>Capture a document</MenuItem>
          <MenuItem onClick={() => {}}>Gather signatures</MenuItem>
          <MenuItem onClick={() => {}}>Share a document</MenuItem>
          <MenuItem onClick={() => {}}>Design a form</MenuItem>
          <MenuItem onClick={() => {}}>Create a project with tasks</MenuItem>
          <MenuItem onClick={() => {}}>Invite users</MenuItem>
          <MenuItem onClick={() => {}}>Schedule an onboarding session</MenuItem>
        </Menu>*/}
      </Paper>
    );

    return (
      <DashboardCard
        xs={12}
        sm={6}
        md={3}
        lg={4}
        xl={4}
        isMaxHeightWidget
        title="Jump In"
        titleIcon={<Icon style={{color: IconColor[500]}} />}
        passThroughContent={paper}
        {...passThroughProps} 
      />
    );
  }
}

JumpInWidget.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(JumpInWidget);