import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { GlobalContext } from '../Context/Global.context';

import { withStyles } from '@material-ui/core/styles';

import { IsMobile } from '../Util/MobileDetector';
import queryString from 'query-string';

const styles = theme => ({
  dialogContentRoot: {
    padding:"0 !important",
    display:"flex",
    overflow:"hidden",
    height:"100%",
  },
  dialogContentLeft: {
    width:"100%",
    display:(IsMobile()) ? "none" : "flex",
    flexDirection:"column",
  },
  dialogContentRight: {
    flexGrow:1,
    display:"flex",
    flexDirection:"column",
  },
  dialogPaper: {
    maxHeight:(!IsMobile()) ? "85%" : undefined,
  },
  dialogTitle: {
    textAlign:"center",
  },
  dialogContentGrid: {
    flexGrow:1,
    height:"100%",
    overflowY:"auto",
    overflowX:"hidden",
  },
  bottomBar:{
    height:74,
    width:"100%",
    padding:theme.spacing(2),
    backgroundColor: theme.palette.background.pane,
    display:"flex",
    justifyContent:"flex-end",
  },
  illustrationBox: {
    flexGrow:1,
    backgroundColor:"#ecf4fa",
    display:"flex",
    alignItems:"center",
    width:"100%",
  },
  content: {
    flexGrow:1,
    padding:(IsMobile()) ? theme.spacing(3) : theme.spacing(6),
    paddingBottom:theme.spacing(3),
    marginBottom:0,
    textAlign:"center",
  },
});

class WelcomeDialog extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      Closed: false,
      DismissPermanently: false,
      ShowProgressIndicatorImmediately: false,
    }
  }

  handleClose = () => {
    this.setState({Closed:true});
    this.context.SetViewedWelcomeDialogThisSession();
    if (this.state.DismissPermanently) {
      this.context.SetViewedWelcomeDialog(true);
    }
    if (this.props.onClose) {
      this.props.onClose(this.state.DismissPermanently);
    }
  }

  handleBuildNow = () => {
    this.handleClose();
    this.props.onBeginCreateProject();
  }

  handleApiError = err => {
    this.setState({ ShowProgressIndicatorImmediately: false, });
    this.props.onApiError(err);
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      this.setState({open: this.props.open !== false});
    }
  }

  render() {
    const {
      Closed,
      DismissPermanently,
    } = this.state;
    const {
      classes,
      theme,
      location,
      promoteProjectCreation,
    } = this.props;
    const {
      ViewedWelcomeDialog,
    } = this.context;

    const dismissDialogContent = (
      <div className={classes.bottomBar}>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary" 
              checked={DismissPermanently}
              onChange={e => this.setState({DismissPermanently:e.target.checked})} />
          }
          label="Don't show again"
        />
        <Button onClick={this.handleClose} variant="contained">
          CLOSE
        </Button>
      </div>
    );

    const illustration = (
      <div className={classes.illustrationBox}>
        <Grid container direction="column">
          <Grid item>
            <img
              src="/welcome2.png"
              alt=""
              style={{
                width:"100%",
              }}
            />
          </Grid>
          <Grid item style={{color:theme.palette.background.paper,textAlign:"center",fontSize:10}}>
            <a style={{color:theme.palette.background.paper}} target="n1_external" href="https://www.freepik.com/free-vector/teamwork-team-success-concept_9176902.htm#page=5&position=24&from_view=author">Image by pch.vector</a> on Freepik
          </Grid>
        </Grid>
      </div>
    );

    const projectCreationGridItems = (promoteProjectCreation)
     ? [
      <Grid item key="projectCreationText" style={{
        paddingTop:(IsMobile()) ? theme.spacing(2) : theme.spacing(4),
        paddingBottom:0,
      }}>
        <Typography variant="body1" style={{
          fontSize:22,
          fontFamily:"Nunito",
          fontWeight:500,
        }}>
          Now let's jump start your first project!
        </Typography>
      </Grid>,
      <Grid item key="projectCreationButton">
        <Button variant="contained"
          color="secondary"
          onClick={() => this.handleBuildNow()}
        >
          BUILD NOW
        </Button>
      </Grid>
    ] : null;

    const dialogContentGrid = (
      <Grid container direction="column" className={classes.dialogContentGrid}>
        <Grid item>
          <Grid container direction="column" spacing={5} className={classes.content}>
            <Grid item>
              <Typography variant={(IsMobile()) ? "h3" : "h2"} style={{
                fontWeight:800,
                fontFamily:"Nunito",
              }}>
                Welcome to Nucleus One
              </Typography>
            </Grid>
            <Grid item style={{paddingTop:0}}>
              <Typography variant="body1" style={{
                fontSize:(IsMobile()) ? 26 : 30,
                fontFamily:"Nunito",
                fontWeight:600,
              }}>
                Nucleus One is how teams get work done!
              </Typography>
            </Grid>
            <Grid item style={{
              paddingTop:(IsMobile()) ? theme.spacing(2) : theme.spacing(4),
              paddingBottom:0,
            }}>
              <Typography variant="body1" style={{
                fontSize:22,
                fontFamily:"Nunito",
                fontWeight:500,
              }}>
                Find your way around and learn more with these resources...
              </Typography>
            </Grid>
            <Grid item>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Grid container direction="column">
                    <Grid item>
                      <Button variant="outlined" onClick={() => window.open("https://www.youtube.com/watch?v=lXKUs8AeJ68", "n1_resourceContent")}>
                        <img style={{ width:"100%",paddingTop:8,paddingBottom:8}} src="/generalwalkthrough.png" alt="General Walkthrough Video" />
                      </Button>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" style={{
                        // fontSize:22,
                        fontFamily:"Nunito",
                        // fontWeight:500,
                      }}>
                        General Walkthrough Video
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container direction="column">
                    <Grid item>
                      <Button variant="outlined" onClick={() => window.open("https://nucleus.one/learn/", "n1_resourceContent")}>
                        <img style={{ width:"100%",paddingTop:8,paddingBottom:8}} src="/learnsite.png" alt="Learn Site" />
                      </Button>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" style={{
                        // fontSize:22,
                        fontFamily:"Nunito",
                        // fontWeight:500,
                      }}>
                        Knowledge Base
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {/*<Grid item xs={4}>
                  <Grid container direction="column">
                    <Grid item>
                      <Button style={{
                        width:"100%",
                        padding:(IsMobile()) ? 11.5 : 18.5,
                      }} variant="outlined">
                        <LibraryIcon style={{fontSize:(IsMobile()) ? 40 : 100}} />
                      </Button>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" style={{
                        // fontSize:22,
                        fontFamily:"Nunito",
                        // fontWeight:500,
                      }}>
                        All Resources &#8212; Look for this icon
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>*/}
              </Grid>
            </Grid>

            {projectCreationGridItems}
          </Grid>
        </Grid>
      </Grid>
    );

    return (
      <Dialog
        fullWidth={!IsMobile()}
        fullScreen={IsMobile()}
        maxWidth="lg"
        classes={{
          paper:classes.dialogPaper,
        }}
        open={(!Closed && 
          (!ViewedWelcomeDialog || Boolean(queryString.parse(location.search).showWelcome))) || false}
        onClose={this.handleClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description">
        <DialogContent
          classes={{
            root:classes.dialogContentRoot,
          }}
        >
          <div className={classes.dialogContentLeft}>
            {illustration}
            <div className={classes.bottomBar} />
          </div>
          <div className={classes.dialogContentRight}>
            {dialogContentGrid}
            {dismissDialogContent}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

WelcomeDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  promoteProjectCreation: PropTypes.bool,
  onBeginCreateProject: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default withStyles(styles, {withTheme: true})(WelcomeDialog);
