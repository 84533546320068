import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import Collection from '../Model/Collection';

import ItemTableRow from '../Components/ItemTableRow';
import OrganizationCard from '../Support/Components/OrganizationCard';
import OrganizationCollectionFields from '../Model/OrganizationCollectionFields';

import red from '@material-ui/core/colors/red';
import brown from '@material-ui/core/colors/brown';
import grey from '@material-ui/core/colors/grey';

import { NumberWithSeparators } from '../Util/NumberFormatting';
import {
  GetDateValue,
  GetUserValue,
  GetBoolValue,
} from '../Util/Properties';
import {
  GetSupportOrganizationDetailPath,
} from '../Util/PathHelper';
import {
  GetSupportOrganizationsPathForApi,
} from '../Util/api';
import {
  OrganizationRecommendedForRemoval,
} from '../Util/Organizations';

export class SupportOrganizations_Collection extends Collection {
  constructor(props, onSetState, onApiError, isWorkspace, isProjects, indexedOnly) {
    super(props, onSetState, onApiError, isWorkspace, isProjects);

    this.PageTitle = "Organizations";
    this.ContentUri = GetSupportOrganizationsPathForApi();
    this.ContentUriParams = {
      indexedOnly,
    };
    this.CollectionName = "SupportOrganizations";
    this.ItemsName = "Organizations";
    this.ItemName = "Organization";
    this.DefaultViewType = "List";
  }

  HandleGetCollectionFieldsPromise = () => Promise.resolve(OrganizationCollectionFields);

  HandleGetHeadCells = (items, sensitiveFields) => [
    { id: 'Name', numeric: false, label: 'Name' },
    { id: 'CreatedOn', numeric: false, label: 'Created On' },
    { id: 'CreatingUserLastActivity', numeric: false, label: 'Creating User Last Activity' },
    { id: 'CreatedUser', numeric: false, label: 'Creating User' },
    // { id: 'Firestore', numeric: false, label: 'Firestore' },
    // { id: 'BucketName', numeric: false, label: 'Cloud Storage' },
    // { id: 'SearchIndexID', numeric: false, label: 'ElasticSearch' },
    { id: 'SubscriptionFreeUsers', numeric: true, label: 'Free Members Allowed' },
    { id: 'UniqueFreeOrganizationMembers', numeric: true, label: 'Free Members Used' },
    { id: 'UniqueBillableOrganizationMembers', numeric: true, label: 'Billable Members' },
    { id: 'UniqueNonReadOnlyOrganizationMembers', numeric: true, label: 'Total Members' },
    { id: 'ActiveProjectCount', numeric: true, label: 'Active Projects' },
    { id: 'HasDocuments', numeric: true, label: 'Min Documents' },
    { id: 'HasTasks', numeric: true, label: 'Min Tasks' },
    { id: 'HasFormTemplates', numeric: true, label: 'Min Forms' },
    { id: 'HasProcesss', numeric: true, label: 'Min Processes' },
    { id: 'SearchIndexRestoredAtLeastOnce', numeric: false, label: 'Index Restored Once' },
    { id: 'SearchIndexRemovedForCostSavings', numeric: false, label: 'Index Deleted' },
    // { id: 'Disabled', numeric: false, label: 'Disabled?' },
    // { id: 'PurgeDate', numeric: false, label: 'Purge Date?' },
  ];

  getPurgeMarkedText = (organization, classes, includeLabel) => {
    return (organization.IsMarkedForPurge)
      ? (
        <Typography style={{color:red[500]}}>
          <span className={classes.metadataName}>{`${(includeLabel) ? "Removal on " : ""}${GetDateValue(organization.PurgeDate)}`}</span>
        </Typography>
      ) : null;
  }

  getDisabledText = (organization, classes) => {
    return (organization.Disabled)
      ? (
        <Typography style={{color:red[500]}}>
          <span className={classes.metadataName}>DISABLED</span>
        </Typography>
      ) : null;
  }

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length) 
      ? items
        .map(i => {
          return (
            <Grid item key={i.ID} sm={12} md={6} lg={4} xl={3} className={classes.cardGridItem}>
              <OrganizationCard
                Organization={i}
                PurgeMarkedText={this.getPurgeMarkedText(i, classes, true)}
                DisabledText={this.getDisabledText(i, classes)}
                onCardAction={() => this.handleRouteToOrganizationDetail(i)}
              />
            </Grid>
          );
        }) 
      : [];
  }

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length)
      ? items
        .map((i/*, idx*/) => {
          // const testOrg = {...i, 
          //   // CreatedOn: (idx == 0) ? new Date() : i.CreatedOn,
          //   CreatingUserLastActivity: (idx == 0) ? i.CreatedOn : i.CreatingUserLastActivity,
          //   UniqueNonReadOnlyOrganizationMembers: 1,
          //   ActiveProjectCount: 1,
          //   HasUpToMinTasks: 0,
          //   HasUpToMinDocuments: 0,
          //   HasUpToMinFormTemplates: 0,
          //   HasUpToMinProcesss: 0,
          // };
          const rowStyle = 
            (OrganizationRecommendedForRemoval(i))
              ? {backgroundColor:(OrganizationRecommendedForRemoval(i, true)) ? brown[800] : grey[800]} 
              : {};
          return (
            <ItemTableRow key={`k_${i.ID}`}
              style={rowStyle}
              onItemClick={() => this.handleRouteToOrganizationDetail(i)}
            >
              <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
                <Checkbox
                  style={{
                    visibility: "hidden",
                  }}
                  color="secondary"
                />
              </TableCell>
              <TableCell className={classes.tableCell} component="th" id={`label_${i.ID}`} scope="row" padding="none">
                {i.Name}
              </TableCell>
              <TableCell className={classes.tableCell}>{GetDateValue(i.CreatedOn)}</TableCell>
              <TableCell className={classes.tableCell}>{GetDateValue(i.CreatingUserLastActivity)}</TableCell>
              <TableCell className={classes.tableCell}>{GetUserValue(i.CreatedByUserEmail, i.CreatedByUserName)}</TableCell>
              {/*<TableCell className={classes.tableCell}>{i.ID}</TableCell>
              <TableCell className={classes.tableCell}>{i.BucketName}</TableCell>
              <TableCell className={classes.tableCell}>{i.SearchIndexID}</TableCell>*/}
              <TableCell className={classes.tableCell} align="right">{NumberWithSeparators(i.SubscriptionFreeUsers)}</TableCell>
              <TableCell className={classes.tableCell} align="right">{NumberWithSeparators(i.UniqueFreeOrganizationMembers)}</TableCell>
              <TableCell className={classes.tableCell} align="right">{NumberWithSeparators(i.UniqueBillableOrganizationMembers)}</TableCell>
              <TableCell className={classes.tableCell} align="right">{NumberWithSeparators(i.UniqueNonReadOnlyOrganizationMembers)}</TableCell>
              <TableCell className={classes.tableCell} align="right">{NumberWithSeparators(i.ActiveProjectCount)}</TableCell>
              <TableCell className={classes.tableCell} align="right">{NumberWithSeparators(i.HasUpToMinDocuments)}</TableCell>
              <TableCell className={classes.tableCell} align="right">{NumberWithSeparators(i.HasUpToMinTasks)}</TableCell>
              <TableCell className={classes.tableCell} align="right">{NumberWithSeparators(i.HasUpToMinFormTemplates)}</TableCell>
              <TableCell className={classes.tableCell} align="right">{NumberWithSeparators(i.HasUpToMinProcesses)}</TableCell>
              <TableCell className={classes.tableCell}>{GetBoolValue(i.SearchIndexRestoredAtLeastOnce, true)}</TableCell>
              <TableCell className={classes.tableCell}>{GetBoolValue(i.SearchIndexRemovedForCostSavings, true)}</TableCell>
              {/*<TableCell className={classes.tableCell}>{this.getDisabledText(i, classes)}</TableCell>
              <TableCell className={classes.tableCell}>{this.getPurgeMarkedText(i, classes)}</TableCell>*/}
            </ItemTableRow>
          );
        }) 
      : [];
  }

  handleRouteToOrganizationDetail = organization => {
    // this.props.history.push(GetSupportOrganizationDetailPath(organization.ID));
    window.open(GetSupportOrganizationDetailPath(organization.ID), "n1_supportOrganization");
  }
}