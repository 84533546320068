import React, { Component } from 'react';

import UiCore from '../Components/UiCore';
import { GlobalContext } from '../Context/Global.context';

import {
  GetWorkspaceTaskPath,
  GetProjectTaskPath,
} from '../Util/PathHelper';

class TaskRedirect extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      ShowProgressIndicatorImmediately:true,
    }
  }

  handleApiError = err => {
    err.closeCallback = this.tryCloseWindowOrRedirectToHome;
    this.setState({ ApiError: err, ShowProgressIndicatorImmediately: false });
  }

  tryCloseWindowOrRedirectToHome = () => {
    this.setState({ShowProgressIndicatorImmediately: false});
    window.close();
    this.props.history.replace("/home");
  }

  componentDidMount() {
    const organizationID = this.props.match.params.organizationID;
    if (!organizationID) {
      this.handleApiError("Invalid or missing organization");
    }
    const taskID = this.props.match.params.taskID;
    if (!taskID) {
      this.handleApiError("Invalid or missing task");
      return null;
    }
    const routeToTask = replace => {
      const projectID = this.props.match.params.projectID;
      if (projectID) {
        if (replace) {
          this.props.history.replace(GetProjectTaskPath(projectID, taskID));
        } else {
          this.props.history.push(GetProjectTaskPath(projectID, taskID));  
        }
      } else {
        if (replace) {
          this.props.history.replace(GetWorkspaceTaskPath(taskID));
        } else {
          this.props.history.push(GetWorkspaceTaskPath(taskID));
        }
      }
    }
    if (this.context.UserPreferences.ActiveOrganizationID !== organizationID) {
      // Switch to the project provided
      this.context.SaveUserPreferences_ActiveOrganization(organizationID)
        .then(() => {
          this.context.Reset();
          routeToTask();
        })
        .catch(this.handleApiError);
    } else {
      routeToTask(true);
    }
  }

  render() {
    const {
      Alert,
      ShowProgressIndicatorImmediately,
    } = this.state;

    return (
      <UiCore title="Tasks"
        hideNavDrawer
        alert={Alert}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
      />
    );
  }
}

export default TaskRedirect;
