import React from 'react';

import DeleteIcon from '@material-ui/icons/Delete';

const UserApiKeyActionType = {
	UserApiKey_Delete: {
		Id: "UserApiKeyDelete",
		Label: "Delete",
		Icon: (<DeleteIcon />),
	},
};

export default UserApiKeyActionType;