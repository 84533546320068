import Button from '@material-ui/core/Button';

import TreeNode from '../Components/TreeNode';

import ProcessIcon from '@material-ui/icons/Timeline';
import ProcessActionIcon from '@material-ui/icons/AssignmentInd';

import {
  GetSearchField,
} from '../Util/Search';

import API from '../Util/api';

export const TreeComponentDidUpdate = (prevLocation, currentLocation, onSetState) => {
  // Restores or wipes SelectedNode for ItemCollectionBase breadcrumb component
  if (prevLocation.state !== currentLocation.state) {
    if (currentLocation.state && currentLocation.state.SelectedNode) {
      if (!prevLocation.state
        || prevLocation.state.SelectedNode !== currentLocation.state.SelectedNode) {
        onSetState({ SelectedNode: currentLocation.state.SelectedNode });
      }
    } else if (prevLocation.state) {
      onSetState({ SelectedNode: null });
    }
  }
}

export const GetTreeNodeComponent = (props_optional, state, onSetState, node, onGetMoreNodes,
  onGetChildNodesPromise, onGetChildTreeNodeComponent, onGetContextMenu, onSetNodeExpandFunction,
  onSetNodeCollapseFunction, onNodeSelected) => {
  return (node.GetMoreButton)
    ? (
      <div key={node.UniqueId}>
        <Button
          variant="contained"
          size="small"
          color={node.GetMoreButtonColor || "primary"}
          style={{
            marginLeft:26,
            marginTop:3,
            marginBottom:3,
            fontSize:10,
          }}
          onClick={() => onGetMoreNodes()}>
          {node.GetMoreButtonText || "GET MORE"}
        </Button>
      </div>
    )
    : (
      <TreeNode
        key={node.UniqueId}
        node={node}
        selectedNodeUrl={(node.Url && props_optional.location) ? props_optional.location.pathname : undefined}
        selectedNodeUniqueId={(state.SelectedNode) ? state.SelectedNode.UniqueId : undefined}

        onGetChildNodesPromise={onGetChildNodesPromise}
        onGetChildTreeNodeComponent={onGetChildTreeNodeComponent}
        onNodeSelect={handleNodeSelect(props_optional, state, onSetState, onNodeSelected)}
        onSetNodeCollapseFunction={handleSetNodeCollapseFunction(props_optional, state, onSetState, onSetNodeCollapseFunction)}
        onSetNodeExpandFunction={onSetNodeExpandFunction}
        onExpand={handleNodeExpand(props_optional, state, onSetState)}
        onGetContextMenu={onGetContextMenu}
      />
    );
}

export const GetNodeForGetMoreItems = (ParentNode, buttonText_optional, buttonColor_optional) => {
  return {
    GetMoreButton: true,
    GetMoreButtonText: buttonText_optional,
    GetMoreButtonColor: buttonColor_optional,
    UniqueId: `getMore${(ParentNode) ? "_" + ParentNode.UniqueId : ""}`,
    ParentNode,
  };
}

export const SetSelectedNodeByCurrentLocationRecursive = (nodes, childNodesByParentNodeUniqueId, 
  location, onSetState, nodeExpandFuncs) => {

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    if (location.pathname.startsWith(node.Url)) {
      onSetState({ SelectedNode: node });
      // Ensure node is expanded
      if (nodeExpandFuncs) {
        const nodeExpandFuncFinder = 
          nodeExpandFuncs.filter(d => d.UniqueId === node.UniqueId);
        if (nodeExpandFuncFinder.length) {
          nodeExpandFuncFinder[0].onNodeExpand(false, false, false, false, true);
        }
      }
    }
    if (childNodesByParentNodeUniqueId) {
      const childNodesFinder = childNodesByParentNodeUniqueId.filter(n => n.UniqueId === node.UniqueId);
      if (childNodesFinder.length) {
        SetSelectedNodeByCurrentLocationRecursive(childNodesFinder[0].ChildNodes, childNodesByParentNodeUniqueId,
          location, onSetState, nodeExpandFuncs);
      }
    }
  }
}

const handleNodeSelect = (props_optional, state, onSetState, onNodeSelected) => (SelectedNode, forMounting) => {
  const stateToSave = { SelectedNode };
  onSetState(stateToSave);

  if (SelectedNode.Url && props_optional) {
    if (props_optional.location.pathname === SelectedNode.Url || forMounting) {
      // console.log("replacing history", SelectedNode.Url);
      props_optional.history.replace(SelectedNode.Url, { ...props_optional.location.state, ...stateToSave });
    } else {
      // console.log("pushing history", props_optional.location.pathname, SelectedNode.Url);
      props_optional.history.push(SelectedNode.Url, { ...props_optional.location.state, ...stateToSave });  
    }
  }

  if (onNodeSelected) {
    onNodeSelected(SelectedNode);
  }
}

let RootNodeCollapseFunction = () => {};
const handleSetNodeCollapseFunction = (props_optional, state, onSetState, onSetNodeCollapseFunction) =>
  (node, collapseFunction) => {
  if (node.IsRoot) {
    RootNodeCollapseFunction = collapseFunction;
  }
  if (onSetNodeCollapseFunction) {
    onSetNodeCollapseFunction(node, collapseFunction);
  }
}

let LastExpandedNodeRootId = "";
const handleNodeExpand = (props_optional, state, onSetState) => node => {
  if (node.RootId && node.RootId !== LastExpandedNodeRootId) {
    RootNodeCollapseFunction(node);
  }
  LastExpandedNodeRootId = node.RootId;
}














// Inactive

const getFolderHierarchyItems = (props, state, onSetState, folderHierarchyId) => {
  // onSetState({ShowProgressIndicatorAfter2s:true});
  return API.get(`/folderHierarchies/${folderHierarchyId}/items`,
    {
      params: { 
        getAll: true
    }
  })
    .then(resp => {
      return resp.data;
    })
    .catch(props.onApiError);
}

let FolderHierarchyItemsByFolderHierarchyID = {};

// exported to avoid warning - will hopefully be gone in the end
export const handleGetChildNodes = (props, state, onSetState) => node => {
  if (node.NodeType === "folderHierarchies"
    && node.FolderHierarchyID
    && !FolderHierarchyItemsByFolderHierarchyID[node.FolderHierarchyID]) {
    return getFolderHierarchyItems(props, state, onSetState, node.FolderHierarchyID)
      .then(folderHierarchyItems => {
        if (folderHierarchyItems && folderHierarchyItems.length > 0) {
          FolderHierarchyItemsByFolderHierarchyID[node.FolderHierarchyID] = folderHierarchyItems;
          return getChildNodes(props, state, onSetState, node)
            .then(childNodes => {
              // let nodeChildren = childNodes.map(n => {
              //   return GetTreeNodeComponent(props, state, onSetState, n);
              // });
              onSetState({ShowProgressIndicatorAfter2s:false})
              return childNodes;
            })
            .catch(props.onApiError);
        } else {
          onSetState({ShowProgressIndicatorAfter2s:false})
          return [];
        }
      })
      .catch(props.onApiError);
  } else {
    return getChildNodes(props, state, onSetState, node)
      .then(childNodes => {
        // let nodeChildren = childNodes.map(n => {
        //   return GetTreeNodeComponent(props, state, onSetState, n);
        // });
        onSetState({ShowProgressIndicatorAfter2s:false})
        return childNodes;
      })
      .catch(props.onApiError);
  }
}

const getChildNodes = async (props, state, onSetState, currentNode) => {
  let nodes = [];
  const itemIndex = 1 + currentNode.ItemIndex
  switch (currentNode.NodeType) {
    case "tasks":
      if (props.showProjects) {
        return Promise.resolve([]);
      } else {
        return this.getProjectSubnode(currentNode, "tasksProjects");  
      }
    case "allTasks":
      if (props.showProjects) {
        return Promise.resolve([]);
      } else {
        return this.getProjectSubnode(currentNode, "allTasksProjects");
      }
    case "approvals":
      if (props.showProjects) {
        return Promise.resolve([]);
      } else {
        return this.getProjectSubnode(currentNode, "approvalsProjects");
      }
    case "allApprovals":
      if (props.showProjects) {
        return Promise.resolve([]);
      } else {
        return this.getProjectSubnode(currentNode, "allApprovalsProjects");
      }
    case "needSignature":
      if (props.showProjects) {
        return Promise.resolve([]);
      } else {
        return this.getProjectSubnode(currentNode, "needSignatureProjects");
      }
    case "subscriptions":
      if (props.showProjects) {
        return Promise.resolve([]);
      } else {
        return this.getProjectSubnode(currentNode, "subscriptionsProjects");
      }
    case "forms":
      if (props.showProjects) {
        return Promise.resolve([]);
      } else {
        return this.getProjectSubnode(currentNode, "formsProjects");
      }
    case "inboxProjects":
      return this.getOrganizationMemberProcesses(currentNode, "Approval")
        .then(processList => {
          if (processList.Processes && processList.Processes.length > 0) {
            for (let i = 0; i < processList.Processes.length; i++) {
              const p = processList.Processes[i];
              let nodeType = "approvalProcesses";
              nodes.push(
                {
                  NodeType: nodeType,
                  CustomIcon: (<ProcessIcon />),
                  Name: p.ProcessName,
                  OrganizationMemberProcessID: p.ID,
                  RootId: currentNode.RootId,
                  UniqueId: `${nodeType}_${p.ProcessID}_${itemIndex.toString()}`,
                  ItemIndex: itemIndex,
                  HasChildren: true,
                  ApiParams: { processID: p.ProcessID },
                  ParentNode: currentNode,
                }
              );
            }
            if (processList.Processes.length === processList.PageSize) {
              currentNode.Cursor = processList.Cursor;
              nodes.push(
                {
                  GetMoreButton: true,
                  UniqueId: "getMore_" + currentNode.UniqueId,
                  ParentNode: currentNode,
                }
              );
            }
            return nodes;
          } else {
            return [];
          }
        })
        .catch(props.onApiError);
    case "approvalProjects":
      return this.getOrganizationMemberProcesses(currentNode, "Approval")
        .then(processList => {
          if (processList.Processes && processList.Processes.length > 0) {
            for (let i = 0; i < processList.Processes.length; i++) {
              let p = processList.Processes[i];
              let nodeType = "approvalProcesses";
              nodes.push(
                {
                  NodeType: nodeType,
                  CustomIcon: (<ProcessIcon />),
                  Name: p.ProcessName,
                  OrganizationMemberProcessID: p.ID,
                  RootId: currentNode.RootId,
                  UniqueId: `${nodeType}_${p.ProcessID}_${itemIndex.toString()}`,
                  ItemIndex: itemIndex,
                  HasChildren: true,
                  ApiParams: { processID: p.ProcessID },
                  ParentNode: currentNode,
                }
              );
            }
            if (processList.Processes.length === processList.PageSize) {
              currentNode.Cursor = processList.Cursor;
              nodes.push(
                {
                  GetMoreButton: true,
                  UniqueId: "getMore_" + currentNode.UniqueId,
                  ParentNode: currentNode,
                }
              );
            }
            return nodes;
          } else {
            return [];
          }
        })
        .catch(props.onApiError);
    case "approvalProcesses":
      return this.getOrganizationMemberProcessElements(currentNode, "Approval")
        .then(elementList => {
          // If the user is an Administrator, insert a special approval node at the top
          if (elementList.ProcessElements && elementList.ProcessElements.length > 0) {
            for (let i = 0; i < elementList.ProcessElements.length; i++) {
              let pe = elementList.ProcessElements[i];
              nodes.push(
                {
                  NodeType: currentNode.NodeType,
                  CustomIcon: (<ProcessActionIcon />),
                  Name: pe.ProcessElementName,
                  RootId: currentNode.RootId,
                  UniqueId: `${currentNode.NodeType}_${pe.ProcessID}_${pe.ProcessElementID}_${itemIndex.toString()}`,
                  ItemIndex: itemIndex,
                  HasChildren: false,
                  ApiParams: { processID: pe.ProcessID, processElementID: pe.ProcessElementID},
                  ParentNode: currentNode,
                }
              );
            }
            if (elementList.ProcessElements.length === elementList.PageSize) {
              currentNode.Cursor = elementList.Cursor;
              nodes.push(
                {
                  GetMoreButton: true,
                  UniqueId: "getMore_" + currentNode.UniqueId,
                  ParentNode: currentNode,
                }
              );
            }
            return nodes;
          } else {
            return [];
          }
        })
        .catch(props.onApiError);
      case "allApprovalProjects":
      return this.getProcesses(currentNode)
        .then(processList => {
          if (processList.Processes && processList.Processes.length > 0) {
            for (let i = 0; i < processList.Processes.length; i++) {
              let p = processList.Processes[i];
              let nodeType = "allApprovalProcesses";
              nodes.push(
                {
                  NodeType: nodeType,
                  CustomIcon: (<ProcessIcon />),
                  Name: p.Name,
                  ProcessID: p.ID,
                  RootId: currentNode.RootId,
                  UniqueId: `${nodeType}_${p.ID}_${itemIndex.toString()}`,
                  ItemIndex: itemIndex,
                  HasChildren: true,
                  ApiParams: { processID: p.ID },
                  ParentNode: currentNode,
                }
              );
            }
            if (processList.Processes.length === processList.PageSize) {
              currentNode.Cursor = processList.Cursor;
              nodes.push(
                {
                  GetMoreButton: true,
                  UniqueId: "getMore_" + currentNode.UniqueId,
                  ParentNode: currentNode,
                }
              );
            }
            return nodes;
          } else {
            return [];
          }
        })
        .catch(props.onApiError);
    case "allApprovalProcesses":
      return this.getProcessElements(currentNode, "Task", "Approval")
        .then(elementList => {
          // If the user is an Administrator, insert a special approval node at the top
          if (elementList.ProcessElements && elementList.ProcessElements.length > 0) {
            for (let i = 0; i < elementList.ProcessElements.length; i++) {
              let pe = elementList.ProcessElements[i];
              nodes.push(
                {
                  NodeType: currentNode.NodeType,
                  Name: pe.Name,
                  RootId: currentNode.RootId,
                  UniqueId: `${currentNode.NodeType}_${pe.ProcessID}_${pe.ID}_${itemIndex.toString()}`,
                  ItemIndex: itemIndex,
                  HasChildren: false,
                  ApiParams: {
                    processID: pe.ProcessID,
                    processElementID: pe.ID,
                  },
                  ParentNode: currentNode,
                }
              );
            }
            if (elementList.ProcessElements.length === elementList.PageSize) {
              currentNode.Cursor = elementList.Cursor;
              nodes.push(
                {
                  GetMoreButton: true,
                  UniqueId: "getMore_" + currentNode.UniqueId,
                  ParentNode: currentNode,
                }
              );
            }
            return nodes;
          } else {
            return [];
          }
        })
        .catch(props.onApiError);
    case "taskProjects":
      return this.getOrganizationMemberProcesses(currentNode, "Task")
        .then(processList => {
          if (processList.Processes && processList.Processes.length > 0) {
            for (let i = 0; i < processList.Processes.length; i++) {
              let p = processList.Processes[i];
              nodes.push(
                {
                  NodeType: "taskProcesses",
                  CustomIcon: (<ProcessIcon />),
                  Name: p.ProcessName,
                  OrganizationMemberProcessID: p.ID,
                  RootId: currentNode.RootId,
                  UniqueId: `taskProcesses_${p.ProcessID}_${itemIndex.toString()}`,
                  ItemIndex: itemIndex,
                  HasChildren: true,
                  ApiParams: { processID: p.ProcessID},
                  ParentNode: currentNode,
                }
              );
            }
            if (processList.Processes.length === processList.PageSize) {
              currentNode.Cursor = processList.Cursor;
              nodes.push(
                {
                  GetMoreButton: true,
                  UniqueId: "getMore_" + currentNode.UniqueId,
                  ParentNode: currentNode,
                }
              );
            }
            return nodes;
          } else {
            return [];
          }
        })
        .catch(props.onApiError);
    case "taskProcesses":
      return this.getOrganizationMemberProcessElements(currentNode, "Task")
        .then(elementList => {
          // If the user is an Administrator, insert a special approval node at the top
          if (elementList.ProcessElements && elementList.ProcessElements.length > 0) {
            for (let i = 0; i < elementList.ProcessElements.length; i++) {
              let pe = elementList.ProcessElements[i];
              nodes.push(
                {
                  NodeType: "taskProcesses",
                  CustomIcon: (<ProcessActionIcon />),
                  Name: pe.ProcessElementName,
                  RootId: currentNode.RootId,
                  UniqueId: `taskProcesses_${pe.ProcessID}_${pe.ProcessElementID}_${itemIndex.toString()}`,
                  ItemIndex: itemIndex,
                  HasChildren: false,
                  ApiParams: { processID: pe.ProcessID, processElementID: pe.ProcessElementID},
                  ParentNode: currentNode,
                }
              );
            }
            if (elementList.ProcessElements.length === elementList.PageSize) {
              currentNode.Cursor = elementList.Cursor;
              nodes.push(
                {
                  GetMoreButton: true,
                  UniqueId: "getMore_" + currentNode.UniqueId,
                  ParentNode: currentNode,
                }
              );
            }
            return nodes;
          } else {
            return [];
          }
        })
        .catch(props.onApiError);
    case "allTaskProjects":
      return this.getProcesses(currentNode)
        .then(processList => {
          if (processList.Processes && processList.Processes.length > 0) {
            for (let i = 0; i < processList.Processes.length; i++) {
              let p = processList.Processes[i];
              nodes.push(
                {
                  NodeType: "taskProcesses",
                  CustomIcon: (<ProcessIcon />),
                  Name: p.Name,
                  ProcessID: p.ID,
                  RootId: currentNode.RootId,
                  UniqueId: `taskProcesses_${p.ID}_${itemIndex.toString()}`,
                  ItemIndex: itemIndex,
                  HasChildren: true,
                  ApiParams: { processID: p.ID},
                  ParentNode: currentNode,
                }
              );
            }
            if (processList.Processes.length === processList.PageSize) {
              currentNode.Cursor = processList.Cursor;
              nodes.push(
                {
                  GetMoreButton: true,
                  UniqueId: "getMore_" + currentNode.UniqueId,
                  ParentNode: currentNode,
                }
              );
            }
            return nodes;
          } else {
            return [];
          }
        })
        .catch(props.onApiError);
    case "allTaskProcesses":
      return this.getProcessElements(currentNode, "Task", "Tasks")
        .then(elementList => {
          // If the user is an Administrator, insert a special approval node at the top
          if (elementList.ProcessElements && elementList.ProcessElements.length > 0) {
            for (let i = 0; i < elementList.ProcessElements.length; i++) {
              let pe = elementList.ProcessElements[i];
              nodes.push(
                {
                  NodeType: "taskProcesses",
                  Name: pe.Name,
                  RootId: currentNode.RootId,
                  UniqueId: `taskProcesses_${pe.ProcessID}_${pe.ID}_${itemIndex.toString()}`,
                  ItemIndex: itemIndex,
                  HasChildren: false,
                  ApiParams: { processID: pe.ProcessID, processElementID: pe.ID},
                  ParentNode: currentNode,
                }
              );
            }
            if (elementList.ProcessElements.length === elementList.PageSize) {
              currentNode.Cursor = elementList.Cursor;
              nodes.push(
                {
                  GetMoreButton: true,
                  UniqueId: "getMore_" + currentNode.UniqueId,
                  ParentNode: currentNode,
                }
              );
            }
            return nodes;
          } else {
            return [];
          }
        })
        .catch(props.onApiError);
    case "folderHierarchies":
      let fhi = this.FolderHierarchyItemsByFolderHierarchyID[currentNode.FolderHierarchyID];
      let currentItem = fhi[currentNode.ItemIndex];
      if (currentItem === undefined) {
        return Promise.resolve([]);
      }
      let hasChildren = currentNode.ItemIndex < fhi.length - 1;
      switch (currentItem.Type) {
        case "FolderHierarchyItemType_Field":
          return this.getDocumentFieldValues(currentItem.FieldID, currentItem.FieldType, currentItem.FieldValueType, currentNode)
            .then(documentFieldsList => {
              if (documentFieldsList.DocumentFields && documentFieldsList.DocumentFields.length > 0) {
                for (let i = 0; i < documentFieldsList.DocumentFields.length; i++) {
                  let df = documentFieldsList.DocumentFields[i];
                  if (df.Value) {
                    nodes.push(
                      {
                        NodeType: "folderHierarchies",
                        Name: df.Value,
                        RootId: currentNode.RootId,
                        UniqueId: `${currentNode.FolderHierarchyID}_${currentNode.Name.match(/\w*/gi).join('')}_${df.ID}_${itemIndex.toString()}`,
                        FolderHierarchyID: currentNode.FolderHierarchyID,
                        ItemIndex: itemIndex,
                        HasChildren: hasChildren,
                        ApiParams: {
                          [`fieldID${currentNode.ItemIndex}`]: df.FieldID,
                          [`fieldValue${currentNode.ItemIndex}`]: df.Value,
                          [`fieldType${currentNode.ItemIndex}`]: currentItem.FieldType,
                          [`fieldValueType${currentNode.ItemIndex}`]: currentItem.FieldValueType,
                        },
                        SearchField: GetSearchField(df.FieldID, df.Value, JSON.stringify([df.Value]), null, null, 
                          "equals", currentItem.FieldValueType),
                        ParentNode: currentNode,
                      }
                    );
                  }
                }
                if (documentFieldsList.DocumentFields.length === documentFieldsList.PageSize) {
                  currentNode.Cursor = documentFieldsList.Cursor;
                  nodes.push(
                    {
                      GetMoreButton: true,
                      UniqueId: `getMore_${currentNode.UniqueId}`,
                      ParentNode: currentNode,
                    }
                  );
                }
                return nodes;
              } else {
                return [];
              }
            });
        default:
        return Promise.resolve([]);
      }
    default:
      return Promise.resolve([]);
  }
}