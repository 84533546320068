import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { DragSource } from 'react-dnd';
import { IsMobile } from '../Util/MobileDetector';
import { withStyles } from '@material-ui/core/styles';

/**
 * Implements the drag source contract.
 */
const signatureFieldSource = {
  canDrag(props, monitor) {
    return !IsMobile()
      && !props.disableDragging;
  },
  beginDrag(props, monitor) {
    props.onBeginDrag();
    return {
      SignatureField: props.SignatureField,
      onDrop: props.onDrop,
      onDrop2: props.onDrop2,
    };
  },
  endDrag(props, monitor, component) {
    props.onEndDrag();
    if (!monitor.didDrop()) {
      // props.onAbortMove();
    }
    // props.onDrop(monitor.getDropResult(), props.SignatureField);
  }
};

/**
 * Specifies the props to inject into your component.
 */
function dragCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

const styles = theme => ({
  div: {
    cursor:"grab",
    position:"absolute",
    opacity:0.8,
    fontWeight:600,
    display:"flex",
    fontSize:12,
    borderRadius:2,
    borderWidth:1,
    borderStyle:"dashed",
    // height:24,
    // [theme.breakpoints.down('md')]: {
    //   height:16,
    //   fontSize:10,
    // },
    // [theme.breakpoints.down('sm')]: {
    //   height:12,
    //   fontSize:8,
    // },
  },
  divContent: {
    paddingLeft:theme.spacing(1)/2,
    paddingTop:theme.spacing(1)/2,
    [theme.breakpoints.down('md')]: {
      paddingLeft:theme.spacing(1)/4,
      paddingTop:theme.spacing(1)/4,
    },
    // [theme.breakpoints.down('sm')]: {
    //   paddingLeft:theme.spacing(1)/4,
    //   paddingTop:theme.spacing(1)/4,
    // },
  },
});

class DraggableSignatureField extends Component {
  handleClick = e => {
    if (this.props.onClick) {
      this.props.onClick(e, this.props.SignatureField.ID);
    }
  }

  handleMouseDown = e => {
    if (this.props.onMouseDown) {
      this.props.onMouseDown(e, this.props.SignatureField.ID);
    }
  }

  render() {
    const { 
      isDragging,
      connectDragSource,
      SignatureField,
      height,
      width,
      selected,
      backgroundColor,
      classes,
    } = this.props;
    const {
      X,
      Y,
      WidthPercent,
      Label,
    } = SignatureField;

    // width, when available, takes priority over WidthPercent calculation

    return (
      <div
        className={classes.div}
        style={{
          height,
          borderColor:(selected) ? "red" : "#808080",
          left: `${X*100}%`, top: `${Y*100}%`, width: width || `${WidthPercent*100}%`,
          opacity:(isDragging) ? 0 : undefined,
          backgroundColor:backgroundColor,
          color:"#222",
        }}
        ref={instance => connectDragSource(ReactDOM.findDOMNode(instance))}
        onClick={this.handleClick}
        onMouseDown={this.handleMouseDown}
      >
        <div className={classes.divContent}>
          {Label}
        </div>
      </div>
    );
  }
}

export default DragSource('DraggableSignatureField', signatureFieldSource, dragCollect)(withStyles(styles, {withTheme: true})(DraggableSignatureField)) 