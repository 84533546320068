import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
// import Slide from '@material-ui/core/Slide';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Grid from '@material-ui/core/Grid';
// import Switch from '@material-ui/core/Switch';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

import InfoIcon from '@material-ui/icons/Info';

import API, {
  GetFormTemplatesPathForApi,
  GetFormTemplateSourceUploadsPathForApi,
  GetProjectsPathForApi,
} from '../Util/api';
import FormTemplateRenderType from '../Model/FormTemplateRenderType';
import ProgressIndicator from '../Components/ProgressIndicator';
import CaptureCore from '../Components/CaptureCore';

import { IsMobile } from '../Util/MobileDetector';
import debounce from 'es6-promise-debounce';

import {
  GetNewFormTemplate,
} from '../Model/FormTemplate';

const styles = theme => ({
  dialogPaper: {
    height:"100%",
    maxHeight: 480,
  },
  card: {
    backgroundColor: theme.palette.background.default,
  },
  cardContent: {
    minHeight: 80,
    // minWidth: 180,
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    position:"relative",
  },
  selectedCard: {
    color: "white",
    backgroundColor: theme.palette.secondary.main,
  },
  infoIcon: {
    position:"absolute",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    opacity:0.5,
  },
});

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="left" ref={ref} {...props} />;
// });

class FormTemplateCreationDialog extends Component {
  constructor(props) {
    super(props);
    
    this.initialState = {
      FormType: "",
      Projects: [],
      ProjectsCursor: "",
      ShowGetMoreProjects: false,
      SelectedProjectID: null,
      FormTemplates: null,
      FormTemplatesCursor: "",
      ShowGetMoreFormTemplates: false,
      SelectedFormTemplateID: null,
      ShowProgressIndicatorImmediately: false,
      Name: "",
    };

    this.state = {
      ...this.initialState,
      open: props.open,
    }
  }

  setInitialState = () => {
    this.setState(this.initialState);
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
    this.setInitialState();
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleCreate = (name, formTemplateSourceId, renderType) => {
    if (!name) {
      name = this.state.Name;
    }
    const isCopyExisting = this.state.FormType === "copyExisting";
    if (!isCopyExisting && !name) {
      return;
    }

    this.setState({ShowProgressIndicatorImmediately: true});
    
    const newFormTemplate = GetNewFormTemplate(name, formTemplateSourceId, renderType);
    if (this.props.onBeginCreate) {
      this.props.onBeginCreate(newFormTemplate);
    }

    const params = {
      copyFromProjectId: (isCopyExisting) ? this.state.SelectedProjectID : "",
      copyFromFormTemplateId: (isCopyExisting) ? this.state.SelectedFormTemplateID : "",
    };

    API.post(GetFormTemplatesPathForApi(this.props.organizationId, this.props.projectId), [newFormTemplate], { params })
      .then(resp => {
        if (this.props.onCreated) {
          this.props.onCreated(resp.data[0]);
        }
        this.setInitialState();
      })
      .catch(this.handleApiError);
  }

  handleLoadProjectsForAdmin = reset => {
    let params = {
      adminOnly: true,
      includeProjectId: this.props.projectId,
      cursor: (!reset) ? this.state.ProjectsCursor : "",
    };
    API.get(GetProjectsPathForApi(this.props.organizationId), { params })
      .then(resp => {
        let Projects = [];
        if (reset) {
          Projects = resp.data.Projects;
        } else {
          Projects = [...this.state.Projects];
          resp.data.Projects.forEach(newProject => {
            const existingFinder = Projects.filter(p => p.ID === newProject.ID);
            if (existingFinder.length) {
              Projects.splice(Projects.indexOf(existingFinder[0]), 1);
            }
            Projects.push(newProject);
          });
        }
        this.setState({
          Projects,
          ProjectsCursor: resp.data.Cursor,
          ShowGetMoreProjects: resp.data.Projects.length >= resp.data.PageSize,
        });
      })
      .catch(this.handleApiError);
  }

  handleLoadFormTemplates = debounce((projectId, reset) => {
    let params = {
      cursor: (!reset) ? this.state.FormTemplatesCursor : "",
    };
    API.get(GetFormTemplatesPathForApi(this.props.organizationId, projectId), { params })
      .then(resp => {
        let FormTemplates = [];
        if (reset) {
          FormTemplates = resp.data.FormTemplates;
        } else {
          FormTemplates = [...this.state.FormTemplates];
          resp.data.FormTemplates.forEach(newFormTemplate => {
            const existingFinder = FormTemplates.filter(p => p.ID === newFormTemplate.ID);
            if (existingFinder.length) {
              FormTemplates.splice(FormTemplates.indexOf(existingFinder[0]), 1);
            }
            FormTemplates.push(newFormTemplate);
          });
        }
        this.setState({
          FormTemplates,
          FormTemplatesCursor: resp.data.Cursor,
          ShowGetMoreFormTemplates: resp.data.FormTemplates.length >= resp.data.PageSize,
        });
      })
      .catch(this.handleApiError);
  }, 250);

  handleSelectProject = SelectedProjectID => {
    this.setState({
      SelectedProjectID,
      FormTemplates: null,
      SelectedFormTemplateID: null,
      ShowGetMoreFormTemplates: false,
    });
    this.handleLoadFormTemplates(SelectedProjectID, true);
  }

  handleSelectFormTemplate = SelectedFormTemplateID => {
    if (SelectedFormTemplateID === this.state.SelectedFormTemplateID) {
      this.setState({SelectedFormTemplateID: ""});
    } else {
      this.setState({SelectedFormTemplateID});
    }
  }

  handleSelectFormType = FormType => {
    this.setState({FormType});
  }

  handleFormTemplateSourceUploadComplete = (reservation, file) => {
    if (reservation.FormTemplateSourceID) {
      let renderType;
      switch (this.state.FormType) {
        case "pdfFormForRender":
          renderType = FormTemplateRenderType.FormTemplateRenderType_RenderNew.Type;
          break;
        case "pdfFormForFill":
        default:
          renderType = FormTemplateRenderType.FormTemplateRenderType_FillSource.Type;
          break;
      }
      this.handleCreate(reservation.OriginalFilename, reservation.FormTemplateSourceID, renderType);
    }
  }

  handleApiError = (err, title_optional) => {
    this.setState({
      ShowProgressIndicatorImmediately: false,
    });
    this.props.onApiError(err, title_optional);
    if (err) {
      setTimeout(() => this.props.onApiError(null), 1);
    }
  }

  componentDidMount() {
    this.handleLoadProjectsForAdmin(true);
    this.handleSelectProject(this.props.projectId, true);
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      this.setState({open: this.props.open !== false});
    }
  }

  render() {
    const { 
      open,
      Name,
      FormType,
      Projects,
      SelectedProjectID,
      ShowGetMoreProjects,
      FormTemplates,
      SelectedFormTemplateID,
      ShowGetMoreFormTemplates,
      ShowProgressIndicatorImmediately,
  	} = this.state;
    const {
      history,
      location,
      onAlert,
      classes,
      theme,
      projectName,
      organizationId,
      projectId,
    } = this.props;

    let progressIndicator = null;
    if (ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    const backButton = (FormType) ? (
      <Button onClick={() => this.setState({FormType:""})}>
        BACK
      </Button>
    ) : null;
    const createButton = (FormType && (
      FormType === "copyExisting" || FormType === "blank"))
     ? (
        <Button onClick={() => this.handleCreate()}
          disabled={FormType === "copyExisting" && (!SelectedProjectID || !SelectedFormTemplateID)}>
          CREATE
        </Button>
      ) : null;

    const dialogActions = (
      <DialogActions>
        <Button onClick={() => this.handleClose()}>
          CLOSE
        </Button>
        {backButton}
        {createButton}
      </DialogActions>
    );

    let projectListItems = Projects.map(p => 
      <ListItem key={`li_${p.ID}`} button
        onClick={() => this.handleSelectProject(p.ID)}
        selected={p.ID === SelectedProjectID}
      >
        <ListItemText primary={p.Name} />
      </ListItem>
    );
    if (ShowGetMoreProjects) {
      projectListItems.push(
        <ListItem key={`li_getMoreProjects`} button
          onClick={() => this.handleLoadProjectsForAdmin(false)}
        >
          <ListItemText primary="GET MORE" style={{textAlign:"center"}}/>
        </ListItem>
      );
    }

    let formTemplateListItems = [];
    if (FormTemplates) {
      if (!FormTemplates.length) {
        formTemplateListItems.push(
          <ListItem key={`li_noFormTemplates`}>
            <ListItemText primary={`There are no form templates available.`}
              style={{textAlign:"center"}}/>
          </ListItem>
        );
      } else {
        formTemplateListItems = FormTemplates.map(ft => 
          <ListItem key={`li_${ft.ID}`} button
            onClick={() => this.handleSelectFormTemplate(ft.ID)}
            selected={ft.ID === SelectedFormTemplateID}
          >
            <ListItemText primary={ft.Name} />
          </ListItem>
        );
      }
      if (ShowGetMoreFormTemplates) {
        formTemplateListItems.push(
          <ListItem key={`li_getMoreFormTemplates`} button
            onClick={() => this.handleLoadFormTemplates(SelectedProjectID, false)}
          >
            <ListItemText primary="GET MORE" style={{textAlign:"center"}}/>
          </ListItem>
        );
      }
    } else {
      formTemplateListItems.push(
        <ListItem key={`li_loadingFormTemplates`}>
          <ListItemText primary="Loading form templates..."
            style={{textAlign:"center"}}/>
        </ListItem>
      );
    }

    let dialogTitle = `New form template${(projectName) ? " in " + projectName : ""}`;

    const step1Content = (!FormType) ? (
      <Grid container spacing={2}>
        
        <Grid item key="blank" xs={4}>
          <Card className={classNames(
            classes.card,
            (FormType === "blank") ? classes.selectedCard : undefined,
          )}>
            <CardActionArea
              onClick={() => this.handleSelectFormType("blank")}>
              <CardContent className={classes.cardContent}>
                <Typography variant="h6">
                  Create Blank
                </Typography>

                <Tooltip title="Create a template from the ground up.">
                  <InfoIcon className={classes.infoIcon} />
                </Tooltip>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item key="copyExisting" xs={4}>
          <Card className={classNames(
            classes.card,
            (FormType === "copyExisting") ? classes.selectedCard : undefined,
          )}>
            <CardActionArea
              onClick={() => this.handleSelectFormType("copyExisting")}>
              <CardContent className={classes.cardContent}>
                <Typography variant="h6">
                  Copy Existing
                </Typography>

                <Tooltip title="Copy an existing form template from the organization.">
                  <InfoIcon className={classes.infoIcon} />
                </Tooltip>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item key="pdfFormForFill" xs={4}>
          <Card className={classNames(
            classes.card,
            (FormType === "pdfFormForFill") ? classes.selectedCard : undefined,
          )}>
            <CardActionArea
              onClick={() => this.handleSelectFormType("pdfFormForFill")}>
              <CardContent className={classes.cardContent}>
                <Typography variant="h6">
                  Upload PDF
                </Typography>

                <Tooltip title="Use a PDF form as the source. Submissions can fill the original PDF or render a new PDF.">
                  <InfoIcon className={classes.infoIcon} />
                </Tooltip>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        {/*<Grid item key="pdfFormForRender" xs={6}>
          <Card className={classNames(
            classes.card,
            (FormType === "pdfFormForRender") ? classes.selectedCard : undefined,
          )}>
            <CardActionArea
              onClick={() => this.handleSelectFormType("pdfFormForRender")}>
              <CardContent className={classes.cardContent}>
                <Typography variant="h6">
                  Upload <span style={{whiteSpace:"nowrap"}}>PDF Form</span>
                  <br />for Template Only
                </Typography>

                <Tooltip title="Use a PDF form to establish the template. Submissions will result in a rendered, non-form PDF.">
                  <InfoIcon className={classes.infoIcon} />
                </Tooltip>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>*/}

      </Grid>
    ) : null;

    let step2Content;
    if (FormType) {
      switch (FormType) {
        case "pdfFormForFill":
        case "pdfFormForRender":
          dialogTitle = "Upload PDF source";
          step2Content = (
            <CaptureCore
              history={history}
              location={location}
              reservationUri={GetFormTemplateSourceUploadsPathForApi(organizationId, projectId)}
              fullWidth
              singleFile
              hideCaptureOriginalOption
              skipCompleteAlert
              onComplete={this.handleFormTemplateSourceUploadComplete}
              acceptTypes="application/pdf"
              onApiError={this.handleApiError}
              onAlert={onAlert}
            />
          );
          break;
        case "copyExisting":
          dialogTitle = "Select an existing template";
          step2Content = (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <List
                  style={{
                    backgroundColor: theme.palette.background.paper,
                    height:340,
                    overflowY: "auto",
                  }}
                  subheader={
                    <ListSubheader component="div">
                      Project
                    </ListSubheader>
                  }
                >
                  {projectListItems}
                </List>
              </Grid>
              <Grid item xs={6}>
                <List
                  style={{
                    backgroundColor: theme.palette.background.paper,
                    height:340,
                    overflowY: "auto",
                  }}
                  subheader={
                    <ListSubheader component="div">
                      Select a form template to copy
                    </ListSubheader>
                  }
                >
                  {formTemplateListItems}
                </List>
              </Grid>
            </Grid>
          );
          break;
        default:
          dialogTitle = "Enter a template name";
          step2Content = (
            <TextField
              fullWidth
              autoFocus
              variant="outlined"
              label="Name"
              onChange={e => this.setState({Name: e.target.value})}
              value={Name}
              onKeyDown={e => { if (e.keyCode === 13) { this.handleCreate(); } }}
            />
          );
          break;
      }
    }

    const content = (
      <React.Fragment>
        {step1Content}
        {step2Content}
      </React.Fragment>
    );

    return (
       <Dialog
        fullWidth={!IsMobile()}
        fullScreen={IsMobile()}
        maxWidth={(FormType === "copyExisting" || FormType.startsWith("pdfForm")) ? "md" : "sm"}
        // TransitionComponent={Transition}
        // PaperProps={{
        //   style:{
        //     minHeight:"50%",
        //   }
        // }}
        classes={{
          paper: (FormType.startsWith("pdfForm")) ? classes.dialogPaper: null,
        }}
        open={open}
        onClose={() => this.handleClose()}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description">
        <DialogTitle id="dialog-title">
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          {progressIndicator}

          {content}
        </DialogContent>

        {dialogActions}
      </Dialog>
    );
  }
}

FormTemplateCreationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  open: PropTypes.bool,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  projectName: PropTypes.string,
  onBeginCreate: PropTypes.func,
  onCreated: PropTypes.func,
  onApiError: PropTypes.func.isRequired,
  onAlert: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default withStyles(styles, {withTheme: true})(FormTemplateCreationDialog);