import API from './api';
import debounce from 'es6-promise-debounce';
import {
  GetAssetPathForApi,
  GetAssetFieldIDsPathForApi,
} from '../Util/api';

export const GetAsset = (organizationId, projectId, assetId) => {
  return API.get(GetAssetPathForApi(organizationId, projectId, assetId))
    .then(resp => {
      let asset = resp.data;
      return asset;
    });
}

export const UpdateAsset = debounce((organizationId, projectId, asset) => {
  return API.put(GetAssetPathForApi(organizationId, projectId, asset.ID), asset);
}, 500);

export const UpdateAssetFieldIDs = debounce((organizationId, projectId, assetId, fieldIds) => {
  return API.put(GetAssetFieldIDsPathForApi(organizationId, projectId, assetId), fieldIds);
}, 500);