import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import InfoIcon from '@material-ui/icons/Info';

import UiCore from '../../Components/UiCore';
import TitleComponent from '../../Components/TitleComponent';
import API, {
  GetOrganizationSubscriptionsPathForApi,
  GetOrganizationSubscriptionInvoicesPathForApi,
  GetOrganizationSubscriptionPlansPathForApi,
} from '../../Util/api';
import { NumberWithSeparators } from '../../Util/NumberFormatting';
import { ValidateEmail } from '../../Util/Regex';
import ProgressIndicator from '../../Components/ProgressIndicator';
import MultiUseDialog from '../../Components/MultiUseDialog';
import { GlobalContext } from '../../Context/Global.context';

import {CardElement, injectStripe} from 'react-stripe-elements';
import dateformat from 'dateformat';

const styles = theme => ({
  contentContainer: {
    background:theme.palette.background.paper,
    padding: theme.spacing(3),
    // [theme.breakpoints.down('xs')]: {
    //   padding: theme.spacing(2),
    // },
    height:"100%",
    overflowY:"auto",
  },
  baseCard: {
    background: theme.palette.background.default,
  },
  selectedCard: {
    background: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
  },
  cardElement_base: {
    fontSize: 16,
    padding: "18.5px 15px",
    border: "1px solid",
    borderColor: theme.palette.type === "dark" ? "rgba(255, 255, 255, 0.23)" : "rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
    "&:hover": {
      border: "1px solid",
      borderColor: theme.palette.type === "dark" ? "rgba(255, 255, 255, 0.87)" : "rgba(0, 0, 0, 0.87)",
    },
  },
  cardElement_focus: {
    padding: "17.5px 14px",
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    "&:hover": {
      borderWidth: 2,
      borderColor: theme.palette.primary.main,
    },
  },
  tableRowNoBorder: {
    "& td": {
      border:0,
    }
  },
  tableCellBold: {
    fontWeight:600,
  },
});

const NewSubscriptionPlanDetails = {
  CurrentUniqueNonReadOnlyOrganizationMemberCount: 1,
  CurrentUniqueReadOnlyOrganizationMemberCount: 0,
  SalesTaxRate: 0.07,
  SubscriptionPlans: [],
};

const NewSubscriptionDetails = {
  PaymentSubscriptionExists: false,
  PaymentPlanId: "",
  Token: "",
  CustomerName: "",
  BillingEmail: "",
  PostalCode: "",
};

class OrganizationSubscription extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
  
    this.state = {
      SubscriptionPlanDetails: {...NewSubscriptionPlanDetails},
      SubscriptionDetails: {...NewSubscriptionDetails},
      SubscriptionDetailsLoaded: false,
      CardElementHasData: false,
      CardElementClearFunction: null,
      CardholderName: "",
      IsFlorida: false,
      ShowCancelConfirmation: false,
      ShowProgressIndicatorImmediately: false,
    }
  }

  loadPlanDetails() {
    return API.get(GetOrganizationSubscriptionPlansPathForApi(this.props.organizationId))
      .then(resp => {
        let planDetails = resp.data;
        const preferredTitlesInOrder = [
          "Professional User",
          "Corporate User",
          "Enterprise User",
        ];
        let subscriptionPlans = [...planDetails.SubscriptionPlans];
        preferredTitlesInOrder.reverse().forEach(t => {
          const plan = subscriptionPlans.find(p => p.Title === t);
          if (plan) {
            subscriptionPlans.splice(subscriptionPlans.indexOf(plan), 1);
            subscriptionPlans.splice(0, 0, plan);
          }
        });
        planDetails.SubscriptionPlans = subscriptionPlans;
        return planDetails;
      })
  }

  loadSubscriptionInvoices() {
    return API.get(GetOrganizationSubscriptionInvoicesPathForApi(this.props.organizationId))
      .then(resp => {
        return resp.data;
      })
      .catch(this.handleApiError);
  }

  loadSubscription() {
    this.setState({ShowProgressIndicatorImmediately: true,});
  
    this.loadPlanDetails()
      .then(subscriptionPlanDetails => {
        API.get(GetOrganizationSubscriptionsPathForApi(this.props.organizationId))
          .then(resp => {
            let sd = {...resp.data};
            if (!sd.PaymentPlanId || sd.PaymentSubscriptionCanceled) {
              sd.PaymentPlanId = subscriptionPlanDetails.SubscriptionPlans
                .filter(sp => sp.Active)[0].ID;
            }
            this.setState({
              SubscriptionPlanDetails: subscriptionPlanDetails,
              SubscriptionDetails: sd,
              SubscriptionDetailsLoaded: true,
              ShowProgressIndicatorImmediately: false,
            });
            this.setIsFlorida(sd.PostalCode);
            if (sd.IsExpired) {
              this.handleAlert({
                Title: "Subscription expired",
                BodyText: `Please subscribe to a payment plan to reactivate this organization or reduce the number of unique users across all projects to the free-user threshold (${sd.FreeUsers}).`,
              });
            } else if (sd.IsExpiringSoon || 
              (sd.PaymentSubscriptionCanceled && this.getProjectMemberQuantityForSubscription() > 0)) {
              this.handleAlert({
                Title: "Subscription expiring soon",
                BodyText: `You have exceeded ${
                  sd.FreeUsers
                } free users. Subscribe to a payment plan before ${
                  dateformat(new Date(sd.Expiration), "m/d/yyyy")
                } to avoid service interruption.`,
              });
            }
            this.loadSubscriptionInvoices()
              .then(SubscriptionInvoices => {
                this.setState({ SubscriptionInvoices });
              });
          })
      })
      .catch(this.handleApiError);
  }

  getTotalUniqueNonReadOnlyProjectMembers = () => {
    return this.state.SubscriptionPlanDetails.CurrentUniqueNonReadOnlyOrganizationMemberCount;
  }

  getProjectMemberQuantityForSubscription = () => {
    const subscriptionDetails = this.state.SubscriptionDetails;
    const totalUniqueNonReadOnlyProjectMembers = this.getTotalUniqueNonReadOnlyProjectMembers();
    return (this.state.SubscriptionDetailsLoaded)
      ? (totalUniqueNonReadOnlyProjectMembers <= subscriptionDetails.MinUsers
        && subscriptionDetails.FreeUsers < subscriptionDetails.MinUsers)
        ? subscriptionDetails.MinUsers 
        : (totalUniqueNonReadOnlyProjectMembers <= subscriptionDetails.FreeUsers)
          ? 0
          : (subscriptionDetails.SubtractFreeUsersFromPlan)
            ? totalUniqueNonReadOnlyProjectMembers - subscriptionDetails.FreeUsers
            : totalUniqueNonReadOnlyProjectMembers
      : 0;
  }

  clearProtectedFields() {
    if (this.state.CardElementClearFunction) {
      this.state.CardElementClearFunction();
    }
    this.setState({
      CardholderName: "",
    });
  }

  handleSetShowCancelConfirmationDialog = ShowCancelConfirmation => {
    this.setState({ShowCancelConfirmation});
  }

  handleUpdateSubscription() {
    if ((!this.state.SubscriptionDetails.PaymentSubscriptionExists
        || this.state.SubscriptionDetails.PaymentSubscriptionCanceled)
      && !this.state.CardElementHasData) {
      this.handleAlert({
        Title: "Payment details required",
        BodyText: "Please provide payment details to subscribe.",
        CloseCallback: () => this.handleAlert(null),
      });
      return;
    }

    if (this.state.CardElementHasData
      && (!this.state.CardholderName
      || this.state.CardholderName.length < 3)) {
      this.handleAlert({
        Title: "Name required",
        BodyText: "Please provide a cardholder name.",
        CloseCallback: () => this.handleAlert(null),
      });
      return;
    }

    if (!ValidateEmail(this.state.SubscriptionDetails.BillingEmail)) {
      this.handleAlert({
        Title: "Billing e-mail required",
        BodyText: "Please provide a valid billing e-mail.",
        CloseCallback: () => this.handleAlert(null),
      });
      return;
    }

    if (!this.state.CardElementHasData) {
      this.updateSubscription();
      return;
    }

    this.setState({
      ShowProgressIndicatorImmediately: true,
    });
    this.props.stripe.createToken({ name: this.state.CardholderName })
      .then(resp => {
        if (resp.error) {
          this.setState({ShowProgressIndicatorImmediately:false,});
          this.handleApiError(resp.error.message);
        } else if (resp.token) {
          this.updateSubscription(resp.token);
        }
      })
      .catch(this.handleApiError);
  }

  updateSubscription(token) {
    let sd = {...this.state.SubscriptionDetails};
    if (token) {
      sd.TokenId = token.id;  
      sd.PostalCode = token.card.address_zip;
    }
    this.setState({ShowProgressIndicatorImmediately: true});
    API.put(GetOrganizationSubscriptionsPathForApi(this.props.organizationId), sd)
      .then(resp => { 
        this.clearProtectedFields();
        // Call a context reset in some seconds, which should be enough time for
        // the backend to process the invoice in most situations
        setTimeout(() => this.context.Reset(), 10000);
        this.setState({
          SubscriptionDetails: resp.data,
          ShowProgressIndicatorImmediately: false,
        });
        this.handleAlert({
          Title: "Subscription updated",
          BodyText: "The subscription for this organization has been updated successfully.",
        });
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.loadSubscription();
      });
  }

  handleCancelSubscription = () => {
    this.handleSetShowCancelConfirmationDialog(false);
    this.setState({ShowProgressIndicatorImmediately: true});
    API.delete(GetOrganizationSubscriptionsPathForApi(this.props.organizationId))
      .then(resp => {
        this.setState({ShowProgressIndicatorImmediately: false});
        window.location.reload();
      })
      .catch(this.handleApiError);
  }

  handleApiError = err => {
    this.setState({
      ApiError: err, 
      ShowProgressIndicatorImmediately: false,
    });
    if (this.props.onApiError) {
      this.props.onApiError(err);
    }
  }

  handleAlert = details => {
    this.setState({Alert:details});
    this.props.onAlert(details);
  }

  handleStringChange(propertyName, string) {
    let sd = {...this.state.SubscriptionDetails};
    sd[propertyName] = string;
    this.setState({SubscriptionDetails: sd});
  }

  handleCardElementChange(c) {
    let postalCode = null;
    if (c.value && c.value.postalCode && c.value.postalCode.length === 5) {
      postalCode = c.value.postalCode;
    }
    this.setIsFlorida(postalCode);
    this.setState({ CardElementHasData: !c.empty });
  }

  setIsFlorida(postalCode) {
    if (!postalCode) {
      if (this.state.SubscriptionDetails
        && this.state.SubscriptionDetails.PostalCode
        && this.state.SubscriptionDetails.PostalCode.length === 5) {
        postalCode = this.state.SubscriptionDetails.PostalCode;
      }
    }
    let isFlorida = false;
    if (postalCode) {
      let prefix = 0;
      try {
        prefix = parseInt(postalCode.substr(0, 3));
        isFlorida =
             (prefix >= 320 && prefix <= 339)
          || (prefix >= 341 && prefix <= 342)
          || (prefix === 344)
          || (prefix >= 346 && prefix <= 347)
          || (prefix === 349)
        ;
      } catch (e) { isFlorida = false; }
    }
    this.setState({ IsFlorida: isFlorida });
  }

  componentDidMount() {
    this.loadSubscription();
  }

  componentDidUpdate(prevProps) {
    if (this.props.organizationId !== prevProps.organizationId) {
      this.loadSubscription();
    }
  }

  render() {
    const { 
      ApiError,
      Alert,
      SubscriptionPlanDetails,
      SubscriptionDetails,
      SubscriptionDetailsLoaded,
      CardholderName,
      CardElementHasData,
      // IsFlorida,
      SubscriptionInvoices,
      ShowCancelConfirmation,
      ShowProgressIndicatorImmediately,
  	} = this.state;
    const {
      classes,
      theme,
      returnContentOnly,
    } = this.props;

    const paymentSubscriptionCanceled = SubscriptionDetails.PaymentSubscriptionCanceled;
    const selectedPlanId = SubscriptionDetails.PaymentPlanId;
    const projectMemberQuantityForSubscription = this.getProjectMemberQuantityForSubscription();
    const uniqueNonReadOnlyProjectMembers = this.getTotalUniqueNonReadOnlyProjectMembers();

    const planCardGridItems = SubscriptionPlanDetails.SubscriptionPlans.map(p => {
      // Don't show archived/inactive plans unless it's active for this organization.
      if (!p.Active) {
        if (!(p.ID === selectedPlanId && !paymentSubscriptionCanceled)) {
          return null;
        }
      }
      let billed = "";
      let monthlyPrice = "";
      switch (p.Interval) {
        case "year":
        billed = "Billed Annually";
        monthlyPrice = `$${NumberWithSeparators((p.AmountPerUnit / 12).toFixed(2))}`;
        break;
        case "month":
        default:
          billed = "Billed Monthly";
          monthlyPrice = `$${NumberWithSeparators(p.AmountPerUnit.toFixed(2))}`;
        break;
      }
      const billDetail = "per user per month";
      const savingsPercent = (p.SavingsPercent) ? `${p.SavingsPercent}%` : "";
      // let volumePricing = p.TieredPricing;
      return (
        <Grid item key={p.ID} xs={12} sm={6}>
          <Card className={selectedPlanId === p.ID ? classes.selectedCard : classes.baseCard}>
            <CardActionArea
              onClick={() => this.handleStringChange("PaymentPlanId", p.ID)}>
              <CardContent style={{display:"flex"}}>
                <div>
                  <Checkbox style={{padding:0,marginRight:theme.spacing(2)}}
                    checked={selectedPlanId === p.ID}
                    icon={<CheckBoxOutlineBlankIcon style={{fontSize:48}} />}
                    checkedIcon={<CheckBoxIcon fontSize="large" style={{fontSize:48,color:"white"}} />}
                     />
                </div>
                
                <div style={{flexGrow:1}}>
                  <Typography variant="h6" style={{fontWeight:500}}>{p.Title}</Typography>
                  <div style={{display:"flex", alignItems:"center"}}>
                    <Typography variant="h5" style={{fontWeight:500}}>{monthlyPrice}</Typography>
                    { savingsPercent ? (
                      <div style={{
                        borderRadius:12,
                        backgroundColor:theme.palette.primary.main,
                        color:theme.palette.primary.contrastText,
                        marginLeft:theme.spacing(1),
                        padding:6,
                        fontSize:10,
                        fontWeight:600,
                      }}>
                        Save {savingsPercent}
                      </div>
                    ) : null }
                    {/* { volumePricing ? ( */}
                    {/*   <div style={{ */}
                    {/*     borderRadius:12, */}
                    {/*     backgroundColor:theme.palette.primary.main, */}
                    {/*     color:theme.palette.primary.contrastText, */}
                    {/*     marginLeft:theme.spacing(1), */}
                    {/*     padding:6, */}
                    {/*     fontSize:10, */}
                    {/*     fontWeight:600, */}
                    {/*   }}> */}
                    {/*     volume pricing */}
                    {/*   </div> */}
                    {/* ) : null } */}
                  </div>
                  <Typography>{billDetail}</Typography>
                  <Typography variant="h6" style={{fontWeight:500,fontSize:12,marginTop:4}}>{billed}</Typography>
                  { !p.Active ? (<Typography style={{fontStyle:"italic"}}>legacy plan</Typography>) : null }
                </div>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid> 
      )
    });

    let cardHolderNameGridItem = null;
    if (CardElementHasData) {
      cardHolderNameGridItem = (
        <Grid item style={{ display: (!CardElementHasData) ? "none" : "inherit" }}>
          <TextField
            label="Cardholder name"
            fullWidth
            variant="outlined"
            onChange={event => this.setState({CardholderName: event.target.value})}
            value={CardholderName}
          />
        </Grid>
      );
    }

    let summary = null;
    if (SubscriptionDetailsLoaded
      && SubscriptionPlanDetails.SubscriptionPlans.length > 0) {
      let selectedPlan = SubscriptionPlanDetails.SubscriptionPlans.filter(p => p.ID === selectedPlanId)[0];
      let pricePerUnit = selectedPlan.AmountPerUnit;
      // let subTotal = projectMemberQuantityForSubscription * pricePerUnit;
      // 2021-05-16 - Evelyn requested that we do NOT collect sales tax for Florida customers
      // because we have never collected sales tax from anyone.
      let salesTax = 0;// (IsFlorida) ? subTotal * SubscriptionPlanDetails.SalesTaxRate : 0;
      let total = (projectMemberQuantityForSubscription)
        ? (projectMemberQuantityForSubscription * pricePerUnit) + salesTax
        : 0;
      let interval = "";
      switch (selectedPlan.Interval) {
        case "year":
        interval = "annual";
        break;
        case "month":
        default:
        interval = "monthly";
        break;
      }
      let minUsersTableRow = (SubscriptionDetails.MinUsers
          && uniqueNonReadOnlyProjectMembers < SubscriptionDetails.MinUsers
          && SubscriptionDetails.FreeUsers < SubscriptionDetails.MinUsers)
        ? (
          <TableRow className={classes.tableRowNoBorder}>
            <TableCell style={{verticalAlign: "top"}}>
              {NumberWithSeparators(SubscriptionDetails.MinUsers)} minimum user licenses across all projects
            </TableCell>
            <TableCell align="right">
              {`$${NumberWithSeparators(pricePerUnit.toFixed(2))}`}<br />each
            </TableCell>
          </TableRow>
        ) : null;
      let readOnlyUsersTableRow = (SubscriptionPlanDetails.CurrentUniqueReadOnlyOrganizationMemberCount)
        ? (
          <TableRow className={classes.tableRowNoBorder}>
            <TableCell style={{verticalAlign: "top"}}>
              {`${NumberWithSeparators(SubscriptionPlanDetails.CurrentUniqueReadOnlyOrganizationMemberCount)} read-only user${
                (SubscriptionPlanDetails.CurrentUniqueReadOnlyOrganizationMemberCount > 1) ? "s" : ""
              } across all projects`}
            </TableCell>
            <TableCell align="right">
              {`$0.00`}
            </TableCell>
          </TableRow>
        ) : null;
      let paidUsersTableRow = (projectMemberQuantityForSubscription
        && uniqueNonReadOnlyProjectMembers >= SubscriptionDetails.MinUsers)
        ? (
          <TableRow className={classes.tableRowNoBorder}>
            <TableCell style={{verticalAlign: "top"}}>
              {`${NumberWithSeparators(projectMemberQuantityForSubscription)} ${interval} user license${
                (projectMemberQuantityForSubscription > 1) ? "s" : ""
              } across all projects`}
            </TableCell>
            <TableCell align="right">
              {`$${NumberWithSeparators(pricePerUnit.toFixed(2))}`}<br />each
            </TableCell>
          </TableRow>
        ) : null;
      let freeUsersTableRow = (SubscriptionDetails.FreeUsers && !minUsersTableRow
        && (!paidUsersTableRow || SubscriptionDetails.SubtractFreeUsersFromPlan))
        ? (
          <TableRow className={classes.tableRowNoBorder}>
            <TableCell style={{verticalAlign: "top"}}>
              Up to {NumberWithSeparators(SubscriptionDetails.FreeUsers)} free users across all projects
            </TableCell>
            <TableCell align="right">
              {`$0.00`}
            </TableCell>
          </TableRow>
        ) : null;
      let salesTaxRow = (projectMemberQuantityForSubscription && salesTax)
        ? (
            <TableRow>
              <TableCell>
                Sales tax
              </TableCell>
              <TableCell align="right">
                {`$${NumberWithSeparators(salesTax.toFixed(2))}`}
              </TableCell>
            </TableRow>
          )
        : null;
      let totalRow = (total)
        ? (
          <TableRow className={classes.tableRowNoBorder}>
            <TableCell className={classes.tableCellBold}>
              Total
            </TableCell>
            <TableCell align="right" className={classes.tableCellBold}>
              {`$${NumberWithSeparators(total.toFixed(2))}`}
            </TableCell>
          </TableRow>
        ) : null;
      summary = (
        <div style={{marginTop: theme.spacing(3)}}>
          <Typography variant="h6" style={{fontWeight:500}}>
            Plan Summary
          </Typography>

          <Table size="small">
            <TableBody>
              {freeUsersTableRow}
              {minUsersTableRow}
              {readOnlyUsersTableRow}
              {paidUsersTableRow}
              {salesTaxRow}
              {totalRow}
            </TableBody>
          </Table>
        </div>
      );
    }

    let upcomingInvoices = null;
    let recentInvoices = null;
    if (SubscriptionInvoices && SubscriptionInvoices.length) {
      let upcomingInvoiceRows = [];
      let recentInvoiceRows = [];
      SubscriptionInvoices.forEach(si => {
        const pdfButton = (/*si.Status === "paid" && */si.PDFUrl)
          ? (
              <Button
                target="n1_urlFollow"
                onClick={() => window.open(si.PDFUrl, "n1_subscriptionInvoice")}
              >
                PDF
              </Button>
            )
          : null;
        const invoiceStatus = (si.IsUpcoming)
          ? "" 
          : `${si.Status}${(si.LastPaymentError) ? ", " + si.LastPaymentError : ""}`;
        const invoiceRow = (
          <TableRow key={`tr_${si.ID}`} className={classes.tableRowNoBorder}>
            <TableCell>
              {dateformat(new Date(si.CreatedOn), "m/d/yyyy")}
            </TableCell>
            <TableCell align="center">
              {pdfButton}
            </TableCell>
            <TableCell align="center">
              {invoiceStatus}
            </TableCell>
            <TableCell align="right">
              {`$${NumberWithSeparators(si.AmountDue.toFixed(2))}`}
            </TableCell>
          </TableRow>
        );
        if (si.IsUpcoming) {
          upcomingInvoiceRows.push(invoiceRow);
        } else {
          recentInvoiceRows.push(invoiceRow);
        }
      });

      const getInvoiceDiv = (invoiceRows, title, extraTitleContent) => {
        return (
          <div style={{marginTop: theme.spacing(3)}}>
            <Typography variant="h6" style={{fontWeight:500}}>
              {title}
              {extraTitleContent}
            </Typography>

            <Table size="small">
              <TableBody>
                {invoiceRows}
              </TableBody>
            </Table>
          </div>
        );
      }

      if (upcomingInvoiceRows.length) {
        const extraTitleContent = (
          <Tooltip title="For annual subscriptions, a portion of upcoming invoices may be charged within 30 days from now.">
            <InfoIcon style={{marginLeft: theme.spacing(1), paddingTop:theme.spacing(1)}} />
          </Tooltip>
        );
        upcomingInvoices = getInvoiceDiv(upcomingInvoiceRows, "Upcoming Invoices", extraTitleContent);
      }
      if (recentInvoiceRows.length) {
        recentInvoices = getInvoiceDiv(recentInvoiceRows, "Recent Invoices");
      }
    }

    let payWithCreditCardHeader = (
      <Typography variant="h6" style={{fontWeight:500, marginTop: theme.spacing(3), marginBottom:theme.spacing(1)}}>
        Pay with Credit Card
      </Typography>
    );

    let cardElementGridItem = (
      <Grid item>
        <CardElement
          onReady={e => this.setState({ CardElementClearFunction: e.clear })}
          onChange={c => this.handleCardElementChange(c)}
          classes={{base: classes.cardElement_base, focus: classes.cardElement_focus}}
          style={{
            base: {
              color: theme.palette.text.primary,
              fontSize: "16px",
            },
          }} />
      </Grid>
    );

    let billingEmailGridItem = (
      <Grid item>
        <TextField
          label="Billing e-mail"
          fullWidth
          variant="outlined"
          onChange={event => this.handleStringChange("BillingEmail", event.target.value)}
          value={SubscriptionDetails.BillingEmail}
        />
      </Grid>
    );

    let cardHolderDetailsGrid = (
      <Grid container direction="column" spacing={3}>
        {cardElementGridItem}
        {cardHolderNameGridItem}
        {billingEmailGridItem}
      </Grid>
    );

    let submitButton = null;
    if (SubscriptionDetailsLoaded /*&& process.env.NODE_ENV !== 'production'*/) {
      submitButton = (
        <Button variant="contained"
          color="primary"
          size="large"
          style={{marginTop:theme.spacing(3)}}
          fullWidth
          onClick={() => this.handleUpdateSubscription()}>
          { SubscriptionDetails.PaymentSubscriptionExists && !SubscriptionDetails.PaymentSubscriptionCanceled
            ? "UPDATE"
            : "SUBSCRIBE"
          }
        </Button>
      );
    }

    const cancelButton = (SubscriptionDetailsLoaded
      && SubscriptionDetails.PaymentSubscriptionExists
      && !SubscriptionDetails.PaymentSubscriptionCanceled)
      ? (
        <Button
          // size="large"
          style={{marginTop:theme.spacing(3)}}
          onClick={() => this.handleSetShowCancelConfirmationDialog(true)}>
          CANCEL
        </Button>
      ) : null;

    const cancelConfirmationDialogDetails = {
      Open:ShowCancelConfirmation,
      IsConfirmation:true,
      Title:"Cancel your subscription?",
      BodyText:`If you cancel now your subscription will not be renewed${
        (SubscriptionDetailsLoaded && SubscriptionDetails.SubscriptionRequired)
          ? " and you will lose access on " + dateformat(new Date(SubscriptionDetails.Expiration), "m/d/yyyy")
          : ""
        }.`,
      BodyClassName:"warning",
      CancelCallback:() => this.handleSetShowCancelConfirmationDialog(false),
      CloseCallback:() => this.handleSetShowCancelConfirmationDialog(false),
      ConfirmCallback:() => this.handleCancelSubscription(),
    };

    const internalProgressIndicator = (returnContentOnly && ShowProgressIndicatorImmediately)
      ? (
        <ProgressIndicator constrained showImmediately={ShowProgressIndicatorImmediately} />
      ) : null;

    let content = (
      <Container maxWidth="sm" className={classes.contentContainer}>
        {internalProgressIndicator}

        <TitleComponent title={`Subscription Plan: ${SubscriptionDetails.OrganizationName}`} />

        <Grid container spacing={4} style={{alignItems:"center"}}>
          <Grid item>
            <Typography variant="h6" style={{fontWeight:500}}>
              Billing Plan
            </Typography>
          </Grid>
          <Grid item>
            <Button size="small" onClick={() => window.open("https://nucleus.one/pricing")}>
            Learn more
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {planCardGridItems}
        </Grid>

        {payWithCreditCardHeader}
        
        {cardHolderDetailsGrid}

        {summary}

        {submitButton}

        {upcomingInvoices}
        {recentInvoices}

        {cancelButton}
        <MultiUseDialog Details={cancelConfirmationDialogDetails} />
      </Container>
    );

    let pageTitle = (SubscriptionDetails.OrganizationName)
      ? SubscriptionDetails.OrganizationName
      : "Loading subscription...";

    return (returnContentOnly)
      ? content
      : (
        <UiCore title={pageTitle}
          showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
          apiError={ApiError}
          alert={Alert}
          content={content}
        />
      );
  }
}

OrganizationSubscription.propTypes = {
  classes: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  returnContentOnly: PropTypes.bool,
  onApiError: PropTypes.func.isRequired,
  onAlert: PropTypes.func.isRequired,
};

export default injectStripe(withStyles(styles, {withTheme: true})(OrganizationSubscription));