import {
  createMuiTheme,
} from '@material-ui/core/styles';

// import {
//   GetSingleUserPreference_Bool,
//   SaveSingleUserPreference_Bool,
// } from './SingleUserPreference.js';

export const GetDarkMode = cookies => {
  if (cookies) {
    const darkMode = cookies.get("darkMode");
    return (darkMode === "true");
  }
  return false;
}

// const saveDarkMode = (cookies, useDarkMode) => {
//   if (cookies) {
//     cookies.set("darkMode", useDarkMode, { path: "/", sameSite: "lax", maxAge: 60 * 60 * 24 * 365 });
//   }
// }

export const GetLightMode = cookies => {
  if (cookies) {
    const lightMode = cookies.get("lightMode");
    return (lightMode === "true");
  }
  return false;
}

const saveLightMode = (cookies, useLightMode) => {
  if (cookies) {
    cookies.set("lightMode", useLightMode, { path: "/", sameSite: "lax", maxAge: 60 * 60 * 24 * 365 });
  }
}

export const CycleTheme = cookies => {
  const lightMode = !GetLightMode(cookies);
  saveLightMode(cookies, lightMode);
  // if (window && window.location) {
  //   window.location.reload();
  // }
}

export const hsl = (h,s,l) => `hsl(${h},${100*s}%,${100*l}%)`;

const getBaseTheme = (cookies, forceLightMode) => {
  const useDarkMode = !forceLightMode && !GetLightMode(cookies);
  
  const primaryHue = 178.6;
  const primaryColor = hsl(primaryHue,.652,.606); //59dcd9
  const secondaryColor = hsl(primaryHue,.652,.4); //23a9a5, original 24a8a6
  const backgroundHue = 220;
  const background_default_dark = hsl(backgroundHue,.267,.198); //252e40
  const background_paper = hsl(0,0,1); //fff
  const background_paper_dark = hsl(backgroundHue,.238,.247); //303a4e
  const background_appBar_dark = hsl(backgroundHue,.292,.094); //11151f
  const background_pane_dark = hsl(backgroundHue,.289,.149); //1b2231
  const background_navRail_dark = hsl(backgroundHue,.29,.122); //161c28
  const background_kanbanColumn_dark = hsl(backgroundHue,.267,.176); //212939
  const background_chip_dark = hsl(backgroundHue,.235,.333); //414e69

  return {
    palette: {
      type: useDarkMode ? "dark" : "light",
      primary: { 
        main: primaryColor,
        contrastText: "#fff",
      },
      secondary: {
        main: secondaryColor,
        contrastText: "#fff",
      },
      background: {
        default_dark: background_default_dark,
        default: useDarkMode ? background_default_dark : hsl(0,0,.94), //f0f0f0
        pane_dark: background_pane_dark,
        pane: useDarkMode ? background_pane_dark : hsl(0,0,.92), //eaeaea
        navRail: useDarkMode ? background_navRail_dark : hsl(0,0,.88), //e0e0e0
        toolBar: useDarkMode ? background_paper_dark : hsl(0,0,1), //fff
        appBar_dark: background_appBar_dark,
        appBar: useDarkMode ? background_appBar_dark : primaryColor,
        paper_dark: background_paper_dark,
        paper: useDarkMode ? background_paper_dark : background_paper,
        treeNodeHighlight: useDarkMode ? background_navRail_dark : hsl(0,0,.8), //ccc
        cardActionBar: useDarkMode ? background_pane_dark : hsl(0,0,.92), //eaeaea
        kanbanColumn: useDarkMode ? background_kanbanColumn_dark : hsl(0,0,.88), //e0e0e0
      },
      text: {
        primary_dark: "#fff", // This should match official value, used in one-off pages like /form submission
        disabled_dark: "hsl(0,0%,100%,50%)",
      },
    },
    typography: {
      // fontFamily: [
      //   "Roboto",
      //   "-apple-system",
      //   "BlinkMacSystemFont",
      //   "Segoe UI",
      //   "Arial",
      //   "sans-serif"
      // ].join(","),
      useNextVariants: true
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          "*::-webkit-scrollbar": {
            width: 10,
            height: 10,
          },
          "*::-webkit-scrollbar-track": {
            // boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
            borderRadius: 6,
            backgroundColor: "rgba(0,0,0,0.2)",
            opacity: 0.3,
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,0.3)",
            borderRadius: 6,
          },
        },
      },
      MuiTableRow: {
        root: {
          '&.Mui-selected': {
            backgroundColor: useDarkMode ? background_default_dark : "#e0e0e0",
            "&:hover": {
              backgroundColor: useDarkMode ? background_default_dark : "#e0e0e0",
            },
          },
        },
      },
      MuiTableCell: {
        root: {
          borderBottom: useDarkMode ? "1px solid rgba(255, 255, 255, 0.12)" : "1px solid rgba(0, 0, 0, 0.12)", // Color should be equivalent to theme.palette.divider
        },
      },
      MuiCard: {
        root: {
          borderRadius:8,
        },
      },
      MuiCardContent: {
        root: {
          '&:last-child': {
            paddingBottom:undefined, // Corrects issue with cards having mixed action area and no action area e.g. roles
          },
        },
      },
      MuiChip: {
        root: {
          backgroundColor: useDarkMode ? background_chip_dark : "#e0e0e0",
        },
      },
      MuiInputAdornment: {
        positionEnd: {
          marginLeft:-8, // This corrects what appears to be unnecessary spacing between an input adornment,
          // like the clear icon to clear an input value, and a system adornment like the calendar picker icon
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: useDarkMode ? background_paper_dark : background_paper,
          color: useDarkMode ? "#eee" : "#222",
        },
      },
      MuiSwitch: {
        switchBase: {
          '&.Mui-checked': {
            color: secondaryColor,
          },
        },
      },
      MuiTabs: {
        "flexContainerVertical": {
          height:"100%",
        },
      },
    },
  };
}

export const GetObjectWithOverrides = (obj, overrides) => {
  Object.keys(overrides).forEach(k => {
    if (obj[k] && typeof obj[k] === "object") {
      GetObjectWithOverrides(obj[k], overrides[k]);
    } else {
      obj[k] = overrides[k];
    }
  });
  return obj;
}

export const GetMuiTheme = (cookies, forceLightMode, themeOverrides) => {
  let theme = getBaseTheme(cookies, forceLightMode);
  if (themeOverrides) {
    theme = GetObjectWithOverrides(theme, themeOverrides);
  }
  return createMuiTheme(theme);
}