import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import UiCore from '../../Components/UiCore';
import Typography from '@material-ui/core/Typography';

import API, {
  GetSupportStatOrganizationCountPathForApi,
  GetSupportStatUserCountPathForApi,
  GetSupportStatActiveUsersPathForApi,
  GetSupportStatBillableUsersPathForApi,
  GetSupportStatUserProvidersPathForApi,
} from '../../Util/api';
import {
  GetSupportOrganizationsPath,
  GetSupportOrganizationsRequiringSubscriptionPath,
  GetSupportUsersPath,
} from '../../Util/PathHelper';

import { NumberWithSeparators } from '../../Util/NumberFormatting';
import classNames from 'classnames';

const styles = theme => ({
  root:{
    padding: theme.spacing(3),
    overflow: "auto",
  },
  group: {
    marginTop:theme.spacing(2),
  },
  elAsButton: {
    cursor:"pointer",
  },
});

class Stats extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      OrganizationCount: 0,
      UserCount: 0,
      ActiveUsersStat: null,
      BillableUsersStat: null,
      UserProvidersStat: null,
      UserProviderTotal:0,
      ShowProgressIndicatorImmediately: false,
    }
  }

  loadStats = () => {
    this.setState({ShowProgressIndicatorImmediately:true});

    let completeCounts = {
      OrganizationCount: false,
      UserCount: false,
      ActiveUsersStat: false,
      BillableUsersStat: false,
      UserProvidersStat: false,
    };
    const hideProgressIndicatorOnComplete = () => {
      const keys = Object.keys(completeCounts);
      for (let i = 0; i < keys.length; i++) {
        if (!completeCounts[keys[i]]) {
          return false;
        }
      }
      this.setState({ShowProgressIndicatorImmediately:false});
    }
    API.get(GetSupportStatOrganizationCountPathForApi())
      .then(resp => {
        this.setState({
          OrganizationCount: resp.data.Total,
        });
        completeCounts.OrganizationCount = true;
        hideProgressIndicatorOnComplete();
      })
      .catch(this.handleApiError);
    
    API.get(GetSupportStatUserCountPathForApi())
      .then(resp => {
        this.setState({
          UserCount: resp.data.Total,
        });
        completeCounts.UserCount = true;
        hideProgressIndicatorOnComplete();
      })
      .catch(this.handleApiError);

    API.get(GetSupportStatActiveUsersPathForApi(),{ params: { pageSize: 1 }})
      .then(resp => {
        if (resp.data.length) {
          this.setState({
            ActiveUsersStat: resp.data[0],
          });
        }
        completeCounts.ActiveUsersStat = true;
        hideProgressIndicatorOnComplete();
      })
      .catch(this.handleApiError);

    API.get(GetSupportStatBillableUsersPathForApi(),{ params: { pageSize: 1 }})
      .then(resp => {
        if (resp.data.length) {
          this.setState({
            BillableUsersStat: resp.data[0],
          });
        }
        completeCounts.BillableUsersStat = true;
        hideProgressIndicatorOnComplete();
      })
      .catch(this.handleApiError);

    API.get(GetSupportStatUserProvidersPathForApi(),{ params: { pageSize: 1 }})
      .then(resp => {
        if (resp.data.length) {
          const UserProvidersStat = resp.data[0];
          let UserProviderTotal = 0;
          Object.keys(UserProvidersStat).forEach(k => {
            if (k.startsWith("UserProvider_")) {
              UserProviderTotal += parseInt(UserProvidersStat[k]);
            }
          });
          this.setState({
            UserProvidersStat,
            UserProviderTotal,
          });
        }
        completeCounts.UserProvidersStat = true;
        hideProgressIndicatorOnComplete();
      })
      .catch(this.handleApiError);
  }

  roundTo = (n, digits) => {
    if (digits === undefined) {
      digits = 0;
    }

    const multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    return Math.round(n) / multiplicator;
  }

  getUserProviderPct = provider => {
    const s = {...this.state.UserProvidersStat};
    if (s && s[provider] !== undefined && this.state.UserProviderTotal) {
      const v = 100 * (s[provider]/this.state.UserProviderTotal);
      return `(${this.roundTo(v, 1)}%)`;
    }
  }

  handleApiError = err => {
    this.setState({
      ApiError: err,
      ShowProgressIndicatorImmediately: false,
    });
  }

  componentDidMount() {
    this.loadStats();
  }

  render() {
    const {
      OrganizationCount,
      UserCount,
      ActiveUsersStat,
      BillableUsersStat,
      UserProvidersStat,
      ApiError,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      classes,
      // theme,
      history,
    } = this.props;

    const organizationCount = (OrganizationCount)
      ? (
        <Typography variant="h5"
          className={classes.elAsButton}
          onClick={() => history.push(GetSupportOrganizationsPath())}>
          {NumberWithSeparators(OrganizationCount)} total organizations
        </Typography>
      ) : null;
    const userCount = (UserCount)
      ? (
        <Typography variant="h5"
          className={classes.elAsButton}
          onClick={() => history.push(GetSupportUsersPath())}>
          {NumberWithSeparators(UserCount)} total users
        </Typography>
      ) : null;
    const activeUsersStat = (ActiveUsersStat)
      ? (
        <div className={classNames(classes.group, classes.elAsButton)}
          onClick={() => history.push(GetSupportUsersPath())}>
          <Typography variant="h6">Active Users</Typography>
          <Typography variant="body1">{NumberWithSeparators(ActiveUsersStat.ActiveUsersLast30Days)} in the last 30 days</Typography>
          <Typography variant="body1">{NumberWithSeparators(ActiveUsersStat.ActiveUsersLast60Days)} in the last 60 days</Typography>
          <Typography variant="body1">{NumberWithSeparators(ActiveUsersStat.ActiveUsersLast90Days)} in the last 90 days</Typography>
        </div>
      ) : null;
    const billableUsersStat = (BillableUsersStat)
      ? (
        <div className={classNames(classes.group, classes.elAsButton)}
          onClick={() => history.push(GetSupportOrganizationsRequiringSubscriptionPath())}>
          <Typography variant="h6">Billable Users</Typography>
          <Typography variant="body1">{NumberWithSeparators(BillableUsersStat.BillableUsersActive)} active</Typography>
          <Typography variant="body1">{NumberWithSeparators(BillableUsersStat.BillableUsersExpired)} expired</Typography>
        </div>
      ) : null;

    const userProvidersStat = (UserProvidersStat)
      ? (
        <div className={classes.group}>
          <Typography variant="h6">Sign-in Providers</Typography>
          <Typography variant="body1">{NumberWithSeparators(UserProvidersStat.UserProvider_Apple)} Apple {this.getUserProviderPct("UserProvider_Apple")}</Typography>
          <Typography variant="body1">{NumberWithSeparators(UserProvidersStat.UserProvider_Google)} Google {this.getUserProviderPct("UserProvider_Google")}</Typography>
          <Typography variant="body1">{NumberWithSeparators(UserProvidersStat.UserProvider_LinkedIn)} LinkedIn {this.getUserProviderPct("UserProvider_LinkedIn")}</Typography>
          <Typography variant="body1">{NumberWithSeparators(UserProvidersStat.UserProvider_Microsoft)} Microsoft {this.getUserProviderPct("UserProvider_Microsoft")}</Typography>
          <Typography variant="body1">{NumberWithSeparators(UserProvidersStat.UserProvider_Email)} Email {this.getUserProviderPct("UserProvider_Email")}</Typography>
        </div>
      ) : null;

    const content = (
      <div className={classes.root}>
        {organizationCount}
        {userCount}
        {activeUsersStat}
        {billableUsersStat}
        {userProvidersStat}
      </div>
    );

    return (
      <UiCore
        title="Support: Stats"
        content={content}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
        apiError={ApiError}
      />
    );
  }
}

Stats.propTypes = {
}

export default withStyles(styles, {withTheme: true})(Stats);
