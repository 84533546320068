import { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import {
  GetSubTaskComponentClasses,
  GetSubTaskComponent,
} from '../Util/Task';

import { DragSource, DropTarget } from 'react-dnd';

/**
 * Implements the drag source contract.
 */
const subTaskSource = {
  canDrag(props) {
    return !props.disabled;
  },
  beginDrag(props, monitor, component) {
    props.onStartMoveSubTask(props.SubTaskIndex);
    const SubTask = props.Task.SubTasks[props.SubTaskIndex];
    return {
      SubTask,
      // onDropSubTask: props.onDropSubTask,
    };
  },
  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      props.onAbortMoveSubTask();
    } else {
      props.onEndMoveSubTask();
    }
  }
};

/**
 * Specifies the drop target contract.
 * All methods are optional.
 */
const subTaskTarget = {
  // drop(props, monitor, component) {
  //   if (monitor.didDrop()) {
  //   //   // If you want, you can check whether some nested
  //   //   // target already handled drop
  //     return;
  //   }

  //   // Obtain the dragged item
  //   const sourceSubTask = monitor.getItem();
  //   sourceSubTask.onDropSubTask();

  //   // You can also do nothing and return a drop result,
  //   // which will be available as monitor.getDropResult()
  //   // in the drag source's endDrag() method
  //   //return { moved: true };
  // },
  hover(props, monitor, component) {
    if (!component)
      return null;

    const sourceClientOffset = monitor.getClientOffset();
    // console.log("sourceClientOffset", sourceClientOffset);

    // Obtain the dragged item
    const sourceSubTask = monitor.getItem();
    props.onMoveSubTask(sourceSubTask.SubTask, sourceClientOffset.y);
  },
}

/**
 * Specifies the props to inject into your component.
 */
function dragCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    // isDragging: monitor.isDragging(),
  };
}

/**
 * Specifies which props to inject into your component.
 */
function dropCollect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    // isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    // canDrop: monitor.canDrop(),
    // itemType: monitor.getItemType()
  };
}

const styles = theme => ({
  ...GetSubTaskComponentClasses(theme),
});

class DraggableTaskSubTask extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  render() {
    const { 
      theme,
      classes,
      connectDragSource,
      connectDropTarget,
      Task,
      SubTaskIndex,
      onSetSubTaskTextFieldRef,
      onSubTaskChanged,
      onSubTaskAddedAfter,
      disabled,
    } = this.props;

    const SubTask = Task.SubTasks[SubTaskIndex];
    
    return GetSubTaskComponent(classes, theme, SubTask, disabled, false, SubTaskIndex, connectDragSource, connectDropTarget,
      onSetSubTaskTextFieldRef, onSubTaskChanged, onSubTaskAddedAfter);
  }
}

DraggableTaskSubTask.propTypes = {
  classes: PropTypes.object.isRequired,
  Task: PropTypes.object.isRequired,
  SubTaskIndex: PropTypes.number.isRequired,
  onSetSubTaskTextFieldRef: PropTypes.func.isRequired,
  onSubTaskChanged: PropTypes.func.isRequired,
  onSubTaskAddedAfter: PropTypes.func.isRequired,
  onStartMoveSubTask: PropTypes.func.isRequired,
  onMoveSubTask: PropTypes.func.isRequired,
  onAbortMoveSubTask: PropTypes.func.isRequired,
  onEndMoveSubTask: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default DropTarget('DraggableTaskSubTask', subTaskTarget, dropCollect)(DragSource('DraggableTaskSubTask', subTaskSource, dragCollect)(withStyles(styles, {withTheme: true})(DraggableTaskSubTask)));