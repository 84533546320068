import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import DashboardCard from '../DashboardCard';

import Icon from '@material-ui/icons/LabelImportant';
import IconColor from '@material-ui/core/colors/green';

import LineChartIcon from '@material-ui/icons/Timeline';
import BarChartIcon from '@material-ui/icons/BarChart';

import { 
  MultiLineChart,
  ColumnChart,
  // Legend,
} from 'eazychart-react';
import 'eazychart-css';

import ChartTooltip from '../ChartTooltip';

import API,
{
  GetSignatureCompletionsStatsPathForApi,
} from '../../Util/api';
import {
  // GetProjectNeedSignaturePath,
} from '../../Util/PathHelper';
import {
  GetStatUTCKey,
} from '../../Util/DashboardWidgets';

import green from '@material-ui/core/colors/green';

import dateformat from 'dateformat';

class LineChartTooltip extends Component {
  render() {
    const {
      datum,
    } = this.props;

    return (
      <ChartTooltip {...this.props}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            {`Date: ${datum && datum["Date"]}`}
          </Grid>
          <Grid item>
            {`Completions: ${datum && datum["Completions"]}${(datum && datum.DataAsOf) ? " as of " + datum.DataAsOf : ""}`}
          </Grid>
        </Grid>
      </ChartTooltip>
    );
  }
}

const styles = theme => ({
  chart: {
    display:"flex",
    justifyContent:"center",
    "& .ez-legend": {
      paddingTop:4,
    },
    "& .ez-legend-key": {
      // display:"flex",
      // alignItems:"center",
      marginRight: theme.spacing(2),
    },
    "& .ez-legend-box": {
      marginBottom:2,
    },
    "& .ez-legend-text": {
      marginLeft:theme.spacing(1),
      marginTop:2,
    },
    "& .ez-axis-path": {
      // stroke:(theme.palette.type === "dark") ? "white" : undefined,
      stroke:"transparent",
    },
    "& .ez-axis-tick": {
      // stroke:(theme.palette.type === "dark") ? "white" : undefined,
      stroke:"transparent",
    },
    "& .ez-axis-tick-text": {
      fontSize:8,
      fill:(theme.palette.type === "dark") ? "white" : undefined,
    },
    "& .ez-grid-line": {
      stroke:(theme.palette.type === "dark") ? "white" : undefined,
      opacity:0.1,
    },
  },
  chartSwitcher: {
    pointerEvents:"auto",
    position:"absolute",
    bottom:0,
    right:0,
  },
});

class SignatureCompletionsWidget extends Component {
  constructor(props) {
    super(props);

    let ChartType;
    if (props.DashboardWidget.JsonData) {
      const j = JSON.parse(props.DashboardWidget.JsonData);
      if (j && j.ChartType) {
        ChartType = j.ChartType;
      }
    }
   
    this.state = {
      LoadComplete: false,
      Stats: props.samples || [],
      DashboardWidget: props.DashboardWidget,
      ChartType,
    }
  }

  loadStats() {
    API.get(GetSignatureCompletionsStatsPathForApi(this.props.organizationId, 
      this.props.DashboardWidget.ProjectID))
      .then(resp => {
        let Stats = resp.data.Stats;
        // Ignore showing stats for tomorrow (local timezone is negative UTC)
        Stats = Stats.filter(s => new Date(s.Date.substr(0, s.Date.length-1)) <= new Date());
        this.setState({
          Stats,
          LoadComplete: true,
        });
      })
      .catch(this.props.onApiError);
  }

  handleSwitchChart = e => {
    e.stopPropagation();

    let ChartType;
    switch (this.state.ChartType) {
      case "Bar":
        ChartType = "Line";
        break;
      case "Line":
      default:
        ChartType = "Bar";
        break;
    }
      
    let DashboardWidget = {
      ...this.state.DashboardWidget,
      JsonData: JSON.stringify({
        ChartType,
      }),
    };
    this.setState({
      ChartType,
      DashboardWidget,
    });
    if (!this.props.isSample && this.props.onUpdateDashboardWidget) {
      this.props.onUpdateDashboardWidget(DashboardWidget);
    }
  }

  handleCardAction = () => {
    // const dw = this.props.DashboardWidget;
    // this.props.history.push(GetProjectNeedSignaturePath(dw.ProjectID));
  }

  componentDidMount() {
    if (!this.props.samples) {
      this.loadStats();
    }
  }

  render() {
    const {
      LoadComplete,
      Stats,
      DashboardWidget,
      ChartType,
    } = this.state;
    const { 
      classes,
      theme,
      isSample,
      ...passThroughProps
    } = this.props;

    let noStatsMessage;
    let chart;
    const maxPoints = 7;
    if (Stats && Stats.length > 0) {
      const dataAsOf = dateformat(Stats[0].CreatedOn, "m/d/yy h:MM:ss TT");
      let chartData = [];
      const maxI = Math.min(maxPoints, Stats.length);
      for (let i = 0; i < maxI; i++) {
        const s = Stats[i];
        chartData.push({
          Index: (maxI - i - 1),
          Date: dateformat(s.Date, "m/d/yy", true),
          Completions: s[GetStatUTCKey()],
          DataAsOf: (i <= 1) ? dataAsOf : undefined,
        });
      }
      switch (ChartType) {
      case "Bar":
        const barChartData = chartData.reverse();
        chart = (
          <ColumnChart
            dimensions={{
              width:260,
              height:220,
            }}
            padding={{
              top:10,
              bottom:40,
              left:48,
              right:24,
            }}
            grid={{
              // directions: [],
            }}
            xAxis={{
              domainKey: "Index",
              tickFormat: i => {
                const iFinder = barChartData.filter(d => d.Index === i);
                if (iFinder.length) {
                  return dateformat(iFinder[0].Date, "m/dd/yy");
                }
                return "";
              },
            }}
            yAxis={{
              domainKey: "Completions",
              tickFormat: i => {
                if (i === Math.round(i)) {
                  return i.toString();
                }
                return "";
              },
            }}
            marker={{
              hidden:false,
              radius: 4,
            }}
            colors={
              [
                green[500],
              ]
            }
            data={barChartData}
            scopedSlots={{
              // LegendComponent: Legend,
              TooltipComponent: (!DashboardWidget.IsDragging) ? LineChartTooltip : null,
            }}
          />
        );
        break;
      case "Line":
      default:
        chart = (
          <MultiLineChart
            dimensions={{
              width:260,
              height:220,
            }}
            padding={{
              top:10,
              bottom:40,
              left:48,
              right:24,
            }}
            grid={{
              // directions: [],
            }}
            xAxis={{
              domainKey: "Index",
              tickFormat: i => {
                const iFinder = chartData.filter(d => d.Index === i);
                if (iFinder.length) {
                  return dateformat(iFinder[0].Date, "m/dd/yy");
                }
                return "";
              },
            }}
            yAxis={{
              domainKeys: [
                "Completions",
              ],
              tickFormat: i => {
                if (i === Math.round(i)) {
                  return i.toString();
                }
                return "";
              },
            }}
            marker={{
              hidden:false,
              radius: 4,
            }}
            colors={
              [
                green[500],
              ]
            }
            data={chartData}
            scopedSlots={{
              // LegendComponent: Legend,
              TooltipComponent: (!DashboardWidget.IsDragging) ? LineChartTooltip : null,
            }}
          />
        );
        break;
      }      
    } else if (LoadComplete) {
      noStatsMessage = (
        <Typography variant="body1" style={{
          display:"flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            textAlign:"center",
        }}>
          There are no statistics at this time.
        </Typography>
      );
    }

    const divChart = (chart)
      ? (
        <div className={classes.chart}
          style={{
            pointerEvents:(!isSample) ? "auto" : undefined,
          }}
        >
          {chart}
        </div>
      ) : null;

    let chartSwitcherIcon;
    let chartSwitcherTitle;
    switch (ChartType) {
      case "Bar":
        chartSwitcherTitle = "Switch to line chart";
        chartSwitcherIcon = <LineChartIcon />;
        break;
      case "Line":
      default:
        chartSwitcherTitle = "Switch to bar chart";
        chartSwitcherIcon = <BarChartIcon />;
        break;
    }

    const chartSwitcher = (
      <Tooltip title={chartSwitcherTitle}>
        <IconButton className={classes.chartSwitcher}
          onClick={this.handleSwitchChart}
        >
          {chartSwitcherIcon}
        </IconButton>
      </Tooltip>
    );

    return (
      <DashboardCard
        title={`Signature Completions${(DashboardWidget && DashboardWidget.ProjectName) 
          ? ": " + DashboardWidget.ProjectName
          : ""
        }`}
        // When we have an area for displaying signed documents, we need to adjust this.handleCardAction()
        // onCardAction={this.handleCardAction}
        titleIcon={<Icon style={{color: IconColor[500]}} />}
        noFadeout
        xs={12}
        sm={6}
        md={6}
        lg={2}
        xl={2}
        showSampleOverlay={isSample}
        useSmallTitle={!isSample}
        {...passThroughProps}
        DashboardWidget={DashboardWidget}
      >
        {divChart}
        {chartSwitcher}
        {noStatsMessage}
      </DashboardCard>
    );
  }
}

SignatureCompletionsWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  DashboardWidget: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  onUpdateDashboardWidget: PropTypes.func,
  samples: PropTypes.array,
  isSample: PropTypes.bool,
};

export default withStyles(styles, {withTheme: true})(SignatureCompletionsWidget);