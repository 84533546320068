import _objectSpread from '@babel/runtime/helpers/esm/objectSpread2';
import _applyDecoratedDescriptor from '@babel/runtime/helpers/esm/applyDecoratedDescriptor';
import {
  extension,
  ExtensionPriority,
  command,
  NodeExtension,
  ExtensionTag,
  isElementDomNode,
  omitExtraAttributes,
} from '@remirror/core';

var MERGEFIELDPLACEHOLDERID_DATA_ATTRIBUTE = 'data-remirror-mergeFieldPlaceholderId';
var MERGEFIELDPLACEHOLDERLABEL_DATA_ATTRIBUTE = 'data-remirror-mergeFieldPlaceholderLabel';

function getPlaceholderFromFieldLabel(fieldLabel) {
  return "<<Field: "+fieldLabel+">>";
}

var _dec, _dec2, _class, _class2;
var MergeFieldPlaceholderExtension = (_dec = extension({
  defaultPriority: ExtensionPriority.Low,
}), _dec2 = command(), _dec(_class = (_class2 = class MergeFieldPlaceholderExtension extends NodeExtension {
  get name() {
    return 'mergeFieldPlaceholder';
  }

  createTags() {
    return [ExtensionTag.InlineNode, ExtensionTag.LastNodeCompatible];
  }

  createNodeSpec(extra, override) {
    var _override$parseDOM;

    return _objectSpread(_objectSpread({
      selectable: true,
      content: "text*",
      marks: "_",
      inline: true,
      atom: true,
    }, override), {}, {
      attrs: _objectSpread(_objectSpread({}, extra.defaults()), {}, {
        fieldId: "",
        fieldLabel: "",
      }),
      parseDOM: [{
        tag: "span[".concat(MERGEFIELDPLACEHOLDERID_DATA_ATTRIBUTE),
        getAttrs: node => {
          if (!isElementDomNode(node)) {
            return;
          }

          var fieldId = node.getAttribute(MERGEFIELDPLACEHOLDERID_DATA_ATTRIBUTE);
          var fieldLabel = node.getAttribute(MERGEFIELDPLACEHOLDERLABEL_DATA_ATTRIBUTE);
          return _objectSpread(_objectSpread({}, extra.parse(node)), {}, {
            fieldId,
            fieldLabel,
          });
        }
      }, ...((_override$parseDOM = override.parseDOM) !== null && _override$parseDOM !== void 0 ? _override$parseDOM : [])],
      toDOM: node => {
        var _ref = omitExtraAttributes(node.attrs, extra),
            fieldId = _ref.fieldId,
            fieldLabel = _ref.fieldLabel;

        return ['span', {
          contentEditable: false,
          [MERGEFIELDPLACEHOLDERID_DATA_ATTRIBUTE]: fieldId,
          [MERGEFIELDPLACEHOLDERLABEL_DATA_ATTRIBUTE]: fieldLabel,
        }, 0];
      }
    });
  }

  insertMergeFieldPlaceholder(fieldId, fieldLabel) {
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return props => {
      if (!fieldId || !fieldLabel) {
        // Nothing to do here since no fieldId, fieldLabel provided.
        return false;
      }

      const state = props.state;
      const schema = state.schema;
      const textNode = schema.text(getPlaceholderFromFieldLabel(fieldLabel));
      return this.store.commands.insertNode.original(this.type, {
        attrs: {
          fieldId,
          fieldLabel
        },
        content: textNode,
        selection: options.selection,
      })(props);
    };
  }
  
}, (_applyDecoratedDescriptor(_class2.prototype, "insertMergeFieldPlaceholder", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "insertMergeFieldPlaceholder"), _class2.prototype)), _class2)) || _class);

export { MergeFieldPlaceholderExtension };