import { DefaultFormTemplatePageSize } from '../Model/FormTemplatePageSizes';
import FormTemplateRenderType from '../Model/FormTemplateRenderType';

export const FormTemplate = {
    ID: "",
    Name: "",
    PageSize: DefaultFormTemplatePageSize.PageSize,
    PageWidth: DefaultFormTemplatePageSize.PageWidth,
    PageHeight: DefaultFormTemplatePageSize.PageHeight,
    Aspect: DefaultFormTemplatePageSize.Aspect,
    RenderType: FormTemplateRenderType.FormTemplateRenderType_RenderNew.Type,
}

export const GetNewFormTemplate = (Name, FormTemplateSourceID, RenderType) => {
	if (!RenderType) {
        RenderType = FormTemplateRenderType.FormTemplateRenderType_RenderNew.Type;
    }
    return {
		...FormTemplate,
		Name,
        FormTemplateSourceID,
        RenderType,
	};
}

export default FormTemplate;