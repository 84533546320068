import React from 'react';

import Grid from '@material-ui/core/Grid';

import {
  GetDocumentUploadFieldImageUploadsPathForApi,
  GetUserOrganizationProjectDocumentFolderUploadFieldImageUploadsPathForApi,
  GetUserOrganizationProjectTaskUploadFieldImageUploadsPathForApi,
  GetUserOrganizationProjectApprovalTaskUploadFieldImageUploadsPathForApi,
  GetUserOrganizationProjectApprovalAssetItemUploadFieldImageUploadsPathForApi,
  GetUserOrganizationProjectApprovalAssetItemTaskUploadFieldImageUploadsPathForApi,
} from '../Util/api';

import { GetAllFieldsPromise } from '../Util/Fields';
import {
  GetComposedFieldListLabelsAndValues,
  // GetDedupedValues,
  GetUpdatedFieldObjectForValueChange,
  GetUpdatedFieldObjectForImageChange,
  HandleGetFieldListItemsFilterPromise,
  HandleFieldListItemAdd,
  GetDependentFieldsParentValue,
  GetDependentFieldsParentField,
  GetEffectiveSelectionListIsDependent,
  GetEffectiveFieldLabelOrName,
  GetFieldPassesValidation,
} from '../Util/Field';
import { RecurseDependentSelectionListsDown } from '../Util/Fields';
import ProgressIndicator from '../Components/ProgressIndicator';
import MultiUseDialog from '../Components/MultiUseDialog';
import GridWithConditionalRendering from '../Components/GridWithConditionalRendering';
import FieldInput from '../Components/FieldInput';


const handleSetShowFieldGatherDialog = (onSetState, ShowFieldGatherDialog) => {
  onSetState({ShowFieldGatherDialog});
}

const allRequiredFieldsComplete = (onGetState, fieldsForGather) => {
  if (!fieldsForGather) {
    fieldsForGather = [...onGetState().FieldsForGather];
  }
  return fieldsForGather
    .filter(f => f.Required)
    .filter(f => !GetFieldPassesValidation(f))
    .length === 0;
}

const compareFieldsByRank = (a, b) => {
  if (a.Rank < b.Rank) {
    return -1;
  }
};

export const HandlePreCaptureFieldGathering = (organizationId, projectId,
  onApiError, onGetState, onSetState, captureItemCount, onPreGatherFields, onCapture,
  documentFolderIdForDocumentFolderAssignmentContext, taskIdForTaskAssignmentContext,
  approvalIdForApprovalAssignmentContext, assetItemForAssetItemContext) => {

  // Include initial state here
  onSetState({
    ShowFieldGatherDialog: false,
    FieldsForGather: [],
    ShowFieldGatheringProgressIndicator: true,
  });
  GetAllFieldsPromise(organizationId, projectId, null, documentFolderIdForDocumentFolderAssignmentContext,
    taskIdForTaskAssignmentContext, approvalIdForApprovalAssignmentContext, assetItemForAssetItemContext)
    .then(resp => {
      const FieldsForGather = resp.data.Fields
        .map(f => ({
          ...f,
          Value: "",
          Values: "",
        }))
        .sort(compareFieldsByRank);

      // If no fields or no required fields, proceed with capture
      if (!FieldsForGather.length || allRequiredFieldsComplete(onGetState, FieldsForGather)) {
        return onCapture({});
      }

      onSetState({FieldsForGather});

      // If more than one file, abort if there is at least one required field
      const requiredFields = FieldsForGather.filter(f => f.Required);
      if (captureItemCount > 1 && requiredFields.length) {
        return onApiError("One or more project fields are marked required. Please capture one file at a time.")
      }

      // If one file, request field values
      if (captureItemCount === 1) {
        onPreGatherFields(FieldsForGather);
        return handleSetShowFieldGatherDialog(onSetState, true);
      }
    })
    .catch(onApiError)
    .finally(() => {
      onSetState({ShowFieldGatheringProgressIndicator:false});
    });
}

const handleFieldGatheringComplete = (onGetState, onSetState, onCapture) => {
  handleSetShowFieldGatherDialog(onSetState, false);

  let fieldIDsAndValues = {};
  onGetState().FieldsForGather.forEach(f => {
    let values = [];
    switch (f.Type) {
    case "FieldType_Image":
      values = [f.ImageObjectName];
      break;
    default:
      if (f.Values) {
        if (Array.isArray(f.Values)) {
          values = f.Values;
        } else if (typeof f.Values === "string") {
          values = JSON.parse(f.Values);
        }
      } else if (f.Value) {
        values = [f.Value];
      }
      break;
    }

    fieldIDsAndValues = {
      ...fieldIDsAndValues, 
      [f.ID]: values,
    };
  });

  onCapture(fieldIDsAndValues);
}

const handleFieldValueChange = (onGetState, onSetState, fieldID, isNewItem) => (event, selectedOptions) => {
  let FieldsForGather = [...onGetState().FieldsForGather];
  const field = FieldsForGather.find(f => f.ID === fieldID);

  if (field) {
    GetUpdatedFieldObjectForValueChange(
      field,
      event, selectedOptions);

    // Clear any fields that are selection lists and a child of the current field
    clearAndReloadDependentFieldValues(field, FieldsForGather);

    if (field.DisplaySelectionList) {
      field.ListValues = GetComposedFieldListLabelsAndValues(field);
      if (field.SaveNewSelectionListItems && isNewItem) {
        // Cause the selection list to be reloaded
        field.UpdateId = new Date();
      }
    }
    onSetState({FieldsForGather});
  }
}

const clearAndReloadDependentFieldValues = (field, fields) => {
  if (!document || !field) {
    return;
  }

  RecurseDependentSelectionListsDown(field, fields,
    f => f.ID,
    f => f.ParentFieldID,
    f => {
      if (!GetEffectiveSelectionListIsDependent(f)) {
        return;
      }
      // Clear the field's value
      GetUpdatedFieldObjectForValueChange(f, undefined, []);
      
      // Cause the selection list to be reloaded
      f.UpdateId = new Date();
    }
  );
}

const handleFieldImageChange = (onGetState, onSetState, fieldID) => (imageObjectName, imageSignedUrl) => {
  let FieldsForGather = [...onGetState().FieldsForGather];
  const field = FieldsForGather.find(f => f.ID === fieldID);
  GetUpdatedFieldObjectForImageChange(
    field,
    imageObjectName, imageSignedUrl);
  
  // Clear any fields that are selection lists and a child of the current field
  clearAndReloadDependentFieldValues(field, FieldsForGather);

  onSetState({FieldsForGather});
}

export const GetPreCaptureFieldGatheringContent = (organizationId, projectId,
  onGetState, onSetState, onApiError, onAlert, onCapture, additionalBodyText, 
  documentFolderIdForDocumentFolderAssignmentContext, taskIdForTaskAssignmentContext,
  approvalIdForApprovalAssignmentContext, assetItemForAssetItemContext) => {
  const {
    FieldsForGather,
    ShowFieldGatherDialog,
    ShowFieldGatheringProgressIndicator,
  } = onGetState();

  const progressIndicator = (ShowFieldGatheringProgressIndicator)
    ? ( <ProgressIndicator /> )
    : null;

  const getImageUploadUri = fieldId => {
    let uri;
    if (documentFolderIdForDocumentFolderAssignmentContext) {
      uri = GetUserOrganizationProjectDocumentFolderUploadFieldImageUploadsPathForApi(organizationId, projectId, 
        documentFolderIdForDocumentFolderAssignmentContext, fieldId);
    } else if (approvalIdForApprovalAssignmentContext) {
      if (assetItemForAssetItemContext) {
        if (taskIdForTaskAssignmentContext) {
          uri = GetUserOrganizationProjectApprovalAssetItemTaskUploadFieldImageUploadsPathForApi(organizationId, projectId,
            approvalIdForApprovalAssignmentContext, assetItemForAssetItemContext.AssetID, 
            assetItemForAssetItemContext.ID, taskIdForTaskAssignmentContext, fieldId);
        } else {
          uri = GetUserOrganizationProjectApprovalAssetItemUploadFieldImageUploadsPathForApi(organizationId, projectId, 
            approvalIdForApprovalAssignmentContext, assetItemForAssetItemContext.AssetID, 
            assetItemForAssetItemContext.ID, fieldId);
        }
      } else if (taskIdForTaskAssignmentContext) {
        uri = GetUserOrganizationProjectApprovalTaskUploadFieldImageUploadsPathForApi(organizationId, projectId, 
          approvalIdForApprovalAssignmentContext, taskIdForTaskAssignmentContext, fieldId);
      }
    } else if (taskIdForTaskAssignmentContext) {
      uri = uri = GetUserOrganizationProjectTaskUploadFieldImageUploadsPathForApi(organizationId, projectId, 
          taskIdForTaskAssignmentContext, fieldId);
    } else {
      uri = GetDocumentUploadFieldImageUploadsPathForApi(organizationId, projectId, fieldId);
    }
    return uri;
  }

  const fieldGatheringDialog = (ShowFieldGatherDialog) ? (
    <MultiUseDialog Details={{
      Open: true,
      Title: "Field values",
      DialogWidth: "sm",
      FullWidth: true,
      BodyContent: (
        <Grid container spacing={3} direction="column" style={{marginBottom:0}}>
          <Grid item>
            <span>One or more fields are required to add a document.</span>
            {(additionalBodyText) ? (<span> {additionalBodyText}</span>) : null}
          </Grid>
          {
            FieldsForGather.map((f, index) => {
              const parentField = GetDependentFieldsParentField(f, FieldsForGather, f => f.ID);
              const parentFieldLacksValue = GetEffectiveSelectionListIsDependent(f) && parentField && !parentField.Value;
              return (
                <GridWithConditionalRendering item key={f.ID}
                  shouldRender={FieldInput.ShouldRender(f, FieldsForGather)}
                >
                  <FieldInput
                    organizationId={organizationId}
                    projectId={projectId}
                    onApiError={onApiError}
                    onAlert={onAlert}
                    Field={f}
                    fields={FieldsForGather}
                    key={f.ID}
                    Index={index}
                    padRightMarginWhenDragAndDropDisabled
                    onValueChange={handleFieldValueChange(onGetState, onSetState, f.ID)}
                    onImageChange={handleFieldImageChange(onGetState, onSetState, f.ID)}
                    fieldImageUploadReservationUri={getImageUploadUri(f.ID)}
                    onGetSelectionListFilterPromise={
                      (() => {
                        const parentFieldValue = GetDependentFieldsParentValue(f, FieldsForGather, f => f.ID);
                        return HandleGetFieldListItemsFilterPromise(organizationId, projectId, f.ID, onApiError, 
                          undefined, undefined, undefined, parentFieldValue, documentFolderIdForDocumentFolderAssignmentContext,
                          taskIdForTaskAssignmentContext, approvalIdForApprovalAssignmentContext, assetItemForAssetItemContext);
                      })()
                    }
                    onSelectionListItemAdd={HandleFieldListItemAdd(
                      organizationId, projectId, f.ID, f, 
                      (fieldId, isNewItem) => handleFieldValueChange(onGetState, onSetState, fieldId, isNewItem),
                      onApiError
                    )}
                    disabled={parentField && parentFieldLacksValue}
                    parentFieldLacksValue={parentFieldLacksValue}
                    parentFieldName={GetEffectiveFieldLabelOrName(parentField, false)}
                  />
                </GridWithConditionalRendering>
              );
            })
          }
        </Grid>
      ),
      IsConfirmation: true,
      DisableConfirmButton: !allRequiredFieldsComplete(onGetState),
      ConfirmLabel: "PROCEED",
      ConfirmCallback: () => handleFieldGatheringComplete(onGetState, onSetState, onCapture),
      CancelCallback: () => handleSetShowFieldGatherDialog(onSetState, false),
      CloseCallback: () => handleSetShowFieldGatherDialog(onSetState, false),
    }} />
  ) : null;

  return (
    <React.Fragment>
      {progressIndicator}
      {fieldGatheringDialog}
    </React.Fragment>
  );
}