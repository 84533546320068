import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid';
// import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';

import {
  DefaultWidgetHeight,
} from '../Util/DashboardWidgets';

import { DragSource, DropTarget } from 'react-dnd';
import { IsMobile } from '../Util/MobileDetector';
import classNames from 'classnames';
import debounce from 'es6-promise-debounce';

/**
 * Implements the drag source contract.
 */
const dashboardCardSource = {
  canDrag(props) {
    return !IsMobile();
  },
  beginDrag(props, monitor, component) {
    props.onStartMoveDashboardCard(props.DashboardWidget.ID);
    return {
      DashboardWidget: props.DashboardWidget,
      onDropDashboardCard: props.onDropDashboardCard,
    };
  },
  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      if (props.onAbortMoveDashboardCard) {
        props.onAbortMoveDashboardCard();
      }
    }
    props.onEndMoveDashboardCard();
  }
};

/**
 * Specifies the drop target contract.
 * All methods are optional.
 */
const dashboardCardTarget = {
  drop(props, monitor, component) {
    if (monitor.didDrop()) {
    //   // If you want, you can check whether some nested
    //   // target already handled drop
      return;
    }

    // Obtain the dragged item
    const sourceDashboardCard = monitor.getItem();
    sourceDashboardCard.onDropDashboardCard();

    // You can also do nothing and return a drop result,
    // which will be available as monitor.getDropResult()
    // in the drag source's endDrag() method
    //return { moved: true };
  },
  hover(props, monitor, component) {
    if (!component)
      return null;

    // Obtain the dragged item  
    const sourceDashboardCard = monitor.getItem();
    const sourceClientOffset = monitor.getClientOffset();
    // const dragRank = sourceDashboardCard.DashboardWidget.Rank;
    // const hoverRank = props.DashboardWidget.Rank;
    props.onMoveDashboardCard(sourceDashboardCard.DashboardWidget, sourceClientOffset);
  },
}

/**
 * Specifies the props to inject into your component.
 */
function dragCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    // isDragging: monitor.isDragging(),
  };
}

/**
 * Specifies which props to inject into your component.
 */
function dropCollect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    //isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    // canDrop: monitor.canDrop(),
    // itemType: monitor.getItemType()
  };
}

const styles = theme => ({
  root: {
    // cursor:"pointer",
    "&:hover $titleButtons": {
      display:"flex",
    },
    position:"relative",
  },
  card: {
    // marginBottom: theme.spacing(2),
    // marginRight: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    // height:"100%", // For widget height factoring
  },
  cardActionArea: {
    // height:210,
    // "&:hover $cardActionArea_focusHighlight": {
    //   opacity:0.25,
    // },
  },
  // cardActionArea_focusHighlight: {
  // },
  cardContent: {
    // marginRight:theme.spacing(2),
    overflow:"hidden",
    overflowWrap: "break-word",
    height:DefaultWidgetHeight,
    position:"relative",
  },
  cardContent_fadeOut: {
    position:"relative",
    "&:after": {
      position: "absolute",
      bottom: 0,
      height: "60%",
      width: "100%",
      content: '""',
      background: `linear-gradient(to top, ${theme.palette.background.paper} 10%, rgba(255,255,255, 0) 90%)`,
      pointerEvents: "none",
    },
  },
  titleButtons: {
    display:(IsMobile()) ? "flex" : "none",
    alignItems:"center",
    marginBottom:8,
  },
  titleDiv: {
    display:"flex",
    height:40,
  },
  titleIconDiv: {
    marginTop:3,
    marginRight:theme.spacing(1),
  },
  sampleOverlay: {
    position:"absolute",
    right:"35%",
    top:"35%",
    transform:"rotate(25deg)",
    opacity:0.5,
  },
});

class DashboardCard extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
    }

    this.WidgetRef = React.createRef();
    this.LastRankTimestamp = new Date();
  }

  handleWindowResize = debounce(() => {
    this.setWidgetHeight();
  }, 250)

  handleRankTimestampChanged = debounce(() => {
    if (this.props.DashboardWidget && this.props.DashboardWidget.RankTimestamp) {
      this.LastRankTimestamp = this.props.DashboardWidget.RankTimestamp;
      this.setWidgetHeight();  
    }
  }, 250)

  setWidgetHeight = debounce(() => {
    if (this.props.isMaxHeightWidget) {
      return;
    }
    const setHeight = height => {
      const card = this.WidgetRef.childNodes[1];
      if (card && card.childNodes) {
        const cardContent = card.childNodes[0];
        // console.log("this card height", card.clientHeight);
        // console.log("this overall height", this.WidgetRef.clientHeight);
        cardContent.style.height = height + 'px';
      }
    }
    if (this.WidgetRef && this.WidgetRef.childNodes) {
      const thisTop = this.WidgetRef.offsetTop;
      const grid = this.WidgetRef.parentNode;
      if (grid) {
        let heightSet = false;
        for (let i = 0; i < grid.childNodes.length; i++) {
          const n = grid.childNodes[i];
          if (n.childNodes.length < 2) {
            continue;
          }
          if (n === this.WidgetRef) {
            continue;
          }
          if (n.offsetTop === thisTop
            && n.getAttribute("data-maxheight")) {
            setHeight(n.childNodes[1].offsetHeight);
            heightSet = true;
            break;
          }
        }
        if (!heightSet) {
          setHeight(DefaultWidgetHeight);
        }
      }
    }
  }, 250)

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  componentDidUpdate(prevProps) {
    if (this.props.DashboardWidget
      && this.props.DashboardWidget.RankTimestamp
      && this.props.DashboardWidget.RankTimestamp !== this.LastRankTimestamp){
      this.handleRankTimestampChanged();
    }
    this.setWidgetHeight();
  }

  render() {
    // const {
    // } = this.state;
    const { 
      classes,
      theme,
      title,
      titleIcon,
      titleButtons,
      titleStyle,
      useSmallTitle,
      showSampleOverlay,
      noFadeout,
      xl, lg,  md, sm, xs,
      children,
      passThroughContent,
      noPointerEvents,
      gridItemStyle,
      cardStyle,
      height,
      onCardAction,
      connectDragSource,
      connectDropTarget,
      // isDragging,
      isDragLayer,
      isMaxHeightWidget,
      onSetWidgetRef,
      DashboardWidget,
    } = this.props;

    let cardContentClasses = [
      classes.cardContent,
    ];
    if (!noFadeout) {
      cardContentClasses.push(classes.cardContent_fadeOut);
    }
    const cardContent = (
      <CardContent className={classNames(...cardContentClasses)}>
        {children}
      </CardContent>
    );

    const cardActionAreaOrContent = (onCardAction)
      ? (
        <CardActionArea
          component="div"
          classes={{
            root: classes.cardActionArea,
            // focusHighlight: classes.cardActionArea_focusHighlight,  
          }}
          disableRipple
          onClick={onCardAction}>
          {cardContent}
        </CardActionArea>
      )
      : cardContent;

    let cardOrPassthrough = (DashboardWidget && DashboardWidget.IsDragging)
      ? null
      : (passThroughContent)
        ? passThroughContent
        : (
          <Card className={classes.card}
            style={{
              height,
              ...cardStyle
            }}>
            {cardActionAreaOrContent}
          </Card>
        );

    const titleButtonsDiv = (titleButtons && titleButtons.length)
      ? (
      <div className={classes.titleButtons}>
        {titleButtons}
      </div>
      ) : null;

    const titleComponent = (
      <div className={classes.titleDiv}>
        <div className={classes.titleIconDiv}
          style={{
            paddingTop: (useSmallTitle) ? 3 : undefined,
          }}
        >
          {titleIcon}
        </div>
        <Typography variant="h6"
          style={{
            flexGrow: 1,
            fontWeight:600,
            marginBottom:theme.spacing(1),
            whiteSpace: "nowrap",
            overflowX: "hidden",
            fontSize:(useSmallTitle) ? 14 : undefined,
            paddingTop:(useSmallTitle) ? 8 : undefined,
            ...titleStyle,
          }}
        >
          {title}
        </Typography>
        {titleButtonsDiv}
      </div>
    );

    const sampleOverlay = (showSampleOverlay)
      ? (
        <div className={classes.sampleOverlay}>
          sample data
        </div>
      ) : null;

    return (
      <Grid item
        xs={(IsMobile()) ? 12 : xs || 3}
        sm={(IsMobile()) ? undefined : sm || 3}
        md={(IsMobile()) ? undefined : md || 3}
        lg={(IsMobile()) ? undefined : lg || 3}
        xl={(IsMobile()) ? undefined : xl || 3}
        data-maxheight={isMaxHeightWidget ? true : undefined}
        className={classes.root}
        ref={instance => {
          connectDropTarget(connectDragSource(ReactDOM.findDOMNode(instance)));
          this.WidgetRef = instance;
          if (onSetWidgetRef) {
            onSetWidgetRef(instance);
          }
        }}
        style={{
          ...gridItemStyle,
          // paddingBottom: 48, // For widget height factoring
          pointerEvents: (noPointerEvents) ? "none" : "",
          backgroundColor: (DashboardWidget && DashboardWidget.IsDragging) ? theme.palette.background.pane : undefined,
          borderRadius: (DashboardWidget && DashboardWidget.IsDragging) ? 8 : undefined,
          opacity: (!isDragLayer && DashboardWidget && DashboardWidget.IsDragging) ? 0 : undefined,
        }}
      >
        {titleComponent}
        {cardOrPassthrough}
        {sampleOverlay}
      </Grid>
    // )
    );
  }
}

DashboardCard.propTypes = {
  classes: PropTypes.object.isRequired,
  // Injected by React DnD:
  // isDragging: PropTypes.bool.isRequired,
  // isOver: PropTypes.bool.isRequired,
  isMaxHeightWidget: PropTypes.bool,
  isDragLayer: PropTypes.bool,
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  title: PropTypes.string.isRequired,
  titleIcon: PropTypes.object.isRequired,
  titleButtons: PropTypes.array,
  titleStyle: PropTypes.object,
  showSampleOverlay: PropTypes.bool,
  useSmallTitle: PropTypes.bool,
  noFadeout: PropTypes.bool,
  passThroughContent: PropTypes.object,
  DashboardWidget: PropTypes.object,
  onSetWidgetRef: PropTypes.func,
  onCardAction: PropTypes.func,
};

export default DropTarget('DashboardCard', dashboardCardTarget, dropCollect)(DragSource('DashboardCard', dashboardCardSource, dragCollect)(withStyles(styles, {withTheme: true})(DashboardCard)));