import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import ProgressIndicator from './ProgressIndicator';

import MultiUseDialog from '../Components/MultiUseDialog';

import {
  GetUserValue,
} from '../Util/Properties';

import dateformat from 'dateformat';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const styles = theme => ({
  grid: {
    paddingTop:theme.spacing(2),
  },
  completed: {
    color: green[500],
    marginBottom:theme.spacing(1),
  },
  pending: {
    color: red[500],
    marginBottom:theme.spacing(1),
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

class DocumentSignatureSessionReviewDialog extends Component {
  constructor(props) {
    super(props);

    this.ControlRefs = [];

    this.state = {
      open: props.open,
      ShowSignatureSessionResetConfirmation: false,
      ShowProgressIndicator: false,
    }
  }

  handleClose = () => {
    this.setState({ open: false });
    if (this.props.closeCallback) {
      this.props.closeCallback();
    }
  }

  handleSetShowSignatureSessionResetConfirmationVisibility = visible => {
    this.setState({
      ShowSignatureSessionResetConfirmation: visible,
    });
  }

  handleResetSignatureSession = () => {
    this.handleSetShowSignatureSessionResetConfirmationVisibility(false);
    this.props.onReset();
  }

  handleApiError = (err, title_optional) => {
    this.setState({
      ShowProgressIndicator: false,
    });
    this.props.onApiError(err, title_optional);
    if (err) {
      setTimeout(() => this.props.onApiError(null), 1);
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      this.setState({open: this.props.open !== false});
    }
  }

  render() {
    const { 
      open,
      ShowSignatureSessionResetConfirmation,
      ShowProgressIndicator,
  	} = this.state;
    const {
      classes,
      // theme,
      documentSignatureSessionPackage,
    } = this.props;

    let dssp = documentSignatureSessionPackage;

    let progressIndicator = null;
    if (ShowProgressIndicator) {
      progressIndicator = (
        <ProgressIndicator />
      );
    }

    let resetSignatureSessionConfirmationDialogDetails = {
      Open:ShowSignatureSessionResetConfirmation,
      IsConfirmation:true,
      Title:"Stop current session?",
      BodyText:"This action cannot be undone.",
      BodyClassName:"warning",
      CancelCallback:() => this.handleSetShowSignatureSessionResetConfirmationVisibility(false),
      CloseCallback:() => this.handleSetShowSignatureSessionResetConfirmationVisibility(false),
      ConfirmCallback:() => this.handleResetSignatureSession(),
    };

    let dialogActions = (
      <DialogActions>
        <Button onClick={() => this.handleSetShowSignatureSessionResetConfirmationVisibility(true)}>
          RESET
        </Button>
        <Button onClick={() => this.handleClose()}>
          CLOSE
        </Button>
      </DialogActions>
    );

    const getRecipientGridItem = r => {
      const signedText = (r.IsComplete)
        ? "signed " + dateformat(r.CompletedOn, "m/d/yy h:MM:ss TT")
        : "";
      return (
        <Grid item key={`r_${r.ID}`} xs={12}>
          {
            GetUserValue(r.AssignmentUserEmail, 
              (r.AssignmentUserName)
                ? `${r.AssignmentUserName} (${r.AssignmentUserEmail}) ${signedText}`
                : `${r.AssignmentUserEmail} ${signedText}`
            )
            
          }
        </Grid>
      );
    }

    let pendingGridItems = dssp.Recipients
      .filter(r => !r.IsComplete)
      .map(r => getRecipientGridItem(r));
    let completedGridItems = dssp.Recipients
      .filter(r => r.IsComplete)
      .map(r => getRecipientGridItem(r));

    let completedGridItem = (completedGridItems.length)
      ? (
        <Grid item>
          <Typography variant="subtitle2" className={classes.completed}>
            Completed
          </Typography>
          <Grid container spacing={2}>
            {completedGridItems}
          </Grid>
        </Grid>
      ) : null;
    let pendingGridItem = (pendingGridItems.length)
      ? (
        <Grid item>
          <Typography variant="subtitle2" className={classes.pending}>
            Pending
          </Typography>
            <Grid container spacing={1}>
              {pendingGridItems}
            </Grid>
        </Grid>
      ) : null;

    let content = (
      <React.Fragment>
        <MultiUseDialog Details={resetSignatureSessionConfirmationDialogDetails} />
        <Grid container spacing={3} direction="column"
          style={{
            marginBottom:0,
          }}
        >
          {completedGridItem}
          {pendingGridItem}
        </Grid>
      </React.Fragment>
    );

    return (
       <Dialog
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        open={open}
        onClose={() => this.handleClose()}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description">
        <DialogTitle id="dialog-title">
          <span>Signatures in progress...</span>
        </DialogTitle>
        <DialogContent>
          {progressIndicator}

          {content}
        </DialogContent>

        {dialogActions}
      </Dialog>
    );
  }
}

export default withStyles(styles, {withTheme: true})(DocumentSignatureSessionReviewDialog);