import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { IsMobile } from '../Util/MobileDetector';

/**
 * Implements the drag source contract.
 */
const listItemSource = {
  canDrag(props) {
    return !IsMobile();
  },
  beginDrag(props) {
    // props.onStartMove();
    return {
      Data: props.Data,
      onDrop: props.onDrop,
      onDrop2: props.onDrop2,
    };
  },
  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      // props.onAbortMove();
    }
    // props.onDrop(monitor.getDropResult(), props.Data);
  }
};

/**
 * Specifies the props to inject into your component.
 */
function dragCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

class DraggableListItem extends Component {
  componentDidMount() {
    if (this.props.hideDragPreview) {
      const { connectDragPreview } = this.props;
      if (connectDragPreview) {
        // Use empty image as a drag preview so browsers don't draw it
        // and we can draw whatever we want on the custom drag layer instead.
        connectDragPreview(getEmptyImage(), {
          // IE fallback: specify that we'd rather screenshot the node
          // when it already knows it's being dragged so we can hide it with CSS.
          captureDraggingState: true,
        })
      }
    }
  }

  render() {
     const { 
       // isDragging,
       connectDragSource,
       Text,
       fontSize,
       Icon,
       onClick,
    } = this.props;

    let listItemIcon = (Icon)
      ? (
        <ListItemIcon>
          {Icon}
        </ListItemIcon>
      ) : null;

    return (
        <ListItem button
          style={{
            cursor:"grab",
          }}
          disableRipple
          onClick={onClick}
          ref={instance => connectDragSource(ReactDOM.findDOMNode(instance))}>
          {listItemIcon}
          <ListItemText primary={Text}
            primaryTypographyProps={{
              style: {
                fontSize,
              },
            }}
          />
        </ListItem>
    );
  }
}

export default DragSource('DraggableListItem', listItemSource, dragCollect)(DraggableListItem)  