import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import FieldInput from './FieldInput';
import {
  HandleGetFieldListItemsFilterPromise,
  HandleFieldListItemAdd,
  GetDependentFieldsParentValue,
  GetDependentFieldsParentField,
  GetEffectiveFieldLabelOrName,
} from '../Util/Field';

import { IsMobile } from '../Util/MobileDetector';

const styles = theme => ({
});

class AssetItemDialogGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  render() {
    // const {
    // } = this.state;
    const {
      // theme,
      // classes,
      organizationId,
      projectId,
      AssetItemFields,
      disabled,
      isWorkflow,
      onGetFieldImageUploadReservationUri,
      onFieldValueChange,
      onFieldImageChange,
      enableDragAndDrop,
      onStartMoveFieldInput,
      onMoveFieldInput,
      onAbortMoveFieldInput,
      onDropFieldInput,
      onApiError,
      onAlert,
      Fields,
    } = this.props;

    const fieldGridItems = AssetItemFields
      .map((aif, i) => {
        const parentField = GetDependentFieldsParentField(aif, AssetItemFields, f => f.FieldID);
        const parentFieldLacksValue = aif.SelectionListIsDependent && parentField && !parentField.Value;
        return (
          <Grid item xs={(IsMobile()) ? 12 : 4} key={aif.ID}>
            <FieldInput Field={aif}
              key={aif.ID}
              Index={i}
              disabled={disabled || (parentField && parentFieldLacksValue)}
              parentFieldLacksValue={parentFieldLacksValue}
              parentFieldName={GetEffectiveFieldLabelOrName(parentField, false)}
              onValueChange={onFieldValueChange(aif.ID)}
              onImageChange={onFieldImageChange(aif.ID)}
              fieldImageUploadReservationUri={onGetFieldImageUploadReservationUri(aif)}
              onGetSelectionListFilterPromise={
                (() => {
                  const parentFieldValue = GetDependentFieldsParentValue(aif, AssetItemFields, f => f.ID);
                  return HandleGetFieldListItemsFilterPromise(organizationId, projectId, aif.FieldID, onApiError, undefined, undefined, undefined, parentFieldValue);
                })()
              }
              onSelectionListItemAdd={HandleFieldListItemAdd(organizationId, projectId, aif.FieldID, aif, onFieldValueChange, onApiError)}
              onApiError={onApiError}
              onAlert={onAlert}
              EnableDragAndDropReordering={enableDragAndDrop}
              onStartMoveFieldInput={onStartMoveFieldInput}
              onMoveFieldInput={fieldTarget => onMoveFieldInput(aif, fieldTarget)}
              onAbortMoveFieldInput={onAbortMoveFieldInput}
              onDropFieldInput={() => onDropFieldInput(aif)}
              fields={Fields}
            />
          </Grid>
        );
      });
    
    return (
      <Grid container spacing={(isWorkflow) ? 2 : 3}>
        {fieldGridItems}
      </Grid>
    );
  }
}

AssetItemDialogGrid.propTypes = {
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  AssetItemFields: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  isWorkflow: PropTypes.bool,
  onGetFieldImageUploadReservationUri: PropTypes.func.isRequired,
  onFieldValueChange: PropTypes.func.isRequired,
  onFieldImageChange: PropTypes.func.isRequired,
  enableDragAndDrop: PropTypes.bool,
  onStartMoveFieldInput: PropTypes.func,
  onMoveFieldInput: PropTypes.func,
  onAbortMoveFieldInput: PropTypes.func,
  onDropFieldInput: PropTypes.func,
  onApiError: PropTypes.func.isRequired,
  onAlert: PropTypes.func.isRequired,
  Fields: PropTypes.array.isRequired,
};

export default withStyles(styles, {withTheme: true})(AssetItemDialogGrid);