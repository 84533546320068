import API, {
  GetFormTemplatesPublicFieldListItemsPathForApi,
} from './api';
import {
  GetEffectiveSaveNewSelectionListItems,
  RouteNewListItemValueToFieldValueChange,
} from './Field';

export function SortForTabOrder(formTemplateFields) {
	const nearest3 = f => {
		if (f === 0) return f;
		let rounded = Number(Math.round(f+'e3')+'e-3').toString();
		let decimalMatches = rounded.match(/(\d*\.)(\d*)/);
		if (decimalMatches < 3) {
			return f;
		}
		let decimal = decimalMatches[2];
		while (decimal.length < 3) {
			decimal += "0";
			rounded += "0";
		}
		return rounded;
	}
	formTemplateFields.sort((a, b) => {
		let posA = `${nearest3(a.Y)}_${nearest3(a.X)}`;
		let posB = `${nearest3(b.Y)}_${nearest3(b.X)}`;
		if (posA < posB) return -1;
		else if (posA > posB) return 1;
		else return 0;
	});
	return formTemplateFields;
}

const clearAdornmentStyle = {
  "& .MuiIconButton-edgeEnd": {
    marginRight:-12,
    padding:0,
  },
};
const fieldInputInputStyle = {
  "& .fieldInputInput": {
    height:19,
  },
};

export const FormTemplateFieldControlSizeStyles = {
  controlSizeMedium: {
    "& .MuiInputBase-input": {
      padding:8,
    },
    "& .MuiInputBase-inputMultiline": {
      padding:0,
    },
    "& .MuiInputBase-multiline": {
      padding:8,
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 10px) scale(1)",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& .fieldInputValueContainer": {
      fontSize:"1rem",
      margin:0,
      marginLeft:6,
      marginRight:6,
      whiteSpace:"nowrap",
    },
    ...fieldInputInputStyle,
    "& .MuiCheckbox-root": {
      height:34,
    },
    ...clearAdornmentStyle,
    "& .MuiRadio-root": {
      paddingTop:7,
      paddingBottom:7,
    },
    "& .MuiButton-root": {
    },
    "& .fieldControlBoxForReadOnlyControlSizing":{
      paddingTop:7,
      height:36,
    },
  },
  controlSizeSmall: {
    "& .MuiInputBase-input": {
      padding:4,
    },
    "& .MuiInputBase-inputMultiline": {
      padding:0,
    },
    "& .MuiInputBase-multiline": {
      padding:4,
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 10px) scale(1)",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& .fieldInputValueContainer": {
      fontSize:"1rem",
      margin:0,
      marginLeft:3,
      marginRight:3,
      whiteSpace:"nowrap",
    },
    ...fieldInputInputStyle,
    "& .MuiCheckbox-root": {
      height:26,
    },
    ...clearAdornmentStyle,
    "& .MuiRadio-root": {
      paddingTop:4,
      paddingBottom:3,
    },
    "& .MuiButton-root": {
      padding:2,
      paddingLeft:8,
      paddingRight:8,
      fontSize:13,
    },
    "& .fieldControlBoxForReadOnlyControlSizing":{
      paddingTop:3,
      height:28,
    },
  },
  controlSizeMicro: {
    "& .MuiInputBase-input": {
      padding:0,
      paddingLeft:4,
      paddingRight:4,
    },
    "& .MuiInputBase-inputMultiline": {
      padding:0,
    },
    "& .MuiInputBase-multiline": {
      padding:2,
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 2px) scale(1)",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -10px) scale(0.75)",
    },
    "& .fieldInputValueContainer": {
      fontSize:"1rem",
      margin:0,
      marginLeft:2,
      marginRight:2,
      whiteSpace:"nowrap",
    },
    ...fieldInputInputStyle,
    "& .MuiCheckbox-root": {
      height:19,
    },
    "& .MuiTypography-root": {
      fontSize:14,
    },
    "& .MuiSvgIcon-root": {
      height:20,
    },
    ...clearAdornmentStyle,
    "& .MuiRadio-root": {
      paddingTop:0,
      paddingBottom:0,
    },
    "& .MuiButton-root": {
      padding:0,
      paddingLeft:8,
      paddingRight:8,
      fontSize:10,
    },
    "& .fieldControlBoxForReadOnlyControlSizing":{
      paddingTop:0,
      height:20,
    },
  },

  controlSizeHeightExcluded: {
    "& .fieldInputInput": {
      height:"inherit",
    },
  },
};

export const GetFormTemplateFieldControlSizeClasses = (FormTemplateField, classes) => {
  let outClasses = [];
  switch (FormTemplateField.ControlSize) {
    case "Medium":
      outClasses.push(classes.controlSizeMedium);
      break;
    case "Small":
      outClasses.push(classes.controlSizeSmall);
      break;
    case "Micro":
      outClasses.push(classes.controlSizeMicro);
      break;
    default:
      break;
  }
  if (FormTemplateField.Field.DisplaySelectionList
    && FormTemplateField.Field.AllowMultipleValues) {
    outClasses.push(classes.controlSizeHeightExcluded);
  }
  return outClasses;
}

export const HandleGetFormTemplateFieldListItemsFilter = (formTemplateField, onApiError, parentValue) => valueFilter => {
  // Abort if this field is new (doesn't exist in backend/have a real ID yet)
  if (formTemplateField.IsNew) {
    return;
  }

  // TODO
  // If Field.ParentFieldID has a value, we need to extract the value (if not provided as argument) from the parent and pass along
  // if (typeof parentValue === "undefined" || parentValue === null) {
  //   parentValue = this.getParentFieldValue(formTemplateId, formTemplateFieldId);
  // }
  // //console.log("handleSelectionListFilter", formTemplateId, formTemplateFieldId, valueFilter, parentValue);

  return API.get(GetFormTemplatesPublicFieldListItemsPathForApi(formTemplateField.FormTemplateID, formTemplateField.ID),
    { 
      params: { 
        organizationId: formTemplateField.OrganizationID,
        projectId: formTemplateField.ProjectID,
        valueFilter,
        parentValue,
      }
    }
  )
    .then(resp => {
      if (!resp.data) {
        return null;
      }
      return resp.data.map(item => { return ({ value: item.Value, label: item.Value}) });
    })
    .catch(err => onApiError(err));
}

export const HandleFormTemplateFieldListItemAdd = (formTemplateField, onApiError, onValueChange) => newValue => {
  if (!GetEffectiveSaveNewSelectionListItems(formTemplateField.Field)) {
    RouteNewListItemValueToFormTemplateFieldValueChange(formTemplateField, newValue, onValueChange);
    return;
  }

  return API.post(GetFormTemplatesPublicFieldListItemsPathForApi(formTemplateField.FormTemplateID, formTemplateField.ID),
    [{ /*ParentValue: parentValue,*/ Value: newValue }],
    { 
      params: {
        organizationId: formTemplateField.OrganizationID,
        projectId: formTemplateField.ProjectID,
      }
    }
  )
    .then(resp => {
      RouteNewListItemValueToFormTemplateFieldValueChange(formTemplateField, newValue, onValueChange);
    })
    .catch(err => onApiError(err));  
}

export const RouteNewListItemValueToFormTemplateFieldValueChange = (formTemplateField, newValue, onValueChange) => {
  RouteNewListItemValueToFieldValueChange(null, formTemplateField.Field, newValue,
    (unusedFieldId) => (unusedEvent, selectedOptions) => onValueChange(formTemplateField.ID)(null, selectedOptions)
  );
} 

const _minFieldWidth = 0.055;
const _minFieldWidth_SelectionList = 0.075;
const _minFieldWidth_RadioGroup = 0.095;
const _minFieldWidth_CheckBox = 0.05;
const _minFieldWidth_Date = 0.125;
export const GetMinFieldWidth = formTemplateField => {
  switch (formTemplateField.Type) {
    case "CheckBox":
      return _minFieldWidth_CheckBox;
    case "RadioGroup":
      return _minFieldWidth_RadioGroup;
    case "Field":
    case "ListBox":
    case "TextBox":
      switch (formTemplateField.Field.Type) {
        case "FieldType_Bool":
          return _minFieldWidth_CheckBox;
        case "FieldType_Date":
          return _minFieldWidth_Date;
        default:
          if (formTemplateField.Field.DisplaySelectionList) {
            return _minFieldWidth_SelectionList;
          } else {
            return _minFieldWidth;
          }
      }
    default:
      return _minFieldWidth;
  }
}

export const GetHideAdornment = width => {
  return width < 0.15;
}