export const DefaultFormTemplatePageSize = {
	PageSize: "US_LETTER",
	Name: "Letter",
	PageWidth:8.5,
	PageHeight:11.0,
	Aspect: 1.294,
};

export const FormTemplatePageSizes = [
	{...DefaultFormTemplatePageSize},
	{
		PageSize: "US_LEGAL",
		Name: "Legal",
		PageWidth:8.5,
		PageHeight:14.0,
		Aspect: 1.647,
	},
	{
		PageSize: "US_TABLOID",
		Name: "Tabloid",
		PageWidth:11.0,
		PageHeight:17.0,
		Aspect: 1.545,
	},
	{
		PageSize: "US_STATEMENT",
		Name: "Statement",
		PageWidth:5.5,
		PageHeight:8.5,
		Aspect: 1.545,
	},
	{
		PageSize: "US_EXECUTIVE",
		Name: "Executive",
		PageWidth:7.25,
		PageHeight:10.5,
		Aspect: 1.448,
	},
	{
		PageSize: "US_FOLIO",
		Name: "Folio",
		PageWidth:8.5,
		PageHeight:13.0,
		Aspect: 1.529,
	},
	{
		PageSize: "US_LETTER_L",
		Name: "Letter (landscape)",
		PageWidth:11.0,
		PageHeight:8.5,
		Aspect: 0.773,
	},
	{
		PageSize: "US_LEGAL_L",
		Name: "Legal (landscape)",
		PageWidth:14.0,
		PageHeight:8.5,
		Aspect: 0.607,
	},
	{
		PageSize: "US_TABLOID_L",
		Name: "Tabloid (landscape)",
		PageWidth:17.0,
		PageHeight:11.0,
		Aspect: 0.647,
	},
	{
		PageSize: "US_STATEMENT_L",
		Name: "Statement (landscape)",
		PageWidth:8.5,
		PageHeight:5.5,
		Aspect: 0.647,
	},
	{
		PageSize: "US_EXECUTIVE_L",
		Name: "Executive (landscape)",
		PageWidth:10.5,
		PageHeight:7.25,
		Aspect: 0.690,
	},
	{
		PageSize: "US_FOLIO_L",
		Name: "Folio (landscape)",
		PageWidth:13.0,
		PageHeight:8.5,
		Aspect: 0.654,
	},
	{
		PageSize: "Custom",
		Name: "Custom",
		PageWidth:10.0,
		PageHeight:10.0,
		Aspect: 1.0,
	},
];

export default FormTemplatePageSizes;