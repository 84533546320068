import React from 'react';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import PropTypes from 'prop-types';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import ProgressIndicator from './ProgressIndicator';
import MultiUseDialog from './MultiUseDialog';
import API from '../Util/api';
import FolderHierarchyItemPropertiesDialog from './FolderHierarchyItemPropertiesDialog';
import FolderHierarchyItemTableRow from './FolderHierarchyItemTableRow';
import ReRankItemInCollection from '../Util/ItemRanking';

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(2),
    position: "sticky",
    top: 0,
    zIndex: 11,
  },
  highlight: {
    backgroundColor: theme.palette.type === "dark" ? "#303030" : "#e0e0e0",
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes, handleAdd, handleDelete } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Folder Levels
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {
          numSelected > 0
          ? (
            <Tooltip title="Delete">
              <IconButton aria-label="Delete" onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            )
          : (
            <Tooltip title="Add">
              <IconButton aria-label="Add" onClick={handleAdd}>
                <AddIcon />
              </IconButton>
            </Tooltip>
            )
        }
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
});

const FolderHierarchy = {
  ID: "",
  Name: "",
};

const rankIncrement = 1000;

class FolderHierarchyPropertiesDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      FolderHierarchy: FolderHierarchy,
      FolderHierarchyItems: [],
      SelectedItems: [],
      DialogFolderHierarchyItem: {},
      DialogFolderHierarchyItemIsNew: false,
      FolderHierarchyItemPropertiesDialogIsOpen: false,
      ShowDeleteItemConfirmation: false,
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
      ShowDialogProgressIndicator: false,
      PreMoveFolderHierarchyItemsJson: "",
    }
  }

  handleClose(closeCallback, isCloseOrCancel) {
    this.setState({ 
      FolderHierarchy: FolderHierarchy, 
      FolderHierarchyItems: [], 
      ShowProgressIndicator:false,
      ShowProgressIndicatorImmediately: false, 
      open: false
    });
    if (typeof closeCallback !== "undefined" && closeCallback) {
      closeCallback(isCloseOrCancel);
    }
  }

  handleApiError = err => {
    this.setState({
      ShowDeleteItemConfirmation: false, 
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false, 
    });
    this.props.onApiError(err);
  }

  handleLoadFolderHierarchy() {
    this.setState({ShowProgressIndicatorImmediately:true});
    API.get("/folderHierarchies/" + this.props.folderHierarchy.ID)
      .then(resp => {
        let folderHierarchy = resp.data;
        this.setState({
          FolderHierarchy: folderHierarchy,
          // ShowProgressIndicatorImmediately:false,
        });
        this.handleLoadFolderHierarchyItems();
      })
      .catch(this.handleApiError);
  }

  handleUpdateFolderHierarchy(closeCallback) {
    this.setState({ShowProgressIndicator:true});
    API.put("/admin/folderHierarchies/" + this.state.FolderHierarchy.ID, this.state.FolderHierarchy)
    .then(resp => {
      this.handleClose(closeCallback, false);
    })
    .catch(this.handleApiError);
  }

  handleStringChange(propertyName, string) {
    let folderHierarchy = {...this.state.FolderHierarchy};
    folderHierarchy[propertyName] = string;
    this.setState({FolderHierarchy: folderHierarchy});
  }

  handleSelectItem(id)
  {
    let selected = [...this.state.SelectedItems];
    const selectedIndex = selected.indexOf(id);

    if (selectedIndex === -1) {
      selected = selected.concat(id);
    } else {
      selected.splice(selectedIndex, 1);
    }
    this.setState({ SelectedItems: selected });
  }

  handleSelectAllItems(event) {
    let selectedItems = [...this.state.FolderHierarchyItems];
    let selected = [];
    if (!event.target.checked) {
      this.setState({ SelectedItems: [] });
      return;
    }
    for (let i = 0; i < selectedItems.length; i++) {
      selected = selected.concat(selectedItems[i].ID)
    }
    this.setState({ SelectedItems: selected });
  }

  NewFolderHierarchyItem = {
    ID: "",
    FolderHierarchyID: "",
    Type: "FolderHierarchyItemType_Field",
    FieldID: "",
  };
  
  handleAddFolderHierarchyItem() {
    let newFolderHierarchyItem = {...this.NewFolderHierarchyItem};
    newFolderHierarchyItem.FolderHierarchyID = this.state.FolderHierarchy.ID;
    API.post("/admin/folderHierarchies/" + newFolderHierarchyItem.FolderHierarchyID + "/items", [newFolderHierarchyItem])
      .then(resp => {
        this.handleOpenFolderHierarchyItemPropertiesDialog(resp.data[0], true);
      })
      .catch(this.handleApiError);
  }

  handleSetDeleteItemConfirmationVisibility(show) {
    this.setState({ ShowDeleteItemConfirmation: show });
    this.setState({ ShowDialogProgressIndicator: false });
  }

  handleDeleteItem(folderHierarchyItemID) {
    this.setState({ShowDialogProgressIndicator:true});
    let selectedItems = [];
    if (folderHierarchyItemID) {
      selectedItems.push(folderHierarchyItemID);
    } else {
      if (this.state.SelectedItems.length < 1) {
        return;
      }
      selectedItems = this.state.SelectedItems;
    }
    return API.delete("/admin/folderHierarchies/" + this.state.FolderHierarchy.ID + "/items", 
      { data: { IDs: selectedItems } })
      .then(resp => {
        this.handleLoadFolderHierarchyItems();
        this.handleSetDeleteItemConfirmationVisibility(false);
      })
      .catch(this.handleApiError);
  }

  handleLoadFolderHierarchyItems() {
    if (!this.state.FolderHierarchy.ID) {
      return;
    }
    this.setState({ShowProgressIndicatorImmediately:true});
    API.get("/folderHierarchies/" + this.state.FolderHierarchy.ID + "/items",
      {
        params: {
          getAll: true,
        }
      })
      .then(resp => {
        this.setState({ 
          FolderHierarchyItems: resp.data, 
          SelectedItems: [], 
          ShowProgressIndicatorImmediately: false, 
        });
    })
      .catch(this.handleApiError);
  }

  handleUpdateFolderHierarchyItems(updatedFolderHierarchyItems) {
    API.put("/admin/folderHierarchies/" + this.state.FolderHierarchy.ID + "/items", updatedFolderHierarchyItems)
      .then(resp => { 
      })
      .catch(this.handleApiError);
  }

  handleOpenFolderHierarchyItemPropertiesDialog(folderHierarchyItem, isNew) {
    this.setState({FolderHierarchyItemPropertiesDialogIsOpen: true, DialogFolderHierarchyItem: folderHierarchyItem, DialogFolderHierarchyItemIsNew: isNew});
  }

  handleCloseFolderHierarchyItemPropertiesDialog(folderHierarchyItemID, isNew, isCloseOrCancel) {
    this.setState({FolderHierarchyItemPropertiesDialogIsOpen: false});
    if (isNew && isCloseOrCancel) {
      this.handleDeleteItem(folderHierarchyItemID);
    } else {
      this.handleLoadFolderHierarchyItems();
    }
  }

  handleStartMoveFolderHierarchyItemTableRow() {
    // Generate rank if the first two have equal rank
    let folderHierarchyItems = [...this.state.FolderHierarchyItems];
    let stateToUpdate = {};
    stateToUpdate.PreMoveFolderHierarchyItemsJson = JSON.stringify(folderHierarchyItems);
    if (folderHierarchyItems.length > 1 && folderHierarchyItems[0].Rank === folderHierarchyItems[1].Rank) {
      for (let i = 0; i < folderHierarchyItems.length; i++) {
        folderHierarchyItems[i].Rank = i * rankIncrement;
      }
      stateToUpdate.FolderHierarchyItems = folderHierarchyItems;
    }
    
    this.setState(stateToUpdate);
  }

  handleMoveFolderHierarchyItemTableRow(sourceFolderHierarchyItem, targetFolderHierarchyItem) {
    if (!sourceFolderHierarchyItem)
      return;
    // console.log("Source FolderHierarchyItem: ", sourceFolderHierarchyItem.ID);
    if (targetFolderHierarchyItem) {
      // console.log("Target FolderHierarchyItem: ", targetFolderHierarchyItem.ID);
      if (sourceFolderHierarchyItem.Rank === targetFolderHierarchyItem.Rank)
        return;
    }

    let folderHierarchyItems = [...this.state.FolderHierarchyItems];
    let sourceIndex = folderHierarchyItems.indexOf(sourceFolderHierarchyItem);
    let targetIndex = folderHierarchyItems.indexOf(targetFolderHierarchyItem);
    if (sourceIndex === targetIndex)
      return;

    //console.log("Index of source: ", sourceIndex, " Index of target: ", targetIndex);
    if (sourceIndex === null || targetIndex === null)
      return;

    folderHierarchyItems.splice(targetIndex, 1);
    folderHierarchyItems.splice(sourceIndex, 0, targetFolderHierarchyItem);
    this.setState({ FolderHierarchyItems: folderHierarchyItems });
  }

  handleAbortMoveFolderHierarchyItemTableRow() {
    let folderHierarchyItems = [...this.state.FolderHierarchyItems];
    folderHierarchyItems = JSON.parse(this.state.PreMoveFolderHierarchyItemsJson)
    this.setState({ FolderHierarchyItems: folderHierarchyItems });
  }

  handleDropFolderHierarchyItemTableRow(folderHierarchyItem) {
    if (!folderHierarchyItem)
      return;
    this.reRankFolderHierarchyItem(folderHierarchyItem);
  }

  reRankFolderHierarchyItem(folderHierarchyItem) {
    let { 
      Collection: reRankedFolderHierarchyItems, 
      StartIndex, 
      EndIndex,
    } = ReRankItemInCollection(folderHierarchyItem, [...this.state.FolderHierarchyItems], rankIncrement);
    this.setState({ FolderHierarchyItems: reRankedFolderHierarchyItems});

    // Build array of changed items to send to server
    let preMoveFolderHierarchyItems = JSON.parse(this.state.PreMoveFolderHierarchyItemsJson);
    let updatedFolderHierarchyItems = [];
    for (let i = StartIndex; i <= EndIndex; i++) {
      let folderHierarchyItem = reRankedFolderHierarchyItems[i];
      let filtered = preMoveFolderHierarchyItems.filter(p => p.ID === folderHierarchyItem.ID)
      if (filtered.length > 0) {
        let preMoveFolderHierarchyItem = filtered[0];
        // console.log("original: ", preMoveFolderHierarchyItem.Rank, " new: ", folderHierarchyItem.Rank);
        if (folderHierarchyItem.Rank !== preMoveFolderHierarchyItem.Rank) {
          updatedFolderHierarchyItems = updatedFolderHierarchyItems.concat({
            ID: folderHierarchyItem.ID,
            Rank: folderHierarchyItem.Rank,
          });
        }
      }
    }

    // Send changed items to server
    this.handleUpdateFolderHierarchyItems(updatedFolderHierarchyItems);
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.handleLoadFolderHierarchy();
      }
      this.setState({open: this.props.open !== false});
    }
  }

  render() {
    const {
      open,
      FolderHierarchy,
      FolderHierarchyItems,
      SelectedItems,
      DialogFolderHierarchyItem,
      DialogFolderHierarchyItemIsNew,
      FolderHierarchyItemPropertiesDialogIsOpen,
      ShowDeleteItemConfirmation,
      ShowProgressIndicator,
      ShowProgressIndicatorImmediately,
      ShowDialogProgressIndicator,
    } = this.state;
    const { 
      classes,
      theme,
      closeCallback,
    } = this.props;

    let dialogActions = (
      <DialogActions>
        <Button onClick={() => this.handleClose(closeCallback, true)}>
          CLOSE
        </Button>
        <Button onClick={() => this.handleUpdateFolderHierarchy(closeCallback)}>
          SAVE
        </Button>
      </DialogActions>
    );

    let deleteConfirmationDialogDetails = {
      Open:ShowDeleteItemConfirmation,
      ShowProgressIndicator:ShowDialogProgressIndicator,
      IsConfirmation:true,
      Title:"Delete item?",
      BodyText:"This action cannot be undone.",
      BodyClassName:"warning",
      CancelCallback:() => this.handleSetDeleteItemConfirmationVisibility(false),
      CloseCallback:() => this.handleSetDeleteItemConfirmationVisibility(false),
      ConfirmCallback:() => this.handleDeleteItem(),
    };

    let numSelected = SelectedItems.length;
    let indeterminate = numSelected > 0 && numSelected < FolderHierarchyItems.length;
    let allSelected = numSelected > 0 && numSelected === FolderHierarchyItems.length;

    let progressIndicator = null;
    if (ShowProgressIndicator || ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    let table = null;
    if (FolderHierarchyItems.length > 0) {
      table = (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="none" className={classes.tableHead}></TableCell>
              <TableCell padding="checkbox" className={classes.tableHead}>
                <Checkbox
                  color="secondary"
                  indeterminate={indeterminate}
                  checked={indeterminate || allSelected}
                  onChange={event => this.handleSelectAllItems(event)}
                />
              </TableCell>
              <TableCell className={classes.tableHead}>Level</TableCell>
              <TableCell className={classes.tableHead}>Type</TableCell>
              <TableCell className={classes.tableHead}>Detail</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {FolderHierarchyItems.map(i => (
              <FolderHierarchyItemTableRow
                key={i.ID}
                FolderHierarchyItem={i}
                Selected={SelectedItems.indexOf(i.ID) !== -1}
                LevelNumber={1+FolderHierarchyItems.indexOf(i)}
                onOpenFolderHierarchyItemPropertiesDialog={() => this.handleOpenFolderHierarchyItemPropertiesDialog(i, false)}
                onSelectItem={() => this.handleSelectItem(i.ID)}
                onStartMoveFolderHierarchyItemTableRow={() => this.handleStartMoveFolderHierarchyItemTableRow()}
                onMoveFolderHierarchyItemTableRow={folderHierarchyItemTarget => this.handleMoveFolderHierarchyItemTableRow(i, folderHierarchyItemTarget)}
                onAbortMoveFolderHierarchyItemTableRow={() => this.handleAbortMoveFolderHierarchyItemTableRow()}
                onDropFolderHierarchyItemTableRow={() => this.handleDropFolderHierarchyItemTableRow(i)}
               />
            ))}
          </TableBody>
        </Table>
      );
    }

    return (
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={() => this.handleClose(closeCallback, true)}
        // aria-labelledby="dialog-title"
        // aria-describedby="dialog-description">
        // <DialogTitle id="dialog-title">
        //   <span>{FolderHierarchy.Name}</span>
        // </DialogTitle>
        >
        <DialogContent>
          <MultiUseDialog Details={deleteConfirmationDialogDetails} />
          {progressIndicator}

          <FolderHierarchyItemPropertiesDialog
            open={FolderHierarchyItemPropertiesDialogIsOpen}
            onApiError={(err) => this.handleApiError(err)}
            closeCallback={isCloseOrCancel => this.handleCloseFolderHierarchyItemPropertiesDialog(
              DialogFolderHierarchyItem.ID, DialogFolderHierarchyItemIsNew, isCloseOrCancel)}
            folderHierarchyItem={DialogFolderHierarchyItem} />
          
          <Grid container direction="column" spacing={1}>
            
            <Grid item xs={12} style={{marginTop:theme.spacing(2)}}>
              <TextField
                variant="outlined"
                autoFocus
                label="Folder-Group Name"
                value={FolderHierarchy.Name}
                onChange={event => this.handleStringChange("Name", event.target.value)}
                InputLabelProps={{ shrink: true, }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Paper elevation={0} style={{ overflowY: "auto", width: "100%" }}>
                <EnhancedTableToolbar numSelected={numSelected}
                  handleAdd={() => this.handleAddFolderHierarchyItem()}
                  handleDelete={() => this.handleSetDeleteItemConfirmationVisibility(true)} />
                {table}
              </Paper>
            </Grid>

          </Grid>

        </DialogContent>
        {dialogActions}
      </Dialog>
    );
  }
}

FolderHierarchyPropertiesDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(FolderHierarchyPropertiesDialog);