import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import Collection from '../Model/Collection';

import MultiUseDialog from '../Components/MultiUseDialog';
import ItemTableRow from '../Components/ItemTableRow';
import TaskCard from '../Components/TaskCard';
import TaskCardForKanban from '../Components/TaskCardForKanban';
import TaskDialog from '../Components/TaskDialog';
import TaskCollectionFields from '../Model/TaskCollectionFields';
import ActionType from '../Model/ActionType';

import { GetDocumentLinkComponent } from '../Util/Documents';
import { GetTextWithUrlsAsAnchors } from '../Util/Regex';
import {
  GetProjectTasksPath,
  GetProjectCompletedTasksPath,
  GetProjectDeletedTasksPath,
  GetWorkspaceTasksPath,
  GetWorkspaceTasksProjectPath,
} from '../Util/PathHelper';
import {
  GetUserValue,
  GetDateValue,
} from '../Util/Properties';
import {
  // ViewType_KanbanAssignment,
} from '../Util/ViewType';
import API, {
  GetUserOrganizationTasksPathForApi,
  GetTasksPathForApi,
  GetUserOrganizationProjectTaskPathForApi,
  GetTaskPathForApi,
} from '../Util/api';
import {
  GetTaskPriorityLabel,
  GetTaskDurationAsString,
} from '../Util/Task';
import {
  HandleRouteToTask,
  GetDueOnStyle,
} from '../Util/Tasks';
import {
  GetTagsForList,
} from '../Util/Tags';
import {
  ViewType_List,
  ViewType_KanbanMilestone,
} from '../Util/ViewType';
import {
  HandleStartMoveTask,
  HandleMoveTaskOverTask,
  HandleMoveTaskOverColumn,
  HandleAbortMoveTask,
  HandleEndMoveTask,
} from '../Util/Task';
import debounce from 'es6-promise-debounce';

export class Tasks_Collection extends Collection {
  constructor(props, onGetState, onSetState, onApiError, onAlert, isWorkspace, isProjects, 
    organizationId, projectId, userEmail, showCompletedTasks, showDeniedTasks,
    onResetAutoRefresh, onItemTitleChange, allowKanban, disableItemClick) {

    super(props, onSetState, onApiError, isWorkspace, isProjects, organizationId, projectId);

    this.userEmail = userEmail;

    this.getState = onGetState;
    this.handleAlert = onAlert;
    this.handleResetAutoRefresh = onResetAutoRefresh;
    this.handleItemTitleChange = onItemTitleChange;
    this.disableItemClick = disableItemClick;

    this.ShowCompletedTasks = showCompletedTasks;
    this.ShowDeniedTasks = showDeniedTasks;

    this.PageTitle = `${(props.isAdmin) ? "All " : ""}${showCompletedTasks ? "Completed " : showDeniedTasks ? "Deleted " : "Active "}Tasks`;
    this.ContentUri = (isWorkspace)
      ? GetUserOrganizationTasksPathForApi(organizationId)
      : GetTasksPathForApi(organizationId, projectId);
    this.ContentUriParams = {
      pageSize: (!showCompletedTasks && !showDeniedTasks) ? 192 : undefined,
      completedOnly: (showCompletedTasks) ? true : undefined,
      deniedOnly: (showDeniedTasks) ? true : undefined,
    };
    if (isWorkspace) {
      this.ContentUriParams.projectId = projectId;
    }
    this.CollectionName = `${showCompletedTasks ? "Completed" : showDeniedTasks ? "Denied" : ""}Tasks`;
    this.ItemsName = "Tasks";
    this.ItemName = "Task";
    this.AllowKanban = allowKanban || false;
    this.DefaultViewType = (this.AllowKanban)
      ? ViewType_KanbanMilestone
      : ViewType_List;
    this.AllowSelect = true;
    this.TasksForDialog = [];

    this.canCreateNew = isProjects && !showCompletedTasks && !showDeniedTasks;
    this.HandleCreateNew = (this.canCreateNew)
      ? () => {
        if (!this.canCreateNew) {
          return;
        }
        this.setState({
          TaskForTaskDialog: null,
          TaskDialogIsOpen: true,
          TaskDialogIsCreateNew: true,
        });
      }
      : null;
    }

  HandleGetCollectionFieldsPromise = () => {
    return Promise.resolve(
      TaskCollectionFields.filter(f =>
        (this.isWorkspace)
          ? (
              f.ID !== "Meta_text_kw256lc[AssignmentUserEmail].keyword"
              && f.ID !== "Meta_date_str256[CompletedOn].string"
              && f.ID !== "Meta_text_kw256lc[CompletedByUserEmail].keyword"
            )
          : (this.ShowCompletedTasks || this.ShowDeniedTasks)
            ? f
            : (
                f.ID !== "Meta_date_str256[CompletedOn].string"
                && f.ID !== "Meta_text_kw256lc[CompletedByUserEmail].keyword"
              )
      )
    );
  }

  HandleGetHeadCells = (items, sensitiveFields) => {
    let headCells = [
      { id: 'Name', sortId: 'Meta_text_kw256lc[Name].keyword', numeric: false, label: 'Name' },
      { id: 'Email', sortId: 'Meta_text_kw256lc[AssignmentUserEmail].keyword', numeric: false, label: 'Assigned To' },
      { id: 'CreatedOn', sortId: 'Meta_date_str256[CreatedOn].string', numeric: false, label: 'Created On' },
      { id: 'DueOn', sortId: 'Meta_date_str256[DueOn].string', numeric: false, label: 'Due On' },
      { id: 'Duration', sortId: 'Meta_long[Duration]', numeric: false, label: 'Duration' },
      { id: 'Priority', sortId: 'Meta_int[Priority]', numeric: false, label: 'Priority' },
      { id: 'Milestone', sortId: 'Meta_text_kw256lc[MilestoneName].keyword', numeric: false, label: 'Milestone' },
      { id: 'State', sortId: 'Meta_text_kw256lc[StateName].keyword', numeric: false, label: 'Task State' },
      // { id: 'CreatedBy', sortId: 'Meta_text_kw256lc[CreatedByUserEmail].keyword', numeric: false, label: 'Created By' },
      // { id: 'ProcessName', sortId: 'Meta_text_kw256lc[ProcessName].keyword', numeric: false, label: 'Process' },
      // { id: 'ProcessElementName', sortId: 'Meta_text_kw256lc[ProcessElementName].keyword', numeric: false, label: 'Process Action' },
      { id: 'Tags', sortId: 'Meta_text_kw50lc[Tag].keyword', numeric: false, label: 'Tags' },
    ];
    if (this.ShowCompletedTasks || this.ShowDeniedTasks) {
      headCells.push(
        { id: 'CompletedOn', sortId: 'Meta_date_str256[CompletedOn].string', numeric: false, label: 'Completed On' },
        { id: 'CompletedBy', sortId: 'Meta_text_kw256lc[CompletedByUserEmail].keyword', numeric: false, label: 'Completed By' },
      );
    }
    if (!this.ProjectID) {
      headCells.push(
        { id: 'ProjectName', sortId: 'Meta_text_kw256lc[ProjectName].keyword', numeric: false, label: "Project"},
      );
    }
    headCells.push(
      { id: 'Description', numeric: false, label: 'Description' },
    );
    // headCells.push(
    //   { id: 'PrimaryDocument', sortId: 'Meta_text_kw256lc[PrimaryDocumentName].keyword', numeric: false, label: 'Primary Document' },
    // );
    return headCells;
  }

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, selectedIDs,
    onAction, postActionData, sortType, sortDescending, useSingleColumnLayout, cardStyle, viewType,
    onItemClick, disableCardActions) => {
    
    this.ActionHandlerFunction = onAction;
    return (items && items.length) 
      ? items
        .map(i => {
          return (
            <Grid item key={i.ID} sm={12} md={6} lg={4} xl={3} className={classes.cardGridItem}>
              <TaskCard
                Task={i}
                primaryDocumentLinkComponent={this.getPrimaryDocumentLinkComponent(i, theme)}
                onAction={(disableCardActions) ? undefined : onAction}
                onCardAction={(this.disableItemClick)
                  ? null
                  : () => HandleRouteToTask(this.props, i.ProjectID, i.ID, 
                      this.isProjects, false, this.ShowCompletedTasks, this.ShowDeniedTasks)
                }
                onSelect={() => onSelect(i.ID)}
                selected={selectedIDs.indexOf(i.ID) > -1}
                style={cardStyle}
              />
            </Grid>
          );
        }) 
      : [];
  }

  HandleGetCardGridItemsForKanban = (items, sensitiveFields, classes, theme, onSelect, selectedIDs,
    onAction, postActionData, sortType, sortDescending, useSingleColumnLayout, cardStyle, viewType,
    onItemClick, disableCardActions) => {
    
    this.ActionHandlerFunction = onAction;
    return (items && items.length) 
      ? items
        .map((i, index) => {
          return (
            <Grid item key={i.ID} rawobject={i} className={classes.cardGridItem}>
              <TaskCardForKanban
                Task={i}
                TaskIndex={index}
                // hideAssignment={viewType === ViewType_KanbanAssignment}
                selectedTaskIds={selectedIDs}
                primaryDocumentLinkComponent={this.getPrimaryDocumentLinkComponent(i, theme)}
                onAction={onAction}
                onCardAction={(this.disableItemClick)
                  ? null
                  : () => HandleRouteToTask(this.props, i.ProjectID, i.ID, 
                      this.isProjects, false, this.ShowCompletedTasks, this.ShowDeniedTasks)
                }
                onSelect={() => onSelect(i.ID)}
                selected={selectedIDs.indexOf(i.ID) > -1}
                onStartMoveTask={sourceIndex => this.handleStartMoveTask(sourceIndex, selectedIDs)}
                onMoveTaskOverTask={sourceTask => this.handleMoveTaskOverTask(viewType, sourceTask, selectedIDs, i)}
                onMoveTaskOverColumn={(sourceTask, targetColumnProperty) => this.handleMoveTaskOverColumn(sourceTask, selectedIDs, targetColumnProperty)}
                onAbortMoveTask={this.handleAbortMoveTask}
                onEndMoveTask={this.handleEndMoveTask}
              />
            </Grid>
          );
        }) 
      : [];
  }

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs,
    onAction, postActionData, sortType, sortDescending) => {
    
    this.ActionHandlerFunction = onAction;
    return (items && items.length)
      ? items
        .map(i => {
          const selected = selectedIDs.indexOf(i.ID) !== -1;          
          const projectNameCell = (!this.ProjectID)
            ? <TableCell className={classes.tableCell}>{i.ProjectName}</TableCell>
            : null;
          const completedOnTableCell = (this.ShowCompletedTasks || this.ShowDeniedTasks)
            ? (
              <TableCell className={classes.tableCell}>{GetDateValue(i.CompletedOn)}</TableCell>
            ) : null;
          const completedByTableCell = (this.ShowCompletedTasks || this.ShowDeniedTasks)
            ? (
              <TableCell className={classes.tableCell}>{GetUserValue(i.CompletedByUserEmail, i.CompletedByUserName)}</TableCell>
            ) : null;
          return (
            <ItemTableRow key={`k_${i.ID}`}
              onSelect={() => onSelect(i.ID)}
              selected={selected}
              onItemClick={(this.disableItemClick)
                ? null
                : () => HandleRouteToTask(this.props, i.ProjectID, i.ID, 
                    this.isProjects, false, this.ShowCompletedTasks, this.ShowDeniedTasks)
              }
            >
              <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
                <Checkbox
                  color="secondary"
                  onClick={e => { e.stopPropagation(); onSelect(i.ID); }}
                  checked={selected}
                />
              </TableCell>
              <TableCell className={classes.tableCell} component="th" id={`label_${i.ID}`} scope="row" padding="none">
                {i.Name}
              </TableCell>
              <TableCell className={classes.tableCell}>{GetUserValue(i.AssignmentUserEmail, i.AssignmentUserName)}</TableCell>
              <TableCell className={classes.tableCell}>
                {GetDateValue(i.CreatedOn)}
              </TableCell>
              <TableCell className={classes.tableCell} style={(!i.Result) ? GetDueOnStyle(i.DueOn) : undefined}>
                {GetDateValue(i.DueOn)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {GetTaskDurationAsString(i.DurationMultiplier, i.DurationInterval)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {GetTaskPriorityLabel(i.Priority)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {i.TaskMilestoneName}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {i.TaskStateName}
              </TableCell>
              {/*<TableCell className={classes.tableCell}>{GetUserValue(i.CreatedByUserEmail, i.CreatedByUserName)}</TableCell>*/}
              {/*<TableCell className={classes.tableCell}>{(i.PrimaryDocument && i.PrimaryDocument.DocumentID)
                ? this.getPrimaryDocumentLinkComponent(i, theme)
                : null}
              </TableCell>*/}
              {/*<TableCell className={classes.tableCell}>{i.ProcessName}</TableCell>*/}
              {/*<TableCell className={classes.tableCell}>{i.ProcessElementName}</TableCell>*/}
              <TableCell className={classes.tableCell}>
                {GetTagsForList(i, sortType, sortDescending)}
              </TableCell>
              {completedOnTableCell}
              {completedByTableCell}
              {projectNameCell}
              <TableCell style={{maxWidth:1500}} className={classes.tableCell}>
                {GetTextWithUrlsAsAnchors(i.Description, theme, null, 100)}
              </TableCell>
            </ItemTableRow>
          );
        }) 
      : [];
  }

  handleUpdateServerTasks = debounce(tasksToUpdate => {
    if (!tasksToUpdate || !tasksToUpdate.length) {
      return;
    }
    API.put(GetTasksPathForApi(this.OrganizationID, this.ProjectID), tasksToUpdate)
      .then(resp => {
        let allTasks = this.HandleGetAllItems();
        tasksToUpdate.forEach(ttu => {
          const taskFinder = allTasks.filter(t => t.ID === ttu.ID);
          if (taskFinder.length) {
            taskFinder[0].IsUpdated = false;
          }
        });
        this.HandleSetAllItems(allTasks);
      })
      .catch(this.handleApiError);
  }, 250)

  handleTaskDragFunction = f => (...props) => {
    f(
      this.HandleGetAllItems(),

      this.getState,

      (updatedTasks, updateServer, otherState) => {
        if (updatedTasks) {
          this.HandleSetAllItems(updatedTasks);
        }
        if (updateServer) {
          this.handleResetAutoRefresh();
          this.handleUpdateServerTasks(updatedTasks.filter(t => t.IsUpdated));
        }
        if (otherState) {
          this.setState(otherState);
        }
      },

      this.handleApiError,

      DialogDetailsForDragDropTaskAssignment => {
        this.setState({DialogDetailsForDragDropTaskAssignment});
      },
    )
    (...props);
  }

  handleStartMoveTask = (...props) => {
    this.handleTaskDragFunction(HandleStartMoveTask)(...props);
  }

  handleMoveTaskOverTask = (...props) => {
    this.handleTaskDragFunction(HandleMoveTaskOverTask)(...props);
  }

  handleMoveTaskOverColumn = (...props) => {
    this.handleTaskDragFunction(HandleMoveTaskOverColumn)(...props);
  }

  handleAbortMoveTask = (...props) => {
    this.handleTaskDragFunction(HandleAbortMoveTask)(...props);
  }

  handleEndMoveTask = (...props) => {
    this.handleTaskDragFunction(HandleEndMoveTask)(...props);
  }

  getPrimaryDocumentLinkComponent = (task, theme) => {
    if (!task.PrimaryDocument || !task.PrimaryDocument.DocumentID) {
      return null;
    }
    return GetDocumentLinkComponent(task.OrganizationID, task.ProjectID, task.PrimaryDocument, theme,
      {taskID: task.ID}, (this.isWorkspace || task.AssignmentUserEmail === this.userEmail) ? "task" : undefined);
  }

  HandleGetDialogContent = state => {
    if (state.DialogDetailsForDragDropTaskAssignment) {
      return (
        <MultiUseDialog Details={state.DialogDetailsForDragDropTaskAssignment} />
      );
    } else {
      return (state.TaskForTaskDialog || state.TaskDialogIsCreateNew)
        ? (
          <TaskDialog
            organizationId={this.OrganizationID}
            projectId={(state.TaskDialogIsCreateNew) ? this.ProjectID : state.TaskForTaskDialog.ProjectID}
            history={this.props.history}
            location={this.props.location}
            open={state.TaskDialogIsOpen || false}
            isCreateNew={state.TaskDialogIsCreateNew}
            Task={state.TaskForTaskDialog}
            onMarkComplete={() => this.handleAction(ActionType.Task_Complete, state.TaskForTaskDialog)}
            onRestore={() => this.handleAction(ActionType.Task_Restore, state.TaskForTaskDialog)}
            onTaskRevised={this.HandleItemRevised}
            onApiError={this.handleApiError}
            onAlert={this.handleAlert}
            onCreated={this.handleTaskCreated}
            onClose={() => this.handleTaskDialogClosed(state.TaskDialogIsCreateNew)}
            showProgressIndicatorImmediately={state.ShowDialogProgressIndicatorImmediately}
            isWorkspace={this.isWorkspace}
            pathnameForFilterStateRestore={state.PathnameForFilterStateRestore}
          />
        ) : null;
    }
  }

  handleAction = (actionType, item) => {
    this.setState({ ShowDialogProgressIndicatorImmediately: true });
    this.ActionHandlerFunction(actionType, item.ID)
      .then(() => {
        this.handleTaskDialogClosed(null);
      });
  }

  HandleItemsChanged = items => {
    this.TasksForDialog = items || [];
    this.handleTryDisplayTaskIfApplicable(null, items, true);
  }

  handleTaskCreated = task => {
    if (!task) {
      return;
    }
    this.setState({ForcePrependItems:[task]});
  }

  handleTaskDialogClosed = isCreateNew => {
    const stateToUpdate = {
      TaskDialogIsOpen: false,
      PathnameForFilterStateRestore: null,
      ShowDialogProgressIndicatorImmediately: false,
    };
    this.setState(stateToUpdate);
    const newPath = (this.isWorkspace)
      ? (this.ProjectID)
        ? GetWorkspaceTasksProjectPath(this.ProjectID)
        : GetWorkspaceTasksPath()
      : (this.ShowCompletedTasks)
        ? GetProjectCompletedTasksPath(this.ProjectID)
        : (this.ShowDeniedTasks)
          ? GetProjectDeletedTasksPath(this.ProjectID)
          : GetProjectTasksPath(this.ProjectID);
    if (this.props.location.pathname !== newPath) {
      this.props.history.push(newPath, { ...this.props.location.state, ...stateToUpdate });
    }
    if (this.handleItemTitleChange) {
      this.handleItemTitleChange(null);
    }
  }

  handleTryDisplayTaskIfApplicable = (taskId_optional, tasks, fromItemsChanged, pathnameForFilterStateRestore) => {
    const taskId = (taskId_optional) 
      ? taskId_optional
      : this.props.match.params.collectionItemID;
    if (taskId) {
      const displayDialog = (task, extraState) => {
        this.setState({
          TaskForTaskDialog: task,
          TaskDialogIsOpen: true,
          TaskDialogIsCreateNew: false,
          PathnameForFilterStateRestore: pathnameForFilterStateRestore,
          ...extraState,
        });
        if (this.handleItemTitleChange) {
          this.handleItemTitleChange(task.Name);
        }
      }
      if (!tasks) {
        tasks = [...this.TasksForDialog];
      }
      const taskFinder = tasks.filter(t => t.ID === taskId);
      if (this.isWorkspace && taskFinder.length) {
        displayDialog(taskFinder[0]);
      }
      const uri = (this.isProjects)
        ? GetTaskPathForApi(this.OrganizationID, this.ProjectID, taskId)
        : (this.props.match.params.projectID)
          ? GetUserOrganizationProjectTaskPathForApi(this.OrganizationID, this.props.match.params.projectID, taskId)
          : null
      ;
      if (fromItemsChanged) {
        if (taskFinder.length) {
          displayDialog(taskFinder[0]);
        }
      } else if (uri) {
        API.get(uri)
          .then(resp => {
            displayDialog(resp.data, {
              ForcePrependItems: [resp.data],
            });
          })
          .catch(err => {
            this.handleApiError(err);
            this.handleTaskDialogClosed(null);
          });
      }
    }
  }

  // Returns whether further path changes can occur.
  updateProps(props) {
    Collection.prototype.updateProps.bind(this)(props);

    if (this.props.match.params.collectionItemID && !this.prevProps.match.params.collectionItemID) {
      this.handleTryDisplayTaskIfApplicable(null, null, false, this.prevProps.location.pathname);
      return false;
    } else if (!this.props.match.params.collectionItemID && this.prevProps.match.params.collectionItemID) {
      this.handleTaskDialogClosed(null);
      return false;
    }

    return true;
  }
}