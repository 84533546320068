import React, { Component } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  progress: {
    // margin: theme.spacing(2),
    color:theme.palette.type === "dark" ? "#888" : theme.palette.primary.main,
  },
  progressContainer: {
    position: "fixed",
    top:0,
    left:0,
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,0.3)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1250,
  },
});

class ProgressIndicator extends Component {
  render() {
    const {
      classes,
      showImmediately,
      showAfter2s,
      constrained,
      noBackground,
    } = this.props;

    let delay = "800ms";
    if (showImmediately) {
      delay = "0";
    } else if (showAfter2s) {
      delay = "2000ms";
    }

    return (
      <Fade in style={{transitionDelay: delay}}>
        <div
          className={classes.progressContainer}
          style={{
            position:(constrained) ? "absolute" : undefined,
            background: (noBackground) ? 0 : undefined,
          }}
          onContextMenu={e => e.preventDefault()}
        >
          <CircularProgress className={classes.progress} />
        </div>
      </Fade>
    );
  }
}

export default withStyles(styles, {withTheme: true})(ProgressIndicator);