import React, { Component } from 'react';

import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';

import API from '../Util/api';
import { GetCommentForDisplay } from '../Util/DocumentComments';
import { GlobalContext } from '../Context/Global.context';

import { MentionsInput, Mention } from 'react-mentions';
import ScrollToBottom from 'react-scroll-to-bottom';
import InfiniteScroll from 'react-infinite-scroller';
import dateformat from 'dateformat';
import debounce from 'es6-promise-debounce';
import PropTypes from 'prop-types';

import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  GetCommentSuggestionsPathForApi
} from '../Util/api';
import MultiUseDialog from '../Components/MultiUseDialog';
import {
  GetUserValue,
} from '../Util/Properties';

import classNames from 'classnames';

const _defaultCommentListBottomPadding = 58;
const styles = theme => ({
  comments: {
    position:"relative",
    height:"100%",
    display:"flex",
    flexDirection:"column",
  },
  commentsTop: {
    width:"100%",
    overflow:"auto",
    marginBottom: theme.spacing(2),
  },
  commentsTopHidden: {
    display:"none",
  },
  commentListContainer: {
    display:"flex",
    height: "100%",
  },
  commentsList: {
    marginTop: -theme.spacing(2),
    paddingRight: theme.spacing(3),
  },
  submitCommentButton: {
    height:"inherit",
    marginLeft:theme.spacing(1),
    marginTop:4,
    marginBottom:6,
  },
  commentListItem: {
    "&:hover $commentButton": {
      display:"inherit"
    },
  },
  commentButton: {
    display:"none",
    position:"absolute",
  },
  editButton: {
    right:24,
  },
  deleteButton: {
    right:0,
  },
  commentIcon: {
    fontSize:18,
  },
  newCommentDiv: {
    display:"flex",
    height:"max-content",
    flexGrow:1, 
    alignItems: "flex-end",
  },
  editCommentDiv: {
    display:"flex",
    height:"max-content",
    flexGrow:1, 
    alignItems: "flex-end",
  },
  commentCreatedOn: {
    fontWeight:400,
    fontSize:10,
    marginTop:3,
  },
  commentCreatedBy: {
    fontWeight:500,
    fontSize:12,
    // flexGrow:1,
    marginRight:theme.spacing(2),
  },
  commentHeader: {
    display:"flex",
  },
  commentComment: {
    marginLeft:54,
    marginTop:-12,
  },
  edited: {
    fontSize:10,
    marginLeft:8,
  },
});

const newCommentInputId = "newCommentInput";
const editCommentInputId = "editCommentInput";

class Comments extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.state = {
      NewCommentText: "",
      EditCommentText: "",
      EditCommentID: "",
      DeleteCommentID: "",
      ShowDeleteCommentConfirmation: false,
      CommentListBottomPadding: _defaultCommentListBottomPadding,
      Comments: [],
      CommentsCursor: "",
      CommentsCursorReverse: "",
      LastLoadOlderCommentsStart: new Date(),
      MoreCommentsExist: false,
    }

    this.mountDate = new Date();
    this.LoadNewCommentsCheckIntervalID = null;
    this.NewCommentContainerRef = React.createRef();
    this.NewCommentInputRef = null;
    this.EditCommentContainerRef = React.createRef();
    this.EditCommentInputRef = null;
  }

  compareComments = (a, b) => {
    if (new Date(a.CreatedOn) < new Date(b.CreatedOn)) {
      return -1;
    }
  };

  handleLoadComments = (reset, loadNewer, autoLoadNew) => {
    // This prevents multiple calls within the same timeframe from infinite-scroll component 
    if (!reset && !loadNewer) {
      if ((new Date()).getTime() < this.state.LastLoadOlderCommentsStart.getTime() + 1000) {
        return;
      }
      this.setState({
        LastLoadOlderCommentsStart: new Date(),
      });
    } 
    
    let params = {
      includeModifiedAfter: new Date(new Date().getTime() - 60000).toISOString(),
    };
    if (!reset) {
      params.cursor = (loadNewer)
        ? this.state.CommentsCursorReverse
        : this.state.CommentsCursor;
    }
    params.sortDescending = reset || !loadNewer;
    API.get(this.props.commentsUri, { params })
      .then(resp => {
        const loadedComments = resp.data[this.props.collectionName];
        const modifiedComments = resp.data.ModifiedEvents;
        let stateComments = [...this.state.Comments];
        if (reset) {
          stateComments = loadedComments;
        } else {
          // Ensure comments aren't duplicated / update modified comments
          let newComments = [];
          loadedComments.forEach(lc => {
            const existingComment = stateComments.find(c => c.ID === lc.ID);
            if (!existingComment) {
              newComments.push(lc);
            }
          });
          if (newComments.length) {
            stateComments = stateComments.concat(newComments);
          }
          if (modifiedComments && modifiedComments.length) {
            modifiedComments.forEach(mc => {
              const existingComment = stateComments.find(c => c.ID === mc.ID);
              if (existingComment) {
                existingComment.ModifiedOn = mc.ModifiedOn;
                existingComment.DetailJson = mc.DetailJson;
              }
            });
          }
        }
        let stateToUpdate = {
          Comments: stateComments.sort(this.compareComments),
        };
        // Cursors
        if (reset) {
          stateToUpdate.CommentsCursor = resp.data.Cursor;
          stateToUpdate.CommentsCursorReverse = resp.data.ReverseCursor;
        } else if (loadNewer) {
          if (resp.data.Cursor) {
            stateToUpdate.CommentsCursorReverse = resp.data.Cursor;
          }
        } else {
          stateToUpdate.CommentsCursor = resp.data.Cursor;
        }
        // MoreCommentsExist
        if (reset || !loadNewer) {
          stateToUpdate.MoreCommentsExist = loadedComments.length === resp.data.PageSize;
        }
        // If false, it was just recently aborted
        if (this.LoadNewCommentsCheckIntervalID !== false) {
          this.setState(stateToUpdate);
          if (autoLoadNew) {
            this.setLoadNewCommentsTimeout();
          }
        }
      })
      .catch(err => {
        // For now, terminate loop on any 418 response
        if (err.response && err.response.status === 418) {
          this.disableCommentAutoLoader();
          this.handleApiError(err);
        } else if (autoLoadNew) {
          this.setLoadNewCommentsTimeout();
        }
      });  
  }

  setLoadNewCommentsTimeout = () => {
    this.LoadNewCommentsCheckIntervalID = setTimeout(() => this.handleLoadComments(false, true, true), 3000);
  }

  disableCommentAutoLoader = () => {
    clearTimeout(this.LoadNewCommentsCheckIntervalID);
    this.LoadNewCommentsCheckIntervalID = false;
  }

  getCommentDetailJson = Comment => {
    return JSON.stringify({Comment});
  }

  getNewCommentObject = (tempId, newCommentText) => {
    return {
      pending: true,
      ID: tempId,
      CreatedByUserID: this.context.UserPreferences.UserID,
      CreatedByUserName: this.context.UserPreferences.UserName,
      CreatedByUserEmail: this.context.UserPreferences.UserEmail,
      CreatedOn: new Date(),
      Type: "Comment",
      DetailJson: this.getCommentDetailJson(newCommentText),
      ...this.props.newCommentAdditionalProperties
    };
  }

  handleAddComment = () => {
    const newCommentText = this.state.NewCommentText.trim();
    if (newCommentText.length < 1) {
      return;
    }
    this.disableCommentAutoLoader();
    let tempID = `comment${(new Date()).getTime()}`;
    let Comments = [...this.state.Comments];
    Comments.push(this.getNewCommentObject(tempID, newCommentText));
    this.setState({
      Comments,
      NewCommentText: "",
    });
    this.setCommentListBottomPadding();
    API.post(this.props.commentsUri, { Comments: [newCommentText] })
      .then(resp => {
        let comment = Comments.find(c => c.ID === tempID);
        if (comment) {
          comment.ID = resp.data[0].ID;
          comment.pending = false;
          this.setState({Comments});
        }
      })
      .catch(err => {
        const originalComments = [...this.state.Comments].filter(c => c.ID !== tempID);
        this.setState({
          NewCommentText: newCommentText,
          Comments: originalComments,
        });
        this.handleApiError(err);
      })
      .finally(() => {
        this.setLoadNewCommentsTimeout();
      });  
  }

  handleEditComment = () => {
    if (!this.state.EditCommentID) {
      return;
    }
    const EditCommentID = this.state.EditCommentID;
    const originalComments = [...this.state.Comments];
    let Comments = [...this.state.Comments];
    let comment = Comments.find(c => c.ID === EditCommentID);
    if (!comment) {
      return;
    }
    const EditCommentText = this.state.EditCommentText.trim();
    if (EditCommentText.length < 1) {
      return;
    }
    this.disableCommentAutoLoader();
    comment.ModifiedOn = new Date();
    comment.DetailJson = this.getCommentDetailJson(EditCommentText);
    this.setState({
      Comments,
      EditCommentText: "",
      EditCommentID: "",
    });
    API.put(`${this.props.commentsUri}/${comment.ID}`, comment)
      .then(resp => {
        comment = resp.data;
        this.setState({ Comments });
      })
      .catch(err => {
        this.setState({
          Comments: originalComments,
          EditCommentText,
          EditCommentID,
        });
        this.handleApiError(err);
      })
      .finally(() => {
        this.setLoadNewCommentsTimeout();
      }); 
  }

  handleDeleteComment = () => {
    this.handleSetShowDeleteCommentConfirmation(false);
    if (!this.state.DeleteCommentID) {
      return;
    }
    const DeleteCommentID = this.state.DeleteCommentID;
    const originalComments = [...this.state.Comments];
    let Comments = [...this.state.Comments]
      .filter(c => c.ID !== DeleteCommentID);
    this.disableCommentAutoLoader();
    this.setState({
      Comments,
      DeleteCommentID: "",
    });
    API.delete(`${this.props.commentsUri}/${DeleteCommentID}`)
      .catch(err => {
        this.setState({
          Comments: originalComments,
          DeleteCommentID,
        });
        this.handleApiError(err);
      })
      .finally(() => {
        this.setLoadNewCommentsTimeout();
      }); 
  }

  getCommentInput = (suggestionsPortalHostRef, id, value, onChange, onKeyDown, onGetInputRef) => props => {
    const {inputRef, ...other} = props;
    return (
      <MentionsInput
        {...other}
        id={id}
        style={{
          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
          input: {
            // height:60,
            border:0,
            outline:0,
            color: this.props.theme.palette.text.primary,
          },
          suggestions: {
            left:0,
            right:"unset",
            backgroundColor: this.props.theme.palette.background.paper,
            boxShadow: "0 0 3px 1px #808080",
            borderRadius:4,
            padding:this.props.theme.spacing(2),
            item: {
              padding:this.props.theme.spacing(1)/2,
              "&focused": {
                backgroundColor: this.props.theme.palette.background.default,
              },
            },
            list: {
              maxHeight:250,
              overflowY:"auto",
            },
          },
        }}
        inputRef={ref => { if (onGetInputRef) { onGetInputRef(ref); }}}
        tabIndex={-1}
        autoFocus={this.props.autoFocus}
        allowSuggestionsAboveCursor
        forceSuggestionsAboveCursor
        suggestionsPortalHost={(suggestionsPortalHostRef && suggestionsPortalHostRef.current) ? suggestionsPortalHostRef : undefined}
        onChange={onChange}
        value={value}
        onKeyDown={onKeyDown}
      >
        <Mention
          trigger="@"
          data={this.handleLoadCommentSuggestions}
          markup="@{{[__display__]_(__id__)}}"
          displayTransform={(id, display) => `@${display} `}
          renderSuggestion={this.handleRenderSuggestion}
        />
      </MentionsInput>
    );
  }

  newCommentInput = props => {
    return this.getCommentInput(
      this.NewCommentContainerRef,
      newCommentInputId,
      this.state.NewCommentText,
      this.handleNewCommentValueChange,
      this.handleNewCommentKeyDown,
      inputRef => {
        if (inputRef) {
          this.NewCommentInputRef = inputRef;
        }
      },
    )
    (props);
  }

  editCommentInput = props => {
    return this.getCommentInput(
      this.EditCommentContainerRef,
      editCommentInputId,
      this.state.EditCommentText,
      this.handleEditCommentValueChange,
      this.handleEditCommentKeyDown,
      inputRef => {
        if (inputRef) {
          this.EditCommentInputRef = inputRef;
          setTimeout(() => {
            // Ensure cursor begins at the end of the line
            inputRef.selectionStart = inputRef.value.length;
            inputRef.selectionEnd = inputRef.selectionStart;
          }, 1);
        }
      },
    )
    (props);
  }

  handleNewCommentValueChange = event => {
    this.setState({
      NewCommentText: event.target.value,
    });
    this.setCommentListBottomPadding();
  }

  handleEditCommentValueChange = event => {
    this.setState({
      EditCommentText: event.target.value,
    });
    // this.setCommentListBottomPadding();
  }

  handleNewCommentKeyDown = e => { 
    // ENTER
    if (e.keyCode === 13 && !e.shiftKey) { 
      e.preventDefault(); 
      this.handleAddComment(); 
    }
    // UP
    if (e.keyCode === 38 && !this.state.NewCommentText) {
      // Find recent comment by user and edit
      let reverseComments = [...this.state.Comments].reverse();
      for (let i = 0; i < reverseComments.length; i++) {
        const comment = reverseComments[i];
        if (comment.CreatedByUserID === this.context.UserPreferences.UserID) {
          this.setState({
            EditCommentID: comment.ID,
            EditCommentText: JSON.parse(comment.DetailJson).Comment,
          })
          break;
        }
      }
    }
  }

  handleEditCommentKeyDown = e => { 
    // ENTER
    if (e.keyCode === 13 && !e.shiftKey) { 
      e.preventDefault(); 
      this.handleEditComment(); 
    }
    // ESC
    if (e.keyCode === 27) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({
        EditCommentID:"",
        EditCommentText:"",
      });
      this.setLoadNewCommentsTimeout();
    }
  }

  handleLoadCommentSuggestions = debounce((nameFilter, callback) => {
    let params = {
      nameFilter: nameFilter,
    };
    API.get(GetCommentSuggestionsPathForApi(this.props.organizationId, this.props.projectId),
      { params: params }
    )
      .then(resp => {
        let suggestions = [];
        resp.data.Suggestions.forEach(s => {
          const display = 
            (s.UserName)
              ? `${s.UserName} (${s.UserEmail})`
              : s.UserEmail;
          const fancyDisplay = GetUserValue(s.UserEmail, (s.UserName)
            ? `${s.UserName} (${s.UserEmail})`
            : s.UserEmail);
          suggestions.push({
            id: s.ProjectMemberID,
            display,
            fancyDisplay,
          });
        });
        if (suggestions.length) {
          callback(suggestions);
        }
      })
      .catch(err => {
        // Ignore 403, which is likely due to the user not being a project member
        if (err.response && err.response.status === 403) {
          callback([]);
        } else {
          this.handleApiError(err);
        }
      });
  }, 250);

  handleRenderSuggestion = entry => {
    return entry.fancyDisplay;
  }

  setCommentListBottomPadding = () => {
    setTimeout(() => {
      this.setState({
        CommentListBottomPadding: (this.NewCommentContainerRef) ? this.NewCommentContainerRef.scrollHeight : _defaultCommentListBottomPadding,
      });
    }, 50);
  }

  handleBeginEditComment = EditCommentID => {
    if (!EditCommentID) {
      return;
    }
    
    this.disableCommentAutoLoader();

    let EditCommentText = "";
    const comment = [...this.state.Comments].find(c => c.ID === EditCommentID);
    if (comment) {
      EditCommentText = JSON.parse(comment.DetailJson).Comment;
    }

    this.setState({
      EditCommentID,
      EditCommentText,
    });
  }

  handleBeginDeleteComment = DeleteCommentID => {
    if (!DeleteCommentID) {
      return;
    }
    
    this.setState({
      DeleteCommentID,
    });

    this.handleSetShowDeleteCommentConfirmation(true);
  }

  handleSetShowDeleteCommentConfirmation = ShowDeleteCommentConfirmation => {
    this.setState({ShowDeleteCommentConfirmation});
  }

  handleCommentsListClick = e => {
    if (this.state.EditCommentID) {
      this.setState({
        EditCommentID: "",
        EditCommentText: "",
      });
    }
  }

  handleTextFieldClick = (e, inputRefGetter) => {
    e.stopPropagation();
    if (inputRefGetter) {
      setTimeout(() => inputRefGetter().focus(), 1);
    }
  }

  getNewCommentInputRef = () => {
    return this.NewCommentInputRef;
  }

  getEditCommentInputRef = () => {
    return this.EditCommentInputRef;
  }

  handleApiError = err => {
    this.props.onApiError(err);
  }

  componentDidMount() {
    this.handleLoadComments(true, true, true);
    this.setCommentListBottomPadding();
    window.addEventListener('resize', this.setCommentListBottomPadding);
  }

  componentWillUnmount() {
    this.disableCommentAutoLoader();
    window.removeEventListener('resize', this.setCommentListBottomPadding);
  }

  componentDidUpdate(prevProps) {
    if (this.props.focusOnNewComment !== undefined
      && prevProps.focusOnNewComment !== this.props.focusOnNewComment) {
      if (this.props.focusOnNewComment) {
        let textAreas = document.getElementsByClassName("MuiInputBase-input__input");
        if (textAreas.length) {
          for (let i = 0; i < textAreas.length; i++) {
            if (textAreas[i].id === newCommentInputId) {
              setTimeout(() => textAreas[i].focus(), 250);  
              break;
            }
          }
        }
      }
    }
  }

  render() {
    const {
      Comments,
      MoreCommentsExist,
      NewCommentText,
      CommentListBottomPadding,
      EditCommentID,
      EditCommentText,
      ShowDeleteCommentConfirmation,
    } = this.state;
    const {
      newCommentBottomFixed,
      theme,
      classes,
    } = this.props;
    const {
      UserID,
    } = this.context.UserPreferences;

    const getEditButton = commentId => (
      <Tooltip title="Edit comment">
        <IconButton
          size="small"
          className={classNames(classes.editButton, classes.commentButton)}
          onClick={() => this.handleBeginEditComment(commentId)}
        >
          <EditIcon className={classes.commentIcon} />
        </IconButton>
      </Tooltip>
    );

    const getDeleteButton = commentId => (
      <Tooltip title="Delete comment">
        <IconButton
          size="small"
          className={classNames(classes.deleteButton, classes.commentButton)}
          onClick={() => this.handleBeginDeleteComment(commentId)}
        >
          <DeleteIcon className={classes.commentIcon} />
        </IconButton>
      </Tooltip>
    );

    const getCommentTextField = (placeholder, inputComponent, inputRefGetter) => {
      return (
        <TextField
          variant="outlined"
          autoComplete="off"
          placeholder={placeholder}
          InputProps={{
            inputComponent,
          }}
          multiline
          fullWidth
          onClick={e => this.handleTextFieldClick(e, inputRefGetter)}
        />
      );
    }

    const deleteCommentConfirmationDialogDetails = {
      Open:ShowDeleteCommentConfirmation,
      IsConfirmation:true,
      Title:"Delete comment?",
      BodyText:"This action cannot be undone.",
      BodyClassName:"warning",
      CancelCallback:() => this.handleSetShowDeleteCommentConfirmation(false),
      CloseCallback:() => this.handleSetShowDeleteCommentConfirmation(false),
      ConfirmCallback:() => this.handleDeleteComment(),
    };

    const commentListItems = [];
    if (Comments && Comments.length) {
      Comments.forEach(c => {
        if (c.DetailJson) {
          const detail = JSON.parse(c.DetailJson);
          if (detail) {
            const createdBy = GetUserValue(
              c.CreatedByUserEmail,
              c.CreatedByUserName,
              "lg",
              false,
              undefined,
              { marginRight:8 },
              { alignItems:"flex-start" },
            );
            const createdByContent = (createdBy)
              ? (<Typography variant="subtitle1" className={classes.commentCreatedBy}>{createdBy}</Typography>)
              : null;
            const createdOn = (c.CreatedOn) ? dateformat(new Date(c.CreatedOn), "m/d/yyyy h:MM:ss TT") : null;
            const comment = GetCommentForDisplay(detail.Comment, theme);
            const edited = (new Date(c.ModifiedOn) > new Date(c.CreatedOn))
              ?  (
                <span className={classes.edited}>(edited)</span>
              ) : null;

            if (commentListItems.length) {
              commentListItems.push(<Divider key={`divider_${c.ID}`} />);
            }
            let editButton, deleteButton;
            if (!EditCommentID && c.CreatedByUserID === UserID && !c.pending) {
              editButton = getEditButton(c.ID);
              deleteButton = getDeleteButton(c.ID);
            }
            let editCommentContainer;
            if (EditCommentID && c.ID === EditCommentID) {
              const submitModifiedCommentIconButton = (EditCommentText) 
                ? (
                  <Tooltip title="Submit">
                    <IconButton
                      className={classes.submitCommentButton}
                      onClick={() => this.handleEditComment()}
                    >
                      <SendIcon />
                    </IconButton>
                  </Tooltip>
                ) : null;
              
              const editCommentTextField = getCommentTextField(
                null,
                this.editCommentInput,
                this.getEditCommentInputRef,
              );
              editCommentContainer = (
                <div className={classes.editCommentDiv}
                  ref={instance => this.EditCommentContainerRef = instance}
                >
                  {editCommentTextField}
                  {submitModifiedCommentIconButton}
                </div>
              );
            };

            const commentGrid = (!EditCommentID || EditCommentID !== c.ID) ? (
              <Grid container direction="column">
                <div className={classes.commentHeader}>
                  {createdByContent}
                  <Typography variant="caption" className={classes.commentCreatedOn}>{createdOn}</Typography>
                  {editButton}
                  {deleteButton}
                </div>
                <Grid item className={classes.commentGridItem}>
                  <Typography className={classes.commentComment} variant="body1">{comment}{edited}</Typography>
                </Grid>
              </Grid>
            ) : null;

            commentListItems.push(
              <ListItem key={c.ID} disableGutters className={classes.commentListItem}>
                {commentGrid}
                {editCommentContainer}
              </ListItem>
            );
          }
        }
        return null;
      });
    }

    const commentsList = (commentListItems)
      ? (
        <List className={classes.commentsList}
          onClick={this.handleCommentsListClick}
        >
          {commentListItems}
          <MultiUseDialog Details={deleteCommentConfirmationDialogDetails} />
        </List>
      ) : null;

    const submitCommentIconButton = (NewCommentText) 
      ? (
        <Tooltip title="Submit">
          <IconButton
            className={classes.submitCommentButton}
            onClick={() => this.handleAddComment()}
          >
            <SendIcon />
          </IconButton>
        </Tooltip>
      ) : null;
    
    const newCommentTextField = getCommentTextField(
      "Enter a new comment...",
      this.newCommentInput,
      this.getNewCommentInputRef,
    );
    const newCommentDiv = (
      <div className={classes.newCommentDiv}
        style={{
          bottom: (newCommentBottomFixed) ? 0 : undefined,
          position: (newCommentBottomFixed) ? "absolute" : undefined,
          width: (newCommentBottomFixed) ? "100%" : undefined,
        }}
        ref={instance => this.NewCommentContainerRef = instance}
      >
        {newCommentTextField}
        {submitCommentIconButton}
      </div>
    );

    let scrollToBottomClasses = [classes.commentsTop];
    if (!Comments || !Comments.length) {
      scrollToBottomClasses.push(classes.commentsTopHidden);
    }
    const scrollToBottomClassName = classNames(...scrollToBottomClasses);

    let scrollToBottom = (
      <ScrollToBottom className={scrollToBottomClassName}>
        <InfiniteScroll
          useWindow={false}
          isReverse
          loadMore={() => this.handleLoadComments()}
          hasMore={MoreCommentsExist && (new Date()).getTime() - 2000 > this.mountDate.getTime()}
          initialLoad={false}
        >
          {commentsList}
        </InfiniteScroll>
      </ScrollToBottom>
    );
    if (newCommentBottomFixed) {
      scrollToBottom = (
        <div className={classes.commentListContainer}
          style={{
            paddingBottom: CommentListBottomPadding,
          }}
        >
          {scrollToBottom}
        </div>
      );
    }

    return (
      <div className={classes.comments}
        style={{
          overflow: (newCommentBottomFixed) ? "hidden" : "auto",
        }}
      >
        {scrollToBottom}
        {newCommentDiv}
      </div>
    );
  }
}

Comments.propTypes = {
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  commentsUri: PropTypes.string.isRequired,
  collectionName: PropTypes.string.isRequired,
  newCommentBottomFixed: PropTypes.bool,
  onApiError: PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(Comments);