const DocumentSignatureSessionRecipientType = [
	{
		Type: "Signer",
		Label: "Signer",
	},
	{
		Type: "Recipient",
		Label: "Recipient",
	},
];

export default DocumentSignatureSessionRecipientType;