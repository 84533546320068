import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Card from './Card';

import {
  GetDateValue,
} from '../Util/Properties';

class AssetCard extends Component {
  render() {
    const {
      Asset,
      onCardAction,
      style,
      noSelect,
      onSelect,
      selected,
      isSearchResults
    } = this.props; 

    let metadata = [
      ["Plural Name", Asset.PluralName],
      ["Project", Asset.ProjectName],
      ["Created On", GetDateValue(Asset.CreatedOn)],
    ];

    return (
      <Card
        style={style}
        noSelect={noSelect}
        onSelect={onSelect}
        selected={selected}
        onCardAction={onCardAction}
        name={Asset.Name}
        isSearchResults={isSearchResults}
        metadata={metadata}
        contentItem={Asset}
      />
    );
  }
}

AssetCard.propTypes = {
  Asset: PropTypes.object.isRequired,
  onCardAction: PropTypes.func,
};

export default AssetCard;