import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Card from '../../Components/Card';

import {
  GetDateValue,
} from '../../Util/Properties';

class OrganizationProjectCard extends Component {
  render() {
    const {
      Project,
      style,
      height,
      onCardAction,
      onSelect,
      noSelect,
      selected,
      isSearchResults,
    } = this.props; 

    let metadata = [];
    let createdOnDate = new Date(Project.CreatedOn);
    let createdOn = (createdOnDate > new Date("2000-01-01")) ? GetDateValue(Project.CreatedOn) : null;
    if (createdOn) {
      metadata.push(["Created On", createdOn]);
    }
    
    return (
      <Card
        style={style}
        height={height}
        onCardAction={onCardAction}
        onSelect={onSelect}
        noSelect={noSelect}
        selected={selected}
        name={Project.Name}
        metadata={metadata}
        minHeight={100}
        isSearchResults={isSearchResults}
        contentItem={Project}
      />
    );
  }
}

OrganizationProjectCard.propTypes = {
  Project: PropTypes.object.isRequired,
  onCardAction: PropTypes.func.isRequired,
};

export default OrganizationProjectCard;