import React from 'react';
import Button from '@material-ui/core/Button';

const GetMoreButton = (visible, onClick, viewType, theme, styleOverride) => {
	let getMoreButton;
	if (visible) {
		let getMoreButtonStyle = (styleOverride)
      ? styleOverride
      : {
        margin: viewType === "List" ? theme.spacing(2) : `${theme.spacing(2)}px 0 0 0`,
        height:theme.spacing(7),
        width:`calc(100% - ${viewType === "List" ? theme.spacing(4) : 0}px)`,
      };
    getMoreButton = (
      <Button style={getMoreButtonStyle} onClick={onClick}>GET MORE</Button>
    );
	}
	return getMoreButton;
}

export default GetMoreButton;