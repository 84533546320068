import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import AddIcon from '@material-ui/icons/Add';

import ProgressIndicator from './ProgressIndicator';
import KanbanColumn from './KanbanColumn';
import KanbanTaskDragLayer from './KanbanTaskDragLayer';
import MultiUseDialog from './MultiUseDialog';
// import GetMoreButton from '../Util/GetMoreButton';
import TaskDialog from '../Components/TaskDialog';

// import { IsMobile } from '../Util/MobileDetector';

import API, {
  GetTaskStatsPathForApi,
  GetTaskMilestonesPathForApi,
  GetTaskMilestonePathForApi,
  GetTaskStatesPathForApi,
  GetTaskStatePathForApi,
  GetProjectMembersPathForApi,
} from '../Util/api';
import { 
  GetMetaField, 
} from '../Util/Search';
import {
  GetTaskMilestonesPromise,
} from '../Util/TaskMilestones';
import {
  GetTaskStatesPromise,
} from '../Util/TaskStates';
import TaskPriorityType from '../Model/TaskPriorityType';
import {
  GetUserValue,
} from '../Util/Properties';
import {
  GetNewTaskMilestone,
} from '../Model/TaskMilestone';
import {
  GetNewTaskState,
} from '../Model/TaskState';
import {
  HandleStartMoveTaskMilestone,
  HandleMoveTaskMilestoneOverTaskMilestone,
  HandleAbortMoveTaskMilestone,
  HandleEndMoveTaskMilestone,
} from '../Util/TaskMilestone';
import {
  HandleStartMoveTaskState,
  HandleMoveTaskStateOverTaskState,
  HandleAbortMoveTaskState,
  HandleEndMoveTaskState,
} from '../Util/TaskState';
import {
  ViewType_KanbanAssignment,
  ViewType_KanbanPriority,
  ViewType_KanbanMilestone,
  ViewType_KanbanState,
} from '../Util/ViewType';

import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

const styles = theme => ({
  scrollContainer: {
    height:"100%",
    overflow:"auto",
  },
  kbContainer: {
    display:"flex",
    padding:theme.spacing(1),
    width:"max-content",
    minHeight:"100%",
  },
  newColumnButton: {
    marginTop:theme.spacing(1),
    height:48,
  },
});

class KanbanBase extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      TaskMilestones: [],
      TaskMilestoneToDelete: null,
      TaskMilestoneToUpdate: null,
      TaskStates: [],
      TaskStateToDelete: null,
      TaskStateToUpdate: null,
      ProjectMembers: [],
      CollectionNameForTaskCreation: "",
      TaskPropertiesForTaskCreation: null,
      ShowCreateTaskDialog: false,
      ColumnActionMenuIsOpen: false,
      ColumnsAreUpdating: false,
      CollectionIsDragging: false,
      ShowAddMilestoneDialog: false,
      ShowRenameMilestoneDialog: false,
      ShowDeleteMilestoneConfirmationDialog: false,
      ShowAddStateDialog: false,
      ShowRenameStateDialog: false,
      ShowDeleteStateConfirmationDialog: false,
      ShowTaskStatsDialog: false,
      TaskStats: null,
      TaskStatsTitle: "",
      IncludeTaskStatsFilterDisclaimer: false,
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
    }

    this.ScrollContainerRef = React.createRef();
    this.ScrollTimer = null;
    this.ScrollMargin = 100;    
  }

  scrollToTopRight = () => {
    const viewportWidth = this.ScrollContainerRef.clientWidth;
    const containerWidth = this.ScrollContainerRef.scrollWidth;
    const maxScrollX = containerWidth - viewportWidth;
    this.ScrollContainerRef.scrollTo(maxScrollX, 0);
  }

  handleDragOverForEnhancedScroll = e => {
    const x = e.clientX - this.ScrollContainerRef.offsetLeft;
    const y = e.clientY - this.ScrollContainerRef.offsetTop;

    // console.log(x, y);

    const viewportWidth = this.ScrollContainerRef.clientWidth;
    const viewportHeight = this.ScrollContainerRef.clientHeight;

    const scrollMargin = Math.min(this.ScrollMargin, (Math.min(viewportWidth, viewportHeight) / 2));

    // console.log(x, y, viewportWidth, viewportHeight);

    const edgeLeft = 0 + scrollMargin;
    const edgeRight = viewportWidth - scrollMargin;
    const edgeTop = 0 + scrollMargin;
    const edgeBottom = viewportHeight - scrollMargin;

    const isLeft = x < edgeLeft;
    const isRight = x > edgeRight;
    const isTop = y < edgeTop;
    const isBottom = y > edgeBottom;

    // console.log(isLeft, isRight, isTop, isBottom);

    if (!(isLeft || isRight || isTop || isBottom)) {
      clearTimeout(this.ScrollTimer);
      return;
    }

    const containerWidth = this.ScrollContainerRef.scrollWidth;
    const containerHeight = this.ScrollContainerRef.scrollHeight;

    const maxScrollX = containerWidth - viewportWidth;
    const maxScrollY = containerHeight - viewportHeight;

    // console.log(containerWidth, viewportWidth, maxScrollX);

    const handleContainerScroll = () => {
      const scrollX = this.ScrollContainerRef.scrollLeft;
      const scrollY = this.ScrollContainerRef.scrollTop;

      const canScrollUp = scrollY > 0;
      const canScrollDown = scrollY < maxScrollY;
      const canScrollLeft = scrollX > 0;
      const canScrollRight = scrollX < maxScrollX;

      // console.log(canScrollUp, canScrollDown, canScrollLeft, canScrollRight);

      let nextScrollX = scrollX;
      let nextScrollY = scrollY;

      const maxStep = 50;
      let intensity;
      if (isLeft && canScrollLeft) {
        intensity = (edgeLeft - x) / scrollMargin;
        nextScrollX -= (maxStep * intensity);
      } else if (isRight && canScrollRight) {
        intensity = (x - edgeRight) / scrollMargin;
        nextScrollX += (maxStep * intensity);
      } else if (isTop && canScrollUp) {
        intensity = (edgeTop - y) / scrollMargin;
        nextScrollY -= (maxStep * intensity);
      } else if (isBottom && canScrollDown) {
        intensity = (y - edgeBottom) / scrollMargin;
        nextScrollY += (maxStep * intensity);
      }

      nextScrollX = Math.max(0, Math.min(maxScrollX, nextScrollX));
      nextScrollY = Math.max(0, Math.min(maxScrollY, nextScrollY));

      if (nextScrollX !== scrollX || nextScrollY !== scrollY) {
        this.ScrollContainerRef.scrollTo(nextScrollX, nextScrollY);
        return true;
      } else {
        return false;
      }
    }

    // const checkForContainerScroll = () => {
    //   clearTimeout(this.ScrollTimer);
    //   if (handleContainerScroll()) {
    //     this.ScrollTimer = setTimeout(checkForContainerScroll, 30);
    //   }
    // }
    // checkForContainerScroll();
    handleContainerScroll();
  }

  canLoadColumnItems = () => {
    return !this.state.ColumnsAreUpdating
      && !this.state.ColumnActionMenuIsOpen
      && !this.state.CollectionIsDragging
    ;
  }

  handleLoadProjectMembers = (caller, noProgressIndicator) => {
    // console.log("handleLoadProjectMembers");
    if (!this.canLoadColumnItems()) {
      // console.log("handleLoadProjectMembers early exit 1");
      this.setState({ ShowProgressIndicatorImmediately: false });
      return;
    }
    if (!noProgressIndicator) {
      this.setState({ShowProgressIndicatorImmediately:true});
    }
    let params = {
      getAll: true,
      sortType: "Meta_text_kw256lc[Name].keyword",
    };
    API.get(GetProjectMembersPathForApi(this.props.organizationId, this.props.projectId), { params })
      .then(resp => {
        if (!this.canLoadColumnItems() || !resp.data) {
          // console.log("handleLoadProjectMembers early exit 2");
          this.setState({ ShowProgressIndicatorImmediately: false });
          return;
        }
        this.setState({
          ProjectMembers: resp.data.ProjectMembers,
          ShowProgressIndicatorImmediately: false,
        });
      })
      .catch(err => {
        if (caller === "handleRefreshColumns") {
          return;
        }
        this.handleApiError(err);
      });
  }

  handleLoadMilestones = (caller, noProgressIndicator, scrollToTopRight) => {
    // console.log("handleLoadMilestones");
    if (!this.canLoadColumnItems() && caller !== "milestoneFunc") {
      // console.log("handleLoadMilestones early exit 1");
      this.setState({ ShowProgressIndicatorImmediately: false });
      return;
    }
    if (!noProgressIndicator) {
      this.setState({ShowProgressIndicatorImmediately:true});
    }
    GetTaskMilestonesPromise(this.props.organizationId, this.props.projectId, null, true, 
      false, false, null, true)
      .then(taskMilestoneList => {
        if (!this.canLoadColumnItems() && caller !== "milestoneFunc") {
          // console.log("handleLoadMilestones early exit 2");
          if (!noProgressIndicator) {
            this.setState({ ShowProgressIndicatorImmediately: false });
          }
          return;
        }
        this.setState({
          TaskMilestones: taskMilestoneList.TaskMilestones,
          ShowProgressIndicatorImmediately: false,
        });
        if (scrollToTopRight) {
          this.scrollToTopRight();
        }
      })
      .catch(err => {
        if (caller === "handleRefreshColumns") {
          return;
        }
        this.handleApiError(err);
      });
  }

  handleSetShowAddMilestoneDialog = ShowAddMilestoneDialog => {
    this.setState({ShowAddMilestoneDialog});
  }

  handleAddMilestone = Name => {
    if (!Name) {
      return;
    }
    this.handleSetShowAddMilestoneDialog(false);
    this.setState({
      ColumnsAreUpdating: true,
      ShowProgressIndicatorImmediately: true,
    });
    const newMilestone = GetNewTaskMilestone(Name);
    API.post(GetTaskMilestonesPathForApi(this.props.organizationId, this.props.projectId), [newMilestone])
      .then(resp => {
        this.handleLoadMilestones("milestoneFunc", true, true);
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ ColumnsAreUpdating: false });
      });
  }

  handleBeginGetStats = (columnFilter, TaskStatsTitle)  => {
    let metaFieldFilters = this.props.metaFieldFilters;     
    if (metaFieldFilters) {
      metaFieldFilters = metaFieldFilters.concat(columnFilter);
    } else {
      metaFieldFilters = [columnFilter];
    }
    const includeTaskStatsFilterDisclaimer = (this.props.fullTextFilter
      || (this.props.metaFieldFilters && this.props.metaFieldFilters.length));
    this.handleGetStats(metaFieldFilters, TaskStatsTitle, includeTaskStatsFilterDisclaimer);
  }

  handleBeginGetStatsForProjectMember = projectMember => {
    const projectMemberFilter = 
      GetMetaField(
        "Meta_text_kw256lc[AssignmentUserEmail].keyword", 
        "FieldType_Text",
        projectMember.UserEmail,
        "",
        "equals",
      );

    this.handleBeginGetStats(projectMemberFilter, projectMember.UserEmail);
  }

  handleBeginGetStatsForPriority = priority => {
    const priorityFilter = 
      GetMetaField(
        "Meta_int[Priority]", 
        "FieldType_Number",
        priority.toString(),
        "",
        "equals",
      );
    let priorityLabel = "";
    const priorityTypeFinder = TaskPriorityType.filter(t => t.Type === priority);
    if (priorityTypeFinder.length) {
      priorityLabel = priorityTypeFinder[0].Label;
    }
    this.handleBeginGetStats(priorityFilter, priorityLabel);
  }

  handleBeginGetStatsForTaskMilestone = taskMilestone => {
    const taskMilestoneFilter = 
      GetMetaField(
        "Meta_text_kw256lc[MilestoneName].keyword", 
        "FieldType_Text",
        taskMilestone.Name,
        "",
        "equals",
      );
    this.handleBeginGetStats(taskMilestoneFilter, taskMilestone.Name);
  }

  handleBeginGetStatsForTaskState = taskState => {
    const taskStateFilter = 
      GetMetaField(
        "Meta_text_kw256lc[StateName].keyword", 
        "FieldType_Text",
        taskState.Name,
        "",
        "equals",
      );
    this.handleBeginGetStats(taskStateFilter, taskState.Name);
  }

  handleGetStats = (metaFieldFilters, TaskStatsTitle, IncludeTaskStatsFilterDisclaimer) => {
    this.setState({
      ShowProgressIndicatorImmediately:true,
      TaskStats: null,
    });
    let params = {};
    if (this.props.fullTextFilter) {
      params.fullTextFilter = this.props.fullTextFilter;
    }
    if (metaFieldFilters) {
      params.metaFieldFilters_json = JSON.stringify(metaFieldFilters);
    }
    API.get(GetTaskStatsPathForApi(this.props.organizationId, this.props.projectId), { params })
      .then(resp => {
        this.setState({
          ShowProgressIndicatorImmediately:false,
          TaskStatsTitle,
          IncludeTaskStatsFilterDisclaimer,
          TaskStats: resp.data,
        });
        this.handleSetShowTasksStatsDialog(true);
      })
      .catch(this.handleApiError);
  }

  handleSetShowTasksStatsDialog = ShowTaskStatsDialog => {
    this.setState({ShowTaskStatsDialog});
  }

  handleBeginRenameMilestone = TaskMilestoneToUpdate => {
    this.setState({TaskMilestoneToUpdate});
    this.handleSetShowRenameMilestoneDialog(true);
  }

  handleSetShowRenameMilestoneDialog = ShowRenameMilestoneDialog => {
    this.setState({ShowRenameMilestoneDialog});
  }

  handleUpdateMilestoneName = Name => {
    if (!Name) {
      return;
    }
    if (!this.state.TaskMilestoneToUpdate || !this.state.TaskMilestoneToUpdate.ID) {
      return;
    }
    this.handleSetShowRenameMilestoneDialog(false);
    const taskMilestoneToUpdate = {
      ...this.state.TaskMilestoneToUpdate,
      Name,
    }
    this.handleUpdateMilestone(taskMilestoneToUpdate);
  }

  handleUpdateMilestone = taskMilestoneToUpdate => {
    this.setState({
      ColumnsAreUpdating: true,
      ShowProgressIndicatorImmediately: true,
    });
    
    API.put(GetTaskMilestonePathForApi(this.props.organizationId, this.props.projectId, 
      taskMilestoneToUpdate.ID), taskMilestoneToUpdate)
      .then(resp => {
        this.handleLoadMilestones("milestoneFunc", true);
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ColumnsAreUpdating: false});
      });
  }

  handleUpdateMilestones = taskMilestonesToUpdate => {
    this.setState({
      ColumnsAreUpdating: true,
      ShowProgressIndicatorImmediately: true,
    });
    
    API.put(GetTaskMilestonesPathForApi(this.props.organizationId, this.props.projectId), taskMilestonesToUpdate)
      .then(resp => {
        this.handleLoadMilestones("milestoneFunc", true);
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ColumnsAreUpdating: false});
      });
  }

  handleBeginDeleteMilestone = TaskMilestoneToDelete => {
    this.setState({TaskMilestoneToDelete});
    this.handleSetShowDeleteMilestoneConfirmationDialog(true);
  }

  handleSetShowDeleteMilestoneConfirmationDialog = ShowDeleteMilestoneConfirmationDialog => {
    this.setState({ShowDeleteMilestoneConfirmationDialog});
  }

  handleDeleteMilestone = () => {
    this.handleSetShowDeleteMilestoneConfirmationDialog(false);
    if (!this.state.TaskMilestoneToDelete || !this.state.TaskMilestoneToDelete.ID) {
      return;
    }
    this.setState({
      ColumnsAreUpdating: true,
      ShowProgressIndicatorImmediately: true,
    });
    API.delete(GetTaskMilestonesPathForApi(this.props.organizationId, this.props.projectId),
      { data: { IDs:[this.state.TaskMilestoneToDelete.ID] } }
    )
      .then(resp => {
        this.handleLoadMilestones("milestoneFunc", true);
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ColumnsAreUpdating: false});
      });
  }

  handleLoadStates = (caller, noProgressIndicator, scrollToTopRight) => {
    // console.log("handleLoadStates");
    if (!this.canLoadColumnItems() && caller !== "stateFunc") {
      // console.log("handleLoadStates early exit 1");
      this.setState({ ShowProgressIndicatorImmediately: false });
      return;
    }
    if (!noProgressIndicator) {
      this.setState({ShowProgressIndicatorImmediately:true});
    }
    GetTaskStatesPromise(this.props.organizationId, this.props.projectId, null, true, 
      false, false, null, true)
      .then(taskStateList => {
        if (!this.canLoadColumnItems() && caller !== "stateFunc") {
          // console.log("handleLoadStates early exit 2");
          if (!noProgressIndicator) {
            this.setState({ ShowProgressIndicatorImmediately: false });
          }
          return;
        }
        this.setState({
          TaskStates: taskStateList.TaskStates,
          ShowProgressIndicatorImmediately: false,
        });
        if (scrollToTopRight) {
          this.scrollToTopRight();
        }
      })
      .catch(err => {
        if (caller === "handleRefreshColumns") {
          return;
        }
        this.handleApiError(err);
      });
  }

  handleSetShowAddStateDialog = ShowAddStateDialog => {
    this.setState({ShowAddStateDialog});
  }

  handleAddState = Name => {
    if (!Name) {
      return;
    }
    this.handleSetShowAddStateDialog(false);
    this.setState({
      ColumnsAreUpdating: true,
      ShowProgressIndicatorImmediately: true,
    });
    const newState = GetNewTaskState(Name);
    API.post(GetTaskStatesPathForApi(this.props.organizationId, this.props.projectId), [newState])
      .then(resp => {
        this.handleLoadStates("stateFunc", true, true);
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ ColumnsAreUpdating: false });
      });
  }

  handleBeginRenameState = TaskStateToUpdate => {
    this.setState({TaskStateToUpdate});
    this.handleSetShowRenameStateDialog(true);
  }

  handleSetShowRenameStateDialog = ShowRenameStateDialog => {
    this.setState({ShowRenameStateDialog});
  }

  handleUpdateStateName = Name => {
    if (!Name) {
      return;
    }
    if (!this.state.TaskStateToUpdate || !this.state.TaskStateToUpdate.ID) {
      return;
    }
    this.handleSetShowRenameStateDialog(false);
    const taskStateToUpdate = {
      ...this.state.TaskStateToUpdate,
      Name,
    }
    this.handleUpdateState(taskStateToUpdate);
  }

  handleUpdateState = taskStateToUpdate => {
    this.setState({
      ColumnsAreUpdating: true,
      ShowProgressIndicatorImmediately: true,
    });
    
    API.put(GetTaskStatePathForApi(this.props.organizationId, this.props.projectId, 
      taskStateToUpdate.ID), taskStateToUpdate)
      .then(resp => {
        this.handleLoadStates("stateFunc", true);
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ColumnsAreUpdating: false});
      });
  }

  handleUpdateStates = taskStatesToUpdate => {
    this.setState({
      ColumnsAreUpdating: true,
      ShowProgressIndicatorImmediately: true,
    });
    
    API.put(GetTaskStatesPathForApi(this.props.organizationId, this.props.projectId), taskStatesToUpdate)
      .then(resp => {
        this.handleLoadStates("stateFunc", true);
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ColumnsAreUpdating: false});
      });
  }

  handleBeginDeleteState = TaskStateToDelete => {
    this.setState({TaskStateToDelete});
    this.handleSetShowDeleteStateConfirmationDialog(true);
  }

  handleSetShowDeleteStateConfirmationDialog = ShowDeleteStateConfirmationDialog => {
    this.setState({ShowDeleteStateConfirmationDialog});
  }

  handleDeleteState = () => {
    this.handleSetShowDeleteStateConfirmationDialog(false);
    if (!this.state.TaskStateToDelete || !this.state.TaskStateToDelete.ID) {
      return;
    }
    this.setState({
      ColumnsAreUpdating: true,
      ShowProgressIndicatorImmediately: true,
    });
    API.delete(GetTaskStatesPathForApi(this.props.organizationId, this.props.projectId),
      { data: { IDs:[this.state.TaskStateToDelete.ID] } }
    )
      .then(resp => {
        this.handleLoadStates("stateFunc", true);
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ColumnsAreUpdating: false});
      });
  }

  handleColumnActionMenuOpenState = ColumnActionMenuIsOpen => {
    this.setState({ColumnActionMenuIsOpen});
  }

  handleTaskMilestoneDragFunction = f => (...props) => {
    f(
      this.state.TaskMilestones,

      id => this.state[id],

      (taskMilestonesForState, taskMilestonesForServer, otherState) => {
        if (taskMilestonesForState) {
          this.setState({TaskMilestones: taskMilestonesForState});
        }
        if (taskMilestonesForServer && taskMilestonesForServer.length) {
          this.handleUpdateMilestones(taskMilestonesForServer);
        }
        if (otherState) {
          this.setState(otherState);
        }
      },

    )
    (...props);
  }

  handleTaskStateDragFunction = f => (...props) => {
    f(
      this.state.TaskStates,

      id => this.state[id],

      (taskStatesForState, taskStatesForServer, otherState) => {
        if (taskStatesForState) {
          this.setState({TaskStates: taskStatesForState});
        }
        if (taskStatesForServer && taskStatesForServer.length) {
          this.handleUpdateStates(taskStatesForServer);
        }
        if (otherState) {
          this.setState(otherState);
        }
      },

    )
    (...props);
  }

  handleStartMoveColumn = (...props) => {
    switch (props[0].name) {
      case "TaskMilestoneID":
        this.handleTaskMilestoneDragFunction(HandleStartMoveTaskMilestone)(...props);
        break;
      case "TaskStateID":
        this.handleTaskStateDragFunction(HandleStartMoveTaskState)(...props);
        break;
      default:
        break;
    }
  }

  handleMoveColumnOverColumn = (...props) => {
    switch (props[0].name) {
      case "TaskMilestoneID":
        this.handleTaskMilestoneDragFunction(HandleMoveTaskMilestoneOverTaskMilestone)(...props);
        break;
      case "TaskStateID":
        this.handleTaskStateDragFunction(HandleMoveTaskStateOverTaskState)(...props);
        break;
      default:
        break;
    }
  }

  handleAbortMoveColumn = (...props) => {
    switch (props[0].name) {
      case "TaskMilestoneID":
        this.handleTaskMilestoneDragFunction(HandleAbortMoveTaskMilestone)(...props);
        break;
      case "TaskStateID":
        this.handleTaskStateDragFunction(HandleAbortMoveTaskState)(...props);
        break;
      default:
        break;
    }
  }

  handleEndMoveColumn = (...props) => {
    switch (props[0].name) {
      case "TaskMilestoneID":
        this.handleTaskMilestoneDragFunction(HandleEndMoveTaskMilestone)(...props);
        break;
      case "TaskStateID":
        this.handleTaskStateDragFunction(HandleEndMoveTaskState)(...props);
        break;
      default:
        break;
    }
  }

  handleRefreshColumns = () => {
    if (!this.canLoadColumnItems()) {
      return;
    }
    switch (this.props.viewType) {
      case ViewType_KanbanAssignment:
        this.handleLoadProjectMembers("handleRefreshColumns", true);
        break;
      case ViewType_KanbanMilestone:
        this.handleLoadMilestones("handleRefreshColumns", true);
        break;
      case ViewType_KanbanState:
        this.handleLoadStates("handleRefreshColumns", true);
        break;
      default:
        break;
    }
  }

  handleBeginCreateTask = columnProperty => {
    // console.log(columnProperty);

    let TaskPropertiesForTaskCreation = {};
    if (!columnProperty.useBasicCreateTaskDialog) {
      TaskPropertiesForTaskCreation = {
        ...TaskPropertiesForTaskCreation,
        [columnProperty.name]: columnProperty.value,
      };
      if (columnProperty.additionalItemProps && columnProperty.additionalItemProps.length) {
        columnProperty.additionalItemProps.forEach(prop => {
          TaskPropertiesForTaskCreation = {
            ...TaskPropertiesForTaskCreation,
            [prop.name]: prop.value,
          };
        });
      }
    }

    this.handleSetShowCreateTaskDialogVisibility(
      true,
      {
        CollectionNameForTaskCreation: (!columnProperty.useBasicCreateTaskDialog) ? columnProperty.title : undefined,
        TaskPropertiesForTaskCreation,
      }
    );
  }

  handleSetShowCreateTaskDialogVisibility = (ShowCreateTaskDialog, additionalState) => {
    this.setState({
      ShowCreateTaskDialog,
      ...additionalState,
    });
  }

  handleTaskCreated = task => {
    // console.log("handleTaskCreated", task);
    if (this.props.onForcePrependItems) {
      this.props.onForcePrependItems([task]);
    }
  }

  handleApiError = err => {
    this.setState({ShowProgressIndicatorImmediately:false});
    this.props.onApiError(err);
  }

  componentDidMount() {
    window.addEventListener("dragover", this.handleDragOverForEnhancedScroll);
    switch (this.props.viewType) {
      case ViewType_KanbanAssignment:
        this.handleLoadProjectMembers("componentDidMount");
        break;
      case ViewType_KanbanMilestone:
        this.handleLoadMilestones("componentDidMount");
        break;
      case ViewType_KanbanState:
        this.handleLoadStates("componentDidMount");
        break;
      default:
        break;
    }

    if (this.props.onSetRefreshColumnsFunc) {
      this.props.onSetRefreshColumnsFunc(this.handleRefreshColumns);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("dragover", this.handleDragOverForEnhancedScroll);
  }

  componentDidUpdate(prevProps) {
    if ((!prevProps.viewType || this.props.viewType !== prevProps.viewType)) {
      switch (this.props.viewType) {
        case ViewType_KanbanAssignment:
          this.handleLoadProjectMembers("componentDidUpdate");
          break;
        case ViewType_KanbanMilestone:
          this.handleLoadMilestones("componentDidUpdate");
          break;
        case ViewType_KanbanState:
          this.handleLoadStates("componentDidUpdate");
          break;
        default:
          break;
      }
    }
    if (this.props.projectId && prevProps.projectId && prevProps.projectId !== this.props.projectId) {
      this.handleRefreshColumns();
    }
  }

  render() {
    const {
      TaskMilestones,
      TaskMilestoneToUpdate,
      TaskStates,
      TaskStateToUpdate,
      ProjectMembers,
      CollectionIsDragging,
      CollectionNameForTaskCreation,
      TaskPropertiesForTaskCreation,
      ShowCreateTaskDialog,
      ShowAddMilestoneDialog,
      ShowRenameMilestoneDialog,
      ShowDeleteMilestoneConfirmationDialog,
      ShowAddStateDialog,
      ShowRenameStateDialog,
      ShowDeleteStateConfirmationDialog,
      ShowTaskStatsDialog,
      TaskStats,
      TaskStatsTitle,
      IncludeTaskStatsFilterDisclaimer,
      ShowProgressIndicator,
      ShowProgressIndicatorImmediately,
  	} = this.state;
    const {
      classes,
      theme,
      organizationId,
      projectId,
      projectAccess,
      isProjectAdmin,
      viewType,
      cardGridItems,
      showGetMoreButton,
      onGetMoreItems,
      onAlert,
      // getMoreButtonLeftMargin,
      // onSelectAll,
      // viewType,
      // indeterminate,
      // allSelected,
    } = this.props;

    let progressIndicator = null;
    if (ShowProgressIndicator || ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator constrained showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    const addMilestoneDialogDetails = {
      Open:ShowAddMilestoneDialog,
      Title:"Add milestone",
      RequireTextInput1:true,
      TextInput1Label:"Milestone",
      TextInput1PlaceHolder:"1st quarter",
      ConfirmLabel:"ADD",
      ConfirmCallback:this.handleAddMilestone,
      CancelCallback:() => this.handleSetShowAddMilestoneDialog(false),
      CloseCallback:() => this.handleSetShowAddMilestoneDialog(false),
    };

    const renameMilestoneDialogDetails = {
      Open: ShowRenameMilestoneDialog,
      Title: "Rename milestone",
      DialogWidth: "sm",
      FullWidth: true,
      RequireTextInput1: true,
      TextInput1Label: "Milestone name",
      TextInput1DefaultValue: TaskMilestoneToUpdate && TaskMilestoneToUpdate.Name,
      TextInput1SelectAllOnFocus: true,
      ConfirmLabel: "RENAME",
      ConfirmCallback: this.handleUpdateMilestoneName,
      CloseCallback: () => this.handleSetShowRenameMilestoneDialog(false),
      CancelCallback: ()=> this.handleSetShowRenameMilestoneDialog(false),
    };

    const deleteMilestoneConfirmationDialogDetails = {
      Open:ShowDeleteMilestoneConfirmationDialog,
      IsConfirmation:true,
      Title:"Delete milestone?",
      BodyText:"This action cannot be undone.",
      BodyClassName:"warning",
      ConfirmCallback:this.handleDeleteMilestone,
      CancelCallback:() => this.handleSetShowDeleteMilestoneConfirmationDialog(false),
      CloseCallback:() => this.handleSetShowDeleteMilestoneConfirmationDialog(false),
    };

    const addStateDialogDetails = {
      Open:ShowAddStateDialog,
      Title:"Add task state",
      RequireTextInput1:true,
      TextInput1Label:"Task State",
      TextInput1PlaceHolder:"In progress",
      ConfirmLabel:"ADD",
      ConfirmCallback:this.handleAddState,
      CancelCallback:() => this.handleSetShowAddStateDialog(false),
      CloseCallback:() => this.handleSetShowAddStateDialog(false),
    };

    const renameStateDialogDetails = {
      Open: ShowRenameStateDialog,
      Title: "Rename state",
      DialogWidth: "sm",
      FullWidth: true,
      RequireTextInput1: true,
      TextInput1Label: "Task state name",
      TextInput1DefaultValue: TaskStateToUpdate && TaskStateToUpdate.Name,
      TextInput1SelectAllOnFocus: true,
      ConfirmLabel: "RENAME",
      ConfirmCallback: this.handleUpdateStateName,
      CloseCallback: () => this.handleSetShowRenameStateDialog(false),
      CancelCallback: ()=> this.handleSetShowRenameStateDialog(false),
    };

    const deleteStateConfirmationDialogDetails = {
      Open:ShowDeleteStateConfirmationDialog,
      IsConfirmation:true,
      Title:"Delete task state?",
      BodyText:"This action cannot be undone.",
      BodyClassName:"warning",
      ConfirmCallback:this.handleDeleteState,
      CancelCallback:() => this.handleSetShowDeleteStateConfirmationDialog(false),
      CloseCallback:() => this.handleSetShowDeleteStateConfirmationDialog(false),
    };

    const taskStatsDialogDetails = {
      Open: ShowTaskStatsDialog,
      Title: `Task statistics${(TaskStatsTitle) ? " for " + TaskStatsTitle : ""}`,
      DialogWidth: "sm",
      FullWidth: true,
      BodyContent: (TaskStats)
        ? (
          <Grid container direction="column" spacing={1}>
            <Grid item>
              Total tasks: {TaskStats.TotalInFilter}
            </Grid>
            <Grid item>
              Total duration: {TaskStats.TotalDurationAsString || "None"}
            </Grid>
            {(TaskStats.TotalOnTime)
              ? <Grid item>
                  On time: <span style={{color:green[500]}}>{TaskStats.TotalOnTime }</span>
                </Grid>
              : null
            }
            {(TaskStats.TotalPastDue)
              ? <Grid item>
                  Past due: <span style={{color:red[500]}}>{TaskStats.TotalPastDue}</span>
                </Grid>
              : null
            }
            {(TaskStats.TotalUnscheduled)
              ? <Grid item>
                  Unscheduled: {TaskStats.TotalUnscheduled}
                </Grid>
              : null
            }
            {(IncludeTaskStatsFilterDisclaimer)
              ? <Grid item style={{marginTop:theme.spacing(2)}}>
                  Stats are based on the current filter.
                </Grid>
              : null
            }
          </Grid>
        ) : null,
      CloseCallback: () => this.handleSetShowTasksStatsDialog(false),
      CancelCallback: ()=> this.handleSetShowTasksStatsDialog(false),
    };

    const taskCreationDialog = (
      <TaskDialog
        organizationId={organizationId}
        projectId={projectId}
        collectionNameForTitle={CollectionNameForTaskCreation}
        additionalTaskPropertiesForCreation={TaskPropertiesForTaskCreation}
        open={ShowCreateTaskDialog}
        isCreateNew
        onApiError={this.handleApiError}
        onAlert={onAlert}
        onCreated={this.handleTaskCreated}
        onClose={() => this.handleSetShowCreateTaskDialogVisibility(false)}
        // showProgressIndicatorImmediately={state.ShowDialogProgressIndicatorImmediately}
      />
    );

    const getKbColumn = (columnIndex, columnProperty, columnItem, onDeleteColumn, onRenameColumn, onGetStats) => {
      return (
        <KanbanColumn
          key={`kbCol_${columnIndex}`}
          columnProperty={columnProperty}
          columnItem={columnItem}
          collectionIsDragging={CollectionIsDragging}
          cardGridItems={cardGridItems}
          showGetMoreButton={showGetMoreButton}
          onBeginCreateTask={this.handleBeginCreateTask}
          onGetMoreItems={onGetMoreItems}
          onDeleteColumn={onDeleteColumn}
          onRenameColumn={onRenameColumn}
          onGetStats={onGetStats}
          onActionMenuOpen={() => this.handleColumnActionMenuOpenState(true)}
          onActionMenuClose={() => this.handleColumnActionMenuOpenState(false)}
          onStartMoveColumn={this.handleStartMoveColumn}
          onMoveColumnOverColumn={sourceColumnProperty => this.handleMoveColumnOverColumn(sourceColumnProperty, columnProperty)}
          onAbortMoveColumn={this.handleAbortMoveColumn}
          onEndMoveColumn={this.handleEndMoveColumn}
          isProjectAdmin={isProjectAdmin}
        />
      );
    }

    let newColumnButton;
    let kanbanColumns = [];
    switch (viewType) {
      case ViewType_KanbanAssignment:
        if (!ProjectMembers.length) {
          break;
        }
        // Show non-member column only in Projects that allow such assignments
        if (projectAccess && projectAccess.MemberAccess.Tasks === "All") {
          kanbanColumns.push(
            getKbColumn(
              kanbanColumns.length,
              {
                title:"Non members",
                name:"AssignmentUserEmail",
                value:"",
                useBasicCreateTaskDialog: true,
                customFilter: task => {
                  return ProjectMembers.filter(pm => pm.UserEmail === task.AssignmentUserEmail).length === 0;
                },
              }
            )
          );
        }
        kanbanColumns.push(ProjectMembers.map((pm, index) =>
          getKbColumn(
            index,
            {
              canDrag:false,
              title:GetUserValue(pm.UserEmail, pm.UserName),
              name:"AssignmentUserEmail",
              value:pm.UserEmail,
              additionalItemProps: [
                { name: "AssignmentUserName", value: pm.UserName },
                { name: "AssignmentUserNameLower", value: pm.UserNameLower },
              ],
              ProjectMember:pm,
            },
            pm,
            null,
            null,
            () => this.handleBeginGetStatsForProjectMember(pm),
          )
        ));
        break;
      case ViewType_KanbanPriority:
        kanbanColumns = [
          getKbColumn(0, {
            title:"No priority",
            name:"Priority",
            value:0,
            useBasicCreateTaskDialog:true,
          }),
          getKbColumn(
            1,
            {title:"Highest priority",name:"Priority",value:7},
            7,
            null,
            null,
            () => this.handleBeginGetStatsForPriority(7),
          ),
          getKbColumn(
            2,
            {title:"Higher priority",name:"Priority",value:6},
            6,
            null,
            null,
            () => this.handleBeginGetStatsForPriority(6),
          ),
          getKbColumn(
            3,
            {title:"High priority",name:"Priority",value:5},
            5,
            null,
            null,
            () => this.handleBeginGetStatsForPriority(5),
          ),
          getKbColumn(
            4,
            {title:"Medium priority",name:"Priority",value:4},
            4,
            null,
            null,
            () => this.handleBeginGetStatsForPriority(4),
          ),
          getKbColumn(
            5,
            {title:"Low priority",name:"Priority",value:3},
            3,
            null,
            null,
            () => this.handleBeginGetStatsForPriority(3),
          ),
          getKbColumn(
            6,
            {title:"Lower priority",name:"Priority",value:2},
            2,
            null,
            null,
            () => this.handleBeginGetStatsForPriority(2),
          ),
          getKbColumn(
            7,
            {title:"Lowest priority",name:"Priority",value:1},
            1,
            null,
            null,
            () => this.handleBeginGetStatsForPriority(1),
          ),
        ];
        break;
      case ViewType_KanbanMilestone:
        kanbanColumns.push(
          getKbColumn(
            kanbanColumns.length,
            {
              title:"No milestone",
              name:"TaskMilestoneID",
              value:"",
              useBasicCreateTaskDialog:true,
            },
          )
        );
        kanbanColumns.push(TaskMilestones.map((m, index) =>
          getKbColumn(
            index,
            {
              canDrag:isProjectAdmin,
              title:m.Name,
              name:"TaskMilestoneID",
              value:m.ID,
              additionalItemProps: [
                { name: "TaskMilestoneName", value: m.Name },
              ],
              TaskMilestone:m,
            },
            m,
            () => this.handleBeginDeleteMilestone(m),
            () => this.handleBeginRenameMilestone(m),
            () => this.handleBeginGetStatsForTaskMilestone(m),
          )
        ));
        newColumnButton = (isProjectAdmin) ? (
          <Tooltip title="Add milestone">
            <IconButton variant="contained" className={classes.newColumnButton}
              onClick={() => this.handleSetShowAddMilestoneDialog(true)}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        ) : null;
        break;
      case ViewType_KanbanState:
        kanbanColumns.push(
          getKbColumn(
            kanbanColumns.length,
            {
              title:"No task state",
              name:"TaskStateID",
              value:"",
              useBasicCreateTaskDialog:true,
            },
          )
        );
        kanbanColumns.push(TaskStates.map((s, index) =>
          getKbColumn(
            index,
            {
              canDrag:isProjectAdmin,
              title:s.Name,
              name:"TaskStateID",
              value:s.ID,
              additionalItemProps: [
                { name: "TaskStateName", value: s.Name },
              ],
              TaskState:s,
            },
            s,
            () => this.handleBeginDeleteState(s),
            () => this.handleBeginRenameState(s),
            () => this.handleBeginGetStatsForTaskState(s),
          )
        ));
        newColumnButton = (isProjectAdmin) ? (
          <Tooltip title="Add task state">
            <IconButton variant="contained" className={classes.newColumnButton}
              onClick={() => this.handleSetShowAddStateDialog(true)}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        ) : null;
        break;
      default:
        break;
    }

    const kanbanTaskDragLayer = (!CollectionIsDragging)
      ? (
        <KanbanTaskDragLayer
          width={244}
          hideAssignment={viewType === ViewType_KanbanAssignment}
        />
      )
      : null;

    return (
      <div className={classes.scrollContainer}
        ref={instance => this.ScrollContainerRef = instance}
      >
        {progressIndicator}
        <MultiUseDialog Details={addMilestoneDialogDetails} />
        <MultiUseDialog Details={renameMilestoneDialogDetails} />
        <MultiUseDialog Details={deleteMilestoneConfirmationDialogDetails} />
        <MultiUseDialog Details={addStateDialogDetails} />
        <MultiUseDialog Details={renameStateDialogDetails} />
        <MultiUseDialog Details={deleteStateConfirmationDialogDetails} />
        <MultiUseDialog Details={taskStatsDialogDetails} />

        <div className={classes.kbContainer}>
          {kanbanColumns}
          {newColumnButton}
          {kanbanTaskDragLayer}
          {taskCreationDialog}
        </div>

      </div>    
    );
  }
}

KanbanBase.propTypes = {
  classes: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  projectAccess: PropTypes.object.isRequired,
  isProjectAdmin: PropTypes.bool,
  viewType: PropTypes.string.isRequired,
  metaFieldFilters: PropTypes.array,
  fullTextFilter: PropTypes.string,
  cardGridItems: PropTypes.array,
  showGetMoreButton: PropTypes.bool.isRequired,
  onGetMoreItems: PropTypes.func.isRequired,
  onSetRefreshColumnsFunc: PropTypes.func,
  onForcePrependItems: PropTypes.func,
  onApiError: PropTypes.func.isRequired,
  onAlert: PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(KanbanBase);