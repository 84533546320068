import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import FormDesigner from '../Components/SignatureFormDesigner';
import ProgressIndicator from './ProgressIndicator';
import MultiUseDialog from '../Components/MultiUseDialog';

const styles = theme => ({
  content: {
    height: "100%",
    overflow:"auto",
    position:"relative",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

class DocumentSignatureSessionFormDesignerDialog extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      open: props.open,
      CanMoveForwardDialogDetails: null,
      ShowProgressIndicator: false,
    }

    this.CanMoveForwardFunction = () => {}
  }

  handleClose = (moveBack, moveForward) => {
    this.setState({ open: false });
    if (this.props.closeCallback) {
      this.props.closeCallback(moveBack, moveForward, {} /* No real state needed externally*/);
    }
  }

  handleBack = () => {
    this.handleClose(true, false);
  }

  handleNext = skipCanMoveForwardCheck => {
    // if (!this.state.SelectedDesignType) {
    //   this.handleApiError("Please choose a design type.", "Design type required");
    //   return;
    // }

    this.setState({CanMoveForwardDialogDetails: null});

    if (this.CanMoveForwardFunction) {
      let canMoveForward = this.CanMoveForwardFunction();
      if (!canMoveForward.Proceed && !skipCanMoveForwardCheck) {
        this.setState({
          CanMoveForwardDialogDetails: {
            Open: true,
            IsConfirmation: canMoveForward.AllowSkip,
            Title:canMoveForward.DialogTitle,
            BodyText:canMoveForward.DialogBodyText,
            CancelLabel: canMoveForward.DialogCancelLabel,
            CloseLabel: canMoveForward.DialogCloseLabel,
            ConfirmLabel: canMoveForward.DialogConfirmLabel,
            ConfirmCallback: () => this.handleNext(true),
            CancelCallback: () => this.setState({CanMoveForwardDialogDetails: null}),
            CloseCallback: () => this.setState({CanMoveForwardDialogDetails: null}),
          },
        });
        return;
      }
    }

    this.handleClose(false, true);
  }

  handleApiError = (err, title_optional) => {
    this.setState({
      ShowProgressIndicator: false,
    });
    this.props.onApiError(err, title_optional);
    if (err) {
      setTimeout(() => this.props.onApiError(null), 1);
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      this.setState({open: this.props.open !== false});
    }
  }

  render() {
    const { 
      open,
      CanMoveForwardDialogDetails,
      ShowProgressIndicator,
  	} = this.state;
    const {
      // classes,
      // theme,
      organizationId,
      projectId,
      documentId,
      documentPageCount,
      recipients,
    } = this.props;

    let progressIndicator = null;
    if (ShowProgressIndicator) {
      progressIndicator = (
        <ProgressIndicator />
      );
    }

    let dialogActions = (
      <DialogActions>
        <Button onClick={() => this.handleClose()}>
          CLOSE
        </Button>
        <Button onClick={() => this.handleBack()}>
          BACK
        </Button>
        <Button onClick={() => this.handleNext()}>
          NEXT
        </Button>
      </DialogActions>
    );

    let canMoveForwardDialog = (CanMoveForwardDialogDetails) 
      ? (
        <MultiUseDialog Details={CanMoveForwardDialogDetails} />
      ) : null;

    let content = (
      <div style={{
        width:"calc(100% - 2px)",
        height:"calc(100% - 2px)",
        border:"1px solid #ddd",
      }}>
        {canMoveForwardDialog}
        <FormDesigner
          organizationId={organizationId}
          projectId={projectId}
          documentId={documentId}
          documentPageCount={documentPageCount}
          recipients={recipients}
          onApiError={err => this.handleApiError(err)}
          onConnectCanMoveForward={f => this.CanMoveForwardFunction = f}
        />
      </div>
    );

    return (
      <Dialog
        fullScreen
        TransitionComponent={Transition}
        // PaperProps={{
        //   style:{
        //     minHeight:"50%",
        //   }
        // }}
        open={open}
        onClose={() => this.handleClose()}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description">
        <DialogTitle id="dialog-title">
          <span>Signature-form designer</span>
        </DialogTitle>
        <DialogContent
          style={{
            paddingTop:0,
          }}
        >
          {progressIndicator}

          {content}
        </DialogContent>

        {dialogActions}
      </Dialog>
    );
  }
}

DocumentSignatureSessionFormDesignerDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  documentId: PropTypes.string.isRequired,
  documentPageCount: PropTypes.number.isRequired,
}

export default withStyles(styles, {withTheme: true})(DocumentSignatureSessionFormDesignerDialog);