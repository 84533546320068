import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import Collection from '../Model/Collection';

import ItemTableRow from '../Components/ItemTableRow';
import UserCard from '../Support/Components/UserCard';
import UserCollectionFields from '../Model/UserCollectionFields';

import {
  GetDateValue,
  GetUserValue,
} from '../Util/Properties';

import {
  GetSupportUsersPathForApi,
} from '../Util/api';

export class SupportUsers_Collection extends Collection {
  constructor(props, onSetState, onApiError, isWorkspace, isProjects) {
    super(props, onSetState, onApiError, isWorkspace, isProjects);

    this.PageTitle = "Users";
    this.ContentUri = GetSupportUsersPathForApi();
    this.CollectionName = "SupportUsers";
    this.ItemsName = "Users";
    this.ItemName = "User";
    this.DefaultViewType = "List";
  }

  HandleGetCollectionFieldsPromise = () => Promise.resolve(UserCollectionFields);

  HandleGetHeadCells = (items, sensitiveFields) => [
    { id: 'Name', numeric: false, label: 'Name' },
    { id: 'Email', numeric: false, label: 'Email' },
    { id: 'CreatedOn', numeric: false, label: 'Created On' },
    { id: 'LastSignIn', numeric: false, label: 'Last Sign In' },
    { id: 'LastActivity', numeric: false, label: 'Last Activity' },
    { id: 'Provider', numeric: false, label: 'Sign-in Provider' },
  ];

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length) 
      ? items
        .map(i => {
          return (
            <Grid item key={i.ID} sm={12} md={6} lg={4} xl={3} className={classes.cardGridItem}>
              <UserCard
                User={i}
                // onCardAction={undefined}
              />
            </Grid>
          );
        }) 
      : [];
  }

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length)
      ? items
        .map(i => {
          return (
            <ItemTableRow key={`k_${i.ID}`}>
              <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
                <Checkbox
                  style={{
                    visibility: "hidden",
                  }}
                  color="secondary"
                />
              </TableCell>
              <TableCell className={classes.tableCell} component="th" id={`label_${i.ID}`} scope="row" padding="none">
                {GetUserValue(i.Email, i.Name)}
              </TableCell>
              <TableCell className={classes.tableCell}>{i.Email}</TableCell>
              <TableCell className={classes.tableCell}>{GetDateValue(i.CreatedOn)}</TableCell>
              <TableCell className={classes.tableCell}>{GetDateValue(i.LastSignIn)}</TableCell>
              <TableCell className={classes.tableCell}>{GetDateValue(i.LastActivity)}</TableCell>
              <TableCell className={classes.tableCell}>{i.Provider}</TableCell>
            </ItemTableRow>
          );
        }) 
      : [];
  }
}