import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import UiCore from '../../Components/UiCore';

import API, {
  GetSupportOrganizationPathForApi,
} from '../../Util/api';
import {
  GetBoolValue,
  GetDateValue,
} from '../../Util/Properties';
import { NumberWithSeparators } from '../../Util/NumberFormatting';
import {
  OrganizationRecommendedForRemoval,
} from '../../Util/Organizations';

import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

const styles = theme => ({
  root:{
    padding: theme.spacing(3),
    overflow: "auto",
  },
  table: {
    width: "max-content",
    marginTop: theme.spacing(2),
  },
});

class OrganizationDetail extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      ShowProgressIndicatorImmediately: false,
    }
  }

  loadOrganizationDetail = () => {
    this.setState({ShowProgressIndicatorImmediately: true});
    API.get(GetSupportOrganizationPathForApi(this.props.match.params.organizationID))
      .then(resp => {
        this.setState({
          Organization: resp.data,
          ShowProgressIndicatorImmediately: false,
        });
      })
      .catch(this.handleApiError);
  }

  handleSearchIndexRemoval = () => {
    this.setState({ShowProgressIndicatorImmediately: true});
    API.put(GetSupportOrganizationPathForApi(this.props.match.params.organizationID), 
      {...this.state.Organization}, { params: { deleteSearchIndex: true }})
      .then(resp => {
        this.setState({
          Organization: resp.data,
          ShowProgressIndicatorImmediately: false,
        });
      })
      .catch(this.handleApiError);
  }

  handleSubscriptionRefresh = () => {
    this.setState({ShowProgressIndicatorImmediately: true});
    API.put(GetSupportOrganizationPathForApi(this.props.match.params.organizationID), 
      {...this.state.Organization}, { params: { refreshSubscription: true }})
      .then(resp => {
        this.setState({
          Organization: resp.data,
          ShowProgressIndicatorImmediately: false,
        });
      })
      .catch(this.handleApiError);
  }

  handleApiError = err => {
    this.setState({
      ApiError: err,
      ShowProgressIndicatorImmediately: false,
    });
  }

  componentDidMount() {
    this.loadOrganizationDetail();
  }

  render() {
    const {
      Organization,
      ApiError,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      classes,
      theme,
    } = this.props;

    const refreshSubscriptionButton = (Organization)
      ? (
        <Button variant="contained" size="small" 
          style={{marginLeft:theme.spacing(3)}}
          onClick={() => this.handleSubscriptionRefresh()}
        >
          REFRESH SUBSCRIPTION
        </Button>
      )
      : null;

    const isExpired = Organization && new Date(Organization.SubscriptionExpiration) < new Date();

    let tableRows = [];
    if (Organization) {
      Object.entries(Organization).forEach(o => {
        const [key, value] = o;
        let stringValue;
        let cellStyle;
        let include;
        switch (typeof value) {
          case "boolean":
            stringValue = GetBoolValue(value, true);
            if (key === "SubscriptionRequired") {
              include = refreshSubscriptionButton;
            }
            break;
          case "number":
            stringValue = NumberWithSeparators(value);
            break;
          default:
            switch (key) {
              case "CreatedOn":
              case "PurgeDate":
              case "CreatingUserLastActivity":
                stringValue = GetDateValue(value);
                break;
              case "SubscriptionExpiration":
                stringValue = GetDateValue(value);
                cellStyle = { color: (Organization.SubscriptionRequired) ? (isExpired) ? red[500] : green[500] : undefined }
                break;
              default:
                stringValue = value;
                break;
            }
            break;
        }
        tableRows.push(
          <TableRow key={key}>
            <TableCell>{key}</TableCell>
            <TableCell style={cellStyle}>{stringValue}{include}</TableCell>
          </TableRow>
        );
      })
    }

    // const testOrg = Organization && {...Organization,
    //   // CreatedOn: new Date(),
    //   CreatingUserLastActivity: Organization.CreatedOn,
    //   UniqueNonReadOnlyOrganizationMembers: 1,
    //   ActiveProjectCount: 1,
    //   HasUpToMinTasks: 0,
    //   HasUpToMinDocuments: 0,
    //   HasUpToMinFormTemplates: 0,
    //   HasUpToMinProcesses: 0,
    // };

    const indexRemovalNote = (Organization)
      ? (Organization.SearchIndexRemovedForCostSavings)
        ? (
          <Typography variant="h6">
            Search index is deleted, but will be recreated at next user access.
          </Typography>
        )
        : (OrganizationRecommendedForRemoval(Organization))
          ? (
            <Typography variant="h5">
              Search-index removal recommended because of a relatively empty state. It will be recreated on next access.
              {
                (OrganizationRecommendedForRemoval(Organization, true))
                  ? " Also, the creating user's last activity is within 60s of organization creation."
                  : ""
              }
            </Typography>
          )
          : null
        : null
      ;

    const indexRemovalButton = (Organization && !Organization.SearchIndexRemovedForCostSavings
      && OrganizationRecommendedForRemoval(Organization))
      ? (
        <Button variant="contained" style={{marginTop:theme.spacing(2)}}
          onClick={() => this.handleSearchIndexRemoval()}
        >
          REMOVE SEARCH INDEX
        </Button>
      )
      : null;

    const content = (
      <div className={classes.root}>
        {indexRemovalNote}
        {indexRemovalButton}
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            {tableRows}
          </TableBody>
         </Table>
      </div>
    );

    return (
      <UiCore
        title={`Support: ${(Organization) ? Organization.Name : "Organization Detail"}`}
        content={content}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
        apiError={ApiError}
      />
    );
  }
}

OrganizationDetail.propTypes = {
}

export default withStyles(styles, {withTheme: true})(OrganizationDetail);
