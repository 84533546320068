import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import classNames from 'classnames';

const styles = theme => ({
  tableHeadCell: {
    backgroundColor: theme.palette.background.paper,
    // zIndex:3,//This appeared only in ErrorEventListBase and DocumentEventListBase, not DocumentListBase or 
  },
  tableHeadCell_FirstCell: {
    paddingLeft:15,
    paddingRight:8,
    [theme.breakpoints.down('xs')]: {
      paddingLeft:7,
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

class EnhancedTableHead extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
    }
  }

  render() {
    const { 
      classes,
      headCells,
      sortDescending,
      sortType,
      collectionFields,
      onSortChange,
      onSelectAllClick,
      selectAllIndeterminate,
      selectAllChecked,
    } = this.props;

    let headTableCells = headCells.map(headCell => {
      let headCellInnerContent = (
        <span>
        {headCell.label}
        {
          (sortType && (sortType === headCell.sortId || sortType === headCell.id))
          ?
            <span className={classes.visuallyHidden}>
              {sortDescending ? 'sorted descending' : 'sorted ascending'}
            </span>
          : null
        }
        </span>
      );
      let headCellContent = (onSortChange && collectionFields.find(st => (headCell.sortId && st.ID === headCell.sortId) || st.ID === headCell.id))
      ? (
          <TableSortLabel
            active={sortType && ((headCell.sortId && sortType === headCell.sortId) || sortType === headCell.id)}
            direction={sortDescending ? 'desc' : 'asc'}
            onClick={() => onSortChange(headCell.sortId || headCell.id)}
          >
            {headCellInnerContent}
          </TableSortLabel>
        )
      : headCellInnerContent;

      return (
        <TableCell
          className={classes.tableHeadCell}
          key={headCell.id}
          align={headCell.numeric ? 'right' : 'left'}
          padding={headCells.indexOf(headCell) === 0 ? "none" : "default"}
          sortDirection={
            (sortType && ((headCell.sortId && sortType === headCell.sortId) || sortType === headCell.id))
              ? ((sortDescending) ? "desc" : "asc")
              : false
          }
        >
          {headCellContent}
        </TableCell>
      );
    });

    let selectAllTableCell = (
      <TableCell className={classNames(classes.tableHeadCell, classes.tableHeadCell_FirstCell)} padding="checkbox">
        <Checkbox
          color="secondary"
          indeterminate={selectAllIndeterminate}
          checked={selectAllChecked}
          onChange={onSelectAllClick}
          inputProps={{ 'aria-label': 'Select' }}
          style={{ visibility: (onSelectAllClick) ? "visible" : "hidden", }}
        />
      </TableCell>
    );

    return (
      <TableHead>
        <TableRow>
          {selectAllTableCell}
          {headTableCells}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onSortChange: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  selectAllIndeterminate: PropTypes.bool,
  selectAllChecked: PropTypes.bool,
};

export default withStyles(styles, {withTheme: true})(EnhancedTableHead);