export default class Collection {
  constructor(props, onSetState, onApiError, isWorkspace, isProjects, organizationId, projectId, context) {
    if (typeof onSetState !== "function") {
      console.log("Warning: onSetState required and not found");
    }
    if (typeof onApiError !== "function") {
      console.log("Warning: onApiError required and not found");
    }

    this.props = {...props, isWorkspace, isProjects};
    this.prevProps = {};
    this.setState = onSetState;
    this.onApiError = onApiError;
    this.isWorkspace = isWorkspace;
    this.isProjects = isProjects;
    this.OrganizationID = organizationId;
    this.ProjectID = projectId;
    this.context = context;

    this.ActionHandlerFunction = () => {};
    this.UpdateRevisedItemFunction = () => {};
    this.GetAllItemsFunction = () => {};
    this.SetAllItemsFunction = () => {};

    this.PassThroughComponent = null;
    this.PageTitle = "";
    this.ContentUri = "";
    this.ContentUriParams = null;
    this.CollectionName = "";
    this.ItemsName = "Items";
    this.ItemName = "Item";
    this.DefaultViewType = "Card";
    this.AllowSelect = false;
    this.AllowKanban = false;
    this.HideSensitiveFields = false;
    this.CanSelectItem = item => true;
    this.HideToolHeader = false;
    this.HideFilterSortDrawer = false;
    this.HideFilters = false;
    this.HideSearchAllFilter = false;
    this.SingleLineTableCells = false;
  }

  handleApiError = err => {
    if (err && !err.response) {
      err = {
        response: {
          status: 500,
          data: {
            message: err.toString(),
          },
        },
      };
    }
    this.setState({
      ShowDialogProgressIndicatorImmediately: false,
    });
    this.onApiError(err);
  }

  // Returns whether further path changes can occur.
  updateProps(props) {
    this.prevProps = this.props;
    this.props = {...props, isWorkspace: this.isWorkspace, isProjects: this.isProjects};

    return true;
  }

  HandleGetCollectionFieldsPromise = () => Promise.resolve([]);

  HandleGetHeadCells = (items, sensitiveFields) => [];

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, 
    selectedIDs, onAction, postActionData, sortType, sortDescending, 
    useSingleColumnLayout, cardStyle, viewType) => [];

  HandleGetCardGridItemsForKanban = (items, sensitiveFields, classes, theme, onSelect, 
    selectedIDs, onAction, postActionData, sortType, sortDescending, 
    useSingleColumnLayout, cardStyle, viewType) => [];

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, 
    selectedIDs, onAction, postActionData, sortType, sortDescending) => [];

  HandleGetDialogContent = state => null;

  HandleGetMiscContent = state => null;

  HandleCreateNew = null;

  HandleGetFabMenu = null;

  HandleSetGetAllItemsFunction = f => this.GetAllItemsFunction = f;

  HandleGetAllItems = () => this.GetAllItemsFunction();

  HandleSetSetAllItemsFunction = f => this.SetAllItemsFunction = f;

  HandleSetAllItems = Items => this.SetAllItemsFunction(Items);

  HandleSetUpdateRevisedItemFunction = f => this.UpdateRevisedItemFunction = f;

  HandleItemRevised = revisedItem => {
    if (revisedItem) {
      this.UpdateRevisedItemFunction(revisedItem);
    }
  }

  HandleRefresh = () => {};
}