import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';

import { withStyles } from '@material-ui/core/styles';

import DashboardCard from '../DashboardCard';
import { Player } from 'video-react';
import "video-react/dist/video-react.css";
import {
  GetPublicVideoPath,
} from '../../Util/api';
import {
  DefaultWidgetHeight,
} from '../../Util/DashboardWidgets';

import Icon from '@material-ui/icons/Star';
import IconColor from '@material-ui/core/colors/amber';

const styles = theme => ({
  paper: {
    width:"100%",
    overflow:"hidden",
    borderRadius: 8,
    minHeight: DefaultWidgetHeight,
  },
});

class FeatureSpotlightWidget extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
    }
  }

  render() {
    // const {
    // } = this.state;
    const { 
      classes,
      theme,
      ...passThroughProps
    } = this.props;

    const paper = (
      <Paper
        className={classes.paper}
      >
        <Player
          playsInline
          poster={GetPublicVideoPath("feature-spotlight-tour.jpg")}
          src={GetPublicVideoPath("feature-spotlight-tour.mp4")}
        />
      </Paper>
    );

    return (
      <DashboardCard
        xs={12}
        sm={6}
        md={3}
        lg={3}
        xl={3}
        isMaxHeightWidget
        title="Feature Spotlight"
        titleIcon={<Icon style={{color: IconColor[500]}} />}
        passThroughContent={paper}
        {...passThroughProps} 
      />
    );
  }
}

FeatureSpotlightWidget.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(FeatureSpotlightWidget);