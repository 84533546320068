import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
// import ListSubheader from '@material-ui/core/ListSubheader';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import InfoIcon from '@material-ui/icons/Info';

import { IsMobile } from '../Util/MobileDetector';

// import API from '../Util/api';
import ProgressIndicator from './ProgressIndicator';

const styles = theme => ({
  content: {
    height: "100%",
    overflow:"auto",
    position:"relative",
  },
  grid: {
    paddingTop:theme.spacing(2),
  },
  infoIcon: {
    opacity:0.5,
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

class DocumentSignatureSessionFinalDialog extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      open: props.open,
      ShowProgressIndicator: false,
      Exportable: {
        // Initial state is a DocumentSignatureSession
        UseAccessCode: (this.props.session) ? this.props.session.UseAccessCode : false,
        AccessCode: (this.props.session) ? this.props.session.AccessCode : "",
        UseCustomSubjectAndBody: (this.props.session) ? this.props.session.UseCustomSubjectAndBody : false,
        CustomSubject: (this.props.session && this.props.session.CustomSubject) 
          ? this.props.session.CustomSubject 
          : `Please sign ${this.props.documentName}`,
        CustomBody: (this.props.session) ? this.props.session.CustomBody : "",
        QuickDesignPlaceInitials: (this.props.session) ? this.props.session.QuickDesignPlaceInitials : "",
        QuickDesignPlaceFullName: (this.props.session) ? this.props.session.QuickDesignPlaceFullName : "",
        QuickDesignPlaceEmail: (this.props.session) ? this.props.session.QuickDesignPlaceEmail : "",
        QuickDesignPlaceTitle: (this.props.session) ? this.props.session.QuickDesignPlaceTitle : "",
      },
    }
  }

  handleClose = (moveBack, moveForward) => {
    this.setState({ open: false });
    if (this.props.closeCallback) {
      this.props.closeCallback(moveBack, moveForward, this.state.Exportable);
    }
  }

  handleBack = () => {
    this.handleClose(true, false);
  }

  handleNext = () => {
    if (this.state.Exportable.UseAccessCode && !this.state.Exportable.AccessCode) {
      this.handleApiError("Please provide an access code.", "Access code missing");
      return;
    }
    this.handleClose(false, true);
  }

  handleApiError = (err, title_optional) => {
    this.setState({
      ShowProgressIndicator: false,
    });
    this.props.onApiError(err, title_optional);
    if (err) {
      setTimeout(() => this.props.onApiError(null), 1);
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      this.setState({open: this.props.open !== false});
    }
  }

  render() {
    const { 
      open,
      Exportable,
      ShowProgressIndicator,
  	} = this.state;
    const {
      formDesignType,
      classes,
      theme,
    } = this.props;

    let progressIndicator = null;
    if (ShowProgressIndicator) {
      progressIndicator = (
        <ProgressIndicator />
      );
    }

    let dialogActions = (
      <DialogActions>
        <Button onClick={() => this.handleClose()}>
          CLOSE
        </Button>
        <Button onClick={() => this.handleBack()}>
          BACK
        </Button>
        <Button onClick={() => this.handleNext()}>
          FINISH & SEND
        </Button>
      </DialogActions>
    );

    let accessCodeTextField = (Exportable.UseAccessCode)
      ? (
        <TextField
          fullWidth
          style={{marginTop:theme.spacing(2)}}
          variant="outlined"
          label="Access code"
          type="password"
          value={Exportable.AccessCode}
          onChange={e => this.setState({Exportable: {...this.state.Exportable, AccessCode:e.target.value}})}
        />
      ) : null;

    let customSubjectAndBodyFields = (Exportable.UseCustomSubjectAndBody)
      ? [(
        <TextField
          key="tf_subject"
          fullWidth
          style={{marginTop:theme.spacing(2)}}
          variant="outlined"
          label="Subject"
          value={Exportable.CustomSubject}
          onChange={e => this.setState({Exportable: {...this.state.Exportable, CustomSubject:e.target.value}})}
        />
      ),(
        <TextField
          key="tf_body"
          fullWidth
          style={{marginTop:theme.spacing(2)}}
          variant="outlined"
          label="Body"
          multiline
          rows={5}
          value={Exportable.CustomBody}
          onChange={e => this.setState({Exportable: {...this.state.Exportable, CustomBody:e.target.value}})}
        />
      )] : [];

    let quickDesignGridItems = (formDesignType === "Quick")
      ? (
        <React.Fragment>
          <Grid item key="grid_quickDesignInitials" xs={(IsMobile()) ? 12 : 6}>
            <FormControlLabel
              control={<Switch
                color="secondary"
                checked={Exportable.QuickDesignPlaceInitials}
                onChange={e => this.setState({Exportable: {...this.state.Exportable, QuickDesignPlaceInitials:e.target.checked}})} />
              }
              label="Place initials on pages" />
          </Grid>

          <Grid item key="grid_quickDesignFullName" xs={(IsMobile()) ? 12 : 6}>
            <FormControlLabel
              control={<Switch
                color="secondary"
                checked={Exportable.QuickDesignPlaceFullName}
                onChange={e => this.setState({Exportable: {...this.state.Exportable, QuickDesignPlaceFullName:e.target.checked}})} />
              }
              label="Place full name" />
          </Grid>

          <Grid item key="grid_quickDesignEmail" xs={(IsMobile()) ? 12 : 6}>
            <FormControlLabel
              control={<Switch
                color="secondary"
                checked={Exportable.QuickDesignPlaceEmail}
                onChange={e => this.setState({Exportable: {...this.state.Exportable, QuickDesignPlaceEmail:e.target.checked}})} />
              }
              label="Place e-mail" />
          </Grid>

          <Grid item key="grid_quickDesignTitle" xs={(IsMobile()) ? 12 : 6}>
            <FormControlLabel
              control={<Switch
                color="secondary"
                checked={Exportable.QuickDesignPlaceTitle}
                onChange={e => this.setState({Exportable: {...this.state.Exportable, QuickDesignPlaceTitle:e.target.checked}})} />
              }
              label="Place title" />
          </Grid>
        </React.Fragment>
      ) : null;

    // <ListSubheader disableGutters>Options</ListSubheader>
    let optionsContent = (
      <div>
        <Grid container spacing={2}>
          
          {quickDesignGridItems}

          <Grid item key="grid_useAccessCode" xs={(IsMobile()) ? 12 : 6}>
            <FormControlLabel
              control={<Switch
                color="secondary"
                checked={Exportable.UseAccessCode}
                onChange={e => this.setState({Exportable: {...this.state.Exportable, UseAccessCode:e.target.checked}})} />
              }
              label="Use access code" />
              <Tooltip 
              title="An access code provides stronger authorization for signers.
                Send this code to recipients using a channel other than e-mail."
              >
                <InfoIcon className={classes.infoIcon} />
              </Tooltip>
              {accessCodeTextField}
          </Grid>

          <Grid item key="grid_customizeSubjectBody" xs={(IsMobile()) ? 12 : 6}>
            <FormControlLabel
              control={<Switch
                color="secondary"
                checked={Exportable.UseCustomSubjectAndBody}
                onChange={e => this.setState({Exportable: {...this.state.Exportable, UseCustomSubjectAndBody:e.target.checked}})} />
              }
              label="Customize subject/body" />
          </Grid>

        </Grid>

        {customSubjectAndBodyFields}
      </div>
    );

    let quickDesignMessage = (formDesignType === "Quick")
      ? (
        <div style={{marginBottom:theme.spacing(2)}}>
          A date-signed field will be added for each signer.
        </div>)
      : null;

    let content = (
      <div>
        {quickDesignMessage}
        {optionsContent}
      </div>
    );

    return (
       <Dialog
        fullWidth={!IsMobile()}
        fullScreen={IsMobile()}
        maxWidth="sm"
        TransitionComponent={Transition}
        // PaperProps={{
        //   style:{
        //     minHeight:"50%",
        //   }
        // }}
        open={open}
        onClose={() => this.handleClose()}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description">
        <DialogTitle id="dialog-title">
          <span>Signature options</span>
        </DialogTitle>
        <DialogContent style={{
          // paddingTop:0,
        }}>
          {progressIndicator}

          {content}
        </DialogContent>

        {dialogActions}
      </Dialog>
    );
  }
}

export default withStyles(styles, {withTheme: true})(DocumentSignatureSessionFinalDialog);