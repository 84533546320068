export default class WorkflowModel {
  ProcessElement = {
    ID: "",
    Name: "",
    Type: "",
    Subtype: "",
    GraphX: null,
    GraphY: null,
    Connections: [],
  }

  ProcessElementConnection = {
    ID: "",
    Name: "",
    Type: null,
    SourceProcessElementID: null,
    TargetProcessElementID: null,
    SourcePortType: null,
    TargetPortType: null,
  }

  static GetNewElement(processElementSubtype, graphX, graphY) {
    let newElement = {...this.ProcessElement};
    newElement.ID = (new Date()).getTime().toString();
    newElement.Name = processElementSubtype.Name;
    newElement.Type = processElementSubtype.ProcessElementType;
    newElement.Subtype = processElementSubtype.ProcessElementSubtype;
    newElement.GraphX = graphX;
    newElement.GraphY = graphY;
    return newElement;
  }

  static GetElementById(elements, id) {
    let e = elements.filter(e => e.ID === id);
    if (e.length)
      return e[0];
    return null;
  }

  static GetNewElementConnection(targetElementID, sourcePortType, targetPortType, name) {
    let newElementConnection = {...this.ProcessElementConnection};
    newElementConnection.ID = (new Date()).getTime().toString();
    newElementConnection.Name = name;
    newElementConnection.TargetProcessElementID = targetElementID;
    newElementConnection.SourcePortType = sourcePortType;
    newElementConnection.TargetPortType = targetPortType;
    return newElementConnection;
  }

  static AddConnectionsToElementsAndReturnElements(elements, elementID, connections) {
    let element = this.GetElementById(elements, elementID);
    if (element) {
      if (element.Connections) {
        connections.map(ec => element.Connections.push(ec));
      }
      else {
        element.Connections = connections;
      }
    }
    return elements;
  }

  static GetElementConnectionById(elements, id) {
    let e = elements.filter(e => (e.Connections && e.Connections.filter(ec => ec.ID === id).length > 0));
    if (e.length) {
      let ec = e[0].Connections.filter(ec => ec.ID === id);
      if (ec.length) {
        return ec[0];
      }
    }
    return null;
  }
}