import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import Tooltip from '@material-ui/core/Tooltip';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Collection from '../Model/Collection';
import ItemTableRow from '../Components/ItemTableRow';

import ImageCaptureIcon from '@material-ui/icons/Scanner';
import FileUploadIcon from '@material-ui/icons/CloudUpload';
import DocumentCreateIcon from '@material-ui/icons/Add';

import { GetDocumentsCollectionFields } from '../Model/DocumentsCollectionFields';
import DocumentCard from '../Components/DocumentCard';
import {
  GetTagsForList,
} from '../Util/Tags';

// import { GetDocumentsCount } from '../Util/api';

// import {
//   GetPreviewMetadataHeadCells,
//   GetPreviewMetadataTableCells,
// } from '../Util/Metadata';
import {
  HandleRouteToDocument,
} from '../Util/Documents';
import {
  GetDateValue,
  GetUserValue,
  // GetBoolValue,
} from '../Util/Properties';
import {
  GetPageCountForDisplay,
  GetFileSizeSpan,
} from '../Util/Document';
import {
  DocumentFoldersNodeType,
} from '../Util/Nodes';
import API, {
  GetDocumentsPathForApi,
  GetDocumentsPublicPathForApi,
  GetUserOrganizationProjectDocumentFolderDocumentsPathForApi,
  GetDocumentFilterSortFieldsPathForApi,
  GetDocumentFilterSortFieldsPublicPathForApi,
  GetUserOrganizationProjectDocumentFolderDocumentFilterSortFieldsPathForApi,
} from '../Util/api';
import {
  GetPublicDocumentFoldersDocumentPrefix,
} from '../Util/PathHelper';
import {
  AddFilterSortFieldsToHeadCells,
  GetItemTableCellsForFilterSortFields,
} from '../Util/Fields';

import { IsMobile } from '../Util/MobileDetector';

export class Documents_Collection extends Collection {
  constructor(props, onSetState, onApiError, isWorkspace, isProjects, loadByDocumentFolderAssignment, 
    loadByPublicApi, organizationId, projectId, documentFolderId, documentOriginType,
    onBeginFileUploadFunc, onBeginImageCaptureFunc, onBeginDocumentCreateFunc,
    allowSubscriptions, disableSelect, documentGroupId, showAll,
    extraUrlParamsForDocumentRouting, onlyUnsigned, onlyHasSinglePageImages,
    onRouteToDocumentFunc) {
    
    super(props, onSetState, onApiError, isWorkspace, isProjects, organizationId, projectId);

    this.PageTitle = "Documents";
    this.ContentUri = (loadByDocumentFolderAssignment)
      ? GetUserOrganizationProjectDocumentFolderDocumentsPathForApi(organizationId, projectId, documentFolderId)
      : (loadByPublicApi)
        ? GetDocumentsPublicPathForApi(organizationId, projectId)
        : GetDocumentsPathForApi(organizationId, projectId);
    this.ContentUriParams = {
      documentFolderId: documentFolderId || undefined,
      documentGroupId: documentGroupId || undefined,
      documentOriginType: documentOriginType || undefined,
      showAll: (showAll || (!documentFolderId && IsMobile())) || undefined,
      unsigned: onlyUnsigned || undefined,
      hasSinglePageImages: onlyHasSinglePageImages || undefined,
    };
    this.CollectionName = "Documents";
    this.ItemsName = "Documents";
    this.ItemName = "Document";
    this.DefaultViewType = "Card";
    this.AllowSelect = !disableSelect;
    this.CanSelectItem = item => this.AllowSelect;
    // this.HideSensitiveFields = true;
    this.SingleLineTableCells = true;

    this.loadByDocumentFolderAssignment = loadByDocumentFolderAssignment;
    this.loadByPublicApi = loadByPublicApi;
    this.allowSubscriptions = allowSubscriptions;
    this.DocumentFolderID = documentFolderId;
    this.onBeginFileUploadFunc = onBeginFileUploadFunc;
    this.onBeginImageCaptureFunc = onBeginImageCaptureFunc;
    this.onBeginDocumentCreateFunc = onBeginDocumentCreateFunc;
    this.onRouteToDocumentFunc = onRouteToDocumentFunc;

    this.HandleCreateNew = e =>
      (this.onBeginImageCaptureFunc || this.onBeginDocumentCreateFunc)
        ? this.handleSetFabMenuVisibility(true)(e)
        : (this.onBeginFileUploadFunc)
          ? this.onBeginFileUploadFunc(documentFolderId)
          : null;
    this.ExtraUrlParamsForDocumentRouting = extraUrlParamsForDocumentRouting;

    // this.setDocumentsCount();

    this.filterSortFields = [];
    this.loadedFilterSortFields = false;
  }

  handleSetFabMenuVisibility = visible => event => {
    this.setState({
      FabMenu_MouseY: (visible) ? ((event) ? event.clientY - 100 : (window.innerHeight/2) - 50) : null,
      FabMenu_MouseX: (visible) ? ((event) ? event.clientX - 180 : (window.innerWidth/2) - 100) : null,
    });
  }

  HandleGetFabMenu = state => {
    if (!state.FabMenu_MouseY) {
      return null;
    }
    const imageCaptureMenuItem = (this.onBeginImageCaptureFunc)
      ? (
        <MenuItem key="imageCapture" onClick={this.handleFabMenuClick(state, "imageCapture")}>
          <ListItemIcon>
            <ImageCaptureIcon />
          </ListItemIcon>
          Document scan
        </MenuItem>
      ) : null;
    const blankDocumentMenuItem = (this.onBeginDocumentCreateFunc)
      ? (
        <MenuItem key="documentCreate" onClick={this.handleFabMenuClick(state, "documentCreate")}>
          <ListItemIcon>
            <DocumentCreateIcon />
          </ListItemIcon>
          Blank document
        </MenuItem>
      ) : null;
    return (
        <Menu
          id="fabMenu"
          keepMounted
          open={state.FabMenu_MouseY !== null}
          anchorReference="anchorPosition"
          anchorPosition={
            state.FabMenu_MouseY !== null && state.FabMenu_MouseX !== null
              ? { top: state.FabMenu_MouseY, left: state.FabMenu_MouseX }
              : undefined
          }
          onClose={this.handleSetFabMenuVisibility(false)}
        >
          {blankDocumentMenuItem}
          {imageCaptureMenuItem}
          <MenuItem key="fileUpload" onClick={this.handleFabMenuClick(state, "fileUpload")}>
            <ListItemIcon>
              <FileUploadIcon />
            </ListItemIcon>
            Document upload
          </MenuItem>
        </Menu>
      );
  }

  handleFabMenuClick = (state, id) => e => {
    this.handleSetFabMenuVisibility(false)(e);
    const documentFolderNode = (state.SelectedNode
      && state.SelectedNode.NodeType === DocumentFoldersNodeType)
      ? state.SelectedNode
      : undefined;
    switch (id) {
      case "imageCapture":
        if (this.onBeginImageCaptureFunc) {
          this.onBeginImageCaptureFunc(documentFolderNode);
        }
        break;
      case "fileUpload":
        if (this.onBeginFileUploadFunc) {
          this.onBeginFileUploadFunc(this.DocumentFolderID);
        }
        break;
      case "documentCreate":
        if (this.onBeginDocumentCreateFunc) {
          this.onBeginDocumentCreateFunc(documentFolderNode);
        }
        break;
      default:
        break;
    }
  }

  // Since we now show only those without a documentFolderId when no documentFolderId is passed,
  // This count is inaccurate
  setDocumentsCount = () => {
    // if (this.DocumentFolderID) {
    //   this.setState({TotalItems:0});
    //   return;
    // }
    // GetDocumentsCount(this.OrganizationID, this.ProjectID, true)
    //   .then(total => {
    //     this.setState({ TotalItems: total });
    //   })
    //   .catch(this.handleApiError);
  }

  HandleRefresh = () => {};//this.setDocumentsCount();

  HandleGetCollectionFieldsPromise = () => {
    const getCollectionFieldsFunc = fields => {
      return GetDocumentsCollectionFields(fields, this.loadByPublicApi)
        .filter(f =>
          (this.isWorkspace)
            ? f.ID !== "Meta_text_kw256lc[AssignmentUserEmail].keyword"
            : f
        );
    }

    if (this.loadedFilterSortFields) {
      return Promise.resolve(
        getCollectionFieldsFunc(this.filterSortFields)
      );
    }

    const uri = (this.loadByDocumentFolderAssignment)
      ? GetUserOrganizationProjectDocumentFolderDocumentFilterSortFieldsPathForApi(
        this.OrganizationID, this.ProjectID, this.DocumentFolderID)
      : (this.loadByPublicApi)
        ? GetDocumentFilterSortFieldsPublicPathForApi(this.OrganizationID, this.ProjectID)
        : GetDocumentFilterSortFieldsPathForApi(this.OrganizationID, this.ProjectID);
    return API.get(uri)
      .then(resp => {
        this.filterSortFields = resp.data;
        this.loadedFilterSortFields = true;
        return getCollectionFieldsFunc(this.filterSortFields);
      })
      .catch(this.handleApiError);
  }

  HandleGetHeadCells = (items, sensitiveFields) => {
    let headCells = [
      { id: 'Name', sortId: 'Meta_text_kw256lc[Name].keyword', numeric: false, label: 'Name' },
    ];
    if (!this.loadByPublicApi) {
      if (!this.ProjectID || this.isWorkspace) {
        headCells.push(
          { id: 'ProjectName', sortId: 'Meta_text_kw256lc[ProjectName].keyword', numeric: false, label: "Project" },
        );
      }
    }
    headCells.push(
      { id: 'CreatedOn', sortId: 'Meta_date_str256[CreatedOn].string', numeric: false, label: 'Created On' },
    );
    if (!this.loadByPublicApi) {
      headCells.push(
        { id: 'CreatedByUserEmail', sortId: 'Meta_text_kw256lc[CreatedByUserEmail].keyword', numeric: false, label: 'Created By' },
        { id: 'Assignment', sortId: 'Meta_text_kw256lc[AssignmentUserEmail].keyword', numeric: false, label: 'Shared with' },
        { id: 'Tags', sortId: 'Meta_text_kw50lc[Tag].keyword', numeric: false, label: 'Tags' },
      );
    }
    headCells.push(
      { id: 'PageCount', sortId: 'Meta_int[PageCount]', numeric: true, label: 'Page Count' },
      { id: 'FileSize', sortId: 'Meta_long[FileSize]', numeric: true, label: 'File Size' },
    );
    if (!this.loadByPublicApi) {
      AddFilterSortFieldsToHeadCells(headCells, this.filterSortFields);
    }

    return headCells;
  }

  getExtraUrlParamsForDocumentRouting = item => {
    return {
      documentFolderID: item.DocumentFolderID,
      ...this.ExtraUrlParamsForDocumentRouting,
    };
  }

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, selectedIDs,
    onAction, postActionData, sortType, sortDescending, useSingleColumnLayout, cardStyle,
    viewType, onItemClick, disableCardActions) => {
    return (items && items.length)
      ? items
        .map(i => {
          return (
            <Grid item key={i.ID} className={classes.cardGridItem}
              sm={(!useSingleColumnLayout) ? 12 : undefined}
              md={(!useSingleColumnLayout) ? 6 : undefined}
              lg={(!useSingleColumnLayout) ? 4 : undefined}
              xl={(!useSingleColumnLayout) ? 3 : undefined}
            >
              <DocumentCard
                organizationId={this.OrganizationID}
                projectId={this.ProjectID}
                isPublicApi={this.loadByPublicApi}
                Document={i}
                sensitiveFields={sensitiveFields}
                postActionData={postActionData}
                allowDownload={i.Origin !== "Editor"}
                allowSubscriptions={this.allowSubscriptions}
                onCardAction={() => {
                  const defaultAction = () => {
                    if (this.onRouteToDocumentFunc) {
                      this.onRouteToDocumentFunc(i.ID);
                    } else {
                      HandleRouteToDocument(this.OrganizationID, this.ProjectID,
                        i, items, this.ContentUri, this.ContentUriParams, sortType, sortDescending, false, false, 
                        this.getExtraUrlParamsForDocumentRouting(i),
                        (this.loadByDocumentFolderAssignment) ? "documentFolder" : undefined,
                        this.loadByPublicApi, this.loadByPublicApi, 
                        GetPublicDocumentFoldersDocumentPrefix(this.props.location), this.props.history,
                      );
                    }
                  };
                  if (onItemClick) {
                    onItemClick(i, defaultAction);
                  } else {
                    defaultAction();
                  }
                }}
                disableCardActions={disableCardActions}
                onSelect={(this.CanSelectItem(i)) ? () => onSelect(i.ID) : undefined}
                onAction={onAction}
                noSelect={!this.CanSelectItem(i)}
                selected={selectedIDs.indexOf(i.ID) > -1}
                style={cardStyle}
              />
            </Grid>
          );
        }) 
      : [];
  }

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs, 
    onAction, postActionData, sortType, sortDescending, onItemClick) => {
    return (items && items.length)
      ? items.map(i => {
        const selected = selectedIDs.indexOf(i.ID) !== -1;
        const projectNameCell = (!this.ProjectID || this.isWorkspace)
            ? <TableCell className={classes.tableCell}>{i.ProjectName}</TableCell>
            : null;
        const labelId = `label_${i.ID}`;
        // const metadataTableCells = [...GetPreviewMetadataTableCells(i, headCells, classes)];

        let nonPublicFilterSortFieldTableCells = [];
        if (!this.loadByPublicApi) {
          nonPublicFilterSortFieldTableCells = GetItemTableCellsForFilterSortFields(i, this.filterSortFields, classes);
        }

        const nonPublicCells1 = (!this.loadByPublicApi)
          ? (
            <React.Fragment>
              <TableCell style={{whiteSpace:"nowrap"}}>{GetUserValue(i.CreatedByUserEmail, i.CreatedByUserName)}</TableCell>
              <TableCell style={{whiteSpace:"nowrap"}}>{
                (i.AssignmentUserEmails && i.AssignmentUserEmails.length)
                  ? i.AssignmentUserEmails.join(", ")
                  : null
              }</TableCell>
              <TableCell className={classes.tableCell}>
                {GetTagsForList(i, sortType, sortDescending)}
              </TableCell>
            </React.Fragment>
          ) : null;

        const routeToDocument = () => {
          const defaultAction = () => {
            if (this.onRouteToDocumentFunc) {
              this.onRouteToDocumentFunc(i.ID);
            } else {
              HandleRouteToDocument(this.OrganizationID, this.ProjectID,
                i, items, this.ContentUri, this.ContentUriParams, sortType, sortDescending, false, false, 
                this.getExtraUrlParamsForDocumentRouting(i),
                (this.loadByDocumentFolderAssignment) ? "documentFolder" : undefined,
                this.loadByPublicApi, this.loadByPublicApi, 
                GetPublicDocumentFoldersDocumentPrefix(this.props.location), this.props.history,
              );
            }
          };
          if (onItemClick) {
            onItemClick(i, defaultAction);
          } else {
            defaultAction();
          }
        }

        return (
          <ItemTableRow
            key={`k_${i.ID}`}
            onItemClick={() => routeToDocument()}
            selected={selected}
            onSelect={(this.CanSelectItem(i)) ? () => onSelect(i.ID) : undefined}
          >
            <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
              <Checkbox
                style={{
                  visibility: (!this.CanSelectItem(i)) ? "hidden" : undefined,
                }}
                color="secondary"
                onClick={event => { event.stopPropagation(); onSelect(i.ID); }}
                checked={selected}
                inputProps={{ 'aria-labelledby': labelId, style:{zIndex:0} }}
              />
            </TableCell>
            <TableCell component="th" id={labelId} scope="row" padding="none" style={{color:i.DocumentFolderHexColor}}>
              {i.Name}
            </TableCell>
            {projectNameCell}
            <TableCell style={{whiteSpace:"nowrap"}}>{GetDateValue(i.CreatedOn)}</TableCell>
            {nonPublicCells1}
            <TableCell align="right">{GetPageCountForDisplay(i.PageCount)}</TableCell>
            <TableCell align="right">{GetFileSizeSpan(i.FileSize)}</TableCell>
            {nonPublicFilterSortFieldTableCells}
            {/*{metadataTableCells}*/}
          </ItemTableRow>
        );
      })
    : null;
  }
}