import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Card from './Card';

import {
  GetDateValue,
} from '../Util/Properties';

class TagCard extends Component {
  render() {
    const {
      Tag,
      onCardAction,
      style,
      noSelect,
      onSelect,
      selected,
      isSearchResults
    } = this.props; 

    let metadata = [
      ["Modified On", GetDateValue(Tag.ModifiedOn)],
    ];

    return (
      <Card
        style={style}
        noSelect={noSelect}
        onSelect={onSelect}
        selected={selected}
        onCardAction={onCardAction}
        name={Tag.Text}
        isSearchResults={isSearchResults}
        metadata={metadata}
        contentItem={Tag}
      />
    );
  }
}

TagCard.propTypes = {
  Tag: PropTypes.object.isRequired,
  onCardAction: PropTypes.func.isRequired,
};

export default TagCard;