import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Card from './Card';

import {
  GetBoolValue,
  GetDateValue,
  GetFieldTypeLabel,
} from '../Util/Properties';

class FieldCard extends Component {
  render() {
    const {
      Field,
      onCardAction,
      style,
      noSelect,
      onSelect,
      selected,
      isSearchResults
    } = this.props; 

    let metadata = [
      ["Project", Field.ProjectName],
      ["Created On", GetDateValue(Field.CreatedOn)],
      ["Label", Field.Label],
      ["Type", GetFieldTypeLabel(Field.Type || Field.ItemType)],
      ["Required", GetBoolValue(Field.Required)],
    ];

    return (
      <Card
        style={style}
        noSelect={noSelect}
        onSelect={onSelect}
        selected={selected}
        onCardAction={onCardAction}
        name={Field.Name}
        isSearchResults={isSearchResults}
        metadata={metadata}
        contentItem={Field}
      />
    );
  }
}

FieldCard.propTypes = {
  Field: PropTypes.object.isRequired,
  onCardAction: PropTypes.func.isRequired,
};

export default FieldCard;