import React, {Component} from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { Player } from 'video-react';
import "video-react/dist/video-react.css";

import { IsMobile } from '../Util/MobileDetector';

const styles = theme => ({
  dialogPaper: {
    height:(!IsMobile()) ? "85%" : undefined,
    width:(!IsMobile()) ? "90%" : undefined,
    border:"1px solid #444",
    borderRadius:10,
  },
  dialogContentRoot: {
    padding:0,
  },
  dialogContainer: {
    backgroundColor:"rgba(0,0,0,0.7)",
  },
});

class VideoHelpDialog extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      open: props.open,
    }

    this.player = null;
  }

  handleClose = () => {
    this.setState({ open: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handlePlayerStateChange = state => {
    if (state && state.ended) {
      this.handleClose();
    }
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.player) {
        this.player.subscribeToStateChange(this.handlePlayerStateChange);
      }
    }, 1);
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      this.setState({open: this.props.open !== false});
    }
  }

  render() {
    const {
      open,
    } = this.state;
    const {
      src,
      classes,
      // theme,
    } = this.props;

    return (
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={this.handleClose}
        classes={{
          paper:classes.dialogPaper,
          container:classes.dialogContainer,
        }}
        aria-describedby="dialog-description">
        <DialogContent
          classes={{
            root:classes.dialogContentRoot,
          }}
          style={{
            padding:0,
          }}
        >
          <Player
            ref={(player) => { this.player = player; }}
            playsInline
            autoPlay
            fluid={false}
            height="100%"
            // poster={posterUri}
            src={src}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

VideoHelpDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  src: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

export default withStyles(styles, {withTheme: true})(VideoHelpDialog);
