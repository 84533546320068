import Collection from '../Model/Collection';

import OrganizationSubscription from '../Admin/Screens/OrganizationSubscription';

export class OrganizationSubscription_Collection extends Collection {
  constructor(props, onSetState, onApiError, organizationId, onAlert) {
    if (typeof organizationId !== "string") {
      console.log("Warning: organizationId required and not found");
    }
    if (typeof onAlert !== "function") {
      console.log("Warning: onAlert required and not found");
    }
    super(props, onSetState, onApiError, false, false);

    this.HideToolHeader = true;
    this.PassThroughComponent = (
      <OrganizationSubscription
        {...props}
        organizationId={organizationId}
        returnContentOnly
        onApiError={onApiError}
        onAlert={onAlert}
      />
    );
  }
}