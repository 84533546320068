import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import DashboardCard from '../DashboardCard';

import { TasksIcon as Icon } from '../../Util/Icons';
import IconColor from '@material-ui/core/colors/green';

import PieChartIcon from '@material-ui/icons/PieChart';
import LineChartIcon from '@material-ui/icons/Timeline';

import ChartTooltip from '../ChartTooltip';

import { 
  PieChart,
  MultiLineChart,
  Legend,
} from 'eazychart-react';
import 'eazychart-css';

import API,
{
  GetTaskSchedulesStatsPathForApi,
} from '../../Util/api';
import {
  GetProjectTasksPath,
} from '../../Util/PathHelper';

import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';

import dateformat from 'dateformat';

class LineChartTooltip extends Component {
  render() {
    const {
      datum,
    } = this.props;

    return (
      <ChartTooltip {...this.props}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            {`Date: ${datum && datum["DateAsString"]}`}
          </Grid>
          <Grid item>
            {`On time: ${datum && datum["On time"]}`}
          </Grid>
          <Grid item>
            {`Past due: ${datum && datum["Past due"]}`}
          </Grid>
          <Grid item>
            {`Unscheduled: ${datum && datum["Unscheduled"]}`}
          </Grid>
        </Grid>
      </ChartTooltip>
    );
  }
}

class PieChartTooltip extends Component {
  render() {
    const {
      datum,
    } = this.props;

    return (
      <ChartTooltip {...this.props}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            {datum && `${datum.Item}: ${((datum.Value/datum.Total)*100).toFixed(1)}% (${datum.Value} of ${datum.Total}) as of ${datum.DateAsString}`}
          </Grid>
        </Grid>
      </ChartTooltip>
    );
  }
}

const styles = theme => ({
  chart: {
    display:"flex",
    justifyContent:"center",
    "& .ez-legend": {
      paddingTop:4,
    },
    "& .ez-legend-key": {
      // display:"flex",
      // alignItems:"center",
      marginRight: theme.spacing(2),
    },
    "& .ez-legend-box": {
      marginBottom:2,
    },
    "& .ez-legend-text": {
      marginLeft:theme.spacing(1),
      marginTop:2,
    },
    "& .ez-axis-path": {
      // stroke:(theme.palette.type === "dark") ? "white" : undefined,
      stroke:"transparent",
    },
    "& .ez-axis-tick": {
      // stroke:(theme.palette.type === "dark") ? "white" : undefined,
      stroke:"transparent",
    },
    "& .ez-axis-tick-text": {
      fontSize:8,
      fill:(theme.palette.type === "dark") ? "white" : undefined,
    },
    "& .ez-grid-line": {
      stroke:(theme.palette.type === "dark") ? "white" : undefined,
      opacity:0.1,
    },
  },
  chartSwitcher: {
    pointerEvents:"auto",
    position:"absolute",
    bottom:0,
    right:0,
  },
});

class TaskSchedulesWidget extends Component {
  constructor(props) {
    super(props);

    let ChartType;
    if (props.DashboardWidget.JsonData) {
      const j = JSON.parse(props.DashboardWidget.JsonData);
      if (j && j.ChartType) {
        ChartType = j.ChartType;
      }
    }
   
    this.state = {
      LoadComplete: false,
      Stats: props.samples || [],
      DashboardWidget: props.DashboardWidget,
      ChartType,
    }
  }

  loadStats() {
    API.get(GetTaskSchedulesStatsPathForApi(this.props.organizationId, 
      this.props.DashboardWidget.ProjectID))
      .then(resp => {
        this.setState({
          Stats: resp.data.Stats,
          LoadComplete: true,
        });
      })
      .catch(this.props.onApiError);
  }

  handleSwitchChart = e => {
    e.stopPropagation();

    let ChartType;
    switch (this.state.ChartType) {
      case "Line":
        ChartType = "Pie";
        break;
      case "Pie":
      default:
        ChartType = "Line";
        break;
    }
    let DashboardWidget = {
      ...this.state.DashboardWidget,
      JsonData: JSON.stringify({
        ChartType,
      }),
    };
    this.setState({
      ChartType,
      DashboardWidget,
    });
    if (!this.props.isSample && this.props.onUpdateDashboardWidget) {
      this.props.onUpdateDashboardWidget(DashboardWidget);
    }
  }

  handleCardAction = () => {
    const dw = this.props.DashboardWidget;
    this.props.history.push(GetProjectTasksPath(dw.ProjectID));
  }

  componentDidMount() {
    if (!this.props.samples) {
      this.loadStats();
    }
  }

  render() {
    const {
      LoadComplete,
      Stats,
      DashboardWidget,
      ChartType,
    } = this.state;
    const { 
      classes,
      theme,
      isSample,
      ...passThroughProps
    } = this.props;

    let noStatsMessage;
    let chart;
    const maxPoints = 12;
    if (Stats && Stats.length > 0) {
      switch (ChartType) {
      case "Line":
        let lineChartData = [];
        const maxI = Math.min(maxPoints, Stats.length);
        for (let i = 0; i < maxI; i++) {
          const s = Stats[i];
          lineChartData.push({
            Index: (maxI - i - 1),
            DateAsString: dateformat(s.CreatedOn, "m/d/yy h:MM:ss TT"),
            Day: dateformat(s.CreatedOn, "m/d/yy"),
            Time: dateformat(s.CreatedOn, "h:MMTT"),
            CreatedOn: s.CreatedOn,
            "On time": s.OnTime,
            "Past due": s.PastDue,
            "Unscheduled": s.Unscheduled,
          });
        }
        let lastDayDisplayed = "";
        chart = (
          <MultiLineChart
            dimensions={{
              width:260,
              height:210,
            }}
            padding={{
              top:10,
              bottom:40,
              left:48,
              right:24,
            }}
            grid={{
              // directions: [],
            }}
            xAxis={{
              domainKey: "Index",
              tickFormat: i => {
                const iFinder = lineChartData.filter(d => d.Index === i);
                if (iFinder.length) {
                  if (i === 0) {
                    lastDayDisplayed = iFinder[0].Day;
                    return lastDayDisplayed;
                  }
                  if (iFinder[0].Day !== lastDayDisplayed) {
                    lastDayDisplayed = iFinder[0].Day;
                    return lastDayDisplayed;
                  }
                  return iFinder[0].Time;
                }
                return "";
              },
            }}
            yAxis={{
              domainKeys: [
                "On time",
                "Past due",
                "Unscheduled",
              ],
              tickFormat: i => {
                if (i === Math.round(i)) {
                  return i.toString();
                }
                return "";
              },
            }}
            marker={{
              hidden:false,
              radius: 4,
              // color: "transparent",
            }}
            colors={
              [
                green[500],
                red[500],
                grey[500],
              ]
            }
            data={lineChartData}
            scopedSlots={{
              LegendComponent: Legend,
              TooltipComponent: (!DashboardWidget.IsDragging) ? LineChartTooltip : null,
            }}
          />
        );
        break;
      case "Pie":
      default:
        const lastStat = Stats[0];
        const Total = lastStat.OnTime + lastStat.Unscheduled + lastStat.PastDue;
        const DateAsString = dateformat(lastStat.CreatedOn, "m/d/yy h:MM:ss TT")
        const pieChartData = [
          {
            Item: "On time",
            Value: lastStat.OnTime,
            Total,
            DateAsString,
          },
          {
            Item: "Past due",
            Value: lastStat.PastDue,
            Total,
            DateAsString,
          },
          {
            Item: "Unscheduled",
            Value: lastStat.Unscheduled,
            Total,
            DateAsString,
          },
        ];
        chart = (
          <PieChart
            dimensions={{
              width:200,
              height:210,
            }}
            padding={{
              top:0,
              bottom:16,
              left:0,
              right:0,
            }}
            colors={
              [
                green[500],
                red[500],
                grey[500],
              ]
            }
            labelDomainKey="Item"
            valueDomainKey="Value"
            data={pieChartData}
            scopedSlots={{
              LegendComponent: Legend,
              TooltipComponent: (!DashboardWidget.IsDragging) ? PieChartTooltip : null,
            }}
          />
        );
        break;
      }      
    } else if (LoadComplete) {
      noStatsMessage = (
        <Typography variant="body1" style={{
          display:"flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            textAlign:"center",
        }}>
          There are no statistics at this time.
        </Typography>
      );
    }

    const divChart = (chart)
      ? (
        <div className={classes.chart}
          style={{
            pointerEvents:(!isSample) ? "auto" : undefined,
          }}
        >
          {chart}
        </div>
      ) : null;

    let chartSwitcherIcon;
    let chartSwitcherTitle;
    switch (ChartType) {
      case "Line":
        chartSwitcherTitle = "Switch to pie chart";
        chartSwitcherIcon = <PieChartIcon />;
        break;
      case "Pie":
      default:
        chartSwitcherTitle = "Switch to line chart";
        chartSwitcherIcon = <LineChartIcon />;
        break;
    }

    const chartSwitcher = (
      <Tooltip title={chartSwitcherTitle}>
        <IconButton className={classes.chartSwitcher}
          onClick={this.handleSwitchChart}
        >
          {chartSwitcherIcon}
        </IconButton>
      </Tooltip>
    );

    return (
      <DashboardCard
        title={`Task Schedules${(DashboardWidget && DashboardWidget.ProjectName) 
          ? ": " + DashboardWidget.ProjectName
          : ""
        }`}
        onCardAction={this.handleCardAction}
        titleIcon={<Icon style={{color: IconColor[500],marginTop:2}} />}
        noFadeout
        xs={12}
        sm={6}
        md={6}
        lg={2}
        xl={2}
        showSampleOverlay={isSample}
        useSmallTitle={!isSample}
        {...passThroughProps}
        DashboardWidget={DashboardWidget}
      >
        {divChart}
        {chartSwitcher}
        {noStatsMessage}
      </DashboardCard>  
    );
  }
}

TaskSchedulesWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  DashboardWidget: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  onUpdateDashboardWidget: PropTypes.func,
  samples: PropTypes.array,
  isSample: PropTypes.bool,
};

export default withStyles(styles, {withTheme: true})(TaskSchedulesWidget);