import { GetFieldForFilterAndSort } from '../Util/Field';

const TagCollectionFields = [
	GetFieldForFilterAndSort(
		"TextLower",
		"Text",
		"FieldType_Text",
		null,
		[
			{
			    value: "startsWith",
			    label: "word starts with",
			},
		],
	),
];

export default TagCollectionFields;