import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';

import Collection from '../Model/Collection';
import ItemTableRow from '../Components/ItemTableRow';

import DocumentSubscriptionCollectionFields from '../Model/DocumentSubscriptionCollectionFields';
import DocumentSubscriptionCard from '../Components/DocumentSubscriptionCard';

// import {
//   GetPreviewMetadataHeadCells,
//   GetPreviewMetadataTableCells,
// } from '../Util/Metadata';
import { 
  HandleRouteToDocument,
} from '../Util/Documents';
import {
  GetPageCountForDisplay,
  GetFileSizeSpan,
} from '../Util/Document';
import {
  GetDateValue,
} from '../Util/Properties';
import {
  GetProjectDocumentSubscriptionsPathForApi,
} from '../Util/api';

export class DocumentSubscriptions_Collection extends Collection {
  constructor(props, onSetState, onApiError, isWorkspace, isProjects, 
    organizationId, projectId) {
    
    super(props, onSetState, onApiError, isWorkspace, isProjects, organizationId, projectId);

    this.PageTitle = "Document Subscriptions";
    this.ContentUri = GetProjectDocumentSubscriptionsPathForApi(organizationId, projectId);
    this.CollectionName = "SubscriptionDocuments";
    this.ItemsName = "DocumentSubscriptions";
    this.ItemName = "Document Subscription";
    this.DefaultViewType = "Card";
    this.AllowSelect = true;
    this.HideSensitiveFields = true;
  }

  HandleGetCollectionFieldsPromise = () => {
    return Promise.resolve(DocumentSubscriptionCollectionFields);
  }

  HandleGetHeadCells = (items, sensitiveFields) => {
    let headCells = [
      { id: 'DocumentName', sortId: 'Meta_text_kw256lc[Name].keyword', numeric: false, label: 'Name' },
    ];
    if (!this.ProjectID) {
      headCells.push(
        { id: 'ProjectName', numeric: false, label: "Project" },
      );
    }
    headCells.push(
      { id: 'CreatedOn', sortId: 'Meta_date_str256[CreatedOn].string', numeric: false, label: 'Created On' },
      { id: 'DocumentCreatedOn', sortId: 'Meta_date_str256[ItemCreatedOn].string', numeric: false, label: 'Document Created On' },
      { id: 'DocumentPageCount', sortId: 'Meta_int[PageCount]', numeric: true, label: 'Page Count' },
      { id: 'DocumentFileSize', sortId: 'Meta_long[FileSize]', numeric: true, label: 'File Size' },
    );
    // headCells.push(...GetPreviewMetadataHeadCells(sensitiveFields, items));
    return headCells;
  }

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length)
      ? items
        .map(i => {
          return (
            <Grid item key={i.ID} sm={12} md={6} lg={4} xl={3} className={classes.cardGridItem}>
              <DocumentSubscriptionCard
                DocumentSubscription={i}
                sensitiveFields={sensitiveFields}
                onCardAction={() => HandleRouteToDocument(this.OrganizationID, this.ProjectID,
                  i, items, this.ContentUri, this.ContentUriParams, sortType, sortDescending, false)}
                onSelect={() => onSelect(i.ID)}
                onAction={onAction}
                selected={selectedIDs.indexOf(i.ID) > -1}
                postActionData={postActionData}
              />
            </Grid>
          );
        }) 
      : [];
  }

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length)
      ? items
        .map(i => {
          const selected = selectedIDs.indexOf(i.ID) !== -1;
          const projectNameCell = (!this.ProjectID)
            ? <TableCell className={classes.tableCell}>{i.ProjectName}</TableCell>
            : null;
          return (
            <ItemTableRow key={`k_${i.ID}`}
              onSelect={() => onSelect(i.ID)}
              selected={selected}
              onItemClick={() => HandleRouteToDocument(this.OrganizationID, this.ProjectID,
                i, items, this.ContentUri, this.ContentUriParams, sortType, sortDescending, false)}
            >
              <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
                <Checkbox
                  color="secondary"
                  onClick={e => { e.stopPropagation(); onSelect(i.ID); }}
                  checked={selected}
                />
              </TableCell>
              <TableCell className={classes.tableCell} component="th" id={`label_${i.ID}`} scope="row" padding="none">
                {i.DocumentName}
              </TableCell>
              {projectNameCell}
              <TableCell className={classes.tableCell}>{GetDateValue(i.CreatedOn)}</TableCell>
              <TableCell className={classes.tableCell}>{GetDateValue(i.DocumentCreatedOn)}</TableCell>
              <TableCell className={classes.tableCell} align="right">{GetPageCountForDisplay(i.DocumentPageCount)}</TableCell>
              <TableCell className={classes.tableCell} align="right">{GetFileSizeSpan(i.DocumentFileSize)}</TableCell>
              
            </ItemTableRow>
          );
        }) 
      : [];
  }
}