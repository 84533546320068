import React, { Component } from 'react';

import UiCore from '../Components/UiCore';
import { GlobalContext } from '../Context/Global.context';

class OrganizationWorkspaceRedirect extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      ShowProgressIndicatorImmediately:true,
    }
  }

  handleApiError = err => {
    if (typeof err === "string") {
      err = { response: { data: { message: err }}};
    }
    err.closeCallback = this.tryCloseWindowOrRedirectToWorkspace;
    this.setState({ ApiError: err, ShowProgressIndicatorImmediately: false });
  }

  tryCloseWindowOrRedirectToWorkspace = () => {
    this.setState({ShowProgressIndicatorImmediately: false});
    window.close();
    this.props.history.replace("/workspace");
  }

  componentDidMount() {
    let organizationID = this.props.match.params.organizationID;
    if (!organizationID) {
      return this.handleApiError("Invalid or missing organization" );
    }
    // Switch to the organization provided
    this.context.SaveUserPreferences_ActiveOrganization(organizationID)
      .then(() => {
        this.props.history.replace(`/workspace`);
      })
      .catch(this.handleApiError);
  }

  render() {
    const {
      ApiError,
      Alert,
      ShowProgressIndicatorImmediately,
    } = this.state;

    return (
      <div>
        <UiCore title="Organization Access"
          apiError={ApiError}
          alert={Alert}
          showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
        />
      </div>
    );
  }
}

export default OrganizationWorkspaceRedirect;
