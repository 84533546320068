import React, {Component} from 'react';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import API, {
  GetUserSignupPathForApi,
} from '../Util/api';
import ProgressIndicator from './ProgressIndicator';

// import { IsMobile } from '../Util/MobileDetector';
import { GlobalContext } from '../Context/Global.context';

const styles = theme => ({
  // dialogPaper: {
  //   height:(!IsMobile()) ? "80%" : undefined,
  // },
});

class LeadDialog extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      Lead: {
        OrganizationID: "",
        OrganizationName: "",
        UserName: "",
        BusinessPhone: "",
      },
      open: props.open,
      ShowProgressIndicatorImmediately: false,
    }
  }

  handleLeadValueChange = propertyName => e => {
    const Lead = {...this.state.Lead};
    Lead[propertyName] = e.target.value;
    this.setState({Lead});
  }

  handleKeyDown = e => {
    if (e.keyCode === 13) {
      this.handleSubmit();
    }
  } 

  handleSubmit = () => {
    this.setState({ ShowProgressIndicatorImmediately: true });
    API.post(GetUserSignupPathForApi(), this.state.Lead)
      .then(resp => {
        this.setState({ 
          ShowProgressIndicatorImmediately: false, 
        });
        this.context.Reset();
        this.handleClose();
      })
      .catch(this.handleApiError);
  }

  handleClose = () => {
    this.setState({ open: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleApiError = err => {
    this.setState({ ShowProgressIndicatorImmediately: false, });
    this.props.onApiError(err);
  }

  componentDidMount() {
    let Lead = {...this.state.Lead};
    Lead.OrganizationID = this.context.UserPreferences.ActiveOrganizationID;
    Lead.UserName = this.context.UserPreferences.UserName;
    this.setState({Lead});
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      //this.handleLoadData(true);
      this.setState({open: this.props.open !== false});
    }
  }

  render() {
    const {
      Lead,
      open,
      ShowProgressIndicatorImmediately,
    } = this.state;
    // const {
    //   classes,
    //   // theme,
    // } = this.props;

    const complete = Lead.UserName.length >= 3 && Lead.OrganizationName.length >= 3 && Lead.BusinessPhone.length >= 8;

    const progressIndicator = (ShowProgressIndicatorImmediately) ? (
      <ProgressIndicator constrained showImmediately={ShowProgressIndicatorImmediately} />
    ) : null;

    const dialogActions = (
      <DialogActions>
        <Button onClick={this.handleSubmit} disabled={!complete}>
          SUBMIT
        </Button>
      </DialogActions>
    );

    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        // classes={{
        //   paper:classes.dialogPaper,
        // }}
      >
        <DialogTitle id="dialog-title">
          <span>Thanks for connecting!</span>
        </DialogTitle>
        <DialogContent>
          {progressIndicator}

          <Grid container direction="column" spacing={3} style={{marginBottom:0}}>
            <Grid item>
              <Typography>
               Before we get started, tell us a little about yourself. 
              </Typography>
            </Grid>

            <Grid item>
              <TextField
                fullWidth
                autoFocus
                label="Your Name"
                variant="outlined"
                inputProps={{maxLength:200}}
                value={Lead.UserName}
                onChange={this.handleLeadValueChange("UserName")}
                onKeyDown={this.handleKeyDown}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="Company"
                variant="outlined"
                value={Lead.OrganizationName}
                onChange={this.handleLeadValueChange("OrganizationName")}
                onKeyDown={this.handleKeyDown}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="Phone"
                variant="outlined"
                inputProps={{maxLength:30}}
                value={Lead.BusinessPhone}
                onChange={this.handleLeadValueChange("BusinessPhone")}
                onKeyDown={this.handleKeyDown}
              />
            </Grid>
          </Grid>
        </DialogContent>
        {dialogActions}
      </Dialog>
    );
  }
}

LeadDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  userName: PropTypes.string,
  onApiError: PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(LeadDialog);
