import React, {Component} from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import SelectControl from '../../Components/SelectControl';
import ProgressIndicator from '../../Components/ProgressIndicator';
import { GlobalContext } from '../../Context/Global.context';

import {
  HandleUpdateOrganization,
} from '../../Util/Organizations';
import API, {
  GetOrganizationDetailPathForApi,
} from '../../Util/api';


const styles = theme => ({
  contentContainer: {
    background:theme.palette.background.paper,
    padding: theme.spacing(3),
    // [theme.breakpoints.down('xs')]: {
    //   padding: theme.spacing(2),
    // },
    height:"100%",
    overflowY:"auto",
  },
  contentGrid: {
  },
});

class OrganizationProfile extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      Organization: null,
      OrganizationDetail: null,
      ShowProgressIndicatorImmediately: false,
    }

    this.OriginalOrganization = null;
  }

  handleGetOrganizationDetail = organization => {
    this.setState({ShowProgressIndicatorImmediately: true});
    API.get(GetOrganizationDetailPathForApi(organization.ID))
      .then(resp => {
        this.setState({OrganizationDetail:resp.data})
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ShowProgressIndicatorImmediately: false});
      });
  }

  handleUpdateOrganizationDetail = () => {
    this.setState({ShowProgressIndicatorImmediately: true});
    API.put(GetOrganizationDetailPathForApi(this.state.Organization.ID), this.state.OrganizationDetail)
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ShowProgressIndicatorImmediately: false});
      });
  }

  handleUpdateOrganization = () => {
    this.setState({ShowProgressIndicatorImmediately: true});
    return HandleUpdateOrganization(
      {...this.state.Organization},
      this.handleApiError,
      () => {
        this.setState({ShowProgressIndicatorImmediately: false});
        // TODO: Update org name on node in left pane
        this.context.UpdateUserOrganizationName(this.state.Organization.ID, this.state.Organization.Name);
      },
    );
  }

  handleUpdateServer = async () => {
    // Organization updated only if the name changed
    if (this.state.Organization.Name !== this.OriginalOrganization.Name) {
      await this.handleUpdateOrganization()
        .then(() => {
          this.OriginalOrganization = this.state.Organization;    
        });
    }

    this.handleUpdateOrganizationDetail();
  }

  handlePropertyChange = (collectionName, propertyName, value) => {
    const collection = {...this.state[collectionName]};
    collection[propertyName] = value;
    this.setState({[collectionName]:collection});
  }

  handleOrgStringChange = (propertyName, string) => {
    this.handlePropertyChange("Organization", propertyName, string);
  }

  handleOrgDetailStringChange = (propertyName, string) => {
    this.handlePropertyChange("OrganizationDetail", propertyName, string);
  }

  setOrganizationWhenReady = () => {
    if (!this.context.CompletedGET.OrganizationMembershipPackages) {
      return setTimeout(() => this.setOrganizationWhenReady(), 250);
    }

    const orgPkgFinder = this.context.OrganizationMembershipPackages
      .filter(op => op.Organization.ID === this.props.organizationId);
    if (orgPkgFinder.length) {
      const Organization = orgPkgFinder[0].Organization;
      this.OriginalOrganization = Organization;
      this.setState({Organization});
      this.handleGetOrganizationDetail(Organization);
    }
  }

  handleApiError = err => {
    this.setState({ShowProgressIndicatorImmediately: false});
    this.props.onApiError(err);
  }

  componentDidMount() {
    this.setOrganizationWhenReady();
  }

  componentDidUpdate(prevProps) {
    if (this.props.organizationId !== prevProps.organizationId) {
      this.setOrganizationWhenReady();
    }
  }

  render() {
    const {
      Organization,
      OrganizationDetail,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      classes,
      // theme,
    } = this.props;

    const industryOptionsRaw = [
      "Agriculture",
      "Construction",
      "Consulting",
      "Copier",
      "Education",
      "Energy",
      "Engineering",
      "Financial",
      "Government/Townships",
      "Hospitality",
      "Insurance",
      "Laboratory",
      "Legal",
      "Logistics",
      "Manufacturing",
      "Marketing / Media",
      "Medical",
      "Non-Profit",
      "Partner",
      "Payroll Service / HR Outsourcing",
      "Publishing",
      "Realty",
      "Restaurant",
      "Retail",
      "Service",
      "Transportation",
      "Wholesaler",
    ];
    const industryOptions = industryOptionsRaw.map(o => {
      return {
        label: o,
        value: o,
      };
    });

    const progressIndicator = (ShowProgressIndicatorImmediately)
      ? (
        <ProgressIndicator showImmediately />
      )
      : null;

    const content = (Organization && OrganizationDetail) 
      ? (
        <Container maxWidth="sm" className={classes.contentContainer}>
          <Grid container spacing={3} className={classes.contentGrid}>
            <Grid item xs={12}>
              <TextField
                label="Organization Name"
                fullWidth
                variant="outlined"
                inputProps={{maxLength:200}}
                onChange={e => this.handleOrgStringChange("Name", e.target.value)}
                value={Organization.Name}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <SelectControl
                id="industry"
                label="Industry"
                // forceShrinkLabel
                // hideEmpty
                options={industryOptions} 
                value={OrganizationDetail.Industry}
                onValueChange={value => this.handleOrgDetailStringChange("Industry", value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Website"
                fullWidth
                variant="outlined"
                inputProps={{maxLength:255}}
                onChange={e => this.handleOrgDetailStringChange("Website", e.target.value)}
                value={OrganizationDetail.Website}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Business Phone"
                fullWidth
                variant="outlined"
                inputProps={{maxLength:30}}
                onChange={e => this.handleOrgDetailStringChange("Phone", e.target.value)}
                value={OrganizationDetail.Phone}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Billing Address"
                fullWidth
                variant="outlined"
                inputProps={{maxLength:200}}
                onChange={e => this.handleOrgDetailStringChange("BillingStreet", e.target.value)}
                value={OrganizationDetail.BillingStreet}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Billing City"
                fullWidth
                variant="outlined"
                inputProps={{maxLength:100}}
                onChange={e => this.handleOrgDetailStringChange("BillingCity", e.target.value)}
                value={OrganizationDetail.BillingCity}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Billing State"
                fullWidth
                variant="outlined"
                inputProps={{maxLength:100}}
                onChange={e => this.handleOrgDetailStringChange("BillingState", e.target.value)}
                value={OrganizationDetail.BillingState}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Billing Postal Code"
                fullWidth
                variant="outlined"
                inputProps={{maxLength:30}}
                onChange={e => this.handleOrgDetailStringChange("BillingCode", e.target.value)}
                value={OrganizationDetail.BillingCode}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Billing Country"
                fullWidth
                variant="outlined"
                inputProps={{maxLength:100}}
                onChange={e => this.handleOrgDetailStringChange("BillingCountry", e.target.value)}
                value={OrganizationDetail.BillingCountry}
              />
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained"
                color="secondary"
                onClick={() => this.handleUpdateServer()}
              >
                UPDATE
              </Button>
            </Grid>
          </Grid>

          {progressIndicator}
        </Container>
      )
      : null;

    return content;
  }
}

OrganizationProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  onApiError: PropTypes.func.isRequired,
  onAlert: PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(OrganizationProfile);
