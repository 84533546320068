import React from 'react';
import { DragLayer } from 'react-dnd';
import PropTypes from 'prop-types';
import DraggableFormTemplateField from './DraggableFormTemplateField';
import {
  GetMinFieldWidth,
} from '../../Util/FormTemplateFields';

function collect(monitor, props) {
  return {
    getRelativePosition: props.getRelativePosition,
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    clientOffset: monitor.getClientOffset(),
    initialClientOffset: monitor.getInitialSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }
}

function FormTemplateFieldDragLayer(
  {
    organizationId,
    projectId,
    getRelativePosition,
    item,
    itemType,
    clientOffset,
    initialClientOffset,
    isDragging,
    fields,
  }) {
  if (!item
    || !(item.Data || item.FormTemplateField)
    // || itemType !== "DraggableListItem"
    || !clientOffset
    || !isDragging) {
    return null;
  }

  let pos = getRelativePosition(clientOffset, initialClientOffset, true, false, false);
  let formTemplateField = item.Data || item.FormTemplateField;

  let fieldWidth = 0;
  let useMaxContent = false;
  const minWidth = GetMinFieldWidth(formTemplateField);
  switch (formTemplateField.Type) {
    case "Label":
    // case "CheckBox":
    // case "RadioGroup":
      useMaxContent = true;
    break;
    default:
      fieldWidth = formTemplateField.Width;
      if (!fieldWidth || fieldWidth < minWidth) {
        fieldWidth = minWidth;
      }
    break;
  }

  return (
    <div style={{
      position:"absolute",
      pointerEvents:"none",
      width:(useMaxContent) ? "max-content" : `${fieldWidth*100}%`,
      height:"max-content",
      left:pos.x,
      top:pos.y,
      zIndex:2,
    }}>
      <DraggableFormTemplateField
        organizationId={organizationId}
        projectId={projectId}
        FormTemplateField={formTemplateField}
        minWidth={minWidth}
        isDragLayer
        onApiError={() => {}}
        onAlert={() => {}}
        fields={fields}
      />
    </div>
  );
}
FormTemplateFieldDragLayer.propTypes = {
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
}

export default DragLayer(collect)(FormTemplateFieldDragLayer);
