import API, {
  GetTaskStatesPathForApi,
  GetUserOrganizationProjectTaskStatesPathForApi,
  GetUserOrganizationProjectApprovalTaskStatesPathForApi,
} from './api';

export const GetTaskStatesPromise = (organizationId, projectId, taskId, getAll, 
  isTaskAssignment, isApprovalTaskAssignment, approvalId, sortByIndex) => {
  const params = {
    getAll,
    sortType: (sortByIndex) ? "Index" : undefined,
  }
  const uri = (isApprovalTaskAssignment)
    ? GetUserOrganizationProjectApprovalTaskStatesPathForApi(organizationId, projectId, approvalId, taskId)
    : (isTaskAssignment)
      ? GetUserOrganizationProjectTaskStatesPathForApi(organizationId, projectId, taskId)
      : GetTaskStatesPathForApi(organizationId, projectId);
  return API.get(uri, { params })
    .then(resp => {
      if (resp && resp.data) {
        return resp.data;
      } else {
        return [];
      }
    });
}

export const DeleteTaskStates = (organizationId, projectId, IDs) => {
  return API.delete(GetTaskStatesPathForApi(organizationId, projectId), { data: { IDs } });
}