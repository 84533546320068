import React from 'react';
import Button from '@material-ui/core/Button';
// import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import InfoIcon from '@material-ui/icons/Info';

import SelectControl from './SelectControl'

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import FieldType from '../Model/FieldType';
import { CustomMaskValue } from '../Model/Field';
import { 
  GetFieldMaskOptions,
  GetFieldTextMatchTypeOptions,
  GetDependentFieldParentFieldOptions,
  IsAllowNewSelectionListItemsPossiblyAllowed,
  GetEffectiveAllowNewSelectionListItems,
  IsSaveNewSelectionListItemsPossiblyAllowed,
  GetEffectiveSaveNewSelectionListItems,
  IsAllowMultipleValuesPossiblyAllowed,
  GetEffectiveAllowMultipleValues,
  IsAParentField
} from '../Util/Field';
import {
  GetTagsControl,
  GetTagListValuesFromTagsObject,
  GetTagsAndAssetItemTagsFromTagListValues,
} from '../Util/Tags';

const styles = theme => ({
  popoverPaper: {
    padding: theme.spacing(2),
  },
});

class FieldPropertyGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ShowSelectionListItemsUploadedPopover: false,
      DocumentTagListValues: GetTagListValuesFromTagsObject(this.props.Field, "Document"),
    }

    this.UploadSelectionListItemsGridRef = React.createRef();
  }

  handleStringChange = (propertyName, string) => {
    this.props.onChangeFieldProperty(f => f[propertyName] = string);
  }

  handleIntegerChange = (propertyName, integer) => {
    this.props.onChangeFieldProperty(f => f[propertyName] = integer);
  }

  handleBoolChange = (propertyName, bool, postPropertySetCallback) => {
   this.props.onChangeFieldProperty(f => {
    f[propertyName] = bool;
    postPropertySetCallback?.(f);
   });
  }

  handleMaskTypeChange(mask) {
    this.props.onChangeFieldProperty(f => {
      f.MaskType = mask;
      if (mask !== CustomMaskValue) {
        f.Mask = mask;
      }
    });
  }

  handleTextMatchTypeChange(matchType) {
    this.props.onChangeFieldProperty(f => {
      f.TextMatchType = matchType;
    });
  }

  handleUploadSelectionListItems = e => {
    this.props.onUploadSelectionListItems(e);
  }

  handleSetListItemsUploadPopoverVisibility = isVisible => {
    this.setState({
      ShowSelectionListItemsUploadedPopover: isVisible,
    });
  }

  handleDocumentTagListValuesChanged = tagListValues => {
    const {
      Tags,
    } = GetTagsAndAssetItemTagsFromTagListValues(tagListValues);
    this.props.onChangeFieldProperty(f => f["DocumentTags"] = Tags);
  }

  handleApiError = err => {
    this.props.onApiError(err);
  }

  componentDidMount() {
    if (this.props.onSetSelectionListItemsUploadedPopoverVisibilityFunction) {
      this.props.onSetSelectionListItemsUploadedPopoverVisibilityFunction(
        this.handleSetListItemsUploadPopoverVisibility
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showSelectionListItemsUploadedPopover !== this.props.showSelectionListItemsUploadedPopover) {
      this.handleSetListItemsUploadPopoverVisibility(this.props.showSelectionListItemsUploadedPopover);
    }

    if (prevProps.Field.ID !== this.props.Field.ID) {
      this.setState({DocumentTagListValues:GetTagListValuesFromTagsObject(this.props.Field, "Document")});
    }
  }

  render() {
    const {
      classes,
      theme,
      dense,
      spacing,
      allowCreationDate,
      organizationId,
      projectId,
      Field,
      FieldID,
      Fields,
      hideTypeComponent,
      hideListItemsComponent,
      onDownloadSelectionListItems,
      formTemplateFieldType,
      hideDocumentTags,
    } = this.props;
    const {
      ShowSelectionListItemsUploadedPopover,
      DocumentTagListValues,
    } = this.state;

    let fieldNameGridItem = (
      <Grid item xs={(dense) ? 4 : undefined}>
        <TextField
          variant="outlined"
          label="Field name"
          value={Field.Name}
          onChange={event => this.handleStringChange("Name", event.target.value)}
          // InputLabelProps={{ shrink: true, }}
          fullWidth
        />
      </Grid>
    );
    let fieldLabelGridItem = (
      <Grid item xs={(dense) ? 4 : undefined}>
        <TextField
          variant="outlined"
          label="Label (optional)"
          value={(Field.Label !== undefined) ? Field.Label : ""}
          onChange={event => this.handleStringChange("Label", event.target.value)}
          // InputLabelProps={{ shrink: true, }}
          fullWidth
        />
      </Grid>
    );
    const hideLabelGridItem = (
      <Grid item xs={(dense) ? 4 : undefined}>
        <FormControlLabel
          control={<Switch checked={Boolean(Field.HideLabel)}
          color="secondary"
          onChange={event => this.handleBoolChange("HideLabel", event.target.checked)} />}
          label="Hide label" />
      </Grid>
    );
    const readOnlyGridItem = (
      <Grid item xs={(dense) ? 4 : undefined}>
        <FormControlLabel
          control={<Switch checked={Boolean(Field.ReadOnly)}
          color="secondary"
          onChange={event => this.handleBoolChange("ReadOnly", event.target.checked)} />}
          label="Read-only" />
      </Grid>
    );

    let fieldTypeGridItem = null;
    if (!hideTypeComponent) {
      const fieldTypeOptions = FieldType.map(f => ({ label: f.Label, value: f.Type }));
      
      let confirmationMessageBeforeValueChange = "";
      // If other fields depend on this field then changing it from a Text field will cause
      // those fields to no longer be able to depend on this field, so, warn the user
      if ((Field.Type === "FieldType_Text") && IsAParentField(Field)) {
        confirmationMessageBeforeValueChange = "Other fields depend on this Text field."
          + "  If its type is changed, those fields will be disconnected from this field."
          + "\nContinue?";
      }

      fieldTypeGridItem = (
        <Grid item xs={(dense) ? 4 : undefined}>
          <SelectControl
            id="select_fieldType"
            label="Data type"
            forceShrinkLabel
            hideEmpty
            options={fieldTypeOptions}
            value={Field.Type}
            confirmationMessageBeforeValueChange={confirmationMessageBeforeValueChange}
            onValueChange={(type) => this.handleStringChange("Type", type)} />
        </Grid>
      );
    }

    let fieldSettingsGridItems = [];
    switch (Field.Type) {
      case "FieldType_Text":
        if (!Field.DisplaySelectionList
          && !Field.AllowMultipleLines
          && !Field.Mask) {
          fieldSettingsGridItems.push(
            <Grid key="textMatchType" item xs={(dense) ? 4 : undefined}>
              <SelectControl
                id="select_textMatchType"
                label="Match type" 
                options={GetFieldTextMatchTypeOptions()} 
                value={Field.TextMatchType}
                onValueChange={(mask) => this.handleTextMatchTypeChange(mask)} />
            </Grid>
          );
        }

        if (!Field.DisplaySelectionList 
          && !Field.AllowMultipleLines
          && !Field.TextMatchType) {
          fieldSettingsGridItems.push(
            <Grid key="maskType" item xs={(dense) ? 4 : undefined}>
              <SelectControl
                id="select_mask"
                label="Mask" 
                options={GetFieldMaskOptions()} 
                value={Field.MaskType}
                onValueChange={(mask) => this.handleMaskTypeChange(mask)} />
            </Grid>
          );
          if (Field.MaskType === CustomMaskValue) {
            const maskInstructions = [
              "& Any keyboard character",
              "C Any keyboard character (optional)",
              "L Letter",
              "? Letter (optional)",
              "0 Digit",
              "9 Digit (optional)",
              "A Letter or digit",
              "a Letter or digit (optional)",
              "> Uppercase following characters",
              "< Lowercase following characters",
              "\\ Character literal",
            ];
            const maskInstructionsGrid = (
              <Grid container direction="column">
                {maskInstructions.map(i => <Grid item key={`i_${maskInstructions.indexOf(i)}`}>{i}</Grid>)}
              </Grid>
            );
            fieldSettingsGridItems.push(
              <Grid key="customMask" item xs={12}
                style={{
                  display:"flex",
                  alignItems:"center",
                }}
              >
                <TextField
                  variant="outlined"
                  label="Custom mask"
                  value={Field.Mask}
                  onChange={event => this.handleStringChange("Mask", event.target.value)}
                  fullWidth
                />  
                <Tooltip title={maskInstructionsGrid}>
                  <InfoIcon style={{
                    color:"#888",
                    marginLeft:theme.spacing(2),
                  }} />
                </Tooltip>
              </Grid>
            );
          }
        }

        if (!Field.AllowMultipleLines
          && !Field.Mask
          && !Field.TextMatchType) {
          if (!hideListItemsComponent) {
            fieldSettingsGridItems.push(
              <Grid key="displaySelectionList" item xs={(dense) ? 4 : undefined}>
                <FormControlLabel
                  control={<Switch checked={Field.DisplaySelectionList}
                  color="secondary"
                  onChange={event => this.handleBoolChange("DisplaySelectionList", event.target.checked)} />}
                  label="List items" />
              </Grid>
            );
          }

          if (Field.DisplaySelectionList) {
            fieldSettingsGridItems.push(
              <Grid key="selectionListIsDependent" item xs={(dense) ? 4 : undefined}>
                <FormControlLabel
                  control={
                    <Switch checked={Field.SelectionListIsDependent}
                      color="secondary"
                      onChange={event => this.handleSelectionListIsDependentChange(event.target.checked)}
                    />
                  }
                  label="Dependent items" />
              </Grid>
            );
          }

          if (Field.DisplaySelectionList && Field.SelectionListIsDependent && Fields) {
            const isForForms = !!formTemplateFieldType;
            fieldSettingsGridItems.push(
              <Grid key="dependentFieldParentField" item xs={(dense) ? 4 : undefined}>
                <SelectControl
                  id="select_dependentFieldParentField"
                  label="Parent field"
                  forceShrinkLabel
                  options={GetDependentFieldParentFieldOptions(FieldID, Fields, isForForms, formTemplateFieldType)}
                  value={(Field.ParentFieldID !== undefined) ? Field.ParentFieldID : ""}
                  onValueChange={(type) => this.handleStringChange("ParentFieldID", type)} />
              </Grid>
            );
          }
        }

        if (!Field.DisplaySelectionList
          && !Field.TextMatchType
          && !Field.Mask) {
          fieldSettingsGridItems.push(
            <Grid key="allowMultipleLines" item xs={(dense) ? 4 : undefined}>
              <FormControlLabel
                control={<Switch checked={Field.AllowMultipleLines}
                          color="secondary"
                          onChange={event => this.handleBoolChange("AllowMultipleLines", event.target.checked)} />}
                label="Multiline" />
            </Grid>
          );
          if (Field.AllowMultipleLines) {
            let rowOptions = [];
            for (let i = 1; i <= 25; i++) {
              rowOptions.push({label:i.toString(),value:i.toString()});
            }
            fieldSettingsGridItems.push(
              <Grid key="rows" item xs={(dense) ? 4 : undefined}>
                <SelectControl
                  id="select_rows"
                  label="Rows to display" 
                  forceShrinkLabel
                  options={rowOptions} 
                  value={Field.Rows.toString()}
                  hideEmpty
                  onValueChange={(value) => this.handleIntegerChange("Rows", parseInt(value, 10))} />
              </Grid>
            );
          }
        }

        if (Field.DisplaySelectionList) {
          if (IsAllowNewSelectionListItemsPossiblyAllowed(Field)) {
            const effectiveAllowNewSelectionListItems = GetEffectiveAllowNewSelectionListItems(Field);
            fieldSettingsGridItems.push(
              <Grid key="allowNewSelectionListItems" item xs={(dense) ? 4 : undefined}>
                <FormControlLabel
                  control={<Switch checked={effectiveAllowNewSelectionListItems}
                            color="secondary"
                            onChange={event => this.handleBoolChange("AllowNewSelectionListItems", event.target.checked)} />}
                  label="Allow new" />
              </Grid>
            );

            if (IsSaveNewSelectionListItemsPossiblyAllowed(Field)) {
              const effectiveSaveNewSelectionListItems = GetEffectiveSaveNewSelectionListItems(Field);
              fieldSettingsGridItems.push(
                <Grid key="saveNewSelectionListItems" item xs={(dense) ? 4 : undefined}>
                  <FormControlLabel
                    control={<Switch checked={effectiveSaveNewSelectionListItems}
                              color="secondary"
                              onChange={event => this.handleBoolChange("SaveNewSelectionListItems", event.target.checked)} />}
                    label="Save new" />
                </Grid>
              );
            }
          }
          
          if (IsAllowMultipleValuesPossiblyAllowed(Field)) {
            const effectiveAllowMultipleValues = GetEffectiveAllowMultipleValues(Field);
            fieldSettingsGridItems.push(
              <Grid key="allowMultipleValues" item xs={(dense) ? 4 : undefined}>
                <FormControlLabel
                  control={<Switch checked={effectiveAllowMultipleValues}
                              color="secondary"
                              onChange={event => this.handleBoolChange("AllowMultipleValues", event.target.checked)} />}
                  label="Allow multiple values" />
              </Grid>
            );
          }

          const uploadItemsText = 'Replace items by uploading a text file, one item per line'
            + (Field.SelectionListIsDependent
              ? ', formatted \'parent item,item\'.'
              : '.');
          fieldSettingsGridItems.push(
            <Grid key="uploadSelectionListItemsMessage" item xs={(dense) ? 6 : undefined}>
              <Typography>
                {uploadItemsText}
              </Typography>
            </Grid>
          );
          fieldSettingsGridItems.push(
            <Grid key="uploadSelectionListItems" item xs={(dense) ? 3 : undefined}
              ref={instance => this.UploadSelectionListItemsGridRef = instance}>
              <input
                accept=".txt,.csv"
                // className={classes.input}
                style={{display:"none"}}
                id="buttonFile"
                // multiple
                type="file"
                onChange={this.handleUploadSelectionListItems}
              />
              <label htmlFor="buttonFile">
                <Button variant="contained" component="span" 
                  fullWidth
                >
                  UPLOAD
                </Button>
              </label>
              <Popover
                open={ShowSelectionListItemsUploadedPopover}
                anchorEl={
                  (this.UploadSelectionListItemsGridRef && !this.UploadSelectionListItemsGridRef.hasOwnProperty('current'))
                    ? this.UploadSelectionListItemsGridRef
                    : undefined
                  }
                onClose={() => this.handleSetListItemsUploadPopoverVisibility(false)}
                anchorOrigin={{
                  vertical:'bottom',
                  horizontal:'center',
                }}
                transformOrigin={{
                  vertical:'top',
                  horizontal:'center',
                }}
                classes={{
                  paper: classes.popoverPaper,
                }}
              >
                Items uploaded successfully
              </Popover>
            </Grid>
          );
          fieldSettingsGridItems.push(
            <Grid key="downloadSelectionListItems" item xs={(dense) ? 3 : undefined}>
              <Button variant="contained" component="span"
                fullWidth
                onClick={onDownloadSelectionListItems}
              >
                DOWNLOAD
              </Button>
            </Grid>
          );
        }
        break;
      
      case "FieldType_Number":
        let decimalPlacesOptions = [];
        for (let i = 0; i <= 10; i++) {
          decimalPlacesOptions.push({label:i.toString(),value:i.toString()});
        }
        fieldSettingsGridItems.push(
          <Grid key="decimalPlaces" item xs={(dense) ? 3 : undefined}>
            <SelectControl
              id="select_decimalPlaces"
              label="Decimal places" 
              forceShrinkLabel
              options={decimalPlacesOptions} 
              value={Field.DecimalPlaces.toString()}
              hideEmpty
              onValueChange={(value) => this.handleIntegerChange("DecimalPlaces", parseInt(value, 10))} />
          </Grid>
        );

        break;
      case "FieldType_Currency":
        break;
      case "FieldType_Date":
        if (allowCreationDate) {
          fieldSettingsGridItems.push(
            <Grid key="useCreationDate" item xs={(dense) ? 4 : undefined}>
              <FormControlLabel
                control={<Switch checked={Field.UseCreationDate}
                color="secondary"
                onChange={event => this.handleBoolChange("UseCreationDate", event.target.checked)} />}
                label="Creation date" />
            </Grid>
          );
        }
        break;
      default:
        break;
    }

    fieldSettingsGridItems.push(
      <Grid key="required" item xs={(dense) ? 4 : undefined}>
        <FormControlLabel
          control={
            <Switch checked={Field.Required}
              color="secondary"
              onChange={event => this.handleBoolChange("Required", event.target.checked)} />
          }
          label="Required" />
      </Grid>
    );
   
    // fieldSettingsGridItems.push(
    //   <Grid key="sensitive" item xs={(dense) ? 4 : undefined}>
    //     <FormControlLabel
    //       control={<Switch checked={(Field.Sensitive !== undefined) ? Field.Sensitive : false}
    //                 color="secondary"
    //                 onChange={event => this.handleBoolChange("Sensitive", event.target.checked)} />}
    //                 label="Sensitive" />
    //   </Grid>
    // );

    if (!hideDocumentTags) {
      fieldSettingsGridItems.push(
        <Grid key="documentTags" item xs={12}>
          {
            GetTagsControl(organizationId, projectId, null, 
              false, false, null, false,
              DocumentTagListValues,
              DocumentTagListValues => this.setState({DocumentTagListValues}),
              this.handleDocumentTagListValuesChanged,
              false, -1, "Document tags", false,
            )
          }
        </Grid>
      );
    }

    return (dense)
      ? (
        <Grid container direction="column" spacing={2} 
          style={{
            marginTop:theme.spacing(1),
          }}>
          <Grid item>
            <Grid container spacing={2}
              style={{
                alignItems: "center",
              }}>
              {fieldNameGridItem}
              {fieldLabelGridItem}
              {fieldTypeGridItem}  
            </Grid>
          </Grid>
        
          <Grid item>
            <Grid container spacing={2}
              style={{
                alignItems: "center",
              }}>
              {hideLabelGridItem}
              {readOnlyGridItem}
              {fieldSettingsGridItems}
            </Grid>
          </Grid>
        </Grid>
      )
      : (
        <Grid container direction="column" spacing={spacing || 3}>
          {fieldNameGridItem}
          {fieldLabelGridItem}
          {hideLabelGridItem}
          {readOnlyGridItem}
          {fieldTypeGridItem}  
          {fieldSettingsGridItems}
        </Grid>
      );
  }

  handleSelectionListIsDependentChange(value) {
    this.handleBoolChange("SelectionListIsDependent", value,
      f => {
        // If this is a dependent field, disable any dependent properties.  This logic is also
        // present in the Data layer.
        if (value) {
          f.AllowMultipleValues = false;
          f.AllowNewSelectionListItems = false;
          f.SaveNewSelectionListItems = false;
        }
      });
  }

  handleDisplaySelectionListChange(value) {
    return this.handleBoolChange("DisplaySelectionList", value);
  }
}

FieldPropertyGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  Field: PropTypes.object.isRequired,
  allowCreationDate: PropTypes.bool,
  spacing: PropTypes.number,
  hideDocumentTags: PropTypes.bool,
  onChangeFieldProperty: PropTypes.func.isRequired,
  onDownloadSelectionListItems: PropTypes.func.isRequired,
  onUploadSelectionListItems: PropTypes.func.isRequired,
  showSelectionListItemsUploadedPopover: PropTypes.bool,
  onSetSelectionListItemsUploadedPopoverVisibilityFunction: PropTypes.func,
  onShowProgressIndicatorImmediately: PropTypes.func.isRequired,
  onApiError: PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(FieldPropertyGrid);
