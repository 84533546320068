import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Card from '../../Components/Card';

import {
  GetDateValue,
} from '../../Util/Properties';

class ErrorEventCard extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
    }
  }

  componentDidMount() {
  }

  render() {
    const { 
      ErrorEvent,
    } = this.props;

    let metadata = [
      ["ID", ErrorEvent.ID],
      ["Service", ErrorEvent.ServiceNameLower],
      ["Level", ErrorEvent.Level],
      ["Request URI", ErrorEvent.RequestURI],
      ["Request Method", ErrorEvent.RequestMethod],
    ];
    if (ErrorEvent.OrganizationName) {
      metadata.push(["Organization", ErrorEvent.OrganizationName]);
    }
    if (ErrorEvent.OrganizationID) {
      metadata.push(["Organization ID", ErrorEvent.OrganizationID]);
    }
    if (ErrorEvent.ProjectName) {
      metadata.push(["Project", ErrorEvent.ProjectName]);
    }
    if (ErrorEvent.ProjectID) {
      metadata.push(["Project ID", ErrorEvent.ProjectID]);
    }
    if (ErrorEvent.UserEmailLower) {
      metadata.push(["User Email", ErrorEvent.UserEmailLower]);
    }
    if (ErrorEvent.UserID) {
      metadata.push(["User ID", ErrorEvent.UserID]);
    }
    metadata.push(["Unique ID", ErrorEvent.UniqueID]);
    if (ErrorEvent.Header) {
      metadata.push(["Header", ErrorEvent.Header]);
    }
    if (ErrorEvent.Message) {
      metadata.push(["Message", ErrorEvent.Message]);
    }
    if (ErrorEvent.ClientMessage) {
      metadata.push(["Client Message", ErrorEvent.ClientMessage]);
    }
    if (ErrorEvent.OtherValues && ErrorEvent.OtherValues.length) {
      for (let i = 0; i < ErrorEvent.OtherValues.length; i++) {
        metadata.push([`Other ${1+i}`, ErrorEvent.OtherValues[i]]);
      };        
    }

    return (
      <Card
        name={GetDateValue(ErrorEvent.CreatedOn)}
        metadata={metadata}
      />
    );
  }
}

ErrorEventCard.propTypes = {
  ErrorEvent: PropTypes.object.isRequired,
};

export default ErrorEventCard;