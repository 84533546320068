import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import Collection from '../Model/Collection';

import ItemTableRow from '../Components/ItemTableRow';
import ProcessCard from '../Admin/Screens/Workflow/ProcessCard';
import ProcessCollectionFields from '../Model/ProcessCollectionFields';

import ProcessCreationDialog from '../Components/ProcessCreationDialog';

import {
  GetProcessesPathForApi,
} from '../Util/api';

import {
  GetDateValue,
} from '../Util/Properties';
import {
  HandleRouteToProcessDesigner,
} from '../Util/Processes';

export class Processes_Collection extends Collection {
  constructor(props, onSetState, onApiError, isWorkspace, isProjects, 
    organizationId, projectId) {
    
    if (typeof organizationId !== "string") {
      console.log("Warning: organizationId required and not found");
    }

    super(props, onSetState, onApiError, isWorkspace, isProjects, organizationId, projectId);

    this.PageTitle = "Workflow Processes";
    this.ContentUri = GetProcessesPathForApi(organizationId, projectId);
    this.CollectionName = "Processes";
    this.ItemsName = "Processes";
    this.ItemName = "Process";
    this.DefaultViewType = "Card";
  }

  HandleGetCollectionFieldsPromise = () => Promise.resolve(ProcessCollectionFields);

  HandleGetHeadCells = (items, sensitiveFields) => {
    let headCells = [
      { id: 'Name', sortId: 'Meta_text_kw256lc[Name].keyword', numeric: false, label: 'Name' },
    ];
    if (!this.ProjectID) {
      headCells.push(
        { id: 'ProjectName', numeric: false, label: "Project" },
      );
    }
    headCells.push(
      { id: 'CreatedOn', sortId: 'Meta_date_str256[CreatedOn].string', numeric: false, label: 'Created On' },
    );
    return headCells;
  }

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length) 
      ? items
        .map(i => {
          return (
            <Grid item key={i.ID} sm={12} md={6} lg={4} xl={3} className={classes.cardGridItem}>
              <ProcessCard
                Process={i}
                onCardAction={() => HandleRouteToProcessDesigner(this.props, this.OrganizationID, this.ProjectID, i.ID)}
              />
            </Grid>
          );
        }) 
      : [];
  }

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length)
      ? items
        .map(i => {
          const projectNameCell = (!this.ProjectID)
            ? <TableCell className={classes.tableCell}>{i.ProjectName}</TableCell>
            : null;
          return (
            <ItemTableRow key={`k_${i.ID}`}
              onItemClick={() => HandleRouteToProcessDesigner(this.props, this.OrganizationID, this.ProjectID, i.ID)}
            >
              <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
                <Checkbox
                  style={{
                    visibility: "hidden",
                  }}
                  color="secondary"
                />
              </TableCell>
              <TableCell className={classes.tableCell} component="th" id={`label_${i.ID}`} scope="row" padding="none">
                {i.Name}
              </TableCell>
              {projectNameCell}
              <TableCell className={classes.tableCell}>{GetDateValue(i.CreatedOn)}</TableCell>
            </ItemTableRow>
          );
        }) 
      : [];
  }

  HandleCreateNew = () => {
    this.setState({
      AddProcessDialogIsOpen: true,
    });
  }

  HandleGetDialogContent = state => {
    if (state.AddProcessDialogIsOpen) {
      return (
        <ProcessCreationDialog
          organizationId={this.OrganizationID}
          projectId={this.ProjectID}
          // projectName={projectName}
          open={state.AddProcessDialogIsOpen || false}
          onCreated={this.handleProcessCreated}
          onClose={this.handleAddProcessDialogClosed}
          onApiError={this.handleApiError}
        />
      );
    }
  }

  handleAddProcessDialogClosed = () => {
    this.setState({
      AddProcessDialogIsOpen: false,
    })
  }

  handleProcessCreated = process => {
    this.handleAddProcessDialogClosed();
    if (!process) {
      return;
    }

    HandleRouteToProcessDesigner(this.props, this.OrganizationID, this.ProjectID, process.ID);
  }
}