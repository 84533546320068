import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import Collection from '../Model/Collection';

import ItemTableRow from '../Components/ItemTableRow';
import TaskStateCard from '../Components/TaskStateCard';
import TaskStateCollectionFields from '../Model/TaskStateCollectionFields';

import MultiUseDialog from '../Components/MultiUseDialog';
import TaskStateCreationDialog from '../Components/TaskStateCreationDialog';

import API from '../Util/api';

import {
  GetTaskStatesPathForApi,
  GetTaskStatePathForApi,
} from '../Util/api';
import {
  GetDateValue,
  GetUserValue,
} from '../Util/Properties';
import { 
  GetProjectTaskStatesPath,
  GetProjectTaskStatePath,
} from '../Util/PathHelper';

export class TaskStates_Collection extends Collection {
  constructor(props, onSetState, onApiError, isWorkspace, isProjects, organizationId, projectId, 
    onItemTitleChange) {
    
    if (typeof organizationId !== "string") {
      console.log("Warning: organizationId required and not found");
    }
    if (typeof projectId !== "string") {
      console.log("Warning: projectId required and not found");
    }
    super(props, onSetState, onApiError, isWorkspace, isProjects, organizationId, projectId);

    this.handleItemTitleChange = onItemTitleChange;

    this.PageTitle = "Task States";
    this.ContentUri = GetTaskStatesPathForApi(organizationId, projectId);
    this.CollectionName = "TaskStates";
    this.ItemsName = "TaskStates";
    this.ItemName = "Task State";
    this.AllowSelect = true;
    this.DefaultViewType = "List";
    this.TaskStatesForDialog = [];

    this.HideFilters = true;
  }

  HandleGetCollectionFieldsPromise = () => Promise.resolve(TaskStateCollectionFields);

  HandleGetHeadCells = (items, sensitiveFields) => {
    let headCells = [
      { id: 'Name', sortId: 'NameLower', numeric: false, label: 'Name' },
      { id: 'CreatedOn', sortId: 'CreatedOn', numeric: false, label: 'Created On' },
      { id: 'CreatedByUserEmail', sortId: 'CreatedByUserEmail', numeric: false, label: 'Created By' },
      { id: 'Index', sortId: 'Index', numeric: true, label: 'Position' },
    ];
    if (!this.ProjectID) {
      headCells.push(
        { id: 'ProjectName', numeric: false, label: "Project" },
      );
    }
    return headCells;
  }

  handleRouteToTaskStateDialog = taskStateId => {
    this.props.history.push(GetProjectTaskStatePath(this.ProjectID, taskStateId), 
      { ...this.props.location.state });
  }

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length) 
      ? items
        .map(i => {
          return (
            <Grid item key={i.ID} sm={12} md={6} lg={4} xl={3} className={classes.cardGridItem}>
              <TaskStateCard
                TaskState={i}
                onCardAction={() => this.handleRouteToTaskStateDialog(i.ID)}
                onSelect={() => onSelect(i.ID)}
                selected={selectedIDs.indexOf(i.ID) > -1}
              />
            </Grid>
          );
        }) 
      : [];
  }

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length)
      ? items
        .map(i => {
          const selected = selectedIDs.indexOf(i.ID) !== -1;
          const projectNameCell = (!this.ProjectID)
            ? <TableCell className={classes.tableCell}>{i.ProjectName}</TableCell>
            : null;
          return (
            <ItemTableRow key={`k_${i.ID}`}
              onSelect={() => onSelect(i.ID)}
              selected={selected}
              onItemClick={() => this.handleRouteToTaskStateDialog(i.ID)}
            >
              <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
                <Checkbox
                  color="secondary"
                  onClick={e => { e.stopPropagation(); onSelect(i.ID); }}
                  checked={selected}
                />
              </TableCell>
              <TableCell className={classes.tableCell} component="th" id={`label_${i.ID}`} scope="row" padding="none">
                {i.Name}
              </TableCell>
              <TableCell className={classes.tableCell}>{GetDateValue(i.CreatedOn)}</TableCell>
              <TableCell className={classes.tableCell}>{GetUserValue(i.CreatedByUserEmail)}</TableCell>
              <TableCell className={classes.tableCell} align="right">{1 + i.Index}</TableCell>
              {projectNameCell}
            </ItemTableRow>
          );
        }) 
      : [];
  }

  HandleItemsChanged = items => {
    this.TaskStatesForDialog = items || [];
    this.handleTryDisplayTaskStateDialogIfApplicable(null, items, true);
  }

  handleTryDisplayTaskStateDialogIfApplicable = (taskStateId_optional, taskStates, fromItemsChanged) => {
    const taskStateId = (taskStateId_optional) 
      ? taskStateId_optional
      : this.props.match.params.collectionItemID;
    if (taskStateId) {
      const displayDialog = (taskState, extraState) => {
        this.setState({
          TaskStateForTaskStateDialog: taskState,
          TaskStatePropertiesDialogIsOpen: true,
          ...extraState,
        });
        if (this.handleItemTitleChange) {
          this.handleItemTitleChange(taskState.Name);
        }
      }
      if (!taskStates) {
        taskStates = [...this.TaskStatesForDialog];
      }
      const taskStateFinder = taskStates.filter(f => f.ID === taskStateId);
      if (this.isWorkspace && taskStateFinder.length) {
        displayDialog(taskStateFinder[0]);
      }
      if (fromItemsChanged) {
        if (taskStateFinder.length) {
          displayDialog(taskStateFinder[0]);
        }
      } else {
        API.get(GetTaskStatePathForApi(this.OrganizationID, this.ProjectID, taskStateId))
          .then(resp => {
            displayDialog(resp.data, {
              ForcePrependItems: [resp.data],
            });
          })
          .catch(err => {
            this.handleApiError(err);
            this.handleTaskStatePropertiesDialogClosed();
          });
      }
    }
  }

  HandleCreateNew = () => {
    this.setState({
      AddTaskStateDialogIsOpen: true,
    });
  }

  HandleGetDialogContent = state => {
    if (state.AddTaskStateDialogIsOpen) {
      return (
        <TaskStateCreationDialog
          organizationId={this.OrganizationID}
          projectId={this.ProjectID}
          open={state.AddTaskStateDialogIsOpen || false}
          onCreated={this.handleTaskStateCreated}
          onClose={this.handleAddTaskStateDialogClosed}
          onApiError={this.handleApiError}
        />
      );
    } else if (state.TaskStatePropertiesDialogIsOpen) {
      return (
        <MultiUseDialog
          Details={{
            Open: state.TaskStatePropertiesDialogIsOpen,
            Title: "Update task state",
            RequireTextInput1: true,
            ShowProgressIndicatorImmediately: state.ShowDialogProgressIndicatorImmediately || false,
            TextInput1Label: "Name",
            TextInput1DefaultValue: state.TaskStateForTaskStateDialog.Name,
            ConfirmLabel: "UPDATE",
            ConfirmCallback: name => this.handleUpdateTaskStateName(state.TaskStateForTaskStateDialog, name),
            CancelCallback: () => this.handleTaskStatePropertiesDialogClosed(),
            CloseCallback: () => this.handleTaskStatePropertiesDialogClosed(),
          }}
        />
      );
    }
  }

  handleUpdateTaskStateName = (taskState, name) => {
    if (!taskState || !name) {
      return;
    }

    this.setState({ShowDialogProgressIndicatorImmediately: true});
    taskState.Name = name;
    API.put(GetTaskStatePathForApi(this.OrganizationID, this.ProjectID, taskState.ID), taskState)
      .then(resp => {
        this.setState({
          ShowDialogProgressIndicatorImmediately: false,
        });
        this.HandleItemRevised(taskState);
        this.handleTaskStatePropertiesDialogClosed();
      })
      .catch(this.handleApiError);
  }

  handleAddTaskStateDialogClosed = () => {
    this.setState({
      AddTaskStateDialogIsOpen: false,
    })
  }

  handleTaskStatePropertiesDialogClosed = () => {
    const stateToUpdate = {
      TaskStatePropertiesDialogIsOpen: false,
      ShowDialogProgressIndicatorImmediately: false,
    };
    this.setState(stateToUpdate);

    const newPath = GetProjectTaskStatesPath(this.ProjectID);
    if (this.props.location.pathname !== newPath) {
      this.props.history.push(newPath, { ...this.props.location.state, ...stateToUpdate });
    }
    if (this.handleItemTitleChange) {
      this.handleItemTitleChange(null);
    }
  }

  handleTaskStateCreated = taskState => {
    this.handleAddTaskStateDialogClosed();
    if (!taskState) {
      return;
    }
    this.handleAddTaskStateDialogClosed();
    this.setState({ForcePrependItems:[taskState]});
  }

  // Returns whether further path changes can occur.
  updateProps(props) {
    Collection.prototype.updateProps.bind(this)(props);

    if (this.props.match.params.collectionItemID && !this.prevProps.match.params.collectionItemID) {
      this.handleTryDisplayTaskStateDialogIfApplicable(null, null);
      return false;
    } else if (!this.props.match.params.collectionItemID && this.prevProps.match.params.collectionItemID) {
      this.handleTaskStatePropertiesDialogClosed();
      return false;
    }

    return true;
  }
}