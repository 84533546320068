import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { DropTarget } from 'react-dnd';

import red from '@material-ui/core/colors/red';

/**
 * Specifies the drop target contract.
 * All methods are optional.
 */
const hotspotTarget = {
  drop(props, monitor, component) {
    // if (monitor.didDrop()) {
    //   // If you want, you can check whether some nested
    //   // target already handled drop
    //   return;
    // }

    // Obtain the dragged item
    const source = monitor.getItem();
    props.onDrop(source);

    // You can also do nothing and return a drop result,
    // which will be available as monitor.getDropResult()
    // in the drag source's endDrag() method
    //return { moved: true };
  },
  hover(props, monitor, component) {
    if (!component)
      return null;

    // props.onMove();
  },
}

/**
 * Specifies which props to inject into your component.
 */
function dropCollect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    // canDrop: monitor.canDrop(),
    // itemType: monitor.getItemType()
  };
}

const styles = theme => ({
  paper: {
    width: 64,
    height: 64,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.secondary.main,
  },
});

class HotspotDropTarget extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
    }
  }

  render() {
    const { 
      classes,
      icon,
      connectDropTarget,
      isOver,
    } = this.props;

    return (
      <Paper elevation={4} 
        className={classNames(classes.paper, (this.props.className) ? this.props.className : null)}
        style={{
          backgroundColor: (isOver) ? red[500] : undefined,
        }}
        ref={instance => connectDropTarget(ReactDOM.findDOMNode(instance))}
      >
        {icon}
      </Paper>
    );
  }
}

HotspotDropTarget.propTypes = {
  classes: PropTypes.object.isRequired,
  isOver: PropTypes.bool.isRequired,
};

export default DropTarget(['DashboardCard',], hotspotTarget, dropCollect)(withStyles(styles, {withTheme: true})(HotspotDropTarget));