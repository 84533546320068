import API, {
  GetUserAddressBookItemsPathForApi,
} from './api';

export const GetAddressBookItemsPromise = (organizationId, projectId, includeAddressBookItems,
  includeProjectMembers, includeFields, includeFormTemplateFields, filter) => {

  let params = {
    organizationId,
    projectId,
    includeAddressBookItems,
    includeProjectMembers,
    includeFields,
    includeFormTemplateFields,
  };
  if (filter) {
    params.filter = filter;
  }
  return API.get(GetUserAddressBookItemsPathForApi(), { params })
    .then(resp => {
      return resp.data;
    })
}