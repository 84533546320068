import React, { Component } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height:"100%",
    backgroundColor: theme.palette.background.default,
  },
  appBarTitleContainer: {
    display: "flex",
    flexGrow: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    alignItems: "center",
  },
  content: {
    paddingTop: 64,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56,
    },
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    display:"flex",
  },

});

class NotFound404 extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
    }
  }

  componentDidMount() {
  }

  handleNavigateHome = () => {
    this.props.history.push("/");
  }

  render() {
    // const {
    // } = this.state;
    const {
      classes,
      theme,
    } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="fixed"
          style={{backgroundColor:theme.palette.background.appBar}}
        >
          <Toolbar>
            <div className={classes.appBarTitleContainer}>
              <Typography variant="h6" color="inherit">
                Nucleus One
              </Typography>
            </div>
          </Toolbar>
        </AppBar>

        <div className={classes.content}>
          <Grid container spacing={4} direction="column" style={{textAlign:"center"}}>
            <Grid item>
              <Typography variant="h5" color="inherit">
                Oops! You've requested a page that doesn't exist.
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="contained" color={theme.palette.type === "dark" ? "default" : "secondary"}
                onClick={this.handleNavigateHome}>
                HOME
              </Button>
            </Grid>
          </Grid>
        </div>

      </div>
    );
  }
}

export default withStyles(styles, {withTheme: true})(NotFound404);