import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Tooltip from '@material-ui/core/Tooltip';
// import Checkbox from '@material-ui/core/Checkbox';
import InfoIcon from '@material-ui/icons/Info';

import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { IsMobile } from '../../Util/MobileDetector';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// import API from '../../Util/api';
// import SelectControl from '../../Components/SelectControl';
import FieldInput from '../../Components/FieldInput';
import {
  GetUpdatedFieldObjectForValueChange,
  GetComposedFieldListLabelsAndValues,
  HandleGetFieldListItemsFilterPromise,
  HandleFieldListItemAdd,
  RequiredFieldStyle,
  GetEffectiveAllowNewSelectionListItems,
  GetDependentFieldsParentValue,
  GetEffectiveSelectionListIsDependent,
} from '../../Util/Field';
import {
  HandleGetFormTemplateFieldListItemsFilter,
  HandleFormTemplateFieldListItemAdd,
  FormTemplateFieldControlSizeStyles,
  GetFormTemplateFieldControlSizeClasses,
  GetHideAdornment,
} from '../../Util/FormTemplateFields';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import { RecurseDependentSelectionListsDown } from '../../Util/Fields';

/**
 * Implements the drag source contract.
 */
const formTemplateFieldSource = {
  canDrag(props, monitor) {
    return !IsMobile()
      && !props.disableDragging;
  },
  beginDrag(props, monitor) {
    props.onBeginDrag();
    return {
      FormTemplateField: props.FormTemplateField,
      onDrop: props.onDrop,
      onDrop2: props.onDrop2,
    };
  },
  endDrag(props, monitor, component) {
    props.onEndDrag();
    if (!monitor.didDrop()) {
      // props.onAbortMove();
    }
    // props.onDrop(monitor.getDropResult(), props.FormTemplateField);
  }
};

/**
 * Specifies the props to inject into your component.
 */
function dragCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

const _borderRadius = 4;
const styles = theme => ({
  root: {
    cursor:"grab",
    position:"absolute",
    display:"flex",
    opacity:0.7,
    borderRadius:_borderRadius,
    borderColor:"transparent",
    borderStyle:"solid",
    borderWidth:1,
    "&:hover": {
      borderColor:theme.palette.divider,//"#ddd",
    },
  },
  ...FormTemplateFieldControlSizeStyles,
  dragIndicator: {
    color:"#777",
    marginLeft:2,
    marginRight:2,
    alignSelf:"center",
  },
  horizDragHandle: {
    position:"absolute",
    width:3,
    // borderColor:"red",
    // borderStyle:"solid",
    // borderWidth:0,
    // borderRightWidth:1,
    cursor:"ew-resize",
  },
  imagePlaceholderContainer: {
    display:"flex",
    alignItems:"center",
    height:"100%",
    paddingLeft:theme.spacing(1),
  },
  radioButtonContainer: {
    "& .MuiRadio-root": {
      alignSelf: "flex-start",
    },
  },
  uploadButtonContainer: {
    display:"flex",
    flexDirection: "column",
  },
  uploadRequiredLabel: {
    color:(theme.palette.type === "dark") ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.54)",
    marginTop:2,
    marginLeft:14,
  },
  required: {...RequiredFieldStyle},
});

class DraggableFormTemplateField extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  handleClick = e => {
    if (this.props.onClick) {
      this.props.onClick(e, this.props.FormTemplateField.ID);
    }
  }

  handleMouseDown = e => {
    if (this.props.onMouseDown) {
      this.props.onMouseDown(e, this.props.FormTemplateField.ID);
    }
  }

  handleKeyDown = e => {
    // Added to prevent fields from being moved when text is being entered in label/text/etc
    e.stopPropagation();
  }

  handleDefaultValueChange = formTemplateFieldId => (value, selectedOptions) => {
    const field = GetUpdatedFieldObjectForValueChange(this.props.FormTemplateField.Field, value, selectedOptions);
    
    // Clear any fields that are selection lists and a child of the current field
    this.clearAndReloadDependentFieldValues(field, this.props.FormTemplateFields);
    
    if (this.props.onDefaultValueChange) {
      this.props.onDefaultValueChange(formTemplateFieldId, field.Value, field.Values);
    }
  }

  handleHorizDragHandleMouseDown = formTemplateFieldId => e => {
      if (this.props.onHorizDragHandleMouseDown) {
        this.props.onHorizDragHandleMouseDown(formTemplateFieldId)(e);
      }
  }

  clearAndReloadDependentFieldValues = (field, fields) => {
    if (!document || !field) {
      return;
    }

    let isFirstField = true;
    RecurseDependentSelectionListsDown(field, fields,
      f => {
        if (isFirstField) {
          isFirstField = false;
          return this.props.FormTemplateField.FieldID || this.props.FormTemplateField.ID;
        }
        return (f.FieldID) ? f.FieldID : f.ID;
      },
      f => f.Field.ParentFieldID,
      f => {
        if (!GetEffectiveSelectionListIsDependent(f.Field)) {
          return;
        }

        if (this.props.onDefaultValueChange) {
          this.props.onDefaultValueChange(f.ID, null, []);
        }

        // Cause the selection list to be reloaded
        f.Field.UpdateId = new Date();
      },
    );
  }

  componentDidMount() {
    if (this.props.hideDragPreview) {
      const { connectDragPreview } = this.props;
      if (connectDragPreview) {
        // Use empty image as a drag preview so browsers don't draw it
        // and we can draw whatever we want on the custom drag layer instead.
        connectDragPreview(getEmptyImage(), {
          // IE fallback: specify that we'd rather screenshot the node
          // when it already knows it's being dragged so we can hide it with CSS.
          captureDraggingState: true,
        })
      }
    }
  }

  render() {
    // const {
    // } = this.state;
    const {
      organizationId,
      projectId,
      theme,
      isDragging,
      isDragLayer,
      connectDragSource,
      FormTemplateField,
      FormTemplateFields,
      minWidth,
      selected,
      totalFields,
      tabIndex,
      FormTemplate,
      classes,
      onApiError,
      onAlert,
      fields,
    } = this.props;
    const {
      ID,
      X,
      Y,
      Width,
      Type,
      TypeLabel,
      UseColumnLayout,
      DefaultValue,
      DefaultValues,
      PossibleValues,
      FieldID,
      Field,
      FontSize,
      FontBold,
      FontItalic,
      FontUnderline,
      AssetSignedUrl,
    } = FormTemplateField;

    const forcedPlaceholder = 
      (Field.DisplaySelectionList
        && (
          (GetEffectiveAllowNewSelectionListItems(Field) && FormTemplateField.Width < 0.23)
          || (!GetEffectiveAllowNewSelectionListItems(Field) && FormTemplateField.Width < 0.15)
        ))
      ? ""
      : undefined;
    const useSmallFont = Field.Type === "FieldType_Date" && FormTemplateField.Width < 0.18;

    const selectedBackgroundColor = (theme.palette.type === "dark")
      ? blue[900]
      : theme.palette.secondary.main;
    const backgroundColor = (selected && !isDragging)
      ? selectedBackgroundColor 
      : theme.palette.background.paper;
    const fieldLabel = (Field && !Field.HideLabel)
      ? (Field.Label || Field.Name)
      : null;
    let padding = 0;
    let showHorizDragHandle = false;
    let fieldComponent = null;
    switch (Type) {
      case "Label":
        fieldComponent = (selected)
          ? (
            <TextField
              // key={Field.UpdateId} // This is a hack to force the component to update when the value of Field.UpdateId changes
              variant="outlined"
              autoComplete="off"
              label={fieldLabel}
              placeholder="Sample label"
              // disabled
              value={DefaultValue}
              size="small"
              onChange={e => this.handleDefaultValueChange(ID)(e.target.value)}
              onKeyDown={this.handleKeyDown}
              // id={fieldID}
              InputProps={{notched: false}}
              // InputLabelProps={{ shrink: fieldLabel !== "", }}
            />
          )
          : (
            <FormLabel style={{lineHeight:1.5}}>
              <Typography>
                {DefaultValue || "Label"}
              </Typography>
            </FormLabel>
          );
        padding = (!selected) ? 8 : 0;
    break;
    case "Text":
      fieldComponent = (selected)
        ? (
          <TextField
            // key={Field.UpdateId} // This is a hack to force the component to update when the value of Field.UpdateId changes
            variant="outlined"
            autoComplete="off"
            label={fieldLabel}
            placeholder="Sample text"
            // disabled
            value={DefaultValue}
            multiline
            size="small"
            onChange={e => this.handleDefaultValueChange(ID)(e.target.value)}
            onKeyDown={this.handleKeyDown}
            // id={fieldID}
            fullWidth
            InputProps={{
              notched: false,
              style:{
                fontSize: (FontSize) ? FontSize : undefined,
                fontWeight: (FontBold) ? "bold" : undefined,
                fontStyle: (FontItalic) ? "italic" : undefined,
                textDecoration: (FontUnderline) ? "underline" : undefined,
              },
            }}
            // InputLabelProps={{ shrink: fieldLabel !== "", }}
          />
        )
        : (
          <Typography variant="body1"
            style={{
              fontSize: (FontSize) ? FontSize : undefined,
              fontWeight: (FontBold) ? "bold" : undefined,
              fontStyle: (FontItalic) ? "italic" : undefined,
              textDecoration: (FontUnderline) ? "underline" : undefined,
              lineHeight: 1,
            }}
          >
            {DefaultValue || "Label"}
          </Typography>
        );
        padding = (!selected) ? 8 : 0;
        showHorizDragHandle = true;
      break;
    case "Image":
      fieldComponent = AssetSignedUrl
        ? (
          <img src={AssetSignedUrl} style={{width:"100%"}} alt="" />
        )
        : (
          <div className={classes.imagePlaceholderContainer}>
            <div>Select an image</div>
          </div>
        );
      showHorizDragHandle = true;
      break;
    case "Field":
      const selectFieldOnRightMessage = "Select or add a field on the right...";
      if (FieldID && Field) {
        // Image type not handled yet
        if (Field.Type === "FieldType_Image") {
          fieldComponent = (
            <TextField
              variant="outlined"
              value={selectFieldOnRightMessage}
              disabled
              fullWidth
            />
          );
        } else {
          Field.Value = DefaultValue;
          Field.Values = DefaultValues;
          Field.ListValues = GetComposedFieldListLabelsAndValues(Field);
          fieldComponent = (
            <FieldInput Field={Field}
              Index={-1}
              // UpdateId={new Date()}
              // forceSmallControls
              forceAllowClear
              redBorderForRequired
              forcedPlaceholder={forcedPlaceholder}
              hideAdornment={GetHideAdornment(FormTemplateField.Width)}
              useSmallFont={useSmallFont}
              // forcedBackgroundColor={backgroundColor}
              onValueChange={this.handleDefaultValueChange(ID)}
              onGetSelectionListFilterPromise={
                (() => {
                  const parentFieldValue = GetDependentFieldsParentValue(Field, FormTemplateFields, f => f.FieldID,
                    pf => pf.DefaultValue);
                  return HandleGetFieldListItemsFilterPromise(organizationId, projectId, FieldID, onApiError, undefined, undefined, undefined, parentFieldValue);
                })()
              }
              onSelectionListItemAdd={
                HandleFieldListItemAdd(organizationId, projectId, FieldID, Field, 
                  (unusedFieldId) => (unusedEvent, selectedOptions) => this.handleDefaultValueChange(ID)(null, selectedOptions),
                  onApiError)
              }
              onApiError={onApiError}
              onAlert={onAlert}
              fields={fields}
              isInDesigner
            />
          );
        }
      } else {
        // This is just a placeholder until the user chooses a field, 
        // which is essentially required for this type
        fieldComponent = (
          <TextField
            // key={Field.UpdateId} // This is a hack to force the component to update when the value of Field.UpdateId changes
            variant="outlined"
            // autoComplete="off"
            value={selectFieldOnRightMessage}
            disabled
            // value={DefaultValue}
            // onChange={e => this.handleDefaultValueChange(ID)(e.target.value)}
            // id={fieldID}
            // size="small"
            fullWidth
            // InputLabelProps={{ shrink: fieldLabel !== "", }}
          />
        );
      }
      showHorizDragHandle = true;
      break;
    case "TextBox":
    case "ListBox":
    case "CheckBox":
      // The following is likely identical to the "Field" type above... DRY it later
      Field.Value = DefaultValue;
      Field.Values = DefaultValues;
      Field.ListValues = GetComposedFieldListLabelsAndValues(Field);
      // Field.UpdateId = new Date();
      fieldComponent = (
        <FieldInput Field={Field}
          Index={-1}
          // forceSmallControls
          hideAdornment={GetHideAdornment(FormTemplateField.Width)}
          useSmallFont={useSmallFont}
          forceAllowClear
          redBorderForRequired
          forcedPlaceholder={forcedPlaceholder}
          // forcedBackgroundColor={backgroundColor}
          onValueChange={this.handleDefaultValueChange(ID)}
          onGetSelectionListFilterPromise={
            (() => {
              const parentFieldValue = GetDependentFieldsParentValue(Field, FormTemplateFields, f => f.ID,
                pf => pf.DefaultValue);
              return HandleGetFormTemplateFieldListItemsFilter(FormTemplateField, onApiError, parentFieldValue);
            })()
          }
          onSelectionListItemAdd={HandleFormTemplateFieldListItemAdd(FormTemplateField, onApiError, this.handleDefaultValueChange)}
          onApiError={onApiError}
          onAlert={onAlert}
          fields={fields}
        />
      );
      showHorizDragHandle = true;
      break;
    case "RadioGroup":
      const radioControls = (PossibleValues && PossibleValues.length)
        ? PossibleValues.filter(v => v).map((v, i) => {
          const requiredClass = (Field.Required) ? classes.required : null;
          const formControlClasses =classNames(classes.radioButtonContainer, requiredClass);
          return (
            <FormControlLabel
              key={`${i}_${v}`}
              className={formControlClasses}
              value={v}
              control={<Radio size="small" name={`r_${ID}_${PossibleValues.indexOf(v)}`/*Name specified to differentiate among other radio groups for proper keyboard tabbing*/}/>}
              label={<Typography>{v}</Typography>}
              onClick={() => this.handleDefaultValueChange(ID)(v)}
            />
          );
        })
        : [];
      fieldComponent = (
        <FormControl>
          <FormLabel component="legend">
            {fieldLabel}
          </FormLabel>
          <RadioGroup row={!UseColumnLayout} aria-label={fieldLabel}
            name={fieldLabel}
            value={DefaultValue}
            // onChange={e => this.handleDefaultValueChange(ID)(e.target.value)}
            // style={{
            //   width:"max-content",
            // }}
          >
            {radioControls}
          </RadioGroup>
        </FormControl>
      );
      showHorizDragHandle = true;
      break;
    case "Signature":
      const fillSourceSignatureInfo = (FormTemplate && FormTemplate.RenderType === "FillSource")
        ? (
          <Tooltip title="Submitted signatures will not appear in filled PDFs.">
            <InfoIcon style={{color:red[500]}} />
          </Tooltip>
        ) : null;
      fieldComponent = (
        <div style={{display:"flex",alignItems:"center"}}>
          <Button
            variant="contained"
            style={{flexGrow:1}}
            disabled
          >
            {fieldLabel || TypeLabel}
          </Button>
          {fillSourceSignatureInfo}
        </div>
      );
      break;
    case "Upload":
      fieldComponent = (
        <div className={classes.uploadButtonContainer}>
          <Button
            variant="contained"
            disabled
            style={{backgroundColor:(Field.Required) ? red[500] : undefined}}
          >
            {fieldLabel || TypeLabel}
          </Button>
        </div>
      );
      break;
    default:
      break;
    }

    let fieldWidth = 0;
    let useMaxContent = false;
    switch (Type) {
      // case "CheckBox":
      // case "RadioGroup":
      case "Label":
      case "Signature":
      case "Upload":
        useMaxContent = true;
      break;
      default:
        if (isDragLayer) {
          fieldWidth = 1;
        } else {
          fieldWidth = Width;
          if (!fieldWidth || fieldWidth < minWidth) {
            fieldWidth = minWidth;
          }
        }
      break;
    }

    let horizDragHandle = null;
    if (showHorizDragHandle) {
      horizDragHandle = (
        <div 
          key={`dh_${ID}`}
          className={classes.horizDragHandle}
          style={{
            right:0,
            top: 0,
            height: "100%",
          }}
          onMouseDown={this.handleHorizDragHandleMouseDown(ID)}
        />
      );
    }

    fieldComponent = (
      <div style={{
        width:"100%",
        backgroundColor,
        borderRadius:4,
      }}>
        {fieldComponent}
      </div>
    );

    const rootClasses = [
      classes.root,
      ...GetFormTemplateFieldControlSizeClasses(FormTemplateField, classes),
    ];

    return (
      <div
        className={classNames(rootClasses)}
        style={{
          backgroundColor:(selected && !isDragging) ? selectedBackgroundColor : undefined,
          left: (!isDragLayer) ? `${X*100}%` : undefined,
          top: (!isDragLayer) ? `${Y*100}%` : undefined,
          width: (useMaxContent) ? "max-content" : `${fieldWidth*100}%`,
          opacity:(isDragging)
            ? 0
            : (FormTemplate && FormTemplate.FormTemplateSourceID && (Field.Type === "FieldType_Number" || Field.Type === "FieldType_Currency"))
              ? 1
              : undefined,
          paddingTop: (padding) ? padding : undefined,
          paddingBottom: (padding) ? padding : undefined,
          zIndex:(totalFields && tabIndex > -1) ? totalFields - tabIndex : undefined, // This fixes an issue where field-based selection lists are not displayed over other fields
        }}
        ref={instance => connectDragSource(ReactDOM.findDOMNode(instance))}
        onClick={this.handleClick}
        onMouseDown={this.handleMouseDown}
      >
        {horizDragHandle}
        {fieldComponent}
        <DragIndicatorIcon
          className={classes.dragIndicator}
        />
      </div>
    );
  }
}


DraggableFormTemplateField.propTypes = {
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  minWidth: PropTypes.number.isRequired,
  FormTemplate: PropTypes.object,
  FormTemplateField: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  onHorizDragHandleMouseDown: PropTypes.func,
  onDefaultValueChange: PropTypes.func,
  onApiError: PropTypes.func.isRequired,
  onAlert: PropTypes.func.isRequired,
};

export default DragSource('DraggableFormTemplateField', formTemplateFieldSource, dragCollect)(withStyles(styles, {withTheme: true})(DraggableFormTemplateField));
