import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Collection from '../Model/Collection';
import ItemTableRow from '../Components/ItemTableRow';

import ImageCaptureIcon from '@material-ui/icons/Scanner';
import FileUploadIcon from '@material-ui/icons/CloudUpload';
import DocumentCreateIcon from '@material-ui/icons/NoteAdd';
import SearchIcon from '@material-ui/icons/Search';

import TaskDocumentsCollectionFields from '../Model/TaskDocumentsCollectionFields';
import DocumentCard from '../Components/DocumentCard';

import {
  GetPreviewMetadataHeadCells,
  GetPreviewMetadataTableCells,
} from '../Util/Metadata';
import {
  HandleRouteToDocument,
  AreDocumentsOrDocumentFoldersLoadedByAssignment,
} from '../Util/Documents';
import {
  GetTagsForList,
} from '../Util/Tags';
import {
  GetDateValue,
  GetUserValue,
} from '../Util/Properties';
import {
  GetPageCountForDisplay,
  GetFileSizeSpan,
} from '../Util/Document';
import {
  GetTaskDocumentsPathForApi,
  GetUserOrganizationProjectTaskDocumentsPathForApi,
  GetUserOrganizationProjectApprovalTaskDocumentsPathForApi,
  GetUserOrganizationProjectApprovalAssetItemTaskDocumentsPathForApi,
} from '../Util/api';

export class TaskDocuments_Collection extends Collection {
  constructor(props, context, onSetState, onApiError, isWorkspace, isProjects, 
    loadByTaskAssignment, loadByApprovalTaskAssignment, loadByApprovalAssetItemTaskAssignment,
    organizationId, projectId, task, approvalId, approvalAssetItem, isProjectMember,
    onBeginFileUploadFunc, onBeginImageCaptureFunc,
    onBeginDocumentCreateFunc, onBeginAddExistingDocumentsFunc,
    allowSubscriptions, disableSelect) {
    
    super(props, onSetState, onApiError, isWorkspace, isProjects, organizationId, projectId);

    this.context = context;
    this.IsProjectMember = isProjectMember;

    this.PageTitle = "Documents";
    this.ContentUri = (loadByApprovalAssetItemTaskAssignment)
      ? GetUserOrganizationProjectApprovalAssetItemTaskDocumentsPathForApi(organizationId, projectId,
          approvalId, approvalAssetItem.AssetID, approvalAssetItem.ID, task.ID)
      : (loadByApprovalTaskAssignment)
        ? GetUserOrganizationProjectApprovalTaskDocumentsPathForApi(organizationId, projectId,
          approvalId, task.ID)
        : (loadByTaskAssignment)
          ? GetUserOrganizationProjectTaskDocumentsPathForApi(organizationId, projectId, task.ID)
          : GetTaskDocumentsPathForApi(organizationId, projectId, task.ID);
    this.ContentUriParams = {};
    this.CollectionName = "TaskDocuments";
    this.ItemsName = "TaskDocuments";
    this.ItemName = "TaskDocument";
    this.DefaultViewType = "Card";
    this.AllowSelect = !disableSelect;
    // Do not allow select of Task.PrimaryDocument
    this.CanSelectItem = item => this.AllowSelect && item.DocumentID !== task.PrimaryDocument.DocumentID;
    // this.HideSensitiveFields = true;
    this.SingleLineTableCells = true;

    this.loadByTaskAssignment = loadByTaskAssignment;
    this.loadByApprovalTaskAssignment = loadByApprovalTaskAssignment;
    this.loadByApprovalAssetItemTaskAssignment = loadByApprovalAssetItemTaskAssignment;
    this.allowSubscriptions = allowSubscriptions;
    this.Task = task;
    this.approvalId = approvalId;
    this.approvalAssetItem = approvalAssetItem;
    this.onBeginFileUploadFunc = onBeginFileUploadFunc;
    this.onBeginImageCaptureFunc = onBeginImageCaptureFunc;
    this.onBeginDocumentCreateFunc = onBeginDocumentCreateFunc;
    this.onBeginAddExistingDocumentsFunc = onBeginAddExistingDocumentsFunc;

    this.HandleCreateNew = e =>
      (this.onBeginImageCaptureFunc || this.onBeginImageCaptureFunc || this.onBeginDocumentCreateFunc || this.onBeginAddExistingDocumentsFunc)
        ? this.handleSetFabMenuVisibility(true)(e)
        : (this.onBeginFileUploadFunc)
          ? this.onBeginFileUploadFunc()
          : null;
  }

  handleSetFabMenuVisibility = visible => event => {
    this.setState({
      FabMenu_MouseY: (visible) ? event.clientY : null,
      FabMenu_MouseX: (visible) ? event.clientX : null,
    });
  }

  HandleGetFabMenu = state => {
    if (!state.FabMenu_MouseY) {
      return null;
    }
    const imageCaptureMenuItem = (this.onBeginImageCaptureFunc)
      ? (
        <MenuItem key="imageCapture" onClick={this.handleFabMenuClick(state, "imageCapture")}>
          <ListItemIcon>
            <ImageCaptureIcon />
          </ListItemIcon>
          Document scan
        </MenuItem>
      ) : null;
    const documentCreateMenuItem = (this.onBeginDocumentCreateFunc)
      ? (
        <MenuItem key="documentCreate" onClick={this.handleFabMenuClick(state, "documentCreate")}>
          <ListItemIcon>
            <DocumentCreateIcon />
          </ListItemIcon>
          Blank document
        </MenuItem>
      ) : null;
    const addExistingMenuItem = (this.IsProjectMember
      && !AreDocumentsOrDocumentFoldersLoadedByAssignment(this.context, this.ProjectID)
      && this.onBeginAddExistingDocumentsFunc)
      ? (
        <MenuItem key="addExisting" onClick={this.handleFabMenuClick(state, "addExisting")}>
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          Add existing
        </MenuItem>
      ) : null;
    return (
        <Menu
          id="fabMenu"
          keepMounted
          open={state.FabMenu_MouseY !== null}
          anchorReference="anchorPosition"
          anchorPosition={
            state.FabMenu_MouseY !== null && state.FabMenu_MouseX !== null
              ? { top: state.FabMenu_MouseY, left: state.FabMenu_MouseX }
              : undefined
          }
          onClose={this.handleSetFabMenuVisibility(false)}
        >
          {documentCreateMenuItem}
          {imageCaptureMenuItem}
          <MenuItem key="fileUpload" onClick={this.handleFabMenuClick(state, "fileUpload")}>
            <ListItemIcon>
              <FileUploadIcon />
            </ListItemIcon>
            Document upload
          </MenuItem>
          {addExistingMenuItem}
        </Menu>
      );
  }

  handleFabMenuClick = (state, id) => e => {
    this.handleSetFabMenuVisibility(false)(e);
    switch (id) {
      case "imageCapture":
        if (this.onBeginImageCaptureFunc) {
          this.onBeginImageCaptureFunc();
        }
        break;
      case "fileUpload":
        if (this.onBeginFileUploadFunc) {
          this.onBeginFileUploadFunc();
        }
        break;
      case "documentCreate":
        if (this.onBeginDocumentCreateFunc) {
          this.onBeginDocumentCreateFunc();
        }
        break;
      case "addExisting":
        if (this.onBeginAddExistingDocumentsFunc) {
          this.onBeginAddExistingDocumentsFunc();
        }
        break;
      default:
        break;
    }
  }

  HandleRefresh = () => {};//this.setDocumentsCount();

  HandleGetCollectionFieldsPromise = () => {
    return Promise.resolve(
      TaskDocumentsCollectionFields.filter(f =>
        (this.loadByTaskAssignment)
          ? f.ID !== "Meta_text_kw256lc[AssignmentUserEmail].keyword"
          : f
      )
    );
  }

  HandleGetHeadCells = (items, sensitiveFields) => {
    let headCells = [
      { id: 'Name', sortId: 'Meta_text_kw256lc[Name].keyword', numeric: false, label: 'Name' },
      { id: 'CreatedOn', sortId: 'Meta_date_str256[CreatedOn].string', numeric: false, label: 'Created On' },
      { id: 'CreatedByUserEmail', sortId: 'Meta_text_kw256lc[CreatedByUserEmail].keyword', numeric: false, label: 'Created By' },
      // { id: 'Assignment', sortId: 'Meta_text_kw256lc[AssignmentUserEmail].keyword', numeric: false, label: 'Shared with' },
      { id: 'Tags', sortId: 'Meta_text_kw50lc[Tag].keyword', numeric: false, label: 'Tags' },
      { id: 'PageCount', sortId: 'Meta_int[PageCount]', numeric: true, label: 'Page Count' },
      { id: 'FileSize', sortId: 'Meta_long[FileSize]', numeric: true, label: 'File Size' },
    ];
    headCells.push(...GetPreviewMetadataHeadCells(sensitiveFields, items));

    return headCells;
  }

  getExtraUrlParamsForDocumentRouting = item => {
    let params = {
      taskID: this.Task.ID,
    };
    if (this.loadByApprovalAssetItemTaskAssignment) {
      params.approvalID = this.approvalId;
      params.assetID = this.approvalAssetItem.AssetID;
      params.assetItemID = this.approvalAssetItem.ID;
    } else if (this.loadByApprovalTaskAssignment) {
      params.approvalID = this.approvalId;
    }
    return params;
  }

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, selectedIDs,
    onAction, postActionData, sortType, sortDescending, useSingleColumnLayout, cardStyle) => {
    return (items && items.length)
      ? items
        .map(i => {
          return (
            <Grid item key={i.ID} className={classes.cardGridItem}
              sm={(!useSingleColumnLayout) ? 12 : undefined}
              md={(!useSingleColumnLayout) ? 6 : undefined}
              lg={(!useSingleColumnLayout) ? 4 : undefined}
              xl={(!useSingleColumnLayout) ? 3 : undefined}
            >
              <DocumentCard
                organizationId={this.OrganizationID}
                projectId={this.ProjectID}
                Document={i}
                sensitiveFields={sensitiveFields}
                postActionData={postActionData}
                allowDownload={i.Origin !== "Editor"}
                allowSubscriptions={this.allowSubscriptions}
                onCardAction={() => HandleRouteToDocument(this.OrganizationID, this.ProjectID,
                  i, items, this.ContentUri, this.ContentUriParams, sortType, 
                  sortDescending, false, false, 
                  this.getExtraUrlParamsForDocumentRouting(i),
                  (this.loadByApprovalAssetItemTaskAssignment)
                    ? "approvalAssetItemTask"
                    : (this.loadByApprovalTaskAssignment)
                      ? "approvalTask"
                      : (this.loadByTaskAssignment)
                        ? "task"
                        : undefined)
                }
                onSelect={(this.CanSelectItem(i)) ? () => onSelect(i.ID) : undefined}
                onAction={this.handleItemAction(onAction)}
                noSelect={!this.CanSelectItem(i)}
                selected={selectedIDs.indexOf(i.ID) > -1}
                style={{
                  backgroundColor: theme.palette.background.default,
                  ...cardStyle
                }}
              />
            </Grid>
          );
        }) 
      : [];
  }

  handleItemAction = onAction => (actionType, id_optional, silentProgress, params) => {
    if (!params) {
      params = {};
    }
    if (this.loadByApprovalAssetItemTaskAssignment) {
      params.loadByApprovalAssetItemTaskAssignment = this.loadByApprovalAssetItemTaskAssignment;
      params.approvalId = this.approvalId;
      params.assetId = this.approvalAssetItem.AssetID;
      params.assetItemId = this.approvalAssetItem.ID;
    } else if (this.loadByApprovalTaskAssignment) {
      params.loadByApprovalTaskAssignment = this.loadByApprovalTaskAssignment;
      params.approvalId = this.approvalId;
    }
    onAction(actionType, id_optional, silentProgress, params);
  }

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length)
      ? items.map(i => {
        const selected = selectedIDs.indexOf(i.ID) !== -1;
        const labelId = `label_${i.ID}`;
        const metadataTableCells = [...GetPreviewMetadataTableCells(i, headCells, classes)];

        return (
          <ItemTableRow
            key={`k_${i.ID}`}
            onItemClick={() => HandleRouteToDocument(this.OrganizationID, this.ProjectID,
              i, items, this.ContentUri, this.ContentUriParams, sortType, sortDescending, false, false,
              this.getExtraUrlParamsForDocumentRouting(i),
              (this.loadByApprovalAssetItemTaskAssignment)
                    ? "approvalAssetItemTask"
                    : (this.loadByApprovalTaskAssignment)
                      ? "approvalTask"
                      : (this.loadByTaskAssignment)
                        ? "task"
                        : undefined)
            }
            selected={selected}
            onSelect={(this.CanSelectItem(i)) ? () => onSelect(i.ID) : undefined}
          >
            <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
              <Checkbox
                style={{
                  visibility: (!this.CanSelectItem(i)) ? "hidden" : undefined,
                }}
                color="secondary"
                onClick={event => { event.stopPropagation(); onSelect(i.ID); }}
                checked={selected}
                inputProps={{ 'aria-labelledby': labelId, style:{zIndex:0} }}
              />
            </TableCell>
            <TableCell component="th" id={labelId} scope="row" padding="none" style={{color:i.DocumentFolderHexColor}}>
              {i.Name}
            </TableCell>
            <TableCell style={{whiteSpace:"nowrap"}}>{GetDateValue(i.CreatedOn)}</TableCell>
            <TableCell style={{whiteSpace:"nowrap"}}>{GetUserValue(i.CreatedByUserEmail, i.CreatedByUserName)}</TableCell>
            {/*<TableCell style={{whiteSpace:"nowrap"}}>{
              (i.AssignmentUserEmails && i.AssignmentUserEmails.length)
                ? i.AssignmentUserEmails.join(", ")
                : null
            }</TableCell>*/}
            <TableCell className={classes.tableCell}>
              {GetTagsForList(i, sortType, sortDescending)}
            </TableCell>
            <TableCell align="right">{GetPageCountForDisplay(i.PageCount)}</TableCell>
            <TableCell align="right">{GetFileSizeSpan(i.FileSize)}</TableCell>
            {metadataTableCells}
          </ItemTableRow>
        );
      })
    : null;
  }
}