import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import orange from '@material-ui/core/colors/orange';

const getFirstStepIndex = steps => {
  return steps[0].index;
}

const getFinalStepIndex = steps => {
  return steps[steps.length - 1].index;
}

export const HandleBack = (onGetState, onSetState) => {
  const {
    Steps,
    BackStep,
    ActiveStepIndex,
  } = onGetState();
  let previousStepIndex = (typeof BackStep === "number" && BackStep >= 0)
      ? BackStep
      : Math.max(ActiveStepIndex - 1, getFirstStepIndex(Steps));
  let newSteps = [...Steps];
  newSteps.find(s => s.index === previousStepIndex).completed = false;
  onSetState({
    Steps: newSteps,
    ActiveStepIndex: previousStepIndex,
  });
}

export const HandleNext = (onGetState, onSetState, onFinish, proceedToStep, activeStepSkipped, additionalState) => {
  if (!additionalState) {
    additionalState = {};
  }
  const {
    Steps,
    ActiveStepIndex,
  } = onGetState();
  switch (ActiveStepIndex) {
    case getFinalStepIndex(Steps):
      return onFinish();
    default:
      break;
  }
  let stateToUpdate = {
    ActiveStepIndex: (typeof proceedToStep === "number" && proceedToStep >= 0)
      ? proceedToStep
      : Math.min(1 + ActiveStepIndex, getFinalStepIndex(Steps)),
    BackStep: null,
    ...additionalState,
  };
  if (!activeStepSkipped) {
    let newSteps = [...Steps];
    newSteps.find(s => s.index === ActiveStepIndex).completed = true;
    stateToUpdate.Steps = newSteps;
  }
  onSetState(stateToUpdate);
}

const getBackButton = (onGetState, onSetState, classes) => {
  const {
    ActiveStepIndex,
  } = onGetState();
  
  return (ActiveStepIndex > 0)
    ? (
      <Button onClick={() => HandleBack(onGetState, onSetState)}
        className={classes.stepButton}
      >
        BACK
      </Button>
    ) : null;
}

const getSkipButton = (onGetState, onSetState, onSkip, classes) => {
  const {
    Steps,
    ActiveStepIndex,
  } = onGetState();

  return (Steps[ActiveStepIndex].optional && ActiveStepIndex !== getFinalStepIndex(Steps))
    ? (
      <Button onClick={onSkip}
        className={classes.stepButton}
      >
        SKIP
      </Button>
    ) : null;
}

const getNextButton = (onGetState, onSetState, onFinish, classes, nextButtonIsDisabled, finishButtonLabel) => {
  const {
    Steps,
    ActiveStepIndex,
  } = onGetState();

  return (!Steps[ActiveStepIndex].hideNext) ? (
    <Button onClick={() => HandleNext(onGetState, onSetState, onFinish)}
      variant="contained"
      className={classes.stepButton}
      disabled={nextButtonIsDisabled}
      color="secondary"
    >
      {(ActiveStepIndex === getFinalStepIndex(Steps)) ? finishButtonLabel || "FINISH" : "NEXT"}
    </Button>
  ) : null;
}

const getButtons = (onGetState, onSetState, onSkip, onFinish, classes, nextButtonIsDisabled,
  finishButtonLabel, buttonsBackgroundColor, additionalButtons) => {

  const additionalButtonsDiv = (additionalButtons) ? (
    <Grid container spacing={1} style={{marginTop:8}}>
      {
        additionalButtons.map((button, index) => (
          <Grid item key={`gi_${index}`}>
            {button}
          </Grid>
        ))
      }
    </Grid>
  ) : null;
  return (
    <React.Fragment>
      <div className={classes.stepButtons} style={{
        backgroundColor: buttonsBackgroundColor,
      }}>
        {getBackButton(onGetState, onSetState, classes)}
        {getSkipButton(onGetState, onSetState, onSkip, classes)}
        {getNextButton(onGetState, onSetState, onFinish, classes, nextButtonIsDisabled, finishButtonLabel)}
      </div>
    {additionalButtonsDiv}
    </React.Fragment>
  );
}

export const GetStepper = (onGetState, onSetState, onSkip, onMove, onFinish, classes, stepContent, 
  nextButtonIsDisabled, finishButtonLabel, buttonsBackgroundColor, additionalButtons) => {
  const {
    Steps,
    ActiveStepIndex,
  } = onGetState();

  const canMoveToStep = stepIndex => {
    return Steps.find(s => s.index === stepIndex)?.completed && onMove;
  };
  const getStepClassName = stepIndex => {
    return (canMoveToStep(stepIndex)) ? classes.stepHover : undefined;
  }; 

  return (
    <Stepper
      activeStep={ActiveStepIndex}
      orientation="vertical"
      className={classes.stepper}
      style={{backgroundColor:"unset"}}
    >
      {
        Steps
        .map(step => (
          <Step key={step.index} completed={step.completed}
            onClick={(canMoveToStep(step.index)) ? () => onMove(step.index) : undefined}
            className={getStepClassName(step.index)}
          >
            <StepLabel StepIconProps={{
              classes: { 
                active: classes.stepIconActive,
              },
            }}>
              {step.getLabel && step.getLabel(onGetState)}
              {
                (step.notApplicable || (step.optional && !step.hideOptionalLabel))
                  ? (
                      <Typography variant="body2" color="textSecondary" style={{fontSize:12}}>
                        {
                          (step.notApplicable)
                            ? "Not applicable"
                            : "Optional"
                        }
                      </Typography>
                    )
                  : null
              }
            </StepLabel>
            <StepContent>
              <Typography>
                {step.getDescription && step.getDescription()}
              </Typography>
              {stepContent}
              {getButtons(onGetState, onSetState, onSkip, onFinish, classes, nextButtonIsDisabled,
                finishButtonLabel, buttonsBackgroundColor, additionalButtons)}
            </StepContent>
          </Step>
        ))
      }
    </Stepper>
  );
}

export const GetStepperStyles = theme => ({
  stepper:{
    padding:theme.spacing(2),
    paddingTop:0,
    paddingBottom:theme.spacing(3),
  },
  stepIconActive: {
    color: [orange[900], "!important"],
  },
  stepButtons: {
    marginTop:theme.spacing(3),
    paddingBottom:theme.spacing(2),
    position:"sticky",
    bottom:0,
  },
  stepButton: {
    marginRight:theme.spacing(1),
  },
  stepHover: {
    cursor:"pointer",
    paddingTop:8,
    "&:hover": {
      backgroundColor:"#80808020",
    },
  },
});