import React from 'react';
import ReactDOM from 'react-dom';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

import { DragSource, DropTarget } from 'react-dnd';
import { IsMobile } from '../Util/MobileDetector';

import FolderHierarchyItemType from '../Model/FolderHierarchyItemType';
import FieldValueType from '../Model/FieldValueType';

/**
 * Implements the drag source contract.
 */
const folderHierarchyItemTableRowSource = {
  canDrag(props) {
    return !IsMobile();
  },
  beginDrag(props, monitor, component) {
    props.onStartMoveFolderHierarchyItemTableRow();
    return {
      FolderHierarchyItem: props.FolderHierarchyItem,
      onDropFolderHierarchyItemTableRow: props.onDropFolderHierarchyItemTableRow,
    };
  },
  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      props.onAbortMoveFolderHierarchyItemTableRow();
    }
  }
};

/**
 * Specifies the drop target contract.
 * All methods are optional.
 */
const folderHierarchyItemTableRowTarget = {
  drop(props, monitor, component) {
    // if (monitor.didDrop()) {
    //   // If you want, you can check whether some nested
    //   // target already handled drop
    //   return;
    // }

    // Obtain the dragged item
    const folderHierarchyItemTableRow = monitor.getItem();
    folderHierarchyItemTableRow.onDropFolderHierarchyItemTableRow();

    // You can also do nothing and return a drop result,
    // which will be available as monitor.getDropResult()
    // in the drag source's endDrag() method
    //return { moved: true };
  },
  hover(props, monitor, component) {
    if (!component)
      return null;
      
    const folderHierarchyItemTableRow = monitor.getItem();
    const dragRank = folderHierarchyItemTableRow.FolderHierarchyItem.Rank;
    const hoverRank = props.FolderHierarchyItem.Rank;

    // Don't replace items with themselves
    if (dragRank === hoverRank)
      return;
 
    props.onMoveFolderHierarchyItemTableRow(folderHierarchyItemTableRow.FolderHierarchyItem);
  },
}

/**
 * Specifies the props to inject into your component.
 */
function dragCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

/**
 * Specifies which props to inject into your component.
 */
function dropCollect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    //isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    // canDrop: monitor.canDrop(),
    // itemType: monitor.getItemType()
  };
}

const styles = theme => ({
  tableRow: {
    cursor:"pointer",
  },
});

class FolderHierarchyItemTableRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  render() {
    const { 
      classes,
      // theme,
      FolderHierarchyItem,
      Selected,
      LevelNumber,
      connectDragSource,
      connectDropTarget,
      isDragging,
      onOpenFolderHierarchyItemPropertiesDialog,
      onSelectItem,
    } = this.props;

    let detail = null;
    switch (FolderHierarchyItem.Type) {
      case "FolderHierarchyItemType_Field":
        detail = FolderHierarchyItem.FieldName;
        let fieldValueType = FieldValueType.filter(f => f.Type === FolderHierarchyItem.FieldValueType);
        if (fieldValueType.length > 0) {
          detail += " (" + fieldValueType[0].Label + ")";
        }

      break;
      default:
      break;
    }

    return (
      <TableRow
        className={classes.tableRow}
        hover
        selected={Selected}
        onClick={event => onOpenFolderHierarchyItemPropertiesDialog()}
        tabIndex={-1}
        key={FolderHierarchyItem.ID}
        ref={instance => connectDropTarget(connectDragSource(ReactDOM.findDOMNode(instance)))}
        style={{
          opacity: (isDragging) && 0,
        }}
      >
        <TableCell component="th" scope="row">
          <DragIndicatorIcon style={{marginBottom:-4}} />
        </TableCell>
        <TableCell padding="checkbox">
          <Checkbox checked={Selected}
            color="secondary"
            onClick={(event) => { event.stopPropagation(); onSelectItem(); }} />
        </TableCell>
        <TableCell component="th" scope="row">
          {LevelNumber}
        </TableCell>
        <TableCell component="th" scope="row">
          {FolderHierarchyItemType.filter(f => f.Type === FolderHierarchyItem.Type)[0].Label}
        </TableCell>
        <TableCell component="th" scope="row">
          {detail}
        </TableCell>
      </TableRow>
    );
  }
}

FolderHierarchyItemTableRow.propTypes = {
  classes: PropTypes.object.isRequired,
  // Injected by React DnD:
  isDragging: PropTypes.bool.isRequired,
  //isOver: PropTypes.bool.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
};

export default DropTarget('FolderHierarchyItemTableRow', folderHierarchyItemTableRowTarget, dropCollect)(DragSource('FolderHierarchyItemTableRow', folderHierarchyItemTableRowSource, dragCollect)(withStyles(styles, {withTheme: true})(FolderHierarchyItemTableRow)));