import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import SubscribedIcon from '@material-ui/icons/Bookmark';

import Card from './Card';

import { 
  GetFileSizePageCountSpan,
  GetDocumentSubscription,
} from '../Util/Document';
import { 
  GetCardMetadataFromPreviewMetadata,
} from '../Util/Fields';
import {
  GetDateValue,
  GetUserValue,
} from '../Util/Properties';
import {
  GetTagsPreviewFromTagsObject,
} from '../Util/Tags';
import ActionType from '../Model/ActionType';

import orange from '@material-ui/core/colors/orange';

class DocumentCard extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      DocumentSubscription: null,
    }
  }

  loadDocumentSubscription = () => {
    GetDocumentSubscription(this.props.organizationId, this.props.projectId, this.props.Document.DocumentID)
      .then(DocumentSubscription => this.setState({DocumentSubscription}));
  }

  handleSubscribeAction = (actionType, id) => {
    if (!this.props.onAction) {
      return;
    }
    this.props.onAction(actionType, id, true)
      .then(documentSubscriptions => {
        if (documentSubscriptions && documentSubscriptions.length) {
          this.setState({DocumentSubscription: documentSubscriptions[0]});
        } else {
          this.loadDocumentSubscription();
        }
      });
  }

  handlePostActionData = data => {
    // Updated subscription
    if (data.DocumentSubscriptions) {
      let subscriptionFinder = data.DocumentSubscriptions.filter(ds => ds.DocumentID === this.props.Document.DocumentID);
      if (subscriptionFinder.length) {
        this.setState({DocumentSubscription: subscriptionFinder[0]});
      }
    }
  }

  componentDidMount() {
    if (this.props.allowSubscriptions) {
      this.loadDocumentSubscription();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.postActionData
      && this.props.postActionData !== prevProps.postActionData) {
      this.handlePostActionData(this.props.postActionData);
    }

    if (prevProps.Document
      && this.props.Document
      && prevProps.Document.RefreshID !== this.props.Document.RefreshID
      && this.props.allowSubscriptions) {
      this.loadDocumentSubscription();
    }
  }

  render() {
    const {
      DocumentSubscription,
    } = this.state;
    const { 
      Document,
      allowDownload,
      sensitiveFields,
      noSelect,
      selected,
      onSelect,
      onAction,
      onCardAction,
      isSearchResults,
      forcedThumbnailUrl,
      style,
      height,
      disableCardActions,
      isPublicApi,
    } = this.props;

    let metadata = [
      ["", GetFileSizePageCountSpan(Document.FileSize, Document.PageCount)],
    ];
    if (!isPublicApi) {
      metadata.push(
        ["Project", Document.ProjectName],
      );
    }
    metadata.push(
      ["Created On", GetDateValue(Document.CreatedOn)],
    );
    if (!isPublicApi) {
      metadata.push(
        ["Created By", GetUserValue(Document.CreatedByUserEmail, Document.CreatedByUserName, "sm")],
        ["Folder", Document.DocumentFolderPath, {color:Document.DocumentFolderHexColor}],
        ["Shared with", 
          (Document.AssignmentUserEmails && Document.AssignmentUserEmails.length)
            ? Document.AssignmentUserEmails.join(", ")
            : null
        ],
        ["Tags", GetTagsPreviewFromTagsObject(Document)],
      );
      metadata.push(...GetCardMetadataFromPreviewMetadata(Document.PreviewMetadata, sensitiveFields));
    }
    let subscriptionIconButton = (DocumentSubscription)
      ? (
        (DocumentSubscription.Subscribed)
          ? (
            <IconButton
              key={ActionType.Subscription_Delete.Id}
              aria-label={ActionType.Subscription_Delete.Label}
              title={ActionType.Subscription_Delete.Label}
              onClick={() => this.handleSubscribeAction(ActionType.Subscription_Delete, Document.ID)}
              >
              <SubscribedIcon style={{color:orange[500]}} />
            </IconButton> 
          )
          : (
            <IconButton
              key={ActionType.Subscription_Create.Id}
              aria-label={ActionType.Subscription_Create.Label}
              title={ActionType.Subscription_Create.Label}
              onClick={() => this.handleSubscribeAction(ActionType.Subscription_Create, Document.ID)}
              >
              {ActionType.Subscription_Create.Icon}
            </IconButton> 
          )
      ) : null;
    let downloadIconButton = (allowDownload)
      ? (
        <IconButton
          key={ActionType.Download.Id}
          aria-label={ActionType.Download.Label}
          title={ActionType.Download.Label}
          onClick={() => onAction(ActionType.Download, Document.ID)}
          >
          {ActionType.Download.Icon}
        </IconButton>
      ) : null;

    return (
      <Card
        style={style}
        onCardAction={onCardAction}
        noSelect={noSelect}
        onSelect={onSelect}
        selected={selected}
        height={height || 256}
        name={Document.Name}
        fileName={Document.Name}
        isSigned={Document.IsSigned}
        pendingSignatures={Document.SignatureSessionIsActive || Document.DocumentSignatureSessionIsActive}
        thumbnailUrl={forcedThumbnailUrl || Document.ThumbnailUrl}
        metadata={metadata}
        hoverActionItems={!disableCardActions
          ? [
            subscriptionIconButton,
            downloadIconButton,
          ]
          : undefined
        }
        isSearchResults={isSearchResults}
        contentItem={Document}
      />
    );
  }
}

DocumentCard.propTypes = {
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  isPublicApi: PropTypes.bool,
  Document: PropTypes.object.isRequired,
  onCardAction: PropTypes.func.isRequired,
  allowSubscriptions: PropTypes.bool,
  allowDownload: PropTypes.bool,
  disableCardActions: PropTypes.bool,
}

export default DocumentCard;