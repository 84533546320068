import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Card from './Card';

import {
  GetDateValue,
  GetUserValue,
} from '../Util/Properties';

class TaskMilestoneCard extends Component {
  render() {
    const {
      TaskMilestone,
      onCardAction,
      style,
      noSelect,
      onSelect,
      selected,
      isSearchResults
    } = this.props; 

    let metadata = [
      ["Project", TaskMilestone.ProjectName],
      ["Created On", GetDateValue(TaskMilestone.CreatedOn)],
      ["Created By", GetUserValue(TaskMilestone.CreatedByUserEmail)],
      ["Position", 1 + TaskMilestone.Index],
    ];

    return (
      <Card
        style={style}
        noSelect={noSelect}
        onSelect={onSelect}
        selected={selected}
        onCardAction={onCardAction}
        name={TaskMilestone.Name}
        isSearchResults={isSearchResults}
        metadata={metadata}
        contentItem={TaskMilestone}
      />
    );
  }
}

TaskMilestoneCard.propTypes = {
  TaskMilestone: PropTypes.object.isRequired,
  onCardAction: PropTypes.func.isRequired,
};

export default TaskMilestoneCard;