export const Field = {
    ID: "",
    Name: "",
    Type: "",
    Required: false,
    HideLabel: false,
    Value: "",
}

export const CustomMaskValue = "!-custom-!";

export const GetNewTextField = Name => {
	return {
		...Field,
		Name,
		Type: "FieldType_Text",
	};
}

export default Field;