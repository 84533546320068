import API from '../Util/api';

const SearchQuery = {
  FullText: "",
  RoleIDs: [],
  SearchFields: [],
  MetaFields: [],
  FriendlyName: "",
}

const SearchField = {
  FieldID: "",
  FieldValue: "",
  FieldValues: "",
  SecondaryFieldValue: "",
  SecondaryFieldValues: "",
  Operator: "",
  FieldValueType: "",
}

const MetaField = {
  FieldID: "",
  FieldType: "",
  FieldValue: "",
  SecondaryFieldValue: "",
  Operator: "",
}

export const TextFieldOperators = [
  {
    value: "startsWith",
    label: "word starts with",
  },
  {
    value: "notStartsWith",
    label: "does not start with",
  },
];

export const MultiTextFieldOperators = [
  {
    value: "startsWith",
    label: "is any of",
  },
  {
    value: "notStartsWith",
    label: "is not",
  },
];

export const BoolFieldOperators = [
  {
    value: "equals",
    label: "equals",
  },
];

export const NumericDateFieldOperators = [
  {
    value: "equals",
    label: "equals",
  },
  {
    value: "notEquals",
    label: "does not equal",
  },
  {
    value: "greaterThan",
    label: "is greater than",
  },
  {
    value: "lessThan",
    label: "is less than",
  },
  {
    value: "greaterThanEqual",
    label: "is greater than or equal to",
  },
  {
    value: "lessThanEqual",
    label: "is less than or equal to",
  },
  {
    value: "betweenAndIncluding",
    label: "is between and including",
  },
];

export function GetSearchQuery(fullText, searchFields, metaFields, friendlyName) {
  let q = {...SearchQuery};
  if (fullText)
    q.FullText = fullText;
  if (searchFields)
    q.SearchFields = searchFields;
  if (metaFields)
    q.MetaFields = metaFields;
  if (friendlyName)
    q.FriendlyName = friendlyName;

  return q;
}

export function GetSearchField(fieldID, singleValue, multiValues, 
  secondarySingleValue_optional, secondaryMultiValues_optional, 
  operator_optional, fieldValueType_optional) {
  let sf = {...SearchField};
  sf.FieldID = fieldID;
  sf.FieldValue = singleValue;
  sf.FieldValues = multiValues;
  sf.SecondaryFieldValue = secondarySingleValue_optional;
  sf.SecondaryFieldValues = secondaryMultiValues_optional;
  sf.Operator = operator_optional
  sf.FieldValueType = fieldValueType_optional;
  return sf; 
}

export function GetMetaField(fieldID, fieldType, singleValue, secondarySingleValue_optional,
  operator_optional, multiValues) {
  let mf = {...MetaField};
  mf.FieldID = fieldID;
  mf.FieldType = fieldType;
  mf.FieldValue = singleValue;
  mf.SecondaryFieldValue = secondarySingleValue_optional;
  mf.Operator = operator_optional;
  mf.FieldValues = multiValues;
  return mf; 
}

export const GetSearchResultsCollectionFields = (includeScore, onSetState_optional, onApiError) => {
  return API.get("/searchCollectionFields", { params: { includeScore } })
    .then(resp => {
      if (onSetState_optional) {
        onSetState_optional({ CollectionFields: resp.data });
      }
      return resp.data;
    })
    .catch(onApiError);
}