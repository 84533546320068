import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import Slide from '@material-ui/core/Slide';
// import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import PropTypes from 'prop-types';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { IsMobile } from '../Util/MobileDetector';
import MultiUseDialog from './MultiUseDialog';
import ProgressIndicator from './ProgressIndicator';
import FieldPropertiesDialog from './FieldPropertiesDialog';
import FieldType from '../Model/FieldType';

import API from '../Util/api';
import {
  GetNewTextField,
} from '../Model/Field';
import {
  GetFieldsPathForApi,
  GetFieldPathForApi,
} from '../Util/api';
import { GetAllFieldsPromise } from '../Util/Fields';

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(2),
  },
  highlight: {
    backgroundColor: theme.palette.background.pane,
  },
  title: {
    flex: '1 0 auto',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes, handleAdd, handleDelete } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}

    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Fields
          </Typography>
        )}
      </div>
      <div className={classes.actions}>
        {
          numSelected > 0
          ? (
            <Tooltip title="Delete">
              <IconButton aria-label="Delete" onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            )
          : (
            <Tooltip title="Add">
              <IconButton aria-label="Add" onClick={handleAdd}>
                <AddIcon />
              </IconButton>
            </Tooltip>
            // <Tooltip title="Filter list">
            //   <IconButton aria-label="Filter list">
            //     <FilterListIcon />
            //   </IconButton>
            // </Tooltip>
            )
        }
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  tableHead: {
    backgroundColor:theme.palette.background.paper,
  },
  tableRow: {
    cursor: "pointer",
  },
  checkboxColumn: {
    width: theme.spacing(6),
  },
  dialogPaper: {
    height:"100%",
  },
  dialogContent: {
    height:"100%",
  },
  dialogContentFlexContainer: {
    height:"100%",
    display:"flex",
    flexDirection:"column",
  },
  maxHeightFlexContainer: {
    flexGrow:1,
    overflowY:"auto",
  },
});

// const ConfigureTransition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="left" ref={ref} {...props} />;
// });

class ConfigureFieldsDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      Fields: [],
      FieldsLoaded: false,
      Cursor: "",
      Selected: [],
      ShowProgressIndicatorImmediately: false,
      DialogField: {},
      ShowAddDialog: false,
      // ShowUpdateDialog: false,
      ShowDeleteConfirmation: false,
      FieldPropertiesDialogIsOpen: false,
    }
  }

  handleClose = () => {
    this.setState({ Fields: [], open: false });
    if (this.props.closeCallback) {
      this.props.closeCallback();
    }
  }

  handleApiError = err => {
    this.setState({
      ShowDeleteConfirmation: false,
      ShowProgressIndicatorImmediately: false,
    });
    this.props.onApiError(err);
  }

  handleSetAddDialogVisibility(show) {
    this.setState({ ShowAddDialog: show });
  }

  handleAdd(Name) {
    if (!Name) {
      return;
    }
    this.handleSetAddDialogVisibility(false);
    this.setState({ShowProgressIndicatorImmediately:true});
    let newField = GetNewTextField(Name);

    API.post(GetFieldsPathForApi(this.props.organizationId, this.props.projectId), [newField])
      .then(resp => {
        const newField = resp.data[0];
        const Fields = [newField, ...this.state.Fields];
        this.setState({ 
          Fields,
          FieldsLoaded: true,
          ShowProgressIndicatorImmediately: false,
        });
      })
      .catch(this.handleApiError);
  }

  handleLoadFields(reset) {
    this.setState({ShowProgressIndicatorImmediately:true});
    //let cursor = (reset) ? "" : this.state.Cursor;
    GetAllFieldsPromise(this.props.organizationId, this.props.projectId)
      .then(resp => {
        let fieldList = resp.data;
        let Fields = (reset) 
          ? fieldList.Fields 
          : [...this.state.Fields].concat(fieldList.Fields);
          this.setState({ 
            Fields,
            FieldsLoaded: true,
            Cursor: fieldList.Cursor, 
            Selected: [], 
            ShowProgressIndicatorImmediately: false,
          });
      })
      .catch(this.handleApiError);
  }

  handleSelect(id)
  {
    let selected = [...this.state.Selected];
    const selectedIndex = selected.indexOf(id);

    if (selectedIndex === -1) {
      selected = selected.concat(id);
    } else {
      selected.splice(selectedIndex, 1);
    }
    this.setState({ Selected: selected });
  }
  
  handleSelectAll(event) {
    let fields = [...this.state.Fields];
    let selected = [];
    if (!event.target.checked) {
      this.setState({ Selected: [] });
      return;
    }
    for (let i = 0; i < fields.length; i++) {
      selected = selected.concat(fields[i].ID)
    }
    this.setState({ Selected: selected });
  }

  handleUpdateFieldProperty = (fieldId, propertyName, value) => {
    let fields = [...this.state.Fields];
    const field = fields.find(f => f.ID === fieldId);
    if (field) {
      field[propertyName] = value;
      this.setState({fields});
      API.put(GetFieldPathForApi(this.props.organizationId, this.props.projectId, field.ID), field)
        .catch(this.handleApiError);
    }
  }

  handleSetDeleteConfirmationVisibility(show) {
    this.setState({ ShowDeleteConfirmation: show });
  }

  handleDelete() {
    this.handleSetDeleteConfirmationVisibility(false);
    this.setState({ ShowProgressIndicatorImmediately: true });
    if (this.state.Selected.length < 1) {
      return;
    }
    return API.delete(GetFieldsPathForApi(this.props.organizationId, this.props.projectId),
      { data: { IDs: this.state.Selected } })
      .then(resp => {
        this.handleLoadFields(true);
      })
      .catch(this.handleApiError);
  }

  handleOpenFieldPropertiesDialog(field) {
    this.setState({FieldPropertiesDialogIsOpen: true, DialogField: field});
    return true;
  }

  handleCloseFieldPropertiesDialog = () => {
    this.setState({FieldPropertiesDialogIsOpen: false});
    this.handleLoadFields(true);
  }

  handleRowClick = (e, field) => {
    if (e.ctrlKey) {
      this.handleSelect(field.ID);
    } else {
      this.handleOpenFieldPropertiesDialog(field);
    }
  }

  componentDidMount() {
    this.handleLoadFields(true);
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.handleLoadFields(true);
      }
      this.setState({open: this.props.open !== false});
    }
  }

  render() {
    const {
      open,
      Fields,
      Selected,
      ShowProgressIndicatorImmediately,
      DialogField,
      ShowAddDialog,
      // ShowUpdateDialog,
      ShowDeleteConfirmation,
      FieldPropertiesDialogIsOpen,
    } = this.state;
    const {
      organizationId,
      projectId,
      classes,
      closeCallback,
    } = this.props;

    let dialogActions = (
      <DialogActions>
        <Button onClick={() => this.handleClose(closeCallback)}>
          CLOSE
        </Button>
      </DialogActions>
    );

    let addDialogDetails = {
      Open:ShowAddDialog,
      Title:"Add field",
      RequireTextInput1:true,
      TextInput1Label:"Name",
      TextInput1PlaceHolder:"Invoice Number",
      CancelCallback:() => this.handleSetAddDialogVisibility(false),
      CloseCallback:() => this.handleSetAddDialogVisibility(false),
      ConfirmLabel:"ADD",
      ConfirmCallback:(name) => this.handleAdd(name),
    };

    let deleteConfirmationDialogDetails = {
      Open:ShowDeleteConfirmation,
      IsConfirmation:true,
      Title:`Delete field${(Selected.length > 1) ? "s" : ""}?`,
      BodyText:"This action cannot be undone.",
      BodyClassName:"warning",
      CancelCallback:() => this.handleSetDeleteConfirmationVisibility(false),
      CloseCallback:() => this.handleSetDeleteConfirmationVisibility(false),
      ConfirmCallback:() => this.handleDelete(),
    };

    let numSelected = Selected.length;
    let indeterminate = numSelected > 0 && numSelected < Fields.length;
    let allSelected = numSelected > 0 && numSelected === Fields.length;

    let progressIndicator = null;
    if (ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    let table = null;
    if (Fields.length > 0) {
      table = (
        <div className={classes.maxHeightFlexContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" className={classes.tableHead}>
                <Checkbox
                  color="secondary"
                  indeterminate={indeterminate}
                  checked={indeterminate || allSelected}
                  onChange={event => this.handleSelectAll(event)}
                />
              </TableCell>
              <TableCell padding="none" className={classes.tableHead}>Name</TableCell>
              <TableCell className={classes.tableHead}>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Fields.map(f => {
              const isSelected = Selected.indexOf(f.ID) !== -1;
              return (
                <TableRow
                  className={classes.tableRow}
                  hover
                  selected={isSelected}
                  onClick={e => this.handleRowClick(e, f)}
                  tabIndex={-1}
                  key={f.ID}
                >
                  <TableCell padding="checkbox" className={classes.checkboxColumn}>
                    <Checkbox checked={isSelected}
                      color="secondary"
                      onClick={e => { e.stopPropagation(); this.handleSelect(f.ID); }} />
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none">
                    {f.Name + ((f.Label) ? ` (${f.Label})` : "")}
                  </TableCell>
                  <TableCell>
                    {FieldType.filter(t => t.Type === f.Type)[0].Label}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        </div>
      );
    }

    return (
      <Dialog
        fullWidth={!IsMobile()}
        fullScreen={IsMobile()}
        maxWidth="md"
        // TransitionComponent={ConfigureTransition}
        open={open}
        onClose={this.handleClose}
        classes={{paper:classes.dialogPaper}}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description">
        {/* <DialogTitle id="dialog-title"> */}
        {/*   <span>Fields</span> */}
        {/* </DialogTitle> */}
        <DialogContent className={classes.dialogContent}>
          <MultiUseDialog Details={addDialogDetails} />
          <MultiUseDialog Details={deleteConfirmationDialogDetails} />
          {progressIndicator}
         
          <FieldPropertiesDialog
            organizationId={organizationId}
            projectId={projectId}
            open={FieldPropertiesDialogIsOpen}
            onApiError={this.handleApiError}
            closeCallback={this.handleCloseFieldPropertiesDialog}
            field={DialogField} />

          <div className={classes.dialogContentFlexContainer}>
            <EnhancedTableToolbar numSelected={numSelected}
              handleAdd={() => this.handleSetAddDialogVisibility(true)}
              handleDelete={() => this.handleSetDeleteConfirmationVisibility(true)} />
            {table}
          </div>
          
        </DialogContent>
        {dialogActions}
      </Dialog>
    );
  }
}

ConfigureFieldsDialog.propTypes = {
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onApiError: PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(ConfigureFieldsDialog);