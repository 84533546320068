import React from 'react';
import API from '../Util/api';
import { BytesToSize, NumberWithSeparators } from '../Util/NumberFormatting';

import {
  GetDocumentActionsPathForApi,
  GetProjectDocumentSubscriptionsPathForApi,
  GetProjectDocumentSubscriptionPathForApi,
  ProcessApiCallsGroupedByProjectForItems,
  GetDocumentContentPackagesPathForApi,
  GetDocumentContentPackagesPublicPathForApi,
  GetUserOrganizationProjectTaskDocumentContentPackagesPathForApi,
  GetUserOrganizationProjectApprovalDocumentContentPackagesPathForApi,
  GetUserOrganizationProjectApprovalTaskDocumentContentPackagesPathForApi,
  GetUserOrganizationProjectApprovalAssetItemDocumentContentPackagesPathForApi,
  GetUserOrganizationProjectApprovalAssetItemTaskDocumentContentPackagesPathForApi,
  GetUserOrganizationProjectDocumentFolderDocumentContentPackagesPathForApi,
} from './api';

import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import lightBlue from '@material-ui/core/colors/lightBlue';
import deepOrange from '@material-ui/core/colors/deepOrange';
import grey from '@material-ui/core/colors/grey';

export const GetExtensionAndOverlayColorFromFilename = filename => {
  const dot = filename && filename.lastIndexOf(".");
  const extension = (dot > -1) ? filename.substr(1 + dot).toLowerCase() : null;
  let overlayColor = grey[500];
  switch (extension) {
    // PDF
    case "pdf":
    overlayColor = red[500];
    break;
    // MS Word
    case "doc":
    case "docx":
    case "dot":
    case "dotx":
    case "dotm":
    case "docm":
    overlayColor = lightBlue[900];
    break;
    // MS Excel
    case "xls":
    case "xlsx":
    case "xlt":
    case "xla":
    case "xltx":
    case "xlsm":
    case "xltm":
    case "xlam":
    case "xlsb":
    overlayColor = green[800];
    break;
    // MS PowerPoint
    case "ppt":
    case "pptx":
    case "pot":
    case "pps":
    case "ppa":
    case "potx":
    case "ppsx":
    case "ppam":
    case "pptm":
    case "potm":
    case "ppsm":
    overlayColor = deepOrange[700];
    break;
    // MS Visio
    case "vsd":
    case "vss":
    case "vst":
    case "vdx":
    case "vsx":
    case "vtx":
    case "vsw":
    case "vsdx":
    case "vstx":
    case "vssx":
    case "vssm":
    case "vsdm":
    case "vstm":
    overlayColor = lightBlue[800];
    break;
    default:
    break;  
  }
  return {
    extension,
    overlayColor,
  }  
}

export const GetDocumentContentPackageUri = (organizationId, projectId, documentId, usePublicApi, userAssignmentContext, 
  documentFolderId, approvalId, taskId, assetId, assetItemId) => {

  let uri = (usePublicApi)
    ? GetDocumentContentPackagesPublicPathForApi(organizationId, projectId, documentId)
    : GetDocumentContentPackagesPathForApi(organizationId, projectId, documentId);
  switch (userAssignmentContext) {
    case "task":
      uri = GetUserOrganizationProjectTaskDocumentContentPackagesPathForApi(organizationId,
        projectId, taskId, documentId);
      break;
    case "approval":
      uri = GetUserOrganizationProjectApprovalDocumentContentPackagesPathForApi(organizationId,
        projectId, approvalId, documentId);
      break;
    case "approvalTask":
      uri = GetUserOrganizationProjectApprovalTaskDocumentContentPackagesPathForApi(organizationId,
        projectId, approvalId, taskId, documentId);
      break;
    case "approvalAssetItem":
      uri = GetUserOrganizationProjectApprovalAssetItemDocumentContentPackagesPathForApi(organizationId,
        projectId, approvalId, assetId, assetItemId, documentId);
      break;
    case "approvalAssetItemTask":
      uri = GetUserOrganizationProjectApprovalAssetItemTaskDocumentContentPackagesPathForApi(organizationId,
        projectId, approvalId, assetId, assetItemId, taskId, documentId);
      break;
    case "documentFolder":
      uri = GetUserOrganizationProjectDocumentFolderDocumentContentPackagesPathForApi(organizationId,
        projectId, documentFolderId, documentId);
      break;
    default:
      break;
    }
  return uri;
}

export function GetDocumentContentPackagePromise(uri, displayInline, preview, resolvedCallback,
  singlePage, requireSinglePage, pageIndex, ignoreMissingContentError, documentRevisionId) {
	
  const params = {
    displayInline,
    preview,
    singlePage,
    requireSinglePage,
    pageIndex,
    ignoreMissingContentError,
    documentRevisionId,
  };

  return API.get(uri, { params })
	  .then(resp => {
	  	if (resolvedCallback) {
	  		return resolvedCallback(resp.data);
	  	} else {
	    	return resp.data;
	  	}
	  });
}

export function GetExecuteDownloadPromise(uri) {
	return GetDocumentContentPackagePromise(uri)
	  .then(documentContentPackage => {
	    executeDownload(documentContentPackage.Url);
	  });
}

export function GetExecuteMultipleDownloadsPromise(uris, index = 0) {
	let maxPerSession = 10;
	if (uris.length > maxPerSession) {
		return Promise.reject(`The maximum number of downloads allowed at one time is ${maxPerSession}.`);
	}
	if (index >= uris.length) {
		return Promise.resolve();
	}

  const uri = uris[index];
	return GetDocumentContentPackagePromise(uri, false, false,
		(documentContentPackage) => {
    	executeDownload(documentContentPackage.Url);
    	index++;
    	return GetExecuteMultipleDownloadsPromise(uris, index);
  	});
}

function executeDownload(url) {
	const link = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function GetDocumentSubscription(organizationId, projectId, documentId) {
  return API.get(GetProjectDocumentSubscriptionPathForApi(organizationId, projectId, documentId))
    .then(resp => {
      return resp.data;
    });
}

export function GetPageCountForDisplay(pageCount) {
  return (pageCount > 0)
    ? `${NumberWithSeparators(pageCount)} Page${(pageCount > 1) ? "s" : ""}`
    : null; 
}

export function GetFileSizeSpan(fileSize) {
  const outFileSize = (fileSize > 0)
    ? BytesToSize(fileSize)
    : null;
    return (<span title={`${NumberWithSeparators(fileSize)} bytes`}>{outFileSize}</span>);
}

export function GetFileSizePageCountSpan(fileSize, pageCount) {
  let fileSizeSpan = GetFileSizeSpan(fileSize);
  let outPageCount = GetPageCountForDisplay(pageCount);
  let fileSizePageCount = null;
  if (fileSizeSpan || outPageCount) {
    fileSizePageCount = (
      <span>
        {fileSizeSpan}
        {(fileSizeSpan && outPageCount) ? ", " : ""}
        {outPageCount}
      </span>
    );
  }
  return fileSizePageCount;
}

export function SendDocumentsToRecycleBin(documents) {
  return ProcessApiCallsGroupedByProjectForItems(documents, 
    itemsByProject => API.post(
      `${GetDocumentActionsPathForApi(itemsByProject.OrganizationID, itemsByProject.ProjectID)}/sendToRecycleBin`,
        { IDs: itemsByProject.Items.map(i => i.ID) }));
}

export function RestoreDocumentsFromRecycleBin(documents) {
  return ProcessApiCallsGroupedByProjectForItems(documents, 
    itemsByProject => API.post(
      `${GetDocumentActionsPathForApi(itemsByProject.OrganizationID, itemsByProject.ProjectID)}/restoreFromRecycleBin`,
        { IDs: itemsByProject.Items.map(i => i.ID) }));
}

export function SetDocumentFolderForDocuments(documents, DocumentFolderID) {
  return ProcessApiCallsGroupedByProjectForItems(documents, 
    itemsByProject => API.post(
      `${GetDocumentActionsPathForApi(itemsByProject.OrganizationID, itemsByProject.ProjectID)}/setDocumentFolder`,
        { IDs: itemsByProject.Items.map(i => i.ID), DocumentFolderID, }));
}

export function CreateDocumentSubscriptions(documents) {
  return ProcessApiCallsGroupedByProjectForItems(documents, 
    itemsByProject => API.post(
      GetProjectDocumentSubscriptionsPathForApi(itemsByProject.OrganizationID, itemsByProject.ProjectID), 
      { DocumentIDs: itemsByProject.Items.map(i => i.ID) }));
}

export function DeleteDocumentSubscriptions(documents) {
  return ProcessApiCallsGroupedByProjectForItems(documents, 
    itemsByProject => API.delete(
      GetProjectDocumentSubscriptionsPathForApi(
      itemsByProject.OrganizationID, itemsByProject.ProjectID),
      { data: { DocumentIDs: itemsByProject.Items.map(i => i.ID) } }));
}