import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import CompleteIcon from '@material-ui/icons/CheckCircle';

import { AssetItemDocuments_Collection } from '../Model/AssetItemDocuments';
import { AssetItemTasks_Collection } from '../Model/AssetItemTasks';
import { AssetItemAssetItems_Collection } from '../Model/AssetItemAssetItems';
import { GlobalContext } from '../Context/Global.context';
import { IsMobile } from '../Util/MobileDetector';
import { IsMicrosoftWindows } from '../Util/MicrosoftWindowsDetector';

import ProgressIndicator from './ProgressIndicator';
import ItemSearchDialog from './ItemSearchDialog';
import {
  OpenDocumentDetailTab,
} from '../Util/Documents';
import API, {
  GetDocumentsPathForApi,
  GetUserOrganizationProjectApprovalAssetItemDocumentsPathForApi,
  GetAssetItemsPathForApi,
  GetAssetItemFieldsPathForApi,
  GetUserOrganizationProjectApprovalAssetItemFieldsPathForApi,
  GetAssetItemFieldsByAssetPathForApi,
  GetAssetItemFieldImageUploadsPathForApi,
  GetNewAssetItemFieldImageUploadsPathForApi,
  GetAssetItemCommentsPathForApi,
  GetUserOrganizationProjectApprovalAssetItemCommentsPathForApi,
} from '../Util/api';
import {
  GetTagsControl,
  GetTagListValuesFromTagsObject,
  GetTagsAndAssetItemTagsFromTagListValues,
} from '../Util/Tags';
import {
  GetSavedFilterState,
  SaveFilterStateToHistory,
} from '../Util/Filters';
import {
  GetAssetItem,
  ClearAndReloadDependentFieldValues,
} from '../Util/AssetItem';
import {
  GetDedupedValues,
  GetUpdatedFieldObjectForValueChange,
  GetUpdatedFieldObjectForImageChange,
  GetComposedFieldListLabelsAndValues,
  GetFieldPassesValidation,
} from '../Util/Field';
import {
  UpdateAssetFieldIDs,
} from '../Util/Asset';
import {
  UpdateAssetItem,
  GetAssetItemProgress,
} from '../Util/AssetItem';
import {
  AddAssetItemDocuments,
} from '../Util/AssetItemDocuments';
import {
  AddAssetItemTasks,
} from '../Util/AssetItemTasks';
import {
  AddAssetItemAssetItems,
} from '../Util/AssetItemAssetItems';
import NewAssetItem from '../Model/AssetItem';
import {
  HandlePreCaptureFieldGathering,
  GetPreCaptureFieldGatheringContent,
} from '../Util/PreCaptureFieldGathering';

import Comments from './Comments';
import DeviceCaptureDialog from './DeviceCaptureDialog';
import ItemCollectionBase from './ItemCollectionBase';
import AssetItemDialogGrid from './AssetItemDialogGrid';

import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import { UniqueIdDebouncer } from '../Util/Debounce';

const styles = theme => ({
  dialogPaper: {
    height:(!IsMobile()) ? "80%" : undefined,
  },
  dialogTitle: {
    paddingBottom:0,
  },
  buttonWithColor: {
    color: "#ffffff",
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  contentContainer: {
    height:"100%",
    display:"flex",
    flexDirection: "column",
  },
  paper: {
    overflowX:"hidden",
    overflowY:"auto",
    flexGrow:1,
  },
  tabs: {
  },
  tab: {
  },
  comments: {
    overflowY:"auto",
    height:"100%",
  },
});


class AssetItemDialog extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.InitialState = {
      AssetItemLoadComplete: false,
      SelectedTab: "properties",
      ShowCompletedTasks: false,
      DocumentThumbnailUrlsByDocumentId: [],
      ShowDeviceCaptureDialog: false,
      FabMenu_MouseY: null,
      FabMenu_MouseX: null,
      CompletedGET: {},
      FieldIDsAndValuesForCapture: null,
    };

    this.state = {
      ...this.InitialState,
      open: props.open,
      AssetItem: (props.assetItem && props.assetItem.ID)
        ? props.assetItem
        : {...NewAssetItem},
      AssetItemFields: [],
      ForcePrependItems: [],
      TagListValues: (props.assetItem && props.assetItem.ID)
        ? GetTagListValuesFromTagsObject(props.assetItem)
        : [],
      TaskDialogIsOpen:false,
      CreateNewTask: false,
      CreateNewAssetItem: false,
      AssetForNewAssetItem: null,
      PreMoveAssetItemFieldsJson: "",
      AssetItemItemsCollectionUpdateID: null,
      ForceAssetItemItemsRefresh: false,
      ShowProgressIndicatorImmediately: false,
    }

    this.BeginFileUploadFunc = null;
    this.AssetItemItemsCollection = null;
    this.CallerFilterState = {};
    this.FieldUniqueIdDebouncer = new UniqueIdDebouncer(250);
    this.PostFieldGatheringCaptureFunc = null;
    this.AdditionalBodyTextForFieldGatheringDialog = null;
  }

  handleClose = () => {
    this.setState({ open: false });
    this.restoreCallerFilterState();
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleGetAssetItemFields = () => {
    this.setState({ShowProgressIndicatorImmediately: true});

    const uri = (this.props.isCreateNew)
      ? GetAssetItemFieldsByAssetPathForApi(this.props.organizationId, this.props.projectId,
        this.props.assetId)
      : (this.props.isWorkspace && this.props.workspaceApprovalId)
        ? GetUserOrganizationProjectApprovalAssetItemFieldsPathForApi(this.props.organizationId,
          this.props.projectId, this.props.workspaceApprovalId, this.props.assetId, 
          this.state.AssetItem.ID)
        : GetAssetItemFieldsPathForApi(this.props.organizationId, this.props.projectId,
            this.props.assetId, this.state.AssetItem.ID);

    return API.get(uri)
      .then(resp => {
        this.handleUpdateAssetItemFieldsState(resp.data);
        this.setState({
          CompletedGET:{...this.state.CompletedGET, AssetItemFields: true},
          ShowProgressIndicatorImmediately: false,
        });
      })
      .catch(this.handleApiError);
  }

  handleFieldValueChange = fieldId => (e, selectedOptions) => {
    let assetItemFields = [...this.state.AssetItemFields];
    let assetItemField = assetItemFields.filter(aif => aif.FieldID === fieldId)[0];
    GetUpdatedFieldObjectForValueChange(assetItemField, e, selectedOptions);

    // If a dependent field is cleared, raise the value-changed handler for that fields
    const postFieldValueClearedAction = (clearedChildAssetItemField) => {
      this.updateServerAssetItemField(clearedChildAssetItemField);
    };
    // Clear any fields that are selection lists and a child of the current field
    ClearAndReloadDependentFieldValues(assetItemField, assetItemFields, false, postFieldValueClearedAction);

    this.handleUpdateAssetItemFieldsState(assetItemFields);
    this.updateServerAssetItemField(assetItemField);
  }

  handleFieldImageChange = fieldId => (imageObjectName, imageSignedUrl) => {
    let assetItemFields = [...this.state.AssetItemFields];
    let assetItemField = assetItemFields.filter(aif => aif.FieldID === fieldId)[0];
    GetUpdatedFieldObjectForImageChange(assetItemField, imageObjectName, imageSignedUrl);
    this.handleUpdateAssetItemFieldsState(assetItemFields);
    this.updateServerAssetItemField(assetItemField);
  }

  handleUpdateAssetItemFieldsState = AssetItemFields => {
    // Add/Update ListValues for each Fields[]
    for (let i = 0; i < AssetItemFields.length; i++) {
      let assetItemField = AssetItemFields[i];
      // if (updateFields) {
      //   assetItemField.UpdateId = new Date();
      // }
      if (typeof assetItemField.ListValues === "undefined") {
        Object.assign(assetItemField, { ListValues: [] });
      }
      // For multi-value fields, dedupe (for safety)
      assetItemField.Values = GetDedupedValues(assetItemField);
      // ListValues are for selection lists
      assetItemField.ListValues = GetComposedFieldListLabelsAndValues(assetItemField);
      // Values must exist as a JSON array
      if (Array.isArray(assetItemField.Values)) {
        assetItemField.Values = JSON.stringify(assetItemField.Values);
      }
    }
    this.setState({AssetItemFields});
  }

  updatePreviewMetadataAndPrimaryField = updatedAssetItemFields => {
    const assetItemFields = [...this.state.AssetItemFields];
    let assetItem = {...this.state.AssetItem};
    let previewMetadata = [];
    let primaryValue = null;
    let imageFieldIDs = [];
    let updatedThumbnailUrl = null;
    assetItemFields.forEach(aif => {
      const valuesAsArray = (aif.AllowMultipleValues && aif.Values)
        ? JSON.parse(aif.Values)
        : [aif.Value];
      const valuesAsString = valuesAsArray.join("; ");
      previewMetadata.push({
        "0": aif.FieldID,
        "1": aif.LabelOrName,
        "2": valuesAsString,
      });
      if (aif.IsPrimaryField) {
        primaryValue = (aif.AllowMultipleValues)
          ? valuesAsArray
          : valuesAsArray[0];
      }
      if (aif.Type === "FieldType_Image") {
        imageFieldIDs.push(aif.FieldID);
        if (updatedAssetItemFields) {
          const updatedFieldFinder = updatedAssetItemFields.filter(u => u.FieldID === aif.FieldID);
          if (updatedFieldFinder.length) {
            updatedThumbnailUrl = updatedFieldFinder[0].ImageSignedUrl;
          }
        }
      }
    });
    assetItem.PreviewMetadata = previewMetadata;
    if (primaryValue) {
      assetItem.Name = primaryValue;
    }
    if (imageFieldIDs.length === 1 && updatedThumbnailUrl !== null) {
      assetItem.ThumbnailUrl = updatedThumbnailUrl;
    }
    if (this.props.onAssetItemRevised) {
      this.props.onAssetItemRevised(assetItem);  
    }
    return assetItem;
  }

  handleCreate = () => {
    // Primary field check
    if (!this.checkPrimaryFieldAndRequiredFieldsForCompletion(true)) {
      return;
    }

    this.setState({ShowProgressIndicatorImmediately: true});
  
    let AssetItem = {...this.state.AssetItem};
    const assetItemForNewAssetItem = this.props.assetItemForNewAssetItem;
    if (assetItemForNewAssetItem && assetItemForNewAssetItem.AssetID
      && assetItemForNewAssetItem.ID) {
      if (!AssetItem.AssetItemTags) {
        AssetItem.AssetItemTags = [];
      }
      AssetItem.AssetItemTags = [
        ...AssetItem.AssetItemTags,
        {
          AssetID: assetItemForNewAssetItem.AssetID,
          AssetName: assetItemForNewAssetItem.AssetName,
          AssetItemID: assetItemForNewAssetItem.ID,
          AssetItemName: assetItemForNewAssetItem.Name,
        },
      ];
    }

    API.post(GetAssetItemsPathForApi(this.props.organizationId, this.props.projectId, 
      this.props.assetId), [{
        AssetItem,
        Fields: [...this.state.AssetItemFields],
      }])
      .then(resp => {
        let AssetItem = resp.data[0];
        // return API.put(GetAssetItemFieldsPathForApi(this.props.organizationId, this.props.projectId, 
        //   this.props.assetId, AssetItem.ID), [...this.state.AssetItemFields])
        //   .then(() => {
          this.setState({
            AssetItem,
          });
          AssetItem = this.updatePreviewMetadataAndPrimaryField();
          if (this.props.onCreated) {
            this.props.onCreated(AssetItem);
          }
          this.handleClose();
        //   });
      })
      .catch(this.handleApiError);
  }
  
  updateServerAssetItemField = assetItemField => {
    return this.FieldUniqueIdDebouncer.debounceByUniqueId(
      assetItemField.ID,
      () => {
        if (!this.state.AssetItem.ID) {
          return;
        }
        // Primary field check
        this.checkPrimaryFieldAndRequiredFieldsForCompletion(true);
    
        return API.put(GetAssetItemFieldsPathForApi(this.props.organizationId, this.props.projectId,
          this.props.assetId, this.state.AssetItem.ID), [assetItemField])
          .then(resp => {
            this.setState({AssetItem:this.updatePreviewMetadataAndPrimaryField(resp.data)});
          })
          .catch(this.handleApiError);
      }
    );
  };

  checkPrimaryFieldAndRequiredFieldsForCompletion = showErrorMessageWhenIncomplete => {
    let allComplete = true;
    // Primary Field
    let primaryFieldComplete = false;
    if (!primaryFieldComplete) {
      let primaryField;
      const primaryFieldFinder = this.state.AssetItemFields
        .filter(aif => aif.IsPrimaryField);
      if (primaryFieldFinder.length) {
        primaryField = primaryFieldFinder[0];
        primaryFieldComplete = (primaryField.AllowMultipleValues)
          ? primaryField.Values?.length > 0
          : primaryField.Value?.length > 0;
      }
      if (!primaryFieldComplete) {
        if (showErrorMessageWhenIncomplete) {
          this.handleApiError(`Please provide a value for ${
            (primaryField) ? primaryField.LabelOrName : "the primary field"}.`);
        }
        allComplete = false;
      }
    }
    // Required Fields
    if (this.state.AssetItemFields.length) {
      let incompleteFieldNames = [];
      this.state.AssetItemFields.forEach(aif => {
        if (!GetFieldPassesValidation(aif) && aif.Required) {
          incompleteFieldNames.push(aif.LabelOrName);
        }
      });
      if (incompleteFieldNames.length) {
        if (showErrorMessageWhenIncomplete) {
          this.handleApiError(`Please provide a value for required field${
            (incompleteFieldNames.length > 1) ? "s" : ""} ${
            incompleteFieldNames.join(", ")}.`);
        }
        allComplete = false;
      }
    }
    return allComplete;
  }

  handleStartMoveFieldInput = () => {
    const assetItemFields = [...this.state.AssetItemFields];
    if (assetItemFields.length > 1
      && assetItemFields[0].FieldRank === assetItemFields[1].FieldRank) {
      for (let i = 0; i < assetItemFields.length; i++) {
        assetItemFields[i].FieldRank = i * 1000;
      }
    }
    this.setState({PreMoveAssetItemFieldsJson:JSON.stringify(assetItemFields)});
  }

  handleMoveFieldInput = (sourceField, targetField) => {
    if (!sourceField || !targetField) {
      return;
    }
    // console.log("Source Field: ", sourceField.Name);
    // console.log("Target Field: ", targetField.Name);
    const AssetItemFields = [...this.state.AssetItemFields];
    const sourceAssetItemFieldFinder = AssetItemFields.filter(aif => aif.FieldID === sourceField.ID);
    if (!sourceAssetItemFieldFinder.length) {
      return;
    }
    const sourceAssetItemField = sourceAssetItemFieldFinder[0];
    
    const targetAssetItemFieldFinder = AssetItemFields.filter(aif => aif.FieldID === targetField.ID);
    if (!targetAssetItemFieldFinder.length) {
      return;
    }
    const targetAssetItemField = targetAssetItemFieldFinder[0];

    if (sourceAssetItemField.FieldID === targetAssetItemField.FieldID) {
      return;
    }

    const sourceIndex = AssetItemFields.indexOf(sourceAssetItemField);
    const targetIndex = AssetItemFields.indexOf(targetAssetItemField);
    if (sourceIndex === targetIndex)
      return;

    // console.log("Index of source: ", sourceIndex, " Index of target: ", targetIndex);
    if (sourceIndex === null || targetIndex === null)
      return;

    AssetItemFields.splice(targetIndex, 1);
    AssetItemFields.splice(sourceIndex, 0, targetAssetItemField);
    this.setState({ AssetItemFields });
  }

  handleAbortMoveFieldInput = () => {
    this.setState({ AssetItemFields: JSON.parse(this.state.PreMoveAssetItemFieldsJson) });
  }

  handleDropFieldInput = field => {
    if (!field) {
      return;
    }

    const fieldIDs = [...this.state.AssetItemFields].map(aif => aif.FieldID);
    UpdateAssetFieldIDs(this.props.organizationId, this.props.projectId, 
      this.props.assetId, fieldIDs)
      .catch(this.handleApiError);
  }

  handlePreCaptureFieldGathering = () => {
    const handlePreGatherFields = () => {
    }

    const handleCapture = fieldIDsAndValues => {
      this.PostFieldGatheringCaptureFunc(fieldIDsAndValues);
    }

    const {
      organizationId,
      projectId,
      workspaceApprovalId,
      isWorkspace,
    } = this.props;
    const {
      AssetItem,
    } = this.state;

    HandlePreCaptureFieldGathering(
      organizationId,
      projectId,
      this.handleApiError,
      () => this.state,
      state => this.setState(state),
      1,
      handlePreGatherFields,
      handleCapture,
      null,
      null,
      isWorkspace && workspaceApprovalId,
      isWorkspace && AssetItem,
    );
  }

  handleBeginDocumentCreation = fieldIDsAndValuesForCapture => {
    // Set some local variables for GetPreCaptureFieldGatheringContent
    this.PostFieldGatheringCaptureFunc = fieldIDsAndValues => this.handleBeginDocumentCreation(fieldIDsAndValues);
    this.AdditionalBodyTextForFieldGatheringDialog = null;

    const newDocument = {
      Name: "Untitled document",
      Origin: "Editor",
    };
    const params = {
      fieldIDsAndValues_json: (fieldIDsAndValuesForCapture) ? JSON.stringify(fieldIDsAndValuesForCapture) : undefined,
    };

    const {
      organizationId,
      projectId,
      workspaceApprovalId,
      isWorkspace,
    } = this.props;
    const {
      AssetItem
    } = this.state;

    const uri = (isWorkspace && workspaceApprovalId)
      ? GetUserOrganizationProjectApprovalAssetItemDocumentsPathForApi(organizationId, projectId, workspaceApprovalId,
          AssetItem.AssetID, AssetItem.ID)
      : GetDocumentsPathForApi(organizationId, projectId);

    const beginDocumentCreation = () => {
      this.setState({ShowProgressIndicatorImmediately: true});

      API.post(uri, [newDocument], { params })
        .then(newDocResp => {
          if (newDocResp && newDocResp.data && newDocResp.data.length) {
            const newDoc = newDocResp.data[0];
            AddAssetItemDocuments(this.props.organizationId, this.props.projectId, 
              this.props.assetId, this.state.AssetItem.ID, [newDoc])
              .then(addAssetItemDocumentsResp => {
                this.forceAssetItemItemsRefresh();
                OpenDocumentDetailTab(this.props.organizationId, this.props.projectId, newDoc);
              });
          }
        })
        .catch(this.handleApiError)
        .finally(() => {
          this.setState({ShowProgressIndicatorImmediately:false});
        });
    };

    if (!fieldIDsAndValuesForCapture) {
      this.handlePreCaptureFieldGathering();
    } else {
      beginDocumentCreation();
    }
  }

  handleBeginImageCapture = fieldIDsAndValuesForCapture => {
    if (IsMobile() || !IsMicrosoftWindows()) {
      return;
    }
    
    // Set some local variables for GetPreCaptureFieldGatheringContent
    this.PostFieldGatheringCaptureFunc = fieldIDsAndValues => this.handleBeginImageCapture(fieldIDsAndValues);
    this.AdditionalBodyTextForFieldGatheringDialog = "All documents will receive these values.";

    let extraState = {
      FieldIDsAndValuesForCapture: fieldIDsAndValuesForCapture || null,
    };

    const beginImageCapture = () => {
      this.handleSetShowDeviceCaptureDialog(true, extraState);
    };

    if (!fieldIDsAndValuesForCapture) {
      this.handlePreCaptureFieldGathering();
    } else {
      beginImageCapture();
    }
  }

  setItemsCollectionWhenReady = () => {
    if (!this.state.AssetItemLoadComplete
      || !this.context.CompletedGET.ProjectMembershipPackages) {
      setTimeout(() => this.setItemsCollectionWhenReady(), 250);
      return;
    }

    switch (this.state.SelectedTab) {
      case "documents":
        this.AssetItemItemsCollection = new AssetItemDocuments_Collection(
          this.props,
          this.context,
          state => this.setState(state),
          this.handleApiError,
          this.props.isWorkspace,
          !this.props.isWorkspace,
          
          Boolean(this.props.workspaceApprovalId),

          this.props.organizationId,
          this.props.projectId,
          this.state.AssetItem,

          this.props.workspaceApprovalId,

          this.getIsProjectMember(),
          () => { if (this.BeginFileUploadFunc) { this.BeginFileUploadFunc(); }},
          (!IsMobile() && IsMicrosoftWindows()) ? this.handleBeginImageCapture : null,
          (!this.getIsUserRestrictedToAssignedDocumentFolders())
            ? this.handleBeginDocumentCreation
            : null,
          () => this.handleSetShowAddExistingDialog(true),
          !this.props.isWorkspace,
          this.state.AssetItem.Archived || (!this.getIsProjectMember() && this.props.isWorkspace),
        );
        break;
      case "tasks":
        this.AssetItemItemsCollection = new AssetItemTasks_Collection(
          this.props,
          this.context,
          state => this.setState(state),
          this.handleApiError,
          this.props.onAlert,
          this.props.isWorkspace,
          !this.props.isWorkspace,
          
          Boolean(this.props.workspaceApprovalId),

          this.props.organizationId,
          this.props.projectId,
          this.state.AssetItem,

          this.props.workspaceApprovalId,

          this.getIsProjectMember(),
          () => this.setState({TaskDialogIsOpen:true,CreateNewTask:true}),
          () => this.handleSetShowAddExistingDialog(true),
          this.state.AssetItem.Archived || (!this.getIsProjectMember() && this.props.isWorkspace),
          this.state.ShowCompletedTasks,
        );
        break;
      case "assetItems":
        this.AssetItemItemsCollection = new AssetItemAssetItems_Collection(
          this.props,
          this.context,
          state => this.setState(state),
          this.handleApiError,
          this.props.onAlert,
          this.props.isWorkspace,
          !this.props.isWorkspace,
          
          Boolean(this.props.workspaceApprovalId),

          this.props.organizationId,
          this.props.projectId,
          this.state.AssetItem,

          this.props.workspaceApprovalId,

          this.getIsProjectMember(),
          this.handleBeginCreateNewAssetItem,
          () => this.handleSetShowAddExistingDialog(true),
          this.state.AssetItem.Archived || (!this.getIsProjectMember() && this.props.isWorkspace),
          this.props.assetsForAssetItemCreation || [],
        );
        break;
      default:
        break;
    }

    // This ensures ItemCollectionBase always sees the new collection
    this.setState({AssetItemItemsCollectionUpdateID: new Date()});
  }

  handleBeginCreateNewAssetItem = AssetForNewAssetItem => {
    this.setState({
      TaskDialogIsOpen:true,
      CreateNewAssetItem:true,
      AssetForNewAssetItem,
    });
  }

  handleSetShowDeviceCaptureDialog = (ShowDeviceCaptureDialog, extraState) => {
    this.setState({
      ...extraState,
      ShowDeviceCaptureDialog,
    });
    if (!ShowDeviceCaptureDialog) {
      this.forceAssetItemItemsRefresh();
    }
  }

  forceAssetItemItemsRefresh = () => {
    this.setState({ForceAssetItemItemsRefresh:true});
    setTimeout(() => this.setState({ForceAssetItemItemsRefresh:false}), 1);
  }

  handleSetShowAddExistingDialog = (ShowAddExistingDialog, itemType, selectedItems) => {
    this.setState({ShowAddExistingDialog});

    if (!ShowAddExistingDialog && itemType && selectedItems && selectedItems.length) {
      this.setState({ShowProgressIndicatorImmediately:true});
      switch (itemType) {
        case "task":
          AddAssetItemTasks(this.props.organizationId, this.props.projectId, 
            this.props.assetId, this.state.AssetItem.ID, selectedItems)
            .then(resp => {
              this.forceAssetItemItemsRefresh();
            })
            .catch(this.handleApiError)
            .finally(() => {
              this.setState({ShowProgressIndicatorImmediately:false});
            });
          break;
        case "document":
          AddAssetItemDocuments(this.props.organizationId, this.props.projectId, 
            this.props.assetId, this.state.AssetItem.ID, selectedItems)
            .then(resp => {
              this.forceAssetItemItemsRefresh();
            })
            .catch(this.handleApiError)
            .finally(() => {
              this.setState({ShowProgressIndicatorImmediately:false});
            });
          break;
        case "assetItem":
          AddAssetItemAssetItems(this.props.organizationId, this.props.projectId, 
            this.props.assetId, this.state.AssetItem.ID, selectedItems)
            .then(resp => {
              this.forceAssetItemItemsRefresh();
            })
            .catch(this.handleApiError)
            .finally(() => {
              this.setState({ShowProgressIndicatorImmediately:false});
            });
          break;
        default:
          break;
      }
    }
  }

  handleTabChange = (e, newValue) => {
    this.setState({SelectedTab: newValue});
  }

  handleTagListValuesChanged = tagListValues => {
    const {
      Tags,
      AssetItemTags,
    } = GetTagsAndAssetItemTagsFromTagListValues(tagListValues);
    let AssetItem = {...this.state.AssetItem};
    AssetItem.Tags = Tags;
    AssetItem.AssetItemTags = AssetItemTags;
    this.setState({AssetItem});
    if (!this.props.isCreateNew) {
      UpdateAssetItem(this.props.organizationId, this.props.projectId,
        AssetItem.AssetID, AssetItem)
        .catch(this.handleApiError);

      if (this.props.onAssetItemRevised) {
        this.props.onAssetItemRevised(AssetItem);  
      }
    }
  }

  handleShowProgressIndicatorImmediately = ShowProgressIndicatorImmediately => {
    this.setState({ShowProgressIndicatorImmediately});
  }

  handleApiError = err => {
    this.setState({ShowProgressIndicatorImmediately: false});
    this.props.onApiError(err);
  }

  saveCallerFilterState = () => {
    this.CallerFilterState = GetSavedFilterState(this.props,
      (this.props.location && this.props.location.state)
        ? this.props.location.state.Fields
        : [],
      "", true);
  }

  restoreCallerFilterState = () => {
    if (!this.props.pathnameForFilterStateRestore) {
      return;
    }
    SaveFilterStateToHistory(this.props.pathnameForFilterStateRestore,
      this.props, this.CallerFilterState);
  }

  getProjectMemberPackage = () => {
    return this.context.ProjectMembershipPackages.find(p => p.Project.ID === this.props.projectId);
  }

  getIsProjectMember = () => {
    return Boolean(this.getProjectMemberPackage());
  }

  getIsProjectAdmin = () => {
    const projectMemberPkg = this.getProjectMemberPackage();
    return Boolean(projectMemberPkg)
      && projectMemberPkg.IsAdmin;
  }

  getIsUserRestrictedToAssignedDocumentFolders = () => {
    const projectMemberPkg = this.getProjectMemberPackage();
    return Boolean(projectMemberPkg)
      && !projectMemberPkg.IsAdmin
      && projectMemberPkg.Project.Access.MemberAccess.DocumentFolders === "Assigned";
  }

  componentDidMount() {
    this.handleGetAssetItemFields();
    this.saveCallerFilterState();

    this.setState({AssetItemLoadComplete: true});
  }

  componentDidUpdate(prevProps, prevState) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      let stateToUpdate = {
        ...this.InitialState,
        open: this.props.open !== false,
      }
      if (this.props.open) {
        this.saveCallerFilterState();

        if (!this.props.isCreateNew && this.props.assetItem) {
          this.setState({ShowProgressIndicatorImmediately: true});
          GetAssetItem(this.props.organizationId, this.props.projectId, this.props.assetId, 
            this.props.assetItem.ID)
            .then(AssetItem => {
              this.setState({
                ...stateToUpdate,
                AssetItem,
                AssetItemLoadComplete: true,
                ShowProgressIndicatorImmediately: false,
              });
              this.handleGetAssetItemFields();
              this.setItemsCollectionWhenReady();
            })
            .catch(this.handleApiError);
        }
      } else {
        stateToUpdate.AssetItem = null;
        this.setState({stateToUpdate});
      }
    }

    if (prevState.SelectedTab !== this.state.SelectedTab
      || prevState.ShowCompletedTasks !== this.state.ShowCompletedTasks
    ) {
      this.setItemsCollectionWhenReady();
    }
  }

  render() {
    const {
      open,
      ShowProgressIndicatorImmediately,
      AssetItem,
      AssetItemFields,
      SelectedTab,
      TagListValues,
      ShowCompletedTasks,
      AssetItemItemsCollectionUpdateID,
      ForceAssetItemItemsRefresh,
      ShowDeviceCaptureDialog,
      ShowAddExistingDialog,
      FabMenu_MouseY,
      FabMenu_MouseX,
      ForcePrependItems,
      FieldIDsAndValuesForCapture,
      CompletedGET,
    } = this.state;
    const { 
      classes,
      theme,
      organizationId,
      projectId,
      projectName,
      assetId,
      assetName,
      isCreateNew,
      assetItemForNewAssetItem,
      isWorkspace,
      workspaceApprovalId,
      onAlert,
      onApprove,
      onDecline,
      onRestore,
      showProgressIndicatorImmediately,
      ...restProps
    } = this.props;

    const enableDragAndDrop = this.getIsProjectAdmin() && !IsMobile();

    const approveButton = (onApprove)
      ? (
        <Button className={classes.buttonWithColor} 
          style={{
            backgroundColor: green[700]
          }}
          onClick={() => onApprove()}>
          APPROVE
        </Button>
      ) : null;
    const declineButton = (onDecline)
      ? (
        <Button className={classes.buttonWithColor}
          style={{
            backgroundColor: red[700]
          }}
          onClick={() => onDecline()}>
          DECLINE
        </Button>
      ) : null;
    const restoreButton = (!isCreateNew && AssetItem.Archived && onRestore
      && SelectedTab === "properties")
      ? (
        <Button onClick={() => onRestore()}>
          RESTORE
        </Button>
      ) : null;
    const createButton = (isCreateNew)
      ? (
        <Button onClick={() => this.handleCreate()}
          disabled={!this.checkPrimaryFieldAndRequiredFieldsForCompletion()}
        >
          CREATE
        </Button>
      ) : null;
    const dialogActions = (
      <DialogActions>
        {approveButton}
        {declineButton}
        {restoreButton}
        <Button onClick={this.handleClose}>
          CLOSE
        </Button>
        {createButton}
      </DialogActions>
    );

    let progressIndicator = null;
    if (ShowProgressIndicatorImmediately || showProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator constrained showImmediately />
      );
    }

    const handleGetFieldImageUploadReservationUri = (aif => {
      return (isCreateNew)
        ? GetNewAssetItemFieldImageUploadsPathForApi(organizationId, projectId, assetId, aif.FieldID)
        : GetAssetItemFieldImageUploadsPathForApi(
          organizationId, projectId, assetId, AssetItem.ID, aif.FieldID);
    });

    const fieldGrid = (AssetItemFields) ? (
      <AssetItemDialogGrid
        organizationId={organizationId}
        projectId={projectId}
        AssetItemFields={AssetItemFields}
        disabled={AssetItem.Archived || isWorkspace}
        onGetFieldImageUploadReservationUri={handleGetFieldImageUploadReservationUri}
        onFieldValueChange={this.handleFieldValueChange}
        onFieldImageChange={this.handleFieldImageChange}
        enableDragAndDrop={enableDragAndDrop}
        onStartMoveFieldInput={this.handleStartMoveFieldInput}
        onMoveFieldInput={this.handleMoveFieldInput}
        onAbortMoveFieldInput={this.handleAbortMoveFieldInput}
        onDropFieldInput={this.handleDropFieldInput}
        onApiError={this.handleApiError}
        onAlert={onAlert}
        Fields={AssetItemFields}
      />
    ) : null;

    const tagsControl = (CompletedGET.AssetItemFields)
      ? GetTagsControl(organizationId, projectId, null, false,
        (isWorkspace && Boolean(workspaceApprovalId)), workspaceApprovalId, true,
        TagListValues, TagListValues => this.setState({TagListValues}),
        this.handleTagListValuesChanged, AssetItem.Archived || isWorkspace, -1, null, true)
      : null;

    const fieldsAndTags = (
      <Grid container direction="column" spacing={3}>
        <Grid item>
          {fieldGrid}
        </Grid>
        <Grid item style={{width:(!IsMobile()) ? "50%" : undefined}}>
          {tagsControl}
        </Grid>
      </Grid>
    );

    const deviceCaptureDialog = (!isCreateNew && AssetItem && !IsMobile() && IsMicrosoftWindows()) ? (
        <DeviceCaptureDialog
          open={ShowDeviceCaptureDialog}
          organizationId={organizationId}
          projectId={projectId}
          approvalId={workspaceApprovalId}
          assetId={assetId}
          assetItemId={AssetItem.ID}
          isWorkspace={isWorkspace}
          fieldIDsAndValues={FieldIDsAndValuesForCapture}
          closeAfterFirstSubmit
          onApiError={this.handleApiError}
          onAlert={onAlert}
          onClose={() => this.handleSetShowDeviceCaptureDialog(false)}
        />
      ) : null;

    const getAddExistingDialog = (itemType, itemPluralTitle, onClose) => {
      return (this.getIsProjectMember() && ShowAddExistingDialog) ? (
        <ItemSearchDialog
          {...restProps}
          open
          itemType={itemType}
          dialogTitle={`Tag existing ${itemPluralTitle.toLowerCase()} with ${(AssetItem.Name) ? AssetItem.Name + " (" + assetName + ")" : ""}`}
          confirmButtonText={`TAG ${itemPluralTitle.toUpperCase()}`}
          onClose={onClose}
          onAlert={onAlert}
          organizationId={organizationId}
          projectId={projectId}
          isProjectMember={true}
          isWorkspace={isWorkspace}
        />
      ) : null;
    }

    const toolHeaderRightContent = (SelectedTab === "tasks")
      ? (
        <FormControlLabel
          style={{
            fontSize:10,
          }}
          control={
            <Switch
              color="secondary"
              checked={ShowCompletedTasks}
              onChange={e => this.setState({ShowCompletedTasks: e.target.checked})}
            />
          }
          label="Completed"
        />
      )
      : null;

    const itemCollectionBase = (!isCreateNew && this.AssetItemItemsCollection
      && AssetItemItemsCollectionUpdateID) ? (
      <ItemCollectionBase
        {...restProps}

        returnContentOnly

        contentUri={this.AssetItemItemsCollection.ContentUri}
        contentUriParams={this.AssetItemItemsCollection.ContentUriParams}
        collectionName={this.AssetItemItemsCollection.CollectionName}
        itemsName={this.AssetItemItemsCollection.ItemsName}
        itemName={this.AssetItemItemsCollection.ItemName}
        defaultViewType={this.AssetItemItemsCollection.DefaultViewType}
        
        onGetCollectionFieldsPromise={this.AssetItemItemsCollection.HandleGetCollectionFieldsPromise}
        onGetHeadCells={this.AssetItemItemsCollection.HandleGetHeadCells}
        onGetCardGridItems={this.AssetItemItemsCollection.HandleGetCardGridItems}
        onGetTableRows={this.AssetItemItemsCollection.HandleGetTableRows}
        singleLineTableCells={this.AssetItemItemsCollection.SingleLineTableCells}

        hideSensitiveFields={this.AssetItemItemsCollection.HideSensitiveFields}
        hideFilterSortDrawer={this.AssetItemItemsCollection.HideFilterSortDrawer}
        skipFilterSavedState={true}
        hideSearchAllFilter

        dialogContent={this.AssetItemItemsCollection.HandleGetDialogContent(this.state)}

        loadItemsImmediately
        organizationId={organizationId}
        projectId={projectId}
        // apiError={ApiError}
        // alert={Alert}
        onAlert={onAlert}
        // onRefresh={this.AssetItemItemsCollection.HandleRefresh}
        // onItemsChanged={this.AssetItemItemsCollection.HandleItemsChanged}
        forcePrependItems={ForcePrependItems}
        forceRefresh={ForceAssetItemItemsRefresh}
        // includeItemIds={(this.props.match.params.collectionItemID) ? [this.props.match.params.collectionItemID] : undefined}
        allowSelect={this.AssetItemItemsCollection.AllowSelect}
        canSelectItem={this.AssetItemItemsCollection.CanSelectItem}
        allowFabOnDesktop
        fabTop={(IsMobile()) ? 112 : 100}
        fabLeft={theme.spacing(3)}
        fabSize={(IsMobile()) ? "small" : "medium"}
        // fabPosition={(!IsMobile()) ? "absolute" : undefined}
        fabPosition="absolute"
        onFabClick={(!isWorkspace) ? this.AssetItemItemsCollection.HandleCreateNew : undefined}
        onGetFabMenu={this.AssetItemItemsCollection.HandleGetFabMenu}
        fabMenuCoords={{
          FabMenu_MouseY,
          FabMenu_MouseX,
        }}
        itemListContainerStyle={{overflowY:(IsMobile()) ? "scroll" : undefined}}
        itemListStyle={{paddingLeft:0}}
        captureReservationParams={{
          assetId,
          assetItemId: (AssetItem) ? AssetItem.ID : undefined,
        }}
        additionalToolHeaderRightContent={toolHeaderRightContent}
        toolHeaderStyle={{
          paddingRight:0,
        }}
        toolHeaderControlSize={(IsMobile()) ? "small" : undefined}
        approvalIdForApprovalAssignmentContext={workspaceApprovalId}
        assetItemForAssetItemContext={AssetItem}
        onSetUpdateRevisedItemFunction={this.AssetItemItemsCollection.HandleSetUpdateRevisedItemFunction}
        onSetBeginFileUploadFunc={f => this.BeginFileUploadFunc = f}
        // showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
      />
    ) : null;

    const tasksContent = (
      <React.Fragment>
        {itemCollectionBase}
        {
          getAddExistingDialog(
            "task",
            "Tasks",
            selectedItems => this.handleSetShowAddExistingDialog(false, "task", selectedItems)
          )
        }
      </React.Fragment>
    );

    const preCaptureFieldGatheringContent = GetPreCaptureFieldGatheringContent(
      organizationId,
      projectId,
      () => this.state,
      state => this.setState(state),
      this.handleApiError,
      onAlert,
      this.PostFieldGatheringCaptureFunc,
      this.AdditionalBodyTextForFieldGatheringDialog,
      null,
      null,
      isWorkspace && workspaceApprovalId,
      isWorkspace && AssetItem,
    );

    const documentsContent = (
      <React.Fragment>
        {itemCollectionBase}
        {deviceCaptureDialog}
        {preCaptureFieldGatheringContent}
        {
          getAddExistingDialog(
            "document",
            "Documents",
            selectedItems => this.handleSetShowAddExistingDialog(false, "document", selectedItems)
          )
        }
      </React.Fragment>
    );

    const assetItemsContent = (
      <React.Fragment>
        {itemCollectionBase}
        {
          getAddExistingDialog(
            "assetItem",
            "Assets",
            selectedItems => this.handleSetShowAddExistingDialog(false, "assetItem", selectedItems)
          )
        }
      </React.Fragment>
    );

    const commentsContent = (!isCreateNew && AssetItem)
      ? (
        <div className={classes.comments}>
          <Comments
            organizationId={organizationId}
            projectId={projectId}
            newCommentBottomFixed={!IsMobile()}
            commentsUri={
              (isWorkspace && workspaceApprovalId)
                ? GetUserOrganizationProjectApprovalAssetItemCommentsPathForApi(organizationId, projectId,
                    workspaceApprovalId, AssetItem.AssetID, AssetItem.ID)
                : GetAssetItemCommentsPathForApi(organizationId, projectId, AssetItem.AssetID, AssetItem.ID)
            }
            collectionName="AssetItemEvents"
            autoFocus
            newCommentAdditionalProperties={{AssetID: AssetItem.AssetID, AssetItemID: AssetItem.ID}}
            onApiError={this.handleApiError}
          />
        </div>
      )
      : null;

    const tabs = (!isCreateNew)
      ? (
        <Tabs
          variant={(IsMobile()) ? "scrollable" : "fullWidth"}
          className={classes.tabs}
          value={SelectedTab}
          onChange={this.handleTabChange}
        >
          <Tab label="Properties" value="properties" className={classes.tab}
            style={{
              marginLeft:(IsMobile()) ? 32 : undefined,
            }}
          />
          <Tab label="Tasks" value="tasks" className={classes.tab} />
          <Tab label="Documents" value="documents" className={classes.tab} />
          <Tab label="Assets" value="assetItems" className={classes.tab} />
          <Tab label="Comments" value="comments" className={classes.tab} />
        </Tabs>
      ) : null;

    let tabContent;
    switch (SelectedTab) {
      case "properties":
        tabContent = fieldsAndTags;
        break;
      case "tasks":
        tabContent = tasksContent;
        break;
      case "documents":
        tabContent = documentsContent;
        break;
      case "assetItems":
        tabContent = assetItemsContent;
        break;
      case "comments":
        tabContent = commentsContent; 
        break;
      default:
        break;
    }

    let content = (
      <div className={classes.contentContainer}>
        {tabs}
        <Paper elevation={0} className={classes.paper}
          style={{
            paddingTop:
              (isCreateNew || SelectedTab !== "properties")
                ? theme.spacing(1)
                : theme.spacing(3)
            ,
          }}>
          {tabContent}
        </Paper>
      </div>
    );

    const progress = GetAssetItemProgress(AssetItem);
    const dialogTitle = (isCreateNew)
      ? `Create ${assetName}${
        (projectName) ? " in " + projectName : ""
      }${
        (assetItemForNewAssetItem) ? " for " + assetItemForNewAssetItem.Name
          + " (" + assetItemForNewAssetItem.AssetName  + ")" : ""
      }`
      : `${(AssetItem.Name) ? AssetItem.Name + " (" + assetName + ")" : assetName}${
        (AssetItem.Archived) ? " (archived)" : ""
      }${
        (progress) ? " (" + progress + ")" : ""
      }`;
    const dialogTitleComponent = (AssetItem.IsComplete)
      ? (
        <Grid container spacing={1} style={{alignItems:"center"}}>
          <Grid item style={{height:32}}>
            <CompleteIcon style={{color:green[500]}} />
          </Grid>
          <Grid item>
            <Typography variant="h6">
              {dialogTitle}
            </Typography>
          </Grid>
        </Grid>
      )
      : dialogTitle;

    return (
      <Dialog
        fullWidth={!IsMobile()}
        fullScreen={IsMobile()}
        maxWidth="lg"
        open={open}
        classes={{
          paper:classes.dialogPaper,
        }}
        onClose={this.handleClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description">
        <DialogTitle id="dialog-title" className={classes.dialogTitle}>
          {dialogTitleComponent}
        </DialogTitle>
        <DialogContent>
          {progressIndicator}
          {content}
        </DialogContent>
        {dialogActions}
      </Dialog>
    );
  }
}

AssetItemDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  projectName: PropTypes.string,
  assetId: PropTypes.string.isRequired,
  assetName: PropTypes.string.isRequired,
  assetItem: PropTypes.object,
  assetsForAssetItemCreation: PropTypes.array,
  assetItemForNewAssetItem: PropTypes.object,
  onAssetItemRevised: PropTypes.func,
  onApiError: PropTypes.func.isRequired,
  onAlert: PropTypes.func.isRequired,
  pathnameForFilterStateRestore: PropTypes.string,
  isCreateNew: PropTypes.bool,
  isWorkspace: PropTypes.bool,
  workspaceApprovalId: PropTypes.string,
  onCreated: PropTypes.func,
  onClose: PropTypes.func,
  onApprove: PropTypes.func,
  onDecline: PropTypes.func,
  onRestore: PropTypes.func,
  showProgressIndicatorImmediately: PropTypes.bool,
};

export default withStyles(styles, {withTheme: true})(AssetItemDialog);
