import { GetFieldForFilterAndSort } from '../Util/Field';

const FieldCollectionFields = [
	GetFieldForFilterAndSort(
		"Meta_date_str256[CreatedOn].string",
		"Created On",
		"FieldType_Date",
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[Name].keyword",
		"Name",
		"FieldType_Text",
		"Meta_text_kw256lc[Name]",
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[LabelOrName].keyword",
		"Label",
		"FieldType_Text",
		"Meta_text_kw256lc[LabelOrName]",
	),
	GetFieldForFilterAndSort(
		"Meta_kw256[FieldType]",
		"Type",
		"FieldType_Text",
		"Meta_kw256[FieldType]",
		[
			{
			    value: "equals",
			    label: "equals",
			},
		],
    	"documentFieldTypes",
	),
];

export default FieldCollectionFields;