import debounce from 'es6-promise-debounce';
import API from '../Util/api';
import {
  GetOrganizationMemberPath,
} from './PathHelper';
import {
  GetOrganizationMembersPathForApi,
} from './api';

import {
  GetUserValue,
} from './Properties';

export const HandleRouteToOrganizationMember = (props, organizationId, organizationMemberId, openInNewTab) => {
  const uri = GetOrganizationMemberPath(organizationId, organizationMemberId);
  if (openInNewTab) {
    window.open(uri, "n1_organizationMemberContent")
  } else {
    props.history.push(uri, { ...props.location.state });  
  }
}

export const GetOrganizationMemberListValuesPromise = debounce((organizationId, filter, emailForValue, onApiError) => {
  let params = {
    sortType: "Meta_text_kw256lc[Name].keyword",
  };
  if (filter) {
    params.fullTextFilter = filter;
  }
  
  return API.get(GetOrganizationMembersPathForApi(organizationId), { params })
    .then(resp => {
      if (!resp.data) {
        return null;
      }
      let listValues = resp.data.OrganizationMembers
        .map(om => {
          return {
            value: (emailForValue) ? om.UserEmail : om.ID,
            label: GetUserValue(om.UserEmail, (om.UserName) ? `${om.UserName} (${om.UserEmail})` : om.UserEmail),
          };
        });
      return listValues;
    })
    .catch(err => {
      if (onApiError) {
        onApiError(err);
      }
    });
}, 150);