import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import SelectControl from './SelectControl';
import AsyncSelectControl from './AsyncSelectControl';

import ProgressIndicator from './ProgressIndicator';
import API from '../Util/api';
import FolderHierarchyItemType from '../Model/FolderHierarchyItemType';
import FieldValueType from '../Model/FieldValueType';

const styles = theme => ({
  grid: {
    marginTop: theme.spacing(1),
    overflowY: "inherit",
    marginBottom:0,
  },
});

const NewFolderHierarchyItem = {
    ID: "",
    FolderHierarchyID: "",
    Type: "FolderHierarchyItemType_Field",
    FieldID: "",
  };

class FolderHierarchyItemPropertiesDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      FolderHierarchyItem: NewFolderHierarchyItem,
      Field: null,
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
    }
  }

  handleClose(closeCallback, isCloseOrCancel) {
    this.setState({ 
      FolderHierarchyItem: NewFolderHierarchyItem, 
      Field: null, 
      ShowProgressIndicator:false,
      ShowProgressIndicatorImmediately: false,
      open: false,
    });
    if (typeof closeCallback !== "undefined" && closeCallback) {
      closeCallback(isCloseOrCancel);
    }
  }

  handleApiError = err => {
    this.setState({
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
    });
    this.props.onApiError(err);
  }

  setFolderHierarchyItemState(folderHierarchyItem, additionalState) {
    this.setState({FolderHierarchyItem: folderHierarchyItem, ...additionalState});
    switch(folderHierarchyItem.Type) {
      case "FolderHierarchyItemType_Field":
        this.handleGetFieldAndUpdateFolderHierarchyItem(folderHierarchyItem);
      break;
      default:
      break;
    }
  }

  handleLoadFolderHierarchyItem() {
    this.setState({ShowProgressIndicatorImmediately:true});
    API.get("/folderHierarchies/" + this.props.folderHierarchyItem.FolderHierarchyID + "/items/" + this.props.folderHierarchyItem.ID)
      .then(resp => {
        let folderHierarchyItem = resp.data;
        this.setFolderHierarchyItemState(folderHierarchyItem, {
          ShowProgressIndicatorImmediately:false
        });
      })
      .catch(this.handleApiError);
  }

  handleUpdateFolderHierarchyItem(closeCallback) {
    this.setState({ShowProgressIndicator:true});
    API.put("/admin/folderHierarchies/" + this.props.folderHierarchyItem.FolderHierarchyID + "/items/" + this.props.folderHierarchyItem.ID
      , this.state.FolderHierarchyItem)
    .then(resp => {
      this.handleClose(closeCallback, false);
    })
    .catch(this.handleApiError);
  }

  handleGetFieldAndUpdateFolderHierarchyItem(folderHierarchyItem) {
    if (folderHierarchyItem.FieldID) {
      return API.get("/fields/" + folderHierarchyItem.FieldID)
        .then(resp => {
          folderHierarchyItem.FieldListValue = { 
            label: resp.data.LabelOrName,
            value: resp.data.ID, 
          };
          folderHierarchyItem.FieldName = resp.data.LabelOrName;
          folderHierarchyItem.FieldType = resp.data.Type;
          this.setState({FolderHierarchyItem: folderHierarchyItem, Field: resp.data, ShowProgressIndicator:false});
        })
        .catch(this.handleApiError);
    }
  }

  handleGetFieldListValuesPromise(filter) {
    let parameters = { getAll: true };
    if (filter) {
      parameters.filter = filter;
    }
    return API.get("/fields",
      { 
        params: parameters
      }
    )
      .then(resp => {
        if (!resp.data) {
          return null;
        }
        let fields = [];
        resp.data.Fields.forEach(f => {
          switch (f.Type) {
            case "FieldType_Bool":
              // Not valid for folder hierarchies
              break;
            default:
              fields.push({ value: f.ID, label: f.LabelOrName });
              break;
          }
        });
        return fields;
      })
      .catch(this.handleApiError);
  }

  composeListValue(label, value) {
    return { label: label, value: value};
  }

  handleStringChange(propertyName, string) {
    let item = {...this.state.FolderHierarchyItem};
    item[propertyName] = string;
    this.setState({FolderHierarchyItem: item});
  }

  handleFieldChange(selectedOptions) {
    let id = (selectedOptions) ? selectedOptions.value : "";
    let label = (selectedOptions) ? selectedOptions.label : "";
    let item = {...this.state.FolderHierarchyItem};
    item.FieldID = id;
    item.FieldName = label;
    item.FieldListValue = { 
      label: (selectedOptions) ? label : null, 
      value: (selectedOptions) ? id : null, 
    };
    this.setFolderHierarchyItemState(item);
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.handleLoadFolderHierarchyItem();
      }
      this.setState({open: this.props.open !== false});
    }
  }

  render() {
    const {
      open,
      FolderHierarchyItem,
      Field,
      ShowProgressIndicator,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const { 
      classes,
      // theme,
      closeCallback,
    } = this.props;

    let dialogActions = (
      <DialogActions>
        <Button onClick={() => this.handleClose(closeCallback, true)}>
          CLOSE
        </Button>
        <Button onClick={() => this.handleUpdateFolderHierarchyItem(closeCallback)}>
          SAVE
        </Button>
      </DialogActions>
    );

    let progressIndicator = null;
    if (ShowProgressIndicator || ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    const itemTypeOptions = FolderHierarchyItemType.map(f => ({ label: f.Label, value: f.Type }));
    let itemTypeGridItem = (
      <Grid item xs={12}>
        <SelectControl
          id="select_folderType"
          label="Folder Type" 
          forceShrinkLabel
          hideEmpty
          options={itemTypeOptions} 
          value={FolderHierarchyItem.Type}
          onValueChange={type => this.handleStringChange("Type", type)} />
      </Grid>
      );

    let itemTypeGridItems = [];
    switch (FolderHierarchyItem.Type) {
      case "FolderHierarchyItemType_Field":
        itemTypeGridItems.push(
          <Grid item xs={12} key="FolderHierarchyItemType_Field">
            <AsyncSelectControl label="Index Field" 
              forceShrinkLabel
              // floatingOptions
              onGetOptionsFilterPromise={filter => this.handleGetFieldListValuesPromise(filter)} 
              value={FolderHierarchyItem.FieldListValue}
              onValueChange={selectedOptions => this.handleFieldChange(selectedOptions)} />
          </Grid>
          );
        break;
      default:
        break;
    }

    let optionGridItems = [];
    if (Field) {
      switch (FolderHierarchyItem.Type) {
        case "FolderHierarchyItemType_Field":
          switch (Field.Type) {
            case "FieldType_Text":
            const textFieldValueTypeOptions = FieldValueType
              .filter(f => f.FieldType === "All" || f.FieldType === "Text")
              .map(f => ({ label: f.Label, value: f.Type }));
            let textFieldValueType = FolderHierarchyItem.FieldValueType || "FieldValueType_FullValue";
            optionGridItems.push(
              <Grid item xs={12} key="FolderHierarchyItemType_TextField_ValueType">
                <SelectControl
                  id="select_textFieldValueType"
                  label="Value Type" 
                  forceShrinkLabel
                  hideEmpty
                  options={textFieldValueTypeOptions} 
                  value={textFieldValueType}
                  onValueChange={type => this.handleStringChange("FieldValueType", type)} />
              </Grid>
              );
            break;
            case "FieldType_Date":
            const dateFieldValueTypeOptions = FieldValueType
              .filter(f => f.FieldType === "All" || f.FieldType === "Date")
              .map(f => ({ label: f.Label, value: f.Type }));
            let dateFieldValueType = FolderHierarchyItem.FieldValueType || "FieldValueType_FullValue";
            optionGridItems.push(
              <Grid item xs={12} key="FolderHierarchyItemType_DateField_ValueType">
                <SelectControl
                  id="select_dateFieldValueType"
                  label="Value Type" 
                  forceShrinkLabel
                  hideEmpty
                  options={dateFieldValueTypeOptions} 
                  value={dateFieldValueType}
                  onValueChange={type => this.handleStringChange("FieldValueType", type)} />
              </Grid>
              );
            break;
            default:
            break;
          }       
          break;
        default:
          break;
      }
    }

    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={() => this.handleClose(closeCallback, true)}
        // aria-labelledby="dialog-title"
        // aria-describedby="dialog-description">
        // <DialogTitle id="dialog-title">
        //   <span>{FolderHierarchyItem.ID}</span>
        // </DialogTitle>
        >
        <DialogContent>
          {progressIndicator}
          
          <Grid container direction="column" spacing={3} className={classes.grid}>
            
            {itemTypeGridItem}
            {itemTypeGridItems}
            {optionGridItems}

          </Grid>

        </DialogContent>
        {dialogActions}
      </Dialog>
    );
  }
}

FolderHierarchyItemPropertiesDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(FolderHierarchyItemPropertiesDialog);