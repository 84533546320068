import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Card from '../../../Components/Card';

import {
  GetDateValue,
} from '../../../Util/Properties';

class ProcessCard extends Component {
  render() {
    const {
      Process,
      onCardAction,
      isSearchResults,
      style,
      height,
    } = this.props; 

    let metadata = [
      ["Project", Process.ProjectName],
      ["Created On", GetDateValue(Process.CreatedOn)],
    ];

    return (
      <Card
        style={style}
        height={height}
        onCardAction={onCardAction}
        name={Process.Name}
        metadata={metadata}
        isSearchResults={isSearchResults}
        contentItem={Process}
      />
    );
  }
}

ProcessCard.propTypes = {
  Process: PropTypes.object.isRequired,
  onCardAction: PropTypes.func.isRequired,
};

export default ProcessCard;