import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Card from './Card';

import {
  GetDateValue,
  // GetBoolValue,
} from '../Util/Properties';
import {
  GetBodyHtmlWithModifiedAnchors,
} from '../Util/Regex';

const styles = theme => ({
  Description: {
    "& a": {
      color:theme.palette.text.primary,
    },
  },
});

class NotificationCard extends Component {
  render() {
    const {
      UserNotification,
      onCardAction,
      style,
      height,
      classes,
      minHeight,
      isSearchResults,
      onSelect,
      selected,
      theme,
    } = this.props; 

    let metadata = [
      ["Project", UserNotification.ProjectName],
      ["Created On", GetDateValue(UserNotification.CreatedOn)],
      // ["Read", GetBoolValue(UserNotification.IsRead, true)],
    ];

    const description = (
      <div
        className={classes.Description}
        dangerouslySetInnerHTML={{
        __html: GetBodyHtmlWithModifiedAnchors(UserNotification.BodyHtml, theme, true)
        }}
      />
    );

    return (
      <Card
        style={style}
        height={height}
        minHeight={minHeight}
        onCardAction={onCardAction}
        name={UserNotification.Subject}
        nameFontWeight={UserNotification.IsRead ? 300 : undefined}
        metadata={metadata}
        description={description}
        onSelect={onSelect}
        selected={selected}
        isSearchResults={isSearchResults}
        contentItem={UserNotification}
        translucentActionBar
      />
    );
  }
}

NotificationCard.propTypes = {
  UserNotification: PropTypes.object.isRequired,
  onCardAction: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(NotificationCard);