import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import Collection from '../Model/Collection';

import ItemTableRow from '../Components/ItemTableRow';
import TaskMilestoneCard from '../Components/TaskMilestoneCard';
import TaskMilestoneCollectionFields from '../Model/TaskMilestoneCollectionFields';

import MultiUseDialog from '../Components/MultiUseDialog';
import TaskMilestoneCreationDialog from '../Components/TaskMilestoneCreationDialog';

import API from '../Util/api';

import {
  GetTaskMilestonesPathForApi,
  GetTaskMilestonePathForApi,
} from '../Util/api';
import {
  GetDateValue,
  GetUserValue,
} from '../Util/Properties';
import { 
  GetProjectTaskMilestonesPath,
  GetProjectTaskMilestonePath,
} from '../Util/PathHelper';

export class TaskMilestones_Collection extends Collection {
  constructor(props, onSetState, onApiError, isWorkspace, isProjects, organizationId, projectId, 
    onItemTitleChange) {

    if (typeof organizationId !== "string") {
      console.log("Warning: organizationId required and not found");
    }
    if (typeof projectId !== "string") {
      console.log("Warning: projectId required and not found");
    }
    super(props, onSetState, onApiError, isWorkspace, isProjects, organizationId, projectId);

    this.handleItemTitleChange = onItemTitleChange;

    this.PageTitle = "Task Milestones";
    this.ContentUri = GetTaskMilestonesPathForApi(organizationId, projectId);
    this.CollectionName = "TaskMilestones";
    this.ItemsName = "TaskMilestones";
    this.ItemName = "Task Milestone";
    this.AllowSelect = true;
    this.DefaultViewType = "List";
    this.TaskMilestonesForDialog = [];

    this.HideFilters = true;
  }

  HandleGetCollectionFieldsPromise = () => Promise.resolve(TaskMilestoneCollectionFields);

  HandleGetHeadCells = (items, sensitiveFields) => {
    let headCells = [
      { id: 'Name', sortId: 'NameLower', numeric: false, label: 'Name' },
      { id: 'CreatedOn', sortId: 'CreatedOn', numeric: false, label: 'Created On' },
      { id: 'CreatedByUserEmail', sortId: 'CreatedByUserEmail', numeric: false, label: 'Created By' },
      { id: 'Index', sortId: 'Index', numeric: true, label: 'Position' },
    ];
    if (!this.ProjectID) {
      headCells.push(
        { id: 'ProjectName', numeric: false, label: "Project" },
      );
    }
    return headCells;
  }

  handleRouteToTaskMilestoneDialog = taskMilestoneId => {
    this.props.history.push(GetProjectTaskMilestonePath(this.ProjectID, taskMilestoneId), 
      { ...this.props.location.state });
  }

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length) 
      ? items
        .map(i => {
          return (
            <Grid item key={i.ID} sm={12} md={6} lg={4} xl={3} className={classes.cardGridItem}>
              <TaskMilestoneCard
                TaskMilestone={i}
                onCardAction={() => this.handleRouteToTaskMilestoneDialog(i.ID)}
                onSelect={() => onSelect(i.ID)}
                selected={selectedIDs.indexOf(i.ID) > -1}
              />
            </Grid>
          );
        }) 
      : [];
  }

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length)
      ? items
        .map(i => {
          const selected = selectedIDs.indexOf(i.ID) !== -1;
          const projectNameCell = (!this.ProjectID)
            ? <TableCell className={classes.tableCell}>{i.ProjectName}</TableCell>
            : null;
          return (
            <ItemTableRow key={`k_${i.ID}`}
              onSelect={() => onSelect(i.ID)}
              selected={selected}
              onItemClick={() => this.handleRouteToTaskMilestoneDialog(i.ID)}
            >
              <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
                <Checkbox
                  color="secondary"
                  onClick={e => { e.stopPropagation(); onSelect(i.ID); }}
                  checked={selected}
                />
              </TableCell>
              <TableCell className={classes.tableCell} component="th" id={`label_${i.ID}`} scope="row" padding="none">
                {i.Name}
              </TableCell>
              <TableCell className={classes.tableCell}>{GetDateValue(i.CreatedOn)}</TableCell>
              <TableCell className={classes.tableCell}>{GetUserValue(i.CreatedByUserEmail)}</TableCell>
              <TableCell className={classes.tableCell} align="right">{1 + i.Index}</TableCell>
              {projectNameCell}
            </ItemTableRow>
          );
        }) 
      : [];
  }

  HandleItemsChanged = items => {
    this.TaskMilestonesForDialog = items || [];
    this.handleTryDisplayTaskMilestoneDialogIfApplicable(null, items, true);
  }

  handleTryDisplayTaskMilestoneDialogIfApplicable = (taskMilestoneId_optional, taskMilestones, fromItemsChanged) => {
    const taskMilestoneId = (taskMilestoneId_optional) 
      ? taskMilestoneId_optional
      : this.props.match.params.collectionItemID;
    if (taskMilestoneId) {
      const displayDialog = (taskMilestone, extraState) => {
        this.setState({
          TaskMilestoneForTaskMilestoneDialog: taskMilestone,
          TaskMilestonePropertiesDialogIsOpen: true,
          ...extraState,
        });
        if (this.handleItemTitleChange) {
          this.handleItemTitleChange(taskMilestone.Name);
        }
      }
      if (!taskMilestones) {
        taskMilestones = [...this.TaskMilestonesForDialog];
      }
      const taskMilestoneFinder = taskMilestones.filter(f => f.ID === taskMilestoneId);
      if (this.isWorkspace && taskMilestoneFinder.length) {
        displayDialog(taskMilestoneFinder[0]);
      }
      if (fromItemsChanged) {
        if (taskMilestoneFinder.length) {
          displayDialog(taskMilestoneFinder[0]);
        }
      } else {
        API.get(GetTaskMilestonePathForApi(this.OrganizationID, this.ProjectID, taskMilestoneId))
          .then(resp => {
            displayDialog(resp.data, {
              ForcePrependItems: [resp.data],
            });
          })
          .catch(err => {
            this.handleApiError(err);
            this.handleTaskMilestonePropertiesDialogClosed();
          });
      }
    }
  }

  HandleCreateNew = () => {
    this.setState({
      AddTaskMilestoneDialogIsOpen: true,
    });
  }

  HandleGetDialogContent = state => {
    if (state.AddTaskMilestoneDialogIsOpen) {
      return (
        <TaskMilestoneCreationDialog
          organizationId={this.OrganizationID}
          projectId={this.ProjectID}
          open={state.AddTaskMilestoneDialogIsOpen || false}
          onCreated={this.handleTaskMilestoneCreated}
          onClose={this.handleAddTaskMilestoneDialogClosed}
          onApiError={this.handleApiError}
        />
      );
    } else if (state.TaskMilestonePropertiesDialogIsOpen) {
      return (
        <MultiUseDialog
          Details={{
            Open: state.TaskMilestonePropertiesDialogIsOpen,
            Title: "Update task milestone",
            RequireTextInput1: true,
            ShowProgressIndicatorImmediately: state.ShowDialogProgressIndicatorImmediately || false,
            TextInput1Label: "Name",
            TextInput1DefaultValue: state.TaskMilestoneForTaskMilestoneDialog.Name,
            ConfirmLabel: "UPDATE",
            ConfirmCallback: name => this.handleUpdateTaskMilestoneName(state.TaskMilestoneForTaskMilestoneDialog, name),
            CancelCallback: () => this.handleTaskMilestonePropertiesDialogClosed(),
            CloseCallback: () => this.handleTaskMilestonePropertiesDialogClosed(),
          }}
        />
      );
    }
  }

  handleUpdateTaskMilestoneName = (taskMilestone, name) => {
    if (!taskMilestone || !name) {
      return;
    }

    this.setState({ShowDialogProgressIndicatorImmediately: true});
    taskMilestone.Name = name;
    API.put(GetTaskMilestonePathForApi(this.OrganizationID, this.ProjectID, taskMilestone.ID), taskMilestone)
      .then(resp => {
        this.setState({
          ShowDialogProgressIndicatorImmediately: false,
        });
        this.HandleItemRevised(taskMilestone);
        this.handleTaskMilestonePropertiesDialogClosed();
      })
      .catch(this.handleApiError);
  }

  handleAddTaskMilestoneDialogClosed = () => {
    this.setState({
      AddTaskMilestoneDialogIsOpen: false,
    })
  }

  handleTaskMilestonePropertiesDialogClosed = () => {
    const stateToUpdate = {
      TaskMilestonePropertiesDialogIsOpen: false,
      ShowDialogProgressIndicatorImmediately: false,
    };
    this.setState(stateToUpdate);

    const newPath = GetProjectTaskMilestonesPath(this.ProjectID);
    if (this.props.location.pathname !== newPath) {
      this.props.history.push(newPath, { ...this.props.location.state, ...stateToUpdate });
    }
    if (this.handleItemTitleChange) {
      this.handleItemTitleChange(null);
    }
  }

  handleTaskMilestoneCreated = taskMilestone => {
    this.handleAddTaskMilestoneDialogClosed();
    if (!taskMilestone) {
      return;
    }
    this.handleAddTaskMilestoneDialogClosed();
    this.setState({ForcePrependItems:[taskMilestone]});
  }

  // Returns whether further path changes can occur.
  updateProps(props) {
    Collection.prototype.updateProps.bind(this)(props);

    if (this.props.match.params.collectionItemID && !this.prevProps.match.params.collectionItemID) {
      this.handleTryDisplayTaskMilestoneDialogIfApplicable(null, null);
      return false;
    } else if (!this.props.match.params.collectionItemID && this.prevProps.match.params.collectionItemID) {
      this.handleTaskMilestonePropertiesDialogClosed();
      return false;
    }

    return true;
  }
}