const FieldValueType = [
	{
		Type: "FieldValueType_FullValue",
		FieldType: "All",
		Label: "Full value",
	},
	// {
	// 	Type: "FieldValueType_First1",
	// 	FieldType: "Text",
	// 	Label: "First character",
	// },
	// {
	// 	Type: "FieldValueType_First2",
	// 	FieldType: "Text",
	// 	Label: "First 2 characters",
	// },
	// {
	// 	Type: "FieldValueType_First3",
	// 	FieldType: "Text",
	// 	Label: "First 3 characters",
	// },
	// {
	// 	Type: "FieldValueType_Year",
	// 	FieldType: "Date",
	// 	Label: "Year",
	// },
	// {
	// 	Type: "FieldValueType_YearMonth",
	// 	FieldType: "Date",
	// 	Label: "Year-Month",
	// },
];

export default FieldValueType;