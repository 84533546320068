import React, { Component } from 'react';

import UiCore from '../Components/UiCore';
import { GlobalContext } from '../Context/Global.context';

import {
  GetDocumentDetailPath,
} from '../Util/PathHelper';

class DocumentRedirect extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      ShowProgressIndicatorImmediately:true,
    }
  }

  handleApiError = err => {
    err.closeCallback = this.tryCloseWindowOrRedirectToHome;
    this.setState({ ApiError: err, ShowProgressIndicatorImmediately: false });
  }

  tryCloseWindowOrRedirectToHome = () => {
    this.setState({ShowProgressIndicatorImmediately: false});
    window.close();
    this.props.history.replace("/home");
  }

  componentDidMount() {
    let organizationID = this.props.match.params.organizationID;
    if (!organizationID) {
      this.handleApiError("Invalid or missing organization");
    }
    let projectID = this.props.match.params.projectID;
    if (!projectID) {
      this.handleApiError("Invalid or missing project");
    }
    // Switch to the organization provided
    this.context.SaveUserPreferences_ActiveOrganization(organizationID)
      .then(() => {
        let documentID = this.props.match.params.documentID;
        if (!documentID) {
          this.handleApiError("Invalid or missing document");
          return null;
        }
        this.props.history.replace(GetDocumentDetailPath(organizationID, projectID, documentID));
      })
      .catch(this.handleApiError);
  }

  render() {
    const {
      Alert,
      ShowProgressIndicatorImmediately,
    } = this.state;

    return (
      <UiCore title="Document"
        hideNavDrawer
        alert={Alert}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
      />
    );
  }
}

export default DocumentRedirect;
