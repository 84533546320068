import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Collection from '../Model/Collection';
import ItemTableRow from '../Components/ItemTableRow';

import SearchIcon from '@material-ui/icons/Search';
import CreateAssetIcon from '@material-ui/icons/Category';

import AssetItemAssetItemsCollectionFields from '../Model/AssetItemAssetItemsCollectionFields';
import AssetItemCard from '../Components/AssetItemCard';
import AssetItemDialog from '../Components/AssetItemDialog';
import {
  GetPreviewMetadataHeadCells,
  GetPreviewMetadataTableCells,
} from '../Util/Metadata';
import {
  HandleRouteToAssetItem,
} from '../Util/AssetItems';
import {
  GetDateValue,
} from '../Util/Properties';
import {
  GetTagsForList,
} from '../Util/Tags';
import {
  GetAssetItemAssetItemsPathForApi,
  GetUserOrganizationProjectApprovalAssetItemAssetItemsPathForApi,
} from '../Util/api';

export class AssetItemAssetItems_Collection extends Collection {
  constructor(props, context, onSetState, onApiError, onAlert,
    isWorkspace, isProjects, loadByApprovalAssignment,
    organizationId, projectId, assetItem, approvalId, isProjectMember,
    onBeginAssetItemCreateFunc, onBeginAddExistingAssetItemsFunc,
    disableSelect, assetsForAssetItemCreation) {
    
    super(props, onSetState, onApiError, isWorkspace, isProjects, organizationId, projectId);

    this.context = context;
    this.handleAlert = onAlert;
    this.IsProjectMember = isProjectMember;

    this.PageTitle = assetItem.AssetPluralName;
    this.ContentUri = (loadByApprovalAssignment)
      ? GetUserOrganizationProjectApprovalAssetItemAssetItemsPathForApi(organizationId, projectId, approvalId, assetItem.AssetID, assetItem.ID)
      : GetAssetItemAssetItemsPathForApi(organizationId, projectId, assetItem.AssetID, assetItem.ID);
    this.ContentUriParams = {};
    this.CollectionName = "AssetItemAssetItems";
    this.ItemsName = "AssetItems";
    this.ItemName = assetItem.AssetName;
    this.DefaultViewType = "Card";
    this.AllowSelect = !disableSelect;
    this.CanSelectItem = item => this.AllowSelect && isProjectMember;
    
    // this.HideSensitiveFields = true;
    this.SingleLineTableCells = true;

    this.loadByApprovalAssignment = loadByApprovalAssignment;
    this.AssetItem = assetItem;
    this.approvalId = approvalId;
    this.onBeginAssetItemCreateFunc = onBeginAssetItemCreateFunc;
    this.onBeginAddExistingAssetItemsFunc = onBeginAddExistingAssetItemsFunc;
    this.assetsForAssetItemCreation = assetsForAssetItemCreation;

    this.HandleCreateNew = e =>
      (this.onBeginAssetItemCreateFunc || this.onBeginAddExistingAssetItemsFunc)
        ? this.handleSetFabMenuVisibility(true)(e)
        : null;
  }

  handleSetFabMenuVisibility = visible => event => {
    this.setState({
      FabMenu_MouseY: (visible) ? event.clientY : null,
      FabMenu_MouseX: (visible) ? event.clientX : null,
    });
  }

  HandleGetFabMenu = state => {
    if (!state.FabMenu_MouseY) {
      return null;
    }
    const createMenuItems = (this.onBeginAssetItemCreateFunc && this.assetsForAssetItemCreation
      && this.assetsForAssetItemCreation.length)
      ? this.assetsForAssetItemCreation.map(asset => (
        <MenuItem key={`create_${asset.ID}`} onClick={this.handleFabMenuClick(state, "create", asset)}>
          <ListItemIcon>
            <CreateAssetIcon />
          </ListItemIcon>
          {`New ${asset.Name.toLowerCase()}`}
        </MenuItem>
      )) : null;
    const addExistingMenuItem = (this.IsProjectMember
      && this.onBeginAddExistingAssetItemsFunc)
      ? (
        <MenuItem key="addExisting" onClick={this.handleFabMenuClick(state, "addExisting")}>
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          Tag existing assets
        </MenuItem>
      ) : null;
    return (
        <Menu
          id="fabMenu"
          keepMounted
          open={state.FabMenu_MouseY !== null}
          anchorReference="anchorPosition"
          anchorPosition={
            state.FabMenu_MouseY !== null && state.FabMenu_MouseX !== null
              ? { top: state.FabMenu_MouseY, left: state.FabMenu_MouseX }
              : undefined
          }
          onClose={this.handleSetFabMenuVisibility(false)}
        >
          {addExistingMenuItem}
          {createMenuItems}
        </Menu>
      );
  }

  handleFabMenuClick = (state, id, asset) => e => {
    this.handleSetFabMenuVisibility(false)(e);
    switch (id) {
      case "create":
        if (this.onBeginAssetItemCreateFunc) {
          this.onBeginAssetItemCreateFunc(asset);
        }
        break;
      case "addExisting":
        if (this.onBeginAddExistingAssetItemsFunc) {
          this.onBeginAddExistingAssetItemsFunc();
        }
        break;
      default:
        break;
    }
  }

  HandleGetCollectionFieldsPromise = () => Promise.resolve(AssetItemAssetItemsCollectionFields);

  HandleGetHeadCells = (items, sensitiveFields) => {
    let headCells = [];
    headCells.push(
      { id: 'Name', sortId: 'Meta_text_kw256lc[Name].keyword', numeric: false, label: 'Name' },
      { id: 'CreatedOn', sortId: 'Meta_date_str256[CreatedOn].string', numeric: false, label: 'Created On' },
      { id: 'AssetName', numeric: false, label: 'Type' },
    );
    headCells.push(...GetPreviewMetadataHeadCells(sensitiveFields, items));
    headCells.push(
      { id: 'Tags', sortId: 'Meta_text_kw50lc[Tag].keyword', numeric: false, label: 'Tags' },
    );
    return headCells;
  }

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, selectedIDs,
    onAction, postActionData, sortType, sortDescending, useSingleColumnLayout, cardStyle) => {
    return (items && items.length)
      ? items
        .map(i => {
          i.ContentType = "AssetItem"
          return (
            <Grid item key={i.ID} className={classes.cardGridItem}
              sm={(!useSingleColumnLayout) ? 12 : undefined}
              md={(!useSingleColumnLayout) ? 6 : undefined}
              lg={(!useSingleColumnLayout) ? 4 : undefined}
              xl={(!useSingleColumnLayout) ? 3 : undefined}
            >
              <AssetItemCard
                AssetItem={i}
                onCardAction={(!this.isWorkspace)
                  ? () => HandleRouteToAssetItem(this.props, this.ProjectID,
                    i.AssetID, i.ID, i.Archived, true)
                  : undefined
                }
                onSelect={(this.CanSelectItem(i)) ? () => onSelect(i.ID) : undefined}
                noSelect={!this.CanSelectItem(i)}
                selected={selectedIDs.indexOf(i.ID) > -1}
                style={{
                  backgroundColor: theme.palette.background.default,
                  ...cardStyle
                }}
                height={256}
                showContentType
              />
            </Grid>
          );
        }) 
      : [];
  }

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length)
      ? items.map(i => {
        const selected = selectedIDs.indexOf(i.ID) !== -1;
        const metadataTableCells = [...GetPreviewMetadataTableCells(i, headCells, classes)];
        return (
          <ItemTableRow key={`k_${i.ID}`}
            onSelect={(this.CanSelectItem(i)) ? () => onSelect(i.ID) : undefined}
            selected={selected}
            onItemClick={(!this.isWorkspace)
              ? () => HandleRouteToAssetItem(this.props, this.ProjectID,
                i.AssetID, i.ID, i.Archived, true)
              : undefined
            }
          >
            <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
              <Checkbox
                style={{
                  visibility: (!this.CanSelectItem(i)) ? "hidden" : undefined,
                }}
                color="default"
                onClick={e => { e.stopPropagation(); onSelect(i.ID); }}
                checked={selected}
              />
            </TableCell>
            <TableCell className={classes.tableCell} component="th" id={`label_${i.ID}`} scope="row" padding="none">
              {i.Name}
            </TableCell>
            <TableCell className={classes.tableCell}>{GetDateValue(i.CreatedOn)}</TableCell>
            <TableCell className={classes.tableCell}>{i.AssetName}</TableCell>
            {metadataTableCells}
            <TableCell className={classes.tableCell}>
              {GetTagsForList(i, sortType, sortDescending)}
            </TableCell>
          </ItemTableRow>
        );
      })
    : null;
  }

  HandleGetDialogContent = state => {
    return (state.TaskDialogIsOpen && state.CreateNewAssetItem)
      ? (
        <AssetItemDialog
          history={this.props.history}
          location={this.props.location}
          organizationId={this.OrganizationID}
          projectId={this.ProjectID}
          assetId={state.AssetForNewAssetItem.ID}
          assetName={state.AssetForNewAssetItem.Name}
          isCreateNew
          assetItemForNewAssetItem={this.AssetItem}
          open={state.TaskDialogIsOpen || false}
          onCreated={newAssetItem => this.setState({ForcePrependItems: [newAssetItem]})}
          onClose={this.handleTaskDialogClosed}
          onApiError={this.handleApiError}
          onAlert={this.handleAlert}
        />
      ) : null;
  }

  handleShowTaskDialog = () => {
    this.setState({
      TaskDialogIsOpen: true,
    });
  }

  handleTaskDialogClosed = () => {
    const stateToUpdate = {
      CreateNewAssetItem: false,
      TaskDialogIsOpen: false,
      AssetForNewAssetItem: null,
      ShowDialogProgressIndicatorImmediately: false,
    };
    this.setState(stateToUpdate);
  }
}