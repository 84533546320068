import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import { IsMobile } from '../Util/MobileDetector';

import { ValidateEmail } from '../Util/Regex';
import ProgressIndicator from './ProgressIndicator';
import DraggableSignatureSessionRecipientRow from './DraggableSignatureSessionRecipientRow';
import ReRankItemInCollection from '../Util/ItemRanking';

import {
  GetUserValue,
} from '../Util/Properties';

const styles = theme => ({
  grid: {
    paddingTop:theme.spacing(2),
  },
});

const rankIncrement = 1000;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

class DocumentSignatureSessionRecipientsDialog extends Component {
  constructor(props) {
    super(props);

    this.ControlRefs = [];

    this.state = {
      open: props.open,
      ShowProgressIndicator: false,
      Exportable: {
        Recipients: this.getInitialRecipients(),
      },
      PreMoveRecipientsJson: "",
    }
  }

  getTempId = () => {
    return (new Date()).getTime().toString();
  }

  getControlRefs = (recipientId, type) => {
    let cr = this.ControlRefs.filter(c => c.RecipientID === recipientId);
    if (cr.length) {
      return cr[0];
    }
    return null;
  }
  
  addOrUpdateControlRef = (recipientId, type, ref) => {
    let cr = this.getControlRefs(recipientId);
    if (cr) {
      cr[type] = ref;
    } else {
      this.ControlRefs.push({
        RecipientID: recipientId,
        [type]: ref,
      });
    }
  }

  deleteControlRefs = recipientId => {
    this.ControlRefs = this.ControlRefs.filter(c => c.RecipientID && c.RecipientID !== recipientId);
  }

  getNewRecipient = () => {
    let r = {
      ID: this.getTempId(),
      UniqueID: this.getTempId(),
      AssignmentUserEmail: "",
      AssignmentUserEmail_ListValue: null,
      AssignmentUserName: "",
      Type: "Signer",
      Rank: (this.state && this.state.Exportable && this.state.Exportable.Recipients)
        ? rankIncrement * this.state.Exportable.Recipients.length : 0,
    };

    this.addOrUpdateControlRef(r.ID, "AssignmentUserEmail", React.createRef());
    this.addOrUpdateControlRef(r.ID, "AssignmentUserName", React.createRef());

    return r;
  }

  getAssignmentUserEmail_ListValueFromRecipient = recipient => {
    return GetUserValue(recipient.AssignmentUserEmail, 
      `${recipient.AssignmentUserName} (${recipient.AssignmentUserEmail})`,
      "", false, undefined, {}, {}, true,
      )
  }

  getInitialRecipients = () => {
    // Initial state is an array of DocumentSignatureSessionRecipient
    if (this.props.recipients
      && this.props.recipients.length) {
      return this.props.recipients.map(r => {
        this.addOrUpdateControlRef(r.ID, "AssignmentUserEmail", React.createRef());
        this.addOrUpdateControlRef(r.ID, "AssignmentUserName", React.createRef());
        return {
          ID: r.ID,
          UniqueID: r.UniqueID,
          AssignmentUserEmail: r.AssignmentUserEmail,
          AssignmentUserEmail_ListValue: 
            (r.AssignmentUserEmail) 
              ? {
                label:this.getAssignmentUserEmail_ListValueFromRecipient(r),
                value:r.AssignmentUserEmail,
                projectMemberId: r.ProjectMemberID
              }
              : null,
          AssignmentUserName: r.AssignmentUserName,
          Type: r.Type,  
          Rank: r.Rank,
        };
      });
    } else {
      return [this.getNewRecipient()];
    }
  }

  getRecipientFinder = (id, recipients) => {
    if (!recipients || recipients.length === 0) {
      recipients = [...this.state.Exportable.Recipients];
    }
    return recipients.filter(r => r.ID === id)
  }

  updateRecipient = (id, updateFunc) => {
    if (!id || !updateFunc) {
      return;
    }
    let recipients = [...this.state.Exportable.Recipients];
    let recipientFinder = this.getRecipientFinder(id, recipients);
    if (recipientFinder.length) {
      updateFunc(recipientFinder[0]);
      this.setState({Exportable: {...this.state.Exportable, Recipients: recipients}});
    }
  }

  handleRecipientEmailListValueChange = (id, option) => {
    if (option && option.value) {
      this.updateRecipient(id, r => r.AssignmentUserEmail = option.value);
    }
    this.updateRecipient(id, r => r.AssignmentUserEmail_ListValue = option);
  }

  handleRecipientNameChange = (id, e) => {
    this.updateRecipient(id, r => r.AssignmentUserName = e.target.value);
  }

  handleUpdateRecipientType = (id, type) => {
    this.updateRecipient(id, r => r.Type = type);
  }

  setFocusOnEmailInput = id => {
    let cr = this.getControlRefs(id);
    if (cr && cr.AssignmentUserEmail) {
      cr.AssignmentUserEmail.focus();
    }
  }

  setFocusOnNameInput = id => {
    let cr = this.getControlRefs(id);
    if (cr && cr.AssignmentUserName) {
      cr.AssignmentUserName.focus();
    }
  }

  validateRecipients = checkMinimumSigners => {
    let uniqueEmails = [];
    let recipients = [...this.state.Exportable.Recipients];
    if (checkMinimumSigners) {
      recipients = recipients.filter(r => r.AssignmentUserEmail_ListValue && r.AssignmentUserEmail_ListValue.value);
      if (!recipients.filter(r => r.Type === "Signer").length) {
        this.handleApiError("Please provide at least one signer.", "Signer required")
        return false;
      }
    }
    for (let i = 0; i < recipients.length; i++) {
      let r = recipients[i];
      // Check for valid entries
      let email = "";
      if (r.AssignmentUserEmail_ListValue && r.AssignmentUserEmail_ListValue.value) {
        email = r.AssignmentUserEmail_ListValue.value.trim();
        r.AssignmentUserEmail = email;
        r.AssignmentUserEmail_ListValue.value = email;
        r.AssignmentUserEmail_ListValue.label = this.getAssignmentUserEmail_ListValueFromRecipient(r);
      }
      if (!ValidateEmail(email)) {
        this.setFocusOnEmailInput(r.ID);
        this.handleApiError("Please provide a valid e-mail address.", "E-mail required");
        return false;
      }
      if (uniqueEmails.filter(e => e === email).length) {
        this.setFocusOnEmailInput(r.ID);
        this.handleApiError("A recipient has been added twice.", "Duplicate entry");
        return false;
      }
      uniqueEmails = uniqueEmails.concat(email);
      r.AssignmentUserName = r.AssignmentUserName.trim();
      if (!r.AssignmentUserName.length) {
        this.setFocusOnNameInput(r.ID);
        this.handleApiError("Please provide a name.", "Name required");
        return false; 
      }
    }
    return true;
  }

  handleAddRecipient = () => {
    if (!this.validateRecipients()) {
      return;
    }
    let recipients = [...this.state.Exportable.Recipients].concat(this.getNewRecipient()); 
    this.setState({Exportable: {...this.state.Exportable, Recipients: recipients}});
  }

  handleRemoveRecipient = id => {
    this.setState({
      Exportable: {...this.state.Exportable, Recipients: this.state.Exportable.Recipients.filter(r => r.ID !== id)}
    });
    this.deleteControlRefs(id);
  }

  handleClose = (moveBack, moveForward) => {
    this.setState({ open: false });
    if (this.props.closeCallback) {
      this.props.closeCallback(moveBack, moveForward, this.state.Exportable);
    }
  }

  handleNext = () => {
    if (!this.validateRecipients(true)) {
      return;
    }
    this.handleClose(false, true);
  }

  handleStartMoveRecipientRow = () => {
    // Generate rank if the first two have equal rank
    let recipients = [...this.state.Exportable.Recipients];
    let stateToUpdate = { PreMoveRecipientsJson: JSON.stringify(recipients) }
    if (recipients.length > 1 
      && recipients[0].Rank === recipients[1].Rank) {
      for (let i = 0; i < recipients.length; i++) {
        recipients[i].Rank = i * rankIncrement;
      }
      let exportable = {...this.state.Exportable};
      exportable.Recipients = recipients
      stateToUpdate.Exportable = exportable;
    }

    this.setState(stateToUpdate);
  }

  handleMoveRecipientRow(sourceRecipient, targetRecipient) {
    if (!sourceRecipient) {
      return;
    }
    // console.log("Source Recipient: ", sourceRecipient.ID);
    
    if (this.state.RecipientIsMoving) {
      return;
    }
    // this.setState({RecipientIsMoving: true});

    // Target is DraggableSignatureSessionRecipientRow
    if (targetRecipient) {
      // console.log("Target Recipient: ", targetRecipient.ID);
      if (sourceRecipient.Rank === targetRecipient.Rank)
        return;

      let recipients = [...this.state.Exportable.Recipients];
      let sourceIndex = recipients.indexOf(sourceRecipient);
      let targetIndex = recipients.indexOf(targetRecipient);
      if (sourceIndex === targetIndex)
        return;

      // console.log("Index of source: ", sourceIndex, " Index of target: ", targetIndex);
      if (sourceIndex === null || targetIndex === null)
        return;

      recipients.splice(sourceIndex, 1);
      recipients.splice(targetIndex, 0, sourceRecipient);
      let exportable = {...this.state.Exportable};
      exportable.Recipients = recipients;
      this.setState({ Exportable: exportable });
    }
  }

  handleAbortMoveRecipientRow = () => {
    const recipients = JSON.parse(this.state.PreMoveRecipientsJson);
    // Refresh AssignmentUserEmail_ListValue.label to avoid crash
    recipients.forEach(r => {
      if (r.AssignmentUserEmail_ListValue && r.AssignmentUserEmail_ListValue.label) {
        r.AssignmentUserEmail_ListValue.label = this.getAssignmentUserEmail_ListValueFromRecipient(r);
      }
    });
    const Exportable = {...this.state.Exportable};
    Exportable.Recipients = recipients;
    this.setState({ Exportable });
  }

  handleEndMoveRecipientRow = () => {
  }

  handleDropRecipientRow = recipient => {
    if (!recipient)
      return;
    this.reRankRecipient(recipient);
  }

  reRankRecipient(recipient) {
    let { 
      Collection: reRankedRecipients, 
      StartIndex, 
      EndIndex,
    } = ReRankItemInCollection(recipient, [...this.state.Exportable.Recipients], rankIncrement);    
    let exportable = {...this.state.Exportable};
    exportable.Recipients = reRankedRecipients;
    this.setState({ Exportable: exportable});

    // Build array of changed items to send to server
    let preMoveRecipients = JSON.parse(this.state.PreMoveRecipientsJson);
    let updatedRecipients = [];
    for (let i = StartIndex; i <= EndIndex; i++) {
      let recipient = reRankedRecipients[i];
      let filtered = preMoveRecipients.filter(p => p.ID === recipient.ID)
      if (filtered.length > 0) {
        let preMoveRecipient = filtered[0];
        // console.log("original: ", preMoveRecipient.Rank, " new: ", recipient.Rank);
        if (recipient.Rank !== preMoveRecipient.Rank) {
          updatedRecipients = updatedRecipients.concat({
            ID: recipient.ID,
            Rank: recipient.Rank,
          });
        }
      }
    }

    // Send changed items to server
    // this.handleUpdateRecipients(updatedRecipients, true);
  }

  handleApiError = (err, title_optional) => {
    this.setState({
      ShowProgressIndicator: false,
    });
    this.props.onApiError(err, title_optional);
    if (err) {
      setTimeout(() => this.props.onApiError(null), 1);
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      this.setState({open: this.props.open !== false});
    }
  }

  render() {
    const { 
      open,
      ShowProgressIndicator,
      Exportable,
  	} = this.state;
    const {
      classes,
      theme,
    } = this.props;

    let progressIndicator = null;
    if (ShowProgressIndicator) {
      progressIndicator = (
        <ProgressIndicator />
      );
    }

    let dialogActions = (
      <DialogActions>
        <Button onClick={() => this.handleClose()}>
          CLOSE
        </Button>
        <Button onClick={() => this.handleNext()}>
          NEXT
        </Button>
      </DialogActions>
    );

    let recipientGridItems = Exportable.Recipients.map(r => (
      <Grid item key={`r_${r.ID}`}>
        <DraggableSignatureSessionRecipientRow
          organizationId={this.props.organizationId}
          projectId={this.props.projectId}
          Recipient={r}
          onConnectEmailRef={ref => this.addOrUpdateControlRef(r.ID, "AssignmentUserEmail", ref)}
          onConnectNameRef={ref => this.addOrUpdateControlRef(r.ID, "AssignmentUserName", ref)}
          onRecipientEmailListValueChange={this.handleRecipientEmailListValueChange}
          onRecipientEmailListCreateOption={this.handleRecipientEmailListCreateOption}
          onRecipientNameChange={this.handleRecipientNameChange}
          onRecipientTypeKeyDown={e => { 
            if (r.ID === Exportable.Recipients[Exportable.Recipients.length-1].ID
              && e.keyCode === 9
              && !e.shiftKey) {
              e.preventDefault();
              this.handleAddRecipient() 
            }
          }}
          onUpdateRecipientType={this.handleUpdateRecipientType}
          showRemoveIcon={r.ID !== Exportable.Recipients[0].ID}
          onRemoveRecipient={this.handleRemoveRecipient}
          onStartMoveRecipientRow={this.handleStartMoveRecipientRow}
          onMoveRecipientRow={sourceRecipient => this.handleMoveRecipientRow(sourceRecipient, r)}
          onAbortMoveRecipientRow={this.handleAbortMoveRecipientRow}
          onEndMoveRecipientRow={this.handleEndMoveRecipientRow}
          onDropRecipientRow={() => this.handleDropRecipientRow(r)}
          draggable={Exportable.Recipients.length > 1}
          onApiError={this.handleApiError}
        />
      </Grid>
    ));
    let recipientGrid = (
      <Grid container spacing={2} direction="column" className={classes.grid}>
        {recipientGridItems}
      </Grid>
    );

    let content = (
      <div>
        <Typography variant="body1">
          Provide one or more signers in signing order and optional recipients.
        </Typography>
        {recipientGrid}
        <Tooltip title="Add">
          <IconButton aria-label="Add" style={{marginTop:theme.spacing(1)}} onClick={this.handleAddRecipient}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </div>
    );

    return (
       <Dialog
        fullWidth={!IsMobile()}
        fullScreen={IsMobile()}
        maxWidth="md"
        TransitionComponent={Transition}
        PaperProps={{
          style:{
            minHeight:"50%",
          }
        }}
        open={open}
        onClose={() => this.handleClose()}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description">
        <DialogTitle id="dialog-title">
          <span>Signers and recipients</span>
        </DialogTitle>
        <DialogContent style={{paddingTop:0}}>
          {progressIndicator}

          {content}
        </DialogContent>

        {dialogActions}
      </Dialog>
    );
  }
}

DocumentSignatureSessionRecipientsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
}

export default withStyles(styles, {withTheme: true})(DocumentSignatureSessionRecipientsDialog);