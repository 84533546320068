import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import Collection from '../Model/Collection';

import ItemTableRow from '../Components/ItemTableRow';
import TagCard from '../Components/TagCard';
import TagCollectionFields from '../Model/TagCollectionFields';
import MultiUseDialog from '../Components/MultiUseDialog';
import TagDialog from '../Components/TagDialog';

import {
  GetTagPathForApi,
  GetTagsPathForApi,
} from '../Util/api';
import {
  GetProjectTagsPath,
  GetProjectTagPath,
} from '../Util/PathHelper';

import API from '../Util/api';

import {
  GetDateValue,
} from '../Util/Properties';

export class Tags_Collection extends Collection {
  constructor(props, onSetState, onApiError, isWorkspace, isProjects, organizationId, 
    projectId, context, onItemTitleChange) {
    
    if (typeof organizationId !== "string") {
      console.log("Warning: organizationId required and not found");
    }
    if (typeof projectId !== "string") {
      console.log("Warning: projectId required and not found");
    }
    super(props, onSetState, onApiError, isWorkspace, isProjects, organizationId, projectId);

    this.handleItemTitleChange = onItemTitleChange;

    this.PageTitle = "Tags";
    this.ContentUri = GetTagsPathForApi(organizationId, projectId);
    this.CollectionName = "Tags";
    this.ItemsName = "Tags";
    this.ItemName = `Tag`;
    this.DefaultViewType = "List";
    this.AllowSelect = true;
    this.HideSearchAllFilter = true;
    this.TagsForDialog = [];

    this.context = context;
  }

  HandleGetCollectionFieldsPromise = () => Promise.resolve(TagCollectionFields);

  HandleGetHeadCells = (items, sensitiveFields) => [
    { id: 'Text', sortId: 'TextLower', numeric: false, label: 'Text' },
    { id: 'ModifiedOn', sortId: 'ModifiedOn', numeric: false, label: 'Modified On' },
  ];

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length)
      ? items
        .map(i => {
          return (
            <Grid item key={i.ID} sm={12} md={6} lg={4} xl={3} className={classes.cardGridItem}>
              <TagCard
                Tag={i}
                onCardAction={() => this.handleRouteToTagDialog(this.props, this.ProjectID, i.ID)}
                onSelect={(this.CanSelectItem(i)) ? () => onSelect(i.ID) : undefined}
                selected={selectedIDs.indexOf(i.ID) > -1}
              />
            </Grid>
          );
        }) 
      : [];
  }

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length)
      ? items
        .map(i => {
          const labelId = `label_${i.UserEmail}`;
          return (
            <ItemTableRow key={`k_${i.ID}`}
              onItemClick={() => this.handleRouteToTagDialog(this.props, this.ProjectID, i.ID)}
              onSelect={(this.CanSelectItem(i)) ? () => onSelect(i.ID) : undefined}
            >
              <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
                <Checkbox
                  style={{
                    visibility: (!this.CanSelectItem(i)) ? "hidden" : undefined,
                  }}
                  color="secondary"
                  onClick={event => { event.stopPropagation(); onSelect(i.ID); }}
                  checked={selectedIDs.indexOf(i.ID) > -1}
                  inputProps={{ 'aria-labelledby': labelId, style:{zIndex:0} }}
                />
              </TableCell>
              <TableCell className={classes.tableCell} component="th" id={labelId} scope="row" padding="none">{i.Text} </TableCell>
              <TableCell className={classes.tableCell}>{GetDateValue(i.ModifiedOn)}</TableCell>
            </ItemTableRow>
          );
        }) 
      : [];
  }

  handleRouteToTagDialog = (props, projectId, tagId) => {
    props.history.push(GetProjectTagPath(projectId, tagId), 
      { ...props.location.state });
  }

  HandleItemsChanged = items => {
    this.TagsForDialog = items || [];
    this.handleDisplayTagDialogIfApplicable(null, items);
  }

  handleDisplayTagDialogIfApplicable = (tagId, tags) => {
    if (!tagId) {
      tagId = this.props.match.params.collectionItemID;
    }
    if (tagId) {
      const displayTagDialog = (tag, extraState) => {
        this.setState({
          TagForTagDialog: tag,
          TagDialogIsOpen: true,
          ...extraState,
        });
        if (this.handleItemTitleChange) {
          this.handleItemTitleChange(tag.Text);
        }
      }
      if (!tags) {
        tags = [...this.TagsForDialog];
      }
      const tagItem = tags.find(t => t.ID === tagId);
      if (tagItem) {
        displayTagDialog(tagItem);
      } else {
        API.get(GetTagPathForApi(this.OrganizationID, this.ProjectID, tagId))
          .then(resp => {
            displayTagDialog(resp.data, {
              ForcePrependItems: [resp.data],
            });
          })
          .catch(err => {
            this.handleApiError(err);
            this.handleTagDialogClosed();
          });
      }
    }
  }

  handleTagDialogClosed = () => {
    const stateToUpdate = {
      TagDialogIsOpen: false,
      ShowDialogProgressIndicatorImmediately: false,
    };
    this.setState(stateToUpdate);
    
    const newPath = GetProjectTagsPath(this.ProjectID);
    if (this.props.location.pathname !== newPath) {
      this.props.history.push(newPath, { ...this.props.location.state, ...stateToUpdate });
    }
    if (this.handleItemTitleChange) {
      this.handleItemTitleChange(null);
    }
  }

  HandleGetDialogContent = state => {
    if (state.TagDialogIsOpen) {
      return (
        <TagDialog
          organizationId={this.OrganizationID}
          projectId={this.ProjectID}
          open={state.TagDialogIsOpen || false}
          Tag={state.TagForTagDialog}
          onApiError={this.handleApiError}
          onClose={this.handleTagDialogClosed}
          showProgressIndicatorImmediately={state.ShowDialogProgressIndicatorImmediately}
        />
      );
    }
    else if (state.AddTagDialogIsOpen) {
      return (
        <MultiUseDialog
          Details={{
            Open: state.AddTagDialogIsOpen,
            Title: "Add tag",
            RequireTextInput1: true,
            ShowProgressIndicatorImmediately: state.ShowDialogProgressIndicatorImmediately || false,
            TextInput1Label: "Text",
            ConfirmLabel: "ADD",
            ConfirmCallback: text => this.handleAddTag(state, text),
            CancelCallback: this.handleAddTagDialogClosed,
            CloseCallback: this.handleAddTagDialogClosed,
          }}
        />
      );
    }
  }

  handleAddTagDialogClosed = () => {
    this.setState({
      AddTagDialogIsOpen: false,
    })
  }

  handleAddTag = (state, Text) => {
    if (!Text)
      return;
    
    this.setState({ShowDialogProgressIndicatorImmediately: true});
    const tag = {
      Text,
    };
    API.post(GetTagsPathForApi(this.OrganizationID, this.ProjectID), [tag])
      .then(resp => {
        this.handleAddTagDialogClosed();
        this.setState({
          ForcePrependItems: resp.data,
          ShowDialogProgressIndicatorImmediately: false,
        })
      })
      .catch(this.handleApiError);
  }

  HandleCreateNew = () => {
    this.setState({
      AddTagDialogIsOpen: true,
    });
  }

  // Returns whether further path changes can occur.
  updateProps(props) {
    Collection.prototype.updateProps.bind(this)(props);

    if (this.props.match.params.collectionItemID && !this.prevProps.match.params.collectionItemID) {
      this.handleDisplayTagDialogIfApplicable(null, null);
      return false;
    } else if (!this.props.match.params.collectionItemID && this.prevProps.match.params.collectionItemID) {
      this.handleTagDialogClosed();
      return false;
    }

    return true;
  }
}