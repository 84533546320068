import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import NewFieldIcon from '@material-ui/icons/Add';

import { IsMobile } from '../Util/MobileDetector';
import SelectControl from './SelectControl';
import ProgressIndicator from './ProgressIndicator';
import { Asset } from '../Model/Asset';
import { GetAsset, UpdateAsset } from '../Util/Asset';
import {
  GetTagsControl,
  GetTagListValuesFromTagsObject,
  GetTagsAndAssetItemTagsFromTagListValues,
} from '../Util/Tags';

import FieldPropertiesDialog from './FieldPropertiesDialog';
import FieldCreationDialog from './FieldCreationDialog';
import ConfigureFieldsDialog from './ConfigureFieldsDialog';
import { GetFieldsFirstPagePromise } from '../Util/Fields';

const styles = theme => ({
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    // overflowY:"hidden",
  },
  dialogPaper: {
    height:(!IsMobile()) ? "70%" : undefined,
  },
  tabs: {
    marginTop:theme.spacing(2),
    marginBottom:theme.spacing(2),
  },
  tab: {
  },
});

class AssetPropertiesDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      Asset: (props.asset && props.asset.ID) ? props.asset : {...Asset},
      Fields: [],
      FieldToCustomize: {},
      SelectedTab: "fields",
      TaskTagListValues: (props.asset && props.asset.ID)
        ? GetTagListValuesFromTagsObject(props.asset, "CompletedTaskRequirement")
        : [],
      DocumentTagListValues: (props.asset && props.asset.ID)
        ? GetTagListValuesFromTagsObject(props.asset, "DocumentRequirement")
        : [],
      AssetItemTagListValues: (props.asset && props.asset.ID)
        ? GetTagListValuesFromTagsObject(props.asset, "AssetItemRequirement")
        : [],
      ShowManageFieldsDialog: false,
      ShowAddFieldDialog: false,
      ShowFieldPropertiesDialog: false,
      ShowProgressIndicatorImmediately: false,
    }

    this.PrimaryFieldIDUpdated = new Date();
  }

  handleClose = () => {
    this.setState({ open: false });
    if (this.props.closeCallback) {
      this.props.closeCallback();
    }
  }

  handleTabChange = (e, newValue) => {
    this.setState({SelectedTab: newValue});
  }

  handleGetFields = () => {
    this.setState({ShowProgressIndicatorImmediately: true});

    return GetFieldsFirstPagePromise(this.props.organizationId, this.props.projectId)
      .then(resp => {
        const Fields = resp.data.Fields;
        this.setState({
          Fields,
          ShowProgressIndicatorImmediately: false,
        });
        return Fields;
      })
      .catch(this.handleApiError);
  }

  handleChangeAssetProperty = (propertyName, value, Asset) => {
    const timestamp = new Date();
    this.ChangeAssetPropertyTimestamp = timestamp;

    if (!Asset) {
      Asset = {...this.state.Asset};
    }
    Asset[propertyName] = value;
    this.setState({Asset});
    UpdateAsset(this.props.organizationId, this.props.projectId, Asset)
      .then(resp => {
        const serverAsset = resp.data;
        if (Asset.PrimaryFieldID !== serverAsset.PrimaryFieldID
          && this.ChangeAssetPropertyTimestamp === timestamp) {
          Asset.PrimaryFieldID = serverAsset.PrimaryFieldID;
          this.setState({Asset});
        }
      })
      .catch(this.handleApiError);
    if (this.props.onAssetRevised) {
      this.props.onAssetRevised({...Asset});
    }

    return Asset;
  }

  handleEnableField = (field, enable) => {
    let Fields = [...this.state.Fields];
    const fieldFinder = Fields.filter(f => f.ID === field.ID);
    if (!fieldFinder.length) {
      Fields.push(field);
    }
    let FieldIDs = [];
    // Sanitize FieldIDs to those that exist
    if (this.state.Asset.FieldIDs && this.state.Asset.FieldIDs.length) {
      this.state.Asset.FieldIDs.forEach(id => {
        const fieldFinder = Fields.filter(f => f.ID === id);
        if (fieldFinder.length) {
          FieldIDs.push(id);
        }
      });
    }
    if (enable && !FieldIDs.includes(field.ID)) {
      FieldIDs.push(field.ID);
    } else if (!enable && FieldIDs.includes(field.ID)) {
      FieldIDs = FieldIDs.filter(fieldId => fieldId !== field.ID);
    }
    this.handleChangeAssetProperty("FieldIDs", FieldIDs);
    this.setState({Fields});
  }

  handleFieldCreated = newField => {
    this.handleSetShowAddFieldDialog(false);
    this.handleSetShowFieldPropertiesDialog(true, newField);
    this.handleEnableField(newField, true);
  }

  handleSetShowAddFieldDialog = ShowAddFieldDialog => {
    this.setState({ShowAddFieldDialog});
  }

  handleSetShowFieldPropertiesDialog = (ShowFieldPropertiesDialog, FieldToCustomize) => {
    this.setState({
      ShowFieldPropertiesDialog,
      FieldToCustomize,
    });
  }

  handleSetShowManageFieldsDialog = ShowManageFieldsDialog => {
    this.setState({ShowManageFieldsDialog});
    if (!ShowManageFieldsDialog) {
      this.handleGetFields()
        .then(fields => {
          let Asset = {...this.state.Asset};
          if (Asset.PrimaryFieldID) {
            const primaryFieldFinder = fields.filter(f => f.ID === Asset.PrimaryFieldID);
            if (!primaryFieldFinder.length) {
              Asset.PrimaryFieldID = "";
              this.setState({Asset});
              this.handleChangeAssetProperty("PrimaryFieldID", "");
            }
          }
        });
    }
  }

  handleTagListValuesChanged = propertyPrefix => tagListValues => {
    const {
      Tags,
      AssetItemTags,
    } = GetTagsAndAssetItemTagsFromTagListValues(tagListValues);
    let Asset = this.handleChangeAssetProperty(propertyPrefix + "Tags", Tags);
    this.handleChangeAssetProperty(propertyPrefix + "AssetItemTags", AssetItemTags, Asset);
  }

  handleShowProgressIndicatorImmediately = ShowProgressIndicatorImmediately => {
    this.setState({ShowProgressIndicatorImmediately});
  }

  handleApiError = err => {
    this.setState({ShowProgressIndicatorImmediately: false});
    this.props.onApiError(err);
  }

  componentDidMount() {
    this.handleGetFields();
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      this.setState({open: this.props.open !== false});
      if (this.props.open) {
        GetAsset(this.props.organizationId, this.props.projectId, this.props.asset.ID)
          .then(Asset => {
            this.setState({Asset});
          })
          .catch(this.handleApiError);
      } else {
        this.setState({Asset:{...Asset}});
      }
    }
  }

  render() {
    const {
      open,
      ShowProgressIndicatorImmediately,
      ShowAddFieldDialog,
      ShowFieldPropertiesDialog,
      ShowManageFieldsDialog,
      SelectedTab,
      TaskTagListValues,
      DocumentTagListValues,
      AssetItemTagListValues,
      Asset,
      Fields,
      FieldToCustomize,
    } = this.state;
    const { 
      classes,
      theme,
      organizationId,
      projectId,
    } = this.props;

    let dialogActions = (
      <DialogActions>
        <Button onClick={() => this.handleSetShowManageFieldsDialog(true)}>
          MANAGE FIELDS
        </Button>
        <Button onClick={this.handleClose}>
          CLOSE
        </Button>
      </DialogActions>
    );

    let progressIndicator = null;
    if (ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator constrained showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    const fieldGridItems = Fields.map(f => {
      return (
        <Grid item xs={4} key={f.ID}>
          <FormControlLabel
            control={
              <Switch
                color="default"
                checked={(Asset.FieldIDs) ? Asset.FieldIDs.includes(f.ID) : false}
                onChange={e => this.handleEnableField(f, e.target.checked)}
              />
            }
            label={f.LabelOrName} />
        </Grid>
      );
    });
    fieldGridItems.push(
      <Grid item xs={4} key="newField">
        <FormControlLabel
          control={
            <IconButton aria-label="Add field"
              style={{marginRight:theme.spacing(1)}}
              onClick={() => this.handleSetShowAddFieldDialog(true)}
            >
              <NewFieldIcon />
            </IconButton>
          }
          label="Add field"/>
      </Grid>
    );
    const fieldGrid = (fieldGridItems.length)
      ? (
        <Grid container style={{alignItems:"center"}}>
          {fieldGridItems}
        </Grid>
      ) : null;

    const primaryFieldOptions = (Asset.FieldIDs && Asset.FieldIDs.length)
      ? (
        Fields.filter(f => Asset.FieldIDs.includes(f.ID))
          .map(of => {
            return {
              label: of.LabelOrName,
              value: of.ID,
            };
          })
      ) : null;
    const primaryFieldControl = (Asset.FieldIDs && Asset.FieldIDs.length && Fields.length)
      ? (
        <div style={{marginTop:theme.spacing(3),width:"50%"}}>
          <SelectControl
            id="selectPrimaryField"
            label="Field for item naming"
            // forceShrinkLabel
            hideNone
            options={primaryFieldOptions} 
            value={Asset.PrimaryFieldID}
            onValueChange={value => this.handleChangeAssetProperty("PrimaryFieldID", value)}
          />
        </div>
      ) : null;

    const taskTagRequirements = GetTagsControl(organizationId, projectId,
      null, false, false, null, true,
      TaskTagListValues,
      TaskTagListValues => this.setState({TaskTagListValues}),
      this.handleTagListValuesChanged("CompletedTaskRequirement"),
      false, -1, "Tags for completed tasks", true
    );
    const documentTagRequirements = GetTagsControl(organizationId, projectId,
      null, false, false, null, true,
      DocumentTagListValues,
      DocumentTagListValues => this.setState({DocumentTagListValues}),
      this.handleTagListValuesChanged("DocumentRequirement"),
      false, -1, "Tags for documents", true
    );
    const assetItemTagRequirements = GetTagsControl(organizationId, projectId,
      null, false, false, null, true,
      AssetItemTagListValues,
      AssetItemTagListValues => this.setState({AssetItemTagListValues}),
      this.handleTagListValuesChanged("AssetItemRequirement"),
      false, -1, "Tags for assets", true
    );

    const requirementsGrid = (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography>
            {(Asset.PluralName) ? Asset.PluralName : "Assets of this type"} will be marked complete after 
            documents, completed tasks, and other assets exist with these tags.
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {taskTagRequirements}
        </Grid>
        <Grid item xs={6}>
          {documentTagRequirements}
        </Grid>
        <Grid item xs={6}>
          {assetItemTagRequirements}
        </Grid>
      </Grid>
    );

    const tabs = (
      <Tabs
        variant={(IsMobile()) ? "scrollable" : "fullWidth"}
        className={classes.tabs}
        value={SelectedTab}
        onChange={this.handleTabChange}
      >
        <Tab label="Fields" value="fields" className={classes.tab}
          style={{
            marginLeft:(IsMobile()) ? 32 : undefined,
          }}
        />
        <Tab label="Requirements" value="requirements" className={classes.tab} />
      </Tabs>
    );

    let tabContent;
    switch (SelectedTab) {
      case "fields":
        tabContent = (
          <React.Fragment>
            {fieldGrid}
            {primaryFieldControl}
          </React.Fragment>
        );
        break;
      case "requirements":
        tabContent = requirementsGrid;
        break;
      default:
        break;
    }

    const addFieldDialog = (ShowAddFieldDialog)
      ? (
        <FieldCreationDialog
          organizationId={organizationId}
          projectId={projectId}
          open={ShowAddFieldDialog}
          onCreated={this.handleFieldCreated}
          onClose={() => this.handleSetShowAddFieldDialog(false)}
          onApiError={this.handleApiError}
        />
      ) : null;

    const fieldPropertiesDialog = (ShowFieldPropertiesDialog)
      ? (
        <FieldPropertiesDialog
          organizationId={organizationId}
          projectId={projectId}
          open={ShowFieldPropertiesDialog}
          onApiError={this.handleApiError}
          closeCallback={() => this.handleSetShowFieldPropertiesDialog(false)}
          field={FieldToCustomize}
        />
      ) : null;

    const manageFieldsDialog = (ShowManageFieldsDialog)
      ? (
        <ConfigureFieldsDialog
          organizationId={organizationId}
          projectId={projectId}
          open={ShowManageFieldsDialog}
          onApiError={this.handleApiError}
          closeCallback={() => this.handleSetShowManageFieldsDialog(false)} />
      ) : null;

    const content = (
      <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            autoFocus
            variant="outlined"
            label="Name"
            onChange={e => this.handleChangeAssetProperty("Name", e.target.value)}
            value={Asset.Name}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            label="Plural name"
            onChange={e => this.handleChangeAssetProperty("PluralName", e.target.value)}
            value={Asset.PluralName}
          />
        </Grid>
      </Grid>
      {tabs}
      {tabContent}
      {addFieldDialog}
      {fieldPropertiesDialog}
      {manageFieldsDialog}
      </React.Fragment>
    );

    return (
      <Dialog
        fullWidth={!IsMobile()}
        fullScreen={IsMobile()}
        maxWidth="md"
        open={open}
        onClose={this.handleClose}
        classes={{
          paper:classes.dialogPaper,
        }}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description">
        <DialogTitle id="dialog-title">
          <span>{Asset.Name}</span>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {progressIndicator}
          {content}
        </DialogContent>
        {dialogActions}
      </Dialog>
    );
  }
}

AssetPropertiesDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  asset: PropTypes.object.isRequired,
  onAssetRevised: PropTypes.func,
  onApiError: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default withStyles(styles, {withTheme: true})(AssetPropertiesDialog);