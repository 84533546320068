import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

import EnhancedTableHead from '../Components/EnhancedTableHead';
import ProgressIndicator from './ProgressIndicator';
import DocumentEventType from '../Model/DocumentEventType';
import { GetCommentForDisplay } from '../Util/DocumentComments';
import GetMoreButton from '../Util/GetMoreButton';

import {
  GetDateValue,
  GetUserValue,
} from '../Util/Properties';

const styles = theme => ({
  content: {
    // padding: theme.spacing(2),
    // [theme.breakpoints.down('xs')]: {
    //   padding: theme.spacing(2),
    // },
  },
  // For Table
  tableWrapper: {
    minWidth:"100%",
    width:'max-content',
    overflowX: "visible",
  },
  table: {
    backgroundColor: theme.palette.background.paper,
  },
  wrapCell: {
    maxWidth:300,
  },
});

class DocumentEventListBase extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
    }
  }

  handleRowClick = documentEvent => e => {
    // if (e.ctrlKey) {

    // } else if (this.props.onDocumentEventClick !== undefined) {
    //   this.props.onDocumentEventClick(documentEvent.ID);
    // }
  }

  getEventDetail = documentEvent => {
    let detailContent = null;
    if (!documentEvent.DetailJson) {
      return detailContent;
    }
    let detail = JSON.parse(documentEvent.DetailJson);
    switch (documentEvent.Type) {
      case "Revision":
        detailContent = (detail.Revision === 0)
          ? `Creation`
          : `Revision ${detail.Revision}`;
        break;
      case "NameChange":
        detailContent = `Document name '${detail.OldName}' changed to '${detail.NewName}'`;
        break;
      case "FieldsCommit":
        let fieldDetailArray = [];
        for (const key of Object.keys(detail.FieldNamesAndValues)) {
          fieldDetailArray.push(`${key}: ${detail.FieldNamesAndValues[key].join(", ")}`);
        }
        detailContent = fieldDetailArray.join("; ");
        break;
      case "TagsChange":
        let tagDetailArray = [...detail.Tags];
        detail.AssetItemTags.forEach(ait => {
          tagDetailArray.push(`${ait.AssetItemName} (${ait.AssetName})`);
        });
        detailContent = tagDetailArray.join(", ");
        break;
      case "Comment":
        detailContent = GetCommentForDisplay(detail.Comment, this.props.theme);
        break;
      case "DocumentApprovalAction":
        let approvalAction = "";
        switch (detail.Action) {
          case "Start":
            approvalAction = "Approval started for"
          break;
          case "Approve":
            approvalAction = "Approved for";
          break;
          case "Decline":
            approvalAction = "Declined for";
          break;
          case "Deny":
            approvalAction = "Denied from";
          break;
          default:
          break;
        }
        if (approvalAction) {
          detailContent = `${approvalAction} ${detail.ProcessName}, ${detail.ProcessElementName}; Assigned to: ${detail.AssignmentUserEmail}`;
        }
        break;
      case "GroupApprovalAction":
        let groupApprovalAction = "";
        switch (detail.Action) {
          case "Start":
            groupApprovalAction = "Approval started for"
          break;
          case "Approve":
            groupApprovalAction = "Approved for";
          break;
          case "Decline":
            groupApprovalAction = "Declined for";
          break;
          case "Deny":
            groupApprovalAction = "Denied from";
          break;
          default:
          break;
        }
        if (groupApprovalAction) {
          detailContent = `${groupApprovalAction} ${detail.ProcessName}, ${detail.ProcessElementName}; Assigned to: ${detail.AssignmentUsers.map(au => au.UserEmail).join(", ")}; Participation requirement: ${detail.ParticipationType}`;
        }
        break;
      case "RecycleBinAction":
        switch (detail.Action) {
          case "SendTo":
            detailContent = "Sent to"
          break;
          case "RestoreFrom":
            detailContent = "Restored from";
          break;
          default:
          break;
        }
        break;
      case "DocumentFolderChange":
        detailContent = detail.DocumentFolderPath || "No folder";
        break;
      case "SignatureSessionEvent":
        switch (detail.DocumentSignatureSessionEventType) {
          case "SessionStart":
            let signers = (detail.SignerNamesAndEmails && detail.SignerNamesAndEmails.length)
              ? detail.SignerNamesAndEmails
                .map(s => `${s[0]} (${s[1]})`)
                .join(", ")
              : "";
            let recipientsClause = (detail.RecipientNamesAndEmails && detail.RecipientNamesAndEmails.length)
              ? "; Recipients: " + detail.RecipientNamesAndEmails
                .map(r => `${r[0]} (${r[1]})`)
                .join(", ")
              : "";
            detailContent = `Signature-gathering initiated; Signers: ${signers}${recipientsClause}`;
          break;
          case "RecipientSigning":
            detailContent = "Signature received";
            // The signer name and e-mail is the same as the User value/already exists
            // if (detail.SignerNamesAndEmails && detail.SignerNamesAndEmails.length) {
            //   detailContent += ` from ${detail.SignerNamesAndEmails[0][0]} (${detail.SignerNamesAndEmails[0][1]})`;
            // }
          break;
          case "SessionStop":
            detailContent = "Signature session stopped";
          break;
          case "SessionCompletion":
            detailContent = "Signature-gathering complete";
          break;
          case "RenderCompletion":
            detailContent = "Signatures rendered";
          break;
          default:
          break;
        }
        break;
      case "FormSubmissionEvent":
        switch (detail.FormSubmissionEventType) {
          case "Submission":
            detailContent = "Form submitted";
          break;
          case "RenderCompletion":
            detailContent = "Form rendered to PDF";
          break;
          default:
          break;
        }
        break;
      default:
        break;
    }
    return detailContent;
  }

  render() {
    const { 
      ShowProgressIndicator,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      classes,
      theme,
      documentEvents,
      showGetMoreButton,
      onGetMoreDocumentEvents,
      onDocumentEventClick,
      sortType,
      collectionFields,
      sortDescending,
      onSortChangeFromTableHeader,
    } = this.props;

    let progressIndicator = null;
    if (ShowProgressIndicator || ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    let headCells = [
      { id: 'CreatedOn', numeric: false, label: 'Created On' },
      { id: 'EventType', numeric: false, label: 'Event Type' },
      { id: 'Detail', numeric: false, label: 'Detail' },
      { id: 'User', numeric: false, label: 'User' },
    ];

    let tableHead = (
      <EnhancedTableHead
        headCells={headCells}
        sortDescending={sortDescending}
        sortType={sortType}
        collectionFields={collectionFields}
        onSortChange={id => onSortChangeFromTableHeader(id)}
      />
    );

    let table = (documentEvents) ? (
      <div className={classes.tableWrapper}>
        <Table
          stickyHeader
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'medium'}
        >
          {tableHead}
          <TableBody>
            {documentEvents.map(de => {
              const labelId = `label_${de.ID}`;
              let eventType = "";
              let eventTypeMatch = DocumentEventType.filter(t => t.Type === de.Type);
              if (eventTypeMatch.length) {
                eventType = eventTypeMatch[0].Label;
              }
              return (
                <TableRow
                  hover={onDocumentEventClick !== undefined}
                  onClick={this.handleRowClick(de)}
                  tabIndex={-1}
                  key={de.ID}
                  style={{cursor:(onDocumentEventClick) ? "pointer" : null}}
                >
                  <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
                    <Checkbox
                      style={{
                        visibility: "hidden",
                      }}
                      color="secondary"
                    />
                  </TableCell>
                  <TableCell component="th" id={labelId} scope="row" padding="none">{GetDateValue(de.CreatedOn)}</TableCell>
                  <TableCell>{eventType}</TableCell>
                  <TableCell className={classes.wrapCell}>{this.getEventDetail(de)}</TableCell>
                  <TableCell className={classes.wrapCell}>
                  { 
                    GetUserValue(de.CreatedByUserEmail, 
                      (de.CreatedByUserName)
                        ? `${de.CreatedByUserName} (${de.CreatedByUserEmail})`
                        : de.CreatedByUserEmail
                    )
                  }
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    ) : null;

    return (
      <div className={classes.content}>

        {progressIndicator}

        {table}

        {GetMoreButton(showGetMoreButton, () => onGetMoreDocumentEvents(), "List", theme)}
      </div>    
    );
  }
}

export default withStyles(styles, {withTheme: true})(DocumentEventListBase);