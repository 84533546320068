import React, { Component } from 'react';
import TitleComponent from '../Components/TitleComponent';

import { withStyles } from '@material-ui/core/styles';

// import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
// import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import AccountCircle from '@material-ui/icons/AccountCircle';
// import ClearIcon from '@material-ui/icons/Clear';

import UiCore from '../Components/UiCore';
import API, {
  GetUserPathForApi,
  GetUserProfilePathForApi,
  GetUserAddressBookItemsPathForApi,
  GetUserAvatarsPathForApi,
} from '../Util/api';
import MaskedTextField from '../Components/MaskedTextField';
import { 
  SMSNumberControl,
  GetSMSCountryCodeAndNumberIsValid,
  SMSNumberMask,
} from '../Components/SMSNumberControl';
import {
  GetUserApiKeysPath,
} from '../Util/PathHelper';
import {
  GetUserValue,
} from '../Util/Properties';
import { GlobalContext } from '../Context/Global.context';

import { ValidateEmail } from '../Util/Regex';
import MultiUseDialog from '../Components/MultiUseDialog';
import debounce from 'es6-promise-debounce';
import AvatarSelectionDialog from '../Components/AvatarSelectionDialog';

import { IsMobile } from '../Util/MobileDetector';

const styles = theme => ({
  contentContainer: {
    background:theme.palette.background.paper,
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    height:"100%",
  },
  profileGrid: {
    alignItems:"center",
  },
});

class UserProfile extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
  
    this.state = {
      AvatarUpdateInProgress: false,
      UserProfile: null,
      CurrentAvatar: null,
      ShowAvatarSelectionDialog: false,
      ShowEmailAddressDialog: false,
      EmailAddressDialogBodyText: "",
      EmailAddressDialogConfirm: () => {},
      EmailDialogEmail: "",
      Email: "",
      ShowSMSNumberDialog: false,
      ShowSMSNumberConfirmationDialog: false,
      SMSNumberDialogSMSNumber: "",
      SMSNumber: "",
      ShowSMSNumberChangeCodeVerifyDialog: false,
      SMSNumberChangeCode: "",
      ShowRemoveSMSNumberDialog: false,
      ShowClearAddressBookDialog: false,
      ShowDeleteAccountDialog: false,
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
      ShowDialogProgressIndicatorImmediately: false,
    }

    this.AvatarImageUploadCancelToken = null;
    this.BeginFileUploadFunc = () => {};
  }

  loadUserProfileAndAvatar = () => {
    this.setState({
      ShowProgressIndicatorImmediately: true,
    });
    API.get(GetUserProfilePathForApi())
      .then(resp => {
        this.setState({
          UserProfile: resp.data,
          ShowProgressIndicatorImmediately: false,
        });
      })
      .catch(this.handleApiError);
  }

  handleUpdateUserProfile = debounce(userProfile => {
    if (!userProfile) {
      userProfile = {...this.state.UserProfile};
    }
    
    API.put(GetUserProfilePathForApi(), userProfile)
      .catch(this.handleApiError);
  }, 500)

  handleProfileChange = (propName, value) => {
    let UserProfile = {...this.state.UserProfile};
    
    UserProfile[propName] = value;

    this.setState({UserProfile});
    this.handleUpdateUserProfile(UserProfile);
  }

  handleSetEmailDialogVisibility = (visible, isConfirmation) => {
    if (!this.state.UserProfile || this.state.UserProfile.UserProvider !== "email") {
      return;
    }
    let stateToUpdate = {
      ShowEmailAddressDialog: visible,
      ShowDialogProgressIndicatorImmediately: false,
    };
    if (visible) {
      stateToUpdate.EmailDialogEmail = "";
      if (isConfirmation) {
        stateToUpdate.EmailAddressDialogBodyText = "Confirm your e-mail address.";
        stateToUpdate.EmailAddressDialogConfirm = this.handleEmailConfirmation;
      } else {
        stateToUpdate.EmailAddressDialogBodyText = "Enter a new e-mail address.";
        stateToUpdate.EmailAddressDialogConfirm = this.handleEmailChangeVerify;
      }
    }
    this.setState(stateToUpdate);
  }

  handleSetEmailConfirmationDialogVisibility = visible => {
    this.handleSetEmailDialogVisibility(visible, true);
  }

  handleEmailChangeVerify = () => {
    if (!this.state.EmailDialogEmail) {
      return;
    }
    if (!ValidateEmail(this.state.EmailDialogEmail)) {
      this.handleApiError("Please enter a valid e-mail address.");
      return;
    }
    let email = this.state.EmailDialogEmail.trim();
    if (email.toLowerCase() === this.state.UserProfile.UserEmail.toLowerCase()) {
      this.handleApiError("This matches your current e-mail address.");
      return; 
    }

    this.setState({ShowDialogProgressIndicatorImmediately: true});
    API.post("/user/emailAddressVerifications", {Email: email})
      .then(resp => {
        this.setState({
          Email: email,
          ShowDialogProgressIndicatorImmediately: false,
        });
        this.handleSetEmailDialogVisibility(true, true);
      })
      .catch(this.handleApiError);
  }

  handleEmailConfirmation = () => {
    if (!this.state.EmailDialogEmail) {
      return;
    }
    let email = this.state.EmailDialogEmail.trim();
    if (email.toLowerCase() !== this.state.Email.toLowerCase()) {
      this.handleApiError("E-mail address doesn't match.");
      return;
    }
    this.handleSetEmailDialogVisibility(false);
    this.handleSendEmailChangeConfirmation();
  }

  handleSendEmailChangeConfirmation = () => {
    this.setState({ShowProgressIndicatorImmediately: true});
    API.post(`/user/emailAddresses`, {Email: this.state.Email})
      .then(resp => {
          this.setState({
            Alert: {
              Title: "Confirmation sent",
              BodyText: "Check your new e-mail address for instructions on completing the address change.",
            },
            ShowProgressIndicatorImmediately: false,
          });
      })
      .catch(this.handleApiError);
  }

  handleSetSMSNumberDialogVisibility = visible => {
    if (!this.state.UserProfile || this.state.UserProfile.UserProvider !== "email") {
      return;
    }
    this.setState({
      SMSNumberDialogSMSNumber: "",
      ShowSMSNumberDialog: visible,
      ShowDialogProgressIndicatorImmediately: false,
    });
  }

  handleSetSMSNumberConfirmationDialogVisibility = visible => {
    if (!this.state.UserProfile || this.state.UserProfile.UserProvider !== "email") {
      return;
    }
    this.setState({
      SMSNumberDialogSMSNumber: "",
      ShowSMSNumberConfirmationDialog: visible,
      ShowDialogProgressIndicatorImmediately: false,
    });
  }

  handleSMSNumberDialogSMSNumberValueChange = smsNumber => {
    this.setState({ SMSNumberDialogSMSNumber: smsNumber });
  }

  handleSMSNumberChangeVerify = () => {
    if (!this.state.SMSNumberDialogSMSNumber) {
      return;
    }
    if (!GetSMSCountryCodeAndNumberIsValid(this.state.SMSNumberDialogSMSNumber)) {
      this.handleApiError("Please enter a valid phone number.");
      return;
    }
    if (this.state.SMSNumberDialogSMSNumber === this.state.UserProfile.OTPSMSNumber) {
      this.handleApiError("This matches your current phone number.");
      return; 
    }
    this.setState({SMSNumber: this.state.SMSNumberDialogSMSNumber});
    this.handleSetSMSNumberDialogVisibility(false);
    this.handleSetSMSNumberConfirmationDialogVisibility(true);
  }

  handleSMSNumberConfirmation = () => {
    if (!this.state.SMSNumberDialogSMSNumber) {
      return;
    }
    let smsNumber = this.state.SMSNumberDialogSMSNumber;
    if (smsNumber !== this.state.SMSNumber) {
      this.handleApiError("Phone number doesn't match.");
      return;
    }
    this.handleSetSMSNumberConfirmationDialogVisibility(false);
    this.handleSMSNumberChangeCodeSend();
  }

  handleSMSNumberChangeCodeSend = () => {
    this.handleSetSMSNumberChangeCodeVerifyDialogVisibility(false);
    this.setState({ ShowProgressIndicatorImmediately: true });
    API.post("/user/smsNumbers", {SMSNumber: this.state.SMSNumber})
      .then(resp => {
        this.setState({
          ShowProgressIndicatorImmediately: false,
        });
        this.handleSetSMSNumberChangeCodeVerifyDialogVisibility(true);
      })
      .catch(this.handleApiError);
  }

  handleSetSMSNumberChangeCodeVerifyDialogVisibility = visible => {
    let stateToUpdate = {
      ShowSMSNumberChangeCodeVerifyDialog: visible,
      ShowDialogProgressIndicatorImmediately: false,
    };
    if (visible) {
      stateToUpdate.SMSNumberChangeCode = "";
    }
    this.setState(stateToUpdate);
  }

  handleSMSNumberChangeCodeVerify = () => {
    if (!this.state.SMSNumberChangeCode) {
      return;
    }
    this.setState({ShowDialogProgressIndicatorImmediately: true});
    API.put(`/user/smsNumbers/${this.state.SMSNumberChangeCode}`)
      .then(resp => {
          this.handleSetSMSNumberChangeCodeVerifyDialogVisibility(false);
          this.loadUserProfileAndAvatar();
          this.setState({
            Alert: {
              Title: "Phone number updated",
              BodyText: "Your phone number has been set successfully.",
            },
            ShowDialogProgressIndicatorImmediately: false,
          });
      })
      .catch(this.handleApiError);
  }

  handleSetRemoveSMSNumberDialogVisibility = visible => {
    this.setState({
      ShowRemoveSMSNumberDialog: visible,
      ShowDialogProgressIndicatorImmediately: false,
    });
  }

  handleRemoveSMSNumber = () => {
    this.handleSetRemoveSMSNumberDialogVisibility(false);
    this.setState({ShowProgressIndicatorImmediately: true});
    API.delete(`/user/smsNumbers`)
      .then(resp => {
          this.loadUserProfileAndAvatar();
          this.setState({
            Alert: {
              Title: "Phone number removed",
              BodyText: "Your phone number has been removed successfully.",
            },
            ShowProgressIndicatorImmediately: false,
          });
      })
      .catch(this.handleApiError);
  }

  handleSetClearAddressBookDialogVisibility = visible => {
    this.setState({
      ShowClearAddressBookDialog: visible,
      ShowDialogProgressIndicatorImmediately: false,
    });
  }

  handleClearAddressBook = () => {
    this.handleSetClearAddressBookDialogVisibility(false);
    this.setState({ShowProgressIndicatorImmediately: true});
    API.delete(GetUserAddressBookItemsPathForApi())
      .then(resp => {
          this.setState({
            Alert: {
              Title: "Address book cleared",
              BodyText: "All items in your address book have been cleared.",
            },
            ShowProgressIndicatorImmediately: false,
          });
      })
      .catch(this.handleApiError);
  }

  handleSetDeleteAccountDialogVisibility = visible => {
    this.setState({
      ShowDeleteAccountDialog: visible,
      ShowDialogProgressIndicatorImmediately: false,
    });
  }

  handleDeleteAccount = confirmation => {
    if (!confirmation) {
      return;
    }
    if (confirmation !== this.context.UserPreferences.UserEmail) {
      this.handleAlert({
        Title: "Email mismatch",
        BodyText:"Please enter your e-mail address exactly as it appears.",
      });
      return;
    }
    this.handleSetDeleteAccountDialogVisibility(false);
    this.setState({ShowProgressIndicatorImmediately: true});
    API.delete(GetUserPathForApi())
      .then(resp => {
          this.props.history.push("/logout");
      })
      .catch(this.handleApiError);
  }

  handleSetShowAvatarSelectionDialog = ShowAvatarSelectionDialog => {
    this.setState({ShowAvatarSelectionDialog});
  }

  handleAvatarSelectionDialogClosed = file => {
    this.handleSetShowAvatarSelectionDialog(false);
    if (file) {
      this.handleReplaceAvatarImage(file);
    }
  }

  handleReplaceAvatarImage = file => {
    this.setState({
      ShowProgressIndicatorImmediately: true,
      AvatarUpdateInProgress: true,
    });
    let avatarImageUpload = {};
    if (file && file.type) {
      avatarImageUpload.ContentType = file.type;
    }
    API.put(GetUserAvatarsPathForApi(), avatarImageUpload)
      .catch(this.handleApiError)
      .finally(() => {
        this.context.Reset();
        // Delay a bit to ensure avatar on this page is updated correctly
        setTimeout(() => this.setState({
          AvatarUpdateInProgress: false,
          ShowProgressIndicatorImmediately: false,
        }), 500);
      });
  }

  handleAlert = Alert => {
    this.setState({Alert});
  }

  handleApiError = err => {
    this.setState({
      ApiError: err,
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
      ShowDialogProgressIndicatorImmediately: false,
    });
    if (err) {
      setTimeout(() => this.handleApiError(null), 1);
    }
  }

  componentDidMount() {
    this.loadUserProfileAndAvatar();
  }

  render() {
    const {
      UserProfile,
      ApiError,
      Alert,
      AvatarUpdateInProgress,
      ShowEmailAddressDialog,
      EmailDialogEmail,
      EmailAddressDialogBodyText,
      EmailAddressDialogConfirm,
      ShowSMSNumberDialog,
      ShowSMSNumberConfirmationDialog,
      ShowSMSNumberChangeCodeVerifyDialog,
      SMSNumberChangeCode,
      ShowRemoveSMSNumberDialog,
      ShowClearAddressBookDialog,
      ShowDeleteAccountDialog,
      ShowAvatarSelectionDialog,
      ShowProgressIndicator,
      ShowProgressIndicatorImmediately,
      ShowDialogProgressIndicatorImmediately,
  	} = this.state;
    const {
      history,
      location,
      classes,
      theme,
    } = this.props;
    const {
      UserPreferences,
      // UserHasCustomAvatar,
    } = this.context;

    let content = null;
    if (UserProfile) {
      let providerIsEmail = (UserProfile.UserProvider === "email");

      let emailAddressDialogDetails = {
        Open: ShowEmailAddressDialog,
        ShowProgressIndicatorImmediately: ShowDialogProgressIndicatorImmediately,
        Title: "E-mail address change",
        IsConfirmation: true,
        BodyContent: (
          <Grid container direction="column" spacing={3} style={{marginBottom:0}}>
            <Grid item>
              {EmailAddressDialogBodyText}
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                fullWidth
                autoFocus
                onChange={e => this.setState({EmailDialogEmail:e.target.value})}
                onKeyDown={e => { if (e.keyCode === 13) { e.preventDefault(); EmailAddressDialogConfirm(); } }}
                value={EmailDialogEmail}
              />
            </Grid>
          </Grid>
        ),
        ConfirmLabel: "NEXT",
        ConfirmCallback: EmailAddressDialogConfirm,
        CancelCallback: () => this.handleSetEmailDialogVisibility(false),
        CloseCallback: () => this.handleSetEmailDialogVisibility(false),
      };

      let smsNumberDialogDetails = {
        Open: ShowSMSNumberDialog,
        ShowProgressIndicatorImmediately: ShowDialogProgressIndicatorImmediately,
        Title: "Phone number change",
        IsConfirmation: true,
        BodyContent: (
          <Grid container direction="column" spacing={3} style={{marginBottom:0}}>
            <Grid item>
              Enter your phone number.
            </Grid>
            <Grid item>
             <SMSNumberControl
                onEnterKey={this.handleSMSNumberChangeVerify}
                onValueChange={this.handleSMSNumberDialogSMSNumberValueChange}
              />
            </Grid>
          </Grid>
        ),
        ConfirmLabel: "NEXT",
        ConfirmCallback: this.handleSMSNumberChangeVerify,
        CancelCallback: () => this.handleSetSMSNumberDialogVisibility(false),
        CloseCallback: () => this.handleSetSMSNumberDialogVisibility(false),
      };

      let smsNumberConfirmationDialogDetails = {
        Open: ShowSMSNumberConfirmationDialog,
        ShowProgressIndicatorImmediately: ShowDialogProgressIndicatorImmediately,
        Title: "Phone number change",
        IsConfirmation: true,
        BodyContent: (
          <Grid container direction="column" spacing={3} style={{marginBottom:0}}>
            <Grid item>
              Confirm your phone number.
            </Grid>
            <Grid item>
             <SMSNumberControl
                onEnterKey={this.handleSMSNumberConfirmation}
                onValueChange={this.handleSMSNumberDialogSMSNumberValueChange}
              />
            </Grid>
          </Grid>
        ),
        ConfirmLabel: "NEXT",
        ConfirmCallback: this.handleSMSNumberConfirmation,
        CancelCallback: () => this.handleSetSMSNumberConfirmationDialogVisibility(false),
        CloseCallback: () => this.handleSetSMSNumberConfirmationDialogVisibility(false),
      };

      let smsNumberChangeCodeDialogDetails = {
        Open: ShowSMSNumberChangeCodeVerifyDialog,
        ShowProgressIndicatorImmediately: ShowDialogProgressIndicatorImmediately,
        Title: "Device verification",
        DialogWidth:"xs",
        IsConfirmation: true,
        BodyContent: (
          <Grid container direction="column" spacing={3} style={{marginBottom:0}}>
            <Grid item>
              A code has been sent to your device. Enter it here.
            </Grid>
            <Grid item>
              <MaskedTextField
                id="otp"
                label="Code"
                value={SMSNumberChangeCode}
                mask=">AAAAAAAAaaaaaaaa"
                autoFocus
                onValueChange={e => this.setState({SMSNumberChangeCode:e.target.value})}
                onEnterKey={() => this.handleSMSNumberChangeCodeVerify()}
              />
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={this.handleSMSNumberChangeCodeVerify}
              >
                VERIFY
              </Button>
            </Grid>
          </Grid>
        ),
        ConfirmLabel: "RESEND",
        ConfirmCallback: this.handleSMSNumberChangeCodeSend,
        CancelCallback: () => this.handleSetSMSNumberChangeCodeVerifyDialogVisibility(false),
        CloseCallback: () => this.handleSetSMSNumberChangeCodeVerifyDialogVisibility(false),
      };

      let removeSMSNumberDialogDetails = {
        Open: ShowRemoveSMSNumberDialog,
        ShowProgressIndicatorImmediately: ShowDialogProgressIndicatorImmediately,
        Title: "Remove phone number?",
        IsConfirmation: true,
        BodyText: "You will not be able to sign in using text messaging if you remove this number. Continue?",
        ConfirmCallback: this.handleRemoveSMSNumber,
        CancelCallback: () => this.handleSetRemoveSMSNumberDialogVisibility(false),
        CloseCallback: () => this.handleSetRemoveSMSNumberDialogVisibility(false),
      };

      let clearAddressBookDialogDetails = {
        Open: ShowClearAddressBookDialog,
        ShowProgressIndicatorImmediately: ShowDialogProgressIndicatorImmediately,
        Title: "Clear address book?",
        IsConfirmation: true,
        BodyText:"This action cannot be undone.",
        BodyClassName:"warning",
        ConfirmCallback: this.handleClearAddressBook,
        CancelCallback: () => this.handleSetClearAddressBookDialogVisibility(false),
        CloseCallback: () => this.handleSetClearAddressBookDialogVisibility(false),
      };

      let deleteUserAccountConfirmationDialogDetails = {
        Open:ShowDeleteAccountDialog,
        ShowProgressIndicatorImmediately:ShowDialogProgressIndicatorImmediately,
        Title:"Delete your user account?",
        FullScreen: IsMobile(),
        FullWidth: !IsMobile(),
        RequireTextInput1:true,
        BodyContent:(
          <Grid container spacing={2} style={{paddingBottom:theme.spacing(3)}}>
            <Grid item className={classes.warning}>
              This action will remove your user account permanently, including all personal data associated with it.
            </Grid>
            <Grid item>
            Confirm you want to delete your account by entering your email address:
            <span style={{marginLeft:theme.spacing(1),fontWeight:"bold"}}>{UserPreferences.UserEmail}</span>
            </Grid>
          </Grid>
        ),
        DialogWidth: "xs",
        // TextInput1PlaceHolder:UserPreferences.UserEmail,
        CancelCallback:() => this.handleSetDeleteAccountDialogVisibility(false),
        CloseCallback:() => this.handleSetDeleteAccountDialogVisibility(false),
        ConfirmLabel:"DELETE",
        ConfirmCallback:this.handleDeleteAccount,
      };

      // const emailChangeButton = (providerIsEmail)
      //   ? (
      //       <Button variant="contained" fullWidth size="large"
      //         onClick={() => this.handleSetEmailDialogVisibility(true)}>
      //         CHANGE
      //       </Button>
      //   ) : null;
      // const emailAddressGridItem = (
      //   <Grid item key="g_email" style={{width:"100%"}}>
      //     <Grid container spacing={2} style={{alignItems:"center"}}>
      //       <Grid item xs={9}>
      //         <TextField
      //           fullWidth
      //           variant="outlined"
      //           id="email"
      //           label="E-mail"
      //           disabled
      //           value={UserProfile.UserEmail}
      //         />
      //       </Grid>
      //       <Grid item xs={3}>
      //         {emailChangeButton}
      //       </Grid>
      //     </Grid>
      //   </Grid>
      // );
      let smsNumberSetChangeButtonText = (UserProfile.OTPSMSNumber)
        ? "CHANGE" : "SET";
      let smsPhoneNumberRemoveButton = (UserProfile.OTPSMSNumber)
        ? (
          <Button variant="contained" fullWidth size="large"
            onClick={() => this.handleSetRemoveSMSNumberDialogVisibility(true)}>
            REMOVE
          </Button>
        ) : null;
      const smsPhoneNumberGridItem = (providerIsEmail)
        ? (
          <Grid key="gi_phoneInfo" item>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="body1">
                  A phone number allows you to receive one-time codes via text message.
                </Typography>
              </Grid>
              <Grid key="gi_smsnumber" item xs={6}
                onClick={() => this.handleSetSMSNumberDialogVisibility(true)}>
                <MaskedTextField
                  id="smsnumber"
                  guide={false}
                  disabled
                  label="Phone number"
                  value={UserProfile.OTPSMSNumber}
                  mask={SMSNumberMask}
                  onValueChange={() => {}}
                />
              </Grid>
              <Grid item key="gi_smschange" xs={3}>
                <Button variant="contained" fullWidth size="large"
                  onClick={() => this.handleSetSMSNumberDialogVisibility(true)}>
                  {smsNumberSetChangeButtonText}
                </Button>
              </Grid>
              <Grid item key="gi_smsremove" xs={3}>
                {smsPhoneNumberRemoveButton}
              </Grid>
            </Grid>
          </Grid>
        ) : null;

      // const getUploadAvatarMenuItem = clickFunc => {
      //   return (
      //     <Button onClick={e => (clickFunc) ? clickFunc(e) : undefined}>
      //       SET AVATAR
      //     </Button>
      //   );
      // }

      // const removeAvatarGridItem = (UserHasCustomAvatar)
      //   ? (
      //     <Tooltip title="Clear avatar">
      //       <IconButton aria-label="Clear avatar"
      //         onClick={this.handleClearAvatar}
      //       >
      //         <ClearIcon />
      //       </IconButton>
      //     </Tooltip>
      //   ) : null;

      const nameGridItem = (
        <Grid item key="gi_name" style={{width:"100%"}}>
          <TextField
            fullWidth
            variant="outlined"
            id="name"
            label="Name"
            value={UserProfile.UserNameOverride || ""}
            onChange={e => this.handleProfileChange("UserNameOverride", e.target.value)}
          />
        </Grid>
        );

      const phoneGridItem = (
        <Grid item key="gi_phone" style={{width:"100%"}}>
          <TextField
            fullWidth
            variant="outlined"
            id="phone"
            label="Contact Phone"
            value={UserProfile.UserPhone || ""}
            onChange={e => this.handleProfileChange("UserPhone", e.target.value)}
          />
        </Grid>
      );

      const profileGrid = (UserProfile)
        ? (
          <Grid container direction="column" spacing={3} className={classes.profileGrid}>
            <Grid item key="gi_avatar" style={{marginBottom:theme.spacing(2)}}>
              {
                (!AvatarUpdateInProgress)
                  ? GetUserValue(
                    UserProfile.UserEmail,
                    UserProfile.UserNameOverride || UserProfile.UserName,
                    "profile",
                    false,
                    undefined,
                    {cursor:"pointer"},
                    {},
                    true,
                    () => this.handleSetShowAvatarSelectionDialog(true),
                  )
                  : <AccountCircle style={{ width: 96, height: 96, }} />
              }
            </Grid>

            {/*{emailAddressGridItem}*/}

            {nameGridItem}
            {phoneGridItem}

            {smsPhoneNumberGridItem}
            
            <Grid key="gi_clearAddressBook" item>
              <Button fullWidth size="large"
                onClick={() => this.handleSetClearAddressBookDialogVisibility(true)}>
                CLEAR ADDRESS BOOK
              </Button>
            </Grid>
            
            <Grid key="gi_apiKeys" item xs={12}>
              <Button fullWidth size="large"
                onClick={() => history.push(GetUserApiKeysPath())}>
                API KEYS
              </Button>
            </Grid>

            <Grid key="gi_deleteAccount" item>
              <Button fullWidth size="large"
                onClick={() => this.handleSetDeleteAccountDialogVisibility(true)}>
                DELETE ACCOUNT
              </Button>
            </Grid>

          </Grid>
        )
        : null;

      content = (
        <Container maxWidth="sm" className={classes.contentContainer}>
          <TitleComponent title="User Profile" />
          
          <MultiUseDialog Details={emailAddressDialogDetails} />
          <MultiUseDialog Details={smsNumberDialogDetails} />
          <MultiUseDialog Details={smsNumberConfirmationDialogDetails} />
          <MultiUseDialog Details={smsNumberChangeCodeDialogDetails} />
          <MultiUseDialog Details={removeSMSNumberDialogDetails} />
          <MultiUseDialog Details={clearAddressBookDialogDetails} />
          <MultiUseDialog Details={deleteUserAccountConfirmationDialogDetails} />

          <AvatarSelectionDialog
            open={ShowAvatarSelectionDialog}
            history={history}
            location={location}
            onClose={this.handleAvatarSelectionDialogClosed}
            onApiError={this.handleApiError}
            onAlert={this.handleAlert}
          />

          {profileGrid}
        </Container>
      );
    }

    let title = (UserProfile) ? "User Profile" : "Loading profile...";

    return (
      <UiCore title={title}
        apiError={ApiError}
        alert={Alert}
        hideProjectName
        showProgressIndicator={ShowProgressIndicator}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
        content={content}
      />
    );
  }
}

export default withStyles(styles, {withTheme: true})(UserProfile);