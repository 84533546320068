import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';

// import FlipMove from 'react-flip-move';

import { DragSource, DropTarget } from 'react-dnd';

import GetMoreButton from '../Util/GetMoreButton';

/**
 * Implements the drag source contract.
 */
const kbColumnSource = {
  canDrag(props) {
    return (props.columnProperty.canDrag);
  },
  beginDrag(props, monitor, component) {
    props.onStartMoveColumn(props.columnProperty);
    return {
      columnProperty: props.columnProperty,
      onMoveColumnOverColumnContainer: props.onMoveColumnOverColumnContainer,
      // onDropColumn: props.onDropColumn,
    };
  },
  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      props.onAbortMoveColumn(props.columnProperty);
    } else {
      props.onEndMoveColumn(props.columnProperty);
    }
  }
};

/**
 * Specifies the drop target contract.
 * All methods are optional.
 */
const kbColumnTarget = {
  // drop(props, monitor, component) {
  //   if (monitor.didDrop()) {
  //   //   // If you want, you can check whether some nested
  //   //   // target already handled drop
  //     return;
  //   }

  //   // Obtain the dragged item
  //   const sourceTask = monitor.getItem();
  //   sourceTask.onDropTask();

  //   // You can also do nothing and return a drop result,
  //   // which will be available as monitor.getDropResult()
  //   // in the drag source's endDrag() method
  //   //return { moved: true };
  // },
  hover(props, monitor, component) {
    if (!component)
      return null;

    const isOverColumnNotCard = monitor.isOver({ shallow: true});
    if (!isOverColumnNotCard) {
      return null;
    }

    // Obtain the dragged item
    const sourceItem = monitor.getItem();
    // console.log("hover over column", component.props.columnProperty, sourceItem);

    switch (monitor.getItemType()) {
      case "KanbanColumn":
        props.onMoveColumnOverColumn(sourceItem.columnProperty);
        break;
      case "TaskCardForKanban":
        sourceItem.onMoveTaskOverColumn(sourceItem.Task, component.props.columnProperty);
        break;
      default:
        break;
    }
  },
}

/**
 * Specifies the props to inject into your component.
 */
function dragCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    itemType: monitor.getItemType(),
  };
}

/**
 * Specifies which props to inject into your component.
 */
function dropCollect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    // canDrop: monitor.canDrop(),
    // itemType: monitor.getItemType()
  };
}

const styles = theme => ({
  kbColumn: {
    position:"relative",
    // height:"max-content",
    minHeight:150,
    width:260,
    margin:theme.spacing(1),
    backgroundColor:theme.palette.background.kanbanColumn,
    "&:hover $kbColumnHeaderActions": {
      display:"flex",
    }
  },
  kbColumnHeader: {
    position:"sticky",
    top:0,
    userSelect: "none",
    zIndex:2,
    backgroundColor:theme.palette.background.kanbanColumn,
    display:"flex",
    alignItems:"center",
    cursor:"grab",
  },
  kbColumnHeaderActions: {
    flexGrow: 1,
    display:"none",
    justifyContent:"flex-end",
  },
  kbTitle: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    padding:theme.spacing(1),
    paddingTop:theme.spacing(1) / 2,
    paddingBottom:theme.spacing(1) / 2,
  },
  kbCardGrid: {
    padding:theme.spacing(1),
  },
});

class KanbanColumn extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      ShowActionMenu: false,
    }

    this.ActionMenuRef = React.createRef();
  }

  handleSetShowActionMenu = ShowActionMenu => {
    if (ShowActionMenu) {
      if (this.props.onActionMenuOpen) {
        this.props.onActionMenuOpen();
      }
    } else {
      if (this.props.onActionMenuClose) {
        this.props.onActionMenuClose();
      }
    }
    this.setState({ShowActionMenu});
  }

  handleActionMenuItem = actionFunc => {
    this.handleSetShowActionMenu(false);
    actionFunc();
  }

  render() {
    const {
      theme,
      classes,
      isProjectAdmin,
      cardGridItems,
      columnProperty,
      connectDragSource,
      connectDropTarget,
      collectionIsDragging,
      isDragging,
      isOver,
      itemType,
      showGetMoreButton,
      onGetMoreItems,
      onDeleteColumn,
      onRenameColumn,
      onGetStats,
      onBeginCreateTask,
    } = this.props;
    const {
      ShowActionMenu,
    } = this.state;

    let actionItems = [];
    actionItems.push(
      <Tooltip title="Add task" key="action_createTask">
        <IconButton 
          size="small"
          onClick={() => onBeginCreateTask(columnProperty)}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
    );
    const hasAdminActions = (
      onRenameColumn
      || onDeleteColumn
      || onGetStats
    );
    if (hasAdminActions && isProjectAdmin) {
      actionItems.push(
        <IconButton
         key="action_menu"
          size="small"
          ref={instance => this.ActionMenuRef = instance}
          onClick={() => this.handleSetShowActionMenu(true)}
        >
          <MoreVertIcon />
        </IconButton>
      );
    }
    let actions;
    if (actionItems.length) {
      actions = (
        <div className={classes.kbColumnHeaderActions}
          style={{
            display:(isDragging || collectionIsDragging) ? "none" : undefined,
          }}
        >
          {actionItems}
        </div>
      );
    }

    const getStatsMenuItem = (onGetStats)
      ? (
        <MenuItem onClick={() => this.handleActionMenuItem(onGetStats)}>
          Get stats
        </MenuItem>
      ) : null;
    const renameMenuItem = (onRenameColumn)
      ? (
        <MenuItem onClick={() => this.handleActionMenuItem(onRenameColumn)}>
          Rename
        </MenuItem>
      ) : null;
    const deleteMenuItem = (onDeleteColumn)
      ? (
        <MenuItem onClick={() => this.handleActionMenuItem(onDeleteColumn)}>
          Delete
        </MenuItem>
      ) : null;


    const actionMenu = (
      <Menu
        id="action-menu"
        anchorEl={(this.ActionMenuRef && !this.ActionMenuRef.hasOwnProperty('current')) ? this.ActionMenuRef : undefined}
        open={ShowActionMenu}
        onClose={() => this.handleSetShowActionMenu(false)}
      >
        {getStatsMenuItem}
        {renameMenuItem}
        {/*<Divider />*/}
        {deleteMenuItem}
      </Menu>
    );

    const filteredCardGridItems = (cardGridItems && cardGridItems.length)
      ? (columnProperty.customFilter)
        ? cardGridItems
          .filter(c => columnProperty.customFilter(c.props.rawobject))
        : cardGridItems
          .filter(c => c.props.rawobject[columnProperty.name] === columnProperty.value)
      : null;
    return (
      <Paper elevation={2} className={classes.kbColumn}
        style={{
          backgroundColor: (isOver && !(itemType === "KanbanColumn" && !columnProperty.canDrag))
            ? theme.palette.background.appBar : undefined,
        }}
        ref={instance => connectDropTarget(connectDragSource(ReactDOM.findDOMNode(instance)))}
      >
        <div className={classes.kbColumnHeader}
          style={{
            cursor:(!columnProperty.canDrag || !isProjectAdmin) ? "default" : undefined,
          }}
        >
          <Typography variant="h6" className={classes.kbTitle}>
            {columnProperty.title}
          </Typography>
          {actions}
          {actionMenu}
        </div>
        <Grid container direction="column" spacing={1} className={classes.kbCardGrid}>
          {/*<FlipMove typeName={null}>*/}
          {filteredCardGridItems}
          {/*</FlipMove>*/}
          {GetMoreButton(showGetMoreButton, () => onGetMoreItems(), "Kanban", theme)}
        </Grid>
      </Paper>
    );
  }
}

KanbanColumn.propTypes = {
  classes: PropTypes.object.isRequired,
  cardGridItems: PropTypes.array,
  columnProperty: PropTypes.object.isRequired,
  collectionIsDragging: PropTypes.bool,
  showGetMoreButton: PropTypes.bool.isRequired,
  onGetMoreItems: PropTypes.func.isRequired,
  onBeginCreateTask: PropTypes.func.isRequired,
  onStartMoveColumn: PropTypes.func.isRequired,
  onRenameColumn: PropTypes.func,
  onDeleteColumn: PropTypes.func,
  onGetStats: PropTypes.func,
  onActionMenuOpen: PropTypes.func,
  onActionMenuClose: PropTypes.func,
  isProjectAdmin: PropTypes.bool,
};

export default DropTarget(['TaskCardForKanban','KanbanColumn'], kbColumnTarget, dropCollect)(
  DragSource('KanbanColumn', kbColumnSource, dragCollect)(
    withStyles(styles, {withTheme: true})(KanbanColumn)
  )
);