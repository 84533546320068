import { GetFieldForFilterAndSort } from '../Util/Field';
import {
	AddFilterSortFieldsToCollectionFields,
} from '../Util/Fields';

export const GetAssetItemCollectionFields = filterSortFields => {
	let collectionFields = [
		GetFieldForFilterAndSort(
			"Meta_text_kw256lc[Name].keyword",
			"Name",
			"FieldType_Text",
			"Meta_text_kw256lc[Name]",
			[
				{
				    value: "startsWith",
				    label: "word starts with",
				},
			],
		),
		GetFieldForFilterAndSort(
			"Meta_date_str256[CreatedOn].string",
			"Created On",
			"FieldType_Date",
		),
		GetFieldForFilterAndSort(
			"Meta_bool[IsComplete]",
			"Complete",
			"FieldType_Bool",
		),
	];
	AddFilterSortFieldsToCollectionFields(collectionFields, filterSortFields);
	return collectionFields;
}
