import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Comments from '../Components/Comments';

import {
  GetProjectCommentsPathForApi,
} from '../Util/api';
import { IsMobile } from '../Util/MobileDetector';
import UiCore from '../Components/UiCore';

const styles = theme => ({
  root: {
    padding:theme.spacing(2),
    height:"100%",
  }
});

class ProjectComments extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
    }
  }

  handleApiError = err => {
    if (this.props.onApiError) {
      this.setState({ApiError:err});
      this.props.onApiError(err);
    }
  }

  handleAlert = details => {
    this.setState({Alert:details});
    if (this.props.onAlert) {
      this.props.onAlert(details);
    }
  }

  render() {
    const {
      ApiError,
      Alert,
    } = this.state;
    const {
      organizationId,
      projectId,
      classes,
      returnContentOnly,
    } = this.props;

    let content = (
      <div className={classes.root}>
        <Comments
          organizationId={organizationId}
          projectId={projectId}
          newCommentBottomFixed={!IsMobile()}
          commentsUri={GetProjectCommentsPathForApi(organizationId, projectId)}
          collectionName="ProjectEvents"
          autoFocus
          onApiError={this.handleApiError}
        />
      </div>
    );

    if (returnContentOnly) {
      return content;
    }

    return (
      <UiCore title="Project Chat"
        apiError={ApiError}
        alert={Alert}
        // showProgressIndicator={ShowProgressIndicator}
        // showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
        content={content}
      />
    );
  }
}

ProjectComments.propTypes = {
  classes: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  onApiError: PropTypes.func,
  onAlert: PropTypes.func,
  returnContentOnly: PropTypes.bool,
};

export default withStyles(styles, {withTheme: true})(ProjectComments);