import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import Collection from '../Model/Collection';

import ItemTableRow from '../Components/ItemTableRow';
import ApprovalCard from '../Components/ApprovalCard';
import TaskDialog from '../Components/TaskDialog';
import AssetItemDialog from '../Components/AssetItemDialog';
import ApprovalCollectionFields from '../Model/ApprovalCollectionFields';
import ActionType from '../Model/ActionType';

import {
  GetProjectApprovalsPath,
  GetWorkspaceApprovalsPath,
  GetWorkspaceApprovalsProjectPath,
} from '../Util/PathHelper';
import { 
  GetItemTypeNameFromApproval,
  HandleRouteToApproval,
} from '../Util/Approvals';
import {
  GetTagsForList,
} from '../Util/Tags';
import {
  GetUserValue,
  GetDateValue,
} from '../Util/Properties';

import API, {
  GetApprovalsPathForApi,
  GetUserOrganizationApprovalsPathForApi,
  GetTaskPathForApi,
  GetUserOrganizationProjectApprovalTaskPathForApi,
  GetAssetItemPathForApi,
  GetUserOrganizationProjectApprovalAssetItemPathForApi,
} from '../Util/api';

import {
  OpenDocumentDetailTab,
} from '../Util/Documents';

export class Approvals_Collection extends Collection {
  constructor(props, onSetState, onApiError, onAlert, isWorkspace, isProjects, 
    organizationId, projectId, userEmail, allowApproval) {

    super(props, onSetState, onApiError, isWorkspace, isProjects, organizationId, projectId);

    this.handleAlert = onAlert;

    this.PageTitle = `${(props.isAdmin) ? "All " : ""}Approval Processes`;
    // this.ContentUri = `${(props.isAdmin) ? "/admin" : ""}/approvals`;
    this.ContentUri = (isWorkspace)
      ? GetUserOrganizationApprovalsPathForApi(organizationId)
      : GetApprovalsPathForApi(organizationId, projectId);
    this.ContentUriParams = (isWorkspace)
      ? { projectId }
      : undefined;
    this.CollectionName = "Approvals";
    this.ItemsName = "Approvals";
    this.DefaultViewType = "Card";
    this.AllowSelect = true;
    this.ApprovalsForDialog = [];

    this.UserEmail = userEmail;
    this.AllowApproval = allowApproval;
  }

  HandleGetCollectionFieldsPromise = () => {
    return Promise.resolve(
      ApprovalCollectionFields.filter(f =>
        (this.isWorkspace)
          ? f.ID !== "Meta_text_kw256lc[AssignmentUserEmail].keyword"
          : f
      )
    );
  }

  HandleGetHeadCells = (items, sensitiveFields) => {
    let headCells = [
      { id: 'ItemName', sortId: 'Meta_text_kw256lc[Name].keyword', numeric: false, label: 'Item Name' },
      { id: 'ItemType', sortId: 'Meta_text_kw256lc[ItemType].keyword', numeric: false, label: 'Item Type' },
      { id: 'Email', sortId: 'Meta_text_kw256lc[AssignmentUserEmail].keyword', numeric: false, label: 'Assigned to' },
    ];
    if (!this.ProjectID) {
      headCells.push(
        { id: 'ProjectName', sortId: 'Meta_text_kw256lc[ProjectName].keyword', numeric: false, label: "Project" },
      );
    }
    headCells.push(
      { id: 'ProcessName', sortId: 'Meta_text_kw256lc[ProcessName].keyword', numeric: false, label: 'Process' },
      { id: 'ProcessElementName', sortId: 'Meta_text_kw256lc[ProcessElementName].keyword', numeric: false, label: 'Stage' },
      { id: 'CreatedOn', sortId: 'Meta_date_str256[CreatedOn].string', numeric: false, label: 'Approval Created On' },
      { id: 'ItemCreatedOn', sortId: 'Meta_date_str256[ItemCreatedOn].string', numeric: false, label: 'Item Created On' },
      { id: 'ItemCreatedBy', sortId: 'Meta_text_kw256lc[ItemCreatedByUserEmail].keyword', numeric: false, label: 'Item Created By' },
      { id: 'Tags', sortId: 'Meta_text_kw50lc[Tag].keyword', numeric: false, label: 'Tags' },
    );
    return headCells;
  }

  handleShowItem = item => {
    switch (item.ItemType) {
      case "Document":
        OpenDocumentDetailTab(item.OrganizationID, item.ProjectID, item.ItemID, false, 
          { approvalID: item.ID, allowApproval: this.AllowApproval }, false,
          (this.isWorkspace) ? "approval" : undefined);
        break;
      case "Task":
        this.setState({ShowProgressIndicatorImmediately: true});
        this.handleLoadTaskFromApproval(item)
          .then(task => {
            // We aren't doing anything with the task here.
            // We should probably store it locally so we don't have to retrieve it later.
            HandleRouteToApproval(this.props, item.ProjectID, item.ID, this.isWorkspace);
          });
        break;
      case "AssetItem":
        this.setState({ShowProgressIndicatorImmediately: true});
        this.handleLoadAssetItemFromApproval(item)
          .then(assetItem => {
            // We aren't doing anything with the assetItem here.
            // We should probably store it locally so we don't have to retrieve it later.
            HandleRouteToApproval(this.props, item.ProjectID, item.ID, this.isWorkspace);
          });
        break;
      default:
        break;
    }
  }

  handleLoadTaskFromApproval = approval => {
    const uri = (this.isWorkspace)
      ? GetUserOrganizationProjectApprovalTaskPathForApi(approval.OrganizationID, approval.ProjectID, approval.ID, approval.ItemID)
      : GetTaskPathForApi(approval.OrganizationID, approval.ProjectID, approval.ItemID);
    return API.get(uri)
      .then(resp => {
        return resp.data;
      })
      .catch(this.handleApiError);
  }

  handleLoadAssetItemFromApproval = approval => {
    const uri = (this.isWorkspace)
      ? GetUserOrganizationProjectApprovalAssetItemPathForApi(approval.OrganizationID, approval.ProjectID, 
          approval.ID, approval.ItemAncestorIDs[0], approval.ItemID)
      : GetAssetItemPathForApi(approval.OrganizationID, approval.ProjectID, 
          approval.ItemAncestorIDs[0], approval.ItemID);
    return API.get(uri)
      .then(resp => {
        return resp.data;
      })
      .catch(this.handleApiError);
  }

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    this.ActionHandlerFunction = onAction;
    return (items && items.length)
      ? items
        .map(i => {
          return (
            <Grid item key={i.ID} sm={12} md={6} lg={4} xl={3} className={classes.cardGridItem}>
              <ApprovalCard
                Approval={i}
                onCardAction={() => this.handleShowItem(i)}
                onSelect={() => onSelect(i.ID)}
                onAction={onAction}
                selected={selectedIDs.indexOf(i.ID) > -1}
              />
            </Grid>
          );
        }) 
      : [];
  }

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    this.ActionHandlerFunction = onAction;
    return (items && items.length)
      ? items
        .map(i => {
          const selected = selectedIDs.indexOf(i.ID) !== -1;
          const projectNameCell = (!this.ProjectID)
            ? <TableCell className={classes.tableCell}>{i.ProjectName}</TableCell>
            : null;
          return (
            <ItemTableRow key={`k_${i.ID}`}
              onSelect={() => onSelect(i.ID)}
              selected={selected}
              onItemClick={() => this.handleShowItem(i)}
            >
              <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
                <Checkbox
                  color="secondary"
                  onClick={e => { e.stopPropagation(); onSelect(i.ID); }}
                  checked={selected}
                />
              </TableCell>
              <TableCell className={classes.tableCell} component="th" id={`label_${i.ID}`} scope="row" padding="none">
                {i.ItemName}
              </TableCell>
              <TableCell className={classes.tableCell}>{GetItemTypeNameFromApproval(i)}</TableCell>
              <TableCell className={classes.tableCell}>{GetUserValue(i.AssignmentUserEmail, i.AssignmentUserName)}</TableCell>
              {projectNameCell}
              <TableCell className={classes.tableCell}>{i.ProcessName}</TableCell>
              <TableCell className={classes.tableCell}>{i.ProcessElementName}</TableCell>
              <TableCell className={classes.tableCell}>{GetDateValue(i.CreatedOn)}</TableCell>
              <TableCell className={classes.tableCell}>{GetDateValue(i.ItemCreatedOn)}</TableCell>
              <TableCell className={classes.tableCell}>{GetUserValue(i.ItemCreatedByUserEmail, i.ItemCreatedByUserName)}</TableCell>
              <TableCell className={classes.tableCell}>
                {GetTagsForList(i, sortType, sortDescending)}
              </TableCell>
            </ItemTableRow>
          );
        }) 
      : [];
  }

  HandleGetDialogContent = state => {
    return (state.ApprovalTaskForTaskDialog)
      ? (
        <TaskDialog
          organizationId={this.OrganizationID}
          projectId={state.ApprovalTaskForTaskDialog.ProjectID}
          history={this.props.history}
          location={this.props.location}
          open={state.ItemDialogIsOpen || false}
          isCreateNew={false}
          Task={state.ApprovalTaskForTaskDialog}
          onTaskRevised={this.handleTaskItemRevised}
          onApiError={this.handleApiError}
          onAlert={this.handleAlert}
          onClose={this.handleItemDialogClosed}
          showProgressIndicatorImmediately={state.ShowDialogProgressIndicatorImmediately}
          onApprove={() => this.handleApprovalAction(state, ActionType.Approval_Approve)}
          onDecline={() => this.handleApprovalAction(state, ActionType.Approval_Decline)}
          isWorkspace={this.isWorkspace}
          workspaceApprovalId={(this.isWorkspace) ? this.props.match.params.collectionItemID : undefined}
          pathnameForFilterStateRestore={state.PathnameForFilterStateRestore}
        />
      )
      : (state.ApprovalAssetItemForAssetItemDialog)
        ? (
          <AssetItemDialog
            history={this.props.history}
            location={this.props.location}
            match={this.props.match}
            organizationId={this.OrganizationID}
            projectId={state.ApprovalAssetItemForAssetItemDialog.ProjectID}
            assetId={state.ApprovalAssetItemForAssetItemDialog.AssetID}
            assetName={state.ApprovalAssetItemForAssetItemDialog.AssetName}
            assetItem={state.ApprovalAssetItemForAssetItemDialog}
            open={state.ItemDialogIsOpen || false}
            isCreateNew={false}
            isWorkspace={this.isWorkspace}
            workspaceApprovalId={(this.isWorkspace) ? this.props.match.params.collectionItemID : undefined}
            onAssetItemRevised={this.handleAssetItemRevised}
            onApiError={this.handleApiError}
            onAlert={this.handleAlert}
            onClose={this.handleItemDialogClosed}
            showProgressIndicatorImmediately={state.ShowDialogProgressIndicatorImmediately}
            onApprove={() => this.handleApprovalAction(state, ActionType.Approval_Approve)}
            onDecline={() => this.handleApprovalAction(state, ActionType.Approval_Decline)}
            pathnameForFilterStateRestore={state.PathnameForFilterStateRestore}
          />
        ) : null;
  }

  handleApprovalAction = (state, actionType) => {
    this.setState({ ShowDialogProgressIndicatorImmediately: true });
    this.ActionHandlerFunction(actionType, state.ApprovalIdForItemDialog)
      .then(() => {
        this.handleItemDialogClosed();
      });
  }

  handleTaskItemRevised = revisedItem => {
    if (revisedItem) {
      let approvalFinder = this.ApprovalsForDialog.filter(a => a.ItemID === revisedItem.ID);
      if (approvalFinder.length) {
        let a = approvalFinder[0];
        // Update properties here that are displayed on cards/table
        a.ItemName = revisedItem.Name;
        a.TaskDueOn = revisedItem.DueOn;
        a.ItemDescription = revisedItem.Description;
        this.UpdateRevisedItemFunction(a);  
      }
    }
  }

  handleAssetItemRevised = revisedItem => {
    if (revisedItem) {
      let approvalFinder = this.ApprovalsForDialog.filter(a => a.ItemID === revisedItem.ID);
      if (approvalFinder.length) {
        let a = approvalFinder[0];
        // Update properties here that are displayed on cards/table
        a.ItemName = revisedItem.Name;
        a.ItemPreviewMetadata = revisedItem.PreviewMetadata;
        a.ThumbnailUrl = revisedItem.ThumbnailUrl;
        this.UpdateRevisedItemFunction(a);  
      }
    }
  }

  HandleItemsChanged = items => {
    this.ApprovalsForDialog = items || [];
    if (items !== null) {
      this.handleTryDisplayTaskIfApplicable(items);
      this.handleTryDisplayAssetItemIfApplicable(items);
    }
  }

  handleItemDialogClosed = () => {
    const stateToUpdate = {
      ItemDialogIsOpen: false,
      ApprovalTaskForTaskDialog: null,
      ApprovalAssetItemForAssetItemDialog: null,
      PathnameForFilterStateRestore: null,
      ShowDialogProgressIndicatorImmediately: false,
      ShowProgressIndicatorImmediately: false,
    };
    this.setState(stateToUpdate);
    
    const newPath = (this.isWorkspace)
      ? (this.ProjectID)
        ? GetWorkspaceApprovalsProjectPath(this.ProjectID)
        : GetWorkspaceApprovalsPath()
      : GetProjectApprovalsPath(this.ProjectID);
    if (this.props.location.pathname !== newPath) {
      this.props.history.push(newPath, { ...this.props.location.state, ...stateToUpdate });
    }
  }

  handleTryDisplayTaskIfApplicable = (approvals, pathnameForFilterStateRestore) => {
    if (this.props.match.params.collectionItemID) {
      if (!approvals) {
        approvals = [...this.ApprovalsForDialog];
      }
      let approvalFinder = approvals.filter(a => a.ID === this.props.match.params.collectionItemID);
      if (approvalFinder.length) {
        let approval = approvalFinder[0];
        if (approval.ItemType === "Task" && approval.ItemID) {
          this.setState({ShowProgressIndicatorImmediately: true});    
          this.handleLoadTaskFromApproval(approval)
            .then(task => {
              this.setState({
                ApprovalIdForItemDialog: approval.ID,
                ApprovalTaskForTaskDialog: task,
                ItemDialogIsOpen: true,
                PathnameForFilterStateRestore: pathnameForFilterStateRestore,
                ShowProgressIndicatorImmediately: false,
              });
            });
        }
      } else {
        this.handleItemDialogClosed();
      }
    }
  }

  handleTryDisplayAssetItemIfApplicable = (approvals, pathnameForFilterStateRestore) => {
    if (this.props.match.params.collectionItemID) {
      if (!approvals) {
        approvals = [...this.ApprovalsForDialog];
      }
      let approvalFinder = approvals.filter(a => a.ID === this.props.match.params.collectionItemID);
      if (approvalFinder.length) {
        let approval = approvalFinder[0];
        if (approval.ItemType === "AssetItem" && approval.ItemID) {
          this.setState({ShowProgressIndicatorImmediately: true});    
          this.handleLoadAssetItemFromApproval(approval)
            .then(assetItem => {
              this.setState({
                ApprovalIdForItemDialog: approval.ID,
                ApprovalAssetItemForAssetItemDialog: assetItem,
                ItemDialogIsOpen: true,
                PathnameForFilterStateRestore: pathnameForFilterStateRestore,
                ShowProgressIndicatorImmediately: false,
              });
            });
        }
      } else {
        this.handleItemDialogClosed();
      }
    }
  }

  // Returns whether further path changes can occur.
  updateProps(props) {
    Collection.prototype.updateProps.bind(this)(props);

    if (this.props.match.params.collectionItemID && !this.prevProps.match.params.collectionItemID) {
      this.handleTryDisplayTaskIfApplicable(null, this.prevProps.location.pathname);
      this.handleTryDisplayAssetItemIfApplicable(null, this.prevProps.location.pathname);
      return false;
    } else if (!this.props.match.params.collectionItemID && this.prevProps.match.params.collectionItemID) {
      this.handleItemDialogClosed();
      return false;
    }

    return true;
  }
}