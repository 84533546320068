import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import Collection from '../Model/Collection';

import ItemTableRow from '../Components/ItemTableRow';
import AssetItemCard from '../Components/AssetItemCard';
import {
GetAssetItemCollectionFields,
} from '../Model/AssetItemCollectionFields';

import AssetItemDialog from '../Components/AssetItemDialog';

import API, {
  GetAssetItemsPathForApi,
  GetProjectAssetItemsPathForApi,
  GetAssetItemPathForApi,
  GetAssetFilterSortFieldsPathForApi,
} from '../Util/api';
import {
  GetPreviewMetadataHeadCells,
  GetPreviewMetadataTableCells,
} from '../Util/Metadata';
import {
  GetDateValue,
  GetBoolValue,
} from '../Util/Properties';
import { 
  GetProjectAssetItemsPath,
  GetProjectAssetItemsArchivedPath,
} from '../Util/PathHelper';
import {
  HandleRouteToAssetItem,
} from '../Util/AssetItems';
import {
  GetAssetItemProgress,
} from '../Util/AssetItem';
import {
  AddFilterSortFieldsToHeadCells,
  GetItemTableCellsForFilterSortFields,
} from '../Util/Fields';
import {
  GetTagsForList,
} from '../Util/Tags';
import ActionType from '../Model/ActionType';

export class AssetItems_Collection extends Collection {
  constructor(props, onSetState, onApiError, onAlert, isWorkspace, isProjects, 
    organizationId, projectId, asset_optional, activeOnly, archivedOnly, disableItemClick,
    showContentTypeOnCards, assetsForAssetItemCreation) {

    if (typeof organizationId !== "string") {
      console.log("Warning: organizationId required and not found");
    }
    if (typeof projectId !== "string") {
      console.log("Warning: projectId required and not found");
    }
    super(props, onSetState, onApiError, isWorkspace, isProjects, organizationId, projectId);

    this.handleAlert = onAlert;
    this.Asset = asset_optional;

    this.activeOnly = activeOnly;
    this.archivedOnly = archivedOnly;
    this.disableItemClick = disableItemClick;
    this.showContentTypeOnCards = showContentTypeOnCards;
    this.assetsForAssetItemCreation = assetsForAssetItemCreation;

    this.PageTitle = (asset_optional) ? asset_optional.PluralName : "Asset items";
    this.ContentUri = (asset_optional)
      ? GetAssetItemsPathForApi(organizationId, projectId, asset_optional.ID)
      : GetProjectAssetItemsPathForApi(organizationId, projectId);
    this.ContentUriParams = {
      activeOnly,
      archivedOnly,
    };
    this.CollectionName = `${archivedOnly ? "Archived" : ""}${(asset_optional) ? asset_optional.Name : "items"}`;
    this.ItemsName = "AssetItems";
    this.ItemName = (asset_optional) ? asset_optional.Name : "Asset";
    this.AllowSelect = true;
    this.DefaultViewType = "List";
    this.AssetItemsForDialog = [];
    const canCreateNew = isProjects && activeOnly;
    this.HandleCreateNew = (canCreateNew)
      ? () => {
        this.setState({
          AddAssetItemDialogIsOpen: true,
        });
      } : null;

    this.filterSortFields = [];
    this.loadedFilterSortFields = false;
  }

  HandleGetCollectionFieldsPromise = () => {
    if (!this.Asset || this.loadedFilterSortFields) {
      return Promise.resolve(
        GetAssetItemCollectionFields(this.filterSortFields)
      );
    }

    return API.get(GetAssetFilterSortFieldsPathForApi(this.OrganizationID,
      this.ProjectID, this.Asset.ID))
      .then(resp => {
        let fieldsInOrder = [];
        resp.data.forEach(f => {
          if (this.Asset.PrimaryFieldID && f.Field.ID === this.Asset.PrimaryFieldID) {
            fieldsInOrder.unshift(f);
          } else {
            fieldsInOrder.push(f);
          }
        });
        this.filterSortFields = fieldsInOrder;
        this.loadedFilterSortFields = true;
        return GetAssetItemCollectionFields(fieldsInOrder);
      })
      .catch(this.handleApiError);
  }

  HandleGetHeadCells = (items, sensitiveFields) => {
    let headCells = [
      { id: 'Name', sortId: 'Meta_text_kw256lc[Name].keyword', numeric: false, label: 'Name' },
      { id: 'CreatedOn', sortId: 'Meta_date_str256[CreatedOn].string', numeric: false, label: 'Created On' },
      { id: 'Complete', sortId: 'Meta_bool[IsComplete]', numeric: false, label: 'Complete' },
      { id: 'Progress', numeric: false, label: 'Progress' },
    ];
    if (this.Asset) {
      AddFilterSortFieldsToHeadCells(headCells, this.filterSortFields);
    } else {
      headCells.push(...GetPreviewMetadataHeadCells(sensitiveFields, items));
    }
    headCells.push(
      { id: 'Tags', sortId: 'Meta_text_kw50lc[Tag].keyword', numeric: false, label: 'Tags' },
    );
    if (!this.ProjectID) {
      headCells.push(
        { id: 'ProjectName', numeric: false, label: "Project" },
      );
    }
    return headCells;
  }

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, selectedIDs,
    onAction, postActionData, sortType, sortDescending, useSingleColumnLayout, cardStyle, viewType,
    onItemClick, disableCardActions) => {
    
    this.ActionHandlerFunction = onAction;
    return (items && items.length) 
      ? items
        .map(i => {
          if (this.showContentTypeOnCards) {
            i.ContentType = "AssetItem";
          }
          return (
            <Grid item key={i.ID} sm={12} md={6} lg={4} xl={3} className={classes.cardGridItem}>
              <AssetItemCard
                AssetItem={i}
                onCardAction={(this.disableItemClick)
                  ? null
                  : () => this.handleItemClick(i)
                }
                onSelect={() => onSelect(i.ID)}
                selected={selectedIDs.indexOf(i.ID) > -1}
                style={cardStyle}
                showContentType={this.showContentTypeOnCards}
              />
            </Grid>
          );
        }) 
      : [];
  }

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction,
    postActionData, sortType, sortDescending) => {
    
    this.ActionHandlerFunction = onAction;
    return (items && items.length)
      ? items
        .map(i => {
          const selected = selectedIDs.indexOf(i.ID) !== -1;
          const filterSortFieldTableCells = (this.Asset)
            ? GetItemTableCellsForFilterSortFields(i, this.filterSortFields, classes)
            : [];
          const projectNameCell = (!this.ProjectID)
            ? <TableCell className={classes.tableCell}>{i.ProjectName}</TableCell>
            : null;
          const metadataTableCells = (!this.Asset)
            ? [...GetPreviewMetadataTableCells(i, headCells, classes)]
            : [];
          return (
            <ItemTableRow key={`k_${i.ID}`}
              onSelect={() => onSelect(i.ID)}
              selected={selected}
              onItemClick={(this.disableItemClick)
                ? null
                : () => this.handleItemClick(i)
              }
            >
              <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
                <Checkbox
                  color="default"
                  onClick={e => { e.stopPropagation(); onSelect(i.ID); }}
                  checked={selected}
                />
              </TableCell>
              <TableCell className={classes.tableCell} component="th" id={`label_${i.ID}`} scope="row" padding="none">
                {i.Name || GetDateValue(i.CreatedOn)}
              </TableCell>
              <TableCell className={classes.tableCell}>{GetDateValue(i.CreatedOn)}</TableCell>
              <TableCell className={classes.tableCell}>
                {GetBoolValue(i.IsComplete)}
              </TableCell>
              <TableCell className={classes.tableCell}>{GetAssetItemProgress(i)}</TableCell>
              {filterSortFieldTableCells}
              {metadataTableCells}
              <TableCell className={classes.tableCell}>
                {GetTagsForList(i, sortType, sortDescending)}
              </TableCell>
              {projectNameCell}
            </ItemTableRow>
          );
        }) 
      : [];
  }

  handleItemClick = item => {
    HandleRouteToAssetItem(this.props, this.ProjectID, item.AssetID, item.ID, item.Archived);
  }

  HandleItemsChanged = items => {
    this.AssetItemsForDialog = items || [];
    this.handleTryDisplayAssetItemDialogIfApplicable(null, items, true);
  }

  handleTryDisplayAssetItemDialogIfApplicable = (assetItemId_optional, assetItems, fromItemsChanged,
    pathnameForFilterStateRestore) => {
    const assetItemId = (assetItemId_optional) 
      ? assetItemId_optional
      : this.props.match.params.collectionItemID;
    if (this.Asset && assetItemId) {
      const displayDialog = (assetItem, extraState) => {
        this.setState({
          AssetItemForAssetItemDialog: assetItem,
          AssetItemDialogIsOpen: true,
          PathnameForFilterStateRestore: pathnameForFilterStateRestore,
          ...extraState,
        });
      }
      if (!assetItems) {
        assetItems = [...this.AssetItemsForDialog];
      }
      const assetItemFinder = assetItems.filter(ai => ai.ID === assetItemId);
      if (this.isWorkspace && assetItemFinder.length) {
        displayDialog(assetItemFinder[0]);
      }
      if (fromItemsChanged) {
        if (assetItemFinder.length) {
          displayDialog(assetItemFinder[0]);
        }
      } else {
        API.get(GetAssetItemPathForApi(this.OrganizationID, this.ProjectID, this.Asset.ID, assetItemId))
          .then(resp => {
            displayDialog(resp.data, {
              ForcePrependItems: [resp.data],
            });
          })
          .catch(err => {
            this.handleApiError(err);
            this.handleAssetItemDialogClosed();
          });
      }
    }
  }

  HandleGetDialogContent = state => {
    const isCreateNew = (state.AddAssetItemDialogIsOpen);
    if (state.AddAssetItemDialogIsOpen || state.AssetItemDialogIsOpen) {
      return (
        <AssetItemDialog
          history={this.props.history}
          location={this.props.location}
          organizationId={this.OrganizationID}
          projectId={this.ProjectID}
          assetId={this.Asset.ID}
          assetName={this.Asset.Name}
          assetItem={(!isCreateNew) ? state.AssetItemForAssetItemDialog : undefined}
          onAssetItemRevised={this.HandleItemRevised}
          assetsForAssetItemCreation={this.assetsForAssetItemCreation}
          isCreateNew={isCreateNew}
          open={state.AddAssetItemDialogIsOpen || state.AssetItemDialogIsOpen || false}
          onApiError={this.handleApiError}
          onAlert={this.handleAlert}
          onClose={(isCreateNew)
            ? this.handleAddAssetItemDialogClosed
            : this.handleAssetItemDialogClosed
          }
          onCreated={this.handleAssetItemCreated}
          onRestore={() => this.handleAction(ActionType.AssetItem_Restore, state.AssetItemForAssetItemDialog)}
          showProgressIndicatorImmediately={state.ShowDialogProgressIndicatorImmediately}
          pathnameForFilterStateRestore={state.PathnameForFilterStateRestore}
        />
      );
    }
    return null;
  }

  handleAction = (actionType, item) => {
    this.setState({ ShowDialogProgressIndicatorImmediately: true });
    this.ActionHandlerFunction(actionType, item.ID)
      .then(() => {
        this.handleAssetItemDialogClosed();
      });
  }

  handleAddAssetItemDialogClosed = () => {
    this.setState({
      AddAssetItemDialogIsOpen: false,
    })
  }

  handleAssetItemDialogClosed = () => {
    const stateToUpdate = {
      AssetItemDialogIsOpen: false,
      ShowDialogProgressIndicatorImmediately: false,
    };
    this.setState(stateToUpdate);

    const newPath = (this.archivedOnly)
      ? GetProjectAssetItemsArchivedPath(this.ProjectID, this.Asset.ID)
      : GetProjectAssetItemsPath(this.ProjectID, this.Asset.ID);
    if (this.props.location.pathname !== newPath) {
      this.props.history.push(newPath, { ...this.props.location.state, ...stateToUpdate });
    }
  }

  handleAssetItemCreated = assetItem => {
    this.handleAddAssetItemDialogClosed();
    if (!assetItem) {
      return;
    }
    this.handleAddAssetItemDialogClosed();
    this.setState({ForcePrependItems:[assetItem]});
    // HandleRouteToAssetItem(this.props, this.ProjectID, assetItem.ID);
  }

  // Returns whether further path changes can occur.
  updateProps(props) {
    Collection.prototype.updateProps.bind(this)(props);

    if (this.props.match.params.collectionItemID && !this.prevProps.match.params.collectionItemID) {
      this.handleTryDisplayAssetItemDialogIfApplicable(null, null, false, this.prevProps.location.pathname);
      return false;
    } else if (!this.props.match.params.collectionItemID && this.prevProps.match.params.collectionItemID) {
      this.handleAssetItemDialogClosed();
      return false;
    }

    return true;
  }
}