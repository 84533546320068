import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import Collection from '../Model/Collection';

import ItemTableRow from '../Components/ItemTableRow';
import RecycleBinCard from '../Components/RecycleBinCard';
import RecycleBinCollectionFields from '../Model/RecycleBinCollectionFields';
import { GetRecycleBinDocumentsCount } from '../Util/api';

import {
  GetPreviewMetadataHeadCells,
  GetPreviewMetadataTableCells,
} from '../Util/Metadata';
import { 
  HandleRouteToDocument,
} from '../Util/Documents';
import {
  GetPageCountForDisplay,
  GetFileSizeSpan,
} from '../Util/Document';
import {
  GetDateValue,
  GetUserValue,
} from '../Util/Properties';
import {
  GetRecycleBinDocumentsPathForApi,
} from '../Util/api';

export class RecycleBin_Collection extends Collection {
  constructor(props, onSetState, onApiError, isWorkspace, isProjects, 
    organizationId, projectId) {
    
    super(props, onSetState, onApiError, isWorkspace, isProjects, organizationId, projectId);

    this.PageTitle = "Recycle Bin";
    this.ContentUri = GetRecycleBinDocumentsPathForApi(organizationId, projectId);
    this.CollectionName = "RecycleBinDocuments";
    this.ItemsName = "Documents";
    this.ItemName = "Document";
    this.DefaultViewType = "Card";
    this.AllowSelect = true;
    this.HideSensitiveFields = true;
    this.SingleLineTableCells = true;

    this.setRecycleBinDocumentsCount();
  }

  setRecycleBinDocumentsCount() {
    GetRecycleBinDocumentsCount(this.OrganizationID, this.ProjectID)
      .then(total => {
        this.setState({ TotalItems: total });
      })
      .catch(this.handleApiError);
  }

  HandleRefresh = () => this.setRecycleBinDocumentsCount();

  HandleGetCollectionFieldsPromise = () => Promise.resolve(RecycleBinCollectionFields);

  HandleGetHeadCells = (items, sensitiveFields) => {
    let headCells = [
      { id: 'Name', sortId: 'Meta_text_kw256lc[Name].keyword', numeric: false, label: 'Name' },
    ];
    if (!this.ProjectID) {
      headCells.push(
        { id: 'ProjectName', numeric: false, label: "Project" },
      );
    }
    headCells.push(
      { id: 'CreatedOn', sortId: 'Meta_date_str256[CreatedOn].string', numeric: false, label: 'Created On' },
      { id: 'CreatedByUserEmail', sortId: 'Meta_text_kw256lc[CreatedByUserEmail].keyword', numeric: false, label: 'Created By' },
      { id: 'PurgeMarkedOn', sortId: 'Meta_date_str256[PurgeMarkedOn].string', numeric: false, label: 'Purge Date' },
      { id: 'PageCount', sortId: 'Meta_int[PageCount]', numeric: true, label: 'Page Count' },
      { id: 'FileSize', sortId: 'Meta_long[FileSize]', numeric: true, label: 'File Size' },
    );
    const metadataHeadCells = GetPreviewMetadataHeadCells(sensitiveFields, items);
    headCells.splice(3, 0, ...metadataHeadCells);
    return headCells;
  }

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length)
    ? items
      .map(i => {
        return (
          <Grid item key={i.ID} sm={12} md={6} lg={4} xl={3} className={classes.cardGridItem}>
            <RecycleBinCard
              Document={i}
              sensitiveFields={sensitiveFields}
              postActionData={postActionData}
              onCardAction={() => HandleRouteToDocument(this.OrganizationID, this.ProjectID,
                i, items, this.ContentUri, this.ContentUriParams, sortType, sortDescending, false)}
              onSelect={() => onSelect(i.ID)}
              onAction={onAction}
              selected={selectedIDs.indexOf(i.ID) > -1}
            />
          </Grid>
        );
      }) 
    : [];
  }

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length)
      ? items.map(i => {
        const selected = selectedIDs.indexOf(i.ID) !== -1;
        const labelId = `label_${i.ID}`;
        const projectNameCell = (!this.ProjectID)
          ? <TableCell className={classes.tableCell}>{i.ProjectName}</TableCell>
          : null;
        return (
          <ItemTableRow
            key={`k_${i.ID}`}
            onItemClick={() => HandleRouteToDocument(this.OrganizationID, this.ProjectID,
              i, items, this.ContentUri, this.ContentUriParams, sortType, sortDescending, false)}
            selected={selected}
            onSelect={() => onSelect(i.ID)}
          >
            <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
              <Checkbox
                color="secondary"
                onClick={event => { event.stopPropagation(); onSelect(i.ID); }}
                checked={selected}
                inputProps={{ 'aria-labelledby': labelId, style:{zIndex:0} }}
              />
            </TableCell>
            <TableCell component="th" id={labelId} scope="row" padding="none">
              {i.Name}
            </TableCell>
            {projectNameCell}
            <TableCell style={{whiteSpace:"nowrap"}}>{GetDateValue(i.CreatedOn)}</TableCell>
            <TableCell className={classes.tableCell}>{GetUserValue(i.CreatedByUserEmail, i.CreatedByUserName)}</TableCell>
            {GetPreviewMetadataTableCells(i, headCells, classes)}
            <TableCell style={{whiteSpace:"nowrap"}}>{GetDateValue(i.PurgeDate)}</TableCell>
            <TableCell align="right">{GetPageCountForDisplay(i.PageCount)}</TableCell>
            <TableCell align="right">{GetFileSizeSpan(i.FileSize)}</TableCell>
          </ItemTableRow>
        );
      })
    : [];
  }
}