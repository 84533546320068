import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import ProgressIndicator from './ProgressIndicator';

import API, {
  GetDocumentPackagePathForApi,
} from '../Util/api';
import DocumentFolders, {
  GetRootDocumentFolderNode,
} from '../Util/DocumentFolders';
import {
  GetTreeNodeComponent,
//   // GetNodeForGetMoreItems,
//   // TreeComponentDidUpdate,
} from '../Util/Tree';

import { IsMobile } from '../Util/MobileDetector';

const styles = theme => ({
  dialogPaper: {
    // height:(!IsMobile()) ? "80%" : undefined,
    minWidth:(!IsMobile()) ? 400 : undefined,
  },
  dialogTitle: {
  },
  dialogContent: {
    // overflow:"hidden",
  },
  dialogActions: {
  },
  paper: {
    // overflowX:"hidden",
    // overflowY:"auto",
    // flexGrow:1,
  },
  currentPath: {
    marginBottom:theme.spacing(2),
  },
});

class SetDocumentFolderDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      ShowProgressIndicatorImmediately: false,
      Document: null,
    }

    this.DocumentFolders = null;
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleApiError = err => {
    this.setState({ShowProgressIndicatorImmediately: false});
    this.props.onApiError(err);
  }

  componentDidMount() {
    this.DocumentFolders = new DocumentFolders(
      this.state,
      state => this.setState(state),
      this.handleApiError, this.props.organizationId, this.props.projectId, 
      false, false, "", false,
      null, null, null, null,
      null, null, null, null,
      null, ["folderMove"]);

    this.initiateDocumentFolders();
    this.getDocument();
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      let stateToUpdate = {
        open: this.props.open !== false,
      }
      if (this.props.open && this.props.documentId) {
        this.handleLoadDocument();
      } else {
        stateToUpdate.Document = null;
      }
      this.setState(stateToUpdate);
    }
  }

  getDocument = () => {
    if (!this.props.documentIds || this.props.documentIds.length > 1) {
      return;
    }

    API.get(GetDocumentPackagePathForApi(this.props.organizationId, this.props.projectId, this.props.documentIds[0]))
      .then(resp => {
        this.setState({Document:resp.data.Document});
      })
      .catch(this.handleApiError);
  }

  initiateDocumentFolders = () => {
    if (!this.state.DocumentFolderNodesByNodeId) {
      setTimeout(() => this.initiateDocumentFolders(), 250);
      return;
    }
    this.DocumentFolders.GetAndSetDocumentFolderNodesAsPromise(this.props, this.state)(GetRootDocumentFolderNode());
  }

  getTree = () => {
    if (!this.state.DocumentFolderNodesByNodeId || !this.state.DocumentFolderNodesByNodeId.length) {
      return null;
    }
    return GetTreeNodeComponent(
      this.props, this.state, state => this.setState(state),
      GetRootDocumentFolderNode(),
      () => {},
      this.DocumentFolders.GetAndSetDocumentFolderNodesAsPromise(this.props, this.state, null, null, this.props.ignoreDocumentFolderId),
      this.DocumentFolders.GetDocumentFolderTreeNodeComponent(this.props, this.state, null, null, this.props.ignoreDocumentFolderId),
      this.DocumentFolders.GetContextMenu,
      this.DocumentFolders.HandleSetNodeExpandFunction,
    );
  }

  handleSetClick = () => {
    this.props.onAction(this.state.SelectedNode.DocumentFolderID);
  }

  render() {
    const {
      open,
      Document,
      SelectedNode,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      classes,
      // theme,
    } = this.props;

    let dialogActions = (
      <DialogActions className={classes.dialogActions}>
        <Button onClick={() => this.handleClose()}>
          CLOSE
        </Button>
        <Button 
          disabled={!SelectedNode || typeof SelectedNode.DocumentFolderID !== "string"}
          onClick={() => this.handleSetClick()}>
          MOVE
        </Button>
      </DialogActions>
    );

    let progressIndicator = null;
    if (ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    const currentDocumentPath = (Document && Document.DocumentFolderID)
      ? (
        <div className={classes.currentPath}>
          <Typography variant="subtitle1">
            Current path
          </Typography>
          <Typography variant="body2">
            {Document.DocumentFolderPath}
          </Typography>
        </div>
      )
      : null;

    return (
      <Dialog
        fullScreen={IsMobile()}
        maxWidth="lg"
        open={open}
        classes={{
          paper:classes.dialogPaper,
        }}
        onClose={() => this.handleClose()}
        // aria-labelledby="dialog-title"
        // aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title" className={classes.dialogTitle}>
          Move to folder
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {progressIndicator}
          {currentDocumentPath}
          {this.getTree()}
          {this.DocumentFolders && this.DocumentFolders.GetContent(this.props, () => this.state)}
        </DialogContent>
        {dialogActions}
      </Dialog>
    );
  }
}

SetDocumentFolderDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  documentIds: PropTypes.array,
  ignoreDocumentFolderId: PropTypes.string,
  onAction: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onApiError: PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(SetDocumentFolderDialog);