import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Card from '../../Components/Card';

import { NumberWithSeparators } from '../../Util/NumberFormatting';
import {
  GetDateValue,
  GetUserValue,
  GetBoolValue,
} from '../../Util/Properties';

import {
  OrganizationRecommendedForRemoval,
} from '../../Util/Organizations';

import brown from '@material-ui/core/colors/brown';
import grey from '@material-ui/core/colors/grey';

class OrganizationCard extends Component {
  render() {
    const {
      Organization,
      // PurgeMarkedText,
      // DisabledText,
      onCardAction,
    } = this.props;

    let metadata = [
      ["Created On", GetDateValue(Organization.CreatedOn)],
      ["Creating User Last Activity", GetDateValue(Organization.CreatingUserLastActivity, true)],
      ["Creating User", GetUserValue(Organization.CreatedByUserEmail, Organization.CreatedByUserName, "sm")],
      ["Firestore", Organization.ID],
      ["Cloud Storage", Organization.BucketName],
      ["ElasticSearch", Organization.SearchIndexID],
      ["Free Members Allowed", NumberWithSeparators(Organization.SubscriptionFreeUsers)],
      ["Free Members Used", NumberWithSeparators(Organization.UniqueFreeOrganizationMembers)],
      ["Billable Members", NumberWithSeparators(Organization.UniqueBillableOrganizationMembers)],
      ["Total Members", NumberWithSeparators(Organization.UniqueNonReadOnlyOrganizationMembers)],
      ["Active Projects", NumberWithSeparators(Organization.ActiveProjectCount)],
      ["Min Documents", NumberWithSeparators(Organization.HasUpToMinDocuments)],
      ["Min Tasks", NumberWithSeparators(Organization.HasUpToMinTasks)],
      ["Min Forms", NumberWithSeparators(Organization.HasUpToMinFormTemplates)],
      ["Min Processes", NumberWithSeparators(Organization.HasUpToMinProcesses)],
      ["Index Restored Once", GetBoolValue(Organization.SearchIndexRestoredAtLeastOnce, true)],
      ["Index Deleted", GetBoolValue(Organization.SearchIndexRemovedForCostSavings, true)],
      // ["Read-Only Members", NumberWithSeparators(Organization.UniqueReadOnlyOrganizationMembers)],
      // ["", DisabledText],
      // ["", PurgeMarkedText],
    ];

    // const testOrg = {...Organization, 
    //   // CreatedOn: new Date(),
    //   CreatingUserLastActivity: Organization.CreatedOn,
    //   UniqueNonReadOnlyOrganizationMembers: 1,
    //   ActiveProjectCount: 1,
    //   HasUpToMinTasks: 0,
    //   HasUpToMinDocuments: 0,
    //   HasUpToMinFormTemplates: 0,
    //   HasUpToMinProcesses: 0,
    // };

    const style = OrganizationRecommendedForRemoval(Organization)
      ? { backgroundColor: OrganizationRecommendedForRemoval(Organization, true) ? brown[800] : grey[800] }
      : {};

    return (
      <Card
        onCardAction={onCardAction}
        name={Organization.Name}
        metadata={metadata}
        style={style}
      />
    );
  }
}

OrganizationCard.propTypes = {
  Organization: PropTypes.object.isRequired,
  onCardAction: PropTypes.func.isRequired,
};

export default OrganizationCard;