import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import 'prosemirror-view/style/prosemirror.css';
import {schema as basicSchema} from 'prosemirror-schema-basic';
import {
  baseKeymap,
  chainCommands,
  exitCode,
  joinUp,
  joinDown,
  lift,
  selectParentNode,
  setBlockType,
  toggleMark,
  // wrapIn
} from "prosemirror-commands";
import {
  DOMParser as pmDOMParser,
  DOMSerializer as pmDOMSerializer,
  Node,
  Schema,
} from "prosemirror-model";
import {
  EditorState,
} from "prosemirror-state";
import {
  history,
  redo,
  redoDepth,
  undo,
  undoDepth,
} from "prosemirror-history";
import {
  addListNodes,
  liftListItem,
  sinkListItem,
  splitListItem,
  wrapInList,
} from "prosemirror-schema-list";
import {
  keymap,
} from "prosemirror-keymap";
import {
  undoInputRule,
  inputRules,
  smartQuotes,
  emDash,
  ellipsis,
  wrappingInputRule,
  textblockTypeInputRule,
} from "prosemirror-inputrules";
// import {dropCursor} from "prosemirror-dropcursor"
import {gapCursor} from "prosemirror-gapcursor"

import {ProseMirror} from 'use-prosemirror';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import BoldIcon from '@material-ui/icons/FormatBold';
import ItalicIcon from '@material-ui/icons/FormatItalic';
import LinkIcon from '@material-ui/icons/Link';
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import BulletListIcon from '@material-ui/icons/FormatListBulleted';
import OrderedListIcon from '@material-ui/icons/FormatListNumbered';
import IndentDecreaseIcon from '@material-ui/icons/FormatIndentDecrease';
import ClearIcon from '@material-ui/icons/FormatClear';
import HorizontalRuleIcon from '@material-ui/icons/Remove';
import SelectBlockIcon from '@material-ui/icons/SelectAll';
// import CodeBlockIcon from '@material-ui/icons/Code';
import InfoIcon from '@material-ui/icons/Info';

import MultiUseDialog from './MultiUseDialog';
import SelectControl from './SelectControl';

import { IsMobile } from '../Util/MobileDetector';
import { GetRichTextEditorStyles } from '../Util/RichTextEditor';
import debounce from 'es6-promise-debounce';

const schema = new Schema({
  nodes: addListNodes(basicSchema.spec.nodes, "paragraph block*", "block"),
  marks: basicSchema.spec.marks
})

const styles = theme => {
  return {
    root: {
      position:"relative",
      display:"flex",
      flexDirection:"column",
      // overflow:"hidden",
      height:"100%",
      "& .MuiOutlinedInput-input": {
        padding:10,
        paddingRight:32,
      },
      "& .MuiFormControl-root": {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
    menu: {
      zIndex:(IsMobile()) ? 2 : undefined,
      display:"flex",
      alignItems: "center",
      borderRadius:4,
      transition: "opacity 0.2s",
      boxShadow: (IsMobile()) ? "0px 0px 2px 0px #333" : undefined,
    },
    menuPrimary: {
      display:"flex",
      flexWrap: "wrap",
      alignItems: "center",
      // flexGrow:1,
    },
    menuSecondary: {
      display: (IsMobile()) ? "none" : undefined,
      marginTop:4,
      marginLeft: theme.spacing(3),
    },
    menuDivider: {
      marginLeft:theme.spacing(1),
      marginRight:theme.spacing(1),
    },
    editorContainer: {
      position:"relative",
      marginTop:theme.spacing(1),
      // This provides some room for the floating toolbar when working on the last few lines
      marginBottom:(IsMobile()) ? theme.spacing(6) : undefined,
      flexGrow:1,
    },
    editor: {
      position:"relative",
      height:"100%",
      overflow:(IsMobile()) ? undefined : "auto",
      borderRadius:4,
      ...GetRichTextEditorStyles(theme),
      "& .ProseMirror": {
        outline:"none",
        height:"100%",
      }
    },
    ProseMirrorContainer: {
      height:"100%",
    },
    notch: {
      position:"absolute",
      paddingLeft:4,
      paddingRight:4,
      transition: "top 0.2s",
    },
    notchLabel: {
      fontWeight:400,
    },
    thead: {
      fontWeight:600,
    },
  };
};

const _blockTypeListOption_paragraph = { key: "paragraph", value: "paragraph", label: "Plain" };
const _blockTypeListOptions = [
  _blockTypeListOption_paragraph,
  { key: "heading1", value: "heading1", label: "Heading 1" },
  { key: "heading2", value: "heading2", label: "Heading 2" },
  { key: "heading3", value: "heading3", label: "Heading 3" },
  { key: "code_block", value: "code_block", label: "Code Block" },
];

class RichTextEditor extends Component {
  constructor(props) {
    super(props);

    let initialDoc = null;
    const tryUsePlainTextAsInitialDoc = () => {
      if (props.initialStatePlainText) {
        try {
          const plainTextAsHtmlText = 
            props.initialStatePlainText
              .split("\n")
              .map(p => `<p>${p}</p>`)
              .join("");
          const plainTextAsHtml = (new DOMParser()).parseFromString(plainTextAsHtmlText, "text/html");
          initialDoc = pmDOMParser.fromSchema(schema)
            .parse(plainTextAsHtml);
        }
        catch(err) {
          console.log(err);
          initialDoc = null;
        }
      }
    }
    if (props.initialStateJson) {
      try {
        const initialState = JSON.parse(props.initialStateJson);
        initialDoc = Node.fromJSON(schema, initialState.doc);
      }
      catch(err) {
        console.log(err);
        tryUsePlainTextAsInitialDoc();
      }
    } else {
      tryUsePlainTextAsInitialDoc();
    }

    let RichTextEditorState = EditorState.create({
      schema: schema,
      doc: initialDoc,
      plugins: [
        inputRules({rules:this.getCustomInputRules(schema)}),
        keymap(this.getCustomKeyMap(schema)),
        keymap(baseKeymap),
        history(),
        gapCursor(),
        // dropCursor(),
      ],
    });

    this.state = {
      RichTextEditorState,
      EditorHasFocus: false,
      CursorPosition: null,
      MobileMenuIsVisible: false,
      DialogDetails: { Open: false, },
    }

    this.RichTextEditorRef = React.createRef();
  }

  // *********
  // getHotKeyInfo() should match getCustomKeyMap() as much as possible
  // *********
  getHotKeyInfo = () => {
    return (
      <table>
        <thead className={this.props.classes.thead}>
          <tr><td>Key Combo</td><td>Action</td></tr>
        </thead>
        <tbody>
          <tr><td colSpan="2"><hr /></td></tr>
          <tr><td>SHIFT+CTRL+0</td><td>Plain</td></tr>
          <tr><td>SHIFT+CTRL+1</td><td>Heading 1</td></tr>
          <tr><td>SHIFT+CTRL+2</td><td>Heading 2</td></tr>
          <tr><td>SHIFT+CTRL+3</td><td>Heading 3</td></tr>
          <tr><td>SHIFT+CTRL+\</td><td>Code Block</td></tr>
          <tr><td colSpan="2"><hr /></td></tr>
          <tr><td>CTRL+B</td><td>Bold</td></tr>
          <tr><td>CTRL+I</td><td>Italic</td></tr>
          <tr><td>CTRL+`</td><td>Link</td></tr>
          <tr><td>SHIFT+CTRL+-</td><td>Horizontal Rule</td></tr>
          <tr><td>CTRL+\</td><td>Clear Formatting</td></tr>
          <tr><td colSpan="2"><hr /></td></tr>
          <tr><td>SHIFT+CTRL+8</td><td>Wrap in Bullet List</td></tr>
          <tr><td>SHIFT+CTRL+9</td><td>Wrap in Ordered List</td></tr>
          <tr><td>ALT+UP</td><td>Join Up</td></tr>
          <tr><td>ALT+DOWN</td><td>Join Down</td></tr>
          <tr><td>CTRL+[</td><td>Lift</td></tr>
          <tr><td>CTRL+]</td><td>Sink</td></tr>
          <tr><td>CTRL+ENTER</td><td>Hard Break</td></tr>
          <tr><td colSpan="2"><hr /></td></tr>
          <tr><td>CTRL+Z</td><td>Undo</td></tr>
          <tr><td>CTRL+Y</td><td>Redo</td></tr>
        </tbody>
      </table>
    );
  }

  getCustomKeyMap = schema => {
    const mac = typeof navigator != "undefined" ? /Mac/.test(navigator.platform) : false;
    let keys = {}, type;
    const bindKey = (key, cmd) => {
      keys[key] = cmd;
    }
    bindKey("Mod-z", undo);
    bindKey("Shift-Mod-z", redo);
    bindKey("Backspace", undoInputRule);
    if (!mac) {
      bindKey("Mod-y", redo);
    }

    bindKey("Alt-ArrowUp", joinUp);
    bindKey("Alt-ArrowDown", joinDown);
    bindKey("Mod-BracketLeft", lift);
    // bindKey("Escape", selectParentNode);
    bindKey("Mod-\\", (state, dispatch) => {
      dispatch(state.tr.removeMark(state.selection.from, state.selection.to));
      return true;
    });

    if ((type = schema.marks.strong)) {
      bindKey("Mod-b", toggleMark(type));
      bindKey("Mod-B", toggleMark(type));
    }
    if ((type = schema.marks.em)) {
      bindKey("Mod-i", toggleMark(type));
      bindKey("Mod-I", toggleMark(type));
    }
    // if ((type = schema.marks.code)) {
    //   bindKey("Mod-`", toggleMark(type));
    // }
    if ((type = schema.marks.link)) {
      bindKey("Mod-`", (state, dispatch) => this.doLinkAction(schema, state, dispatch));
    }

    if ((type = schema.nodes.bullet_list)) {
      bindKey("Shift-Ctrl-8", wrapInList(type));
    }
    if ((type = schema.nodes.ordered_list)) {
      bindKey("Shift-Ctrl-9", wrapInList(type));
    }
    // if ((type = schema.nodes.blockquote)) {
    //   bindKey("Ctrl->", wrapIn(type));
    // }
    if ((type = schema.nodes.hard_break)) {
      let br = type, cmd = chainCommands(exitCode, (state, dispatch) => {
        dispatch(state.tr.replaceSelectionWith(br.create()).scrollIntoView());
        return true;
      })
      bindKey("Mod-Enter", cmd);
      bindKey("Shift-Enter", cmd);
      if (mac) {
        bindKey("Ctrl-Enter", cmd);
      }
    }
    if ((type = schema.nodes.list_item)) {
      bindKey("Enter", splitListItem(type));
      bindKey("Mod-[", liftListItem(type));
      bindKey("Mod-]", sinkListItem(type));
    }
    if ((type = schema.nodes.paragraph)) {
      bindKey("Shift-Ctrl-0", setBlockType(type));
    }
    if ((type = schema.nodes.code_block)) {
      bindKey("Shift-Ctrl-\\", setBlockType(type));
    }
    if ((type = schema.nodes.heading)) {
      for (let i = 1; i <= 3; i++) {
        bindKey("Shift-Ctrl-" + i, setBlockType(type, {level: i}));
      }
    }
    if ((type = schema.nodes.horizontal_rule)) {
      let hr = type;
      bindKey("Mod-_", (state, dispatch) => {
        dispatch(state.tr.replaceSelectionWith(hr.create()).scrollIntoView());
        return true;
      });
    }

    return keys;
  }

  getCustomInputRules = schema => {
    let rules = smartQuotes.concat(ellipsis, emDash), type;
    
    // Given a list node type, returns an input rule that turns a number
    // followed by a dot at the start of a textblock into an ordered list.
    if ((type = schema.nodes.ordered_list)) {
      rules.push(
        wrappingInputRule(/^(\d+)\.\s$/, type, match => ({order: +match[1]}),
          (match, node) => node.childCount + node.attrs.order === +match[1])
      );
    }
    // Given a list node type, returns an input rule that turns a bullet
    // (dash, plush, or asterisk) at the start of a textblock into a
    // bullet list.
    if ((type = schema.nodes.bullet_list)) {
      rules.push(
        wrappingInputRule(/^\s*([-+*])\s$/, type)
      );
    }
    // Given a code block node type, returns an input rule that turns a
    // textblock starting with three backticks into a code block.
    if ((type = schema.nodes.code_block)) {
      rules.push(
        textblockTypeInputRule(/^```$/, type)
      );
    }
    // Given a node type and a maximum level, creates an input rule that
    // turns up to that number of `#` characters followed by a space at
    // the start of a textblock into a heading whose level corresponds to
    // the number of `#` signs.
    if ((type = schema.nodes.heading)) {
      const maxLevel = 3;
      rules.push(
        textblockTypeInputRule(new RegExp("^(#{1," + maxLevel + "})\\s$"),
          type, match => ({level: match[1].length}))
      );
    }

    return rules;
  }

  setCursorPosition = debounce(() => {
    const { view } = this.RichTextEditorRef;
    const { state } = view;
    const { selection } = state;
    let CursorPosition;
    try {
      CursorPosition = view.coordsAtPos(selection.to);
    }
    catch {
      CursorPosition = this.state.CursorPosition;
    }
    this.setState({
      CursorPosition,
      MobileMenuIsVisible: this.state.EditorHasFocus,
    });
  }, 250)

  handleRichTextEditorTransaction = transaction => {
    const { view } = this.RichTextEditorRef;
    const originalState = {...this.state.RichTextEditorState};
    const RichTextEditorState = view.state.apply(transaction);
    this.setState({
      RichTextEditorState,
      MobileMenuIsVisible: false,
    });
    this.setCursorPosition();
    
    // This fixes a problem on mobile (at least Chrome on Android) where selecting one of the topmost
    // items causes the Android keyboard to appear (good) but the item selected is scrolled too high (bad).
    if (IsMobile() && !transaction.docChanged && !transaction.scrolledIntoView) {
      setTimeout(() => {
        const { state, dispatch } = view;
        dispatch(state.tr.scrollIntoView());
      }, 150);
    }

    // Update server only if the content changed
    if (originalState.doc !== RichTextEditorState.doc) {
      const stateAsJsonString = JSON.stringify(RichTextEditorState.toJSON());
      let div = document.createElement("div");
      pmDOMSerializer.fromSchema(schema)
        .serializeFragment(RichTextEditorState.doc.content, {}, div);
      const stateAsHtml = div.innerHTML;
      const stateAsPlainText = RichTextEditorState.doc
        .textBetween(0, RichTextEditorState.doc.content.size, "\n", "\n");
      this.props.onChange(stateAsJsonString, stateAsHtml, stateAsPlainText);
    }
  }

  handleBlockTypeChange = blockTypeListOption => {
    if (!blockTypeListOption) {
      return;
    }
    let blockType;
    let blockTypeOptions = {};
    switch (blockTypeListOption) {
      case "heading1":
        blockType = schema.nodes.heading;
        blockTypeOptions = { level: 1 };
        break;
      case "heading2":
        blockType = schema.nodes.heading;
        blockTypeOptions = { level: 2 };
        break;
      case "heading3":
        blockType = schema.nodes.heading;
        blockTypeOptions = { level: 3 };
        break;
      case "code_block":
        blockType = schema.nodes.code_block;
        break;
      case "paragraph":
        blockType = schema.nodes.paragraph;
        break;
      default:
        blockType = schema.nodes.paragraph;
        break;
    }
    return this.handleAction("setBlockType", { blockType, blockTypeOptions });
  }

  handleMenuButtonClick = action => e => {
    return this.handleAction(action);
  }

  doLinkAction = (schema, state, dispatch) => {
    const { tr, selection } = state;
    if (this.currentSelectionHasMarkType(schema.marks.link)) {
      toggleMark(schema.marks.link)(state, dispatch);
    } else {
      this.showTextDialog("Link URL", null, null,
        href => {
          this.hideTextDialog();
          if (!href) {
            tr.setSelection(selection);
            dispatch(tr);
            // Without setTimeout this doesn't work
            setTimeout(() => this.setFocusOnView(), 1);
            return;
          } else {
            toggleMark(schema.marks.link, { href })(state, dispatch);
          }
        },
        () => {
          this.hideTextDialog();
          // Without setTimeout this doesn't work
          setTimeout(() => this.setFocusOnView(), 1);
        },
      );
    }
    return true;
  }

  handleAction = (action, options) => {
    const { state, dispatch } = this.RichTextEditorRef.view;
    const { tr, selection } = state;
    const commonFinale = () => {
      this.setFocusOnView();
    }
    switch (action) {
      case "strong":
        toggleMark(schema.marks.strong)(state, dispatch);
        commonFinale();
        break;
      case "em":
        toggleMark(schema.marks.em)(state, dispatch);
        commonFinale();
        break;
      case "code":
        toggleMark(schema.marks.code)(state, dispatch);
        commonFinale();
        break;
      case "link":
        this.doLinkAction(schema, state, dispatch);
        break;
      case "undo":
        undo(state, dispatch);
        commonFinale();
        break;
      case "redo":
        redo(state, dispatch);
        commonFinale();
        break;
      case "bulletList":
        wrapInList(schema.nodes.bullet_list)(state, dispatch);
        commonFinale();
        break;
      case "orderedList":
        wrapInList(schema.nodes.ordered_list)(state, dispatch);
        commonFinale();
        break;
      case "setBlockType":
        if (options && options.blockType) {
          setBlockType(options.blockType, options.blockTypeOptions)(state, dispatch);
          commonFinale();
        }
        break;
      case "indentDecrease":
        lift(state, dispatch);
        commonFinale();
        break;
      case "horizontalRule":
        dispatch(tr.replaceSelectionWith(schema.nodes.horizontal_rule.create()));
        commonFinale();
        break;
      case "clear":
        const { from, to } = selection;
        dispatch(tr.removeMark(from, to));
        break;
      case "selectBlock":
        selectParentNode(state, dispatch);
        commonFinale();
        break;
      default:
        break;
    }
  }

  currentSelectionHasMarkType = type => {
    const state = this.state.RichTextEditorState;
    const {from, $from, to, empty} = state.selection;
    if (empty) {
      return type.isInSet(state.storedMarks || $from.marks());
    }
    else return state.doc.rangeHasMark(from, to, type);
  }

  getCurrentNode = () => {
    const state = this.state.RichTextEditorState;
    const { $from, to, node } = state.selection;
    if (node) {
      return node;
    }
    return (to <= $from.end())
      ? $from.parent
      : null;
  }

  listItemNodeIsFirstListItem = (node, nodeOffset, resolvedPos) => {
    if (node.type !== schema.nodes.list_item) {
      return false;
    }
    // Get immediate parent node, which will be the list
    const listNode = resolvedPos.node(nodeOffset - 1);
    if (listNode && listNode.firstChild) {
      // console.log("list node", listNode.type);
      const isFirstChild = (node === listNode.firstChild);
      // console.log("isFirstChild", isFirstChild);
      return isFirstChild;
    }
    return false;
  }

  getListItemNodeFromCurrentSelection = callBackIfFound => {
    const state = this.state.RichTextEditorState;
    const { $from } = state.selection;
    let node = $from.parent;
    let nodeOffset = 0;
    while (node) {
      // console.log("node", node.type);
      const nodeIsListItem = node.hasMarkup(schema.nodes.list_item);
      if (nodeIsListItem) {
        // console.log(nodeOffset, "nodeIsListItem");

        if (typeof callBackIfFound === "function") {
          return callBackIfFound(node, nodeOffset, $from);
        }
        return node;
      }

      nodeOffset--;
      node = $from.node(nodeOffset);
    }

    return null;
  }

  currentBlockIsListItem = seekFirstListItem => {
    return this.getListItemNodeFromCurrentSelection(
      (node, nodeOffset, resolvedPos) => {
        return (seekFirstListItem)
          ? this.listItemNodeIsFirstListItem(node, nodeOffset, resolvedPos)
          : true;
      }
    );
  }

  currentBlockCanBeWrappedInListOrIndented = () => {
    // If this is a list item...
    if (this.currentBlockIsListItem()) {
      // ...allow unless it's the first list item      
      return !this.currentBlockIsListItem(true);
    }
    
    // Otherwise, allow if the current block is a paragraph
    const state = this.state.RichTextEditorState;
    const { $from } = state.selection;
    const currentNode = $from.parent;
    // console.log("currentNode", currentNode, currentNode.type);
    return currentNode.type === schema.nodes.paragraph;
  }

  currentBlockIsListItemAndCanBeLifted = () => {
    if (this.currentBlockIsListItem()) {
      const listItemNode = this.getListItemNodeFromCurrentSelection();
      // This is somewhat primitive.
      // We're assuming a single-child node only has a paragraph child.
      // We should probably inspect all children to see if there are any nested lists.
      return listItemNode && listItemNode.childCount === 1;
    }

    return false;
  }

  getCurrentBlockTypeOption = () => {
    if (this.currentBlockIsListItem()) {
      return _blockTypeListOption_paragraph;
    }
    const currentNode = this.getCurrentNode();
    if (!currentNode) {
      return _blockTypeListOption_paragraph;
    }
    let blockTypeOptionValue;
    switch (currentNode.type) {
      case schema.nodes.heading:
        blockTypeOptionValue = `${currentNode.type.name}${currentNode.attrs.level}`;
        break;
      case schema.nodes.code_block:
        blockTypeOptionValue = currentNode.type.name;
        break;
      case schema.nodes.paragraph:
        blockTypeOptionValue = currentNode.type.name;
        break;
      default:
        return _blockTypeListOption_paragraph;
    }
    const currentBlockTypeOption = _blockTypeListOptions.filter(o => o.value === blockTypeOptionValue);
    if (!currentBlockTypeOption.length) {
      return _blockTypeListOption_paragraph;
    }
    return currentBlockTypeOption[0];
  }

  setFocusOnView = () => {
    this.RichTextEditorRef.view.focus();
  }

  showTextDialog = (title, label, defaultValue, confirmCallback, cancelCloseCallback) => {
    this.setState({
      DialogDetails: {
        Open:true,
        Title:title,
        DialogWidth: "xs",
        RequireTextInput1: true,
        TextInput1Label: label,
        TextInput1DefaultValue: defaultValue,
        ConfirmLabel: "GO",
        ConfirmCallback: confirmCallback,
        CancelCallback: cancelCloseCallback,
        CloseCallback: cancelCloseCallback,
      }
    });
  }

  hideTextDialog = () => {
    this.setState({
      DialogDetails: { Open: false, },
    })
  }

  getDivider = key_optional => {
    if (IsMobile()) {
      return null;
    }
    return (
      <Divider key={key_optional} orientation="vertical" flexItem className={this.props.classes.menuDivider} />
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.parentScrollTop && prevProps.parentScrollTop !== this.props.parentScrollTop) {
      this.setState({
        MobileMenuIsVisible: false,
      })
      this.setCursorPosition();
    }
  }

  render() {
    const {
      RichTextEditorState,
      EditorHasFocus,
      CursorPosition,
      MobileMenuIsVisible,
      DialogDetails,
    } = this.state;
    const {
      theme,
      classes,
      label,
    } = this.props;

    let blockTypeItems = [];
    blockTypeItems.push(this.getDivider("divBlockTypeItems"));
    if (!this.currentBlockIsListItem()) {
      const currentBlockTypeOption = this.getCurrentBlockTypeOption();
      blockTypeItems.push(
        <SelectControl
          tabIndex={-1}
          key="blockType"
          id="blockType"
          // label="Block Type"
          // forceShrinkLabel
          style={{
            marginRight:theme.spacing(1),
          }}
          hideEmpty
          options={_blockTypeListOptions} 
          value={currentBlockTypeOption && currentBlockTypeOption.value}
          onValueChange={this.handleBlockTypeChange}
        />
      );
    }
    if (this.currentBlockCanBeWrappedInListOrIndented()) {
      blockTypeItems.push(
        <Tooltip key="btBullet" title="Bullet list">
          <IconButton tabIndex={-1}
            onClick={this.handleMenuButtonClick("bulletList")}>
            <BulletListIcon />
          </IconButton>
        </Tooltip>,
        <Tooltip key="btOrdered" title="Ordered list">
          <IconButton tabIndex={-1}
            onClick={this.handleMenuButtonClick("orderedList")}>
            <OrderedListIcon />
          </IconButton>
        </Tooltip>
      );
    }
    if (this.currentBlockIsListItemAndCanBeLifted()) {
      blockTypeItems.push(
        <Tooltip key="btDecreaseIndent" title="Decrease indent">
          <IconButton tabIndex={-1}
            onClick={this.handleMenuButtonClick("indentDecrease")}>
            <IndentDecreaseIcon />
          </IconButton>
        </Tooltip>
      );
    }

    const menu = (
      <div
        className={classes.menu}
        style={{
          opacity: (IsMobile() && !MobileMenuIsVisible) ? 0 : 1,
          pointerEvents: (IsMobile() && !MobileMenuIsVisible) ? "none" : undefined,
          position: (IsMobile()) ? "fixed" : undefined,
          width: (IsMobile()) ? `calc(100% - ${theme.spacing(8)}px` : undefined,
          top: (IsMobile() && CursorPosition) ? Math.max(108, 28 + CursorPosition.top) : undefined,
          left: (IsMobile() && CursorPosition) ? theme.spacing(2) : undefined,
          backgroundColor: (IsMobile())
            ? theme.palette.background.pane
            : undefined,
        }}
      >
        <div className={classes.menuPrimary}>
          <Tooltip title="Select block">
            <IconButton tabIndex={-1}
              onClick={this.handleMenuButtonClick("selectBlock")}>
              <SelectBlockIcon />
            </IconButton>
          </Tooltip>
          {this.getDivider()}
          <Tooltip title="Bold">
            <IconButton tabIndex={-1}
              onClick={this.handleMenuButtonClick("strong")}>
              <BoldIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Italic">
            <IconButton tabIndex={-1}
              onClick={this.handleMenuButtonClick("em")}>
              <ItalicIcon />
            </IconButton>
          </Tooltip>
          {/*codeButton*/}
          <Tooltip title="Link">
            <IconButton tabIndex={-1}
              onClick={this.handleMenuButtonClick("link")}>
              <LinkIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Horizontal rule">
            <IconButton tabIndex={-1}
              onClick={this.handleMenuButtonClick("horizontalRule")}>
              <HorizontalRuleIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Clear formatting">
            <IconButton tabIndex={-1}
              onClick={this.handleMenuButtonClick("clear")}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
          {this.getDivider()}
          <Tooltip title="Undo">
            <span>
            <IconButton tabIndex={-1}
              disabled={!undoDepth(RichTextEditorState)}
              onClick={this.handleMenuButtonClick("undo")}>
              <UndoIcon />
            </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Redo">
            <span>
            <IconButton tabIndex={-1}
              disabled={!redoDepth(RichTextEditorState)}
              onClick={this.handleMenuButtonClick("redo")}>
              <RedoIcon />
            </IconButton>
            </span>
          </Tooltip>
          {blockTypeItems}
        </div>

        <div className={classes.menuSecondary}>
          <Tooltip title={this.getHotKeyInfo()}>
            <InfoIcon style={{color:theme.palette.text.secondary}} />
          </Tooltip>
        </div>        
      </div>
    );
    const descriptionHasValueOrEditorHasFocus = RichTextEditorState.doc.content.size > 2 || EditorHasFocus;
    const notchComponent = (label)
      ? (
        <div className={classes.notch}
          style={{
            top:(descriptionHasValueOrEditorHasFocus) ? -8 : 19,
            left:(descriptionHasValueOrEditorHasFocus) ? 8 : 9,
            zIndex:(descriptionHasValueOrEditorHasFocus) ? 1 : undefined,
            backgroundColor: (descriptionHasValueOrEditorHasFocus) ? theme.palette.background.paper : undefined,
          }}
        >
          <div className={classes.notchLabel}
            style={{
              fontSize:(descriptionHasValueOrEditorHasFocus) ? "0.77rem" : 16,
              color:(EditorHasFocus) ? theme.palette.primary.main : theme.palette.text.secondary,
            }}
          >
          {label}
          </div>
        </div>
      ) : null;

    return (
      <div className={classes.root}>
        <MultiUseDialog Details={DialogDetails} />

        {menu}

        <div className={classes.editorContainer}>
          {notchComponent}
          <div className={classes.editor}
            onFocus={() => this.setState({EditorHasFocus:true})}
            onBlur={() => this.setState({EditorHasFocus:false})}
            style={{
              border:(EditorHasFocus) ? "2px solid" : "1px solid",
              borderColor: (EditorHasFocus)
                ? theme.palette.primary.main
                : (theme.palette.type === "dark") ? "rgba(255, 255, 255, 0.23)" : "rgba(0, 0, 0, 0.23)",
              padding:(EditorHasFocus) ? 11 : 12,
              paddingTop:(EditorHasFocus) ? 9 : 10,
            }}
          >
            <ProseMirror
              ref={instance => this.RichTextEditorRef = instance}
              state={RichTextEditorState}
              dispatchTransaction={this.handleRichTextEditorTransaction}
              style={{
                height:(IsMobile()) ? "100%" : 0,
                fontSize:16,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

RichTextEditor.propTypes = {
  initialStateJson: PropTypes.string,
  initialStatePlainText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  parentScrollTop: PropTypes.number,
  label: PropTypes.string,
  onApiError: PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(RichTextEditor);