import { GetFieldForFilterAndSort } from '../Util/Field';
import {
  TextFieldOperators,
} from '../Util/Search';

const TaskCollectionFields = [
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[ProjectName].keyword",
		"Project",
		"FieldType_Text",
		"Meta_text_kw256lc[ProjectName]",
		null,
		"projectList",
	    true,
	    true, // SortOnly - filter doesn't make sense because there's no need when in /projects and /workspace has project subnodes
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[AssignmentUserEmail].keyword",
		"Assigned to",
		"FieldType_Text",
		null,
	    [
	      {
	          value: "equals",
	          label: "equals",
	      },
	      {
	        value: "notEquals",
	        label: "does not equal",
	      },
	    ],
	    "addressBookItemsList",
	    true,
	),
	GetFieldForFilterAndSort(
		"Meta_date_str256[CreatedOn].string",
		"Created On",
		"FieldType_Date",
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[CreatedByUserEmail].keyword",
		"Created By",
		"FieldType_Text",
		"Meta_text_kw256lc[CreatedByUserEmail]",
		[
	      {
	          value: "equals",
	          label: "equals",
	      },
	      {
	      	value: "notEquals",
	      	label: "does not equal",
	      },
	    ],
	    "addressBookItemsList",
	    true,
	),
	GetFieldForFilterAndSort(
		"Meta_date_str256[CompletedOn].string",
		"Completed On",
		"FieldType_Date",
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[CompletedByUserEmail].keyword",
		"Completed By",
		"FieldType_Text",
		"Meta_text_kw256lc[CompletedByUserEmail]",
		[
	      {
	          value: "equals",
	          label: "equals",
	      },
	      {
	      	value: "notEquals",
	      	label: "does not equal",
	      },
	    ],
	    "addressBookItemsList",
	    true,
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[Name].keyword",
		"Name",
		"FieldType_Text",
		"Meta_text_kw256lc[Name]",
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[ProcessName].keyword",
		"Process",
		"FieldType_Text",
		"Meta_text_kw256lc[ProcessName]",
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[ProcessElementName].keyword",
		"Process Action",
		"FieldType_Text",
		"Meta_text_kw256lc[ProcessElementName]",
	),
	GetFieldForFilterAndSort(
		"Meta_date_str256[DueOn].string",
		"Due On",
		"FieldType_Date",
	),
	GetFieldForFilterAndSort(
		"Meta_long[Duration]",
		"Duration",
		"FieldType_Number",
		null,
		null,
		null,
		false,
		true,
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[PrimaryDocumentName].keyword",
		"Primary Document Name",
		"FieldType_Text",
		"Meta_text_kw256lc[PrimaryDocumentName]",
	),
	GetFieldForFilterAndSort(
		"Meta_int[Priority]",
		"Priority",
		"FieldType_Text", // We use a text field internally, but use FieldType_Number for API queries
		null,
		[
			{
			    value: "equals",
			    label: "equals",
			},
		],
    	"taskPriorityTypes",
    	false,
    	false,
    	"FieldType_Number", // We use a text field internally, but use FieldType_Number for API queries
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[MilestoneName].keyword",
		"Milestone",
		"FieldType_Text",
		null,
		[
	      {
	          value: "equals",
	          label: "equals",
	      },
	      {
	      	value: "notEquals",
	      	label: "does not equal",
	      },
	    ],
    	"taskMilestones",
    	false,
    	false,
    	null,
    	"Meta_text_kw256lc[MilestoneName]",
    	true,
    	TextFieldOperators,
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[StateName].keyword",
		"Task State",
		"FieldType_Text",
		null,
		[
	      {
	          value: "equals",
	          label: "equals",
	      },
	      {
	      	value: "notEquals",
	      	label: "does not equal",
	      },
	    ],
    	"taskStates",
    	false,
    	false,
    	null,
    	"Meta_text_kw256lc[StateName]",
    	true,
    	TextFieldOperators,
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw50lc[Tag].keyword",
		"Tags",
		"FieldType_Text",
		"Meta_text_kw50lc[Tag]",
		null,
  	"tags",
  	true,
  	false,
  	null,
  	null,
  	false,
  	TextFieldOperators,
  	true,
	),
];

export default TaskCollectionFields;