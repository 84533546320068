import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import ClearIcon from '@material-ui/icons/Clear';

import { GetFilterGridItemsFromFields } from '../Util/Field';
import {
  GetInitialFilterState,
  GetSavedFilterState,
  SaveFilterStateToHistory,
  GetFilterFieldsAsMetaFieldFilters,
} from '../Util/Filters';

import { IsMobile } from '../Util/MobileDetector';
import debounce from 'es6-promise-debounce';

const styles = theme => ({
  filterAndSortDrawerPaper: {
    backgroundColor: theme.palette.background.pane,
    width: 300,
  },
  filterSortTabs: {
  },
  filterSortTab: {
  },
  filterSortContent: {
    overflow:"auto",
    padding:theme.spacing(2),
  },
  filterFieldGridItems: {
    alignItems: "center",
  }
});

class FilterSortDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      SelectedFilterSortTab: (props.hideFilters) ? "sort" : "filter",
      Fields: [],
      SecondaryFields: [],
      FullTextFilter: "",
    }
  }

  handleFilterStateUpdate = (state, skipFiltersChangedCallback) => {
    const stateToUpdate = {
      Fields: state.Fields || this.state.Fields,
      SecondaryFields: state.SecondaryFields || this.state.SecondaryFields,
      FullTextFilter: (typeof state.FullTextFilter === "string") ? state.FullTextFilter : this.state.FullTextFilter,
    };
    this.setState(stateToUpdate);
    if (!skipFiltersChangedCallback) {
      this.props.onFiltersChanged(
        stateToUpdate.FullTextFilter,
        GetFilterFieldsAsMetaFieldFilters(
          stateToUpdate.Fields,
          stateToUpdate.SecondaryFields,
        )
      );
    }
    this.saveStateToHistory(stateToUpdate);
  }

  saveStateToHistory = debounce(state => {
    // This is critical to prevent erratic issues when the collection (CollectionFields, etc.) changes rapidly
    if (this.props.location.pathname !== window.location.pathname) {
      return;
    }
    SaveFilterStateToHistory(this.props.location.pathname, this.props, state)
  }, 250);

  handleResetFilters = (skipSavedState, skipFiltersChangedCallback)  => {
    if (this.props.hideFilters) {
      return;
    }
    const state = (!skipSavedState)
      ? GetSavedFilterState(this.props, this.props.collectionFields || [], this.props.initialFullText)
      : GetInitialFilterState(this.props.collectionFields || [], null, this.props.isWorkspace);
    this.handleFilterStateUpdate(state, skipFiltersChangedCallback);
  }

  handleFullTextFilterChange = eventOrValue => {
    this.handleFilterStateUpdate({FullTextFilter: (eventOrValue.target) ? eventOrValue.target.value : eventOrValue});
  }

  handleResetClick = () => {
    this.props.onSetVisibility(false);
    this.handleResetFilters(true);
  }

  componentDidMount() {
    this.handleResetFilters(this.props.skipSavedState, true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.collectionFields !== prevProps.collectionFields) {
      this.handleResetFilters(true, true);
    } else if (this.props.initialFullText !== prevProps.initialFullText) {
      this.handleResetFilters(false, true);
    }

    if (this.props.forceReset !== prevProps.forceReset && this.props.forceReset) {
      this.handleResetFilters(this.props.skipSavedState, true);
    }
  }

  render() {
    const { 
      SelectedFilterSortTab,
      Fields,
      SecondaryFields,
      FullTextFilter,
  	} = this.state;
    const {
      classes,
      theme,
      organizationId,
      isOrganizationMember,
      isProjectMember,
      projectId,
      isWorkspace,
      sortType,
      collectionFields,
      sortDescending,
      onSetVisibility,
      open,
      hideFilters,
      hideSearchAll,
      onSortChange,
      onFlipSortDirection,
      onApiError,
    } = this.props;


    const sortListItems = collectionFields && collectionFields.length && collectionFields.map(st => (
      <ListItem button
        key={"sortitem_" + st.ID}
        selected={sortType && st.ID === sortType}
        onClick={() => onSortChange(st.ID)}>
        <ListItemText primary={st.Label || st.LabelOrName} />
      </ListItem>
    ));
    sortListItems.unshift(
      <ListItem button
        key={"sortitem_default"}
        selected={!sortType}
        onClick={() => onSortChange(null)}>
        <ListItemText primary="Rank" />
      </ListItem>
    );
    const sortDirectionListItem = (sortType) ? (
      <ListItem button
        key="_sortdirection_"
        onClick={() => onFlipSortDirection()}>
        <FormControlLabel
          onClick={e => e.stopPropagation()}
          control={
            <Switch
              color="secondary"
              onClick={e => {onFlipSortDirection(); e.stopPropagation(); }}
              checked={sortDescending}
             />
          }
          label="Descending" />
      </ListItem>
    ) : null;
    const sortList = (sortListItems && sortListItems.length)
      ? (
        <List style={{paddingTop:0}}>
          {sortDirectionListItem}
          {sortListItems}
        </List>
      ) : null;

    const fullTextFilter = (!hideSearchAll)
      ? (
        <Grid item key="fullTextFilter"
          style={{
            backgroundColor: (FullTextFilter) ? theme.palette.background.default : undefined,
          }}
        >
          <TextField
            label="Search all"
            variant="outlined"
            fullWidth
            autoFocus={!IsMobile()}
            value={FullTextFilter}
            onChange={this.handleFullTextFilterChange}
            InputProps={{
              endAdornment: (FullTextFilter) 
                ? (
                  <InputAdornment position="end">
                    <IconButton
                      tabIndex={-1}
                      edge="end"
                      aria-label="clear"
                      onClick={() => this.handleFullTextFilterChange("")}
                    >
                      <ClearIcon style={{fontSize:18}} />
                    </IconButton>
                  </InputAdornment>
                )
                : undefined,
            }}
          />
        </Grid>
      ) : null;

    const filterGridItems = (!hideFilters && organizationId)
      ? GetFilterGridItemsFromFields(organizationId, projectId, isOrganizationMember, isProjectMember, isWorkspace, 
        Fields, SecondaryFields, true, theme, classes, this.handleFilterStateUpdate, onApiError)
      : [];

    const filterGrid = (
      <Grid container direction="column" spacing={3}>
        {fullTextFilter}
        {filterGridItems}
        <Grid item>
        <Button
          onClick={() => this.handleResetClick()}
          size="large">
            CLEAR ALL
          </Button>
        </Grid>
      </Grid>
    );

    let filterSortContent;
    switch (SelectedFilterSortTab) {
      case "filter":
        filterSortContent = filterGrid;
        break;
      case "sort":
        filterSortContent = sortList;
        break;
      default:
        break;
    }
    const filterSortTabContent = (
      <div className={classes.filterSortContent}>
        {filterSortContent}
      </div>
    );

    const filterTab = (!hideFilters)
      ? (<Tab label="Filter" value="filter" disabled={hideFilters} className={classes.filterSortTab} />)
      : null;

    const filterSortTabs = (
      <Tabs
        variant="fullWidth"
        className={classes.filterSortTabs}
        value={SelectedFilterSortTab}
        onChange={(e, newValue) => this.setState({SelectedFilterSortTab: newValue})}
      >
        {filterTab}
        <Tab label="Sort" value="sort" className={classes.filterSortTab} />
      </Tabs>
    );

    return (
      <SwipeableDrawer
        classes={{ paper: classes.filterAndSortDrawerPaper }}
        open={open}
        swipeAreaWidth={(!IsMobile()) ? 0 : undefined}
        anchor="right"
        onOpen={() => onSetVisibility(true)}
        onClose={() => onSetVisibility(false)}
      >
        {filterSortTabs}
        {filterSortTabContent}
      </SwipeableDrawer> 
    );
  }
}

FilterSortDrawer.propTypes = {
  organizationId: PropTypes.string.isRequired,
  isOrganizationMember: PropTypes.bool,
  isProjectMember: PropTypes.bool,
  projectId: PropTypes.string,
  isWorkspace: PropTypes.bool,
  sortType: PropTypes.string,
  collectionFields: PropTypes.array.isRequired,
  sortDescending: PropTypes.bool.isRequired,
  onSetVisibility: PropTypes.func.isRequired,
  hideFilters: PropTypes.bool,
  hideSearchAll: PropTypes.bool,
  skipSavedState: PropTypes.bool,
  forceReset: PropTypes.bool,
  initialFullText: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onSortChange: PropTypes.func.isRequired,
  onFlipSortDirection: PropTypes.func.isRequired,
  onFiltersChanged: PropTypes.func.isRequired,
  onApiError: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(FilterSortDrawer);