import React from 'react';
import { DragLayer } from 'react-dnd';
import PropTypes from 'prop-types';

import {
  GetSubTaskComponent,
} from '../Util/Task';

function collect(monitor, props) {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    clientOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }
}

function TaskSubTaskDragLayer(
  {
    classes,
    theme,
    width,
    item,
    itemType,
    clientOffset,
    initialClientOffset,
    isDragging,
  }) {
  if (!item
    || !clientOffset
    || !isDragging) {
    return null;
  }

  return (
    <div style={{
      position:"fixed",
      pointerEvents:"none",
      left:clientOffset.x-8,
      top:clientOffset.y-18,
      width,
      zIndex:2,
    }}>
      {GetSubTaskComponent(classes, theme, item.SubTask, false, true)}
    </div>
  );
}

TaskSubTaskDragLayer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.number,
}

export default DragLayer(collect)(TaskSubTaskDragLayer);