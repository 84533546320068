import API, {
  GetAssetsPathForApi,
} from './api';
import {
  GetProjectAssetPath,
} from './PathHelper';

export const HandleGetAssets = (organizationId, projectId, onApiError, filter, otherParams) => {
  let params = {
    ...otherParams,
  }
  if (filter) {
    params.filter = filter;
  }

  return API.get(GetAssetsPathForApi(organizationId, projectId), { params })
    .then(resp => {
      return resp.data.Assets;
    })
    .catch(onApiError);
}

export const HandleUpdateAssets = (organizationId, projectId, assets, onApiError) => {
  return API.put(GetAssetsPathForApi(organizationId, projectId), assets)
    .then(resp => {})
    .catch(onApiError);
}

export const HandleRouteToAssetDialog = (props, projectId, assetId) => {
  props.history.push(GetProjectAssetPath(projectId, assetId), 
    { ...props.location.state });
}