import API, {
  GetAssetItemTasksPathForApi,
} from './api';

export const AddAssetItemTasks = (organizationId, projectId, assetId, assetItemId, tasks) => {
  return API.post(GetAssetItemTasksPathForApi(organizationId, projectId, assetId, assetItemId), { TaskIDs: tasks.map(t => t.ID) });
}

export const RemoveAssetItemTasks = (organizationId, projectId, assetId, assetItemId, IDs) => {
  return API.delete(GetAssetItemTasksPathForApi(organizationId, projectId, assetId, assetItemId), { data: { IDs } });
}