import React from 'react';

import DeleteIcon from '@material-ui/icons/Delete';

const ProjectActionType = {
	ProjectMember_Delete: {
		Id: "ProjectMemberDelete",
		Label: "Delete",
		Icon: (<DeleteIcon />),
	},
};

export default ProjectActionType;