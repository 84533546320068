import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import { GlobalContext } from '../Context/Global.context';

import ItemCollectionBase from '../Components/ItemCollectionBase';

import { UserApiKeys_Collection } from '../Model/UserApiKeys';

const styles = theme => ({
});

class UserApiKeys extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.state = {
      ShowProgressIndicatorImmediately: false,
    }

    this.Collection = new UserApiKeys_Collection(props, state => this.setState(state), this.handleApiError);
  }

  componentDidUpdate(prevProps) {
    this.Collection.updateProps(this.props);
  }

  handleApiError = ApiError => {
    this.setState({ 
      ApiError,
      ShowProgressIndicatorImmediately: false,
    });
    if (ApiError) {
      setTimeout(() => this.handleApiError(null), 1);
    }
  }

  render() {
    const {
      ApiError,
      Alert,
      ForcePrependItems,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      classes,
      // theme,
      ...restProps
    } = this.props;

    return (
      <ItemCollectionBase
        {...restProps}
        
        pageTitle={this.Collection.PageTitle}
        contentUri={this.Collection.ContentUri}
        contentUriParams={this.Collection.ContentUriParams}
        collectionName={this.Collection.CollectionName}
        itemsName={this.Collection.ItemsName}
        itemName={this.Collection.ItemName}
        defaultViewType={this.Collection.DefaultViewType}
        
        onGetCollectionFieldsPromise={this.Collection.HandleGetCollectionFieldsPromise}
        onGetHeadCells={this.Collection.HandleGetHeadCells}
        onGetCardGridItems={this.Collection.HandleGetCardGridItems}
        onGetTableRows={this.Collection.HandleGetTableRows}
        singleLineTableCells={this.Collection.SingleLineTableCells}

        hideFilterSortDrawer
        loadItemsImmediately
        apiError={ApiError}
        alert={Alert}
        onRefresh={this.Collection.HandleRefresh}
        onItemsChanged={this.Collection.HandleItemsChanged}
        forcePrependItems={ForcePrependItems}
        allowSelect={this.Collection.AllowSelect}
        canSelectItem={this.Collection.CanSelectItem}
        onFabClick={this.Collection.HandleCreateNew}
        forceMiniFab
        dialogContent={this.Collection.HandleGetDialogContent(this.state)}
        miscContent={this.Collection.HandleGetMiscContent(this.state)}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
      />
    );
  }
}

export default withStyles(styles, {withTheme: true})(UserApiKeys);