import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import ActionType from '../Model/ActionType';

import { Documents_Collection } from '../Model/Documents';
import GetMoreButton from '../Util/GetMoreButton';
import ProgressIndicator from '../Components/ProgressIndicator';
import API from '../Util/api';
import { 
  GetExecuteMultipleDownloadsPromise,
  GetDocumentContentPackageUri,
} from '../Util/Document';

import { IsMobile } from '../Util/MobileDetector';

const styles = theme => ({
  dialogPaper: {
    maxHeight:(!IsMobile()) ? "80%" : undefined,
  },
  cardGridItem: {
    width: "100%",
  },
});

class RelatedDocumentsDialog extends Component {
  constructor(props) {
    super(props);
    
    this.initialState = {
      Documents: [],
      DocumentsCursor: "",
      ShowGetMoreButton: false,
      ShowProgressIndicatorImmediately: false,
    };

    this.state = {
      ...this.initialState,
      open: props.open,
    }

    this.Collection = new Documents_Collection(props, state => this.setState(state), this.handleApiError,
      false, false, props.isUserAssignmentContextByDocumentFolder, props.usePublicApi,
      props.organizationId, props.projectId, 
      props.documentFolderId, null, null, null, null, false, true, props.documentGroupId,
      false, null, false, false, props.onRouteToDocument,
    );
  }

  setInitialState = () => {
    this.setState(this.initialState);
  }

  handleLoadRelatedDocuments = reset => {
    const params = {
      ...this.Collection.ContentUriParams,
      cursor: (!reset) ? this.state.DocumentsCursor : undefined,
    };

    this.setState({ShowProgressIndicatorImmediately:true});
    API.get(this.Collection.ContentUri, { params })
      .then(resp => {
        const documentList = resp.data;
        const newDocuments = documentList.Documents;
          // This can cause unexpected behavior for the user when a GET MORE button has to be clicked twice because the source is filtered out
          //.filter(d => d.ID !== this.props.sourceDocumentId);
        const Documents = (reset)
          ? newDocuments
          : [...this.state.Documents].concat(newDocuments);
        this.setState({
          Documents,
          DocumentsCursor: documentList.Cursor, 
          ShowGetMoreButton: documentList.Documents.length >= documentList.PageSize,
        });
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ShowProgressIndicatorImmediately: false});
      });
  }

  handleAction = (actionType, documentId) => {
    const actionFinalizer = () => {
      this.setState({ShowProgressIndicatorImmediately: false});
    }
    this.setState({ShowProgressIndicatorImmediately: true});
    switch (actionType) {
      case ActionType.Download:
        const uri = GetDocumentContentPackageUri(this.props.organizationId, this.props.projectId, this.props.documentId,
          this.props.usePublicApi, (this.props.isUserAssignmentContextByDocumentFolder) ? "documentFolder" : null,
          this.props.documentFolderId);
        GetExecuteMultipleDownloadsPromise([uri])
          .finally(() => {
            actionFinalizer();
          })
          .catch(this.handleApiError);
        break;
      default:
        break;
    }
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
    this.setInitialState();
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleApiError = (err, title_optional) => {
    this.setState({
      ShowProgressIndicatorImmediately: false,
    });
    this.props.onApiError(err, title_optional);
    if (err) {
      setTimeout(() => this.props.onApiError(null), 1);
    }
  }

  componentDidMount() {
    this.handleLoadRelatedDocuments();
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      this.setState({open: this.props.open !== false});
      if (this.props.open) {
        this.handleLoadRelatedDocuments();
      }
    }
  }

  render() {
    const { 
      open,
      Documents,
      ShowGetMoreButton,
      ShowProgressIndicatorImmediately,
  	} = this.state;
    const {
      classes,
      theme,
    } = this.props;

    const progressIndicator = (ShowProgressIndicatorImmediately) ?
      ( <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} /> )
      : null;

    const dialogActions = (
      <DialogActions>
        <Button onClick={() => this.handleClose()}>
          CLOSE
        </Button>
      </DialogActions>
    );

    const documentCardGridItems = (Documents && Documents.length) ?
      this.Collection.HandleGetCardGridItems(Documents, null, classes, theme, null, [],
        this.handleAction, null, null, null, true,
        { backgroundColor: theme.palette.background.default },
      )
      : null;

    const getMoreButtonGridItem = (ShowGetMoreButton)
      ? (
        <Grid item>
          {GetMoreButton(ShowGetMoreButton, () => this.handleLoadRelatedDocuments(), "Card", theme)}
        </Grid>
      ) : null;
    const documentGrid = (
      <Grid container spacing={2} direction="column">
        {documentCardGridItems}
        {getMoreButtonGridItem}
      </Grid>
    );

    const content = (
      <div style={{marginTop:theme.spacing(1)}}>
        {documentGrid}
      </div>
    );

    return (
       <Dialog
        fullWidth={!IsMobile()}
        fullScreen={IsMobile()}
        maxWidth="xs"
        classes={{
          paper:classes.dialogPaper,
        }}
        open={open}
        onClose={() => this.handleClose()}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description">
        <DialogTitle id="dialog-title">
          Related documents
        </DialogTitle>
        <DialogContent style={{paddingTop:0,}}>
          {progressIndicator}

          {content}
        </DialogContent>

        {dialogActions}
      </Dialog>
    );
  }
}

RelatedDocumentsDialog.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  usePublicApi: PropTypes.bool,
  onRouteToDocument: PropTypes.func,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  isUserAssignmentContextByDocumentFolder: PropTypes.bool.isRequired,
  documentFolderId: PropTypes.string,
  sourceDocumentId: PropTypes.string.isRequired,
  documentGroupId: PropTypes.string.isRequired,
  onApiError: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default withStyles(styles, {withTheme: true})(RelatedDocumentsDialog);