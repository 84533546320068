import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import SubscribedIcon from '@material-ui/icons/Bookmark';

import Card from './Card';

import { 
  GetFileSizePageCountSpan,
} from '../Util/Document';
// import { 
//   GetCardMetadataFromPreviewMetadata,
// } from '../Util/Fields';
import {
  // GetUserValue,
  GetDateValue,
} from '../Util/Properties';
import ActionType from '../Model/ActionType';

import orange from '@material-ui/core/colors/orange';

class DocumentSubscriptionCard extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      DocumentSubscription: {...this.props.DocumentSubscription},
    }
  }

  handleSubscribeAction = (actionType, id) => {
    if (!this.props.onAction) {
      return;
    }
    this.props.onAction(actionType, id, true)
      .then(documentSubscriptions => {
        if (documentSubscriptions && documentSubscriptions.length) {
          this.setState({DocumentSubscription: documentSubscriptions[0]});
        } else {
          // this.loadDocumentSubscription();
        }
      });
  }

  handlePostActionData = data => {
    // Updated subscription
    if (data.DocumentSubscriptions) {
      let subscriptionFinder = data.DocumentSubscriptions.filter(ds => ds.DocumentID === this.props.DocumentSubscription.DocumentID);
      if (subscriptionFinder.length) {
        this.setState({DocumentSubscription: subscriptionFinder[0]});
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.postActionData
      && this.props.postActionData !== prevProps.postActionData) {
      this.handlePostActionData(this.props.postActionData);
    }
  }

  render() {
    const {
      DocumentSubscription,
    } = this.state;
    const { 
      // sensitiveFields,
      noSelect,
      selected,
      onSelect,
      onAction,
      onCardAction,
      disableCardActions,
      style,
    } = this.props;

    let metadata = [];
    metadata.push(
      ["", GetFileSizePageCountSpan(DocumentSubscription.DocumentFileSize, DocumentSubscription.DocumentPageCount)],
    );

    metadata.push(
      ["Project", DocumentSubscription.ProjectName],
      ["Created On", GetDateValue(DocumentSubscription.CreatedOn)],
      ["Document Created On", GetDateValue(DocumentSubscription.DocumentCreatedOn)],
    );

    // metadata.push(...GetCardMetadataFromPreviewMetadata(DocumentSubscription.DocumentPreviewMetadata, sensitiveFields));

    let subscriptionIconButton = (DocumentSubscription)
      ? (
        (DocumentSubscription.Subscribed)
          ? (
            <IconButton
              key={ActionType.Subscription_Delete.Id}
              aria-label={ActionType.Subscription_Delete.Label}
              title={ActionType.Subscription_Delete.Label}
              onClick={() => this.handleSubscribeAction(ActionType.Subscription_Delete, DocumentSubscription.ID)}
              >
              <SubscribedIcon style={{color:orange[500]}} />
            </IconButton> 
          )
          : (
            <IconButton
              key={ActionType.Subscription_Create.Id}
              aria-label={ActionType.Subscription_Create.Label}
              title={ActionType.Subscription_Create.Label}
              onClick={() => this.handleSubscribeAction(ActionType.Subscription_Create, DocumentSubscription.ID)}
              >
              {ActionType.Subscription_Create.Icon}
            </IconButton> 
          )
      ) : null;
    let downloadIconButton = (
      <IconButton
        key={ActionType.Download.Id}
        aria-label={ActionType.Download.Label}
        title={ActionType.Download.Label}
        onClick={() => onAction(ActionType.Download, DocumentSubscription.ID)}
        >
        {ActionType.Download.Icon}
      </IconButton>
    );

    return (
      <Card
        style={style}
        onCardAction={onCardAction}
        noSelect={noSelect}
        onSelect={onSelect}
        selected={selected}
        height={256}
        name={DocumentSubscription.DocumentName}
        fileName={DocumentSubscription.DocumentName}
        thumbnailUrl={DocumentSubscription.DocumentThumbnailUrl}
        isSigned={DocumentSubscription.DocumentIsSigned || DocumentSubscription.IsSigned}
        pendingSignatures={DocumentSubscription.DocumentSignatureSessionIsActive}
        metadata={metadata}
        hoverActionItems={!disableCardActions
          ? [
            subscriptionIconButton,
            downloadIconButton,
          ]
          : undefined
        }
      />
    );
  }
}

DocumentSubscriptionCard.propTypes = {
  DocumentSubscription: PropTypes.object.isRequired,
  onCardAction: PropTypes.func.isRequired,
  disableCardActions: PropTypes.bool,
}

export default DocumentSubscriptionCard;