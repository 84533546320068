import React, { Component } from 'react';
import PropTypes from 'prop-types';

// import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';


import DashboardCard from '../DashboardCard';
import ItemSearchDialog from '../ItemSearchDialog';
import {
  GetProjectMembersPath,
  GetProjectTasksPath,
  GetProjectDocumentsPath,
  GetProjectFormTemplatesPath,
} from '../../Util/PathHelper';
import API, {
  GetOrganizationRequestsByMailOnboardingSessionPathForApi,
} from '../../Util/api';
import ProjectCreationDialog from '../../Admin/Components/ProjectCreationDialog';
import {
  HandleSetShowProjectSelectionDialog,
  GetProjectSelectionDialog,
} from '../../Util/Projects';
import MultiUseDialog from '../MultiUseDialog';

import Icon from '@material-ui/icons/DirectionsRun';
import IconColor from '@material-ui/core/colors/green';

import { IsMobile } from '../../Util/MobileDetector';
import { GlobalContext } from '../../Context/Global.context';

const styles = theme => ({
  list: {
    padding: 0,
    height: "100%",
    overflow: "auto",
    overflowX: "hidden",
    whiteSpace: "nowrap",
  },
});

class JumpInActionsWidget extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.InitialRequestDialogState = {
      OnboardingSessionDateString :"",
      OnboardingSessionTimeString: "",
      RequestMessage: "",
    };

    this.state = {
      ...this.InitialRequestDialogState,
      ProjectActionID: null,
      OrgNameForDialog: "",
      UserNameForDialog: "",
      RequestPhone: "",
      ShowOnboardingSessionDialog: false,
      ShowCreateProjectDialog: false,
      ShowProjectSelectionDialog: false,
      ShowItemSearchDialogForSignatureGathering: false,
      SelectedProjectMember: null,
    }
  }

  handleRouteTo = (url, useNewTab) => {
    if (url) {
      if (useNewTab) {
        window.open(url, "n1_resourceContent");
      } else {
        this.props.history.push(url);
      }
    }
  }

  handleStartProjectAction = ProjectActionID => {
    this.setState({ProjectActionID})
    HandleSetShowProjectSelectionDialog(state => this.setState(state), true);
  }

  handleCompleteProjectAction = projectMember => {
    HandleSetShowProjectSelectionDialog(state => this.setState(state), false, projectMember);
    switch (this.state.ProjectActionID) {
      case "inviteUsers":
        this.handleRouteTo(GetProjectMembersPath(projectMember.ProjectID, true));
        break;
      case "createTask":
        this.handleRouteTo(GetProjectTasksPath(projectMember.ProjectID, true));
        break;
      case "captureDocument":
        this.handleRouteTo(GetProjectDocumentsPath(projectMember.ProjectID, true));
        break;
      case "gatherSignatures":
        this.handleSetShowItemSearchDialogForSignatureGathering(true);
        break;
      case "createFolder":
        this.handleRouteTo(GetProjectDocumentsPath(projectMember.ProjectID, false, true));
        break;
      case "designForm":
        this.handleRouteTo(GetProjectFormTemplatesPath(projectMember.ProjectID, true));
        break;
      default:
        break;
    }
  }

  getProjectMembershipPackageFilterByProjectAction = () => {
    switch (this.state.ProjectActionID) {
      case "inviteUsers":
      case "designForm":
      case "designWorkflow":
        return pmp => pmp.IsAdmin;
      case "captureDocument":
      case "createFolder":
        return pmp => pmp.IsAdmin
          || pmp.Project.Access.MemberAccess.DocumentFolders === "All";
      default:
        return null;
    }
  }

  handleSetShowCreateProjectDialog = ShowCreateProjectDialog => {
    this.setState({ShowCreateProjectDialog});
  }

  handleSetShowItemSearchDialogForSignatureGathering = ShowItemSearchDialogForSignatureGathering => {
    this.setState({
      ShowItemSearchDialogForSignatureGathering,
    });
  }

  handleProjectCreated = project => {
    this.handleSetShowCreateProjectDialog(false);
    this.context.Reset();
    if (IsMobile()) {
      this.props.history.push(GetProjectTasksPath(project.ID));
    } else {
      this.props.history.push(GetProjectMembersPath(project.ID));
    }
  }

  handleSetShowOnboardingSessionDialog = ShowOnboardingSessionDialog => {
    this.setState({ShowOnboardingSessionDialog});
  }

  handleSendOnboardingRequest = () => {
    if (!this.state.RequestPhone) {
      return this.props.onApiError("Please provide a phone number.");
    }
    this.handleSetShowOnboardingSessionDialog(false);

    API.post(GetOrganizationRequestsByMailOnboardingSessionPathForApi(
      this.context.ActiveOrganizationPermissions.OrganizationID),
    {
      OrganizationName: this.state.OrgNameForDialog,
      UserName: this.state.UserNameForDialog,
      Phone: this.state.RequestPhone,
      PreferredDate: this.state.OnboardingSessionDateString,
      PreferredTime: this.state.OnboardingSessionTimeString,
      TimezoneOffset: (new Date()).getTimezoneOffset(),
      Message: this.state.RequestMessage,
    })
      .then(resp => {
        this.props.onAlert({
          Title: "Request sent",
          BodyText:"Someone will contact you shortly.",
        });
        this.setState({
          ...this.InitialRequestDialogState,
        });
      })
      .catch(this.props.onApiError);
  }

  setPropsForDialogWhenReady = () => {
    if (this.state.OrgNameForDialog && this.state.UserNameForDialog) {
      return;
    }
    if (!this.context.CompletedGET.ActiveOrganizationPermissions
      || !this.context.CompletedGET.UserPreferences) {
      return setTimeout(() => { this.setPropsForDialogWhenReady() }, 250);
    }
    this.setState({
      OrgNameForDialog: this.context.ActiveOrganizationPermissions.OrganizationName,
      UserNameForDialog: this.context.UserPreferences.UserName,
    });
  }

  componentDidMount() {
    this.setPropsForDialogWhenReady();
  }

  render() {
    const {
      ShowCreateProjectDialog,
      ShowProjectSelectionDialog,
      ShowItemSearchDialogForSignatureGathering,
      SelectedProjectMember,
      ShowOnboardingSessionDialog,
      OrgNameForDialog,
      UserNameForDialog,
      RequestPhone,
      OnboardingSessionDateString,
      OnboardingSessionTimeString,
      RequestMessage,
    } = this.state;
    const { 
      classes,
      theme,
      organizationId,
      DashboardWidget,
      ...passThroughProps
    } = this.props;

    const resourcesList = (
      <List dense className={classes.list}>
        <ListItem button onClick={() => this.handleSetShowCreateProjectDialog(true)}>
          <ListItemText primary="Create a project" />
        </ListItem>

        <ListItem button onClick={() => this.handleStartProjectAction("inviteUsers")}>
          <ListItemText primary="Invite users" />
        </ListItem>

        <ListItem button onClick={() => this.handleStartProjectAction("createTask")}>
          <ListItemText primary="Create a task" />
        </ListItem>

        <ListItem button onClick={() => this.handleStartProjectAction("captureDocument")}>
          <ListItemText primary="Capture or create a document" />
        </ListItem>

        <ListItem button onClick={() => this.handleStartProjectAction("gatherSignatures")}>
          <ListItemText primary="Gather signatures" />
        </ListItem>

        <ListItem button onClick={() => this.handleStartProjectAction("createFolder")}>
          <ListItemText primary="Create a folder for sharing" />
        </ListItem>

        <ListItem button onClick={() => this.handleStartProjectAction("designForm")}>
          <ListItemText primary="Design a form" />
        </ListItem>

        <ListItem button onClick={() => this.handleSetShowOnboardingSessionDialog(true)}>
          <ListItemText primary="Schedule an onboarding session" />
        </ListItem>
      </List>
    );

    const projectCreationDialog = (
      <ProjectCreationDialog
        organizationId={organizationId}
        open={ShowCreateProjectDialog}
        onCreated={this.handleProjectCreated}
        onClose={() => this.handleSetShowCreateProjectDialog(false)}
        onApiError={passThroughProps.onApiError}
      />
    );

    const projectSelectionDialog = (ShowProjectSelectionDialog)
      ? GetProjectSelectionDialog(
        this.context,
        this.getProjectMembershipPackageFilterByProjectAction(),
        this.state,
        state => this.setState(state),
        this.handleCompleteProjectAction,
        true,
        () => this.handleSetShowCreateProjectDialog(true),
      ) : null;

    const documentSearchDialogForSignatureGathering = (ShowItemSearchDialogForSignatureGathering)
      ? (
        <ItemSearchDialog
          {...passThroughProps}
          open
          itemType="document"
          onClose={() => this.handleSetShowItemSearchDialogForSignatureGathering(false)}
          dialogTitle="Choose a document"
          organizationId={organizationId}
          onlyUnsigned
          onlyHasSinglePageImages
          projectId={SelectedProjectMember.ProjectID}
          isProjectMember
          isWorkspace={false}
          disableSelect
          hideConfirmButton
          onItemClick={(item, defaultAction) => {
            this.handleSetShowItemSearchDialogForSignatureGathering(false);
            defaultAction();
          }}
          extraUrlParamsForDocumentRouting={{
            sig: true,
          }}
        />
      ) : null;

    const onboardingSessionDialogDetails = {
      FullWidth: !IsMobile(),
      FullScreen: IsMobile(),
      Open:ShowOnboardingSessionDialog || false,
      // ShowProgressIndicatorImmediately:ShowDialogProgressIndicator,
      Title:"Request onboarding session",
      BodyContent:(
        <Grid container spacing={2}>
          <Grid item xs={12} style={{marginBottom:theme.spacing(2)}}>
            We're happy to assist you and your team with a session focused on your needs!
          </Grid>
          <Grid item key="sOrg" xs={12}>
            <TextField
              label="Organization name"
              variant="outlined"
              value={OrgNameForDialog}
              onChange={e => this.setState({OrgNameForDialog:e.target.value})}
              fullWidth
            />
          </Grid>
          <Grid item key="sName" xs={12} sm={6}>
            <TextField
              label="Your name"
              variant="outlined"
              value={UserNameForDialog}
              onChange={e => this.setState({UserNameForDialog:e.target.value})}
              fullWidth
            />
          </Grid>
          <Grid item key="sPhone" xs={12} sm={6}>
            <TextField
              label="Phone"
              variant="outlined"
              value={RequestPhone}
              onChange={e => this.setState({RequestPhone:e.target.value})}
              fullWidth
              autoFocus
            />
          </Grid>
          <Grid item key="sDate" xs={12} sm={7}>
            <TextField
              variant="outlined"
              label="Preferred date (optional)"
              value={OnboardingSessionDateString}
              onChange={e => this.setState({OnboardingSessionDateString:e.target.value})}
              fullWidth
              type="date"
              InputLabelProps={{ shrink: true, }}
              inputProps={{max:"9999-12-31"}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      tabIndex={-1}
                      edge="end"
                      aria-label="clear"
                      onClick={() => this.setState({
                        OnboardingSessionDateString:"",
                        OnboardingSessionTimeString:"",
                      })}
                    >
                      <ClearIcon style={{fontSize:18}} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item key="sTime" xs={12} sm={5}>
            <TextField
              label="Preferred time (optional)"
              variant="outlined"
              value={OnboardingSessionTimeString}
              onChange={e => this.setState({OnboardingSessionTimeString:e.target.value})}
              fullWidth
              type="time"
              InputLabelProps={{ shrink: true, }}
            />
          </Grid>
          <Grid item key="sMessage" xs={12}>
            <TextField
              label="Message (optional)"
              variant="outlined"
              value={RequestMessage}
              onChange={e => this.setState({RequestMessage:e.target.value})}
              fullWidth
              multiline
              rows={6}
            />
          </Grid>
        </Grid>
      ),
      DialogWidth: "sm",
      CancelCallback:() => this.handleSetShowOnboardingSessionDialog(false),
      CloseCallback:() => this.handleSetShowOnboardingSessionDialog(false),
      IsConfirmation: true,
      ConfirmLabel:"SEND REQUEST",
      ConfirmCallback:this.handleSendOnboardingRequest,
    };

    return (
      <DashboardCard
        title="Jump-In Actions"
        titleIcon={<Icon style={{color: IconColor[500]}} />}
        noFadeout
        xs={12}
        sm={6}
        md={3}
        lg={2}
        xl={2}
        {...passThroughProps}
        DashboardWidget={DashboardWidget}
      >
        {resourcesList}
        {projectCreationDialog}
        {projectSelectionDialog}
        {documentSearchDialogForSignatureGathering}
        <MultiUseDialog Details={onboardingSessionDialogDetails} />
      </DashboardCard>  
    );
  }
}

JumpInActionsWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string,
};

export default withStyles(styles, {withTheme: true})(JumpInActionsWidget);