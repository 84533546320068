import Collection from '../Model/Collection';

import CaptureCore from '../Components/CaptureCore';

export class FileCapture_Collection extends Collection {
  constructor(props, onSetState, onApiError, onAlert, isWorkspace, isProjects, organizationId, projectId) {
    if (typeof organizationId !== "string") {
      console.log("Warning: organizationId required and not found");
    }
    if (typeof projectId !== "string") {
      console.log("Warning: projectId required and not found");
    }
    super(props, onSetState, onApiError, isWorkspace, isProjects, organizationId, projectId);

    this.PassThroughComponent = (
      <CaptureCore
        {...props}
        organizationId={organizationId}
        projectId={projectId}
        onApiError={onApiError}
        onAlert={onAlert}
      />
    );
  }
}