import React from 'react';
import { DragLayer } from 'react-dnd';

import DraggableSignatureField from '../Components/DraggableSignatureField';

function collect(monitor) {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    clientOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }
}

function SignatureFieldDragLayer(
  {
    item,
    itemType, 
    clientOffset, 
    isDragging, 
    height, 
    backgroundColor, 
    canvasRef,
  }) {
  // This is exposed only for DraggableListItem, which contains a Data property of type
  // DocumentSignatureFormFieldType
  if (!item 
    || !item.Data
    || itemType !== "DraggableListItem"
    || !clientOffset
    || !isDragging) {
    return null;
  }

  let canvasRect = canvasRef && canvasRef.getBoundingClientRect();
  let width = (canvasRect && item.Data.WidthPercent)
    ? canvasRect.width * item.Data.WidthPercent
    : 100;

  return (
    <div style={{
      position:"fixed",
      pointerEvents:"none",
      width:"max-content",
      height:"max-content",
      zIndex:100,
      left:clientOffset.x,
      top:clientOffset.y,
    }}>
      <DraggableSignatureField
        SignatureField={item.Data}
        backgroundColor={backgroundColor}
        height={height || 24}
        width={width}
      />
    </div>
  );
}

export default DragLayer(collect)(SignatureFieldDragLayer);