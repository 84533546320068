import axios from 'axios';

let agentUri = "https://127.0.0.1:55988";
// if (process.env.REACT_APP_DEBUG) {
//   agentUri = "https://127.0.0.1:55988";
// }
let agentApi = axios.create({
  withCredentials: true,
  baseURL: agentUri+"/NucleusOne/CaptureAgent",
});

export function TestConnection() {
	return agentApi.get("/TestConnectionJsonp");
}

export function GetDevices() {
	return agentApi.get("/GetDevicesJsonp");
}

export function GetDeviceCapabilities(deviceSourceName) {
	let reqJson = JSON.stringify({
	  DeviceSourceName: deviceSourceName,
	});
	return agentApi.get("/GetDeviceCapabilitiesJsonp?reqJson="+reqJson);
}

export function BeginScan(selectedDevice, useDeviceSoftware) {
	let reqJson = JSON.stringify({
	  UseDeviceSoftware: useDeviceSoftware,
	  DeviceSourceName: selectedDevice.DeviceSourceName,
	  Size: selectedDevice.Size,
	  Resolution: selectedDevice.Resolution,
	  PixelType: selectedDevice.PixelType,
	  InputSource: selectedDevice.InputSource,
	  DuplexType: selectedDevice.DuplexType,
	});
	return agentApi.get("/BeginScanJsonp?reqJson="+reqJson);
}

export function CancelScan() {
	return agentApi.get("/CancelScanJsonp");
}

export function GetNextImage() {
	return agentApi.get("/GetNextImageJsonp");
}