export const HandleStartMoveTaskState = (taskStates, getStateFunc, setStateFunc) => columnProperty => {
  setStateFunc(null, null, {
    PreMoveTaskStatesJson: JSON.stringify(taskStates),
    CollectionIsDragging: true,
  });
}

export const HandleMoveTaskStateOverTaskState = (taskStates, getStateFunc, setStateFunc) =>
  (sourceColumnProperty, targetColumnProperty) => {

  if (!sourceColumnProperty || !sourceColumnProperty.TaskState) {
    return;
  }
  // console.log("Source ColumnProperty: ", sourceColumnProperty);
  
  if (!targetColumnProperty || !targetColumnProperty.TaskState) {
    return;
  }
  // console.log("Target ColumnProperty: ", targetColumnProperty);

  let sourceIndex = taskStates.indexOf(sourceColumnProperty.TaskState);
  let targetIndex = taskStates.indexOf(targetColumnProperty.TaskState);
  if (sourceIndex === targetIndex) {
    return;
  }
  
  // console.log("Index of source: ", sourceIndex, " Index of target: ", targetIndex);
  if (sourceIndex === null || targetIndex === null) {
    return;
  }

  let taskStateToUpdate = taskStates[sourceIndex];
  taskStates.splice(sourceIndex, 1);
  taskStates.splice(targetIndex, 0, taskStateToUpdate);
  setStateFunc(taskStates, null, { });
}

export const HandleAbortMoveTaskState = (taskStates, getStateFunc, setStateFunc) => () => {
  const preMoveTaskStates = JSON.parse(getStateFunc("PreMoveTaskStatesJson"));
  setStateFunc(preMoveTaskStates, null, { CollectionIsDragging: false });
}

export const HandleEndMoveTaskState = (taskStates, getStateFunc, setStateFunc) => () => {
  // ReIndex all TaskStates
  let taskStatesChanged = [];
  taskStates.forEach((tm, index) => {
    if (tm.Index !== index) {
      tm.Index = index;
      taskStatesChanged.push(tm);
    }
  });

  if (taskStatesChanged.length) {
    setStateFunc(taskStates, taskStatesChanged, { CollectionIsDragging: false });
  } else {
    setStateFunc(null, null, { CollectionIsDragging: false });
  }
}