import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import NoSsr from '@material-ui/core/NoSsr';
import AsyncSelect from 'react-select/async';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import debounce from 'es6-promise-debounce';

import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';

import {
  GetSearchPath,
} from '../Util/PathHelper';

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  const inputWidthsClass = (props.isFocused || props.selectProps.maxWidth)
    ? (props.selectProps.overrideClasses
      && props.selectProps.overrideClasses.inputWidthsFocused)
        ? props.selectProps.overrideClasses.inputWidthsFocused
        : props.selectProps.classes.inputWidthsFocused
    : (props.selectProps.overrideClasses
      && props.selectProps.overrideClasses.inputWidths)
        ? props.selectProps.overrideClasses.inputWidths
        : props.selectProps.classes.inputWidths
  ;
  const className = classNames(props.selectProps.classes.input, inputWidthsClass);

  return (
    <TextField
      variant="outlined"
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className,
          style: props.selectProps.inputStyle,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      component="div"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      className={props.selectProps.classes.menuItem}
      style={
        (props.selectProps.styles.option)
          ? props.selectProps.styles.option({
            fontWeight: props.isSelected ? 500 : 400,
          }, props)
          : null
      }
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

const styles = theme => ({
  container:{
    display:"flex",
    alignItems:"center",
  },
  textField: {
    "& fieldset": {
      border:0,
      backgroundColor:"rgba(90,90,90,0.2)",
      borderRadius:4,
    },
  },
  input: {
    display: 'flex',
    height:'auto',
    color:theme.palette.text.primary,
    paddingTop: theme.spacing(1) / 2,
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1) / 2,
    paddingLeft: theme.spacing(6),
    transition: theme.transitions.create('width'),
    // "& div div span": { // Force loading animation to stay the same color throughout
    //   backgroundColor:"#888",
    // },
    "& div div": {
      color:"inherit",
    },
    "& svg": { // Force selector to stay the same color throughout
      // color:"inherit",
    },
  },
  inputWidths: {
    width: 220,
    [theme.breakpoints.up('md')]: {
      width: 180,
    },
    [theme.breakpoints.up('lg')]: {
      width: 240,
    },
  },
  inputWidthsFocused: {
    width: 220,
    [theme.breakpoints.up('md')]: {
      width: 600,
    },
    [theme.breakpoints.up('lg')]: {
      width:910,
    },
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    // The following margin items are necessary for variants outlined/filled
    // marginTop: theme.spacing(2),
    // marginRight: theme.spacing(2),
    // marginBottom: theme.spacing(2) - 3,
    // marginLeft: theme.spacing(2) - 2,
    /* These two properties, width:0 and overflow:hidden must remain to fix 
    an issue with the react-select component growing to infinity as users type in the input */
    width: 0,
    overflow: "hidden",
    /* This is a further fix, which prevents the valueContainer from having a large height after a value is in place */
    /* This doesn't appear to be needed now, and, furthermore, it breaks the ability for the height to grow with multi-select fields */ 
    // maxHeight: 27,
  },
  chip: {
    margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    color:"#888",
    position: 'absolute',
    // left: 2,
    fontSize: 16,
  },
  paper: {
    // position: 'static',
    position: "absolute",
    width: "100%",
    // The following is necessary for variants outlined/filled
    // zIndex: 2,
    // marginTop: theme.spacing(1),
    // left: 0,
    // right: 0,
  },
  menuItem: {
    padding:theme.spacing(2),
  },
  advancedSearchContainer: {
    padding:theme.spacing(1),
    paddingTop:theme.spacing(1)-2,
  },
  advancedSearchButton: {
    color:"#888",
    "&:hover": {
      color:"inherit",
    },
  },
  searchIcon: {
    marginTop:4,
    marginLeft: theme.spacing(2),
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 1,
  },
});

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class AppBarSearchControl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      field: '',
    }
  }

  // Workaround to enable home/end keys on input instead of options
  // https://github.com/JedWatson/react-select/issues/3562
  handleKeyDown = e => {
    switch(e.key){
      case "Enter":
      case "ArrowDown":
      case "ArrowUp":
        e.stopPropagation();
        e.preventDefault();
        break;
      case "Home": e.preventDefault();
        if(e.shiftKey) e.target.selectionStart = 0;
        else e.target.setSelectionRange(0,0);
        break;
      case "End": e.preventDefault();
        const len = e.target.value.length;
        if(e.shiftKey) e.target.selectionEnd = len;
        else e.target.setSelectionRange(len,len);
        break;
      default:
        break;
    }
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e);
    }
  };

  componentDidMount() {
  }

  render() {
    const { 
      classes,
      theme,
      value,
      onGetOptionsFilterPromise,
      onValueChange,
      location,
      history,
      updateId,
      maxWidth,
      inputStyle,
      overrideClasses,
      showAdvancedSearchButton,
    } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.primary.contrastText,
        '& input': {
          font: 'inherit',
        },
      }),
      clearIndicator: base => ({
        ...base,
        cursor: "pointer",
      }),
      dropdownIndicator: base => ({
        ...base,
        cursor: "pointer",
      }),
    };

    const advancedSearchButton = (showAdvancedSearchButton && location && history)
      ? (
        <Button onClick={() => this.props.history.push(GetSearchPath())}>
          ADVANCED SEARCH
        </Button>
      ) : null;

    return (
      <NoSsr>
        <div className={classes.container}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <AsyncSelect
            key={updateId} // This is a hack to force the component to update its list when the value changes
            classes={classes}
            styles={{
              ...selectStyles,
            }}
            textFieldProps={{
              className: classes.textField,
            }}
            history={history}
            maxWidth={maxWidth}
            inputStyle={inputStyle}
            overrideClasses={overrideClasses}
            loadOptions={debounce(onGetOptionsFilterPromise, 250)}
            defaultOptions={true}
            cacheOptions={false}
            isClearable
            components={components}
            autoFocusFirstOption={false}
            value={value}
            placeholder="Search..."
            noOptionsMessage={
              e => (e.inputValue && e.inputValue.length > 0)
                ? (
                  <div style={{display:"flex"}}>
                    <div style={{flexGrow:1}}>
                      No items found
                    </div>
                    {advancedSearchButton}
                  </div>
                )
                : advancedSearchButton
            }
            onChange={selectedOptions => (onValueChange) ? onValueChange(selectedOptions) : null}
            onKeyDown={this.handleKeyDown}
            isMulti={false}
          />
        </div>
      </NoSsr>
    );
  }
}

AppBarSearchControl.propTypes = {
  classes: PropTypes.object.isRequired,
  overrideClasses: PropTypes.object,
  theme: PropTypes.object.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  showAdvancedSearchButton: PropTypes.bool,
  onGetOptionsFilterPromise: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  inputStyle: PropTypes.object,
};

export default withStyles(styles, {withTheme: true})(AppBarSearchControl);