import ReactDOM from 'react-dom';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';

import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ClearIcon from '@material-ui/icons/Clear';

import AsyncSelectControl from '../Components/AsyncSelectControl';

import { IsMobile } from '../Util/MobileDetector';

import TaskPriorityType from '../Model/TaskPriorityType';

import {
  ViewType_KanbanAssignment,
  ViewType_KanbanPriority,
  ViewType_KanbanMilestone,
  ViewType_KanbanState,
} from '../Util/ViewType';
import {
  GetAddressBookItemsPromise,
} from '../Util/AddressBookItems';
import {
  GetUserValue,
} from '../Util/Properties';

export const GetTaskLabelByResult = (result, plural) => {
  if (!result) {
    return (plural) ? "Tasks" : "Task";
  }
  switch (result) {
      case "Complete":
        return (plural) ? "Completed Tasks" : "Completed Task";
      case "Deny":
        return (plural) ? "Deleted Tasks" : "Deleted Task";
      default:
        return (plural) ? "Tasks" : "Task";
    }
}

export const GetTaskPriorityLabel = taskPriority => {
  if (taskPriority === undefined) {
    return "";
  }
  const typeFinder = TaskPriorityType.filter(t => t.Type === taskPriority);
  if (typeFinder.length) {
    return typeFinder[0].Label;
  }
  return "";
}

export const GetTaskDurationAsString = (multiplier, interval) => {
  if (multiplier > 0) {
    return `${multiplier} ${interval + ((multiplier > 1) ? "s" : "")}`;
  }
  return "";
}

const handleSubTaskComponentKeyDown = (e, subTaskIndex, onSubTaskAddedAfter) => {
    const c = e.keyCode || e.which;
    // Fix for Gboard on Android
    // It's a bit quirky because a newline will appear and then disappear on the current item.
    // It's probably best to avoid it. In Android, a return press on an empty line will work correctly.
    /*if (c === 0 || c === 229) {
      setTimeout(() => {
        const v = e.target.value;
        const cc = v.charCodeAt(v.length - 1);
        if (cc === 10) {
          e.preventDefault();
          onSubTaskAddedAfter(this.props.SubTaskIndex);
          onSubTaskChanged(this.props.SubTaskIndex, v.substr(0, v.length - 1));
        }
      }, 1);
    } else*/ if (c === 13 && !e.shiftKey) {
      e.preventDefault();
      onSubTaskAddedAfter(subTaskIndex);
    }
  }

const dragLayerMargin = 28;
export const GetSubTaskComponentClasses = theme => {
  return {
    completedSubTaskName: {
      textDecorationLine: "line-through",
      marginTop:11,
      marginLeft:14,
      lineHeight:1.2,
    },
    subTask: {
      position:"relative",
      display:"flex",
      marginBottom:theme.spacing(1),
      "&:hover $subTaskDragIndicatorIcon": {
        visibility:"visible",
      },
      "&:hover $subTaskClear": {
        visibility:"visible",
      },
    },
    subTaskValue: {
      overflow:"hidden",
      flexGrow:1,
    },
    subTaskDragIndicator: {
      cursor:"grab",
      "&:active:hover": {
        opacity: 0,
      },
    },
    subTaskDragIndicatorIcon: {
      marginTop:9,
      visibility:(!IsMobile()) ? "hidden" : undefined,
    },
    subTaskClear: {
      alignSelf:"flex-start",
      marginTop:10,
      visibility:(!IsMobile()) ? "hidden" : undefined,
    },
    subTaskDragCover: {
      position:"absolute",
      marginLeft: dragLayerMargin,
      width:`calc(100% - ${dragLayerMargin}px)`,
      height:"100%",
      pointerEvents:"none",
      touchAction:"none",
      backgroundColor:theme.palette.background.default,
      borderRadius:8,
      zIndex: 2,
    },
  };
}

export const GetSubTaskComponent = (classes, theme, subTask, disabled, isDragLayer, subTaskIndex,
  connectDragSource, connectDropTarget,
  onSetSubTaskTextFieldRef, onSubTaskChanged, onSubTaskAddedAfter) => {

  if (!subTask) {
    return null;
  }

  const subTaskValue = (subTask.IsComplete)
    ? (
      <Typography
        className={classes.completedSubTaskName}
        ref={instance => { if (!isDragLayer) { onSetSubTaskTextFieldRef(subTaskIndex, instance); } }}
      >
        {subTask.Name}
      </Typography>
    )
    : (
      <TextField
        variant="outlined"
        size="small"
        value={subTask.Name}
        onKeyDown={e => { if (!isDragLayer) { handleSubTaskComponentKeyDown(e, subTaskIndex, onSubTaskAddedAfter); } }}
        onChange={e => { if (!isDragLayer) { onSubTaskChanged(subTaskIndex, e.target.value); } }}
        fullWidth
        multiline
        disabled={disabled}
        inputRef={instance => { if (!isDragLayer) { onSetSubTaskTextFieldRef(subTaskIndex, instance); } }}
        InputProps={{
          endAdornment: (!disabled) ? (
            <InputAdornment position="end" className={classes.subTaskClear}>
              <IconButton
                tabIndex={-1}
                edge="end"
                aria-label="remove"
                onClick={() => { if (!isDragLayer) { onSubTaskChanged(subTaskIndex, null, null, true); } }}
              >
                <ClearIcon style={{fontSize:18}} />
              </IconButton>
            </InputAdornment>
          ) : undefined,
        }}
      />
    );

  const dragCover = (!isDragLayer && subTask.IsDragging)
    ? (
      <div className={classes.subTaskDragCover} />
    )
    : undefined;

  return (
    <div className={classes.subTask}
      ref={instance => { if (!isDragLayer) { connectDropTarget(ReactDOM.findDOMNode(instance)); } }}
    >
      {dragCover}
      <div className={classes.subTaskDragIndicator}
        style={{
          cursor: (disabled) ? "default" : undefined,
        }}
        ref={instance => { if (!isDragLayer) { connectDragSource(ReactDOM.findDOMNode(instance)); } }}
      >
        <DragIndicatorIcon className={classes.subTaskDragIndicatorIcon}
          style={{
            visibility:(disabled) ? "hidden" : undefined,
            opacity:(isDragLayer) ? 0 : undefined,
          }}
        />
      </div>
      <div>
        <Checkbox
          tabIndex={-1}
          color="secondary"
          disabled={disabled}
          checked={subTask.IsComplete}
          onChange={e => onSubTaskChanged(subTaskIndex, null, true)}
        />
      </div>
      <div className={classes.subTaskValue}
        style={{
          opacity: (!isDragLayer && subTask.IsDragging) ? 0 : undefined,
        }}
      >
        {subTaskValue}
      </div>
    </div>
  );      
}

export const GetTaskAssignmentDialogDetails = (organizationId, projectId, onDialogConfirmed, onDialogClosed,
  onApiError, listValuesAssignmentOption, onAssignmentValueChange) => {
  
  return {
    Open:true,
    Title: "Task Assignment",
    DialogWidth: "xs",
    FullWidth: true,
    IsConfirmation: true,
    ConfirmLabel: "ASSIGN",
    BodyContent: (
      <AsyncSelectControl label="Assign to" 
        // floatingOptions
        noFlexWrap
        onGetOptionsFilterPromise={
          filter => {
            return GetAddressBookItemsPromise(
              organizationId,
              projectId,
              true,
              true,
              false,
              false,
              filter)
              .then(items => {
                return items.map(abi => { 
                  if (abi.ProjectMemberID) {
                    return ({
                      value: abi.EmailLower,
                      label: GetUserValue(abi.EmailLower,
                        (abi.Name)
                          ? `${abi.Name} (member ${abi.EmailLower})`
                          : `${abi.EmailLower} (member)`),
                      assignmentUserName: abi.Name,
                    });
                  } else {
                    return ({
                      value: abi.EmailLower,
                      label: GetUserValue(abi.EmailLower, (abi.Name) ? `${abi.Name} (${abi.EmailLower})` : abi.EmailLower),
                      assignmentUserName: abi.Name,
                    });
                  }
                });
              })
              .catch(onApiError);
          }
        } 
        listValues={listValuesAssignmentOption}
        onValueChange={onAssignmentValueChange}
        notClearable
      />
    ),
    CancelCallback:() => onDialogClosed(),
    CloseCallback:() => onDialogClosed(),
    ConfirmCallback:() => onDialogConfirmed(),
  };
}



const getNewSubTask = initialValue => {
  return {
    Name: initialValue || "",
    IsComplete: false,
  };
}

export const HandleSubTaskAdded = (subTasks, getStateFunc, setStateFunc) => initialValue => {
  const newSubTask = getNewSubTask(initialValue);
  if (!subTasks) {
    subTasks = [ newSubTask ];
  } else {
    subTasks.push(newSubTask);  
  }
  
  setStateFunc(subTasks, true);
}

export const HandleSubTaskAddedAfter = (subTasks, getStateFunc, setStateFunc) => (index, initialValue) => {
  const newSubTask = getNewSubTask(initialValue);
  if (!subTasks) {
    subTasks = [ newSubTask ];
  } else {
    if (index > subTasks.length) {
      subTasks.push(newSubTask);
    } else {
      subTasks.splice(1 + index, 0, newSubTask);
    }
  }
  
  setStateFunc(subTasks, true);
}

export const HandleSubTaskChanged = (subTasks, getStateFunc, setStateFunc) => (index, name, invertIsComplete, removeItem) => {
  if (removeItem) {
    subTasks = subTasks.filter((t, i) => i !== index);
  } else {
    let subTask = subTasks[index];
    if (typeof name === "string") {
      subTask.Name = name;
    }
    if (invertIsComplete) {
      subTask.IsComplete = !subTask.IsComplete;
    }
  }
  
  setStateFunc(subTasks, true);
}

export const HandleStartMoveSubTask = (subTasks, getStateFunc, setStateFunc) => sourceIndex => {
  subTasks[sourceIndex].IsDragging = true;
  setStateFunc(subTasks, false, {
    PreMoveSubTasksJson: JSON.stringify(subTasks),
  });
}

export const HandleMoveSubTask = (subTasks, getStateFunc, setStateFunc) =>
  (sourceSubTask, sourceClientOffsetY, targetSubTask, targetClientOffsetY) => {
  
  if (!sourceSubTask) {
    return;
  }
  // console.log("Source SubTask: ", sourceSubTask);
  
  // Target is DraggableTaskSubTask
  if (targetSubTask) {
    // console.log("Target SubTask: ", targetSubTask);

    // // This prevents a situation where the hover function repeats
    // // when the source is larger or smaller (in height) than the target
    // const lastSourceClientOffsetY = getStateFunc("sourceClientOffsetY");
    // if (lastSourceClientOffsetY && sourceClientOffsetY && lastSourceClientOffsetY === sourceClientOffsetY) {
    //   return;
    // }

    //  (sourceClientOffsetY, targetClientOffsetY);
    const sourceIsAtTargetTop = 
      sourceClientOffsetY >= targetClientOffsetY && sourceClientOffsetY < targetClientOffsetY + 42;
    // console.log(sourceIsAtTargetTop);
    if (!sourceIsAtTargetTop) {
      return;
    }

    let sourceIndex = subTasks.indexOf(sourceSubTask);
    let targetIndex = subTasks.indexOf(targetSubTask);
    if (sourceIndex === targetIndex) {
      return;
    }
    
    // console.log("Index of source: ", sourceIndex, " Index of target: ", targetIndex);
    if (sourceIndex === null || targetIndex === null)
      return;

    subTasks.splice(sourceIndex, 1);
    subTasks.splice(targetIndex, 0, sourceSubTask);
    setStateFunc(subTasks, false, { sourceClientOffsetY });
  }
}

const clearIsDragging = subTasks => {
  const draggingSubTaskFinder = subTasks.filter(st => st.IsDragging);
  if (draggingSubTaskFinder) {
    draggingSubTaskFinder.forEach(st => st.IsDragging = false);
  }
}

export const HandleAbortMoveSubTask = (subTasks, getStateFunc, setStateFunc) => () => {
  const preMoveSubTasks = JSON.parse(getStateFunc("PreMoveSubTasksJson"));
  clearIsDragging(preMoveSubTasks);
  setStateFunc(preMoveSubTasks, false, { });
}

export const HandleEndMoveSubTask = (subTasks, getStateFunc, setStateFunc) => () => {
  clearIsDragging(subTasks);
  setStateFunc(subTasks, true, { });
}




export const HandleStartMoveTask = (tasks, getStateFunc, setStateFunc) => 
  (sourceIndex, selectedTaskIds) => {

  const PreMoveTasksJson = JSON.stringify([...tasks]);
  let draggingTask = tasks[sourceIndex];
  draggingTask.IsDragging = true;
  
  if (selectedTaskIds && selectedTaskIds.length > 1) {
    // Remove additional selected items from list only if the dragging task is part of the group
    if (selectedTaskIds.filter(id => id === draggingTask.ID).length > 0) {
      selectedTaskIds.forEach(id => {
        // Ignore dragging task
        if (id === draggingTask.ID) {
          return;
        }
        const taskFinder = tasks.filter(t => t.ID === id);
        if (taskFinder.length) {
          taskFinder[0].IsDragging = true;
        }
      });
    }
  }

  setStateFunc(tasks, false, {
    PreMoveTasksJson,
  });
}

export const HandleMoveTaskOverTask = (tasks, getStateFunc, setStateFunc) =>
  (viewType, sourceTask, selectedTaskIds, targetTask) => {

  if (!sourceTask) {
    return;
  }
  // console.log("Source Task: ", sourceTask);
  
  // Target is DraggableTaskTask
  if (!targetTask) {
    return;
  }
  // console.log("Target Task: ", targetTask);

  const primarySourceIndex = tasks.indexOf(sourceTask);
  const targetIndex = tasks.indexOf(targetTask);
  
  // console.log("Index of source: ", primarySourceIndex, " Index of target: ", targetIndex);
  if (primarySourceIndex === -1 || targetIndex === -1) {
    return;
  }
  if (primarySourceIndex === targetIndex) {
    return;
  }

  const draggingTask = tasks[primarySourceIndex];

  let sourceIndexes = [
    primarySourceIndex,
  ];

  if (selectedTaskIds && selectedTaskIds.length > 1) {
    // Consider selected items only if the dragging task is part of the group
    if (selectedTaskIds.filter(id => id === draggingTask.ID).length > 0) {
      selectedTaskIds.forEach(id => {
        // dragging task has already been added
        if (id === draggingTask.ID) {
          return;
        }
        const taskFinder = tasks.filter(t => t.ID === id);
        if (taskFinder.length) {
          sourceIndexes.push(tasks.indexOf(taskFinder[0]));
        }
      });
    }  
  }

  sourceIndexes.forEach(si => {
    let taskToUpdate = tasks[si];
    switch (viewType) {
      case ViewType_KanbanAssignment:
        taskToUpdate.AssignmentUserEmail = targetTask.AssignmentUserEmail;
        taskToUpdate.AssignmentUserName = targetTask.AssignmentUserName;
        taskToUpdate.AssignmentUserNameLower = targetTask.AssignmentUserNameLower;
        break;
      case ViewType_KanbanPriority:
        taskToUpdate.Priority = targetTask.Priority;
        break;
      case ViewType_KanbanMilestone:
        taskToUpdate.TaskMilestoneID = targetTask.TaskMilestoneID;
        break;
      case ViewType_KanbanState:
        taskToUpdate.TaskStateID = targetTask.TaskStateID;
        break;
      default:
        break;
    }
    taskToUpdate.IsUpdated = true;
  });
  
  tasks.splice(primarySourceIndex, 1);
  tasks.splice(targetIndex, 0, draggingTask);
  setStateFunc(tasks, false, { });
}

export const HandleMoveTaskOverColumn = (tasks, getStateFunc, setStateFunc) =>
  (sourceTask, selectedTaskIds, targetColumnProperty) => {
  
  if (!sourceTask) {
    return;
  }
  // console.log("Source Task: ", sourceTask);
  
  // Target is KanbanColumn
  if (!targetColumnProperty) {
    return;
  }

  // console.log("Target Column: ", targetColumnProperty);

  let primarySourceIndex = tasks.indexOf(sourceTask);
  // console.log("Index of source: ", primarySourceIndex);
  if (primarySourceIndex === -1) {
    return;
  }

  const draggingTask = tasks[primarySourceIndex];

  let sourceIndexes = [
    primarySourceIndex,
  ];

  if (selectedTaskIds && selectedTaskIds.length > 1) {
    // Consider selected items only if the dragging task is part of the group
    if (selectedTaskIds.filter(id => id === draggingTask.ID).length > 0) {
      selectedTaskIds.forEach(id => {
        // dragging task has already been added
        if (id === draggingTask.ID) {
          return;
        }
        const taskFinder = tasks.filter(t => t.ID === id);
        if (taskFinder.length) {
          sourceIndexes.push(tasks.indexOf(taskFinder[0]));
        }
      });
    }  
  }

  let updateState = false;
  sourceIndexes.forEach(si => {
    // Example: sourceTask["Priority"]
    let taskToUpdate = tasks[si];
    if (taskToUpdate[targetColumnProperty.name] !== targetColumnProperty.value) {
      taskToUpdate[targetColumnProperty.name] = targetColumnProperty.value;
      if (targetColumnProperty.additionalItemProps) {
        targetColumnProperty.additionalItemProps.forEach(prop => {
          taskToUpdate[prop.name] = prop.value;
        });
      }
      taskToUpdate.IsUpdated = true;
      updateState = true;
    }
  });
  
  if (updateState) {
    setStateFunc(tasks, false, { });
  }
}

const clearAllTaskIsDragging = tasks => {
  const draggingTaskFinder = tasks.filter(st => st.IsDragging);
  if (draggingTaskFinder) {
    draggingTaskFinder.forEach(st => st.IsDragging = false);
  }
}

export const HandleAbortMoveTask = (tasks, getStateFunc, setStateFunc) => () => {
  const preMoveTasks = JSON.parse(getStateFunc("PreMoveTasksJson"));
  clearAllTaskIsDragging(preMoveTasks);
  setStateFunc(preMoveTasks, false, { });
}

export const HandleEndMoveTask = (tasks, getStateFunc, setStateFunc, onApiError, onShowDialog) => () => {
  clearAllTaskIsDragging(tasks);

  // Handle "Non members" column - identified when Task.AssignmentUserEmail === ""
  const tasksNeedingAssignment = tasks.filter(t => !t.AssignmentUserEmail);
  if (tasksNeedingAssignment.length) {
    const dialogConfirmed = () => {
      const selectedOption = getStateFunc("DragDropTaskAssignmentOption");
      if (!selectedOption) {
        return;
      }
      onShowDialog(null);
      // console.log(selectedOption);
      tasksNeedingAssignment.forEach(t => {
        t.AssignmentUserEmail = selectedOption.value;
        t.AssignmentUserName = selectedOption.assignmentUserName;
      });
      setStateFunc(tasks, true, { DragDropTaskAssignmentOption: null });
    };
    const dialogClosed = () => {
      onShowDialog(null);
      HandleAbortMoveTask(tasks, getStateFunc, setStateFunc)();
    };

    onShowDialog(
      GetTaskAssignmentDialogDetails(
        tasks[0].OrganizationID,
        tasks[0].ProjectID,
        dialogConfirmed,
        dialogClosed,
        onApiError,
        getStateFunc("DragDropTaskAssignmentOption"),
        DragDropTaskAssignmentOption => setStateFunc(null, false, { DragDropTaskAssignmentOption }),
      )
    );
  } else {
    setStateFunc(tasks, true, { });
  }
}