import React, { Component } from 'react';

import ItemCollectionBase from '../../Components/ItemCollectionBase';

import { SupportOrganizations_Collection } from '../../Model/SupportOrganizations';

class SupportOrganizations extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    this.Collection = new SupportOrganizations_Collection(
      props,
      () => {},
      () => {},
      props.isWorkspace,
      props.isProjects,
      props.indexedOnly,
    );
  }

  render() {
    return (
      <ItemCollectionBase
        {...this.props}

        pageTitle="Support: Organizations"
        contentUri={this.Collection.ContentUri}
        contentUriParams={this.Collection.ContentUriParams}
        collectionName={this.Collection.CollectionName}
        itemsName={this.Collection.ItemsName}
        itemName={this.Collection.ItemName}
        defaultViewType={this.Collection.DefaultViewType}
        
        onGetCollectionFieldsPromise={this.Collection.HandleGetCollectionFieldsPromise}
        onGetHeadCells={this.Collection.HandleGetHeadCells}
        onGetCardGridItems={this.Collection.HandleGetCardGridItems}
        onGetTableRows={this.Collection.HandleGetTableRows}

        loadItemsImmediately
        hideFilterSortDrawer
      />
    );
  }
}

export default SupportOrganizations;