import React from 'react';

import mxConstants from './mxConstants_CustomAdditions';

// import PersonIcon from '@material-ui/icons/Person';
import AssetItemIcon from '@material-ui/icons/Category';
import AssetItemCompletionIcon from '@material-ui/icons/CheckCircle';
import CommitIcon from '@material-ui/icons/Save';
import FormSubmissionIcon from '@material-ui/icons/ViewHeadline';
import FormShareIcon from '@material-ui/icons/Share';
import DocumentUploadIcon from '@material-ui/icons/CloudUpload';
import DocumentFromTemplateIcon from '@material-ui/icons/FileCopy';
import FolderChangeIcon from '@material-ui/icons/Folder';
import DocumentRenameIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import TagsIcon from '@material-ui/icons/Label';
import { TasksIcon } from '../Util/Icons';
// import GestureIcon from '@material-ui/icons/Gesture';
// import ViewWeekIcon from '@material-ui/icons/ViewWeek';

import GetContent_Trigger_AssetItemCompletion from './ProcessElementType_Trigger/AssetItemCompletion';
import GetContent_Trigger_Commit from './ProcessElementType_Trigger/Commit';
import GetContent_Trigger_FormSubmission from './ProcessElementType_Trigger/FormSubmission';
import GetContent_Trigger_UserUpload from './ProcessElementType_Trigger/UserUpload';
import GetContent_Trigger_TaskCompletion from './ProcessElementType_Trigger/TaskCompletion';
import GetContent_Trigger_NewAssetItem from './ProcessElementType_Trigger/NewAssetItem';
import GetContent_Trigger_TagsUpdate from './ProcessElementType_Trigger/TagsUpdate';

import GetContent_Action_Approval from './ProcessElementType_Action/Approval';
import GetContent_Action_GroupApproval from './ProcessElementType_Action/GroupApproval';
import GetContent_Action_Email from './ProcessElementType_Action/Email';
import GetContent_Action_Task from './ProcessElementType_Action/Task';
import GetContent_Action_AssetItem from './ProcessElementType_Action/AssetItem';
import GetContent_Action_FormShare from './ProcessElementType_Action/FormShare';
import GetContent_Action_DocumentFromTemplate from './ProcessElementType_Action/DocumentFromTemplate';
import GetContent_Action_FolderChange from './ProcessElementType_Action/FolderChange';
import GetContent_Action_DocumentRename from './ProcessElementType_Action/DocumentRename';

const customRequirementFunctionForFields = (designer, element, pointSynonyms) => {
	if (!element.JsonData) {
    return true;
  }
  const data = JSON.parse(element.JsonData);
	if (data["MultiSelectValues_FieldID"] && data["MultiSelectValues_FieldID"].length) {
    if (!designer.isProcessElementJsonDataValid(element, ["MultiSelectValues_FieldID"])) {
      return false;
    }
    for (let i = 0; i < data["MultiSelectValues_FieldID"].length; i++) {
    	const fieldId = data["MultiSelectValues_FieldID"][i];
    	switch (data[`fieldOperator_${fieldId}`]) {
    		case "betweenAndIncluding":
    			if (!data[`fieldPrimary_${fieldId}`]
    				|| !data[`fieldSecondary_${fieldId}`]) {
		    		return false;
		    	}
    			if (data[`fieldPrimary_${fieldId}`].length === 0
    				|| data[`fieldSecondary_${fieldId}`].length === 0) {
		    		return false;
		    	}
    			break;
				case "hasValue":
				case "notHasValue":
					break;
    		default:
	    		if (!data[`fieldPrimary_${fieldId}`]
	    			|| data[`fieldPrimary_${fieldId}`].length === 0) {
		    		return false;
		    	}
    			break;
    	}
    }
  }
  return true;
}

const ProcessElementSubtypes = [
	// {
	//  ProcessElementType: "Trigger",
	//  ProcessElementSubtype: "Manual",
	// 	Name: "Manual Trigger",
	// 	Icon: (<PersonIcon />),
	// 	VertexShapeAndStyle: mxConstants.SHAPE_TRIGGER,
	// 	Width: 120,
	// 	Height: 60,
	// },
	{
		ProcessElementType: "Trigger",
		ProcessElementSubtype: "AssetItemCompletion",
		Name: "Asset completion",
		Icon: (<AssetItemCompletionIcon />),
		VertexShapeAndStyle: mxConstants.SHAPE_TRIGGER,
		Width: 120,
		Height: 60,
		DialogWidth: "sm",
		GetContentFunction: GetContent_Trigger_AssetItemCompletion,
		RequiredJsonKeys: [
		],
	},
	{
		ProcessElementType: "Trigger",
		ProcessElementSubtype: "Commit",
		Name: "Document\nfields commit",
		Icon: (<CommitIcon />),
		VertexShapeAndStyle: mxConstants.SHAPE_TRIGGER,
		Width: 120,
		Height: 60,
		DialogWidth: "md",
		GetContentFunction: GetContent_Trigger_Commit,
		RequiredJsonKeys: [
		],
		CustomRequirementFunction: customRequirementFunctionForFields,
	},
	{
		ProcessElementType: "Trigger",
		ProcessElementSubtype: "Capture",
		Name: "Document upload",
		Icon: (<DocumentUploadIcon />),
		VertexShapeAndStyle: mxConstants.SHAPE_TRIGGER,
		Width: 120,
		Height: 60,
		DialogWidth: "sm",
		GetContentFunction: GetContent_Trigger_UserUpload,
		RequiredJsonKeys: [
		],
	},
	{
		ProcessElementType: "Trigger",
		ProcessElementSubtype: "FormSubmission",
		Name: "Form submit",
		Icon: (<FormSubmissionIcon />),
		VertexShapeAndStyle: mxConstants.SHAPE_TRIGGER,
		Width: 120,
		Height: 60,
		DialogWidth: "sm",
		GetContentFunction: GetContent_Trigger_FormSubmission,
		RequiredJsonKeys: [
		],
	},
	{
		ProcessElementType: "Trigger",
		ProcessElementSubtype: "NewAssetItem",
		Name: "New asset",
		Icon: (<AssetItemIcon />),
		VertexShapeAndStyle: mxConstants.SHAPE_TRIGGER,
		Width: 120,
		Height: 60,
		DialogWidth: "md",
		GetContentFunction: GetContent_Trigger_NewAssetItem,
		RequiredJsonKeys: [
		],
		InvalidConnectionsByPropertiesCheckFunction: (designer, element, targetElement) => {
			if (!element.JsonData || !targetElement.JsonData) {
		    return true;
		  }
		  const targetSubtype = GetProcessElementSubtypeByProcessElement(targetElement);
		  // Invalid if target Subtype is AssetItem and AssetID is identical
		  if (targetSubtype.ProcessElementSubtype === "AssetItem") {
		  	const sourceData = JSON.parse(element.JsonData);
			  const targetData = JSON.parse(targetElement.JsonData);
			  const sourceAssetIds = sourceData["MultiSelectValues_AssetID"];
		    const targetAssetId = targetData["SingleSelectValue_AssetID"];
		    if (targetAssetId && sourceAssetIds && sourceAssetIds.length) {
		    	let foundMatch = false;
			    sourceAssetIds.forEach(sourceAssetId => {
			    	if (sourceAssetId && targetAssetId && sourceAssetId === targetAssetId) {
				    	foundMatch = true;
				    }
			    })
			    if (foundMatch) {
			    	return false;
			    }
			  }
		  }
		  
		  return true;
		},
		CustomRequirementFunction: customRequirementFunctionForFields,
	},
	{
		ProcessElementType: "Trigger",
		ProcessElementSubtype: "TagsUpdate",
		Name: "Tags update",
		Icon: (<TagsIcon />),
		VertexShapeAndStyle: mxConstants.SHAPE_TRIGGER,
		Width: 120,
		Height: 60,
		DialogWidth: "md",
		GetContentFunction: GetContent_Trigger_TagsUpdate,
		RequiredJsonKeys: [
		],
	},
	{
		ProcessElementType: "Trigger",
		ProcessElementSubtype: "TaskCompletion",
		Name: "Task completion",
		Icon: (<TasksIcon />),
		VertexShapeAndStyle: mxConstants.SHAPE_TRIGGER,
		Width: 120,
		Height: 60,
		DialogWidth: "sm",
		GetContentFunction: GetContent_Trigger_TaskCompletion,
		RequiredJsonKeys: [
		],
	},
	{
		ProcessElementType: "Action",
		ProcessElementSubtype: "Approval",
		Name: "Approval",
		Icon: (<ThumbUpIcon />),
		VertexShapeAndStyle: mxConstants.SHAPE_ACTION_APPROVAL,
		Width: 160,
		Height: 80,
		DialogWidth: "sm",
		GetContentFunction: GetContent_Action_Approval,
		RequiredJsonKeys: [
			"SingleSelectValue_AssignmentUserEmail",
		],
	},
	{
		ProcessElementType: "Action",
		ProcessElementSubtype: "GroupApproval",
		Name: "Group Approval",
		Icon: (<ThumbsUpDownIcon />),
		VertexShapeAndStyle: mxConstants.SHAPE_ACTION_APPROVAL,
		Width: 160,
		Height: 80,
		DialogWidth: "sm",
		GetContentFunction: GetContent_Action_GroupApproval,
		RequiredJsonKeys: [
			"MultiSelectValues_AssignmentUserEmail",
		],
	},
	{
		ProcessElementType: "Action",
		ProcessElementSubtype: "AssetItem",
		Name: "Asset",
		Icon: (<AssetItemIcon />),
		VertexShapeAndStyle: mxConstants.SHAPE_ACTION_GENERAL,
		Width: 120,
		Height: 60,
		DialogWidth: "md",
		DisallowedSourceConnectionsBySubtype: ["Task","TaskCompletion"],
		GetContentFunction: GetContent_Action_AssetItem,
		RequiredJsonKeys: [
			"SingleSelectValue_AssetID",
		],
		InvalidConnectionsByPropertiesCheckFunction: (designer, element, targetElement) => {
			if (!element.JsonData || !targetElement.JsonData) {
		    return true;
		  }
		  const targetSubtype = GetProcessElementSubtypeByProcessElement(targetElement);
		  // Invalid if target Subtype is AssetItem and AssetID is identical
		  if (targetSubtype.ProcessElementSubtype === "AssetItem") {
		  	const sourceData = JSON.parse(element.JsonData);
			  const targetData = JSON.parse(targetElement.JsonData);
			  const sourceAssetId = sourceData["SingleSelectValue_AssetID"];
		    const targetAssetId = targetData["SingleSelectValue_AssetID"];
		    if (sourceAssetId && targetAssetId && sourceAssetId === targetAssetId) {
		    	return false;
		    }
		  }
		  
		  return true;
		},
	},
	{
		ProcessElementType: "Action",
		ProcessElementSubtype: "DocumentFromTemplate",
		Name: "Document\nfrom template",
		Icon: (<DocumentFromTemplateIcon />),
		VertexShapeAndStyle: mxConstants.SHAPE_ACTION_GENERAL,
		Width: 120,
		Height: 60,
		DialogWidth: "md",
		GetContentFunction: GetContent_Action_DocumentFromTemplate,
		RequiredJsonKeys: [
			"TemplateDocument",
		],
	},
	{
		ProcessElementType: "Action",
		ProcessElementSubtype: "DocumentRename",
		Name: "Document rename",
		Icon: (<DocumentRenameIcon />),
		VertexShapeAndStyle: mxConstants.SHAPE_ACTION_GENERAL,
		Width: 120,
		Height: 60,
		DialogWidth: "md",
		GetContentFunction: GetContent_Action_DocumentRename,
		RequiredJsonKeys: [],
	},
	{
		ProcessElementType: "Action",
		ProcessElementSubtype: "Email",
		Name: "E-mail",
		Icon: (<EmailIcon />),
		VertexShapeAndStyle: mxConstants.SHAPE_ACTION_NOTIFICATION_INPUTONLY,
		Width: 120,
		Height: 60,
		DialogWidth: "md",
		GetContentFunction: GetContent_Action_Email,
		RequiredJsonKeys: [
			"Subject",
		],
		CustomRequirementFunction: (designer, element, pointSynonyms) => {
			const model = designer.graph.getModel();
			const vertex = model.getCell(element.ID);
			if (!vertex) {
				return true;
			}
      if (!vertex.edges || vertex.edges.length === 0) {
        if (!designer.isProcessElementJsonDataValid(element, ["MultiSelectValues_To"])) {
          return false;
        }
      } else {
        for (let edge of vertex.edges) {
          const exitPoint = designer.getExitPointFromCellStyleKey(edge.style);
          // If the exit point to this vertex is not a notification port,
          // check for the existence of MultiSelectValues_To.
          if (!designer.doPointsMatch(exitPoint, pointSynonyms.notificationsPoint)
          	&& !designer.doPointsMatch(exitPoint, pointSynonyms.deadlineNotificationsPoint)) {
            if (!designer.isProcessElementJsonDataValid(element, ["MultiSelectValues_To"])) {
              return false;
            }
          }
        };
      }
      return true;
		},
	},
	{
		ProcessElementType: "Action",
		ProcessElementSubtype: "FolderChange",
		Name: "Folder change",
		Icon: (<FolderChangeIcon />),
		VertexShapeAndStyle: mxConstants.SHAPE_ACTION_GENERAL,
		Width: 120,
		Height: 60,
		DialogWidth: "md",
		GetContentFunction: GetContent_Action_FolderChange,
		RequiredJsonKeys: [],
	},
	{
		ProcessElementType: "Action",
		ProcessElementSubtype: "FormShare",
		Name: "Form share",
		Icon: (<FormShareIcon />),
		VertexShapeAndStyle: mxConstants.SHAPE_ACTION_GENERAL,
		Width: 120,
		Height: 60,
		DialogWidth: "md",
		GetContentFunction: GetContent_Action_FormShare,
		RequiredJsonKeys: [
			"SingleSelectValue_FormTemplateID",
			"MultiSelectValues_Recipients",
		],
	},
	{
		ProcessElementType: "Action",
		ProcessElementSubtype: "Task",
		Name: "Task",
		Icon: (<TasksIcon />),
		VertexShapeAndStyle: mxConstants.SHAPE_ACTION_TASK,
		Width: 120,
		Height: 60,
		DialogWidth: "lg",
		GetContentFunction: GetContent_Action_Task,
		RequiredJsonKeys: [
			"SingleSelectValue_AssignmentUserEmail",
		],
	},
	// {
	//  ProcessElementType: "Action",
	//  ProcessElementSubtype: "Kanban",
	// 	Name: "Kanban",
	// 	Icon: (<ViewWeekIcon />),
	// 	VertexShapeAndStyle: mxConstants.SHAPE_ACTION_GENERAL,
	// 	Width: 120,
	// 	Height: 60,
	// },
	// {
	//  ProcessElementType: "Action",
	//  ProcessElementSubtype: "Manual",
	// 	Name: "Manual Task",
	// 	Icon: (<PersonIcon />),
	// 	VertexShapeAndStyle: mxConstants.SHAPE_ACTION_GENERAL,
	// 	Width: 120,
	// 	Height: 60,
	// },
	// {
	//  ProcessElementType: "Action",
	//  ProcessElementSubtype: "Signature",
	// 	Name: "Signature",
	// 	Icon: (<GestureIcon />),
	// 	VertexShapeAndStyle: mxConstants.SHAPE_ACTION_GENERAL,
	// 	Width: 120,
	// 	Height: 60,
	// },
];

export function GetProcessElementSubtypeByProcessElement(processElement) {
	return ProcessElementSubtypes
		.filter(subtype => 
			subtype.ProcessElementType === processElement.Type
			&& subtype.ProcessElementSubtype === processElement.Subtype)[0];
}

export default ProcessElementSubtypes;
