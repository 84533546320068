import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import UiCore from '../Components/UiCore';
import { GlobalContext } from '../Context/Global.context';

import { GetWorkspaceApprovalsPath } from '../Util/PathHelper';
import { 
  ApproveApprovals,
  DeclineApprovals,
} from '../Util/Approvals';

const styles = theme => ({
});


class ApprovalActionProcessor extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      ShowProgressIndicatorImmediately:true,
    }
  }

  tryCloseWindowOrRedirectToApprovals = () => {
    this.setState({ShowProgressIndicatorImmediately: false});
    window.close();
    this.props.history.replace(GetWorkspaceApprovalsPath());
  }

  handleApiError = err => {
    if (err.response === undefined) {
      err = {
        response: {
          status: 500,
          data: {
            message: err.toString(),
          },
        },
      };
    }
    err.closeCallback = this.tryCloseWindowOrRedirectToApprovals;
    this.setState({ ApiError: err, ShowProgressIndicatorImmediately: false });
  }

  getApprovalId() {
    let approvalID = this.props.match.params.approvalID;
    if (!approvalID) {
      this.handleApiError("The approval could not be found or has expired.");
      return null;
    }
    return approvalID;
  }

  getBasicApproval = () => {
    return {
      OrganizationID: this.props.match.params.organizationID,
      ProjectID: this.props.match.params.projectID,
      ID: this.getApprovalId(),
    };
  }

  handleAction = isProjectMember => {
    let actionType = this.props.match.params.actionType;
    if (!actionType) {
      this.handleApiError("An action is required.");
      return;
    }
    switch (actionType) {
      case "approve":
        ApproveApprovals([this.getBasicApproval()], isProjectMember)
          .then(() => {
            this.tryCloseWindowOrRedirectToApprovals();
          })
          .catch(this.handleApiError);
      break;
      case "decline":
        DeclineApprovals([this.getBasicApproval()], isProjectMember)
          .then(() => {
            this.tryCloseWindowOrRedirectToApprovals();
          })
          .catch(this.handleApiError);
      break;
      case "reviewAll":
        this.props.history.replace(GetWorkspaceApprovalsPath());
      break;
      default:
        this.handleApiError("An invalid action was provided.");
      break;
    }
  }

  componentDidMount() {
    const organizationID = this.props.match.params.organizationID;
    if (!organizationID) {
      this.handleApiError("Invalid or missing organization");
    }
    const projectID = this.props.match.params.projectID;
    if (!projectID) {
      this.handleApiError("Invalid or missing project");
    }
    this.context.GetProjectMembershipPackages(organizationID)
      .then(projectMembershipPackages => {
        const projectMemberPkgFinder = projectMembershipPackages.filter(p => p.Project.ID === projectID);
        const isProjectMember = projectMemberPkgFinder.length > 0;
        // Switch to the project provided
        this.context.SaveUserPreferences_ActiveOrganization(organizationID)
          .then(() => {
            this.handleAction(isProjectMember);
          })
      })
      .catch(this.handleApiError);
  }

  render() {
    const {
      ApiError,
      Alert,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      theme,
    } = this.props;

    return (
      <UiCore title="Approvals"
        alert={Alert}
        content={
          <Typography variant="h6" style={{padding:theme.spacing(3)}}>
            Please wait while we process your request...
          </Typography>
        }
        hideNavDrawer
        apiError={ApiError}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
      />
    );
  }
}

export default withStyles(styles, {withTheme: true})(ApprovalActionProcessor);
