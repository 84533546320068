import React from 'react';
import TableCell from '@material-ui/core/TableCell';

const compareHeadCells = (a, b) => {
  if (a.label < b.label) {
    return -1;
  }
};

export const GetPreviewMetadataHeadCells = (sensitiveFields, items) => {
  let metadataHeadCells = [];
  if (items) {
    items.forEach((i, index) => {
      if (i.PreviewMetadata) {
        i.PreviewMetadata.forEach((field, index) => {
          if (metadataHeadCells.filter(c => c.id === field[0]).length === 0) {
            if (!sensitiveFields || sensitiveFields.filter(sf => sf.ID === field[0]).length === 0) {
              metadataHeadCells.push({
                isMeta: true,
                id: field[0],
                numeric: false,
                label: field[1],
              });
            }
          }
        });
      }
    });
  }
  metadataHeadCells.sort(compareHeadCells);
  return metadataHeadCells;
}

export const GetPreviewMetadataTableCells = (item, headCells, classes) => {
  let metadataTableCells = [];
  headCells.filter(hc => hc.isMeta).forEach((cell, index) => {
    metadataTableCells.push(
      <TableCell className={classes.metadataCell} key={`meta${index}`}>{
        (item.PreviewMetadata && item.PreviewMetadata.filter(m => m[0] === cell.id).length > 0)
        ? item.PreviewMetadata.filter(m => m[0] === cell.id)[0][2]
        : ""
      }</TableCell>
    );
  });
  return metadataTableCells;
}