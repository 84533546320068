import React, { Component } from 'react';

import UiCore from '../Components/UiCore';
import { GlobalContext } from '../Context/Global.context';

class OrganizationProjectRedirect extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      ShowProgressIndicatorImmediately:true,
    }
  }

  handleApiError = err => {
    if (typeof err === "string") {
      err = { response: { data: { message: err }}};
    }
    err.closeCallback = this.tryCloseWindowOrRedirectToProjects;
    this.setState({ ApiError: err, ShowProgressIndicatorImmediately: false });
  }

  tryCloseWindowOrRedirectToProjects = () => {
    this.setState({ShowProgressIndicatorImmediately: false});
    window.close();
    this.props.history.replace("/projects");
  }

  componentDidMount() {
    let organizationID = this.props.match.params.organizationID;
    if (!organizationID) {
      return this.handleApiError("Invalid or missing organization");
    }
    let projectID = this.props.match.params.projectID;
    if (!projectID) {
      return this.handleApiError("Invalid or missing project");
    }
    // Switch to the organization provided
    this.context.SaveUserPreferences_ActiveOrganization(organizationID)
      .then(() => {
        this.context.SaveUserPreferences_ActiveProject(projectID);
        return this.context.GetOrganizationMembershipPackages()
          .then(() => {
            this.props.history.replace(`/projects/${projectID}`);
          });
      })
      .catch(this.handleApiError);
  }

  render() {
    const {
      ApiError,
      Alert,
      ShowProgressIndicatorImmediately,
    } = this.state;

    return (
      <div>
        <UiCore title="Project Access"
          apiError={ApiError}
          alert={Alert}
          showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
        />
      </div>
    );
  }
}

export default OrganizationProjectRedirect;
