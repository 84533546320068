import { Component } from 'react';

import { GlobalContext } from '../../Context/Global.context';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';

import RenameIcon from '@material-ui/icons/Edit';

import Collection from '../../Model/Collection';
import { OrganizationProfile_Collection } from '../../Model/OrganizationProfile';
import { OrganizationMembers_Collection } from '../../Model/OrganizationMembers';
import { OrganizationSubscription_Collection } from '../../Model/OrganizationSubscription';

import {
  GetOrganizationProfileNode,
  GetOrganizationMembersNode,
  GetOrganizationSubscriptionNode,
} from '../../Util/Nodes';
import {
  GetOrganizationsPathForApi,
} from '../../Util/api';
import {
  GetOrganizationManagementPath,
  GetOrganizationMembersPath,
} from '../../Util/PathHelper';

import ItemCollectionBase from '../../Components/ItemCollectionBase';
import MultiUseDialog from '../../Components/MultiUseDialog';

import {
  GetTreeNodeComponent,
  TreeComponentDidUpdate,
} from '../../Util/Tree';
import {
  GetIconById,
} from '../../Util/Icons';
import {
  HandleSetShowRenameOrganizationDialog,
  GetRenameOrganizationDialog,
} from '../../Util/Organizations';

import API from '../../Util/api';

import { IsMobile } from '../../Util/MobileDetector';

const OrganizationRootNodeType = "organizationRoot";

class OrganizationManagement extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.state = {
      RootNodes: [],
      CollectionNodes: [],
      SelectedNode: null,
      ForcePrependItems: [],
      CollectionUpdateID: null,
      ContextMenuNode: null,
      ShowAddDialog: false,
      ShowDialogProgressIndicatorImmediately: false,
    };

    this.Collection = new Collection(this.props, state => this.setState(state), this.handleApiError);
  }

  componentDidMount() {
    this.setCollection(this.props.match.params.collectionID);
    this.setRootNodes();
  }

  componentDidUpdate(prevProps) {
    const allowPathCheck = this.props.match.params.collectionID !== prevProps.match.params.collectionID
      || this.Collection.updateProps(this.props);
    if (allowPathCheck) {
      TreeComponentDidUpdate(prevProps.location, this.props.location, state => this.setState(state));
      if (this.props.match.params.organizationID !== prevProps.match.params.organizationID) {
        this.setCollection(this.props.match.params.collectionID);
        this.setRootNodes();
      } else if (this.props.match.params.collectionID !== prevProps.match.params.collectionID) {
        this.setCollection(this.props.match.params.collectionID);
        this.ensureSelectedNode();
      }
    }
  }

  setCollection = collectionId => {
    // This is important as it will catch 401s, which when passed to UiCore will redirect to /login.
    if (this.context && this.context.ApiError) {
      // Special case: When 402 (subscription expired), ignore error so user can navigate to resolve expiration
      if (!this.context.ApiError.response || this.context.ApiError.response.status !== 402) {
        this.setState({ApiError:this.context.ApiError});
        return;
      }
    }
    // Delay until we have UserPreferences
    if (!this.context.CompletedGET.UserPreferences) {
      setTimeout(() => this.setCollection(collectionId), 250);
      return;
    }
    let c;
    if (collectionId) {
      switch (collectionId) {
        case "profile":
          c = new OrganizationProfile_Collection(this.props, state => this.setState(state), this.handleApiError, 
            this.props.match.params.organizationID, this.handleAlert);
          break;
        case "members":
          c = new OrganizationMembers_Collection(this.props, this.context, state => this.setState(state), this.handleApiError, 
            this.props.match.params.organizationID, this.context.UserPreferences.UserEmail);
          break;
        case "subscription":
          c = new OrganizationSubscription_Collection(this.props, state => this.setState(state), this.handleApiError,
            this.props.match.params.organizationID, this.handleAlert);
          break;
        default:
          break;
      }
    }
    if (c) {
      this.Collection = c;
    }
    // This ensures ItemCollectionBase always sees the new collection
    this.setState({CollectionUpdateID: new Date()});
  }

  ensureSelectedNode = () => {
    if (this.state.SelectedNode && this.state.SelectedNode.Url !== this.props.location.pathname) {
      for (let i = 0; i < this.state.RootNodes.length; i++) {
        const rootNode = this.state.RootNodes[i];
        if (!rootNode.ChildNodes) {
          continue;
        }
        for (let j = 0; j < rootNode.ChildNodes.length; j++) {
          const childNode = rootNode.ChildNodes[j];
          if (this.props.location.pathname.startsWith(childNode.Url)) {
            this.setState({ SelectedNode: childNode });
          }
        }
      }
    }
  }

  handleApiError = ApiError => {
    this.setState({
      ApiError,
      ShowDialogProgressIndicatorImmediately: false,
    });
    if (ApiError) {
      setTimeout(() => this.handleApiError(null), 1);
    }
  }

  handleAlert = Alert => {
    this.setState({ Alert });
  }

  setRootNodes = () => {
    // Delay until we have OrganizationMembershipPackages
    if (!this.context.CompletedGET.OrganizationMembershipPackages) {
      setTimeout(() => this.setRootNodes(), 250);
      return;
    }
    const optionalSelectedOrganizationId = this.props.match.params.organizationID;
    const NodeType = OrganizationRootNodeType;
    let RootNodes = [];
    this.context.OrganizationMembershipPackages
      .filter(op => op.IsAdmin)
      .forEach((op, ItemIndex) => {
        const OrganizationID = op.Organization.ID;
        const UniqueId = `${NodeType}_${OrganizationID}_${ItemIndex.toString()}`;
        const Open = (optionalSelectedOrganizationId) ? optionalSelectedOrganizationId === OrganizationID : ItemIndex === 0;
        const node = {
          NodeType,
          CustomIconID: "organizations",
          Name: op.Organization.Name,
          OrganizationID,
          Organization:op.Organization,
          IsRoot: true,
          RootId: UniqueId,
          UniqueId,
          ItemIndex,
          HasChildren: true,
          NoSelect: true,
          Open,
        };
        RootNodes.push(node);
      });
    this.setState({ RootNodes });
  }

  handleGetAndSetCollectionNodesPromise = (ParentNode, disallowChildSelectOnLoad, isGetMore) => {
    let itemIndex = 0;
    const getNode = baseNode => {
      const Url = `${GetOrganizationManagementPath()}/${ParentNode.OrganizationID}${baseNode.PathElement}`;
      const optionalSelectedCollectionItemId = this.props.match.params.collectionItemID;
      const collectionNode = {
        ...baseNode,
        RootId: ParentNode.RootId,
        UniqueId: `${ParentNode.OrganizationID}_${baseNode.UniqueId}`,
        ParentNode,
        HasChildren: false,
        SelectOnMount: itemIndex === 0
          && !disallowChildSelectOnLoad
          && !this.props.match.url.startsWith(ParentNode.Url)
          && !optionalSelectedCollectionItemId,
        ItemIndex: itemIndex++,
        Url,
      };
      // Ensure this node is selected (for breadcrumbs) if a child of this node is active
      if (optionalSelectedCollectionItemId && this.props.match.url.startsWith(Url)) {
        this.setState({SelectedNode:collectionNode});
      }
      return collectionNode;
    }
    const CollectionNodes = [
      getNode({...GetOrganizationProfileNode()}),
      getNode({...GetOrganizationMembersNode()}),
      // getNode({...GetOrganizationProjectsNode()}),
      getNode({...GetOrganizationSubscriptionNode()}),
    ];
    ParentNode.ChildNodes = CollectionNodes;
    // CollectionNodes in state are for tabs/mobile view
    this.setState({CollectionNodes});
    return Promise.resolve(CollectionNodes);
  }

  handleSetAddDialogVisibility = visible => {
    this.setState({ShowAddDialog: visible});
  }

  handleAddOrganization = Name => {
    if (!Name)
      return;
    
    this.setState({ShowDialogProgressIndicatorImmediately: true});
    const newOrganization = {
      Name,
    };
    API.post(GetOrganizationsPathForApi(), [newOrganization])
      .then(resp => {
        const newOrganizationFromServer = resp.data[0];
        this.setState({
          ShowDialogProgressIndicatorImmediately: false,
        });
        this.handleSetAddDialogVisibility(false);
        return this.context.GetOrganizationMembershipPackages()
          .then(() => {
            return this.context.SaveUserPreferences_ActiveOrganization(newOrganizationFromServer.ID)
            .then(() => {
              this.context.Reset();
              this.props.history.push(GetOrganizationMembersPath(newOrganizationFromServer.ID));
            });
          });
      })
      .catch(this.handleApiError);
  }

  handleOrgNameUpdated = (organizationId, name) => {
    const RootNodes = [...this.state.RootNodes];
    const rootNodeFinder = RootNodes.filter(n => n.OrganizationID === organizationId);
    if (rootNodeFinder.length) {
      rootNodeFinder[0].Name = name;
      this.setState({ RootNodes });
    }
  }

  handleTabChange = (e, tabValue) => {
    this.props.history.push(tabValue);
  }

  GetContextMenu = (node, mouseX, mouseY, onClose) => {
    if (node.NodeType !== OrganizationRootNodeType) {
      return;
    }

    return (
      <Menu
        keepMounted
        open={mouseY !== null}
        onClose={onClose}
        anchorReference="anchorPosition"
        anchorPosition={
          mouseY !== null && mouseX !== null
            ? { top: mouseY, left: mouseX }
            : undefined
        }
      >
        <MenuItem onClick={e => this.handleContextMenuAction(e, node, "rename", onClose)}>
          <ListItemIcon>
            <RenameIcon />
          </ListItemIcon>
          Rename
        </MenuItem>
      </Menu>
    );
  }

  handleContextMenuAction = (e, ContextMenuNode, actionType, onClose) => {
    e.stopPropagation();
    onClose();

    switch (ContextMenuNode.NodeType) {
      case OrganizationRootNodeType:
        switch (actionType) {
          case "rename":
            HandleSetShowRenameOrganizationDialog(state => this.setState(state), true, {
              ContextMenuNode,
            });
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  render() {
    const {
      ApiError,
      Alert,
      RootNodes,
      CollectionNodes,
      SelectedNode,
      ForcePrependItems,
      ContextMenuNode,
      ShowAddDialog,
      ShowDialogProgressIndicatorImmediately,
    } = this.state;
    const {
      organizationID,
    } = this.props.match.params;
    const {
      CompletedGET,
      OrganizationMembershipPackages,
    } = this.context;

    let pageTitle = "Organization Management";
    if (IsMobile() && CompletedGET.OrganizationMembershipPackages) {
      const orgFinder = OrganizationMembershipPackages.filter(op => op.Organization.ID === organizationID);
      if (orgFinder.length) {
        pageTitle = orgFinder[0].Organization.Name;
      }
    }

    const onGetTreeNodeComponent = (parentNode, onGetMoreNodes, onGetChildNodesPromise, onGetChildTreeNodeComponent) => 
      GetTreeNodeComponent(this.props, this.state, state => this.setState(state), 
        parentNode,
        onGetMoreNodes,
        onGetChildNodesPromise,
        onGetChildTreeNodeComponent,
        this.GetContextMenu,
      );

    const childNodeComponents = RootNodes.map(rootNode => {
      return onGetTreeNodeComponent( 
        rootNode,
        () => {},
        this.handleGetAndSetCollectionNodesPromise,
        collectionNode => onGetTreeNodeComponent(
          collectionNode,
          () => {},
          null,
          () => {},
        ),
      );
    });

    const addDialogDetails = {
      Open:ShowAddDialog,
      ShowProgressIndicatorImmediately:ShowDialogProgressIndicatorImmediately,
      Title:"Add organization",
      RequireTextInput1:true,
      TextInput1Label:"Name",
      CancelCallback:() => this.handleSetAddDialogVisibility(false),
      CloseCallback:() => this.handleSetAddDialogVisibility(false),
      ConfirmLabel:"ADD",
      ConfirmCallback:this.handleAddOrganization,
    };

    const renameDialog = GetRenameOrganizationDialog(this.context, this.state, state => this.setState(state),
      this.handleApiError,
      this.handleOrgNameUpdated,
      ContextMenuNode && ContextMenuNode.Organization,
      ContextMenuNode && ContextMenuNode.Name);

    const leftPaneContent = (
      <div>
        {childNodeComponents}
        
        <Tooltip title="Add organization">
          <IconButton aria-label="Add organization"
            style={{
              marginLeft:-8,
              marginTop:8,
            }}
            onClick={() => this.handleSetAddDialogVisibility(true)}>
            <AddIcon />
          </IconButton>
        </Tooltip>

        <MultiUseDialog Details={addDialogDetails} />
        {renameDialog}
      </div>
    );

    const currentUrlHasNode = CollectionNodes.filter(n => this.props.match.url.startsWith(n.Url)).length;
    const secondaryNavTabs = (IsMobile() && CollectionNodes && currentUrlHasNode) ? (
      <Tabs
        variant="scrollable"
        value={this.props.match.url}
        onChange={this.handleTabChange}
      >
        {
          CollectionNodes.map((n, index) => {
            // This sets the tab value to the extended URL to ensure there is a matching value for the Tabs component
            const tabValue = (this.props.match.url.startsWith(n.Url))
              ? this.props.match.url
              : n.Url;
            return (
              <Tab
                key={n.UniqueId} 
                label={n.Name}
                value={tabValue}
                icon={GetIconById(n.CustomIconID)}
                style={{
                  marginLeft:(index === 0) ? 56 : undefined,
                }}
              />
            );
          })
        }
      </Tabs>
    ) : null;

    return (
      <ItemCollectionBase
        {...this.props}
        
        passThroughComponent={this.Collection.PassThroughComponent}
        pageTitle={pageTitle}
        contentUri={this.Collection.ContentUri}
        collectionName={this.Collection.CollectionName}
        itemsName={this.Collection.ItemsName}
        itemName={this.Collection.ItemName}
        defaultViewType={this.Collection.DefaultViewType}
        
        onGetCollectionFieldsPromise={this.Collection.HandleGetCollectionFieldsPromise}
        onGetHeadCells={this.Collection.HandleGetHeadCells}
        onGetCardGridItems={this.Collection.HandleGetCardGridItems}
        onGetTableRows={this.Collection.HandleGetTableRows}

        selectedNode={(!IsMobile()) ? SelectedNode : undefined}

        hideSubscriptionExpirationAlert
        hideToolHeader={this.Collection.HideToolHeader}
        hideFilterSortDrawer={this.Collection.HideFilterSortDrawer}
        leftPaneContent={leftPaneContent}
        leftPaneStyle={{display:(IsMobile()) ? "none" : undefined}}
        secondaryNavTabs={secondaryNavTabs}
        showFabWhenLeftPaneVisible

        loadItemsImmediately
        organizationId={organizationID}
        apiError={ApiError}
        alert={Alert}
        onItemsChanged={this.Collection.HandleItemsChanged}
        forcePrependItems={ForcePrependItems}
        includeItemIds={(this.props.match.params.collectionItemID) ? [this.props.match.params.collectionItemID] : undefined}
        allowSelect={this.Collection.AllowSelect}
        canSelectItem={this.Collection.CanSelectItem}
        onFabClick={this.Collection.HandleCreateNew}
        dialogContent={this.Collection.HandleGetDialogContent(this.state)}
        onSetUpdateRevisedItemFunction={this.Collection.HandleSetUpdateRevisedItemFunction}
      />
    );
  }
}

export default OrganizationManagement;