import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import ArrowRight from '@material-ui/icons/ArrowRight';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
// import { fade } from '@material-ui/core/styles/colorManipulator';

import { GetIconById } from '../Util/Icons';

const NodeHeight = 30; // Used to calculate overall tree height
const styles = theme => ({
  outerContainer: {
    overflow:"hidden",
  },
  nodeContainer: {
    color: theme.palette.text.secondary,
    display:"flex",
    paddingTop:3,
    paddingBottom:3,
    height:NodeHeight,
  },
  iconAndName: {
    userSelect: "none",
    display:"flex",
    flexWrap:"nowrap",
    whiteSpace:"nowrap",
    alignItems:"center",
    width:"100%", // Added to extend clickable area for context menu access
  },
  highlighted: {
    backgroundColor: theme.palette.background.treeNodeHighlight,//theme.palette.type === "dark" ? fade("#888", 0.2) : fade(theme.palette.primary.main, 0.2),
    color: theme.palette.secondary.main,
    backgroundOpacity: 0.5,
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
  },
  highlightedShort: {
    marginRight:theme.spacing(5),
  },
  arrow: {
    color:"#666",
  },
  arrowOpened: {
    transform:"rotate(90deg)",
    transition:"transform 250ms",
  },
  arrowClosed: {
    transition:"transform 250ms",
  },
  arrowHidden: {
    visibility:"hidden",
  },
  nodeName: {
    fontSize:12,
    fontWeight:600,
    marginLeft: theme.spacing(1),
  },
  nodeNameSuffix: {
    fontSize:9,
    fontWeight:600,
    marginTop:1,
    marginLeft:theme.spacing(2),
  },
  children: {
    height:0,
    marginLeft: theme.spacing(3),
  },
  childrenOpened: {
    transition:"height 250ms",
  },
  childrenClosed: {
    transition:"height 250ms",
  },
});

class TreeNode extends Component {
  constructor(props) {
    super(props);

    this.initialMouseState = {
      mouseX: null,
      mouseY: null,
    }

    this.state = {
      Open: false,
      ChildNodes: [],
      Highlighted: false,
      ...this.initialMouseState,
    }

    this.LastToggleOpenStateCaller = "";
    this.ChildrenRef = React.createRef();
    this.GetMoreButtonHeight = 0;
    this.ClickTimeout = null;
    this.ChildNodesUniqueId = null;
  }

  handleContextMenuClick = e => {
    e.preventDefault();

    if (!this.props.onGetContextMenu) {
      return;
    }

    if (this.state.mouseY !== null) {
      this.setState(this.initialMouseState);
      return;
    }

    this.setState({
      mouseX: e.clientX - 2,
      mouseY: e.clientY - 4,
    })
  }

  handleContextMenuClose = e => {
    if (e) {
      e.stopPropagation();
    }
    this.setState(this.initialMouseState)
  }

  handleGetChildNodes = (callers, disallowChildSelectOnLoad, isGetMore, useLocalChildren) => {
    // console.log(this.props.node.Name, callers, "handleGetChildNodes isGetMore", isGetMore);
    const uniqueId = new Date();
    this.ChildNodesUniqueId = uniqueId;
    if (this.props.onGetChildNodesPromise) {
      this.props.onGetChildNodesPromise(this.props.node, disallowChildSelectOnLoad, isGetMore, useLocalChildren)
        .then(ChildNodes => {
          // Ensure node is still open and global uniqueId matches this function's uniqueId
          if (this.state.Open && this.ChildNodesUniqueId === uniqueId) {
            this.setState({ ChildNodes });
            this.setHeight([...callers, "handleGetChildNodes"], true);
          }
        })
        .catch(err => console.log(err));
    }
  }

  handleToggleOpenState = (caller, forcedValue, skipAppendChildren, disallowChildSelectOnLoad,
    isGetMore, useLocalChildren, scrollIntoView) => {
    // console.log(this.props.node.Name, "handleToggleOpenState caller", caller);
    
    this.LastToggleOpenStateCaller = caller;
    let open;
    if (typeof forcedValue === "boolean") {
      open = forcedValue;
    } else {
      open = !this.state.Open;
    }
    this.setState({Open: open});
    if (open && !skipAppendChildren) {
      this.handleGetChildNodes([caller, "handleToggleOpenState"], disallowChildSelectOnLoad, isGetMore, useLocalChildren);
      if (scrollIntoView) {
        this.tryScrollIntoView();
      }
    } else if (!open) {
      this.setHeight([caller, "handleToggleOpenState"], open);
    }
  }

  getChildCountRecursive = element => {
    let childCount = 0;
    const dataChildCount = element.getAttribute("data-childcount");
    childCount += Number(dataChildCount);

    if (element.childElementCount
      && element.childNodes[0].childElementCount)
    {
      for (let i = 0; i < element.childNodes[0].childElementCount; i++) {
        if (element.childNodes[0].children[i].childElementCount === 2) {
          const childElement = element.childNodes[0].children[i].childNodes[1];
          childCount += this.getChildCountRecursive(childElement);
        }
      }
    }
    return childCount;
  }

  setHeight = (callers, callingOpenState) => {
    // console.log(this.props.node.Name, "setHeight callers callingOpenState", callers, callingOpenState);
    let currentElement = this.ChildrenRef.current;
    let elements = [];
    while (currentElement) {
      elements.push(currentElement);

      if (currentElement.parentElement
        && currentElement.parentElement.parentElement
        && currentElement.parentElement.parentElement.parentElement
        && currentElement.parentElement.parentElement.parentElement.getAttribute("data-children")
      ) {
        currentElement = currentElement.parentElement.parentElement.parentElement;
      } else {
        break;
      }
    }

    let carryForwardOffset = null;
    // console.log("elements.length", elements.length);
    for (let i = 0; i < elements.length; i++) {
      // console.log(i);
      const element = elements[i];
      const childCount = this.getChildCountRecursive(element);
      // const immParent = element.parentElement.childNodes[0].childNodes[1];
      // const elementName = immParent.childNodes[immParent.childNodes.length-1].innerHTML;
      // console.log(this.props.node.Name, elementName, "childCount", childCount);
      let preHeight = parseInt(element.style.height); // This is preferred to avoid issues in sizing the parent
      if (!preHeight) {
        preHeight = element.offsetHeight;
      }

      if (callingOpenState) { // Expand
        this.expandElement([...callers, "setHeight"], element, childCount);
      } else { // Collapse
        if (carryForwardOffset === null) {
          carryForwardOffset = element.scrollHeight;
          this.collapseElement(element);
        } else {
          const newHeight = (preHeight - carryForwardOffset);
          // element.setAttribute("data-height", newHeight);
          element.style.height = newHeight.toString() + 'px';
        }
      }
    }
  }

  tryScrollIntoView = () => {
    setTimeout(() => {
      if (this.ChildrenRef && this.ChildrenRef.current && this.ChildrenRef.current.scrollIntoView) {
        this.ChildrenRef.current.scrollIntoView({block: "center"});
      }
    }, 500);
  }

  setNodeHighlighted = scrollIntoView => {
    this.setState({Highlighted: true});
    if (this.props.onSetNodeUnhighlightFunction) {
      this.props.onSetNodeUnhighlightFunction(() => this.setState({Highlighted: false}));
    }
    if (scrollIntoView) {
      this.tryScrollIntoView();
    }
  }

  handleNodeSelect = (forMounting, scrollIntoView) => {
    // console.log("handleNodeSelect", this.props.node.Name);
    if (this.props.node.NoSelect) {
      return;
    }
    if (!this.props.selectedNodeUrl) {
      this.setNodeHighlighted(scrollIntoView);
    }
    if (this.props.onNodeSelect) {
      this.props.onNodeSelect(this.props.node, forMounting);
    }
  }

  handleClicks = e => {
    if (this.ClickTimeout !== null) { // Double Click
      this.ClickTimeout = null;
      clearTimeout(this.ClickTimeout);
      this.handleToggleOpenState("handleClicks");
    } else { // Single Click
      this.ClickTimeout = setTimeout(()=> {
        if (this.ClickTimeout) {
          clearTimeout(this.ClickTimeout);
          this.ClickTimeout = null;
          if (!this.state.Open) {
            this.handleToggleOpenState("handleClicks", true, this.state.Open);
          }
        }
      }, 300);
    }
    // Node is always selected regardless of single/double click
    this.handleNodeSelect();
  }

  collapseElement(element) {
    // const immParent = element.parentElement.childNodes[0].childNodes[1];
    // const elementName = immParent.childNodes[immParent.childNodes.length-1].innerHTML;
    // console.log(this.props.node.Name, "collapseElement", elementName);
    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = element.childNodes[0].scrollHeight;
    // console.log("measured sectionHeight", elementName, sectionHeight);

    // temporarily disable all css transitions
    var elementTransition = element.style.transition;
    element.style.transition = '';
    
    // on the next frame (as soon as the previous style change has taken effect),
    // explicitly set the element's height to its current pixel height, so we 
    // aren't transitioning out of 'auto'
    requestAnimationFrame(function() {
      element.style.height = sectionHeight + 'px';
      element.style.transition = elementTransition;
      
      // on the next frame (as soon as the previous style change has taken effect),
      // have the element transition to height: 0
      requestAnimationFrame(function() {
        element.style.height = 0 + 'px';
      });
    });

    // To ensure any reopen/reload starts from empty / 0 height
    this.setState({ ChildNodes: [], });
  }

  expandElement(callers, element, childCount) {
    // const immParent = element.parentElement.childNodes[0].childNodes[1];
    // const elementName = immParent.childNodes[immParent.childNodes.length-1].innerHTML;
    // console.log(this.props.node.Name, "expandElement elementName callers positiveOffset negativeOffset", 
    //   elementName, callers, positiveOffset, negativeOffset);
    
    if (this.props.node.SelectOnExpand && callers[0] !== "componentDidUpdate") {
      this.handleNodeSelect();
    }
    // get the height of the element's inner content, regardless of its actual size
    // This is preferred to avoid issues in sizing the parent
    // However, it was found later that it doesn't work when inserting/removing new document folder nodes using the menu
    // let height = parseInt(element.style.height);
    // if (!height) {
    // const measuredHeight = element.childNodes[0].scrollHeight;
    // }
    
    // console.log("scroll height", elementName, element.childNodes[0].scrollHeight);
    // console.log(this.props.node.Name, elementName, "measured height", measuredHeight);
    // const localHeight = (forcedHeight) ? forcedHeight + offset : measuredHeight + offset;
    const localHeight = childCount * NodeHeight;
    // console.log(this.props.node.Name, elementName, "final local height", localHeight);

    // have the element transition to the height of its inner content
    element.style.height = localHeight.toString() + 'px';

    if (this.props.onExpand) {
      this.props.onExpand(this.props.node);
    }
    if (this.props.onSetNodeCollapseFunction) {
      this.props.onSetNodeCollapseFunction(this.props.node, this.handleCollapseNode);
    }

    return localHeight;
  }

  handleExpandNode = (skipAppendChildren, disallowChildSelectOnLoad, isGetMore, useLocalChildren, scrollIntoView) => {
    this.handleToggleOpenState("handleExpandNode", true, skipAppendChildren, disallowChildSelectOnLoad, 
      isGetMore, useLocalChildren, scrollIntoView);
  }

  handleCollapseNode = expandedNode => {
    if (expandedNode !== this.props.node) {
      // console.log("handleCollapseNode to handleToggleOpenState", this.props.node.Name);
      this.handleToggleOpenState("handleCollapseNode", false);
    }
  }

  getChildComponents = ChildNodes => {
    return ChildNodes.map(childNode => this.props.onGetChildTreeNodeComponent(
      childNode,
      () => this.handleGetChildNodes(["render"], false, true)
    ));
  }

  componentDidMount() {
    // console.log("componentDidMount", this.props.node.Name);
    if (this.props.onSetNodeHighlightFunction) {
      this.props.onSetNodeHighlightFunction(this.props.node.UniqueId, this.setNodeHighlighted);
    }
    if (this.props.onSetNodeExpandFunction) {
      this.props.onSetNodeExpandFunction(this.props.node, this.handleExpandNode);
    }

    if (this.props.node.SelectOnMount) {
      // console.log("componentDidMount SelectOnMount", this.props.node.Name);
      this.handleNodeSelect(true, true);
    }
    if (this.props.node.Open) {
      // console.log("componentDidMount handleToggleOpenState", this.props.node.Name);
      this.handleToggleOpenState("componentDidMount", true, false, false, false, false, true);
    }
    if (this.props.node.Url && this.props.selectedNodeUrl
      && this.props.node.Url === this.props.selectedNodeUrl) {
      // console.log("componentDidMount selectedNodeUrl", this.props.node.Name);
      this.handleNodeSelect(false, true);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.node.Open === true
      && this.props.node.Open !== prevProps.node.Open
      && this.state.Open !== this.props.node.Open
      && this.LastToggleOpenStateCaller !== "handleNodeSelect") {
      
      // console.log("componentDidUpdate handleToggleOpenState", this.props.node.Name);
      this.handleToggleOpenState("componentDidUpdate", this.props.node.Open, false, true, false, false, true);
    }
  }

  render() {
    const {
      Open,
      ChildNodes,
      Highlighted,
      mouseX,
      mouseY,
    } = this.state;
    const { 
      classes,
      theme,
      node,
      selectedNodeUrl,
      selectedNodeUniqueId,
      shortenSelector,
      onGetContextMenu,
    } = this.props;
    const {
      Name,
      NameSuffix,
      HexColor,
      UniqueId,
      NoSelect,
      HasChildren,
      CustomIconID,
      NoIcon,
      MarginTop,
      Url,
    } = node;

    const isHighlighted =
      (NoSelect)
        ? false
        : (selectedNodeUrl)
          ? Url === selectedNodeUrl
          : (selectedNodeUniqueId)
            ? UniqueId === selectedNodeUniqueId
            : Highlighted;

    // console.log(this.props.node.Name, "TreeNode render");
    const childComponents = this.getChildComponents(ChildNodes);

    const contextMenu = (onGetContextMenu)
      ? onGetContextMenu(node, mouseX, mouseY, this.handleContextMenuClose)
      : null;

    const nameSuffixSpan = (NameSuffix)
      ? ( 
        <Typography className={classes.nodeNameSuffix} component="span">
          {NameSuffix}
        </Typography>
      ) : null;

    return (
      <div className={classes.outerContainer}
        style={{marginTop: MarginTop}}>
        <div className={classNames(
          classes.nodeContainer,
          (isHighlighted) ? classes.highlighted : null,
          (shortenSelector) ? classes.highlightedShort : null,
          )}
          onContextMenu={this.handleContextMenuClick}
        >
          <ArrowRight
            onClick={() => this.handleToggleOpenState("arrowClick")}
            className={classNames(
              classes.arrow,
              (Open) ? classes.arrowOpened : classes.arrowClosed,
              (!HasChildren) ? classes.arrowHidden : null,
            )}
          />
          <div className={classes.iconAndName}
            style={{
              cursor: (!NoSelect) ? "pointer" : "inherit",
              color: HexColor,
            }}
            onClick={this.handleClicks}
          >
            {(NoIcon) ? null : GetIconById(CustomIconID, theme)}
            <Typography className={classes.nodeName}>
              {Name}
            </Typography>
            {nameSuffixSpan}
            {contextMenu}
          </div>
        </div>
        <div ref={this.ChildrenRef}
          data-children
          data-childcount={childComponents.length}
          className={classNames(
            classes.children,
            (Open) ? classes.childrenOpened : classes.childrenClosed,
          )}>
          <div data-childcomponents>
            {childComponents}
          </div>
        </div>
      </div>
    );
  }
}

TreeNode.propTypes = {
  classes: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  onGetChildTreeNodeComponent: PropTypes.func,
  onGetContextMenu: PropTypes.func,
  onExpand: PropTypes.func,
  onSetNodeExpandFunction: PropTypes.func,
  onSetNodeCollapseFunction: PropTypes.func,
  onSetNodeUnhighlightFunction: PropTypes.func,
  onNodeSelect: PropTypes.func,
  selectedNodeUrl: PropTypes.string,
  selectedNodeUniqueId: PropTypes.string,
};

export default withStyles(styles, {withTheme: true})(TreeNode);