export const RankIncrement = 1000;

// Each item in the collection must have an ID property (or idPropertyName) and Rank int property (or rankPropertyName)
// Returns the updated Collection, StartIndex, and EndIndex as an object
export const ReRankItemInCollection = (item, collection, rankIncrement, rankPropertyName, idPropertyName) => {
	if (!rankIncrement) {
		rankIncrement = RankIncrement;
	}
	if (!rankPropertyName) {
		rankPropertyName = "Rank";
	}
  if (!idPropertyName) {
    idPropertyName = "ID";
  }
  // console.log(collection, item);
	const sourceIndex = collection.map(c => c[idPropertyName]).indexOf(item[idPropertyName]);
  let startIndex = sourceIndex;
  let endIndex = sourceIndex;
  let lastStartIndex, lastEndIndex;
  while (true) {
    if (startIndex > 0)
      startIndex--;
    if (endIndex < collection.length - 1)
      endIndex++;

    if (collection.length && startIndex === endIndex) {
      return {
        Collection: collection,
        StartIndex: sourceIndex,
        EndIndex: sourceIndex,
      };
    }
    // console.log("index start ", startIndex, "end ", endIndex);

    // This protects against an endless loop
    let forceRebuild = false;
    let startRank, endRank, increment;
    if (startIndex === lastStartIndex && endIndex === lastEndIndex) {
      // If we're here, we're looking at the entire collection; force a rebuild of everything
      forceRebuild = true;
      startIndex = 0;
      endIndex = collection.length - 1;
      startRank = 0;
      endRank = rankIncrement * (collection.length - 1);
      increment = rankIncrement;
    } else {
      lastStartIndex = startIndex;
      lastEndIndex = endIndex;
      startRank = (startIndex === 0) ? 0 : collection[startIndex][rankPropertyName];
      endRank = (collection.length === 2) ? rankIncrement : collection[endIndex][rankPropertyName];
      increment = Math.abs((endRank - startRank) / 3);
    }
    // console.log("rank start ", startRank, "end ", endRank);

    // If increment is too small, we need to cycle, which will attempt to incorporate additional outside items
    if (!forceRebuild && increment < 1) {
      continue;
    }
    // console.log("increment ", increment);

    let curRank = startRank;
    for (let i = startIndex; i <= endIndex; i++) {
      collection[i][rankPropertyName] = curRank;
      curRank += increment;
    }
    break;
  }

  return {
  	Collection: collection,
  	StartIndex: startIndex,
  	EndIndex: endIndex,
  };
}

export default ReRankItemInCollection;