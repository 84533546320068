import React, { Component } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  appBarTitleContainer: {
    display: "flex",
    flexGrow: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    alignItems: "center",
  },
  content: {
    paddingTop: 64,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56,
    },
    height: "100%",
    width: "100%",
    // alignItems: "center",
    // justifyContent: "center",
    // display:"flex",
    backgroundColor: theme.palette.background.default,
  },
  container: {
    height:"100%",
  },
  paper: {
    height: "100%",
  },
  iframeContent: {
    width:"100%",
    height:"100%",
    border:0,
  }
});

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
    }
  }

  componentDidMount() {
  }

  handleCloseOrNavigateHome = () => {
    window.close();
    this.props.history.push("/");
  }

  render() {
    // const {
    // } = this.state;
    const {
      classes,
      theme,
    } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="fixed" style={{backgroundColor:theme.palette.background.appBar}}>
          <Toolbar>
            <div className={classes.appBarTitleContainer}>
              <Typography variant="h6" color="inherit">
                Nucleus One Privacy Policy
              </Typography>
            </div>
            <div>
              <Button variant="contained"
                onClick={this.handleCloseOrNavigateHome}>
                CLOSE
              </Button>
            </div>
          </Toolbar>
        </AppBar>

        <div className={classes.content}>
          <Container className={classes.container} maxWidth="sm" disableGutters>
            <Paper className={classes.paper} elevation={1}>
              <iframe src="/privacy.html" title="Privacy Policy" className={classes.iframeContent} />
            </Paper>
          </Container>
        </div>

      </div>
    );
  }
}

export default withStyles(styles, {withTheme: true})(PrivacyPolicy);