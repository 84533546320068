import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import AsyncSelectControl from './AsyncSelectControl';
import SelectControl from './SelectControl'
import TextField from '@material-ui/core/TextField';
import RemoveIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

import { DragSource, DropTarget } from 'react-dnd';
import { IsMobile } from '../Util/MobileDetector';
import { withStyles } from '@material-ui/core/styles';
import debounce from 'es6-promise-debounce';

import API from '../Util/api';
import DocumentSignatureSessionRecipientType from '../Model/DocumentSignatureSessionRecipientType';
import {
  GetUserAddressBookItemsPathForApi,
} from '../Util/api';
import {
  GetUserValue,
} from '../Util/Properties';

/**
 * Implements the drag source contract.
 */
const recipientRowSource = {
  canDrag(props) {
    return !IsMobile();
  },
  beginDrag(props, monitor, component) {
    props.onStartMoveRecipientRow();
    return {
      Recipient: props.Recipient,
      onDropRecipientRow: props.onDropRecipientRow,
    };
  },
  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      props.onAbortMoveRecipientRow();
    }
    props.onEndMoveRecipientRow();
  }
};

/**
 * Specifies the drop target contract.
 * All methods are optional.
 */
const recipientRowTarget = {
  drop(props, monitor, component) {
    if (monitor.didDrop()) {
    //   // If you want, you can check whether some nested
    //   // target already handled drop
      return;
    }

    // Obtain the dragged item
    const sourceRecipientRow = monitor.getItem();
    sourceRecipientRow.onDropRecipientRow();

    // You can also do nothing and return a drop result,
    // which will be available as monitor.getDropResult()
    // in the drag source's endDrag() method
    //return { moved: true };
  },
  hover(props, monitor, component) {
    if (!component)
      return null;
    
    // Obtain the dragged item  
    const sourceRecipientRow = monitor.getItem();
    const dragRank = sourceRecipientRow.Recipient.Rank;
    const hoverRank = props.Recipient.Rank;

    // Don't replace items with themselves
    if (dragRank === hoverRank)
      return;

    props.onMoveRecipientRow(sourceRecipientRow.Recipient);
  },
}

/**
 * Specifies the props to inject into your component.
 */
function dragCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

/**
 * Specifies which props to inject into your component.
 */
function dropCollect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    // isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    // canDrop: monitor.canDrop(),
    // itemType: monitor.getItemType()
  };
}

const styles = theme => ({
  row: {
    display:"flex",
  },
  recipientRow: {
    marginBottom: (IsMobile()) ? theme.spacing(2) : undefined,
  },
  dragHandleContainer: {
    paddingTop:theme.spacing(2),
    width:36,
  },
  removeIconContainer: {
    display: (IsMobile()) ? "none" : undefined,
    width:56,
    paddingTop:2,
    marginLeft:theme.spacing(1),
  },
});

class DraggableSignatureSessionRecipientRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      CurrentAddressBookItems: [],
    }
  }

  handleGetEmailListValues = debounce((filter) => {
    let params = {
      organizationId: this.props.organizationId,
      projectId: this.props.projectId,
      includeAddressBookItems: true,
      includeProjectMembers: true,
      filter,
    }
    return API.get(GetUserAddressBookItemsPathForApi(), { params })
      .then(resp => {
        this.setState({CurrentAddressBookItems: resp.data});
        return resp.data.map(abi => {
          return {
            value: abi.EmailLower,
            label: GetUserValue(abi.EmailLower, 
              (abi.Name)
                ? `${abi.Name} (${(abi.ProjectMemberID) ? "member " : ""}${abi.EmailLower})`
                : abi.EmailLower,
                "", false, undefined, {}, {}, true,
                ),
              //abi.EmailLower + ((abi.Name) ? ` (${abi.Name})` : ""),
            projectMemberId: abi.ProjectMemberID,
          };
        });
      })
      .catch(err => this.props.onApiError(err));
  }, 250);

  handleEmailListValueChange = option => {
    if (!option) {
      this.handleNameChange({target:{value:""}});
    } else {
      let abiFinder = this.state.CurrentAddressBookItems.filter(i => i.EmailLower === option.value);
      this.handleNameChange({
        target:{
          value: (abiFinder.length && abiFinder[0].Name) ? abiFinder[0].Name : "",
        },
      });
    }
    if (this.props.onRecipientEmailListValueChange) {
      this.props.onRecipientEmailListValueChange(this.props.Recipient.ID, option);
    }
  }

  handleEmailListCreateOption = value => {
    if (!value) {
      return;
    }
    value = value.trim();
    let listValue = {value:value,label:value};
    this.handleEmailListValueChange(listValue);
  }

  handleNameChange = e => {
    if (this.props.onRecipientNameChange) {
      this.props.onRecipientNameChange(this.props.Recipient.ID, e);
    } 
  }

  render() {
    const { 
      Recipient,
      onConnectEmailRef,
      onConnectNameRef,
      onRecipientTypeKeyDown,
      onUpdateRecipientType,
      showRemoveIcon,
      onRemoveRecipient,
      connectDragSource,
      connectDropTarget,
      draggable,
      // isDragging,
      classes,
    } = this.props;

    let recipientTypeOptions = DocumentSignatureSessionRecipientType
      .map(t => { return { label: t.Label, value: t.Type }});

    return (
      <div className={classes.row}
        ref={instance => {
          if (draggable) {
            connectDropTarget(connectDragSource(ReactDOM.findDOMNode(instance)));
          }
        }}
      >
        <div className={classes.dragHandleContainer}
          style={{
            display:(!draggable || IsMobile())
              ? "none"
              : undefined
          }}>
          <DragIndicatorIcon />
        </div>

        <Grid container spacing={2} className={classes.recipientRow}>
          <Grid item key={`rEmail_${Recipient.ID}`} xs={(IsMobile()) ? 12 : 6}>
            <AsyncSelectControl label="Person"
              fullWidth
              autoFocus
              noFlexWrap
              // floatingOptions
              onGetOptionsFilterPromise={this.handleGetEmailListValues} 
              value={Recipient.AssignmentUserEmail_ListValue}
              onValueChange={this.handleEmailListValueChange}
              onCreateOption={this.handleEmailListCreateOption}
              autoReloadOnValueChange
              connectRef={ref => onConnectEmailRef(ref)}
            />
          </Grid>
          <Grid item key={`rName_${Recipient.ID}`} xs={(IsMobile()) ? 12 : 4}>
            <TextField
              fullWidth
              variant="outlined"
              label="Name"
              value={Recipient.AssignmentUserName}
              onChange={this.handleNameChange}
              inputRef={instance => onConnectNameRef(instance)}
            />
          </Grid>
          <Grid item key={`rType_${Recipient.ID}`} xs={(IsMobile()) ? 12 : 2}>
            <SelectControl
              id={`rType_select_${Recipient.ID}`}
              label="Type"
              hideEmpty
              options={recipientTypeOptions} 
              value={Recipient.Type}
              onKeyDown={onRecipientTypeKeyDown}
              onValueChange={value => onUpdateRecipientType(Recipient.ID, value)} />
          </Grid>
        </Grid>

        <div className={classes.removeIconContainer}>
          {(showRemoveIcon) ? (
            <Tooltip title="Remove">
              <IconButton aria-label="Remove" onClick={() => onRemoveRecipient(Recipient.ID)}>
                <RemoveIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </div>
      </div>
    );
  }
}

DraggableSignatureSessionRecipientRow.propTypes = {
  classes: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
}

export default DropTarget('DraggableSignatureSessionRecipientRow', recipientRowTarget, dropCollect)(DragSource('DraggableSignatureSessionRecipientRow', recipientRowSource, dragCollect)(withStyles(styles, {withTheme: true})(DraggableSignatureSessionRecipientRow)));