import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

class GridWithConditionalRendering extends Component {
  render() {
    const {
      children,
      shouldRender = true,
      ...allOtherProps
    } = this.props;
  
    if (shouldRender === false) {
      return null;
    }
    
    // Otherwise, render the Grid component with its children
    return <Grid {...allOtherProps}>{children}</Grid>;
  }
}

export default GridWithConditionalRendering;