import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
// import Slide from '@material-ui/core/Slide';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import API from '../Util/api';
import ProgressIndicator from './ProgressIndicator';
import DocumentEventCollectionFields from '../Model/DocumentEventCollectionFields';
import DocumentEventListBase from './DocumentEventListBase';
import { GlobalContext } from '../Context/Global.context';
import debounce from 'es6-promise-debounce';
import { 
  GetCurrentSort,
  SaveSort,
  CompareSortTypeObject,
} from '../Util/Sort';
import {
  GetDocumentEventsPathForApi,
} from '../Util/api';

import { IsMobile } from '../Util/MobileDetector';

const styles = theme => ({
  content: {
    height: "100%",
    overflow:"auto",
    position:"relative",
  },
  metadataName: {
    fontWeight: 500,
  },
});

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

class DocumentEventsDialog extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      open: props.open,
      DocumentEvents: [],
      ShowGetMoreButton: false,
      Cursor: "",
      CollectionFields: [],
      SortType: DocumentEventCollectionFields.filter(t => t.ID === "CreatedOn")[0].ID,
      SortDescending: false,
      ShowProgressIndicator: false,
    }
  }

  handleLoadDocumentEvents = (reset, sortType, sortDescending) => {
    let params = {};
    params.cursor = (!reset) ? this.state.Cursor : "";
    let sortTypeParam = null;
    if (sortType) {
      sortTypeParam = sortType;
    } else if (this.state.SortType)  {
      sortTypeParam = this.state.SortType;
    }
    // Ensure outgoing sortType is valid against CollectionFields
    sortTypeParam = this.getValidSortType(sortTypeParam);
    params.sortType = sortTypeParam;
    if (sortDescending !== undefined) {
      params.sortDescending = sortDescending;
    } else if (this.state.SortDescending !== undefined) {
      params.sortDescending = this.state.SortDescending;
    }

    this.setState({ShowProgressIndicator:true});
    API.get(GetDocumentEventsPathForApi(this.props.organizationId, this.props.projectId, this.props.documentId),
      { params: params })
      .then(resp => {
        let documentEventList = resp.data;
        let documentEvents = (reset) ? documentEventList.DocumentEvents : [...this.state.DocumentEvents].concat(documentEventList.DocumentEvents);
        this.setState({
          DocumentEvents: documentEvents,
          ShowGetMoreButton: documentEventList.DocumentEvents.length >= documentEventList.PageSize,
          Cursor: documentEventList.Cursor, 
          ShowProgressIndicator: false
        });
      })
      .catch(this.handleApiError);
  }

  handleLoadDocumentEventsWithDebounce = debounce((reset, sortType, sortDescending) => {
    return this.handleLoadDocumentEvents(reset, sortType, sortDescending);
  }, 250);

  handleSortChange = (SortType, SortDescending) => {
    if (!SortType) {
      SortType = this.state.SortType;
    }
    if (typeof SortDescending !== "boolean") {
      SortDescending = this.state.SortDescending;
    }
    this.setState({SortType, SortDescending});
    this.handleLoadDocumentEvents(true, SortType, SortDescending);
    SaveSort("DocumentEvents", null, SortType, SortDescending);
  }

  handleFlipSortDirection = () => {
    let sortDescending = !this.state.SortDescending;
    this.handleSortChange(this.state.SortType, sortDescending);
  }

  handleSortChangeFromTableHeader = sortType => {
    // Flip sort direction if same sortType selected
    if (this.state.SortType && this.state.SortType.startsWith(sortType)) {
      this.handleFlipSortDirection();
    } else {
      this.handleSortChange(this.getValidSortType(sortType), false);
    }
  }

  getValidSortType = (sortType, collectionFields) => {
    if (!collectionFields) {
      collectionFields = this.state.CollectionFields;
    }
    let sortTypeFinder = collectionFields.filter(st => st.ID.startsWith(sortType));    
    if (sortTypeFinder.length) {
      return sortTypeFinder[0].ID;
    }
    return null;
  }

  handleClose = (callback) => {
    this.setState({ open: false, DocumentEvents: [] });
    if (typeof callback !== "undefined" && callback) {
      callback();
    }
  }

  handleApiError = err => {
    this.setState({
      ShowProgressIndicator: false, 
    });
    this.props.onApiError(err);
  }

  componentDidMount() {
    let CollectionFields = [...DocumentEventCollectionFields].sort(CompareSortTypeObject);
    GetCurrentSort("DocumentEvents")
      .then(initialSort => {
        let SortType = (initialSort) ? this.getValidSortType(initialSort.SortType, CollectionFields) : null;
        let SortDescending = (initialSort) ? initialSort.SortDescending : true;
        this.setState({CollectionFields, SortType, SortDescending});
        this.handleLoadDocumentEvents(true, SortType, SortDescending);
      });
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.handleLoadDocumentEvents(true);
      }
      this.setState({open: this.props.open !== false});
    }
  }

  render() {
    const { 
      open,
  		DocumentEvents,
      CollectionFields,
      SortType,
      SortDescending,
      ShowGetMoreButton,
      ShowProgressIndicator,
  	} = this.state;
    const {
      // classes,
      closeCallback,
    } = this.props;

    let progressIndicator = null;
    if (ShowProgressIndicator) {
      progressIndicator = (
        <ProgressIndicator />
      );
    }

    let dialogActions = (
      <DialogActions>
        <Button onClick={() => this.handleClose(closeCallback)}>
          CLOSE
        </Button>
      </DialogActions>
    );

    return (
       <Dialog
        fullScreen={IsMobile()}
        fullWidth={!IsMobile()}
        maxWidth="lg"
        // TransitionComponent={Transition}
        open={open}
        onClose={() => this.handleClose(closeCallback)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description">
        <DialogTitle id="dialog-title">
          <span>Document events</span>
        </DialogTitle>
        <DialogContent style={{paddingTop:0}}>
          {progressIndicator}
          
          <DocumentEventListBase
            documentEvents={DocumentEvents}
            onDocumentEventClick={this.handleDocumentEventClick}
            showGetMoreButton={ShowGetMoreButton}
            onGetMoreDocumentEvents={this.handleLoadDocumentEvents}
            sortType={SortType}
            collectionFields={CollectionFields}
            sortDescending={SortDescending}
            onSortChangeFromTableHeader={this.handleSortChangeFromTableHeader}
          />
        </DialogContent>

        {dialogActions}
      </Dialog>
    );
  }
}

export default withStyles(styles, {withTheme: true})(DocumentEventsDialog);