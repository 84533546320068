import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import NavigateFirstIcon from '@material-ui/icons/FirstPage';
import NavigateLastIcon from '@material-ui/icons/LastPage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigatePrevIcon from '@material-ui/icons/NavigateBefore';
import NavigateToPageIcon from '@material-ui/icons/Description';
import FocusIcon from '@material-ui/icons/LabelImportant';
import 'typeface-alex-brush';

// import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import ProgressIndicator from '../Components/ProgressIndicator';
import NumericTextField from '../Components/NumericTextField';
import MultiUseDialog from '../Components/MultiUseDialog';
import { IsMobile } from '../Util/MobileDetector';
import API from '../Util/api';
import { 
  GetExtendedFormFields,
} from '../Model/DocumentSignatureFormFieldTypes';

const marginWidth = 200;
// const imageHeaderHeight = 48;
const focusIconFontSize = 64;

const styles = theme => ({
  outerContainer: {
    height:"100%",
  },
  contentContainer: {
    display:"flex",
    height:"100%",
    // This wasn't needed after UiCore updates from Dark Mode work
    // overflow:"hidden",
  },
  canvasContainer: {
    // height:`calc(100% - ${imageHeaderHeight+17}px)`,
    height:"100%",
    overflowY:"auto",
    backgroundColor:theme.palette.background.default,
  },
  canvas: {
    position:"relative",
    width:(IsMobile()) ? "100%" : `calc(100% - ${marginWidth}px)`,
    height:"max-content", // Don't change this - It's required to keep vertical positioning of fields regardless of browser height
    userSelect: "none",
    marginLeft:(IsMobile()) ? 0 : marginWidth,
  },
  documentImage: {
    width:"100%",
  },
  imageContainer: {
    width:"100%",
    position:"relative",
  },
  imageHeader: {
    position:"sticky",
    top:0,
    left:0,
    // This wasn't needed after UiCore updates from Dark Mode work
    // height:imageHeaderHeight,
    width:"100%",
    backgroundColor:theme.palette.background.paper,
    zIndex:2,
    padding: theme.spacing(1),
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.divider,
  },
  controlMargin: {
    position:"absolute",
    top:0,
    left:-marginWidth,
    width:marginWidth,
    height:"100%",
    textAlign:"center",
    backgroundColor:theme.palette.background.pane,
  },
  controlOverlay: {
    position:"absolute",
    top:0,
    left:0,
    width:"100%",
    height:"100%",
    zIndex:2,
    opacity:0.9,
  },
  marginButton: {
    position:"sticky",
    top:(IsMobile()) ? theme.spacing(1) : theme.spacing(3),
    left:(IsMobile()) ? theme.spacing(1) : undefined,
  },
  formFieldButton: {
    // height:48,
    opacity:0.9,
    zIndex:3,
  },
  formFieldComplete: {
    position:"relative",
    height:(IsMobile()) ? 12 : 24,
    fontFamily:"Arial",
    fontWeight:500,
    display:"flex",
    color:"#222",
    justifyContent: "center",
    alignItems: "center",
    zIndex:2,
    pointerEvents:"none",
  },
  formFieldHeader : {
    position:"absolute",
    bottom:(IsMobile()) ? -8 : -12,
    left:0,
    fontFamily: "Arial",
    fontSize: (IsMobile()) ? 4 : 8,
    // paddingLeft: theme.spacing(1)/2,
  },
  focusIcon: {
    display:(IsMobile()) ? "none" : undefined,
    fontSize:focusIconFontSize,
  },
  pageAddedText: {
    marginBottom: (IsMobile()) ? 0 : theme.spacing(3),
    fontSize: (IsMobile()) ? 12 : undefined,
  },
  signingPageGrid: {
    position:"absolute",
    top:(IsMobile()) ? theme.spacing(4) : theme.spacing(8),
    left:(IsMobile()) ? theme.spacing(4) : theme.spacing(8),
    width:`calc(100% - ${theme.spacing(7)}px)`,
  },
  viewingPageText: {
    marginLeft:(IsMobile()) ? theme.spacing(2) : theme.spacing(3),
  },
});

class SigningForm extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      ShowAccessCodeDialog: false,
      SubmittedAccessCode: undefined,
      DocumentSignatureSessionSigningRecipientPackage: null,
      FormFieldsForSigning: null,
      FocusedFormField: null,
      GetValueDialogDetails: {Open:false},
      NavigateToPage_PageNumber: "",
      ShowNavigateToPageDialog: false,
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
      ShowCompletionDialog: false,
      ShowSubmitDialog: false,
    }

    this.CanvasContainerRef = React.createRef();
    this.FocusedFormFieldRef = React.createRef();
    this.FocusIconRef = React.createRef();
  }

  handleAccessCode = accessCode => {
    this.setState({SubmittedAccessCode: accessCode});
    this.handleGetDocumentSignatureSessionSigningRecipientPackage(0, false, accessCode);
  }

  handleGetDocumentSignatureSessionSigningRecipientPackage = (pageIndex, skipFormFieldData, accessCode) => {
    this.setState({
      ShowAccessCodeDialog: false,
      ShowProgressIndicatorImmediately: true,
    });

    let params = {
      accessCode: accessCode || this.state.SubmittedAccessCode,
      uniqueId: this.props.uniqueId,
      pageIndex,
      skipFormFieldData,
    };
    return API.get(`/documentSignatureSessions/${this.props.sessionId}/signingRecipients/${this.props.recipientId}/fields`,
      { params })
      .then(resp => {
        let p = resp.data;
        
        if (p.RequireAccessCode) {
          this.setState({
            ShowAccessCodeDialog: true,
          });
          return null;
        }

        if (skipFormFieldData) {
          p.FormFieldPackage = {...this.state.DocumentSignatureSessionSigningRecipientPackage.FormFieldPackage};
        } else {
          p.FormFieldPackage.FormFields = GetExtendedFormFields(resp.data.FormFieldPackage.FormFields);
        }
        // Add content pages if Quick design
        if (p.FormFieldPackage.FormDesignType === "Quick") {
          p = this.getModifiedRecipientPackageForQuickDesign(p);          
        }
        this.setState({
          DocumentSignatureSessionSigningRecipientPackage: p,
          ShowProgressIndicatorImmediately: false,
        });
        return resp.data;
      })
      .catch(err => {
        err.closeCallback = () => {
          window.close();
        };
        this.handleApiError(err);}
      ); 
  }

  getModifiedRecipientPackageForQuickDesign = recipientPackage => {
    let cp = recipientPackage.ContentPackage;
    // TODO: Add pages based on number of signers (overflow requires additional page(s))
    cp.QuickDesignStartingPageIndex = cp.PageCount;
    cp.PageCount = 1 + cp.PageCount;
    
    if (recipientPackage.FormFieldPackage) {
      let ffp = recipientPackage.FormFieldPackage;
      if (ffp.CompletedFormFields && ffp.CompletedFormFields.length) {
        let cff = GetExtendedFormFields(ffp.CompletedFormFields);
        cff.sort((a, b) => {
          if (a.DocumentSignatureSessionRecipientRank < b.DocumentSignatureSessionRecipientRank) {
            return -1;
          } else if (a.DocumentSignatureSessionRecipientRank > b.DocumentSignatureSessionRecipientRank) {
            return 1;
          } else if (a.SortRank < b.SortRank) {
            return -1;
          } else if (a.SortRank > b.SortRank) {
            return 1;
          }
          return 0;
        });
        ffp.CompletedFormFields = cff;
      }
    }

    return recipientPackage;
  }

  handleCompleteSigning = () => {
    this.handleSetShowSubmitDialog(false);
    this.setState({ShowProgressIndicatorImmediately: true});

    API.put(`/documentSignatureSessions/${this.props.sessionId}/signingRecipients/${this.props.recipientId}/fields`,
      this.state.FormFieldsForSigning, { params: { uniqueId: this.props.uniqueId} })
      .then(resp => {
        this.setState({
          ShowProgressIndicatorImmediately: false,
          ShowCompletionDialog: true,
        });
      })
      .catch(this.handleApiError); 
  }

  handleGoToContentPage = (pageIndex, skipScrollToTop) => {
    if (pageIndex === undefined || pageIndex === null) {
      pageIndex = this.state.NavigateToPage_PageNumber-1;
    }
    let p = {...this.state.DocumentSignatureSessionSigningRecipientPackage};
    // Exit if we're accessing an added quick-design page
    if (p.FormFieldPackage.FormDesignType === "Quick"
      && pageIndex >= p.ContentPackage.QuickDesignStartingPageIndex) {
      p.ContentPackage.PageIndex = pageIndex;
      p.ContentPackage.Url = null;
      this.setState({DocumentSignatureSessionSigningRecipientPackage: p});
      return;
    }
    let cp = p.ContentPackage;
    if (cp.PageCount && pageIndex > -1 && pageIndex < cp.PageCount) {
      this.setState({ShowProgressIndicatorImmediately:true});
      this.handleGetDocumentSignatureSessionSigningRecipientPackage(pageIndex, true)
        .then(() => {
          this.setState({ShowProgressIndicatorImmediately:false});
          if (!skipScrollToTop) {
            this.CanvasContainerRef.scrollTop = 0;
          }
        });
    }
  }

  handleSetShowNavigateToPageDialogVisibility = visible => {
    this.setState({ NavigateToPage_PageNumber: "", ShowNavigateToPageDialog: visible });
  }

  handleNavigateToPage_PageNumberValueChanged = e => {
    let value = "";
    if (e && e.target) {
      value = e.target.value;
    }
    this.setState({ NavigateToPage_PageNumber: value });      
  }

  handleCreateFormFieldsForSigning = () => {
    let p = {...this.state.DocumentSignatureSessionSigningRecipientPackage};
    let FormFieldsForSigning = [...p.FormFieldPackage.FormFields];
    if (p.FormFieldPackage.FormDesignType === "Quick") {
      
      FormFieldsForSigning.forEach(fffs => {
        // Set PageIndex for all but initial type to the added page
        if (fffs.Type !== "Initial") {
          fffs.PageIndex = p.ContentPackage.PageCount - 1;
        }
      });
    
    }
    FormFieldsForSigning.sort((a, b) => {
      if (a.PageIndex < b.PageIndex) {
        return -1;
      } else if (a.PageIndex > b.PageIndex) {
        return 1;
      } else if (a.Y < b.Y) {
        return -1;
      } else if (a.Y > b.Y) {
        return 1;
      } else if (a.X < b.X) {
        return -1;
      } else if (a.X > b.X) {
        return 1;
      }
      return 0;
    });
    
    this.setState({FormFieldsForSigning});
    this.handleFocusOnFirstUnsignedField(FormFieldsForSigning);
  }

  handleFocusOnFirstUnsignedField = formFieldsForSigning => {
    if (!formFieldsForSigning) {
      formFieldsForSigning = this.state.FormFieldsForSigning;
    }
    if (!formFieldsForSigning 
      || !this.state.DocumentSignatureSessionSigningRecipientPackage) {
      return;
    }
    let firstUnsigned = formFieldsForSigning.filter(f => !f.IsComplete);
    if (firstUnsigned.length) {
      let focusedFormField = {...firstUnsigned[0]};
      if (focusedFormField.PageIndex 
        !== this.state.DocumentSignatureSessionSigningRecipientPackage.ContentPackage.PageIndex) {
        this.handleGoToContentPage(focusedFormField.PageIndex, true)
      }
      this.setState({FocusedFormField:focusedFormField});
    } else {
      // Request complete signing
      this.handleSetShowSubmitDialog(true);
    }
  }

  handleFieldCompletion = (field, value) => {
    let FormFieldsForSigning = [...this.state.FormFieldsForSigning];
    let fieldFinder = FormFieldsForSigning.filter(f => f.ID === field.ID);
    if (fieldFinder.length) {
      let f = fieldFinder[0];
      if (!f.Value) {
        if (!value) {
          this.getValueFromUser(f);
          return;
        }
        f.Value = value;
      }
      f.IsComplete = true;
      // Set all date fields to complete on any signature field completion
      if (f.Type === "Signature") {
        FormFieldsForSigning.forEach(fffs => {
          if (fffs.Type === "Date") {
            fffs.IsComplete = true;
          }
        });
      }
      this.setState({FormFieldsForSigning});
      this.handleFocusOnFirstUnsignedField(FormFieldsForSigning);
    }
  }

  getValueFromUser = field => {
    this.setState({
      GetValueDialogDetails: {
        Open:true,
        // Title:`Enter your ${field.Label}`,
        RequireTextInput1:true,
        TextInput1Label:field.Label,
        CancelCallback:() => this.handleSetGetValueDialogVisibility(false),
        CloseCallback:() => this.handleSetGetValueDialogVisibility(false),
        ConfirmLabel:"NEXT",
        ConfirmCallback:value => {
          this.handleSetGetValueDialogVisibility(false);
          this.handleFieldCompletion(field, value);
        },
      }
    });
  }

  handleSetGetValueDialogVisibility = visible => {
    let GetValueDialogDetails = {...this.state.GetValueDialogDetails};
    GetValueDialogDetails.Open = visible;
    this.setState({GetValueDialogDetails});
  }

  handleCloseCompletionDialog = () => {
    this.setState({ShowCompletionDialog:false})
    window.close();
  }

  handleSetShowSubmitDialog = visible => {
    this.setState({ShowSubmitDialog:visible})
  }

  handleApiError = err => {
    if (this.props.onApiError) {
      this.props.onApiError(err);
    }
    this.setState({ShowProgressIndicator: false, ShowProgressIndicatorImmediately: false});
  }

  componentDidMount() {
    this.handleGetDocumentSignatureSessionSigningRecipientPackage();
  }

  componentWillUnmount() {
  }
  
  render() {
  	const {
      ShowAccessCodeDialog,
      DocumentSignatureSessionSigningRecipientPackage,
      FormFieldsForSigning,
      FocusedFormField,
      GetValueDialogDetails,
      ShowProgressIndicator,
      ShowProgressIndicatorImmediately,
      ShowNavigateToPageDialog,
      NavigateToPage_PageNumber,
      ShowCompletionDialog,
      ShowSubmitDialog,
  	} = this.state;
    const {
      classes,
      theme,
    } = this.props;

    if (ShowAccessCodeDialog) {
      let accessCodeDialogDetails = {
        Open:ShowAccessCodeDialog,
        Title:"Access code",
        DialogWidth: "xs",
        BodyText: "To access this document, please provide the code you were given.",
        RequireTextInput1: true,
        TextInput1Label: "Access code",
        TextInput1Type: "password",
        ConfirmLabel: "GO",
        ConfirmCallback: code => this.handleAccessCode(code),
      };

      return (
        <MultiUseDialog Details={accessCodeDialogDetails} />        
      );
    }

    let p = DocumentSignatureSessionSigningRecipientPackage;
    let ffp = (p && p.FormFieldPackage) ? p.FormFieldPackage : null;
    let cp = (p && p.ContentPackage) ? p.ContentPackage : null;
    let isQuick = (p && ffp.FormDesignType === "Quick");

    let goToPageButton = (cp && cp.PageCount && cp.PageCount > 2)
      ? (
        <Tooltip title="Go to page">
          <IconButton
            disabled={!cp || !cp.PageCount || cp.PageCount <= 2}
            onClick={() => this.handleSetShowNavigateToPageDialogVisibility(true)}>
            <NavigateToPageIcon />
          </IconButton>
        </Tooltip>
      ) : null;

    let currentPageNumber = (cp && cp.PageCount)
    ? (
        <Typography variant="body2"
          className={classes.viewingPageText}>
          {`${(IsMobile()) ? "Page" : "Viewing page"} ${1 + cp.PageIndex} of ${cp.PageCount}`}
        </Typography>
    ) : null;
    let imageHeader = (cp) 
    ? (
      <div className={classes.imageHeader}>
        <div style={{display:"flex",alignItems:"center"}}>
          <Tooltip title="First page">
            <span>
            <IconButton
              disabled={!cp || !cp.PageCount || cp.PageCount < 3 || cp.PageIndex === 0}
              onClick={() => this.handleGoToContentPage(0)}>
              <NavigateFirstIcon />
            </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Previous page">
            <span>
            <IconButton
              disabled={!cp || !cp.PageCount || cp.PageIndex === 0}
              onClick={() => this.handleGoToContentPage(cp.PageIndex - 1)}>
              <NavigatePrevIcon />
            </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Next page">
            <span>
            <IconButton
              disabled={!cp || !cp.PageCount || cp.PageIndex === cp.PageCount - 1}
              onClick={() => this.handleGoToContentPage(cp.PageIndex + 1)}>
              <NavigateNextIcon />
            </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Last page">
            <span>
            <IconButton
              disabled={!cp || !cp.PageCount || cp.PageCount < 3 || cp.PageIndex === cp.PageCount - 1}
              onClick={() => this.handleGoToContentPage(cp && cp.PageCount - 1)}>
              <NavigateLastIcon />
            </IconButton>
            </span>
          </Tooltip>
          {goToPageButton}
          {currentPageNumber}
        </div>
      </div>
    ) : null;

    const getField = f => {
      let isSignature = (f.Type === "Signature");
      let isInitial = (f.Type === "Initial");
      let quickDesignFormFieldHeader = (isQuick && !isInitial)
        ? (
          <span className={classes.formFieldHeader}>
            {f.Label}
          </span>
        ) : null;
      
      return (f.IsComplete) 
        ? (
          <div key={`fieldComplete_${f.ID}`}
            ftype={f.Type}
            fid={f.ID}
            frecipientrank={f.DocumentSignatureSessionRecipientRank}
            className={classes.formFieldComplete}
            style={{
              position: (!isQuick) ? "absolute" : undefined,
              left: (!isQuick) ? `${100*f.X}%` : undefined,
              top: (!isQuick) ? `${100*(f.Y+((IsMobile()) ? 0 : 0.005))}%` : undefined,
              justifyContent: (f.Type === "Initial") ? "left" : undefined,
              fontFamily: (isSignature) ? "Alex Brush" : undefined,
              fontWeight: (isSignature) ? "normal" : undefined,
              fontSize: (isSignature) 
                // signature fields
                ? (IsMobile()) ? 12 : 36 
                // everything else
                : (IsMobile())
                  ? 8
                  : (isQuick)
                    ? 18 : 24,
              borderBottom: (isQuick && !isInitial) ? "1px solid #888" : undefined,
            }}
          >
            {f.Value}
            {quickDesignFormFieldHeader}
          </div>
        ) 
        : (
          <Button key={`fieldButton_${f.ID}`}
            ftype={f.Type}
            fid={f.ID}
            frecipientrank={f.DocumentSignatureSessionRecipientRank}
            ref={instance => this.FocusedFormFieldRef = instance}
            variant="contained"
            color="secondary"
            className={classes.formFieldButton}
            style={{
              position: (!isQuick) ? "absolute" : undefined,
              left: (!isQuick) ? `${100*f.X}%` : undefined,
              top: (!isQuick) ? `${100*f.Y}%` : undefined,
            }}
            onClick={() => this.handleFieldCompletion(f)}>
            {f.Label}
          </Button>
        )
    }

    const getFormFields = fields => {
      let formFields = [];
      if (fields && fields.length) {
        fields.forEach(f => {
          if (cp && cp.PageCount && f.PageIndex === cp.PageIndex) {
            formFields = formFields.concat(
              getField(f)
            );
          }
        });
      }
      return formFields;
    }

    let formFields = (FormFieldsForSigning) ? getFormFields(FormFieldsForSigning) : null;
    let formFields_completed = (ffp) ? getFormFields(ffp.CompletedFormFields) : null;

    let formFieldsAbsolute = [];
    let gridItemsQuickInitial = [];
    let gridItemsQuickSigningPage = [(
      <Grid item xs={12} key="signingPageText">
        <Typography variant="h6"
          className={classes.pageAddedText}>
          This page has been added for signatures.
        </Typography>
      </Grid>
    )];
    let recipientRank = null;
    const createFormFieldComponents = formFields => {
      if (formFields && formFields.length) {
        if (isQuick) { // Quick-Design fields
          const getRecipientGridContainer = gridItems => {
            return (
               <Grid item xs={12} key={`f_recipientgrid_${gridItems[0].key}`}>
                <Grid container spacing={3} style={{marginTop:theme.spacing(1)}}>
                  {gridItems}
                </Grid>
              </Grid>
            );
          }
          let currentRecipientGridItemsQuickSigningPage = [];
          formFields.forEach(f => {
            let recipientChanged = (recipientRank !== f.props.frecipientrank);
            if (recipientChanged) {
              recipientRank = f.props.frecipientrank;
              if (currentRecipientGridItemsQuickSigningPage.length) {
                // Add recipient container for previous recipient
                gridItemsQuickSigningPage = gridItemsQuickSigningPage.concat(
                  getRecipientGridContainer(currentRecipientGridItemsQuickSigningPage)
                );
                currentRecipientGridItemsQuickSigningPage = [];
              }
            }
            if (f.props.ftype === "Initial") {
              gridItemsQuickInitial = gridItemsQuickInitial.concat(
                <Grid item style={{zIndex:3}} key={`f_item_${f.props.fid}`}>
                  {f}
                </Grid>
              );
            } else {
              currentRecipientGridItemsQuickSigningPage = currentRecipientGridItemsQuickSigningPage.concat(
                <Grid item style={{zIndex:3}} key={`f_item_${f.props.fid}`}>
                  {f}
                </Grid>
              );
            }
          });
          // Add recipient container for final recipient
          if (currentRecipientGridItemsQuickSigningPage.length) {
            gridItemsQuickSigningPage = gridItemsQuickSigningPage.concat(
              getRecipientGridContainer(currentRecipientGridItemsQuickSigningPage)
            );
            currentRecipientGridItemsQuickSigningPage = [];
          }
        } else { // Form-Design fields
          formFieldsAbsolute = formFieldsAbsolute.concat(formFields);
        }
      }
    }

    createFormFieldComponents(formFields_completed);
    createFormFieldComponents(formFields);

    let formFieldsQuickInitial = (gridItemsQuickInitial && gridItemsQuickInitial.length)
      ? (
        <Grid container direction="column" spacing={1}
          style={{
            position:"absolute",
            top:theme.spacing(1),
            left:theme.spacing(1),
            width:`calc(100% - ${theme.spacing(7)}px)`,
          }}
          >
          {gridItemsQuickInitial}
        </Grid>
      ) : undefined;

    let formFieldsQuickSigningPage = 
      (gridItemsQuickSigningPage 
        && cp 
        && cp.QuickDesignStartingPageIndex !== undefined
        && cp.PageIndex >= cp.QuickDesignStartingPageIndex)
      ? (
        <Grid container spacing={(IsMobile()) ? 2 : 3}
          className={classes.signingPageGrid}
        >
          {gridItemsQuickSigningPage}
        </Grid>
      ) : undefined;

    let navigateToPageDialogDetails = {
      Open:ShowNavigateToPageDialog,
      Title:`Go to page (1-${(cp && cp.PageCount)})`,
      DialogWidth: "xs",
      BodyContent: (
        <NumericTextField
          id="navigateToPage_pageNumber"
          label="Page number"
          autoFocus
          value={NavigateToPage_PageNumber}
          onValueChange={this.handleNavigateToPage_PageNumberValueChanged}
          hideClearButton
          onEnterKey={() => { this.handleGoToContentPage(); this.handleSetShowNavigateToPageDialogVisibility(false); }}
         />
      ),
      CloseCallback:() => this.handleSetShowNavigateToPageDialogVisibility(false),
    };

    let progressIndicator = null;
    if (ShowProgressIndicator || ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    let marginContent = null;
    // If no FormFieldsForSigning (session hasn't begun)
    if (!FormFieldsForSigning) {
      marginContent = (
        <Button variant="contained" color="secondary"
          className={classes.marginButton}
          onClick={this.handleCreateFormFieldsForSigning}>
          SIGN NOW
        </Button>
      );
    // At least 1 FormFieldsForSigning not IsComplete
    } else if (FormFieldsForSigning.filter(f => !f.IsComplete).length
      && FocusedFormField && cp) {
      if (FocusedFormField.PageIndex === cp.PageIndex) {
        const focusIconOffset = "14px";
        setTimeout(
          () => {
            // This will set the focus icon top to match the focused formfield top
            let focusedFormFieldTop = 
              this.FocusedFormFieldRef.getBoundingClientRect().top
              - this.CanvasContainerRef.getBoundingClientRect().top
              + this.CanvasContainerRef.scrollTop;
            this.FocusIconRef.style.top = `calc(${focusedFormFieldTop}px - ${focusIconOffset})`;
            // Scroll image to place block at 33% from top of image frame
            let scrollTop = focusedFormFieldTop - (this.CanvasContainerRef.clientHeight / 3);
            this.CanvasContainerRef.scrollTop = Math.max(scrollTop, 0);
          }
          , 1);

        marginContent = (
          <div
            ref={instance => this.FocusIconRef = instance}
            style={{
              position:"absolute",
              right:this.props.theme.spacing(1),
            }}
          >
            <FocusIcon className={classes.focusIcon} color="primary" />
          </div>
        );
      } else if (!ShowProgressIndicatorImmediately) {
        marginContent = (
          <Button variant="contained" color="primary"
            className={classes.marginButton}
            onClick={() => this.handleFocusOnFirstUnsignedField()}>
            RESUME
          </Button>
        );
      }
    // All FormFieldsForSigning IsComplete
    } else if (FormFieldsForSigning.filter(f => f.IsComplete).length === FormFieldsForSigning.length) {
        marginContent = (
          <Button variant="contained" color="primary"
            className={classes.marginButton}
            onClick={() => this.handleSetShowSubmitDialog(true)}>
            SUBMIT
          </Button>
        );
    }

    let documentImageCanvas = null;
    if (cp) {
      let contentImage = (isQuick && cp.PageIndex >= cp.QuickDesignStartingPageIndex)
        ? (
          <svg className={classes.documentImage} viewBox="100 100 100 100">
            <rect x="0" y="0" width="2550" height="3300" fill="#fff" />
          </svg>
        ) 
        : (
          <img src={cp.Url} className={classes.documentImage} alt="" />
        );
      documentImageCanvas = (
        <div className={classes.canvas}>
          <div className={(IsMobile()) ? classes.controlOverlay : classes.controlMargin}>
            {marginContent}
          </div>
          {formFieldsAbsolute}
          {formFieldsQuickInitial}
          {formFieldsQuickSigningPage}
          {contentImage}
        </div>
      );
    }

    let completionDialogDetails = {
      Open:ShowCompletionDialog,
      Title:"Signing complete",
      BodyText:"Thank you for signing. You may close this window.",
      CancelCallback:() => this.handleCloseCompletionDialog(),
      CloseCallback:() => this.handleCloseCompletionDialog(),
    };

    let submitDialogDetails = {
      Open: ShowSubmitDialog,
      Title: "Submit signature",
      BodyText: "Submit your signature now?",
      IsConfirmation: true,
      ConfirmLabel: "SUBMIT",
      CancelCallback: () => this.handleSetShowSubmitDialog(false),
      ConfirmCallback: () => this.handleCompleteSigning(),
    }

    return (
      <div className={classes.outerContainer}>
        <MultiUseDialog Details={navigateToPageDialogDetails} />
        <MultiUseDialog Details={GetValueDialogDetails} />
        <MultiUseDialog Details={completionDialogDetails} />
        <MultiUseDialog Details={submitDialogDetails} />

        {progressIndicator}

        {/*header*/}
        <div className={classes.contentContainer}>
          <div className={classes.imageContainer}>
            {imageHeader}
            {/* Document Image Canvas */}
            <div className={classes.canvasContainer}
              ref={instance => this.CanvasContainerRef = instance}>
              {documentImageCanvas}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, {withTheme: true})(SigningForm);