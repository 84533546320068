import React, { Component } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import MultiUseDialog from '../Components/MultiUseDialog';
import SigningForm from '../Components/SigningForm';
import ProgressIndicator from '../Components/ProgressIndicator';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "calc(100% - 64px)",
    backgroundColor:"white",
  },
  appBarTitleContainer: {
    display: "flex",
    flexGrow: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    alignItems: "center",
  },
  content: {
    paddingTop: 64,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56,
    },
    height: "100%",
  },

});

class SignScreen extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
      DialogDetails: {
        Open: false,
      },
    }
  }

  showDialog = details => {
    if (!details)
      return;
    this.setState({
      DialogDetails: {
        Open: (details.Closed) ? false : new Date(),
        IsConfirmation: details.IsConfirmation,
        RequireTextInput1: details.RequireTextInput1,
        FullWidth: details.FullWidth,
        Title: details.Title,
        BodyText: details.BodyText,
        BodyContent: details.BodyContent,
        BodyClassName: details.BodyClassName,
        TextInput1Label: details.TextInput1Label,
        TextInput1PlaceHolder: details.TextInput1PlaceHolder,
        TextInput1DefaultValue: details.TextInput1DefaultValue,
        CancelCallback: (details.CancelCallback) ? details.CancelCallback : () => {},
        CloseLabel: details.CloseLabel,
        CloseCallback: (details.CloseCallback) ? details.CloseCallback : () => {},
        DisableBlurClose: (details.DisableBlurClose) ? details.DisableBlurClose : false,
        ConfirmLabel: details.ConfirmLabel,
        ConfirmCallback: (details.ConfirmCallback) ? details.ConfirmCallback : () => {},
      }
    });
  }

  handleApiError = (err, title_optional) => {
    // console.log(err.response.status);
    // console.log(err.response.data.message);
    if (typeof err.response === "undefined") {
      err = {
        response: {
          status: 500,
          data: {
          message: err.toString(),
          },
        },
      };
    }

    switch(err.response.status) {
      case 500:
      // 500: Internal Server Error
      let dialogDetails = {
        Title: title_optional || "Sorry, something went wrong...",
        BodyText: (err.response.data.message) ? err.response.data.message : "Please try again.",
      }
      if (err.closeCallback !== undefined) {
        dialogDetails.CloseCallback = err.closeCallback;
      }
      this.showDialog(dialogDetails);
      break;
      default:
      break;
    }

    this.setState({ShowProgressIndicator: false});
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }
  
  render() {
  	const {
      DialogDetails,
      ShowProgressIndicator,
      ShowProgressIndicatorImmediately,
  	} = this.state;
    const {
      classes,
      theme,
    } = this.props;

    let progressIndicator = null;
    if (ShowProgressIndicator || ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    let content = (
      <SigningForm
        sessionId={this.props.match.params.sessionID}
        recipientId={this.props.match.params.recipientID}
        uniqueId={this.props.match.params.uniqueID}
        onApiError={this.handleApiError}
      />
    );

    return (
      <div className={classes.root}>
        <AppBar position="fixed"
          style={{backgroundColor: theme.palette.background.appBar}}
        >
          <Toolbar>
            <div className={classes.appBarTitleContainer}>
              <Typography variant="h6" color="inherit">
                Review and Sign
              </Typography>
            </div>
          </Toolbar>
        </AppBar>

        {progressIndicator}

        <MultiUseDialog Details={DialogDetails} />

        <div className={classes.content}>
          {content}
        </div>

      </div>
    );
  }
}

export default withStyles(styles, {withTheme: true})(SignScreen);