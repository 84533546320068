import API, {
  GetTaskMilestonesPathForApi,
  GetUserOrganizationProjectTaskMilestonesPathForApi,
  GetUserOrganizationProjectApprovalTaskMilestonesPathForApi,
} from './api';

export const GetTaskMilestonesPromise = (organizationId, projectId, taskId, getAll, 
  isTaskAssignment, isApprovalTaskAssignment, approvalId, sortByIndex) => {
  const params = {
    getAll,
    sortType: (sortByIndex) ? "Index" : undefined,
  }
  const uri = (isApprovalTaskAssignment)
    ? GetUserOrganizationProjectApprovalTaskMilestonesPathForApi(organizationId, projectId, approvalId, taskId)
    : (isTaskAssignment)
      ? GetUserOrganizationProjectTaskMilestonesPathForApi(organizationId, projectId, taskId)
      : GetTaskMilestonesPathForApi(organizationId, projectId);
  return API.get(uri, { params })
    .then(resp => {
      if (resp && resp.data) {
        return resp.data;
      } else {
        return [];
      }
    });
}

export const DeleteTaskMilestones = (organizationId, projectId, IDs) => {
  return API.delete(GetTaskMilestonesPathForApi(organizationId, projectId), { data: { IDs } });
}