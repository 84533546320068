import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';

import NavigateFirstIcon from '@material-ui/icons/FirstPage';
import NavigateLastIcon from '@material-ui/icons/LastPage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigatePrevIcon from '@material-ui/icons/NavigateBefore';
import NavigateToPageIcon from '@material-ui/icons/Description';

import ProgressIndicator from './ProgressIndicator';
import API, {
  GetFormTemplatesPublicFieldsPathForApi,
  GetFormTemplatesPublicSubmissionsPathForApi,
} from '../Util/api';
import { SortForTabOrder } from '../Util/FormTemplateFields';
import FormField from './FormField';
import MultiUseDialog from './MultiUseDialog';
import NumericTextField from './NumericTextField';
import { 
  GetFieldPassesValidation,
} from '../Util/Field';
import { 
  GetExtendedFormTemplateFields,
} from '../Model/FormTemplateFieldTypes';
import dateformat from 'dateformat';
import {
  GetLightMode,
} from '../Util/Theme';

// import debounce from 'es6-promise-debounce';
import { IsMobile } from '../Util/MobileDetector';

// _pageWidth should match the width of the canvas in the Form-Template Designer
// (scale container width - tool pane width - property pane width - canvas scrollbar width)
const _pageWidth = 1015;
const _navBarHeight = 48;
const _navBarPadding = 8;
const _getTotalNavBarHeight = () => {
  return _navBarHeight + (2 * _navBarPadding)/*top+bottom padding*/ + 1/*bottom border*/;
}

// NOTE: We have to override all items surrounding the form canvas for this page as it's forced to "light mode" in App.js.
// This is because we need a white background and matching form fields.
const styles = theme => ({
	outerContainer: {
    height:"100%",
    // [theme.breakpoints.down('xs')]: {
   	// 	width:"100%",
   	// },
    overflow:"hidden",
  },
  scaleContainer: {
    height:"100%",
    transformOrigin:"top left",
    overflow:"hidden",
    width:_pageWidth,
  },
  mobileContainer: {
   	paddingLeft:theme.spacing(3),
    paddingRight:theme.spacing(3),
    paddingBottom:theme.spacing(3),
    overflowY:"auto",
  },
  mobileItem: {
    paddingTop:theme.spacing(3),
  },
  canvasContainer: {
    height:`calc(100% - ${_getTotalNavBarHeight()}px)`,
    overflowY:"auto",
    backgroundColor:"white",
  },
  canvas: {
    position:"relative",
    width:"100%",
    userSelect: "none",
  },
  pageBackgroundImage: {
    position:"absolute",
    left:0,
    top:0,
    width:"100%",
    height:"100%",
    // opacity:0.5,
  },
  formNavBar: {
    position:"sticky",
    top:0,
    left:0,
    // This wasn't needed after UiCore updates from Dark Mode work
    // height:_navBarHeight,
    display:"flex",
    alignItems:"center",
    // backgroundColor: see render
    zIndex:100,
    padding: _navBarPadding,
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.divider,
  },
  formNavBarFlexEnd: {
    display:"flex",
    flexGrow: 1,
    justifyContent:"flex-end",
    alignItems:"center",
    marginRight:theme.spacing(2),
  },
});

const n1FormPrefix = "n1form_";

class FormInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      FormTemplateFields: [],
      FieldsLoaded: false,
      PageIndex: 0,
      PageIndicesViewed: { 0: true },
      NotCompleteFormTemplateFieldIDs: null,
      NavigateToPage_PageNumber: "",
      ShowNavigateToPageDialog: false,
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
      UseMobileLayout: false,
      FocusOnFirstField: false,
      FocusOnLastField: false,
      FocusOnFieldID: null,
      ShowSubmitResultDialog: false,
      FormIsSubmitted: false,
      ShowSubmitPageViewAlertDialog: false,
      ShowSubmitRequiredFieldsAlertDialog: false,
      RequiredFieldsAlertBodyText: "",
    }

    this.OuterContainerRef = React.createRef();
    this.ScaleContainerRef = React.createRef();
    this.CanvasContainerRef = React.createRef();
    this.CanvasRef = React.createRef();
    
    const getNewItemName = name => `${n1FormPrefix}${name}`;
    const addFormItemToGlobal = (name, item) => {
      window[getNewItemName(name)] = item;
    };

    const formFieldItemsToReplace = {
      app: {
        alert: msg => window.alert(msg),
      },
      getField: id => {
        if (!id) {
          return null;
        }
        const field = this.state.FormTemplateFields.find(f => f.FormTemplateSourceFullName === id);
        if (field) {
          return {
            value: field.Value,
          };
        }

        return {};
      },
      AFMakeNumber: value => {
        return +value;
      },
      AFSimple_Calculate: (cFunction, cFields) => {
        let fields = [];
        cFields.forEach(name => {
          const field = this.state.FormTemplateFields.find(f => f.FormTemplateSourceFullName === name);
          if (field) {
            fields.push(field);
          }
        });
        let fieldValues = [];
        fields.forEach(f => {
          if (f.Value !== "") {
            fieldValues.push(+f.Value);
          }
        });
        if (!window.event) {
          window.event = {};
        }
        switch (cFunction) {
        case "AVG":
          window.event.value = fieldValues.reduce((a, b) => a + b, 0) / fieldValues.length;
          break;
        case "SUM":
          window.event.value = fieldValues.reduce((a, b) => a + b, 0);
          break;
        case "PRD":
          window.event.value = fieldValues.reduce((a, b) => a * b, 1);
          break;
        case "MIN":
          window.event.value = Math.min(...fieldValues);
          break;
        case "MAX":
          window.event.value = Math.max(...fieldValues);
          break;
        default:
          break;
        }
      },
    };
    const formFieldItemsToAdd = {
      onValueChange: (id, value) => {
        if (!id) {
          return;
        }
        const field = this.state.FormTemplateFields.find(f => f.ID === id);
        if (field) {
          if (field.Field.Type === "FieldType_Number" || field.Field.Type === "FieldType_Currency") {
            if (value) {
              if (typeof value !== "number") {
                value = +value;
              }
              value = value.toFixed((field.Field.Type === "FieldType_Currency") ? 2 : field.Field.DecimalPlaces);
            }
          }
          this.handleFormFieldJavaScriptValueChange(id, value, undefined, true);
        }
      },
    }

    this.sanitizeFormFieldJavaScript = js => {
      Object.keys(formFieldItemsToReplace).forEach(k => {
        js = js.replaceAll(k, getNewItemName(k));
      });
      return js;
    };

    Object.keys(formFieldItemsToReplace).forEach(k => {
      addFormItemToGlobal(k, formFieldItemsToReplace[k]);
    });
    Object.keys(formFieldItemsToAdd).forEach(k => {
      addFormItemToGlobal(k, formFieldItemsToAdd[k]);
    });
  }

  getFormTemplateFields = formTemplate => {
    this.setState({ShowProgressIndicatorImmediately: true});

    return API.get(GetFormTemplatesPublicFieldsPathForApi(this.props.FormTemplate.ID),
    	{ params:{ 
        organizationId:this.props.FormTemplate.OrganizationID,
        projectId:this.props.FormTemplate.ProjectID,
      }})
      .then(resp => {
      	let fields = SortForTabOrder(GetExtendedFormTemplateFields(resp.data));
      	// Set default values
      	fields
          .forEach(f => {
          // Set default values
          // If this is FieldType_Date and UseCreationDate, set value to current date
          if (f.Field.Type === "FieldType_Date" && f.Field.UseCreationDate) {
            f.DefaultValue = dateformat(new Date(), "yyyy-mm-dd");
          }
          f.Value = f.DefaultValue;
          f.Values = f.DefaultValues;
          f.Field.Value = f.DefaultValue;
          f.Field.Values = f.DefaultValues;
      	});
        return this.updateFormTemplateFieldState(fields,
          {
            FieldsLoaded: true,
            ShowProgressIndicatorImmediately: false,
          });
      })
      .catch(err => this.props.onApiError(err));
  }

  loadFields = formTemplate => {
    if (!formTemplate) {
      formTemplate = this.props.FormTemplate;
    }

    return this.getFormTemplateFields();
  }

  handleFormTemplateFieldValueChange = (formTemplateFieldId, value, values, updateInnerField) => {
    let fields = [...this.state.FormTemplateFields];
    let formTemplateField = fields.find(f => f.ID === formTemplateFieldId);
    if (formTemplateField) {
      switch (formTemplateField.Type) {
        case "CheckBox":
          formTemplateField.Value = value.toString();
          break;
        case "RadioGroup":
          // Deselect any other radio button having the same FormTemplateField.Field.Name
          // This is for handling PDF forms, where radio groups are multiple controls.
          for (let i = 0; i < fields.length; i++) {
            let ftf = fields[i];
            if (ftf.ID !== formTemplateFieldId
              && ftf.Field.Name === formTemplateField.Field.Name
              && ftf.Type === "RadioGroup") {
              if (ftf.Value) {
                ftf.Value = "";
              }
            }
          }
          formTemplateField.Value = value;
          break;
        default:
          formTemplateField.Value = value;
          formTemplateField.Values = values;
          break;
      }
      if (updateInnerField) {
        formTemplateField.Field.Value = formTemplateField.Value;
        formTemplateField.Field.Values = formTemplateField.Values;
      }
      this.updateFormTemplateFieldState(fields);

      // JavaScript
      const formTemplateFieldsHavingThisFieldInJavascript = fields
        .filter(ftf => ftf.JavaScript && 
          (
            ftf.JavaScript.includes(`"${formTemplateField.FormTemplateSourceFullName}"`)
            || ftf.JavaScript.includes(`"${formTemplateField.FormTemplateSourcePartialName}"`)
          )
        );
      formTemplateFieldsHavingThisFieldInJavascript.forEach(ftf => {
        try {
          let JavaScript = ftf.JavaScript;
          if (JavaScript) {
            JavaScript = this.sanitizeFormFieldJavaScript(JavaScript);
            JavaScript += `\n${n1FormPrefix}onValueChange("${ftf.ID}", event.value);`;
            // console.log(ftf.FormTemplateSourceFullName, JavaScript);
            // eslint-disable-next-line
            const f = new Function([], JavaScript);
            f();
          }
        }
        catch (err) {
          console.log("FormTemplateField.JavaScript error", err);
        }
      });
    }
  }

  handleFormFieldJavaScriptValueChange = (formTemplateFieldId, value, values, updateInnerField) => {
    return this.handleFormTemplateFieldValueChange(formTemplateFieldId, value, values, updateInnerField);
  }

  handleFormTemplateFieldDocumentUpload = (formTemplateFieldId, documentUpload) => {
    let fields = [...this.state.FormTemplateFields];
    let formTemplateFieldFinder = fields.filter(f => f.ID === formTemplateFieldId);
    if (formTemplateFieldFinder.length) {
      let formTemplateField = formTemplateFieldFinder[0];
      formTemplateField.DocumentUpload = documentUpload;
      this.updateFormTemplateFieldState(fields);
    }
  }

  handleFormTemplateFieldImageUploads = (formTemplateFieldId, imageUploadSessionId, imageUploads) => {
    let fields = [...this.state.FormTemplateFields];
    let formTemplateFieldFinder = fields.filter(f => f.ID === formTemplateFieldId);
    if (formTemplateFieldFinder.length) {
      let formTemplateField = formTemplateFieldFinder[0];
      formTemplateField.ImageUploadSessionID = imageUploadSessionId;
      formTemplateField.ImageUploads = imageUploads;
      this.updateFormTemplateFieldState(fields);
    }
  }

  updateFormTemplateFieldState = (fields, additionalState) => {
    if (!fields) {
      fields = [...this.state.FormTemplateFields]; 
    }
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      // Values must exist as a JSON array
      if (Array.isArray(field.Values)) {
        field.Values = JSON.stringify(field.Values);
      }
    }
    let stateToUpdate = {FormTemplateFields: fields};
    if (additionalState) {
      stateToUpdate = {...stateToUpdate, ...additionalState};
    }
    this.setState(stateToUpdate);
    this.setNotCompleteFormTemplateFieldIDs(fields);
    // this.handleUpdateFormTemplateField(formTemplateField);
    return fields;
  }

  getFormSubmissionPackage = () => {
    let formTemplateFields = this.updateFormTemplateFieldState();
    return {
      FormTemplateID: this.props.FormTemplate.ID,
      FormShareID: this.props.FormShareID,
      OrganizationID: this.props.FormTemplate.OrganizationID,
      ProjectID: this.props.FormTemplate.ProjectID,
      FormSubmissionFields: formTemplateFields,
    };
  }

  handleSubmit = () => {
    // Required fields check
    if (this.state.NotCompleteFormTemplateFieldIDs.length > 0) {
      let remainingRequiredNotSpecial = 0,
        remainingSignature = 0,
        remainingUpload = 0,
        firstRequiredField = null;
      this.state.NotCompleteFormTemplateFieldIDs.forEach(id => {
        let fieldFinder = this.state.FormTemplateFields.filter(f => f.ID === id);
        if (fieldFinder.length) {
          if (!firstRequiredField) {
            firstRequiredField = fieldFinder[0];
          }
          if (fieldFinder[0].Type === "Signature") {
            remainingSignature++;
          } else if (fieldFinder[0].Type === "Upload") {
            remainingUpload++;
          } else {
            remainingRequiredNotSpecial++;
          }
        }
      });
      let RequiredFieldsAlertBodyText = `Please complete `;
      if (remainingRequiredNotSpecial) {
        RequiredFieldsAlertBodyText += `${remainingRequiredNotSpecial} required field${(remainingRequiredNotSpecial > 1) ? "s" : ""}`;
      }
      if (remainingSignature) {
        RequiredFieldsAlertBodyText += `${
          (remainingRequiredNotSpecial || remainingUpload) ? " and " : "" }${remainingSignature} signature${(remainingSignature > 1) ? "s" : ""}`;
      }
      if (remainingUpload) {
        RequiredFieldsAlertBodyText += `${
          (remainingRequiredNotSpecial || remainingSignature) ? " and " : "" }${remainingUpload} upload${(remainingUpload > 1) ? "s" : ""}`;
      }
      RequiredFieldsAlertBodyText += ".";
      let formTemplateFields = [...this.state.FormTemplateFields];
      if (firstRequiredField) {
        const field = formTemplateFields.find(f => f.ID === firstRequiredField.ID);
        if (field) {
          field.Field.UpdateId = new Date();
        }
        this.updateFormTemplateFieldState(formTemplateFields);
      }
      this.setState({
        ShowSubmitRequiredFieldsAlertDialog:true,
        RequiredFieldsAlertBodyText,
      });
      this.handleGoToPage(firstRequiredField.PageIndex);
      this.setState({
        FocusOnFieldID: (firstRequiredField) ? firstRequiredField.ID : null,
      });
      return; 
    }
    // Page review check
    if (Object.keys(this.state.PageIndicesViewed).length < this.props.FormTemplate.PageCount) {
      this.setState({ShowSubmitPageViewAlertDialog:true});
      return;
    }

    this.setState({FormIsSubmitted: true, ShowProgressIndicatorImmediately: true});
    return API.post(GetFormTemplatesPublicSubmissionsPathForApi(this.props.FormTemplate.ID),
      [this.getFormSubmissionPackage()])
      .then(resp => {
        this.handleSetShowSubmitResultDialogVisibility(true);
      })
      .catch(err => {
        this.setState({FormIsSubmitted: false});
        this.props.onApiError(err);
      })
      .finally(() => {
        this.setState({ShowProgressIndicatorImmediately: false});
      }); 
  }

  setNotCompleteFormTemplateFieldIDs = fields => {
    let NotCompleteFormTemplateFieldIDs = [];
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      if (field.Type === "Upload" && field.Field.Required) {
        if (
          !(
            (field.DocumentUpload && field.DocumentUpload.ObjectName)
            || (field.ImageUploads && field.ImageUploads.length)
          )
        ) {
          NotCompleteFormTemplateFieldIDs.push(field.ID);
        } 
      } else if (!GetFieldPassesValidation(field.Field)) {
        NotCompleteFormTemplateFieldIDs.push(field.ID);
      } else if (field.Type === "Signature") {
        if (!field.Value || field.Value.trim() === "") {
          NotCompleteFormTemplateFieldIDs.push(field.ID);
        }
      }
    }
    this.setState({NotCompleteFormTemplateFieldIDs});
  }

  handleWindowResize = () => {
    this.updateComponentSizesByDocumentOrCurrentPage(this.getFormTemplatePage());
  }

  getFormTemplatePage = PageIndex => {
    if (PageIndex === undefined || PageIndex === null) {
      PageIndex = this.state.PageIndex;
    }
    let currentFormTemplatePage = {
      PageIndex,
      PageWidth: null,
      PageHeight: null,
      Aspect: null,
    };
    if (this.props.FormTemplate.Pages) {
      const pageFinder = this.props.FormTemplate.Pages
        .filter(p => p.PageIndex === PageIndex);
      if (pageFinder.length) {
        currentFormTemplatePage = pageFinder[0];
      }
    }
    return currentFormTemplatePage;
  }

  updateComponentSizesByDocumentOrCurrentPage = currentFormTemplatePage => {
    if (currentFormTemplatePage.UseCustomSize) {
      this.updateComponentSizes(currentFormTemplatePage.PageHeight/currentFormTemplatePage.PageWidth);
    } else {
      const formTemplate = this.props.FormTemplate;
      if (formTemplate.PageHeight && formTemplate.PageWidth) {
        this.updateComponentSizes(formTemplate.PageHeight/formTemplate.PageWidth);        
      } else {
        this.updateComponentSizes(formTemplate.Aspect);        
      }
    }
  }

  updateComponentSizes = pageAspect => {
    let UseMobileLayout = IsMobile() || window.innerWidth < this.props.theme.breakpoints.values.sm;
    this.setState({UseMobileLayout});
    if (UseMobileLayout) {
      return;
    }

    if (!this.OuterContainerRef
    	|| !this.ScaleContainerRef
    	|| !this.CanvasRef) {
      return;
    }

    if (window.innerWidth < _pageWidth) {
    	this.OuterContainerRef.style.width = "auto";
    } else {
    	this.OuterContainerRef.style.width = `${_pageWidth}px`;
    }
    this.Scale = this.OuterContainerRef.clientWidth / this.ScaleContainerRef.clientWidth;
    // ScaleContainer transform
    this.ScaleContainerRef.style.transform = `scale(${this.Scale})`;
    // ScaleContainer height
    this.ScaleContainerRef.style.height = `${this.OuterContainerRef.clientHeight / this.Scale}px`;
    // Canvas height
    if (!pageAspect) {
      pageAspect = this.props.FormTemplate.Aspect;
    }
    this.CanvasRef.style.height = `${pageAspect * this.CanvasRef.clientWidth}px`;
  }

  handleGoToPage = (PageIndex, FocusOnFirstField, FocusOnLastField) => {
    this.handleSetShowNavigateToPageDialogVisibility(false);
    if (PageIndex === undefined || PageIndex === null) {
      PageIndex = this.state.NavigateToPage_PageNumber-1;
    }
    let pageCount = this.props.FormTemplate.PageCount;
    if (pageCount && PageIndex > -1 && PageIndex < pageCount) {
      this.setState({
        PageIndex,
        FocusOnFirstField,
        FocusOnLastField,
        FocusOnFieldID: null,
        PageIndicesViewed: {...this.state.PageIndicesViewed, [PageIndex]: true},
      });
      this.updateComponentSizesByDocumentOrCurrentPage(this.getFormTemplatePage(PageIndex));
      if (this.CanvasContainerRef) {
        this.CanvasContainerRef.scrollTop = 0;
      }
      if (this.props.onSetCurrentPageIndex) {
        this.props.onSetCurrentPageIndex(PageIndex);
      }
    }
  }

  handleSetShowSubmitResultDialogVisibility = visible => {
    this.setState({ ShowSubmitResultDialog: visible });
    if (!visible) {
      window.close();
    }
  }

  handleSetShowSubmitPageViewAlertDialogVisibility = visible => {
    this.setState({ ShowSubmitPageViewAlertDialog: visible });
  }

  handleSetShowSubmitRequiredFieldsAlertDialogVisibility = visible => {
    this.setState({ ShowSubmitRequiredFieldsAlertDialog: visible });
  }

  handleSetShowNavigateToPageDialogVisibility = visible => {
    this.setState({ NavigateToPage_PageNumber: "", ShowNavigateToPageDialog: visible });
  }

  handleNavigateToPage_PageNumberValueChanged = e => {
    let value = "";
    if (e && e.target) {
      value = e.target.value;
    }
    this.setState({ NavigateToPage_PageNumber: value });      
  }

  handleMoveForward = e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (this.state.PageIndex < this.props.FormTemplate.PageCount - 1) {
      this.handleGoToPage(1 + this.state.PageIndex, true, false);
    } else {
      //solicit submission
    }
  }

  handleMoveBackward = e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (this.state.PageIndex > 0) {
      this.handleGoToPage(this.state.PageIndex - 1, false, true);
    }
  }

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);
    this.loadFields();
    this.handleWindowResize();
    // Run it again to account for scrollbar width
    this.handleWindowResize();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

  render() {
    const { 
      ShowProgressIndicator,
      ShowProgressIndicatorImmediately,
      FormTemplateFields,
      PageIndex,
      // NotCompleteFormTemplateFieldIDs,
      ShowNavigateToPageDialog,
      NavigateToPage_PageNumber,
      UseMobileLayout,
      ShowSubmitResultDialog,
      FormIsSubmitted,
      FocusOnFirstField,
      FocusOnLastField,
      FocusOnFieldID,
      // PageIndicesViewed,
      ShowSubmitPageViewAlertDialog,
      ShowSubmitRequiredFieldsAlertDialog,
      RequiredFieldsAlertBodyText,
      FieldsLoaded,
    } = this.state;
    const {
      FormTemplate,
      onApiError,
      classes,
      theme,
      cookies,
      ...restProps
    } = this.props;

    let progressIndicator = null;
    if (ShowProgressIndicator || ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    const navigateToPageDialogDetails = {
      Open:ShowNavigateToPageDialog,
      Title:`Go to page (1-${(FormTemplate.PageCount)})`,
      DialogWidth: "xs",
      BodyContent: (
        <NumericTextField
          id="navigateToPage_pageNumber"
          label="Page number"
          index={0}
          value={NavigateToPage_PageNumber}
          onValueChange={this.handleNavigateToPage_PageNumberValueChanged}
          hideClearButton
          onEnterKey={this.handleGoToPage}
         />
      ),
      CloseCallback:() => this.handleSetShowNavigateToPageDialogVisibility(false),
    };

    const submitResultDialogDetails = {
      Open:ShowSubmitResultDialog,
      Title:`Form submitted`,
      DialogWidth: "xs",
      BodyText: "Thank you for your submission. You may close this window.",
      CancelCallback:() => this.handleSetShowSubmitResultDialogVisibility(false),
      CloseCallback:() => this.handleSetShowSubmitResultDialogVisibility(false),
    };

    const submitPageViewAlertDialogDetails = {
      Open:ShowSubmitPageViewAlertDialog,
      Title:`Review all pages`,
      DialogWidth: "xs",
      BodyText: `Please review all pages before submitting.`,
      CancelCallback:() => this.handleSetShowSubmitPageViewAlertDialogVisibility(false),
      CloseCallback:() => this.handleSetShowSubmitPageViewAlertDialogVisibility(false),
    };

    const submitRequiredFieldsAlertDialogDetails = {
      Open:ShowSubmitRequiredFieldsAlertDialog,
      Title:`Required fields`,
      DialogWidth: "xs",
      BodyText: RequiredFieldsAlertBodyText,
      CancelCallback:() => this.handleSetShowSubmitRequiredFieldsAlertDialogVisibility(false),
      CloseCallback:() => this.handleSetShowSubmitRequiredFieldsAlertDialogVisibility(false),
    };

    const ftfsLocal = (FieldsLoaded ? FormTemplateFields : []);
    const currentPageFields = ftfsLocal
      .filter(f => f.PageIndex === PageIndex);
    const currentPageInputFields = currentPageFields
      .filter(f => f.Category === "Input" 
        && !(f.Type === "Signature" && f.Value));
    const getFormField = (field, forMobile) => {
      const inputIndex = currentPageInputFields.indexOf(field);
      const firstInputForFocus = inputIndex === 0;
      const lastInputForFocus = inputIndex === currentPageInputFields.length - 1;
      const autoFocus = (FocusOnLastField && lastInputForFocus) 
        || (FocusOnFirstField && firstInputForFocus)
        || FocusOnFieldID === field.ID;
      // For mobile browsers, always show the label when the form is based on a source.
      // This may make it usable for PDF forms that had useful field names.
      if (FormTemplate.FormTemplateSourceID !== "" && IsMobile()) {
        field.Field.HideLabel = false;
      }
      return (
        <FormField
          {...restProps}
          organizationId={FormTemplate.OrganizationID}
          projectId={FormTemplate.ProjectID}
          key={field.ID}
          disabled={FormIsSubmitted}
          tabIndex={inputIndex}
          totalFields={currentPageInputFields.length}
          ForMobile={forMobile}
          FormTemplate={FormTemplate}
          FormTemplateField={field}
          onValueChange={this.handleFormTemplateFieldValueChange}
          onDocumentUploadComplete={this.handleFormTemplateFieldDocumentUpload}
          onImageUploadsComplete={this.handleFormTemplateFieldImageUploads}
          onMoveForward={this.handleMoveForward}
          onMoveBackward={this.handleMoveBackward}
          autoFocus={autoFocus}
          onApiError={onApiError}
          fields={currentPageInputFields}
        />
      );
    }

    let formTemplateFields = ftfsLocal
      .filter(f => f.PageIndex === PageIndex)
      .map(f => getFormField(f, false));

    let formTemplateFieldMobileItems = (UseMobileLayout) 
      ? ftfsLocal
        .filter(f => f.PageIndex === PageIndex)
        .map(f => (
      	<div className={classes.mobileItem} key={`i_${f.ID}`}>
      		{getFormField(f, true)}
      	</div>
      )) : [];

    const navButtonColorPrimary = !GetLightMode(cookies)
      ? "white"
      : undefined;
    const navButtonColorDisabled = !GetLightMode(cookies)
      ? "rgba(255, 255, 255, 0.5)"
      : undefined;
    
    let goToPageButton = (FormTemplate.PageCount > 2)
      ? (
        <Tooltip title="Go to page">
          <IconButton
            style={{
              color: navButtonColorPrimary,
            }}
            tabIndex={-1}
            disabled={FormTemplate.PageCount <= 2}
            onClick={() => this.handleSetShowNavigateToPageDialogVisibility(true)}>
            <NavigateToPageIcon />
          </IconButton>
        </Tooltip>
      ) : null;

    // let currentPageNumber = (FormTemplate.PageCount && !UseMobileLayout)
    // ? (
    //     <Typography variant="body2"
    //       style={{marginLeft:theme.spacing(3)}}>
    //       {`${(UseMobileLayout) ? "Page" : "Viewing page"} ${1 + PageIndex} of ${FormTemplate.PageCount}`}
    //     </Typography>
    // ) : null;

    let submitButton = (
      <div className={classes.formNavBarFlexEnd}>
        <Button variant="contained" color="secondary" onClick={this.handleSubmit} disabled={FormIsSubmitted}>
          SUBMIT
        </Button>
      </div>
    );

    let navBarEnd = null;
    // if (NotCompleteFormTemplateFieldIDs) {
    //   if (NotCompleteFormTemplateFieldIDs.length) {
    //     navBarEnd = (
    //       <div className={classes.formNavBarFlexEnd}>
    //         <Typography variant="body2">
    //           {`${NotCompleteFormTemplateFieldIDs.length} required${((UseMobileLayout) ? "" : " field" + ((NotCompleteFormTemplateFieldIDs.length > 1) ? "s" : ""))}`}
    //         </Typography>
    //       </div>
    //     );
    //   } else if (!FormIsSubmitted && Object.keys(PageIndicesViewed).length === FormTemplate.PageCount) {
        navBarEnd = submitButton;
    //   }
    // }

    const firstPageDisabled = FormTemplate.PageCount < 3 || PageIndex === 0;
    const prevPageDisabled = !FormTemplate.PageCount || PageIndex === 0;
    const nextPageDisabled = !FormTemplate.PageCount || PageIndex === FormTemplate.PageCount - 1;
    const lastPageDisabled = FormTemplate.PageCount < 3 || PageIndex === FormTemplate.PageCount - 1;

    let formNavBar = (
      <div className={classes.formNavBar}
        style={{
          backgroundColor: !GetLightMode(cookies)
            ? theme.palette.background.paper_dark
            : theme.palette.background.paper,
        }}
      >
        <Tooltip title="First page">
          <span>
          <IconButton
            style={{
              color: (firstPageDisabled) ? navButtonColorDisabled : navButtonColorPrimary,
            }}
            tabIndex={-1}
            disabled={firstPageDisabled}
            onClick={() => this.handleGoToPage(0)}>
            <NavigateFirstIcon />
          </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Previous page">
          <span>
          <IconButton
            style={{
              color: (prevPageDisabled) ? navButtonColorDisabled : navButtonColorPrimary,
            }}
            tabIndex={-1}
            disabled={prevPageDisabled}
            onClick={() => this.handleGoToPage(PageIndex - 1)}>
            <NavigatePrevIcon />
          </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Next page">
          <span>
          <IconButton
            style={{
              color: (nextPageDisabled) ? navButtonColorDisabled : navButtonColorPrimary,
            }}
            tabIndex={-1}
            disabled={nextPageDisabled}
            onClick={() => this.handleGoToPage(PageIndex + 1)}>
            <NavigateNextIcon />
          </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Last page">
          <span>
          <IconButton
            style={{
              color: (lastPageDisabled) ? navButtonColorDisabled : navButtonColorPrimary,
            }}
            tabIndex={-1}
            disabled={lastPageDisabled}
            onClick={() => this.handleGoToPage(FormTemplate.PageCount-1)}>
            <NavigateLastIcon />
          </IconButton>
          </span>
        </Tooltip>
        {goToPageButton}
        {/*currentPageNumber*/}
        {navBarEnd}
        {/*selectedFieldInfo*/}
      </div>
    );

    const currentFormTemplatePage = this.getFormTemplatePage();
    const pageImage = (currentFormTemplatePage && currentFormTemplatePage.UseImage
        && currentFormTemplatePage.ImageSignedUrl)
      ? (<img src={currentFormTemplatePage.ImageSignedUrl} className={classes.pageBackgroundImage} alt="" />)
      : null;

    let desktopComponent = (!UseMobileLayout)
      ? (
        <div 
          ref={instance => this.ScaleContainerRef = instance}
          className={classes.scaleContainer}
        >
          {formNavBar}
          <div className={classes.canvasContainer}
            ref={instance => this.CanvasContainerRef = instance}
          >
            <div 
              ref={instance => this.CanvasRef = instance}
              className={classes.canvas}
            >
              {pageImage}
              {formTemplateFields}
            </div>
          </div>
        </div>
      ) : null;
    let mobileComponent = (UseMobileLayout)
      ? (
        <div style={{ height:"100%" }}>
          {formNavBar}
          <div className={classes.canvasContainer}>
            <div className={classes.mobileContainer}>
              {formTemplateFieldMobileItems}
            </div>
          </div>
        </div>
      ) : null;

    return (FormTemplate)
    	? (
	    	<div
	    		ref={instance => this.OuterContainerRef = instance} 
	    		className={classes.outerContainer}
          style={{width:(UseMobileLayout) ? "100%" : undefined}}
    		>
	    		<MultiUseDialog Details={navigateToPageDialogDetails} />
          <MultiUseDialog Details={submitResultDialogDetails} />
          <MultiUseDialog Details={submitPageViewAlertDialogDetails} />
          <MultiUseDialog Details={submitRequiredFieldsAlertDialogDetails} />
          {progressIndicator}
	    		{desktopComponent}
          {mobileComponent}
	    	</div>
	  	)
	  	: null;
  }
}
      
export default withStyles(styles, { withTheme: true})(FormInput);
