import React, {Component} from 'react';

import PropTypes from 'prop-types';

// import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import Link from '@material-ui/core/Link';

import { withStyles } from '@material-ui/core/styles';

import Avatar from './Avatar';
import ProgressIndicator from './ProgressIndicator';

import API, {
  GetPublicUserProfilePathForApi,
} from '../Util/api';

const styles = theme => ({
  contentContainer: {
    display:"flex",
    flexDirection:"column",
    textAlign:"center",
    justifyContent:"center",
    minWidth:300,
    maxWidth:400,
    minHeight:250,
    maxHeight:300,
    backgroundColor:theme.palette.background.pane,
    padding:theme.spacing(3),
  },
});

class AvatarPopover extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      FinishedRemoteLoadAttempt: false,
      UserProfile: null,
    }
  }

  componentDidMount() {
    this.loadUserProfile(); 
  }

  loadUserProfile = () => {
    API.get(GetPublicUserProfilePathForApi(this.props.email))
      .then(resp => {
        this.setState({UserProfile:resp.data});
      })
      .catch(err => {})
      .finally(() => {
        this.setState({FinishedRemoteLoadAttempt:true});
      })
  }

  handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
  }

  render() {
    const {
      UserProfile,
      FinishedRemoteLoadAttempt,
    } = this.state;
    const {
      classes,
      // theme,
      anchorEl,
      onLinkClick,
      onClose,
      email,
    } = this.props;

    const userPhone = (UserProfile && UserProfile.UserPhone) ?
      (
        <Link
          style={{cursor:"pointer"}}
          onClick={e => onLinkClick(e, `sms:${UserProfile.UserPhone}`)}
          color="textPrimary"
        >
          {UserProfile.UserPhone}
        </Link>
      ) : null;

    const innerContent = (!FinishedRemoteLoadAttempt)
      ? ( 
        <ProgressIndicator showImmediately={!FinishedRemoteLoadAttempt} />
      )
      : (
          <React.Fragment>
            <Avatar
              email={email}
              name={(UserProfile && UserProfile.UserName)}
              size="profile"
            />
            {userPhone}
          </React.Fragment>
      );

    return (
      <Popover
        id="avatar-hover-popover"
        className={classes.popover}
        open
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={onClose}
        disableRestoreFocus
        style={{
          borderRadius:8,
        }}
      >
        <div className={classes.contentContainer} onClick={this.handleClick}>
          {innerContent}
        </div>
      </Popover>
    );
  }
}

AvatarPopover.propTypes = {
  classes: PropTypes.object.isRequired,
  anchorEl: PropTypes.object.isRequired,
  onLinkClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
};

export default withStyles(styles, {withTheme: true})(AvatarPopover);
