export const DefaultWidgetHeight = 250;

export const GetStatUTCKey = () => {
	const now = new Date();
	const tzOffset = now.getTimezoneOffset();
	if (tzOffset > 0 && tzOffset <= 720) {
		return `UTCn${Math.abs(Math.round(tzOffset/60))}`;
	}
	if (tzOffset >= -720 && tzOffset < 0) {
		return `UTCp${Math.abs(Math.round(tzOffset/60))}`;
	}
	return "UTC";
}