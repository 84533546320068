import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Card from '../../Components/Card';

import {
  GetDateValue,
  GetUserValue,
  GetBoolValue,
} from '../../Util/Properties';

class UserActivityCard extends Component {
  render() {
    const {
      LoginSession,
    } = this.props; 
    
    let metadata = [];
    
    metadata.push(
      ["Last Activity", GetDateValue(LoginSession.LastActivity)],
      // ["Created On", GetDateValue(LoginSession.CreatedOn)],
      ["Sign-in Provider", LoginSession.UserProvider],
      ["Fingerprint", LoginSession.BrowserFingerprint],
      ["Mobile", GetBoolValue(LoginSession.IsMobile)],
    );

    return (
      <Card
        name={GetUserValue(LoginSession.UserEmail, LoginSession.UserName, "lg")}
        metadata={metadata}
      />
    );
  }
}

UserActivityCard.propTypes = {
  LoginSession: PropTypes.object.isRequired,
  // onCardAction: PropTypes.func.isRequired,
};

export default UserActivityCard;