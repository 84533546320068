import { GetFieldForFilterAndSort } from '../Util/Field';

const AssetCollectionFields = [
	GetFieldForFilterAndSort(
		"Meta_date_str256[CreatedOn].string",
		"Created On",
		"FieldType_Date",
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[Name].keyword",
		"Name",
		"FieldType_Text",
		"Meta_text_kw256lc[Name]",
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[PluralName].keyword",
		"Plural Name",
		"FieldType_Text",
		"Meta_text_kw256lc[PluralName]",
	),
];

export default AssetCollectionFields;