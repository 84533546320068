import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import MultiUseDialog from '../Components/MultiUseDialog';
import ProgressIndicator from '../Components/ProgressIndicator';

import API, {
  GetProjectPathForApi,
} from '../Util/api';
import UiCore from '../Components/UiCore';
import {
  GetProjectAccessGridItems,
} from '../Util/Project';

const styles = theme => ({
  contentContainer: {
    background:theme.palette.background.paper,
    padding: theme.spacing(3),
    minHeight: "100%",
  },
  profileGrid: {
  },
  infoIcon: {
    // position:"absolute",
    // bottom: theme.spacing(1),
    // right: theme.spacing(1),
    opacity:0.5,
  },
});

class ProjectProfile extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      Project: JSON.parse(JSON.stringify(props.project)),
      ProjectAccess: JSON.parse(JSON.stringify(props.project.Access)),
      ShowAccessChangeConfirmationDialog: false,
      ShowProgressIndicatorImmediately: false,
    }
  }

  handleSetAccessChangeConfirmationVisibility = ShowAccessChangeConfirmationDialog => {
    this.setState({ ShowAccessChangeConfirmationDialog });
  }

  handleChangeProjectAccess = () => {
    this.handleSetAccessChangeConfirmationVisibility(false);
    const originalProject = {...this.state.Project};
    const updatedProject = {
      ...this.state.Project,
      Access: this.state.ProjectAccess,
    };
    this.setState({
      Project: JSON.parse(JSON.stringify(updatedProject)),
      ShowProgressIndicatorImmediately: true,
    });
    const params = {
      timezoneOffsetMinutes: (new Date()).getTimezoneOffset(),
    };
    API.put(GetProjectPathForApi(this.props.organizationId, this.state.Project.ID), updatedProject, { params })
      .then(() => {
        this.setState({ShowProgressIndicatorImmediately: false});
        window.location.reload();
      })
      .catch(err => {
        this.setState({
          Project: JSON.parse(JSON.stringify(originalProject)),
        });
        this.handleApiError(err);
      });
  }

  handleAssignmentsChange = propertyName => e => {
    let ProjectAccess = {...this.state.ProjectAccess};
    ProjectAccess.Assignments[propertyName] = e.target.value;
    this.setState({ ProjectAccess });
  }

  handleMemberAccessChange = propertyName => e => {
    let ProjectAccess = {...this.state.ProjectAccess};
    ProjectAccess.MemberAccess[propertyName] = e.target.value;
    this.setState({ ProjectAccess });
  }

  handleApiError = err => {
    if (this.props.onApiError) {
      this.setState({
        ApiError:err,
        ShowProgressIndicatorImmediately: false,
      });
      this.props.onApiError(err);
    }
  }

  handleAlert = details => {
    this.setState({Alert:details});
    if (this.props.onAlert) {
      this.props.onAlert(details);
    }
  }

  render() {
    const {
      Project,
      ProjectAccess,
      ApiError,
      Alert,
      ShowProgressIndicatorImmediately,
      ShowAccessChangeConfirmationDialog,
    } = this.state;
    const {
      // organizationId,
      theme,
      classes,
      returnContentOnly,
    } = this.props;

    const accessChangeConfirmationDialogDetails = {
      Open:ShowAccessChangeConfirmationDialog,
      IsConfirmation:true,
      Title:"Change permissions?",
      BodyText:"Are you sure you want to change this project's permissions? It will take some time to propagate the change to all items in the project.",
      BodyClassName:"warning",
      CancelCallback:() => this.handleSetAccessChangeConfirmationVisibility(false),
      CloseCallback:() => this.handleSetAccessChangeConfirmationVisibility(false),
      ConfirmCallback:() => this.handleChangeProjectAccess(),
    };

    const projectAccessGridItems = GetProjectAccessGridItems(
      classes,
      ProjectAccess,
      this.handleAssignmentsChange,
      this.handleMemberAccessChange,
      4,
    );

    const changeProjectAccessButtonGridItem = (JSON.stringify(Project.Access) !== JSON.stringify(ProjectAccess))
      ? (
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => this.handleSetAccessChangeConfirmationVisibility(true)}
          >
          UPDATE
          </Button>
        </Grid>
      ): null;

    const accessGrid = (
      <Grid container spacing={3} className={classes.profileGrid}>
        {projectAccessGridItems}
        {changeProjectAccessButtonGridItem}
      </Grid>
    );

    const progressIndicator = (ShowProgressIndicatorImmediately) ? (
      <ProgressIndicator showImmediately />
    ) : null;

    const content = (
      <Container maxWidth="md" className={classes.contentContainer}>
        {progressIndicator}
        <MultiUseDialog Details={accessChangeConfirmationDialogDetails} />
        <Typography variant="h6" style={{marginBottom:theme.spacing(2)}}>
          Project permissions
        </Typography>
        {accessGrid}
      </Container>
    );

    if (returnContentOnly) {
      return content;
    }

    return (
      <UiCore title="Project Profile"
        apiError={ApiError}
        alert={Alert}
        content={content}
      />
    );
  }
}

ProjectProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  project: PropTypes.object.isRequired,
  onApiError: PropTypes.func,
  onAlert: PropTypes.func,
  returnContentOnly: PropTypes.bool,
};

export default withStyles(styles, {withTheme: true})(ProjectProfile);