import {
  GetSingleUserPreference_Object,
  SaveSingleUserPreference_Object,
} from './SingleUserPreference.js';

export const GetCurrentSort = (idPrefix, idSubPrefix) => {
  if (idPrefix) {
    return GetSingleUserPreference_Object(`${idPrefix}${(idSubPrefix) ? idSubPrefix : ""}Sort`)
      .then(sort => {
        return sort;
      });
  }
}

export const SaveSort = (idPrefix, idSubPrefix, SortType, SortDescending) => {
  let sort = {
    SortType,
    SortDescending,
  };
  return SaveSingleUserPreference_Object(`${idPrefix}${(idSubPrefix) ? idSubPrefix : ""}Sort`, sort);
}

export const CompareSortTypeObject = (a, b) => {
  if (a.LabelOrName < b.LabelOrName) {
    return -1;
  } else if (a.LabelOrName > b.LabelOrName) {
    return 1;
  }
  else return 0;
}