import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import Slide from '@material-ui/core/Slide';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import PropTypes from 'prop-types';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import MultiUseDialog from './MultiUseDialog';
import ProgressIndicator from './ProgressIndicator';
import FolderHierarchyPropertiesDialog from './FolderHierarchyPropertiesDialog';

import API from '../Util/api';

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(2),
    position: "sticky",
    top: 0,
    zIndex: 11,
  },
  highlight: {
    backgroundColor: theme.palette.type === "dark" ? "#303030" : "#e0e0e0",
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },  
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes, handleAdd, handleDelete } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}

    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Folder Groups
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {
          numSelected > 0
          ? (
            <Tooltip title="Delete">
              <IconButton aria-label="Delete" onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            )
          : (
            <Tooltip title="Add">
              <IconButton aria-label="Add" onClick={handleAdd}>
                <AddIcon />
              </IconButton>
            </Tooltip>
            )
        }
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  tableHead: {
    position: "sticky",
    top: 64,
    zIndex: 10,
  },
  checkboxColumn: {
    width: 48,
  },
  tableRow: {
    cursor:"pointer",
  },
});

// const ConfigureTransition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="left" ref={ref} {...props} />;
// });

class ConfigureFolderHierarchiesDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      FolderHierarchies: [],
      Selected: [],
      ShowProgressIndicatorImmediately: false,
      ShowDialogProgressIndicator: false,
      DialogFolderHierarchy: {},
      DialogFolderHierarchyIsNew: false,
      ShowDeleteConfirmation: false,
      FolderHierarchyPropertiesDialogIsOpen: false,
    }
  }

  handleClose(callback) {
    this.setState({ FolderHierarchies: [], open: false });
    if (typeof callback !== "undefined" && callback) {
      callback();
    }
  }

  handleApiError = err => {
    this.setState({ShowDeleteConfirmation: false, ShowProgressIndicatorImmediately: false, ShowDialogProgressIndicator: false});
    this.props.onApiError(err);
  }

  handleLoadFolderHierarchies() {
    this.setState({ShowProgressIndicatorImmediately:true});
    API.get("/folderHierarchies", 
      {
        params: { getAll: true }
      })
      .then(resp => {
        this.setState({ FolderHierarchies: resp.data.FolderHierarchies, Selected: [], ShowProgressIndicatorImmediately: false, });
    })
      .catch(this.handleApiError);
  }

  NewFolderHierarchy = {
    ID: "",
    Name: "",
  };
  
  handleAddFolderHierarchy() {
    let newFolderHierarchy = {...this.NewFolderHierarchy};
    newFolderHierarchy.Name = "Folder Group";// " + (new Date().getTime()/1000|0);
    API.post("/admin/folderHierarchies", [newFolderHierarchy])
      .then(resp => {
        this.handleOpenFolderHierarchyPropertiesDialog(resp.data[0], true);
      })
      .catch(this.handleApiError);
  }

  handleSelect(id)
  {
    let selected = [...this.state.Selected];
    const selectedIndex = selected.indexOf(id);

    if (selectedIndex === -1) {
      selected = selected.concat(id);
    } else {
      selected.splice(selectedIndex, 1);
    }
    this.setState({ Selected: selected });
  }
  
  handleSelectAll(event) {
    let folderHierarchies = [...this.state.FolderHierarchies];
    let selected = [];
    if (!event.target.checked) {
      this.setState({ Selected: [] });
      return;
    }
    for (let i = 0; i < folderHierarchies.length; i++) {
      selected = selected.concat(folderHierarchies[i].ID)
    }
    this.setState({ Selected: selected });
  }

  handleSetDeleteConfirmationVisibility(show) {
    this.setState({ ShowDeleteConfirmation: show });
    this.setState({ ShowDialogProgressIndicator: false });
  }

  handleDelete(folderHierarchyID) {
    this.setState({ShowDialogProgressIndicator:true});
    let selected = [];
    if (folderHierarchyID) {
      selected.push(folderHierarchyID);
    } else {
      if (this.state.Selected.length < 1) {
        return;
      }
      selected = this.state.Selected;
    }
    return API.delete("/admin/folderHierarchies", { data: { IDs: selected } })
      .then(resp => {
        this.handleLoadFolderHierarchies();
        this.handleSetDeleteConfirmationVisibility(false);
      })
      .catch(this.handleApiError);
  }

  handleOpenFolderHierarchyPropertiesDialog(folderHierarchy, isNew) {
    this.setState({FolderHierarchyPropertiesDialogIsOpen: true, DialogFolderHierarchy: folderHierarchy, DialogFolderHierarchyIsNew: isNew});
  }

  handleCloseFolderHierarchyPropertiesDialog(folderHierarchyID, isNew, isCloseOrCancel) {
    this.setState({FolderHierarchyPropertiesDialogIsOpen: false});
    if (isNew && isCloseOrCancel) {
      this.handleDelete(folderHierarchyID);
    } else {
      this.handleLoadFolderHierarchies();  
    }
  }

  componentDidMount() {
    this.handleLoadFolderHierarchies();
  }

  componentDidUpdate(prevProps) {
    // if (typeof this.props.open !== "undefined"
    //   && prevProps.open !== this.props.open) {
    //   if (this.props.open) {
    //     this.handleLoadFolderHierarchies();
    //   }
    //   this.setState({open: this.props.open !== false});
    // }
  }

  render() {
    const {
      open,
      FolderHierarchies,
      Selected,
      ShowProgressIndicatorImmediately,
      ShowDialogProgressIndicator,
      DialogFolderHierarchy,
      DialogFolderHierarchyIsNew,
      ShowDeleteConfirmation,
      FolderHierarchyPropertiesDialogIsOpen,
    } = this.state;
    const { 
      classes,
      closeCallback,
    } = this.props;

    let dialogActions = (
      <DialogActions>
        <Button onClick={() => this.handleClose(closeCallback)}>
          CLOSE
        </Button>
      </DialogActions>
    );

    let deleteConfirmationDialogDetails = {
      Open:ShowDeleteConfirmation,
      ShowProgressIndicator:ShowDialogProgressIndicator,
      IsConfirmation:true,
      Title:"Delete folder hierarchy?",
      BodyText:"This action cannot be undone.",
      BodyClassName:"warning",
      CancelCallback:() => this.handleSetDeleteConfirmationVisibility(false),
      CloseCallback:() => this.handleSetDeleteConfirmationVisibility(false),
      ConfirmCallback:() => this.handleDelete(),
    };

    let numSelected = Selected.length;
    let indeterminate = numSelected > 0 && numSelected < FolderHierarchies.length;
    let allSelected = numSelected > 0 && numSelected === FolderHierarchies.length;

    let progressIndicator = null;
    if (ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator showImmediately={true} />
      );
    }

    let table = null;
    if (FolderHierarchies.length > 0) {
      table = (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" className={classes.tableHead}>
                <Checkbox
                  color="secondary"
                  indeterminate={indeterminate}
                  checked={indeterminate || allSelected}
                  onChange={event => this.handleSelectAll(event)}
                />
              </TableCell>
              <TableCell className={classes.tableHead}>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {FolderHierarchies.map(f => {
              const isSelected = Selected.indexOf(f.ID) !== -1;
              return (
                <TableRow
                  className={classes.tableRow}
                  hover
                  onClick={event => this.handleOpenFolderHierarchyPropertiesDialog(f, false)}
                  tabIndex={-1}
                  key={f.ID}
                  selected={isSelected}
                >
                  <TableCell padding="checkbox" className={classes.checkboxColumn}>
                    <Checkbox checked={isSelected}
                      color="secondary"
                      onClick={(event) => { event.stopPropagation(); this.handleSelect(f.ID); }} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {f.Name}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      );
    }

    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        // TransitionComponent={ConfigureTransition}
        open={open}
        onClose={() => this.handleClose(closeCallback)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description">
        {/* <DialogTitle id="dialog-title"> */}
        {/*   <span>Folders</span> */}
        {/* </DialogTitle> */}
        <DialogContent>
          <MultiUseDialog Details={deleteConfirmationDialogDetails} />
          {progressIndicator}
         
          <FolderHierarchyPropertiesDialog
            open={FolderHierarchyPropertiesDialogIsOpen}
            onApiError={(err) => this.handleApiError(err)}
            closeCallback={isCloseOrCancel => this.handleCloseFolderHierarchyPropertiesDialog(
              DialogFolderHierarchy.ID, DialogFolderHierarchyIsNew, isCloseOrCancel)}
            folderHierarchy={DialogFolderHierarchy} />

          <Paper elevation={0} style={{ overflowY: "auto", width: "100%" }}>
            <EnhancedTableToolbar numSelected={numSelected}
              handleAdd={() => this.handleAddFolderHierarchy()}
              handleDelete={() => this.handleSetDeleteConfirmationVisibility(true)} />
            {table}
          </Paper>
          
        </DialogContent>
        {dialogActions}
      </Dialog>
    );
  }
}

ConfigureFolderHierarchiesDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(ConfigureFolderHierarchiesDialog);