import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { withStyles } from '@material-ui/core/styles';

import DashboardCard from '../DashboardCard';
import ProgressIndicator from '../ProgressIndicator';

import Icon from '@material-ui/icons/InsertDriveFile';
import IconColor from '@material-ui/core/colors/blueGrey';
import AddIcon from '@material-ui/icons/Add';

import ProjectCreationDialog from '../../Admin/Components/ProjectCreationDialog';
import API,
{
  GetOrganizationRecentlyAccessedDocumentsPathForApi,
} from '../../Util/api';
import {
  HandleSetShowProjectSelectionDialog,
  GetProjectSelectionDialog,
} from '../../Util/Projects';
import {
  OpenDocumentDetailTab,
} from '../../Util/Documents';
import {
  GetExtensionAndOverlayColorFromFilename,
} from '../../Util/Document';
import {
  GetProjectDocumentsPath,
} from '../../Util/PathHelper';
import { GlobalContext } from '../../Context/Global.context';

const styles = theme => ({
  list: {
    padding: 0,
    height: "100%",
    overflow: "auto",
    overflowX: "hidden",
    whiteSpace: "nowrap",
  },
  extensionIcon: {
    height:24,
    minWidth:36,
    maxWidth:48,
    overflow:"hidden",
    paddingLeft:8,
    paddingRight:8,
    display:"flex",
    alignItems:"center",
    lineHeight:1,
    fontWeight:600,
    borderTopRightRadius:6,
    borderBottomLeftRadius:6,
  },
});

class RecentlyAccessedDocumentsWidget extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      LoadComplete: false,
      RecentlyAccessedDocuments: (this.props.samples) ? this.props.samples : [],
      ShowProgressIndicator: false,
      ShowProjectSelectionDialog: false,
      ShowCreateProjectDialog: false,
    }

    this.AutoReloadID = null;
  }

  GetRecentlyAccessedDocuments() {
    this.setState({ShowProgressIndicator: true});
    API.get(GetOrganizationRecentlyAccessedDocumentsPathForApi(this.props.DashboardWidget.OrganizationID))
      .then(resp => {
        this.setState({ 
          RecentlyAccessedDocuments: resp.data.RecentlyAccessedDocuments, 
          LoadComplete: true,
        });
      })
      .catch(err => {
        // Ignore errors as this will be called again
        // this.props.onApiError(err);
      })
      .finally(() => {
        this.setState({ShowProgressIndicator: false});
        this.AutoReloadID = setTimeout(() => this.GetRecentlyAccessedDocuments(), 30000);
      });
  }

  handleRouteToDocument = rad => {
    OpenDocumentDetailTab(
      rad.OrganizationID,
      rad.ProjectID,
      rad.DocumentID,
    );
  }

  handleSetShowCreateProjectDialog = ShowCreateProjectDialog => {
    this.setState({ShowCreateProjectDialog});
  }

  componentDidMount() {
    if (!this.props.samples) {
      this.GetRecentlyAccessedDocuments();
    }
  }

  componentWillUnmount() {
    if (this.AutoReloadID) {
      clearTimeout(this.AutoReloadID);
    }
  }

  render() {
    const {
      LoadComplete,
      RecentlyAccessedDocuments,
      ShowProgressIndicator,
      ShowProjectSelectionDialog,
      ShowCreateProjectDialog,
    } = this.state;
    const { 
      classes,
      theme,
      organizationId,
      DashboardWidget,
      ...passThroughProps
    } = this.props;

    let recentlyAccessedDocumentsList;
    let noResultsMessage;
    if (RecentlyAccessedDocuments && RecentlyAccessedDocuments.length > 0) {
      let recentlyAccessedDocumentsListItems = [];
      for (let i = 0; i < RecentlyAccessedDocuments.length; i++) {
        const rad = RecentlyAccessedDocuments[i];
        const {
          extension,
          overlayColor,
        } = GetExtensionAndOverlayColorFromFilename(rad.DocumentName);
        recentlyAccessedDocumentsListItems.push(
          <ListItem key={`d_${rad.DocumentID}`} button onClick={() => this.handleRouteToDocument(rad)}>
            <ListItemIcon>
              <Typography variant="caption"
                className={classes.extensionIcon}
                style={{
                  color:"#fff",
                  backgroundColor: overlayColor,
                  opacity: (!extension) ? 0.2 : undefined,
                }}
              >
                {(extension) ? extension.toUpperCase() : ""}
              </Typography>
            </ListItemIcon>
            <ListItemText primary={rad.DocumentName} />
          </ListItem>
        );
      }
      recentlyAccessedDocumentsList = (recentlyAccessedDocumentsListItems.length)
        ? (
          <List dense className={classes.list}>
            {recentlyAccessedDocumentsListItems}
          </List>
        ) : null;
    } else if (LoadComplete) {
      noResultsMessage = (
        <Typography variant="body1" style={{
          display:"flex",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          textAlign:"center",
        }}>
        You don't have any recent documents.
      </Typography>
      );
    }

    const projectCreationDialog = (
      <ProjectCreationDialog
        organizationId={organizationId}
        open={ShowCreateProjectDialog}
        onClose={() => this.handleSetShowCreateProjectDialog(false)}
        onApiError={passThroughProps.onApiError}
      />
    );

    const projectSelectionDialog = (ShowProjectSelectionDialog)
      ? GetProjectSelectionDialog(
        this.context,
        null,
        this.state,
        state => this.setState(state),
        projectMember => this.props.history.push(GetProjectDocumentsPath(projectMember.ProjectID, true)),
        true,
        () => this.handleSetShowCreateProjectDialog(true),
      ) : null;

    const progressIndicator = (ShowProgressIndicator)
      ? (
        <ProgressIndicator constrained noBackground />
      )
      : null;

    const titleButtons = [
      (
        <Tooltip key="addDocument" title="Add a document">
          <IconButton
            size="small"
            onClick={() => HandleSetShowProjectSelectionDialog(state => this.setState(state), true)}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      ),
    ];

    return (
      <DashboardCard
        title="Recent Documents"
        titleIcon={<Icon style={{color: IconColor[500]}} />}
        titleButtons={titleButtons}
        xs={12}
        sm={6}
        md={6}
        lg={4}
        xl={4}
        noFadeout
        {...passThroughProps}
        DashboardWidget={DashboardWidget}
      >
        {recentlyAccessedDocumentsList}
        {noResultsMessage}
        {projectCreationDialog}
        {projectSelectionDialog}
        {progressIndicator}
      </DashboardCard>  
    );
  }
}

RecentlyAccessedDocumentsWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string,
  samples: PropTypes.array,
};

export default withStyles(styles, {withTheme: true})(RecentlyAccessedDocumentsWidget);