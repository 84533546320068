import React from 'react';
import { GetTextWithUrlsAsAnchors } from './Regex';

export function GetCommentForDisplay(comment, theme) {
	return comment
		.replace(/@\{\{\[(.*?)\]_\(.*?\)\}\}/g, "@$1 ")
		.split('\n')
		.map((item, index) => (
			<span key={index}>
				{(index > 0) ? <br /> : null}
				{GetTextWithUrlsAsAnchors(item, theme)}
			</span>
		));
}