import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Card from '../Components/Card';
import IconButton from '@material-ui/core/IconButton';

import ActionType from '../Model/ActionType';

import {
  GetFormattedDescription,
  GetDueOnStyle,
} from '../Util/Tasks';
import { IsMobile } from '../Util/MobileDetector';
import { GetRichTextEditorStyles } from '../Util/RichTextEditor';
import {
  GetUserValue,
  GetDateValue,
} from '../Util/Properties';
import {
  GetTaskPriorityLabel,
  GetTaskDurationAsString,
} from '../Util/Task';
import {
  GetTagsPreviewFromTagsObject,
} from '../Util/Tags';

const styles = theme => ({
  TaskDescription: {
    ...GetRichTextEditorStyles(theme),
  },
});

class TaskCard extends Component {
  render() {
    const {
      Task,
      primaryDocumentLinkComponent,
      style,
      onAction,
      noSelect,
      onSelect,
      selected,
      onCardAction,
      height,
      isSearchResults,
      disableCardActions,
      theme,
      classes,
    } = this.props;

    let markCompleteAction = (!Task.Result)
      ? (
        <IconButton
          key={ActionType.Task_Complete.Id}
          aria-label={ActionType.Task_Complete.Label}
          title={ActionType.Task_Complete.Label}
          onClick={() => onAction(ActionType.Task_Complete, Task.ID)}
        >
          {ActionType.Task_Complete.Icon}
        </IconButton>
      ) : undefined;
    const hoverActionItems = (onAction)
      ? [
        markCompleteAction,
      ] : null;

    let metadata = [
      ["Project", Task.ProjectName],
      ["Assigned to", GetUserValue(Task.AssignmentUserEmail, Task.AssignmentUserName, "sm")],
    ];

    if (Task.ProcessName) {
      metadata.push(["Process", Task.ProcessName]);
    }

    if (Task.ProcessElementName) {
      metadata.push(["Process Action", Task.ProcessElementName]);
    }

    metadata.push(
      ["Created On", GetDateValue(Task.CreatedOn)],
      ["Created By", GetUserValue(Task.CreatedByUserEmail, Task.CreatedByUserName, "sm")],
      ["Priority", GetTaskPriorityLabel(Task.Priority)],
      ["Milestone", Task.TaskMilestoneName],
      ["Task State", Task.TaskStateName],
      ["Tags", GetTagsPreviewFromTagsObject(Task)],
      ["Due On", GetDateValue(Task.DueOn), (!Task.Result) ? GetDueOnStyle(Task.DueOn) : undefined],
      ["Duration", GetTaskDurationAsString(Task.DurationMultiplier || Task.TaskDurationMultiplier, Task.DurationInterval || Task.TaskDurationInterval)],
    );

    if (Task.Result === "Complete") {
      metadata.push(
        ["Completed On", GetDateValue(Task.CompletedOn)],
        ["Completed By", GetUserValue(Task.CompletedByUserEmail, Task.CompletedByUserName, "sm")],
      );
    }

    if (primaryDocumentLinkComponent) {
      metadata.push(["Primary Document", primaryDocumentLinkComponent]);
    }

    return (
      <Card
        style={style}
        hoverActionItems={(!disableCardActions) ? hoverActionItems : undefined}
        onCardAction={onCardAction}
        noSelect={noSelect}
        onSelect={onSelect}
        selected={selected}
        height={(height) ? height : (!IsMobile()) ? 450 : undefined}
        maxHeight={(IsMobile()) ? 1000 : undefined}
        translucentActionBar
        name={Task.Name}
        metadata={metadata}
        isSigned={Task.PrimaryDocument && Task.PrimaryDocument.IsSigned}
        fileName={Task.PrimaryDocument && Task.PrimaryDocument.Name}
        thumbnailUrl={Task.PrimaryDocument && Task.PrimaryDocument.ThumbnailUrl}
        description={GetFormattedDescription(Task, theme, classes)}
        isSearchResults={isSearchResults}
        contentItem={Task}
      />
    );
  }
}

TaskCard.propTypes = {
  primaryDocumentLinkComponent: PropTypes.object,
  Task: PropTypes.object.isRequired,
  onCardAction: PropTypes.func.isRequired,
  disableCardActions: PropTypes.bool,
};

export default withStyles(styles, {withTheme: true})(TaskCard);