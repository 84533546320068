import Collection from '../Model/Collection';

import ProjectProfile from '../Screens/ProjectProfile';

export class ProjectProfile_Collection extends Collection {
  constructor(props, onSetState, onApiError, onAlert, organizationId, project) {
    if (typeof organizationId !== "string") {
      console.log("Warning: organizationId required and not found");
    }
    if (!project) {
      console.log("Warning: project required and not found");
    }
    super(props, onSetState, onApiError, false, true, organizationId, project.ID);

    this.PassThroughComponent = (
      <ProjectProfile
        organizationId={organizationId}
        project={project}
        onApiError={onApiError}
        onAlert={onAlert}
        returnContentOnly
      />
    );
  }
}