import Collection from '../Model/Collection';

import ProjectComments from '../Screens/ProjectComments';

export class ProjectComments_Collection extends Collection {
  constructor(props, onSetState, onApiError, onAlert, organizationId, projectId) {
    if (typeof organizationId !== "string") {
      console.log("Warning: organizationId required and not found");
    }
    if (typeof projectId !== "string") {
      console.log("Warning: projectId required and not found");
    }
    super(props, onSetState, onApiError, false, true, organizationId, projectId);

    this.PassThroughComponent = (
      <ProjectComments
        organizationId={organizationId}
        projectId={projectId}
        onApiError={onApiError}
        onAlert={onAlert}
        returnContentOnly
      />
    );
  }
}