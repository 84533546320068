import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Card from '../Components/Card';
// import IconButton from '@material-ui/core/IconButton';

// import ActionType from '../Model/ActionType';

// import {
//   GetFormattedDescription,
// } from '../Util/Tasks';
// import { IsMobile } from '../Util/MobileDetector';
import { GetRichTextEditorStyles } from '../Util/RichTextEditor';
import {
  GetUserValue,
  // GetDateValue,
} from '../Util/Properties';
import {
  GetDueOnStyle,
} from '../Util/Tasks';
// import { GetTaskPriorityLabel } from '../Util/Task';

import { DragSource, DropTarget } from 'react-dnd';

/**
 * Implements the drag source contract.
 */
const taskSource = {
  canDrag(props) {
    return true;
  },
  beginDrag(props, monitor, component) {
    const TaskIndex = props.TaskIndex;
    props.onStartMoveTask(TaskIndex);
    return {
      Task: props.Task,
      TaskIndex,
      selectedTaskIds: props.selectedTaskIds,
      onMoveTaskOverColumn: props.onMoveTaskOverColumn,
      // onDropTask: props.onDropTask,
    };
  },
  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      props.onAbortMoveTask();
    } else {
      props.onEndMoveTask();
    }
  }
};

/**
 * Specifies the drop target contract.
 * All methods are optional.
 */
const taskTarget = {
  // drop(props, monitor, component) {
  //   if (monitor.didDrop()) {
  //   //   // If you want, you can check whether some nested
  //   //   // target already handled drop
  //     return;
  //   }

  //   // Obtain the dragged item
  //   const sourceTask = monitor.getItem();
  //   sourceTask.onDropTask();

  //   // You can also do nothing and return a drop result,
  //   // which will be available as monitor.getDropResult()
  //   // in the drag source's endDrag() method
  //   //return { moved: true };
  // },
  hover(props, monitor, component) {
    if (!component)
      return null;

    // const sourceClientOffset = monitor.getClientOffset();
    // console.log("sourceClientOffset", sourceClientOffset);

    // Obtain the dragged item
    const sourceTask = monitor.getItem();
    props.onMoveTaskOverTask(sourceTask.Task/*, sourceClientOffset.y*/);
  },
}

/**
 * Specifies the props to inject into your component.
 */
function dragCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    // isDragging: monitor.isDragging(),
  };
}

/**
 * Specifies which props to inject into your component.
 */
function dropCollect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    // isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    // canDrop: monitor.canDrop(),
    // itemType: monitor.getItemType()
  };
}

const styles = theme => ({
  TaskDescription: {
    ...GetRichTextEditorStyles(theme),
  },
  dueOnDot: {
    width:6,
    height:6,
    borderRadius:"50%",
    position:"absolute",
    top:0,
    right:0,
    zIndex:0,
  },
});

class TaskCardForKanban extends Component {
  render() {
    const {
      Task,
      hideAssignment,
      // primaryDocumentLinkComponent,
      // style,
      // onAction,
      noSelect,
      onSelect,
      selected,
      onCardAction,
      isDragLayer,
      isMultiTaskDrag,
      // height,
      isSearchResults,
      // theme,
      classes,
      connectDragSource,
      connectDropTarget,
    } = this.props;

    const extraContent = (new Date(Task.DueOn) > 0)
      ? <div className={classes.dueOnDot} style={GetDueOnStyle(Task.DueOn, "backgroundColor")} />
      : null;

    // const markCompleteAction = (!Task.Result)
    //   ? (
    //     <IconButton
    //       key={ActionType.Task_Complete.Id}
    //       aria-label={ActionType.Task_Complete.Label}
    //       title={ActionType.Task_Complete.Label}
    //       onClick={() => onAction(ActionType.Task_Complete, Task.ID)}
    //     >
    //       {ActionType.Task_Complete.Icon}
    //     </IconButton>
    //   ) : undefined;
    // const hoverActionItems = (onAction)
    //   ? [
    //     markCompleteAction,
    //   ] : null;

    let metadata = [];

    if (!isMultiTaskDrag && !hideAssignment && Task.AssignmentUserEmail) {
      metadata.push(
        ["", GetUserValue(Task.AssignmentUserEmail, Task.AssignmentUserName, "sm")],
      );
      // metadata.push(
      //   ["Created On", GetDateValue(Task.CreatedOn)],
      //   ["Created By", GetUserValue(Task.CreatedByUserEmail, Task.CreatedByUserName, "sm")],
      //   ["Priority", GetTaskPriorityLabel(Task.Priority)],
      //   ["Due On", GetDateValue(Task.DueOn)],
      // );
    }

    return (
      <Card
        ref={instance => connectDropTarget(connectDragSource(ReactDOM.findDOMNode(instance)))}
        style={{
          opacity: (!isDragLayer && Task.IsDragging) ? 0 : undefined,
        }}
        // hoverActionItems={hoverActionItems}
        onCardAction={onCardAction}
        noSelect={noSelect}
        onSelect={onSelect}
        selected={selected}
        checkboxOnly
        smallName
        noThumbnail
        height={72}
        disableRipple
        name={(isMultiTaskDrag) ? "Multiple tasks..." : Task.Name}
        metadata={metadata}
        isSigned={Task.PrimaryDocument && Task.PrimaryDocument.IsSigned}
        fileName={Task.PrimaryDocument && Task.PrimaryDocument.Name}
        thumbnailUrl={Task.PrimaryDocument && Task.PrimaryDocument.ThumbnailUrl}
        isSearchResults={isSearchResults}
        contentItem={Task}
        extraContent={extraContent}
      />
    );
  }
}

TaskCardForKanban.propTypes = {
  primaryDocumentLinkComponent: PropTypes.object,
  Task: PropTypes.object.isRequired,
  selectedTaskIds: PropTypes.array,
  hideAssignment: PropTypes.bool,
  isDragLayer: PropTypes.bool,
  isMultiTaskDrag: PropTypes.bool,
  onCardAction: PropTypes.func.isRequired,
  onStartMoveTask: PropTypes.func.isRequired,
  onAbortMoveTask: PropTypes.func.isRequired,
  onEndMoveTask: PropTypes.func.isRequired,
  onMoveTaskOverTask: PropTypes.func.isRequired,
};

export default DropTarget('TaskCardForKanban', taskTarget, dropCollect)(DragSource('TaskCardForKanban', taskSource, dragCollect)(withStyles(styles, {withTheme: true})(TaskCardForKanban)));