import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import { GoogleLogin } from '@react-oauth/google';
import MicrosoftLogin from "react-microsoft-login";
import { LinkedIn } from 'react-linkedin-login-oauth2';
import AppleSignin from 'react-apple-signin-auth';

import getBrowserFingerprint from 'get-browser-fingerprint';

import API from '../Util/api';

import Button from '@material-ui/core/Button';
import MaskedTextField from '../Components/MaskedTextField';
import { SMSNumberControl, GetSMSCountryCodeAndNumberIsValid } from '../Components/SMSNumberControl';
import MultiUseDialog from '../Components/MultiUseDialog';
import ProgressIndicator from '../Components/ProgressIndicator';
import { ValidateEmail } from '../Util/Regex';
import { IsMobile } from '../Util/MobileDetector';

// import './Login.css';

const paneAndLinksColor = "#eee";
const styles = theme => ({
  root: {
    width:"100%",
    height:"100%",
    backgroundImage: "url('/bg3.jpg')",
    backgroundRepeat:"no-repeat",
    backgroundAttachment:"fixed",
    backgroundSize:"cover",
    overflowY:"auto",
  },
  container: {
    marginTop:64,
    [theme.breakpoints.down('xs')]: {
      marginTop:theme.spacing(2),
    },
  },
  paper: {
    backgroundColor: paneAndLinksColor,
    padding:theme.spacing(4),
    borderRadius:theme.spacing(2),
  },
  logo: {
    width:"100%",
    marginBottom:theme.spacing(2),
  },
  gridControls: {
    textAlign:"center",
  },
  button: {
    textTransform:"none",
  },
  or: {
  },
  gridLinks: {
    color:paneAndLinksColor,
    marginTop:theme.spacing(1),
    justifyContent: "center",
  },
  divider:{
    backgroundColor:"#aaa",
  }
});

const _browserFingerprint = getBrowserFingerprint(true);

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToReferrer: false,
      Email: "",
      EmailLoginOptions: {},
      SignInPackage: {},
      MicrosoftSigningIn: false,
      ShowEmailAddressConfirmationDialog: false,
      EmailConfirm: "",
      ShowAuthTypeDialog: false,
      AuthTypes: [],
      AuthType: "",
      ShowSMSNumberDialog: false,
      SMSNumber: "",
      ShowSMSNumberConfirmationDialog: false,
      SMSNumberConfirmation: "",
      ShowOTPGatherDialog: false,
      OTP: "",
      GeneralDialogDetails: {
        Open: false,
      },
      GoogleButtonWidth: null,
      ShowProgressIndicatorImmediately: false,
      ShowDialogProgressIndicatorImmediately: false,
    }

    window.name = "n1main";
    this.ButtonGridSpacing = 1;
    this.ButtonGridRef = React.createRef();
  }

  showGeneralDialog = (title, bodyText, closeCallback_optional) => {
    this.setState({ 
      GeneralDialogDetails: {
        Open: true,
        Title: title,
        BodyText: bodyText,
        CloseCallback: () => this.handleCloseGeneralDialog(closeCallback_optional),
      },
    });
  }

  handleApiError = err => {
    // console.log(err.response.status);
    // console.log(err.response.data.message);

    this.setState({
      MicrosoftSigningIn: false,
      ShowProgressIndicatorImmediately: false,
      ShowDialogProgressIndicatorImmediately: false,
    });

    if (typeof err.response === "undefined") {
      err = {
        response: {
          status: 500,
          data: {
          message: err.toString(),
          },
        },
      };
    }

    switch(err.response.status) {
      case 401:
      case 500:
      default:
        this.showGeneralDialog(
          "Sorry, something went wrong...",
          (err.response.data.message) ? err.response.data.message : "Please try again.",
          err.closeCallback,
        );
        break;
    }
  }

  handleCloseGeneralDialog = closeCallback_optional => {
    this.setState({ GeneralDialogDetails: { Open: false }});
    if (closeCallback_optional) {
      closeCallback_optional();
    }
  }

  buildUpSignInPackage = newProperties => {
    let SignInPackage = {
      ...this.state.SignInPackage,
      BrowserFingerprint: _browserFingerprint,
      IsMobile: IsMobile(),
    };
    if (newProperties) {
      SignInPackage = {...SignInPackage, ...newProperties};
    }
    this.setState({SignInPackage});
    return SignInPackage;
  }

  handleEmailLoginVerify = () => {
    if (!this.state.Email) {
      return;
    }
    if (!ValidateEmail(this.state.Email)) {
      this.showGeneralDialog(
        "E-mail login",
        "Please enter a valid e-mail address.",
      );
      return;
    }

    this.setState({ShowProgressIndicatorImmediately: true});

    let signInPackage = this.buildUpSignInPackage({
      UserProvider: "email",
      Email: this.state.Email,
    });

    API.post("/user/emailLoginVerify", signInPackage)
      .then(resp => {
        let EmailLoginOptions = resp.data;
        let AuthTypes = [
          {
            Type: "email",
            Label: "E-mail",
          },
        ];
        if (EmailLoginOptions.SMSNumberLast2 || !EmailLoginOptions.UserExists) {
          AuthTypes.push({
            Type: "sms",
            Label: "Text Message",
          });
        }

        this.setState({
          EmailLoginOptions,
          AuthTypes,
        });

        this.setState({ShowProgressIndicatorImmediately: false});

        if (EmailLoginOptions.UserExists) {
          this.doAuthTypeStep(AuthTypes);
        } else {
          this.handleSetEmailConfirmationDialogVisibility(true);
        }
      })
      .catch(this.handleApiError);
  }

  handleSetEmailConfirmationDialogVisibility = visible => {
    let stateToUpdate = {
      ShowEmailAddressConfirmationDialog: visible,
      ShowDialogProgressIndicatorImmediately: false,
    };
    if (visible) {
      stateToUpdate.EmailConfirm = "";
    }
    this.setState(stateToUpdate);
  }

  handleEmailConfirmation = () => {
    if (!this.state.EmailConfirm) {
      return;
    }
    if (this.state.Email.toLowerCase() !== this.state.EmailConfirm.toLowerCase()) {
      this.showGeneralDialog(
        "E-mail confirmation",
        "E-mail address doesn't match.",
      );
      return;
    }
    this.handleSetEmailConfirmationDialogVisibility(false);
    this.doAuthTypeStep();
  }

  doAuthTypeStep = authTypes => {
    if (!authTypes) {
      authTypes = this.state.AuthTypes;
    }

    if (authTypes.length > 1) {
      this.handleSetAuthTypeDialogVisibility(true);
    } else {
      this.handleSendOTP(authTypes[0].Type);
    }
  }

  handleSetAuthTypeDialogVisibility = visible => {
    this.setState({
      ShowAuthTypeDialog: visible,
      ShowDialogProgressIndicatorImmediately: false,
    });
  }

  handleSetAuthType = authType => {
    this.setState({AuthType: authType});
    switch (authType) {
      case "sms":
        if (!this.state.EmailLoginOptions.UserExists) {
          this.handleSetAuthTypeDialogVisibility(false);
          this.handleSetSMSNumberDialogVisibility(true);
        } else if (this.state.EmailLoginOptions.SMSNumberLast2) {
          this.handleSendOTP(authType);
        } else {
          this.handleApiError("There is no phone number associated with your account. Please choose a different authentication mechanism.");
        }
        break;
      default:
        this.handleSendOTP(authType);
        break;
    }
  }

  handleSetSMSNumberDialogVisibility = visible => {
    this.setState({
      SMSNumberDialogSMSNumber: "",
      ShowSMSNumberDialog: visible,
      ShowDialogProgressIndicatorImmediately: false,
    });
  }

  handleSetSMSNumberConfirmationDialogVisibility = visible => {
    this.setState({
      SMSNumberDialogSMSNumber: "",
      ShowSMSNumberConfirmationDialog: visible,
      ShowDialogProgressIndicatorImmediately: false,
    });
  }

  handleSMSNumberDialogSMSNumberValueChange = smsNumber => {
    this.setState({ SMSNumberDialogSMSNumber: smsNumber });
  }

  handleValidateSMSNumber = () => {
    if (!this.state.SMSNumberDialogSMSNumber) {
      return;
    }
    if (!GetSMSCountryCodeAndNumberIsValid(this.state.SMSNumberDialogSMSNumber)) {
      this.handleApiError("Please enter a valid phone number.");
      return;
    } 
    this.setState({SMSNumber: this.state.SMSNumberDialogSMSNumber});
    this.handleSetSMSNumberDialogVisibility(false);
    this.handleSetSMSNumberConfirmationDialogVisibility(true);
  }

  handleSMSNumberConfirmation = () => {
    if (!this.state.SMSNumberDialogSMSNumber) {
      return;
    }
    let smsNumber = this.state.SMSNumberDialogSMSNumber;
    if (smsNumber !== this.state.SMSNumber) {
      this.handleApiError("Phone number doesn't match.");
      return;
    }
    this.handleSetSMSNumberConfirmationDialogVisibility(false);
    this.handleSendOTP();
  }

  handleNavigateBackFromSMSNumberDialog = () => {
    this.handleSetSMSNumberDialogVisibility(false);
    this.handleSetAuthTypeDialogVisibility(true);
  }

  handleNavigateBackFromSMSNumberConfirmationDialog = () => {
    this.handleSetSMSNumberConfirmationDialogVisibility(false);
    this.handleSetSMSNumberDialogVisibility(true);
  }

  handleSendOTP = authType => {
    this.handleSetOTPGatherDialogVisibility(false);
    if (authType) {
      this.setState({AuthType: authType});
    } else {
      authType = this.state.AuthType;
    }

    this.setState({ShowProgressIndicatorImmediately: true});

    let signInPackage = this.buildUpSignInPackage({
      AuthType: authType,
      SMSNumber: (!this.state.EmailLoginOptions.UserExists
                    && authType === "sms"
                    && this.state.SMSNumber)
                  ? this.state.SMSNumber.trim()
                  : undefined,
    });

    API.post("/user/emailLoginOTPSend", signInPackage)
      .then(resp => {
        this.setState({ShowProgressIndicatorImmediately: false});
        this.handleSetSMSNumberDialogVisibility(false);
        this.handleSetAuthTypeDialogVisibility(false);
        this.handleSetOTPGatherDialogVisibility(true);
      })
      .catch(this.handleApiError);
  }

  handleSetOTPGatherDialogVisibility = visible => {
    this.setState({
      ShowOTPGatherDialog: visible,
      ShowDialogProgressIndicatorImmediately: false,
      OTP: "",
    });
  }

  handleEmailLoginCompletion = () => {
    if (!this.state.OTP) {
      return;
    }

    this.setState({ShowDialogProgressIndicatorImmediately: true});
    
    let signInPackage = this.buildUpSignInPackage({
      OTP: this.state.OTP,
    });

    this.doLogin(signInPackage)
      .then(() => {
        this.handleSetOTPGatherDialogVisibility(false);
      })
      .catch(this.handleApiError);
  }

  handleGoogleLoginResponse = resp => {
    if (!resp || !resp.credential)
      return;

    this.setState({ShowProgressIndicatorImmediately: true});
  
    let signInPackage = this.buildUpSignInPackage({
      UserProvider: "google",
      OAuthIdToken: resp.credential,
    });

    this.doLogin(signInPackage)
      .catch(this.handleApiError);
  }

  handleMicrosoftLoginResponse = (err, authData, msalInstance) => {
    // console.log("handleMicrosoftLoginResponse", this.state.MicrosoftSigningIn, err, authData, msalInstance);
    if (!this.state.MicrosoftSigningIn) {
      return;
    }
    if (err) {
      this.setState({MicrosoftSigningIn:false});
      // this.handleApiError(err);
      return;
    }

    // console.log(authData);

    this.setState({ ShowProgressIndicatorImmediately: true });
  
    let signInPackage = this.buildUpSignInPackage({
      UserProvider: "microsoft",
      OAuthIdToken: authData.idToken.rawIdToken,
      OAuthIssuer: authData.idToken.issuer,
    });

    this.doLogin(signInPackage)
      .then(() => {
        this.setState({MicrosoftSigningIn: false});
      })
      .catch(this.handleApiError);
  }

  handleLinkedInLoginResponse = code => {
    if (!code)
      return;

    this.setState({ShowProgressIndicatorImmediately: true});
  
    let signInPackage = this.buildUpSignInPackage({
      UserProvider: "linkedin",
      OAuthRedirectUri: `${window.location.href}/linkedin`,
      OAuthAuthCode: code,
    });

    this.doLogin(signInPackage)
      .catch(this.handleApiError);
  }

  handleAppleSignInResponse = response => {
    if (!response || !response.authorization)
      return;

    this.setState({ShowProgressIndicatorImmediately: true});
  
    let signInPackage = this.buildUpSignInPackage({
      UserProvider: "apple",
      OAuthRedirectUri: window.location.href,
      OAuthAuthCode: response.authorization.code,
      OAuthIdToken: response.authorization.id_token,
    });
    if (response.user && response.user.name) {
      signInPackage.OAuthUserName = `${response.user.name.firstName} ${response.user.name.lastName}`;
    }

    this.doLogin(signInPackage)
      .catch(this.handleApiError);
  }

  doLogin = signInPackage => {
    return API.post("/user/login", signInPackage)
      .then(resp => {
        if (resp.status === 200) {
          this.setState({ redirectToReferrer: true });
        }
        return resp;
      });
  }

  handleWindowResize = () => {
    if (this.ButtonGridRef && this.ButtonGridRef.clientWidth) {
      this.setState({
        GoogleButtonWidth:
          this.ButtonGridRef.clientWidth - this.props.theme.spacing(this.ButtonGridSpacing || 0),
      });
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();

    // Clear all local-storage properties, currently used for per-session context
    localStorage.clear();

    if (this.props.match && this.props.match.params) {
      let UniqueID = this.props.match.params.uniqueID;
      let OTP = this.props.match.params.OTP;
      if (UniqueID && OTP) {
        let signInPackage = this.buildUpSignInPackage({
          UserProvider: "email",
          AuthType: "emailLink",
          UniqueID,
          OTP,
        });

        this.doLogin(signInPackage)
          .catch(err => {
            err.closeCallback = () => this.props.history.push("/login");
            this.handleApiError(err);
          });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    const {
      redirectToReferrer,
      Email,
      EmailLoginOptions,
      ShowEmailAddressConfirmationDialog,
      EmailConfirm,
      ShowAuthTypeDialog,
      AuthTypes,
      AuthType,
      ShowSMSNumberDialog,
      ShowSMSNumberConfirmationDialog,
      ShowOTPGatherDialog,
      OTP,
      MicrosoftSigningIn,
      GeneralDialogDetails,
      GoogleButtonWidth,
      ShowProgressIndicatorImmediately,
      ShowDialogProgressIndicatorImmediately,
     } = this.state;
    const {
      classes,
      theme,
      match,
    } = this.props;

    if (redirectToReferrer === true) {
      return (
        <Redirect to={from} />
      );
    }

    let progressIndicator = null;
    if (ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    let authTypeButtonGridItems = (AuthTypes.length) 
      ? AuthTypes.map(at => (
        <Grid item key={`b_${at.Type}`}>
          <Button
            variant="contained" 
            color="secondary" 
            fullWidth
            onClick={() => this.handleSetAuthType(at.Type)}
          >
            {`${at.Label}${(at.Type === "sms" && EmailLoginOptions.SMSNumberLast2) ? " TO NUMBER ENDING IN " + EmailLoginOptions.SMSNumberLast2 : ""}`}
          </Button>
        </Grid>
      )) : null;
    
    let emailAddressConfirmationDialogDetails = {
      Open: ShowEmailAddressConfirmationDialog,
      ShowProgressIndicatorImmediately: ShowDialogProgressIndicatorImmediately,
      Title: "Authentication",
      IsConfirmation: true,
      BodyContent: (
        <Grid container direction="column" spacing={3} style={{marginBottom:0}}>
          <Grid item>
            Confirm your e-mail address.
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              fullWidth
              autoFocus
              onChange={e => this.setState({EmailConfirm:e.target.value})}
              onKeyDown={e => { if (e.keyCode === 13) { e.preventDefault(); this.handleEmailConfirmation(); } }}
              value={EmailConfirm}
            />
          </Grid>
        </Grid>
      ),
      ConfirmCallback: this.handleEmailConfirmation,
      CancelCallback: () => this.handleSetEmailConfirmationDialogVisibility(false),
      CloseCallback: () => this.handleSetEmailConfirmationDialogVisibility(false),
    };

    let authTypeDialogDetails = {
      Open: ShowAuthTypeDialog,
      ShowProgressIndicatorImmediately: ShowDialogProgressIndicatorImmediately,
      Title: "Authentication",
      DialogWidth:"xs",
      BodyContent: (
        <Grid container direction="column" spacing={3} style={{marginBottom:0}}>
          <Grid item>
            Where would you like to get your one-time code?
          </Grid>
          { authTypeButtonGridItems }
        </Grid>
      ),
      CloseCallback: () => this.handleSetAuthTypeDialogVisibility(false),
    };

    let smsNumberDialogDetails = {
      Open: ShowSMSNumberDialog,
      ShowProgressIndicatorImmediately: ShowDialogProgressIndicatorImmediately,
      Title: "Authentication",
      IsConfirmation: true,
      BodyContent: (
        <Grid container direction="column" spacing={3} style={{marginBottom:0}}>
          <Grid item>
            Enter your phone number.
          </Grid>
          <Grid item>
            <SMSNumberControl
              onEnterKey={this.handleValidateSMSNumber}
              onValueChange={this.handleSMSNumberDialogSMSNumberValueChange}
            />
          </Grid>
        </Grid>
      ),
      CancelLabel: "BACK",
      ConfirmLabel: "NEXT",
      ConfirmCallback: this.handleValidateSMSNumber,
      CancelCallback: this.handleNavigateBackFromSMSNumberDialog,
      CloseCallback: () => this.handleSetSMSNumberDialogVisibility(false),
    };

    let smsNumberConfirmationDialogDetails = {
      Open: ShowSMSNumberConfirmationDialog,
      ShowProgressIndicatorImmediately: ShowDialogProgressIndicatorImmediately,
      Title: "Authentication",
      IsConfirmation: true,
      BodyContent: (
        <Grid container direction="column" spacing={3} style={{marginBottom:0}}>
          <Grid item>
            Confirm your phone number.
          </Grid>
          <Grid item>
            <SMSNumberControl
              onEnterKey={this.handleSMSNumberConfirmation}
              onValueChange={this.handleSMSNumberDialogSMSNumberValueChange}
            />
          </Grid>
        </Grid>
      ),
      CancelLabel: "BACK",
      ConfirmLabel: "SEND",
      ConfirmCallback: this.handleSMSNumberConfirmation,
      CancelCallback: this.handleNavigateBackFromSMSNumberConfirmationDialog,
      CloseCallback: () => this.handleSetSMSNumberConfirmationDialogVisibility(false),
    };

    let otpLocationText = "";
    switch (AuthType) {
      case "email":
        otpLocationText = " to your e-mail address";
      break;
      case "sms":
        otpLocationText = " to your device";
      break;
      default:
      break;
    }
    
    let otpGatherDialogDetails = {
      Open: ShowOTPGatherDialog,
      ShowProgressIndicatorImmediately: ShowDialogProgressIndicatorImmediately,
      Title: "Authentication",
      DialogWidth:"xs",
      DialogContentStyle:{overflowY:"visible"},
      IsConfirmation: true,
      BodyContent: (
        <Grid container direction="column" spacing={3} style={{marginBottom:0}}>
          <Grid item>
            A code has been sent{otpLocationText}.
          </Grid>
          <Grid item>
            <MaskedTextField
              id="otp"
              label="Code"
              value={OTP}
              mask=">AAAAAAAAaaaaaaaa"
              autoFocus
              onValueChange={e => this.setState({OTP:e.target.value})}
              onEnterKey={() => this.handleEmailLoginCompletion()}
            />
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              onClick={this.handleEmailLoginCompletion}
            >
              SIGN IN
            </Button>
          </Grid>
        </Grid>
      ),
      ConfirmLabel: "RESEND",
      ConfirmCallback: this.handleSendOTP,
      CancelCallback: () => this.handleSetOTPGatherDialogVisibility(false),
      CloseCallback: () => this.handleSetOTPGatherDialogVisibility(false),
    };

    const microsoftLoginButton = (
      <Button variant="outlined" size="large"
        style={{backgroundColor:"#fff",height:38}}
        className={classes.button}
        fullWidth
        disabled={MicrosoftSigningIn}
        onClick={() => this.setState({MicrosoftSigningIn:true})}
      >
        <div style={{display:"flex",alignItems:"center"}}>
          <div style={{marginTop:4,height:22}}>
            <svg aria-hidden="true" width="22" height="22" viewBox="10 10 28 28">
              <g>
                <path fill="#f25022" d="M13 11h9v9h-9z"></path><path fill="#00a4ef" d="M13 21h9v9h-9z"></path><path fill="#7fba00" d="M23 11h9v9h-9z"></path><path fill="#ffb900" d="M23 21h9v9h-9z"></path>
              </g>
            </svg>
          </div>
          <div style={{marginLeft:8}}>
            Microsoft
          </div>
        </div>
      </Button>
    );
    const microsoftLogin = (!MicrosoftSigningIn)
      ? (
        <MicrosoftLogin
          clientId={process.env.REACT_APP_MICROSOFT_SIGNIN_CLIENTID}
          authCallback={this.handleMicrosoftLoginResponse}
        >
          {microsoftLoginButton}
        </MicrosoftLogin>
      )
      : microsoftLoginButton;

    // Only show login panel if not direct login
    let isDirectLink = (match && match.params && match.params.uniqueID && match.params.OTP);
    let loginPaper = (<div />);
    let gridLinks = null;
    if (!isDirectLink) {
      loginPaper = (
        <Paper elevation={theme.spacing(2)} className={classes.paper}>
          <div style={{textAlign:"center"}}>
            <img className={classes.logo} src="/nucleusOneDark2.png" alt="Nucleus One" />  
          </div>

          <Grid container
            direction="column"
            spacing={this.ButtonGridSpacing}
            ref={instance => this.ButtonGridRef = instance}
            className={classes.gridControls}>

            <Grid item xs={12} style={{
              fontWeight:500,
              fontSize:16,
              marginTop:theme.spacing(1),
              marginBottom:theme.spacing(1),
            }}>
              <Grid container spacing={2} style={{
                justifyContent:"center",
                color:theme.palette.secondary.main,
                fontSize:18,
              }}>
                <Grid item>
                  Welcome!
                </Grid>
                <Grid item>
                  Register by signing in.
                </Grid>
              </Grid>
            </Grid>

            <Grid item style={{marginBottom:2}}>
              <GoogleLogin
                onSuccess={this.handleGoogleLoginResponse}
                onError={() => {
                  console.log('Login Failed');
                }}
                width={GoogleButtonWidth}
                type="standard"
                logo_alignment="center"
                size="large"
              />
              {/*<GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_SIGNIN_CLIENTID}
                onSuccess={this.handleGoogleLoginResponse}
                onFailure={this.handleGoogleLoginResponse}
                render={renderProps => (
                  <Button variant="contained" size="large" color="default"
                    className={classes.button}
                    fullWidth
                    onClick={renderProps.onClick}
                  >
                    <svg style={{marginRight:12}} aria-hidden="true" width="18" height="18" viewBox="0 0 18 18">
                      <g>
                        <path d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 0 0 2.38-5.88c0-.57-.05-.66-.15-1.18z" fill="#4285F4"></path><path d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 0 1-7.18-2.54H1.83v2.07A8 8 0 0 0 8.98 17z" fill="#34A853"></path><path d="M4.5 10.52a4.8 4.8 0 0 1 0-3.04V5.41H1.83a8 8 0 0 0 0 7.18l2.67-2.07z" fill="#FBBC05"></path><path d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 0 0 1.83 5.4L4.5 7.49a4.77 4.77 0 0 1 4.48-3.3z" fill="#EA4335"></path>
                      </g>
                    </svg>
                    Sign in with Google
                  </Button>
                )}
              >
              </GoogleLogin>*/}
            </Grid>

            <Grid item>
              <AppleSignin
                authOptions={{
                  clientId: process.env.REACT_APP_APPLE_SIGNIN_CLIENTID,
                  scope: 'email name',
                  redirectURI: window.location.href,
                  // state: 'state',
                  // nonce: 'nonce',
                  usePopup: true,
                }}
                uiType="light"
                render={props => (
                  <Button variant="outlined" size="large"
                    style={{backgroundColor:"#fff",height:38}}
                    className={classes.button}
                    fullWidth
                    onClick={props.onClick}
                  >
                    <svg style={{marginLeft:-6,marginRight:10}} aria-hidden="true" width="24" height="24" viewBox="2 12 20 20">
                      <g>
                        <path d="M12.2337427,16.9879688 C12.8896607,16.9879688 13.7118677,16.5445313 14.2014966,15.9532812 C14.6449341,15.4174609 14.968274,14.6691602 14.968274,13.9208594 C14.968274,13.8192383 14.9590357,13.7176172 14.9405591,13.6344727 C14.2107349,13.6621875 13.3330982,14.1241016 12.8065162,14.7430664 C12.3907935,15.2142188 12.012024,15.9532812 12.012024,16.7108203 C12.012024,16.8216797 12.0305005,16.9325391 12.0397388,16.9694922 C12.0859302,16.9787305 12.1598365,16.9879688 12.2337427,16.9879688 Z M9.92417241,28.1662891 C10.8202857,28.1662891 11.2175318,27.5658008 12.3353638,27.5658008 C13.4716724,27.5658008 13.721106,28.1478125 14.7188404,28.1478125 C15.6980982,28.1478125 16.3540162,27.2424609 16.972981,26.3555859 C17.6658521,25.339375 17.9522388,24.3416406 17.9707154,24.2954492 C17.9060474,24.2769727 16.0306763,23.5101953 16.0306763,21.3576758 C16.0306763,19.491543 17.5088013,18.6508594 17.5919459,18.5861914 C16.612688,17.1819727 15.1253248,17.1450195 14.7188404,17.1450195 C13.6194849,17.1450195 12.7233716,17.8101758 12.1598365,17.8101758 C11.5501099,17.8101758 10.7463794,17.1819727 9.79483648,17.1819727 C7.98413335,17.1819727 6.14571538,18.6785742 6.14571538,21.5054883 C6.14571538,23.2607617 6.8293482,25.1176563 7.67003179,26.3186328 C8.39061773,27.3348438 9.01882085,28.1662891 9.92417241,28.1662891 Z" fill="#000"></path>
                      </g>
                    </svg>
                    Sign in with Apple
                  </Button>
                )}
                onSuccess={this.handleAppleSignInResponse}
              />
            </Grid>

            <Grid item>
              <Grid container spacing={this.ButtonGridSpacing}>
                <Grid item xs={12} sm={6}>
                  {microsoftLogin}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <LinkedIn
                    clientId={process.env.REACT_APP_LINKEDIN_SIGNIN_CLIENTID}
                    onError={err => { if (err && err.error && err.error !== "user_closed_popup") { this.handleApiError(err.error); }}}
                    onSuccess={this.handleLinkedInLoginResponse}
                    scope="r_emailaddress r_liteprofile"
                    redirectUri={`${window.location.href}/linkedin`}
                  >
                    {({ linkedInLogin }) => (
                      <Button variant="outlined" size="large"
                        style={{backgroundColor:"#fff",height:38}}
                        className={classes.button}
                        fullWidth
                        onClick={linkedInLogin}
                      >
                        <img style={{width:16,marginRight:12}}src="/linkedin.png" alt="Sign in with Linked In" />
                        LinkedIn
                      </Button>
                    )}
                  </LinkedIn>
                </Grid>
              </Grid>
            </Grid>

            <Grid item className={classes.or}>
              <Grid container spacing={0} style={{alignItems:"center"}}>
                <Grid item xs={5}>
                  <Divider className={classes.divider} />
                </Grid>
                <Grid item xs={2}>
                  or
                </Grid>
                <Grid item xs={5}>
                  <Divider className={classes.divider} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
            <Grid container spacing={2} style={{alignItems:"center"}}>
            <Grid item xs={12} sm={8}>
              <TextField
                size="small"
                label="E-mail Address"
                variant="outlined"
                autoFocus
                // InputLabelProps={{ shrink: true, }}
                fullWidth
                onChange={e => this.setState({Email:e.target.value})}
                onKeyDown={e => { if (e.keyCode === 13) { e.preventDefault(); this.handleEmailLoginVerify(); } }}
                value={Email}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Button variant="contained" color="secondary"
                style={{height:40}}
                className={classes.button}
                fullWidth
                onClick={this.handleEmailLoginVerify}>
                <span style={{whiteSpace:"nowrap"}}>SIGN IN</span>
              </Button>
            </Grid>
            </Grid>
            </Grid>
          </Grid>
        </Paper>
      );

      gridLinks = (
        <Grid container spacing={2} className={classes.gridLinks}>
          <Grid item>
            <Link href="/terms" target="n1legal" color="inherit" variant="body2">
              Terms of Service
            </Link>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="inherit">
              &bull;
            </Typography>
          </Grid>
          <Grid item>
            <Link href="/privacy" target="n1legal" color="inherit" variant="body2">
              Privacy Policy
            </Link>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="inherit">
              &bull;
            </Typography>
          </Grid>
          <Grid item>
            <Link href="/licenses" target="n1legal" color="inherit" variant="body2">
              Licenses
            </Link>
          </Grid>
        </Grid>
      );
    }

    return (
      <div className={classes.root}>
        <MultiUseDialog Details={GeneralDialogDetails} />
        <MultiUseDialog Details={emailAddressConfirmationDialogDetails} />
        <MultiUseDialog Details={authTypeDialogDetails} />
        <MultiUseDialog Details={smsNumberDialogDetails} />
        <MultiUseDialog Details={smsNumberConfirmationDialogDetails} />
        <MultiUseDialog Details={otpGatherDialogDetails} />

        {progressIndicator}
        
        <Container className={classes.container} maxWidth="xs">
          {loginPaper}
          {gridLinks}
        </Container>
      </div>
    );
  }
}

export default withStyles(styles, {withTheme: true})(Login);
