import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import Collection from '../Model/Collection';

import ItemTableRow from '../Components/ItemTableRow';
import AssetCard from '../Components/AssetCard';
import AssetCollectionFields from '../Model/AssetCollectionFields';

import AssetPropertiesDialog from '../Components/AssetPropertiesDialog';
import AssetCreationDialog from '../Components/AssetCreationDialog';

import API from '../Util/api';

import {
  GetAssetsPathForApi,
  GetAssetPathForApi,
} from '../Util/api';
import {
  GetDateValue,
} from '../Util/Properties';
import { 
  GetProjectAssetsPath,
} from '../Util/PathHelper';
import {
  HandleRouteToAssetDialog,
} from '../Util/Assets';

export class Assets_Collection extends Collection {
  constructor(props, onSetState, onApiError, isWorkspace, isProjects, 
    organizationId, projectId, activeOnly, archivedOnly) {

    if (typeof organizationId !== "string") {
      console.log("Warning: organizationId required and not found");
    }
    if (typeof projectId !== "string") {
      console.log("Warning: projectId required and not found");
    }
    super(props, onSetState, onApiError, isWorkspace, isProjects, organizationId, projectId);

    this.activeOnly = activeOnly;
    this.archivedOnly = archivedOnly;

    this.PageTitle = "Assets";
    this.ContentUri = GetAssetsPathForApi(organizationId, projectId);
    this.ContentUriParams = {
      activeOnly,
      archivedOnly,
    };
    this.CollectionName = `${archivedOnly ? "Archived" : ""}Assets`;
    this.ItemsName = "Assets";
    this.ItemName = "Asset";
    this.AllowSelect = true;
    this.DefaultViewType = "List";
    this.AssetsForDialog = [];
    const canCreateNew = isProjects && activeOnly;
    this.HandleCreateNew = (canCreateNew)
      ? () => {
        this.setState({
          AddAssetDialogIsOpen: true,
        });
      } : null;
  }

  HandleGetCollectionFieldsPromise = () => Promise.resolve(AssetCollectionFields);

  HandleGetHeadCells = (items, sensitiveFields) => {
    let headCells = [
      { id: 'Name', sortId: 'Meta_text_kw256lc[Name].keyword', numeric: false, label: 'Name' },
      { id: 'PluralName', sortId: 'Meta_text_kw256lc[PluralName].keyword', numeric: false, label: 'PluralName' },
      { id: 'CreatedOn', sortId: 'Meta_date_str256[CreatedOn].string', numeric: false, label: 'Created On' },
    ];
    if (!this.ProjectID) {
      headCells.push(
        { id: 'ProjectName', numeric: false, label: "Project" },
      );
    }
    return headCells;
  }

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length) 
      ? items
        .map(i => {
          return (
            <Grid item key={i.ID} sm={12} md={6} lg={4} xl={3} className={classes.cardGridItem}>
              <AssetCard
                Asset={i}
                onCardAction={(!this.archivedOnly) ? () => this.handleItemClick(i) : undefined}
                onSelect={() => onSelect(i.ID)}
                selected={selectedIDs.indexOf(i.ID) > -1}
              />
            </Grid>
          );
        }) 
      : [];
  }

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length)
      ? items
        .map(i => {
          const selected = selectedIDs.indexOf(i.ID) !== -1;
          const projectNameCell = (!this.ProjectID)
            ? <TableCell className={classes.tableCell}>{i.ProjectName}</TableCell>
            : null;
          return (
            <ItemTableRow key={`k_${i.ID}`}
              onSelect={() => onSelect(i.ID)}
              selected={selected}
              onItemClick={(!this.archivedOnly) ? () => this.handleItemClick(i) : undefined}
            >
              <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
                <Checkbox
                  color="default"
                  onClick={e => { e.stopPropagation(); onSelect(i.ID); }}
                  checked={selected}
                />
              </TableCell>
              <TableCell className={classes.tableCell} component="th" id={`label_${i.ID}`} scope="row" padding="none">
                {i.Name}
              </TableCell>
              <TableCell className={classes.tableCell}>{i.PluralName}</TableCell>
              <TableCell className={classes.tableCell}>{GetDateValue(i.CreatedOn)}</TableCell>
              {projectNameCell}
            </ItemTableRow>
          );
        }) 
      : [];
  }

  handleItemClick = item => {
    HandleRouteToAssetDialog(this.props, this.ProjectID, item.ID);
  }

  HandleItemsChanged = items => {
    this.AssetsForDialog = items || [];
    this.handleTryDisplayAssetDialogIfApplicable(null, items, true);
  }

  handleTryDisplayAssetDialogIfApplicable = (assetId_optional, assets, fromItemsChanged) => {
    const assetId = (assetId_optional) 
      ? assetId_optional
      : this.props.match.params.collectionItemID;
    if (assetId) {
      const displayDialog = (asset, extraState) => {
        this.setState({
          AssetForAssetDialog: asset,
          AssetPropertiesDialogIsOpen: true,
          ...extraState,
        });
      }
      if (!assets) {
        assets = [...this.AssetsForDialog];
      }
      const assetFinder = assets.filter(a => a.ID === assetId);
      if (this.isWorkspace && assetFinder.length) {
        displayDialog(assetFinder[0]);
      }
      if (fromItemsChanged) {
        if (assetFinder.length) {
          displayDialog(assetFinder[0]);
        }
      } else {
        API.get(GetAssetPathForApi(this.OrganizationID, this.ProjectID, assetId))
          .then(resp => {
            displayDialog(resp.data, {
              ForcePrependItems: [resp.data],
            });
          })
          .catch(err => {
            this.handleApiError(err);
            this.handleAssetPropertiesDialogClosed();
          });
      }
    }
  }

  HandleGetDialogContent = state => {
    if (state.AddAssetDialogIsOpen) {
      return (
        <AssetCreationDialog
          organizationId={this.OrganizationID}
          projectId={this.ProjectID}
          // projectName={projectName}
          open={state.AddAssetDialogIsOpen || false}
          onCreated={this.handleAssetCreated}
          onClose={this.handleAddAssetDialogClosed}
          onApiError={this.handleApiError}
        />
      );
    } else if (state.AssetPropertiesDialogIsOpen) {
      return (
        <AssetPropertiesDialog
          organizationId={this.OrganizationID}
          projectId={this.ProjectID}
          onAssetRevised={this.HandleItemRevised}
          open={state.AssetPropertiesDialogIsOpen}
          onApiError={this.handleApiError}
          closeCallback={this.handleAssetPropertiesDialogClosed}
          asset={state.AssetForAssetDialog} />
      );
    }
  }

  handleAddAssetDialogClosed = () => {
    this.setState({
      AddAssetDialogIsOpen: false,
    })
  }

  handleAssetPropertiesDialogClosed = () => {
    const stateToUpdate = {
      AssetPropertiesDialogIsOpen: false,
      ShowDialogProgressIndicatorImmediately: false,
    };
    this.setState(stateToUpdate);

    const newPath = GetProjectAssetsPath(this.ProjectID);
    if (this.props.location.pathname !== newPath) {
      this.props.history.push(newPath, { ...this.props.location.state, ...stateToUpdate });
    }
  }

  handleAssetCreated = asset => {
    this.handleAddAssetDialogClosed();
    if (!asset) {
      return;
    }
    this.handleAddAssetDialogClosed();
    this.setState({ForcePrependItems:[asset]});
    HandleRouteToAssetDialog(this.props, this.ProjectID, asset.ID);
  }

  // Returns whether further path changes can occur.
  updateProps(props) {
    Collection.prototype.updateProps.bind(this)(props);

    if (this.props.match.params.collectionItemID && !this.prevProps.match.params.collectionItemID) {
      this.handleTryDisplayAssetDialogIfApplicable(null, null);
      return false;
    } else if (!this.props.match.params.collectionItemID && this.prevProps.match.params.collectionItemID) {
      this.handleAssetPropertiesDialogClosed();
      return false;
    }

    return true;
  }
}