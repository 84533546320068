import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Card from '../Components/Card';

import {
  GetDateValue,
} from '../Util/Properties';

class UserApiKeyCard extends Component {
  render() {
    const {
      UserApiKey,
      onCardAction,
      onSelect,
      selected,
    } = this.props; 
    
    let metadata = [
      ["Created On", GetDateValue(UserApiKey.CreatedOn)],
    ];

    return (
      <Card
        name={UserApiKey.Name}
        metadata={metadata}
        onCardAction={onCardAction}
        onSelect={onSelect}
        selected={selected}
      />
    );
  }
}

UserApiKeyCard.propTypes = {
  UserApiKey: PropTypes.object.isRequired,
  // onCardAction: PropTypes.func.isRequired,
};

export default UserApiKeyCard;