import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import Collection from '../Model/Collection';

import ItemTableRow from '../Components/ItemTableRow';
import FormTemplateCard from '../Components/FormTemplateCard';
import FormTemplateCollectionFields from '../Model/FormTemplateCollectionFields';

import FormTemplateCreationDialog from '../Components/FormTemplateCreationDialog';

import {
  GetFormTemplatesPathForApi,
} from '../Util/api';
import {
  GetPageCountForDisplay,
} from '../Util/Document';
import {
  GetDateValue,
} from '../Util/Properties';
import {
  HandleRouteToFormInput,
  HandleRouteToFormTemplateDesigner,
} from '../Util/FormTemplates';

import { IsMobile } from '../Util/MobileDetector';

export class FormTemplates_Collection extends Collection {
  constructor(props, onSetState, onApiError, onAlert, isWorkspace, isProjects, 
    organizationId, projectId,
    formInputOnly, isProjectAdmin) {

    if (typeof organizationId !== "string") {
      console.log("Warning: organizationId required and not found");
    }

    super(props, onSetState, onApiError, isWorkspace, isProjects, organizationId, projectId);

    this.handleAlert = onAlert;

    this.PageTitle = "Form Templates";
    this.ContentUri = GetFormTemplatesPathForApi(organizationId, projectId);
    this.CollectionName = (formInputOnly) ? "Forms" : "FormTemplates";
    this.ItemsName = "FormTemplates";
    this.ItemName = "Form Template";
    this.DefaultViewType = "Card";

    this.FormInputOnly = formInputOnly;
    this.IsProjectAdmin = isProjectAdmin;

    this.AllowSelect = true;
    this.HandleCreateNew = (isProjectAdmin && !IsMobile()) 
    ? () => {
      this.setState({
        AddFormTemplateDialogIsOpen: true,
      });
    }
    : undefined;
  }

  HandleGetCollectionFieldsPromise = () => Promise.resolve(FormTemplateCollectionFields);

  HandleGetHeadCells = (items, sensitiveFields) => {
    let headCells = [
      { id: 'Name', sortId: 'Meta_text_kw256lc[Name].keyword', numeric: false, label: 'Name' },
    ];
    if (!this.ProjectID) {
      headCells.push(
        { id: 'ProjectName', numeric: false, label: "Project" },
      );
    }
    headCells.push(
      { id: 'CreatedOn', sortId: 'Meta_date_str256[CreatedOn].string', numeric: false, label: 'Created On' },
      { id: 'PageCount', sortId: 'Meta_int[PageCount]', numeric: true, label: 'Page Count' },
    );
    return headCells;
  }

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length) 
      ? items
        .map(i => {
          return (
            <Grid item key={i.ID} sm={12} md={6} lg={4} xl={3} className={classes.cardGridItem}>
              <FormTemplateCard
                minHeight={120}
                FormTemplate={i}
                history={this.props.history}
                onAction={onAction}
                onCardAction={(this.FormInputOnly)
                  ? () => HandleRouteToFormInput(i.ID, i.UniqueID)
                  : () => HandleRouteToFormTemplateDesigner(this.props, i)
                }
                showFormInputAction={!this.FormInputOnly}
                showFormTemplateDesignerAction={this.FormInputOnly && this.IsProjectAdmin}
                onSelect={(this.CanSelectItem(i)) ? () => onSelect(i.ID) : undefined}
                noSelect={!this.CanSelectItem(i)}
                selected={selectedIDs.indexOf(i.ID) > -1}
              />
            </Grid>
          );
        }) 
      : [];
  }

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length)
      ? items
        .map(i => {
          const selected = selectedIDs.indexOf(i.ID) !== -1;
          const projectNameCell = (!this.ProjectID)
            ? <TableCell className={classes.tableCell}>{i.ProjectName}</TableCell>
            : null;
          const labelId = `label_${i.ID}`;
          return (
            <ItemTableRow key={`k_${i.ID}`}
              onItemClick={(this.FormInputOnly)
                ? () => HandleRouteToFormInput(i.ID, i.UniqueID)
                : () => HandleRouteToFormTemplateDesigner(this.props, i)
              }
            >
              <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
                <Checkbox
                  style={{
                    visibility: (!this.CanSelectItem(i)) ? "hidden" : undefined,
                  }}
                  color="secondary"
                  onClick={event => { event.stopPropagation(); onSelect(i.ID); }}
                  checked={selected}
                  inputProps={{ 'aria-labelledby': labelId, style:{zIndex:0} }}
                />
              </TableCell>
              <TableCell className={classes.tableCell} component="th" id={`label_${i.ID}`} scope="row" padding="none">
                {i.Name}
              </TableCell>
              {projectNameCell}
              <TableCell className={classes.tableCell}>{GetDateValue(i.CreatedOn)}</TableCell>
              <TableCell className={classes.tableCell} align="right">{GetPageCountForDisplay(i.PageCount)}</TableCell>
            </ItemTableRow>
          );
        }) 
      : [];
  }

  HandleGetDialogContent = state => {
    if (state.AddFormTemplateDialogIsOpen) {
      return (
        <FormTemplateCreationDialog
          organizationId={this.OrganizationID}
          projectId={this.ProjectID}
          location={this.props.location}
          history={this.props.history}
          // projectName={projectName}
          open={state.AddFormTemplateDialogIsOpen || false}
          onCreated={this.handleFormTemplateCreated}
          onClose={this.handleAddFormTemplateDialogClosed}
          onApiError={this.handleApiError}
          onAlert={this.handleAlert}
        />
      );
    }
  }

  handleAddFormTemplateDialogClosed = () => {
    this.setState({
      AddFormTemplateDialogIsOpen: false,
    })
  }

  handleFormTemplateCreated = formTemplate => {
    this.handleAddFormTemplateDialogClosed();
    if (!formTemplate) {
      return;
    }
    HandleRouteToFormTemplateDesigner(this.props, formTemplate);
  }
}