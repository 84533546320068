export const Process = {
    ID: "",
    Name: "",
}

export const GetNewProcess = Name => {
	return {
		...Process,
		Name,
	};
}

export default Process;