import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Card from '../../Components/Card';

import { NumberWithSeparators } from '../../Util/NumberFormatting';
import {
  GetDateValue,
  GetBoolValue,
  GetUserValue,
} from '../../Util/Properties';

import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

class OrganizationRequiringSubscriptionCard extends Component {
  render() {
    const {
      Organization,
      // PurgeMarkedText,
      DisabledText,
      onCardAction,
    } = this.props;

    const isExpired = new Date(Organization.SubscriptionExpiration) < new Date();

    let metadata = [
      ["Created On", GetDateValue(Organization.CreatedOn)],
      ["Creating User", GetUserValue(Organization.CreatedByUserEmail, Organization.CreatedByUserName, "sm")],
      ["Expiration", GetDateValue(Organization.SubscriptionExpiration), {color:(isExpired) ? red[500] : green[500]}],
      // ["Firestore", Organization.ID],
      // ["Cloud Storage", Organization.BucketName],
      // ["ElasticSearch", Organization.SearchIndexID],
      ["Free Members Allowed", NumberWithSeparators(Organization.SubscriptionFreeUsers)],
      ["Free Members Used", NumberWithSeparators(Organization.UniqueFreeOrganizationMembers)],
      ["Billable Members", NumberWithSeparators(Organization.UniqueBillableOrganizationMembers)],
      ["Total Members", NumberWithSeparators(Organization.UniqueNonReadOnlyOrganizationMembers)],
      ["Active Subscription", GetBoolValue(Organization.PaymentPlanId !== "" && !Organization.SubscriptionCanceled && !isExpired)],
      ["Plan Title", Organization.PaymentPlanTitle],
      ["Recurring Amount", (Organization.PaymentRecurringAmount) ? `$${NumberWithSeparators(Organization.PaymentRecurringAmount)}` : null],
      // ["Read-Only Members", NumberWithSeparators(Organization.UniqueReadOnlyOrganizationMembers)],
      ["", DisabledText],
      // ["", PurgeMarkedText],
    ];

    // const testOrg = {...Organization, 
    //   // CreatedOn: new Date(),
    //   CreatingUserLastActivity: Organization.CreatedOn,
    //   UniqueNonReadOnlyOrganizationMembers: 1,
    //   ActiveProjectCount: 1,
    //   HasUpToMinTasks: 0,
    //   HasUpToMinDocuments: 0,
    //   HasUpToMinFormTemplates: 0,
    //   HasUpToMinProcesses: 0,
    // };

    return (
      <Card
        onCardAction={onCardAction}
        name={Organization.Name}
        metadata={metadata}
      />
    );
  }
}

OrganizationRequiringSubscriptionCard.propTypes = {
  Organization: PropTypes.object.isRequired,
  onCardAction: PropTypes.func.isRequired,
};

export default OrganizationRequiringSubscriptionCard;