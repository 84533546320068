import { GetFieldForFilterAndSort } from '../Util/Field';

const OrganizationMemberCollectionFields = [
	GetFieldForFilterAndSort(
		"Meta_date_str256[CreatedOn].string",
		"Created On",
		"FieldType_Date",
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[UserName].keyword",
		"Name",
		"FieldType_Text",
		"Meta_text_kw256lc[UserName]",
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[UserEmail].keyword",
		"Email",
		"FieldType_Text",
		"Meta_text_kw256lc[UserEmail]",
	),
];

export default OrganizationMemberCollectionFields;