import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';

import API from '../Util/api';
import {
  GetProjectPath,
} from './PathHelper';
import MultiUseDialog from '../Components/MultiUseDialog';

import debounce from 'es6-promise-debounce';
import { IsMobile } from './MobileDetector';

export const HandleRouteToProject = (props, projectId, openInNewTab) => {
  const uri = GetProjectPath(projectId);
  if (openInNewTab) {
    window.open(uri, "n1_projectContent")
  } else {
    props.history.push(uri, { ...props.location.state });  
  }
}

export const GetProjectListValuesPromise = debounce((filter, selectedListValue, onValueChange, onApiError) => {
  let params = {};
  if (filter) {
    params.filter = filter;
  }
  if (selectedListValue && selectedListValue.value) {
    params.includeProjectId = selectedListValue.value;
  }
  
  return API.get("/projects", { params })
    .then(resp => {
      if (!resp.data) {
        return null;
      }
      // Initial loading may not have or have an outdated name/label. Set it here.
      if (selectedListValue && selectedListValue.value) {
        let projectFinder = resp.data.Projects.filter(r => r.ID === selectedListValue.value);
        if (projectFinder.length > 0) {
          let projectName = projectFinder[0].Name;
          if (selectedListValue.label !== projectName) {
            selectedListValue.label = projectName;
            if (onValueChange) {
              onValueChange(selectedListValue);
            }
          }
        }
      }
      let projectListValues = [];
      resp.data.Projects.forEach(r => {
        projectListValues.push({
          value: r.ID,
          label: r.Name,
        });
      });
      return projectListValues;
    })
    .catch(err => {
      if (onApiError) {
        onApiError(err);
      }
    });
}, 250);

export const GetAndSetProjectsPackageForTreeNodesAsPromise = (props, state, onSetState, onApiError, 
  uri, includeProjectId, assignmentType, itemsName, projectIdPropertyName, reset, getMore) => {
  
  if (!reset && !getMore) {
    return Promise.resolve(state.ProjectsPackage);
  }

  let params = {
    sortType: "Meta_text_kw256lc[Name].keyword", // This is only for the Projects screen uri, but it doesn't hurt to include it in all calls
    cursor: (getMore && state.ProjectsPackage) ? state.ProjectsPackage.Cursor : "",
    includeProjectId,
    assignmentType,
  };
  if (reset && props.match.params.projectID) {
    params.includeProjectId = props.match.params.projectID;
  }
  return API.get(uri, { params })
    .then(resp => {
      let newProjects = resp.data[itemsName];
      // Dedupe new items because of includeProjectId param, which can result in the included project showing up again later
      if (getMore && state.ProjectsPackage && state.ProjectsPackage.Projects) {
        newProjects = newProjects.filter(p => !state.ProjectsPackage.Projects
          .filter(pp => pp[projectIdPropertyName] === p[projectIdPropertyName]).length);
      }
      const Projects = (getMore)
        ? [...state.ProjectsPackage.Projects].concat(newProjects)
        : newProjects || [];
      const ProjectMembers = (getMore)
        ? [...state.ProjectsPackage.ProjectMembers].concat(resp.data.ProjectMembers)
        : resp.data.ProjectMembers || [];
      const ProjectsPackage = {
        Projects,
        ProjectMembers,
        IsOrganizationAdmin: resp.data.IsOrganizationAdmin,
        Cursor: resp.data.Cursor,
        ShowGetMore: resp.data[itemsName].length && resp.data[itemsName].length >= resp.data.PageSize,
      };
      onSetState({ProjectsPackage});
      return ProjectsPackage;
    })
    .catch(onApiError);
}

export const HandleSetShowProjectSelectionDialog = (onSetState, ShowProjectSelectionDialog,
  SelectedProjectMember) => {
  
  onSetState({
    ShowProjectSelectionDialog,
    SelectedProjectMember,
  });
}

export const GetProjectSelectionDialog = (context, projectMembershipFilter, state, onSetState, onConfirm, 
  closeOnSelection, createProjectFunc) => {
  
  const handleClose = projectMember => {
    HandleSetShowProjectSelectionDialog(onSetState, false, projectMember);
    if (onConfirm && projectMember) {
      onConfirm(projectMember);
    }
  };
  let pmp = [...context.ProjectMembershipPackages];
  if (projectMembershipFilter) {
    pmp = pmp.filter(projectMembershipFilter);
  }
  const projectListItems = pmp.map(p => (
    <ListItem key={p.ProjectMember.ID}
      button
      onClick={() => {
        if (closeOnSelection) {
          handleClose(p.ProjectMember);
        } else {
          onSetState({ProjectSelectionProjectMember: p.ProjectMember});  
        }
      }}
      selected={state.ProjectSelectionProjectMember && state.ProjectSelectionProjectMember.ID === p.ProjectMember.ID}
    >
      <ListItemText primary={p.Project.Name} />
    </ListItem>
  ));
  const createProjectButtonGridItem = (createProjectFunc)
    ? (
      <Grid item>
        <Button variant="contained"
          onClick={() => {
            createProjectFunc();
            handleClose();
          }}
        >
          ADD NEW
        </Button>
      </Grid>
    ) : null;
  const projectListOrMessage = (projectListItems && projectListItems.length)
    ? (
      <List style={{
        height: "100%",
        overflow: "auto",
      }}>
        {projectListItems}
      </List>
    )
    : (
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Typography variant="body1">
            None are available for this action.
          </Typography>
        </Grid>
        {createProjectButtonGridItem}
      </Grid>
    );
  const projectSelectionDialogDetails = (state.ShowProjectSelectionDialog)
    ? {
      Open: true,
      Title: "Choose a project",
      FullScreen: IsMobile(),
      DialogWidth: "xs",
      BodyContent: projectListOrMessage,
      DialogContentStyle: {
        height: (!IsMobile()) ? 400 : undefined,
        maxHeight: (!IsMobile()) ? 500 : undefined,
        overflow:"hidden",
      },
      BodyStyle: {
        height: "100%",
        overflow:"hidden",
      },
      IsConfirmation: true,
      DisableConfirmButton: !state.ProjectSelectionProjectMember,
      ConfirmLabel: "GO",
      ConfirmCallback: (!closeOnSelection) ? () => {
        if (!state.ProjectSelectionProjectMember) {
          return;
        }
        handleClose(state.ProjectSelectionProjectMember);
      } : undefined,
      CancelCallback: () => handleClose(),
      CloseCallback: () => handleClose(),
      ShowProgressIndicatorImmediately: !context.CompletedGET.ProjectMembershipPackages,
    } : null;
  return (projectSelectionDialogDetails)
    ? (
      <MultiUseDialog Details={projectSelectionDialogDetails} />
    ) : null;
}