import React, { Component } from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
// import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
// import Checkbox from '@material-ui/core/Checkbox';

import DocumentUploadIcon from '@material-ui/icons/CloudUpload';
import TwainCaptureIcon from '@material-ui/icons/Scanner';

import { IsMobile } from '../Util/MobileDetector';
import { IsMicrosoftWindows } from '../Util/MicrosoftWindowsDetector';
import { withStyles } from '@material-ui/core/styles';
import 'typeface-alex-brush';

// import SelectControl from './SelectControl';
import FieldInput from './FieldInput';
import MultiUseDialog from './MultiUseDialog';
import DeviceCaptureDialog from './DeviceCaptureDialog';
import { 
  GetUpdatedFieldObjectForValueChange,
  GetComposedFieldListLabelsAndValues,
  HandleFieldListItemAdd,
  GetFieldPassesValidation,
  RequiredFieldStyle,
  GetEffectiveAllowNewSelectionListItems,
  GetEffectiveSelectionListIsDependent,
  GetDependentFieldsParentValue,
  GetDependentFieldsParentField,
  GetEffectiveFieldLabelOrName,
} from '../Util/Field';
import {
  HandleGetFormTemplateFieldListItemsFilter,
  HandleFormTemplateFieldListItemAdd,
  GetMinFieldWidth,
  GetHideAdornment,
  FormTemplateFieldControlSizeStyles,
  GetFormTemplateFieldControlSizeClasses,
} from '../Util/FormTemplateFields';
import {
  GetFormTemplatesPublicFieldDocumentUploadsPathForApi,
} from '../Util/api';
import { FormTemplateFieldTypes } from '../Model/FormTemplateFieldTypes';
import CaptureCore from '../Components/CaptureCore';
import dateformat from 'dateformat';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import { RecurseDependentSelectionListsDown } from '../Util/Fields';

const _designerWidthOffset = 0.0285;

const styles = theme => ({
  root: {
    position:"absolute",
  },
  signatureContainer: {
    display:"flex",
    alignItems:"baseline",
    borderBottom:"1px solid",
    borderBottomColor:theme.palette.divider,
  },
  signatureLabel: {
    fontSize:12,
    position:"absolute",
    top:-14,
    left:0,
  },
  signature: {
    fontFamily: "Alex Brush",
    fontSize:36,
    cursor:"pointer",
    lineHeight:0.9,
  },
  signatureDate: {
    marginLeft:theme.spacing(3),
    fontSize:20,
  },
  contextImage: {
    width:`calc(100% + ${theme.spacing(3*2)}px)`,
    border:"1px solid blue",
    marginLeft:-theme.spacing(3),
    marginBottom:-theme.spacing(3),
  },
  radioButtonContainer: {
    "& .MuiRadio-root": {
      alignSelf: "flex-start",
    },
  },
  uploadButtonContainer: {
    display:"flex",
    flexDirection: "column",
  },
  uploadRequiredLabel: {
    color:"rgba(0, 0, 0, 0.54)",
    marginTop:2,
    marginLeft:14,
  },
  ...FormTemplateFieldControlSizeStyles,
  required: {...RequiredFieldStyle},
});

class FormField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ShowDeviceCaptureDialog: false,
      UploadMenuAnchorEl: null,
      UploadComplete: false,
      ShowGetSignatureValueDialog: false,
      AlertDetails: { Open: false },
    }
  }

  handleValueChange = formTemplateFieldId => (eventOrValue, selectedOptions) => {
    let field = GetUpdatedFieldObjectForValueChange(this.props.FormTemplateField.Field, eventOrValue, selectedOptions);
    
    // Clear any fields that are selection lists and a child of the current field
    this.clearAndReloadDependentFieldValues(this.props.FormTemplateField, this.props.fields);
    
    if (this.props.onValueChange) {
      this.props.onValueChange(formTemplateFieldId, field.Value, field.Values);
    }
    // Move forward if this is the last field on the page and it's not tab-capable
    if (this.props.tabIndex === this.props.totalFields - 1
          && this.props.onMoveForward) {
      if (this.props.FormTemplateField.Type === "RadioGroup") {
        this.props.onMoveForward();
      } else {
      switch (this.props.FormTemplateField.Field.Type) {
          case "FieldType_Bool":
            this.props.onMoveForward();
            break;
          default:
            break;
        }
      }
    }
  }

  clearAndReloadDependentFieldValues = (formTemplateField, fields) => {
    if (!document || !formTemplateField) {
      return;
    }

    let isFirstField = true;
    RecurseDependentSelectionListsDown(formTemplateField, fields,
      f => {
        if (isFirstField) {
          isFirstField = false;
          return formTemplateField.FieldID || formTemplateField.ID;
        }
        return (f.FieldID) ? f.FieldID : f.ID;
      },
      f => f.Field.ParentFieldID,
      f => {
        if (!GetEffectiveSelectionListIsDependent(f.Field)) {
          return;
        }

        // Clear the field's value
        GetUpdatedFieldObjectForValueChange(f.Field, undefined, []);
        f.Field.ListValues = GetComposedFieldListLabelsAndValues(f.Field);
        
        // Cause the selection list to be reloaded
        f.Field.UpdateId = new Date();
      },
    );
  }

  handleIndexFieldListItemAdd = (formTemplateFieldId, fieldId, field) => newValue => {
    HandleFieldListItemAdd(this.props.organizationId, this.props.projectId, fieldId, field,
      fieldId => (event, selectedOptions) => this.handleValueChange(formTemplateFieldId)(event, selectedOptions),
      this.props.onApiError,
    )
    (newValue);
  }

  handleKeyDown = e => {
    if (e.keyCode === 9) {
      if (e.shiftKey) {
        if (this.props.tabIndex === 0
          && this.props.onMoveBackward) {
          this.props.onMoveBackward(e);
        }
      } else {
        if (this.props.tabIndex === this.props.totalFields - 1
          && this.props.onMoveForward) {
          this.props.onMoveForward(e);
        } 
      }
    }
  }

  handleSignatureValueChange = formTemplateFieldId => value => {
    this.handleSetShowGetSignatureValueDialogVisibility(false);
    this.handleValueChange(formTemplateFieldId)(value);
    // Move forward if this is the last field on the page
    if (value
      && this.props.tabIndex === this.props.totalFields - 1
      && this.props.onMoveForward) {
      this.props.onMoveForward();
    }
  }

  handleSetShowGetSignatureValueDialogVisibility = visible => {
    this.setState({ShowGetSignatureValueDialog: visible});
  }

  handleAlert = details => {
    if (!details) {
      this.setState({ AlertDetails: { Open: false }});
      return;
    }
    this.setState({ 
      AlertDetails: {
        ...details,
        Open: (details.Closed) ? false : new Date(),
      }
    });
  }

  componentDidMount() {
  }

  render() {
    const {
      ShowDeviceCaptureDialog,
      UploadMenuAnchorEl,
      UploadComplete,
      ShowGetSignatureValueDialog,
      AlertDetails,
    } = this.state;
    const {
      organizationId,
      projectId,
      FormTemplate,
      FormTemplateField,
      ForMobile,
      disabled,
      onApiError,
      totalFields,
      tabIndex,
      autoFocus,
      classes,
      fields,
      ...restProps
    } = this.props;

    const {
      ID,
      X,
      Y,
      Field,
      Type,
      UseColumnLayout,
      PossibleValues,
      DefaultValue,
      ControlSize,
      Value,
      FontSize,
      FontBold,
      FontItalic,
      FontUnderline,
      AssetSignedUrl,
    } = FormTemplateField;

    let signAndDateDialogDetails = {
      Open:ShowGetSignatureValueDialog,
      Title:"Signature",
      BodyText: "By signing this document I agree the form values provided are accurate to the best of my knowledge.",
      RequireTextInput1:true,
      DialogWidth:"xs",
      TextInput1Label:"Your name",
      TextInput1DefaultValue: Value,
      CancelCallback:() => this.handleSetShowGetSignatureValueDialogVisibility(false),
      CloseCallback:() => this.handleSetShowGetSignatureValueDialogVisibility(false),
      ConfirmLabel:"SIGN",
      ConfirmCallback:this.handleSignatureValueChange(ID),
    };

    // Subtract off the amount of width consumed by the drag-indicator in the designer
    const Width = FormTemplateField.Width - _designerWidthOffset;
    const forcedPlaceholder = 
      (Field.DisplaySelectionList
        && (
          (GetEffectiveAllowNewSelectionListItems(Field) && FormTemplateField.Width < 0.23)
          || (!GetEffectiveAllowNewSelectionListItems(Field) && FormTemplateField.Width < 0.15)
        ))
      ? ""
      : undefined;
    const useSmallFont = Field.Type === "FieldType_Date" && FormTemplateField.Width < 0.18;
    const fieldLabel = (Field && !Field.HideLabel)
      ? (Field.Label || Field.Name)
      : null;

    let paddingTop = 0;
    let useMaxContent = false;
    let fieldComponent = null;
    switch (Type) {
      case "Label":
      fieldComponent = (
        <FormLabel key={`f_${ID}`} color="primary">
          {DefaultValue}
        </FormLabel>
      );
      paddingTop = 8;
      useMaxContent = true;
      break;
    case "Text":
      fieldComponent = (
        <Typography variant="body1" key={`f_${ID}`}
          style={{
            fontSize: (FontSize) ? FontSize : undefined,
            fontWeight: (FontBold) ? "bold" : undefined,
            fontStyle: (FontItalic) ? "italic" : undefined,
            textDecoration: (FontUnderline) ? "underline" : undefined,
            lineHeight:1,
          }}
        >
          {DefaultValue}
        </Typography>
      );
      paddingTop = 8;
      break;
    case "Image":
      fieldComponent = AssetSignedUrl
        ? (
          <img src={AssetSignedUrl} style={{width:"100%"}} alt="" />
        )
        : null;
      break;
    case "Field":
      {
        // Image type not handled yet
        if (Field.Type === "FieldType_Image") {
          break;
        }
        const parentField = GetDependentFieldsParentField(Field, fields, f => f.FieldID, f => f.Field.ParentFieldID)
          ?.Field;
        const parentFieldLacksValue = GetEffectiveSelectionListIsDependent(Field) && parentField && !parentField.Value;

        Field.ListValues = GetComposedFieldListLabelsAndValues(Field);
        fieldComponent = (
          <FieldInput Field={Field} key={Field.UpdateId || `f_${ID}`}  // This is a hack to force the component to update when the value of Field.UpdateId changes
            // Index={-1}
            // UpdateId={new Date()}
            forceAutoFocus={autoFocus}
            // forceSmallControls
            smallAdornment={(ControlSize !== "") ? true : false}
            hideAdornment={GetHideAdornment(Width)}
            useSmallFont={useSmallFont}
            redBorderForRequired
            forcedPlaceholder={forcedPlaceholder}
            isDisabled={disabled}
            disableColorAlerts
            onKeyDown={this.handleKeyDown}
            onValueChange={this.handleValueChange(ID)}
            onGetSelectionListFilterPromise={
              (() => {
                const parentFieldValue = GetDependentFieldsParentValue(Field, fields, f => f.FieldID);
                return HandleGetFormTemplateFieldListItemsFilter(FormTemplateField, onApiError, parentFieldValue);
              })()
            }
            onSelectionListItemAdd={HandleFormTemplateFieldListItemAdd(FormTemplateField, onApiError, this.handleValueChange)}
            onApiError={onApiError}
            onAlert={this.handleAlert}
            fields={fields}
            disabled={parentField && parentFieldLacksValue}
            parentFieldLacksValue={parentFieldLacksValue}
            parentFieldName={GetEffectiveFieldLabelOrName(parentField, false)}
          />
        );
      }
      break;
    case "TextBox":
    case "ListBox":
    case "CheckBox":
      {
        const parentField = GetDependentFieldsParentField(Field, fields, f => f.ID, f => f.Field.ParentFieldID)
          ?.Field;
        let parentFieldLacksValue = false;
        if (GetEffectiveSelectionListIsDependent(Field)) {
          parentFieldLacksValue = parentField
            ? !parentField.Value
            : true;
        }

        Field.ListValues = GetComposedFieldListLabelsAndValues(Field);
        fieldComponent = (
          <FieldInput Field={Field} key={Field.UpdateId || `f_${ID}`} // This is a hack to force the component to update when the value of Field.UpdateId changes
            // Index={-1}
            // UpdateId={new Date()}
            forceAutoFocus={autoFocus}
            // forceSmallControls
            smallAdornment={(ControlSize !== "") ? true : false}
            redBorderForRequired
            useSmallFont={useSmallFont}
            forcedPlaceholder={forcedPlaceholder}
            hideAdornment={GetHideAdornment(Width)}
            isDisabled={disabled}
            disableColorAlerts
            onKeyDown={e => {
              if (GetFieldPassesValidation(Field)) {
                this.handleKeyDown(e);
              }
            }}
            onValueChange={this.handleValueChange(ID)}
            onGetSelectionListFilterPromise={
              (() => {
                const parentFieldValue = GetDependentFieldsParentValue(Field, fields, f => f.ID);
                return HandleGetFormTemplateFieldListItemsFilter(FormTemplateField, onApiError, parentFieldValue);
              })()
            }
            onSelectionListItemAdd={HandleFormTemplateFieldListItemAdd(FormTemplateField, onApiError, this.handleValueChange)}
            onApiError={onApiError}
            onAlert={this.handleAlert}
            fields={fields}
            disabled={parentFieldLacksValue}
            parentFieldLacksValue={parentFieldLacksValue}
            parentFieldName={GetEffectiveFieldLabelOrName(parentField, true)}
          />
        );
        // if (Type === "CheckBox") {
        //   useMaxContent = true;
        // }
      }
      break;
    case "RadioGroup":
      const radioControls = (PossibleValues && PossibleValues.length)
        ? PossibleValues.filter(v => v).map(v => {
          const requiredClass = (Field.Required) ? classes.required : null;
          const formControlClasses =classNames(classes.radioButtonContainer, requiredClass);
          return (
            <FormControlLabel
              key={v}
              value={v}
              control={
                <Radio 
                  size="small" 
                  autoFocus={autoFocus}
                  name={`r_${ID}_${PossibleValues.indexOf(v)}`/*Name specified to differentiate among other radio groups for proper keyboard tabbing*/}
                />
              }
              label={<Typography>{v}</Typography>}
              className={formControlClasses}
            />
          );
        })
        : [];

      fieldComponent = (
        <FormControl 
          onKeyDown={this.handleKeyDown}
          disabled={disabled}
        >
          <FormLabel component="legend" key={`fl_${ID}`}>
            {fieldLabel}
          </FormLabel>
          <RadioGroup row={!ForMobile && !UseColumnLayout} aria-label={fieldLabel}
            key={Field.UpdateId || `f_${ID}`} // This is a hack to force the component to update when the value of Field.UpdateId changes
            name={fieldLabel}
            value={Value}
            onChange={e => this.handleValueChange(ID)(e.target.value)}
            // style={{
            //   width:(!IsMobile) ? "max-content" : undefined,
            // }}
          >
            {radioControls}
          </RadioGroup>
        </FormControl>
      );
      // useMaxContent = true;
      break;
    case "Signature":
      let signatureLabel = fieldLabel || FormTemplateFieldTypes.filter(t => t.Type === "Signature")[0].TypeLabel;
      fieldComponent = (Value)
        ? (
          <div className={classes.signatureContainer}>
            <FormLabel
              color="primary"
              className={classes.signatureLabel}
            >
              {signatureLabel}
            </FormLabel>
            <Typography
             
              className={classes.signature}
              onClick={() => { if (!disabled) this.handleSetShowGetSignatureValueDialogVisibility(true); }}
            >
              {Value}
            </Typography>
            <Typography className={classes.signatureDate}>
              {dateformat(new Date(), "m/d/yy")}
            </Typography>
          </div>
        )
        : (
          <Button
            key={Field.UpdateId || `f_${ID}`} // This is a hack to force the component to update when the value of Field.UpdateId changes
            variant="contained"
            color="secondary"
            fullWidth={IsMobile()}
            autoFocus={autoFocus}
            disabled={disabled}
            onKeyDown={this.handleKeyDown}
            onClick={() => this.handleSetShowGetSignatureValueDialogVisibility(true)}
          >
            {signatureLabel}
          </Button>
        );
      useMaxContent = true;
      break;
    case "Upload":
      const getUploadButton = clickFunc => {
        return (
          <div className={classes.uploadButtonContainer}>
            <Button
              key={Field.UpdateId || `f_${ID}`} // This is a hack to force the component to update when the value of Field.UpdateId changes
              variant="contained"
              color="secondary"
              style={{backgroundColor:(Field.Required) ? red[500] : undefined}}
              autoFocus={autoFocus}
              disabled={disabled || UploadComplete}
              onKeyDown={this.handleKeyDown}
              onClick={e => (clickFunc) ? clickFunc(e) : undefined}
            >
              {fieldLabel || FormTemplateFieldTypes.filter(t => t.Type === "Upload")[0].TypeLabel}
            </Button>
          </div>
        );
      };
      const handleSetShowUploadMenu = visible => e => {
        this.setState({UploadMenuAnchorEl: (visible && e && e.target) ? e.target : null});
      }
      const getUploadMenuItem = clickFunc => {
        return (
          <MenuItem onClick={e => (clickFunc) ? clickFunc(e) : undefined}>
            <ListItemIcon>
              <DocumentUploadIcon />
            </ListItemIcon>
            Upload
          </MenuItem>
        );
      }
      const handleUploadComplete = reservation => {
        handleSetShowUploadMenu(false)();
        this.setState({UploadComplete:true});
        this.props.onDocumentUploadComplete(this.props.FormTemplateField.ID, reservation);
      }
      const handleImageUploadsComplete = (imageUploadSessionId, imageUploads) => {
        handleSetShowUploadMenu(false)();
        this.setState({UploadComplete:true});
        this.props.onImageUploadsComplete(this.props.FormTemplateField.ID, imageUploadSessionId, imageUploads); 
      }
      const getCaptureCore = onGetChildren => (
        <CaptureCore
          organizationId={organizationId}
          projectId={projectId}
          reservationUri={GetFormTemplatesPublicFieldDocumentUploadsPathForApi(FormTemplate.ID, FormTemplateField.ID)}
          reservationParams={{ uniqueId: FormTemplate.UniqueID }}
          {...restProps}
          fullWidth
          skipFinalization
          onComplete={handleUploadComplete}
          onApiError={onApiError}
          onAlert={this.handleAlert}
          onGetChildren={onGetChildren}
          singleFile
        />
      );
      const handleSetShowDeviceCaptureDialog = ShowDeviceCaptureDialog => {
        handleSetShowUploadMenu(false)();
        this.setState({ShowDeviceCaptureDialog});
      }
      const deviceCaptureDialog = (!IsMobile() && IsMicrosoftWindows()) ? (
        <DeviceCaptureDialog
          open={ShowDeviceCaptureDialog}
          organizationId={organizationId}
          projectId={projectId}
          formTemplate={FormTemplate}
          formTemplateFieldId={FormTemplateField.ID}
          skipFinalization
          closeAfterFirstSubmit
          onComplete={handleImageUploadsComplete}
          onApiError={onApiError}
          onAlert={this.handleAlert}
          onClose={() => handleSetShowDeviceCaptureDialog(false)}
        />
      ) : null;
      const uploadMenu = (!IsMobile() && IsMicrosoftWindows()) ? (
        <Menu
          id="menu-upload"
          anchorEl={UploadMenuAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(UploadMenuAnchorEl)}
          onClose={() => handleSetShowUploadMenu(false)()}
        >
          {getCaptureCore(getUploadMenuItem)}
          <MenuItem onClick={() => handleSetShowDeviceCaptureDialog(true)}>
            <ListItemIcon>
              <TwainCaptureIcon />
            </ListItemIcon>
            Scan
          </MenuItem>
        </Menu>
      ) : null;
      const uploadButton = (!IsMobile() && IsMicrosoftWindows())
        ? getUploadButton(handleSetShowUploadMenu(true))
        : getCaptureCore(getUploadButton);
      fieldComponent = (
        <React.Fragment>
          {uploadButton}
          {deviceCaptureDialog}
          {uploadMenu}
        </React.Fragment>
      );
      useMaxContent = true;
      break;
    default:
      break;
    }

   // const contextImage = (IsMobile() && FormTemplateField.UseContextImage
   //    && FormTemplateField.ContextImageSignedUrl)
   //  ? (<img src={FormTemplateField.ContextImageSignedUrl}
   //      className={classes.contextImage} alt="" />)
   //  : null;

    const minWidth = GetMinFieldWidth(FormTemplateField) - _designerWidthOffset;

    let fieldWidth = Width;
    if (!Width || Width < minWidth) {
      fieldWidth = minWidth;
    }

    const rootClasses = [
      classes.root,
      ...GetFormTemplateFieldControlSizeClasses(FormTemplateField, classes),
    ];

    return (
      <div 
        className={classNames(rootClasses)}
        style={{
          backgroundColor:(!ForMobile
            && FormTemplateField.Type !== "Label"
            && FormTemplateField.Type !== "Text"
            && FormTemplateField.Type !== "Image"
            && FormTemplateField.Type !== "Upload"
            && FormTemplateField.Type !== "Signature"
          ) ? grey[200] : undefined,
          opacity:(ForMobile)
            ? undefined
            : (FormTemplate.FormTemplateSourceID && (Field.Type === "FieldType_Number" || Field.Type === "FieldType_Currency"))
              ? 1
              : 0.8,
          position: (ForMobile) ? "relative" : undefined,
          width: (ForMobile) ? "100%" : (useMaxContent) ? "max-content" : `${fieldWidth*100}%`,
          left: (ForMobile) ? undefined : `${X*100}%`,
          top: (ForMobile) ? undefined : `${Y*100}%`,
          paddingTop: (paddingTop && !ForMobile) ? paddingTop : undefined,
          zIndex:(Type === "Image") ? 0 : Math.max(totalFields - tabIndex, 0), // This fixes an issue where field-based selection lists are not displayed over other fields
        }}
      >
        <MultiUseDialog Details={signAndDateDialogDetails} />
        <MultiUseDialog Details={AlertDetails} />
        {/*{contextImage}*/}
        {fieldComponent}
      </div>
    );
  }
}

FormField.propTypes = {
  classes: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  FormTemplate: PropTypes.object.isRequired,
  FormTemplateField: PropTypes.object.isRequired,
  onValueChange: PropTypes.func.isRequired,
  onDocumentUploadComplete: PropTypes.func.isRequired,
  onImageUploadsComplete: PropTypes.func.isRequired,
  onMoveForward: PropTypes.func.isRequired,
  onMoveBackward: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool,
  key: PropTypes.string,
  totalFields: PropTypes.number,
  ForMobile: PropTypes.bool,
  onApiError: PropTypes.func.isRequired,
}

export default withStyles(styles, {withTheme: true})(FormField);