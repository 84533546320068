import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Tooltip from '@material-ui/core/Tooltip';

import InfoIcon from '@material-ui/icons/Info';

import { IsMobile } from '../Util/MobileDetector';

// import API from '../Util/api';
import DocumentSignatureSessionFormDesignType from '../Model/DocumentSignatureSessionFormDesignType';
import ProgressIndicator from './ProgressIndicator';

const styles = theme => ({
  content: {
    height: "100%",
    overflow:"auto",
    position:"relative",
  },
  grid: {
    paddingTop:theme.spacing(2),
  },
  card: {
    margin:theme.spacing(1.5),
    backgroundColor: theme.palette.background.default,
  },
  cardContent: {
    minHeight: 80,
    minWidth: 180,
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    position:"relative",
  },
  selectedCard: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
  },
  cardContainer: {
    padding:theme.spacing(2),
    display:"flex",
    flexDirection:(IsMobile()) ? "column" : undefined,
    justifyContent:"center",
  },
  infoIcon: {
    position:"absolute",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    opacity:0.5,
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

class DocumentSignatureSessionDesignTypeDialog extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      open: props.open,
      ShowProgressIndicator: false,
      Exportable: {
        // Initial state is a DocumentSignatureSession
        FormDesignType: (this.props.session) ? this.props.session.FormDesignType : null,
      },
    }
  }

  handleClose = (moveBack, moveForward, exportable) => {
    if (!exportable) {
      exportable = this.state.Exportable;
    }
    this.setState({ open: false });
    if (this.props.closeCallback) {
      this.props.closeCallback(moveBack, moveForward, exportable);
    }
  }

  handleBack = () => {
    this.handleClose(true, false);
  }

  handleNext = exportable => {
    if (!exportable) {
      exportable = this.state.Exportable;
    }
    if (!exportable.FormDesignType) {
      this.handleApiError("Please choose a design type.", "Design type required");
      return;
    }

    this.handleClose(false, true, exportable);
  }

  handleDesignTypeClick = formDesignType => {
    if (formDesignType === "FormDesign" && IsMobile()) {
      this.handleApiError("Please use a desktop browser for this design type.", "Productivity");
      return;
    }

    let Exportable = {...this.state.Exportable, FormDesignType: formDesignType};
    this.setState({ 
      Exportable,
    });
    this.handleNext(Exportable);
  }

  handleApiError = (err, title_optional) => {
    this.setState({
      ShowProgressIndicator: false,
    });
    this.props.onApiError(err, title_optional);
    if (err) {
      setTimeout(() => this.props.onApiError(null), 1);
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      this.setState({open: this.props.open !== false});
    }
  }

  render() {
    const { 
      open,
      Exportable,
      ShowProgressIndicator,
  	} = this.state;
    const {
      classes,
      // theme,
    } = this.props;

    let progressIndicator = null;
    if (ShowProgressIndicator) {
      progressIndicator = (
        <ProgressIndicator />
      );
    }

    let dialogActions = (
      <DialogActions>
        <Button onClick={() => this.handleClose()}>
          CLOSE
        </Button>
        <Button onClick={() => this.handleBack()}>
          BACK
        </Button>
        <Button onClick={() => this.handleNext()}>
          NEXT
        </Button>
      </DialogActions>
    );

    let designTypeCards = DocumentSignatureSessionFormDesignType.map(dt => (
      <Card key={`card_${dt.Type}`} 
        className={classNames(
          classes.card,
          (Exportable.FormDesignType === dt.Type) ? classes.selectedCard : undefined,
        )}>
        <CardActionArea
          onClick={() => this.handleDesignTypeClick(dt.Type)}>
          <CardContent className={classes.cardContent}>
            <Typography variant="h6">
              {dt.Label}
            </Typography>

            <Tooltip title={dt.Info}>
              <InfoIcon className={classes.infoIcon} />
            </Tooltip>
          </CardContent>
        </CardActionArea>
      </Card>
    ));

    let content = (
      <div>
        <Typography variant="body1">
          Choose your form-design method.
        </Typography>
        <div className={classes.cardContainer}>
          {designTypeCards}
        </div>
      </div>
    );

    return (
       <Dialog
        fullWidth={!IsMobile()}
        fullScreen={IsMobile()}
        maxWidth="sm"
        TransitionComponent={Transition}
        // PaperProps={{
        //   style:{
        //     minHeight:"50%",
        //   }
        // }}
        open={open}
        onClose={() => this.handleClose()}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description">
        <DialogTitle id="dialog-title">
          <span>Signature form</span>
        </DialogTitle>
        <DialogContent style={{paddingTop:0}}>
          {progressIndicator}

          {content}
        </DialogContent>

        {dialogActions}
      </Dialog>
    );
  }
}

export default withStyles(styles, {withTheme: true})(DocumentSignatureSessionDesignTypeDialog);