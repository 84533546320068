import React, {Component} from 'react';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import Slide from '@material-ui/core/Slide';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';

import {
  GetUserAvatarsPathForApi,
  GetUploadFilePromise,
} from '../Util/api';
import ProgressIndicator from '../Components/ProgressIndicator';
import CaptureCore,
{
  HandleUploadProgress,
} from '../Components/CaptureCore';
import { IsMobile } from '../Util/MobileDetector';

const styles = theme => ({
  tabs: {
  },
  tab: {
  },
  dialogPaper: {
    height:"100%",
  },
  dialogContent: {
    overflow:"hidden",
    display:"flex",
    flexDirection:"column",
  },
  galleryContainer: {
    overflow:"auto",
    height:"100%",
  },
  galleryGrid: {
    marginTop:theme.spacing(2),
    width:"100%",
  },
  avatar: {
    height:(IsMobile()) ? 64 : 96,
    width:(IsMobile()) ? 64 : 96,
  }
});

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="left" ref={ref} {...props} />;
// });

class AvatarSelectionDialog extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      open: props.open,
      SelectedTab: "gallery",
      UploadProgressItems: [],
      FileCount: 0,
      ShowProgressIndicatorImmediately: false,
    }
  }

  handleTabChange = (e, SelectedTab) => {
    this.setState({SelectedTab});
  }

  handleClose = file => {
    this.setState({ open: false });
    if (this.props.onClose) {
      this.props.onClose(file);
    }
  }

  getAvatarGalleryImagePath = galleryNumber => {
    return `https://storage.googleapis.com/${
      process.env.REACT_APP_GOOGLE_PROJECTID}-avatar-gallery/images/${
        galleryNumber.toString().padStart(4, "0")}`;
  }

  handleUploadGalleryAvatarImage = galleryNumber => {
    const cancelToken = axios.CancelToken.source();
    axios.get(this.getAvatarGalleryImagePath(galleryNumber), { responseType: "blob" })
      .then(async resp => {
        const file = new File([resp.data], "", { type: "image/jpeg" });

        const onCompletedFileHandler = (file, image) => {
          return { resp: null, err: null, };
        }

        await GetUploadFilePromise(file, GetUserAvatarsPathForApi(), {}, cancelToken,
          HandleUploadProgress(
            this.props,
            () => cancelToken,
            name => this.state[name],
            state => this.setState(state),
            this.handleAlert
          ),
          onCompletedFileHandler);

        this.handleClose(file);
      })
      .catch(this.handleApiError);
  }

  handleAvatarImageUploadComplete = (reservation, file) => {
    this.handleClose(file);
  }

  handleAlert = details => {
    this.props.onAlert(details);
  }

  handleApiError = err => {
    this.setState({ ShowProgressIndicatorImmediately: false, });
    this.props.onApiError(err);
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      //this.loadData(true);
      this.setState({open: this.props.open !== false});
    }
  }

  render() {
    const {
      open,
      SelectedTab,
      ShowProgressIndicatorImmediately,
      
    } = this.state;
    const {
      history,
      location,
      classes,
      // theme,
    } = this.props;

    let progressIndicator = null;
    if (ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    const dialogActions = (
      <DialogActions>
        <Button onClick={() => this.handleClose()}>
          CLOSE
        </Button>
      </DialogActions>
    );

    const tabs = (
      <Tabs
        variant="fullWidth"
        className={classes.tabs}
        value={SelectedTab}
        onChange={this.handleTabChange}
      >
        <Tab label="Gallery" value="gallery" className={classes.tab} />
        <Tab label="Custom" value="custom" className={classes.tab} />
      </Tabs>
    );

    let tabContent;
    switch (SelectedTab) {
      case "gallery":
        const gridItems = [];
        for (let i = 1; i <= 100; i++) {
          gridItems.push(
            <Grid item key={`gi_${i}`} xs={(IsMobile()) ? 4 : 3}>
              <Button onClick={() => this.handleUploadGalleryAvatarImage(i)}>
                <Avatar
                  className={classes.avatar}
                  src={this.getAvatarGalleryImagePath(i)}
                />
              </Button>
            </Grid>
          );
        }
        tabContent = (
          <div className={classes.galleryContainer}>
            <Grid container spacing={1} className={classes.galleryGrid}>
              {gridItems}
            </Grid>
          </div>
        );
        break;
      case "custom":
        tabContent = (
          <CaptureCore
            history={history}
            location={location}
            reservationUri={GetUserAvatarsPathForApi()}
            fullWidth
            singleFile
            singleFileDisallowGIF
            singleFileMaxSize={15 * 1024}
            hideCaptureOriginalOption
            skipCompleteAlert
            skipFinalization
            onComplete={this.handleAvatarImageUploadComplete}
            acceptTypes="image/jpeg,image/jpg,image/png"
            onApiError={this.handleApiError}
            onAlert={this.handleAlert}
          />
        );
        break;
      default:
        break;
    }

    return (
      <Dialog style={{overflow:"hidden"}}
        fullWidth={!IsMobile()}
        fullScreen={IsMobile()}
        maxWidth="sm"
        // TransitionComponent={Transition}
        open={open}
        onClose={() => this.handleClose()}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DialogTitle id="dialog-title">
          Choose an avatar
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {tabs}
          {tabContent}

          {progressIndicator}
        </DialogContent>
        {dialogActions}
      </Dialog>
    );
  }
}

export default withStyles(styles, {withTheme: true})(AvatarSelectionDialog);
