const DocumentSignatureSessionFormDesignType = [
	{
		Type: "Quick",
		Label: "Quick Design",
		Info: "A blank page is automatically appended for signatures and optional fields. This type is best for documents that lack signature placeholders."
	},
    {
    	Type: "FormDesign",
    	Label: "Form Design",
    	Info: "Design the form by placing signature fields on the document. This type is best for documents that contain signature placeholders."
    },
];

export default DocumentSignatureSessionFormDesignType;