import React from 'react';

import Avatar from '../Components/Avatar';

import dateformat from 'dateformat';

export const GetUserValue = (email, name, size, iconOnly, srcOverride, iconStyle, 
  gridContainerStyle, disablePopover, onAvatarClick) => {
 
  if (!email && !name) {
    return null;
  }

  return (
    <Avatar 
      email={email}
      name={name}
      size={size}
      iconOnly={iconOnly}
      srcOverride={srcOverride}
      iconStyle={iconStyle}
      gridContainerStyle={gridContainerStyle}
      disablePopover={disablePopover}
      onAvatarClick={onAvatarClick}
    />
  );
}

export const GetDateValue = (date, allowPre2000) => {
  const d = new Date(date);
  return (allowPre2000 || d >= new Date("2020-01-01")) 
    // ? dateformat(d, "m/d/yyyy h:MM:ss TT")
    ? dateformat(d, "m/d/yy h:MM TT")
    : "";
}

export const GetFieldTypeLabel = fieldType => {
  switch (fieldType) {
    case "FieldType_Text":
      return "Text";
    case "FieldType_Date":
      return "Date";
    case "FieldType_Number":
      return "Number";
    case "FieldType_Currency":
      return "Currency";
    case "FieldType_Bool":
      return "Bool";
    case "FieldType_Image":
      return "Image";
    default:
      return fieldType;
  }
}

export const GetBoolValue = (value, showFalse) => {
  return value ? "Yes" : (showFalse) ? "No" : "";
}