import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import { withStyles } from '@material-ui/core/styles';

import ProjectCreationDialog from '../Admin/Components/ProjectCreationDialog';
import WelcomeDialog from '../Guides/WelcomeDialog';
import UiCore from '../Components/UiCore';
import AddDashboardWidgetDialog from '../Components/Dashboard/AddDashboardWidgetDialog';
// import DashboardCardDragLayer from '../Components/DashboardCardDragLayer';

import {
  GetHomePath,
  GetProjectTasksPath,
  GetProjectMembersPath,
} from '../Util/PathHelper';
import API, {
  GetOrganizationDashboardWidgetsPathForApi,
} from '../Util/api';
import { GlobalContext } from '../Context/Global.context';

import ProgressIndicator from '../Components/ProgressIndicator';
import HotspotDropTarget from '../Components/HotspotDropTarget';
// import DashboardColumn from '../Components/DashboardColumn';
import SpacerWidget from '../Components/Dashboard/SpacerWidget';
import EmptyWidget from '../Components/Dashboard/EmptyWidget';
import JumpInWidget from '../Components/Dashboard/JumpInWidget';
import JumpInActionsWidget from '../Components/Dashboard/JumpInActionsWidget';
// import GettingStartedWidget from '../Components/Dashboard/GettingStartedWidget';
import GettingStartedActionsWidget from '../Components/Dashboard/GettingStartedActionsWidget';
import FeatureSpotlightWidget from '../Components/Dashboard/FeatureSpotlightWidget';
import UpcomingEventsWidget from '../Components/Dashboard/UpcomingEventsWidget';
import ResourcesWidget from '../Components/Dashboard/ResourcesWidget';
// import CaptureWidget from '../Components/Dashboard/CaptureWidget';
// import DocumentsWidget from '../Components/Dashboard/DocumentsWidget';
import RecentlyAccessedDocumentsWidget from '../Components/Dashboard/RecentlyAccessedDocumentsWidget';
import ProjectsWidget from '../Components/Dashboard/ProjectsWidget';
import TaskSchedulesWidget from '../Components/Dashboard/TaskSchedulesWidget';
import TaskCompletionsWidget from '../Components/Dashboard/TaskCompletionsWidget';
import FormSubmissionsWidget from '../Components/Dashboard/FormSubmissionsWidget';
import SignatureCompletionsWidget from '../Components/Dashboard/SignatureCompletionsWidget';
import SignatureSessionsWidget from '../Components/Dashboard/SignatureSessionsWidget';
// import FolderWidget from '../Components/Dashboard/FolderWidget';
// import ApprovalsWidget from '../Components/Dashboard/ApprovalsWidget';
// import SubscriptionsWidget from '../Components/Dashboard/SubscriptionsWidget';
// import TasksWidget from '../Components/Dashboard/TasksWidget';
// import NeedSignatureWidget from '../Components/Dashboard/NeedSignatureWidget';
// import PowerSearchWidget from '../Components/Dashboard/PowerSearchWidget';
import ReRankItemInCollection from '../Util/ItemRanking';

import FlipMove from 'react-flip-move';
import { IsMobile } from '../Util/MobileDetector';

// import { DropTarget } from 'react-dnd';

// /**
//  * Specifies the drop target contract.
//  * All methods are optional.
//  */
// const dashboardTarget = {
//   // drop(props, monitor, component) {
//   //   if (monitor.didDrop()) {
//   //   //   // If you want, you can check whether some nested
//   //   //   // target already handled drop
//   //     return;
//   //   }

//   //   // Obtain the dragged item
//   //   const sourceDashboardCard = monitor.getItem();
//   //   sourceDashboardCard.onDropDashboardCard();

//   //   // You can also do nothing and return a drop result,
//   //   // which will be available as monitor.getDropResult()
//   //   // in the drag source's endDrag() method
//   //   //return { moved: true };
//   // },
//   hover(props, monitor, component) {
//     if (!component)
//       return null;

//     // // Don't call unless the hover is directly over the dashboard and not another widget
//     // if (monitor.isOver({ shallow: true})) {
//     //   const sourceDashboardCard = monitor.getItem();
//     //   const sourceClientOffset = monitor.getClientOffset();
//     //   component.handleMoveDashboardCard(sourceDashboardCard.DashboardWidget, sourceClientOffset);
//     // }
//   },
// }

// /**
//  * Specifies which props to inject into your component.
//  */
// function dropCollect(connect, monitor) {
//   return {
//     // Call this function inside render()
//     // to let React DnD handle the drag events:
//     connectDropTarget: connect.dropTarget(),
//     // You can ask the monitor about the current drag state:
//     // isOver: monitor.isOver(),
//     // isOverCurrent: monitor.isOver({ shallow: true }),
//     // canDrop: monitor.canDrop(),
//     // itemType: monitor.getItemType()
//   };
// }

const styles = theme => ({
  content: {
    height:"100%",
    overflow:"auto",
    padding: theme.spacing(2),
  },
  grid: {
    position:"relative",
  },
  fab: {
    position: "fixed",
    zIndex: 1,
    right: theme.spacing(3),
    bottom: theme.spacing(2),
    // [theme.breakpoints.down('xs')]: {
    //   display:"none",
    // },
  },
  deleteWidgetHotspot: {
    position: "fixed",
    bottom: theme.spacing(3),
    left: theme.spacing(10),
    zIndex: 2,
  },
});

const rankIncrement = 1000;

class Dashboard extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      DashboardWidgets: [],
      AddDashboardWidgetDialogIsOpen: false,
      PreMoveDashboardWidgetsJson: "",
      ShowCreateProjectDialog: false,
      WidgetIsDragging: false,
      ShowProgressIndicator: false,
    }

    this.DashboardWidgetRefsByID = [];
    this.LastMoveTime = new Date();
    this.SpacerWidgetID = "SpacerWidget";
  }

  adjustSpacer = () => {
    const refFinder = this.DashboardWidgetRefsByID.filter(r => r.id === this.SpacerWidgetID);
    if (refFinder.length) {
      const spacerRef = refFinder[0].ref;
      spacerRef.style.display = "initial";
      const spacerTop = spacerRef.offsetTop;
      const grid = spacerRef.parentNode;
      if (grid) {
        let totalInRow = 0;
        for (let i = 0; i < grid.childNodes.length; i++) {
          const n = grid.childNodes[i];
          if (n.offsetTop === spacerTop) {
            totalInRow++;
          }
        //   if (n === spacerRef) {
        //     continue;
        //   }
        //   console.log(spacerTop, n.offsetTop);
        }
        if (totalInRow === 1) {
          spacerRef.style.display = "none";
        }
      }
    }
  }

  handleLoadWidgets() {
    if (!this.context.CompletedGET.ActiveOrganizationPermissions
      && !this.context.CompletedGET.ProjectMembershipPackages) {
      return setTimeout(() => this.handleLoadWidgets(), 250);
    }
    // Send the user to /home if they aren't an organization member
    if (!this.context.ActiveOrganizationPermissions.OrganizationID) {
      this.props.history.replace(GetHomePath());
      return;
    }
    this.setState({ShowProgressIndicator: true});
    API.get(GetOrganizationDashboardWidgetsPathForApi(
      this.context.ActiveOrganizationPermissions.OrganizationID))
      .then(resp => { 
        let DashboardWidgets = resp.data;
        // Add in an invisible spacer widget, which helps with dragging items in the last row
        DashboardWidgets.push({
          ID: this.SpacerWidgetID,
          Type: "Spacer",
        });
        this.setState({
          DashboardWidgets,
          ShowProgressIndicator: false,
        });
      })
      .catch(err => {
        if (err.response && err.response.status === 404) {
          this.props.history.replace(GetHomePath());
        } else {
          this.handleApiError(err);
        }
      });
  }

  handleOpenAddDashboardWidgetDialog() {
    this.setState({AddDashboardWidgetDialogIsOpen: true});
  }

  handleCloseAddDashboardWidgetDialog() {
    this.setState({AddDashboardWidgetDialogIsOpen: false});
    this.handleLoadWidgets();
  }

  handleSetCreateProjectDialogVisibility = (ShowCreateProjectDialog) => {
    this.setState({
      ShowCreateProjectDialog,
    });
  }

  handleProjectCreated = project => {
    this.handleSetCreateProjectDialogVisibility(false);
    this.context.Reset();
    if (IsMobile()) {
      this.props.history.push(GetProjectTasksPath(project.ID));
    } else {
      this.props.history.push(GetProjectMembersPath(project.ID));
    }
  }

  handleApiError = err => {
    this.setState({
      ApiError: err,
      ShowProgressIndicator: false,
    });
    setTimeout(() => this.setState({ApiError: null}), 1);
  }

  handleAlert = Alert => {
    this.setState({Alert});
  }

  handleStartMoveDashboardCard = widgetId => {
    // Generate rank if the first two have equal rank
    let dashboardWidgets = [...this.state.DashboardWidgets];
    let stateToUpdate = {WidgetIsDragging: true};
    stateToUpdate.PreMoveDashboardWidgetsJson = JSON.stringify(dashboardWidgets);
    if (dashboardWidgets.length > 1 
      && dashboardWidgets[0].Rank === dashboardWidgets[1].Rank) {
      for (let i = 0; i < dashboardWidgets.length; i++) {
        dashboardWidgets[i].Rank = i * rankIncrement;
      }
      stateToUpdate.DashboardWidgets = dashboardWidgets;
    }
    
    const widgetFinder = dashboardWidgets.filter(w => w.ID === widgetId);
    if (widgetFinder.length) {
      widgetFinder[0].IsDragging = true;
    }

    this.setState(stateToUpdate);
  }

  handleEndMoveDashboardCard = () => {
    this.setState({WidgetIsDragging: false})
  }

  handleMoveDashboardCard(sourceDashboardWidget, sourceClientOffset, 
    targetDashboardWidget, targetClientOffset) {

    const lastMoveTimePlus = new Date(this.LastMoveTime);
    lastMoveTimePlus.setMilliseconds(lastMoveTimePlus.getMilliseconds() + 250);
    if (new Date() < lastMoveTimePlus) {
      return;
    }

    let dashboardWidgets = [...this.state.DashboardWidgets];
    const currentSourceWidgetFinder = dashboardWidgets.filter(w => w.ID === sourceDashboardWidget.ID);
    const currentTargetWidgetFinder = dashboardWidgets.filter(w => w.ID === targetDashboardWidget.ID);

    if (!currentSourceWidgetFinder.length) {
      return;
    }
    const currentSourceWidget = currentSourceWidgetFinder[0];
    // console.log("Source DashboardWidget: ", currentSourceWidget.ID, sourceClientOffset);

    const setLastTargetID = lastTargetID => {
      currentSourceWidget.LastTargetID = lastTargetID;
      // sourceFinder[0].LastTargetX = targetClientOffset.x;
      this.setState({ DashboardWidgets: dashboardWidgets });
    };
    
    if (!currentTargetWidgetFinder.length) {
      if (currentSourceWidget.LastTargetID) {
        setLastTargetID(null);
      }
      return;
    }
    const currentTargetWidget = currentTargetWidgetFinder[0];

    // Target is DashboardCard
    // console.log("Target DashboardWidget: ", currentTargetWidget.ID, targetClientOffset);
    if (currentSourceWidget.Rank === currentTargetWidget.Rank) {
      if (currentSourceWidget.LastTargetID) {
        setLastTargetID(null);
      }
      return;
    }
    
    let sourceIndex = dashboardWidgets.indexOf(currentSourceWidget);
    let targetIndex = dashboardWidgets.indexOf(currentTargetWidget);
    if (sourceIndex === targetIndex) {
      if (currentSourceWidget.LastTargetID) {
        setLastTargetID(null);
      }
      return;
    }
    // console.log("Index of source: ", sourceIndex, " Index of target: ", targetIndex);
    if (sourceIndex === null || targetIndex === null) {
      return;
    }

    // console.log("Source", sourceClientOffset.x, "Target", targetClientOffset.x, targetClientOffset.width);
    if (currentSourceWidget.LastTargetID === currentTargetWidget.ID
      // && currentSourceWidget.LastTargetX !== targetClientOffset.x
    ) {
      setLastTargetID(currentTargetWidget.ID);
      return;
    }

    currentSourceWidget.IsDragging = true;
    currentSourceWidget.LastTargetID = currentTargetWidget.ID;
    // currentSourceWidget.LastTargetX = targetClientOffset.x;

    dashboardWidgets.splice(sourceIndex, 1);
    dashboardWidgets.splice(targetIndex, 0, currentSourceWidget);
    this.setState({ DashboardWidgets: dashboardWidgets });
    this.LastMoveTime = new Date();
    this.adjustSpacer();
  }

  clearAllIsDragging = dashboardWidgets => {
    const draggingWidgetFinder = dashboardWidgets.filter(w => w.IsDragging);
    if (draggingWidgetFinder) {
      draggingWidgetFinder.forEach(w => w.IsDragging = false);
    }
  }

  handleAbortMoveDashboardCard = () => {
    const preDashboardWidgets = JSON.parse(this.state.PreMoveDashboardWidgetsJson)
    let originalDashboardWidgets = [];
    preDashboardWidgets.forEach(pw => {
      const currentWidgetFinder = this.state.DashboardWidgets.filter(cw => cw.ID === pw.ID);
      if (currentWidgetFinder.length) {
        let widget = currentWidgetFinder[0];
        widget.IsDragging = false;
        originalDashboardWidgets.push(widget);
      }
    });
    this.setState({ DashboardWidgets: originalDashboardWidgets });
    this.adjustSpacer();
  }

  handleDropDashboardCard(dashboardWidget) {
    if (!dashboardWidget) {
      return;
    }
    this.reRankDashboardWidget(dashboardWidget);
    this.adjustSpacer();
  }

  reRankDashboardWidget(dashboardWidget) {
    let DashboardWidgets = [...this.state.DashboardWidgets];
    this.clearAllIsDragging(DashboardWidgets);
    DashboardWidgets.forEach(w => {
      w.LastTargetID = null;
      // w.LastTargetX = null;
    });
    const {
      Collection: reRankedDashboardWidgets, 
      StartIndex, 
      EndIndex,
    } = ReRankItemInCollection(dashboardWidget, DashboardWidgets, rankIncrement, "Rank");
    const timestamp = new Date();
    reRankedDashboardWidgets.forEach(w => w.RankTimestamp = timestamp);
    this.setState({ DashboardWidgets: reRankedDashboardWidgets});

    // Build array of changed items to send to server
    let preMoveDashboardWidgets = JSON.parse(this.state.PreMoveDashboardWidgetsJson);
    let updatedDashboardWidgets = [];
    for (let i = StartIndex; i <= EndIndex; i++) {
      let dashboardWidget = reRankedDashboardWidgets[i];
      let filtered = preMoveDashboardWidgets.filter(p => p.ID === dashboardWidget.ID)
      if (filtered.length > 0) {
        let preMoveDashboardWidget = filtered[0];
        // console.log("original: ", preMoveDashboardWidget.Rank, " new: ", dashboardWidget.Rank);
        if (dashboardWidget.Rank !== preMoveDashboardWidget.Rank) {
          updatedDashboardWidgets = updatedDashboardWidgets.concat(dashboardWidget);
        }
      }
    }

    // Send changed items to server
    this.handleUpdateDashboardWidgets(updatedDashboardWidgets);
  }

  handleUpdateDashboardWidgets(updatedDashboardWidgets) {
    updatedDashboardWidgets = updatedDashboardWidgets.filter(w => w.Type !== "Spacer");
    API.put(GetOrganizationDashboardWidgetsPathForApi(
      this.context.ActiveOrganizationPermissions.OrganizationID), updatedDashboardWidgets)
      .then(resp => { 
      })
      .catch(this.handleApiError);
  }

  handleUpdateDashboardWidget = dashboardWidget => {
    this.handleUpdateDashboardWidgets([dashboardWidget]);
  }

  handleDeleteDashboardWidget(widget) {
    let dashboardWidgets = [...this.state.DashboardWidgets];
    dashboardWidgets = dashboardWidgets.filter(dw => dw.ID !== widget.ID);
    this.setState({DashboardWidgets: dashboardWidgets});

    API.delete(GetOrganizationDashboardWidgetsPathForApi(
      this.context.ActiveOrganizationPermissions.OrganizationID), { data: { IDs: [widget.ID] } })
      .then(resp => {
      })
      .catch(this.handleApiError);
  }

  handleSetDashboardWidgetRefByID = (id, ref) => {
    const existingRefFinder = this.DashboardWidgetRefsByID.filter(r => r.id === id);
    if (existingRefFinder.length) {
      existingRefFinder[0].ref = ref;
    } else {
      this.DashboardWidgetRefsByID.push({
        id,
        ref,
      });
      if (id === this.SpacerWidgetID) {
        this.adjustSpacer();
      }
    }
  }

  isProjectAdmin = projectId => {
    const projectMemberPkgFinder = this.context.ProjectMembershipPackages
      .filter(p => p.Project.ID === projectId);
    const isProjectMember = projectMemberPkgFinder.length > 0;
    const isProjectAdmin = isProjectMember && projectMemberPkgFinder[0].ProjectMember.IsAdmin;
    return isProjectAdmin;
  }

  componentDidMount() {
    if (process.env.REACT_APP_DEPLOYMENT_TYPE === "production") {
      // <!-- Event snippet for Sign-up conversion page -->
      const script1 = document.createElement("script");
      script1.id = "gevt1";
      script1.async = true;
      const script1Body = document.createTextNode("gtag('event', 'conversion', {'send_to': 'AW-16550211969/52lACJfU67EZEIHj4NM9'});");
      script1.appendChild(script1Body);
      document.head.appendChild(script1);
    }

    this.handleLoadWidgets();
  }

  componentDidUpdate() {
  }
  
  render() {
    const {
      ApiError,
      Alert,
      DashboardWidgets,
      AddDashboardWidgetDialogIsOpen,
      WidgetIsDragging,
      ShowCreateProjectDialog,
      ShowProgressIndicator,
    } = this.state;
    const { 
      classes,
      history,
      location,
      // theme,
      // connectDropTarget,
    } = this.props;
    const {
      ActiveOrganizationPermissions,
      UserPreferences,
    } = this.context;
    const {
      ActiveOrganizationID,
      ActiveOrganizationMember,
    } = UserPreferences;

    const getOnMoveDashboardCardFunction = targetDashboardWidget => 
      (sourceDashboardWidget, sourceClientOffset) => {
      
      const targetRefFinder = this.DashboardWidgetRefsByID.filter(r => r.id === targetDashboardWidget.ID);
      const targetClientOffset = (targetRefFinder.length && targetRefFinder[0].ref)
        ? targetRefFinder[0].ref.getBoundingClientRect()
        : -1;
      return this.handleMoveDashboardCard(sourceDashboardWidget, sourceClientOffset, 
        targetDashboardWidget, targetClientOffset);
    }

    let widgetGridItems = [];
    const getCoreWidgetProps = widget => { return {
      key: widget.ID,
      history,
      location,
      organizationId: ActiveOrganizationID,
      DashboardWidget: widget,
      onSetWidgetRef: ref => this.handleSetDashboardWidgetRefByID(widget.ID, ref),
      onStartMoveDashboardCard: this.handleStartMoveDashboardCard,
      onEndMoveDashboardCard: this.handleEndMoveDashboardCard,
      onMoveDashboardCard: getOnMoveDashboardCardFunction(widget),
      onAbortMoveDashboardCard: this.handleAbortMoveDashboardCard,
      onDropDashboardCard: () => this.handleDropDashboardCard(widget),
      onUpdateDashboardWidget: this.handleUpdateDashboardWidget,
      onApiError: this.handleApiError,
      onAlert: this.handleAlert,
    }};
    for (let i = 0; i < DashboardWidgets.length; i++) {
      const widget = DashboardWidgets[i];
      if (widget === null) {
        continue;
      }
      switch (widget.Type) {
      case "Spacer":
        widgetGridItems.push(
          <SpacerWidget
            key={widget.ID}
            DashboardWidget={widget}
            onSetWidgetRef={ref => this.handleSetDashboardWidgetRefByID(this.SpacerWidgetID, ref)}
          />
        );
        break;
      case "Empty":
        widgetGridItems.push(
          <EmptyWidget
            {...getCoreWidgetProps(widget)}
          />
        );
        break;
      case "JumpIn":
        widgetGridItems.push(
          <JumpInWidget
            {...getCoreWidgetProps(widget)}
          />
        );
        break;
      case "JumpInActions":
        widgetGridItems.push(
          <JumpInActionsWidget
            {...getCoreWidgetProps(widget)}
          />
        );
        break;
      // case "GettingStarted":
      //   widgetGridItems.push(
      //     <GettingStartedWidget
      //       {...getCoreWidgetProps(widget)}
      //     />
      //   );
      //   break;
      case "GettingStartedActions":
        widgetGridItems.push(
          <GettingStartedActionsWidget
            {...getCoreWidgetProps(widget)}
          />
        );
        break;
      // case "GettingStartedActions":
      //   widgetGridItems.push(
      //     <GettingStartedActionsWidget
      //       {...getCoreWidgetProps(widget)}
      //     />
      //   );
      //   break;
      case "FeatureSpotlight":
        widgetGridItems.push(
          <FeatureSpotlightWidget
            {...getCoreWidgetProps(widget)}
          />
        );
        break;
      case "UpcomingEvents":
        widgetGridItems.push(
          <UpcomingEventsWidget
            {...getCoreWidgetProps(widget)}
          />
        );
        break;
      case "Resources":
        widgetGridItems.push(
          <ResourcesWidget
            {...getCoreWidgetProps(widget)}
          />
        );
        break;
      case "Projects":
        widgetGridItems.push(
          <ProjectsWidget
            {...getCoreWidgetProps(widget)}
          />
        );
        break;
        // case "Capture":
        //   if (!ActiveOrganizationPermissions.IsReadOnly) {
        //     widgetComponent = (
        //       <CaptureWidget
        //         history={this.props.history}
        //         onApiError={err => this.handleApiError(err)}
        //         onAlert={details => this.handleAlert(details)}
        //         DashboardWidget={widget}
        //         onStartMoveDashboardCard={this.handleStartMoveDashboardCard}
        //         onEndMoveDashboardCard={this.handleEndMoveDashboardCard}
        //         onMoveDashboardCard={sourceDashboardWidget => this.handleMoveDashboardCard(sourceDashboardWidget, widget)}
        //         onAbortMoveDashboardCard={this.handleAbortMoveDashboardCard}
        //         onDropDashboardCard={() => this.handleDropDashboardCard(widget)}
        //       />
        //     );
        //   }
        // break;
        // case "Documents":
        //   widgetGridItems.push(
        //     <DocumentsWidget
        //       {...getCoreWidgetProps(widget)}
        //     />
        //   );
        // break;
        case "RecentlyAccessedDocuments":
          widgetGridItems.push(
            <RecentlyAccessedDocumentsWidget
              {...getCoreWidgetProps(widget)}
            />
          );
          break;
        case "TaskSchedules":
          if (this.isProjectAdmin(widget.ProjectID)) {
            widgetGridItems.push(
              <TaskSchedulesWidget
                {...getCoreWidgetProps(widget)}
              />
            );
          }
          break;
        case "TaskCompletions":
          if (this.isProjectAdmin(widget.ProjectID)) {
            widgetGridItems.push(
              <TaskCompletionsWidget
                {...getCoreWidgetProps(widget)}
              />
            );
          }
          break;
        case "FormSubmissions":
          if (this.isProjectAdmin(widget.ProjectID)) {
            widgetGridItems.push(
              <FormSubmissionsWidget
                {...getCoreWidgetProps(widget)}
              />
            );
          }
          break;
        case "SignatureCompletions":
          if (this.isProjectAdmin(widget.ProjectID)) {
            widgetGridItems.push(
              <SignatureCompletionsWidget
                {...getCoreWidgetProps(widget)}
              />
            );
          }
          break;
        case "SignatureSessions":
          if (this.isProjectAdmin(widget.ProjectID)) {
            widgetGridItems.push(
              <SignatureSessionsWidget
                {...getCoreWidgetProps(widget)}
              />
            );
          }
          break;
        // case "Folder":
        //   widgetComponent = (
        //     <FolderWidget
        //       history={this.props.history}
        //       onApiError={err => this.handleApiError(err)}
        //       onAlert={details => this.handleAlert(details)}
        //       DashboardWidget={widget}
        //       onStartMoveDashboardCard={this.handleStartMoveDashboardCard}
        //       onEndMoveDashboardCard={this.handleEndMoveDashboardCard}
        //       onMoveDashboardCard={sourceDashboardWidget => this.handleMoveDashboardCard(sourceDashboardWidget, widget)}
        //       onAbortMoveDashboardCard={this.handleAbortMoveDashboardCard}
        //       onDropDashboardCard={() => this.handleDropDashboardCard(widget)}
        //     />
        //   );
        // break;
        // case "Approvals":
        //   if (!ActiveOrganizationPermissions.IsReadOnly) {
        //     widgetComponent = (
        //       <ApprovalsWidget
        //         history={this.props.history}
        //         onApiError={err => this.handleApiError(err)}
        //         onAlert={details => this.handleAlert(details)}
        //         DashboardWidget={widget}
        //         onStartMoveDashboardCard={this.handleStartMoveDashboardCard}
        //         onEndMoveDashboardCard={this.handleEndMoveDashboardCard}
        //         onMoveDashboardCard={sourceDashboardWidget => this.handleMoveDashboardCard(sourceDashboardWidget, widget)}
        //         onAbortMoveDashboardCard={this.handleAbortMoveDashboardCard}
        //         onDropDashboardCard={() => this.handleDropDashboardCard(widget)}
        //       />
        //     );
        //   }
        // break;
        // case "Subscriptions":
        //   widgetComponent = (
        //     <SubscriptionsWidget
        //       history={this.props.history}
        //       onApiError={err => this.handleApiError(err)}
        //       onAlert={details => this.handleAlert(details)}
        //       DashboardWidget={widget}
        //       onStartMoveDashboardCard={this.handleStartMoveDashboardCard}
        //       onEndMoveDashboardCard={this.handleEndMoveDashboardCard}
        //       onMoveDashboardCard={sourceDashboardWidget => this.handleMoveDashboardCard(sourceDashboardWidget, widget)}
        //       onAbortMoveDashboardCard={this.handleAbortMoveDashboardCard}
        //       onDropDashboardCard={() => this.handleDropDashboardCard(widget)}
        //     />
        //   );
        // break;
        // case "Tasks":
        //   if (!ActiveOrganizationPermissions.IsReadOnly) {
        //     widgetComponent = (
        //       <TasksWidget
        //         history={this.props.history}
        //         onApiError={err => this.handleApiError(err)}
        //         onAlert={details => this.handleAlert(details)}
        //         DashboardWidget={widget}
        //         onStartMoveDashboardCard={this.handleStartMoveDashboardCard}
        //         onEndMoveDashboardCard={this.handleEndMoveDashboardCard}
        //         onMoveDashboardCard={sourceDashboardWidget => this.handleMoveDashboardCard(sourceDashboardWidget, widget)}
        //         onAbortMoveDashboardCard={this.handleAbortMoveDashboardCard}
        //         onDropDashboardCard={() => this.handleDropDashboardCard(widget)}
        //       />
        //     );
        //   }
        // break;
        // case "NeedSignature":
        //   widgetComponent = (
        //     <NeedSignatureWidget
        //       history={this.props.history}
        //       onApiError={err => this.handleApiError(err)}
        //       onAlert={details => this.handleAlert(details)}
        //       DashboardWidget={widget}
        //       onStartMoveDashboardCard={this.handleStartMoveDashboardCard}
        //       onEndMoveDashboardCard={this.handleEndMoveDashboardCard}
        //       onMoveDashboardCard={sourceDashboardWidget => this.handleMoveDashboardCard(sourceDashboardWidget, widget)}
        //       onAbortMoveDashboardCard={this.handleAbortMoveDashboardCard}
        //       onDropDashboardCard={() => this.handleDropDashboardCard(widget)}
        //     />
        //   );
        // break;
        // case "NeedSignatureAdmin":
        //   if (!ActiveOrganizationPermissions.IsReadOnly && ActiveOrganizationPermissions.IsAdmin) {
        //     widgetComponent = (
        //       <NeedSignatureWidget
        //         isAdmin
        //         history={this.props.history}
        //         onApiError={err => this.handleApiError(err)}
        //         onAlert={details => this.handleAlert(details)}
        //         DashboardWidget={widget}
        //         onStartMoveDashboardCard={this.handleStartMoveDashboardCard}
        //         onEndMoveDashboardCard={this.handleEndMoveDashboardCard}
        //         onMoveDashboardCard={sourceDashboardWidget => this.handleMoveDashboardCard(sourceDashboardWidget, widget)}
        //         onAbortMoveDashboardCard={this.handleAbortMoveDashboardCard}
        //         onDropDashboardCard={() => this.handleDropDashboardCard(widget)}
        //       />
        //     );
        //   }
        // break;
        // case "PowerSearch":
        //   widgetComponent = (
        //     <PowerSearchWidget
        //       history={this.props.history}
        //       onApiError={err => this.handleApiError(err)}
        //       onAlert={details => this.handleAlert(details)}
        //       DashboardWidget={widget}
        //       onStartMoveDashboardCard={this.handleStartMoveDashboardCard}
        //       onEndMoveDashboardCard={this.handleEndMoveDashboardCard}
        //       onMoveDashboardCard={sourceDashboardWidget => this.handleMoveDashboardCard(sourceDashboardWidget, widget)}
        //       onAbortMoveDashboardCard={this.handleAbortMoveDashboardCard}
        //       onDropDashboardCard={() => this.handleDropDashboardCard(widget)}
        //     />
        //   );
        // break;
        default:
        break;
      }
    }

    let deleteWidgetHotspot = null;
    if (WidgetIsDragging) {
      deleteWidgetHotspot = (
        <HotspotDropTarget
          className={classes.deleteWidgetHotspot}
          icon={<DeleteIcon />}
          onDrop={dashboardCard => this.handleDeleteDashboardWidget(dashboardCard.DashboardWidget)}
        />
      );
    }

    const addWidgetDialog = (DashboardWidgets)
      ? (
        <AddDashboardWidgetDialog
          organizationId={ActiveOrganizationID}
          history={this.props.history}
          location={this.props.location}
          open={AddDashboardWidgetDialogIsOpen}
          onApiError={this.handleApiError}
          onClose={() => this.handleCloseAddDashboardWidgetDialog()}
          activeDashboardWidgets={DashboardWidgets}
        />
      ) : null;

    const progressIndicator = (ShowProgressIndicator)
      ? (
        <ProgressIndicator />
      ) : null;

    const welcomeDialog = (
      <WelcomeDialog
        location={location}
        promoteProjectCreation={ActiveOrganizationMember}
        onBeginCreateProject={() => this.handleSetCreateProjectDialogVisibility(true)}
      />
    );

    const projectCreationDialog = (ActiveOrganizationID)
      ? (
        <ProjectCreationDialog
          organizationId={ActiveOrganizationID}
          open={ShowCreateProjectDialog || false}
          onCreated={this.handleProjectCreated}
          onClose={() => this.handleSetCreateProjectDialogVisibility(false)}
          onApiError={this.handleApiError}
        />
      ) : null;

    // Checking for IsReadOnly presence prevents some widgets from showing unexpectedly for a brief time
    const content = (ActiveOrganizationPermissions.IsReadOnly === undefined)
      ? null
      : (
      <div
        className={classes.content}
        // ref={instance => connectDropTarget(ReactDOM.findDOMNode(instance))}
      >

        <Fab aria-label="Add widget" className={classes.fab}
          style={{
            opacity: (WidgetIsDragging) ? 0 : undefined,
          }}
          color="secondary"
          onClick={() => this.handleOpenAddDashboardWidgetDialog()}>
          <AddIcon />
        </Fab>

        {addWidgetDialog}
        {deleteWidgetHotspot}
        {progressIndicator}
        {welcomeDialog}
        {projectCreationDialog}

        {/*<DashboardCardDragLayer />*/}

        <Grid container spacing={2} className={classes.grid}>
          <FlipMove typeName={null} appearAnimation="elevator" duration={250}>
            {widgetGridItems}
          </FlipMove>  
        </Grid>
        
      </div>
    );

    return (
      <UiCore
        title="Dashboard"
        showOrgAsTitleOnDesktop
        orgAsTitlePreposition=" for "
        apiError={ApiError}
        alert={Alert}
        content={content}
      />
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  // Injected by React DnD:
  // connectDropTarget: PropTypes.func.isRequired,
};

export default /*DropTarget('DashboardCard', dashboardTarget, dropCollect)(*/withStyles(styles, {withTheme: true})(Dashboard)/*)*/;