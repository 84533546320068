import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import AssetItemDialogGrid from '../../Components/AssetItemDialogGrid';
import ProgressIndicator from '../../Components/ProgressIndicator';
import ProcessElementDialogHelper from '../../Util/ProcessElementDialogs';

import {
  GetDedupedValues,
  GetComposedFieldListLabelsAndValues,
  GetUpdatedFieldObjectForValueChange,
} from '../../Util/Field';
import API, {
  GetAssetItemFieldsByAssetPathForApi,
} from '../../Util/api';
import { ClearAndReloadDependentFieldValues } from '../../Util/AssetItem';

export default function GetContent(location, history, organizationId, projectId, processElement, processElementConnections_Input,
  onGetLocalState, onSetLocalState, onGetLocalProperty, onSetLocalProperty,
  onApiError, onAlert, onGetListValuesAndLabelsPropertyName, onChangeHandlers,
  onSelectionListValueChange, onSelectionListCreateOption, onSetCloseDialogFunc) {
  
  if (!processElement) {
    return null;
  }

  const dh = new ProcessElementDialogHelper(location, history, organizationId, projectId, processElement, processElementConnections_Input,
    onGetLocalState, onSetLocalState, onGetLocalProperty, onSetLocalProperty, 
    onApiError, onGetListValuesAndLabelsPropertyName, onChangeHandlers,
    onSelectionListValueChange);

  const fieldOverridePrefix = "fieldOverride_";

  const handleFieldValueChange = assetItemField => {
    onChangeHandlers.setStringProperty(
      fieldOverridePrefix+assetItemField.ID,
      true, 
      (assetItemField.DisplaySelectionList && assetItemField.AllowMultipleValues)
        ? assetItemField.Values
        : assetItemField.Value,
      assetItemField.Mask > "");
  }

  const handleSetInitialFieldValue = assetItemField => {
    dh.setInitialIndexFieldValue(assetItemField, fieldOverridePrefix)
  }

  const assetIdListValue = processElement[onGetListValuesAndLabelsPropertyName("AssetID", false)];
 
  const assetItemDialogGridItems = (assetIdListValue)
    ? (
      <Grid item>
        <LocalAssetItemDialog
          organizationId={organizationId}
          projectId={projectId}
          assetId={assetIdListValue && assetIdListValue.value}
          onFieldValueChange={handleFieldValueChange}
          onSetInitialFieldValue={handleSetInitialFieldValue}
          dialogHelper={dh}
          onApiError={onApiError}
          onAlert={onAlert}
        />
      </Grid>
    ) : null;

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        {dh.GetAssetsAsAsyncSelectControl()}
      </Grid>
      {assetItemDialogGridItems}
    </Grid>
  );
}

class LocalAssetItemDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      AssetItemFields: [],
      ShowProgressIndicatorImmediately: false,
    }

    this.completedSetCloseDialogFunc = false;
  }

  handleGetAssetItemFields = () => {
    this.setState({ShowProgressIndicatorImmediately: true});

    const uri = GetAssetItemFieldsByAssetPathForApi(this.props.organizationId, this.props.projectId,
      this.props.assetId);
    return API.get(uri)
      .then(resp => {
        const AssetItemFields = resp.data;
        this.handleUpdateAssetItemFieldsState(AssetItemFields);
        // Set initial values from ProcessElement
        AssetItemFields.forEach(aif => {
          this.props.onSetInitialFieldValue(aif);
        });
        this.setState({ShowProgressIndicatorImmediately: false});
      })
      .catch(this.handleApiError);
  }

  handleFieldValueChange = fieldId => (e, selectedOptions) => {
    let assetItemFields = [...this.state.AssetItemFields];
    let assetItemField = assetItemFields.filter(aif => aif.FieldID === fieldId)[0];
    GetUpdatedFieldObjectForValueChange(assetItemField, e, selectedOptions);

    // If a dependent field is cleared, raise the value-changed handler for that fields
    const postFieldValueClearedAction = (clearedChildField) => {
      this.props.onFieldValueChange(clearedChildField);
    };
    // Clear any fields that are selection lists and a child of the current field
    ClearAndReloadDependentFieldValues(assetItemField, assetItemFields, false, postFieldValueClearedAction);
    
    this.handleUpdateAssetItemFieldsState(assetItemFields);
    this.props.onFieldValueChange(assetItemField);
  }

  handleFieldImageChange = fieldId => (imageObjectName, imageSignedUrl) => {
    // Not implemented
  }

  handleUpdateAssetItemFieldsState = AssetItemFields => {
    // Add/Update ListValues for each Fields[]
    for (let i = 0; i < AssetItemFields.length; i++) {
      let assetItemField = AssetItemFields[i];
      // if (updateFields) {
      //   assetItemField.UpdateId = new Date();
      // }
      if (typeof assetItemField.ListValues === "undefined") {
        Object.assign(assetItemField, { ListValues: [] });
      }
      // For multi-value fields, dedupe (for safety)
      assetItemField.Values = GetDedupedValues(assetItemField);
      // ListValues are for selection lists
      assetItemField.ListValues = GetComposedFieldListLabelsAndValues(assetItemField);
      // Values must exist as a JSON array
      if (Array.isArray(assetItemField.Values)) {
        // Don't send "[]" empty value
        if (!assetItemField.Values.length) {
          assetItemField.Values = "";  
        }
      } else {
        // Don't send "false" value when Boolean
        if (assetItemField.Type === "FieldType_Bool" && assetItemField.Value === "false") {
          assetItemField.Value = "";
        }
      }
    }
    this.setState({AssetItemFields});
  }

  handleApiError = err => {
    this.setState({ShowProgressIndicatorImmediately: false});
    this.props.onApiError(err);
  }

  init = () => {
    if (this.props.assetId) {
      this.handleGetAssetItemFields();
    }
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.assetId !== this.props.assetId) {
      this.init();
    }
  }

  render() {
    const {
      ShowProgressIndicatorImmediately,
      AssetItemFields,
    } = this.state;
    const { 
      organizationId,
      projectId,
      assetId,
      dialogHelper,
      onAlert,
    } = this.props;

    let progressIndicator = null;
    if (ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator constrained showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    let content = null;
    if (assetId && AssetItemFields) {
      // Filter out image fields
      const assetItemFields = AssetItemFields.filter(aif => aif.Type !== "FieldType_Image");

      content = (
        <React.Fragment>
          {progressIndicator}
          <Grid container direction="column" spacing={3}>
            <Grid item>
              {dialogHelper.GetActionContentForTags()}
            </Grid>
            <Grid item>
              <Typography>
                Field values below override values from items delivered to this action.
              </Typography>
            </Grid>
            <Grid item>
              <AssetItemDialogGrid
                organizationId={organizationId}
                projectId={projectId}
                AssetItemFields={assetItemFields}
                isWorkflow
                onGetFieldImageUploadReservationUri={() => {}/*handleGetFieldImageUploadReservationUri*/}
                onFieldValueChange={this.handleFieldValueChange}
                onFieldImageChange={this.handleFieldImageChange}
                onApiError={this.handleApiError}
                onAlert={onAlert}
                Fields={assetItemFields}
              />
            </Grid>
          </Grid>
        </React.Fragment>
      );
    }

    return content;
  }
}

LocalAssetItemDialog.propTypes = {
  // classes: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  assetId: PropTypes.string.isRequired,
  dialogHelper: PropTypes.object.isRequired,
  onFieldValueChange: PropTypes.func.isRequired,
  onSetInitialFieldValue: PropTypes.func.isRequired,
  onApiError: PropTypes.func.isRequired,
  onAlert: PropTypes.func.isRequired,
};