import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';

import Card from './Card';

import { 
  GetFileSizePageCountSpan,
} from '../Util/Document';
import { 
  GetCardMetadataFromPreviewMetadata,
} from '../Util/Fields';
import ActionType from '../Model/ActionType';

import {
  GetDateValue,
} from '../Util/Properties';

class RecycleBinCard extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
    }
  }

  render() {
    const { 
      Document,
      sensitiveFields,
      noSelect,
      selected,
      onSelect,
      onAction,
      onCardAction,
      disableCardActions,
      forcedThumbnailUrl,
      style,
    } = this.props;

    let metadata = [];
    metadata.push(
      ["", GetFileSizePageCountSpan(Document.FileSize, Document.PageCount)],
    );
    metadata.push(
      ["Project", Document.ProjectName],
      ["Created On", GetDateValue(Document.CreatedOn)],
    );
    
    metadata.push(["Purge Date", GetDateValue(Document.PurgeDate)]);
    metadata.push(...GetCardMetadataFromPreviewMetadata(Document.PreviewMetadata, sensitiveFields));

    let downloadIconButton = (
      <IconButton
        key={ActionType.Download.Id}
        aria-label={ActionType.Download.Label}
        title={ActionType.Download.Label}
        onClick={() => onAction(ActionType.Download, Document.ID)}
        >
        {ActionType.Download.Icon}
      </IconButton>
    );

    return (
      <Card
        style={style}
        onCardAction={onCardAction}
        noSelect={noSelect}
        onSelect={onSelect}
        selected={selected}
        height={256}
        name={Document.Name}
        fileName={Document.Name}
        isSigned={Document.IsSigned}
        thumbnailUrl={forcedThumbnailUrl || Document.ThumbnailUrl}
        metadata={metadata}
        hoverActionItems={!disableCardActions
          ? [
            downloadIconButton,
          ]
          : undefined
        }
      />
    );
  }
}

RecycleBinCard.propTypes = {
  Document: PropTypes.object.isRequired,
  onCardAction: PropTypes.func.isRequired,
  disableCardActions: PropTypes.bool,
}

export default RecycleBinCard;