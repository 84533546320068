import API, {
  GetSensitiveFieldsPathForApi,
} from '../Util/api';

import {
  GetDocumentDetailPath,
  GetPublicDocumentDetailPath,
  GetPublicDocumentDetailInlinePath,
} from '../Util/PathHelper';

import {
  IsUserProjectAdmin,
} from '../Util/User';

export const DocumentQuery = {
	Endpoint: "/",
	Params: {},
	SortType: "",
	SortDescending: null,
	Offset: 0,
  PreviousCursor: "",
  CurrentCursor: "",
  NextCursor: "",
}

export function GetBase64DocumentQuery(endpoint, params, sortType, sortDescending, offset, previousCursor, currentCursor, nextCursor) {
	let q = {...DocumentQuery};
	q.Endpoint = endpoint;
	q.Params = params;
	q.SortType = sortType;
	q.SortDescending = sortDescending;
	q.Offset = offset;
  q.PreviousCursor = previousCursor;
  q.CurrentCursor = currentCursor;
  q.NextCursor = nextCursor;
	return btoa(JSON.stringify(q));
}

export function GetDocumentQueryFromBase64(base64) {
	return JSON.parse(atob(base64));
}

export function GetActiveDocumentsByCriteria(endpoint, params, cursor, offset, sortType, sortDescending, singleRecord) {
  if (params === undefined || params === null) {
  	params = {};
  }
  if (cursor) {
    params.cursor = cursor;
    params.offset = -1;
  } else if (offset !== undefined && offset !== null) {
    params.cursor = "";
    params.offset = offset;
  }
  if (sortType) {
    params.sortType = sortType;
  } 
  if (sortDescending !== undefined && sortDescending !== null) {
    params.sortDescending = sortDescending;
  } 
  if (singleRecord) {
  	params.singleRecord = true;
  }

  return API.get(endpoint, { params })
    .then(resp => resp.data)
    ;
}

export const GetSensitiveFields = (organizationId, projectId, onSetState_optional, onApiError) => {
  return API.get(GetSensitiveFieldsPathForApi(organizationId, projectId))
    .then(resp => {
      if (resp.data.Fields && onSetState_optional) {
        onSetState_optional({ SensitiveFields: resp.data.Fields });
      }
      return resp.data.Fields;
    })
    .catch(err => {
      if (onSetState_optional) {
        onSetState_optional({ SensitiveFields: [] });
      }
      onApiError(err);
    });
}

export const HandleRouteToDocument = (organizationId, projectId, docOrId, documents, contentUri, 
  contentUriParams, sortType, sortDescending, routeToSignatureGather, navigateToNextOnCommit, 
  extraUrlParams, userAssignmentContext, loadByPublicApi, loadInline, inlinePrefix, propsHistory) => {

  if (loadInline && propsHistory) {
    OpenDocumentDetailInline(organizationId, projectId, docOrId, routeToSignatureGather, extraUrlParams,
      navigateToNextOnCommit, userAssignmentContext, loadByPublicApi, inlinePrefix, propsHistory,
      documents, contentUri, contentUriParams, sortType, sortDescending);
  } else {
    OpenDocumentDetailTab(organizationId, projectId, docOrId, routeToSignatureGather, extraUrlParams,
      navigateToNextOnCommit, userAssignmentContext, loadByPublicApi,
      documents, contentUri, contentUriParams, sortType, sortDescending);
  }
}

export const OpenDocumentDetailTab = (organizationId, projectId, docOrId, routeToSignatureGather, 
  extraUrlParams, navigateToNextOnCommit, userAssignmentContext, loadByPublicApi,
  documents, contentUri, contentUriParams, sortType, sortDescending,
  ) => {
  const url = GetUrlForDocument(organizationId, projectId, docOrId, routeToSignatureGather, 
    extraUrlParams, navigateToNextOnCommit, userAssignmentContext, loadByPublicApi, false,
    "", documents, contentUri, contentUriParams, sortType, sortDescending);
  if (url) {
    // this.props.history.push(url);
    window.open(url, "n1_documentContent");
  }
}

export const OpenDocumentDetailInline = (organizationId, projectId, docOrId, routeToSignatureGather, 
  extraUrlParams, navigateToNextOnCommit, userAssignmentContext, loadByPublicApi, inlinePrefix,
  propsHistory, documents, contentUri, contentUriParams, sortType, sortDescending) => {

  const url = GetUrlForDocument(organizationId, projectId, docOrId, routeToSignatureGather, 
    extraUrlParams, navigateToNextOnCommit, userAssignmentContext, loadByPublicApi, true,
    inlinePrefix, documents, contentUri, contentUriParams, sortType, sortDescending);
  if (url && propsHistory) {
    propsHistory.push(url);
  }
}

export const GetUrlForDocument = (organizationId, projectId, docOrId, routeToSignatureGather, 
  extraUrlParams, navigateToNextOnCommit, userAssignmentContext, loadByPublicApi, loadInline,
  inlinePrefix, documents, contentUri, contentUriParams, sortType, sortDescending) => {
  
  // Get offset of current document if applicable
  let offset = (documents && documents.length)
    ? documents.indexOf(docOrId) : 0;

  // Send current query along for navigation
  let extraUrlParamsForCall = "q=" + GetBase64DocumentQuery(
    contentUri,
    contentUriParams,
    sortType,
    sortDescending,
    offset);
  if (extraUrlParams) {
    Object.keys(extraUrlParams).forEach(k => {
      extraUrlParamsForCall += `&${k}=${extraUrlParams[k]}`;
    });
  }

  let url = "";
  // From the signature area route to signing page
  if (routeToSignatureGather && docOrId.DocumentSignatureSessionID && docOrId.UniqueID) {
    url = `/sign/${docOrId.DocumentSignatureSessionID}/${docOrId.ID}/${docOrId.UniqueID}`;
  }
  // Regular document detail
  else {
    url = (loadByPublicApi)
      ? (loadInline)
        ? GetPublicDocumentDetailInlinePath(inlinePrefix, docOrId.DocumentID || docOrId.ID || docOrId)
        : GetPublicDocumentDetailPath(organizationId, projectId, docOrId.DocumentID || docOrId.ID || docOrId)
      : GetDocumentDetailPath(organizationId, projectId, docOrId.DocumentID || docOrId.ID || docOrId);
    let urlParams = "";
    if (!loadByPublicApi) {
      urlParams = "?w=1"; // w denotes a new window is being created
      if (extraUrlParamsForCall) {
        urlParams += "&" + extraUrlParamsForCall;
      }
      if (navigateToNextOnCommit) {
        urlParams += "&nnoc=1";
      }
      if (userAssignmentContext) {
        urlParams += `&uac=${userAssignmentContext}`;
      }
    }

    url += urlParams;
  }
  return url;
}

export const GetDocumentLinkComponent = (organizationId, projectId, doc, theme, extraUrlParams, userAssignmentContext) => {
  return (
    <span
      onClick={e => {
        e.stopPropagation();
        // e.preventDefault();
        OpenDocumentDetailTab(organizationId, projectId, doc, false, extraUrlParams, false, userAssignmentContext);
      }}
      style={{
        textDecoration: "underline",
        color: theme.palette.type === "dark" ? "#aaa" : undefined,
      }}
    >
      {doc.Name}
    </span>
  );
}

export const AreDocumentsOrDocumentFoldersLoadedByAssignment = (context, projectId) => {
    const projectMemberPkg = context.ProjectMembershipPackages
      .find(p => p.Project.ID === projectId);
    if (!projectMemberPkg) {
      return true;
    }
    return (projectMemberPkg.Project.Access.MemberAccess.DocumentFolders === "Assigned")
      ? !IsUserProjectAdmin(context, projectId)
      : false;
  }