import React from 'react';
import Button from '@material-ui/core/Button';
// import IconButton from '@material-ui/core/IconButton';
// import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import Typography from '@material-ui/core/Typography';
// import Slide from '@material-ui/core/Slide';
import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
// import List from '@material-ui/core/List';
// import TextField from '@material-ui/core/TextField';
// import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';

// import SettingsIcon from '@material-ui/icons/Settings';
import CheckmarkIcon from '@material-ui/icons/Done';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ProgressIndicator from './ProgressIndicator';
// import MultiUseDialog from './MultiUseDialog';

import { GlobalContext } from '../Context/Global.context';
// import { IsMobile } from '../Util/MobileDetector';
import {
  GetOrganizationManagementPath,
} from '../Util/PathHelper';

const styles = theme => ({
  dialogPaper: {
    // height:(!IsMobile()) ? "80%" : undefined,
  },
  dialogTitle: {
    // backgroundColor:theme.palette.background.default,
  },
  dialogContent: {
    height:"100%",
    // overflow:"hidden",
    // backgroundColor:theme.palette.background.default,
  },
  dialogActions: {
    // backgroundColor:theme.palette.background.default,
  },
  dialogContentContainer: {
    height:"100%",
    display:"flex",
    flexDirection:"column",
  },
  nestedList: {
    paddingTop:0,
    paddingBottom:theme.spacing(2),
  },
  nestedListItem: {
    paddingLeft: theme.spacing(1.5),
  },
  organizationListItemIcon: {
    minWidth:36,
  },
  settingsIconButton: {
    position: "absolute",
    right:theme.spacing(1),
    top:theme.spacing(2),
  },
});

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="left" ref={ref} {...props} />;
// });

class OrgSelectDialog extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      ShowProgressIndicator: false,
    }
  }

  handleRouteToOrganizations = () => {
    this.handleClose();
    const uri = `${GetOrganizationManagementPath()}${
      (this.context.UserPreferences.ActiveOrganizationID)
        ? "/" + this.context.UserPreferences.ActiveOrganizationID
        : ""
      }`;
    if (window.location.pathname.startsWith(uri)) {
      return;
    }
    this.props.history.push(uri);
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleApiError = err => {
    this.setState({ShowProgressIndicator: false});
    this.props.onApiError(err);
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      let stateToUpdate = {
        open: this.props.open !== false,
      }
      this.setState(stateToUpdate);
    }
  }

  render() {
    const {
      open,
      ShowProgressIndicator,
    } = this.state;
    const {
      classes,
      theme,
      onSwitchOrganization,
    } = this.props;
    const {
      UserPreferences,
      UserOrganizations,
    } = this.context;

    const dialogActions = (
      <DialogActions className={classes.dialogActions}>
        <Button onClick={() => this.handleClose()}>
          CLOSE
        </Button>
      </DialogActions>
    );

    const progressIndicator = (ShowProgressIndicator)
      ? (
        <ProgressIndicator showImmediately={false} />
      ) : null;

    let dialogTitle = (
        <DialogTitle id="dialog-title" className={classes.dialogTitle}>
          Organizations
        </DialogTitle>
      );

    // For sorting organizations
    const compareOrganizations = (a, b) => {
      if (a.NameLower < b.NameLower) {
        return -1;
      }
    };
    let organizationLists = [];
    UserOrganizations.sort(compareOrganizations).forEach(uo => {
      if (UserPreferences.ActiveOrganizationID === uo.OrganizationID) {
      }
      organizationLists.push(
        <ListItem button key={uo.OrganizationID}
          className={classes.nestedListItem}
          onClick={() => onSwitchOrganization(uo.OrganizationID)}>
          <ListItemIcon className={classes.organizationListItemIcon}>
            <CheckmarkIcon
            style={{
              display: (UserPreferences.ActiveOrganizationID !== uo.OrganizationID) ? "none" : "inherit",
              color: theme.palette.type === "dark" ? theme.palette.text.primary : theme.palette.primary.main, 
            }} />
          </ListItemIcon>
          {uo.OrganizationName}
        </ListItem>
      ); 
    });

    let dialogContent = (
      <div className={classes.dialogContentContainer}>
        {organizationLists}
      </div>
    );

    const settingsIconButton = (
      <Tooltip title="Manage organizations">
        <Button 
          className={classes.settingsIconButton}
          aria-label="Manage"
          onClick={() => this.handleRouteToOrganizations()}>
          {/*<SettingsIcon style={{marginRight:theme.spacing(1)}} />*/}
          <span>MANAGE</span>
        </Button>
      </Tooltip>
    );

    return (
      <Dialog
        // fullScreen={IsMobile()}
        // fullWidth={!IsMobile()}
        // TransitionComponent={Transition}
        maxWidth="xs"
        fullWidth
        open={open}
        classes={{
          paper:classes.dialogPaper,
        }}
        onClose={() => this.handleClose()}
        aria-labelledby="dialog-title"
        // aria-describedby="dialog-description"
      >
        {dialogTitle}
        <DialogContent className={classes.dialogContent}>
          {progressIndicator}
          {dialogContent}
          {settingsIconButton}
          
        </DialogContent>
        {dialogActions}
      </Dialog>
    );
  }
}

OrgSelectDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onApiError: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onSwitchOrganization: PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(OrgSelectDialog);