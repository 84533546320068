const FieldType = [
	{
		Type: "FieldType_Text",
		Label: "Text",
	},
	{
		Type: "FieldType_Number",
		Label: "Number",
	},
	{
		Type: "FieldType_Currency",
		Label: "Currency",
	},
	{
		Type: "FieldType_Date",
		Label: "Date",
	},
	{
		Type: "FieldType_Bool",
		Label: "Checkbox",
	},
	{
		Type: "FieldType_Image",
		Label: "Image",
	},
];

export default FieldType;