import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import red from '@material-ui/core/colors/red';

import DocumentUploadIcon from '@material-ui/icons/CloudUpload';
import ImageCaptureIcon from '@material-ui/icons/Scanner';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import NewFolderIcon from '@material-ui/icons/CreateNewFolder';
import DocumentCreateIcon from '@material-ui/icons/NoteAdd';
import FolderIcon from '@material-ui/icons/Folder';
import ClearIcon from '@material-ui/icons/Clear';
import { FolderMoveIcon } from '../Util/Icons';

import AsyncSelectControl from '../Components/AsyncSelectControl';
import MultiUseDialog from '../Components/MultiUseDialog';
import SetDocumentFolderDialog from '../Components/SetDocumentFolderDialog';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import API, {
  GetDocumentFoldersPathForApi,
  GetDocumentFoldersPublicPathForApi,
  GetDocumentFolderPathForApi,
  GetProjectSettingsPathForApi,
  GetProjectSettingPathForApi,
  GetUserOrganizationDocumentFoldersPathForApi,
  GetUserOrganizationProjectDocumentFolderPathForApi,
} from '../Util/api';
import {
  GetDocumentFoldersNode,
  DocumentFoldersNodeType,
  DocumentsNodeType,

  DocumentsNodeDefaultName,
} from '../Util/Nodes';
import {
  GetTreeNodeComponent,
  GetNodeForGetMoreItems,
} from '../Util/Tree';
import {
  GetUserValue,
} from '../Util/Properties';
import {
  GetProjectDocumentFolderPath,
  GetWorkspaceDocumentFolderPath,
  GetWorkspaceDocumentFoldersPath,
  GetOrganizationLink,
  GetPublicDocumentFoldersPath,
} from '../Util/PathHelper';
import {
  GetAddressBookItemsPromise,
} from '../Util/AddressBookItems';
import { ValidateEmail } from '../Util/Regex';
import { IsMobile } from '../Util/MobileDetector';
import { IsMicrosoftWindows } from '../Util/MicrosoftWindowsDetector';
import { CompactPicker } from 'react-color';
import debounce from 'es6-promise-debounce';

export const DocumentFoldersPathElement = "/documentFolders";

const getCurrentAndAncestorDocumentFolderIdsInOrder = (documentFolder, routeToProjectsScreen) => {
  let documentFolderIdsInOrder = [];
  if (routeToProjectsScreen && documentFolder.AncestorIDs && documentFolder.AncestorIDs.length) {
    documentFolderIdsInOrder.push(...documentFolder.AncestorIDs);
  }
  documentFolderIdsInOrder.push(documentFolder.ID);
  return documentFolderIdsInOrder;
}

export const HandleRouteToDocumentFolder = (props, documentFolder, routeToProjectsScreen) => {
  const documentFolderIdsInOrder = getCurrentAndAncestorDocumentFolderIdsInOrder(documentFolder,
    routeToProjectsScreen);
  const uri = (routeToProjectsScreen)
    ? GetProjectDocumentFolderPath(documentFolder.ProjectID, documentFolderIdsInOrder)
    : GetWorkspaceDocumentFolderPath(documentFolder.ProjectID, documentFolderIdsInOrder);
  props.history.push(uri, { ...props.location.state });
}

export const GetRootDocumentFolderNode = SelectOnMount => {
  const documentFoldersNode = GetDocumentFoldersNode();
  return {
    ...documentFoldersNode,
    Name: "Document root",
    HasChildren: true,
    Open: true,
    DocumentFolderID: "",
    IsDocumentFolderRoot: true,
    RootId: undefined,
    SelectOnMount,
  };
}

export default class DocumentFolders {
  constructor(state_optional, onSetState, onApiError, organizationId, projectId,
    isWorkspace, loadByAssignment, userEmailForAssignmentContext, loadByPublicApi,
    onBeginFileUploadFunc, onBeginImageCaptureFunc, onBeginDocumentCreateFunc, reloadItemsFunc,
    documentFolderNodeExpandFunctionsByUniqueId, onSetDocumentFolderNodeExpandFunctions,
    onDocumentFolderNodeCreated, onNodeSelected, onDocumentFolderUpdated, 
    disallowContextMenuActionIds, onSetRootNodePropertiesFromProjectSetting, userIsProjectAdmin) {

    if (typeof onSetState !== "function") {
      console.log("Warning: onSetState required and not found");
    }
    if (typeof onApiError !== "function") {
      console.log("Warning: onApiError required and not found");
    }
    if (typeof organizationId !== "string") {
      console.log("Warning: organizationId required and not found");
    }

    this.setState = onSetState;
    this.handleApiError = onApiError;
    this.organizationId = organizationId;
    this.projectId = projectId;
    this.userEmailForAssignmentContext = userEmailForAssignmentContext;
    this.isWorkspace = isWorkspace;
    this.loadByAssignment = loadByAssignment;
    this.loadByPublicApi = loadByPublicApi;
    this.onBeginFileUploadFunc = onBeginFileUploadFunc;
    this.onBeginImageCaptureFunc = onBeginImageCaptureFunc;
    this.onBeginDocumentCreateFunc = onBeginDocumentCreateFunc;
    this.reloadItemsFunc = reloadItemsFunc;
    this.onDocumentFolderNodeCreated = onDocumentFolderNodeCreated;
    this.onNodeSelected = onNodeSelected;
    this.onDocumentFolderUpdated = onDocumentFolderUpdated;
    this.onSetRootNodePropertiesFromProjectSetting = onSetRootNodePropertiesFromProjectSetting;
    this.userIsProjectAdmin = userIsProjectAdmin;

    this.DocumentFolderNodeExpandFunctionsByUniqueId = documentFolderNodeExpandFunctionsByUniqueId || [];
    this.onSetDocumentFolderNodeExpandFunctions = onSetDocumentFolderNodeExpandFunctions;
    this.documentFolderRootCollapseFunc = null;
    this.colorPopoverRef = React.createRef();
    this.disallowContextMenuActionIds = disallowContextMenuActionIds;

    this.setState({
      ShowDocumentFolderDialog: false,
      DocumentFolderContextMenuNode: null,
      DocumentFolderNodesByNodeId: (state_optional.DocumentFolderNodesByNodeId)
        ? [...state_optional.DocumentFolderNodesByNodeId]
        : [],
      DocumentFolderName: "",
      DocumentFolderEmailListValues: [],
      DocumentFolderDialogAction: null,
      DocumentFolderDialogTitle: "",
      DocumentFolderDialogConfirmLabel: null,
      DocumentFolderAncestorAssignmentUserEmails: null,
      ShowDeleteDocumentFolderConfirmation: false,
      ShowMoveDocumentFolderDialog: false,
      ShowProjectSettingsDialog: false,
      ProjectSettingsForDialog: {},
      ProjectSettingsDialogPopoverText: null,
    });

    this.ProjectSettingsDialogCopyButtonRef = React.createRef();
  }

  GetContent = (props_optional, getState) => {
    const state = getState();
    const {
      DocumentFolderContextMenuNode,
      ShowDocumentFolderDialog,
      DocumentFolderName,
      DocumentFolderEmailListValues,
      DocumentFolderAncestorAssignmentUserEmails,
      DocumentFolderDialogAction,
      DocumentFolderDialogTitle,
      DocumentFolderDialogConfirmLabel,
      ShowDeleteDocumentFolderConfirmation,
      ShowMoveDocumentFolderDialog,
      ShowProjectSettingsDialog,
      ProjectSettingsDialogPopoverText,
      DocumentFolderDialogHideSharing,
      DocumentFolderDialogColorAnchorEl,
      DocumentFolderHexColor,
      DocumentFolderDialogColorPickerColor,
      ProjectSettingsForDialog,
    } = state;

    const ancestorAssignmentUserEmailsGridItem = (!DocumentFolderDialogHideSharing
      && DocumentFolderAncestorAssignmentUserEmails
      && DocumentFolderAncestorAssignmentUserEmails.length)
      ? (
        <Grid item>
          <Typography variant="subtitle1">
            Shared by inheritance
          </Typography>
          <Typography variant="body2">
            {DocumentFolderAncestorAssignmentUserEmails.join("\u2003")}
          </Typography>
        </Grid>
      ) : null;

    const parentFolderGridItem = (DocumentFolderDialogAction === "folderCreate")
      ? (
        <Grid item>
          <Typography variant="subtitle1">
            {`Parent folder: ${DocumentFolderContextMenuNode.Name}`}
          </Typography>
        </Grid>
      ) : null;
    const sharingGridItem = (!DocumentFolderDialogHideSharing)
      ? (
        <Grid item>
          <AsyncSelectControl label="Share with people (optional)" 
            // floatingOptions
            isMulti
            onCreateOption={this.handleAddDocumentFolderEmailListValues(state)}
            allowCreateWhileLoading
            autoReloadOnValueChange
            onGetOptionsFilterPromise={filter => this.handleGetAddressBookItemsPromise(getState, filter)}
            listValues={DocumentFolderEmailListValues}
            onValueChange={this.handleAddDocumentFolderEmailListValues(state)}
          />
        </Grid>
      ) : null

    const handleColorPopoverBlur = () => {
      if (!this.colorPopoverRef
        || !this.colorPopoverRef.contains(document.activeElement)) {
        this.setState({DocumentFolderDialogColorAnchorEl: null});
      }
    }
    const handleColorChange = (color, e) => {
      this.setState({DocumentFolderDialogColorPickerColor: color.hex});
    }
    const handleColorChangeComplete = (DocumentFolderDialogColorPickerColor, e) => {
      // console.log(color, e);
      this.setState({
        DocumentFolderDialogColorPickerColor,
        DocumentFolderHexColor:DocumentFolderDialogColorPickerColor.hex,
      });
    }
    const handleClearColor = () => {
      this.setState({
        DocumentFolderHexColor: null,
        DocumentFolderDialogColorPickerColor: {},
      });
    }
    const clearFolderColorGridItem = (DocumentFolderHexColor)
      ? (
        <Grid item>
          <Tooltip title="Clear folder color">
            <IconButton aria-label="Clear folder color" size="small" onClick={handleClearColor}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ) : null;
    const folderColorGridItem = (
      <Grid item>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <FolderIcon
              style={{
                color:DocumentFolderHexColor,
              }}
              onClick={e => this.setState({DocumentFolderDialogColorAnchorEl: e.currentTarget})}
            />
          </Grid>
          <Grid item>
            <Button onClick={e => this.setState({DocumentFolderDialogColorAnchorEl: e.currentTarget})}>
              SET COLOR
            </Button>
            <Popover
              anchorEl={DocumentFolderDialogColorAnchorEl}
              // keepMounted
              open={Boolean(DocumentFolderDialogColorAnchorEl)}
              onBlur={e => setTimeout(() => handleColorPopoverBlur(e), 1)}
              anchorOrigin={{
                vertical:'bottom',
                horizontal:'center',
              }}
              transformOrigin={{
                vertical:'top',
                horizontal:'center',
              }}
              ref={instance => this.colorPopoverRef = instance}
            >
              <CompactPicker
                color={DocumentFolderDialogColorPickerColor}
                onChange={handleColorChange}
                onChangeComplete={handleColorChangeComplete}
              />
            </Popover>
          </Grid>
          {clearFolderColorGridItem}
        </Grid>
      </Grid>
    );

    const documentFolderDialogDetails = {
      Open:ShowDocumentFolderDialog,
      Title: DocumentFolderDialogTitle || "Folder properties",
      DialogWidth: "md",
      FullWidth: (!IsMobile()),
      FullScreen: (IsMobile()),
      IsConfirmation: true,
      BodyContent: (
        <Grid container direction="column" spacing={3} style={{marginBottom:0}}>
          {parentFolderGridItem}
          <Grid item>
            <TextField
              fullWidth
              autoFocus
              variant="outlined"
              label="Name"
              onChange={e => this.setState({DocumentFolderName:e.target.value})}
              value={DocumentFolderName}
              onKeyPress={e => {if (e.key === "Enter") { this.createOrUpdateDocumentFolder(props_optional, state); }}}
            />
          </Grid>
          {sharingGridItem}
          {ancestorAssignmentUserEmailsGridItem}
          {folderColorGridItem}
        </Grid>
      ),
      ConfirmLabel: DocumentFolderDialogConfirmLabel,
      CloseCallback:() => this.handleSetDocumentFolderDialogVisibility(false),
      CancelCallback:() => this.handleSetDocumentFolderDialogVisibility(false),
      ConfirmCallback:() => this.createOrUpdateDocumentFolder(props_optional, state),
    };

    const deleteDocumentFolderConfirmationDialogDetails = {
      Open:ShowDeleteDocumentFolderConfirmation,
      // ShowProgressIndicator:ShowDialogProgressIndicator,
      IsConfirmation:true,
      Title:`Delete ${(DocumentFolderContextMenuNode) ? '"' + DocumentFolderContextMenuNode.Name + '"' : "folder"}?`,
      BodyContent:(
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <DialogContentText>
              All subfolders will be deleted.
              <br />
              Documents will be sent to the recycle bin.
            </DialogContentText>
          </Grid>
          <Grid item>
            <DialogContentText style={{color:red[600]}}>
              This action cannot be undone.
            </DialogContentText>
          </Grid>
        </Grid>
      ),
      CancelCallback:() => this.handleSetDeleteDocumentFolderConfirmationVisibility(false),
      CloseCallback:() => this.handleSetDeleteDocumentFolderConfirmationVisibility(false),
      ConfirmCallback:() => this.handleDeleteDocumentFolder(props_optional, state),
    };

    const moveDocumentFolderDialog = (ShowMoveDocumentFolderDialog)
      ? (
        <SetDocumentFolderDialog
          open={ShowMoveDocumentFolderDialog}
          organizationId={this.organizationId}
          projectId={this.projectId}
          ignoreDocumentFolderId={
            (state.DocumentFolderContextMenuNode && state.DocumentFolderContextMenuNode.DocumentFolderID)
              ? state.DocumentFolderContextMenuNode.DocumentFolderID
              : undefined
          }
          onApiError={this.handleApiError}
          onAction={documentFolderId => this.handleMoveDocumentFolder(props_optional, state, documentFolderId)}
          onClose={() => this.handleSetMoveFolderDialogVisibility(false)}
        />
      ) : null;
    // const moveDocumentFolderDialogDetails = {
    //   Open:ShowMoveDocumentFolderDialog,
    //   // ShowProgressIndicator:ShowDialogProgressIndicator,
    //   IsConfirmation:true,
    //   Title:`Move ${(DocumentFolderContextMenuNode) ? '"' + DocumentFolderContextMenuNode.Name + '"' : "folder"}?`,
    //   BodyContent:(
        
    //   ),
    //   CancelCallback:() => this.handleSetMoveFolderDialogVisibility(false),
    //   CloseCallback:() => this.handleSetMoveFolderDialogVisibility(false),
    //   ConfirmCallback:() => this.handleMoveDocumentFolder(props_optional, state),
    // };

    const handleShowProjectSettingsDialogPopover = text => {
      this.setState({ ProjectSettingsDialogPopoverText: text });
      setTimeout(() => this.setState({ ProjectSettingsDialogPopoverText: null }), 1500); 
    }

    const projectSettingsDialogPopover = (
      <Popover
        open={Boolean(ProjectSettingsDialogPopoverText)}
        onClose={() => handleShowProjectSettingsDialogPopover(null)}
        anchorEl={(this.ProjectSettingsDialogCopyButtonRef
          && !this.ProjectSettingsDialogCopyButtonRef.hasOwnProperty('current'))
            ? this.ProjectSettingsDialogCopyButtonRef
            : undefined
        }
        anchorOrigin={{
          vertical:'center',
          horizontal:'center',
        }}
        transformOrigin={{
          vertical:'center',
          horizontal:'center',
        }}
      >
        <div style={{ 
          backgroundColor:(props_optional && props_optional.theme)
            ? props_optional.theme.palette.background.pane
            : undefined,
          padding:16,
        }}>
          {ProjectSettingsDialogPopoverText}
        </div>
      </Popover>
    );

    const documentRootLabelGridItem = (
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          label="Root Label"
          InputLabelProps={{ shrink: true, }}
          onChange={e => this.handleUpdateProjectSetting(state, "DocumentRootLabel", "StringValue", e.target.value)}
          value={ProjectSettingsForDialog.DocumentRootLabel}
          placeholder={DocumentsNodeDefaultName}
        />
      </Grid>
    );

    const embedCode = `<iframe width="100%" height="100%" frameborder="0" src="${
      window.location.origin}${GetPublicDocumentFoldersPath(this.organizationId, 
        this.projectId)}"></iframe>`;
    const embedGridItems = (ProjectSettingsForDialog.AllowPublicDocumentFolders)
      ? (
        <React.Fragment>
          <Grid item>
            Embed code
            <CopyToClipboard
              text={embedCode}
              onCopy={() => handleShowProjectSettingsDialogPopover("Copied to clipboard!")}
            >
              <Button
                size="small"
                style={{marginLeft:16}}
                ref={instance => this.ProjectSettingsDialogCopyButtonRef = instance}
              >
                COPY
              </Button>
            </CopyToClipboard>
          </Grid>
          <Grid item>
            <code>
              {embedCode}
            </code>
          </Grid>
        </React.Fragment>
      ) : null;
    const enablePublicAccessGridItem = (
      <Grid item xs={12}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  checked={ProjectSettingsForDialog.AllowPublicDocumentFolders || false}
                  onChange={e => this.handleUpdateProjectSetting(state, "AllowPublicDocumentFolders", "BoolValue", e.target.checked)}
                />
              }
              label="Enable public access to ALL folders and ALL documents" />
          </Grid>
          {embedGridItems}
        </Grid>
      </Grid>
    );
    const fontUrlGridItem = (
      <Grid item xs={6}>
        <TextField
          fullWidth
          variant="outlined"
          label="Font URL"
          helperText="@font-face stylesheet"
          onChange={e => this.handleUpdateProjectSetting(state, "PublicFontURL", "StringValue", e.target.value)}
          value={ProjectSettingsForDialog.PublicFontURL}
        />
      </Grid>
    );
    const fontFamilyGridItem = (
      <Grid item xs={6}>
        <TextField
          fullWidth
          variant="outlined"
          label="Font Family"
          helperText="Comma-separated list of font names from Font URL stylesheet"
          onChange={e => this.handleUpdateProjectSetting(state, "PublicFontFamily", "StringValue", e.target.value)}
          value={ProjectSettingsForDialog.PublicFontFamily}
        />
      </Grid>
    );

    const folderIconGridItem = (
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          label="Folder Icon URL"
          helperText="URL to SVG/PNG/JPG"
          onChange={e => this.handleUpdateProjectSetting(state, "PublicFolderIconURL", "StringValue", e.target.value)}
          value={ProjectSettingsForDialog.PublicFolderIconURL}
        />
      </Grid>
    );

    const getColorLabel = (text, color) => {
      return (
        <React.Fragment>
          <span>{text}</span>
          {
            (color)
              ? <span
                  style={{
                    color,
                    marginLeft:10,
                  }}
                >&#9608;</span>
              : null
          }
        </React.Fragment>
      );
    }

    const primaryColorGridItem = (
      <Grid item xs={3}>
        <TextField
          fullWidth
          variant="outlined"
          // label="Primary Color"
          label={getColorLabel("Primary Color", ProjectSettingsForDialog.PublicPrimaryColor)}
          helperText="CSS color for text, icons"
          onChange={e => this.handleUpdateProjectSetting(state, "PublicPrimaryColor", "StringValue", e.target.value)}
          value={ProjectSettingsForDialog.PublicPrimaryColor}
        />
      </Grid>
    );
    const secondaryColorGridItem = (
      <Grid item xs={3}>
        <TextField
          fullWidth
          variant="outlined"
          // label="Secondary Color"
          label={getColorLabel("Secondary Color", ProjectSettingsForDialog.PublicSecondaryColor)}
          helperText="CSS color for text, icons"
          onChange={e => this.handleUpdateProjectSetting(state, "PublicSecondaryColor", "StringValue", e.target.value)}
          value={ProjectSettingsForDialog.PublicSecondaryColor}
        />
      </Grid>
    );
    const primaryAccentColorGridItem = (
      <Grid item xs={3}>
        <TextField
          fullWidth
          variant="outlined"
          // label="Primary Accent"
          label={getColorLabel("Primary Accent", ProjectSettingsForDialog.PublicPrimaryAccentColor)}
          helperText="CSS color for control accents"
          onChange={e => this.handleUpdateProjectSetting(state, "PublicPrimaryAccentColor", "StringValue", e.target.value)}
          value={ProjectSettingsForDialog.PublicPrimaryAccentColor}
        />
      </Grid>
    );
    const secondaryAccentColorGridItem = (
      <Grid item xs={3}>
        <TextField
          fullWidth
          variant="outlined"
          // label="Secondary Accent"
          label={getColorLabel("Secondary Accent", ProjectSettingsForDialog.PublicSecondaryAccentColor)}
          helperText="CSS color for control accents"
          onChange={e => this.handleUpdateProjectSetting(state, "PublicSecondaryAccentColor", "StringValue", e.target.value)}
          value={ProjectSettingsForDialog.PublicSecondaryAccentColor}
        />
      </Grid>
    );

    const defaultBackgroundColorGridItem = (
      <Grid item xs={3}>
        <TextField
          fullWidth
          variant="outlined"
          // label="Default Background"
          label={getColorLabel("Default Background", ProjectSettingsForDialog.PublicDefaultBackgroundColor)}
          helperText="CSS color"
          onChange={e => this.handleUpdateProjectSetting(state, "PublicDefaultBackgroundColor", "StringValue", e.target.value)}
          value={ProjectSettingsForDialog.PublicDefaultBackgroundColor}
        />
      </Grid>
    );
    const paneBackgroundColorGridItem = (
      <Grid item xs={3}>
        <TextField
          fullWidth
          variant="outlined"
          // label="Pane Background"
          label={getColorLabel("Pane Background", ProjectSettingsForDialog.PublicPaneBackgroundColor)}
          helperText="CSS color"
          onChange={e => this.handleUpdateProjectSetting(state, "PublicPaneBackgroundColor", "StringValue", e.target.value)}
          value={ProjectSettingsForDialog.PublicPaneBackgroundColor}
        />
      </Grid>
    );
    const toolBarBackgroundColorGridItem = (
      <Grid item xs={3}>
        <TextField
          fullWidth
          variant="outlined"
          // label="ToolBar Background"
          label={getColorLabel("ToolBar Background", ProjectSettingsForDialog.PublicToolBarBackgroundColor)}
          helperText="CSS color"
          onChange={e => this.handleUpdateProjectSetting(state, "PublicToolBarBackgroundColor", "StringValue", e.target.value)}
          value={ProjectSettingsForDialog.PublicToolBarBackgroundColor}
        />
      </Grid>
    );
    const paperBackgroundColorGridItem = (
      <Grid item xs={3}>
        <TextField
          fullWidth
          variant="outlined"
          // label="Paper Background"
          label={getColorLabel("Paper Background", ProjectSettingsForDialog.PublicPaperBackgroundColor)}
          helperText="CSS color for cards/lists"
          onChange={e => this.handleUpdateProjectSetting(state, "PublicPaperBackgroundColor", "StringValue", e.target.value)}
          value={ProjectSettingsForDialog.PublicPaperBackgroundColor}
        />
      </Grid>
    );
    const treeNodeHighlightBackgroundColorGridItem = (
      <Grid item xs={3}>
        <TextField
          fullWidth
          variant="outlined"
          // label="Selected Folder Background"
          label={getColorLabel("Selected Folder BG", ProjectSettingsForDialog.PublicTreeNodeHighlightBackgroundColor)}
          helperText="CSS color"
          onChange={e => this.handleUpdateProjectSetting(state, "PublicTreeNodeHighlightBackgroundColor", "StringValue", e.target.value)}
          value={ProjectSettingsForDialog.PublicTreeNodeHighlightBackgroundColor}
        />
      </Grid>
    );
    const cardActionBarBackgroundColorGridItem = (
      <Grid item xs={3}>
        <TextField
          fullWidth
          variant="outlined"
          // label="Card ActionBar Background"
          label={getColorLabel("Card ActionBar BG", ProjectSettingsForDialog.PublicCardActionBarBackgroundColor)}
          helperText="CSS color"
          onChange={e => this.handleUpdateProjectSetting(state, "PublicCardActionBarBackgroundColor", "StringValue", e.target.value)}
          value={ProjectSettingsForDialog.PublicCardActionBarBackgroundColor}
        />
      </Grid>
    );

    let publicFoldersThemeGridItems = null;
    if (ProjectSettingsForDialog.AllowPublicDocumentFolders) {
      publicFoldersThemeGridItems = (
        <React.Fragment>
          {fontUrlGridItem}
          {fontFamilyGridItem}
          {folderIconGridItem}

          {primaryColorGridItem}
          {secondaryColorGridItem}
          {primaryAccentColorGridItem}
          {secondaryAccentColorGridItem}

          {defaultBackgroundColorGridItem}
          {paneBackgroundColorGridItem}
          {toolBarBackgroundColorGridItem}
          {paperBackgroundColorGridItem}
          {treeNodeHighlightBackgroundColorGridItem}
          {cardActionBarBackgroundColorGridItem}
        </React.Fragment>
      );
    }

    const projectSettingsDialogDetails = {
      Open:ShowProjectSettingsDialog,
      Title: "Document Folders",
      DialogWidth: "md",
      FullWidth: (!IsMobile()),
      FullScreen: (IsMobile()),
      // IsConfirmation: true,
      BodyContent: (
        <Grid container spacing={2} style={{marginBottom:0}}>
          {documentRootLabelGridItem}
          {enablePublicAccessGridItem}
          {projectSettingsDialogPopover}
          {publicFoldersThemeGridItems}
        </Grid>
      ),
      ConfirmLabel: "UPDATE",
      CloseCallback:() => this.handleSetProjectSettingsDialogVisibility(false),
      CancelCallback:() => this.handleSetProjectSettingsDialogVisibility(false),
    };
    const projectSettingsDialog = (ShowProjectSettingsDialog)
      ? (
        <MultiUseDialog Details={projectSettingsDialogDetails} />
      ) : null;

    return (
      <React.Fragment>
        {projectSettingsDialog}
        <MultiUseDialog Details={documentFolderDialogDetails} />
        <MultiUseDialog Details={deleteDocumentFolderConfirmationDialogDetails} />
        {/*<MultiUseDialog Details={moveDocumentFolderDialogDetails} />*/}
        {moveDocumentFolderDialog}
      </React.Fragment>
    );
  }

  getNodeFromDocumentFolder = (props_optional, ParentNode, documentFolder, selectOnMountIfEndOfPath) => {
    const currentDocumentFolderPathIsActive = props_optional && props_optional.location
      && props_optional.location.pathname
      && props_optional.location.pathname.indexOf(documentFolder.ID) > -1;
    const currentDocumentFolderPathIsEndOfPath = props_optional && props_optional.location
      && props_optional.location.pathname
      && props_optional.location.pathname.endsWith(documentFolder.ID);
    // const documentFoldersElementIsEndOfPath = props.location.pathname.endsWith(DocumentFoldersPathElement);

    // If there is no ParentNode.Url, no children will receive Urls.
    // This prevents Tree.handleNodeSelected() from changing browser history on node selection.
    // This is essential for the "Set document folder" actions.
    const Url = (ParentNode.Url)
      ? `${ParentNode.Url}${
        (!ParentNode.DocumentFolderID)
          ? (
            (this.isWorkspace)
              ? "/projects/" + documentFolder.ProjectID
              : ""
          )
            + DocumentFoldersPathElement
          : ""
        }/${documentFolder.ID}`
      : undefined;
    const node = {
      ParentNode,
      NodeType: DocumentFoldersNodeType,
      Name: documentFolder.Name,
      HexColor: documentFolder.HexColor,
      Depth: documentFolder.Depth,
      ProjectID: documentFolder.ProjectID,
      HasAccessToAllDocuments: ParentNode.HasAccessToAllDocuments,
      SharedDirectly: documentFolder.AssignmentUserEmails
        && documentFolder.AssignmentUserEmails.includes(this.userEmailForAssignmentContext),
      CustomIconID: this.getCustomIconId(documentFolder.AssignmentUserEmails),
      DocumentFolderID: documentFolder.ID,
      RootId: ParentNode.RootId,
      UniqueId: `${ParentNode.UniqueId}_${documentFolder.ID}`,
      IsWorkspace: this.isWorkspace,
      HasChildren: true,
      SelectOnMount: currentDocumentFolderPathIsEndOfPath && selectOnMountIfEndOfPath,
      Open: currentDocumentFolderPathIsActive && !currentDocumentFolderPathIsEndOfPath,
      Url,
    };
    return node;
  }

  GetAndSetDocumentFolderNodesAsPromise = (props_optional, state, setChildNodesForParentFunc, 
    selectOnMountIfEndOfPath, ignoreDocumentFolderId) =>
      (ParentNode, disallowChildSelectOnLoad, isGetMore, useLocalChildren) => {

    let DocumentFolderNodesByNodeId = [...state.DocumentFolderNodesByNodeId];
    const stateParentNodeFinder = DocumentFolderNodesByNodeId.filter(n => n.UniqueId === ParentNode.UniqueId);

    if (useLocalChildren) {
      if (stateParentNodeFinder.length) {
        return Promise.resolve(stateParentNodeFinder[0].Nodes);
      } else {
        return Promise.resolve([]);
      }
    }
    
    let params = {};
    if (ParentNode.DocumentFolderID) {
      params.parentId = ParentNode.DocumentFolderID;
    }
    if (isGetMore) {
      if (stateParentNodeFinder.length) {
        params.cursor = stateParentNodeFinder[0].Cursor;
      }
    } else if (props_optional && props_optional.match && props_optional.match.params
        && props_optional.match.params.documentFolderID) {
        const documentFolderIDs = props_optional.match.params.documentFolderID.split("/");
        let includeItemIds = [];
        for (let i = 0; i < documentFolderIDs.length; i++) {
          const currentDocumentFolderId = documentFolderIDs[i];
          let nodeToCheck = ParentNode;
          let foundDocumentFolderId = false;
          while (nodeToCheck) {
            if (nodeToCheck.DocumentFolderID === currentDocumentFolderId
              || nodeToCheck.UniqueId.includes(currentDocumentFolderId)) {
              foundDocumentFolderId = true;
              break;
            }
            if (!ParentNode.Parent) {
              break;
            }
            nodeToCheck = ParentNode.Parent;
          }
          if (!foundDocumentFolderId) {
            includeItemIds.push(currentDocumentFolderId);
            break;
          }
        };
        if (includeItemIds.length) {
          params.includeItemIds_json = JSON.stringify(includeItemIds);          
        }
    }
    if (this.loadByAssignment && this.projectId && !this.isWorkspace) {
      params.projectId = this.projectId;
    }

    const uri = (this.loadByAssignment)
      ? GetUserOrganizationDocumentFoldersPathForApi(this.organizationId)
      : (this.loadByPublicApi)
        ? GetDocumentFoldersPublicPathForApi(this.organizationId, this.projectId)
        : GetDocumentFoldersPathForApi(this.organizationId, this.projectId)
    ;

    let outNodes = [];
    return API.get(uri, { params })
      .then(resp => {
        const newNodes = resp.data.DocumentFolders
          .filter(df => !ignoreDocumentFolderId
            || (df.ID !== ignoreDocumentFolderId
              && (!df.AncestorIDs || !df.AncestorIDs.includes(ignoreDocumentFolderId))
            )
          )
          .map(df => this.getNodeFromDocumentFolder(props_optional, ParentNode, df, selectOnMountIfEndOfPath));
        let Cursor = resp.data.Cursor;
        let Nodes = [];
        if (stateParentNodeFinder.length) {
          if (!isGetMore) {
            Nodes = newNodes;
          } else {
            Nodes = [...stateParentNodeFinder[0].Nodes];
            newNodes.forEach(newNode => {
              const existingNodeFinder = Nodes.filter(n => n.DocumentFolderID === newNode.DocumentFolderID);
              if (existingNodeFinder.length) {
                Nodes.splice(Nodes.indexOf(existingNodeFinder[0]), 1);
              }
              Nodes.push(newNode);
            });
          }
          stateParentNodeFinder[0].Cursor = Cursor;
          stateParentNodeFinder[0].Nodes = Nodes;
        } else {
          Nodes = newNodes;
          DocumentFolderNodesByNodeId.push({
            UniqueId: ParentNode.UniqueId,
            Nodes,
            Cursor,
          });
        }
        this.setState({DocumentFolderNodesByNodeId});
        outNodes = [...Nodes];
        
        if (setChildNodesForParentFunc) {
          setChildNodesForParentFunc(ParentNode.UniqueId, outNodes);
        }

        if (newNodes.length >= resp.data.PageSize) {
          outNodes.push(GetNodeForGetMoreItems(ParentNode));
        }
        return outNodes;
      })
      .catch(err => {
        this.handleApiError(err);
        return outNodes;
      });
  }

  GetDocumentFolderTreeNodeComponent = (props_optional, state, setChildNodesForParentFunc,
    selectOnMountIfEndOfPath, ignoreDocumentFolderId) =>
      documentFolderNode => {

    return GetTreeNodeComponent(
      props_optional, state, state => this.setState(state),
      documentFolderNode,
      () => this.expandDocumentFolderNode(documentFolderNode.ParentNode, false, false, true),
      this.GetAndSetDocumentFolderNodesAsPromise(props_optional, state, setChildNodesForParentFunc, selectOnMountIfEndOfPath, ignoreDocumentFolderId),
      this.GetDocumentFolderTreeNodeComponent(props_optional, state, setChildNodesForParentFunc, selectOnMountIfEndOfPath, ignoreDocumentFolderId),
      this.GetContextMenu,
      this.HandleSetNodeExpandFunction,
      this.handleSetNodeCollapseFunction,
      this.handleNodeSelected,
    );
  }

  handleSetDocumentFolderDialogVisibility = (visible, extraState) => {
    let stateToUpdate = {
      ...extraState,
      ShowDocumentFolderDialog: visible,
    };
    this.setState(stateToUpdate);
  }

  getDocumentFolderFromNode = node => {
    this.setState({ShowProgressIndicatorImmediately:true});
    const uri = (this.loadByAssignment)
      ? GetUserOrganizationProjectDocumentFolderPathForApi(this.organizationId, node.ProjectID, node.DocumentFolderID)
      : GetDocumentFolderPathForApi(this.organizationId, node.ProjectID, node.DocumentFolderID);
    return API.get(uri)
      .then(resp => {
        this.setState({ShowProgressIndicatorImmediately:false});
        return resp.data;
      });
  }

  HandleContextMenuAction = (e, DocumentFolderContextMenuNode, DocumentFolderDialogAction, onClose) => {
    if (e) {
      e.stopPropagation();
    }
    if (onClose) {
      onClose();
    }

    // Exit if the selected action is on the disallow list or "all"
    if (this.disallowContextMenuActionIds && this.disallowContextMenuActionIds.length
      && (
        this.disallowContextMenuActionIds[0] === "all"
        ||
        this.disallowContextMenuActionIds.filter(id => id === DocumentFolderDialogAction).length
      )
    ) {
      return;
    }

    switch (DocumentFolderContextMenuNode.NodeType) {
      case DocumentsNodeType:
      case DocumentFoldersNodeType:
        switch (DocumentFolderDialogAction) {
          case "folderCreate":
            const setDialogVisibility = ancestorAssignmentUserEmails => {
              this.handleSetDocumentFolderDialogVisibility(true, {
                DocumentFolderContextMenuNode,
                DocumentFolderDialogAction,
                DocumentFolderName: "",
                DocumentFolderHexColor: null,
                DocumentFolderDialogColorPickerColor: {},
                DocumentFolderEmailListValues: [],
                DocumentFolderAncestorAssignmentUserEmails: ancestorAssignmentUserEmails,
                DocumentFolderDialogTitle: "Create folder",
                DocumentFolderDialogConfirmLabel: "CREATE",
                DocumentFolderDialogHideSharing: this.loadByAssignment,
              });
            };
            if (DocumentFolderContextMenuNode.NodeType === DocumentFoldersNodeType
              && !DocumentFolderContextMenuNode.IsDocumentFolderRoot
              && !this.loadByAssignment) {
              this.getDocumentFolderFromNode(DocumentFolderContextMenuNode)
                .then(parentDocumentFolder => {
                  // Combine AssignmentUserEmails and AncestorAssignmentUserEmails for visual
                  let ancestorAssignmentUserEmails = parentDocumentFolder.AncestorAssignmentUserEmails || [];
                  if (Array.isArray(parentDocumentFolder.AssignmentUserEmails)) {
                    ancestorAssignmentUserEmails.push(...parentDocumentFolder.AssignmentUserEmails);
                  }
                  ancestorAssignmentUserEmails = ancestorAssignmentUserEmails.sort(this.compareEmails);
                  setDialogVisibility(ancestorAssignmentUserEmails);
                })
                .catch(this.handleApiError);
            } else {
              setDialogVisibility();
            }
            break;
          case "folderUpdate":
            this.getDocumentFolderFromNode(DocumentFolderContextMenuNode)
              .then(documentFolder => {
                this.handleSetDocumentFolderDialogVisibility(true, {
                  DocumentFolderContextMenuNode,
                  DocumentFolderDialogAction,
                  DocumentFolderName: documentFolder.Name,
                  DocumentFolderHexColor: documentFolder.HexColor,
                  DocumentFolderDialogColorPickerColor: {hex:documentFolder.HexColor},
                  DocumentFolderEmailListValues: (documentFolder.AssignmentUserEmails)
                    ? documentFolder.AssignmentUserEmails
                      .map(email => {
                        return { label: email, value: email};
                      })
                    : [],
                  DocumentFolderAncestorAssignmentUserEmails: documentFolder.AncestorAssignmentUserEmails,
                  DocumentFolderDialogTitle: "Update folder",
                  DocumentFolderDialogConfirmLabel: "UPDATE",
                  DocumentFolderDialogHideSharing: this.loadByAssignment,
                });
              })
              .catch(this.handleApiError);
            break;
          case "projectUpdate":
            this.getProjectSettings()
              .then(ProjectSettingsForDialog => {
                this.handleSetProjectSettingsDialogVisibility(true, {
                  ProjectSettingsForDialog,
                });
              })
              .catch(this.handleApiError);
            break;
          case "folderMove":
            this.handleSetMoveFolderDialogVisibility(true, {
              DocumentFolderContextMenuNode,
            });
            break;
          case "folderDelete":
            this.handleSetDeleteDocumentFolderConfirmationVisibility(true, {
              DocumentFolderContextMenuNode,
            });
            break;
          case "documentCreate":
            if (this.onBeginDocumentCreateFunc) {
              this.onBeginDocumentCreateFunc(DocumentFolderContextMenuNode);
            }
            break;
          case "fileUpload":
            if (this.onBeginFileUploadFunc) {
              this.onBeginFileUploadFunc(DocumentFolderContextMenuNode);
            }
            break;
          case "imageCapture":
            if (this.onBeginImageCaptureFunc) {
              this.onBeginImageCaptureFunc(DocumentFolderContextMenuNode);
            }
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  handleNodeSelected = node => {
    if (this.onNodeSelected) {
      this.onNodeSelected(node);
    }
  }

  handleSetDeleteDocumentFolderConfirmationVisibility = (visible, extraState) => {
    let stateToUpdate = {
      ...extraState,
      ShowDeleteDocumentFolderConfirmation: visible,
    };
    this.setState(stateToUpdate);
  }

  handleSetMoveFolderDialogVisibility = (visible, extraState) => {
    let stateToUpdate = {
      ...extraState,
      ShowMoveDocumentFolderDialog: visible,
    };
    this.setState(stateToUpdate);
  }

  handleSetProjectSettingsDialogVisibility = (visible, extraState) => {
    let stateToUpdate = {
      ...extraState,
      ShowProjectSettingsDialog: visible,
    };
    this.setState(stateToUpdate);
  }

  GetContextMenu = (node, mouseX, mouseY, onClose) => {
    if (node.NoSelect) {
      return;
    }

    if (node.NodeType !== DocumentsNodeType
      && node.NodeType !== DocumentFoldersNodeType) {
      return;
    }

    if (this.disallowContextMenuActionIds
      && this.disallowContextMenuActionIds.length
      && this.disallowContextMenuActionIds[0] === "all") {
      return;
    }

    // if (!node.HasAccessToAllDocuments) {
    //   return;
    // }

    const disallowContextMenuActionIds = this.disallowContextMenuActionIds || [];

    const documentCreateMenuItem = (this.onBeginDocumentCreateFunc
      && !disallowContextMenuActionIds.filter(id => id === "documentCreate").length)
      ? (
        <MenuItem key="documentCreate" onClick={e => this.HandleContextMenuAction(e, node, "documentCreate", onClose)}>
          <ListItemIcon>
            <DocumentCreateIcon />
          </ListItemIcon>
          Blank document
        </MenuItem>
      ) : null;
    const documentUploadMenuItem = (this.onBeginFileUploadFunc
      && !disallowContextMenuActionIds.filter(id => id === "fileUpload").length)
      ? (
        <MenuItem key="fileUpload" onClick={e => this.HandleContextMenuAction(e, node, "fileUpload", onClose)}>
          <ListItemIcon>
            <DocumentUploadIcon />
          </ListItemIcon>
          Document upload
        </MenuItem>
      ) : null;
    const imageCaptureMenuItem = (this.onBeginImageCaptureFunc && !IsMobile() && IsMicrosoftWindows()
      && !disallowContextMenuActionIds.filter(id => id === "imageCapture").length)
      ? (
        <MenuItem key="imageCapture" onClick={e => this.HandleContextMenuAction(e, node, "imageCapture", onClose)}>
          <ListItemIcon>
            <ImageCaptureIcon />
          </ListItemIcon>
          Document scan
        </MenuItem>
      ) : null;

    let captureMenuItems = [
      documentCreateMenuItem,
      documentUploadMenuItem,
      imageCaptureMenuItem,
    ];
    if (captureMenuItems.filter(cmi => cmi).length) {
      captureMenuItems.push(
        <Divider key="captureDivider" />
      );
    }

  const folderSettingsMenuItem = (this.userIsProjectAdmin && node.IsDocumentFolderRoot
      && !disallowContextMenuActionIds.filter(id => id === "projectUpdate").length
    )
      ? <MenuItem onClick={e => this.HandleContextMenuAction(e, node, "projectUpdate", onClose)}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          Folder settings
        </MenuItem>
      : null;
  const propertiesMenuItem = (node.NodeType === DocumentFoldersNodeType && !node.IsDocumentFolderRoot
      && (!this.loadByAssignment || !node.SharedDirectly) // Allow properties if accessed as a project member or when accessed by assignment and folder is a child of the shared folder
      && !disallowContextMenuActionIds.filter(id => id === "folderUpdate").length
    )
      ? <MenuItem onClick={e => this.HandleContextMenuAction(e, node, "folderUpdate", onClose)}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          {
            (this.loadByAssignment)
            ? "Properties"
            : "Properties & sharing"
          }
        </MenuItem>
      : null;
    const moveMenuItem = (node.NodeType === DocumentFoldersNodeType && !node.IsDocumentFolderRoot
      && (!this.loadByAssignment) // Allow folder move if accessed as a project member
      && !disallowContextMenuActionIds.filter(id => id === "folderMove").length
    )
      ? [
          <MenuItem key="folderMove" onClick={e => this.HandleContextMenuAction(e, node, "folderMove", onClose)}>
            <ListItemIcon>
              <FolderMoveIcon />
            </ListItemIcon>
            Move to
          </MenuItem>
      ]
      : null;
    const deleteMenuItem = (node.NodeType === DocumentFoldersNodeType && !node.IsDocumentFolderRoot
      && !(this.loadByAssignment && node.SharedDirectly) // Allow delete if accessed as a project member or when accessed by assignment and folder is a child of the shared folder
      && !disallowContextMenuActionIds.filter(id => id === "folderDelete").length
    )
      ? [
          <Divider key="deleteDivider" />,
          <MenuItem key="folderDelete" onClick={e => this.HandleContextMenuAction(e, node, "folderDelete", onClose)}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            Delete
          </MenuItem>
      ]
      : null;
    const createMenuItem = (!disallowContextMenuActionIds.filter(id => id === "folderCreate").length)
      ? <MenuItem onClick={e => this.HandleContextMenuAction(e, node, "folderCreate", onClose)}>
          <ListItemIcon>
            <NewFolderIcon />
          </ListItemIcon>
          New folder
        </MenuItem>
      : null;
    return (
      <Menu
        keepMounted
        open={mouseY !== null}
        onClose={onClose}
        anchorReference="anchorPosition"
        anchorPosition={
          mouseY !== null && mouseX !== null
            ? { top: mouseY, left: mouseX }
            : undefined
        }
      >
        {captureMenuItems}
        {folderSettingsMenuItem}
        {propertiesMenuItem}
        {createMenuItem}
        {moveMenuItem}
        {deleteMenuItem}
      </Menu>
    );
  }

  HandleSetNodeExpandFunction = (node, onNodeExpand) => {
    if (node.NodeType !== DocumentsNodeType
      && node.NodeType !== DocumentFoldersNodeType) {
      return;
    }

    const existingFinder = this.DocumentFolderNodeExpandFunctionsByUniqueId.filter(d => d.UniqueId === node.UniqueId);
    if (existingFinder.length) {
      existingFinder[0].onNodeExpand = onNodeExpand;
    } else {
      this.DocumentFolderNodeExpandFunctionsByUniqueId.push({
        UniqueId: node.UniqueId,
        onNodeExpand,
      });
    }
    // console.log(node.UniqueId, this.DocumentFolderNodeExpandFunctionsByUniqueId);
    if (this.onSetDocumentFolderNodeExpandFunctions) {
      this.onSetDocumentFolderNodeExpandFunctions(this.DocumentFolderNodeExpandFunctionsByUniqueId);
    }
  }

  handleSetNodeCollapseFunction = (node, onNodeCollapse) => {
    if (node.IsDocumentFolderRoot) {
      this.documentFolderRootCollapseFunc = onNodeCollapse;
    }
  }

  createOrUpdateDocumentFolder = (props_optional, state) => {
    if (!state.DocumentFolderName) {
      return;
    }
    this.handleSetDocumentFolderDialogVisibility(false);
    if (!state.DocumentFolderContextMenuNode) {
      return;
    }
    
    const AssignmentUserEmails = state.DocumentFolderEmailListValues.map(lv => lv.value);
    
    switch (state.DocumentFolderDialogAction) {
      case "folderCreate":
        this.createDocumentFolder(props_optional, state, AssignmentUserEmails);
        break;
      case "folderUpdate":
        this.updateDocumentFolder(props_optional, state, AssignmentUserEmails);
        break;
      default:
        break;
    }    
  }

  createDocumentFolder = (props_optional, state, AssignmentUserEmails) => {
    let parentNode = {...state.DocumentFolderContextMenuNode};
    const documentFolderToCreate = {
      ParentID: parentNode.DocumentFolderID,
      Name: state.DocumentFolderName,
      AssignmentUserEmails,
      HexColor: state.DocumentFolderHexColor,
    };
    const params = {
      documentFolderPathPrefix: GetOrganizationLink(this.organizationId,
        GetWorkspaceDocumentFoldersPath(parentNode.ProjectID || this.projectId)),
    };
    this.setState({ShowProgressIndicatorImmediately: true});
    const uri = (this.loadByAssignment)
      ? GetUserOrganizationProjectDocumentFolderPathForApi(this.organizationId, parentNode.ProjectID, parentNode.DocumentFolderID)
      : GetDocumentFoldersPathForApi(this.organizationId, parentNode.ProjectID || this.projectId);
    API.post(uri, [documentFolderToCreate],
      { params })
      .then(resp => {
        const documentFolder = resp.data[0];
        const node = this.getNodeFromDocumentFolder(props_optional, parentNode, documentFolder);
        let DocumentFolderNodesByNodeId = [...state.DocumentFolderNodesByNodeId];
        // console.log("handleDeleteDocumentFolder", parentNode.UniqueId, DocumentFolderNodesByNodeId);
        const stateParentNodeFinder = DocumentFolderNodesByNodeId.filter(n => n.UniqueId === parentNode.UniqueId);
        if (stateParentNodeFinder.length) {
          stateParentNodeFinder[0].Nodes.splice(0, 0, node);
        } else {
          parentNode = {
            UniqueId: parentNode.UniqueId,
            Nodes: [node],
          }
          DocumentFolderNodesByNodeId.push(parentNode);
        }
        if (this.onDocumentFolderNodeCreated) {
          this.onDocumentFolderNodeCreated(node); 
        }
        this.setState({
          DocumentFolderNodesByNodeId,
          ShowProgressIndicatorImmediately: false,
        });
        this.expandDocumentFolderNode(parentNode, false, false, false, true);
      })
      .catch(this.handleApiError);
  }

  getCustomIconId = assignmentUserEmails => {
    return (
        assignmentUserEmails
        && assignmentUserEmails.length
        && !this.loadByPublicApi
      )
        ? "sharedFolder"
        : undefined;
  }

  updateDocumentFolder = (props_optional, state, AssignmentUserEmails) => {
    const locationPathname_optional = (props_optional && props_optional.location)
      ? props_optional.location.pathname
      : null;
    const nodeToUpdate = {...state.DocumentFolderContextMenuNode};
    const documentFolderToUpdate = {
      Name: state.DocumentFolderName,
      AssignmentUserEmails,
      HexColor: state.DocumentFolderHexColor,
    };
    const params = {
      documentFolderPathPrefix: GetOrganizationLink(this.organizationId,
        GetWorkspaceDocumentFoldersPath(nodeToUpdate.ProjectID)),
    };
    this.setState({ShowProgressIndicatorImmediately: true});
    const uri = (this.loadByAssignment)
      ? GetUserOrganizationProjectDocumentFolderPathForApi(this.organizationId, nodeToUpdate.ProjectID, nodeToUpdate.DocumentFolderID)
      : GetDocumentFolderPathForApi(this.organizationId, nodeToUpdate.ProjectID, nodeToUpdate.DocumentFolderID);
    API.put(uri, documentFolderToUpdate, { params })
      .then(resp => {
        const documentFolder = resp.data;
        const DocumentFolderNodesByNodeId = [...state.DocumentFolderNodesByNodeId];
        const parentNode = nodeToUpdate.ParentNode;
        const stateParentNodeFinder = DocumentFolderNodesByNodeId.filter(n => n.UniqueId === parentNode.UniqueId);
        let node;
        if (stateParentNodeFinder.length) {
          const nodeFinder = stateParentNodeFinder[0].Nodes.filter(n => n.DocumentFolderID === documentFolder.ID);
          if (nodeFinder.length) {
            node = nodeFinder[0];
            node.Name = documentFolder.Name;
            node.HexColor = documentFolder.HexColor;
            node.CustomIconID = this.getCustomIconId(documentFolder.AssignmentUserEmails);
            this.setState({
              DocumentFolderNodesByNodeId,
              ShowProgressIndicatorImmediately: false,
            });
            this.expandDocumentFolderNode(stateParentNodeFinder[0], true);
            if (nodeToUpdate.Url && locationPathname_optional
              && locationPathname_optional === nodeToUpdate.Url && this.reloadItemsFunc) {
              this.reloadItemsFunc();
            }
          }
        }
        if (this.onDocumentFolderUpdated) {
          this.onDocumentFolderUpdated(documentFolder, node);
        }
      })
      .catch(this.handleApiError);
  }

  handleMoveDocumentFolder = (props_optional, state, newParentFolderId) => {
    // console.log("handleMoveDocumentFolder", documentFolderId);
    this.handleSetMoveFolderDialogVisibility(false);

    const nodeToUpdate = {...state.DocumentFolderContextMenuNode};
    // Don't allow parenting itself
    if (newParentFolderId === nodeToUpdate.DocumentFolderID) {
      return;
    }
    this.getDocumentFolderFromNode(nodeToUpdate)
      .then(documentFolderToUpdate => {
        documentFolderToUpdate.ParentID = newParentFolderId;
        const params = {
          documentFolderPathPrefix: GetOrganizationLink(documentFolderToUpdate.OrganizationID,
            GetWorkspaceDocumentFoldersPath(documentFolderToUpdate.ProjectID)),
        };

        this.setState({ShowProgressIndicatorImmediately: true});
        const sleep = delay => new Promise(resolve => setTimeout(resolve, delay));
        const uri = /*(this.loadByAssignment)
          ? GetUserOrganizationProjectDocumentFolderPathForApi(this.organizationId, nodeToUpdate.ProjectID, nodeToUpdate.DocumentFolderID)
          : */GetDocumentFolderPathForApi(documentFolderToUpdate.OrganizationID,
                documentFolderToUpdate.ProjectID, documentFolderToUpdate.ID);
        API.put(uri, documentFolderToUpdate, { params })
          .then(async resp => {
            const documentFolder = resp.data;

            // Collapse document root in preparation for reload
            await sleep(1000);
            if (this.documentFolderRootCollapseFunc) {
              this.documentFolderRootCollapseFunc();
            }

            // Move history to the folder i.e. reload
            await sleep(3000);
            if (props_optional && props_optional.history) {
              HandleRouteToDocumentFolder(props_optional, documentFolder, true);
            }
            this.setState({ShowProgressIndicatorImmediately: false});
          })
          .catch(this.handleApiError);
      })
      .catch(this.handleApiError);
  }

  handleDeleteDocumentFolder = (props_optional, state) => {
    const history = (props_optional && props_optional.history)
      ? props_optional.propsHistory
      : null;
    this.handleSetDeleteDocumentFolderConfirmationVisibility(false, {
      ShowProgressIndicatorImmediately: true,
    });
    const nodeToDelete = {...state.DocumentFolderContextMenuNode};
    const uri = (this.loadByAssignment)
      ? GetUserOrganizationProjectDocumentFolderPathForApi(this.organizationId,
        nodeToDelete.ProjectID, nodeToDelete.DocumentFolderID)
      : GetDocumentFoldersPathForApi(this.organizationId, nodeToDelete.ProjectID);
    API.delete(uri, { data: { IDs: [nodeToDelete.DocumentFolderID] }})
      .then(() => {
        let stateToUpdate = {
          ShowProgressIndicatorImmediately: false,
        };
        const DocumentFolderNodesByNodeId = [...state.DocumentFolderNodesByNodeId];
        // console.log("handleDeleteDocumentFolder", nodeToDelete.ParentNode.UniqueId, DocumentFolderNodesByNodeId);
        const stateParentNodeFinder = DocumentFolderNodesByNodeId
          .filter(n => n.UniqueId === nodeToDelete.ParentNode.UniqueId);
        if (stateParentNodeFinder.length) {
          stateParentNodeFinder[0].Nodes = stateParentNodeFinder[0].Nodes
            .filter(n => n.UniqueId !== nodeToDelete.UniqueId);
          stateToUpdate = {
            ...stateToUpdate,
            DocumentFolderNodesByNodeId,
          };
          this.expandDocumentFolderNode(stateParentNodeFinder[0]);
          if (nodeToDelete.ParentNode && nodeToDelete.ParentNode.Url && history) {
            history.replace(nodeToDelete.ParentNode.Url);
          }
        }
        this.setState(stateToUpdate);
      })
      .catch(this.handleApiError);
  }

  getProjectSettings = () => {
    this.setState({ShowProgressIndicatorImmediately:true});
    return API.get(GetProjectSettingsPathForApi(this.organizationId, this.projectId))
      .then(resp => {
        this.setState({ShowProgressIndicatorImmediately:false});
        return resp.data;
      });
  }

  handleUpdateProjectSetting = (state, projectSettingId, name, value) => {
    const ProjectSettingsForDialog = {...state.ProjectSettingsForDialog};
    ProjectSettingsForDialog[projectSettingId] = value;
    this.setState({ProjectSettingsForDialog});

    if (this.onSetRootNodePropertiesFromProjectSetting) {
      this.onSetRootNodePropertiesFromProjectSetting(this.projectId, projectSettingId, value);
    }

    this.handleUpdateProjectSettingAtServer(projectSettingId, name, value);
  }

  handleUpdateProjectSettingAtServer = debounce((projectSettingId, name, value) => {
    API.put(GetProjectSettingPathForApi(this.organizationId, this.projectId, projectSettingId), 
      { [name]: value })
      .then(resp => {
        
      })
      .catch(this.handleApiError);
  }, 500);

  expandDocumentFolderNode = (node, skipAppendChildren, disallowChildSelectOnLoad, isGetMore, useLocalChildren) => {
    // console.log("expandDocumentFolderNode", node.UniqueId, 
    //   this.DocumentFolderNodeExpandFunctionsByUniqueId.map(f => f.UniqueId).join("; "));
    const nodeExpandFuncFinder = 
      this.DocumentFolderNodeExpandFunctionsByUniqueId.filter(d => d.UniqueId === node.UniqueId);
    if (nodeExpandFuncFinder.length) {
      nodeExpandFuncFinder[0].onNodeExpand(skipAppendChildren, disallowChildSelectOnLoad, isGetMore, useLocalChildren);
    }
  }

  handleGetAddressBookItemsPromise = debounce((getState, filter) => {
    return GetAddressBookItemsPromise(this.organizationId, this.projectId, true, true, false, false, filter)
      .then(items => {
        const addressBookItems = items.map(abi => { 
          if (abi.ProjectMemberID) {
            return ({
              value: abi.EmailLower,
              label: GetUserValue(abi.EmailLower,
                (abi.Name)
                  ? `${abi.Name} (member ${abi.EmailLower})`
                  : `${abi.EmailLower} (member)`,
                "", false, undefined, {}, {}, true,
                ),
            });
          } else {
            return ({
              value: abi.EmailLower,
              label: GetUserValue(abi.EmailLower,
                (abi.Name)
                  ? `${abi.Name} (${abi.EmailLower})`
                  : abi.EmailLower,
                "", false, undefined, {}, {}, true,
                ),
            });
          }
        });

        let DocumentFolderEmailListValues = [...getState()["DocumentFolderEmailListValues"]];
        DocumentFolderEmailListValues.forEach(lv => {
          // Attempt to update list values where label and value are both the email.
          if (lv.value === lv.label) {
            const addressBookItemFinder = addressBookItems.filter(abi => abi.value === lv.value);
            if (addressBookItemFinder.length) {
              lv.label = addressBookItemFinder[0].label;
            }
          }
        });
        this.setState({DocumentFolderEmailListValues});
        return addressBookItems;
      })
      .catch(this.handleApiError);
  }, 250);

  handleAddDocumentFolderEmailListValues = state => emailOrListValues => {
    let DocumentFolderEmailListValues = [];
    if (Array.isArray(emailOrListValues)) {
      DocumentFolderEmailListValues = emailOrListValues;
    } else if (typeof emailOrListValues === "string") {
      DocumentFolderEmailListValues = [...state.DocumentFolderEmailListValues]
        .concat({ value: emailOrListValues, label: emailOrListValues });
    }
    if (this.validateSubfolderEmailsToAdd(DocumentFolderEmailListValues)) {
      this.setState({ DocumentFolderEmailListValues });
    }
  }

  validateSubfolderEmailsToAdd = emailsToAdd => {
    for (let i = 0; i < emailsToAdd.length; i++) {
      let email = emailsToAdd[i].value;
      if (!ValidateEmail(email)) {
        this.handleApiError(`${email} is not a valid e-mail address.`);
        return false;
      }
    }
    return true;
  }
}