import React, { Component } from 'react';

import Button from '@material-ui/core/Button';

import ItemCollectionBase from '../Components/ItemCollectionBase';

import Collection from '../Model/Collection';
import { SimpleSearchResults_Collection } from '../Model/SimpleSearchResults';
import { GlobalContext } from '../Context/Global.context';
// import { IsMobile } from '../Util/MobileDetector';

import {
  GetSearchPath,
} from '../Util/PathHelper';

class SearchResults extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.state = {
      CollectionUpdateID: null,
      FullTextQuery: null,
    }

    this.Collection = new Collection(this.props, state => this.setState(state), this.handleApiError);
  }

  setFullTextQuery = () => {
    if (this.props.match.params.fullText) {
      this.setState({FullTextQuery: this.props.match.params.fullText });
    }
  }

  setCollectionWhenReady = () => {
    if (!this.context.CompletedGET.UserPreferences 
      || !this.context.CompletedGET.ProjectMembershipPackages) {
      setTimeout(() => this.setCollectionWhenReady(), 250);
      return;
    }
    this.Collection = new SimpleSearchResults_Collection(this.props, this.context, state => this.setState(state),
      this.handleApiError);
    // This ensures ItemCollectionBase always sees the new collection
    this.setState({CollectionUpdateID: new Date()});
  }

  componentDidMount() {
    this.setFullTextQuery();
    this.setCollectionWhenReady();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setFullTextQuery();
    }
  }

  handleApiError = err => {
    this.setState({ ApiError:err });
    if (err) {
      setTimeout(() => this.handleApiError(null), 1);
    }
  }

  render() {
    const {
      FullTextQuery,
      ApiError,
    } = this.state;
    const {
      UserPreferences,
    } = this.context;

    return (
      <ItemCollectionBase
        {...this.props}

        organizationId={UserPreferences.ActiveOrganizationID}
        pageTitle={
          (FullTextQuery)
            ? "Results for '" + FullTextQuery + "'"
            : "Search results"
        }
        showOrgAsTitleOnDesktop
        contentUri={this.Collection.ContentUri}
        contentUriParams={{
          contentTypesJson: JSON.stringify([
            "Document",
            "Task",
            "FormTemplate",
            "AssetItem",
          ]),
          pageSizePerSearchContentType: 10,
          includeHighlight: true,
        }}
        fullTextFilter={FullTextQuery || undefined}
        collectionName={this.Collection.CollectionName}
        itemsName={this.Collection.ItemsName}
        itemName={this.Collection.ItemName}
        defaultViewType={this.Collection.DefaultViewType}
        groupItemsByContentType
        noSorting
        preContent={(UserPreferences.ActiveOrganizationMember) ? (
          <Button variant="contained" onClick={() => this.props.history.push(GetSearchPath())}>
            ADVANCED SEARCH
          </Button>
        ) : undefined}
        
        hideToolHeader
        // toolHeaderLeftContent={(!IsMobile()) ? (
        //   <Button
        //     size="small"
        //     style={{
        //       marginLeft:70,
        //     }}
        //     onClick={() => this.props.history.push(GetSearchPath())}
        //   >
        //     Advanced Search
        //   </Button>
        // ) : null}

        onGetCollectionFieldsPromise={this.Collection.HandleGetCollectionFieldsPromise}
        onGetHeadCells={this.Collection.HandleGetHeadCells}
        onGetCardGridItems={this.Collection.HandleGetCardGridItems}
        onGetTableRows={this.Collection.HandleGetTableRows}

        // allowSelect
        hideSensitiveFields={this.Collection.HideSensitiveFields}
        hideFilters
        loadItemsImmediately
        apiError={ApiError}
      />
    );
  }
}

export default SearchResults;
