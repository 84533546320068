import React, { Component } from 'react';

import UiCore from '../Components/UiCore';
import { GlobalContext } from '../Context/Global.context';
import ProgressIndicator from '../Components/ProgressIndicator';

class Root extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      ShowProgressIndicatorImmediately: true,
    }
  }

  routeWhenReady() {
    // Check for context API error and pass along.
    // This is important as it will catch 401s, which when passed to UiCore will redirect to /login.
    if (this.context && this.context.ApiError) {
      this.setState({ApiError:this.context.ApiError});
      return;
    }
    if (this.context
      && this.context.CompletedGET.UserOrganizations
      && this.context.CompletedGET.UserPreferences) {
      
      if (this.context.UserOrganizations.length) {
        this.props.history.replace("/dashboard");
      } else {
        this.setState({
          ApiError: "There was a problem accessing an organization. This is valid if you are not a member of any. If this is unexpected, try signing in again.",
          ShowProgressIndicatorImmediately: false,
        }); 
      }
    } else {
      setTimeout(() => this.routeWhenReady(), 250);
    }
  }

  componentDidMount() {
    this.routeWhenReady();
    setTimeout(() => this.setState({ShowProgressIndicatorImmediately:false}), 10000);
  }

  handleApiError = err => {
    this.setState({
      ApiError: err,
      ShowProgressIndicatorImmediately: false,
    });
  }

  render() {
    const {
      ApiError,
      ShowProgressIndicatorImmediately,
    } = this.state;

    let uiCore = (
      <UiCore
        hideSubscriptionExpirationAlert
        hideProjectName
        forcedApiError={ApiError}
      />
    );
    
    let progressIndicator = (ShowProgressIndicatorImmediately)
      ? (
        <ProgressIndicator showProgressIndicatorImmediately={ShowProgressIndicatorImmediately} />
      )
      : null;

    let content = (ApiError)
      ? uiCore 
      : (
        <div style={{
          backgroundColor:"#303a4e",
          width:"100%",
          height:"100%",
        }}>
          {progressIndicator}
        </div>
      );

    return content;
  }
}

export default Root;
