import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Card from './Card';

import {
  GetDateValue,
  GetBoolValue,
} from '../Util/Properties';
import { 
  GetCardMetadataFromPreviewMetadata,
} from '../Util/Fields';
import {
  GetTagsPreviewFromTagsObject,
} from '../Util/Tags';
import {
  GetAssetItemProgress,
} from '../Util/AssetItem';

class AssetItemCard extends Component {
  render() {
    const {
      AssetItem,
      onCardAction,
      style,
      noSelect,
      onSelect,
      selected,
      isSearchResults,
      showContentType,
    } = this.props; 

    let metadata = [
      ["Created On", GetDateValue(AssetItem.CreatedOn)],
      ["Complete", GetBoolValue(AssetItem.IsComplete)],
      ["Progress", GetAssetItemProgress(AssetItem)],
      ["Tags", GetTagsPreviewFromTagsObject(AssetItem)],
    ];
    metadata.push(...GetCardMetadataFromPreviewMetadata(AssetItem.PreviewMetadata));

    const name = (AssetItem.Name)
      ? AssetItem.Name
      : GetDateValue(AssetItem.CreatedOn);

    return (
      <Card
        style={style}
        noSelect={noSelect}
        onSelect={onSelect}
        selected={selected}
        onCardAction={onCardAction}
        name={name}
        height={256}
        isComplete={AssetItem.IsComplete}
        isSearchResults={isSearchResults}
        showContentType={showContentType}
        metadata={metadata}
        thumbnailUrl={AssetItem.ThumbnailUrl}
        contentItem={AssetItem}
      />
    );
  }
}

AssetItemCard.propTypes = {
  AssetItem: PropTypes.object.isRequired,
  onCardAction: PropTypes.func,
};

export default AssetItemCard;