import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
// import Divider from '@material-ui/core/Divider';

import DashboardCard from '../DashboardCard';

import Icon from '@material-ui/icons/LocalLibrary';
import IconColor from '@material-ui/core/colors/brown';

import MultiUseDialog from '../MultiUseDialog';

import KnowledgeBaseIcon from '@material-ui/icons/Book';
// import DemoIcon from '@material-ui/icons/Subject';
import {
  SupportIcon,
  YouTubeIcon,
  DiscordIcon,
  QuoraIcon,
} from '../../Util/Icons';

import {
  DiscordInviteUrl,
  YouTubeChannelUrl,
  KnowledgeBaseUrl,
  QuoraAnswersUrl,
} from '../../Util/PathHelper';
import API, {
  GetOrganizationRequestsByMailSupportPathForApi,
} from '../../Util/api';

import { IsMobile } from '../../Util/MobileDetector';
import { GlobalContext } from '../../Context/Global.context';

import lightGreen from '@material-ui/core/colors/lightGreen';
import orange from '@material-ui/core/colors/orange';
// import teal from '@material-ui/core/colors/teal';

const styles = theme => ({
  list: {
    padding: 0,
    height: "100%",
    overflow: "auto",
    overflowX: "hidden",
    whiteSpace: "nowrap",
  },
  listItemIcon: {
    minWidth: 42,
  },
});

class ResourcesWidget extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.InitialRequestDialogState = {
      RequestMessage: "",
    };

    this.state = {
      ...this.InitialRequestDialogState,
      OrgNameForDialog: "",
      UserNameForDialog: "",
      RequestPhone: "",
      ShowSupportRequestDialog: false,
    }
  }

  handleRouteTo = url => {
    if (url) {
      window.open(url, "n1_resourceContent");
    }
  }

  handleSetShowSupportRequestDialog = ShowSupportRequestDialog => {
    this.setState({ShowSupportRequestDialog});
  }

  handleSendSupportRequest = () => {
    if (!this.state.RequestPhone) {
      return this.props.onApiError("Please provide a phone number.");
    }
    if (!this.state.RequestMessage) {
      return this.props.onApiError("Please provide a message with your concerns.");
    }
    this.handleSetShowSupportRequestDialog(false);

    API.post(GetOrganizationRequestsByMailSupportPathForApi(
      this.context.ActiveOrganizationPermissions.OrganizationID),
    {
      OrganizationName: this.state.OrgNameForDialog,
      UserName: this.state.UserNameForDialog,
      Phone: this.state.RequestPhone,
      Message: this.state.RequestMessage,
    })
      .then(resp => {
        this.props.onAlert({
          Title: "Request sent",
          BodyText:"Someone will contact you shortly.",
        });
        this.setState({
          ...this.InitialRequestDialogState,
        });
      })
      .catch(this.props.onApiError);
  }

  setPropsForDialogWhenReady = () => {
    if (this.state.OrgNameForDialog && this.state.UserNameForDialog) {
      return;
    }
    if (!this.context.CompletedGET.ActiveOrganizationPermissions
      || !this.context.CompletedGET.UserPreferences) {
      return setTimeout(() => { this.setPropsForDialogWhenReady() }, 250);
    }
    this.setState({
      OrgNameForDialog: this.context.ActiveOrganizationPermissions.OrganizationName,
      UserNameForDialog: this.context.UserPreferences.UserName,
    });
  }

  componentDidMount() {
    this.setPropsForDialogWhenReady();
  }

  render() {
    const {
      OrgNameForDialog,
      UserNameForDialog,
      RequestPhone,
      RequestMessage,
      ShowSupportRequestDialog,
    } = this.state;
    const { 
      classes,
      theme,
      DashboardWidget,
      ...passThroughProps
    } = this.props;

    const resourcesList = (
      <List dense className={classes.list}>
        <ListItem button onClick={() => this.handleRouteTo(YouTubeChannelUrl)}>
          <ListItemIcon className={classes.listItemIcon}>
            <YouTubeIcon />
          </ListItemIcon>
          <ListItemText primary="YouTube Channel" />
        </ListItem>

        <ListItem button onClick={() => this.handleRouteTo(DiscordInviteUrl)}>
          <ListItemIcon className={classes.listItemIcon}>
            <DiscordIcon />
          </ListItemIcon>
          <ListItemText primary="Discord Chat Server" />
        </ListItem>

        <ListItem button onClick={() => this.handleRouteTo(QuoraAnswersUrl)}>
          <ListItemIcon className={classes.listItemIcon}>
            <QuoraIcon />
          </ListItemIcon>
          <ListItemText primary="Quora Answers" />
        </ListItem>

        <ListItem button onClick={() => this.handleRouteTo(KnowledgeBaseUrl)}>
          <ListItemIcon className={classes.listItemIcon}>
            <KnowledgeBaseIcon style={{color:lightGreen[500]}} />
          </ListItemIcon>
          <ListItemText primary="Knowledge Base" />
        </ListItem>

        {/*<ListItem button onClick={() => this.handleRouteTo("")}>
          <ListItemIcon className={classes.listItemIcon}>
            <DemoIcon style={{color:teal[500]}} />
          </ListItemIcon>
          <ListItemText primary="Demo Content" />
        </ListItem>*/}

        <ListItem button onClick={() => this.handleSetShowSupportRequestDialog(true)}>
          <ListItemIcon className={classes.listItemIcon}>
            <SupportIcon style={{color:orange[500]}} />
          </ListItemIcon>
          <ListItemText primary="Request Support" />
        </ListItem>
      </List>
    );

    const supportRequestDialogDetails = {
      FullWidth: !IsMobile(),
      FullScreen: IsMobile(),
      Open:ShowSupportRequestDialog || false,
      // ShowProgressIndicatorImmediately:ShowDialogProgressIndicator,
      Title:"Request support",
      BodyContent:(
        <Grid container spacing={2}>
          <Grid item xs={12} style={{marginBottom:theme.spacing(2)}}>
            Provide us with some information for your support needs. Include any existing ticket numbers in the message.
          </Grid>
          <Grid item key="sOrg" xs={12}>
            <TextField
              label="Organization name"
              variant="outlined"
              value={OrgNameForDialog}
              onChange={e => this.setState({OrgNameForDialog:e.target.value})}
              fullWidth
            />
          </Grid>
          <Grid item key="sName" xs={12} sm={6}>
            <TextField
              label="Your name"
              variant="outlined"
              value={UserNameForDialog}
              onChange={e => this.setState({UserNameForDialog:e.target.value})}
              fullWidth
            />
          </Grid>
          <Grid item key="sPhone" xs={12} sm={6}>
            <TextField
              label="Phone"
              variant="outlined"
              value={RequestPhone}
              onChange={e => this.setState({RequestPhone:e.target.value})}
              fullWidth
              autoFocus
            />
          </Grid>
          <Grid item key="sMessage" xs={12}>
            <TextField
              label="Message"
              variant="outlined"
              value={RequestMessage}
              onChange={e => this.setState({RequestMessage:e.target.value})}
              fullWidth
              multiline
              rows={6}
            />
          </Grid>
        </Grid>
      ),
      DialogWidth: "sm",
      CancelCallback:() => this.handleSetShowSupportRequestDialog(false),
      CloseCallback:() => this.handleSetShowSupportRequestDialog(false),
      IsConfirmation: true,
      ConfirmLabel:"SEND REQUEST",
      ConfirmCallback:this.handleSendSupportRequest,
    };

    return (
      <DashboardCard
        title="Resources"
        titleIcon={<Icon style={{color: IconColor[300]}} />}
        noFadeout
        xs={12}
        sm={6}
        md={3}
        lg={2}
        xl={2}
        {...passThroughProps}
        DashboardWidget={DashboardWidget}
      >
        {resourcesList}
        <MultiUseDialog Details={supportRequestDialogDetails} />
      </DashboardCard>  
    );
  }
}

ResourcesWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string,
};

export default withStyles(styles, {withTheme: true})(ResourcesWidget);