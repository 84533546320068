import { GetFieldForFilterAndSort } from '../Util/Field';

const SearchResultsCollectionFields = [
	GetFieldForFilterAndSort(
		"_score",
		"Score",
		"FieldType_Number",
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[ProjectName].keyword",
		"Project",
		"FieldType_Text",
		"Meta_text_kw256lc[ProjectName]",
		null,
    "projectList",
    true,
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[AssignmentUserEmail].keyword",
		"Assigned to/Shared with",
		"FieldType_Text",
		null,
    [
      {
          value: "equals",
          label: "equals",
      },
      {
        value: "notEquals",
        label: "does not equal",
      },
    ],
    "addressBookItemsList",
    true,
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[CompletedByUserEmail].keyword",
		"Completed By",
		"FieldType_Text",
		"Meta_text_kw256lc[CompletedByUserEmail]",
    [
      {
          value: "equals",
          label: "equals",
      },
      {
        value: "notEquals",
        label: "does not equal",
      },
    ],
    "addressBookItemsList",
    true,
	),
	GetFieldForFilterAndSort(
		"Meta_date_str256[CompletedOn].string",
		"Completed On",
		"FieldType_Date",
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[ContentType].keyword",
		"Content Type",
		"FieldType_Text",
		"Meta_text_kw256lc[ContentType]",
    [
      {
          value: "equals",
          label: "equals",
      },
    ],
    "contentTypes",
    false,
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[CreatedByUserEmail].keyword",
		"Created By",
		"FieldType_Text",
		"Meta_text_kw256lc[CreatedByUserEmail]",
    [
      {
          value: "equals",
          label: "equals",
      },
      {
        value: "notEquals",
        label: "does not equal",
      },
    ],
    "addressBookItemsList",
    true,
	),
	GetFieldForFilterAndSort(
		"Meta_date_str256[CreatedOn].string",
		"Created On",
		"FieldType_Date",
	),
	GetFieldForFilterAndSort(
		"Meta_date_str256[DueOn].string",
		"Due On",
		"FieldType_Date",
	),
	GetFieldForFilterAndSort(
		"Meta_long[Duration]",
		"Duration",
		"FieldType_Number",
		null,
		null,
		null,
		false,
		true,
	),
	GetFieldForFilterAndSort(
		"Meta_long[FileSize]",
		"File Size",
		"FieldType_Number",
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[Name].keyword",
		"Name",
		"FieldType_Text",
		"Meta_text_kw256lc[Name]",
	),
	GetFieldForFilterAndSort(
		"Meta_int[PageCount]",
		"Page Count",
		"FieldType_Number",
	),
	GetFieldForFilterAndSort(
		"Meta_int[Priority]",
		"Priority",
		"FieldType_Text", // We use a text field internally, but use FieldType_Number for API queries
		null,
		[
			{
			    value: "equals",
			    label: "equals",
			},
		],
    	"taskPriorityTypes",
    	false,
    	false,
    	"FieldType_Number", // We use a text field internally, but use FieldType_Number for API queries
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[MilestoneName].keyword",
		"Milestone",
		"FieldType_Text",
		"Meta_text_kw256lc[MilestoneName]",
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[ProcessName].keyword",
		"Process",
		"FieldType_Text",
		"Meta_text_kw256lc[ProcessName]",
    // null,
    // "processList", Before enabling this we need to figure out how to query by organization only, which may not be feasible
    // true,
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[ProcessElementName].keyword",
		"Process Action",
		"FieldType_Text",
		"Meta_text_kw256lc[ProcessElementName]",
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw50lc[Tag].keyword",
		"Tags",
		"FieldType_Text",
		"Meta_text_kw50lc[Tag]",
		null,
  	"tags",
  	true,
  	false,
  	null,
  	null,
  	false,
  	null,
  	true,
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[StateName].keyword",
		"Task State",
		"FieldType_Text",
		"Meta_text_kw256lc[StateName]",
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[UserName].keyword",
		"User Name",
		"FieldType_Text",
		"Meta_text_kw256lc[UserName]",
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[UserEmail].keyword",
		"User Email",
		"FieldType_Text",
		"Meta_text_kw256lc[UserEmail]",
	),
];

export default SearchResultsCollectionFields;