import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { GlobalContext } from '../Context/Global.context';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Collection from '../Model/Collection';
import { Notifications_Collection } from '../Model/Notifications';
import { Tasks_Collection } from '../Model/Tasks';
import { Approvals_Collection } from '../Model/Approvals';
import { NeedSignature_Collection } from '../Model/NeedSignature';
import { Documents_Collection } from '../Model/Documents';
// import { DocumentSubscriptions_Collection } from '../Model/DocumentSubscriptions';
// import { Folders_Collection } from '../Model/Folders';

import DeviceCaptureDialog from '../Components/DeviceCaptureDialog';

import {
  DocumentFoldersNodeType,
  WorkspaceProjectNodeType,
  NotificationsNodeType,

  GetNotificationsNode,
  GetTasksNode,
  GetApprovalsNode,
  GetNeedSignatureNode,
  // GetSubscriptionsNode,
  // GetFormsNode,
  // GetFolderHierarchies,
  GetDocumentsNode,
  GetDocumentFoldersNode,

  GetUserOrganizationAssignmentTypeFromNodeType,
} from '../Util/Nodes';

import ItemCollectionBase from '../Components/ItemCollectionBase';
import {
  GetTreeNodeComponent,
  GetNodeForGetMoreItems,
  TreeComponentDidUpdate,
  SetSelectedNodeByCurrentLocationRecursive,
} from '../Util/Tree';
import {
  GetAndSetProjectsPackageForTreeNodesAsPromise,
} from '../Util/Projects';
import {
  GetIconById,
} from '../Util/Icons';
import DocumentFolders from '../Util/DocumentFolders';
import CaptureCore from '../Components/CaptureCore';

import { IsMobile } from '../Util/MobileDetector';
import { IsMicrosoftWindows } from '../Util/MicrosoftWindowsDetector';

import red from '@material-ui/core/colors/red';

import API, {
  GetUserOrganizationProjectsPathForApi,
  GetUserOrganizationDocumentFoldersPathForApi,
} from '../Util/api';
import {
  HandlePreCaptureFieldGathering,
  GetPreCaptureFieldGatheringContent,
} from '../Util/PreCaptureFieldGathering';

const styles = theme => ({
  hasUnreadNotificationIndicator: {
    backgroundColor:red[500],
    borderRadius:4,
    width:8,
    height:8,
    position:"absolute",
    top:14,
    left:66,
    zIndex:1,
  },
  leftPaneTreeContainer: {
    scrollBehavior: "smooth",
  }
});

class Workspace extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.state = {
      RootNodes: [],
      ProjectsPackage: null,
      SelectedNode: null,
      ForcePrependItems: [],
      ForceRefresh: false,
      CollectionUpdateID: null,
      FieldIDsAndValuesForCapture: null,
      ShowDeviceCaptureDialog: false,
      ImageCaptureDocumentFolderID: null,
      ImageCaptureDocumentFolderName: null,
      FabMenu_MouseY: null,
      FabMenu_MouseX: null,
      ItemTitle: "",
    }

    this.DocumentFolders = null;
    this.Collection = new Collection(this.props, state => this.setState(state), this.handleApiError);
    this.hasDocumentFolders = null;
    this.NodeExpandFunctionsByUniqueId = [];
    this.ChildNodesByParentNodeUniqueId = [];
    this.BeginFileUploadFunc = null;
    this.DocumentFolderNodeExpandFunctions = [];
    this.PostFieldGatheringCaptureFunc = null;
    this.AdditionalBodyTextForFieldGatheringDialog = null;
  }

  initiateDocumentFolders = () => {
    // Delay until we have UserPreferences
    if (!this.context || !this.context.CompletedGET.UserPreferences) {
      setTimeout(() => this.initiateDocumentFolders(), 250);
      return;
    }
    this.DocumentFolders = new DocumentFolders(
      this.state,
      state => this.setState(state),
      this.handleApiError,
      this.context.UserPreferences.ActiveOrganizationID,
      this.props.match.params.projectID,
      true, true, this.context.UserPreferences.UserEmail, false,
      this.handleBeginDocumentUpload,
      (IsMicrosoftWindows() && !IsMobile())
        ? this.handleBeginImageCapture
        : null,
      null,
      null,
      this.DocumentFolderNodeExpandFunctions,
      functions => this.DocumentFolderNodeExpandFunctions = functions,
      this.handleDocumentFolderNodeCreated,
    );
  }

  componentDidMount() {
    this.initiateDocumentFolders();
    this.setCollection();
    this.setRootNodes();
  }

  componentDidUpdate(prevProps) {
    const allowPathCheck = 
      this.props.match.params.collectionID !== prevProps.match.params.collectionID
      || this.props.match.params.projectID !== prevProps.match.params.projectID
      || this.Collection.updateProps(this.props);
    if (allowPathCheck) {
      TreeComponentDidUpdate(prevProps.location, this.props.location, state => this.setState(state));
      if (this.props.match.params.collectionID !== prevProps.match.params.collectionID) {
        this.setCollection();
        this.setRootNodes();
      } else if (this.props.match.params.projectID !== prevProps.match.params.projectID) {
        this.initiateDocumentFolders();
        this.setCollection();
        this.setRootNodes();
      } else if (this.props.match.params.documentFolderID !== prevProps.match.params.documentFolderID) {
        this.setCollection();
        this.ensureSelectedNode();
      }
    } else {
      this.ensureSelectedNode();
    }
  }

  setChildNodesForParent = (parentNodeUniqueId, ChildNodes) => {
    this.ChildNodesByParentNodeUniqueId = this.ChildNodesByParentNodeUniqueId.filter(n => n.UniqueId !== parentNodeUniqueId);
    this.ChildNodesByParentNodeUniqueId.push({
      UniqueId: parentNodeUniqueId,
      ChildNodes,
    });
  }

  setCollection = () => {
    // This is important as it will catch 401s, which when passed to UiCore will redirect to /login.
    if (this.context && this.context.ApiError) {
      this.setState({ApiError:this.context.ApiError});
      return;
    }
    // Delay until we have some things for dependents
    if (!this.context
      || !this.context.CompletedGET.UserPreferences
      || !this.context.CompletedGET.ProjectMembershipPackages) {
      setTimeout(() => this.setCollection(), 250);
      return;
    }
    const {
      ActiveOrganizationID,
      UserEmail,
    } = this.context.UserPreferences;
    const {
      collectionID,
      projectID,
      documentFolderID,
    } = this.props.match.params;
    this.setState({
      TotalItems:0,
    });

    let documentFolderId;
    if (documentFolderID) {
      const ids = documentFolderID.split("/");
      documentFolderId = ids[ids.length - 1];
    }

    let c;
    if (collectionID) {
      switch (collectionID) {
        case "notifications":
          c = new Notifications_Collection(this.props, this.context, state => this.setState(state), this.handleApiError,
            ActiveOrganizationID);
          break;
        case "tasks":
          c = new Tasks_Collection(this.props, name => this.state[name], state => this.setState(state), this.handleApiError, this.handleAlert, 
            true, false, ActiveOrganizationID, projectID, UserEmail, false, false, null,
            this.handleItemTitleChange, false);
          break;
        case "approvals":
          c = new Approvals_Collection(this.props, state => this.setState(state), this.handleApiError, this.handleAlert,
            true, false, ActiveOrganizationID, projectID, UserEmail);
          break;
        case "signatures":
          c = new NeedSignature_Collection(this.props, state => this.setState(state), this.handleApiError,
            true, false, ActiveOrganizationID, projectID, this.context);
          break;
        case "documents":
          c = new Documents_Collection(this.props, state => this.setState(state), this.handleApiError,
            true, false, true, false, ActiveOrganizationID, projectID, documentFolderId, null,
            this.BeginFileUploadFunc,
            (IsMicrosoftWindows() && !IsMobile())
              ? this.handleBeginImageCapture
              : null,
            null, false,
          );
          break;
        default:
          break;
      }
    }
    if (c) {
      this.Collection = c;
    }
    // This ensures ItemCollectionBase always sees the new collection
    this.setState({CollectionUpdateID: new Date()});
  }

  handleItemTitleChange = ItemTitle => {
    this.setState({ItemTitle});
  }

  ensureSelectedNode = () => {
    if (!this.state.SelectedNode || this.state.SelectedNode.Url !== this.props.location.pathname) {
      const nodeExpandFuncs = [
        ...this.NodeExpandFunctionsByUniqueId,
        ...this.DocumentFolderNodeExpandFunctions,
      ];
      SetSelectedNodeByCurrentLocationRecursive(this.state.RootNodes, this.ChildNodesByParentNodeUniqueId,
        this.props.location, state => this.setState(state), nodeExpandFuncs);
    }
  }

  handleApiError = ApiError => {
    this.setState({ ApiError });
    if (ApiError) {
      setTimeout(() => this.handleApiError(null), 1);
    }
  }

  handleAlert = Alert => {
    this.setState({ Alert });
  }

  setRootNodes = async () => {
    // Delay until we have UserPreferences
    if (!this.context || !this.context.CompletedGET.UserPreferences) {
      setTimeout(() => this.setRootNodes(), 250);
      return;
    }
    const baseRootNodes = [
      {...GetNotificationsNode()},
      {...GetTasksNode()},
      {...GetApprovalsNode()},
      {...GetNeedSignatureNode()},
    ];
    if (!IsMobile()) {
      if (this.hasDocumentFolders === null) {
        this.hasDocumentFolders = await 
          API.get(GetUserOrganizationDocumentFoldersPathForApi(this.context.UserPreferences.ActiveOrganizationID))
            .then(resp => resp.data.DocumentFolders.length > 0)
            .catch(this.handleApiError);
      }
      if (this.hasDocumentFolders) {
        baseRootNodes.push({
          ...GetDocumentFoldersNode(),
          UniqueId: GetDocumentsNode().UniqueId,
          PathElement: "/documents", // Override to match Projects screen
          NoSelect: true,
        });
      }
    }
    
    const selectedCollectionId = this.props.match.params.collectionID;
    const optionalSelectedProjectId = this.props.match.params.projectID;
    const optionalSelectedCollectionItemId = this.props.match.params.collectionItemID;
    let RootNodes = [];
    baseRootNodes.forEach((base, ItemIndex) => {
      const UniqueId = `${base.UniqueId}_${ItemIndex.toString()}`;
      const Open = (selectedCollectionId)
        ? selectedCollectionId === base.UniqueId
        : ItemIndex === 0;
      const SelectOnMount = (selectedCollectionId === base.UniqueId && !optionalSelectedProjectId && !optionalSelectedCollectionItemId)
        || (ItemIndex === 0 && (!selectedCollectionId && !optionalSelectedProjectId));
      const node = {
        NodeType: base.NodeType,
        CustomIconID: base.CustomIconID,
        Name: base.Name,
        IsRoot: true,
        RootId: UniqueId,
        CollectionID: base.UniqueId,
        UniqueId,
        ItemIndex,
        HasChildren: base.NodeType !== NotificationsNodeType,
        NoSelect: base.NoSelect,
        Open,
        SelectOnMount,
        SelectOnExpand: selectedCollectionId && base.UniqueId !== selectedCollectionId,
        Url: `/workspace${base.PathElement}`,
      };
      RootNodes.push(node);
    });
    this.setState({ RootNodes });
    this.ensureSelectedNode();
  }

  getAndSetProjectNodesAsPromise = (ParentNode, disallowChildSelectOnLoad, isGetMore) => {
    // console.log("getAndSetProjectNodesAsPromise", ParentNode.Name);
    // Delay until we have UserPreferences
    if (!this.context || !this.context.CompletedGET.UserPreferences) {
      setTimeout(() => this.getAndSetProjectNodesAsPromise(ParentNode, disallowChildSelectOnLoad, isGetMore), 250);
      return;
    }
    if (!this.context.UserPreferences.ActiveOrganizationID) {
      return Promise.resolve([]);
    }

    const optionalSelectedProjectId = this.props.match.params.projectID;
    const uri = GetUserOrganizationProjectsPathForApi(this.context.UserPreferences.ActiveOrganizationID);
    return GetAndSetProjectsPackageForTreeNodesAsPromise(this.props, this.state, state => this.setState(state), 
      this.handleApiError, uri, optionalSelectedProjectId, 
        GetUserOrganizationAssignmentTypeFromNodeType(ParentNode.NodeType),
        "UserOrganizationProjects", "ProjectID", !isGetMore, isGetMore)
      .then(projectsPackage => {
        if (!projectsPackage || !projectsPackage.Projects) {
          return Promise.resolve([]);
        }
        const selectedCollectionId = this.props.match.params.collectionID;
        let projectNodes = [];  
        projectsPackage.Projects.forEach((p, ItemIndex) => {
          const UniqueId = `${WorkspaceProjectNodeType}_${p.ProjectID}_${ItemIndex.toString()}`;
          const Open = (optionalSelectedProjectId)
            ? optionalSelectedProjectId === p.ProjectID
            : ItemIndex === 0;
          const node = {
            ParentNode,
            NodeType: WorkspaceProjectNodeType,
            CustomIconID: "projects",
            Name: p.ProjectName,
            ProjectID: p.ProjectID,
            RootId: ParentNode.RootId,
            UniqueId,
            ItemIndex,
            HasChildren: false,
            Open,
            SelectOnMount: ItemIndex === 0 && !optionalSelectedProjectId && !selectedCollectionId,
            Url: `${ParentNode.Url}/projects/${p.ProjectID}`,
          };
          projectNodes.push(node);
        });
        
        this.setChildNodesForParent(ParentNode.UniqueId, projectNodes);

        if (projectsPackage.ShowGetMore) {
          projectNodes.push(GetNodeForGetMoreItems(ParentNode));
        }
        return projectNodes;
      });
  }

  handleTabChange = (e, tabValue) => {
    this.props.history.push(tabValue);
  }

  getTreeFromRootNodes = () => 
    this.state.RootNodes.map(rootNode =>
      GetTreeNodeComponent(
        this.props, this.state, state => this.setState(state),
        rootNode,
        () => {},
        this.getAndSetRootNodeChildNodesAsPromise,
        this.getRootNodeChildNodeComponent,
        () => {},
        this.setRootNodeExpandFunction,
      )
    );

  getAndSetRootNodeChildNodesAsPromise = (ParentNode, disallowChildSelectOnLoad, isGetMore) => {
    switch (ParentNode.NodeType) {
      case DocumentFoldersNodeType:
        return this.DocumentFolders.GetAndSetDocumentFolderNodesAsPromise(this.props, this.state, this.setChildNodesForParent)
          (ParentNode, disallowChildSelectOnLoad, isGetMore);
      case NotificationsNodeType:
        return Promise.resolve([]);
      default:
        return this.getAndSetProjectNodesAsPromise(ParentNode, disallowChildSelectOnLoad, isGetMore);
    }
  }

  getRootNodeChildNodeComponent = (childNode, onGetMoreChildNodes) => {
    // console.log("getRootNodeChildNodeComponent", childNode.Name);
    switch (childNode.ParentNode.NodeType) {
      case DocumentFoldersNodeType:
        return this.DocumentFolders.GetDocumentFolderTreeNodeComponent(this.props, this.state, this.setChildNodesForParent)
          (childNode);
      default:
        return this.getProjectNodeComponent(childNode, onGetMoreChildNodes);
    }
  }

  setRootNodeExpandFunction = (node, onNodeExpand) => {
    switch (node.NodeType) {
      case DocumentFoldersNodeType:
        this.DocumentFolders.HandleSetNodeExpandFunction(node, onNodeExpand);
        break;
      default:
        const existingFuncFinder = this.NodeExpandFunctionsByUniqueId.filter(d => d.UniqueId === node.UniqueId);
        if (existingFuncFinder.length) {
          existingFuncFinder[0].onNodeExpand = onNodeExpand;
        } else {
          this.NodeExpandFunctionsByUniqueId.push({
            UniqueId: node.UniqueId,
            onNodeExpand,
          });
        }
        break;
    }
  }

  getProjectNodeComponent = (projectNode, onGetMoreProjectNodes) =>
    GetTreeNodeComponent(
      this.props, this.state, state => this.setState(state),
      projectNode,
      onGetMoreProjectNodes,
    );

  handleBreadcrumbSelect = SelectedNode => {
    if (SelectedNode.Url) {
      this.props.history.push(SelectedNode.Url, { ...this.props.location.state, SelectedNode, });
    }
  }

  handleSetBeginFileUploadFunc = beginFileUploadFunc => {
    this.BeginFileUploadFunc = beginFileUploadFunc;
  }

  handleBeginDocumentUpload = documentFolderNode => {
    if (!documentFolderNode) {
      return;
    }
    const url = documentFolderNode.Url;
    const documentFolderId = documentFolderNode.DocumentFolderID;
      // We need to be selected on the folder so that CaptureCore is instantiated,
    // which creates this.BeginFileUploadFunc
    if (this.props.location.pathname !== url) {
      this.props.history.push(url);
    }
    // wait for this.BeginFileUploadFunc from CaptureCore
    if (!this.BeginFileUploadFunc) {
      setTimeout(() => this.handleBeginDocumentUpload(documentFolderNode), 250);
      return;
    }
    this.BeginFileUploadFunc(documentFolderId);
  }

  handleBeginImageCapture = (documentFolderNode, fieldIDsAndValuesForCapture) => {
    if (!this.state.SelectedNode || !this.state.SelectedNode.DocumentFolderID
      || IsMobile() || !IsMicrosoftWindows()) {
      return;
    }
    // console.log("SelectedNode", this.state.SelectedNode);

    // Set some local variables for GetPreCaptureFieldGatheringContent
    this.PostFieldGatheringCaptureFunc = fieldIDsAndValues => this.handleBeginImageCapture(documentFolderNode, fieldIDsAndValues);
    this.AdditionalBodyTextForFieldGatheringDialog = "All documents will receive these values.";

    const url = this.state.SelectedNode.Url;
    if (!documentFolderNode) {
      documentFolderNode = {...this.state.SelectedNode};
    }
    if (this.props.location.pathname !== url) {
      this.props.history.push(url);
    }
    
    const extraState = {
      ImageCaptureDocumentFolderID: documentFolderNode.DocumentFolderID,
      ImageCaptureDocumentFolderName: documentFolderNode.Name,
      FieldIDsAndValuesForCapture: fieldIDsAndValuesForCapture || null,
    };

    const beginImageCapture = () => {
      this.handleSetDeviceCaptureDialogVisibility(true, extraState);
    }

    if (!fieldIDsAndValuesForCapture) {
      this.setState(extraState);
      this.handlePreCaptureFieldGathering(documentFolderNode.DocumentFolderID);
    } else {
      beginImageCapture();
    }
  }

  handlePreCaptureFieldGathering = documentFolderId => {
    const handlePreGatherFields = () => {
    }

    const handleCapture = fieldIDsAndValues => {
      this.PostFieldGatheringCaptureFunc(fieldIDsAndValues);
    }

    const {
      projectID,
    } = this.props.match.params;
    const {
      ActiveOrganizationID,
    } = this.context.UserPreferences;

    HandlePreCaptureFieldGathering(
      ActiveOrganizationID,
      projectID,
      this.handleApiError,
      () => this.state,
      state => this.setState(state),
      1,
      handlePreGatherFields,
      handleCapture,
      documentFolderId,
    );
  }

  handleSetDeviceCaptureDialogVisibility = (ShowDeviceCaptureDialog, extraState) => {
    this.setState({
      ...extraState,
      ShowDeviceCaptureDialog,
    });
  }

  handleDocumentFolderNodeCreated = node => {
    let childNodes = [];
    const childNodesFinder = this.ChildNodesByParentNodeUniqueId.filter(n => n.UniqueId === node.ParentNode.UniqueId);
    if (childNodesFinder.length) {
      childNodes = [...childNodesFinder[0].ChildNodes, node];
    } else {
      childNodes.push(node);
    }
    this.setChildNodesForParent(node.ParentNode.UniqueId, childNodes);
  }

  handleRefresh = () => {
    this.setState({
      CollectionUpdateID:new Date(),
      ForceRefresh: true,
    });
    setTimeout(() => this.setState({ForceRefresh:false}), 1);
  }

  render() {
    const {
      ApiError,
      Alert,
      RootNodes,
      SelectedNode,
      ForcePrependItems,
      ForceRefresh,
      ShowDeviceCaptureDialog,
      ImageCaptureDocumentFolderID,
      ImageCaptureDocumentFolderName,
      FabMenu_MouseY,
      FabMenu_MouseX,
      ItemTitle,
      FieldIDsAndValuesForCapture,
    } = this.state;
    const {
      // CompletedGET,
      UserPreferences,
      UserHasUnreadNotification,
    } = this.context;
    const {
      ActiveOrganizationID,
    } = UserPreferences;
    const {
      classes,
      ...restProps
    } = this.props;
    const {
      projectID: ProjectID,
    } = this.props.match.params;

    // Delay until we have UserPreferences
    if (!this.context.CompletedGET.UserPreferences) {
      return null;
    }

    // const pageTitle = (CompletedGET.UserPreferences && UserPreferences.ActiveOrganizationName)
    //   ? `My Work for ${UserPreferences.ActiveOrganizationName}`
    //   : "My Work";

    const documentFolderContent = this.DocumentFolders && this.DocumentFolders.GetContent(this.props, () => this.state);
    const leftPaneContent = (
      <div className={classes.leftPaneTreeContainer}>
        {this.getTreeFromRootNodes()}
        {documentFolderContent}
      </div>
    );

    const hasUnreadNotificationIndicator = (UserHasUnreadNotification)
      ? (
        <div className={classes.hasUnreadNotificationIndicator} />
      ) : (<div />);
    const getTabIcon = iconId => {
      if (iconId === "notifications") {
        return (
          <div style={{marginBottom:0}}>
            {hasUnreadNotificationIndicator}
            {GetIconById(iconId)}
          </div>
        );
      } else {
        return GetIconById(iconId);
      }
    }

    const currentUrlHasNode = RootNodes.filter(n => this.props.match.url.startsWith(n.Url)).length;
    const secondaryNavTabs = (IsMobile() && RootNodes && currentUrlHasNode) ? (
      <Tabs
        variant="scrollable"
        value={this.props.match.url}
        onChange={this.handleTabChange}
      >
        {
          RootNodes.map((n, index) => {
            // This sets the tab value to the extended URL to ensure there is a matching value for the Tabs component
            const tabValue = (this.props.match.url.startsWith(n.Url))
              ? this.props.match.url
              : n.Url;
            return (
              <Tab
                key={n.UniqueId} 
                label={n.Name}
                value={tabValue}
                icon={getTabIcon(n.CustomIconID)}
                style={{
                  marginLeft:(index === 0) ? 32 : undefined,
                }}
              />
            );
          })
        }
      </Tabs>
    ) : null;

    const captureCore = (ActiveOrganizationID && ProjectID)
      ? (
        <CaptureCore
          organizationId={ActiveOrganizationID}
          projectId={ProjectID}
          {...restProps}
          noContent
          node={SelectedNode}
          onApiError={this.handleApiError}
          onAlert={this.handleAlert}
          onClose={() => this.handleRefresh()}
          onSetBeginFileUploadFunc={this.handleSetBeginFileUploadFunc}
          gatherFieldValuesBeforeUpload
        />
      ) : null;

    const deviceCaptureDialog = (ActiveOrganizationID && ProjectID && !IsMobile() && IsMicrosoftWindows())
      ? (
        <DeviceCaptureDialog
          open={ShowDeviceCaptureDialog}
          organizationId={ActiveOrganizationID}
          projectId={ProjectID}
          isWorkspace
          documentFolderId={ImageCaptureDocumentFolderID}
          documentFolderName={ImageCaptureDocumentFolderName}
          fieldIDsAndValues={FieldIDsAndValuesForCapture}
          onApiError={this.handleApiError}
          onAlert={this.handleAlert}
          onClose={() => this.handleSetDeviceCaptureDialogVisibility(false)}
        />
      ) : null;

    const preCaptureFieldGatheringContent = GetPreCaptureFieldGatheringContent(
      ActiveOrganizationID,
      ProjectID,
      () => this.state,
      state => this.setState(state),
      this.handleApiError,
      this.handleAlert,
      this.PostFieldGatheringCaptureFunc,
      this.AdditionalBodyTextForFieldGatheringDialog,
      ImageCaptureDocumentFolderID,
    );

    return (
      <React.Fragment>
        {captureCore}
        {deviceCaptureDialog}
        {preCaptureFieldGatheringContent}
        <ItemCollectionBase
          {...restProps}
          
          isWorkspace
          
          passThroughComponent={this.Collection.PassThroughComponent}
          pageTitle="My Work"
          titleComponentTitle={ItemTitle}
          showOrgAsTitleOnDesktop
          contentUri={this.Collection.ContentUri}
          contentUriParams={this.Collection.ContentUriParams}
          collectionName={this.Collection.CollectionName}
          itemsName={this.Collection.ItemsName}
          itemName={this.Collection.ItemName}
          defaultViewType={this.Collection.DefaultViewType}
          
          onGetCollectionFieldsPromise={this.Collection.HandleGetCollectionFieldsPromise}
          onGetHeadCells={this.Collection.HandleGetHeadCells}
          onGetCardGridItems={this.Collection.HandleGetCardGridItems}
          onGetTableRows={this.Collection.HandleGetTableRows}
          singleLineTableCells={this.Collection.SingleLineTableCells}

          selectedNode={(!IsMobile()) ? SelectedNode : undefined}

          hideSensitiveFields={this.Collection.HideSensitiveFields}
          hideFilterSortDrawer={this.Collection.HideFilterSortDrawer}
          leftPaneContent={leftPaneContent}
          leftPaneStyle={{display:(IsMobile()) ? "none" : undefined}}
          secondaryNavTabs={secondaryNavTabs}
          onBreadcrumbSelect={this.handleBreadcrumbSelect}

          loadItemsImmediately
          organizationId={ActiveOrganizationID}
          projectId={ProjectID}
          apiError={ApiError}
          alert={Alert}
          onRefresh={this.Collection.HandleRefresh}
          onItemsChanged={this.Collection.HandleItemsChanged}
          forcePrependItems={ForcePrependItems}
          forceRefresh={ForceRefresh}
          includeItemIds={(this.props.match.params.collectionItemID) ? [this.props.match.params.collectionItemID] : undefined}
          allowSelect={this.Collection.AllowSelect}
          canSelectItem={this.Collection.CanSelectItem}
          onFabClick={this.Collection.HandleCreateNew}
          onGetFabMenu={this.Collection.HandleGetFabMenu}
          fabMenuCoords={{
            FabMenu_MouseY,
            FabMenu_MouseX,
            SelectedNode,
          }}
          dialogContent={this.Collection.HandleGetDialogContent(this.state)}
          onSetUpdateRevisedItemFunction={this.Collection.HandleSetUpdateRevisedItemFunction}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles, {withTheme: true})(Workspace);