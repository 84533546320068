// Some of RichTextEditorStyles (like "& pre") borrowed from ProseMirror site.css included on Basic Example page
export const GetRichTextEditorStyles = theme => {
	const commonMarginBlock = {
		marginBlockStart: "0.5em",
		marginBlockEnd: "0.25em",
	};
	return {
	  "& li p": {
	  	...commonMarginBlock,
	    marginBlockStart: "0.25em",
	  },
	  "& h1": {
	  	...commonMarginBlock,
	  },
	  "& h2": {
	  	...commonMarginBlock,
	  },
	  "& h3": {
	  	...commonMarginBlock,
	  },
	  "& p": {
	  	fontSize:16,
	  	...commonMarginBlock,
	  },
	  "& ul": {
	  	...commonMarginBlock,
	  },
	  "& a": {
	  	color:theme.palette.text.primary,
	  },
    "& pre": {
      marginLeft: 20,
      padding:8,
      backgroundColor:(theme.palette.type === "dark") ? "#444" : "#dadada",
      borderRadius: 4,
    },
    "& code": {
    },
	};
}