const mxConstants_CustomAdditions = {
	"SHAPE_TRIGGER": "trigger",
	"SHAPE_ACTION_GENERAL": "action_general",
	"SHAPE_ACTION_GENERAL_INPUTONLY": "action_general_inputOnly",
	"SHAPE_ACTION_GENERAL_WITHNOTIFICATION": "action_general_withNotification",
	"SHAPE_ACTION_APPROVAL": "action_approval",
	"SHAPE_ACTION_NOTIFICATION_INPUTONLY": "action_notification_inputOnly",
	"SHAPE_ACTION_TASK": "action_task",
};

export default mxConstants_CustomAdditions;