import React, { Component } from 'react';
import TitleComponent from '../Components/TitleComponent';

import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import UiCore from '../Components/UiCore';

const styles = theme => ({
  contentContainer: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
});

class ExpiredSubscription extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
    }
  }

  componentDidMount() {
  }

  render() {
    const { 
      ApiError,
      Alert,
      ShowProgressIndicator,
      ShowProgressIndicatorImmediately,
  	} = this.state;
    const {
      classes,
      // theme,
    } = this.props;

    let content = (
      <Container maxWidth="md" className={classes.contentContainer}>
        <TitleComponent title="Subscription Expired" />

        {/* <Typography variant="h5" paragraph> */}
        {/*   Project: {SubscriptionDetails.ProjectName} */}
        {/* </Typography> */}

        <Typography variant="h5" style={{fontWeight:500}}>
          The subscription associated with this organization has expired. Please contact your administrator or choose a different organization.
        </Typography>
      </Container>
    );

    return (
      <UiCore title="Subscription Expired"
        apiError={ApiError}
        alert={Alert}
        showProgressIndicator={ShowProgressIndicator}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
        content={content}
      />
    );
  }
}

export default withStyles(styles, {withTheme: true})(ExpiredSubscription);