import { GetFieldForFilterAndSort } from '../Util/Field';

const NeedSignatureCollectionFields = [
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[ProjectName].keyword",
		"Project",
		"FieldType_Text",
		"Meta_text_kw256lc[ProjectName]",
		null,
	    "projectList",
	    true,
	    true, // SortOnly - filter doesn't make sense because there's no need when in /projects and /workspace has project subnodes
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[AssignmentUserEmail].keyword",
		"Assigned to",
		"FieldType_Text",
		null,
	    [
	      {
	          value: "equals",
	          label: "equals",
	      },
	      {
	        value: "notEquals",
	        label: "does not equal",
	      },
	    ],
	    "addressBookItemsList",
	    true,
	),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[Name].keyword",
		"Document Name",
		"FieldType_Text",
		"Meta_text_kw256lc[Name]",
	),
	GetFieldForFilterAndSort(
		"Meta_date_str256[CreatedOn].string",
		"Requested On",
		"FieldType_Date",
	),
	GetFieldForFilterAndSort(
		"Meta_date_str256[ItemCreatedOn].string",
		"Document Created On",
		"FieldType_Date",
	),
	GetFieldForFilterAndSort(
		"Meta_long[FileSize]",
		"File Size",
		"FieldType_Number",
	),
	GetFieldForFilterAndSort(
		"Meta_int[PageCount]",
		"Page Count",
		"FieldType_Number",
	),
];

export default NeedSignatureCollectionFields;