import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

import { IsMobile } from './MobileDetector';

export const GetProjectAccessGridItems = (classes, projectAccess, onAssignmentsChange, onMemberAccessChange) => {
  const assignmentFormControlLabels = dense => (
    <React.Fragment>
      <FormControlLabel value="World" control={<Radio />} label={(dense) ? "Anyone" : "Anyone in the world"} />
      <FormControlLabel value="ProjectMember" control={<Radio />} label={(dense) ? "Members" : "Project members"} />
    </React.Fragment>
  );

  const getAssignmentGridItem = (propertyName, label, dense, desktopGridItemXs) => {
    return (
      <Grid item xs={(IsMobile()) ? 12 : desktopGridItemXs || 6} key={`a_assignment_${propertyName}`}>
        <FormControl component="fieldset">
          <FormLabel component="legend">{label}</FormLabel>
          <RadioGroup aria-label={propertyName} name={propertyName} value={projectAccess.Assignments[propertyName]} onChange={onAssignmentsChange(propertyName)}>
           {assignmentFormControlLabels(dense)}
          </RadioGroup>
        </FormControl>
      </Grid>
    );
  }

  const memberAccessFormControlLabels = (dense, useNoneInsteadOfAssigned) => {
    const assigned = (!useNoneInsteadOfAssigned) ? (
      <FormControlLabel value="Assigned" control={<Radio />} label={(dense) ? "Assignments" : "Direct assignments"} />
    ) : null;
    const none = (useNoneInsteadOfAssigned) ? (
      <FormControlLabel value="None" control={<Radio />} label="Nothing" />
    ) : null;
    return (
      <React.Fragment>
        <FormControlLabel value="All" control={<Radio />} label="Everything" />
        {assigned}
        {none}
      </React.Fragment>
    );
  }

  const getMemberAccessGridItem = (propertyName, label, dense, desktopGridItemXs, useNoneInsteadOfAssigned) => {
    return (
      <Grid item xs={(IsMobile()) ? 12 : desktopGridItemXs || 6} key={`a_memberAccess_${propertyName}`}>
        <FormControl component="fieldset">
          <FormLabel component="legend">{label}</FormLabel>
          <RadioGroup aria-label={propertyName} name={propertyName} value={projectAccess.MemberAccess[propertyName]} onChange={onMemberAccessChange(propertyName)}>
           {memberAccessFormControlLabels(dense, useNoneInsteadOfAssigned)}
          </RadioGroup>
        </FormControl>
      </Grid>
    );
  }

  const gridItems = [
    <Grid item xs={12} key="t_assignments" style={{paddingBottom:0}}>
      <Grid container spacing={1}>
        <Grid item>
          <Typography variant="subtitle1">Assignments</Typography>
        </Grid>
        <Grid item>
          <Tooltip title="These determine to whom an item can be assigned. Note: Documents can be signed by anyone.">
            <InfoIcon className={classes.infoIcon} />
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>,
    getAssignmentGridItem("Tasks", "Tasks", false, 3),
    getAssignmentGridItem("Approvals", "Approvals", true, 3),
    getAssignmentGridItem("DocumentFolders", "Folders", true, 3),
    <Grid item xs={12} key="t_access" style={{paddingBottom:0}}>
      <Grid container spacing={1}>
        <Grid item>
          <Typography variant="subtitle1">Member access</Typography>
        </Grid>
        <Grid item>
          <Tooltip title="These control what project members can access. Note: Project managers can manage everything.">
            <InfoIcon className={classes.infoIcon} />
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>,
    getMemberAccessGridItem("Tasks", "Tasks", false, 3),
    getMemberAccessGridItem("Approvals", "Approvals", true, 3),
    getMemberAccessGridItem("DocumentFolders", "Folders & documents", true, 3),
    getMemberAccessGridItem("AssetItems", "Assets", true, 3, true),
  ];

  return gridItems;
}