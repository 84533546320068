import React, { Component } from 'react';
// import ReactDOM from 'react-dom';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListSubheader from '@material-ui/core/ListSubheader';
// import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import Popover from '@material-ui/core/Popover';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import UiCore from '../Components/UiCore';
import FieldInput from '../Components/FieldInput';
import ConfigureFieldsDialog from '../Components/ConfigureFieldsDialog';
import FieldPropertiesDialog from '../Components/FieldPropertiesDialog';
import TaskDialog from '../Components/TaskDialog';
import SetDocumentFolderDialog from '../Components/SetDocumentFolderDialog';
import RelatedDocumentsDialog from '../Components/RelatedDocumentsDialog';
import DocumentEventsDialog from '../Components/DocumentEventsDialog';
import DocumentSignatureSessionRecipientsDialog from '../Components/DocumentSignatureSessionRecipientsDialog';
import DocumentSignatureSessionDesignTypeDialog from '../Components/DocumentSignatureSessionDesignTypeDialog';
import DocumentSignatureSessionFormDesignerDialog from '../Components/DocumentSignatureSessionFormDesignerDialog';
import DocumentSignatureSessionFinalDialog from '../Components/DocumentSignatureSessionFinalDialog';
import DocumentSignatureSessionReviewDialog from '../Components/DocumentSignatureSessionReviewDialog';
import VideoHelpDialog from '../Components/VideoHelpDialog';
import GridWithConditionalRendering from '../Components/GridWithConditionalRendering';

// import TextField from '@material-ui/core/TextField';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
// import ExpandLessIcon from '@material-ui/icons/ExpandLess';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ShowCommentsIcon from '@material-ui/icons/Comment';
import ShowPropertiesIcon from '@material-ui/icons/Reorder';
import NavigateFirstIcon from '@material-ui/icons/FirstPage';
import NavigateLastIcon from '@material-ui/icons/LastPage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigatePrevIcon from '@material-ui/icons/NavigateBefore';
import NavigateToPageIcon from '@material-ui/icons/Description';
import SignedIcon from '@material-ui/icons/VerifiedUser';
import PendingSignaturesIcon from '@material-ui/icons/AssignmentInd';
import RecycleBinIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { FolderMoveIcon } from '../Util/Icons';

import HelpIcon from '@material-ui/icons/HelpOutline';
import CopyUrlIcon from '@material-ui/icons/Link';
import CalendarIcon from '@material-ui/icons/Schedule';
import RelatedIcon from '@material-ui/icons/InsertDriveFile';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import SubscribeIcon from '@material-ui/icons/Bookmark';
import SignaturesIcon from '@material-ui/icons/LabelImportant';
import { TasksIcon } from '../Util/Icons';
import ImageIcon from '@material-ui/icons/Image';
import RestoreFromRecycleBinIcon from '@material-ui/icons/RestoreFromTrash';
import FieldsIcon from '@material-ui/icons/TextFields';

import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import grey from '@material-ui/core/colors/grey';

import {
  GetComposedFieldListLabelsAndValues,
  GetDedupedValues,
  GetFieldPassesValidation,
  GetUpdatedFieldObjectForValueChange,
  GetUpdatedFieldObjectForImageChange,
  HandleGetFieldListItemsFilterPromise,
  HandleFieldListItemAdd,
  GetFieldHasValueToDisplay,
  GetDependentFieldsParentValue,
  GetDependentFieldsParentField,
  GetEffectiveSelectionListIsDependent,
  GetEffectiveFieldLabelOrName,
} from '../Util/Field';
import NumericTextField from '../Components/NumericTextField';
import MultiUseDialog from '../Components/MultiUseDialog';
import TitleComponent from '../Components/TitleComponent';
import Comments from '../Components/Comments';
import ActionType from '../Model/ActionType';
import { GetNewTextField } from '../Model/Field';
import DocumentEditorHost from '../Components/DocumentEditorHost';
import {
  GetExecuteDownloadPromise, 
  GetDocumentContentPackagePromise,
  CreateDocumentSubscriptions,
  DeleteDocumentSubscriptions,
  SendDocumentsToRecycleBin,
  RestoreDocumentsFromRecycleBin,
  SetDocumentFolderForDocuments,
  GetFileSizePageCountSpan,
  GetDocumentContentPackageUri,
} from '../Util/Document';
import {
  GetUserValue,
} from '../Util/Properties';
import {
  ApproveApprovals,
  DeclineApprovals,
  DenyApprovals,
} from '../Util/Approvals';
import {
  HandleUpdateFields,
  RecurseDependentSelectionListsDown,
 } from '../Util/Fields';
 import {
  GetTagsControl,
  GetTagListValuesFromTagsObject,
  GetTagsAndAssetItemTagsFromTagListValues,
} from '../Util/Tags';
import API, {
  GetDocumentPathForApi,
  GetFieldsPathForApi,
  GetDocumentCommentsPathForApi,
  GetDocumentSignatureSessionPackagesPathForApi,
  GetDocumentPackagePathForApi,
  GetDocumentPackagePublicPathForApi,
  GetUserOrganizationProjectTaskDocumentPackagesPathForApi,
  GetUserOrganizationProjectTaskDocumentCommentsPathForApi,
  GetUserOrganizationProjectApprovalDocumentPackagesPathForApi,
  GetUserOrganizationProjectApprovalDocumentCommentsPathForApi,
  GetUserOrganizationProjectApprovalTaskDocumentPackagesPathForApi,
  GetUserOrganizationProjectApprovalTaskDocumentCommentsPathForApi,
  GetUserOrganizationProjectApprovalAssetItemDocumentPackagesPathForApi,
  GetUserOrganizationProjectApprovalAssetItemDocumentCommentsPathForApi,
  GetUserOrganizationProjectApprovalAssetItemTaskDocumentPackagesPathForApi,
  GetUserOrganizationProjectApprovalAssetItemTaskDocumentCommentsPathForApi,
  GetUserOrganizationProjectDocumentFolderDocumentPackagesPathForApi,
  GetUserOrganizationProjectDocumentFolderDocumentCommentsPathForApi,
  GetPublicVideoPath,
  GetDocumentFieldImageUploadsPathForApi,
} from '../Util/api';
import {
  GetSingleUserPreference_Bool,
  SaveSingleUserPreference_Bool,
} from '../Util/SingleUserPreference';
import {
  GetActiveDocumentsByCriteria,
  GetDocumentQueryFromBase64,
  GetUrlForDocument,
} from '../Util/Documents';
import { GlobalContext } from '../Context/Global.context';
import { 
  GetDocumentDetailPath,
  // GetApprovalsPath,
  GetPublicDocumentFoldersDocumentPrefix,
} from '../Util/PathHelper';
import queryString from 'query-string';
import {
  GetDateValue,
} from '../Util/Properties';
// import debounce from 'es6-promise-debounce';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { IsMobile } from '../Util/MobileDetector';
import ReRankItemInCollection from '../Util/ItemRanking';
import debounce from 'es6-promise-debounce';

const column2Width = 400;
const column3Width = 400;
const toolHeaderHeight = 48;
const ribbonHeight = 48;
const tabsHeight = 48;

const styles = theme => ({
	downloadContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ddd",
  },
  object: {
    width:"100%",
    // height:"100%",
    // marginBottom:-4,
    // [theme.breakpoints.down('sm')]: {
    //   height:400,
    //   marginBottom:0,
    // },
    height:(IsMobile()) ? 400 : "100%",
    marginBottom:(IsMobile()) ? 0 : -4,

    backgroundColor: theme.palette.type === "dark" ? "#888" : undefined,
  },
  imageContainer: {
    width: "100%",
    height: "100%",
    overflowY:"scroll",
  },
  image: {
    width:"100%",
    display:"block",
  },
  editorContainer: {
    // width: "100%",
    height: "100%",
  },
  fieldGrid: {
    paddingLeft:theme.spacing(3),
    paddingRight:theme.spacing(3),
  },
  fieldGridItem: {
    marginRight: (!IsMobile()) ? -22 : undefined,
  },
  tags: {
    padding:theme.spacing(3),
  },
  outerContainer: {
    height:"100%",
  },
  contentContainer: {
    // display:"flex",
    overflow:"hidden",
    // [theme.breakpoints.down('sm')]: {
    //   display:"block",
    //   height:"auto",
    // },
    display: (IsMobile()) ? "block" : "flex",
    height: (IsMobile()) ? "auto" : undefined,
  },
  content: {
    height:"100%",
    // [theme.breakpoints.down('sm')]: {
    //   height:"50%",
    // },
    flexGrow:1,
    "&:hover $navigateLeftSide,&:hover $navigateRightSide": {
      opacity:1,
    },
  },
  column1: {
    position:"relative",
    height:"100%",
    // [theme.breakpoints.down('sm')]: {
    //   display:"none",
    // },
    display: (IsMobile()) ? "none" : undefined,
  },
  column2_visible: {
    // [theme.breakpoints.up('md')]: {
    //   marginRight:0,
    // },
    marginRight:(!IsMobile()) ? 0 : undefined,
  },
  column2_hidden: {
    // [theme.breakpoints.up('md')]: {
    //   marginRight:-1 * column2Width,
    // },
    marginRight:(!IsMobile()) ? -1 * column2Width : undefined,
  },
  column2: {
    position:"relative",
    height:"100%",
    backgroundColor: theme.palette.background.default,
    zIndex:2,
    // [theme.breakpoints.up('md')]: {
    //   // This causes weird behavior with the "Enter a new comment..." textfield (too tall in some cases)
    //   // transition: "width 0.2s",
    //   transition: "margin 0.2s",
    //   minWidth:column2Width,
    //   maxWidth:column2Width,
    // },
    // This causes weird behavior with the "Enter a new comment..." textfield (too tall in some cases)
    // transition: "width 0.2s",
    transition: (!IsMobile()) ? "margin 0.2s" : undefined,
    minWidth: (!IsMobile()) ? column2Width : undefined,
    maxWidth: (!IsMobile()) ? column2Width : undefined,
    // [theme.breakpoints.down('sm')]: {
    //   display:"none",
    // },
    display: (IsMobile()) ? "none" : undefined,
  },
  column2Inner: {
    height:"100%",
    overflowY: "auto",
    overflowX: "hidden",
  },
  column3_visible: {
    // [theme.breakpoints.up('md')]: {
    //   marginRight:0,
    // },
    marginRight:(!IsMobile()) ? 0 : undefined,
  },
  column3_hidden: {
    // [theme.breakpoints.up('md')]: {
    //   marginRight:-1 * column3Width,
    // },
    marginRight:(!IsMobile()) ? -1 * column3Width : undefined,
  },
  column3: {
    position:"relative",
    height:"100%",
    backgroundColor: theme.palette.background.pane,
    padding: theme.spacing(3),
    zIndex:3,
    // [theme.breakpoints.up('md')]: {
    //   // This causes weird behavior with the "Enter a new comment..." textfield (too tall in some cases)
    //   // transition: "width 0.2s",
    //   transition: "margin 0.2s",
    //   minWidth:column3Width,
    //   maxWidth:column3Width,
    // },
    // This causes weird behavior with the "Enter a new comment..." textfield (too tall in some cases)
    // transition: "width 0.2s",
    transition: (!IsMobile()) ? "margin 0.2s" : undefined,
    minWidth: (!IsMobile()) ? column3Width : undefined,
    maxWidth:(!IsMobile()) ? column3Width : undefined,
    // [theme.breakpoints.down('sm')]: {
    //   display:"none",
    // },
    display: (IsMobile()) ? "none" : undefined,
  },
  tabs: {
    // [theme.breakpoints.up('md')]: {
      // display:"none",
    // },
    display:(!IsMobile()) ? "none" : undefined,
    maxHeight:tabsHeight,
  },
  tabContent: {
    // [theme.breakpoints.up('md')]: {
    //   display:"none",
    // },
    display:(!IsMobile()) ? "none" : undefined,
    position:"relative",
    height:`calc(100% - ${tabsHeight}px)`,
  },
  buttonContainer: {
    margin: theme.spacing(3),
    width: `calc(100% - ${theme.spacing(6)}px)`,
    display:"flex",
    justifyContent:"flex-end",
  },
  addFieldButton: {
    marginLeft:theme.spacing(1),
    marginRight:-theme.spacing(2),
  },
  approvalButtonGrid: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    // marginBottom: theme.spacing(1),
    top:theme.spacing(2),
    position:"sticky",
    width: `calc(100% - ${theme.spacing(4)}px)`,
  },
  navButtonContainer: {
    display:"flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(3),
  },
  approveActionButton: {
    backgroundColor: "#ffffff40",
    marginRight: theme.spacing(1)
  },
  approvalButton: {
    color: "#ffffff",
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  approveButton: {
    backgroundColor: green[700],
  },
  declineButton: {
    backgroundColor: red[700],
  },
  denyButton: {
    backgroundColor: grey[700],
  },
  hideCommentsButton: {
    // [theme.breakpoints.down('sm')]: {
    //   display:"none",
    // },
    display: (IsMobile()) ? "none" : undefined,
    position:"absolute",
    top:theme.spacing(4),
    right:theme.spacing(2),
    zIndex: 2,
  },
  showCommentsButton: {
    // display:"flex",
    // [theme.breakpoints.down('sm')]: {
    //   display:"none",
    // },
    display: (IsMobile()) ? "none" : "flex",
    height:42,
    width:36,
    position:"absolute",
    top:theme.spacing(8),
    right:0,
    alignItems:"center",
    justifyContent:"flex-end",
    backgroundColor:"#ddd",
    boxShadow: "-1px 1px 2px 0px #808080",
    zIndex:2,
    borderTopLeftRadius:50,
    borderBottomLeftRadius:50,
    "&:hover": {
      width:54,
    },
    transition: "width 0.2s",
    cursor:"pointer",
    "&:hover $showCommentsIcon": {
      marginRight:20,
    },
  },
  showCommentsIcon: {
    color:"#666",
    marginRight: 6,
    transition: "margin 0.2s",
  },
  navigateLeftSide: {
    // opacity:0,
    // [theme.breakpoints.down('sm')]: {
    //   opacity: 1,
    // },
    opacity: (IsMobile()) ? 1 : 0,
    transition:"opacity 500ms",
    width:"auto",
    position:"absolute",
    top:(IsMobile()) ? theme.spacing(4) : theme.spacing(8),
    left:(IsMobile()) ? theme.spacing(2) : theme.spacing(4),
    zIndex: 2,
  },
  navigateRightSide: {
    // opacity:0,
    // [theme.breakpoints.down('sm')]: {
    //   opacity: 1,
    // },
    opacity: (IsMobile()) ? 1 : 0,
    transition:"opacity 500ms",
    width:"auto",
    position:"absolute",
    top:(IsMobile()) ? theme.spacing(4) : theme.spacing(8),
    right:(IsMobile()) ? theme.spacing(2) : theme.spacing(6),
    zIndex: 2,
  },
  navigateButton: {
    backgroundColor:fade(theme.palette.primary.main, 0.3),
    "&:hover": {
      backgroundColor:fade(theme.palette.primary.main, 0.8),
    },
  },
  ribbon: {
    position:"sticky",
    top:0,
    left:0,
    height:ribbonHeight,
    paddingLeft:(IsMobile()) ? theme.spacing(2) : theme.spacing(3),
    paddingRight:theme.spacing(2.5),
    borderBottom:"1px solid",
    borderBottomColor: theme.palette.divider,
    zIndex:1,
    display:"flex",
    // justifyContent:"flex-end",
    alignItems:"center",
  },
  toolHeader: {
    position:"sticky",
    top:0,
    left:0,
    backgroundColor: theme.palette.background.default, 
    height:toolHeaderHeight,
    paddingLeft:(IsMobile()) ? theme.spacing(2.5) : theme.spacing(3.5),
    paddingRight:(IsMobile()) ? theme.spacing(1) : theme.spacing(2.5),
    borderBottom:"1px solid",
    borderBottomColor: theme.palette.divider,
    zIndex:1,
    display:"flex",
    justifyContent:"flex-end",
  },
  toolHeaderButtonDark: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  toolHeaderButtonLight: {
    color: "#fff",
  },
  toolInfo: {
    alignItems:"center",
    display:"flex",
    flexGrow:1,
  },
  desktopTools: {
    // display:"flex",
    // [theme.breakpoints.down('sm')]: {
    //   display:"none",
    // },
    display: (IsMobile()) ? "none" : "flex",
  },
  popoverPaper: {
    padding: theme.spacing(2),
  },
  menuDivider: {
    marginTop:theme.spacing(1),
    marginBottom:theme.spacing(1),
  },
  headerItem: {
    fontSize: (IsMobile()) ? "0.75rem" : undefined,
    display:"inline",
    marginRight:theme.spacing(2),
    whiteSpace:"nowrap",
  },
  mobileImageScrollIndicator: {
    position:"absolute",
    color:fade(theme.palette.primary.main, 0.5),
  },
  mobileImageScrollUpIndicator: {
    top:8,
    left:`calc(50% - 12px)`,
  },
  mobileImageScrollDownIndicator: {
    bottom:8,
    left:`calc(50% - 12px)`,
  },
});

const rankIncrement = 1000;

class Document extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      WindowWidth: null,
      DocumentPropertiesOpen: false,
      DocumentCommentsOpen: false,
      SinglePagePreview: props.usePublicApi || false,
      DocumentPackage: null,
      DocumentContentPackage: null,
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
      FieldsConfigureDialogIsOpen: false,
      RelatedDocumentsDialogIsOpen: false,
      DocumentEventsDialogIsOpen: false,
      TaskDialogIsOpen: false,
      PreMoveFieldsJson: "",
      ShowFieldValidationAlert: false,
      ShowNavigateToPageDialog: false,
      ActionMenuAnchorEl: null,
      HelpMenuAnchorEl: null,
      NavigateToPage_PageNumber: "",
      PopoverText: false,
      DocumentSignatureSessionRecipientsDialogIsOpen: false,
      DocumentSignatureSessionDesignTypeDialogIsOpen: false,
      DocumentSignatureSessionFormDesignerDialogIsOpen: false,
      DocumentSignatureSessionFinalDialogIsOpen: false,
      DocumentSignatureSessionReviewDialogIsOpen: false,
      SignatureDialogsState: {},
      DocumentSignatureSessionPackage: null,
      ShowAddFieldDialog: false,
      ShowFieldPropertiesDialog: false,
      AddFieldNewFieldName: null,
      FieldToCustomize: {},
      FocusOnNewComment: false,
      ImageContainerScrollTop: 0,
      ImageContainerScrollHeight: 0,
      ImageContainerClientHeight: 0,
      DocumentNameChangeConfirmationDialogIsOpen: false,
      SelectedTab: "content",
      ShowDocumentFolderDialog: false,
      ShowHelpDialog: false,
      HelpVideoFilename: "",
      TagListValues: [],
      RecommendFieldsByTag: false,
    }

    this.loadingDocumentText = "Loading document...";
    this.ImageContainerRef = React.createRef();
    this.OuterContainerRef = React.createRef();
    this.OrganizationID = props.match.params.organizationID;
    this.ProjectID = props.match.params.projectID;
    this.DocumentID = props.match.params.documentID;
    this.ApprovalID = this.getParsedQueryString().approvalID;
    this.AllowApproval = this.getParsedQueryString().allowApproval === "true";
    this.TaskID = this.getParsedQueryString().taskID;
    this.AssetID = this.getParsedQueryString().assetID;
    this.AssetItemID = this.getParsedQueryString().assetItemID;
    this.DocumentFolderID = this.getParsedQueryString().documentFolderID;
    this.NavigateToNextOnCommit = (this.getParsedQueryString().nnoc);
    this.UserAssignmentContext = this.getParsedQueryString().uac;
    if (this.getParsedQueryString().sig) {
      this.tryShowSignatureGatheringDialog();
    }

    this.SetOtherAppBarContentFunc = () => {};
  }

  handleRouteToRelatedDocumentForPublicContext = documentId => {
    this.handleCloseRelatedDocumentsDialog();
    this.DocumentID = documentId;
    const url = GetUrlForDocument(this.OrganizationID, this.ProjectID, documentId, false, {},
      false, "", true, true, GetPublicDocumentFoldersDocumentPrefix(this.props.location),
    );
    this.props.history.replace(url);
    this.loadDocument();
  }

  handleSetFuncToSetOtherAppBarContent = f => {
    this.SetOtherAppBarContentFunc = f;
  }

  handleSetOtherAppBarContent = content => {
    this.SetOtherAppBarContentFunc(content);
  }

  handleGetDocumentPackage(additionalState, updateFields, keepContentIntact) {
    this.setState({
      DocumentPackage: null, 
      ShowProgressIndicatorImmediately: true,
    });
    if (!keepContentIntact) {
      this.setState({DocumentContentPackage: null});
    }
    let approvalId = this.ApprovalID;
    let uri = (this.props.usePublicApi)
      ? GetDocumentPackagePublicPathForApi(this.OrganizationID, this.ProjectID, this.DocumentID)
      : GetDocumentPackagePathForApi(this.OrganizationID, this.ProjectID, this.DocumentID);
    if (this.UserAssignmentContext) {
      switch (this.UserAssignmentContext) {
        case "task":
          uri = GetUserOrganizationProjectTaskDocumentPackagesPathForApi(this.OrganizationID,
            this.ProjectID, this.TaskID, this.DocumentID);
          break;
        case "approval":
          uri = GetUserOrganizationProjectApprovalDocumentPackagesPathForApi(this.OrganizationID,
            this.ProjectID, this.ApprovalID, this.DocumentID);
          break;
        case "approvalTask":
          uri = GetUserOrganizationProjectApprovalTaskDocumentPackagesPathForApi(this.OrganizationID,
            this.ProjectID, this.ApprovalID, this.TaskID, this.DocumentID);
          break;
        case "approvalAssetItem":
          uri = GetUserOrganizationProjectApprovalAssetItemDocumentPackagesPathForApi(this.OrganizationID,
            this.ProjectID, this.ApprovalID, this.AssetID, this.AssetItemID, this.DocumentID);
          break;
        case "approvalAssetItemTask":
          uri = GetUserOrganizationProjectApprovalAssetItemTaskDocumentPackagesPathForApi(this.OrganizationID,
            this.ProjectID, this.ApprovalID, this.AssetID, this.AssetItemID, this.TaskID, this.DocumentID);
          break;
        case "documentFolder":
          uri = GetUserOrganizationProjectDocumentFolderDocumentPackagesPathForApi(this.OrganizationID,
            this.ProjectID, this.DocumentFolderID, this.DocumentID);
          break;  
        default:
          break;
      }
    }
    return API.get(uri, { params: { approvalId }})
      .then(resp => {
        const documentPackage = resp.data;
        this.updateDocumentPackageState(documentPackage, { 
            ShowProgressIndicatorImmediately: false, 
            ...additionalState 
          }, updateFields);
        this.setTagListValuesFromDocumentPackage(documentPackage);

        // If user is project admin, and document is untagged,
        // and more than 10 fields are displayed, 
        // show recommendation to use fields-by-tag
        if (this.getIsProjectAdmin(documentPackage)
          && (!documentPackage.Document.Tags || !documentPackage.Document.Tags.length)
          && documentPackage.Fields
          && documentPackage.Fields.length >= 10
        ) {
          GetSingleUserPreference_Bool("FieldsByTagRecommended")
            .then(fieldsByTagRecommended => {
              if (!fieldsByTagRecommended) {
                this.setState({RecommendFieldsByTag: true});  
              }
            })
            .catch(this.handleApiError);
        }

        return resp.data;
      })
      .catch(this.handleApiError);
  }

  setTagListValuesFromDocumentPackage = documentPackage => {
    this.setState({TagListValues:GetTagListValuesFromTagsObject(documentPackage.Document)});
  }

  handleGetDocumentContentPackageForPreview = (useSinglePagePreview, pageIndex_optional) => {
    const uri = GetDocumentContentPackageUri(this.OrganizationID, this.ProjectID, this.DocumentID, this.props.usePublicApi,
      this.UserAssignmentContext, this.DocumentFolderID, this.ApprovalID, this.TaskID, this.AssetID, this.AssetItemID);
    return GetDocumentContentPackagePromise(uri, true, true, null,
      IsMobile() || useSinglePagePreview, false, pageIndex_optional)
      .then(documentContentPackage => {
        this.setState({DocumentContentPackage: documentContentPackage});
      })
      .catch(this.handleApiError);
  }

  handleGoToContentPage = pageIndex => {
    this.handleSetShowNavigateToPageDialogVisibility(false);
    let p = this.state.DocumentContentPackage;
    if (pageIndex === undefined) {
      pageIndex = this.state.NavigateToPage_PageNumber - 1;
    }
    if (p.PageCount && pageIndex >= 0 && pageIndex < p.PageCount && pageIndex !== p.PageIndex) {
      this.setState({ShowProgressIndicatorImmediately:true});
      this.handleGetDocumentContentPackageForPreview(true, pageIndex)
        .then(() => {
          this.setState({ShowProgressIndicatorImmediately:false});
          if (this.ImageContainerRef) {
            this.ImageContainerRef.scrollTop = 0;
          }
        });
    }
  }

  handleUpdateDocumentPackage(updatedDocumentPackage) {
    this.setState({ShowProgressIndicatorImmediately: true});
    return API.put(GetDocumentPackagePathForApi(this.OrganizationID,
      this.ProjectID, this.DocumentID), updatedDocumentPackage)
      .then(resp => { 
        this.updateDocumentPackageState(resp.data, { ShowProgressIndicatorImmediately: false });
      })
      .catch(err => {
        this.handleApiError(err);
        return Promise.reject(err);
      });
  }

  handleCommit() {
    // Check required fields
    let documentPackage = {...this.state.DocumentPackage};
    let notCompleteFieldIDs = [];
    if (documentPackage.Fields && documentPackage.Fields.length > 0) {
      for (let i = 0; i < documentPackage.Fields.length; i++) {
        const field = documentPackage.Fields[i];
        if (!this.shouldShowField(field)) {
          continue;
        }
        if (!GetFieldPassesValidation(field)) {
          notCompleteFieldIDs.push(field.FieldID);
        }
      }
    }
    documentPackage = this.updateDocumentPackageState(documentPackage, {}, true);
    
    if (notCompleteFieldIDs.length > 0) {
      this.handleSetFieldValidationAlertVisibility(true);
      return false;
    }

    this.handleUpdateDocumentPackage(documentPackage)
      .then(() => {
        // Try to move forward to the next document if this is the inbox
        let q = this.getDocumentQuery();
        if (this.NavigateToNextOnCommit) {
          this.tryMoveToNextDocument(true, q);
        }
      })
  }

  shouldShowField = (f, documentPackage) => {
    if (!documentPackage) {
      documentPackage = this.state.DocumentPackage;
    }
    if (!documentPackage.Document.Tags || !documentPackage.Document.Tags.length) {
      return true;
    }
    if (!f.DocumentTags || !f.DocumentTags.length) {
      return false;
    }
    for (let i = 0; i < documentPackage.Document.Tags.length; i++) {
      if (f.DocumentTags.includes(documentPackage.Document.Tags[i])) {
        return true;
      }
    }
    return false;
  }

  handleSetDocumentNameChangeConfirmationDialogIsOpen = visible => {
    this.setState({
      DocumentNameChangeConfirmationDialogIsOpen: visible,
    });
  }

  handleUpdateDocumentName = newName => {
    let documentPackage = {...this.state.DocumentPackage};
    const originalName = documentPackage.Document.Name;
    documentPackage.Document.Name = newName;
    this.handleSetDocumentNameChangeConfirmationDialogIsOpen(false);
    return this.handleUpdateDocument(documentPackage, true)
      .catch(err => {
        documentPackage.Document.Name = originalName;
        this.updateDocumentPackageState(documentPackage);
      })
      .finally(() => {
        this.context.SetReloadItemsFlag();
      });
  }

  handleUpdateDocument = debounce((documentPackage, updateStateOnCompletion) => {
    return API.put(GetDocumentPathForApi(this.OrganizationID, this.ProjectID, this.DocumentID),
      documentPackage.Document)
      .then(resp => {
        documentPackage.Document = resp.data;
        if (updateStateOnCompletion) {
          this.updateDocumentPackageState(documentPackage);
        }
        return documentPackage;
      })
      .catch(err => {
        this.handleApiError(err);
        return Promise.reject(err);
      });
  }, 250);

  getParsedQueryString() {
    return queryString.parse(this.props.location.search);
  }
  
  getDocumentQuery() {
    const qs = this.getParsedQueryString();
    return (qs.q)
      ? GetDocumentQueryFromBase64(qs.q)
      : null;
  }

  tryMoveToNextDocument(exitOnNoDocuments, documentQuery) {
    if (!documentQuery) {
      documentQuery = this.getDocumentQuery();
    }
    if (documentQuery) {
      documentQuery.Offset++;
      documentQuery.CurrentCursor = "";
      documentQuery.NextCursor = "";
      documentQuery.PreviousCursor = "";
      // if (documentQuery.CurrentCursor) {
      //   documentQuery.PreviousCursor = documentQuery.CurrentCursor;
      //   documentQuery.CurrentCursor = "";
      // }
      // if (documentQuery.NextCursor) {
      //   documentQuery.CurrentCursor = documentQuery.NextCursor;
      //   documentQuery.NextCursor = "";
      // }
      this.tryNavigateToDocument(documentQuery, exitOnNoDocuments);
    }
  }

  tryMoveToPrevDocument(documentQuery) {
    if (!documentQuery) {
      documentQuery = this.getDocumentQuery();
    }
    if (documentQuery) {
      if (documentQuery.Offset > 0) {
        documentQuery.Offset--;
        documentQuery.CurrentCursor = "";
        documentQuery.NextCursor = "";
        documentQuery.PreviousCursor = "";
        // if (documentQuery.CurrentCursor) {
        //   documentQuery.NextCursor = documentQuery.CurrentCursor;
        //   documentQuery.CurrentCursor = "";
        // }
        // if (documentQuery.PreviousCursor) {
        //   documentQuery.CurrentCursor = documentQuery.PreviousCursor;
        //   documentQuery.PreviousCursor = "";
        // }
      }
      this.tryNavigateToDocument(documentQuery);
    }
  }

  tryNavigateToDocument(documentQuery, exitOnNoDocuments) {
    GetActiveDocumentsByCriteria(documentQuery.Endpoint, documentQuery.Params, documentQuery.CurrentCursor, documentQuery.Offset, 
      documentQuery.SortType, documentQuery.SortDescending, true)
      .then(documentList => {
        const qs = this.getParsedQueryString();
        if (documentList.Documents.length > 0) {
          documentQuery.NextCursor = documentList.Cursor;
          let newUrl = GetDocumentDetailPath(this.OrganizationID,
            this.ProjectID,
            documentList.Documents[0].DocumentID);
          let urlParams = "";
          if (qs.w) {
            urlParams += (urlParams.length > 0) ? "&" : "?";
            urlParams += "w=1";
          }
          // if (documentQuery.Params && documentQuery.Params.approvals) {
          //   urlParams += (urlParams.length > 0) ? "&" : "?";
          //   urlParams += "approvalID=" + documentList.Documents[0].ApprovalID;
          // }
          urlParams += (urlParams.length > 0) ? "&" : "?";
          urlParams += "q=" + btoa(JSON.stringify(documentQuery));
          this.props.history.replace(newUrl + urlParams);
          this.loadDocument();
        } else if (exitOnNoDocuments) {
            this.tryExit();
        }
      })
      .catch(err => {
        this.handleApiError(err);
      });
  }

  tryExit = parsedQueryString => {
    if (!parsedQueryString) {
      parsedQueryString = this.getParsedQueryString();
    }
    if (parsedQueryString.w) {
      this.context.SetReloadItemsFlag();
      window.close();
    }
  }

  // This updates Fields on the DocumentPackage object.
  // Among other things, it adds "ListValues" and "ListLabels" to said objects,
  // which is necessary for selection-list operation.
  updateDocumentPackageState(documentPackage, additionalState, updateFields) {
    // Add/Update ListValues for each Fields[]
    let fields = documentPackage.Fields;
    if (fields) {
      for (let i = 0; i < fields.length; i++) {
        let field = fields[i];
        if (updateFields) {
          field.UpdateId = new Date();
        }
        if (typeof field.ListValues === "undefined") {
          Object.assign(field, { ListValues: [] });
        }
        // For multi-value fields, dedupe (for safety)
        field.Values = GetDedupedValues(field);
        // ListValues are for selection lists
        field.ListValues = GetComposedFieldListLabelsAndValues(field);
        // Values must exist as a JSON array
        if (Array.isArray(field.Values)) {
          field.Values = JSON.stringify(field.Values);
        }
      }
    }
    this.setState(Object.assign({ DocumentPackage: documentPackage }, additionalState));

    return documentPackage;
  }

  handleFieldValueChange = fieldID => (event, selectedOptions) => {
    let documentPackage = {...this.state.DocumentPackage};
    const field = documentPackage.Fields.find(f => f.FieldID === fieldID);

    GetUpdatedFieldObjectForValueChange(
      field,
      event, selectedOptions);
    
    // Clear any fields that are selection lists and a child of the current field
    this.clearAndReloadDependentFieldValues(field, documentPackage.Fields);

    this.updateDocumentPackageState(documentPackage);
  }

  handleFieldImageChange = fieldID => (imageObjectName, imageSignedUrl) => {
    let documentPackage = {...this.state.DocumentPackage};
    const field = documentPackage.Fields.find(f => f.FieldID === fieldID);
    GetUpdatedFieldObjectForImageChange(
      field,
      imageObjectName, imageSignedUrl);
    
    // Clear any fields that are selection lists and a child of the current field
    this.clearAndReloadDependentFieldValues(field, documentPackage.Fields);

    this.updateDocumentPackageState(documentPackage);
  }

  clearAndReloadDependentFieldValues = (field, fields) => {
    if (!document || !field) {
      return;
    }

    RecurseDependentSelectionListsDown(field, fields,
      f => f.FieldID,
      f => f.ParentFieldID,
      f => {
        if (!GetEffectiveSelectionListIsDependent(f)) {
          return;
        }
        // Clear the field's value
        GetUpdatedFieldObjectForValueChange(f, undefined, []);
        
        // Cause the selection list to be reloaded
        f.UpdateId = new Date();
      }
    );
  }

  handleOpenConfigureFieldsDialog = () => {
    this.handleActionMenuClose();
    this.setState({FieldsConfigureDialogIsOpen: true});
  }

  handleCloseConfigureFieldsDialog = () => {
    this.setState({FieldsConfigureDialogIsOpen: false});
    // Force DocumentPackage reload to ensure fields are updated and accurate
    this.handleGetDocumentPackage(false, true, true);
  }

  handleOpenDocumentEventsDialog = () => {
    this.handleActionMenuClose();
    this.setState({DocumentEventsDialogIsOpen: true});
  }

  handleCloseDocumentEventsDialog = () => {
    this.setState({DocumentEventsDialogIsOpen: false});
  }

  handleOpenRelatedDocumentsDialog = () => {
    this.handleActionMenuClose();
    this.setState({RelatedDocumentsDialogIsOpen: true});
  }

  handleCloseRelatedDocumentsDialog = () => {
    this.setState({RelatedDocumentsDialogIsOpen: false});
  }

  handleOpenTaskDialog = () => {
    this.handleActionMenuClose();
    this.setState({TaskDialogIsOpen: true});
  }

  handleCloseTaskDialog = () => {
    this.setState({TaskDialogIsOpen: false});
  }

  handleResetSignatureSession = () => {
    this.handleSetDocumentSignatureSessionReviewDialogVisibility(false);
    this.updateDocumentSignatureSessionPackage(null, false, true)
      .then(() => {
        // Get a fresh session
        this.getSignatureSessionPackage(true)
          .then(documentSignatureSessionPackage => {
            this.handleManageSignatureSession(documentSignatureSessionPackage);
          });
      });
  }

  handleManageSignatureSession = (documentSignatureSessionPackage, documentPackage, dialogVisibilityFunction) => {
    this.handleActionMenuClose();

    if (!documentPackage) {
      documentPackage = {...this.state.DocumentPackage};
    }

    // Do not allow signatures if already signed
    if (documentPackage.Document.IsSigned) {
      this.handleApiError("This document has been previously signed.");
      return;
    }

    let checkSignatureSession = documentSignatureSessionPackage => {
      if (documentSignatureSessionPackage.Session.IsActive) {
        this.handleSetDocumentSignatureSessionReviewDialogVisibility(true);
      } else if (dialogVisibilityFunction) {
        dialogVisibilityFunction(true);
      } else {
        this.handleOpenDocumentSignatureSessionRecipientsDialog();
      }
    };

    this.setState({ShowProgressIndicatorImmediately: true,});
    this.getSignatureSessionPackage()
      .then(documentSignatureSessionPackage => {
        this.setState({ShowProgressIndicatorImmediately: false,});
        checkSignatureSession(documentSignatureSessionPackage);
      })
      .catch(this.handleApiError);  
  }

  handleOpenDocumentSignatureSessionRecipientsDialog = () => {
    this.setState({DocumentSignatureSessionRecipientsDialogIsOpen: true});
  }

  handleCloseDocumentSignatureSessionRecipientsDialog = (moveBack, moveForward, dialogState) => {
    let SignatureDialogsState = {...this.state.SignatureDialogsState, Recipients: dialogState};
    this.setState({
      DocumentSignatureSessionRecipientsDialogIsOpen: false,
    });
    this.updateDocumentSignatureSessionPackage(SignatureDialogsState)
      .then(signatureSessionPackage => {
        let newRecipients = [];
        signatureSessionPackage.Recipients.forEach(r => {
          newRecipients.push({...r,
            AssignmentUserEmail_ListValue: {
              label: r.AssignmentUserEmail,
              value: r.AssignmentUserEmail,
              projectMemberId: r.ProjectMemberID,
            },
          });
        });
        SignatureDialogsState.Recipients.Recipients = newRecipients;
        this.setState({SignatureDialogsState});
        if (moveForward) {
          this.handleOpenDocumentSignatureSessionDesignTypeDialog();
        }    
      });
  }

  handleOpenDocumentSignatureSessionDesignTypeDialog = () => {
    this.setState({DocumentSignatureSessionDesignTypeDialogIsOpen: true});
  }

  handleCloseDocumentSignatureSessionDesignTypeDialog = (moveBack, moveForward, dialogState) => {
    let SignatureDialogsState = {...this.state.SignatureDialogsState, DesignType: dialogState};
    this.setState({
      SignatureDialogsState,
      DocumentSignatureSessionDesignTypeDialogIsOpen: false,
    });
    if (moveBack) {
      this.handleOpenDocumentSignatureSessionRecipientsDialog();
    } else if (moveForward) {
      if (dialogState && dialogState.FormDesignType === "FormDesign") {
        this.handleOpenDocumentSignatureSessionFormDesignerDialog();
      } else {
        this.handleOpenDocumentSignatureSessionFinalDialog();
      }
    } else {
      this.updateDocumentSignatureSessionPackage(SignatureDialogsState);
    }
  }

  handleOpenDocumentSignatureSessionFormDesignerDialog = () => {
    this.setState({DocumentSignatureSessionFormDesignerDialogIsOpen: true});
  }

  handleCloseDocumentSignatureSessionFormDesignerDialog = (moveBack, moveForward, dialogState) => {
    let SignatureDialogsState = {...this.state.SignatureDialogsState, FormDesigner: dialogState};
    this.setState({
      SignatureDialogsState,
      DocumentSignatureSessionFormDesignerDialogIsOpen: false,
    });
    if (moveBack) {
      this.handleOpenDocumentSignatureSessionDesignTypeDialog();
    } else if (moveForward) {
      this.handleOpenDocumentSignatureSessionFinalDialog();
    } else {
      this.updateDocumentSignatureSessionPackage(SignatureDialogsState);
    }
  }

  handleOpenDocumentSignatureSessionFinalDialog = () => {
    this.setState({DocumentSignatureSessionFinalDialogIsOpen: true});
  }

  handleCloseDocumentSignatureSessionFinalDialog = (moveBack, moveForward, dialogState) => {
    let SignatureDialogsState = {...this.state.SignatureDialogsState, Final: dialogState};
    this.setState({
      SignatureDialogsState,
      DocumentSignatureSessionFinalDialogIsOpen: false,
    });
    if (moveBack) {
      if (this.state.SignatureDialogsState.DesignType.FormDesignType === "FormDesign") {
        this.handleOpenDocumentSignatureSessionFormDesignerDialog();
      } else {
        this.handleOpenDocumentSignatureSessionDesignTypeDialog();
      }
    } else {
      this.updateDocumentSignatureSessionPackage(SignatureDialogsState, moveForward);
    }
  }

  handleSetDocumentSignatureSessionReviewDialogVisibility = visible => {
    this.setState({DocumentSignatureSessionReviewDialogIsOpen: visible});
  }

  getSignatureSessionPackage = skipProgressIndicatorHide => {
    this.setState({ShowProgressIndicatorImmediately:true});
    return API.get(GetDocumentSignatureSessionPackagesPathForApi(this.OrganizationID,
      this.ProjectID, this.DocumentID))
      .then(resp => {
        let documentSignatureSessionPackage = resp.data;
        let stateToUpdate = {
          DocumentSignatureSessionPackage: documentSignatureSessionPackage,
        };
        if (!skipProgressIndicatorHide) {
          stateToUpdate.ShowProgressIndicatorImmediately = false;
        }
        this.setState(stateToUpdate);
        return documentSignatureSessionPackage;
      });
  }

  updateDocumentSignatureSessionPackage = (dialogsState, beginSession, resetSession) => {
    if (!dialogsState) {
      dialogsState = {...this.state.SignatureDialogsState};
    }
    if (!this.state.DocumentSignatureSessionPackage) {
      this.handleApiError("A local session doesn't exist.");
      return;
    }
    let sessionPackage = {...this.state.DocumentSignatureSessionPackage};
    if (dialogsState.Recipients) {
      let recipients = [];
      dialogsState.Recipients.Recipients.forEach(r => {
        if (r.AssignmentUserEmail_ListValue !== undefined) {
          if (r.AssignmentUserEmail_ListValue && r.AssignmentUserEmail_ListValue.value) {
            recipients.push({
              ID: r.ID,
              UniqueID: r.UniqueID,
              AssignmentUserEmail: r.AssignmentUserEmail_ListValue.value,
              ProjectMemberID: (r.AssignmentUserEmail_ListValue.projectMemberId) ? r.AssignmentUserEmail_ListValue.projectMemberId : "",
              AssignmentUserName: r.AssignmentUserName,
              Type: r.Type,
              Rank: r.Rank,
            });
          }
        } else {
          recipients.push(r);
        }
      });
      sessionPackage.Recipients = recipients;
    }
    if (dialogsState.DesignType) {
      sessionPackage.Session.FormDesignType = dialogsState.DesignType.FormDesignType;
    }
    if (dialogsState.Final) {
      sessionPackage.Session.UseAccessCode = dialogsState.Final.UseAccessCode;
      sessionPackage.Session.AccessCode = dialogsState.Final.AccessCode;
      sessionPackage.Session.UseCustomSubjectAndBody = dialogsState.Final.UseCustomSubjectAndBody;
      sessionPackage.Session.CustomSubject = dialogsState.Final.CustomSubject;
      sessionPackage.Session.CustomBody = dialogsState.Final.CustomBody;
      sessionPackage.Session.QuickDesignPlaceInitials = dialogsState.Final.QuickDesignPlaceInitials;
      sessionPackage.Session.QuickDesignPlaceFullName = dialogsState.Final.QuickDesignPlaceFullName;
      sessionPackage.Session.QuickDesignPlaceEmail = dialogsState.Final.QuickDesignPlaceEmail;
      sessionPackage.Session.QuickDesignPlaceTitle = dialogsState.Final.QuickDesignPlaceTitle;
    }

    sessionPackage.BeginSession = beginSession;
    sessionPackage.ResetSession = resetSession;

    this.setState({ShowProgressIndicatorImmediately:true});
    return API.put(GetDocumentSignatureSessionPackagesPathForApi(
      this.OrganizationID, this.ProjectID,
      this.DocumentID),
      [sessionPackage])
      .then(resp => {
        let newSessionPackage = resp.data[0];
        let stateToUpdate = {
          DocumentSignatureSessionPackage: newSessionPackage,
        };
        if (!resetSession) {
          this.handleGetDocumentPackage(false, false, true);
        }
        this.setState(stateToUpdate);
        return newSessionPackage;
      })
      .catch(this.handleApiError);
  }

  handleStartMoveFieldInput() {
    let documentPackage = {...this.state.DocumentPackage};
    let fields = documentPackage.Fields;
    let stateToUpdate = {};
    if (fields.length > 1 && fields[0].FieldRank === fields[1].FieldRank) {
      for (let i = 0; i < fields.length; i++) {
        fields[i].FieldRank = i * rankIncrement;
      }
      stateToUpdate.DocumentPackage = documentPackage;
    }
    stateToUpdate.PreMoveFieldsJson = JSON.stringify(documentPackage.Fields);
    
    this.setState(stateToUpdate);
  }

  handleMoveFieldInput(sourceField, targetField) {
    if (!sourceField)
      return;
    // console.log("Source Field: ", sourceField.Name);
    if (targetField) {
      // console.log("Target Field: ", targetField.Name);
      if (sourceField.FieldRank === targetField.FieldRank)
        return;
    }

    let documentPackage = {...this.state.DocumentPackage};
    let sourceIndex = documentPackage.Fields.indexOf(sourceField);
    let targetIndex = documentPackage.Fields.indexOf(targetField);
    if (sourceIndex === targetIndex)
      return;

    //console.log("Index of source: ", sourceIndex, " Index of target: ", targetIndex);
    if (sourceIndex === null || targetIndex === null)
      return;

    documentPackage.Fields.splice(targetIndex, 1);
    documentPackage.Fields.splice(sourceIndex, 0, targetField);
    this.setState({ DocumentPackage: documentPackage });
  }

  handleAbortMoveFieldInput() {
    let documentPackage = {...this.state.DocumentPackage};
    documentPackage.Fields = JSON.parse(this.state.PreMoveFieldsJson)
    this.setState({ DocumentPackage: documentPackage });
  }

  handleDropFieldInput(field) {
    if (!field)
      return;
    this.reRankField(field);
  }

  reRankField(field) {
    let documentPackage = {...this.state.DocumentPackage};
    const {
      Collection: reRankedFields,
      StartIndex,
      EndIndex,
    } = ReRankItemInCollection(field, documentPackage.Fields, rankIncrement, "FieldRank", "FieldID");
    // console.log("PRE", JSON.parse(this.state.PreMoveFieldsJson))
    // console.log("POST", reRankedFields);
    documentPackage.Fields = reRankedFields;
    this.setState({ DocumentPackage: documentPackage});

    // Build array of changed items to send to server
    let preMoveFields = JSON.parse(this.state.PreMoveFieldsJson);
    let updatedFields = [];
    for (let i = StartIndex; i <= EndIndex; i++) {
      let field = reRankedFields[i];
      let filtered = preMoveFields.filter(p => p.FieldID === field.FieldID)
      if (filtered.length > 0) {
        let preMoveField = filtered[0];
        // console.log("original: ", preMoveField.FieldRank, " new: ", field.FieldRank);
        if (field.FieldRank !== preMoveField.FieldRank) {
          updatedFields.push(field);
        }
      }
    }

    // Send changed items to server
    const fieldsForServer = updatedFields.map(f => {
      return {...f, ID: f.FieldID, Rank: f.FieldRank };
    });
    HandleUpdateFields(this.OrganizationID, this.ProjectID, fieldsForServer, this.handleApiError);
  }

  handleSetFieldValidationAlertVisibility = visible => {
    this.setState({ ShowFieldValidationAlert: visible });
    return true;
  }

  handleSetShowNavigateToPageDialogVisibility = visible => {
    this.setState({ NavigateToPage_PageNumber: "", ShowNavigateToPageDialog: visible });
  }

  handleCloseFieldsByTagRecommendation = () => {
    this.setState({RecommendFieldsByTag:false});
    SaveSingleUserPreference_Bool("FieldsByTagRecommended", true);
  }

  handleSetAddFieldDialogVisibility = visible => {
    this.setState({
      ShowAddFieldDialog: visible,
      ShowDialogProgressIndicator: false,
    });
    this.setState({
      AddFieldNewFieldName: null,
    })
    return true;
  }

  handleAddFieldNewValue = e => {
    this.setState({
      AddFieldNewFieldName: e.target.value,
    });
  }

  handleAddFieldToCurrentProject = customizeNewField => {
    if (!this.state.AddFieldNewFieldName) {
      return;
    }
    this.setState({ShowDialogProgressIndicator: true});
    let actionFinalizer = () => {
      this.handleSetAddFieldDialogVisibility(false);
      this.setState({
        ShowDialogProgressIndicator: false,
      });
      // Load DocumentPackage to update displayed fields
      this.handleGetDocumentPackage(false, true, true);
    }
    let newField = {
      ...GetNewTextField(this.state.AddFieldNewFieldName),
      DocumentTags: this.state.DocumentPackage.Document.Tags,
    };
    API.post(GetFieldsPathForApi(this.OrganizationID, this.ProjectID),
      [newField])
      .then(resp => {
        newField = resp.data[0];
        actionFinalizer();
        if (customizeNewField) {
          this.handleSetShowFieldPropertiesDialog(true, newField);
        }
      })
      .catch(this.handleApiError);
  }

  handleSetShowFieldPropertiesDialog = (visible, field) => {
    this.setState({
      ShowFieldPropertiesDialog: visible,
      FieldToCustomize: field || {},
    });
    if (!visible) {
      // Load DocumentPackage to update displayed fields
      this.handleGetDocumentPackage(false, true, true);
    }
  }

  handleSetShowDocumentFolderDialog = visible => {
    this.handleActionMenuClose();
    this.setState({
      ShowDocumentFolderDialog: visible,
    });
  }

  getDocumentCommentsUri = () => {
    let uri = GetDocumentCommentsPathForApi(
      this.OrganizationID,
      this.ProjectID,
      this.DocumentID
    );
    if (this.UserAssignmentContext) {
      switch (this.UserAssignmentContext) {
        case "task":
          uri = GetUserOrganizationProjectTaskDocumentCommentsPathForApi(this.OrganizationID,
            this.ProjectID, this.TaskID, this.DocumentID);
          break;
        case "approval":
          uri = GetUserOrganizationProjectApprovalDocumentCommentsPathForApi(this.OrganizationID,
            this.ProjectID, this.ApprovalID, this.DocumentID);
          break;
        case "approvalTask":
          uri = GetUserOrganizationProjectApprovalTaskDocumentCommentsPathForApi(this.OrganizationID,
            this.ProjectID, this.ApprovalID, this.TaskID, this.DocumentID);
          break;
        case "approvalAssetItem":
          uri = GetUserOrganizationProjectApprovalAssetItemDocumentCommentsPathForApi(this.OrganizationID,
            this.ProjectID, this.ApprovalID, this.AssetID, this.AssetItemID, this.DocumentID);
          break;
        case "approvalAssetItemTask":
          uri = GetUserOrganizationProjectApprovalAssetItemTaskDocumentCommentsPathForApi(this.OrganizationID,
            this.ProjectID, this.ApprovalID, this.AssetID, this.AssetItemID, this.TaskID, this.DocumentID);
          break;
        case "documentFolder":
          uri = GetUserOrganizationProjectDocumentFolderDocumentCommentsPathForApi(this.OrganizationID,
            this.ProjectID, this.DocumentFolderID, this.DocumentID);
          break;
        default:
          break;
      }
    }
    return uri;
  }

  handleDownload = () => {
    this.handleActionMenuClose();
    this.setState({ShowProgressIndicatorImmediately: true});
    const uri = GetDocumentContentPackageUri(this.OrganizationID, this.ProjectID, this.DocumentID, this.props.usePublicApi,
      this.UserAssignmentContext, this.DocumentFolderID, this.ApprovalID, this.TaskID, this.AssetID, this.AssetItemID);
    GetExecuteDownloadPromise(uri)
      .then(() => {
        this.setState({ShowProgressIndicatorImmediately: false});
      })
      .catch(this.handleApiError);
  }

  getBasicApproval = () => {
    return {
      OrganizationID: this.OrganizationID,
      ProjectID: this.ProjectID,
      ID: this.ApprovalID,
    };
  }

  handleAction = (actionType, params) => {
    this.setState({ShowProgressIndicatorImmediately: true});
    let actionFinalizer = data => {
      let stateToUpdate = {
        ShowProgressIndicatorImmediately: false,
      };
      let documentPackage = {...this.state.DocumentPackage};
      switch (actionType) {
        case ActionType.Subscription_Create:
        case ActionType.Subscription_Delete:
          if (data) {
            documentPackage.DocumentSubscription = data[0];
          } else {
            documentPackage.DocumentSubscription = null;
          }
          stateToUpdate.DocumentPackage = documentPackage;
        break;
        case ActionType.SendToRecycleBin:
          if (data) {
            documentPackage.Document = data[0];
          }
          stateToUpdate.DocumentPackage = documentPackage;
        break;
        case ActionType.RestoreFromRecycleBin:
          if (data) {
            documentPackage.Document = data[0];
          }
          stateToUpdate.DocumentPackage = documentPackage;
        break;
        default:
        break;
      }
      this.setState(stateToUpdate);
    }
    const projectMemberPkgFinder = this.context.ProjectMembershipPackages.filter(p => p.Project.ID === this.ProjectID);
    const isProjectMember = projectMemberPkgFinder.length > 0;
    switch (actionType) {
      case ActionType.Approval_Approve:
        ApproveApprovals([this.getBasicApproval()], isProjectMember)
          .then(() => {
            actionFinalizer();
            this.tryExit();
          })
          .catch(this.handleApiError);
      break;
      case ActionType.Approval_Decline:
        DeclineApprovals([this.getBasicApproval()], isProjectMember)
          .then(() => {
            actionFinalizer();
            this.tryExit();
          })
          .catch(this.handleApiError);
      break;
      case ActionType.Approval_Deny:
        DenyApprovals([this.getBasicApproval()])
          .then(() => {
            actionFinalizer();
            this.tryExit();
          })
          .catch(this.handleApiError);
      break;
      case ActionType.Subscription_Create:
        CreateDocumentSubscriptions([this.state.DocumentPackage.Document])
          .then(data => {
            this.handleShowPopover("Subscribed");
            actionFinalizer(data);
          })
          .catch(this.handleApiError);
      break;
      case ActionType.Subscription_Delete:
      DeleteDocumentSubscriptions([this.state.DocumentPackage.Document])
          .then(data => {
            this.handleShowPopover("Unsubscribed");
            actionFinalizer(data);
          })
          .catch(this.handleApiError);
      break;
      case ActionType.SendToRecycleBin:
        SendDocumentsToRecycleBin([this.state.DocumentPackage.Document])
          .then(data => {
            this.handleShowPopover("Sent to recycle bin");
            this.context.SetReloadItemsFlag();
            actionFinalizer(data);
          })
          .catch(this.handleApiError);
      break;
      case ActionType.RestoreFromRecycleBin:
        RestoreDocumentsFromRecycleBin([this.state.DocumentPackage.Document])
          .then(data => {
            this.handleShowPopover("Restored from recycle bin");
            this.context.SetReloadItemsFlag();
            actionFinalizer(data);
          })
          .catch(this.handleApiError);
      break;
      case ActionType.SetDocumentFolder:
      this.handleSetShowDocumentFolderDialog(false);
        SetDocumentFolderForDocuments([this.state.DocumentPackage.Document], params.documentFolderId)
          .then(data => {
            this.handleShowPopover("Document moved");
            actionFinalizer();
          })
          .catch(this.handleApiError);
      break;
      default:
      break;
    }
  }

  handleSubscriptionChange = () => {
    this.handleActionMenuClose();
    this.handleAction(
      (this.state.DocumentPackage.DocumentSubscription && this.state.DocumentPackage.DocumentSubscription.Subscribed)
        ? ActionType.Subscription_Delete
        : ActionType.Subscription_Create
    );
  }

  handleRecycleBinChange = () => {
    this.handleActionMenuClose();
    this.handleAction(
      (this.state.DocumentPackage.Document.IsMarkedForPurge)
        ? ActionType.RestoreFromRecycleBin
        : ActionType.SendToRecycleBin
    );
  }

  handleSetCommentsVisibility = DocumentCommentsOpen => {
    SaveSingleUserPreference_Bool("DocumentCommentsOpen", DocumentCommentsOpen);
    this.setState({
      DocumentCommentsOpen,
      FocusOnNewComment:DocumentCommentsOpen,
    });
  }

  handleSetPropertiesVisibility = DocumentPropertiesOpen => {
    SaveSingleUserPreference_Bool("DocumentPropertiesClosed", !DocumentPropertiesOpen);
    this.setState({DocumentPropertiesOpen});
  }

  handleInvertSinglePagePreview = () => {
    const SinglePagePreview = !this.state.SinglePagePreview;
    this.handleActionMenuClose();
    this.setState({
      SinglePagePreview,
      ShowProgressIndicatorImmediately:true,
    });
    this.handleGetDocumentContentPackageForPreview(SinglePagePreview)
      .then(() => {
        if (!this.props.usePublicApi) {
          SaveSingleUserPreference_Bool("PDFPreview", !SinglePagePreview);
        }
        this.setState({ShowProgressIndicatorImmediately:false});
      });
  }

  handleSetShowHelpDialog = (ShowHelpDialog, HelpVideoFilename) => {
    this.handleHelpMenuClose();
    this.setState({
      ShowHelpDialog,
      HelpVideoFilename,
    });
  }

  handleApiError = (err, title_optional) => {
    this.setState({
      ApiError: err,
      ApiErrorTitle: title_optional,
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
      ShowDialogProgressIndicator: false,
    });
  }

  handleAlert = (Alert, persist) => {
    this.setState({Alert});
    if (!persist) {
      setTimeout(() => this.setState({Alert:null}), 1);
    }
  }

  loadDocument = useSinglePagePreview => {
    if (useSinglePagePreview === undefined) {
      useSinglePagePreview = this.state.SinglePagePreview;
    }
    this.handleGetDocumentPackage()
      .then(documentPackage => {
        if (documentPackage && documentPackage.Document.Origin !== "Editor") {
          this.handleGetDocumentContentPackageForPreview(useSinglePagePreview);
        }
      });
  }

  handleActionMenuOpen = event => {
    this.setState({ ActionMenuAnchorEl: event.currentTarget });
  }

  handleActionMenuClose = () => {
    this.setState({ ActionMenuAnchorEl: null });
  }

  handleHelpMenuOpen = event => {
    this.setState({ HelpMenuAnchorEl: event.currentTarget });
  }

  handleHelpMenuClose = () => {
    this.setState({ HelpMenuAnchorEl: null });
  }

  handleNavigateToPage_PageNumberValueChanged = e => {
    let value = "";
    if (e && e.target) {
      value = e.target.value;
    }
    this.setState({ NavigateToPage_PageNumber: value });      
  }

  handleShowPopover = text => {
    this.setState({ PopoverText: text });
    setTimeout(() => this.setState({ PopoverText: null }), 1500); 
  }

  handleImageContainerScrollEvent = e => {
    this.setState({
      ImageContainerScrollTop: e.target.scrollTop,
      ImageContainerScrollHeight: e.target.scrollHeight,
      ImageContainerClientHeight: e.target.clientHeight
    });
  }

  handleTabChange = (e, newValue) => {
    if (this.SetOtherAppBarContentFunc) {
      this.SetOtherAppBarContentFunc(null);
    }
    this.setState({SelectedTab: newValue});
  }

  setWindowWidth = () => {
    if (!IsMobile()) {
      // This will break mobile
      this.setState({WindowWidth: window.innerWidth});
    }
  }

  tryShowSignatureGatheringDialog = () => {
    if (!this.state.DocumentPackage) {
      return setTimeout(() => this.tryShowSignatureGatheringDialog(), 250);
    }
    if (this.getIsUserAssignmentOrPublicContextOrNoDocumentPackageOrProjectMember()) {
      return this.handleApiError("You cannot access signature gathering in this context.");
    }
    if (!this.state.DocumentPackage.Document.HasSinglePageImages) {
      return this.handleApiError("Signatures cannot be gathered for this document.");
    }
    if (this.state.DocumentPackage.Document.IsSigned) {
      return this.handleApiError("This document is already signed.");
    }
    this.handleManageSignatureSession();
  }

  componentDidMount() {
    this.setWindowWidth();
    window.addEventListener('resize', this.setWindowWidth);

    this.setState({ShowProgressIndicatorImmediately: true});

    if (this.props.usePublicApi) {
      this.loadDocument(true);
    } else {
      GetSingleUserPreference_Bool("DocumentPropertiesClosed")
        .then(closed => {
          this.setState({DocumentPropertiesOpen: !closed});
        })
        .catch(this.handleApiError);
      GetSingleUserPreference_Bool("DocumentCommentsOpen")
        .then(DocumentCommentsOpen => {
          this.setState({DocumentCommentsOpen});
        })
        .catch(this.handleApiError);
      GetSingleUserPreference_Bool("PDFPreview")
        .then(usePdfPreview => {
          this.setState({SinglePagePreview: !usePdfPreview});
          this.loadDocument(!usePdfPreview);
        })
        .catch(this.handleApiError);
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.usePublicApi
        && this.props.match.params.documentID !== prevProps.match.params.documentID) {
      this.loadDocument();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setWindowWidth);
  }

  getIsProjectAdmin = documentPackage => {
    if (!documentPackage) {
      documentPackage = this.state.DocumentPackage;
    }
    return (
      documentPackage
        && documentPackage.ProjectMember
        && documentPackage.ProjectMember.IsAdmin
    );
  }

  getIsUserAssignmentOrPublicContextOrNoDocumentPackageOrProjectMember = () => {
    return (
      this.props.usePublicApi
      || Boolean(this.UserAssignmentContext)
      || this.getNoDocumentPackageOrProjectMemberOrAssignment()
    );
  }

  getNoDocumentPackageOrProjectMemberOrAssignment = () => {
    let documentIsAssignedToUser = false;
    const projectMemberPkg = this.context.ProjectMembershipPackages.find(p => p.Project.ID === this.ProjectID);
    if (projectMemberPkg && projectMemberPkg.ProjectMember && this.state.DocumentPackage
      && this.state.DocumentPackage.Document && this.state.DocumentPackage.Document.AssignmentUserEmails) {
      documentIsAssignedToUser = this.state.DocumentPackage.Document.AssignmentUserEmails
        .includes(projectMemberPkg.ProjectMember.UserEmail);
    }

    return (
      !this.state.DocumentPackage
      || !this.state.DocumentPackage.ProjectMember
      || (!this.getIsProjectAdmin()
        && this.state.DocumentPackage.ProjectAccess.MemberAccess.DocumentFolders === "Assigned"
        && !documentIsAssignedToUser
        )
    );
  }

  handleTagListValuesChanged = tagListValues => {
    const {
      Tags,
      AssetItemTags,
    } = GetTagsAndAssetItemTagsFromTagListValues(tagListValues);
    const documentPackage = {...this.state.DocumentPackage};
    documentPackage.Document.Tags = Tags;
    documentPackage.Document.AssetItemTags = AssetItemTags;
    this.handleUpdateDocument(documentPackage);
  }
  
  render() {
  	const { 
      WindowWidth,
      ApiError,
      ApiErrorTitle,
      Alert,
      ShowProgressIndicator,
      ShowProgressIndicatorImmediately,
      ShowDialogProgressIndicator,
      DocumentPackage,
      DocumentContentPackage,
      FieldsConfigureDialogIsOpen,
      RelatedDocumentsDialogIsOpen,
      DocumentEventsDialogIsOpen,
      TaskDialogIsOpen,
      DocumentSignatureSessionPackage,
      DocumentSignatureSessionRecipientsDialogIsOpen,
      DocumentSignatureSessionDesignTypeDialogIsOpen,
      DocumentSignatureSessionFormDesignerDialogIsOpen,
      DocumentSignatureSessionFinalDialogIsOpen,
      DocumentSignatureSessionReviewDialogIsOpen,
      SignatureDialogsState,
      ShowFieldValidationAlert,
      ShowNavigateToPageDialog,
      ActionMenuAnchorEl,
      HelpMenuAnchorEl,
      PopoverText,
      NavigateToPage_PageNumber,
      ShowAddFieldDialog,
      AddFieldNewFieldName,
      ShowFieldPropertiesDialog,
      FieldToCustomize,
      FocusOnNewComment,
      // ImageContainerScrollTop,
      // ImageContainerScrollHeight,
      // ImageContainerClientHeight,
      DocumentNameChangeConfirmationDialogIsOpen,
      SelectedTab,
      ShowDocumentFolderDialog,
      DocumentPropertiesOpen,
      DocumentCommentsOpen,
      SinglePagePreview,
      ShowHelpDialog,
      HelpVideoFilename,
      TagListValues,
      RecommendFieldsByTag,
  	} = this.state;
    const {
      location,
      history,
      classes,
      theme,
      usePublicApi,
    } = this.props;
    const {
      organizationID,
      projectID,
      documentID,
    } = this.props.match.params;

    let isDocumentEditorContext = DocumentPackage && DocumentPackage.Document.Origin === "Editor";
    let dcp = DocumentContentPackage;
    const isProjectAdmin = this.getIsProjectAdmin();
    const isUserAssignmentOrPublicContextOrNoDocumentPackageOrProjectMember
      = this.getIsUserAssignmentOrPublicContextOrNoDocumentPackageOrProjectMember();
    const isReadOnly = (
         Boolean(this.ApprovalID)
      || !DocumentPackage
      || DocumentPackage.Document.IsSigned
      || DocumentPackage.Document.SignatureSessionIsActive
      || isUserAssignmentOrPublicContextOrNoDocumentPackageOrProjectMember
      || (DocumentSignatureSessionPackage && DocumentSignatureSessionPackage.Session.IsActive)
    );

    let pageTitle = (DocumentPackage)
      ? DocumentPackage.Document.Name
      : "Loading document...";

    const useSinglePagePreview = IsMobile() || SinglePagePreview;
    // Actions for all users
    let allUserActions = [];
    const previewModeMenuItems = (!IsMobile() && DocumentPackage && DocumentPackage.Document.Origin !== "Editor")
      ? [
        <MenuItem key="action_singlePagePreview" onClick={this.handleInvertSinglePagePreview}>
          <ListItemIcon>
            <ImageIcon />
          </ListItemIcon>
          {(SinglePagePreview) 
            ? "PDF preview" 
            : "Single-page preview"
          }
        </MenuItem>,
        <Divider />
      ] : [];
    if (previewModeMenuItems.length) {
      allUserActions.push(
        previewModeMenuItems
      );  
    }
    if (!isUserAssignmentOrPublicContextOrNoDocumentPackageOrProjectMember) {
      allUserActions.push(
        <CopyToClipboard
          key="ctc_putUrlInClipboard"
          text={window.location.href.replace(window.location.search, "")}
          onCopy={() => this.handleShowPopover("Document URL copied")}
        >
          <MenuItem key="action_putUrlInClipboard" onClick={this.handleActionMenuClose}>
            <ListItemIcon>
              <CopyUrlIcon />
            </ListItemIcon>
            Copy URL
          </MenuItem>
        </CopyToClipboard>,
        <MenuItem key="action_documentEvents" onClick={this.handleOpenDocumentEventsDialog}>
          <ListItemIcon>
            <CalendarIcon />
          </ListItemIcon>
          Document events
        </MenuItem>,
      );
    }
    if (DocumentPackage && DocumentPackage.Document.Origin !== "Editor") {
      allUserActions.push(
        <MenuItem key="action_download" onClick={this.handleDownload}>
          <ListItemIcon>
            <DownloadIcon />
          </ListItemIcon>
          Download original
        </MenuItem>
      );
    }
    if (!this.UserAssignmentContext
      && DocumentPackage && DocumentPackage.Document.GroupID) {
      allUserActions.push(
        <MenuItem key="action_relatedDocuments" onClick={this.handleOpenRelatedDocumentsDialog}>
          <ListItemIcon>
            <RelatedIcon />
          </ListItemIcon>
          Related documents
        </MenuItem>
      )
    }
    const allowSignatureGathering = 
      !isUserAssignmentOrPublicContextOrNoDocumentPackageOrProjectMember
      && DocumentPackage
      && DocumentPackage.Document.HasSinglePageImages
      && !DocumentPackage.Document.IsSigned;
    if (allowSignatureGathering) {
      allUserActions.push(
        <MenuItem key="action_signatures" onClick={() => this.handleManageSignatureSession()}>
          <ListItemIcon>
            <SignaturesIcon />
          </ListItemIcon>
          Signature gathering
        </MenuItem>
      );
    }
    if (!isUserAssignmentOrPublicContextOrNoDocumentPackageOrProjectMember) {
      allUserActions.push(
        <MenuItem key="action_setDocumentFolder" onClick={() => this.handleSetShowDocumentFolderDialog(true)}>
          <ListItemIcon>
            <FolderMoveIcon />
          </ListItemIcon>
          {ActionType.SetDocumentFolder.Label}
        </MenuItem>,
        <MenuItem key="action_task" onClick={this.handleOpenTaskDialog}>
           <ListItemIcon>
            <TasksIcon />
          </ListItemIcon>
          Start task
        </MenuItem>,
      ); 
    }
    if (!isUserAssignmentOrPublicContextOrNoDocumentPackageOrProjectMember && DocumentPackage/* && !isDocumentEditorContext*/) {
      allUserActions.push(
        <MenuItem key="action_subscription" onClick={this.handleSubscriptionChange}>
          <ListItemIcon>
            <SubscribeIcon />
          </ListItemIcon>
          {(!DocumentPackage.DocumentSubscription || !DocumentPackage.DocumentSubscription.Subscribed)
            ? "Subscribe to changes"
            : "Unsubscribe"
          }
        </MenuItem>
      );
    }
    if (!isUserAssignmentOrPublicContextOrNoDocumentPackageOrProjectMember) {
      allUserActions.push(
        <MenuItem key="action_recycleBin" onClick={this.handleRecycleBinChange}>
          <ListItemIcon>
          {(DocumentPackage && DocumentPackage.Document.IsMarkedForPurge)
            ? <RestoreFromRecycleBinIcon />
            : <RecycleBinIcon />
          }
          </ListItemIcon>
          {(DocumentPackage && DocumentPackage.Document.IsMarkedForPurge)
            ? "Restore from recycle bin"
            : "Send to recycle bin"
          }
        </MenuItem>,
      );
    }
    // Actions for administrators
    let adminActions = [];
    if (isProjectAdmin) {
      if (allUserActions && allUserActions.length) {
        adminActions.push(
          <Divider key="action_adminDivider" className={classes.menuDivider} />
        );
      }
      adminActions.push(
        <MenuItem key="action_fields" onClick={this.handleOpenConfigureFieldsDialog}>
          <ListItemIcon>
            <FieldsIcon />
          </ListItemIcon>
          Manage fields
        </MenuItem>,
      );
    }
    
    const tagsControl = (DocumentPackage)
      ? GetTagsControl(organizationID, projectID, null, 
          false, false, null, true,
          TagListValues, TagListValues => this.setState({TagListValues}),
          this.handleTagListValuesChanged, isReadOnly,
          (!DocumentPropertiesOpen) ? -1 : undefined, null, true)
      : null;
    const tags = (
      <React.Fragment>
      <div className={classes.tags}>
        {tagsControl}
      </div>
      <Divider style={{marginBottom:theme.spacing(3)}} />
      </React.Fragment>
    );

    let addOrMapFieldDialogDetails = {
      Open:ShowAddFieldDialog,
      ShowProgressIndicator:ShowDialogProgressIndicator,
      Title:"Add new field",
      IsConfirmation: true,
      DialogWidth:"xs",
      FullWidth:true,
      BodyContent: (
        <TextField
          autoFocus
          fullWidth
          variant="outlined"
          label="Name"
          value={AddFieldNewFieldName}
          onChange={this.handleAddFieldNewValue}
          onKeyDown={e => { if (AddFieldNewFieldName && e.keyCode === 13) { this.handleAddFieldToCurrentProject(false); } }}
        />
      ),
      CancelCallback:() => this.handleSetAddFieldDialogVisibility(false),
      CloseCallback:() => this.handleSetAddFieldDialogVisibility(false),
      ConfirmLabel: "ADD",
      ConfirmCallback:(AddFieldNewFieldName)
        ? () => this.handleAddFieldToCurrentProject(false) : undefined,
      AdditionalActions: (AddFieldNewFieldName)
        ? (
          <Tooltip title="Add field and customize its settings">
              <Button onClick={() => this.handleAddFieldToCurrentProject(true)}>
              ADD & CUSTOMIZE
            </Button>
          </Tooltip>
        ) : undefined,
    };

    let approval_denyButton = (isProjectAdmin)
      ? (
        <Grid item xs={3}>
          <Button key="denyButton" variant="contained"
            className={classNames(classes.approvalButton, classes.denyButton)}
            fullWidth
            size="large"
            onClick={() => this.handleAction(ActionType.Approval_Deny)}>
            DENY
          </Button>
        </Grid>
      ) : null;

    let fieldGridItems = [];
    if (DocumentPackage) {
      if (DocumentPackage.Fields) {
        DocumentPackage.Fields
          .filter(f => this.shouldShowField(f))
          .forEach((f, index) => {
          if (!isReadOnly || GetFieldHasValueToDisplay(f)) {
            const parentField = GetDependentFieldsParentField(f, DocumentPackage.Fields, f => f.FieldID);
            const parentFieldLacksValue = GetEffectiveSelectionListIsDependent(f) && parentField && !parentField.Value;
            fieldGridItems.push(
              <GridWithConditionalRendering item key={f.FieldID} className={classes.fieldGridItem}
                shouldRender={FieldInput.ShouldRender(f, DocumentPackage.Fields)}
              >
                <FieldInput
                  organizationId={organizationID}
                  projectId={projectID}
                  onApiError={this.handleApiError}
                  onAlert={alert => this.handleAlert(alert, true)}
                  Field={f}
                  fields={DocumentPackage.Fields}
                  isReadOnly={isReadOnly}
                  key={f.FieldID}
                  Index={!DocumentCommentsOpen 
                    && DocumentPropertiesOpen 
                    && index}
                  padRightMarginWhenDragAndDropDisabled
                  tabIndex={(!DocumentPropertiesOpen) ? -1 : undefined}
                  EnableDragAndDropReordering={isProjectAdmin}
                  onStartMoveFieldInput={() => this.handleStartMoveFieldInput()}
                  onMoveFieldInput={fieldTarget => this.handleMoveFieldInput(f, fieldTarget)}
                  onAbortMoveFieldInput={() => this.handleAbortMoveFieldInput()}
                  onDropFieldInput={() => this.handleDropFieldInput(f)}
                  onValueChange={this.handleFieldValueChange(f.FieldID)}
                  onImageChange={this.handleFieldImageChange(f.FieldID)}
                  fieldImageUploadReservationUri={GetDocumentFieldImageUploadsPathForApi(
                    organizationID, projectID, documentID, f.FieldID,
                  )}
                  onGetSelectionListFilterPromise={
                    (() => {
                      const parentFieldValue = GetDependentFieldsParentValue(f, DocumentPackage.Fields, f => f.FieldID);
                      return HandleGetFieldListItemsFilterPromise(organizationID, projectID, f.FieldID, this.handleApiError, undefined, undefined, undefined, parentFieldValue);
                    })()
                  }
                  onSelectionListItemAdd={HandleFieldListItemAdd(organizationID, projectID, f.FieldID, f, this.handleFieldValueChange, this.handleApiError)}
                  disabled={parentField && parentFieldLacksValue}
                  parentFieldLacksValue={parentFieldLacksValue}
                  parentFieldName={GetEffectiveFieldLabelOrName(parentField, false)}
                />
              </GridWithConditionalRendering>
            );
          }
        });
      }
    }
    const fieldsGrid = (fieldGridItems.length)
      ? (
        <Grid container spacing={3} direction="column" className={classes.fieldGrid}>
          {fieldGridItems}
        </Grid>
      ) : null;

    let approvalButtonGrid = [];
    let fieldButtons = null;
    if ((this.AllowApproval || this.UserAssignmentContext === "approval") && this.ApprovalID) {
      approvalButtonGrid.push(
        <Grid container key="approvalButtonGrid" spacing={2} 
          className={classes.approvalButtonGrid}
        >
          <Grid item xs={(approval_denyButton) ? 5 : 6}>
            <Button key="approveButton" variant="contained"
              className={classNames(classes.approvalButton, classes.approveButton)}
              fullWidth
              size="large"
              onClick={() => this.handleAction(ActionType.Approval_Approve)}>
              APPROVE
            </Button>
          </Grid>
          <Grid item xs={(approval_denyButton) ? 4 : 6}>
            <Button key="declineButton" variant="contained"
              className={classNames(classes.approvalButton, classes.declineButton)}
              fullWidth
              size="large"
              onClick={() => this.handleAction(ActionType.Approval_Decline)}>
              DECLINE
            </Button>
          </Grid>
          {approval_denyButton}
        </Grid>
      );
    } else if (DocumentPackage && !isReadOnly) {
      const addFieldButtonContent = (isProjectAdmin)
        ? (
          <IconButton
            className={classes.addFieldButton}
            tabIndex={(!DocumentPropertiesOpen) ? -1 : undefined}
            onClick={() => this.handleSetAddFieldDialogVisibility(true)}
          >
            <AddIcon />
          </IconButton>
        ) : null;
      const addFieldButton = (addFieldButtonContent)
        ? (useSinglePagePreview)
          ? (
            <Tooltip title="Add field">
              {addFieldButtonContent}
            </Tooltip>
          )
          : addFieldButtonContent
        : null;
      const commitButton = (fieldGridItems && fieldGridItems.length)
        ? (
          <Button key="commitButton" variant="contained"
            style={{flexGrow:1}}
            color={theme.palette.type === "dark" ? "default" : "secondary"}
            fullWidth
            size="large"
            tabIndex={(!DocumentPropertiesOpen) ? -1 : undefined}
            onClick={() => this.handleCommit()}>
            COMMIT FIELDS
          </Button>
        ) : null;
      fieldButtons = (
        <div key="buttonContainer" className={classes.buttonContainer}>
          {commitButton}
          {addFieldButton}
        </div>
      );
    }

    let fieldValidationDialogDetails = {
      Open:ShowFieldValidationAlert,
      Title:"Field validation",
      BodyText:"Please correct validation issues.",
      CloseCallback:() => this.handleSetFieldValidationAlertVisibility(false),
    };

    const fieldsByTagRecommendationDialogDetails = {
      Open:RecommendFieldsByTag && DocumentPropertiesOpen,
      Title:"Fields by tag",
      BodyText:`To limit how many fields are displayed, consider adding "Document tags" in field properties and tagging documents accordingly.`,
      CloseCallback:() => this.handleCloseFieldsByTagRecommendation(),
    };

    let approvalProcessInfo = (DocumentPackage && DocumentPackage.Approval)
      ? (
        <Grid container direction="column" spacing={2}
          style={{
            marginTop: (approvalButtonGrid.length) ? theme.spacing(2) : undefined,
            marginBottom: theme.spacing(2),
            paddingLeft:theme.spacing(3),
            paddingRight:theme.spacing(3),
            width:"100%", // Removes horizontal scrollbar
          }}
        >
          <Grid item xs={12}>
            <div>
              <Typography variant="h6">
                Assigned to
              </Typography>
            </div>
            <div>
              <Typography variant="body1">
                {GetUserValue(DocumentPackage.Approval.AssignmentUserEmail,DocumentPackage.Approval.AssignmentUserName)}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <Typography variant="h6">
                Process
              </Typography>
            </div>
            <div>
              <Typography variant="body1">
                {DocumentPackage.Approval.ProcessName}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <Typography variant="h6">
                Stage
              </Typography>
            </div>
            <div>
              <Typography variant="body1">
                {DocumentPackage.Approval.ProcessElementName}
              </Typography>
            </div>
          </Grid>
        </Grid>
      ) : null;

    const column2Visible = (DocumentPropertiesOpen || this.ApprovalID);
    let column2VisibilityClass = (column2Visible)
      ? classes.column2_visible
      : classes.column2_hidden;
    let column2content = (
      <div className={classes.column2Inner}>
        {tags}
        {approvalButtonGrid}
        {approvalProcessInfo}
        {fieldsGrid}
        {fieldButtons}
      </div>
    );

    const column3Visible = DocumentCommentsOpen;
    let column3VisibilityClass = (column3Visible)
      ? classes.column3_visible
      : classes.column3_hidden;
    let column3content = (!usePublicApi)
      ? (
        <Comments
          organizationId={organizationID}
          projectId={projectID}
          commentsUri={this.getDocumentCommentsUri()}
          collectionName="DocumentEvents"
          autoFocus={DocumentCommentsOpen}
          focusOnNewComment={FocusOnNewComment}
          newCommentAdditionalProperties={{DocumentID: documentID}}
          onApiError={this.handleApiError}
          // newCommentBottomFixed={!IsMobile()}
        />
      ) : null;

    let documentEditorHostWidth = null;
    if (WindowWidth) {
      documentEditorHostWidth = WindowWidth;
      if (column2Visible) {
        documentEditorHostWidth -= column2Width;
      }
      if (column3Visible) {
        documentEditorHostWidth -= column3Width;
      }
    }

   const toolHeaderButtonClass = (theme.palette.type === "light"
      && (DocumentPackage && DocumentPackage.Document.Origin !== "Editor"))
        ? classes.toolHeaderButtonDark
        : classes.toolHeaderButtonLight;

    let showPropertiesButtonText = (DocumentPropertiesOpen)
      ? "Hide tags & properties"
      : "Show tags & properties";
    let showPropertiesButton = (
      <IconButton
        // style={{ color: (DocumentPropertiesOpen) ? theme.palette.primary.main : undefined }}
        aria-label={showPropertiesButtonText}
        onClick={() => this.handleSetPropertiesVisibility(!DocumentPropertiesOpen)}
      >
        <ShowPropertiesIcon className={toolHeaderButtonClass} />
      </IconButton>
    );
    let showPropertiesComponent = (!this.ApprovalID)
      ? (useSinglePagePreview)
        ? (
          <Tooltip title={showPropertiesButtonText}>
            {showPropertiesButton}
          </Tooltip>
        ) : showPropertiesButton
      : null;

    let showCommentsButtonText = (DocumentCommentsOpen) ? "Hide comments" : "Show comments";
    let showCommentsButton = (
      <IconButton
        // style={{ color: (DocumentCommentsOpen) ? theme.palette.primary.main : undefined }}
        aria-label={showCommentsButtonText}
        onClick={() => this.handleSetCommentsVisibility(!DocumentCommentsOpen)}
      >
        <ShowCommentsIcon className={toolHeaderButtonClass} />
      </IconButton>
    );
    let showCommentsComponent = (useSinglePagePreview)
      ? (
        <Tooltip title={showCommentsButtonText}>
          {showCommentsButton}
        </Tooltip>
      ) : showCommentsButton;

    const actionMenuOpen = Boolean(ActionMenuAnchorEl);
    const actionMenu = (allUserActions.length || adminActions.length)
      ? (
        <div
          // style={{ marginLeft: theme.spacing(2), marginRight: -theme.spacing(1)}}
          >
          {/* Tooltip causes a PDF-viewer-wiggle-on-tooltip-hover issue */}
          {/*<Tooltip title="Settings">*/}
            <IconButton
              aria-label="Settings"
              aria-controls="settings-menu"
              aria-haspopup="true"
              onClick={this.handleActionMenuOpen}
            >
              <MoreVertIcon className={toolHeaderButtonClass} />
            </IconButton>
          {/*</Tooltip>*/}
          <Menu
            id="settings-menu"
            anchorEl={ActionMenuAnchorEl}
            keepMounted
            open={actionMenuOpen}
            onClose={this.handleActionMenuClose}
          >
            {allUserActions}
            {adminActions}
          </Menu>
        </div>
      ) : null;

    // Actions for all users
    let allHelpActions = [];
    if (!IsMobile() && !usePublicApi) {
      allHelpActions.push(
        <MenuItem key="helpAction_signatures" onClick={() => this.handleSetShowHelpDialog(true, "N1 IP Signatures.mp4")}>
          <ListItemIcon>
            <SignaturesIcon />
          </ListItemIcon>
          Signature gathering
        </MenuItem>
      );
    }

    const helpMenuOpen = Boolean(HelpMenuAnchorEl);
    const helpMenu = (allHelpActions.length)
      ? (
        <div>
          <IconButton
            aria-label="Get help"
            aria-controls="help-menu"
            aria-haspopup="true"
            onClick={this.handleHelpMenuOpen}
          >
            <HelpIcon className={toolHeaderButtonClass} />
          </IconButton>
          <Menu
            id="help-menu"
            anchorEl={HelpMenuAnchorEl}
            keepMounted
            open={helpMenuOpen}
            onClose={this.handleHelpMenuClose}
          >
            {allHelpActions}
          </Menu>
        </div>
      ) : null;

      const helpDialog = (ShowHelpDialog)
      ? (
        <VideoHelpDialog
          open={ShowHelpDialog}
          src={GetPublicVideoPath(HelpVideoFilename)}
          onClose={() => this.handleSetShowHelpDialog(false)}
        />
      ) : null;

    const desktopTools = (!usePublicApi)
      ? (
        <div className={classes.desktopTools}>
          {showPropertiesComponent}
          {showCommentsComponent}
        </div>
      ) : null;

    const toolHeaderButtons = (
      <React.Fragment>
        {helpMenu}
        {helpDialog}
        {desktopTools}
        {actionMenu}
      </React.Fragment>
    );

    const extraContentForAppBarEnd = (IsMobile())
      ? (isDocumentEditorContext)
        ? actionMenu
        : null
      : (isDocumentEditorContext)
        ? toolHeaderButtons
        : null;

    let documentVisualContent = null;
    if (dcp) {
      switch (dcp.ContentType) {
        case "application/pdf":
        case "text/plain":
        case "application/xml":
          documentVisualContent = (<object data={dcp.Url} className={classes.object}> </object>);
        break;
        case "image/jpeg":
        case "image/gif":
        case "image/png":
        case "image/svg+xml":
          documentVisualContent = (
            <div id="divImageContainer" className={classes.imageContainer}
              ref={instance => this.ImageContainerRef = instance}
              onScroll={this.handleImageContainerScrollEvent}
            >
              <img src={dcp.Url} className={classes.image} alt="" />
            </div>
          );
        break;
        default:
          documentVisualContent = (
            <div className={classes.downloadContainer}>
              <Button variant="contained" 
                color="secondary" 
                onClick={this.handleDownload}>
                DOWNLOAD   
              </Button>
            </div>
          );
        break;
      }
    }
    if (isDocumentEditorContext) {
      documentVisualContent = (
        <div className={classes.editorContainer}>
          <DocumentEditorHost
            hostWidth={documentEditorHostWidth}
            organizationId={organizationID}
            projectId={projectID}
            userAssignmentContext={this.UserAssignmentContext}
            documentFolderId={this.DocumentFolderID}
            approvalId={this.ApprovalID}
            taskId={this.TaskID}
            documentPackage={DocumentPackage}
            onApiError={this.handleApiError}
            onUpdateDocument={this.handleUpdateDocument}
            onSetOtherAppBarContent={this.handleSetOtherAppBarContent}
          />
        </div>
      );
    }

    let navigateFirstGridItem = 
      (useSinglePagePreview && dcp && dcp.PageCount && dcp.PageCount > 2 && dcp.PageIndex > 0)
      ? (
        <Grid item>
          <Tooltip title="First page">
            <IconButton
              className={classes.navigateButton}
              onClick={() => this.handleGoToContentPage(0)}
            >
              <NavigateFirstIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )
      : null;
    let navigatePrevGridItem = 
      (useSinglePagePreview && dcp && dcp.PageCount && dcp.PageIndex > 0)
      ? (
        <Grid item>
          <Tooltip title="Previous page">
            <IconButton
              className={classes.navigateButton}
              onClick={() => this.handleGoToContentPage(dcp.PageIndex - 1)}
            >
              <NavigatePrevIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )
      : null;
    let navigateNextGridItem =
      (useSinglePagePreview && dcp && dcp.PageCount && dcp.PageIndex < dcp.PageCount - 1)
      ? (
        <Grid item>
          <Tooltip title="Next page">
            <IconButton
              className={classes.navigateButton}
              onClick={() => this.handleGoToContentPage(dcp.PageIndex + 1)}
            >
              <NavigateNextIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )
      : null;
    let navigateLastGridItem =
      (useSinglePagePreview && dcp && dcp.PageCount && dcp.PageCount > 2 && dcp.PageIndex < dcp.PageCount - 1)
      ? (
        <Grid item>
          <Tooltip title="Last page">
            <IconButton
              className={classes.navigateButton}
              onClick={() => this.handleGoToContentPage(dcp.PageCount - 1)}
            >
              <NavigateLastIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )
      : null;
    let navigateToPageGridItem =
      (useSinglePagePreview && dcp && dcp.PageCount && dcp.PageCount > 2)
      ? (
        <Grid item>
          <Tooltip title="Go to page">
            <IconButton
              className={classes.navigateButton}
              onClick={() => this.handleSetShowNavigateToPageDialogVisibility(true)}
            >
              <NavigateToPageIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )
      : null;
    
    let navigateLeftSide =
      (navigateFirstGridItem || navigatePrevGridItem)
      ? (
        <Grid container direction="column" spacing={2} className={classes.navigateLeftSide}>
          {navigateFirstGridItem}
          {navigatePrevGridItem}
        </Grid>
      )
      : null;
    let navigateRightSide =
      (navigateLastGridItem || navigateNextGridItem || navigateToPageGridItem)
      ? (
        <Grid container direction="column" spacing={2} className={classes.navigateRightSide}>
          {navigateLastGridItem}
          {navigateNextGridItem}
          {navigateToPageGridItem}
        </Grid>
      )
      : null;

    let navigateToPageDialogDetails = {
      Open:ShowNavigateToPageDialog,
      Title:`Go to page (1-${(dcp && dcp.PageCount)})`,
      DialogWidth: "xs",
      BodyContent: (
        <NumericTextField
          id="navigateToPage_pageNumber"
          label="Page number"
          autoFocus
          value={NavigateToPage_PageNumber}
          onValueChange={this.handleNavigateToPage_PageNumberValueChanged}
          hideClearButton
          onEnterKey={this.handleGoToContentPage}
         />
      ),
      CloseCallback:() => this.handleSetShowNavigateToPageDialogVisibility(false),
    };

    let documentNameChangeConfirmationDialogDetails = (DocumentPackage)
      ? {
        Open: DocumentNameChangeConfirmationDialogIsOpen,
        Title: "Change document name",
        DialogWidth: "sm",
        FullWidth: true,
        RequireTextInput1: true,
        TextInput1Label: "Document Name",
        TextInput1DefaultValue: DocumentPackage.Document.Name,
        TextInput1SelectAllOnFocus: true,
        ConfirmLabel: "GO",
        ConfirmCallback: this.handleUpdateDocumentName,
        CloseCallback: () => this.handleSetDocumentNameChangeConfirmationDialogIsOpen(false),
        CancelCallback: ()=> this.handleSetDocumentNameChangeConfirmationDialogIsOpen(false),
      } : { Open: false };

    let createdOn = DocumentPackage && GetDateValue(DocumentPackage.Document.CreatedOn);
    let fileSizePageCount = DocumentPackage && (GetFileSizePageCountSpan(DocumentPackage.Document));
    let singlePagePreview_CurrentPageNumber = (useSinglePagePreview && dcp && dcp.PageCount)
      ? (<Typography
          variant="body2"
          className={classes.headerItem}
          style={{
            display: (IsMobile()) ? "block" : undefined,
          }}
        >
          {`Viewing page ${1 + dcp.PageIndex}`}
        </Typography>)
      : null;
    const signatureHeader = (!usePublicApi && DocumentPackage && DocumentPackage.Document.IsSigned)
      ? (
        <div className={classes.ribbon} style={{backgroundColor:green[200]}}>
          <SignedIcon style={{marginRight:theme.spacing(3.5),color:"#222"}} />
          <Typography variant="body2" className={classes.headerItem} style={{color:"#222"}}>
            {`Signed on ${GetDateValue(DocumentPackage.Document.SignaturesCompletedOn)}`}
          </Typography>
        </div>
      ) : null;
    const signaturesPendingHeader = (!usePublicApi && !usePublicApi && DocumentPackage && DocumentPackage.Document.SignatureSessionIsActive)
      ? (
        <div className={classes.ribbon} style={{backgroundColor:amber[200]}}>
          <PendingSignaturesIcon style={{marginRight:theme.spacing(3.5),color:"#222"}} />
          <Typography variant="body2" className={classes.headerItem} style={{color:"#222"}}>
            {`Signature requested`}
          </Typography>
        </div>
      ) : null;
    const recycleBinHeader = (!usePublicApi && DocumentPackage && DocumentPackage.Document.IsMarkedForPurge)
      ? (
        <div className={classes.ribbon} style={{backgroundColor:red[200]}}>
          <RecycleBinIcon style={{marginRight:theme.spacing(3.5),color:"#222"}} />
          <Typography variant="body2" className={classes.headerItem} style={{color:"#222"}}>
            {`To be purged on ${GetDateValue(DocumentPackage.Document.PurgeDate)}`}
          </Typography>
        </div>
      ) : null;

    const toolHeader = (DocumentPackage && !isDocumentEditorContext)
      ? (
        <div className={classes.toolHeader}>
          <Grid container spacing={3} className={classes.toolInfo}>
            <Grid item>
              <Typography variant="body2" color="textSecondary" className={classes.headerItem}>
                {createdOn}
              </Typography>
              <Typography variant="body2" color="textSecondary" className={classes.headerItem}>
                {fileSizePageCount}
              </Typography>
              {singlePagePreview_CurrentPageNumber}
            </Grid>
          </Grid>
          {toolHeaderButtons}
        </div>
      ) : null;

    let configureFieldsDialog = (FieldsConfigureDialogIsOpen)
      ? (
        <ConfigureFieldsDialog 
          organizationId={organizationID}
          projectId={projectID}
          open={FieldsConfigureDialogIsOpen}
          onApiError={this.handleApiError}
          closeCallback={this.handleCloseConfigureFieldsDialog} />
      ) : null;

    let relatedDocumentsDialog = (DocumentPackage && DocumentPackage.Document.GroupID
      && RelatedDocumentsDialogIsOpen)
      ? (
        <RelatedDocumentsDialog
          location={location}
          history={history}
          usePublicApi={usePublicApi}
          onRouteToDocument={(usePublicApi) ? this.handleRouteToRelatedDocumentForPublicContext : undefined}
          organizationId={organizationID}
          projectId={projectID}
          isUserAssignmentContextByDocumentFolder={
            (this.UserAssignmentContext === "documentFolder" && Boolean(this.DocumentFolderID))
            || false
          }
          documentFolderId={this.DocumentFolderID}
          sourceDocumentId={this.DocumentID}
          documentGroupId={DocumentPackage.Document.GroupID}
          open={RelatedDocumentsDialogIsOpen}
          onApiError={this.handleApiError}
          onClose={this.handleCloseRelatedDocumentsDialog}
        />
      ) : null;

    let documentEventsDialog = (DocumentEventsDialogIsOpen)
      ? (
        <DocumentEventsDialog
          organizationId={organizationID}
          projectId={projectID}
          open={DocumentEventsDialogIsOpen}
          documentId={documentID}
          onApiError={this.handleApiError}
          closeCallback={this.handleCloseDocumentEventsDialog} />
      ) : null;

    let signatureDialogs = (DocumentPackage && DocumentSignatureSessionPackage)
      ? (
        <React.Fragment>
        
        <DocumentSignatureSessionRecipientsDialog
          organizationId={organizationID}
          projectId={projectID}
          open={DocumentSignatureSessionRecipientsDialogIsOpen}
          recipients={DocumentSignatureSessionPackage.Recipients}
          documentId={documentID}
          onApiError={this.handleApiError}
          closeCallback={this.handleCloseDocumentSignatureSessionRecipientsDialog} />

        <DocumentSignatureSessionDesignTypeDialog
          organizationId={organizationID}
          projectId={projectID}
          open={DocumentSignatureSessionDesignTypeDialogIsOpen}
          session={DocumentSignatureSessionPackage.Session}
          documentId={documentID}
          onApiError={this.handleApiError}
          closeCallback={this.handleCloseDocumentSignatureSessionDesignTypeDialog} />

        <DocumentSignatureSessionFormDesignerDialog
          organizationId={organizationID}
          projectId={projectID}
          open={DocumentSignatureSessionFormDesignerDialogIsOpen}
          recipients={DocumentSignatureSessionPackage.Recipients}
          documentId={documentID}
          documentPageCount={DocumentPackage.Document.PageCount}
          onApiError={this.handleApiError}
          closeCallback={this.handleCloseDocumentSignatureSessionFormDesignerDialog} />

        <DocumentSignatureSessionFinalDialog
          organizationId={organizationID}
          projectId={projectID}
          open={DocumentSignatureSessionFinalDialogIsOpen}
          session={DocumentSignatureSessionPackage.Session}
          formDesignType={(SignatureDialogsState && SignatureDialogsState.DesignType) ? SignatureDialogsState.DesignType.FormDesignType : ""}
          documentId={documentID}
          documentName={DocumentPackage && DocumentPackage.Document.Name}
          onApiError={this.handleApiError}
          closeCallback={this.handleCloseDocumentSignatureSessionFinalDialog} />

        <DocumentSignatureSessionReviewDialog
          organizationId={organizationID}
          projectId={projectID}
          open={DocumentSignatureSessionReviewDialogIsOpen}
          documentSignatureSessionPackage={DocumentSignatureSessionPackage}
          onReset={this.handleResetSignatureSession}
          onApiError={this.handleApiError}
          closeCallback={() => this.handleSetDocumentSignatureSessionReviewDialogVisibility(false)} />

        </React.Fragment>
      ) : null;

    let taskDialog = (DocumentPackage && !usePublicApi)
      ? (
        <TaskDialog
          organizationId={organizationID}
          projectId={projectID}
          open={TaskDialogIsOpen}
          title="Start Task"
          isCreateNew
          primaryDocumentIdForCreateNew={DocumentPackage.Document.ID}
          onApiError={this.handleApiError}
          onAlert={this.handleAlert}
          onClose={this.handleCloseTaskDialog}
        />
      ): null;

    const setDocumentFolderDialog = (DocumentPackage && ShowDocumentFolderDialog)
      ? (
        <SetDocumentFolderDialog
          open={ShowDocumentFolderDialog}
          organizationId={organizationID}
          projectId={projectID}
          documentIds={[documentID]}
          onApiError={this.handleApiError}
          onAction={documentFolderId => this.handleAction(ActionType.SetDocumentFolder, { documentFolderId })}
          onClose={() => this.handleSetShowDocumentFolderDialog(false)}
        />
      ): null;

    // let mobileImageScrollUpIndicator = (IsMobile() && ImageContainerScrollTop)
    //   ? (
    //     <ExpandLessIcon
    //       className={classNames(classes.mobileImageScrollIndicator, classes.mobileImageScrollUpIndicator)}
    //     />
    //   ) : null;
    // let mobileImageScrollDownIndicator = (
    //     IsMobile() 
    //     && DocumentPackage
    //     && ImageContainerClientHeight < ImageContainerScrollHeight
    //     && (!ImageContainerClientHeight || (ImageContainerScrollHeight - ImageContainerScrollTop) > ImageContainerClientHeight)
    //   )
    //   ? (
    //     <ExpandMoreIcon
    //       className={classNames(classes.mobileImageScrollIndicator, classes.mobileImageScrollDownIndicator)}
    //     />
    //   )
    //   : null;

    let documentVisual = (
      <React.Fragment>      
        {navigateLeftSide}
        {navigateRightSide}
        {/*mobileImageScrollUpIndicator*/}
        {/*mobileImageScrollDownIndicator*/}
        {documentVisualContent}
      </React.Fragment>
    );

    let tabs = (!usePublicApi)
      ? (
        <Tabs
          variant="fullWidth"
          className={classes.tabs}
          value={SelectedTab}
          onChange={this.handleTabChange}
        >
          <Tab label="Content" value="content" className={classes.tab} />
          <Tab label="Properties" value="properties" className={classes.tab} />
          <Tab label="Comments" value="comments" className={classes.tab} />
        </Tabs>
      )
      : null;

    let tabContent;
    if (IsMobile()) {
      switch (SelectedTab) {
        case "content":
          tabContent = documentVisual;
          break;
        case "properties":
          tabContent = column2content;
          break;
        case "comments":
          tabContent = (
            <div
              style={{
                padding:theme.spacing(3),
                overflowY:"auto",
                height:"100%",
              }}
            >
              {column3content}
            </div>
          );
          break;
        default:
          break;
      }
    }

    let extrasHeight = 0;
    if (DocumentPackage) {
      if (!isDocumentEditorContext) {
        extrasHeight += toolHeaderHeight;
      }
      if (!usePublicApi) {
        if (DocumentPackage.Document.SignatureSessionIsActive) {
          extrasHeight += ribbonHeight;
        }
        if (DocumentPackage.Document.IsSigned) {
          extrasHeight += ribbonHeight;
        }
        if (DocumentPackage.Document.IsMarkedForPurge) {
          extrasHeight += ribbonHeight; 
        }
      }
    }

    const fieldPropertiesDialog = (!isReadOnly) ? (
      <FieldPropertiesDialog
        organizationId={organizationID}
        projectId={projectID}
        open={ShowFieldPropertiesDialog}
        onApiError={this.handleApiError}
        closeCallback={() => this.handleSetShowFieldPropertiesDialog(false)}
        field={FieldToCustomize}
      />
    ) : null;

    let content = (
      <div className={classes.outerContainer}
        ref={instance => this.OuterContainerRef = instance}>
        {signatureHeader}
        {signaturesPendingHeader}
        {recycleBinHeader}
        {toolHeader}
        <div className={classes.contentContainer}
          style={{
            height:`calc(100% - ${extrasHeight}px)`,
          }}
        >
          <div className={classes.content}>
            <TitleComponent title={pageTitle} hideNucleusOne={usePublicApi} />
            
            {configureFieldsDialog}
            {relatedDocumentsDialog}
            {documentEventsDialog}
            {signatureDialogs}
            {taskDialog}
            {setDocumentFolderDialog}
            <MultiUseDialog Details={fieldValidationDialogDetails} />
            <MultiUseDialog Details={navigateToPageDialogDetails} />
            <MultiUseDialog Details={addOrMapFieldDialogDetails} />
            <MultiUseDialog Details={documentNameChangeConfirmationDialogDetails} />
            <MultiUseDialog Details={fieldsByTagRecommendationDialogDetails} />

            {fieldPropertiesDialog}

            <Popover
              open={Boolean(PopoverText)}
              onClose={() => this.handleShowPopover(null)}
              anchorEl={(this.OuterContainerRef && !this.OuterContainerRef.hasOwnProperty('current')) ? this.OuterContainerRef : undefined}
              anchorOrigin={{
                vertical:'center',
                horizontal:'center',
              }}
              transformOrigin={{
                vertical:'center',
                horizontal:'center',
              }}
              classes={{
                paper: classes.popoverPaper,
              }}
            >
              {PopoverText}
            </Popover>

            {/* Document Preview */}
            <div className={classes.column1}>
              {(!IsMobile()) ? documentVisual : null}
            </div>

            {tabs}
            <div className={classes.tabContent}
              style={{
                height:(usePublicApi) ? "100%" : undefined,
              }}
            >
              {tabContent}
            </div>
          </div>

          {/* Properties */}
          <div className={classNames(classes.column2, column2VisibilityClass)}>
            {column2content}
          </div>
          
          {/* Comments */}
          <div className={classNames(classes.column3, column3VisibilityClass)}>
            {column3content}
          </div>
        </div>
      </div>
    );

    // let navigationAppBarContent = (this.getDocumentQuery())
    //   ? (
    //     <div>
    //       <Tooltip title="Previous">
    //         <IconButton key="prevButton" color="inherit"
    //           onClick={() => this.tryMoveToPrevDocument()}>
    //           <PrevIcon />
    //         </IconButton>
    //       </Tooltip>
    //       <Tooltip title="Next">
    //         <IconButton key="nextButton" color="inherit"
    //           onClick={() => this.tryMoveToNextDocument()}>
    //           <NextIcon />
    //         </IconButton>
    //       </Tooltip>
    //     </div>
    //   ) 
    //   : null;

    // let approvalActionsAppBarContent = (this.ApprovalID)
    //   ? (
    //     <div>
    //       <Tooltip title={ActionType.Approval_Approve.Label}>
    //         <IconButton key="approveButton" color="inherit"
    //           className={classes.approveActionButton}
    //           onClick={() => this.handleAction(ActionType.Approval_Approve)}
    //           >
    //           {ActionType.Approval_Approve.Icon}
    //         </IconButton>
    //       </Tooltip>
    //       <Tooltip title={ActionType.Approval_Decline.Label}>
    //         <IconButton key="declineButton" color="inherit"
    //           className={classes.approveActionButton}
    //           onClick={() => this.handleAction(ActionType.Approval_Decline)}
    //           >
    //           {ActionType.Approval_Decline.Icon}
    //         </IconButton>
    //       </Tooltip>
    //     </div>
    //   ) : null;

    // let allDevicesAppBarContent = (
    //   <div style={{display:"flex",alignItems:"center"}}>
    //   {approvalActionsAppBarContent}
    //   {navigationAppBarContent}
    //   </div>
    // );

    return (usePublicApi)
      ? content
      : (
        <UiCore title={pageTitle}
          apiError={ApiError}
          apiErrorTitle={ApiErrorTitle}
          onEditTitle={(DocumentPackage && !isReadOnly)
            ? () => this.handleSetDocumentNameChangeConfirmationDialogIsOpen(true)
            : undefined}
          alert={Alert}
          hideAppBarSearchInMobile
          // allDevicesAppBarContent={allDevicesAppBarContent}
          showProgressIndicator={ShowProgressIndicator}
          showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
          onSetFuncToSetOtherAppBarContent={this.handleSetFuncToSetOtherAppBarContent}
          hideThemeSwitcher
          hideNavDrawer
          content={content}
          extraContentForAppBarEnd={extraContentForAppBarEnd}
        />
      );
  }
}

export default withStyles(styles, {withTheme: true})(Document);
