import React from 'react';

import DeleteIcon from '@material-ui/icons/Delete';

const OrganizationActionType = {
	OrganizationMember_Delete: {
		Id: "OrgMemberDelete",
		Label: "Delete",
		Icon: (<DeleteIcon />),
	},
	OrganizationProject_Delete: {
		Id: "OrgProjectDelete",
		Label: "Delete",
		Icon: (<DeleteIcon />),
	},
};

export default OrganizationActionType;