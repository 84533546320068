import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import EnhancedTableHead from './EnhancedTableHead';
import ProgressIndicator from './ProgressIndicator';
import GetMoreButton from '../Util/GetMoreButton';

import { IsMobile } from '../Util/MobileDetector';

const styles = theme => ({
  // For Table
  tableWrapper: {
    overflowX: "visible",
  },
  table: {
    backgroundColor: theme.palette.background.paper,
  },
});

class ItemListBase extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
    }
  }

  render() {
    const {
      ShowProgressIndicator,
      ShowProgressIndicatorImmediately,
  	} = this.state;
    const {
      classes,
      theme,
      cardGridItems,
      headCells,
      tableBodyContent,
      showGetMoreButton,
      onGetMoreItems,
      getMoreButtonLeftMargin,
      onSelectAll,
      sortType,
      collectionFields,
      sortDescending,
      onSortChangeFromTableHeader,
      viewType,
      indeterminate,
      allSelected,
      singleLineTableCells,
      style,
      // leftPaneIsVisible,
    } = this.props;

    let progressIndicator = null;
    if (ShowProgressIndicator || ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    let tableHead = (
      <EnhancedTableHead
        headCells={headCells}
        onSelectAllClick={onSelectAll}
        selectAllIndeterminate={indeterminate}
        selectAllChecked={indeterminate || allSelected}
        sortDescending={sortDescending}
        sortType={sortType}
        collectionFields={collectionFields}
        onSortChange={onSortChangeFromTableHeader}
      />
    );

    let table = (tableBodyContent) ? (
      <div className={classes.tableWrapper}
        style={{
          // minWidth:"100%",
          width:(IsMobile() || singleLineTableCells) ? 'max-content' : undefined,
          minWidth: (IsMobile() || singleLineTableCells) ? '100%' : undefined,
        }}
      >
        <Table
          className={classes.table}
          stickyHeader
          aria-labelledby="tableTitle"
          size={'medium'}
        >
          {tableHead}
          <TableBody>
            {tableBodyContent}
          </TableBody>
        </Table>
      </div>
    ) : null;

    let contentByView = null;
    let contentStyle = {};
    let cardSidePadding = (IsMobile()) ? 19 : /*(leftPaneIsVisible) ? */theme.spacing(2)/* : 27*/;
    switch (viewType) {
      case "List":
        contentStyle = {};
        contentByView = table;
        break;
      case "Card":
      default:
        contentStyle = {
          padding:theme.spacing(2),
          paddingLeft:cardSidePadding,
          paddingRight:cardSidePadding,
          transition:"padding 200ms",
          ...style,
        };
        contentByView = (
          <Grid container spacing={2}>
            {cardGridItems}
          </Grid>
        );
        break;
    }

    return (
      <div style={contentStyle}>
        {progressIndicator}
        {contentByView}
        <div style={{
          marginLeft: getMoreButtonLeftMargin,
          width: "100%",
        }}>
          {GetMoreButton(showGetMoreButton, () => onGetMoreItems(), viewType, theme)}
        </div>
      </div>    
    );
  }
}

export default withStyles(styles, {withTheme: true})(ItemListBase);