import { GetFieldForFilterAndSort } from '../Util/Field';
import {
	AddFilterSortFieldsToCollectionFields,
} from '../Util/Fields';
import {
  TextFieldOperators,
} from '../Util/Search';

export const DocumentCollectionField_Name = "Meta_text_kw256lc[Name].keyword";

export const GetDocumentsCollectionFields = (filterSortFields, isPublicApi) => {
	let collectionFields = [];
	
	// Non-public only
	if (!isPublicApi) {
		collectionFields.push(
			GetFieldForFilterAndSort(
				"Meta_text_kw256lc[ProjectName].keyword",
				"Project",
				"FieldType_Text",
				"Meta_text_kw256lc[ProjectName]",
				null,
			    "projectList",
			    true,
			    true, // SortOnly - filter doesn't make sense because there's no need when in /projects and /workspace is by folder
			),
			// Assignment filter doesn't make sense because there are no mixed results anywhere
			// GetFieldForFilterAndSort(
			// 	"Meta_text_kw256lc[AssignmentUserEmail].keyword",
			// 	"Shared with",
			// 	"FieldType_Text",
			// 	"Meta_text_kw256lc[AssignmentUserEmail]",
			// 	null,
			//     "addressBookItemsList",
			//     true,
			// ),
			GetFieldForFilterAndSort(
				"Meta_text_kw256lc[CreatedByUserEmail].keyword",
				"Created By",
				"FieldType_Text",
				"Meta_text_kw256lc[CreatedByUserEmail]",
				[
			      {
			          value: "equals",
			          label: "equals",
			      },
			      {
			      	value: "notEquals",
			      	label: "does not equal",
			      },
			    ],
			    "addressBookItemsList",
			    true,
			),
			GetFieldForFilterAndSort(
				"Meta_text_kw50lc[Tag].keyword",
				"Tags",
				"FieldType_Text",
				"Meta_text_kw50lc[Tag]",
				null,
			  	"tags",
			  	true,
			  	false,
			  	null,
			  	null,
			  	false,
			  	TextFieldOperators,
			  	true,
			),
		);
	}
	
	// All Access
	collectionFields.push(
		GetFieldForFilterAndSort(
			"Meta_date_str256[CreatedOn].string",
			"Created On",
			"FieldType_Date",
		),
		GetFieldForFilterAndSort(
			DocumentCollectionField_Name,
			"Name",
			"FieldType_Text",
			"Meta_text_kw256lc[Name]",
		),
		GetFieldForFilterAndSort(
			"Meta_long[FileSize]",
			"File Size",
			"FieldType_Number",
		),
		GetFieldForFilterAndSort(
			"Meta_int[PageCount]",
			"Page Count",
			"FieldType_Number",
		),
	);

	AddFilterSortFieldsToCollectionFields(collectionFields, filterSortFields);

	return collectionFields;
}
