import React from 'react';

import DeleteIcon from '@material-ui/icons/Delete';

const FormTemplateActionType = {
	FormTemplate_Delete: {
		Id: "FormTemplateDelete",
		Label: "Delete",
		Icon: (<DeleteIcon />),
	},
};

export default FormTemplateActionType;