import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

// import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  MuiThemeProvider,
} from '@material-ui/core/styles';

import API, {
  GetProjectSettingsPublicPathForApi,
  GetDocumentSearchResultsPublicPathForApi,
} from '../Util/api';
import MultiUseDialog from '../Components/MultiUseDialog';
import ProgressIndicator from '../Components/ProgressIndicator';
import TitleComponent from '../Components/TitleComponent';
import ItemCollectionBase from '../Components/ItemCollectionBase';
import DocumentFolders from '../Util/DocumentFolders';
import AppBarSearchControl from '../Components/AppBarSearchControl';
import {
  GetDocumentFoldersNode,
} from '../Util/Nodes';
import {
  GetPublicDocumentFoldersPath,
} from '../Util/PathHelper';
import {
  GetTreeNodeComponent,
  TreeComponentDidUpdate,
} from '../Util/Tree';
import { GetSearchQuery } from '../Util/Search';
import {
  OpenDocumentDetailInline,
} from '../Util/Documents';
import {
  GetSearchResultsClasses,
} from '../Util/SearchResults';
import {
  DocumentCollectionField_Name,
} from '../Model/DocumentsCollectionFields';
import {
  GetMuiTheme,
  hsl,
  GetObjectWithOverrides,
} from '../Util/Theme';

import Collection from '../Model/Collection';
import { Documents_Collection } from '../Model/Documents';
import { PublicDocumentPassThrough_Collection } from '../Model/PublicDocumentPassThrough';

// NOTE: We have to override all items surrounding the form canvas for this page as it's forced to "light mode" in App.js.
// This is because we need a white background and matching form fields.
const styles = theme => ({
  root: {
    height:"100%",
    backgroundColor: theme.palette.background.default,
    // This ensures action-drawer icons (or any icons in a list) match the color of the adjoining text
    "& .MuiListItemIcon-root": {
      color:"currentColor",
    },
  },
  container: {
    display:"flex",
    height: "100%",
  },
  folders: {
  },
  content: {
    flexGrow:1,
  },
  ...GetSearchResultsClasses(theme),
  searchControl: {
    marginTop:-1,
    [theme.breakpoints.down('sm')]: {
      display:"none",
    },
  },
  searchControlInputWidths: {
    width: 220,
    [theme.breakpoints.up('md')]: {
      width: 180,
    },
    [theme.breakpoints.up('lg')]: {
      width: 240,
    },
  },
  searchControlInputWidthsFocused: {
    width: 220,
    [theme.breakpoints.up('md')]: {
      width: 280,
    },
    [theme.breakpoints.up('lg')]: {
      width:580,
    },
  },
});

class PublicFoldersContent extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      ShowProgressIndicatorImmediately: false,
      DocumentFolderNodesByNodeId: null,
    }

    this.organizationId = this.props.match.params.organizationID;
    this.projectId = this.props.match.params.projectID;
    this.DocumentFolders = null;
    this.Collection = new Collection(this.props, state => this.setState(state), this.handleApiError);
  }

  componentDidMount() {
    this.DocumentFolders = new DocumentFolders(
      this.state,
      state => this.setState(state),
      this.handleApiError, this.organizationId, this.projectId, 
      false, false, "", true,
      null, null, null, null,
      null, null, null, null,
      null, ["all"]);

    this.initiateDocumentFolders();

    this.setCollection();
  }

  componentDidUpdate(prevProps, prevState) {
    TreeComponentDidUpdate(prevProps.location, this.props.location, state => this.setState(state));
    if (this.props.match.params.documentFolderID !== prevProps.match.params.documentFolderID
      || this.props.match.params.documentID !== prevProps.match.params.documentID) {
      this.setCollection();
    }
  }

  setCollection = () => {
    const {
      documentFolderID,
      documentID,
    } = this.props.match.params;

    let documentFolderId;
    if (documentFolderID) {
      const ids = documentFolderID.split("/");
      documentFolderId = ids[ids.length - 1];
    }

    if (documentID) {
      this.Collection = new PublicDocumentPassThrough_Collection(this.props, state => this.setState(state),
        this.handleApiError,
        this.organizationId, this.projectId, documentID,
      );
    } else {
      this.Collection = new Documents_Collection(this.props, state => this.setState(state), 
        this.handleApiError,
        false, false, false, true,
        this.organizationId, this.projectId, documentFolderId, null,
        null, null, null,
        false, false,
      );
    }
  }

  initiateDocumentFolders = () => {
    if (!this.props.projectSettings || !this.state.DocumentFolderNodesByNodeId) {
      setTimeout(() => this.initiateDocumentFolders(), 250);
      return;
    }
    this.DocumentFolders.GetAndSetDocumentFolderNodesAsPromise(this.props, this.state)
      (this.getRootNodeComponent());
  }

  showDialog = details => {
    if (!details)
      return;
    this.setState({
      DialogDetails: {
        Open: (details.Closed) ? false : new Date(),
        IsConfirmation: details.IsConfirmation,
        RequireTextInput1: details.RequireTextInput1,
        FullWidth: details.FullWidth,
        Title: details.Title,
        BodyText: details.BodyText,
        BodyContent: details.BodyContent,
        BodyClassName: details.BodyClassName,
        TextInput1Label: details.TextInput1Label,
        TextInput1PlaceHolder: details.TextInput1PlaceHolder,
        TextInput1DefaultValue: details.TextInput1DefaultValue,
        CancelCallback: (details.CancelCallback) ? details.CancelCallback : () => {},
        CloseLabel: details.CloseLabel,
        CloseCallback: (details.CloseCallback) ? details.CloseCallback : () => {},
        DisableBlurClose: (details.DisableBlurClose) ? details.DisableBlurClose : false,
        ConfirmLabel: details.ConfirmLabel,
        ConfirmCallback: (details.ConfirmCallback) ? details.ConfirmCallback : () => {},
      }
    });
  }

  getRootNodeComponent = () => {
    return {
      ...GetDocumentFoldersNode(),
      Name: this.props.projectSettings.DocumentRootLabel || "Documents",
      HasChildren: true,
      Open: true,
      DocumentFolderID: "",
      IsRoot: true,
      IsDocumentFolderRoot: true,
      RootId: undefined,
      Url: GetPublicDocumentFoldersPath(this.organizationId, this.projectId),
    };
  }

  handleGetInstantSearchPromise = fullText => {
    if (!fullText) {
      return new Promise(resolve => resolve());
    }
    const { classes } = this.props;
    const uri = GetDocumentSearchResultsPublicPathForApi(this.organizationId, this.projectId);
    return API.get(uri, { params: { searchQuery_json_base64: btoa(unescape(encodeURIComponent(JSON.stringify(GetSearchQuery(fullText))))) }})
      .then(resp => {
        if (!resp.data || !resp.data.SearchResults) {
          return null;
        }

        let resultsForOutput = [];
        resp.data.SearchResults.forEach((sr, i) => {
          resultsForOutput.push({
            value: sr,
            label: (
              <div className={classes.searchResultContainer}>
                <div className={classes.searchResult}>
                  <div className={(sr.ThumbnailUrl) ? classes.searchResultThumbnail : classes.searchResultNoThumbnail}>
                    {sr.ThumbnailUrl &&
                      <img src={sr.ThumbnailUrl} alt=""
                        className={classes.searchResultThumbnailImage} />
                    }
                  </div>
                  <div className={classes.searchResultText}>
                    {sr.Name}
                    <div className={classes.searchResultTextMetadata}>
                      {
                        (sr.DocumentFolderPath &&
                          <span className={classes.searchResultTextMetadataItem} style={{color:sr.DocumentFolderHexColor}}>
                            <span className={classes.searchResultTextMetadataName}>Folder</span>: {sr.DocumentFolderPath}
                          </span>
                        )
                      }
                    </div>
                  </div>  
                </div>
              </div>
            ),
          });
        });

        return resultsForOutput;
      })
      .catch(this.handleApiError);
  }

  handleSearchResultSelected = selectedOption => {
    if (selectedOption && selectedOption.value) {
      OpenDocumentDetailInline(
        this.organizationId, this.projectId, selectedOption.value.ID,
        false, {}, false, null, true, this.props.location.pathname, this.props.history,
      );
    }
  }

  handleApiError = (err, title_optional) => {
    // console.log(err.response.status);
    // console.log(err.response.data.message);
    if (typeof err.response === "undefined") {
      err = {
        response: {
          status: 500,
          data: {
          message: err.toString(),
          },
        },
      };
    }

    switch(err.response.status) {
      case 500:
      // 500: Internal Server Error
      let dialogDetails = {
        Title: title_optional || "Sorry, something went wrong...",
        BodyText: (err.response.data.message) ? err.response.data.message : "Please try again.",
      }
      if (err.closeCallback !== undefined) {
        dialogDetails.CloseCallback = err.closeCallback;
      }
      this.showDialog(dialogDetails);
      break;
      default:
      break;
    }

    this.setState({ShowProgressIndicatorImmediately: false});
  }

  render() {
  	const {
      DialogDetails,
      ShowProgressIndicatorImmediately,
  	} = this.state;
    const {
      projectSettings,
      classes,
      theme,
      cookies,
      ...restProps
    } = this.props;

    let progressIndicator = null;
    if (ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    const dialog = (DialogDetails)
      ? (
        <MultiUseDialog Details={DialogDetails} />
      )
      : null;

    const folders = (this.DocumentFolders && projectSettings)
      ? (
        GetTreeNodeComponent(
          this.props, this.state, state => this.setState(state),
          this.getRootNodeComponent(),
          () => {},
          this.DocumentFolders.GetAndSetDocumentFolderNodesAsPromise(this.props, this.state, null, null, null),
          this.DocumentFolders.GetDocumentFolderTreeNodeComponent(this.props, this.state, null, null, null),
          this.DocumentFolders.GetContextMenu,
          this.DocumentFolders.HandleSetNodeExpandFunction,
        )
      ) : null;

    const searchComponent = (
      <div className={classes.searchControl}>
        <AppBarSearchControl
          inputStyle={{
            paddingTop:0,
            paddingBottom:0,
          }}
          overrideClasses={{
            inputWidths: classes.searchControlInputWidths,
            inputWidthsFocused: classes.searchControlInputWidthsFocused,
          }}
          onGetOptionsFilterPromise={this.handleGetInstantSearchPromise} 
          value={null /* This prevents the control from receiving a value */}
          onValueChange={this.handleSearchResultSelected}
        />
      </div>
    );

    const content = (
      <ItemCollectionBase
        {...restProps}
        
        pageTitle="Folders"
        contentUri={this.Collection.ContentUri}
        contentUriParams={this.Collection.ContentUriParams}
        collectionName={this.Collection.CollectionName}
        itemsName={this.Collection.ItemsName}
        itemName={this.Collection.ItemName}
        defaultViewType={this.Collection.DefaultViewType}
        passThroughComponent={this.Collection.PassThroughComponent}
        
        onGetCollectionFieldsPromise={this.Collection.HandleGetCollectionFieldsPromise}
        onGetHeadCells={this.Collection.HandleGetHeadCells}
        onGetCardGridItems={this.Collection.HandleGetCardGridItems}
        onGetCardGridItemsForKanban={this.Collection.HandleGetCardGridItemsForKanban}
        onGetTableRows={this.Collection.HandleGetTableRows}
        singleLineTableCells={this.Collection.SingleLineTableCells}

        organizationId={this.organizationId}
        projectId={this.projectId}
        isPublicApi
        returnContentOnly

        leftPaneContent={folders}
        toolHeaderStyle={{
          paddingLeft:theme.spacing(2),
        }}
        toolHeaderLeftContent={searchComponent}
        hideToolHeader={Boolean(this.Collection.PassThroughComponent)}

        // hideFilterSortDrawer={this.Collection.HideFilterSortDrawer}
        // hideFilters={this.Collection.HideFilters}
        // hideFilters

        allowSelect={this.Collection.AllowSelect}
        canSelectItem={this.Collection.CanSelectItem}
        initialViewTypeForPublicApi="Card"
        initialSortTypeForPublicApi={DocumentCollectionField_Name}

        // selectedNode={(!IsMobile()) ? SelectedNode : undefined}

        // hideSensitiveFields={this.Collection.HideSensitiveFields}
        
        // onBreadcrumbSelect={this.handleBreadcrumbSelect}
        // secondaryNavTabs={secondaryNavTabs}
        // toolHeaderLeftContent={mobileProjectSelector}

        // loadItemsImmediately
        
        // apiError={ApiError}
        // alert={Alert}
        // totalItems={TotalItems}
        // onRefresh={this.Collection.HandleRefresh}
        // onItemsChanged={this.Collection.HandleItemsChanged}
        // forcePrependItems={ForcePrependItems}
        // forceRefresh={ForceRefresh}
        // forceRefreshKanbanColumns={ForceRefreshKanbanColumns}
        // onSetResetAutoRefreshFunc={this.handleSetResetAutoRefreshFunc}
        // includeItemIds={(this.props.match.params.collectionItemID) ? [this.props.match.params.collectionItemID] : undefined}
        
        // onFabClick={this.Collection.HandleCreateNew}
        // onGetFabMenu={this.Collection.HandleGetFabMenu}
        // fabMenuCoords={{
        //   FabMenu_MouseY,
        //   FabMenu_MouseX,
        //   SelectedNode,
        // }}
        // onViewTypeChanged={this.handleViewTypeChanged}
        // dialogContent={this.Collection.HandleGetDialogContent(this.state)}
        // onSetUpdateRevisedItemFunction={this.Collection.HandleSetUpdateRevisedItemFunction}
        // onSetGetAllItemsFunction={this.Collection.HandleSetGetAllItemsFunction}
        // onSetSetAllItemsFunction={this.Collection.HandleSetSetAllItemsFunction}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
      />
    );

    return (
      <div className={classes.root}>
        <TitleComponent title="Folders" />

        {progressIndicator}

        {dialog}

        <div className={classes.container}>
          {content}
        </div>

      </div>
    );
  }
}

PublicFoldersContent = withStyles(styles, {withTheme: true})(PublicFoldersContent);

class PublicFolders extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      ProjectSettings: null,
    }
  }

  componentDidMount() {
    this.getProjectSettings();
  }

  getProjectSettings = () => {
    const {
      organizationID,
      projectID,
    } = this.props.match.params;
    API.get(GetProjectSettingsPublicPathForApi(organizationID, projectID))
      .then(resp => {
        this.setState({
          ProjectSettings: resp.data,
        });
      });
  }

  getThemeOverrides = ProjectSettings => {
    // These overrides create a neutral tone for public folders
    let themeOverrides = {
      palette: {
        primary: { 
          main: hsl(0,0,.15),
        },
        secondary: { 
          main: hsl(0,0,.25),
        },
      },
    };

    // This is a mockup of various theme settings to show the capabilities
    // const folderIconUrl = "https://cdnjs.cloudflare.com/ajax/libs/simple-icons/9.0.0/fonoma.svg";
    // themeOverrides = GetObjectWithOverrides(
    //   themeOverrides,
    //   {
    //     palette: {
    //       primary: { 
    //         main: hsl(30,0.7,.4),
    //       },
    //       secondary: { 
    //         main: hsl(140,0.7,.4),
    //       },
    //       background: {
    //         default: hsl(0,.5,.7),
    //         pane: hsl(40,.7,.92),
    //         toolBar: hsl(200,.5,.6),
    //         paper: hsl(140,.5,.85),
    //         treeNodeHighlight: hsl(320,.3,.8),
    //         cardActionBar: hsl(220,.3,.5),
    //       },
    //       text: {
    //         primary: "#808000",
    //         secondary: "#000080",
    //       },
    //     },
    //     // typography: {
    //     //   fontFamily: "Dancing Script",
    //     // },
    //     iconURLs: {
    //       "folderNode": folderIconUrl,
    //       "documentFolders": folderIconUrl,
    //     },
    //     // fontURL: "https://fonts.googleapis.com/css?family=Dancing+Script:300,400,500,700&display=swap",
    //   }
    // );

    // Set user-based overrides here
    if (ProjectSettings) {
      if (ProjectSettings.PublicFontURL) {
        themeOverrides = GetObjectWithOverrides(themeOverrides,
          { fontURL: ProjectSettings.PublicFontURL }
        );
      }
      if (ProjectSettings.PublicFontFamily) {
        themeOverrides = GetObjectWithOverrides(themeOverrides,
          { typography: { fontFamily: ProjectSettings.PublicFontFamily }}
        );
      }
      if (ProjectSettings.PublicFolderIconURL) {
        themeOverrides = GetObjectWithOverrides(themeOverrides,
          { iconURLs: {
             "folderNode": ProjectSettings.PublicFolderIconURL,
             "documentFolders": ProjectSettings.PublicFolderIconURL,
          }}
        );
      }

      if (ProjectSettings.PublicPrimaryColor) {
        themeOverrides = GetObjectWithOverrides(themeOverrides,
          { palette: { text: { primary: ProjectSettings.PublicPrimaryColor }}}
        );
      }
      if (ProjectSettings.PublicSecondaryColor) {
        themeOverrides = GetObjectWithOverrides(themeOverrides,
          { palette: { text: { secondary: ProjectSettings.PublicSecondaryColor }}}
        );
      }
      if (ProjectSettings.PublicPrimaryAccentColor) {
        themeOverrides = GetObjectWithOverrides(themeOverrides,
          { palette: { primary: { main: ProjectSettings.PublicPrimaryAccentColor }}}
        );
      }
      if (ProjectSettings.PublicSecondaryAccentColor) {
        themeOverrides = GetObjectWithOverrides(themeOverrides,
          { palette: { secondary: { main: ProjectSettings.PublicSecondaryAccentColor }}}
        );
      }

      if (ProjectSettings.PublicDefaultBackgroundColor) {
        themeOverrides = GetObjectWithOverrides(themeOverrides,
          { palette: { background: { default: ProjectSettings.PublicDefaultBackgroundColor }}}
        );
      }
      if (ProjectSettings.PublicPaneBackgroundColor) {
        themeOverrides = GetObjectWithOverrides(themeOverrides,
          { palette: { background: { pane: ProjectSettings.PublicPaneBackgroundColor }}}
        );
      }
      if (ProjectSettings.PublicToolBarBackgroundColor) {
        themeOverrides = GetObjectWithOverrides(themeOverrides,
          { palette: { background: { toolBar: ProjectSettings.PublicToolBarBackgroundColor }}}
        );
      }
      if (ProjectSettings.PublicPaperBackgroundColor) {
        themeOverrides = GetObjectWithOverrides(themeOverrides,
          { palette: { background: { paper: ProjectSettings.PublicPaperBackgroundColor }}}
        );
      }
      if (ProjectSettings.PublicCardActionBarBackgroundColor) {
        themeOverrides = GetObjectWithOverrides(themeOverrides,
          { palette: { background: { cardActionBar: ProjectSettings.PublicCardActionBarBackgroundColor }}}
        );
      }
      if (ProjectSettings.PublicPaneBackgroundColor) {
        themeOverrides = GetObjectWithOverrides(themeOverrides,
          { palette: { background: { treeNodeHighlight: ProjectSettings.PublicTreeNodeHighlightBackgroundColor }}}
        );
      }
    }

    return themeOverrides;
  };

  render() {
    const {
      ProjectSettings,
    } = this.state;
    const {
      cookies,
    } = this.props;

    const theme = GetMuiTheme(cookies, true, this.getThemeOverrides(ProjectSettings));
    const fontHelmet = (theme.fontURL && theme.typography && theme.typography.fontFamily)
      ? (
        <Helmet>
          <link rel="stylesheet" href={theme.fontURL} />
        </Helmet>
      ) : null;
    return (
      <MuiThemeProvider theme={theme}>
        {fontHelmet}
        <CssBaseline />
        <PublicFoldersContent {...this.props} projectSettings={ProjectSettings} />
      </MuiThemeProvider>
    );
  }
}

export default PublicFolders;