import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import Collection from '../Model/Collection';

import ItemTableRow from '../Components/ItemTableRow';
import FieldCard from '../Components/FieldCard';
import FieldCollectionFields from '../Model/FieldCollectionFields';

import FieldPropertiesDialog from '../Components/FieldPropertiesDialog';
import FieldCreationDialog from '../Components/FieldCreationDialog';

import API from '../Util/api';

import {
  GetFieldsPathForApi,
  GetFieldPathForApi,
} from '../Util/api';
import {
  GetDateValue,
  GetFieldTypeLabel,
} from '../Util/Properties';
import { 
  GetProjectFieldsPath,
} from '../Util/PathHelper';
import {
  HandleRouteToFieldDialog,
} from '../Util/Fields';

export class Fields_Collection extends Collection {
  constructor(props, onSetState, onApiError, isWorkspace, isProjects, organizationId, projectId, 
    onItemTitleChange) {

    if (typeof organizationId !== "string") {
      console.log("Warning: organizationId required and not found");
    }
    if (typeof projectId !== "string") {
      console.log("Warning: projectId required and not found");
    }
    super(props, onSetState, onApiError, isWorkspace, isProjects, organizationId, projectId);

    this.handleItemTitleChange = onItemTitleChange;

    this.PageTitle = "Fields";
    this.ContentUri = GetFieldsPathForApi(organizationId, projectId);
    this.CollectionName = "Fields";
    this.ItemsName = "Fields";
    this.ItemName = "Field";
    this.AllowSelect = true;
    this.DefaultViewType = "List";
    this.FieldsForDialog = [];
  }

  HandleGetCollectionFieldsPromise = () => Promise.resolve(FieldCollectionFields);

  HandleGetHeadCells = (items, sensitiveFields) => {
    let headCells = [
      { id: 'Name', sortId: 'Meta_text_kw256lc[Name].keyword', numeric: false, label: 'Name' },
      { id: 'Label', sortId: 'Meta_text_kw256lc[LabelOrName].keyword', numeric: false, label: 'Label' },
      { id: 'Type', sortId: 'Meta_kw256[FieldType]', numeric: false, label: 'Type' },
      { id: 'CreatedOn', sortId: 'Meta_date_str256[CreatedOn].string', numeric: false, label: 'Created On' },
    ];
    if (!this.ProjectID) {
      headCells.push(
        { id: 'ProjectName', numeric: false, label: "Project" },
      );
    }
    return headCells;
  }

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length) 
      ? items
        .map(i => {
          return (
            <Grid item key={i.ID} sm={12} md={6} lg={4} xl={3} className={classes.cardGridItem}>
              <FieldCard
                Field={i}
                onCardAction={() => HandleRouteToFieldDialog(this.props, this.ProjectID, i.ID)}
                onSelect={() => onSelect(i.ID)}
                selected={selectedIDs.indexOf(i.ID) > -1}
              />
            </Grid>
          );
        }) 
      : [];
  }

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length)
      ? items
        .map(i => {
          const selected = selectedIDs.indexOf(i.ID) !== -1;
          const projectNameCell = (!this.ProjectID)
            ? <TableCell className={classes.tableCell}>{i.ProjectName}</TableCell>
            : null;
          return (
            <ItemTableRow key={`k_${i.ID}`}
              onSelect={() => onSelect(i.ID)}
              selected={selected}
              onItemClick={() => HandleRouteToFieldDialog(this.props, this.ProjectID, i.ID)}
            >
              <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
                <Checkbox
                  color="secondary"
                  onClick={e => { e.stopPropagation(); onSelect(i.ID); }}
                  checked={selected}
                />
              </TableCell>
              <TableCell className={classes.tableCell} component="th" id={`label_${i.ID}`} scope="row" padding="none">
                {i.Name}
              </TableCell>
              <TableCell className={classes.tableCell}>{i.Label || i.LabelOrName}</TableCell>
              <TableCell className={classes.tableCell}>{GetFieldTypeLabel(i.Type)}</TableCell>
              <TableCell className={classes.tableCell}>{GetDateValue(i.CreatedOn)}</TableCell>
              {projectNameCell}
            </ItemTableRow>
          );
        }) 
      : [];
  }

  HandleItemsChanged = items => {
    this.FieldsForDialog = items || [];
    this.handleTryDisplayFieldDialogIfApplicable(null, items, true);
  }

  handleTryDisplayFieldDialogIfApplicable = (fieldId_optional, fields, fromItemsChanged) => {
    const fieldId = (fieldId_optional) 
      ? fieldId_optional
      : this.props.match.params.collectionItemID;
    if (fieldId) {
      const displayDialog = (field, extraState) => {
        this.setState({
          FieldForFieldDialog: field,
          FieldPropertiesDialogIsOpen: true,
          ...extraState,
        });
        if (this.handleItemTitleChange) {
          this.handleItemTitleChange(field.Name);
        }
      }
      if (!fields) {
        fields = [...this.FieldsForDialog];
      }
      const fieldFinder = fields.filter(f => f.ID === fieldId);
      if (this.isWorkspace && fieldFinder.length) {
        displayDialog(fieldFinder[0]);
      }
      if (fromItemsChanged) {
        if (fieldFinder.length) {
          displayDialog(fieldFinder[0]);
        }
      } else {
        API.get(GetFieldPathForApi(this.OrganizationID, this.ProjectID, fieldId))
          .then(resp => {
            displayDialog(resp.data, {
              ForcePrependItems: [resp.data],
            });
          })
          .catch(err => {
            this.handleApiError(err);
            this.handleFieldPropertiesDialogClosed();
          });
      }
    }
  }

  HandleCreateNew = () => {
    this.setState({
      AddFieldDialogIsOpen: true,
    });
  }

  HandleGetDialogContent = state => {
    if (state.AddFieldDialogIsOpen) {
      return (
        <FieldCreationDialog
          organizationId={this.OrganizationID}
          projectId={this.ProjectID}
          // projectName={projectName}
          open={state.AddFieldDialogIsOpen || false}
          onCreated={this.handleFieldCreated}
          onClose={this.handleAddFieldDialogClosed}
          onApiError={this.handleApiError}
        />
      );
    } else if (state.FieldPropertiesDialogIsOpen) {
      return (
        <FieldPropertiesDialog
          organizationId={this.OrganizationID}
          projectId={this.ProjectID}
          onFieldRevised={this.HandleItemRevised}
          open={state.FieldPropertiesDialogIsOpen}
          onApiError={this.handleApiError}
          closeCallback={this.handleFieldPropertiesDialogClosed}
          field={state.FieldForFieldDialog} />
      );
    }
  }

  handleAddFieldDialogClosed = () => {
    this.setState({
      AddFieldDialogIsOpen: false,
    })
  }

  handleFieldPropertiesDialogClosed = () => {
    const stateToUpdate = {
      FieldPropertiesDialogIsOpen: false,
      ShowDialogProgressIndicatorImmediately: false,
    };
    this.setState(stateToUpdate);

    const newPath = GetProjectFieldsPath(this.ProjectID);
    if (this.props.location.pathname !== newPath) {
      this.props.history.push(newPath, { ...this.props.location.state, ...stateToUpdate });
    }
    if (this.handleItemTitleChange) {
      this.handleItemTitleChange(null);
    }
  }

  handleFieldCreated = field => {
    this.handleAddFieldDialogClosed();
    if (!field) {
      return;
    }
    this.handleAddFieldDialogClosed();
    this.setState({ForcePrependItems:[field]});
    HandleRouteToFieldDialog(this.props, this.ProjectID, field.ID);
  }

  // Returns whether further path changes can occur.
  updateProps(props) {
    Collection.prototype.updateProps.bind(this)(props);

    if (this.props.match.params.collectionItemID && !this.prevProps.match.params.collectionItemID) {
      this.handleTryDisplayFieldDialogIfApplicable(null, null);
      return false;
    } else if (!this.props.match.params.collectionItemID && this.prevProps.match.params.collectionItemID) {
      this.handleFieldPropertiesDialogClosed();
      return false;
    }

    return true;
  }
}