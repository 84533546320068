import React from 'react';

export const BrowserImageMimeTypes = "image/jpeg,image/jpg,image/png,image/gif";

export function GetImageComponent(image, parentWidth, maxHeight) {
  if (!maxHeight) {
    maxHeight = 9999;
  }
  let ratio = image.Width/image.Height;
  let iRatio = image.Height/image.Width;
  let stI = {};
  let stPositioner = {
    position:"absolute",
    top:0,
    left:0,
  };
  let stAspect = {
    position:"relative",
    width:"100%",
    transition:"padding 200ms",
  };
  let rotation = image.RotationRequested%360;
  stI.transform = `rotate(${image.RotationRequested}deg)`;
  stI.transition = "transform 200ms";
  // We can't leave the transform at 0 degrees in place without causing ghost issues on drag for very tall images.
  // This needs to be dealt with later.
  if (Math.abs(rotation) === 0) {
  } 
  switch (rotation) {
    case 90:
    case -270:
      stAspect.paddingTop = `${ratio*100}%`;
      stPositioner.maxHeight = maxHeight;
      stI.transformOrigin = `${(iRatio/2)*100}% 50%`;
      stI.width = `${parentWidth*ratio}px`;
    break;
    case 270:
    case -90:
      stAspect.paddingTop = `${ratio*100}%`;
      stI.transformOrigin = `50% ${(ratio/2)*100}%`;
      stI.width = `${parentWidth*ratio}px`;
    break;
    case 180:
    case -180:
      stAspect.paddingTop = Math.min((parentWidth/image.Width)*image.Height, maxHeight);
      stI.objectFit = "cover";
      stI.objectPosition = "top";
      stI.maxHeight = maxHeight;
      stI.width = `${parentWidth}px`;
    break;
    default:
      stAspect.paddingTop = Math.min((parentWidth/image.Width)*image.Height, maxHeight);
      stPositioner.maxHeight = maxHeight;
      stI.width = `${parentWidth}px`;
    break;
  }

  return (
    <div style={stAspect}>
      <div style={stPositioner}>
        <img id={`img_${image.ID}`}
          style={stI}
          src={`data:${image.ContentType};base64,${image.Base64}`} 
          alt="" />
      </div>
    </div>
  );
}