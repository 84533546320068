import debounce from 'es6-promise-debounce';

export class UniqueIdDebouncer {
  constructor(wait) {
    this.debouncers = {};
    this.debounceWait = wait;
  }

  debounceByUniqueId(uniqueId, func) {
    let debouncer = this.debouncers[uniqueId];

    // If debouncer doesn't exist for this ID, create one
    if (!debouncer) {
      //console.log(`*** ${performance.now().toFixed(3)} debounceByUniqueId 1: ${uniqueId}: Creating new debouncer`);

      this.debouncers[uniqueId] = debouncer = debounce(async (...args) => {
        // Once the underlying function is actually called and the debounce period has passed, remove
        // the debouncer to clean up resources
        delete this.debouncers[uniqueId];
  
        //console.log(`*** ${performance.now().toFixed(3)} debounceByUniqueId 3: ${uniqueId}: Calling underlying function`);

        // Call the underlying function
        return await func(...args);
      }, this.debounceWait);
    }

    //console.log(`*** ${performance.now().toFixed(3)} debounceByUniqueId 2: ${uniqueId}: Calling existing debouncer`);
    // Execute the debounced function
    return debouncer();
  }
}