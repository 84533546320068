import { GetFieldForFilterAndSort } from '../Util/Field';

const TaskMilestoneCollectionFields = [
	GetFieldForFilterAndSort(
		"NameLower",
		"Name",
		"FieldType_Text",
	),
	GetFieldForFilterAndSort(
		"Index",
		"Position",
		"FieldType_Number",
	),
];

export default TaskMilestoneCollectionFields;