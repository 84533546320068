import React, { Component } from 'react';

import UiCore from '../Components/UiCore';

import API from '../Util/api';

import { GlobalContext } from '../Context/Global.context';
import {
  GetUserProfilePath,
} from '../Util/PathHelper';

class EmailChangeConfirmation extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      ShowProgressIndicatorImmediately: false,
    }
  }

  setErrorWithHomeRedirectCloseCallback = errorOrMessage => {
    let err = (typeof errorOrMessage === "string")
      ? {
        response: {
          status: 500,
          data: {
            message: errorOrMessage
          }
        }
      }
      : errorOrMessage;
    err.closeCallback = () => this.props.history.push("/");
    this.setState({ ApiError: err });
  }

  componentDidMount() {
    this.setState({ShowProgressIndicatorImmediately: true});
    let confirmationId = this.props.match.params.confirmationID;
    if (!confirmationId) {
      this.setErrorWithHomeRedirectCloseCallback("This confirmation is invalid.");
      return;
    }

  	API.put(`/user/emailAddresses/${confirmationId}`)
		  .then(resp => {
          this.setState({
            Alert: {
              Title: "E-mail address changed",
              BodyText: "You will now be redirected to your user profile.",
              CloseCallback: () => {
                this.context.Reset();
                this.props.history.push(GetUserProfilePath());
              },
            }
          });
		  })
		  .catch(err => {
        this.setErrorWithHomeRedirectCloseCallback(err);
      });
  }

  render() {
    const {
      ShowProgressIndicatorImmediately,
      ApiError,
      Alert, 
    } = this.state;

    return (
      <div style={{height:"100%"}}>
        <UiCore title="E-mail Address Change Confirmation"
          apiError={ApiError}
          alert={Alert}
          showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
        />
      </div>
    );
  }
}

export default EmailChangeConfirmation;
