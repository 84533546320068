import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import DashboardCard from '../DashboardCard';

import Icon from '@material-ui/icons/SpaceBar';

const styles = theme => ({
});

class EmptyWidget extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
    }
  }

  render() {
    // const {
    // } = this.state;
    const { 
      classes,
      theme,
      ...passThroughProps
    } = this.props;

    return (
      <DashboardCard
        {...passThroughProps}
        desktopXs={2}
        title="Empty"
        titleIcon={<Icon />}
      >
        {(passThroughProps.DashboardWidget) ? passThroughProps.DashboardWidget.ID : undefined}
      </DashboardCard>
    );
  }
}

EmptyWidget.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(EmptyWidget);