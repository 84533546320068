import { GetFieldForFilterAndSort } from '../Util/Field';

const TaskDocumentsCollectionFields = [
	// GetFieldForFilterAndSort(
	// 	"Meta_date_str256[CreatedOn].string",
	// 	"Created On",
	// 	"FieldType_Date",
	// ),
	// GetFieldForFilterAndSort(
	// 	"Meta_text_kw256lc[CreatedByUserEmail].keyword",
	// 	"Created By",
	// 	"FieldType_Text",
	// 	"Meta_text_kw256lc[CreatedByUserEmail]",
	// 	[
	//       {
	//           value: "equals",
	//           label: "equals",
	//       },
	//       {
	//       	value: "notEquals",
	//       	label: "does not equal",
	//       },
	//     ],
	//     "addressBookItemsList",
	//     true,
	// ),
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[Name].keyword",
		"Name",
		"FieldType_Text",
		"Meta_text_kw256lc[Name]",
		[
			{
			    value: "startsWith",
			    label: "word starts with",
			},
		],
	),
	// GetFieldForFilterAndSort(
	// 	"Meta_long[FileSize]",
	// 	"File Size",
	// 	"FieldType_Number",
	// ),
	// GetFieldForFilterAndSort(
	// 	"Meta_int[PageCount]",
	// 	"Page Count",
	// 	"FieldType_Number",
	// ),
];

export default TaskDocumentsCollectionFields;