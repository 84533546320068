import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Backdrop from '@material-ui/core/Backdrop';
import Slide from '@material-ui/core/Slide';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';

import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

import classNames from 'classnames';
import { IsMobile } from '../Util/MobileDetector';

const styles = theme => ({
  container: {
    height:"100%",
    position:(IsMobile()) ? "fixed" : "relative",
    right:0,
    zIndex:1201,
  },
  container_preload: {
    "& *": {
      transition: ["none", "!important"],
    }
  },
  pane: {
    backgroundColor: theme.palette.background.pane,
    height:"100%",
    overflowY:"auto",
    position:"relative",
    minWidth:240,
    maxWidth:400,
    zIndex:1201,
  },
  paneInner: {
    height:"100%",
  },
  pane_visible: {
    marginRight:0,
  },
  pane_hidden: {
    // This is now handled via a ref and offsetWidth
    // marginLeft:-300,
  },
  actionDrawerTab: {
    height:100,
    width:theme.spacing(7),
    minWidth:0,
    marginRight: -1 * theme.spacing(1),
    justifyContent: "left",
    padding:0,
    backgroundColor: theme.palette.secondary.main,
    // borderRadius:4,
    // boxShadow: "2px 3px 6px 0px #000",
    position:"fixed",
    right:0,
    top:200,
    zIndex:1200,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    }
  },
  actionDrawerTabLabel: {
    color:"white",
    marginLeft: theme.spacing(1) - 1,
  },
});

class ActionDrawer extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      AllowDrawer: false,
      ActionDrawerOpen: !IsMobile(),
      ShowActionDrawerTab: false,
      IsMounted: false,
    };

    this.ContainerRef = React.createRef();
    this.ActionDrawerPaneRef = React.createRef();
  }

  toggleActionDrawerForMobile = open => () => {
    if (!IsMobile()) {
      return;
    }
    this.setState({
      ActionDrawerOpen: open,
    });
  };

  componentDidMount() {
    // This must be set in setTimeout as componentDidMount raw is too fast for the browser
    setTimeout(() => {
      this.setState({IsMounted: true});
    }, 1);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.allowDrawer !== this.props.allowDrawer) {
      let stateToUpdate = {
        AllowDrawer: this.props.allowDrawer,
      };
      if (IsMobile()) {
        stateToUpdate.ShowActionDrawerTab = this.props.allowDrawer;
        stateToUpdate.ActionDrawerOpen = false;
      }
      this.setState(stateToUpdate);
    }
    if (this.state.AllowDrawer !== prevState.AllowDrawer
      || this.state.ActionDrawerOpen !== prevState.ActionDrawerOpen) {
      if (this.props.onVisibilityChange) {
        this.props.onVisibilityChange();
      }
    }
  }

  render() {
    const {
      ShowActionDrawerTab,
      ActionDrawerOpen,
      AllowDrawer,
      IsMounted,
    } = this.state;
    const {
      classes,
      actionListItems,
      noAnimate,
    } = this.props; 

    const actionDrawerList = (
      <div>
        <List>
          {actionListItems}
        </List>
      </div>
    );

    // This isn't ideal because when ShowActionDrawerTab becomes false, the tab disappears
    // instead of sliding out. But we're doing this because a browser resize causes strange
    // behavior where the tab comes out of hiding while the browser width is changed.
    let actionDrawerTab = null;
    if (AllowDrawer && IsMobile()) {
      actionDrawerTab = (
        <Slide direction="left" in={ShowActionDrawerTab}>
          <Button variant="contained"
            elevation="10"
            classes={{ label: classes.actionDrawerTabLabel }}
            className={classes.actionDrawerTab}
            onClick={this.toggleActionDrawerForMobile(true)}
            >
            <ArrowLeftIcon fontSize="large" />
          </Button>
        </Slide>
      );
    }

    const mobileBackdrop = (IsMobile())
      ? (
        <Backdrop open={AllowDrawer && ActionDrawerOpen}
          onClick={this.toggleActionDrawerForMobile(false)} />
      ) : null;

    const paneVisibilityClass = (AllowDrawer && ActionDrawerOpen) ? classes.pane_visible : classes.pane_hidden;
    const containerClassNames = (!IsMounted)
      ? classNames(classes.container, classes.container_preload)
      : classNames(classes.container);

    return (
      <div className={containerClassNames}
        ref={instance => this.ContainerRef = instance}
      >
        {mobileBackdrop}
        {actionDrawerTab}
          
        <div
          className={classNames(classes.pane, paneVisibilityClass)}
          ref={instance => this.ActionDrawerPaneRef = instance}
          style={{
            transition: (!noAnimate) ? "margin 0.2s" : undefined,
            marginRight: (!AllowDrawer || !ActionDrawerOpen) ? -1 * (this.ActionDrawerPaneRef.offsetWidth || 3000) : undefined,
          }}
        >
          <div
            className={classes.paneInner}
            tabIndex={-1}
            role="button"
            onClick={this.toggleActionDrawerForMobile(false)}
            onKeyDown={this.toggleActionDrawerForMobile(false)}
          >
            {actionDrawerList}
          </div>
        </div>
      </div>
    );
  }
}

ActionDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  actionListItems: PropTypes.array.isRequired,
  allowDrawer: PropTypes.bool,
  noAnimate: PropTypes.bool,
};

export default withStyles(styles, {withTheme: true})(ActionDrawer);