import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Collection from '../Model/Collection';
import ItemTableRow from '../Components/ItemTableRow';

import CreateIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

import AssetItemTasksCollectionFields from '../Model/AssetItemTasksCollectionFields';
import TaskCard from '../Components/TaskCard';
import TaskDialog from '../Components/TaskDialog';
import ActionType from '../Model/ActionType';

import {
  GetDueOnStyle,
} from '../Util/Tasks';
import {
  GetTaskPriorityLabel,
  GetTaskDurationAsString,
} from '../Util/Task';
import {
  GetDateValue,
  GetUserValue,
} from '../Util/Properties';
import {
  GetTagsForList,
} from '../Util/Tags';
import {
  GetAssetItemTasksPathForApi,
  GetUserOrganizationProjectApprovalAssetItemTasksPathForApi,
} from '../Util/api';
import { GetTextWithUrlsAsAnchors } from '../Util/Regex';

export class AssetItemTasks_Collection extends Collection {
  constructor(props, context, onSetState, onApiError, onAlert,
    isWorkspace, isProjects, loadByApprovalAssignment,
    organizationId, projectId, assetItem, approvalId, isProjectMember,
    onBeginTaskCreateFunc, onBeginAddExistingTasksFunc, disableSelect, completedOnly) {
    
    super(props, onSetState, onApiError, isWorkspace, isProjects, organizationId, projectId);

    this.context = context;
    this.handleAlert = onAlert;
    this.IsProjectMember = isProjectMember;

    this.PageTitle = "Tasks";
    this.ContentUri = (loadByApprovalAssignment)
      ? GetUserOrganizationProjectApprovalAssetItemTasksPathForApi(organizationId, projectId, approvalId, assetItem.AssetID, assetItem.ID)
      : GetAssetItemTasksPathForApi(organizationId, projectId, assetItem.AssetID, assetItem.ID);
    this.ContentUriParams = {
      completedOnly: (completedOnly) ? true : undefined,
    };
    this.CollectionName = "AssetItemTasks";
    this.ItemsName = "Tasks";
    this.ItemName = "Task";
    this.DefaultViewType = "Card";
    this.AllowSelect = !disableSelect;
    this.CanSelectItem = item => this.AllowSelect && isProjectMember;
    
    // this.HideSensitiveFields = true;
    this.SingleLineTableCells = true;

    this.loadByApprovalAssignment = loadByApprovalAssignment;
    this.AssetItem = assetItem;
    this.approvalId = approvalId;
    this.onBeginTaskCreateFunc = onBeginTaskCreateFunc;
    this.onBeginAddExistingTasksFunc = onBeginAddExistingTasksFunc;

    this.HandleCreateNew = e =>
      (this.onBeginTaskCreateFunc || this.onBeginAddExistingTasksFunc)
        ? this.handleSetFabMenuVisibility(true)(e)
        : null;
  }

  handleSetFabMenuVisibility = visible => event => {
    this.setState({
      FabMenu_MouseY: (visible) ? event.clientY : null,
      FabMenu_MouseX: (visible) ? event.clientX : null,
    });
  }

  HandleGetFabMenu = state => {
    if (!state.FabMenu_MouseY) {
      return null;
    }
    const createMenuItem = (this.onBeginTaskCreateFunc)
      ? (
        <MenuItem key="create" onClick={this.handleFabMenuClick(state, "create")}>
          <ListItemIcon>
            <CreateIcon />
          </ListItemIcon>
          New task
        </MenuItem>
      ) : null;
    const addExistingMenuItem = (this.IsProjectMember
      && this.onBeginAddExistingTasksFunc)
      ? (
        <MenuItem key="addExisting" onClick={this.handleFabMenuClick(state, "addExisting")}>
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          Tag existing tasks
        </MenuItem>
      ) : null;
    return (
        <Menu
          id="fabMenu"
          keepMounted
          open={state.FabMenu_MouseY !== null}
          anchorReference="anchorPosition"
          anchorPosition={
            state.FabMenu_MouseY !== null && state.FabMenu_MouseX !== null
              ? { top: state.FabMenu_MouseY, left: state.FabMenu_MouseX }
              : undefined
          }
          onClose={this.handleSetFabMenuVisibility(false)}
        >
          {createMenuItem}
          {addExistingMenuItem}
        </Menu>
      );
  }

  handleFabMenuClick = (state, id) => e => {
    this.handleSetFabMenuVisibility(false)(e);
    switch (id) {
      case "create":
        if (this.onBeginTaskCreateFunc) {
          this.onBeginTaskCreateFunc();
        }
        break;
      case "addExisting":
        if (this.onBeginAddExistingTasksFunc) {
          this.onBeginAddExistingTasksFunc();
        }
        break;
      default:
        break;
    }
  }

  HandleGetCollectionFieldsPromise = () => Promise.resolve(AssetItemTasksCollectionFields);

  HandleGetHeadCells = (items, sensitiveFields) => {
    let headCells = [
      { id: 'Name', sortId: 'Meta_text_kw256lc[Name].keyword', numeric: false, label: 'Name' },
      { id: 'Email', sortId: 'Meta_text_kw256lc[AssignmentUserEmail].keyword', numeric: false, label: 'Assigned To' },
      { id: 'CreatedOn', sortId: 'Meta_date_str256[CreatedOn].string', numeric: false, label: 'Created On' },
      { id: 'DueOn', sortId: 'Meta_date_str256[DueOn].string', numeric: false, label: 'Due On' },
      { id: 'Duration', sortId: 'Meta_long[Duration]', numeric: false, label: 'Duration' },
      { id: 'Priority', sortId: 'Meta_int[Priority]', numeric: false, label: 'Priority' },
      { id: 'Milestone', sortId: 'Meta_text_kw256lc[MilestoneName].keyword', numeric: false, label: 'Milestone' },
      { id: 'State', sortId: 'Meta_text_kw256lc[StateName].keyword', numeric: false, label: 'Task State' },
      { id: 'Tags', sortId: 'Meta_text_kw50lc[Tag].keyword', numeric: false, label: 'Tags' },
    ];
    // if (this.ShowCompletedTasks || this.ShowDeniedTasks) {
    //   headCells.push(
    //     { id: 'CompletedOn', sortId: 'Meta_date_str256[CompletedOn].string', numeric: false, label: 'Completed On' },
    //     { id: 'CompletedBy', sortId: 'Meta_text_kw256lc[CompletedByUserEmail].keyword', numeric: false, label: 'Completed By' },
    //   );
    // }
    headCells.push(
      { id: 'Description', numeric: false, label: 'Description' },
    );
    return headCells;
  }

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, selectedIDs,
    onAction, postActionData, sortType, sortDescending, useSingleColumnLayout, cardStyle) => {
    
    this.ActionHandlerFunction = onAction;
    return (items && items.length)
      ? items
        .map(i => {
          return (
            <Grid item key={i.ID} className={classes.cardGridItem}
              sm={(!useSingleColumnLayout) ? 12 : undefined}
              md={(!useSingleColumnLayout) ? 6 : undefined}
              lg={(!useSingleColumnLayout) ? 4 : undefined}
              xl={(!useSingleColumnLayout) ? 3 : undefined}
            >
              <TaskCard
                Task={i}
                // primaryDocumentLinkComponent={this.getPrimaryDocumentLinkComponent(i, theme)}
                onAction={onAction}
                onCardAction={() => this.handleShowTaskDialog(i)}
                onSelect={(this.CanSelectItem(i)) ? () => onSelect(i.ID) : undefined}
                noSelect={!this.CanSelectItem(i)}
                disableCardActions={this.isWorkspace}
                selected={selectedIDs.indexOf(i.ID) > -1}
                style={{
                  backgroundColor: theme.palette.background.default,
                  ...cardStyle
                }}
                height={256}
              />
            </Grid>
          );
        }) 
      : [];
  }

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs,
    onAction, postActionData, sortType, sortDescending) => {
    
    this.ActionHandlerFunction = onAction;
    return (items && items.length)
      ? items.map(i => {
        const selected = selectedIDs.indexOf(i.ID) !== -1;          
        // const completedOnTableCell = (this.ShowCompletedTasks || this.ShowDeniedTasks)
        //   ? (
        //     <TableCell className={classes.tableCell}>{GetDateValue(i.CompletedOn)}</TableCell>
        //   ) : null;
        // const completedByTableCell = (this.ShowCompletedTasks || this.ShowDeniedTasks)
        //   ? (
        //     <TableCell className={classes.tableCell}>{GetUserValue(i.CompletedByUserEmail, i.CompletedByUserName)}</TableCell>
        //   ) : null;
        return (
          <ItemTableRow key={`k_${i.ID}`}
            onSelect={(this.CanSelectItem(i)) ? () => onSelect(i.ID) : undefined}
            selected={selected}
            onItemClick={() => this.handleShowTaskDialog(i)}
          >
            <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
              <Checkbox
                style={{
                  visibility: (!this.CanSelectItem(i)) ? "hidden" : undefined,
                }}
                color="secondary"
                onClick={e => { e.stopPropagation(); onSelect(i.ID); }}
                checked={selected}
              />
            </TableCell>
            <TableCell className={classes.tableCell} component="th" id={`label_${i.ID}`} scope="row" padding="none">
              {i.Name}
            </TableCell>
            <TableCell className={classes.tableCell}>{GetUserValue(i.AssignmentUserEmail, i.AssignmentUserName)}</TableCell>
            <TableCell className={classes.tableCell}>
              {GetDateValue(i.CreatedOn)}
            </TableCell>
            <TableCell className={classes.tableCell} style={(!i.Result) ? GetDueOnStyle(i.DueOn) : undefined}>
              {GetDateValue(i.DueOn)}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {GetTaskDurationAsString(i.DurationMultiplier, i.DurationInterval)}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {GetTaskPriorityLabel(i.Priority)}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {i.TaskMilestoneName}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {i.TaskStateName}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {GetTagsForList(i, sortType, sortDescending)}
            </TableCell>
            {/*{completedOnTableCell}*/}
            {/*{completedByTableCell}*/}
            <TableCell style={{maxWidth:1500}} className={classes.tableCell}>
              {GetTextWithUrlsAsAnchors(i.Description, theme, null, 100)}
            </TableCell>
          </ItemTableRow>
        );
      })
    : null;
  }

  handleAction = (actionType, item) => {
    this.setState({ ShowDialogProgressIndicatorImmediately: true });
    this.ActionHandlerFunction(actionType, item.ID)
      .then(() => {
        this.handleTaskDialogClosed(null);
      });
  }

  HandleGetDialogContent = state => {
    return (state.TaskDialogIsOpen && (state.CreateNewTask || state.TaskForTaskDialog))
      ? (
        <TaskDialog
          organizationId={this.OrganizationID}
          projectId={this.ProjectID}
          history={this.props.history}
          location={this.props.location}
          open={state.TaskDialogIsOpen || false}
          isCreateNew={state.CreateNewTask}
          assetItemForNewTask={this.AssetItem}
          onCreated={newTask => this.setState({ForcePrependItems: [newTask]})}
          Task={(!state.CreateNewTask) ? state.TaskForTaskDialog : undefined}
          onTaskRevised={this.HandleItemRevised}
          onMarkComplete={() => this.handleAction(ActionType.Task_Complete, state.TaskForTaskDialog)}
          onRestore={() => this.handleAction(ActionType.Task_Restore, state.TaskForTaskDialog)}
          onApiError={this.handleApiError}
          onAlert={this.handleAlert}
          onClose={this.handleTaskDialogClosed}
          showProgressIndicatorImmediately={state.ShowDialogProgressIndicatorImmediately}
          isWorkspace={this.isWorkspace}
          workspaceApprovalId={(this.isWorkspace) ? this.approvalId : undefined}
          workspaceApprovalAssetItem={(this.isWorkspace) ? this.AssetItem : undefined}
        />
      ) : null;
  }

  handleShowTaskDialog = TaskForTaskDialog => {
    this.setState({
      TaskDialogIsOpen: true,
      TaskForTaskDialog,
    });
  }

  handleTaskDialogClosed = () => {
    const stateToUpdate = {
      CreateNewTask: false,
      TaskDialogIsOpen: false,
      TaskForTaskDialog: null,
      ShowDialogProgressIndicatorImmediately: false,
    };
    this.setState(stateToUpdate);
  }
}