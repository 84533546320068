import React, { Component } from 'react';

import TableRow from '@material-ui/core/TableRow';

class ItemTableRow extends Component {
  handleRowClick = e => {
    if (e.ctrlKey) {
      if (this.props.onSelect) {
        this.props.onSelect();
      }
    } else if (this.props.onItemClick) {
      this.props.onItemClick();
    }
  }

  render() {
    const {
      children,
      onItemClick,
      selected,
      style,
    } = this.props; 

    return (
      <TableRow
        hover={Boolean(onItemClick)}
        aria-checked={selected}
        selected={selected}
        onClick={this.handleRowClick}
        tabIndex={-1}
        style={{
          cursor:(onItemClick) ? "pointer" : undefined,
          ...style,
        }}
      >
        {children}
      </TableRow>
    );
  }
}

export default ItemTableRow;