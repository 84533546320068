import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import UiCore from '../../Components/UiCore';
import FormTemplateDesignerComponent from '../Components/FormTemplateDesigner';
import API, {
  GetFormTemplatesPathForApi,
  GetFormTemplatePathForApi,
} from '../../Util/api';
import debounce from 'es6-promise-debounce';
import { IsMobile } from '../../Util/MobileDetector';
import {
  GetProjectFormTemplatesPath,
} from '../../Util/PathHelper';

const styles = theme => ({
});

class FormTemplateDesigner extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      FormTemplate: null,
      PendingFormTemplate: null,
      Title: "",
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
    }
  }

  loadFormTemplate = sourceAlertShown => {
    this.setState({ShowProgressIndicatorImmediately: true});
    API.get(GetFormTemplatePathForApi(this.props.match.params.organizationID, this.props.match.params.projectID,
      this.props.match.params.formTemplateID))
      .then(resp => {
        const FormTemplate = resp.data;
        if (FormTemplate.FormTemplateSourceID) {
          if (FormTemplate.FormTemplateSourceFailedProcessing) {
            this.handleUpdateFormTemplate(
              0,
              {
                ...this.state.PendingFormTemplate,
                FormTemplateSourceIsImported: true,
              },
            );
            return this.handleApiError("There was an issue encountered while processing the source PDF.");
          }
          // If this FormTemplate is based on a FormTemplateSource, 
          // ensure the FormTemplateSource has been imported.
          else if (!FormTemplate.FormTemplateSourceIsImported)  {
            if (!sourceAlertShown) {
              this.handleAlert({
                Title:"Processing source...",
                BodyText: "The source file is being processed. You can wait here or return later.",
              });
              this.setState({
                Title: FormTemplate.Name,
                PendingFormTemplate: FormTemplate,
              });
            }
            setTimeout(() => this.loadFormTemplate(true), 2500);
            return;
          }
        }
        this.handleAlert(null);
        this.setState({
          FormTemplate,
          Title: FormTemplate.Name,
          ShowProgressIndicatorImmediately: false,
        });
      })
      .catch(this.handleApiError);
  }

  handleUpdateFormTemplate = debounce((totalFormTemplateFields, FormTemplate, 
    skipStateUpdate, skipServerUpdate) => {
    
    if (!FormTemplate) {
      FormTemplate = {...this.state.FormTemplate};
    }
    FormTemplate.TotalFormTemplateFields = totalFormTemplateFields;
    FormTemplate.HasFormTemplateFields = totalFormTemplateFields > 0;
    if (skipServerUpdate) {
      if (!skipStateUpdate) {
        this.setState({
          FormTemplate,
          Title: FormTemplate.Name,
          ShowProgressIndicatorImmediately: false,
        });
      }
      return Promise.resolve(FormTemplate);
    } else {
    return API.put(GetFormTemplatePathForApi(this.props.match.params.organizationID, this.props.match.params.projectID,
      this.props.match.params.formTemplateID), FormTemplate)
      .then(resp => {
        if (!skipStateUpdate) {
          this.setState({
            FormTemplate: resp.data,
            Title: FormTemplate.Name,
            ShowProgressIndicatorImmediately: false,
          });
        }
        return resp.data;
      })
      .catch(this.handleApiError);
    }
  }, 500)

  handleUpdateTemplateName = name => {
    if (!name) {
      return;
    }
    this.setState({ShowProgressIndicatorImmediately: true});
    let formTemplate = {...this.state.FormTemplate};
    formTemplate.Name = name;
    return this.handleUpdateFormTemplate(formTemplate.TotalFormTemplateFields, formTemplate);
  }

  handleDeleteFormTemplate = () => {
    this.setState({ShowProgressIndicatorImmediately: true});
    return API.delete(GetFormTemplatesPathForApi(this.props.match.params.organizationID,
      this.props.match.params.projectID), { data: { IDs: [this.state.FormTemplate.ID] } })
      .then(resp => {
        this.setState({
          ShowProgressIndicatorImmediately: false,
        });
        this.props.history.push(GetProjectFormTemplatesPath(this.props.match.params.projectID));
        return resp.data;
      })
      .catch(this.handleApiError); 
  }

  handleConfirmTemplateName = () => {
    this.setState({
      Confirmation: {
        Title: "Change template name",
        RequireTextInput1: true,
        TextInput1Label: "Name",
        TextInput1DefaultValue: this.state.FormTemplate.Name,
        ConfirmLabel: "GO",
        ConfirmCallback: this.handleUpdateTemplateName,
      }
    });
  }

  handleFormTemplateMultiPropertyChange = nameValuePairs => {
    let FormTemplate = {...this.state.FormTemplate};
    nameValuePairs.forEach(p => FormTemplate[p.name] = p.value);
    this.setState({FormTemplate});
    return this.handleUpdateFormTemplate(FormTemplate.TotalFormTemplateFields, FormTemplate, true);
  }

  handleFormTemplatePageMultiPropertyChange = (PageIndex, nameValuePairs) => {
    let FormTemplate = {...this.state.FormTemplate};
    if (!FormTemplate.Pages) {
      FormTemplate.Pages = [];
    }
    const pageFinder = FormTemplate.Pages.filter(p => p.PageIndex === PageIndex);
    if (pageFinder.length) {
      nameValuePairs.forEach(p => pageFinder[0][p.name] = p.value);
    } else {
      nameValuePairs.forEach(p =>
        FormTemplate.Pages.push({
          PageIndex,
          [p.name]: p.value,
        })
      );
    }
    this.setState({FormTemplate});
    return this.handleUpdateFormTemplate(FormTemplate.TotalFormTemplateFields, FormTemplate, true);
  }

  handleAlert = alertDetails => {
    this.setState({Alert: alertDetails});
  }

  handleApiError = err => {
    this.setState({
      ApiError: err,
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
    });
  }

  componentDidMount() {
    this.loadFormTemplate();
    if (IsMobile()) {
      this.handleAlert({
        Title: "Productivity",
        BodyText: "Use a desktop browser for the best experience.",
      });
    }
  }

  componentWillUnmount() {
  }
  
  render() {
  	const { 
      FormTemplate,
      Title,
      Confirmation,
      Alert,
      ApiError,
      ShowProgressIndicator,
      ShowProgressIndicatorImmediately,
  	} = this.state;
    // const {
    //   classes,
    //   theme,
    // } = this.props;
    const {
      organizationID,
      projectID,
    } = this.props.match.params;

    let content = (FormTemplate)
      ? (
        <FormTemplateDesignerComponent
          {...this.props}
          organizationId={organizationID}
          projectId={projectID}
          FormTemplate={FormTemplate}
          onUpdateFormTemplate={this.handleUpdateFormTemplate}
          onDeleteFormTemplate={this.handleDeleteFormTemplate}
          onFormTemplateMultiPropertyChange={this.handleFormTemplateMultiPropertyChange}
          onFormTemplatePageMultiPropertyChange={this.handleFormTemplatePageMultiPropertyChange}
          onApiError={this.handleApiError}
          onAlert={this.handleAlert}
        />
      ) : null;

    return (
      <UiCore
        title={Title || "Loading form template..."}
        onEditTitle={(FormTemplate) ? this.handleConfirmTemplateName : undefined}
        alert={Alert}
        apiError={ApiError}
        confirmation={Confirmation}
        noSelectAppBar
        showProgressIndicator={ShowProgressIndicator}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
        content={content}
      />
    );
  }
}

FormTemplateDesigner.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, {withTheme: true})(FormTemplateDesigner);