import { GetFieldForFilterAndSort } from '../Util/Field';

const AssetItemAssetItemsCollectionFields = [
	GetFieldForFilterAndSort(
		"Meta_text_kw256lc[Name].keyword",
		"Name",
		"FieldType_Text",
		"Meta_text_kw256lc[Name]",
		[
			{
			    value: "startsWith",
			    label: "word starts with",
			},
		],
	),
];

export default AssetItemAssetItemsCollectionFields;