import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MaskedInput from 'react-text-mask';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';

import { IsMobile } from '../Util/MobileDetector';
import { GetRegexMaskFromMask, GetModifiersFromMask, GetStaticCharactersFromMask } from '../Util/Field';

import PropTypes from 'prop-types';

const styles = theme => ({
});

const placeHolderChar = '\xA0';

class MaskedTextField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: (new Date()).toString(),
      ValueChangeUpdateId: new Date(),
    };

    this.MaskedInput = props => {
      const { inputRef, ...other } = props;
      let { mask, onEnterKey, guide } = this.props;

      return (
        <MaskedInput
          {...other}
          autoComplete="off"
          ref={instance => { inputRef(instance ? instance.inputElement : null);}}
          mask={GetRegexMaskFromMask(mask)}
          pipe={this.ProcessValue}
          placeholderChar={placeHolderChar}
          guide={guide}
          onKeyDown={e => { if (onEnterKey && e.keyCode === 13) { e.preventDefault(); onEnterKey(); } }}
        />
      );
    }

    this.MaskedInput.propTypes = {
      inputRef: PropTypes.func.isRequired,
    };
  }

  ProcessValue = (value, config) => {
    let { mask } = this.props;
    // Modify according to modifier
    let m = GetModifiersFromMask(mask);
    if (m.length > 0) {
      for (let i = 0; i < m.length; i++) {
        let before = value.substr(0, m[i].position),
          current = value.substr(m[i].position, 1),
          after = value.substr(1 + m[i].position);
        switch (m[i].modifier) {
           case ">":
           value = before + (current + after).toUpperCase();
           break;
           case "<":
           value = before + (current + after).toLowerCase();
           break;
          default:
          value = before + current + after; 
          break;
        }
      }
      // Static characters should always remain static
      let sc = GetStaticCharactersFromMask(mask);
      if (sc.length > 0) {
        for (let i = 0; i < sc.length; i++) {
          let before = value.substr(0, sc[i].position),
            after = value.substr(1 + sc[i].position);
          value = before + sc[i].character + after; 
        }
      }
    }

    return value;
  }

  handleValueChange = e => {
    this.setState({ValueChangeUpdateId: new Date()});
    this.props.onValueChange(e);
  }

  handleClearValue = () => {
    this.props.onValueChange("");
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(this.props.id, "Updated");
  }

  componentDidMount() {
    //console.log(this.props.id, "Mounted");
  }

  render() {
    const { 
      // classes,
      // theme, 
      id,
      updateId,
      autoFocus,
      label,
      required,
      value,
      onValueChange,
      disabled,
      isReadOnly,
      hideClearButton,
    } = this.props;

    let helperTextComponent = null;
    // let helperText = null;
    // let formHelperTextProps = {};
    // if (Field.RequirementNotMet) {
    //   helperText = "Required";
    //   formHelperTextProps = {
    //     style: {color: "red"},
    //   };
    //   helperTextComponent = (<FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>);
    // }

    let fieldLabel = (label) 
      ? label + ((required) ? " *" : "")
      : null;

    let clearInputAdornment = 
    (value && value.length > 0 && !disabled) 
      ? (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              aria-label="clear"
              onClick={this.handleClearValue}
            >
              <ClearIcon style={{fontSize:18}} />
            </IconButton>
          </InputAdornment>
        ) 
      : null;

    let fieldControl = null;
    if (isReadOnly) {
      if (value) {
        fieldControl = (
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography variant="h6">
                {fieldLabel}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                {value}
              </Typography>
            </Grid>
          </Grid>
        );
      }
    } else {
      fieldControl = (
        <FormControl
          disabled={disabled}
          variant="outlined"
          fullWidth>
          <InputLabel htmlFor={id}
            shrink={value !== undefined && value.length > 0}
          >
            {fieldLabel}
          </InputLabel>
          <OutlinedInput
            autoComplete="off"
            label={(value !== undefined && value.length > 0) ? fieldLabel : null}
            notched={(fieldLabel && value !== undefined && value.length > 0) || false}
            key={updateId} // This is a hack to force the component to update when the value of Field.UpdateId changes
            autoFocus={!IsMobile() && autoFocus}
            value={value}
            onChange={onValueChange}
            id={id}
            inputComponent={this.MaskedInput}
            endAdornment={!hideClearButton && clearInputAdornment}
          />
          {helperTextComponent}
        </FormControl>
      );
    }

    return fieldControl;
  }
}

MaskedTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(MaskedTextField);