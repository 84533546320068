import React, {Component} from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import ItemCollectionBase from './ItemCollectionBase';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Collection from '../Model/Collection';
import { Documents_Collection } from '../Model/Documents';
import { Tasks_Collection } from '../Model/Tasks';
import { AssetItems_Collection } from '../Model/AssetItems';
import { GlobalContext } from '../Context/Global.context';
import { IsMobile } from '../Util/MobileDetector';
// import ProgressIndicator from './ProgressIndicator';

const styles = theme => ({
  dialogPaper: {
    height:(!IsMobile()) ? "90%" : undefined,
  },
});

class ItemSearchDialog extends Component {
  static contextType = GlobalContext;
  
  constructor(props) {
    super(props);
    
    this.state = {
      open: props.open,
      ShowProgressIndicatorImmediately: false,
      CollectionUpdateID: null,
      SelectedItems: [],
    }

    this.Collection = new Collection(this.props, state => this.setState(state), this.handleApiError);
  }

  setCollectionWhenReady = () => {
    if (!this.context.CompletedGET.UserPreferences 
      || !this.context.CompletedGET.ProjectMembershipPackages) {
      setTimeout(() => this.setCollectionWhenReady(), 250);
      return;
    }
    if (!this.props.isProjectMember) {
      return;
    }
    switch (this.props.itemType) {
    case "document":
      this.Collection = new Documents_Collection(
        this.props, 
        state => this.setState(state),
        this.handleApiError,
        this.props.isWorkspace,
        !this.props.isWorkspace, 
        false,//AreDocumentsOrDocumentFoldersLoadedByAssignment(this.context, this.props.projectId),
        false,
        this.props.organizationId,
        this.props.projectId,
        "", this.props.documentOriginType,
        null, null, null,
        false, this.props.disableSelect, "",
        true,
        this.props.extraUrlParamsForDocumentRouting,
        this.props.onlyUnsigned,
        this.props.onlyHasSinglePageImages,
      );
      break;
    case "task":
      this.Collection = new Tasks_Collection(
        this.props, 
        name => this.state[name],
        state => this.setState(state),
        this.handleApiError,
        this.props.onAlert,
        this.props.isWorkspace,
        !this.props.isWorkspace, 
        this.props.organizationId,
        this.props.projectId,
        null,
        false, false,
        null, null,
        false, true,
      );
      break;
    case "assetItem":
      this.Collection = new AssetItems_Collection(
        this.props, 
        state => this.setState(state),
        this.handleApiError,
        this.props.onAlert,
        this.props.isWorkspace,
        !this.props.isWorkspace, 
        this.props.organizationId,
        this.props.projectId,
        null,
        true, false,
        true, true,
      );
      break;
    default:
      break;
    }
    // This ensures ItemCollectionBase always sees the new collection
    this.setState({CollectionUpdateID: new Date()});
  }

  handleSelectedItemsChanged = SelectedItems => {
    this.setState({SelectedItems});
  }

  handleClose = SelectedItems => {
    this.setState({ open: false });
    if (this.props.onClose) {
      this.props.onClose(SelectedItems);
    }
  }

  handleApiError = err => {
    this.setState({ ShowProgressIndicatorImmediately: false, });
    this.props.onApiError(err);
  }

  componentDidMount() {
    this.setCollectionWhenReady();
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      
      this.setState({open: this.props.open !== false});
    }
  }

  render() {
    const {
      open,
      SelectedItems,
      // ShowProgressIndicatorImmediately,
      ApiError,
    } = this.state;
    const {
      classes,
      theme,
      dialogTitle,
      organizationId,
      disableSelect,
      confirmButtonText,
      hideConfirmButton,
      onItemClick,
      // projectId,
      maxWidth,
      ...restProps
    } = this.props;
    // const {
    //   UserPreferences
    // } = this.context;

    // const progressIndicator = null;
    // if (ShowProgressIndicatorImmediately) {
    //   progressIndicator = (
    //     <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} />
    //   );
    // }

    const confirmButton = (!hideConfirmButton)
      ? (
        <Button disabled={!SelectedItems || !SelectedItems.length}
          onClick={() => this.handleClose(SelectedItems)}>
          {confirmButtonText || "GO"}
        </Button>
      ) : null;

    const dialogActions = (
      <DialogActions>
        <Button onClick={this.handleClose}>
          CLOSE
        </Button>
        {confirmButton}
      </DialogActions>
    );

    return (
      <Dialog
        fullWidth={!IsMobile()}
        fullScreen={IsMobile()}
        maxWidth={maxWidth || "xl"}
        // TransitionComponent={Transition}
        open={open}
        onClose={this.handleClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        classes={{
          paper:classes.dialogPaper,
        }}
      >
        <DialogTitle id="dialog-title" style={{paddingBottom:0}}>
          <span>{dialogTitle || "Search for existing items"}</span>
        </DialogTitle>
        <DialogContent style={{paddingTop:0}}>
          {/*{progressIndicator}*/}

          <ItemCollectionBase
            {...restProps}

            returnContentOnly
            disableActionDrawer

            organizationId={organizationId}
            contentUri={this.Collection.ContentUri}
            contentUriParams={this.Collection.ContentUriParams}
            collectionName={
              /* Override to prevent regular actions from appearing in action drawer */
              "AddItems"
            } //this.Collection.CollectionName}
            itemsName={this.Collection.ItemsName}
            itemName={this.Collection.ItemName}
            defaultViewType={this.Collection.DefaultViewType}
            
            onGetCollectionFieldsPromise={this.Collection.HandleGetCollectionFieldsPromise}
            onGetHeadCells={this.Collection.HandleGetHeadCells}
            onGetCardGridItems={this.Collection.HandleGetCardGridItems}
            onGetTableRows={this.Collection.HandleGetTableRows}

            allowSelect={!disableSelect}
            additionalCardStyle={{
              backgroundColor: theme.palette.background.default,
              height:256,
            }}
            itemListContainerStyle={{overflowY:(IsMobile()) ? "scroll" : undefined}}
            itemListStyle={{paddingLeft:0}}
            onSelectedItemsChanged={this.handleSelectedItemsChanged}
            hideSensitiveFields={this.Collection.HideSensitiveFields}
            hideFilterSortDrawer={this.Collection.HideFilterSortDrawer}
            loadItemsImmediately
            // showFilterAndSortDrawerOnLoad={!IsMobile()}
            skipFilterSavedState={true}
            apiError={ApiError}
            onItemClick={onItemClick}
            disableCardActions
            toolHeaderStyle={{
              paddingRight:0,
            }}
          />        
        </DialogContent>
        {dialogActions}
      </Dialog>
    );
  }
}

ItemSearchDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dialogTitle: PropTypes.string,
  onClose: PropTypes.func,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  itemType: PropTypes.string.isRequired,
  isProjectMember: PropTypes.bool.isRequired,
  isWorkspace: PropTypes.bool,
  documentOriginType: PropTypes.string,
  disableSelect: PropTypes.bool,
  maxWidth: PropTypes.string,
  hideConfirmButton: PropTypes.bool,
  confirmButtonText: PropTypes.string,
  onItemClick: PropTypes.func,
  onlyUnsigned: PropTypes.bool,
  onlyHasSinglePageImages: PropTypes.bool,
  extraUrlParamsForDocumentRouting: PropTypes.object,
  onApiError: PropTypes.func.isRequired,
  onAlert: PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(ItemSearchDialog);
