import API, {
  GetAssetItemDocumentsPathForApi,
} from './api';

export const AddAssetItemDocuments = (organizationId, projectId, assetId, assetItemId, documents) => {
  return API.post(GetAssetItemDocumentsPathForApi(organizationId, projectId, assetId, assetItemId), { DocumentIDs: documents.map(d => d.ID) });
}

export const RemoveAssetItemDocuments = (organizationId, projectId, assetId, assetItemId, IDs) => {
  return API.delete(GetAssetItemDocumentsPathForApi(organizationId, projectId, assetId, assetItemId), { data: { IDs } });
}