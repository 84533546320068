import React, {Component} from 'react';

import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import AvatarPopover from '../Components/AvatarPopover';

import { withStyles } from '@material-ui/core/styles';

import {
  GetPublicAvatarImagePathForApi,
} from '../Util/api';

import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey,
} from '@material-ui/core/colors';

const styles = theme => ({
});

class AvatarComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ShowPopover: false,
    }

    this.AvatarRef = React.createRef();
  }

  stringToColor = s => {
    switch (s.substr(0, 1).toUpperCase()) {
      case "A":
        return red[500];
      case "B":
        return pink[500];
      case "C":
        return purple[500];
      case "D":
        return deepPurple[500];
      case "E":
        return indigo[500];
      case "F":
        return blue[500];
      case "G":
        return lightBlue[500];
      case "H":
        return cyan[500];
      case "I":
        return yellow[500];
      case "J":
        return green[500];
      case "K":
        return lightGreen[500];
      case "L":
        return lime[500];
      case "M":
        return teal[500];
      case "N":
        return amber[500];
      case "O":
        return orange[500];
      case "P":
        return deepOrange[500];
      case "Q":
        return brown[500];
      case "R":
        return blueGrey[500];
      case "S":
        return red[800];
      case "T":
        return pink[800];
      case "U":
        return purple[800];
      case "V":
        return deepPurple[800];
      case "W":
        return indigo[800];
      case "X":
        return blue[800];
      case "Y":
        return lightBlue[800];
      case "Z":
        return cyan[800];
      default:
        return grey[500];
    }
  }

  handleAvatarClick = e => {
    if (this.props.onAvatarClick) {
      e.preventDefault();
      e.stopPropagation();
      return this.props.onAvatarClick(e);
    } else if (this.props.disablePopover || this.props.size === "profile") {
      return;
    }

    this.handleSetShowPopover(e, !this.state.ShowPopover);
  }

  handleLinkClick = (e, uri) => {
    const link = document.createElement('a');
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  handleSetShowPopover = (e, ShowPopover) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ShowPopover});
  }

  render() {
    const {
      ShowPopover,
    } = this.state;
    const {
      // classes,
      // theme,
      email,
      name,
      size,
      iconOnly,
      srcOverride,
      iconStyle,
      gridContainerStyle,
      disablePopover,
    } = this.props;

    if (!email && !name) {
      return null;
    }

    let sizeValue;
    let fontSizeValue;
    switch (size) {
      case "sm":
        fontSizeValue = 10;
        sizeValue = 20;
        break;
      case "lg":
        fontSizeValue = 22;
        sizeValue = 38;
        break;
      case "profile":
        fontSizeValue = 48;
        sizeValue = 96;
        break;
      default:
        fontSizeValue = 12;
        sizeValue = 24;
        break;
    }

    const avatarStyles = {
      width:sizeValue,
      height:sizeValue,
      fontSize:fontSizeValue,
    };

    const nameGridItem = (
      <Grid item>
        {name || email}
      </Grid>
    );

    const avatarPopover = (!disablePopover && ShowPopover)
      ? (
        <AvatarPopover
          anchorEl={this.AvatarRef}
          onClose={e => this.handleSetShowPopover(e, false)}
          onLinkClick={this.handleLinkClick}
          email={email}
          name={name}
        />
      ) : null;
    
    const avatar = (
      <React.Fragment>
      <Avatar
        // src === null will prevent user menu avatar from reloading on page changes
        src={(srcOverride === null) ? null : srcOverride || `${process.env.REACT_APP_API_HOST}/api/v1${GetPublicAvatarImagePathForApi(email)}`}
        style={{
          cursor: (size !== "profile") ? "pointer" : undefined,
          ...iconStyle,
          ...avatarStyles,
        }}
        onClick={this.handleAvatarClick}
        ref={instance => this.AvatarRef = instance}
      >
        <Avatar
          style={{
            backgroundColor: this.stringToColor(name || email),
            ...avatarStyles,
          }}
        >
          {
            (name)
              ? name.trim().substr(0, 1).toUpperCase()
              : (email)
                ? email.trim().substr(0, 1).toUpperCase()
                : null
          }
        </Avatar>
      </Avatar>
      {avatarPopover}
      </React.Fragment>
    );

    const emailOrLink = (email)
      ? (
        <Link
          style={{cursor:"pointer"}}
          onClick={e => this.handleLinkClick(e, `mailto:${email}`)}
          color="textPrimary"
        >
          {email}
        </Link>
      ) : email;

    if (size === "profile") {
      return (
        <div style={{
          display:"flex",
          flexDirection:"column",
          alignItems:"center",
        }}>
          <div style={{marginBottom:8}}>
            {avatar}
          </div>
          <Typography variant="h6">
            { (name) ? name : emailOrLink }
          </Typography>
          <Typography>
            { (name) ? emailOrLink : null }
          </Typography>
        </div>
      );
    } else if (iconOnly) {
      return avatar;
    } else {
      return (
        <Grid container spacing={1} alignItems="center" wrap="nowrap"
          style={{
            overflow:"hidden",
            ...gridContainerStyle,
          }}>
          <Grid item>
            {avatar}
          </Grid>
          {nameGridItem}
        </Grid>
      );
    }
  }
}

AvatarComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  email: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  iconOnly: PropTypes.bool,
  srcOverride: PropTypes.string,
  iconStyle: PropTypes.object,
  gridContainerStyle: PropTypes.object,
  disablePopover: PropTypes.bool,
  onAvatarClick: PropTypes.func,
};

export default withStyles(styles, {withTheme: true})(AvatarComponent);
