import API, {
  GetApprovalActionsPathForApi,
  GetUserOrganizationProjectApprovalActionsPathForApi,
  ProcessApiCallsGroupedByProjectForItems,
} from '../Util/api';

import {
  OpenDocumentDetailTab,
} from './Documents';

import { 
  GetProjectApprovalPath,
  GetWorkspaceApprovalPath,
} from '../Util/PathHelper';

const getApprovalActionUriPrefix = (organizationId, projectId, accessByProjectMembership) => {
  return (accessByProjectMembership)
    ? GetApprovalActionsPathForApi(organizationId, projectId)
    : GetUserOrganizationProjectApprovalActionsPathForApi(organizationId, projectId);
}

export function ApproveApprovals(approvals, accessByProjectMembership) {
  return ProcessApiCallsGroupedByProjectForItems(approvals, 
    itemsByProject => API.post(`${getApprovalActionUriPrefix(itemsByProject.OrganizationID, 
      itemsByProject.ProjectID, accessByProjectMembership)}/approve`,
      { IDs: itemsByProject.Items.map(i => i.ID) }));
}

export function DeclineApprovals(approvals, accessByProjectMembership) {
  return ProcessApiCallsGroupedByProjectForItems(approvals, 
    itemsByProject => API.post(`${getApprovalActionUriPrefix(itemsByProject.OrganizationID, 
      itemsByProject.ProjectID, accessByProjectMembership)}/decline`,
      { IDs: itemsByProject.Items.map(i => i.ID) }));
}

export function DenyApprovals(approvals) {
  return ProcessApiCallsGroupedByProjectForItems(approvals, 
    itemsByProject => API.post(
      `${GetApprovalActionsPathForApi(itemsByProject.OrganizationID, itemsByProject.ProjectID)}/deny`,
        { IDs: itemsByProject.Items.map(i => i.ID) }));
}

export const GetItemTypeNameFromApproval = approval => {
  let itemType = "";
  switch (approval.ItemType) {
    case "Document":
      itemType = "Document";
      break;
    case "Task":
      itemType = "Task";
      break;
    case "AssetItem":
      itemType = approval.ItemParentName || approval.AssetName || "Asset";
      break;
    default:
      break;
  }
  return itemType;
}

export const HandleRouteToApprovalDocument = (organizationId, projectId, approvalID, documentId,
  useApprovalContext, allowApproval) => {
  
  OpenDocumentDetailTab(organizationId, projectId, documentId, false, { approvalID, allowApproval },
    false, (useApprovalContext) ? "approval" : undefined);
}

export const HandleRouteToApproval = (props, projectId, approvalId, isUserAssignmentContext) => {
  const uri = (isUserAssignmentContext)
      ? GetWorkspaceApprovalPath(approvalId)
      : GetProjectApprovalPath(projectId, approvalId);
  props.history.push(uri, { ...props.location.state });
}