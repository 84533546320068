export const HandleStartMoveTaskMilestone = (taskMilestones, getStateFunc, setStateFunc) => sourceColumnProperty => {
  setStateFunc(null, null, {
    PreMoveTaskMilestonesJson: JSON.stringify(taskMilestones),
    CollectionIsDragging: true,
  });
}

export const HandleMoveTaskMilestoneOverTaskMilestone = (taskMilestones, getStateFunc, setStateFunc) =>
  (sourceColumnProperty, targetColumnProperty) => {

  if (!sourceColumnProperty || !sourceColumnProperty.TaskMilestone) {
    return;
  }
  // console.log("Source ColumnProperty: ", sourceColumnProperty);
  
  if (!targetColumnProperty || !targetColumnProperty.TaskMilestone) {
    return;
  }
  // console.log("Target ColumnProperty: ", targetColumnProperty);

  let sourceIndex = taskMilestones.indexOf(sourceColumnProperty.TaskMilestone);
  let targetIndex = taskMilestones.indexOf(targetColumnProperty.TaskMilestone);
  if (sourceIndex === targetIndex) {
    return;
  }
  
  // console.log("Index of source: ", sourceIndex, " Index of target: ", targetIndex);
  if (sourceIndex === null || targetIndex === null) {
    return;
  }

  let taskMilestoneToUpdate = taskMilestones[sourceIndex];
  taskMilestones.splice(sourceIndex, 1);
  taskMilestones.splice(targetIndex, 0, taskMilestoneToUpdate);
  setStateFunc(taskMilestones, null, { });
}

export const HandleAbortMoveTaskMilestone = (taskMilestones, getStateFunc, setStateFunc) => sourceColumnProperty => {
  const preMoveTaskMilestones = JSON.parse(getStateFunc("PreMoveTaskMilestonesJson"));
  setStateFunc(preMoveTaskMilestones, null, { CollectionIsDragging: false });
}

export const HandleEndMoveTaskMilestone = (taskMilestones, getStateFunc, setStateFunc) => sourceColumnProperty => {
  // ReIndex all TaskMilestones
  let taskMilestonesChanged = [];
  taskMilestones.forEach((tm, index) => {
    if (tm.Index !== index) {
      tm.Index = index;
      taskMilestonesChanged.push(tm);
    }
  });

  if (taskMilestonesChanged.length) {
    setStateFunc(taskMilestones, taskMilestonesChanged, { CollectionIsDragging: false });
  } else {
    setStateFunc(null, null, { CollectionIsDragging: false });
  }
}