import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
// import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import AsyncSelectControl from '../../Components/AsyncSelectControl';

import CloseIcon from '@material-ui/icons/Close';

import { ValidateEmail } from '../../Util/Regex';
import API, {
  GetProjectsPathForApi,
  GetProjectTemplatesPathForApi,
} from '../../Util/api';
import {
  GetHomePath,
  GetProjectsPath,
  GetOrganizationLink,
} from '../../Util/PathHelper';
import {
  GetOrganizationMemberListValuesPromise,
} from '../../Util/OrganizationMembers';
import ProgressIndicator from '../../Components/ProgressIndicator';
import {
  HandleNext,
  GetStepper,
  GetStepperStyles,
} from '../../Util/Stepper';


import { IsMobile } from '../../Util/MobileDetector';
import { GlobalContext } from '../../Context/Global.context';

const styles = theme => ({
  templateList: {
    flexGrow:1,
    overflow:"auto",
    maxHeight:200,
  },
  ...GetStepperStyles(theme),
});

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="left" ref={ref} {...props} />;
// });

const Step_TemplateSelect = 0;
const Step_ProjectName = 1;
const Step_MemberAdditions = 2;

class ProjectCreationDialog extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    const Steps = [
      { index: Step_TemplateSelect,
        getLabel: onGetState => {
          const { SelectedTemplate, ActiveStepIndex } = onGetState();
          return `Project template${(ActiveStepIndex > Step_TemplateSelect && SelectedTemplate)
            ? ": " + SelectedTemplate.Name
            : ""}`;
        },
        optional: true,
        // getDescription: () => "Jump start your project with a template.",
      },
      { index: Step_ProjectName,
        getLabel: onGetState => {
          const { ProjectName, ActiveStepIndex } = onGetState();
          return `Project name${(ActiveStepIndex > Step_ProjectName && ProjectName) 
            ? ": " + ProjectName
            : ""}`;
        },
        optional: false,
      },
      { index: Step_MemberAdditions,
        getLabel: onGetState => "Add members",
        optional: true,
      },
    ];
    
    this.initialState = {
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
      ProjectName: "",
      MemberEmailOptionsToAdd: [],
      ProjectTemplates: [],
      SelectedTemplate: null,
      SourceContentCopy: true,
      ActiveStepIndex: 0,
      BackStep: null,
    };

    this.state = {
      ...this.initialState,
      open: props.open,
      Steps,
    }
  }

  setInitialState = () => {
    this.setState(this.initialState);
  }

  handleClose = () => {
    this.setState({ 
      open: false,
    });
    this.setInitialState();
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleAddEmailOptions = emailOrListValues => {
    let MemberEmailOptionsToAdd = [];
    if (Array.isArray(emailOrListValues)) {
      MemberEmailOptionsToAdd = emailOrListValues;
    } else if (typeof emailOrListValues === "string") {
      MemberEmailOptionsToAdd = [...this.state.MemberEmailOptionsToAdd]
        .concat({ value: emailOrListValues, label: emailOrListValues });
    }
    if (this.validateMemberEmailOptionsToAdd(MemberEmailOptionsToAdd)) {
      this.setState({ MemberEmailOptionsToAdd });
    }
  }

  validateMemberEmailOptionsToAdd = MemberEmailOptionsToAdd => {
    for (let i = 0; i < MemberEmailOptionsToAdd.length; i++) {
      let email = MemberEmailOptionsToAdd[i].value;
      if (!ValidateEmail(email)) {
        this.handleApiError(`${email} is not a valid e-mail address.`);
        return false;
      }
    }
    return true;
  }

  handleGetOrganizationMemberListPromiseIfApplicable = filter => {
    if (!this.context.ActiveOrganizationPermissions || !this.context.ActiveOrganizationPermissions.IsAdmin) {
      return Promise.resolve([]);
    }

    return GetOrganizationMemberListValuesPromise(
      this.props.organizationId,
      filter,
      true,
      this.handleApiError,
    );
  }

  handleGetProjectTemplates = () => {
    const params = {
      getAll: true,
    }
    this.setState({ShowProgressIndicator: true});
    API.get(GetProjectTemplatesPathForApi(), { params })
      .then(resp => {
        this.setState({
          ProjectTemplates: resp.data.Projects,
          ShowProgressIndicator: false,
        })
      })
      .catch(this.handleApiError);
  }

  handleTemplateSelect = SelectedTemplate => {
    this.setState({
      SelectedTemplate,
      ProjectName: SelectedTemplate.Name,
    });
  }

  handleCreate = () => {
    if (!this.state.ProjectName) {
      return;
    }

    this.setState({ShowProgressIndicatorImmediately: true});
    
    const newProject = {
      Name: this.state.ProjectName,
      SourceID: (this.state.SelectedTemplate) ? this.state.SelectedTemplate.ID : undefined,
      SourceContentCopy: this.state.SourceContentCopy,
    };
    if (this.props.onBeginCreate) {
      this.props.onBeginCreate(newProject);
    }

    let params = {
      homePath: GetOrganizationLink(this.props.organizationId, GetHomePath()),
      projectPathPrefix: GetOrganizationLink(this.props.organizationId, GetProjectsPath()),
    };
    if (this.state.MemberEmailOptionsToAdd.length) {
      params.additionalMemberEmails_json = JSON.stringify(this.state.MemberEmailOptionsToAdd.map(o => o.value));
    }

    API.post(GetProjectsPathForApi(this.props.organizationId), [newProject], { params })
      .then(resp => {
        if (this.props.onCreated) {
          this.props.onCreated(resp.data[0]);
        }
        this.handleClose();
      })
      .catch(this.handleApiError);
  }

  handleSkip = () => {
    const onGetState = () => this.state;
    const onSetState = state => this.setState(state);
    switch (this.state.ActiveStepIndex) {
      case Step_TemplateSelect:
        HandleNext(onGetState, onSetState, this.handleFinish, null, true, {
          SelectedTemplate: null,
          ProjectName: "",
        });
        break;
      case Step_ProjectName:
        HandleNext(onGetState, onSetState, this.handleFinish, null, true, {
          ProjectName: "",
        });
        break;
      case Step_MemberAdditions:
        HandleNext(onGetState, onSetState, this.handleFinish, null, true, {
          MemberEmailOptionsToAdd: [],
        });
        break;
      default:
        HandleNext(onGetState, onSetState, this.handleFinish, null, true, null);
        break;
    }
  }

  handleFinish = () => {
    this.handleCreate();
  }

  handleApiError = (err, title_optional) => {
    this.setState({
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
    });
    this.props.onApiError(err, title_optional);
    if (err) {
      setTimeout(() => this.props.onApiError(null), 1);
    }
  }

  componentDidMount() {
    this.handleGetProjectTemplates();
  }

  componentDidUpdate(prevProps) {
    if (this.props.open !== undefined
      && prevProps.open !== this.props.open) {
      this.setState({open: this.props.open !== false});
      if (this.props.open) {
        this.handleGetProjectTemplates();
      }
    }
  }

  render() {
    const { 
      open,
      ProjectName,
      MemberEmailOptionsToAdd,
      ProjectTemplates,
      SelectedTemplate,
      SourceContentCopy,
      ActiveStepIndex,
      ShowProgressIndicator,
      ShowProgressIndicatorImmediately,
  	} = this.state;
    const {
      classes,
      theme,
    } = this.props;

    let progressIndicator = null;
    if (ShowProgressIndicator || ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator constrained showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    const closeButton = (
      <Tooltip title="Close dialog">
        <IconButton
          style={{marginTop:-theme.spacing(1),marginBottom:-theme.spacing(1)}}
          edge="end"
          color="inherit" aria-label="Close"
          onClick={() => this.handleClose()}
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
      // <Button onClick={() => this.handleClose()}>
      //   CLOSE
      // </Button>
    );

    // const dialogActions = (
    //   <DialogActions>
    //     {closeButton}
    //     <Button disabled={ProjectName === ""} onClick={() => this.handleCreate()}>
    //       CREATE
    //     </Button>
    //   </DialogActions>
    // );

    let nextButtonIsDisabled = true;
    switch (ActiveStepIndex) {
      case Step_TemplateSelect:
        nextButtonIsDisabled = !SelectedTemplate
          || (ProjectTemplates 
            && ProjectTemplates.length
            && !ProjectTemplates.filter(pt => pt.ID === SelectedTemplate.ID).length);
        break;
      case Step_ProjectName:
        nextButtonIsDisabled = !ProjectName;
        break;
      case Step_MemberAdditions:
        nextButtonIsDisabled = false;
        break;
      default:
        break;
    }
    
    const templateListItems = ProjectTemplates.map(pt => (
      <ListItem key={pt.ID}
        button
        onClick={() => this.handleTemplateSelect(pt)}
        selected={SelectedTemplate && SelectedTemplate.ID === pt.ID}
      >
        <ListItemText primary={pt.Name} />
      </ListItem>
    ));
    const templateContent = (templateListItems.length)
      ? (
        <Grid container direction="column" spacing={0}>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  checked={SourceContentCopy}
                  onClick={e => e.stopPropagation()}
                  onChange={e => this.setState({SourceContentCopy: e.target.checked})}
                />
              }
              label="Include demo content"
            />
          </Grid>
          <Grid item>
            <List className={classes.templateList}>
              {templateListItems}
            </List>
          </Grid>
        </Grid>
        
      ) : null;

    const projectNameContent = (
      <TextField
        fullWidth
        autoFocus
        variant="outlined"
        label="Name"
        onChange={e => this.setState({ProjectName: e.target.value})}
        onKeyDown={e => { if (e.keyCode === 13) { 
          e.preventDefault();
          HandleNext(() => this.state, state => this.setState(state), this.handleFinish);
        }}}
        value={ProjectName}
      />
    );

    const memberAdditionsContent = (
      <React.Fragment>
        <AsyncSelectControl label="Members to add"
          // floatingOptions
          isMulti
          onCreateOption={this.handleAddEmailOptions}
          allowCreateWhileLoading
          autoReloadOnValueChange
          onGetOptionsFilterPromise={this.handleGetOrganizationMemberListPromiseIfApplicable}
          listValues={MemberEmailOptionsToAdd}
          onValueChange={this.handleAddEmailOptions}
          notClearable
        />
        <Typography variant="caption" style={{marginLeft:theme.spacing(2)}}>
          Organization administrators will be added automatically as managers.
        </Typography>
      </React.Fragment>
    );

    let stepContent;
    switch (ActiveStepIndex) {
      case Step_TemplateSelect:
        stepContent = templateContent;
        break;
      case Step_ProjectName:
        stepContent = projectNameContent;
        break;
      case Step_MemberAdditions:
        stepContent = memberAdditionsContent;
        break;
      default:
        break;
    }

    const stepper = GetStepper(() => this.state, state => this.setState(state),
      this.handleSkip, null, this.handleFinish, classes, stepContent, nextButtonIsDisabled);

    const dialogTitle = "Create a project"
    const dialogTitleDiv = (
      <div style={{
        display:"flex",
      }}>
        <div style={{
            flexGrow:1,
          }}
        >
          {dialogTitle}
        </div>
        {closeButton}
      </div>
    );

    return (
       <Dialog
        fullWidth={!IsMobile()}
        fullScreen={IsMobile()}
        maxWidth="sm"
        // TransitionComponent={Transition}
        // PaperProps={{
        //   style:{
        //     minHeight:"50%",
        //   }
        // }}
        open={open}
        onClose={() => this.handleClose()}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description">
        <DialogTitle id="dialog-title">
          {dialogTitleDiv}
        </DialogTitle>
        <DialogContent style={{paddingTop:0}}>
          {progressIndicator}

          {stepper}
        </DialogContent>

        {/*{dialogActions}*/}
      </Dialog>
    );
  }
}

ProjectCreationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  organizationId: PropTypes.string.isRequired,
  onBeginCreate: PropTypes.func,
  onCreated: PropTypes.func,
  onApiError: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default withStyles(styles, {withTheme: true})(ProjectCreationDialog);