import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import Collection from '../Model/Collection';

import ItemTableRow from '../Components/ItemTableRow';
import OrganizationMemberCard from '../Admin/Components/OrganizationMemberCard';
import OrganizationMemberCollectionFields from '../Model/OrganizationMemberCollectionFields';
import OrganizationMemberDialog from '../Admin/Components/OrganizationMemberDialog';
import MultiUseDialog from '../Components/MultiUseDialog';

import {
  GetOrganizationMembersPathForApi,
  GetOrganizationMemberPathForApi,
} from '../Util/api';
import {
  HandleRouteToOrganizationMember,
} from '../Util/OrganizationMembers';
import {
  GetHomePath,
  GetOrganizationMembersPath,
  GetOrganizationLink,
} from '../Util/PathHelper';
import { ValidateEmail } from '../Util/Regex';

import API from '../Util/api';

import {
  GetBoolValue,
  GetDateValue,
  GetUserValue,
} from '../Util/Properties';

export class OrganizationMembers_Collection extends Collection {
  constructor(props, context, onSetState, onApiError, organizationId, currentUserEmail) {
    if (typeof organizationId !== "string") {
      console.log("Warning: organizationId required and not found");
    }
    if (typeof currentUserEmail !== "string") {
      console.log("Warning: currentUserEmail required and not found");
    }
    super(props, onSetState, onApiError, false, false, organizationId);

    this.PageTitle = "Organization Members";
    this.ContentUri = GetOrganizationMembersPathForApi(organizationId);
    this.CollectionName = "OrganizationMembers";
    this.ItemsName = "OrganizationMembers";
    this.ItemName = "Organization Member";
    this.DefaultViewType = "List";
    this.AllowSelect = true;
    this.OrganizationMembersForDialog = [];
    this.CanSelectItem = item => item.UserEmail !== currentUserEmail;

    this.context = context;
  }

  HandleGetCollectionFieldsPromise = () => Promise.resolve(OrganizationMemberCollectionFields);

  HandleGetHeadCells = (items, sensitiveFields) => [
    { id: 'UserName', sortId: 'Meta_text_kw256lc[UserName].keyword', numeric: false, label: 'Name' },
    { id: 'UserEmail', sortId: 'Meta_text_kw256lc[UserEmail].keyword', numeric: false, label: 'Email' },
    { id: 'CreatedOn', sortId: 'Meta_date_str256[CreatedOn].string', numeric: false, label: 'Added' },
    { id: 'Admin', numeric: false, label: 'Organization Admin' },
  ];

  HandleGetCardGridItems = (items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length)
      ? items
        .map(i => {
          return (
            <Grid item key={i.ID} sm={12} md={6} lg={4} xl={3} className={classes.cardGridItem}>
              <OrganizationMemberCard
                OrganizationMember={i}
                onCardAction={() => HandleRouteToOrganizationMember(this.props, this.OrganizationID, i.ID)}
                onSelect={(this.CanSelectItem(i)) ? () => onSelect(i.ID) : undefined}
                selected={selectedIDs.indexOf(i.ID) > -1}
              />
            </Grid>
          );
        }) 
      : [];
  }

  HandleGetTableRows = (headCells, items, sensitiveFields, classes, theme, onSelect, selectedIDs, onAction, postActionData, sortType, sortDescending) => {
    return (items && items.length)
      ? items
        .map(i => {
          const labelId = `label_${i.UserEmail}`;
          return (
            <ItemTableRow key={`k_${i.ID}`}
              onItemClick={() => HandleRouteToOrganizationMember(this.props, this.OrganizationID, i.ID)}
              onSelect={(this.CanSelectItem(i)) ? () => onSelect(i.ID) : undefined}
            >
              <TableCell className={classes.tableCell_FirstCell} padding="checkbox">
                <Checkbox
                  style={{
                    visibility: (!this.CanSelectItem(i)) ? "hidden" : undefined,
                  }}
                  color="secondary"
                  onClick={event => { event.stopPropagation(); onSelect(i.ID); }}
                  checked={selectedIDs.indexOf(i.ID) > -1}
                  inputProps={{ 'aria-labelledby': labelId, style:{zIndex:0} }}
                />
              </TableCell>
              <TableCell className={classes.tableCell} component="th" id={labelId} scope="row" padding="none">
                {GetUserValue(i.UserEmail, i.UserName)}
              </TableCell>
              <TableCell className={classes.tableCell}>{i.UserEmail}</TableCell>
              <TableCell className={classes.tableCell}>{GetDateValue(i.CreatedOn)}</TableCell>
              <TableCell className={classes.tableCell}>{GetBoolValue(i.IsAdmin)}</TableCell>
            </ItemTableRow>
          );
        }) 
      : [];
  }

  HandleItemsChanged = items => {
    this.OrganizationMembersForDialog = items || [];
    this.handleDisplayOrganizationMemberDialogIfApplicable(null, items);
  }

  handleDisplayOrganizationMemberDialogIfApplicable = (organizationMemberId, organizationMembers) => {
    if (!organizationMemberId) {
      organizationMemberId = this.props.match.params.collectionItemID;
    }
    if (organizationMemberId) {
      const displayMemberDialog = (organizationMember, extraState) => {
        this.setState({
          OrganizationMemberForOrganizationMemberDialog: organizationMember,
          OrganizationMemberDialogIsOpen: true,
          ...extraState,
        });
      }
      if (!organizationMembers) {
        organizationMembers = [...this.OrganizationMembersForDialog];
      }
      let memberFinder = organizationMembers.filter(tm => tm.ID === organizationMemberId);
      if (!memberFinder.length) {
        API.get(GetOrganizationMemberPathForApi(this.OrganizationID, organizationMemberId))
          .then(resp => {
            displayMemberDialog(resp.data, {
              ForcePrependItems: [resp.data],
            });
          })
          .catch(err => {
            this.handleApiError(err);
            this.handleOrganizationMemberDialogClosed();
          });
      } else {
        displayMemberDialog(memberFinder[0]);
      }
    }
  }

  handleOrganizationMemberDialogClosed = () => {
    const stateToUpdate = {
      OrganizationMemberDialogIsOpen: false,
      ShowDialogProgressIndicatorImmediately: false,
    };
    this.setState(stateToUpdate);
    
    const newPath = GetOrganizationMembersPath(this.OrganizationID);
    if (this.props.location.pathname !== newPath) {
      this.props.history.push(newPath, { ...this.props.location.state, ...stateToUpdate });
    }
  }

  HandleGetDialogContent = state => {
    if (state.OrganizationMemberDialogIsOpen) {
      return (
        <OrganizationMemberDialog
          {...this.props}
          open={state.OrganizationMemberDialogIsOpen || false}
          OrganizationMember={state.OrganizationMemberForOrganizationMemberDialog}
          onOrganizationMemberRevised={this.HandleItemRevised}
          onApiError={this.handleApiError}
          onClose={this.handleOrganizationMemberDialogClosed}
          showProgressIndicatorImmediately={state.ShowDialogProgressIndicatorImmediately}
        />
      );
    }
    else if (state.AddOrganizationMemberDialogIsOpen) {
      return (
        <MultiUseDialog
          Details={{
            Open: state.AddOrganizationMemberDialogIsOpen,
            Title: "Add member",
            RequireTextInput1: true,
            ShowProgressIndicatorImmediately: state.ShowDialogProgressIndicatorImmediately || false,
            TextInput1Label: "Email Address",
            ConfirmLabel: "ADD",
            ConfirmCallback: this.handleAddOrganizationMember,
            CancelCallback: this.handleAddOrganizationMemberDialogClosed,
            CloseCallback: this.handleAddOrganizationMemberDialogClosed,
          }}
        />
      );
    }
  }

  handleAddOrganizationMemberDialogClosed = () => {
    this.setState({
      AddOrganizationMemberDialogIsOpen: false,
    })
  }

  handleAddOrganizationMember = email => {
    if (!email)
      return;
    if (!ValidateEmail(email)) {
      this.handleApiError("Please enter a valid e-mail address.");
      return;
    }
    
    this.setState({ShowDialogProgressIndicatorImmediately: true});
    const organizationMember = {
      UserEmail: email,
    };
    const params = {
      homePath: GetOrganizationLink(this.OrganizationID, GetHomePath()),
    }
    API.post(GetOrganizationMembersPathForApi(this.OrganizationID), [organizationMember], { params })
      .then(resp => {
        this.context.Reset();
        this.setState({
          // Alert: {
          //   Title: "Invitation sent",
          //   BodyText: "An invitation to join this project has been sent."
          // },
          ForcePrependItems: resp.data,
          ShowDialogProgressIndicatorImmediately: false,
        });
        this.handleAddOrganizationMemberDialogClosed();
      })
      .catch(this.handleApiError);
  }

  HandleCreateNew = () => {
    this.setState({
      AddOrganizationMemberDialogIsOpen: true,
    });
  }

  // Returns whether further path changes can occur.
  updateProps(props) {
    Collection.prototype.updateProps.bind(this)(props);

    if (this.props.match.params.collectionItemID && !this.prevProps.match.params.collectionItemID) {
      this.handleDisplayOrganizationMemberDialogIfApplicable(null, null);
      return false;
    } else if (!this.props.match.params.collectionItemID && this.prevProps.match.params.collectionItemID) {
      this.handleOrganizationMemberDialogClosed();
      return false;
    }

    return true;
  }
}