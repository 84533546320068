export const DocumentSignatureFormFieldTypes = [
	{
		Type: "Signature",
		Label: "Signature",
		WidthPercent: 0.35,
		SortRank: 1,
	},
	{
		Type: "Initial",
		Label: "Initial",
		WidthPercent: 0.05,
		SortRank: 0,
	},
	{
		Type: "Date",
		Label: "Date",
		WidthPercent: 0.1,
		SortRank: 2,
	},
	{
		Type: "Name",
		Label: "Name",
		WidthPercent: 0.2,
		SortRank: 3,
	},
	{
		Type: "Title",
		Label: "Title",
		WidthPercent: 0.3,
		SortRank: 4,
	},
	{
		Type: "Email",
		Label: "Email",
		WidthPercent: 0.3,
		SortRank: 5,
	},
];

export function GetExtendedFormField(field) {
	let matchingType = DocumentSignatureFormFieldTypes.filter(t => t.Type === field.Type);
	if (matchingType.length) {
	  return {...field, ...matchingType[0]};
	}
	return null;
}

export function GetExtendedFormFields(formFields) {
	let fields = [];
	formFields.forEach(field => {
	    let extendedField = GetExtendedFormField(field);
	    if (extendedField) {
	    	fields = fields.concat(extendedField);
	    }
	});
	return fields;
}