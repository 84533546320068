import yellow from '@material-ui/core/colors/yellow';
import lightGreen from '@material-ui/core/colors/lightGreen';
import cyan from '@material-ui/core/colors/cyan';
import purple from '@material-ui/core/colors/purple';
import deepOrange from '@material-ui/core/colors/deepOrange';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import deepPurple from '@material-ui/core/colors/deepPurple';
import amber from '@material-ui/core/colors/amber';
import pink from '@material-ui/core/colors/pink';
import teal from '@material-ui/core/colors/teal';
import indigo from '@material-ui/core/colors/indigo';

export const SignatureColorCodes = [
  yellow[200],
  lightGreen[200],
  cyan[200],
  purple[200],
  deepOrange[200],
  green[200],
  blue[200],
  deepPurple[200],
  amber[200],
  pink[200],
  teal[200],
  indigo[200],
];