import React, { Component } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { IsMobile } from '../Util/MobileDetector';

const mobileContentWidth = 850;
const desktopContentWidth = 670;
const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  appBarTitleContainer: {
    display: "flex",
    flexGrow: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    alignItems: "center",
  },
  content: {
    paddingTop: 64,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56,
    },
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.background.default,
    overflow:"hidden",
  },
  container: {
    height: "100%",
    width: (!IsMobile()) ? desktopContentWidth : undefined,
    overflow: "hidden",
  },
  paper: {
    height: "100%",
    backgroundColor: "white",
    overflowY: "hidden",
    overflowX: "auto",
  },
  textObject: {
    width: (IsMobile()) ? mobileContentWidth : desktopContentWidth,
    height: "100%",
  },
});

class Licenses extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
    }
  }

  handleCloseOrNavigateHome = () => {
    window.close();
    this.props.history.push("/");
  }

  render() {
    // const {
    // } = this.state;
    const {
      classes,
      theme,
    } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="fixed" style={{backgroundColor:theme.palette.background.appBar}}>
          <Toolbar>
            <div className={classes.appBarTitleContainer}>
              <Typography variant="h6" color="inherit">
                Open-Source Licenses
              </Typography>
            </div>
            <div>
              <Button variant="contained"
                onClick={this.handleCloseOrNavigateHome}>
                CLOSE
              </Button>
            </div>
          </Toolbar>
        </AppBar>

        <div className={classes.content}>
          <Container 
            className={classes.container}
            maxWidth="md"
            disableGutters
          >
            <Paper className={classes.paper} elevation={1}>
              <object className={classes.textObject} data="/licenses.txt" type="text/plain"> </object>
            </Paper>
          </Container>
        </div>

      </div>
    );
  }
}

export default withStyles(styles, {withTheme: true})(Licenses);