import React, { Component } from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography'
// import Tooltip from '@material-ui/core/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import DashboardCard from '../DashboardCard';

import NavigateIcon from '@material-ui/icons/ExitToApp';

import Icon from '@material-ui/icons/EventNote';
import IconColor from '@material-ui/core/colors/deepOrange';

import API,
{
  GetSupportUpcomingEventsPublicPathForApi,
} from '../../Util/api';
import dateformat from 'dateformat';


const styles = theme => ({
  title: {
    whiteSpace: "nowrap",
    marginLeft: theme.spacing(2),
    overflowX: "hidden",
  },
});

class UpcomingEventsWidget extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      LoadComplete: false,
      SupportUpcomingEvents:  [],
    }
  }

  GetUpcomingEvents() {
    API.get(GetSupportUpcomingEventsPublicPathForApi())
      .then(resp => {
        this.setState({
          SupportUpcomingEvents: resp.data.SupportUpcomingEvents,
          LoadComplete: true,
        });
      })
      .catch(this.props.onApiError);
  }

  handleNavigateToEventUrl = url => {
    window.open(url, "n1_upcomingEvent");
  }

  componentDidMount() {
    this.GetUpcomingEvents();
  }

  render() {
    const {
      LoadComplete,
      SupportUpcomingEvents,
    } = this.state;
    const { 
      classes,
      theme,
      DashboardWidget,
      ...passThroughProps
    } = this.props;

    let upcomingEventsList;
    let noEventsMessage;
    if (SupportUpcomingEvents && SupportUpcomingEvents.length > 0) {
      let upcomingEventsListItems = [];
      for (let i = 0; i < /*Math.min(*/SupportUpcomingEvents.length/*, 7)*/; i++) {
        const sue = SupportUpcomingEvents[i];
        const navigateIcon = (sue.URL)
          ? (
              <NavigateIcon style={{marginLeft:theme.spacing(2)}} />
          ) : null;
        upcomingEventsListItems.push(
          <ListItem button={Boolean(sue.URL)} key={`d_${i}`}
            onClick={(sue.URL) ? () => this.handleNavigateToEventUrl(sue.URL) : undefined}>
            <Typography variant="body2" color="textSecondary" style={{whiteSpace:"nowrap"}}>
              {dateformat(new Date(sue.EventDate), "mm/dd/yyyy")}
            </Typography>
            <Typography variant="body1" className={classes.title}>
              {sue.Title}
            </Typography>
            {navigateIcon}
          </ListItem>
        );
      }
      upcomingEventsList = (
        <List dense style={{padding:0,height:"100%",overflowY:"auto"}}>
          {upcomingEventsListItems}
        </List>
      );
    } else if (LoadComplete) {
      noEventsMessage = (
        <Typography variant="body1" style={{
          display:"flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            textAlign:"center",
        }}>
          New events will be posted soon.
        </Typography>
      );
    }

    return (
      <DashboardCard
        title="Upcoming Events"
        titleIcon={<Icon style={{color: IconColor[500]}} />}
        noFadeout
        xs={12}
        sm={6}
        md={6}
        lg={3}
        xl={3}
        // onCardAction={() => this.navigateToEvent()}
        {...passThroughProps}
        DashboardWidget={DashboardWidget}
      >
        {upcomingEventsList}
        {noEventsMessage}
      </DashboardCard>  
    );
  }
}

UpcomingEventsWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(UpcomingEventsWidget);