import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { GlobalContext } from '../Context/Global.context';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import SelectControl from '../Components/SelectControl';
import DeviceCaptureDialog from '../Components/DeviceCaptureDialog';
import VideoHelpDialog from '../Components/VideoHelpDialog';

import HelpIcon from '@material-ui/icons/HelpOutline';
import AddIcon from '@material-ui/icons/Add';
import RenameIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  ProjectsIcon,
  TasksIcon,
 } from '../Util/Icons';
import FormTemplateIcon from '@material-ui/icons/ViewHeadline';
import FieldsIcon from '@material-ui/icons/TextFields';
import MembersIcon from '@material-ui/icons/Group';
import AssetsIcon from '@material-ui/icons/Category';
import ProcessIcon from '@material-ui/icons/Timeline';
import TaskMilestoneIcon from '@material-ui/icons/Landscape';
import TaskStateIcon from '@material-ui/icons/SyncAlt';
import DocumentUploadIcon from '@material-ui/icons/CloudUpload';
import ImageCaptureIcon from '@material-ui/icons/Scanner';
import DocumentCreateIcon from '@material-ui/icons/NoteAdd';

import Collection from '../Model/Collection';
import { ProjectMembers_Collection } from '../Model/ProjectMembers';
import { ProjectComments_Collection } from '../Model/ProjectComments';
import { ProjectProfile_Collection } from '../Model/ProjectProfile';
import { Tasks_Collection } from '../Model/Tasks';
import { Approvals_Collection } from '../Model/Approvals';
import { FileCapture_Collection } from '../Model/FileCapture';
import { DeviceCapture_Collection } from '../Model/DeviceCapture';
import { Documents_Collection } from '../Model/Documents';
import { DocumentSubscriptions_Collection } from '../Model/DocumentSubscriptions';
import { FormTemplates_Collection } from '../Model/FormTemplates';
import { Processes_Collection } from '../Model/Processes';
import { Fields_Collection } from '../Model/Fields';
import { Assets_Collection } from '../Model/Assets';
import { AssetItems_Collection } from '../Model/AssetItems';
import { TaskMilestones_Collection } from '../Model/TaskMilestones';
import { TaskStates_Collection } from '../Model/TaskStates';
import { NeedSignature_Collection } from '../Model/NeedSignature';
import { Tags_Collection } from '../Model/Tags';
import { RecycleBin_Collection } from '../Model/RecycleBin';

import {
  TasksNodeType,
  FieldsNodeType,
  AssetsNodeType,
  AssetItemsNodeType,
  FormTemplatesNodeType,
  ProcessesNodeType,
  TaskMilestonesNodeType,
  TaskStatesNodeType,
  DocumentsNodeType,
  DocumentFoldersNodeType,
  ProjectMembersNodeType,
  SettingsNodeType,

  SettingsPathElement,
  TasksPathElement,
  AssetsPathElement,
  ArchivedAssetsPathElement,
  AssetItemsPathElementPrefix,
  ArchivedAssetItemsPathElement,
  CompletedTasksPathElement,
  DeletedTasksPathElement,

  GetApprovalsNode,
  // GetAllApprovalsNode,
  GetProjectMembersNode,
  GetProjectCommentsNode,
  GetTasksNode,
  GetCompletedTasksNode,
  GetDeletedTasksNode,
  GetNeedSignatureNode,
  GetFileCaptureNode,
  GetDocumentsNode,
  GetDocumentSubscriptionsNode,
  GetFormTemplatesNode,
  GetFormsNode,
  GetFieldsNode,
  GetAssetsNode,
  GetAssetItemsNode,
  GetArchivedAssetsNode,
  GetArchivedAssetItemsNode,
  GetProcessesNode,
  GetTaskMilestonesNode,
  GetTaskStatesNode,
  GetRecycleBinNode,
  GetProjectSettingsNode,
  GetProjectProfileNode,
  GetTagsNode,

  GetUserOrganizationAssignmentTypeFromNodeType,

  DocumentsNodeDefaultName,
  DocumentsNodeDefaultNameForRestrictiveAccess,
} from '../Util/Nodes';

import ItemCollectionBase from '../Components/ItemCollectionBase';
import {
  GetTreeNodeComponent,
  GetNodeForGetMoreItems,
  TreeComponentDidUpdate,
  SetSelectedNodeByCurrentLocationRecursive,
} from '../Util/Tree';
import {
  GetAndSetProjectsPackageForTreeNodesAsPromise,
} from '../Util/Projects';
import {
  GetIconById,
} from '../Util/Icons';
import {
  IsUserProjectAdmin,
} from '../Util/User';
import ActionType from '../Model/ActionType';
import {
  HandlePreCaptureFieldGathering,
  GetPreCaptureFieldGatheringContent,
} from '../Util/PreCaptureFieldGathering';

import ProjectCreationDialog from '../Admin/Components/ProjectCreationDialog';
import ProjectMemberCreationDialog from '../Admin/Components/ProjectMemberCreationDialog';
import TaskDialog from '../Components/TaskDialog';
import AssetCreationDialog from '../Components/AssetCreationDialog';
import AssetItemDialog from '../Components/AssetItemDialog';
import FieldCreationDialog from '../Components/FieldCreationDialog';
import FormTemplateCreationDialog from '../Components/FormTemplateCreationDialog';
import ProcessCreationDialog from '../Components/ProcessCreationDialog';
import TaskMilestoneCreationDialog from '../Components/TaskMilestoneCreationDialog';
import TaskStateCreationDialog from '../Components/TaskStateCreationDialog';
import CaptureCore from '../Components/CaptureCore';

import {
  GetProjectTasksPath,
  GetProjectMembersPath,
  GetProjectAssetsPath,
  GetProjectAssetPath,
  GetProjectAssetItemsPath,
  GetProjectFieldsPath,
  GetProjectFieldPath,
  GetProjectFormTemplatesPath,
  GetProjectDocumentsPath,
  GetFormTemplateDesignerPath,
  GetProjectProcessesPath,
  GetProjectTaskMilestonesPath,
  GetProjectTaskStatesPath,
  GetProcessDesignerPath,
} from '../Util/PathHelper';
import DocumentFolders, {
  DocumentFoldersPathElement,
} from '../Util/DocumentFolders';
import {
  OpenDocumentDetailTab,
  AreDocumentsOrDocumentFoldersLoadedByAssignment,
} from '../Util/Documents';
import API, {
  GetProjectsPathForApi,
  GetProjectPathForApi,
  GetDocumentsPathForApi,
  GetProjectSettingsPathForApi,
  GetPublicVideoPath,
  GetAssetsPathForApi,
} from '../Util/api';
import MultiUseDialog from '../Components/MultiUseDialog';
import { 
  GetCurrentViewType,
  GetViewTypeName,
  GetIdSubPrefix,
  ViewType_KanbanMilestone
} from '../Util/ViewType';

import queryString from 'query-string';
import { IsMobile } from '../Util/MobileDetector';
import { IsMicrosoftWindows } from '../Util/MicrosoftWindowsDetector';
import debounce from 'es6-promise-debounce';

const ProjectRootNodeType = "projectRoot";

const styles = theme => ({
  createNewButton: {
    // minWidth:100,
    borderRadius:20,
    minHeight:42,
  },
  leftPaneContent: {
    display:"flex",
    flexDirection:"column",
    overflowY:"hidden",
    height:"100%",
  },
  leftPaneTreeContainer: {
    scrollBehavior: "smooth",
    marginTop:theme.spacing(2),
    marginBottom:theme.spacing(5), // This is necessary to ensure the collapse button is visible
    flexGrow:1,
    overflowY:"auto",
  },
});

class Projects extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.state = {
      RootNodes: [],
      CollectionNodes: [],
      SettingNodes: [],
      TaskNodes: [],
      AssetNodes: [],
      CompletedGET: {},
      ProjectsPackage: null,
      SelectedNode: null,
      ForcePrependItems: [],
      ForceRefresh: false,
      ForceRefreshKanbanColumns: false,
      FabMenu_MouseY: null,
      FabMenu_MouseX: null,
      TotalItems: 0,
      TasksViewType: "",
      TasksViewName: "",
      ItemTitle: "",
      ProjectSettings: {},
      Assets: [],
      AssetForCreationDialog: null,
      CollectionUpdateID: null,
      ContextMenuNode: null,
      ImageCaptureDocumentFolderID: null,
      ImageCaptureDocumentFolderName: null,
      FieldIDsAndValuesForCapture: null,
      CreateNewMenu_MouseY: null,
      CreateNewMenu_MouseX: null,
      ShowAddProjectDialog: false,
      ShowAddProjectMemberDialog: false,
      ShowAddTaskDialog: false,
      ShowAddFieldDialog: false,
      ShowAddAssetDialog: false,
      ShowAddAssetItemDialog: false,
      ShowAddFormTemplateDialog: false,
      ShowAddProcessDialog: false,
      ShowAddTaskMilestoneDialog: false,
      ShowAddTaskStateDialog: false,
      ShowRenameProjectDialog: false,
      ShowDeviceCaptureDialog: false,
      ShowDeleteProjectConfirmation: false,
      ShowTaskHelpDialog: false,
      ShowAssetHelpDialog: false,
      ShowProgressIndicatorImmediately: false,
    }

    this.DocumentFolders = null;
    this.Collection = new Collection(this.props, state => this.setState(state), this.handleApiError);
    this.BeginFileUploadFunc = null;
    this.NodeExpandFunctionsByUniqueId = [];
    // this.NodeCollapseFunctionsByUniqueId = [];
    this.ChildNodesByParentNodeUniqueId = [];
    this.ResetAutoRefreshFunc = null;
    this.PostFieldGatheringCaptureFunc = null;
    this.AdditionalBodyTextForFieldGatheringDialog = null;
  }

  getProjectSettings = () => {
    const {
      projectID,
    } = this.props.match.params;
    // Delay until we have some context and settings
    if (!projectID
      || !this.context
      || !this.context.CompletedGET.ActiveOrganizationPermissions) {
      setTimeout(() => this.getProjectSettings(), 250);
      return;
    }
    const {
      OrganizationID
    } = this.context.ActiveOrganizationPermissions;

    return API.get(GetProjectSettingsPathForApi(OrganizationID, projectID))
      .then(resp => {
        const ProjectSettings = resp.data;
        this.setState({ ProjectSettings });
        this.setDocumentsNodePropertiesFromProjectSetting(ProjectSettings,
          projectID, "AllowPublicDocumentFolders", ProjectSettings.AllowPublicDocumentFolders);
        this.setDocumentsNodePropertiesFromProjectSetting(ProjectSettings,
          projectID, "DocumentRootLabel", ProjectSettings.DocumentRootLabel);

        return ProjectSettings;
      })
      .finally(() => {
        this.setState({CompletedGET:{...this.state.CompletedGET, ProjectSettings: true}});
      });
  }

  getAssets = (organizationId, projectId) => {
    return API.get(GetAssetsPathForApi(organizationId, projectId), { params: { getAll: true }})
      .then(resp => {
        const assetList = resp.data;
        this.setState({ Assets: assetList.Assets });
        return assetList.Assets;
      })
      .finally(() => {
        this.setState({CompletedGET:{...this.state.CompletedGET, Assets: true}});
      });
  }

  initiateDocumentFolders = () => {
    // Delay until we have ActiveOrganizationPermissions
    if (!this.context
      || !this.context.CompletedGET.ActiveOrganizationPermissions
      || !this.context.CompletedGET.ProjectMembershipPackages) {
      setTimeout(() => this.initiateDocumentFolders(), 250);
      return;
    }
    const {
      projectID,
    } = this.props.match.params;
    this.DocumentFolders = new DocumentFolders(
      this.state,
      state => this.setState(state),
      this.handleApiError,
      this.context.ActiveOrganizationPermissions.OrganizationID,
      projectID,
      false,
      AreDocumentsOrDocumentFoldersLoadedByAssignment(this.context, projectID),
      this.context.UserPreferences.UserEmail,
      false,
      this.handleBeginDocumentUpload,
      this.handleBeginImageCapture,
      this.handleBeginDocumentCreation,
      this.context.SetReloadItemsFlag,
      this.NodeExpandFunctionsByUniqueId,
      this.setLocalNodeExpandFunction,
      this.handleDocumentFolderNodeCreated,
      null,
      null,
      null,
      this.setDocumentsNodePropertiesFromProjectSettingExternal,
      IsUserProjectAdmin(this.context, projectID),
    );
  }

  getCurrentTasksViewName = () => {
    GetCurrentViewType(
      "Tasks",
      GetIdSubPrefix("Tasks", IsMobile(), false),
      null,
      ViewType_KanbanMilestone,
      this.handleApiError,
    )
      .then(TasksViewType => {
        this.setState({TasksViewType});
        this.setTasksViewName(TasksViewType);
      });
  }

  setTasksViewName = viewType => {
    const TasksViewName = (viewType)
      ? GetViewTypeName(viewType)
      : "";
    this.setState({TasksViewName});
    const CollectionNodes = [...this.state.CollectionNodes];
    const tasksNodeFinder = CollectionNodes.filter(n => n.NodeType === TasksNodeType);
    if (tasksNodeFinder.length) {
      tasksNodeFinder[0].NameSuffix = TasksViewName;
      this.setState({CollectionNodes});
    }
  }

  setDocumentsNodePropertiesFromProjectSettingExternal = debounce((projectId, projectSettingId, value) => {
    return this.setDocumentsNodePropertiesFromProjectSetting(null, projectId, projectSettingId, value);
  }, 1000);

  setDocumentsNodePropertiesFromProjectSetting = (ProjectSettings, projectId, projectSettingId, value) => {
    if (!ProjectSettings) {
      ProjectSettings = this.state.ProjectSettings;
    }
    const CollectionNodes = [...this.state.CollectionNodes];
    const documentsNodeFinder = CollectionNodes.filter(n => n.NodeType === DocumentsNodeType);
    if (documentsNodeFinder.length) {
      switch (projectSettingId) {
        case "AllowPublicDocumentFolders":
          documentsNodeFinder[0].CustomIconID = (value) ? "sharedFolder" : null;
          break;
        case "DocumentRootLabel":
          if (!value) {
            if (AreDocumentsOrDocumentFoldersLoadedByAssignment(this.context, projectId)) {
              value = DocumentsNodeDefaultNameForRestrictiveAccess;
            } else {
              value = DocumentsNodeDefaultName;
            }
          }
          documentsNodeFinder[0].Name = value;
          break;
        default:
          break;
      }
      ProjectSettings[projectSettingId] = value;
      this.setState({
        CollectionNodes,
        ProjectSettings,
      });
    }
  }

  componentDidMount() {
    this.getProjectSettings();
    this.initiateDocumentFolders();
    this.setCollection();
    this.setRootNodes(true, false, true);
    this.getCurrentTasksViewName();
  }

  componentDidUpdate(prevProps, prevState) {
    const allowPathCheck =
      this.props.match.params.collectionID !== prevProps.match.params.collectionID
      || this.getAssetIdFromPath(this.props) !== this.getAssetIdFromPath(prevProps)
      || this.props.match.params.projectID !== prevProps.match.params.projectID
      || this.Collection.updateProps(this.props);
    if (allowPathCheck) {
      TreeComponentDidUpdate(prevProps.location, this.props.location, state => this.setState(state));
      if (this.props.match.params.projectID !== prevProps.match.params.projectID) {
        this.getProjectSettings();
        this.initiateDocumentFolders();
        this.setCollection();
        this.setRootNodes(false, false);
      } else if (this.props.match.params.collectionID !== prevProps.match.params.collectionID
        || this.props.match.params.documentFolderID !== prevProps.match.params.documentFolderID
        || this.getAssetIdFromPath(this.props) !== this.getAssetIdFromPath(prevProps)) {
        this.setCollection();
        this.ensureSelectedNode();
      }
    } else {
      this.ensureSelectedNode();
    }
  }

  setChildNodesForParent = (parentNodeUniqueId, ChildNodes) => {
    this.ChildNodesByParentNodeUniqueId = this.ChildNodesByParentNodeUniqueId.filter(n => n.UniqueId !== parentNodeUniqueId);
    this.ChildNodesByParentNodeUniqueId.push({
      UniqueId: parentNodeUniqueId,
      ChildNodes,
    });
  }

  setCollection = () => {
    // This is important as it will catch 401s, which when passed to UiCore will redirect to /login.
    if (this.context && this.context.ApiError) {
      this.setState({ApiError:this.context.ApiError});
      return;
    }
    const {
      collectionID,
      projectID,
      documentFolderID,
    } = this.props.match.params;
    // Delay until we have some context and settings
    if (!this.context
      || !this.context.CompletedGET.ActiveOrganizationPermissions
      || !this.context.CompletedGET.ProjectMembershipPackages
      || !this.context.CompletedGET.UserPreferences
      || !this.state.CompletedGET.Assets
    ) {
      setTimeout(() => this.setCollection(), 250);
      return;
    }
    const {
      OrganizationID
    } = this.context.ActiveOrganizationPermissions;
    const {
      UserEmail,
    } = this.context.UserPreferences;
    this.setState({
      TotalItems:0,
    });
    
    let documentFolderId;
    if (documentFolderID) {
      const ids = documentFolderID.split("/");
      documentFolderId = ids[ids.length - 1];
    }

    const projectMembershipPkg = this.context.ProjectMembershipPackages.find(pmp => pmp.Project.ID === projectID);

    let c;
    if (collectionID) {
      let switchId = collectionID;
      if (collectionID.startsWith(AssetItemsPathElementPrefix)) {
        switchId = AssetItemsPathElementPrefix;
      }
      switch (switchId) {
        case "comments":
          c = new ProjectComments_Collection(this.props, state => this.setState(state), this.handleApiError, this.handleAlert,
            OrganizationID, projectID);
          break;
        case "profile":
          c = new ProjectProfile_Collection(this.props, state => this.setState(state), this.handleApiError, this.handleAlert,
            OrganizationID, this.state.SelectedNode.Project);
          break;
        case "tasks":
          c = new Tasks_Collection(this.props, name => this.state[name], state => this.setState(state), this.handleApiError, this.handleAlert,
            false, true, OrganizationID, projectID, UserEmail, false, false, 
            this.handleResetAutoRefresh, this.handleItemTitleChange, !IsMobile());
          break;
        case "completedTasks":
          c = new Tasks_Collection(this.props, name => this.state[name], state => this.setState(state), this.handleApiError, this.handleAlert,
            false, true, OrganizationID, projectID, UserEmail, true, false,
            null, this.handleItemTitleChange, false);
          break;
        case "deletedTasks":
          c = new Tasks_Collection(this.props, name => this.state[name], state => this.setState(state), this.handleApiError, this.handleAlert,
            false, true, OrganizationID, projectID, UserEmail, false, true,
            null, this.handleItemTitleChange, false);
          break;
        case "approvals":
          c = new Approvals_Collection(this.props, state => this.setState(state), this.handleApiError, this.handleAlert,
            false, true, OrganizationID, projectID, UserEmail, 
            projectMembershipPkg && 
              (projectMembershipPkg.IsAdmin || projectMembershipPkg.Project.Access.MemberAccess.Approvals === "All")
          );
          break;
        case "fileCapture":
          c = new FileCapture_Collection(this.props, state => this.setState(state), this.handleApiError, this.handleAlert,
            false, true, OrganizationID, projectID);
          break;
        case "deviceCapture":
          c = new DeviceCapture_Collection(this.props, state => this.setState(state), this.handleApiError, this.handleAlert,
            false, true, OrganizationID, projectID);
          break;
        case "documents":
          c = new Documents_Collection(this.props, state => this.setState(state), this.handleApiError,
            false, true, false, false,
            OrganizationID, projectID, documentFolderId, null,
            this.BeginFileUploadFunc, 
            (IsMicrosoftWindows() && !IsMobile())
              ? this.handleBeginImageCapture
              : null,
            this.handleBeginDocumentCreation,
            true,
          );
          break;
        case "signatures":
          c = new NeedSignature_Collection(this.props, state => this.setState(state), this.handleApiError,
            false, true, OrganizationID, projectID, this.context);
          break;
        case "recycleBin":
          c = new RecycleBin_Collection(this.props, state => this.setState(state), this.handleApiError,
            false, true, OrganizationID, projectID);
          break;
        case "subscriptions":
          c = new DocumentSubscriptions_Collection(this.props, state => this.setState(state), this.handleApiError,
            false, true, OrganizationID, projectID);
          break;
        case "members":
          c = new ProjectMembers_Collection(this.props, state => this.setState(state), this.handleApiError,
            false, true, OrganizationID, projectID, this.context,
            this.handleItemTitleChange);
          break;
        case "tags":
          c = new Tags_Collection(this.props, state => this.setState(state), this.handleApiError,
            false, true, OrganizationID, projectID, this.context, this.handleItemTitleChange);
          break;
        case "formTemplates":
          c = new FormTemplates_Collection(this.props, state => this.setState(state), this.handleApiError, this.handleAlert,
            false, true, OrganizationID, projectID, false, IsUserProjectAdmin(this.context, projectID));
          break;
        case "forms":
          c = new FormTemplates_Collection(this.props, state => this.setState(state), this.handleApiError, this.handleAlert,
            false, true, OrganizationID, projectID, true, IsUserProjectAdmin(this.context, projectID));
          break;
        case "processes":
          c = new Processes_Collection(this.props, state => this.setState(state), this.handleApiError, 
            false, true, OrganizationID, projectID);
          break;
        case "fields":
          c = new Fields_Collection(this.props, state => this.setState(state), this.handleApiError,
            false, true, OrganizationID, projectID, this.handleItemTitleChange);
          break;
        case "assets":
          c = new Assets_Collection(this.props, state => this.setState(state), this.handleApiError,
            false, true, OrganizationID, projectID, true, false);
          break;
        case "archivedAssets":
          c = new Assets_Collection(this.props, state => this.setState(state), this.handleApiError,
            false, true, OrganizationID, projectID, false, true);
          break;
        case AssetItemsPathElementPrefix:
          const asset = this.getAssetFromPath();
          if (asset) {
            c = new AssetItems_Collection(this.props, state => this.setState(state), this.handleApiError,
              this.handleAlert, false, true, OrganizationID, projectID, asset,
              true, false, false, false,
              this.state.Assets);
          }
          break;
        case "archivedAssetItems":
          const assetId = this.props.match.params.assetID;
          if (assetId) {
            const asset = this.getAsset(assetId);
            c = new AssetItems_Collection(this.props, state => this.setState(state), this.handleApiError,
              this.handleAlert, false, true, OrganizationID, projectID, asset,
              false, true, false, false,
              this.state.Assets);
          }
          break;
        case "taskMilestones":
          c = new TaskMilestones_Collection(this.props, state => this.setState(state), this.handleApiError,
            false, true, OrganizationID, projectID, this.handleItemTitleChange);
          break;
        case "taskStates":
          c = new TaskStates_Collection(this.props, state => this.setState(state), this.handleApiError,
            false, true, OrganizationID, projectID, this.handleItemTitleChange);
          break;
        default:
          break;
      }
    }
    if (c) {
      this.Collection = c;
    }
    const qs = queryString.parse(this.props.location.search);
    if (qs.addNew) {
      this.Collection.HandleCreateNew();
    }
    if (qs.createRootFolder) {
      this.tryCreateRootFolderWhenReady();
    }
    // This ensures ItemCollectionBase always sees the new collection
    this.setState({CollectionUpdateID: new Date()});
  }

  getAssetIdFromPath = props => {
    if (!props) {
      props = this.props;
    }
    if (props.match.params.assetID) {
      return props.match.params.assetID;
    }
    const collectionId = props.match.params.collectionID;
    if (!collectionId || !collectionId.startsWith(AssetItemsPathElementPrefix)) {
      return null;
    }
    return collectionId.substr(AssetItemsPathElementPrefix.length);
  }

  getAssetFromPath = () => {
    const assetId = this.getAssetIdFromPath();
    if (!assetId) {
      return null;
    }
    return this.getAsset(assetId);
  }

  getAsset = assetId => {
    const assetFinder = this.state.Assets.filter(a => a.ID === assetId);
    if (assetFinder.length) {
      return assetFinder[0];
    }
    return null;
  }

  handleItemTitleChange = ItemTitle => {
    this.setState({ItemTitle});
  }

  tryCreateRootFolderWhenReady = () => {
    if (!this.state.CollectionNodes || !this.state.CollectionNodes.length || !this.DocumentFolders) {
      return setTimeout(() => this.tryCreateRootFolderWhenReady(), 250);
    }
    const documentsNodeFinder = this.state.CollectionNodes
      .filter(n => n.NodeType === DocumentsNodeType);
    if (documentsNodeFinder.length) {
      this.DocumentFolders.HandleContextMenuAction(null, documentsNodeFinder[0], "folderCreate");
    }
  }

  handleApiError = ApiError => {
    this.setState({ 
      ApiError,
      ShowProgressIndicator: false,
    });
    if (ApiError) {
      setTimeout(() => this.handleApiError(null), 1);
    }
  }

  handleAlert = Alert => {
    this.setState({ Alert });
  }

  handleRefresh = () => {
    this.setState({
      CollectionUpdateID:new Date(),
      ForceRefresh: true,
    });
    setTimeout(() => this.setState({ForceRefresh:false}), 1);
  }

  handleSetResetAutoRefreshFunc = f => {
    this.ResetAutoRefreshFunc = f;
  }

  handleResetAutoRefresh = () => {
    if (this.ResetAutoRefreshFunc) {
      this.ResetAutoRefreshFunc();
    }
  }

  setRootNodes = (reset, getMore, isComponentMount) => {
    // Delay until we have some context
    if (!this.context.CompletedGET.ActiveOrganizationPermissions
      || !this.context.CompletedGET.ProjectMembershipPackages
      || !this.context.CompletedGET.UserPreferences) {
      setTimeout(() => this.setRootNodes(reset, getMore), 250);
      return;
    }
    if (!this.context.ActiveOrganizationPermissions.OrganizationID) {
      // This is important as it will catch 402s, which when passed to UiCore will redirect to subscription-expired screen
      if (this.context.ApiError) {
        this.setState({ApiError:this.context.ApiError});
        return;
      }
      this.handleApiError("You do not have access to any projects in this organization.");
      return;
    }
    let optionalSelectedProjectId = this.props.match.params.projectID;
    if (!optionalSelectedProjectId) {
      optionalSelectedProjectId = this.context.UserPreferences.ActiveProjectID;
    }
    const uri = GetProjectsPathForApi(this.context.ActiveOrganizationPermissions.OrganizationID);
    GetAndSetProjectsPackageForTreeNodesAsPromise(this.props, this.state, state => this.setState(state), this.handleApiError,
        uri, optionalSelectedProjectId, 
        GetUserOrganizationAssignmentTypeFromNodeType(this.props.match.params.collectionID),
        "Projects", "ID", reset, getMore)
      .then(projectsPackage => {
        if (!projectsPackage || !projectsPackage.Projects) {
          return Promise.resolve([]);
        }
        const NodeType = ProjectRootNodeType;
        let RootNodes = [];
        let processedFirstProject = false;
        let processedAllProjects = false;
        projectsPackage.Projects.forEach((p, ItemIndex) => {
          let HasAccessToAllDocuments = projectsPackage.IsOrganizationAdmin
            || p.Access.MemberAccess.DocumentFolders === "All";
          if (!HasAccessToAllDocuments
            && p.Access.MemberAccess.DocumentFolders === "Assigned") {
            const projectMember = projectsPackage.ProjectMembers.find(pm => pm.ProjectID === p.ID);
            if (projectMember) {
              HasAccessToAllDocuments = projectMember.IsAdmin;
            }
          }
          const isAllProjects = false;
          const UniqueId = `${NodeType}_${p.ID}_${ItemIndex.toString()}`;
          const Open = (optionalSelectedProjectId && projectsPackage.Projects
            .filter(p => p.ID === optionalSelectedProjectId).length)
              ? optionalSelectedProjectId === p.ID : ItemIndex === 0;
          const node = {
            NodeType,
            // CustomIconID: "projects",
            NoIcon: true,
            Name: p.Name,
            Project: p,
            ProjectID: p.ID,
            ProjectAccess: p.Access,
            HasAccessToAllDocuments,
            IsRoot: true,
            RootId: UniqueId,
            UniqueId,
            ItemIndex,
            HasChildren: true,
            NoSelect: true,
            Open,
            MarginTop: (RootNodes.length && (isAllProjects && !processedAllProjects))
              ? this.props.theme.spacing(2)
              : undefined,
            ComponentMountProjectID: optionalSelectedProjectId,
            Url: `/projects/${p.ID}`,
          };
          if (!processedFirstProject) {
            processedFirstProject = true;
          }
          if (!processedAllProjects && isAllProjects) {
            processedAllProjects = true;
          }
          RootNodes.push(node);
        });
        if (projectsPackage.ShowGetMore) {
          RootNodes.push(GetNodeForGetMoreItems());
        }
        this.setState({ RootNodes });
        this.ensureSelectedNode();
      });
  }

  getAndSetCollectionNodesAsPromise = async (parentNode, disallowChildSelectOnLoad) => {
    this.context.SaveUserPreferences_ActiveProject(parentNode.ProjectID);
    
    let itemIndex = 0;
    const optionalSelectedCollectionItemId = this.props.match.params.collectionItemID;
    const settingPathIsActive = this.props.location.pathname.indexOf(SettingsPathElement) > -1;
    const completedTasksPathIsActive = this.props.location.pathname.indexOf(CompletedTasksPathElement) > -1;
    const deletedTasksPathIsActive = this.props.location.pathname.indexOf(DeletedTasksPathElement) > -1;
    const documentFolderPathIsActive = this.props.location.pathname.indexOf(DocumentFoldersPathElement) > -1;
    const archivedAssetItemsPathIsActive = this.props.location.pathname.indexOf(ArchivedAssetItemsPathElement) > -1;
    const getNode = baseNode => {
      const Url = `/projects/${parentNode.ProjectID}${baseNode.PathElement}`;
      const childNode = {
        ...baseNode,
        RootId: parentNode.RootId,
        UniqueId: `${parentNode.ProjectID}_${baseNode.UniqueId}`,
        ParentNode: parentNode,
        Project: parentNode.Project,
        ProjectID: parentNode.ProjectID,
        ProjectAccess: parentNode.ProjectAccess,
        HasChildren: 
          baseNode.NodeType === DocumentsNodeType
          || baseNode.NodeType === SettingsNodeType
          || (baseNode.NodeType === TasksNodeType && (IsUserProjectAdmin(this.context, parentNode.ProjectID) || parentNode.ProjectAccess.MemberAccess.Tasks === "All"))
          || baseNode.NodeType === AssetItemsNodeType,
        NoSelect: baseNode.NoSelect || baseNode.NodeType === SettingsNodeType,
        SelectOnMount: Url === this.props.location.pathname,
        Open: (baseNode.NodeType === TasksNodeType && (completedTasksPathIsActive || deletedTasksPathIsActive))
          || (baseNode.NodeType === SettingsNodeType && settingPathIsActive)
          || (baseNode.NodeType === AssetItemsNodeType && archivedAssetItemsPathIsActive)
          || (baseNode.NodeType === DocumentsNodeType && documentFolderPathIsActive
            && parentNode.ComponentMountProjectID === parentNode.ProjectID),
        ItemIndex: itemIndex++,
        Url,
      };
      // Ensure this node is selected (for breadcrumbs) if a child of this node is active
      if (optionalSelectedCollectionItemId && this.props.location.pathname.startsWith(Url)) {
        this.setState({SelectedNode:childNode});
      }
      return childNode;
    }
    // Always Visible Nodes
    let CollectionNodes = [];
    const documentsNode = GetDocumentsNode();
    if (AreDocumentsOrDocumentFoldersLoadedByAssignment(this.context, parentNode.ProjectID)) {
      documentsNode.NoSelect = true;
      documentsNode.Name = DocumentsNodeDefaultNameForRestrictiveAccess;
    }
    if (this.state.ProjectSettings.ProjectID === parentNode.ProjectID
      && this.state.ProjectSettings.DocumentRootLabel) {
      documentsNode.Name = this.state.ProjectSettings.DocumentRootLabel;
    }
    if (IsMobile()) {
      documentsNode.Name = "All Documents";
    }
    if (this.state.ProjectSettings.AllowPublicDocumentFolders) {
      documentsNode.CustomIconID = "sharedFolder";
    }

    // if (!parentNode.HasAccessToAllDocuments) {
    //   documentsNode.NoSelect = true;
    //   documentsNode.Name = "Document Folders";
    // }
    // documentsNode.HasAccessToAllDocuments = parentNode.HasAccessToAllDocuments;
    CollectionNodes.push(
      getNode(GetProjectCommentsNode()),
      getNode(GetTasksNode(this.state.TasksViewName)),
      getNode(GetApprovalsNode()),
    );
    if (parentNode.HasAccessToAllDocuments) {
      CollectionNodes.push(
        getNode(GetNeedSignatureNode()),
      );
    }
    CollectionNodes.push(
      getNode(documentsNode),
    );
    if (parentNode.HasAccessToAllDocuments) {
      CollectionNodes.push(
        getNode(GetDocumentSubscriptionsNode()),
      );
    }
    CollectionNodes.push(getNode(GetFormsNode()));

    let assets = [];
    if (IsUserProjectAdmin(this.context, parentNode.ProjectID)
      || parentNode.ProjectAccess.MemberAccess.AssetItems === "All") {
      assets = await this.getAssets(
        this.context.ActiveOrganizationPermissions.OrganizationID,
        parentNode.ProjectID,
      );
    } else {
      this.setState({CompletedGET:{...this.state.CompletedGET, Assets: true}});
    }
    assets.forEach(a => {
      CollectionNodes.push(
        getNode(GetAssetItemsNode(a)),
      );
    });

    if (IsMobile()) {
      CollectionNodes.push(
        getNode(GetFileCaptureNode()),
      );
    }
    if (IsUserProjectAdmin(this.context, parentNode.ProjectID) && !IsMobile()) {
      CollectionNodes.push(getNode(GetProjectSettingsNode()));
    }
    if (parentNode.HasAccessToAllDocuments) {
      CollectionNodes.push(
        getNode(GetRecycleBinNode()),
      );
    }

    // Set SelectOnMount to the default (Tasks) if none of the nodes already have it set
    // and other criteria is met
    if (!CollectionNodes.filter(cn => cn.SelectOnMount === true).length) {
      const defaultNodeType = TasksNodeType;
      const defaultNodeFinder = CollectionNodes.filter(cn => cn.NodeType === defaultNodeType);
      if (defaultNodeFinder.length) {
        defaultNodeFinder[0].SelectOnMount = 
        !disallowChildSelectOnLoad 
        && !(this.props.location.pathname.startsWith(parentNode.Url) && !parentNode.IsRoot)
        && !optionalSelectedCollectionItemId
        && (
          !documentFolderPathIsActive
          || parentNode.ComponentMountProjectID !== parentNode.ProjectID
        )
        && (
          !settingPathIsActive
          || parentNode.ComponentMountProjectID !== parentNode.ProjectID
        )
        && (
          !(completedTasksPathIsActive || deletedTasksPathIsActive)
          || parentNode.ComponentMountProjectID !== parentNode.ProjectID
        )
        && (
          !(archivedAssetItemsPathIsActive)
          || parentNode.ComponentMountProjectID !== parentNode.ProjectID
        );
      }
    }

    this.setChildNodesForParent(parentNode.UniqueId, CollectionNodes);

    // CollectionNodes in state are for tabs/mobile view and other reasons
    this.setState({ CollectionNodes });
    return Promise.resolve(CollectionNodes);
  }

  getAndSetCollectionSubNodesAsPromise = (parentNode, disallowChildSelectOnLoad) => {
    switch (parentNode.NodeType) {
      case TasksNodeType:
        return this.getAndSetTaskNodesAsPromise(parentNode, disallowChildSelectOnLoad);
      case DocumentFoldersNodeType:
      case DocumentsNodeType:
        return this.DocumentFolders.GetAndSetDocumentFolderNodesAsPromise(this.props, this.state, this.setChildNodesForParent)
          (parentNode, disallowChildSelectOnLoad);
      case AssetItemsNodeType:
        return this.getAndSetAssetItemNodesAsPromise(parentNode, disallowChildSelectOnLoad);
      case SettingsNodeType:
        return this.getAndSetSettingNodesAsPromise(parentNode, disallowChildSelectOnLoad);
      default:
        return Promise.resolve([]);
    }
  }

  getAndSetSettingNodesAsPromise = (parentNode, disallowChildSelectOnLoad) => {
    let itemIndex = 0;
    const archivedAssetsPathIsActive = this.props.location.pathname.indexOf(ArchivedAssetsPathElement) > -1;
    const getNode = baseNode => {
      const Url = `/projects/${parentNode.ProjectID}${SettingsPathElement}${baseNode.PathElement}`;
      const optionalSelectedCollectionItemId = this.props.match.params.collectionItemID;
      const childNode = {
        ...baseNode,
        RootId: parentNode.RootId,
        UniqueId: `${parentNode.ProjectID}_${baseNode.UniqueId}`,
        ParentNode: parentNode,
        Project: parentNode.Project,
        ProjectID: parentNode.ProjectID,
        HasChildren: 
          baseNode.NodeType === AssetsNodeType,
        Open: (baseNode.NodeType === AssetsNodeType && (archivedAssetsPathIsActive)),
        // SelectOnMount: Url === this.props.location.pathname,
        // SelectOnMount: itemIndex === 0
        //   && !disallowChildSelectOnLoad 
        //   && !this.props.location.pathname.startsWith(parentNode.Url)
        //   && !optionalSelectedCollectionItemId
        //   ,
        ItemIndex: itemIndex++,
        Url,
      };
      // Ensure this node is selected (for breadcrumbs) if a child of this node is active
      if (optionalSelectedCollectionItemId && this.props.location.pathname.startsWith(Url)) {
        this.setState({SelectedNode:childNode});
      }
      return childNode;
    }
    // Always Visible Nodes
    const SettingNodes = (IsUserProjectAdmin(this.context, parentNode.ProjectID))
      ? [
        getNode(GetProjectProfileNode()),
        getNode(GetProjectMembersNode()),
        getNode(GetFieldsNode()),
        getNode(GetAssetsNode()),
        getNode(GetFormTemplatesNode()),
        getNode(GetTagsNode()),
        getNode(GetTaskMilestonesNode()),
        getNode(GetTaskStatesNode()),
        getNode(GetProcessesNode()),
      ]
      : null;
    
    this.setChildNodesForParent(parentNode.UniqueId, SettingNodes);

    // SettingNodes in state are for tabs/mobile view
    this.setState({SettingNodes});
    return Promise.resolve(SettingNodes);
  }

  getAndSetSettingsSubNodesAsPromise = (parentNode, disallowChildSelectOnLoad) => {
    switch (parentNode.NodeType) {
      case AssetsNodeType:
        return this.getAndSetAssetNodesAsPromise(parentNode, disallowChildSelectOnLoad);
      default:
        return Promise.resolve([]);
    }
  }

  getAndSetTaskNodesAsPromise = (parentNode, disallowChildSelectOnLoad) => {
    if (!IsUserProjectAdmin(this.context, parentNode.ProjectID)
      && parentNode.ProjectAccess.MemberAccess.Tasks === "Assigned") {
      return Promise.resolve([]);
    }
    
    let itemIndex = 0;
    const getNode = baseNode => {
      const Url = `/projects/${parentNode.ProjectID}${TasksPathElement}${baseNode.PathElement}`;
      const optionalSelectedCollectionItemId = this.props.match.params.collectionItemID;
      const childNode = {
        ...baseNode,
        RootId: parentNode.RootId,
        UniqueId: `${parentNode.ProjectID}_${baseNode.UniqueId}`,
        ParentNode: parentNode,
        ProjectID: parentNode.ProjectID,
        HasChildren: false,
        // SelectOnMount: itemIndex === 0
        //   && !disallowChildSelectOnLoad 
        //   && !this.props.location.pathname.startsWith(parentNode.Url)
        //   && !optionalSelectedCollectionItemId
        //   ,
        ItemIndex: itemIndex++,
        Url,
      };
      // Ensure this node is selected (for breadcrumbs) if a child of this node is active
      if (optionalSelectedCollectionItemId && this.props.location.pathname.startsWith(Url)) {
        this.setState({SelectedNode:childNode});
      }
      return childNode;
    }
    // Always Visible Nodes
    const TaskNodes = [
      getNode(GetCompletedTasksNode()),
      getNode(GetDeletedTasksNode()),
    ];
    this.setChildNodesForParent(parentNode.UniqueId, TaskNodes);

    // TaskNodes in state are for tabs/mobile view
    this.setState({TaskNodes});
    return Promise.resolve(TaskNodes);
  }

  getAndSetAssetNodesAsPromise = (parentNode, disallowChildSelectOnLoad) => {
    let itemIndex = 0;
    const getNode = baseNode => {
      const Url = `/projects/${parentNode.ProjectID}${SettingsPathElement}${AssetsPathElement}${baseNode.PathElement}`;
      const optionalSelectedCollectionItemId = this.props.match.params.collectionItemID;
      const childNode = {
        ...baseNode,
        RootId: parentNode.RootId,
        UniqueId: `${parentNode.ProjectID}_${baseNode.UniqueId}`,
        ParentNode: parentNode,
        ProjectID: parentNode.ProjectID,
        HasChildren: false,
        // SelectOnMount: itemIndex === 0
        //   && !disallowChildSelectOnLoad 
        //   && !this.props.location.pathname.startsWith(parentNode.Url)
        //   && !optionalSelectedCollectionItemId
        //   ,
        ItemIndex: itemIndex++,
        Url,
      };
      // Ensure this node is selected (for breadcrumbs) if a child of this node is active
      if (optionalSelectedCollectionItemId && this.props.location.pathname.startsWith(Url)) {
        this.setState({SelectedNode:childNode});
      }
      return childNode;
    }
    // Always Visible Nodes
    const AssetNodes = [
      getNode(GetArchivedAssetsNode()),
    ];
    
    this.setChildNodesForParent(parentNode.UniqueId, AssetNodes);

    // AssetNodes in state are for tabs/mobile view
    this.setState({AssetNodes});
    return Promise.resolve(AssetNodes);
  }

  getAndSetAssetItemNodesAsPromise = (parentNode, disallowChildSelectOnLoad) => {
    let itemIndex = 0;
    const getNode = baseNode => {
      const Url = `/projects/${parentNode.ProjectID}/${AssetItemsPathElementPrefix}${parentNode.Asset.ID}${baseNode.PathElement}`;
      const optionalSelectedCollectionItemId = this.props.match.params.collectionItemID;
      const childNode = {
        ...baseNode,
        RootId: parentNode.RootId,
        UniqueId: `${parentNode.ProjectID}_${baseNode.UniqueId}`,
        ParentNode: parentNode,
        ProjectID: parentNode.ProjectID,
        HasChildren: false,
        // SelectOnMount: itemIndex === 0
        //   && !disallowChildSelectOnLoad 
        //   && !this.props.location.pathname.startsWith(parentNode.Url)
        //   && !optionalSelectedCollectionItemId
        //   ,
        ItemIndex: itemIndex++,
        Url,
      };
      // Ensure this node is selected (for breadcrumbs) if a child of this node is active
      if (optionalSelectedCollectionItemId && this.props.location.pathname.startsWith(Url)) {
        this.setState({SelectedNode:childNode});
      }
      return childNode;
    }
    // Always Visible Nodes
    const AssetItemNodes = [
      getNode(GetArchivedAssetItemsNode()),
    ];

    this.setChildNodesForParent(parentNode.UniqueId, AssetItemNodes);

    // AssetNodes in state are for tabs/mobile view
    this.setState({AssetItemNodes});
    return Promise.resolve(AssetItemNodes);
  }

  handleBreadcrumbSelect = SelectedNode => {
    if (SelectedNode.Url) {
      this.props.history.push(SelectedNode.Url, { ...this.props.location.state, SelectedNode, });
    }
  }

  compareEmails = (a, b) => {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    }
    else return 0;
  }

  ensureSelectedNode = () => {
    if (!this.state.SelectedNode || this.state.SelectedNode.Url !== this.props.location.pathname) {
      const nodeExpandFuncs = [
        ...this.NodeExpandFunctionsByUniqueId,
        ...this.DocumentFolders.DocumentFolderNodeExpandFunctionsByUniqueId,
      ];
      SetSelectedNodeByCurrentLocationRecursive(this.state.RootNodes, this.ChildNodesByParentNodeUniqueId,
        this.props.location, state => this.setState(state), nodeExpandFuncs);
    }
  }

  handleTabChange = (e, tabValue) => {
    this.props.history.push(tabValue);
  }

  getTreeFromRootNodes = () => {
    return this.state.RootNodes.map(rootNode =>
      GetTreeNodeComponent(
        this.props, this.state, state => this.setState(state),
        rootNode,
        () => this.setRootNodes(false, true),
        this.getAndSetCollectionNodesAsPromise,
        this.getCollectionNodeComponent,
        this.getRootNodeContextMenu,
        this.setRootNodeExpandFunction,
      )
    );
  }

  getCollectionNodeComponent = (collectionNode, onGetMoreCollectionNodes) => {
    switch (collectionNode.NodeType) {
      case DocumentsNodeType:
        return this.DocumentFolders.GetDocumentFolderTreeNodeComponent(this.props, this.state, this.setChildNodesForParent)
          (collectionNode);
      default:
        return GetTreeNodeComponent(
          this.props, this.state, state => this.setState(state),
          collectionNode,
          onGetMoreCollectionNodes,
          this.getAndSetCollectionSubNodesAsPromise,
          this.getCollectionSubNodeComponent,
          this.getCollectionNodeContextMenu,
          this.setCollectionNodeExpandFunction,
          null,
        );
    }
    
  }

  getCollectionSubNodeComponent = (collectionSubNode, onGetMoreCollectionSubNodes) => {
    switch (collectionSubNode.ParentNode.NodeType) {
      case DocumentFoldersNodeType:
        return this.DocumentFolders.GetDocumentFolderTreeNodeComponent(this.props, this.state, this.setChildNodesForParent)
          (collectionSubNode);
      case TasksNodeType:
      case AssetItemsNodeType:
        return GetTreeNodeComponent(
          this.props, this.state, state => this.setState(state),
          collectionSubNode,
          onGetMoreCollectionSubNodes,
        );
      case SettingsNodeType:
        return GetTreeNodeComponent(
          this.props, this.state, state => this.setState(state),
          collectionSubNode,
          onGetMoreCollectionSubNodes,
          this.getAndSetSettingsSubNodesAsPromise,
          this.getSettingsSubNodeComponent,
        );
      default:
        return null;
    }
  }

  getSettingsSubNodeComponent = (settingsSubNode, onGetMoreSettingsSubNodes) => {
    switch (settingsSubNode.ParentNode.NodeType) {
      case AssetsNodeType:
        return GetTreeNodeComponent(
          this.props, this.state, state => this.setState(state),
          settingsSubNode,
          onGetMoreSettingsSubNodes,
        );
      default:
        return null;
    }
  }

  getRootNodeContextMenu = (node, mouseX, mouseY, onClose) => {
    if (!IsUserProjectAdmin(this.context, node.ProjectID)) {
      return;
    }
    return (
      <Menu
        keepMounted
        open={mouseY !== null}
        onClose={onClose}
        anchorReference="anchorPosition"
        anchorPosition={
          mouseY !== null && mouseX !== null
            ? { top: mouseY, left: mouseX }
            : undefined
        }
      >
        <MenuItem onClick={e => this.handleContextMenuAction(e, node, "rename", onClose)}>
          <ListItemIcon>
            <RenameIcon />
          </ListItemIcon>
          Rename
        </MenuItem>
        <Divider />
        <MenuItem onClick={e => this.handleContextMenuAction(e, node, "delete", onClose)}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
    );
  }

  setLocalNodeExpandFunction = (node, onNodeExpand) => {
    const existingFuncFinder = this.NodeExpandFunctionsByUniqueId.filter(d => d.UniqueId === node.UniqueId);
    if (existingFuncFinder.length) {
      existingFuncFinder[0].onNodeExpand = onNodeExpand;
    } else {
      this.NodeExpandFunctionsByUniqueId.push({
        UniqueId: node.UniqueId,
        onNodeExpand,
      });
    }
  }

  setRootNodeExpandFunction = (node, onNodeExpand) => {
    this.setLocalNodeExpandFunction(node, onNodeExpand);
  }

  // setLocalNodeCollapseFunction = (node, onNodeCollapse) => {
  //   const existingFuncFinder = this.NodeCollapseFunctionsByUniqueId.filter(d => d.UniqueId === node.UniqueId);
  //   if (existingFuncFinder.length) {
  //     existingFuncFinder[0].onNodeCollapse = onNodeCollapse;
  //   } else {
  //     this.NodeCollapseFunctionsByUniqueId.push({
  //       UniqueId: node.UniqueId,
  //       onNodeCollapse,
  //     });
  //   }
  // }

  getCollectionNodeContextMenu = (node, mouseX, mouseY, onClose) => {
    switch (node.NodeType) {
      case DocumentFoldersNodeType:
      case DocumentsNodeType:
        return this.DocumentFolders.GetContextMenu(node, mouseX, mouseY, onClose);
      default:
        return null;
    }
  }

  setCollectionNodeExpandFunction = (node, onNodeExpand) => {
    switch (node.NodeType) {
      case DocumentFoldersNodeType:
      case DocumentsNodeType:
        return this.DocumentFolders.HandleSetNodeExpandFunction(node, onNodeExpand);
      default:
        this.setLocalNodeExpandFunction(node, onNodeExpand);
        break;
    }
  }

  handleContextMenuAction = (e, ContextMenuNode, actionType, onClose) => {
    e.stopPropagation();
    onClose();

    switch (ContextMenuNode.NodeType) {
      case ProjectRootNodeType:
        switch (actionType) {
          case "rename":
            this.handleSetRenameProjectDialogVisibility(true, { ContextMenuNode });
            break;
          case "delete":
            this.handleSetDeleteProjectConfirmationVisibility(true, { ContextMenuNode });
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  handleSetRenameProjectDialogVisibility = (ShowRenameProjectDialog, extraState) => {
    this.setState({
      ...extraState,
      ShowRenameProjectDialog,
    });
  }

  handleSetDeviceCaptureDialogVisibility = (ShowDeviceCaptureDialog, extraState) => {
    this.setState({
      ...extraState,
      ShowDeviceCaptureDialog,
    });
  }

  handleBeginDocumentUpload = documentFolderNode => {
    if (this.BeginFileUploadFunc) {
      let url, documentFolderId;
      if (documentFolderNode) {
        url = documentFolderNode.Url;
        documentFolderId = documentFolderNode.DocumentFolderID;
      } else {
        if (this.state.SelectedNode && this.state.SelectedNode.DocumentFolderID) {
          url = this.state.SelectedNode.Url;
          documentFolderId = this.state.SelectedNode.DocumentFolderID;
        } else {
          url = GetProjectDocumentsPath(this.props.match.params.projectID);
        }
      }
      if (this.props.location.pathname !== url) {
        this.props.history.push(url);
      }
      this.BeginFileUploadFunc(documentFolderId);
    }
  }

  handleBeginImageCapture = (documentFolderNode, fieldIDsAndValuesForCapture) => {
    if (IsMobile() || !IsMicrosoftWindows()) {
      return;
    }
    
    // Set some local variables for GetPreCaptureFieldGatheringContent
    this.PostFieldGatheringCaptureFunc = fieldIDsAndValues => this.handleBeginImageCapture(documentFolderNode, fieldIDsAndValues);
    this.AdditionalBodyTextForFieldGatheringDialog = "All documents will receive these values.";

    let url;
    if (documentFolderNode) {
      url = documentFolderNode.Url;
    } else {
      if (this.state.SelectedNode && this.state.SelectedNode.DocumentFolderID) {
        url = this.state.SelectedNode.Url;
        documentFolderNode = {...this.state.SelectedNode};
      } else {
        url = GetProjectDocumentsPath(this.props.match.params.projectID);
      }
    }
    if (this.props.location.pathname !== url) {
      this.props.history.push(url);
    }
    let extraState = {
      FieldIDsAndValuesForCapture: fieldIDsAndValuesForCapture || null,
    };
    if (documentFolderNode) {
      extraState.ImageCaptureDocumentFolderID = documentFolderNode.DocumentFolderID;
      extraState.ImageCaptureDocumentFolderName = documentFolderNode.Name;
    } else {
      extraState.ImageCaptureDocumentFolderID = null;
      extraState.ImageCaptureDocumentFolderName = null;
    }

    const beginImageCapture = () => {
      this.handleSetDeviceCaptureDialogVisibility(true, extraState);
    };

    if (!fieldIDsAndValuesForCapture) {
      this.handlePreCaptureFieldGathering();
    } else {
      beginImageCapture();
    }
  }

  handlePreCaptureFieldGathering = () => {
    const handlePreGatherFields = () => {
    }

    const handleCapture = fieldIDsAndValues => {
      this.PostFieldGatheringCaptureFunc(fieldIDsAndValues);
    }

    const {
      projectID,
    } = this.props.match.params;
    const {
      OrganizationID,
    } = this.context.ActiveOrganizationPermissions;

    HandlePreCaptureFieldGathering(
      OrganizationID,
      projectID,
      this.handleApiError,
      () => this.state,
      state => this.setState(state),
      1,
      handlePreGatherFields,
      handleCapture,
    );
  }

  handleBeginDocumentCreation = (documentFolderNode, fieldIDsAndValuesForCapture) => {
    const {
      projectID,
    } = this.props.match.params;

    // Set some local variables for GetPreCaptureFieldGatheringContent
    this.PostFieldGatheringCaptureFunc = fieldIDsAndValues => this.handleBeginDocumentCreation(documentFolderNode, fieldIDsAndValues);
    this.AdditionalBodyTextForFieldGatheringDialog = null;

    let url, documentFolderId;
    if (documentFolderNode) {
      url = documentFolderNode.Url;
      documentFolderId = documentFolderNode.DocumentFolderID;
    } else {
      if (this.state.SelectedNode && this.state.SelectedNode.DocumentFolderID) {
        url = this.state.SelectedNode.Url;
        documentFolderId = this.state.SelectedNode.DocumentFolderID;
      } else {
        url = GetProjectDocumentsPath(this.props.match.params.projectID);
      }
    }
    if (this.props.location.pathname !== url) {
      this.props.history.push(url);
    }
    
    const newDocument = {
      Name: "Untitled document",
      DocumentFolderID: documentFolderId,
      Origin: "Editor",
    };
    const params = {
      fieldIDsAndValues_json: (fieldIDsAndValuesForCapture) ? JSON.stringify(fieldIDsAndValuesForCapture) : undefined,
    };

    const beginDocumentCreation = () => {
      this.setState({ShowProgressIndicatorImmediately: true});
      API.post(GetDocumentsPathForApi(this.context.ActiveOrganizationPermissions.OrganizationID,
        projectID), [newDocument], { params })
        .then(resp => {
           this.setState({
            ShowProgressIndicatorImmediately: false,
            ForcePrependItems:resp.data,
          });
           OpenDocumentDetailTab(this.context.ActiveOrganizationPermissions.OrganizationID,
            projectID, resp.data[0]);
        })
        .catch(this.handleApiError);
    };

    if (!fieldIDsAndValuesForCapture) {
      this.handlePreCaptureFieldGathering();
    } else {
      beginDocumentCreation();
    }
  }

  handleUpdateProjectName = name => {
    if (!name)
      return;
    this.handleSetRenameProjectDialogVisibility(false);
    if (!this.state.ContextMenuNode || !this.state.ContextMenuNode.Project) {
      return;
    }
    const project = this.state.ContextMenuNode.Project;
    project.Name = name;
    this.setState({ShowProgressIndicatorImmediately: true});
    API.put(GetProjectPathForApi(project.OrganizationID, project.ID), project)
      .then(resp => {
        this.setState({ShowProgressIndicatorImmediately: false});
        let RootNodes = [...this.state.RootNodes];
        const rootNodeFinder = RootNodes.filter(n => n.UniqueId === this.state.ContextMenuNode.UniqueId);
        if (rootNodeFinder.length) {
          rootNodeFinder[0].Name = name;
          this.setState({RootNodes});
        }
      })
      .catch(this.handleApiError);
  }

  handleSetDeleteProjectConfirmationVisibility(ShowDeleteProjectConfirmation, extraState) {
    this.setState({ 
      ...extraState,
      ShowDeleteProjectConfirmation,
    });
  }

  handleDeleteProject = confirmation => {
    if (!confirmation) {
      return;
    }
    if (confirmation !== this.state.ContextMenuNode.Name) {
      this.handleAlert({
        Title: "Project name mismatch",
        BodyText:"Please enter the name of the project exactly as it appears.",
      });
      return;
    }
    this.handleSetDeleteProjectConfirmationVisibility(false);
    this.setState({ShowProgressIndicatorImmediately: true});
    return API.delete(GetProjectsPathForApi(this.state.ContextMenuNode.Project.OrganizationID), 
      { data: { IDs:[this.state.ContextMenuNode.Project.ID] } })
      .then(resp => {
        this.setState({ShowProgressIndicatorImmediately: false});
        this.context.Reset();
        let RootNodes = [...this.state.RootNodes];
        RootNodes = RootNodes.filter(n => n.UniqueId !== this.state.ContextMenuNode.UniqueId);
        if (RootNodes.length) {
          this.setRootNodes(true, false);
          this.props.history.push(GetProjectTasksPath(RootNodes[0].ProjectID));
        } else {
          this.props.history.push("/home");
        }
      })
      .catch(this.handleApiError);
  }

  ensureNodeExists = (nodeType, url_optional) => {
    const projectId = this.props.match.params.projectID;
    const RootNodes = [...this.state.RootNodes];
    for (let i = 0; i < this.ChildNodesByParentNodeUniqueId.length; i++) {
      const childNodes = this.ChildNodesByParentNodeUniqueId[i].ChildNodes;
      for (let j = 0; j < childNodes.length; j++) {
        const childNode = childNodes[j];
        if (childNode.NodeType === nodeType) {
          if (!url_optional || childNode.Url === url_optional)
          return;
        }
      }
    }
    this.setState({RootNodes: RootNodes.filter(n => n.ProjectID !== projectId)});
    this.setRootNodes(true, false);
  }

  handleSetAddItemVisibility = (itemName, visible, extraState) => {
    this.setState({ 
      ...extraState,
      [itemName] : visible,
    });
  }

  handleViewTypeChanged = TasksViewType => {
    if (this.state.SelectedNode && this.state.SelectedNode.NodeType === TasksNodeType) {
      this.setState({TasksViewType});
      this.setTasksViewName(TasksViewType);
    }
  }

  gotoUrlWithOptionalNewItems = (url, newItems) => {
    if (this.props.location.pathname === url) {
      if (newItems && newItems.length) {
        this.setState({ForcePrependItems:newItems});
      }
    } else {
      if (!this.props.location.pathname.startsWith(url)) {
        this.props.history.push(url, { ...this.props.location.state });
      }
    }
  }

  handleProjectCreated = project => {
    this.handleSetAddItemVisibility('ShowAddProjectDialog', false);
    this.context.Reset();
    this.setRootNodes(true, false);
    this.props.history.push(GetProjectMembersPath(project.ID));
  }

  handleProjectMembersCreated = projectMembers => {
    this.handleSetAddItemVisibility('ShowAddProjectMemberDialog', false);
    if (this.props.match.params.projectID) {
      this.ensureNodeExists(ProjectMembersNodeType);
      this.gotoUrlWithOptionalNewItems(
        GetProjectMembersPath(this.props.match.params.projectID),
        projectMembers,
      );
    }
  }

  handleTaskCreated = task => {
    this.handleSetAddItemVisibility('ShowAddTaskDialog', false);
    if (this.props.match.params.projectID) {
      this.ensureNodeExists(TasksNodeType);
      this.gotoUrlWithOptionalNewItems(
        GetProjectTasksPath(this.props.match.params.projectID),
        [task],
      );
    }
  }

  handleFieldCreated = field => {
    this.handleSetAddItemVisibility('ShowAddFieldDialog', false);
    if (this.props.match.params.projectID) {
      this.ensureNodeExists(FieldsNodeType);
      if (this.props.location.pathname === GetProjectFieldsPath(this.props.match.params.projectID)) {
        this.setState({ForcePrependItems:[field]});
      }
      this.props.history.push(GetProjectFieldPath(this.props.match.params.projectID, field.ID),
        { ...this.props.location.state });
    }
  }

  handleAssetCreated = asset => {
    this.handleSetAddItemVisibility('ShowAddAssetDialog', false);
    if (this.props.match.params.projectID) {
      this.ensureNodeExists(AssetsNodeType);
      if (this.props.location.pathname === GetProjectAssetsPath(
        this.props.match.params.projectID)) {
        this.setState({ForcePrependItems:[asset]});
      }
      this.props.history.push(GetProjectAssetPath(this.props.match.params.projectID, asset.ID),
        { ...this.props.location.state });
    }
  }

  handleAssetItemCreated = assetItem => {
    this.handleSetAddItemVisibility('ShowAddAssetItemDialog', false);
    if (this.props.match.params.projectID) {
      const assetItemsPath = GetProjectAssetItemsPath(this.props.match.params.projectID,
        assetItem.AssetID, assetItem.ID);
      this.ensureNodeExists(AssetItemsNodeType, assetItemsPath);
      if (this.props.location.pathname === assetItemsPath) {
        this.setState({ForcePrependItems:[assetItem]});
      }
      // this.props.history.push(GetProjectAssetItemPath(this.props.match.params.projectID, 
      //   assetItem.AssetID, assetItem.ID),
      //   { ...this.props.location.state });
    }
  }

  handleFormTemplateCreated = formTemplate => {
    this.handleSetAddItemVisibility('ShowAddFormTemplateDialog', false);
    if (this.props.match.params.projectID) {
      this.ensureNodeExists(FormTemplatesNodeType);
      if (this.props.location.pathname === GetProjectFormTemplatesPath(this.props.match.params.projectID)) {
        this.setState({ForcePrependItems:[formTemplate]});
      }
      this.props.history.push(GetFormTemplateDesignerPath(this.context.ActiveOrganizationPermissions.OrganizationID,
        this.props.match.params.projectID, formTemplate.ID), { ...this.props.location.state });
    }
  }

  handleProcessCreated = workflowProcess => {
    this.handleSetAddItemVisibility('ShowAddProcessDialog', false);
    if (this.props.match.params.projectID) {
      this.ensureNodeExists(ProcessesNodeType);
      if (this.props.location.pathname === GetProjectProcessesPath(this.props.match.params.projectID)) {
        this.setState({ForcePrependItems:[workflowProcess]});
      }
      this.props.history.push(GetProcessDesignerPath(this.context.ActiveOrganizationPermissions.OrganizationID,
        this.props.match.params.projectID, workflowProcess.ID), { ...this.props.location.state });
    }
  }

  handleTaskMilestoneCreated = taskMilestone => {
    this.handleSetAddItemVisibility('ShowAddTaskMilestoneDialog', false);
    if (this.props.match.params.projectID) {
      this.ensureNodeExists(TaskMilestonesNodeType);
      if (this.props.location.pathname === GetProjectTaskMilestonesPath(this.props.match.params.projectID)) {
        this.setState({ForcePrependItems:[taskMilestone]});
      }
      this.setState({ForceRefreshKanbanColumns:true});
      setTimeout(() => this.setState({ForceRefreshKanbanColumns:false}), 1);
    }
  }

  handleTaskStateCreated = taskState => {
    this.handleSetAddItemVisibility('ShowAddTaskStateDialog', false);
    if (this.props.match.params.projectID) {
      this.ensureNodeExists(TaskStatesNodeType);
      if (this.props.location.pathname === GetProjectTaskStatesPath(this.props.match.params.projectID)) {
        this.setState({ForcePrependItems:[taskState]});
      }
      this.setState({ForceRefreshKanbanColumns:true});
      setTimeout(() => this.setState({ForceRefreshKanbanColumns:false}), 1);
    }
  }

  handleDocumentFolderNodeCreated = node => {
    let childNodes = [];
    const childNodesFinder = this.ChildNodesByParentNodeUniqueId.filter(n => n.UniqueId === node.ParentNode.UniqueId);
    if (childNodesFinder.length) {
      childNodes = [...childNodesFinder[0].ChildNodes, node];
    } else {
      childNodes.push(node);
    }
    this.setChildNodesForParent(node.ParentNode.UniqueId, childNodes);
  }

  handleSetShowTaskHelpDialogVisibility = ShowTaskHelpDialog => {
    this.setState({ShowTaskHelpDialog});
  }

  handleSetShowAssetHelpDialogVisibility = ShowAssetHelpDialog => {
    this.setState({ShowAssetHelpDialog});
  }

  handleSetCreateNewMenuVisibility = visible => event => {
    this.setState({
      CreateNewMenu_MouseY: (visible) ? event.clientY : null,
      CreateNewMenu_MouseX: (visible) ? event.clientX : null,
    });
  }

  handleCreateNewMenuClick = (type, additionalProps) => event => {
    this.handleSetCreateNewMenuVisibility(false)();
    const projectId = this.props.match.params.projectID;
    switch (type) {
      case "project":
        this.handleSetAddItemVisibility('ShowAddProjectDialog', true);
        break;
      case "projectMember":
        this.gotoUrlWithOptionalNewItems(
          GetProjectMembersPath(projectId),
        );
        this.handleSetAddItemVisibility('ShowAddProjectMemberDialog', true);
        break;
      case "task":
        this.gotoUrlWithOptionalNewItems(
          GetProjectTasksPath(projectId),
        );
        this.handleSetAddItemVisibility('ShowAddTaskDialog', true);
        break;
      case "asset":
        this.gotoUrlWithOptionalNewItems(
          GetProjectAssetsPath(projectId),
        );
        this.handleSetAddItemVisibility('ShowAddAssetDialog', true);
        break;
      case "assetItem":
        if (additionalProps.AssetID) {
          const assetFinder = this.state.Assets.filter(a => a.ID === additionalProps.AssetID);
          if (assetFinder.length) {
            this.gotoUrlWithOptionalNewItems(
              GetProjectAssetItemsPath(projectId, additionalProps.AssetID),
            );
            this.handleSetAddItemVisibility('ShowAddAssetItemDialog', true, 
              {
                AssetForCreationDialog: assetFinder[0],
              });
          }
        }
        break;
      case "field":
        this.gotoUrlWithOptionalNewItems(
          GetProjectFieldsPath(projectId),
        );
        this.handleSetAddItemVisibility('ShowAddFieldDialog', true);
        break;
      case "formTemplate":
        this.gotoUrlWithOptionalNewItems(
          GetProjectFormTemplatesPath(projectId),
        );
        this.handleSetAddItemVisibility('ShowAddFormTemplateDialog', true);
        break;
      case "process":
        this.gotoUrlWithOptionalNewItems(
          GetProjectProcessesPath(projectId),
        );
        this.handleSetAddItemVisibility('ShowAddProcessDialog', true);
        break;
      case "taskMilestone":
        this.gotoUrlWithOptionalNewItems(
          GetProjectTaskMilestonesPath(projectId),
        );
        this.handleSetAddItemVisibility('ShowAddTaskMilestoneDialog', true);
        break;
      case "taskState":
        this.gotoUrlWithOptionalNewItems(
          GetProjectTaskStatesPath(projectId),
        );
        this.handleSetAddItemVisibility('ShowAddTaskStateDialog', true);
        break;
      case "fileUpload":
        this.gotoUrlWithOptionalNewItems(
          GetProjectDocumentsPath(projectId),
        );
        this.handleBeginDocumentUpload();
        break;
      case "imageCapture":
        this.gotoUrlWithOptionalNewItems(
          GetProjectDocumentsPath(projectId),
        );
        this.handleBeginImageCapture();
        break;
      case "documentCreate":
        this.gotoUrlWithOptionalNewItems(
          GetProjectDocumentsPath(projectId),
        );
        this.handleBeginDocumentCreation();
        break;
      default:
        break;
    }
  }

  handleSetBeginFileUploadFunc = beginFileUploadFunc => {
    this.BeginFileUploadFunc = beginFileUploadFunc;
  }

  handleActionCompleted = actionType => {
    const projectId = this.props.match.params.projectID;
    const RootNodes = [...this.state.RootNodes];
    switch (actionType) {
    case ActionType.Asset_Archive:
    case ActionType.Asset_Restore:
      this.setState({RootNodes: RootNodes.filter(n => n.ProjectID !== projectId)});
      this.setRootNodes(true, false);
      break;
    default:
      break;
    }
  }

  render() {
    const {
      ApiError,
      Alert,
      RootNodes,
      CollectionNodes,
      SelectedNode,
      ForcePrependItems,
      ForceRefresh,
      ForceRefreshKanbanColumns,
      TotalItems,
      Assets,
      ContextMenuNode,
      ImageCaptureDocumentFolderID,
      ImageCaptureDocumentFolderName,
      CreateNewMenu_MouseY,
      CreateNewMenu_MouseX,
      FabMenu_MouseY,
      FabMenu_MouseX,
      ItemTitle,
      FieldIDsAndValuesForCapture,
      ShowAddProjectDialog,
      ShowAddProjectMemberDialog,
      ShowAddTaskDialog,
      ShowAddAssetDialog,
      ShowAddAssetItemDialog,
      ShowAddFieldDialog,
      ShowAddFormTemplateDialog,
      ShowAddProcessDialog,
      ShowAddTaskMilestoneDialog,
      ShowAddTaskStateDialog,
      ShowRenameProjectDialog,
      ShowDeviceCaptureDialog,
      ShowDeleteProjectConfirmation,
      ShowTaskHelpDialog,
      ShowAssetHelpDialog,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      // CompletedGET,
      ActiveOrganizationPermissions,
    } = this.context;
    const {
      OrganizationID,
    } = ActiveOrganizationPermissions;
    const {
      projectID: ProjectID,
    } = this.props.match.params;
    const {
      classes,
      theme,
      ...restProps
    } = this.props;

    // const isOrgMember = CompletedGET.ActiveOrganizationPermissions && ActiveOrganizationPermissions.OrganizationID;

    // const createAProjectButton = (isOrgMember)
    //   ? (
    //     <Tooltip title="Create a project">
    //       <IconButton aria-label="Create a project"
    //         style={{
    //           marginLeft:-8,
    //           marginTop:8,
    //         }}
    //         onClick={() => this.handleSetAddItemDialogVisibility('ShowAddProjectDialog', true)}>
    //         <AddIcon />
    //       </IconButton>
    //     </Tooltip>
    //   ) : null;

    const imageCaptureMenuItem = (!IsMobile() && IsMicrosoftWindows())
      ? (
        <MenuItem key="mi_imageCapture" onClick={this.handleCreateNewMenuClick("imageCapture")}>
          <ListItemIcon>
            <ImageCaptureIcon />
          </ListItemIcon>
          Document scan
        </MenuItem>
      ) : null;

    const alwaysVisibleMenuItems = (RootNodes && RootNodes.length)
      ? [
        <MenuItem key="mi_task" onClick={this.handleCreateNewMenuClick("task")}>
          <ListItemIcon>
            <TasksIcon />
          </ListItemIcon>
          Task
        </MenuItem>,
        <MenuItem key="mi_documentCreate" onClick={this.handleCreateNewMenuClick("documentCreate")}>
          <ListItemIcon>
            <DocumentCreateIcon />
          </ListItemIcon>
          Blank document
        </MenuItem>,
        imageCaptureMenuItem,
        <MenuItem key="mi_fileUpload" onClick={this.handleCreateNewMenuClick("fileUpload")}>
          <ListItemIcon>
            <DocumentUploadIcon />
          </ListItemIcon>
          Document upload
        </MenuItem>,
        <Divider key="alwaysVisibleDivider" />,
      ]
      : [];

    const assetMenuItems = (Assets && Assets.length)
      ? Assets.map(a => {
        return (
          <MenuItem key={`asset_${a.ID}`}
            onClick={this.handleCreateNewMenuClick("assetItem", { AssetID: a.ID })}
          >
            <ListItemIcon>
              <AssetsIcon />
            </ListItemIcon>
            {a.Name}
          </MenuItem>
        );
      })
      : [];
    if (assetMenuItems.length) {
      assetMenuItems.push(
        <Divider key="assetDivider" />,
      );
    }

    const currentProjectMenuItems = (ProjectID && IsUserProjectAdmin(this.context, ProjectID)) ? [
      <MenuItem key="mi_assets" onClick={this.handleCreateNewMenuClick("asset")}>
        <ListItemIcon>
          <AssetsIcon />
        </ListItemIcon>
        Asset
      </MenuItem>,
      <MenuItem key="mi_fields" onClick={this.handleCreateNewMenuClick("field")}>
        <ListItemIcon>
          <FieldsIcon />
        </ListItemIcon>
        Field
      </MenuItem>,
      <MenuItem key="mi_formTemplate" onClick={this.handleCreateNewMenuClick("formTemplate")}>
        <ListItemIcon>
          <FormTemplateIcon />
        </ListItemIcon>
        Form template
      </MenuItem>,
      <MenuItem key="mi_members" onClick={this.handleCreateNewMenuClick("projectMember")}>
        <ListItemIcon>
          <MembersIcon />
        </ListItemIcon>
        {`Project member`}
      </MenuItem>,
      <MenuItem key="mi_process" onClick={this.handleCreateNewMenuClick("process")}>
        <ListItemIcon>
          <ProcessIcon />
        </ListItemIcon>
        Workflow process
      </MenuItem>,
      <MenuItem key="mi_taskMilestone" onClick={this.handleCreateNewMenuClick("taskMilestone")}>
        <ListItemIcon>
          <TaskMilestoneIcon />
        </ListItemIcon>
        Task milestone
      </MenuItem>,
      <MenuItem key="mi_taskState" onClick={this.handleCreateNewMenuClick("taskState")}>
        <ListItemIcon>
          <TaskStateIcon />
        </ListItemIcon>
        Task state
      </MenuItem>,
      <Divider key="currentProjectDivider" />,
    ]
    : [];

    const createNewMenu = (
      <Menu
        id="create-new-menu"
        keepMounted
        open={CreateNewMenu_MouseY !== null}
        anchorReference="anchorPosition"
        anchorPosition={
          CreateNewMenu_MouseY !== null && CreateNewMenu_MouseX !== null
            ? { top: CreateNewMenu_MouseY, left: CreateNewMenu_MouseX }
            : undefined
        }
        onClose={this.handleSetCreateNewMenuVisibility(false)}
      >
        {/*{currentCollectionMenuItems}*/}
        {alwaysVisibleMenuItems}
        {assetMenuItems}
        {currentProjectMenuItems}
        <MenuItem key="mi_project" onClick={this.handleCreateNewMenuClick("project")}>
          <ListItemIcon>
            <ProjectsIcon />
          </ListItemIcon>
          Project
        </MenuItem>
      </Menu>
    );

    const documentFolderContent = this.DocumentFolders && this.DocumentFolders.GetContent(this.props, () => this.state);
    const leftPaneContent = (
      <div className={classes.leftPaneContent}>
        <div>
          <Tooltip title="Create something new...">
            <Button variant="contained" color="secondary" fullWidth
              aria-label="Create something new" className={classes.createNewButton}
              onClick={this.handleSetCreateNewMenuVisibility(true)}>
              <AddIcon style={{marginLeft:-8}} />
              &nbsp;NEW
            </Button>
          </Tooltip>
          {createNewMenu}
          {documentFolderContent}
        </div>
        <div className={classes.leftPaneTreeContainer}>
          {this.getTreeFromRootNodes()}
        </div>
      </div>
    );

    const currentUrlHasNode = CollectionNodes.filter(n => this.props.match.url.startsWith(n.Url)).length;
    const secondaryNavTabs = (IsMobile() && CollectionNodes && currentUrlHasNode) ? (
      <Tabs
        variant="scrollable"
        value={this.props.match.url}
        onChange={this.handleTabChange}
      >
        {
          CollectionNodes.map((n, index) => {
            // This sets the tab value to the extended URL to ensure there is a matching value for the Tabs component
            const tabValue = (this.props.match.url.startsWith(n.Url))
              ? this.props.match.url
              : n.Url;
            return (
              <Tab
                key={n.UniqueId} 
                label={n.Name}
                value={tabValue}
                icon={GetIconById(n.CustomIconID)}
                style={{
                  marginLeft:(index === 0) ? 32 : undefined,
                }}
              />
            );
          })
        }
      </Tabs>
    ) : null;

    let projectSelectorOptions = RootNodes.map(n => {
      return { label: n.Name, value: n.ProjectID };
    });
    const validProject = projectSelectorOptions.filter(o => o.value === ProjectID).length;
    if (!ProjectID || !validProject) {
      projectSelectorOptions.push({ label: "Select project...", value: "select" });
    }
    const projectSelectorValue = (validProject) ? ProjectID : "select";

    const mobileProjectSelector = (IsMobile() && RootNodes.length)
      ? (
        <div style={{
          marginLeft:17,
          overflowX: "hidden",
          minWidth:72,
        }}>
          <SelectControl
            id={"projectSelector"}
            hideEmpty
            options={projectSelectorOptions}
            value={projectSelectorValue}
            fontSize={12}
            onValueChange={value => this.props.history.push(GetProjectTasksPath(value))}
            useMiniStyle
          />
        </div>
      ) : null;

    const getLearnMoreButton = (onClick, learnMoreText) => {
      if (!learnMoreText) {
        learnMoreText = "Learn more about this area";
      }
      return (
        <Tooltip title={learnMoreText}>
          <IconButton color="default" aria-label={learnMoreText}
            onClick={onClick}>
            <HelpIcon />
          </IconButton>
        </Tooltip>
      );
    };
    
    let additionalToolHeaderRightContent;
    if (!IsMobile() && SelectedNode) {
      switch (SelectedNode.NodeType) {
        case TasksNodeType:
          additionalToolHeaderRightContent = getLearnMoreButton(
            () => this.handleSetShowTaskHelpDialogVisibility(true),
          );
          break;
        case AssetsNodeType:
          additionalToolHeaderRightContent = getLearnMoreButton(
            () => this.handleSetShowAssetHelpDialogVisibility(true),
          );
          break;
        case AssetItemsNodeType:
          additionalToolHeaderRightContent = getLearnMoreButton(
            () => this.handleSetShowAssetHelpDialogVisibility(true),
            "Learn more about assets",
          );
          break;
        default:
          break;
      }
    }
    
    const taskHelpDialog = (ShowTaskHelpDialog)
      ? (
        <VideoHelpDialog
          open={ShowTaskHelpDialog}
          src={GetPublicVideoPath("N1 IP Task Views.mp4")}
          onClose={() => this.handleSetShowTaskHelpDialogVisibility(false)}
        />
      ) : null;
    const assetHelpDialog = (ShowAssetHelpDialog)
      ? (
        <VideoHelpDialog
          open={ShowAssetHelpDialog}
          src={GetPublicVideoPath("N1 IP Assets.mp4")}
          onClose={() => this.handleSetShowAssetHelpDialogVisibility(false)}
        />
      ) : null;

    const toolHeaderLeftContent = (
      <React.Fragment>
        {mobileProjectSelector}
      </React.Fragment>
    );

    const projectCreationDialog = (OrganizationID && ShowAddProjectDialog)
      ? (
        <ProjectCreationDialog
          organizationId={OrganizationID}
          open={ShowAddProjectDialog || false}
          onCreated={this.handleProjectCreated}
          onClose={() => this.handleSetAddItemVisibility('ShowAddProjectDialog', false)}
          onApiError={this.handleApiError}
        />
      ) : null;

    let projectName = "";
    if (SelectedNode) {
      let currentNode = SelectedNode;
      while (currentNode) {
        if (currentNode.NodeType === ProjectRootNodeType) {
          projectName = currentNode.Name;
          break;
        } else if (currentNode.ParentNode) {
          currentNode = currentNode.ParentNode;
        } else {
          currentNode = null;
        }
      }
    } 
    
    const projectMemberCreationDialog = (OrganizationID && ProjectID && ShowAddProjectMemberDialog)
      ? (
        <ProjectMemberCreationDialog
          organizationId={OrganizationID}
          projectId={ProjectID}
          projectName={projectName}
          open={ShowAddProjectMemberDialog || false}
          onCreated={this.handleProjectMembersCreated}
          onClose={() => this.handleSetAddItemVisibility('ShowAddProjectMemberDialog', false)}
          onApiError={this.handleApiError}
        />
      ) : null;

    const taskCreationDialog = (OrganizationID && ProjectID && ShowAddTaskDialog)
      ? (
        <TaskDialog
          organizationId={OrganizationID}
          projectId={ProjectID}
          collectionNameForTitle={projectName}
          open={ShowAddTaskDialog || false}
          isCreateNew
          onApiError={this.handleApiError}
          onAlert={this.handleAlert}
          onCreated={this.handleTaskCreated}
          onTaskMilestoneCreated={this.handleTaskMilestoneCreated}
          onTaskStateCreated={this.handleTaskStateCreated}
          onClose={() => this.handleSetAddItemVisibility('ShowAddTaskDialog', false)}
          // showProgressIndicatorImmediately={state.ShowDialogProgressIndicatorImmediately}
        />
      ) : null;

    const assetCreationDialog = (OrganizationID && ProjectID && ShowAddAssetDialog)
      ? (
        <AssetCreationDialog
          organizationId={OrganizationID}
          projectId={ProjectID}
          projectName={projectName}
          open={ShowAddAssetDialog || false}
          onCreated={this.handleAssetCreated}
          onClose={() => this.handleSetAddItemVisibility('ShowAddAssetDialog', false)}
          onApiError={this.handleApiError}
        />
      ) : null;

    const assetItemCreationDialog = (OrganizationID && ProjectID && ShowAddAssetItemDialog)
      ? (
        <AssetItemDialog
          history={this.props.history}
          location={this.props.location}
          organizationId={OrganizationID}
          projectId={ProjectID}
          projectName={projectName}
          assetId={this.getAssetIdFromPath()}
          assetName={this.getAssetFromPath().Name}
          isCreateNew
          open={ShowAddAssetItemDialog || false}
          onCreated={this.handleAssetItemCreated}
          onClose={() => this.handleSetAddItemVisibility('ShowAddAssetItemDialog', false)}
          onApiError={this.handleApiError}
          onAlert={this.handleAlert}
        />
      ) : null;

    const fieldCreationDialog = (OrganizationID && ProjectID && ShowAddFieldDialog)
      ? (
        <FieldCreationDialog
          organizationId={OrganizationID}
          projectId={ProjectID}
          projectName={projectName}
          open={ShowAddFieldDialog || false}
          onCreated={this.handleFieldCreated}
          onClose={() => this.handleSetAddItemVisibility('ShowAddFieldDialog', false)}
          onApiError={this.handleApiError}
        />
      ) : null;

    const formTemplateCreationDialog = (OrganizationID && ProjectID && ShowAddFormTemplateDialog)
      ? (
        <FormTemplateCreationDialog
          history={this.props.history}
          location={this.props.location}
          organizationId={OrganizationID}
          projectId={ProjectID}
          projectName={projectName}
          open={ShowAddFormTemplateDialog || false}
          onCreated={this.handleFormTemplateCreated}
          onClose={() => this.handleSetAddItemVisibility('ShowAddFormTemplateDialog', false)}
          onApiError={this.handleApiError}
          onAlert={this.handleAlert}
        />
      ) : null;

    const processCreationDialog = (OrganizationID && ProjectID && ShowAddProcessDialog)
      ? (
        <ProcessCreationDialog
          organizationId={OrganizationID}
          projectId={ProjectID}
          projectName={projectName}
          open={ShowAddProcessDialog || false}
          onCreated={this.handleProcessCreated}
          onClose={() => this.handleSetAddItemVisibility('ShowAddProcessDialog', false)}
          onApiError={this.handleApiError}
        />
      ) : null;

    const taskMilestoneCreationDialog = (OrganizationID && ProjectID && ShowAddTaskMilestoneDialog)
      ? (
        <TaskMilestoneCreationDialog
          organizationId={OrganizationID}
          projectId={ProjectID}
          projectName={projectName}
          open={ShowAddTaskMilestoneDialog || false}
          onCreated={this.handleTaskMilestoneCreated}
          onClose={() => this.handleSetAddItemVisibility('ShowAddTaskMilestoneDialog', false)}
          onApiError={this.handleApiError}
        />
      ) : null;

    const taskStateCreationDialog = (OrganizationID && ProjectID && ShowAddTaskStateDialog)
      ? (
        <TaskStateCreationDialog
          organizationId={OrganizationID}
          projectId={ProjectID}
          projectName={projectName}
          open={ShowAddTaskStateDialog || false}
          onCreated={this.handleTaskStateCreated}
          onClose={() => this.handleSetAddItemVisibility('ShowAddTaskStateDialog', false)}
          onApiError={this.handleApiError}
        />
      ) : null;

    const renameProjectDialog = (ShowRenameProjectDialog)
      ? (
        <MultiUseDialog
          Details={{
            Open:ShowRenameProjectDialog,
            Title:"Change project name",
            RequireTextInput1:true,
            TextInput1Label:"Name",
            TextInput1DefaultValue: (ContextMenuNode) ? ContextMenuNode.Project.Name : undefined,
            CancelCallback:() => this.handleSetRenameProjectDialogVisibility(false),
            CloseCallback:() => this.handleSetRenameProjectDialogVisibility(false),
            ConfirmLabel:"UPDATE",
            ConfirmCallback:this.handleUpdateProjectName,
          }}
        />
      ) : null;

    const deleteProjectConfirmationDialog = (ShowDeleteProjectConfirmation)
      ? (
        <MultiUseDialog
          Details={{
            Open:ShowDeleteProjectConfirmation,
            Title:"Delete project?",
            RequireTextInput1:true,
            BodyContent:(
              <Grid container spacing={2} style={{paddingBottom:theme.spacing(3)}}>
                <Grid item className={classes.warning}>
                  This action will remove this project completely, including its documents and settings.
                </Grid>
                <Grid item>
                  Confirm you want to delete this project by entering its name:
                <span style={{marginLeft:theme.spacing(1),fontWeight:"bold"}}>{ContextMenuNode && ContextMenuNode.Project.Name}</span>
                </Grid>
              </Grid>
            ),
            DialogWidth: "xs",
            TextInput1PlaceHolder:ContextMenuNode && ContextMenuNode.Project.Name,
            CancelCallback:() => this.handleSetDeleteProjectConfirmationVisibility(false),
            CloseCallback:() => this.handleSetDeleteProjectConfirmationVisibility(false),
            ConfirmLabel:"DELETE",
            ConfirmCallback:this.handleDeleteProject,
          }}
        />
      ) : null;

    const captureCore = (OrganizationID && ProjectID)
      ? (
        <CaptureCore
          organizationId={OrganizationID}
          projectId={ProjectID}
          {...restProps}
          noContent
          onApiError={this.handleApiError}
          onAlert={this.handleAlert}
          onClose={() => this.handleRefresh()}
          onSetBeginFileUploadFunc={this.handleSetBeginFileUploadFunc}
          gatherFieldValuesBeforeUpload
        />
      ) : null;

    const deviceCaptureDialog = (OrganizationID && ProjectID && !IsMobile() && IsMicrosoftWindows())
      ? (
        <DeviceCaptureDialog
          open={ShowDeviceCaptureDialog}
          organizationId={OrganizationID}
          projectId={ProjectID}
          documentFolderId={ImageCaptureDocumentFolderID}
          documentFolderName={ImageCaptureDocumentFolderName}
          fieldIDsAndValues={FieldIDsAndValuesForCapture}
          onApiError={this.handleApiError}
          onAlert={this.handleAlert}
          onClose={() => this.handleSetDeviceCaptureDialogVisibility(false)}
        />
      ) : null;

    const preCaptureFieldGatheringContent = GetPreCaptureFieldGatheringContent(
      OrganizationID,
      ProjectID,
      () => this.state,
      state => this.setState(state),
      this.handleApiError,
      this.handleAlert,
      this.PostFieldGatheringCaptureFunc,
      this.AdditionalBodyTextForFieldGatheringDialog,
    );

    return (
      <React.Fragment>
        {projectCreationDialog}
        {projectMemberCreationDialog}
        {preCaptureFieldGatheringContent}
        {taskCreationDialog}
        {assetCreationDialog}
        {assetItemCreationDialog}
        {fieldCreationDialog}
        {formTemplateCreationDialog}
        {processCreationDialog}
        {taskMilestoneCreationDialog}
        {taskStateCreationDialog}
        {captureCore}
        {deviceCaptureDialog}
        {taskHelpDialog}
        {assetHelpDialog}
        {renameProjectDialog}
        {deleteProjectConfirmationDialog}
        <ItemCollectionBase
          {...restProps}
          
          passThroughComponent={this.Collection.PassThroughComponent}
          pageTitle="Projects"
          titleComponentTitle={ItemTitle}
          showOrgAsTitleOnDesktop
          contentUri={this.Collection.ContentUri}
          contentUriParams={this.Collection.ContentUriParams}
          collectionName={this.Collection.CollectionName}
          itemsName={this.Collection.ItemsName}
          itemName={this.Collection.ItemName}
          defaultViewType={this.Collection.DefaultViewType}
          
          onGetCollectionFieldsPromise={this.Collection.HandleGetCollectionFieldsPromise}
          onGetHeadCells={this.Collection.HandleGetHeadCells}
          onGetCardGridItems={this.Collection.HandleGetCardGridItems}
          onGetCardGridItemsForKanban={this.Collection.HandleGetCardGridItemsForKanban}
          onGetTableRows={this.Collection.HandleGetTableRows}
          singleLineTableCells={this.Collection.SingleLineTableCells}

          selectedNode={(!IsMobile()) ? SelectedNode : undefined}

          hideSensitiveFields={this.Collection.HideSensitiveFields}
          hideFilterSortDrawer={this.Collection.HideFilterSortDrawer}
          hideFilters={this.Collection.HideFilters}
          hideSearchAllFilter={this.Collection.HideSearchAllFilter}
          leftPaneContent={leftPaneContent}
          leftPaneStyle={{
            display:(IsMobile()) ? "none" : undefined,
            // marginLeft:-8,
          }}
          onBreadcrumbSelect={this.handleBreadcrumbSelect}
          secondaryNavTabs={secondaryNavTabs}
          toolHeaderLeftContent={toolHeaderLeftContent}
          additionalToolHeaderRightContent={additionalToolHeaderRightContent}

          loadItemsImmediately
          organizationId={OrganizationID}
          projectId={ProjectID}
          apiError={ApiError}
          alert={Alert}
          totalItems={TotalItems}
          allowKanban={this.Collection.AllowKanban}
          onRefresh={this.Collection.HandleRefresh}
          onItemsChanged={this.Collection.HandleItemsChanged}
          forcePrependItems={ForcePrependItems}
          forceRefresh={ForceRefresh}
          forceRefreshKanbanColumns={ForceRefreshKanbanColumns}
          onSetResetAutoRefreshFunc={this.handleSetResetAutoRefreshFunc}
          includeItemIds={(this.props.match.params.collectionItemID) ? [this.props.match.params.collectionItemID] : undefined}
          allowSelect={this.Collection.AllowSelect}
          canSelectItem={this.Collection.CanSelectItem}
          onFabClick={this.Collection.HandleCreateNew}
          onGetFabMenu={this.Collection.HandleGetFabMenu}
          fabMenuCoords={{
            FabMenu_MouseY,
            FabMenu_MouseX,
            SelectedNode,
          }}
          onViewTypeChanged={this.handleViewTypeChanged}
          dialogContent={this.Collection.HandleGetDialogContent(this.state)}
          onSetUpdateRevisedItemFunction={this.Collection.HandleSetUpdateRevisedItemFunction}
          onSetGetAllItemsFunction={this.Collection.HandleSetGetAllItemsFunction}
          onSetSetAllItemsFunction={this.Collection.HandleSetSetAllItemsFunction}
          onActionCompleted={this.handleActionCompleted}
          showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles, {withTheme: true})(Projects);