import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';

import { DropTarget } from 'react-dnd';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NavigateFirstIcon from '@material-ui/icons/FirstPage';
import NavigateLastIcon from '@material-ui/icons/LastPage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigatePrevIcon from '@material-ui/icons/NavigateBefore';
import NavigateToPageIcon from '@material-ui/icons/Description';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import RenameIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';

// import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import ProgressIndicator from '../Components/ProgressIndicator';
import TextField from '@material-ui/core/TextField';
import NumericTextField from '../Components/NumericTextField';
import MultiUseDialog from '../Components/MultiUseDialog';
import DraggableListItem from '../Components/DraggableListItem';
import DraggableSignatureField from '../Components/DraggableSignatureField';
import SignatureFieldDragLayer from '../Components/SignatureFieldDragLayer';
import SelectControl from './SelectControl'
import dateformat from 'dateformat';
import API, {
  GetDocumentContentPackagesPathForApi,
} from '../Util/api';
import {
  DocumentSignatureFormFieldTypes,
  GetExtendedFormFields,
} from '../Model/DocumentSignatureFormFieldTypes';
import { 
  GetDocumentContentPackagePromise,
} from '../Util/Document';
import { SignatureColorCodes } from '../Util/SignatureColorCodes';
import debounce from 'es6-promise-debounce';

import {
  GetDocumentSignatureFormsPathForApi,
  GetDocumentSignatureFormPathForApi,
  GetDocumentSignatureFormFieldsPathForApi,
  GetDocumentSignatureFormFieldPathForApi,
  GetSignatureFormTemplatesPathForApi,
  GetSignatureFormTemplatePathForApi,
  GetSignatureFormTemplateFieldsPathForApi,
  GetRecentDocumentSignatureFormsPathForApi,
} from '../Util/api';
import {
  GetUserValue,
} from '../Util/Properties';

/**
 * Specifies the drop target contract.
 * All methods are optional.
 */
const canvasTarget = {
  drop(props, monitor, component) {
    if (monitor.didDrop()) {
    //   // If you want, you can check whether some nested
    //   // target already handled drop
      return;
    }

    // Obtain the dragged item
    switch (monitor.getItemType()) {
      case "DraggableListItem":
        const draggableListItem = monitor.getItem();
        draggableListItem.onDrop(draggableListItem.Data,
          monitor.getClientOffset(), monitor.getInitialClientOffset(), true);
        break;
      case "DraggableSignatureField":
        const draggableSignatureField = monitor.getItem();
        draggableSignatureField.onDrop(draggableSignatureField.SignatureField, 
          monitor.getClientOffset(), monitor.getInitialSourceClientOffset(), false);
        break;
      default:
        break;
    }

    // You can also do nothing and return a drop result,
    // which will be available as monitor.getDropResult()
    // in the drag source's endDrag() method
    //return { moved: true };
  },
  hover(props, monitor, component) {
    if (!component)
      return null;

    // const sourceSignatureField = monitor.getItem();
    // component.handleMoveSignatureField(sourceSignatureField.Data, monitor.getClientOffset());
  },
}

/**
 * Specifies which props to inject into your component.
 */
function canvasDropCollect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectCanvasDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    //isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    // canDrop: monitor.canDrop(),
    // itemType: monitor.getItemType()
  };
}

/**
 * Specifies the drop target contract.
 * All methods are optional.
 */
const toolPaneTarget = {
  drop(props, monitor, component) {
    if (monitor.didDrop()) {
    //   // If you want, you can check whether some nested
    //   // target already handled drop
      return;
    }

    // Obtain the dragged item
    switch (monitor.getItemType()) {
      case "DraggableSignatureField":
        const sourceSignatureField = monitor.getItem();
        sourceSignatureField.onDrop2(sourceSignatureField.SignatureField);
        break;
      default:
        break;
    }

    // You can also do nothing and return a drop result,
    // which will be available as monitor.getDropResult()
    // in the drag source's endDrag() method
    //return { moved: true };
  },
  hover(props, monitor, component) {
    if (!component)
      return null;

    // const sourceSignatureField = monitor.getItem();
    // component.handleMoveSignatureField(sourceSignatureField.Data, monitor.getClientOffset());
  },
}

/**
 * Specifies which props to inject into your component.
 */
function toolPaneDropCollect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectToolPaneDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    //isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    // canDrop: monitor.canDrop(),
    // itemType: monitor.getItemType()
  };
}

const toolPaneWidth = 250;
const imageHeaderHeight = 48;
const _draggableSignatureFieldHeight = 24;
const styles = theme => ({
  outerContainer: {
    height:"100%",
  },
  contentContainer: {
    display:"flex",
    height:"100%",
    overflow:"hidden",
  },
  toolPane: {
    height:"100%",
    overflowY:"auto",
    minWidth:toolPaneWidth,
    maxWidth:toolPaneWidth,
    backgroundColor:theme.palette.background.pane,
  },
  toolPaneTopBar: {
    position:"sticky",
    top:0,
    left:0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    zIndex:2,
    display:"flex",
    alignItems:"center",
  },
  toolBoxTitle: {
    flexGrow: 1,
    paddingLeft:11,
  },
  canvasContainer: {
    height:`calc(100% - ${imageHeaderHeight+17}px)`,
    overflowY:"auto",
  },
  canvas: {
    position:"relative",
    backgroundColor:"white",
    width:"100%",
    height:"max-content",
    userSelect: "none",
  },
  documentImage: {
    width:"100%",
  },
  fieldGrid: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  imageContainer: {
    width:"100%",
    position:"relative",
    backgroundColor:"white",
  },
  imageHeader: {
    position:"sticky",
    top:0,
    left:0,
    // This wasn't needed after UiCore updates from Dark Mode work
    // height:imageHeaderHeight,
    display:"flex",
    alignItems:"center",
    backgroundColor:theme.palette.background.pane,
    zIndex:2,
    padding: theme.spacing(1),
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.divider,
  },
  imageHeaderFlexEnd: {
    display:"flex",
    flexGrow: 1,
    justifyContent:"flex-end",
    alignItems:"center",
    marginRight:theme.spacing(2),
  },
  templateInfoItem: {
    marginLeft:theme.spacing(2),
  },
  recentCreatedOn: {
    fontSize:10,
  },
  templateListSubheader: {
    paddingRight:0,
    backgroundColor:theme.palette.background.pane,
  },
  templateListSubheaderContent: {
    display:"flex",
    justifyContent: "space-between",
  },
  toolPaneList: {
    padding: theme.spacing(1),
  },
});

// const _autoPlaceFieldHeight = 40;

class SignatureFormDesigner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      DocumentSignatureForm: null,
      DocumentContentPackage: null,
      DocumentSignatureFormFields: [],
      FormFieldsForTemplateChanged: false,
      NavigateToPage_PageNumber: "",
      FieldIsDragging: false,
      ShowNavigateToPageDialog: false,
      ToolboxMenuAnchorEl: null,
      ShowDragExampleDialog: false,
      ShowClearAllFieldsConfirmation: false,
      ShowCreateTemplateConfirmation: false,
      ShowRemoveTemplateConfirmation: false,
      ShowRenameTemplateConfirmation: false,
      ShowUpdateTemplateConfirmation: false,
      ShowFormTemplates: false,
      ShowRecentForms: false,
      DocumentSignatureForms_Recent: [],
      RecentFormDocumentNameFilter: "",
      SignatureFormTemplates: [],
      TemplateNameFilter:"",
      SelectedTemplate: null,
      SelectedRecipientId: (this.props.recipients && this.props.recipients.length) ? this.props.recipients[0].ID : "",
      SelectedFormFieldIds: [],
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
    }

    this.CanvasRef = React.createRef();
    this.CanvasContainerRef = React.createRef();
    this.PreDragMousePosition = {x:0,y:0};
    // this.DefaultDropLocations = [/* { pageIndex, active, ID, x, y } */];
  }

  loadSignatureForm = () => {
    this.handleGetDocumentSignatureForm()
      .then(documentSignatureForm => {
        const pageIndex = (documentSignatureForm.LastViewedPageIndex < this.props.documentPageCount)
          ? documentSignatureForm.LastViewedPageIndex
          : 0;
        return this.handleGetDocumentContentPackage(pageIndex)
          .then(documentContentPackage => {
            return this.getSignatureFormFields(documentSignatureForm)
              .then(fields => {
                if (!fields.length) {
                  this.handleSetDragExampleVisibility(true)(null);
                }
              });
          });
      })
      .catch(this.handleApiError);
  }

  handleGetDocumentSignatureForm = () => {
    this.setState({ShowProgressIndicatorImmediately: true});
    return API.get(GetDocumentSignatureFormsPathForApi(this.props.organizationId, this.props.projectId,
      this.props.documentId))
      .then(resp => {
        this.setState({DocumentSignatureForm: resp.data});
        return resp.data;
      });
  }

  updateDocumentSignatureForm = documentSignatureForm => {
    if (!documentSignatureForm) {
      documentSignatureForm = {...this.state.DocumentSignatureForm};
    }
    documentSignatureForm.TotalFormFields = this.state.DocumentSignatureFormFields.length;
    documentSignatureForm.HasFormFields = documentSignatureForm.TotalFormFields > 0;
    return API.put(GetDocumentSignatureFormPathForApi(this.props.organizationId, this.props.projectId,
      documentSignatureForm.DocumentID, documentSignatureForm.ID),
      documentSignatureForm)
      .catch(this.handleApiError); 
  }

  handleGetDocumentContentPackage = (pageIndex_optional, updateLastViewedPageIndex) => {
    // this.setState({DocumentContentPackage: null});
    const uri = GetDocumentContentPackagesPathForApi(
      this.props.organizationId, this.props.projectId, this.props.documentId
    );
    return GetDocumentContentPackagePromise(uri, true, true, null, true, true, pageIndex_optional)
      .then(documentContentPackage => {
        let stateToUpdate = {
          DocumentContentPackage: documentContentPackage
        };
        if (pageIndex_optional !== undefined) {
          let documentSignatureForm = {...this.state.DocumentSignatureForm};
          documentSignatureForm.LastViewedPageIndex = pageIndex_optional;
          stateToUpdate = {...stateToUpdate, DocumentSignatureForm: documentSignatureForm};
          if (updateLastViewedPageIndex) {
            this.updateDocumentSignatureForm(documentSignatureForm);
          }
        }
        this.setState(stateToUpdate);
        return documentContentPackage;
      });
  }

  // DocumentSignatureFormField and SignatureFormTemplateField should be identical
  getSignatureFormFields = (formOrTemplate, isTemplate) => {
    if (!formOrTemplate) {
      formOrTemplate = this.state.DocumentSignatureForm;
    }
    this.setState({ShowProgressIndicatorImmediately: true});
    
    let uri = (isTemplate)
      ? GetSignatureFormTemplateFieldsPathForApi(this.props.organizationId, this.props.projectId,
        formOrTemplate.ID)
      : GetDocumentSignatureFormFieldsPathForApi(this.props.organizationId, this.props.projectId, 
        formOrTemplate.DocumentID, formOrTemplate.ID);

    return API.get(uri)
      .then(resp => {
        this.setState({
          DocumentSignatureFormFields: GetExtendedFormFields(resp.data),
          ShowProgressIndicatorImmediately: false,
        });
        return resp.data;
      });
  }

  handleAddDocumentSignatureFormField = field => {
    return this.addDocumentSignatureFormFields([field], false);
  }

  addDocumentSignatureFormFields = (newFields, clearExisting) => {
    this.setState({
      ShowProgressIndicator: true,
      SelectedFormFieldIds: [],
    });
    return API.post(GetDocumentSignatureFormFieldsPathForApi(this.props.organizationId, this.props.projectId,
      this.state.DocumentSignatureForm.DocumentID, this.state.DocumentSignatureForm.ID),
      newFields, { params: { clearExisting }})
      .then(resp => {
        let returnedFields = resp.data;
        let existingFields = [...this.state.DocumentSignatureFormFields];
        existingFields.forEach(ef => {
          for (let i = 0; i < newFields.length; i++) {
            if (newFields[i].ID === ef.ID) {
              ef.IsNew = true;
              break;
            }
          }
        });
        let nonNewFields = existingFields.filter(ef => !ef.IsNew);
        let allFields = [...nonNewFields, ...GetExtendedFormFields(returnedFields)];
        // TODO
        // this.updateDefaultDropLocationId(existingFieldFinder[0].ID, returnedField.ID);
        this.setState({
          DocumentSignatureFormFields: allFields,
          ShowProgressIndicator: false,
        });
        this.updateDocumentSignatureForm();
        return allFields;
      })
      .catch(this.handleApiError); 
  }

  handleUpdateDocumentSignatureFormField = field => {
    return API.put(GetDocumentSignatureFormFieldPathForApi(this.props.organizationId, this.props.projectId,
      this.state.DocumentSignatureForm.DocumentID, this.state.DocumentSignatureForm.ID, field.ID),
      field)
      .catch(this.handleApiError); 
  }

  handleClearAllDocumentSignatureFormFields = () => {
    this.setState({
      ShowClearAllFieldsConfirmation: false,
      ShowProgressIndicatorImmediately:true,
    });
    return API.delete(GetDocumentSignatureFormFieldsPathForApi(this.props.organizationId, this.props.projectId,
      this.state.DocumentSignatureForm.DocumentID, this.state.DocumentSignatureForm.ID))
      .then(() => {
        this.setState({
          DocumentSignatureFormFields: [], 
          SelectedFormFieldIds: [],
          FormFieldsForTemplateChanged: false,
          ShowProgressIndicatorImmediately:false,
        });
        this.updateDocumentSignatureForm();
      })
      .catch(this.handleApiError);  
  }

  deleteDocumentSignatureFormField = field => {
    this.setState({
      DocumentSignatureFormFields: this.state.DocumentSignatureFormFields
        .filter(f => f.ID !== field.ID),
      SelectedFormFieldIds: this.state.SelectedFormFieldIds
        .filter(i => i !== field.ID),
    });
    return API.delete(GetDocumentSignatureFormFieldPathForApi(this.props.organizationId, this.props.projectId,
      this.state.DocumentSignatureForm.DocumentID,this.state.DocumentSignatureForm.ID, field.ID))
      .then(() => {
        this.updateDocumentSignatureForm();
      })
      .catch(err => {
        this.setState({
          DocumentSignatureFormFields: this.state.DocumentSignatureFormFields.concat(field),
          SelectedFormFieldIds: this.state.SelectedFormFieldIds.concat(field.ID),
        });
        this.handleApiError(err);
      });  
  }

  handleCreateTemplateFromCurrentForm = name => {
    if (!name) {
      return;
    }
    this.setState({
      ShowProgressIndicator: true,
      ShowCreateTemplateConfirmation: false,
    });
    API.post(GetSignatureFormTemplatesPathForApi(this.props.organizationId, this.props.projectId), 
      [{ Name: name }])
      .then(resp => {
        this.replaceSignatureFormTemplateFields(resp.data[0].ID, [...this.state.DocumentSignatureFormFields]);

        let DocumentSignatureForm = {...this.state.DocumentSignatureForm};
        DocumentSignatureForm.SignatureFormTemplateID = resp.data[0].ID;
        this.setState({ DocumentSignatureForm});
        this.updateDocumentSignatureForm(DocumentSignatureForm);
      })
      .catch(this.handleApiError); 
  }

  handleRenameSelectedSignatureFormTemplate = name => {
    if (!name) {
      return;
    }
    let templates = [...this.state.SignatureFormTemplates];
    let templateFinder = templates.filter(t => t.ID === this.state.SelectedTemplate.ID);
    if (!templateFinder.length) {
      return;
    }
    templateFinder[0].Name = name;
    this.setState({
      ShowProgressIndicator: true,
      ShowRenameTemplateConfirmation: false,
    });
    API.put(GetSignatureFormTemplatePathForApi(this.props.organizationId, this.props.projectId,
      templateFinder[0].ID), templateFinder[0])
      .then(resp => {
        this.setState({
          SignatureFormTemplates: templates,
          ShowProgressIndicator: false,
        });
      })
      .catch(this.handleApiError); 
  }

  handleDeleteSignatureFormTemplate = signatureFormTemplateId => {
    this.setState({
      ShowProgressIndicator: true, 
      ShowRemoveTemplateConfirmation: false,
    });
    
    API.delete(GetSignatureFormTemplatePathForApi(this.props.organizationId, this.props.projectId,
      signatureFormTemplateId))
      .then(resp => {
        let DocumentSignatureForm = {...this.state.DocumentSignatureForm};
        DocumentSignatureForm.SignatureFormTemplateID = "";
        this.setState({ 
          DocumentSignatureForm,
          ShowProgressIndicator: false,
          SignatureFormTemplates: this.state.SignatureFormTemplates.filter(t => t.ID !== signatureFormTemplateId),
        });
        this.updateDocumentSignatureForm(DocumentSignatureForm);
      })
      .catch(this.handleApiError); 
  }

  handleUpdateSignatureFormTemplateFields = () => {
    this.setState({ShowUpdateTemplateConfirmation: false});
    if (!this.state.DocumentSignatureForm.SignatureFormTemplateID) {
      return;
    }
    this.replaceSignatureFormTemplateFields(this.state.DocumentSignatureForm.SignatureFormTemplateID,
      this.state.DocumentSignatureFormFields);
  }

  replaceSignatureFormTemplateFields = (signatureFormTemplateId, fields) => {
    this.setState({ ShowProgressIndicator: true, });
    API.post(GetSignatureFormTemplateFieldsPathForApi(this.props.organizationId, this.props.projectId,
      signatureFormTemplateId),
      fields, { params: { clearExisting: true }})
      .then(resp => {
        this.setState({ 
          ShowProgressIndicator: false, 
          FormFieldsForTemplateChanged: false,
        });
      })
      .catch(this.handleApiError); 
  }

  handleGoToContentPage = pageNumber => {
    if (pageNumber === undefined || pageNumber === null) {
      pageNumber = this.state.NavigateToPage_PageNumber-1;
    }
    let p = this.state.DocumentContentPackage;
    if (p.PageCount && pageNumber > -1 && pageNumber < p.PageCount) {
      this.setState({ShowProgressIndicatorImmediately:true});
      this.handleGetDocumentContentPackage(pageNumber, true)
        .then(() => {
          this.setState({ShowProgressIndicatorImmediately:false});
          this.CanvasContainerRef.scrollTop = 0;
        })
        .catch(this.handleApiError);
    }
  }

  handleSetShowNavigateToPageDialogVisibility = visible => {
    this.setState({ NavigateToPage_PageNumber: "", ShowNavigateToPageDialog: visible });
  }

  handleSetShowRecentForms = visible => {
    this.setState({
      ToolboxMenuAnchorEl: null,
    });
    let stateToUpdate = {
      ShowFormTemplates: false,
      ShowRecentForms: visible,
      SelectedTemplate: null,
      RecentFormDocumentNameFilter: "",
      DocumentSignatureFormFields: [],
    };
    if (visible) {
      this.loadRecentForms(null, stateToUpdate);
    } else {
      this.setState(stateToUpdate);
      this.getSignatureFormFields()
        .catch(this.handleApiError);
    }
  }

  handleSetShowFormTemplates = visible => {
    this.setState({
      ToolboxMenuAnchorEl: null,
    });
    let stateToUpdate = {
      ShowRecentForms: false,
      ShowFormTemplates: visible,
      SelectedTemplate: null,
      TemplateNameFilter: "",
      DocumentSignatureFormFields: [],
    };
    if (visible) {
      this.loadFormTemplates(null, stateToUpdate);
    } else {
      this.setState(stateToUpdate);
      this.getSignatureFormFields()
        .catch(this.handleApiError);
    }
  }

  handleNavigateToPage_PageNumberValueChanged = e => {
    let value = "";
    if (e && e.target) {
      value = e.target.value;
    }
    this.setState({ NavigateToPage_PageNumber: value });      
  }

  getRelativePosition = (widthPercent, clientOffset, initialClientOffset_optional) => {
    const canvasContainerRect = this.CanvasContainerRef.getBoundingClientRect();
    let x = clientOffset.x, y = clientOffset.y;
    if (initialClientOffset_optional) {
      let mouseDownOffset = {
        x:this.PreDragMousePosition.x - initialClientOffset_optional.x,
        y:this.PreDragMousePosition.y - initialClientOffset_optional.y,
      };
      x += this.CanvasContainerRef.scrollLeft - canvasContainerRect.x - mouseDownOffset.x;
      y += this.CanvasContainerRef.scrollTop - canvasContainerRect.y - mouseDownOffset.y;
    }
    if (x < 0) {
      x = 0;
    }
    if (y < 0) {
      y = 0;
    }
    const canvasRect = this.CanvasRef.getBoundingClientRect();
    let adjustedWidth = (canvasRect.width*(widthPercent*100))/100;
    if (x > canvasRect.width - adjustedWidth - 2) {
      x = canvasRect.width - adjustedWidth - 2;
    }
    if (y > canvasRect.height - _draggableSignatureFieldHeight - 2) {
      y = canvasRect.height - _draggableSignatureFieldHeight - 2;
    }

    return { 
      x: x/this.CanvasRef.clientWidth, 
      y: y/this.CanvasRef.clientHeight,
    };
  }

  getNewDocumentSignatureFieldFromType = documentSignatureFormFieldType => {
    let f = {...documentSignatureFormFieldType};
    if (!f.ID) {
      f.ID = (new Date()).getTime().toString();
    }
    return f;
  }

  handleDropSignatureFieldOnCanvas = (documentSignatureFieldOrType, clientOffset, initialClientOffset, isNew) => {
    if (!this.CanvasRef.getBoundingClientRect) {
      return;
    }

    let fields = this.state.DocumentSignatureFormFields;
    let f = {};
    if (isNew) {
      f = this.getNewDocumentSignatureFieldFromType(documentSignatureFieldOrType);
      f.DocumentSignatureSessionRecipientID = this.state.SelectedRecipientId;
    } else {
      f = fields.filter(sf => sf.ID === documentSignatureFieldOrType.ID)[0];
      // this.setDefaultDropLocationInactive(f.ID);
    }
    if (f) {
      let pos = this.getRelativePosition(f.WidthPercent, clientOffset, initialClientOffset);
      f.X = pos.x;
      f.Y = pos.y;
      if (isNew) {
        f.PageIndex = this.state.DocumentContentPackage.PageIndex;
        fields = fields.concat(f);
        this.setState({ DocumentSignatureFormFields: fields });
        this.handleAddDocumentSignatureFormField(f);
      } else {
        this.handleUpdateDocumentSignatureFormField(f);
      }
      this.setState({ FormFieldsForTemplateChanged: this.state.DocumentSignatureForm.SignatureFormTemplateID });
    }
  }

  handleDropSignatureFieldOnToolPane = documentSignatureField => {
    this.deleteDocumentSignatureFormField(documentSignatureField);
  }

  handleSignatureFieldMouseDown = (e, id) => {
    // Disallow select when we're previewing a recent form or template
    if (this.state.SelectedTemplate) {
      return;
    }
    this.PreDragMousePosition = {x:e.clientX, y:e.clientY};
    e.stopPropagation();
    if (!this.props.recipients) {
      return;
    }
    let SelectedFormFieldIds = [...this.state.SelectedFormFieldIds];
    let isMulti = e.ctrlKey;
    let fieldFinder = this.state.DocumentSignatureFormFields.filter(f => f.ID === id);
    if (fieldFinder.length) {
      if (isMulti) {
        if (!SelectedFormFieldIds.filter(i => i === fieldFinder[0].ID).length) {
          SelectedFormFieldIds.push(fieldFinder[0].ID);
        }
      } else {
        SelectedFormFieldIds = [fieldFinder[0].ID];
      }
      let stateToUpdate = {
        SelectedFormFieldIds,
      };
      if (!isMulti) {
        stateToUpdate.SelectedRecipientId = fieldFinder[0].DocumentSignatureSessionRecipientID;
      }
      this.setState(stateToUpdate);
    }
  }

  // setDefaultDropLocationInactive = id => {
  //   let location = this.DefaultDropLocations.filter(l => l.ID === id);
  //   if (location.length) {
  //     location[0].active = false;
  //   }
  // }

  // updateDefaultDropLocationId = (oldId, newId) => {
  //   let locations = this.DefaultDropLocations;
  //   let locationFinder = locations.filter(l => l.ID === oldId);
  //   if (locationFinder.length) {
  //     locationFinder[0].ID = newId;
  //   }
  // }

  // Needs work - Team opted to shelve this
  // handleCreateNewSignatureFieldAtDefaultLocation = documentSignatureFormFieldType => {
  //   let f = this.getNewDocumentSignatureFieldFromType(documentSignatureFormFieldType);

  //   // Get default drop location
  //   let maxDefaultPositions = 10;
  //   let defaultPos = this.props.theme.spacing(3);
  //   let increment = _autoPlaceFieldHeight + this.props.theme.spacing(2);
  //   let pageIndex = this.state.DocumentContentPackage.PageIndex;
  //   let locations = [...this.DefaultDropLocations.filter(l => l.pageIndex === pageIndex)];
  //   let location = {};
  //   if (!locations.length) {
  //     location = { pageIndex, active: true, ID: f.ID, x: defaultPos, y: defaultPos };
  //     this.DefaultDropLocations = this.DefaultDropLocations.concat(location);
  //   } else {
  //     let ddl = locations.filter(l => !l.active);
  //     if (ddl.length) {
  //       location = { pageIndex, active: true, ID: f.ID, x: ddl[0].x, y: ddl[0].y };
  //       this.DefaultDropLocations.splice(this.DefaultDropLocations.indexOf(ddl[0]), 1, location);
  //     } else {
  //       location = locations[locations.length-1];
  //       if (locations.length < maxDefaultPositions) {
  //         // We're only incrementing the y value for now
  //         let x = location.x;
  //         let y = location.y + increment;
  //         location = { pageIndex, active: true, ID: f.ID, x, y };
  //         this.DefaultDropLocations = this.DefaultDropLocations.concat(location);
  //       }
  //     }
  //   }
  //   let clientOffset = { x: location.x, y: location.y };
  //   this.handleDropSignatureFieldOnCanvas(f, clientOffset, undefined, true);
  // }

  handleSetToolboxMenuVisibility = visible => event => {
    this.setState({ ToolboxMenuAnchorEl: (visible) ? event.currentTarget : null });
  }

  handleSetClearAllFieldsConfirmationVisibility = visible => event => {
    this.setState({
      ToolboxMenuAnchorEl: null,
      ShowClearAllFieldsConfirmation: visible,
    });
  }

  handleSetShowCreateTemplateConfirmationVisibility = visible => event => {
    this.setState({
      ToolboxMenuAnchorEl: null,
      ShowCreateTemplateConfirmation: visible,
    });
  }

  handleSetShowRemoveTemplateConfirmationVisibility = visible => event => {
    this.setState({
      ShowRemoveTemplateConfirmation: visible,
    });
  }

  handleSetShowRenameTemplateConfirmationVisibility = visible => event => {
    this.setState({
      ShowRenameTemplateConfirmation: visible,
    });
  }

  handleSetShowUpdateTemplateConfirmationVisibility = visible => event => {
    this.setState({
      ToolboxMenuAnchorEl: null,
      ShowUpdateTemplateConfirmation: visible,
    });
  }

  handleSetSignatureFieldsMissingDialogVisibility = visible => event => {
    this.setState({
      ShowSignatureFieldsMissingDialog: visible,
    });
  }

  handleSetSignatureFieldsUnmappedDialogVisibility = visible => event => {
    this.setState({
      ShowSignatureFieldsUnmappedDialog: visible,
    }); 
  }
  
  handleCanvasMouseDown = e => {
    this.PreDragMousePosition = {x:e.clientX, y:e.clientY};
    this.setState({SelectedFormFieldIds: []});
  }

  handleRecipientValueChange = recipientId => {
    let stateToUpdate = {SelectedRecipientId:recipientId};
    if (this.state.SelectedFormFieldIds.length) {
      let fields = [...this.state.DocumentSignatureFormFields];
      this.state.SelectedFormFieldIds.forEach(id => {
        let fieldFinder = fields.filter(f => f.ID === id);
        if (fieldFinder.length) {
          fieldFinder[0].DocumentSignatureSessionRecipientID = recipientId;
          this.handleUpdateDocumentSignatureFormField(fieldFinder[0]);
        }
      });
      stateToUpdate.DocumentSignatureFormFields = fields;
    }
    this.setState(stateToUpdate);
  }

  handleApiError = err => {
    if (this.props.onApiError) {
      this.props.onApiError(err);
    }
    this.setState({ShowProgressIndicator: false, ShowProgressIndicatorImmediately: false});
  }

  canMoveForward = () => {
    // Ensure at least one signature field for each signer
    let emailsMissingSignature = [];
    if (this.props.recipients && this.props.recipients.length) {
      this.props.recipients.filter(r => r.Type === "Signer")
        .forEach(s => {
          let sigFieldFinder = this.state.DocumentSignatureFormFields
            .filter(f => f.DocumentSignatureSessionRecipientID === s.ID && f.Type === "Signature");
          if (!sigFieldFinder.length) {
            emailsMissingSignature.push(s.AssignmentUserEmail);
          }
        });
    }
    if (emailsMissingSignature.length) {
      return {
        Proceed: false,
        DialogTitle:"Signature field(s) missing",
        DialogBodyText:`Please place one signature field for each signer. Missing: ${emailsMissingSignature.join(", ")}`,
      };
    }

    let unmappedFields = [];
    this.state.DocumentSignatureFormFields.forEach(f => {
      let recipientFinder = this.props.recipients.filter(r => r.ID === f.DocumentSignatureSessionRecipientID);
      if (!f.DocumentSignatureSessionRecipientID || !recipientFinder.length) {
        unmappedFields.push({...f});        
      }
    });
    if (unmappedFields.length) {
      return {
        Proceed: false,
        AllowSkip: true,
        DialogTitle:"Unmapped field(s)",
        DialogBodyText:`One or more fields is not mapped to a recipient.`,
        DialogCancelLabel: "STAY",
        DialogConfirmLabel: "SKIP",
      };
    }
    return { Proceed: true };
  }

  loadRecentForms = debounce((nameFilter, additionalStateToUpdate) => {
    this.setState({ShowProgressIndicator: true});
    return API.get(GetRecentDocumentSignatureFormsPathForApi(this.props.organizationId, this.props.projectId),
      { params: { excludingId: this.state.DocumentSignatureForm.ID, nameFilter }} )
      .then(resp => {
        let stateToUpdate = {
          DocumentSignatureForms_Recent: resp.data,
          ShowProgressIndicator: false,
        };
        if (additionalStateToUpdate) {
          stateToUpdate = {...stateToUpdate, ...additionalStateToUpdate};
        }
        this.setState(stateToUpdate);
        return resp.data;
      })
      .catch(this.handleApiError); 
  }, 250)

  loadFormTemplates = debounce((nameFilter, additionalStateToUpdate) => {
    this.setState({ShowProgressIndicator: true});
    return API.get(GetSignatureFormTemplatesPathForApi(this.props.organizationId, this.props.projectId),
      { params: { nameFilter }} )
      .then(resp => {
        let stateToUpdate = {
          SignatureFormTemplates: resp.data,
          ShowProgressIndicator: false,
        };
        if (additionalStateToUpdate) {
          stateToUpdate = {...stateToUpdate, ...additionalStateToUpdate};
        }
        this.setState(stateToUpdate);
        return resp.data;
      })
      .catch(this.handleApiError); 
  }, 250)

  handleSelectRecentForm = form => {
    this.setState({
      DocumentSignatureFormFields: [],
      SelectedTemplate: form,
    });
    this.getSignatureFormFields(form)
      .catch(this.handleApiError);
  }

  handleSelectFormTemplate = template => {
    // Mark template as such for handleCopyVisibleFieldsToCurrentForm()
    template.IsTemplate = true;
    this.setState({
      DocumentSignatureFormFields: [],
      SelectedTemplate: template,
    });
    this.getSignatureFormFields(template, true)
      .catch(this.handleApiError);
  }

  handleCopyVisibleFieldsToCurrentForm = () => {
    this.setState({
      ShowFormTemplates: false,
      ShowRecentForms: false,
      SelectedTemplate: null,
      SelectedFormFieldIds: [],
      FormFieldsForTemplateChanged: false,
    });
    this.addDocumentSignatureFormFields([...this.state.DocumentSignatureFormFields], true);
    let templateForm = {...this.state.SelectedTemplate};
  
    let documentSignatureForm = {...this.state.DocumentSignatureForm};
    documentSignatureForm.SignatureFormTemplateID = (templateForm.IsTemplate)
      ? templateForm.ID : "";
    this.setState({DocumentSignatureForm: documentSignatureForm});
    this.updateDocumentSignatureForm(documentSignatureForm);
  }

  handleClearRecentFormDocumentNameFilter = () => {
    this.handleSetRecentFormDocumentNameFilter("");
  }

  handleSetRecentFormDocumentNameFilter = nameFilter => {
    this.setState({RecentFormDocumentNameFilter: nameFilter});
    this.loadRecentForms(nameFilter); 
  }

  handleClearTemplateFilterName = () => {
    this.handleSetTemplateNameFilter("");
  }

  handleSetTemplateNameFilter = nameFilter => {
    this.setState({TemplateNameFilter: nameFilter});
    this.loadFormTemplates(nameFilter); 
  }

  handleSetDragExampleVisibility = visible => event => {
    this.setState({
      ToolboxMenuAnchorEl: null,
      ShowDragExampleDialog: visible,
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
    });
  }

  componentDidMount() {
    if (this.props.onConnectCanMoveForward) {
      this.props.onConnectCanMoveForward(this.canMoveForward);
    }

    this.loadSignatureForm();
  }

  componentWillUnmount() {
  }
  
  render() {
  	const { 
      DocumentSignatureForm,
      DocumentContentPackage,
      ShowProgressIndicator,
      ShowProgressIndicatorImmediately,
      DocumentSignatureFormFields,
      FormFieldsForTemplateChanged,
      ShowNavigateToPageDialog,
      NavigateToPage_PageNumber,
      FieldIsDragging,
      ToolboxMenuAnchorEl,
      ShowDragExampleDialog,
      ShowClearAllFieldsConfirmation,
      ShowCreateTemplateConfirmation,
      ShowRemoveTemplateConfirmation,
      ShowRenameTemplateConfirmation,
      ShowUpdateTemplateConfirmation,
      SelectedRecipientId,
      SelectedFormFieldIds,
      DocumentSignatureForms_Recent,
      RecentFormDocumentNameFilter,
      SignatureFormTemplates,
      TemplateNameFilter,
      SelectedTemplate,
      ShowRecentForms,
      ShowFormTemplates,
  	} = this.state;
    const {
      connectCanvasDropTarget,
      connectToolPaneDropTarget,
      recipients,
      classes,
      theme,
    } = this.props;

    let clearAllFieldsConfirmationDialogDetails = {
      Open:ShowClearAllFieldsConfirmation,
      IsConfirmation:true,
      Title:"Clear all fields?",
      BodyText:"All fields on all pages will be removed. This action cannot be undone.",
      BodyClassName:"warning",
      CancelCallback:this.handleSetClearAllFieldsConfirmationVisibility(false),
      CloseCallback:this.handleSetClearAllFieldsConfirmationVisibility(false),
      ConfirmCallback:this.handleClearAllDocumentSignatureFormFields,
    };

    let createTemplateConfirmationDialogDetails = {
      Open:ShowCreateTemplateConfirmation,
      RequireTextInput1:true,
      TextInput1Label:"Name",
      TextInput1PlaceHolder:"New Template",
      Title:"Create template",
      CancelCallback:this.handleSetShowCreateTemplateConfirmationVisibility(false),
      CloseCallback:this.handleSetShowCreateTemplateConfirmationVisibility(false),
      ConfirmLabel: "CREATE",
      ConfirmCallback:this.handleCreateTemplateFromCurrentForm,
    };

    let removeTemplateConfirmationDialogDetails = {
      Open:ShowRemoveTemplateConfirmation,
      IsConfirmation:true,
      Title:"Remove template?",
      BodyClassName:"warning",
      BodyText:`The template${(SelectedTemplate && SelectedTemplate.Name) ? " \"" + SelectedTemplate.Name + "\"" : ""} will be deleted. This action cannot be undone.`,
      CancelCallback:this.handleSetShowRemoveTemplateConfirmationVisibility(false),
      CloseCallback:this.handleSetShowRemoveTemplateConfirmationVisibility(false),
      ConfirmCallback:() => this.handleDeleteSignatureFormTemplate(SelectedTemplate && SelectedTemplate.ID),
    };

    let renameTemplateConfirmationDialogDetails = {
      Open:ShowRenameTemplateConfirmation,
      RequireTextInput1:true,
      TextInput1Label:"Name",
      TextInput1PlaceHolder: SelectedTemplate && SelectedTemplate.Name,
      Title:"Rename template",
      CancelCallback:this.handleSetShowRenameTemplateConfirmationVisibility(false),
      CloseCallback:this.handleSetShowRenameTemplateConfirmationVisibility(false),
      ConfirmLabel: "RENAME",
      ConfirmCallback:this.handleRenameSelectedSignatureFormTemplate,
    };

    let updateTemplateConfirmationDialogDetails = {
      Open:ShowUpdateTemplateConfirmation,
      IsConfirmation:true,
      Title:"Update template?",
      BodyText: "Changes you have made to this form will be updated on the template.",
      CancelCallback:this.handleSetShowUpdateTemplateConfirmationVisibility(false),
      CloseCallback:this.handleSetShowUpdateTemplateConfirmationVisibility(false),
      ConfirmCallback:this.handleUpdateSignatureFormTemplateFields,
    };

    let dragExampleDetails = {
      Open:ShowDragExampleDialog,
      Title:"Adding fields",
      DialogWidth:"md",
      BodyContent: (
        <img src="/signatureFormDesignerDragExample.gif" alt="Tutorial: Adding fields" />
      ),
      CloseCallback:this.handleSetDragExampleVisibility(false),
    }

    let dcp = DocumentContentPackage;
    
    let templatePreviewInfo = null;
    let isPreviewingForm = (ShowRecentForms || ShowFormTemplates);
    if (isPreviewingForm) {
      let templateInfoItems = [];
      if (SelectedTemplate) {
        templateInfoItems.push(
          <div key="k_previewing" className={classes.templateInfoItem}>
            <Typography variant="body2" color={theme.palette.type === "dark" ? "white" : "secondary"}>
              {`Previewing ${(ShowRecentForms) ? "recent form" : "form template"}`}
            </Typography>
          </div>
        );
      }
      if (ShowRecentForms && SelectedTemplate) {
        templateInfoItems.push(
          <div key="k_copy" className={classes.templateInfoItem}>
            <Tooltip title="Replace current form with copy of recent form"> 
              <Button variant="contained" color="primary"
                onClick={() => this.handleCopyVisibleFieldsToCurrentForm(SelectedTemplate)}>
                COPY
              </Button>
            </Tooltip>
          </div>
        );
        templateInfoItems.push(
          <div key="k_close" className={classes.templateInfoItem}>
            <Tooltip title="Hide preview"> 
              <Button variant="contained" color="primary"
                onClick={() => this.handleSetShowRecentForms(false)}>
                HIDE
              </Button>
            </Tooltip>
          </div>
        );
      }
      if (ShowFormTemplates && SelectedTemplate) {
        templateInfoItems.push(
          <div key="k_copy" className={classes.templateInfoItem}>
            <Tooltip title="Replace current form with copy of template"> 
              <Button variant="contained" color="primary"
                onClick={() => this.handleCopyVisibleFieldsToCurrentForm(SelectedTemplate)}>
                COPY
              </Button>
            </Tooltip>
          </div>
        );
        templateInfoItems.push(
          <div key="k_close" className={classes.templateInfoItem}>
            <Tooltip title="Hide preview"> 
              <Button variant="contained" color="primary"
                onClick={() => this.handleSetShowFormTemplates(false)}>
                HIDE
              </Button>
            </Tooltip>
          </div>
        );
      }
      templatePreviewInfo = (
        <div className={classes.imageHeaderFlexEnd}>
          {templateInfoItems}
        </div>
      );
    }

    let templateUpdateInfo = null;
    if (FormFieldsForTemplateChanged && !isPreviewingForm) {
      templateUpdateInfo = (
        <div className={classes.imageHeaderFlexEnd}>
          <div key="k_copy" className={classes.templateInfoItem}>
            <Button variant="contained" color="primary"
              onClick={this.handleSetShowUpdateTemplateConfirmationVisibility(true)}>
              UPDATE TEMPLATE
            </Button>
          </div>
        </div>
      );
    }

    let selectedFieldInfo = null;
    if (SelectedFormFieldIds.length && !isPreviewingForm) {
      selectedFieldInfo = (
        <div className={classes.imageHeaderFlexEnd}>
          {`${SelectedFormFieldIds.length} selected field${SelectedFormFieldIds.length > 1 ? "s" : ""}`}
        </div>
      );
    }

    let goToPageButton = (dcp && dcp.PageCount && dcp.PageCount > 2)
      ? (
        <Tooltip title="Go to page">
          <IconButton
            disabled={!dcp || !dcp.PageCount || dcp.PageCount <= 2}
            onClick={() => this.handleSetShowNavigateToPageDialogVisibility(true)}>
            <NavigateToPageIcon />
          </IconButton>
        </Tooltip>
      ) : null;

    let currentPageNumber = (dcp && dcp.PageCount)
    ? (
        <Typography variant="body2"
          style={{marginLeft:theme.spacing(3)}}>
          {`Viewing page ${1 + dcp.PageIndex} of ${dcp.PageCount}`}
        </Typography>
    ) : null;

    let imageHeader = (
      <div className={classes.imageHeader}>
        <Tooltip title="First page">
          <span>
          <IconButton
            disabled={!dcp || !dcp.PageCount || dcp.PageCount < 3 || dcp.PageIndex === 0}
            onClick={() => this.handleGoToContentPage(0)}>
            <NavigateFirstIcon />
          </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Previous page">
          <span>
          <IconButton
            disabled={!dcp || !dcp.PageCount || dcp.PageIndex === 0}
            onClick={() => this.handleGoToContentPage(dcp.PageIndex - 1)}>
            <NavigatePrevIcon />
          </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Next page">
          <span>
          <IconButton
            disabled={!dcp || !dcp.PageCount || dcp.PageIndex === dcp.PageCount - 1}
            onClick={() => this.handleGoToContentPage(dcp.PageIndex + 1)}>
            <NavigateNextIcon />
          </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Last page">
          <span>
          <IconButton
            disabled={!dcp || !dcp.PageCount || dcp.PageCount < 3 || dcp.PageIndex === dcp.PageCount - 1}
            onClick={() => this.handleGoToContentPage(dcp.PageCount-1)}>
            <NavigateLastIcon />
          </IconButton>
          </span>
        </Tooltip>
        {goToPageButton}
        {currentPageNumber}
        {selectedFieldInfo}
        {templatePreviewInfo}
        {templateUpdateInfo}
      </div>
    );

    let formFieldListItems = DocumentSignatureFormFieldTypes.map(t => {
      return (
        <DraggableListItem 
          key={t.Type}
          Data={t} 
          Text={t.Label}
          hideDragPreview
          onDrop={this.handleDropSignatureFieldOnCanvas}
          // onClick={() => this.handleCreateNewSignatureFieldAtDefaultLocation(t)}
        />
      );
    });

    let signerOptions = [];
    if (recipients && recipients.length) {
      let signers = recipients.filter(r => r.Type === "Signer");
      if (signers.length) {
        signerOptions = signers
          .map(
            s => { return {
              label: GetUserValue(
                s.AssignmentUserEmail, 
                (s.AssignmentUserName)
                  ? `${s.AssignmentUserName} (${s.AssignmentUserEmail})`
                  : s.AssignmentUserEmail
                ,
              ),
              value: s.ID,
              color:"#222",
              backgroundColor: SignatureColorCodes[signers.indexOf(s) % SignatureColorCodes.length],
            }});
      }
    }

    let currentSignerOptionFinder = signerOptions.filter(so => so.value === SelectedRecipientId);
    let currentSignerOptionBackgroundColor = currentSignerOptionFinder.length && currentSignerOptionFinder[0].backgroundColor;

    let documentSignatureFormFields = DocumentSignatureFormFields.map(f => {
      let signerOptionFinder = signerOptions.filter(so => so.value === f.DocumentSignatureSessionRecipientID);
      let backgroundColor = (SelectedTemplate)
        ? theme.palette.primary.main
        : (signerOptionFinder.length)
          ? signerOptionFinder[0].backgroundColor
          : undefined;
      return (dcp && dcp.PageCount && f.PageIndex === dcp.PageIndex)
        ? (
          <DraggableSignatureField
            key={f.ID}
            disableDragging={SelectedTemplate}
            disableSelecting={SelectedTemplate}
            SignatureField={f}
            height={_draggableSignatureFieldHeight}
            backgroundColor={backgroundColor}
            selected={SelectedFormFieldIds.filter(id => id === f.ID).length}
            onMouseDown={this.handleSignatureFieldMouseDown}
            onBeginDrag={() => this.setState({FieldIsDragging:true})}
            onEndDrag={() => this.setState({FieldIsDragging:false})}
            onDrop={this.handleDropSignatureFieldOnCanvas}
            onDrop2={this.handleDropSignatureFieldOnToolPane}
          />
        ) : null;
    });

    let documentImageCanvas = (dcp)
      ? (
        <div
          ref={instance => {
            connectCanvasDropTarget(ReactDOM.findDOMNode(instance));
            this.CanvasRef = instance;
          }}
          onMouseDown={this.handleCanvasMouseDown}
          className={classes.canvas}>
          {documentSignatureFormFields}
          <img src={dcp.Url} className={classes.documentImage} alt="" />
        </div>
      ) : null;

    let navigateToPageDialogDetails = {
      Open:ShowNavigateToPageDialog,
      Title:`Go to page (1-${(dcp && dcp.PageCount)})`,
      DialogWidth: "xs",
      BodyContent: (
        <NumericTextField
          id="navigateToPage_pageNumber"
          label="Page number"
          autoFocus
          value={NavigateToPage_PageNumber}
          onValueChange={this.handleNavigateToPage_PageNumberValueChanged}
          hideClearButton
          onEnterKey={() => { this.handleGoToContentPage(); this.handleSetShowNavigateToPageDialogVisibility(false); }}
         />
      ),
      CloseCallback:() => this.handleSetShowNavigateToPageDialogVisibility(false),
    };

    let templateList = null;
    if (ShowRecentForms) {
      let recentListItems = [];
      DocumentSignatureForms_Recent.forEach(
        f => {
          let createdOn = dateformat(f.CreatedOn, "m/d/yy h:MM:ss TT");
          let selected = (SelectedTemplate 
            && SelectedTemplate.ID === f.ID);
          recentListItems.push(
            <ListItem button key={`li_${f.ID}`}
              selected={selected}
              onClick={() => this.handleSelectRecentForm(f)}>
              <ListItemText>
                <span className={classes.recentCreatedOn}>
                  {createdOn}
                </span>
                <br />
                {f.DocumentName}
              </ListItemText>
            </ListItem>
          );
        });
      let clearInputAdornment = 
        (RecentFormDocumentNameFilter) 
          ? (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                aria-label="clear"
                onClick={this.handleClearRecentFormDocumentNameFilter}
              >
                <ClearIcon style={{fontSize:18}} />
              </IconButton>
            </InputAdornment>
          ) 
        : null;
      templateList = (
        <List className={classes.toolPaneList} 
          subheader={
          <ListSubheader component="div"
            className={classes.templateListSubheader}>
            <div className={classes.templateListSubheaderContent}>
              <div style={{flexGrow:1,marginTop:6}}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Search recent..."
                  size="small"
                  value={RecentFormDocumentNameFilter}
                  onChange={e => this.handleSetRecentFormDocumentNameFilter(e.target.value)}
                  InputProps={{
                    endAdornment: clearInputAdornment,
                  }}
                />
              </div>
              <div>
                <IconButton onClick={() => this.handleSetShowRecentForms(false)}>
                  <ArrowBackIcon />
                </IconButton>
              </div>
            </div>
          </ListSubheader>
        }>
          {recentListItems}
        </List>
      );
    }
    if (ShowFormTemplates) {
      let selectedListItemGrid = (
        <Grid container spacing={0}
          justify="flex-end"
        >
          <Grid item>
            <Tooltip title="Rename template"> 
              <IconButton onClick={this.handleSetShowRenameTemplateConfirmationVisibility(true)}>
                <RenameIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Remove template"> 
              <IconButton onClick={this.handleSetShowRemoveTemplateConfirmationVisibility(true)}>
                <RemoveIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      );
      let templateListItems = [];
      SignatureFormTemplates.forEach(
        f => {
          let selected = (SelectedTemplate 
            && SelectedTemplate.ID === f.ID);
          templateListItems.push(
            <ListItem button key={`li_${f.ID}`}
              selected={selected}
              onClick={() => this.handleSelectFormTemplate(f)}>
              <ListItemText>
                {f.Name}
              </ListItemText>
            </ListItem>
          );
          if (selected) {
            templateListItems.push(
              <ListItem key="li_selectedMenu" style={{paddingRight:0}}>
                {selectedListItemGrid}
              </ListItem>
            );
          }
        });
      let clearInputAdornment = 
        (TemplateNameFilter) 
          ? (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                aria-label="clear"
                onClick={this.handleClearTemplateFilterName}
              >
                <ClearIcon style={{fontSize:18}} />
              </IconButton>
            </InputAdornment>
          ) 
        : null;
      templateList = (
        <List className={classes.toolPaneList} 
          subheader={
          <ListSubheader component="div"
            className={classes.templateListSubheader}>
            <div className={classes.templateListSubheaderContent}>
              <div style={{flexGrow:1,marginTop:6}}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Search templates..."
                  size="small"
                  value={TemplateNameFilter}
                  onChange={e => this.handleSetTemplateNameFilter(e.target.value)}
                  InputProps={{
                    endAdornment: clearInputAdornment,
                  }}
                />
              </div>
              <div>
                <IconButton onClick={() => this.handleSetShowFormTemplates(false)}>
                  <ArrowBackIcon />
                </IconButton>
              </div>
            </div>
          </ListSubheader>
        }>
          {templateListItems}
        </List>
      );
    }

    let clearAllFieldsMenuItem = (DocumentSignatureFormFields.length)
      ? (
        <MenuItem onClick={this.handleSetClearAllFieldsConfirmationVisibility(true)}>Clear all fields</MenuItem>
      ) : null;

    let createTemplateMenuItem = (!DocumentSignatureForm || !DocumentSignatureForm.SignatureFormTemplateID)
      ? (
        <MenuItem onClick={this.handleSetShowCreateTemplateConfirmationVisibility(true)}>Save as template</MenuItem>
      ) : null;

    let toolPaneTopBar = (
      <div className={classes.toolPaneTopBar}>
        <Typography variant="subtitle2" className={classes.toolBoxTitle}>
          Toolbox
        </Typography>
        <IconButton
          aria-label="Toolbox menu"
          aria-controls="toolbox-menu"
          aria-haspopup="true"
          onClick={this.handleSetToolboxMenuVisibility(true)}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="toolbox-menu"
          anchorEl={ToolboxMenuAnchorEl}
          keepMounted
          open={Boolean(ToolboxMenuAnchorEl)}
          onClose={this.handleSetToolboxMenuVisibility(false)}
        >
          {createTemplateMenuItem}
          <MenuItem onClick={() => this.handleSetShowFormTemplates(true)}>Templates</MenuItem>
          <MenuItem onClick={() => this.handleSetShowRecentForms(true)}>Recent forms</MenuItem>
          {clearAllFieldsMenuItem}
          <Divider />
          <MenuItem onClick={this.handleSetDragExampleVisibility(true)}>Tutorial: Adding fields</MenuItem>
        </Menu>
      </div>
    );

    let toolPaneContent = (FieldIsDragging)
      ? (
        <div 
          style={{
            width:"100%",
            height:"100%",
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
          }}
          ref={instance => connectToolPaneDropTarget(ReactDOM.findDOMNode(instance))}>
          <DeleteIcon fontSize="large" />
        </div>
      )
      : (templateList)
        ? templateList
        : (
          <div>
            {toolPaneTopBar}
            <div style={{marginLeft:theme.spacing(3), marginRight:theme.spacing(3)}}>
              <SelectControl
                id="signerSelect"
                fontSize={12}
                // label="Signer"
                showTooltipForLabel
                hideEmpty
                options={signerOptions} 
                value={SelectedRecipientId}
                onValueChange={this.handleRecipientValueChange} />
            </div>
            <Grid container direction="column" className={classes.fieldGrid}>
              <Grid item>
                <List component="nav"
                  subheader={<ListSubheader component="div">Form Fields</ListSubheader>}>
                  {formFieldListItems}
                </List>
              </Grid>
            </Grid>
          </div>
        );

    let progressIndicator = null;
    if (ShowProgressIndicator || ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    return (
      <div className={classes.outerContainer}>
        <SignatureFieldDragLayer
          backgroundColor={currentSignerOptionBackgroundColor}
          canvasRef={this.CanvasRef}
        />
        <MultiUseDialog Details={navigateToPageDialogDetails} />
        <MultiUseDialog Details={clearAllFieldsConfirmationDialogDetails} />
        <MultiUseDialog Details={createTemplateConfirmationDialogDetails} />
        <MultiUseDialog Details={renameTemplateConfirmationDialogDetails} />
        <MultiUseDialog Details={removeTemplateConfirmationDialogDetails} />
        <MultiUseDialog Details={updateTemplateConfirmationDialogDetails} />
        <MultiUseDialog Details={dragExampleDetails} />
        {progressIndicator}

        {/*header*/}
        <div className={classes.contentContainer}>
          {/* Tool pane */}
          <div className={classes.toolPane}
            onMouseDown={e => this.PreDragMousePosition = {x:e.clientX, y:e.clientY}}>
            {toolPaneContent}
          </div>

          <div className={classes.imageContainer}>
            {imageHeader}
            {/* Document Image Canvas */}
            <div 
              ref={instance => this.CanvasContainerRef = instance}
              className={classes.canvasContainer}>
              {documentImageCanvas}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SignatureFormDesigner.propTypes = {
  classes: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  documentId: PropTypes.string.isRequired,
  documentPageCount: PropTypes.number.isRequired,
}

export default DropTarget(['DraggableListItem','DraggableSignatureField'], canvasTarget, canvasDropCollect)(DropTarget('DraggableSignatureField', toolPaneTarget, toolPaneDropCollect)(withStyles(styles, {withTheme: true})(SignatureFormDesigner)));