import React, { Component } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from '../Util/api';
import MultiUseDialog from '../Components/MultiUseDialog';
import FormInput from '../Components/FormInput';
import ProgressIndicator from '../Components/ProgressIndicator';
import TitleComponent from '../Components/TitleComponent';

import {
  GetLightMode,
} from '../Util/Theme';

import { IsMobile } from '../Util/MobileDetector';

// NOTE: We have to override all items surrounding the form canvas for this page as it's forced to "light mode" in App.js.
// This is because we need a white background and matching form fields.
const styles = theme => ({
  root: {
    flexGrow: 1,
    // height: "calc(100% - 64px)",
    height:"100%",
  },
  appBarTitleContainer: {
    display: "flex",
    flexGrow: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    alignItems: "center",
  },
  content: {
    // backgroundColor: see render
    display:"flex",
    justifyContent:"center",
    paddingTop: 64,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56,
    },
    height: "100%",
  },
});

class Form extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      FormTemplate: null,
      CurrentPageIndex: 0,
      ShowProgressIndicatorImmediately: false,
      DialogDetails: {
        Open: false,
      },
    }
  }

  getFormTemplate = () => {
    this.setState({ShowProgressIndicatorImmediately: true});
    return API.get(`/formTemplatesPublic/${this.props.match.params.formTemplateID}`,
      {params:{uniqueId:this.props.match.params.uniqueID}})
      .then(resp => {
        const FormTemplate = resp.data;
        this.setState({
          FormTemplate,
          ShowProgressIndicatorImmediately: false,
        });
        if (FormTemplate.FormTemplateSourceID !== "" && IsMobile()) {
          this.showDialog({
            Title: "Productivity",
            BodyText: "Use a desktop browser for the best experience with a full-page view.",
          });
        }
        return resp.data;
      })
      .catch(this.handleApiError);
  }

  showDialog = details => {
    if (!details)
      return;
    this.setState({
      DialogDetails: {
        Open: (details.Closed) ? false : new Date(),
        IsConfirmation: details.IsConfirmation,
        RequireTextInput1: details.RequireTextInput1,
        FullWidth: details.FullWidth,
        Title: details.Title,
        BodyText: details.BodyText,
        BodyContent: details.BodyContent,
        BodyClassName: details.BodyClassName,
        TextInput1Label: details.TextInput1Label,
        TextInput1PlaceHolder: details.TextInput1PlaceHolder,
        TextInput1DefaultValue: details.TextInput1DefaultValue,
        CancelCallback: (details.CancelCallback) ? details.CancelCallback : () => {},
        CloseLabel: details.CloseLabel,
        CloseCallback: (details.CloseCallback) ? details.CloseCallback : () => {},
        DisableBlurClose: (details.DisableBlurClose) ? details.DisableBlurClose : false,
        ConfirmLabel: details.ConfirmLabel,
        ConfirmCallback: (details.ConfirmCallback) ? details.ConfirmCallback : () => {},
      }
    });
  }

  handleApiError = (err, title_optional) => {
    // console.log(err.response.status);
    // console.log(err.response.data.message);
    if (typeof err.response === "undefined") {
      err = {
        response: {
          status: 500,
          data: {
          message: err.toString(),
          },
        },
      };
    }

    switch(err.response.status) {
      case 403:
      case 500:
      // 500: Internal Server Error
      let dialogDetails = {
        Title: title_optional || "Sorry, something went wrong...",
        BodyText: (err.response.data.message) ? err.response.data.message : "Please try again.",
      }
      if (err.closeCallback !== undefined) {
        dialogDetails.CloseCallback = err.closeCallback;
      }
      this.showDialog(dialogDetails);
      break;
      default:
      break;
    }

    this.setState({ShowProgressIndicatorImmediately: false});
  }

  handleSetCurrentPageIndex = CurrentPageIndex => {
    this.setState({CurrentPageIndex});
  }

  componentDidMount() {
    this.getFormTemplate();
  }

  componentWillUnmount() {
  }
  
  render() {
  	const {
      DialogDetails,
      FormTemplate,
      CurrentPageIndex,
      ShowProgressIndicatorImmediately,
  	} = this.state;
    const {
      classes,
      theme,
      cookies,
      match,
      ...restProps
    } = this.props;

    let progressIndicator = null;
    if (ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    let content = (FormTemplate)
      ? (
        <FormInput
          {...restProps}
          FormTemplate={FormTemplate}
          FormShareID={match && match.params && match.params.formShareID}
          onSetCurrentPageIndex={this.handleSetCurrentPageIndex}
          onApiError={this.handleApiError}
          cookies={cookies}
        />
      ) : null;

    return (
      <div className={classes.root}>
        <TitleComponent title={FormTemplate && FormTemplate.Name} />
        <AppBar position="fixed"
          style={{
            backgroundColor: !GetLightMode(cookies)
              ? theme.palette.background.appBar_dark
              : theme.palette.background.appBar,
          }}
        >
          <Toolbar>
            <div className={classes.appBarTitleContainer}>
              <Typography variant="h6" color="inherit">
                {
                  (FormTemplate)
                    ? FormTemplate.Name + ((FormTemplate.PageCount > 1) ? ` (Page ${1 + CurrentPageIndex})` : "")
                    : "Loading form..."
                }
              </Typography>
            </div>
          </Toolbar>
        </AppBar>

        {progressIndicator}

        <MultiUseDialog Details={DialogDetails} />

        <div className={classes.content}
          style={{
            backgroundColor: !GetLightMode(cookies)
              ? theme.palette.background.default_dark
              : theme.palette.background.default,
          }}
        >
          {content}
        </div>

      </div>
    );
  }
}

export default withStyles(styles, {withTheme: true})(Form);