import React, { Component } from 'react';

import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';

import Card from './Card';

import FormSubmitIcon from '@material-ui/icons/PostAdd';
import FormDesignerIcon from '@material-ui/icons/Edit';
import FormShareIcon from '@material-ui/icons/Share';

import {
  GetPageCountForDisplay,
} from '../Util/Document';
import {
  GetDateValue,
} from '../Util/Properties';
import {
  HandleRouteToFormInput,
  HandleRouteToFormTemplateDesigner,
} from '../Util/FormTemplates';
import ActionType from '../Model/ActionType';

import { IsMobile } from '../Util/MobileDetector';

class FormTemplateCard extends Component {
  render() {
    const {
      FormTemplate,
      onCardAction,
      onAction,
      style,
      height,
      minHeight,
      noSelect,
      onSelect,
      selected,
      isSearchResults,
      showFormInputAction,
      showFormTemplateDesignerAction,
    } = this.props; 

    let metadata = [
      ["Project", FormTemplate.ProjectName],
      ["Created On", GetDateValue(FormTemplate.CreatedOn)],
      ["Pages", GetPageCountForDisplay(FormTemplate.PageCount)],
    ];

    const hoverActionItems = [
      <IconButton
        key="Form_Share"
        aria-label="Share"
        title="Share"
        onClick={() => onAction(ActionType.ShareForm, FormTemplate.ID)}
        >
        <FormShareIcon />
      </IconButton>
    ];
    if (showFormInputAction) {
      hoverActionItems.push(
        <IconButton
          key="Form_RouteToInput"
          aria-label="Submit New"
          title="Submit New"
          onClick={() => HandleRouteToFormInput(FormTemplate.ID, FormTemplate.UniqueID)}
          >
          <FormSubmitIcon />
        </IconButton>
      );
    }
    if (showFormTemplateDesignerAction && !IsMobile()) {
      hoverActionItems.push(
        <IconButton
          key="Form_RouteToDesigner"
          aria-label="Designer"
          title="Designer"
          onClick={() => HandleRouteToFormTemplateDesigner(this.props, FormTemplate)}
          >
          <FormDesignerIcon />
        </IconButton>
      );
    }

    return (
      <Card
        style={style}
        height={height}
        minHeight={minHeight}
        onCardAction={onCardAction}
        name={FormTemplate.Name}
        metadata={metadata}
        noSelect={noSelect}
        onSelect={onSelect}
        selected={selected}
        isSearchResults={isSearchResults}
        contentItem={FormTemplate}
        hoverActionItems={hoverActionItems}
      />
    );
  }
}

FormTemplateCard.propTypes = {
  FormTemplate: PropTypes.object.isRequired,
  onCardAction: PropTypes.func.isRequired,
  showFormInputAction: PropTypes.bool,
  showFormTemplateDesignerAction: PropTypes.bool,
  history: PropTypes.object.isRequired,
};

export default FormTemplateCard;