import React from 'react';
import { BrowserRouter, Route, Switch, Redirect, useLocation } from 'react-router-dom';

import TitleComponent from './Components/TitleComponent';
import Login from './Screens/Login';
import Logout from './Screens/Logout';
import NotFound404 from './Screens/NotFound404';
import PrivacyPolicy from './Screens/PrivacyPolicy';
import TermsOfService from './Screens/TermsOfService';
import Licenses from './Screens/Licenses';
import Root from './Screens/Root';
import Home from './Screens/Home';
import Dashboard from './Screens/Dashboard';
// import DeviceCapture from './Screens/DeviceCapture';
import Projects from './Screens/Projects';
import Workspace from './Screens/Workspace';
import ApprovalActionProcessor from './Screens/ApprovalActionProcessor';

// These redirects to be removed in a few months, replaced by OrganizationLink
import DocumentRedirect from './Screens/DocumentRedirect';
import TaskRedirect from './Screens/TaskRedirect';
import OrganizationHomeRedirect from './Screens/OrganizationHomeRedirect';
import OrganizationWorkspaceRedirect from './Screens/OrganizationWorkspaceRedirect';
import OrganizationProjectRedirect from './Screens/OrganizationProjectRedirect';

import OrganizationLink from './Screens/OrganizationLink';
// import Folders from './Screens/Folders';
// import FolderDocuments from './Screens/FolderDocuments';
import SearchResults from './Screens/SearchResults';
import SimpleSearchResults from './Screens/SimpleSearchResults';
import AdvancedSearch from './Screens/AdvancedSearch';
import Document from './Screens/Document';
// import MobileFolders from './Screens/MobileFolders';
import ExpiredSubscription from './Screens/ExpiredSubscription';
import EmailChangeConfirmation from './Screens/EmailChangeConfirmation';
import UserProfile from './Screens/UserProfile';
import UserApiKeys from './Screens/UserApiKeys';

import OrganizationManagement from './Admin/Screens/OrganizationManagement';
import ProcessDesigner from './Admin/Screens/Workflow/ProcessDesigner';
import FormTemplateDesigner from './Admin/Screens/FormTemplateDesigner';

import SignScreen from './Screens/Sign';
import FormScreen from './Screens/Form';
import PublicFolders from './Screens/PublicFolders';

// import Test from './Screens/test';

import {
  GetMuiTheme,
} from './Util/Theme';

import SupportUpcomingEvents from './Support/Screens/UpcomingEvents';
import SupportStats from './Support/Screens/Stats';
import SupportOrganizations from './Support/Screens/Organizations';
import SupportOrganizationsRequiringSubscription from './Support/Screens/OrganizationsRequiringSubscription';
import SupportOrganizationDetail from './Support/Screens/OrganizationDetail';
import SupportUsers from './Support/Screens/Users';
import SupportUserActivity from './Support/Screens/UserActivity';
import SupportErrorEvents from './Support/Screens/ErrorEvents';
import GlobalContextProvider from './Context/Global.context';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { withCookies } from 'react-cookie';
// import { DndProvider } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import './App.css';

const Authentication = {
  // rootRedirectRequested(cookies) {
  //   let c = cookies.get("rootRedirect");
  //   if (c !== undefined && c === "true") {
  //     // Remove cookie
  //     cookies.remove("rootRedirect");
  //     return true;
  //   }
  //   return false;
  // },
  isAuthenticated(cookies) {
    let c = cookies.get("session_v1");
    return c !== undefined;
  },
}

const withTitle = ({ component: Component, title }) => {
  return class Title extends React.Component {
    render() {
      return (
        <React.Fragment>
          <CssBaseline />
          <TitleComponent title={title} />
          <Component {...this.props} />
        </React.Fragment>
      );
    }
  };
};

const withTitleAndTheme = ({ component: Component, title, forceLightMode }) => {
  return class Title extends React.Component {
    render() {
      return (
        <MuiThemeProvider theme={GetMuiTheme(this.props.cookies, forceLightMode)}>
          <CssBaseline />
          <TitleComponent title={title} />
          <Component {...this.props} />
        </MuiThemeProvider>
      );
    }
  };
};

const withTheme = ({ component: Component, forceLightMode }) => {
  return class ThemedComponent extends React.Component {
    render() {
      return (
        <MuiThemeProvider theme={GetMuiTheme(this.props.cookies, forceLightMode)}>
          <CssBaseline />
          <Component {...this.props} />
        </MuiThemeProvider>
      );
    }
  };
};

const OrganizationManagementComponent = withTitleAndTheme({
  component: props => {
    return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
        <Elements>
          <OrganizationManagement {...props} />
        </Elements>
      </StripeProvider>  
    );
  },
  title: "Organization Management",
});

const ProjectsComponent = withTitleAndTheme({
  component: props => {
    return (
      <Projects {...props} />
    );
  },
  title: "Projects"
});

const ProjectTaskRedirectComponent = withTitleAndTheme({
  component: props => {
    return (
      <TaskRedirect {...props} />
    );
  },
  title: "Task"
});

const ProtectedRoute = withCookies(({ component: Component, render, cookies, ...rest }) => {
  return (<Route {...rest}
    render={
      (props) => {
        // if (Authentication.rootRedirectRequested(cookies) === true) {
        //   console.log("redirecting");
        //   return (
        //     <Redirect to={{
        //       pathname: '/',
        //       state: { from: props.location }
        //     }} />
        //   );
        // }
        // else {
          if (Authentication.isAuthenticated(cookies) === true) {
            // console.log("isAuthenticated");
            return (
              <GlobalContextProvider>
                {
                  (render)
                    ? render({...props, cookies})
                    : <Component cookies={cookies} {...props} />
                }
              </GlobalContextProvider>
            );
          } else {
            // console.log("login redirect");
            return (
              <Redirect to={{
                pathname: '/login',
                state: { 
                  from: props.location, 
                }
              }} />
            );
          }
        // }
      }
    } 
  />)
  }
);

const PublicRoute = withCookies(({ component: Component, cookies, ...rest }) =>
  <Route {...rest}
    render={
      (props) => {
        return (
          <Component cookies={cookies} {...props} />
        );
      }
    }
  />
);

function CustomEventRouterLocationChanged() {
  const location = useLocation();

  // Listen for router location change events
  React.useEffect(
    () => {
      window.dispatchEvent(
        new CustomEvent('routerLocationChanged', {
          detail: { pathname: location.pathname }
        }));
    },
    [location]
  );

  // This component renders no content
  return null;
}

class App extends React.Component {
  render() {
    if (process.env.REACT_APP_DEPLOYMENT_TYPE === "production") {
      // <!-- Google tag (gtag.js) -->
      const script1 = document.createElement("script");
      script1.id = "ga1";
      script1.async = true;
      script1.src = "https://www.googletagmanager.com/gtag/js?id=G-J8M4GFZ1BP";
      document.head.appendChild(script1);
      const script2 = document.createElement("script");
      script2.id = "ga2";
      const script2Body = document.createTextNode("window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-J8M4GFZ1BP'); gtag('config', 'AW-16550211969');");
      script2.appendChild(script2Body);
      document.head.appendChild(script2);

      // <!-- Matomo tag -->
      const script3 = document.createElement("script");
      script3.id = "matomo1";
      const script3Body = document.createTextNode(`var _mtm = window._mtm = window._mtm || []; _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'}); (function() { var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0]; g.async=true; g.src='https://cdn.matomo.cloud/nucleusone.matomo.cloud/container_cSg1UbVT.js'; s.parentNode.insertBefore(g,s); })();`);
      script3.appendChild(script3Body);
      document.head.appendChild(script3);
    }

    return (
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_SIGNIN_CLIENTID}>
        <DndProvider options={HTML5toTouch}>
          <BrowserRouter>
            <CustomEventRouterLocationChanged />
            <Switch>
              <ProtectedRoute exact path={"/"} component={withTitleAndTheme({ component: Root })} />
              <ProtectedRoute exact path={"/home"} component={withTitleAndTheme({ component: Home, title:"Home" })} />
              <ProtectedRoute exact path={"/dashboard"} component={withTitleAndTheme({ component: Dashboard, title: "Dashboard" })} />
              <ProtectedRoute path={"/user/profile"} component={withTitleAndTheme({ component: UserProfile, title: "User Profile" })} />
              <ProtectedRoute
                path={[
                  "/user/apiKeys/:collectionItemID",
                  "/user/apiKeys",
                ]}
                component={withTitleAndTheme({ component: UserApiKeys, title: "API Keys" })}
              />
              <ProtectedRoute path={"/user/emailChangeConfirmation/:confirmationID"} component={withTitleAndTheme({ component: EmailChangeConfirmation, title: "E-mail Address Change Confirmation" })} />
            
              {/* /projects paths array should be identical beyond the first element */}
              <ProtectedRoute
                path={[
                  "/projects/:projectID/settings/assets/management/:collectionID/:collectionItemID",
                  "/projects/:projectID/settings/assets/management/:collectionID",
                  "/projects/:projectID/settings/:collectionID/:collectionItemID",
                  "/projects/:projectID/settings/:collectionID",
                  "/projects/:projectID/tasks/management/:collectionID/:collectionItemID",
                  "/projects/:projectID/tasks/management/:collectionID",
                  "/projects/:projectID/assetItems_:assetID/management/:collectionID/:collectionItemID",
                  "/projects/:projectID/assetItems_:assetID/management/:collectionID",
                  "/projects/:projectID/:collectionID/documentFolders/:documentFolderID*",
                  "/projects/:projectID/:collectionID/:collectionItemID",
                  "/projects/:projectID/:collectionID",
                  "/projects/:projectID",
                  "/projects",

                  // Available for backward compatibility - Can be removed in the future
                  "/departments/:projectID/settings/:collectionID/:collectionItemID",
                  "/departments/:projectID/settings/:collectionID",
                  "/departments/:projectID/tasks/management/:collectionID/:collectionItemID",
                  "/departments/:projectID/tasks/management/:collectionID",
                  "/departments/:projectID/:collectionID/documentFolders/:documentFolderID*",
                  "/departments/:projectID/:collectionID/:collectionItemID",
                  "/departments/:projectID/:collectionID",
                  "/departments/:projectID",
                  "/departments",
                ]}
                component={ProjectsComponent}
              />
              
              <ProtectedRoute
                path={[
                  "/workspace/:collectionID/projects/:projectID/documentFolders/:documentFolderID*",
                  "/workspace/:collectionID/projects/:projectID",
                  "/workspace/:collectionID/projects",
                  "/workspace/:collectionID/:collectionItemID",
                  "/workspace/:collectionID",
                  "/workspace",
                ]}
                component={withTitleAndTheme({ component: Workspace, title: "My Work" })}
              />

              {/*<ProtectedRoute path={"/searchResults/:searchQuery_json_base64"} component={withTitleAndTheme({ component: SearchResults, title: "Search Results" })} />*/}
              <ProtectedRoute
                path={[
                  "/legacySearch/:searchQuery_json_base64",
                  "/legacySearch",
                ]} component={withTitleAndTheme({ component: SearchResults, title: "Search" })} />
              <ProtectedRoute
                path={"/search/:fullText"} component={withTitleAndTheme({ component: SimpleSearchResults, title: "Search results" })} />
              <ProtectedRoute path={"/search"} component={withTitleAndTheme({ component: AdvancedSearch, title: "Search" })} />
              
              <ProtectedRoute path={"/organizations/:organizationID/projects/:projectID/formTemplates/:formTemplateID"} component={withTitleAndTheme({ component: FormTemplateDesigner, title: "Form Template Designer" })} />
              <ProtectedRoute path={"/organizations/:organizationID/projects/:projectID/processes/:processID"} component={withTitleAndTheme({ component: ProcessDesigner, title: "Process Designer" })} />
              <ProtectedRoute path={"/organizations/:organizationID/projects/:projectID/documents/:documentID"} component={withTitleAndTheme({ component: Document, title: "Document" })} />

              <ProtectedRoute path={"/expiredSubscriptions/:organizationID"} component={withTitleAndTheme({ component: ExpiredSubscription, title: "Subscription Expired" })} />            

              <ProtectedRoute path={[
                "/organizations/:organizationID/projects/:projectID/approvalActions/:actionType/:approvalID",
                "/organizations/:organizationID/projects/:projectID/approvalActions/:actionType"
                ]}
                component={withTitleAndTheme({ component: ApprovalActionProcessor, title: "Approvals" })}
              />
              
              <ProtectedRoute path={"/organizations/:organizationID/link/:link*"}
                component={withTitleAndTheme({ component: OrganizationLink, title: "Organization Link" })}
              />

              {/*This section to be removed in a few months, replaced by OrganizationLink above*/}
              <ProtectedRoute path={"/organizationRedirect/:organizationID/home"} component={withTitleAndTheme({ component: OrganizationHomeRedirect, title: "Organization Access" })} />
              <ProtectedRoute path={"/organizationRedirect/:organizationID/workspace"} component={withTitleAndTheme({ component: OrganizationWorkspaceRedirect, title: "Organization Access" })} />
              <ProtectedRoute path={[
                "/organizationRedirect/:organizationID/projects/:projectID",
                // Available for backward compatibility - Can be removed in the future
                "/organizationRedirect/:organizationID/departments/:projectID" 
                ]}
                component={withTitleAndTheme({ component: OrganizationProjectRedirect, title: "Project Access" })} />
              <ProtectedRoute path={"/organizations/:organizationID/projects/:projectID/documentRedirect/:documentID"} component={withTitleAndTheme({ component: DocumentRedirect, title: "Document" })} />
              <ProtectedRoute path={"/organizations/:organizationID/taskRedirect/:taskID"}
                component={withTitleAndTheme({ component: TaskRedirect, title: "Task" })}
              />
              <ProtectedRoute path={[
                "/organizations/:organizationID/projects/:projectID/taskRedirect/:taskID",
                // Available for backward compatibility - Can be removed in the future
                "/organizations/:organizationID/departments/:projectID/taskRedirect/:taskID"
                ]}
                component={ProjectTaskRedirectComponent}
              />
              
              <ProtectedRoute
                path={[
                  "/organizationManagement/:organizationID/:collectionID/:collectionItemID",
                  "/organizationManagement/:organizationID/:collectionID",
                  "/organizationManagement/:organizationID",
                  "/organizationManagement"
                ]}
                component={OrganizationManagementComponent}
              />

              <ProtectedRoute path={"/support/upcomingEvents"} component={withTitleAndTheme({ component: SupportUpcomingEvents, title: "Upcoming Events" })} />
              <ProtectedRoute path={"/support/stats"} component={withTitleAndTheme({ component: SupportStats, title: "Stats" })} />
              <ProtectedRoute path={"/support/indexedOrganizations"}
                component={
                  withTitleAndTheme({
                    component: props => {
                      return (
                        <SupportOrganizations {...props} indexedOnly />
                      );
                    },
                    title: "Organizations",
                  })
                }
              />
              <ProtectedRoute path={"/support/organizations/:organizationID"} component={withTitleAndTheme({ component: SupportOrganizationDetail, title: "Organization Detail" })} />
              <ProtectedRoute path={"/support/organizations"} component={withTitleAndTheme({ component: SupportOrganizations, title: "Organizations" })} />
              <ProtectedRoute path={"/support/organizationsRequiringSubscription"} component={withTitleAndTheme({ component: SupportOrganizationsRequiringSubscription, title: "Organizations requiring a subscription" })} />
              <ProtectedRoute path={"/support/users"} component={withTitleAndTheme({ component: SupportUsers, title: "Users" })} />              
              <ProtectedRoute path={"/support/userActivity"} component={withTitleAndTheme({ component: SupportUserActivity, title: "User Activity" })} />
              <ProtectedRoute path={"/support/errorEvents"} component={withTitleAndTheme({ component: SupportErrorEvents, title: "Error Events" })} />
              
              <PublicRoute exact path={"/login/linkedin"} component={LinkedInCallback} />
              <PublicRoute exact path={"/login/:uniqueID/:OTP"} component={withTitleAndTheme({ component: Login, title: "Sign in", forceLightMode: true })} />
              <PublicRoute exact path={"/login"} component={withTitleAndTheme({ component: Login, title: "Sign in", forceLightMode: true })} />
              <PublicRoute path={"/logout"} component={Logout} />

              <PublicRoute path={"/sign/:sessionID/:recipientID/:uniqueID"} component={withTheme({ component: SignScreen })} />
              <PublicRoute path={[
                "/form/:formTemplateID/:uniqueID/:formShareID",
                "/form/:formTemplateID/:uniqueID",
                ]} component={withTitleAndTheme({ component: FormScreen, title: "Form", forceLightMode: true })} />
              <PublicRoute path={[
                "/public/folders/:organizationID/:projectID/documentFolders/:documentFolderID*/documents/:documentID",
                "/public/folders/:organizationID/:projectID/documentFolders/:documentFolderID*",
                "/public/folders/:organizationID/:projectID/documents/:documentID",
                "/public/folders/:organizationID/:projectID",
                ]} component={withTitle({ component: PublicFolders, title: "Folders" })} />
              <PublicRoute path={"/public/documents/:organizationID/:projectID/:documentID"} component={
                withTitleAndTheme({ 
                  component: props => {
                    return (
                      <Document {...props} usePublicApi />
                    );
                  },
                  title: "Document" })
              } />

              {/*<PublicRoute path={"/test"} component={withTheme({ component: Test })} />*/}

              <PublicRoute path={"/privacy"} component={withTheme({ component: PrivacyPolicy })} />
              <PublicRoute path={"/terms"} component={withTheme({ component: TermsOfService })} />
              <PublicRoute path={"/licenses"} component={withTheme({ component: Licenses })} />

              
              {/*<ProtectedRoute path={"/twainCapture"} component={withTitleAndTheme({ component: DeviceCapture, title: "TWAIN Capture" })} />*/}
              {/*<ProtectedRoute path={"/foldersMobile"} component={withTitleAndTheme({ component: MobileFolders, title: "Document Folders" })} />*/}
              {/*<ProtectedRoute path={"/folderDocuments/:searchQuery_json_base64"} component={withTitleAndTheme({ component: FolderDocuments, title: "Folder Documents" })} />*/}
              {/*<ProtectedRoute path={"/folders"} component={withTitleAndTheme({ component: Folders, title: "Document Folders" })} />*/}
              

              <PublicRoute component={withTheme({ component: NotFound404 })} />
            </Switch>
          </BrowserRouter>
        </DndProvider>
      </GoogleOAuthProvider>
    );
  }
}

export default App;