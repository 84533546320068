export const FormTemplateFieldTypes = [
	{
		Type: "Label",
		TypeLabel: "Label",
		Category: "Static",
		Field: {},
		HideFromUI: true,
	},
	{
		Type: "Text",
		TypeLabel: "Label",
		Category: "Static",
		Field: {},
	},
	{
		Type: "Image",
		TypeLabel: "Image",
		Category: "Static",
		Field: {},
	},
	// {
	// 	Type: "Line",
	// 	TypeLabel: "Line",
	// 	Category: "Static",
	// 	Field: {},
	// },
	{
		Type: "TextBox",
		TypeLabel: "Text Box",
		Category: "Input",
		Field: {
			Type: "FieldType_Text",
		},
	},
	{
		Type: "ListBox",
		TypeLabel: "List Box",
		Category: "Input",
		Field: {
			Type: "FieldType_Text",
			DisplaySelectionList: true,
		},
	},
	{
		Type: "CheckBox",
		TypeLabel: "Check Box",
		Category: "Input",
		Field: {
			Type: "FieldType_Bool"
		},
	},
	{
		Type: "RadioGroup",
		TypeLabel: "Radio Group",
		Category: "Input",
		Field: {},
		PossibleValues: ["Radio Item 1"], // So something shows initially besides the drag indicator
	},
	{
		Type: "Signature",
		TypeLabel: "Sign & Date",
		Category: "Input",
		Field: {},
	},
	{
		Type: "Upload",
		TypeLabel: "Upload",
		Category: "Input",
		Field: {},
	},
	{
		Type: "Field",
		TypeLabel: "Project Field",
		Category: "Input",
		Field: {},
	},
];

export function GetExtendedFormTemplateField(field) {
	let matchingType = FormTemplateFieldTypes.filter(t => t.Type === field.Type);
	if (matchingType.length) {
	  return {...matchingType[0], ...field};
	}
	return null;
}

export function GetExtendedFormTemplateFields(formFields) {
	let fields = [];
	formFields.forEach(field => {
	    let extendedField = GetExtendedFormTemplateField(field);
	    if (extendedField) {
	    	fields = fields.concat(extendedField);
	    }
	});
	return fields;
}