import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import UiCore from '../../Components/UiCore';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import API, {
  GetSupportUpcomingEventsPathForApi,
  GetSupportUpcomingEventsPublicPathForApi,
} from '../../Util/api';

import dateformat from 'dateformat';

const styles = theme => ({
  root:{
    padding: theme.spacing(3),
    overflow: "auto",
  },
});

class UpcomingEvents extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      SupportUpcomingEvents: [],
      ShowProgressIndicatorImmediately: false,
    }
  }

  getEventDateString = eventDate => {
    let eventDateString = "";
    if (eventDate) {
      let eventDateYear = dateformat(eventDate, "yyyy");
      if (eventDateYear.length < 4) {
        eventDateYear = eventDateYear.padStart(4, "0");
      }
      eventDateString = eventDateYear + dateformat(eventDate, "-mm-dd");
    }
    return eventDateString;
  }

  loadUpcomingEvents = () => {
    this.setState({ShowProgressIndicatorImmediately:true});

    API.get(GetSupportUpcomingEventsPublicPathForApi())
      .then(resp => {
        let SupportUpcomingEvents = resp.data.SupportUpcomingEvents;
        SupportUpcomingEvents.forEach(sue => {
          sue.EventDateString = this.getEventDateString(sue.EventDate);
        })
        this.setState({SupportUpcomingEvents});
      })
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ShowProgressIndicatorImmediately:false});
      });
  }

  handleAddEvent = () => {
    const SupportUpcomingEvents = [...this.state.SupportUpcomingEvents].concat({
      EventDate: null,
      EventDateString: "",
      Title: "",
      URL: "",
    });
    this.setState({SupportUpcomingEvents});
  }

  handleRemoveEvent = index => {
    const SupportUpcomingEvents = [...this.state.SupportUpcomingEvents]
      .filter((sue, i) => i !== index);
    this.setState({SupportUpcomingEvents});
  }

  handleSaveEvents = () => {
    this.setState({ShowProgressIndicatorImmediately:true});

    API.post(GetSupportUpcomingEventsPathForApi(), this.state.SupportUpcomingEvents)
      .catch(this.handleApiError)
      .finally(() => {
        this.setState({ShowProgressIndicatorImmediately:false});
      });
  }

  handleEventDateChange = index => eventOrValue => {
    let SupportUpcomingEvents = [...this.state.SupportUpcomingEvents];
    if (eventOrValue.target) {
      const eventDate = new Date(`${eventOrValue.target.value}T12:00:00`);
      SupportUpcomingEvents[index]["EventDate"] = eventDate;
      SupportUpcomingEvents[index]["EventDateString"] = this.getEventDateString(eventDate);
    } else if (typeof eventOrValue === "string" && eventOrValue === "") {
      SupportUpcomingEvents[index]["EventDate"] = null;
    }
    this.setState({SupportUpcomingEvents});
  }

  handleStringChange = (index, propName) => eventOrValue => {
    let SupportUpcomingEvents = [...this.state.SupportUpcomingEvents];
    if (eventOrValue.target) {
      SupportUpcomingEvents[index][propName] = eventOrValue.target.value;
    } else if (typeof eventOrValue === "string") {
      SupportUpcomingEvents[index][propName] = eventOrValue;
    }
    this.setState({SupportUpcomingEvents});
  }

  handleApiError = err => {
    this.setState({
      ApiError: err,
      ShowProgressIndicatorImmediately: false,
    });
  }

  componentDidMount() {
    this.loadUpcomingEvents();
  }

  render() {
    const {
      SupportUpcomingEvents,
      ApiError,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const {
      classes,
      // theme,
    } = this.props;

    const eventGridItems = SupportUpcomingEvents.map((sue, index) => {
      return (
        <Grid item key={`sue_${index}`}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={2}>
              <div style={{display:"flex"}}>
                <div>
                  <Tooltip title="Remove event">
                    <IconButton onClick={() => this.handleRemoveEvent(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                <div style={{flexGrow:1,marginLeft:4}}>
                  <TextField
                    variant="outlined"
                    label="Event Date"
                    value={sue.EventDateString}
                    onChange={this.handleEventDateChange(index)}
                    fullWidth
                    type="date"
                    InputLabelProps={{ shrink: true, }}
                    inputProps={{max:"9999-12-31"}}
                  />
                </div>
              </div>
            </Grid>
            {/*<Grid item xs={12} sm={6} lg={3}>
              <TextField
                variant="outlined"
                label="Event Time (optional)"
                value={sue.EventTimeString}
                onChange={this.handleTimeChange("EventTime")}
                fullWidth
                type="time"
                InputLabelProps={{ shrink: true, }}
              />
            </Grid>*/}
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                label="Title"
                variant="outlined"
                value={sue.Title}
                onChange={this.handleStringChange(index, "Title")}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                label="URL (optional)"
                variant="outlined"
                value={sue.URL}
                onChange={this.handleStringChange(index, "URL")}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      );
    });

    const grid = (
      <Grid container spacing={2} direction="column">
        {eventGridItems}
        <Grid item>
          <Tooltip title="Add event">
            <IconButton onClick={() => this.handleAddEvent()}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Button onClick={() => this.handleSaveEvents()}
            variant="contained"
          >
            SAVE
          </Button>
        </Grid>
        <Grid item>
          Changes are temporary until SAVE is clicked.
        </Grid>
      </Grid>
    );

    const content = (
      <div className={classes.root}>
        {grid}
      </div>
    );

    return (
      <UiCore
        title="Support: Upcoming Events"
        content={content}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
        apiError={ApiError}
      />
    );
  }
}

UpcomingEvents.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, {withTheme: true})(UpcomingEvents);
