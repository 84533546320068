import API, {
  GetAssetItemsPathForApi,
} from './api';
import {
  GetProjectAssetItemPath,
  GetProjectAssetItemArchivedPath,
} from './PathHelper';

export const HandleUpdateAssetItems = (organizationId, projectId, assetId, assetItems, onApiError) => {
  return API.put(GetAssetItemsPathForApi(organizationId, projectId, assetId), assetItems)
    .then(resp => {})
    .catch(onApiError);
}

export const HandleRouteToAssetItem = (props, projectId, assetId, assetItemId, useArchivedPath, openInNewTab) => {
  let url;
  if (useArchivedPath) {
    url = GetProjectAssetItemArchivedPath(projectId, assetId, assetItemId);
  } else {
    url = GetProjectAssetItemPath(projectId, assetId, assetItemId);
  }
  if (openInNewTab) {
    window.open(url, assetItemId);
  } else {
    props.history.push(url, { ...props.location.state });
  }
}