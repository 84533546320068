import React, { Component } from 'react';

import UiCore from '../Components/UiCore';
import { GlobalContext } from '../Context/Global.context';

class OrganizationLink extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      ShowProgressIndicatorImmediately:true,
    }
  }

  handleApiError = err => {
    err.closeCallback = this.tryCloseWindowOrRedirectToHome;
    this.setState({ ApiError: err, ShowProgressIndicatorImmediately: false });
  }

  tryCloseWindowOrRedirectToHome = () => {
    this.setState({ShowProgressIndicatorImmediately: false});
    window.close();
    this.props.history.replace("/home");
  }

  componentDidMount() {
    const organizationID = this.props.match.params.organizationID;
    if (!organizationID) {
      this.handleApiError("Invalid or missing organization");
    }
    // Switch to the project provided
    this.context.SaveUserPreferences_ActiveOrganization(organizationID)
      .then(() => {
        this.context.Reset();
        const link = this.props.match.params.link;
        if (!link) {
          this.tryCloseWindowOrRedirectToHome();
        }
        this.props.history.replace(`/${link}`);
      })
      .catch(this.handleApiError);
  }

  render() {
    const {
      Alert,
      ShowProgressIndicatorImmediately,
    } = this.state;

    return (
      <UiCore title="Organization Link"
        hideNavDrawer
        alert={Alert}
        showProgressIndicatorImmediately={ShowProgressIndicatorImmediately}
      />
    );
  }
}

export default OrganizationLink;
