import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Card from './Card';

import {
  GetDateValue,
  GetUserValue,
} from '../Util/Properties';

class TaskStateCard extends Component {
  render() {
    const {
      TaskState,
      onCardAction,
      style,
      noSelect,
      onSelect,
      selected,
      isSearchResults
    } = this.props; 

    let metadata = [
      ["Project", TaskState.ProjectName],
      ["Created On", GetDateValue(TaskState.CreatedOn)],
      ["Created By", GetUserValue(TaskState.CreatedByUserEmail)],
      ["Position", 1 + TaskState.Index],
    ];

    return (
      <Card
        style={style}
        noSelect={noSelect}
        onSelect={onSelect}
        selected={selected}
        onCardAction={onCardAction}
        name={TaskState.Name}
        isSearchResults={isSearchResults}
        metadata={metadata}
        contentItem={TaskState}
      />
    );
  }
}

TaskStateCard.propTypes = {
  TaskState: PropTypes.object.isRequired,
  onCardAction: PropTypes.func.isRequired,
};

export default TaskStateCard;