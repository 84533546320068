import React, { Component } from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import DashboardCard from '../DashboardCard';
import ProgressIndicator from '../ProgressIndicator';

import AddIcon from '@material-ui/icons/Add';
import {
  ProjectsIcon,
} from '../../Util/Icons';
import blue from '@material-ui/core/colors/blue';

import {
  GetProjectPath,
  GetProjectMembersPath,
  GetProjectTasksPath,
} from '../../Util/PathHelper';
import ProjectCreationDialog from '../../Admin/Components/ProjectCreationDialog';
import { IsMobile } from '../../Util/MobileDetector';
import { GlobalContext } from '../../Context/Global.context';

const styles = theme => ({
});

class ProjectsWidget extends Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    
    this.state = {
      ShowCreateProjectDialog: false,
      ShowProgressIndicatorImmediately: false,
    }
  }

  handleNavigateToProject = id => {
    this.props.history.push(GetProjectPath(id));
  }

  waitForContext = () => {
    if (this.context.CompletedGET.ProjectMembershipPackages) {
      this.setState({ShowProgressIndicatorImmediately: false});
    } else {
      setTimeout(() => this.waitForContext(), 250);
    }
  }

  handleSetShowCreateProjectDialog = ShowCreateProjectDialog => {
    this.setState({ShowCreateProjectDialog});
  }

  handleProjectCreated = project => {
    this.handleSetShowCreateProjectDialog(false);
    this.context.Reset();
    if (IsMobile()) {
      this.props.history.push(GetProjectTasksPath(project.ID));
    } else {
      this.props.history.push(GetProjectMembersPath(project.ID));
    }
  }

  componentDidMount() {
    if (!this.context.CompletedGET.ProjectMembershipPackages) {
      this.setState({ShowProgressIndicatorImmediately: true});
      this.waitForContext();
    }
  }

  render() {
    const {
      ShowCreateProjectDialog,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const { 
      // classes,
      theme,
      organizationId,
      DashboardWidget,
      ...passThroughProps
    } = this.props;
    const {
      CompletedGET,
      ProjectMembershipPackages,
    } = this.context;

    let projectsList;
    let projectListItems = [];
    let noProjectsMessage;
    if (ProjectMembershipPackages && ProjectMembershipPackages.length > 0) {
      for (let i = 0; i < ProjectMembershipPackages.length; i++) {
        const pmp = ProjectMembershipPackages[i];
        projectListItems.push(
          <ListItem button key={`p_${i}`}
            onClick={() => this.handleNavigateToProject(pmp.Project.ID)}>
            <ListItemText>
              {pmp.Project.Name}
            </ListItemText>
          </ListItem>
        );
      }
      if (projectListItems.length) {
        projectsList = (
          <List dense style={{padding:0,height:"100%",overflowY:"auto"}}>
            {projectListItems}
          </List>
        );
      }
    }
    if (!projectListItems.length) {
      if (CompletedGET.ProjectMembershipPackages) {
        noProjectsMessage = (
          <Typography variant="body1" style={{
            display:"flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            textAlign:"center",
          }}>
            You don't have any project memberships.
          </Typography>
        );
      }
    }

    const progressIndicator = (ShowProgressIndicatorImmediately)
      ? (
        <ProgressIndicator showImmediately constrained noBackground />
      )
      : null;

    const projectCreationDialog = (
      <ProjectCreationDialog
        organizationId={organizationId}
        open={ShowCreateProjectDialog}
        onCreated={this.handleProjectCreated}
        onClose={() => this.handleSetShowCreateProjectDialog(false)}
        onApiError={passThroughProps.onApiError}
      />
    );

    const titleButtons = [
      (
        <Tooltip key="addProject" title="Create a project">
          <IconButton
            size="small"
            onClick={() => this.handleSetShowCreateProjectDialog(true)}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      ),
    ];

    return (
      <DashboardCard
        title="Projects"
        titleIcon={(<ProjectsIcon style={{color: blue[500]}} />)}
        titleButtons={titleButtons}
        noFadeout
        xs={12}
        sm={6}
        md={3}
        lg={2}
        xl={2}
        // onCardAction={() => this.navigateToEvent()}
        {...passThroughProps}
        DashboardWidget={DashboardWidget}
      >
        {progressIndicator}
        {projectCreationDialog}
        {projectsList}
        {noProjectsMessage}
      </DashboardCard>  
    );
  }
}

ProjectsWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
};

export default withStyles(styles, {withTheme: true})(ProjectsWidget);