import API from './api';
import debounce from 'es6-promise-debounce';
import {
  GetAssetItemPathForApi,
} from '../Util/api';
import { RecurseDependentSelectionListsDown } from './Fields';
import {
  GetComposedFieldListLabelsAndValues,
  GetEffectiveSelectionListIsDependent,
  GetUpdatedFieldObjectForValueChange
} from './Field';

export const GetAssetItem = (organizationId, projectId, assetId, assetItemId) => {
  return API.get(GetAssetItemPathForApi(organizationId, projectId, assetId, assetItemId))
    .then(resp => {
      let assetItem = resp.data;
      return assetItem;
    });
}

export const UpdateAssetItem = debounce((organizationId, projectId, assetId, assetItem) => {
  return API.put(GetAssetItemPathForApi(organizationId, projectId, assetId, assetItem.ID), assetItem);
}, 500);

export const ClearAndReloadDependentFieldValues = (field, fields, isForForms, postFieldValueClearedAction) => {
  if (!document || !field) {
    return;
  }

  const getEffectiveField = f => isForForms ? f.Field : f;

  RecurseDependentSelectionListsDown(field, fields,
    f => {
      return (f.FieldID !== undefined) ? f.FieldID : f.ID;
    },
    f => getEffectiveField(f).ParentFieldID,
    f => {
      const fLocal = getEffectiveField(f);
      
      if (!GetEffectiveSelectionListIsDependent(fLocal)) {
        return;
      }
      // Clear the field's value
      GetUpdatedFieldObjectForValueChange(fLocal, undefined, []);
      fLocal.ListValues = GetComposedFieldListLabelsAndValues(fLocal);
      // this.onChangeHandlers.setStringProperty(`${prefix}${f.ID}`, true, fLocal.Values); 
      
      if (postFieldValueClearedAction) {
        postFieldValueClearedAction(fLocal);
      }

      // Cause the selection list to be reloaded
      fLocal.UpdateId = new Date();
    },
  );
};

export const GetAssetItemProgress = assetItem => {
  if (assetItem && assetItem.ItemsCompleted + assetItem.ItemsRemaining > 0) {
    return `${assetItem.ItemsCompleted} of ${assetItem.ItemsCompleted + assetItem.ItemsRemaining} items complete`;
  }
  return "";
}