import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Card from './Card';

import {
  GetDateValue,
} from '../Util/Properties';

class DocumentFolderCard extends Component {
  render() {
    const {
      DocumentFolder,
      onCardAction,
      style,
      height,
      isSearchResults,
    } = this.props;

    let metadata = [
      ["Project", DocumentFolder.ProjectName],
      ["Shared with", DocumentFolder.AssignmentUserEmails && DocumentFolder.AssignmentUserEmails.join(", ")],
      ["Created On", GetDateValue(DocumentFolder.CreatedOn)],
    ];

    const hoverActionItems = [];

    return (
      <Card
        style={style}
        height={height}
        onCardAction={onCardAction}
        name={DocumentFolder.Name}
        nameStyle={{color:DocumentFolder.HexColor || DocumentFolder.DocumentFolderHexColor}}
        metadata={metadata}
        noSelect
        isSearchResults={isSearchResults}
        contentItem={DocumentFolder}
        hoverActionItems={hoverActionItems}
      />
    );
  }
}

DocumentFolderCard.propTypes = {
  DocumentFolder: PropTypes.object.isRequired,
  onCardAction: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default DocumentFolderCard;