import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IsMobile } from '../Util/MobileDetector';

const chartStyles = theme => ({
  tooltip: {
    zIndex:2,
    position:"fixed",
    borderRadius:4,
    backgroundColor:theme.palette.background.pane,
    minHeight:48,
    minWidth:96,
    padding:theme.spacing(2),
    display:(IsMobile()) ? "none" : "flex",
    justifyContent:"center",
    alignItems:"center",
  },
})

class ChartTooltip extends Component {
  render() {
    const {
      isVisible,
      mousePosition,
      classes,
      children,
    } = this.props;
    if (!isVisible) {
      return null;
    }

    if (mousePosition.x <= 100 || mousePosition.y <= 100) {
      return null;
    }

    return (
      <div
        className={classes.tooltip}
        style={{
          left:12 + mousePosition.x,
          top:12 + mousePosition.y,
        }}
      >
        {children}
      </div>
    );
  }
}

ChartTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  mousePosition: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default withStyles(chartStyles, {withTheme: true})(ChartTooltip);