import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
// import Typography from '@material-ui/core/Typography';

import AsyncSelectControl from '../../../Components/AsyncSelectControl';
// import SelectControl from '../../../Components/SelectControl'

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ProgressIndicator from '../../../Components/ProgressIndicator';
import {
  HandleGetAssets,
} from '../../../Util/Assets';
import API, {
  GetProcessElementPathForApi,
} from '../../../Util/api';
import {
  GetTagsControl,
  // PostTagsFromListValues,
} from '../../../Util/Tags';

import debounce from 'es6-promise-debounce';

const styles = theme => ({
  dialogPaper: {
    height:"70%",
  },
  dialogContent: {
    overflow:"hidden",
  },
  contentContainer: {
    paddingTop:theme.spacing(1),
    height:"100%",
    overflow:"hidden",
    display:"flex",
    flexDirection:"column",
  },
  innerDialogContent: {
    flexGrow:1,
    height:"100%",
    overflow:"hidden",
    overflowY:"auto",
    display:"flex",
    flexDirection:"column",
    paddingTop:theme.spacing(2),
  },
  tabContent: {
    flexGrow:1,
    paddingTop:theme.spacing(2),
    overflowX:"hidden",
    overflowY:"auto",
    // // This fixes an issue with the scroll bar appearing when it probably shouldn't
    // paddingBottom: theme.spacing(1),
    // // The following two items are an attempt to provdie some cushion for controls (switch, checkbox) that have a larger width on hover
    // paddingLeft: theme.spacing(2),
    // marginLeft: -theme.spacing(2),
  },
  tabs: {
  },
  tab: {
  },
});

const local_SingleListValueKeyPrefix = "SingleListValue_";
const local_SingleListValueAndLabelKeyPrefix = "SingleListValueAndLabel_";
const local_MultiListValuesKeyPrefix = "MultiListValues_";
const local_MultiListValuesAndLabelsKeyPrefix = "MultiListValuesAndLabels_";

const output_SingleSelectValueKeyPrefix = "SingleSelectValue_";
const output_SingleSelectValueAndLabelKeyPrefix = "SingleSelectValueAndLabel_";
const output_MultiSelectValuesKeyPrefix = "MultiSelectValues_";
const output_MultiSelectValuesAndLabelsKeyPrefix = "MultiSelectValuesAndLabels_";

class ProcessElementDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ProcessElement: null,
      SelectedTab: "elementSpecificContent",
      ShowProgressIndicator: false,
      ShowProgressIndicatorImmediately: false,
    }

    this.CloseDialogFunc = null;
    // this.DialogHelper = null;
  }

  handleGetSettingsState = id => {
    if (id) {
      return this.state[id];
    } else {
      return this.state;
    }
  }

  handleSetSettingsState = state => {
    this.setState(state);
  }

  handleGetLocalProperty = id => {
    return this[id];
  }

  handleSetLocalProperty = (id, value) => {
    this[id] = value;
  }

  handleLoadProcessElement = () => {
    this.setState({ShowProgressIndicator:true});
    API.get(GetProcessElementPathForApi(this.props.organizationId, this.props.projectId, 
      this.props.processId, this.props.processElementId))
      .then(resp => {
        const ProcessElement = resp.data;
        ProcessElement.Data = (ProcessElement.JsonData) ? JSON.parse(ProcessElement.JsonData) : {};

        this.populateLocalListValues(ProcessElement);
        this.setState({ProcessElement, ShowProgressIndicator:false});
        // this.setDialogHelper(ProcessElement);
      })
      .catch(this.handleApiError);
  }

  // setDialogHelper = processElement => {
  //   this.DialogHelper = new ProcessElementDialogHelper(
  //     this.props.location, this.props.history,
  //     this.props.organizationId, this.props.projectId,
  //     processElement,
  //     this.props.processElementConnections_Input,
  //     this.handleGetSettingsState, this.handleSetSettingsState,
  //     this.handleGetLocalProperty, this.handleSetLocalProperty,
  //     this.handleApiError,
  //     this.props.onAlert,
  //     this.handleGetLocalListValuesAndLabelsPropertyName, this.getOnChangeHandlers(),
  //     this.handleSelectionListValueChange,
  //     this.handleSelectionListCreateOption,
  //     this.handleSetCloseDialogFunc,
  //   );
  // }

  populateLocalListValues = processElement => {
    // For list-value properties, convert selection value(s) [and value(s)+label(s)]...
    Object.keys(processElement.Data).forEach(k => {
      // ...to SingleListValue_ object
      if (k.startsWith(output_SingleSelectValueKeyPrefix)) {
        let key = k.substr(output_SingleSelectValueKeyPrefix.length);
        if (processElement.Data[k]) {
          processElement[this.handleGetLocalListValuesPropertyName(key, false)] = {value:processElement.Data[k]};
          // Populate ValuesAndLabel (if not exists) for prior versions that only have a value stored
          if (!processElement[this.handleGetLocalListValuesAndLabelsPropertyName(key, false)]) {
            processElement[this.handleGetLocalListValuesAndLabelsPropertyName(key, false)] = {
              label:processElement.Data[k],
              value:processElement.Data[k],
            };
          }  
        }
      } 
      // ...to SingleListValueAndLabel_ object
      if (k.startsWith(output_SingleSelectValueAndLabelKeyPrefix)) {
        let key = k.substr(output_SingleSelectValueAndLabelKeyPrefix.length);
        if (processElement.Data[k] && processElement.Data[k].value) {
          processElement[this.handleGetLocalListValuesAndLabelsPropertyName(key, false)] = processElement.Data[k];
        }
      }
      // ...to MultiListValues_ object array
      if (k.startsWith(output_MultiSelectValuesKeyPrefix)) {
        let key = k.substr(output_MultiSelectValuesKeyPrefix.length);
        processElement[this.handleGetLocalListValuesPropertyName(key, true)] = (processElement.Data[k] && processElement.Data[k]
          .map(v => ({value:v,label:v})));
        // Populate ValuesAndLabel (if not exists) for prior versions that only have a value stored
        if (!processElement[this.handleGetLocalListValuesAndLabelsPropertyName(key, true)]) {
          processElement[this.handleGetLocalListValuesAndLabelsPropertyName(key, true)] = (processElement.Data[k] 
            && processElement.Data[k]
              .map(v => ({value:v,label:v})));
        }
      }
      // ...to MultiListValues_ object array
      if (k.startsWith(output_MultiSelectValuesAndLabelsKeyPrefix)) {
        let key = k.substr(output_MultiSelectValuesAndLabelsKeyPrefix.length);
        processElement[this.handleGetLocalListValuesAndLabelsPropertyName(key, true)] = processElement.Data[k];
      }
    });
  }

  handleUpdateProcessElement = () => {
    this.setState({ShowProgressIndicator:true});
    let processElement = {...this.state.ProcessElement};

    // For list-value properties, convert objects...
    Object.keys(processElement).forEach(k => {
      //...to SingleSelectValue_ string
      if (k.startsWith(local_SingleListValueKeyPrefix)) {
        let key = k.substr(local_SingleListValueKeyPrefix.length);
        if (processElement[k]) {
          processElement.Data[output_SingleSelectValueKeyPrefix+key] = processElement[k].value;
        } else if (processElement.Data[output_SingleSelectValueKeyPrefix+key] !== undefined) {
          processElement.Data[output_SingleSelectValueKeyPrefix+key] = undefined;
        }
      }
      if (k.startsWith(local_SingleListValueAndLabelKeyPrefix)) {
        let key = k.substr(local_SingleListValueAndLabelKeyPrefix.length);
        if (processElement[k]) {
          processElement.Data[output_SingleSelectValueAndLabelKeyPrefix+key] = processElement[k];
        } else if (processElement.Data[output_SingleSelectValueAndLabelKeyPrefix+key] !== undefined) {
          processElement.Data[output_SingleSelectValueAndLabelKeyPrefix+key] = undefined;
        }
      }
      //...to MultiSelectValue_ string arrays
      if (k.startsWith(local_MultiListValuesKeyPrefix)) {
        let key = k.substr(local_MultiListValuesKeyPrefix.length);
        if (processElement[k]) {
          processElement.Data[output_MultiSelectValuesKeyPrefix+key] = processElement[k].map(v => v.value);
        } else if (processElement.Data[output_MultiSelectValuesKeyPrefix+key] !== undefined) {
          processElement.Data[output_MultiSelectValuesKeyPrefix+key] = undefined;
        }
      }
      if (k.startsWith(local_MultiListValuesAndLabelsKeyPrefix)) {
        let key = k.substr(local_MultiListValuesAndLabelsKeyPrefix.length);
        if (processElement[k]) {
          processElement.Data[output_MultiSelectValuesAndLabelsKeyPrefix+key] = processElement[k];
        } else if (processElement.Data[output_MultiSelectValuesAndLabelsKeyPrefix+key] !== undefined) {
          processElement.Data[output_MultiSelectValuesAndLabelsKeyPrefix+key] = undefined;
        }
      }
    });

    processElement.JsonData = JSON.stringify(processElement.Data);
    this.props.onUpdate(processElement)
      .then(processElement => {
        this.setState({ShowProgressIndicator:false});
        this.handleClose(processElement);
      })
      .catch(this.handleApiError);
  }

  updateProcessElementState(propertyName, isData, value) {
    let processElement = {...this.state.ProcessElement};
    if (isData) {
      processElement.Data[propertyName] = value;
    } else {
      processElement[propertyName] = value;
    }
    this.setState({ProcessElement: processElement});
  }

  setStringProperty = (propertyName, isData, value, trimEnd) => {
    if (trimEnd) {
      value = value.trimEnd();
    }
    this.updateProcessElementState(propertyName, isData, value);
  }

  setNumberProperty = (propertyName, isData, value) => {
    this.updateProcessElementState(propertyName, isData, value);
  }

  setBoolProperty = (propertyName, isData, value) => {
    this.updateProcessElementState(propertyName, isData, value);
  }

  setAnyProperty = (propertyName, isData, value) => {
    this.updateProcessElementState(propertyName, isData, value);
  }

  handleTextFieldChange = (propertyName, isData) => eventOrValue => {
    if (eventOrValue.target) {
      this.setStringProperty(propertyName, isData, eventOrValue.target.value);
    } else if (typeof eventOrValue === "string") {
      this.setStringProperty(propertyName, isData, eventOrValue);  
    }
  }

  handleRichTextChange = (propertyNamePrefix, isData) => (stateAsJsonString, stateAsHtml, stateAsPlainText) => {
    this.setStringProperty(`${propertyNamePrefix}RichTextJson`, isData, stateAsJsonString);
    this.setStringProperty(`${propertyNamePrefix}Html`, isData, stateAsHtml);
    this.setStringProperty(propertyNamePrefix, isData, stateAsPlainText);
  }

  handleCheckboxChange = (propertyName, isData) => e => {
    this.setBoolProperty(propertyName, isData, e.target.checked);
  }

  getOnChangeHandlers = () => {
    return {
      setStringProperty: this.setStringProperty,
      setNumberProperty: this.setNumberProperty,
      setBoolProperty: this.setBoolProperty,
      setAnyProperty: this.setAnyProperty,
      handleTextFieldChange: this.handleTextFieldChange,
      handleRichTextChange: this.handleRichTextChange,
      handleCheckboxChange: this.handleCheckboxChange,
    }
  }

  handleGetLocalListValuesPropertyName = (id, isMulti) => {
    return (isMulti)
      ? local_MultiListValuesKeyPrefix + id
      : local_SingleListValueKeyPrefix + id;
  }

  handleGetLocalListValuesAndLabelsPropertyName = (id, isMulti) => {
    return (isMulti)
      ? local_MultiListValuesAndLabelsKeyPrefix + id
      : local_SingleListValueAndLabelKeyPrefix + id;
  }

  getSelectedOptionsToSave = (selectedOptions, isMulti) => {
    // As some labels are too fancy for persistence, we look for
    // a plainLabel to persist in their place
    let selectedOptionsToSave;
    if (isMulti) {
      selectedOptionsToSave = [];
      selectedOptions.forEach(so => {
        if (so.plainLabel) {
          selectedOptionsToSave.push({
            value: so.value,
            label: so.plainLabel,
          });
        } else {
          selectedOptionsToSave.push(so);
        }
      })
    } else {
      if (selectedOptions) {
        if (selectedOptions.plainLabel) {
          selectedOptionsToSave = {
            value: selectedOptions.value,
            label: selectedOptions.plainLabel,
          };
        } else {
          selectedOptionsToSave = selectedOptions;
        }
      }
    }
    return selectedOptionsToSave;
  }

  handleSelectionListValueChange = (id, isMulti) => selectedOptions => {
    const selectedOptionsToSave = this.getSelectedOptionsToSave(selectedOptions, isMulti);    
    let ProcessElement = {...this.state.ProcessElement};
    ProcessElement[this.handleGetLocalListValuesPropertyName(id, isMulti)] = selectedOptionsToSave;
    ProcessElement[this.handleGetLocalListValuesAndLabelsPropertyName(id, isMulti)] = selectedOptionsToSave;
    this.setState({ProcessElement});
  }

  handleSelectionListCreateOption = (id, isMulti) => option => {
    let listValue = {value:option,label:option};
    let processElement = {...this.state.ProcessElement};
    
    let selectedOptions = processElement[this.handleGetLocalListValuesAndLabelsPropertyName(id, isMulti)];
    let newSelectedOptionOrOptions = [];
    if (isMulti) {
      if (!selectedOptions) {
        newSelectedOptionOrOptions = [listValue];
      } else {
        newSelectedOptionOrOptions = selectedOptions.concat(listValue);
      }
    } else {
      newSelectedOptionOrOptions = listValue;
    }    
    this.handleSelectionListValueChange(id, isMulti)(newSelectedOptionOrOptions);
  }

  handleAllowedAssetsForTagsValueChange = selectedOptions => {
    const selectedOptionsToSave = this.getSelectedOptionsToSave(selectedOptions, true);
    let ProcessElement = {...this.state.ProcessElement};
    ProcessElement.AllowedAssetListValuesForTags = selectedOptionsToSave;
    this.setState({ProcessElement});
  }

  handleDisallowedTagListValuesChange = selectedOptions => {
    const selectedOptionsToSave = this.getSelectedOptionsToSave(selectedOptions, true);
    let ProcessElement = {...this.state.ProcessElement};
    ProcessElement.DisallowedTagListValues = selectedOptionsToSave;
    this.setState({ProcessElement});
  }

  handleSetCloseDialogFunc = func => {
    this.CloseDialogFunc = func;
  }

  handleTabChange = (e, SelectedTab) => {
    this.setState({SelectedTab});
  }

  handleGetAssetListValuesPromise = debounce(filter => {
    return HandleGetAssets(this.props.organizationId, this.props.projectId, this.handleApiError, filter)
      .then(assets => {
        return assets.map(a => ({ value: a.ID, label: a.Name, }));
      });
  }, 250);

  handleClose = element => {
    if (!element) {
      element = {...this.state.ProcessElement};
    }
    this.setState({ ProcessElement: null });
    if (this.CloseDialogFunc) {
      this.CloseDialogFunc(element);
    }
    if (this.props.onClose) {
      this.props.onClose(element);
    }
  }

  handleApiError = err => {
    this.setState({ShowProgressIndicator: false, ShowProgressIndicatorImmediately: false});
    this.props.onApiError(err);
  }

  componentDidMount() {
    this.handleLoadProcessElement();
  }

  render() {
    const {
      ProcessElement,
      SelectedTab,
      ShowProgressIndicator,
      ShowProgressIndicatorImmediately,
    } = this.state;
    const { 
      // theme,
      classes,
      open,
      dialogWidth,
      onGetContent,
      location,
      history,
      organizationId,
      projectId,
      processElementConnections_Input,
    } = this.props;

    let dialogActions = (
      <DialogActions>
        <Button onClick={e => this.handleClose()}>
          CLOSE
        </Button>
        <Button onClick={this.handleUpdateProcessElement}>
          SAVE
        </Button>
      </DialogActions>
    );

    let progressIndicator = null;
    if (ShowProgressIndicator || ShowProgressIndicatorImmediately) {
      progressIndicator = (
        <ProgressIndicator showImmediately={ShowProgressIndicatorImmediately} />
      );
    }

    let elementNameTextField;
    let processElementSpecificContent;
    let tagInheritanceContent;
    if (ProcessElement) {
      elementNameTextField = (
        <TextField
          variant="outlined"
          label="Name"
          value={ProcessElement.Name}
          onChange={this.handleTextFieldChange("Name")}
          InputLabelProps={{ shrink: true, }}
          fullWidth
        />
      );

      if (ProcessElement.ID) {
        processElementSpecificContent = onGetContent(location, history, organizationId, projectId,
          ProcessElement, processElementConnections_Input,
          this.handleGetSettingsState, this.handleSetSettingsState,
          this.handleGetLocalProperty, this.handleSetLocalProperty,
          this.handleApiError,
          this.props.onAlert,
          this.handleGetLocalListValuesAndLabelsPropertyName, this.getOnChangeHandlers(),
          this.handleSelectionListValueChange,
          this.handleSelectionListCreateOption,
          this.handleSetCloseDialogFunc);
      }

      tagInheritanceContent = (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <AsyncSelectControl label="Allowed assets for tag inheritance" 
              isMulti
              key="assetsForTagInheritance" // Needed to avoid a quirk when the matching control-by-position on the PROPERTIES tab is also an AsyncSelectControl
              onGetOptionsFilterPromise={this.handleGetAssetListValuesPromise} 
              listValues={ProcessElement.AllowedAssetListValuesForTags}
              onValueChange={this.handleAllowedAssetsForTagsValueChange}
            />
          </Grid>
          <Grid item>
            {
              GetTagsControl(organizationId, projectId, null, 
                false, false, null, true,
                ProcessElement.DisallowedTagListValues,
                TagListValues => {},
                this.handleDisallowedTagListValuesChange,
                false, -1, "Disallowed tags to inherit", true,
                "disallowedTagsForInheritance" // Needed to avoid a quirk when the matching control-by-position on the PROPERTIES tab is also an AsyncSelectControl
              )
            }
          </Grid>
        </Grid>
      );
    }

    let tabContent;
    switch (SelectedTab) {
      case "elementSpecificContent":
        tabContent = processElementSpecificContent;
        break;
      case "tagInheritance":
        tabContent = tagInheritanceContent;
        break;
      default:
        break;
    }

    const tabs = (ProcessElement) ? (
      <Tabs
        variant="fullWidth"
        className={classes.tabs}
        value={SelectedTab}
        onChange={this.handleTabChange}
      >
        <Tab label="Properties" value="elementSpecificContent" className={classes.tab} />
        <Tab label="Tag Inheritance" value="tagInheritance" className={classes.tab} />
      </Tabs>
    ) : null;

    const tabsContent = (
      <div className={classes.innerDialogContent}>
        {tabs}
        <div className={classes.tabContent}>
          <div>
            {tabContent}
          </div>
        </div>
      </div>
    );
    const processElementSpecificContentContainer = (
      <div className={classes.innerDialogContent}>
        {processElementSpecificContent}
      </div>
    );
    
    let content;
    if (ProcessElement) {
      switch (ProcessElement.Type) {
      case "Action":
        switch (ProcessElement.Subtype) {
          case "AssetItem":
          case "DocumentFromTemplate":
          case "FormShare":
          case "Task":
          content = tabsContent;
          break;
        default:
          content = processElementSpecificContentContainer;
          break;
        }
        break;
      case "Trigger":
      default:
        content = processElementSpecificContentContainer;
        break;
      }
    }

    return (
      <Dialog
        fullWidth
        maxWidth={dialogWidth || "sm"}
        open={open}
        onClose={e => this.handleClose()}
        classes={{
          paper:classes.dialogPaper,
        }}
        // aria-labelledby="dialog-title"
        // aria-describedby="dialog-description">
        // <DialogTitle id="dialog-title">
        //   <span>{ProcessElement.Name}</span>
        // </DialogTitle>
        >
        <DialogContent className={classes.dialogContent}>
          {progressIndicator}
          
          <div className={classes.contentContainer}>
              <div>
                {elementNameTextField}
              </div>
              {content}
          </div>
        </DialogContent>
        {dialogActions}
      </Dialog>
    );
  }
}

ProcessElementDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  onApiError: PropTypes.func.isRequired,
  onAlert: PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(ProcessElementDialog);